// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/icon-number.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sq-input {\n    box-shadow: .2px .2px;\n    box-sizing: border-box;\n    border: 1px solid #365073;\n    border-radius: 10px;\n    outline-offset: -2px;\n    display: inline-block;\n    transition: border-color .2s ease-in-out, background .2s ease-in-out;\n    padding-left: 16px;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat left;\n    background-size: 25px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/card/form.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,sBAAsB;IACtB,yBAAyB;IACzB,mBAAmB;IACnB,oBAAoB;IACpB,qBAAqB;IACrB,oEAAoE;IACpE,kBAAkB;IAClB,kEAAqE;IACrE,qBAAqB;AACzB","sourcesContent":[".sq-input {\n    box-shadow: .2px .2px;\n    box-sizing: border-box;\n    border: 1px solid #365073;\n    border-radius: 10px;\n    outline-offset: -2px;\n    display: inline-block;\n    transition: border-color .2s ease-in-out, background .2s ease-in-out;\n    padding-left: 16px;\n    background: url(\"../../assets/images/icon-number.svg\") no-repeat left;\n    background-size: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
