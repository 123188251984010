import styled from '@emotion/styled';
import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import buttonClose from '../../assets/images/button-close.svg';
import Button, { ButtonType } from '../../components/Button';
import { notification } from '../../components/notification';
import CustomSpinner from '../../components/spinner/CustomSpinner';
import TextField, {
  TextFieldIconType,
  TextFieldType,
} from '../../components/TextField';
import UserContext from '../../contexts/UserContext';
import PropertyFiltersContext from '../../contexts/PropertyFiltersContext';
import {
  createBuyerNotificationByMail,
  inviteBuyerToAgentService,
  linkSellerWithAgent,
  updateSellerDetails,
} from '../../services/NavihomeService';
import validator from 'email-validator';
interface InviteSellerModalProps {
  isShowing: boolean;
  closeAction: () => void;
  setHomePage: any;
  propertyId: number;
  mlsId?: number;
}
const Customheading = styled.h1``;

const InviteSellerModal: React.FC<InviteSellerModalProps> = ({
  isShowing,
  closeAction,
  setHomePage,
  propertyId,
  mlsId
}) => {
  const { userState } = useContext(UserContext);
  const {setCreateNotificationsInfo, setGetNotificationsUserEmail} = useContext(PropertyFiltersContext);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(`Hello! Connect with your agent to schedule your listing's showing schedule and manage stats for your listed property.`);
  const [isLoading, setIsLoading] = useState(false);
  const [isInput, setIsInput] = useState(false);
  const [isInputMessage, setIsInputMessage] = useState('');

  const inviteSeller = async () => {
    const token = userState?.agent?.token ?? '';
    setIsLoading(true);
    try {
      const result: any = await inviteBuyerToAgentService(
        name,
        email,
        message,
        token,
        'seller'
      );
      let payload = {
        name: name,
        sellerEmail: email,
        message: message,
      };
      // await updateSellerDetails(propertyId, payload, token);
      

      if (result?.message === 'Seller Invited Successfully!') {
        
        // const res: any = await linkSellerWithAgent(
        //   result?.buyerId,
        //   userState?.agent?.agentId
        // );
      }
      if (result?.error) {
        setIsLoading(false);
        return notification(result.message, 'error');
      }
      await sendNotificationToBuyer();
      notification(`Seller Invited Successfully`, 'success');
      closeAction();
      setHomePage(true);
      return;
    } catch (error) {
      setIsLoading(false);
      console.log('ERROR:', error);
    }
  };

  let sendNotificationToBuyer = async () => {
    const token = userState?.agent?.token;
    setIsLoading(true);
    try {
      const result: any = await createBuyerNotificationByMail(
        email,
        `Listing Agent ${userState?.agent?.name.toUpperCase()} listed a property #${mlsId} and wants to connect with you. Accept the request to get started.`,
        'invite',
        token,
        'seller',
        propertyId
      );
      // await createBuyerNotificationByMail(
      //   email,
      //   `Listing Agent ${userState?.agent?.name.toUpperCase()} listed a property #${mlsId}`,
      //   'no',
      //   token,
      //   'seller',
      //   propertyId
      // );
      if (result?.error) {
        setIsLoading(false);
        return console.log(result.message, 'error');
      }
      setCreateNotificationsInfo(String(Math.floor(Math.random()*100+1)));
      setGetNotificationsUserEmail(email)
      setIsLoading(false);
      closeAction();
      setHomePage(true);
      return;
    } catch (error) {
      setIsLoading(false);
    }
  };

  const checkInputHandler = async () => {
    if (!name) {
      setIsInput(true);
      return notification('Please Enter Name!', 'error');
    }
    if (!email) {
      setIsInput(true);
      return notification('Please Enter Email!', 'error');
    }
    if (!validator.validate(email)) {
      return notification('Please Enter Valid Email!', 'error');
    }
    if (!message) {
      setIsInput(true);
      return notification('Please Enter Message!', 'error');
    }

    await inviteSeller();
    
  };

  const modalClassName = isShowing
    ? 'invite-buyer-modal u-visible'
    : 'invite-buyer-modal u-hidden';
  const cardClassName = isShowing
    ? 'invite-buyer-modal__card animation__pop-in'
    : 'invite-buyer-modal__card animation__pop-out';
  const isMobile = useMediaQuery({ maxWidth: 500 });

  return (
    <div className={modalClassName}>
      {isLoading && <CustomSpinner />}
      <div className={cardClassName}>
        <div className="invite-buyer-modal__row">
          <Customheading>
            Invite
            <span style={{ borderBottom: '3px solid #C2D500' }}> Seller</span>
          </Customheading>
          <img
            className="invite-buyer-modal__close-button"
            src={buttonClose}
            onClick={closeAction}
            alt="Close button"
          />
        </div>
        <p className="invite-buyer-modal__subtitle">
          Add your seller to keep them updated on showings!
        </p>
        {isInput && (
          <p className="invite-buyer-modal__subtitle" style={{ color: 'red' }}>
            {isInputMessage}
          </p>
        )}
        <div className="invite-buyer-modal__column">
          <TextField
            value={name}
            setValue={setName}
            placeholder="Jane Doe"
            label="Name"
            type={TextFieldType.ICON_AND_LABEL}
            icon={TextFieldIconType.USER}
            isRequired={true}
            width={isMobile ? undefined : 38.4}
            className="invite-buyer-modal__name-field"
          />
          <TextField
            value={email}
            setValue={setEmail}
            placeholder="janedoe@hotmail.com"
            label="Email"
            type={TextFieldType.ICON_AND_LABEL}
            icon={TextFieldIconType.EMAIL}
            isRequired={true}
            width={isMobile ? undefined : 38.4}
            className="invite-buyer-modal__email-field"
          />
          <TextField
            value={message}
            setValue={setMessage}
            placeholder="Hello! Connect with your agent to schedule your listing's showing schedule and manage stats for your listed property."
            label="Invite message"
            type={TextFieldType.BIG}
            icon={TextFieldIconType.EMAIL}
            isRequired={true}
            width={isMobile ? undefined : 38.4}
            className="invite-buyer-modal__message-field"
          />
          <Button
            type={ButtonType.PRIMARY}
            text="SEND INVITE"
            clickHandler={checkInputHandler}
            width={isMobile ? undefined : 18.5}
            className="invite-buyer-modal__button"
          />
        </div>
      </div>
    </div>
  );
};

export default InviteSellerModal;
