import { Avatar, Col, Divider, Row, Input } from "antd";
import { CaretRightOutlined, CommentOutlined, UserOutlined } from "@ant-design/icons";
import React, { useState, useContext, useEffect } from "react";
import styled from "@emotion/styled";
import {
  createBuyerNotificationById,
  createPropertyCommentForAgent,
  createPropertyCommentForBuyer,
  getBuyerAccountSettings,
  getPropertyCommentForAgent,
  getPropertyCommentForBuyer,
  inviteAgentByBuyer,
  postMessageToUser
} from "../services/NavihomeService";
import UserContext, { UserType } from "../contexts/UserContext";
import AgentState from "../contexts/AgentContext";
import { Spinner } from "reactstrap";
import { io } from "socket.io-client";
import { Socket } from "dgram";
import PropertyFiltersContext from "../contexts/PropertyFiltersContext";

const { TextArea } = Input;
const CustomDivider = styled(Divider)`
  background: #c2d500;
  margin: 18px 0px;
`;

const CustomSpan = styled.span`
  color: #004876;
  font-weight: 500;
  font-size: 16px;
`;
const CustomTextSpan = styled.span`
  font-size: 14px;
`;

const CustomTextArea = styled(TextArea)`
  border-radius: 10px;
  border: 1px solid #004876;
`;

const CustomDivSendButton = styled.div`
  position: absolute;
  right: 8px;
  bottom: 8px;
  background: #c2cc30;
  border-radius: 6px;
  padding: 2px 6px 4px 6px;
  cursor: pointer;
`;
const CustomParaPrev = styled.p`
  font-weight: 600;
  cursor: pointer;
  padding: 6px;
  &:hover {
    text-decoration: underline;
  }
`;

const CustomAvatarCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 5px;
`;

const CustomAvatar = styled(Avatar)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomCommentCont = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

const CommentCont = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;
const CustomCommentSpinner = styled(Spinner)`
  text-align: center;
  align-self: center;
`;
const CustomSpinnerDiv = styled.div`
  display: flex;
  padding-bottom: 16px;
  justify-content: center;
`;

interface PropertyCommentSectionPropsModel {
  propertyId?: number;
  agentId?: number;
  buyerId?: number;
  agentName?: string;
  buyerName?: string;
  agentPhone?: string;
  buyerPhone?: string;
  propertyType?: string;
  mlsId?: number;
  latitude?: number;
  longitude?: number;
  country?: string;
  imageUrl: string;
  listPrice: number;
  streetNumber: number;
  streetName: string;
  city?: string;
  state?: string;
  postalCode: string;
  bedrooms: number;
  bathrooms: number;
  area: number;
  bathsHalf?: number;
  opneHouse?: string;
  setOpenHouseOnCommentProp?: (value: string) => void;
  propertyDetails?: any;
  openHouseStartTime?: Date;
  openHouseEndTime?: Date;
  openHouseId?: string;
  openHouseKey?: string;
  listingAgentName?: string;
}

const PropertyCommentSection: React.FC<PropertyCommentSectionPropsModel> = ({
  propertyId,
  agentId,
  buyerId,
  agentName,
  buyerName,
  agentPhone,
  buyerPhone,
  propertyType,
  mlsId,
  latitude,
  longitude,
  country,
  listPrice,
  imageUrl,
  streetNumber,
  streetName,
  city,
  state,
  postalCode,
  bedrooms,
  bathrooms,
  area,
  openHouseId,
  openHouseKey,
  bathsHalf,
  opneHouse,
  setOpenHouseOnCommentProp,
  propertyDetails,
  openHouseEndTime,
  openHouseStartTime,
  listingAgentName
}): JSX.Element => {
  const [comments, setComments] = useState<Array<any>>([]);
  const [totalComments, setTotalComments] = useState<Array<any>>([]);
  const [inputCommnet, setInputComment] = useState<string>("");
  const [maxAgentLength, setMaxAgentLength] = useState<number>(7);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userState } = useContext(UserContext);
  const [socket, setSocket] = useState<Socket>();
  const { agentState } = useContext(AgentState);

  const commentsPayload: any = {
    comment: inputCommnet,
    propertyType: propertyType || "type",
    agentId: userState?.buyer?.buyerAgentId || agentId || 0,
    mlsId: mlsId || 0,
    latitude: latitude || 0,
    longitude: longitude || 0,
    country: country || "United State",
    imageUrl: imageUrl || "",
    listPrice: listPrice || 0,
    streetNumber: streetNumber || 0,
    streetName: streetName || "",
    city: city || "",
    state: state || "",
    postalCode: postalCode || "",
    bedrooms: bedrooms || 0,
    bathrooms: bathrooms || 0,
    area: area || 0,
    bathsHalf: bathsHalf || 0,
    opneHouse: opneHouse || "open",
    propertyDetails: Object?.keys(propertyDetails)?.length > 0 ? JSON.stringify(propertyDetails) : {},
    openHouseStartTime: openHouseStartTime ? openHouseStartTime : new Date(),
    openHouseEndTime: openHouseEndTime ? openHouseEndTime : new Date(),
    openHouseId: openHouseId ? openHouseId : "",
    openHouseKey: openHouseKey ? openHouseKey : "",
    createdAt: new Date(),
    updatedAt: new Date(),
    listingAgentName: listingAgentName ? listingAgentName : propertyDetails?.agent?.firstName + " " + propertyDetails?.agent?.lastName
  };

  const { setSocketCall, setCreateNotificationsInfo } = useContext(PropertyFiltersContext);

  const getPropertyComment = async () => {
    setIsLoading(true);
    try {
      if (userState?.userType === UserType?.BUYER) {
        const commentsData: any = await getPropertyCommentForBuyer(userState?.buyer?.buyerAgentId, propertyId, userState?.buyer?.token);
        if (commentsData?.length > 0) {
          setOpenHouseOnCommentProp(commentsData?.[0]?.opneHouse?.length > 0 ? commentsData?.[0]?.opneHouse : "");
          const len = commentsData?.length;
          if (len > 7) {
            setComments(commentsData?.slice(len - maxAgentLength, len));
            setTotalComments(commentsData);
            setIsLoading(false);
          } else {
            setComments(commentsData);
            // setTotalComments(commentsData);
            setIsLoading(false);
          }
        } else {
          setComments([]);
          setTotalComments([]);
        }
        setIsLoading(false);
      }

      if (userState?.userType === UserType?.AGENT && (agentState?.buyerId || buyerId)) {
        const commentsData: any = await getPropertyCommentForAgent(agentState?.buyerId || buyerId, propertyId, userState?.agent?.token);
        if (commentsData?.length > 0) {
          setOpenHouseOnCommentProp(commentsData?.[0]?.opneHouse?.length > 0 ? commentsData?.[0]?.opneHouse : "");
          setComments(commentsData);
          setIsLoading(false);
        } else {
          setComments([]);
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const recieve = (val: string) => {
    socket?.emit("propertyComments", val);
  };

  useEffect(() => {
    // const newSocket: any = io("http://localhost:8001",{multiplex: false}); { multiplex: false, transports: ["websocket", "polling"], withCredentials: false, forceNew: true }
    const newSocket: any = io(process.env.REACT_APP_BASE_URL,{path: "/apiwebsocket",port:8001, transports:["polling"]});

    newSocket?.on("connect", () => {});
    setSocket(newSocket);
  }, [setSocket]);

  const Messagelistenr = (message: string) => {
    if (userState?.userType === UserType?.BUYER) {
      // setGetNotificationsUserId(agentId);
      setCreateNotificationsInfo(String(Math.floor(Math.random() * 100 + 1)));
      getPropertyComment();
    }
    if (userState?.userType === UserType?.AGENT) {
      getPropertyComment();
      setCreateNotificationsInfo(String(Math.floor(Math.random() * 100 + 1)));
      // setGetNotificationsUserId(buyerId);
    }

    setSocketCall(String(Math.random() * 10) + 10);
  };

  useEffect(() => {
    socket?.on("propertyComments", Messagelistenr);
    return () => {
      socket?.off("propertyComments", Messagelistenr);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Messagelistenr]);

  const handleInputComments = async () => {
    if (inputCommnet?.length === 0) return;
    setIsLoading(true);
    let buyerPhone: string = "",
      messageType: string = "No",
      preferredContact: string = "No";
    try {
      if (userState?.userType === UserType?.BUYER && (userState?.buyer?.buyerAgentId || agentId)) {
        commentsPayload["messageType"] = "Realtime";
        commentsPayload["agentName"] = userState?.buyer?.buyerAgentName;
        commentsPayload["agentEmail"] = userState?.buyer?.buyerAgentEmail;
        commentsPayload["buyerName"] = userState?.buyer?.name;
        commentsPayload["buyerEmail"] = "Agent";
        await createPropertyCommentForBuyer(userState?.buyer?.buyerAgentId || agentId, propertyId, userState?.buyer?.token, commentsPayload);
        setCreateNotificationsInfo(String(Math.floor(Math.random() * 100 + 1)));
        recieve(commentsPayload?.comment);
        setInputComment("");
        if (userState?.buyer?.buyerAgentId || agentId) {
          await inviteAgentByBuyer(
            userState?.buyer?.buyerAgentId || agentId,
            "Comment",
            `Your Buyer ${userState?.buyer?.name?.toUpperCase()} has commented on a property #${mlsId}.`,
            propertyId,
            "",
            "",
            userState?.buyer?.buyerId || agentState?.buyerId || buyerId
          );
          if (userState?.buyer?.buyerAgentPhone) {
            postMessageToUser(userState?.buyer?.buyerAgentPhone, `Your Buyer ${userState?.buyer?.name?.toUpperCase()} has commented on a property #${mlsId}.`);
          }
        }
      }

      if (userState?.userType === UserType?.AGENT) {
        buyerName = agentState?.buyerName ?? "";
        buyerPhone = agentState?.buyerPhone ?? "";
        preferredContact = agentState?.buyerPreferredContact;
        const buyerAccountSettingsResult: any = await getBuyerAccountSettings(userState?.agent?.token, buyerId ?? agentState.buyerId);
        if (buyerAccountSettingsResult?.length > 0) {
          const lastIndex: number = buyerAccountSettingsResult?.length - 1;
          messageType = buyerAccountSettingsResult?.[lastIndex]?.notifyCommentForReqFavProp;
        }
        commentsPayload["messageType"] = preferredContact === "CONTACT_RADIO_BOTH" || preferredContact === "CONTACT_RADIO_EMAIL" ? messageType : "No";
        commentsPayload["agentName"] = userState?.agent?.name;
        commentsPayload["agentEmail"] = "Buyer";
        commentsPayload["buyerName"] = agentState?.buyerName;
        commentsPayload["buyerEmail"] = agentState?.buyerEmail;

        await createPropertyCommentForAgent(agentState?.buyerId || buyerId, propertyId, userState?.agent?.token, commentsPayload);

        setInputComment("");
        if (agentState?.buyerId || buyerId) {
          await createBuyerNotificationById(
            agentState?.buyerId || buyerId,
            `Your Agent ${userState?.agent?.name?.toUpperCase()} has commented on a property #${mlsId}.`,
            "Comment",
            userState?.agent?.token,
            "buyer",
            propertyId,
            "No",
            "",
            "",
            ""
          );

          if (messageType === "Yes" && (preferredContact === "CONTACT_RADIO_BOTH" || preferredContact === "CONTACT_RADIO_PHONE")) {
            postMessageToUser(buyerPhone, `Your Agent ${userState?.agent?.name?.toUpperCase()} has commented on a property #${mlsId}.`);
          }
        }
        recieve(commentsPayload?.comment);
      }
      setIsLoading(false);
      getPropertyComment();
    } catch (error) {
      setIsLoading(false);
      console?.log("error", error);
    }
  };
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (inputCommnet?.length === 0) return;
    if (event?.key === "Enter") {
      handleInputComments();
    }
  };

  const prevCommentHanlder = () => {
    if (maxAgentLength <= totalComments?.length - 5) setMaxAgentLength(maxAgentLength + 5);
    else setMaxAgentLength(totalComments?.length);
    setComments(totalComments?.slice(totalComments?.length - maxAgentLength, totalComments?.length));
  };
  const seeLessHandler = () => {
    setComments(comments?.slice(comments?.length - 7, comments?.length));
    setMaxAgentLength(5);
  };
  useEffect(() => {
    getPropertyComment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyId]);

  const renderComments = (item: any, index: any) => {
    return (
      <div>
        <Row key={index}>
          <Col span={4}>
            {item?.sender === "agent" ? (
              <div>
                {item?.agent?.avatarUrl ? (
                  <CustomAvatarCont>
                    <CustomAvatar size={38} src={item?.agent?.avatarUrl} className="avatar" />
                  </CustomAvatarCont>
                ) : (
                  <CustomAvatarCont>
                    <CustomAvatar size={38} icon={<UserOutlined />} className="avatar" />
                  </CustomAvatarCont>
                )}
              </div>
            ) : (
              <>
                {item?.buyer?.avatarUrl ? (
                  <CustomAvatarCont>
                    <CustomAvatar size={38} src={item?.buyer?.avatarUrl} className="avatar" />
                  </CustomAvatarCont>
                ) : (
                  <CustomAvatarCont>
                    <CustomAvatar size={38} icon={<UserOutlined />} className="avatar" />
                  </CustomAvatarCont>
                )}
              </>
            )}
          </Col>
          <Col span={20}>
            <CustomCommentCont>
              <div>
                <CustomSpan>{item?.sender === "agent" ? <>{item?.agent?.name}</> : <>{item?.buyer?.name}</>}</CustomSpan>
              </div>
              <CustomTextSpan>{item?.comment}</CustomTextSpan>
            </CustomCommentCont>
          </Col>
          <CustomDivider />
        </Row>
      </div>
    );
  };

  return (
    <CommentCont>
      <h3>Comments</h3>
      <p style={{ fontSize: "16px", padding: "16px 0" }}>Add comments about this property to communicate with {userState?.userType === UserType?.AGENT ? <>{buyerName}</> : <>{agentName}</>}.</p>
      {totalComments?.length > 7 && (
        <>
          {comments?.length === totalComments?.length ? (
            <CustomParaPrev onClick={seeLessHandler}>See less</CustomParaPrev>
          ) : (
            <CustomParaPrev onClick={prevCommentHanlder}>Previous Comments</CustomParaPrev>
          )}
        </>
      )}
      <div>{comments?.map((name, key) => renderComments(name, key))}</div>
      <CustomSpinnerDiv>
        {isLoading && (
          <CustomCommentSpinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </CustomCommentSpinner>
        )}
      </CustomSpinnerDiv>

      <Row>
        <Col span={4}>
          <CustomAvatarCont>
            <CustomAvatar size={38} icon={<CommentOutlined />} className="avatar" />
          </CustomAvatarCont>
        </Col>
        <Col span={20}>
          <CustomTextArea
            placeholder="Write a comment"
            value={inputCommnet}
            onChange={(e) => {
              setInputComment(e?.target?.value);
            }}
            onKeyPress={handleKeyPress}
            rows={3}
          />
          {inputCommnet?.length > 0 && (
            <CustomDivSendButton onClick={handleInputComments}>
              <CaretRightOutlined style={{ color: "white", fontSize: "14px" }} />
            </CustomDivSendButton>
          )}
        </Col>
      </Row>
    </CommentCont>
  );
};

export default PropertyCommentSection;
