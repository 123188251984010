import radioOn from '../assets/images/button-radio-on.svg';
import radioOff from '../assets/images/button-radio-off.svg';
import {useMediaQuery} from 'react-responsive'

export enum ConsumerRadioType {
  BUYER = 'BUYER',
  SELLER = 'SELLER'
}

interface RadioProps {
  value: ConsumerRadioType;
  setValue: (value: ConsumerRadioType) => void;
  className?: string;
}

const ConsumerTypeRadio: React.FC<RadioProps> = ({value, setValue, className }) => {

  const isMobile = useMediaQuery({maxWidth: 576})
  const selectEmailHandler = () => {
    setValue(ConsumerRadioType.BUYER);
  }

  const selectPhoneHandler = () => {
    setValue(ConsumerRadioType.SELLER);
  }



  return (
    <div className={className === undefined ? "contact-radio" : `contact-radio ${className}`}>
      <p className="f-heading">Consumer type:</p>
      <div className='contact-radio-div'>
      <div className="contact-radio__option" onClick={selectEmailHandler}>
        <img className="contact-radio__option--on" src={radioOn} alt="Radio On" style={{ opacity: value === ConsumerRadioType.BUYER ? 1 : 0 }} />
        <img className="contact-radio__option--off" src={radioOff} alt="Radio Off" style={{ opacity: value === ConsumerRadioType.BUYER ? 0 : 1 }} />
        <p>Buyer</p>
      </div>
      <div className="contact-radio__option" onClick={selectPhoneHandler}>
        <img className="contact-radio__option--on" src={radioOn} alt="Radio On" style={{ opacity: value === ConsumerRadioType.SELLER ? 1 : 0 }} />
        <img className="contact-radio__option--off" src={radioOff} alt="Radio Off" style={{ opacity: value === ConsumerRadioType.SELLER ? 0 : 1 }} />
        <p>Seller</p>
      </div>
      </div>
    </div>
  );
};

export default ConsumerTypeRadio;