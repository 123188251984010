import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import Button, { ButtonType } from "../components/Button";
import Checkbox, { CheckboxType } from "../components/Checkbox";
import ContactRadio, { ContactRadioType } from "../components/ContactRadio";
import TextField, {
  TextFieldIconType,
  TextFieldType,
} from "../components/TextField";
import PhoneInput from "react-phone-input-2";
import { useMediaQuery } from "react-responsive";
import { notification } from "../components/notification";
import { ToastContainer } from "react-toastify";
import ForgotPasswordModal from "../modals/forgotPasswordModal";
import styled from "@emotion/styled";
import CustomSpinner from "../components/spinner/CustomSpinner";
import {
  postMessageToUser,
  registerBuyerService,
} from "../services/NavihomeService";
import LogInModal from "../modals/LoginModal";
import ThankyouModal from "../modals/ThankyouModal";
import { Col, Row } from "antd";
import { ConsumerRadioType } from "../components/consumerTypeRadio";
interface RegisterBuyerPagePropsModel {
  setIsShowingLoginModal: any;
}
const CustomPhoneInput = styled(PhoneInput)`
  &.react-tel-input .country-list .search-box {
    text-transform: capitalize;
  }
`;
const CustomHeadingDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  align-items: center;
`;

const CustomBackP = styled.p`
  margin-left: 12px;
  margin-top: 16px;
  cursor: pointer;
  font-size: 17px;
  &:hover {
    text-decoration: underline;
    font-weight: 600;
  }
`;

const Registerheading = styled.h1`
  font-size: 2.75rem;
  font-weight: 600;
  @media screen and (max-width: 455px) {
    font-size: 2.75rem;
  }
`;
const Subheading = styled.h3`
  font-size: 2rem;
  font-weight: 600;
  @media screen and (max-width: 357px) {
    &:after {
      display: none;
    }
  }
`;
const CustomSpan = styled.span`
  marginleft: 4px;
  cursor: pointer;
  color: #eb752f;
  border-bottom: 1px solid #eb752f;
  text-decoration: none;
  &:hover {
    color: blue;
    border-bottom: 1px solid blue;
  }
`;

const RegisterBuyerPage: React.FC<RegisterBuyerPagePropsModel> = ({
  setIsShowingLoginModal,
}): JSX.Element => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isVisibleLoginModal, setIsVisibleLoginModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowingForgotPasswordModal, setIsShowingForgotPasswordModal] =
    useState(false);
  const [preferredContact, setPreferredContact] = useState(
    ContactRadioType.EMAIL
  );
  const [preferredType, setPreferredType] = useState(ConsumerRadioType.BUYER);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordRequiredLength, setIsPasswordRequiredLength] =
    useState(false);
  const [doesPasswordContainUppercase, setDoesPasswordContainUppercase] =
    useState(false);
  const [doesPasswordContainLowercase, setDoesPasswordContainLowercase] =
    useState(false);
  const [doesPasswordContainNumer, setDoesPasswordContainNumber] =
    useState(false);
  const [isShowingThankyouModal, setIsShowingThankyouModal] =
    useState<boolean>(false);

  const [keepMeSignedIn, setKeepMeSignedIn] = useState(false);
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: 850 });

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let param = useQuery();

  const handleRegister = async () => {
    if (name === "") {
      return notification("Please enter full name.", "error");
    }

    if (email === "") {
      return notification("Please enter email.", "error");
    }
    if (phone === "") {
      return notification("Please enter phone number.", "error");
    }
    if (password === "") {
      return notification("Please enter password.", "error");
    }
    if (confirmPassword === "") {
      return notification("Please enter confirm password.", "error");
    }
    if (password !== confirmPassword) {
      return notification("Passwords don't match.", "error");
    }
    if (!isPasswordRequiredLength) {
      notification("Passwords must be at least 8 characters long.", "error");
      return;
    }
    if (!doesPasswordContainUppercase) {
      notification(
        "Passwords must contain at least 1 UPPERCASE letter.",
        "error"
      );
      return;
    }
    if (!doesPasswordContainLowercase) {
      notification(
        "Passwords must contain at least 1 lowercase letter.",
        "error"
      );
      return;
    }
    if (!doesPasswordContainNumer) {
      return notification(
        "Passwords must contain at least 1 number character.",
        "error"
      );
    }

    try {
      setIsLoading(true);
      const success: any = await registerBuyerService(
        email,
        password,
        phone,
        name,
        preferredContact,
        preferredType
      );

      if (!success) {
        setIsLoading(false);
        return notification(`${success}`, "error");
      } else {
        if (
          preferredContact === "CONTACT_RADIO_BOTH" ||
          preferredContact === "CONTACT_RADIO_PHONE"
        ) {
          postMessageToUser(
            phone,
            `Thank you for registering at Navihome. An email has been sent to your registered email. Please verify and login.`
          );
        }
        setIsLoading(false);
        setIsShowingThankyouModal(true);
        handlerModalTimeout();
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      return notification(
        `${
          error?.toString()?.includes("email must be an email")
            ? "Please enter a valid email!"
            : error
        } `,
        "error"
      );
    }
  };

  const handleCloseLoginModal = () => {
    setIsVisibleLoginModal(!isVisibleLoginModal);
  };
  const handleCloseForgotPasswordModal = () => {
    setIsShowingForgotPasswordModal(!isShowingForgotPasswordModal);
  };
  const handleCloseThankyouModal = () => {
    setIsShowingThankyouModal(!isShowingThankyouModal);
    history.push("/home");
  };
  const handlerModalTimeout = () => {
    setTimeout(() => {
      handleCloseThankyouModal();
    }, 4000);
  };
  const renderPasswordRequirements = () => {
    return (
      <div className="register-buyer-page__password-filters">
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;• is at least 8 characters long:
          {isPasswordRequiredLength ? (
            <span className="green">✔</span>
          ) : (
            <span className="red">✗</span>
          )}
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;• contains 1 UPPERCASE letter:
          {doesPasswordContainUppercase ? (
            <span className="green">✔</span>
          ) : (
            <span className="red">✗</span>
          )}
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;• contains 1 lowercase letter:
          {doesPasswordContainLowercase ? (
            <span className="green">✔</span>
          ) : (
            <span className="red">✗</span>
          )}
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;• contains 1 number character:
          {doesPasswordContainNumer ? (
            <span className="green">✔</span>
          ) : (
            <span className="red">✗</span>
          )}
        </p>
      </div>
    );
  };

  useEffect(() => {
    var tempIsPasswordRequiredLength = password?.length >= 8 ? true : false;
    var tempDoesPasswordContainUppercase = false;
    var tempDoesPasswordContainLowercase = false;
    var tempDoesPasswordContainNumer = false;

    for (var i = 0; i < password?.length; i++) {
      const char = password.charAt(i);
      if (!tempDoesPasswordContainUppercase) {
        tempDoesPasswordContainUppercase = /^[A-Z]*$/.test(char);
      }
      if (!tempDoesPasswordContainLowercase) {
        tempDoesPasswordContainLowercase = /^[a-z]*$/.test(char);
      }
      if (!tempDoesPasswordContainNumer) {
        tempDoesPasswordContainNumer = /^[0-9]*$/.test(char);
      }
    }

    setIsPasswordRequiredLength(tempIsPasswordRequiredLength);
    setDoesPasswordContainUppercase(tempDoesPasswordContainUppercase);
    setDoesPasswordContainLowercase(tempDoesPasswordContainLowercase);
    setDoesPasswordContainNumber(tempDoesPasswordContainNumer);
  }, [password, phone]);

  useEffect(() => {
    if (param?.get("type")?.toLocaleLowerCase() === "seller")
      setPreferredType(ConsumerRadioType.SELLER);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CustomBackP
        onClick={() => {
          history.goBack();
        }}>
        Back
      </CustomBackP>

      <div className="register-buyer-page">
        <div className="register-buyer-page__content">
          <div className="register-buyer-page__heading">
            {isLoading && <CustomSpinner />}
            <CustomHeadingDiv>
              <Registerheading>
                Regist
                <span style={{ borderBottom: "3px solid #C2D500" }}>
                  er now!
                </span>
              </Registerheading>
              <Button
                disable={
                  name && email && phone && password && confirmPassword
                    ? false
                    : true
                }
                type={ButtonType.REGISTER_BUTTON}
                text="Register"
                clickHandler={handleRegister}
                className="register-buyer-page__form-item"
              />
            </CustomHeadingDiv>
            <ToastContainer />
            <p>
              Create your account now! If you already have an account,{" "}
              <CustomSpan
                onClick={() => {
                  setIsVisibleLoginModal(true);
                }}>
                Login here
              </CustomSpan>
            </p>
          </div>
          <form>
            <div className="register-buyer-page__form-content">
              <div className="register-buyer-page__general-information">
                <Subheading>General Information</Subheading>
                <p>
                  You will receive reminders and notifications based on the
                  contact information below. You can update your contact
                  preferences after you sign in.
                </p>
                <TextField
                  value={name}
                  setValue={setName}
                  placeholder="Jane Doe"
                  label="Name"
                  type={TextFieldType.ICON_AND_LABEL}
                  icon={TextFieldIconType.USER}
                  isRequired={true}
                  width={isMobile ? undefined : 38.4}
                  className="register-buyer-page__form-item"
                />
                <TextField
                  value={email}
                  setValue={setEmail}
                  placeholder="janedoe@hotmail.com"
                  label="Email"
                  type={TextFieldType.ICON_AND_LABEL}
                  icon={TextFieldIconType.EMAIL}
                  isRequired={true}
                  width={isMobile ? undefined : 38.4}
                  className="register-buyer-page__form-item"
                />
                <br />
                <div
                  className="register-buyer-page__form-item"
                  style={{ position: "relative", bottom: "18px" }}>
                  <h5
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      position: "relative",
                      top: "11px",
                      color: "black",
                    }}>
                    Phone Number*
                  </h5>
                  <Row style={{ display: "block" }}>
                    <Col>
                      <CustomPhoneInput
                        value={phone}
                        inputClass="register-buyer-page__form-item"
                        inputStyle={{
                          justifyContent: "flexEnd",
                          bottom: "-2rem",
                          right: "-7rem",
                          height: "5.2rem",
                          width: isMobile ? "100%" : "38.4rem",
                          marginTop: "20px",
                          border: "1px solid #365073 ",
                          boxShadow: "0px 1px 5px #888888",
                          borderRadius: "8px",
                        }}
                        inputProps={{
                          setValue: { setPhone },
                        }}
                        onChange={(phone: any) => {
                          if (phone?.length >= 10) {
                            setPhone(phone);
                          } else {
                            setPhone("");
                          }
                        }}
                        dropdownStyle={{
                          width: "20rem",
                          paddingLeft: "3rem",
                          position: "relative",
                          bottom: "21rem",
                          overflowX: "hidden",
                          left: "5rem",
                        }}
                        country={"us"}
                        specialLabel=""
                        buttonStyle={{
                          position: "absolute",
                          bottom: "4.5rem",
                          height: "auto",
                          top: "2.5rem",
                        }}
                        placeholder="+1 (404) 123 4567"
                        disableDropdown={false}
                        autoFormat={true}
                        enableSearch={true}
                      />
                    </Col>
                  </Row>
                </div>
                <ContactRadio
                  value={preferredContact}
                  setValue={setPreferredContact}
                />
              </div>
              <div className="register-buyer-page__create-password">
                <Subheading>Create Password</Subheading>
                <p>Ensure that the password you create is strong:</p>
                {renderPasswordRequirements()}
                <TextField
                  value={password}
                  setValue={setPassword}
                  placeholder="••••••••"
                  label="Create Password"
                  type={TextFieldType.PASSWORD}
                  icon={TextFieldIconType.PASSWORD}
                  isRequired={true}
                  width={isMobile ? undefined : 38.4}
                  className="register-buyer-page__form-item"
                />
                <TextField
                  value={confirmPassword}
                  setValue={setConfirmPassword}
                  placeholder="••••••••"
                  label="Confirm Password"
                  type={TextFieldType.PASSWORD}
                  icon={TextFieldIconType.PASSWORD}
                  isRequired={true}
                  width={isMobile ? undefined : 38.4}
                  className="register-buyer-page__form-item register-buyer-page_type"
                />
                <Checkbox
                  type={CheckboxType.SMALL}
                  value={keepMeSignedIn}
                  setValue={setKeepMeSignedIn}
                  text="Keep me signed in"
                />
                {/* <Button
                type={ButtonType.PRIMARY}
                text="Register"
                clickHandler={handleRegister}
                className="register-buyer-page__form-item"
              /> */}
                {/* <ConsumerTypeRadio
                  value={preferredType}
                  setValue={setPreferredType}
                  className='register-buyer-page_type'
                /> */}
              </div>
            </div>
          </form>
          {isVisibleLoginModal && (
            <LogInModal
              isShowing={isVisibleLoginModal}
              closeAction={handleCloseLoginModal}
              setIsShowingForgotPasswordModal={setIsShowingForgotPasswordModal}
            />
          )}
          {isShowingForgotPasswordModal && (
            <ForgotPasswordModal
              isShowing={isShowingForgotPasswordModal}
              closeAction={handleCloseForgotPasswordModal}
              setIsShowingLoginModal={setIsVisibleLoginModal}
            />
          )}
          {isShowingThankyouModal && (
            <ThankyouModal
              isShowing={isShowingThankyouModal}
              closeAction={handleCloseThankyouModal}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default RegisterBuyerPage;
