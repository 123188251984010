import checkboxOn from '../assets/images/button-checkbox-on.svg';
import checkboxOff from '../assets/images/button-checkbox-off.svg';

export enum CheckboxType {
  REGULAR = 'CHECKBOX_REGULAR',
  SMALL = 'CHECKBOX_SMALL'
}

interface CheckboxProps {
  type: CheckboxType
  value: boolean;
  setValue: (value: boolean) => void;
  text: string;
  className?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({type, value, setValue, text, className }) => {
  const clickHandler = () => {
    setValue(!value);
  }

  const textClass = type === CheckboxType.SMALL ? 'f-caption' : ''

  return (
    <div className={className === undefined ? "checkbox" : `checkbox ${className}`} onClick={clickHandler}>
      <div className="checkbox__button">
        <img className="checkbox__button--on" src={checkboxOn} alt="Checkbox On" style={{ opacity: value ? 1 : 0 }} />
        <img className="checkbox__button--off" src={checkboxOff} alt="Checkbox Off" style={{ opacity: value ? 0 : 1 }} />
      </div>
      <p className={textClass}>{text}</p>
    </div>
  );
};

export default Checkbox;