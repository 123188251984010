// import React from 'react';

const ScheduleBody = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '80vh',
        background: '#dde1a3',
        padding: '200px',
        textAlign: 'center',
      }}
    >
      <h1>Schedule One Body</h1>
    </div>
  );
};

export default ScheduleBody;