import { parse } from "query-string";
import React, { useContext, useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { useGlobal } from "reactn";
import iconConnected from "../assets/images/icon-connected.svg";
import Button, { ButtonType } from "../components/Button";
import GoogleMapReact from "../components/GoogleMap/PropertyMap";
import PropertyCommentSection from "../components/PropertyCommentSection";
import SchoolInformationSummary from "../components/SchoolInformationSummary";
import GreatSchoolLogo from "../assets/images/greatschool.png";

import {
  Avatar,
  Card,
  Col,
  Divider,
  Grid,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import favoriteOff from "../assets/images/button-favorite-off.svg";
import favoriteOn from "../assets/images/button-favorite-on.svg";
import requestShowingOff from "../assets/images/button-request-showing-off.svg";
import requestShowingOn from "../assets/images/button-request-showing-on.svg";
import shareImg from "../assets/images/button-share.svg";
import { notification } from "../components/notification";
import FindAnAgentConfirmModal from "../modals/FindAnAgentConfirmModal";
import {
  addPropertyToFavoritesForBuyerService,
  addPropertyToRequestedForBuyerService,
  checkIfPropertyIsFavoriteForBuyerService,
  checkIfPropertyIsRequestedForBuyerService,
  checkIsListedPropertyService,
  createBuyerNotificationById,
  filterOpenHousesProperties,
  getActivatedSubscriptionPlan,
  getAgentAccountSettings,
  getAgentInfoByMlsId,
  getBuyerAccountSettings,
  getBuyerInfoService,
  getOpenHousesByCity,
  getPropByOpenHouseKey,
  getPropertiesByCity,
  getPropertyDetailsService,
  getSchoolInfoOfProperty,
  inviteAgentByBuyer,
  postMessageToUser,
  removePropertyFromFavoritesForBuyerService,
  removePropertyFromRequestedForBuyerService,
  totalViewsCountServices,
} from "../services/NavihomeService";

import { UserOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import moment from "moment-timezone";
import { useHistory } from "react-router";
import iconEmail from "../assets/images/icon-email.svg";
import iconPhone from "../assets/images/icon-phone.svg";
import CustomSpinner from "../components/spinner/CustomSpinner";
import AgentContext from "../contexts/AgentContext";
import UserContext, { UserType } from "../contexts/UserContext";
import AgentConnectingModal from "../modals/AgentConnecting";
import NotListAgentModal from "../modals/GoogleMapModals/Kevinheardsib";
import ListAgentModal from "../modals/GoogleMapModals/ReginaGeorge";
import LogInModal from "../modals/LoginModal";
import PropertyShareModal from "../modals/PropertyShareModal";
import FindAnAgentModal from "../modals/findAnAgentModal";
import ForgotPasswordModal from "../modals/forgotPasswordModal";

const { useBreakpoint } = Grid;
const { Option } = Select;

const CustomDiv = styled.div`
  font-size: 14px;
  margin: 10px 0px;
`;
const CustomParaOpenHouse = styled.p`
  font-weight: 600;
  font-size: 2rem;
`;
const CustomViewCalenderPara = styled.p`
  color: #eb752f;
  border-bottom: 1px solid #eb752f;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
`;
const CustomDivider = styled(Divider)`
  background: #004876;
  margin: 18px 0px;
  border-bottom: 1px solid #004876;
`;

const CustomCard: any = styled(Card) <{ screen: any }>`
  width: 224px;

  border: none;
  & .ant-card-body {
    padding: 8px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const CustomRowOtherProperty = styled(Row)`
  flex-wrap: wrap;
  justify-content: space-around;
  height: auto;
`;

const ImageGalleryDiv = styled.div`
  .image-gallery-index {
    font-size: 18px;
  }
`;
const CustomOpenHouseDiv = styled.div`
  background: #eb752f;
  border-radius: 14px;
  padding: 4px 8px;
  position: absolute;
  z-index: 3;
  top: 8px !important;
  left: 26px !important;
  p {
    color: white !important;
  }
  @media screen and (max-width: 576px) {
    margin-top: 12px;
    top: 6px;
    left: 6px;
  }
`;

const OpenHouseOtherPropDiv = styled.div`
  background: #eb752f;
  border-radius: 14px;
  padding: 4px 8px;
  position: absolute;
  /* z-index: 3; */
  top: 14px;
  left: 14px;
  p {
    color: white !important;
    font-size: 12px;
  }
  @media screen and (max-width: 576px) {
    margin-top: 12px;
    top: 6px;
    left: 6px;
  }
`;

const StartEndOtherPropDiv = styled.div`
  background: #eb752f;
  border-radius: 14px;
  padding: 4px 8px;
  position: absolute;
  /* z-index: 3; */
  top: 45px;
  left: 12px;
  p {
    color: white !important;
    font-size: 12px;
  }
  @media screen and (max-width: 576px) {
    margin-top: 12px;
    top: 6px;
    left: 6px;
  }
`;
const CustomBackPara = styled.p`
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  margin: 12px;

  &:hover {
    text-decoration: underline;
  }
`;

const CustomPriceHeading = styled.h4`
  margin-right: 10px;
`;

const CustomPara = styled.p`
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  width: 100%;
`;
const CustomHeading = styled.h1`
  @media screen and (min-width: 1200px) {
    font-size: 1.75rem;
  }
  @media screen and (max-width: 1200px) {
    font-size: calc(1.3rem + 0.6vw);
  }
`;
const CustomSharerow = styled(Row)`
  @media screen and (max-width: 1200px) {
    width: 92%;
  }
  @media screen and (max-width: 768px) {
    width: 88%;
  }
  @media screen and (max-width: 600px) {
    width: 85%;
  }
  @media screen and (max-width: 350px) {
    width: 82%;
  }
`;
interface PropertyDetailsPagePropsModel {
  propertyId?: string | number;
  propertyType?: string;
}

const PropertyDetailsPage: React.FC<PropertyDetailsPagePropsModel> = ({
  propertyId,
  propertyType,
}) => {
  const [isShowingSalesTab, setIsShowingSalesTab] = useState(true);
  let { mlsId }: any = useParams<{ mlsId: string }>();
  mlsId = mlsId == null ? propertyId : mlsId;
  const [imageSrc, setImageSrc] = useState("");
  const [buyerId, setBuyerId] = useState<number>(null);
  const [galleryImage, setGalleryImage] = useState([]);
  const [schoolInfo, setSchoolInfo] = useState([]);
  const [showAllSchool, setShowAllSchool] = useState(false);
  const [checkIsRequested, setCheckIsRequested] = useState(false);
  const [isShowingLoginModal, setIsShowingLoginModal] =
    useState<boolean>(false);
  const [isShowingForgotPasswordModal, setIsShowingForgotPasswordModal] =
    useState<boolean>(false);
  const [checkIsFavorite, setCheckIsFavorite] = useState(false);
  const [openHouseOnCommentProp, setOpenHouseOnCommentProp] =
    useState<string>("");
  const [isShowFindAnAgentConfirmModal, setIsShowFindAnAgentConfirmModal] =
    useState(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowNoPopertyConfirmModal, setIsShowNoPopertyConfirmModal] = useState<boolean>(false);
  const [remarks, setRemarks] = useState("");
  const [listPrice, setListPrice] = useState("");
  const [sqrtPrice, setSqrtPrice] = useState(" ");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [globalState, setGlobalState] = useGlobal<any>("propState");
  const [geoLoc, setGeoLoc] = useState({
    lat: 0,
    lng: 0,
    direction: "",
  });
  const isMobile = useMediaQuery({ maxWidth: 414 });
  const isResponsive = useMediaQuery({ maxWidth: 1200 });
  const [address, setAddress] = useState({
    fullAddress: " ",
    city: " ",
    country: " ",
    streetNumber: " ",
    streetName: " ",
    state: " ",
    postalCode: " ",
  });
  const [area, setArea] = useState("");
  const [area1, setArea1] = useState("");
  const [yearBuilt, setYearBuilt] = useState("");

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let param = useQuery();
  const [propDetail, setPropDetail] = useState({
    imageURL: "",
    bedrooms: 0,
    bathrooms: 0,
    bathsFull: 0,
    bathsHalf: 0,
    bathsThreeQuarter: " ",
    heating: " ",
    cooling: " ",
    fireplaces: " ",
    flooring: " ",
    interior: " ",
    laundryfeat: " ",
    subTypeText: " ",
    type: " ",
    parking: {
      description: " ",
      spaces: " ",
    },
    mls: {
      status: " ",
    },
    monthly_payment: " ",
    brokerage: " ",
    virtual_tour: 0,
    foundation: " ",
    roof: " ",
    directions: " ",
    agent: {
      firstName: "",
      lastName: "",
    },
    office: {
      name: "",
    },
    listingId: null,
    mlsId: null,
    lotSizeArea: "",
    lotSizeAreaUnits: "",
    area: 0,
    listPrice: 0,
  });
  const [taxDet, setTaxDet] = useState({
    id: " ",
    taxAnnualAmount: 0,
    taxYear: " ",
    land: " ",
    additions: " ",
    assessedValue: " ",
  });

  const [otherProperties, setOtherProperties] = useState<any>([
    {
      price: 0,
      city: "",
      fulladd: "",
      prop_id: 0,
      area: 0,
      imageURL: "",
      bedrooms: 0,
      bathrooms: 0,
      bathsFull: 0,
      bathsHalf: 0,
      bathsThreeQuarter: " ",
      heating: " ",
      cooling: " ",
      fireplaces: " ",
      flooring: " ",
      interior: " ",
      laundryfeat: " ",
      subTypeText: " ",
      type: " ",
      parking: {
        description: " ",
        spaces: " ",
      },
      state: " ",
      postalCode: " ",
      openHouseKey: null,
      startTime: null,
      endTime: null,
      listingId: null,
    },
  ]);

  const [sharePropModal, setSharePropModal] = useState(false);
  const screens = useBreakpoint();
  const history = useHistory();
  const { userState } = useContext(UserContext);
  const [openHouseStartTime, setOpenHouseStartTime] = useState<any>("");
  const [openHouseEndTime, setOpenHouseEndTime] = useState<any>("");
  const [openHouseId, setOpenHouseId] = useState<string>("");
  const [openHouseKey, setOpenHouseKey] = useState<string>("");
  const [isFindAnAgentModal, setIsFindAnAgentModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [globalStateProp, setGlobalStateProp] =
    useGlobal<any>("checkpropstate");
  const [agentId, setAgentId] = useState<number>(null);
  const [agentName, setAgentName] = useState<string>("");
  const [agentPhone, setAgentPhone] = useState<string>("");
  const [buyerName, setBuyerName] = useState<string>("");
  const [buyerPhone, setBuyerPhone] = useState<string>("");
  const [isListed, setIsListed] = useState<Boolean>(false);
  const [listingAgentId, setListingAgentId] = useState<number>(0);
  const [listingAgentSellerName, setListingAgentSellerName] =
    useState<string>("");
  const [listingAgentSellerPhone, setListingAgentSellerPhone] =
    useState<string>("");
  const [listingAgentName, setListingAgentName] = useState<string>("");
  const [listingAgentEmail, setListingAgentEmail] = useState<string>("");
  const [listingAgentComp, setListingAgentComp] = useState<string>("");
  const [listingAgentImg, setListingAgentImg] = useState<string>("");
  const [listingAgentPhone, setListingAgentPhone] = useState<string>("");
  const [listingAgentPreffCont, setListingAgentPreffCont] =
    useState<string>("");
  const [listingAgentActivatedPlan, setListingAgentActivatedPlan] =
    useState<string>("");
  const [agentMlsId, setAgentMlsId] = useState<string>("");
  const [agentNotListedPhone, setAgentNotListedPhone] = useState<string>("");
  const [listedAgentModal, setListedAgentModal] = useState<boolean>(false);
  const [notListedAgentModal, setNotListedAgentModal] =
    useState<boolean>(false);
  const [isZoomed, setIsZoomed] = useState<boolean>(false);

  const [modeOfContact, setModeOfContact] = useState<string>("");

  const [propertyDetails, setPropertyDetails] = useState<any>([]);
  const [allPropertyDetails, setAllPropertyDetails] = useState<any>(null);

  const [isOpenHouse, setIsOpenHouse] = useState<boolean>(false);

  const [openHousePropDetail, setOpenHousePropDetail] = useState<any>([]);
  const [userName, setUserName] = useState<string>("");

  const [redirectUrl, setRedirectUrl] = useState<string>("");
  const { agentState } = useContext(AgentContext);
  const isSm = useMediaQuery({ maxWidth: 768 });

  const addToFavPayload: any = {
    propertyType: propDetail?.type || "type",
    agentId:
      userState?.userType === UserType?.AGENT
        ? userState?.agent?.agentId
        : userState?.buyer?.buyerAgentId,
    mlsId: Number(propDetail?.listingId) || 0,
    latitude: Number(geoLoc?.lat) || 0,
    longitude: Number(geoLoc.lng) || 0,
    country: address?.country || "United State",
    imageUrl: propDetail?.imageURL || "",
    listPrice: Number(propDetail?.listPrice) || 0,
    streetNumber: Number(address?.streetNumber) || 0,
    streetName: address?.streetName || "",
    city: address?.city || "",
    state: address?.state || "",
    postalCode: address?.postalCode || "",
    bedrooms: Number(propDetail?.bedrooms) || 0,
    bathrooms: Number(propDetail?.bathsFull) || 0,
    area: Number(propDetail?.area) || 0,
    bathsHalf: Number(propDetail?.bathsHalf) || 0,
    opneHouse: param?.get("type") ?? "",
    propertyDetails: allPropertyDetails ? JSON.stringify(allPropertyDetails) : {},
    openHouseStartTime: openHouseStartTime ? openHouseStartTime : new Date(),
    openHouseEndTime: openHouseEndTime ? openHouseEndTime : new Date(),
    openHouseId: openHouseId ? openHouseId : "",
    openHouseKey: openHouseKey ? openHouseKey : "",
    createdAt: new Date(),
    updatedAt: new Date(),
    listingAgentName: listingAgentName?.length > 0 ? listingAgentName : propertyDetails?.agent?.firstName +
      " " +
      propertyDetails?.agent?.lastName,
  }

  const addRequested: any = {
    propertyType: propDetail?.type || "type",
    agentId:
      userState?.userType === UserType?.AGENT
        ? userState?.agent?.agentId
        : userState?.buyer?.buyerAgentId,
    initialStatus: true,
    accept: true,
    mlsId: Number(propDetail?.listingId) || 0,
    latitude: Number(geoLoc?.lat) || 0,
    longitude: Number(geoLoc.lng) || 0,
    country: address?.country || "United State",
    imageUrl: propDetail?.imageURL || "",
    listPrice: Number(propDetail?.listPrice) || 0,
    streetNumber: Number(address?.streetNumber) || 0,
    streetName: address?.streetName || "",
    city: address?.city || "",
    state: address?.state || "",
    postalCode: address?.postalCode || "",
    bedrooms: Number(propDetail?.bedrooms) || 0,
    bathrooms: Number(propDetail?.bathsFull) || 0,
    area: Number(propDetail?.area) || 0,
    bathsHalf: Number(propDetail?.bathsHalf) || 0,
    opneHouse: param?.get("type") ?? "",
    propertyDetails: allPropertyDetails ? JSON.stringify(allPropertyDetails) : "",
    openHouseStartTime: openHouseStartTime ? openHouseStartTime : new Date(),
    openHouseEndTime: openHouseEndTime ? openHouseEndTime : new Date(),
    openHouseId: openHouseId ? openHouseId : "",
    openHouseKey: openHouseKey ? openHouseKey : "",
    createdAt: new Date(),
    updatedAt: new Date(),
    listingAgentName: listingAgentName?.length > 0 ? listingAgentName : propertyDetails?.agent?.firstName +
      " " +
      propertyDetails?.agent?.lastName,
  }

  //ACTIONS

  const getBuyerInfo = async (getBuyerId?: any) => {
    if (userState?.userType === UserType.BUYER) {
      setAgentId(userState?.buyer?.buyerAgentId);
      setAgentName(userState?.buyer?.buyerAgentName);
      setAgentPhone(userState?.buyer?.buyerAgentPhone);
    }
    if (userState?.userType === UserType.AGENT) {
      const result: any = await getBuyerInfoService(
        userState?.agent?.token,
        getBuyerId
      );
      setBuyerName(result?.name);
      setBuyerPhone(result?.phone);
    }
  };

  const schoolInfoData = async (lat: any, lng: any) => {
    try {
      const data = await getSchoolInfoOfProperty(lat, lng);
      setSchoolInfo(data?.schools);
    } catch (err) {
      console.error("school api error", err);
      return;
    }
  };

  const getPropertiesDetailByCity = async (cityName: string, propertyId: number) => {
    try {
      let ResultByCity: any;
      const propCate = param?.get("type");

      if (propCate === "open_house") {
        ResultByCity = await getOpenHousesByCity(cityName, 0);
      } else {
        ResultByCity = await getPropertiesByCity(cityName, 0);
      }

      if (ResultByCity) {
        let arr: any = [];
        // eslint-disable-next-line array-callback-return
        ResultByCity?.map((newlisting: any) => {
          if (
            arr?.length <= 2 &&
            Number(
              propCate === "open_house"
                ? newlisting?.listing?.mlsId
                : newlisting?.mlsId
            ) !== propertyId
          ) {
            arr.push({
              price:
                propCate === "open_house"
                  ? newlisting?.listing?.listPrice
                  : newlisting?.listPrice ?? 0,
              city:
                propCate === "open_house"
                  ? newlisting?.listing?.address?.city
                  : newlisting?.address?.city || "N/A",
              fulladd:
                propCate === "open_house"
                  ? newlisting?.listing?.address?.full
                  : newlisting?.address?.full || "N/A",
              prop_id:
                propCate === "open_house"
                  ? newlisting?.listing?.mlsId
                  : newlisting?.mlsId || 0,
              area:
                propCate === "open_house"
                  ? newlisting?.listing?.property?.area
                  : newlisting?.property?.area ?? 0,
              imageURL:
                propCate === "open_house"
                  ? newlisting?.listing?.photos?.[0]
                  : newlisting?.photos?.[0] ?? "",
              bedrooms:
                propCate === "open_house"
                  ? newlisting?.listing?.property?.bedrooms
                  : newlisting?.property?.bedrooms ?? 0,
              bathrooms:
                propCate === "open_house"
                  ? newlisting?.listing?.property?.bathrooms
                  : newlisting?.property?.bathrooms ?? 0,
              bathsFull:
                propCate === "open_house"
                  ? newlisting?.listing?.property?.bathsFull
                  : newlisting?.property?.bathsFull ?? 0,
              bathsHalf:
                propCate === "open_house"
                  ? newlisting?.listing?.property?.bathsHalf
                  : newlisting?.property?.bathsHalf ?? 0,
              bathsThreeQuarter:
                propCate === "open_house"
                  ? newlisting?.listing?.property?.bathsThreeQuarter
                  : newlisting?.property?.bathsThreeQuarter ?? 0,
              heating:
                propCate === "open_house"
                  ? newlisting?.listing?.property?.heating
                  : newlisting?.property?.heating || "N/A",
              cooling:
                propCate === "open_house"
                  ? newlisting?.listing?.property?.cooling
                  : newlisting?.property?.cooling || "N/A",
              fireplaces:
                propCate === "open_house"
                  ? newlisting?.listing?.property?.fireplaces
                  : newlisting?.property?.fireplaces ?? 0,
              flooring:
                propCate === "open_house"
                  ? newlisting?.listing?.property?.flooring
                  : newlisting?.property?.flooring ?? "N/A",
              interior:
                propCate === "open_house"
                  ? newlisting?.listing?.property?.interiorFeatures
                  : newlisting?.property?.interiorFeatures ?? "N/A",
              laundryfeat:
                propCate === "open_house"
                  ? newlisting?.listing?.property?.laundryFeatures
                  : newlisting?.property?.laundryFeatures ?? "N/A",
              subTypeText:
                propCate === "open_house"
                  ? newlisting?.listing?.subTypeText
                  : newlisting?.subTypeText ?? "N/A",
              type:
                propCate === "open_house"
                  ? newlisting?.listing?.type
                  : newlisting?.type ?? "N/A",
              parking:
                propCate === "open_house"
                  ? newlisting?.listing?.property?.parking
                  : newlisting?.property?.parking ?? "N/A ",
              state:
                propCate === "open_house"
                  ? newlisting?.listing?.address?.state
                  : newlisting?.address?.state ?? "N/A",
              postalCode:
                propCate === "open_house"
                  ? newlisting?.listing?.address?.postalCode
                  : newlisting?.address?.postalCode ?? "N/A",
              openHouseKey:
                propCate === "open_house" ? newlisting?.openHouseKey : null,
              startTime:
                propCate === "open_house" ? newlisting?.startTime : null,
              endTime: propCate === "open_house" ? newlisting?.endTime : null,
              listingId:
                propCate === "open_house"
                  ? newlisting?.listing?.listingId
                  : newlisting?.listingId,
            });
          }
        });
        setOtherProperties(arr);
      }
      return;
    } catch (error) {
      console.error("error", error);
      return;
    }
  };

  const totalViewsCount = async (propertyId: any) => {
    setShowAllSchool(false);
    try {
      const response = await totalViewsCountServices(propertyId);
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (geoLoc?.lat && geoLoc?.lng) {
      schoolInfoData(geoLoc?.lat, geoLoc?.lng);
    }
  }, [geoLoc]);

  useEffect(() => {
    const buyerId = userState.userType === UserType.BUYER ? userState?.buyer?.buyerId : parseInt(param?.get("id"));
    setBuyerId(buyerId);

    userState?.isLoggedIn && getBuyerInfo(buyerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, mlsId]);

  const getPropertyDetails = async () => {
    setIsLoading(true);
    try {
      const open_house = param?.get("type");
      const listingId = param?.get("listingId");
      const openHouseKey = param?.get("openHouseKey");
      let propertyDetails: any = null;
      if (
        listingId?.length > 0 &&
        listingId !== "undefined" &&
        open_house === "open_house"
      ) {
        //this API is used with the help of listingId
        const result: any = await filterOpenHousesProperties(Number(listingId));
        if (result?.data?.length === 0) {
          const result: any = await getPropertyDetailsService(parseInt(mlsId));
          propertyDetails = result;
        }
        // this API is used with the help of OpenHouseKey to get data
        let prop_time: any;
        if (openHouseKey !== null) {
          prop_time = await getPropByOpenHouseKey(Number(openHouseKey));
        }
        if (result?.success) {
          propertyDetails = result?.data?.[0]?.listing;
          setOpenHouseStartTime(
            openHouseKey !== "null"
              ? prop_time?.data?.startTime
              : result?.data[0]?.startTime
          );
          setOpenHouseEndTime(
            openHouseKey !== "null"
              ? prop_time?.data?.endTime
              : result?.data[0]?.endTime
          );
          setOpenHouseId(prop_time?.data?.openHouseId);
          setOpenHouseKey(prop_time?.data?.openHouseKey);
          setIsOpenHouse(true);
          setOpenHousePropDetail(result);
        }
      } else {
        const result: any = await getPropertyDetailsService(parseInt(mlsId));
        propertyDetails = result;
      }

      if (Object.keys(propertyDetails)?.length <= 0) {
        setIsShowNoPopertyConfirmModal(true)
        setIsLoading(false);
        return;
      }

      getPropertiesDetailByCity(propertyDetails?.address?.city, propertyDetails?.mlsId);
      const images: any = propertyDetails?.photos?.map((item: any) => {
        return {
          original: item,
          thumbnail: item,
          thumbnailHeight: 100,
          thumbnailWidth: 200,
        };
      });
      setGalleryImage(images?.length > 0 ? images : []);
      setImageSrc(propertyDetails?.photos?.[0] ?? "");
      setRemarks(propertyDetails?.remarks ?? "");
      setListPrice(`${propertyDetails?.listPrice ?? ""}`);
      setSqrtPrice(propertyDetails?.listPrice);

      setPropDetail({
        imageURL: propertyDetails?.photos?.[0] ?? propertyDetails?.photos?.[1],
        bedrooms: propertyDetails?.property?.bedrooms || 0,
        bathrooms: propertyDetails?.property?.bathrooms || 0,
        bathsFull: propertyDetails?.property?.bathsFull || 0,
        bathsHalf: propertyDetails?.property?.bathsHalf || 0,
        bathsThreeQuarter: propertyDetails?.property?.bathsThreeQuarter || 0,
        heating: propertyDetails?.property?.heating || "N/A",
        cooling: propertyDetails?.property?.cooling || "N/A",
        fireplaces: propertyDetails?.property?.fireplaces || 0,
        flooring: propertyDetails?.property?.flooring || "N/A",
        interior: propertyDetails?.property?.interiorFeatures || "N/A",
        laundryfeat: propertyDetails?.property?.laundryFeatures || "N/A",
        subTypeText: propertyDetails?.property?.subTypeText || "N/A",
        type: propertyDetails?.property?.type || "N/A",
        parking: propertyDetails?.property?.parking || 0,
        mls: propertyDetails?.mls || "N/A",
        monthly_payment: "N/A",
        brokerage: "N/A",
        virtual_tour: propertyDetails?.virtualTourUrl || 0,
        foundation: propertyDetails?.property?.foundation || "N/A",
        roof: propertyDetails?.property?.roof || "N/A",
        directions: propertyDetails?.geo?.directions || "N/A",
        agent: propertyDetails?.agent || "N/A",
        office: propertyDetails?.office || "",
        listingId: propertyDetails?.listingId || "N/A",
        mlsId: propertyDetails?.mlsId || "N/A",
        lotSizeArea: propertyDetails?.property?.lotSizeArea || "N/A",
        lotSizeAreaUnits: propertyDetails?.property?.lotSizeAreaUnits || "N/A",
        area: propertyDetails?.property?.area || "N/A",
        listPrice: propertyDetails?.listPrice || 0,
      });

      setTaxDet({
        id: propertyDetails?.tax?.id || "N/A",
        taxAnnualAmount: Number(propertyDetails?.tax?.taxAnnualAmount) || 0,
        taxYear: propertyDetails?.tax?.taxYear || "N/A",
        land: propertyDetails?.tax?.land || "N/A",
        additions: propertyDetails?.tax?.additions || "N/A",
        assessedValue: propertyDetails?.tax?.assessedValue || "N/A",
      });
      setGeoLoc({
        lat: propertyDetails?.geo?.lat ?? null,
        lng: propertyDetails?.geo?.lng ?? null,
        direction:
          propertyDetails?.geo?.directions + " " + propertyDetails?.geo?.county,
      });
      setAddress({
        fullAddress: propertyDetails?.address?.full || "N/A",
        city: propertyDetails?.address?.city || "N/A",
        country: propertyDetails?.address?.country || "N/A",
        streetNumber: propertyDetails?.address?.streetNumberText || "N/A",
        streetName: propertyDetails?.address?.streetName || "N/A",
        state: propertyDetails?.address?.state || "N/A",
        postalCode: propertyDetails?.address?.postalCode || "N/A",
      });
      setYearBuilt(propertyDetails?.property?.yearBuilt || "N/A"); //TODO change
      setArea(propertyDetails?.property?.area || "N/A"); //TODO change
      setArea1(propertyDetails?.property?.lotSizeArea || "N/A"); //TODO change
      setAgentMlsId(propertyDetails?.agent?.id);
      setAgentNotListedPhone(propertyDetails?.agent?.contact?.office);
      setPropertyDetails(propertyDetails);
      setAllPropertyDetails(propertyDetails);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error: ", error);
    }
  };

  const checkIfRequested = async (getMlsId: any, getBuyerId: any) => {
    if (!userState.isLoggedIn) {
      return;
    }

    var mayBeRequested = false;

    switch (userState.userType) {
      case UserType.BUYER:
        mayBeRequested = await checkIfPropertyIsRequestedForBuyerService(
          parseInt(getMlsId),
          userState.buyer.buyerId,
          userState.buyer.token
        );
        break;
      case UserType.AGENT:
        mayBeRequested = await checkIfPropertyIsRequestedForBuyerService(
          parseInt(getMlsId),
          parseInt(getBuyerId),
          userState.agent.token
        );
        break;
      default:
        break;
    }

    setCheckIsRequested(mayBeRequested);
  };

  const checkIfFavorite = async (getMlsId: any, getBuyerId: any) => {
    if (!userState.isLoggedIn) {
      return;
    }

    var mayBeFavorite = false;

    switch (userState.userType) {
      case UserType.BUYER:
        mayBeFavorite = await checkIfPropertyIsFavoriteForBuyerService(
          parseInt(getMlsId),
          userState.buyer.buyerId,
          userState.buyer.token
        );
        break;
      case UserType.AGENT:
        mayBeFavorite = await checkIfPropertyIsFavoriteForBuyerService(
          parseInt(getMlsId),
          parseInt(getBuyerId),
          userState.agent.token
        );
        break;
      default:
        break;
    }

    setCheckIsFavorite(mayBeFavorite);
  };

  const confirmModalHandler = () => {
    setIsShowFindAnAgentConfirmModal(!isShowFindAnAgentConfirmModal);
  };

  const confirmNoPropertyModalHandler = () => {
    setIsShowNoPopertyConfirmModal(false);
    backHandler();
  };
  const confirmYesPropertyModalHandler = () => {
    setIsShowNoPopertyConfirmModal(false);
    backHandler();
  };

  const toggleRequested = async () => {
    if (!userState.isLoggedIn) {
      return notification("Please Login!", "error");
    }
    if (
      !userState?.buyer?.isLinked &&
      userState?.userType === UserType?.BUYER
    ) {
      setIsShowFindAnAgentConfirmModal(!isShowFindAnAgentConfirmModal);
      return;
    }

    const previousRequestedStatus = checkIsRequested;
    setCheckIsRequested(!checkIsRequested);

    let token = "",
      getBuyerId = -1,
      agentPhone: string = "",
      messageType: string = "No",
      preferredContact: string = "";

    setIsLoading(true);

    try {
      switch (userState.userType) {
        case UserType.BUYER:
          getBuyerId = userState?.buyer?.buyerId;
          token = userState?.buyer?.token;
          agentPhone = userState?.buyer?.buyerAgentPhone;
          preferredContact = userState?.buyer?.buyerAgentPreferredContact;
          const agentAccountSettingsResult: any = await getAgentAccountSettings(
            userState?.buyer?.buyerAgentId
          );
          if (agentAccountSettingsResult?.length > 0) {
            const lastIndex: number = agentAccountSettingsResult?.length - 1;
            messageType =
              agentAccountSettingsResult?.[lastIndex]?.notifyRequestedShowings;
          }
          addRequested["messageType"] =
            preferredContact === "CONTACT_RADIO_BOTH" ||
              preferredContact === "CONTACT_RADIO_EMAIL"
              ? messageType
              : "not real time";
          addRequested["agentName"] = userState?.buyer?.buyerAgentName;
          addRequested["agentEmail"] = userState?.buyer?.buyerAgentEmail;
          addRequested["buyerName"] = userState?.buyer?.name;
          addRequested["buyerEmail"] = 'Agent';
          break
        case UserType.AGENT:
          getBuyerId = agentState?.buyerId;
          token = userState?.agent?.token;
          agentPhone = userState?.agent?.phone;
          preferredContact = agentState?.buyerPreferredContact;
          const buyerAccountSettingsResult: any = await getBuyerAccountSettings(
            userState?.agent?.token,
            agentState.buyerId
          );
          if (buyerAccountSettingsResult?.length > 0) {
            const lastIndex: number = buyerAccountSettingsResult?.length - 1;
            messageType =
              buyerAccountSettingsResult?.[lastIndex]?.notifyRequestedShowing;
          }
          addRequested["messageType"] = preferredContact === "CONTACT_RADIO_BOTH" || preferredContact === "CONTACT_RADIO_EMAIL" ? messageType : "not real time";
          addRequested["agentName"] = userState?.agent?.name;
          addRequested["agentEmail"] = 'Buyer';
          addRequested["buyerName"] = agentState?.buyerName;
          addRequested["buyerEmail"] = agentState?.buyerEmail;
          break
        default:
          break;
      }
      if (previousRequestedStatus) {
        await removePropertyFromRequestedForBuyerService(
          parseInt(mlsId),
          getBuyerId,
          token
        );
        setIsLoading(false);
        return;
      }
      await addPropertyToRequestedForBuyerService(
        parseInt(mlsId),
        getBuyerId,
        token,
        addRequested
      );

      if (agentId || userState?.buyer?.buyerAgentId) {
        const linkedAgentId = agentId
          ? agentId
          : userState?.buyer?.buyerAgentId;
        if (
          messageType === "Realtime" &&
          (preferredContact === "CONTACT_RADIO_BOTH" ||
            preferredContact === "CONTACT_RADIO_PHONE")
        ) {
          postMessageToUser(
            agentPhone,
            `Your buyer ${userState?.buyer?.name?.toUpperCase()} added ${Number(address?.streetNumber) ?? ""} ${address?.streetName ?? ""} ${address?.city ?? ""}, ${address?.state ?? ""} ${address?.postalCode ?? ""} | #${propDetail?.listingId} valued at  ${Number(propDetail?.listPrice)?.toLocaleString(
              'en-US',
              {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 3,
              }
            )} to requested showing property list.`
          );
        }

        await inviteAgentByBuyer(
          linkedAgentId,
          'requested',
          `Your buyer ${userState?.buyer?.name?.toUpperCase()} added ${Number(address?.streetNumber) ?? ""} ${address?.streetName ?? ""} ${address?.city ?? ""}, ${address?.state ?? ""} ${address?.postalCode ?? ""} | #${propDetail?.listingId} valued at  ${Number(propDetail?.listPrice)?.toLocaleString(
            'en-US',
            {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 3,
            }
          )} to requested showing property list`,
          Number(propertyId ?? mlsId),
          "",
          "",
          userState?.buyer?.buyerId || buyerId
        );
      }

      if (userState.userType === UserType.AGENT) {
        await createBuyerNotificationById(
          agentState?.buyerId,
          `Your Agent ${userState?.agent?.name.toUpperCase()} added ${Number(address?.streetNumber) ?? ""} ${address?.streetName ?? ""} ${address?.city ?? ""}, ${address?.state ?? ""} ${address?.postalCode ?? ""} | #${propDetail?.listingId} valued at ${Number(propDetail?.listPrice)?.toLocaleString(
            'en-US',
            {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 3,
            }
          )} to your requested showing property list.`,
          'Comment',
          userState?.agent?.token,
          "buyer",
          Number(propertyId ?? mlsId),
          "No",
          '',
          '',
          '',
        );

        if (
          messageType === "Yes" &&
          (preferredContact === "CONTACT_RADIO_BOTH" ||
            preferredContact === "CONTACT_RADIO_PHONE")
        ) {
          postMessageToUser(
            agentState.buyerPhone,
            `Your Agent ${userState?.agent?.name.toUpperCase()} added ${Number(address?.streetNumber) ?? ""} ${address?.streetName ?? ""} ${address?.city ?? ""}, ${address?.state ?? ""} ${address?.postalCode ?? ""} | #${propDetail?.listingId} valued at ${Number(propDetail?.listPrice)?.toLocaleString(
              'en-US',
              {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 3,
              }
            )} to your requested showing property list.`
          );
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const toggleFavorite = async () => {
    if (!userState.isLoggedIn) {
      return notification("Please Login!", "error");
    }
    const previousFavoriteStatus = checkIsFavorite;
    setCheckIsFavorite(!checkIsFavorite);

    let token = "",
      getBuyerId = -1,
      agentPhone: string = "",
      messageType: string = "No",
      preferredContact: string = "No";

    switch (userState.userType) {
      case UserType.BUYER:
        getBuyerId = userState.buyer.buyerId;
        token = userState.buyer.token;
        agentPhone = userState?.buyer?.buyerAgentPhone;
        preferredContact = userState?.buyer?.buyerAgentPreferredContact;
        const agentAccountSettingsResult: any = await getAgentAccountSettings(
          userState?.buyer?.buyerAgentId
        );
        if (agentAccountSettingsResult?.length > 0) {
          const lastIndex: number = agentAccountSettingsResult?.length - 1;
          messageType =
            agentAccountSettingsResult?.[lastIndex]?.notifyFavoritesProperties;
        }
        addToFavPayload["messageType"] =
          preferredContact === "CONTACT_RADIO_BOTH" ||
            preferredContact === "CONTACT_RADIO_EMAIL"
            ? messageType
            : "not real time";
        addToFavPayload["agentName"] = userState?.buyer?.buyerAgentName;
        addToFavPayload["agentEmail"] = userState?.buyer?.buyerAgentEmail;
        addToFavPayload["buyerName"] = userState?.buyer?.name;
        addToFavPayload["buyerEmail"] = 'Agent';

        break;
      case UserType.AGENT:
        getBuyerId = buyerId ?? null;
        token = userState.agent.token;
        agentPhone = userState?.agent?.phone;
        preferredContact = agentState?.buyerPreferredContact;
        const buyerAccountSettingsResult: any = await getBuyerAccountSettings(
          userState?.agent?.token,
          agentState.buyerId
        );
        if (buyerAccountSettingsResult?.length > 0) {
          const lastIndex: number = buyerAccountSettingsResult?.length - 1;
          messageType =
            buyerAccountSettingsResult?.[lastIndex]?.notifyFavoriteProp;
        }
        addToFavPayload["messageType"] = preferredContact === "CONTACT_RADIO_BOTH" || preferredContact === "CONTACT_RADIO_EMAIL" ? messageType : "No";
        addToFavPayload["agentName"] = userState?.agent?.name;
        addToFavPayload["agentEmail"] = 'Buyer';
        addToFavPayload["buyerName"] = agentState?.buyerName;
        addToFavPayload["buyerEmail"] = agentState?.buyerEmail;
        break;
      default:
        break;
    }
    setIsLoading(true);
    try {
      if (previousFavoriteStatus) {
        await removePropertyFromFavoritesForBuyerService(
          parseInt(mlsId),
          getBuyerId,
          token
        );
        setIsLoading(false);
        return;
      }
      await addPropertyToFavoritesForBuyerService(
        parseInt(mlsId),
        getBuyerId,
        token,
        addToFavPayload
      );

      if (userState?.buyer?.buyerAgentId && userState?.buyer?.isLinked) {
        const linkedAgentId = agentId
          ? agentId
          : userState?.buyer?.buyerAgentId;
        // to agent account setting
        if (
          messageType === "Realtime" &&
          (preferredContact === "CONTACT_RADIO_BOTH" ||
            preferredContact === "CONTACT_RADIO_PHONE")
        ) {
          postMessageToUser(
            agentPhone,
            `Your buyer ${userState?.buyer?.name
            } added ${Number(address?.streetNumber) ?? ""} ${address?.streetName ?? ""} ${address?.city ?? ""}, ${address?.state ?? ""} ${address?.postalCode ?? ""} | #${propDetail?.listingId} valued at ${Number(propDetail?.listPrice)?.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 3,
            })} to their favorites list.`
          );
        }
        await inviteAgentByBuyer(
          linkedAgentId,
          'liked',
          `Your buyer ${userState?.buyer?.name
          } added ${Number(address?.streetNumber) ?? ""} ${address?.streetName ?? ""} ${address?.city ?? ""}, ${address?.state ?? ""} ${address?.postalCode ?? ""} | #${propDetail?.listingId} valued at ${Number(propDetail?.listPrice)?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 3,
          })} to their favorites list`,
          Number(propertyId ?? mlsId),
          "",
          "",
          userState?.buyer?.buyerId || buyerId
        );
      }

      if (userState.userType === UserType.AGENT) {
        await createBuyerNotificationById(
          buyerId,
          `Your Agent ${userState?.agent?.name?.toUpperCase()} added ${Number(address?.streetNumber) ?? ""} ${address?.streetName ?? ""} ${address?.city ?? ""}, ${address?.state ?? ""} ${address?.postalCode ?? ""} | #${propDetail?.listingId} valued at ${Number(propDetail?.listPrice)?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 3,
          })} to your favorities list.`,
          'Comment',
          userState?.agent?.token,
          "buyer",
          Number(propertyId ?? mlsId),
          preferredContact === "CONTACT_RADIO_BOTH" ||
            preferredContact === "CONTACT_RADIO_EMAIL"
            ? messageType
            : "No",
          userState?.agent?.name,
          agentState.buyerName,
          agentState.buyerEmail
        );
        if (
          messageType === "Yes" &&
          (preferredContact === "CONTACT_RADIO_BOTH" ||
            preferredContact === "CONTACT_RADIO_PHONE")
        ) {
          postMessageToUser(
            agentState.buyerPhone,
            `Your Agent ${userState?.agent?.name?.toUpperCase()} added ${Number(address?.streetNumber) ?? ""} ${address?.streetName ?? ""} ${address?.city ?? ""}, ${address?.state ?? ""} ${address?.postalCode ?? ""} | #${propDetail?.listingId} valued at ${Number(propDetail?.listPrice)?.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 3,
            })} to your favorities list.`
          );
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const checkIfListed = async (listingId: number) => {
    try {
      const response: any = await checkIsListedPropertyService(listingId);

      if (response?.success) {
        const listingAgentActivatedPlans: any = await getActivatedSubscriptionPlan(
          response?.data?.agentId,
          userState?.agent?.token
        );
        if (listingAgentActivatedPlans) {
          const activatedPlan: string =
            listingAgentActivatedPlans?.subscription_type
              ? listingAgentActivatedPlans?.subscription_type
              : "Navihome Free Plan";
          setListingAgentActivatedPlan(activatedPlan);
        }
        setIsListed(response?.success);
        setListingAgentId(response?.data?.agentId ?? 0);
        setListingAgentSellerName(response?.data?.name ?? "");
        setListingAgentName(response?.data?.agentName ?? "");
        setListingAgentSellerPhone(response?.data?.sellerPhone);
        setListingAgentEmail(response?.data?.agentEmail);
        setListingAgentComp(response?.data?.agent?.company);
        setListingAgentImg(response?.data?.agent?.avatarUrl);
        setListingAgentPhone(response?.data?.agent?.phone);
        setListingAgentPreffCont(response?.data?.agent?.preferredContact);
      }
      console.error("Error:", response);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const checkListedAgent = async () => {
    let token = "";
    if (userState?.userType === UserType.AGENT) {
      token = userState?.agent?.token;
    } else {
      token = userState?.buyer?.token;
    }
    try {
      const responseData: any = await getAgentInfoByMlsId(token, agentMlsId);
      if (responseData?.email) {
        setModeOfContact(responseData?.preferredContact);
        setUserName(responseData?.name);
      } else {
        setUserName(
          `${propDetail?.agent?.firstName} ${propDetail?.agent?.lastName}`
        );
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  useEffect(() => {
    const query = history?.location?.pathname;
    const upComingBuyerId: any = param.get("id");

    checkIfFavorite(
      query.split("/")?.[2] ?? null,
      Number(upComingBuyerId) ?? null
    );
    checkIfRequested(
      query.split("/")?.[2] ?? null,
      Number(upComingBuyerId) ?? null
    );

    if (mlsId) checkIfListed(Number(mlsId) ?? 0);
    totalViewsCount(query.split("/")?.[2] ?? 0);
    getPropertyDetails();
    window.scrollTo({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mlsId]);

  useEffect(() => {
    if (userState?.buyer?.token) {
      getBuyerInfo(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (agentMlsId && userState?.isLoggedIn) {
      checkListedAgent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentMlsId]);

  const findAnAgentModalHandler = () => {
    history.push(
      `/agentBuyerPropertyCalendar/${mlsId}/?query=${buyerId}&listingId=${param?.get(
        "listingId"
      )}&listingAgentId=${listingAgentId}&client=${listingAgentSellerName}&listingAgent=${listingAgentName}&fav=${checkIsFavorite}&req=${checkIsRequested}&clientPh=${listingAgentSellerPhone}&LAPhone=${listingAgentPhone}&LAPreffCont=${listingAgentPreffCont}&LAEmail=${listingAgentEmail}`
    );

    //   if buyer is not logged in
    if (!userState.isLoggedIn) {
      notification(
        "You need to login before you can perform this action.",
        "error"
      );
      return;
    }
    if (userState.isLoggedIn && userState.userType === UserType.AGENT) {
      return;
    }
    // if buyer is login but not linked with any agent
    if (
      userState?.isLoggedIn &&
      !userState?.buyer?.isLinked &&
      userState?.userType === UserType?.BUYER
    ) {
      setIsFindAnAgentModal(!isFindAnAgentModal);
      return;
    }
  };
  //RENDER

  const renderPropertyDetailItem = (name: string, items: string[]) => {
    return (
      <div className="property-details-page__property-details-item">
        <h5>{name}</h5>
        <ul style={{ paddingLeft: "0%" }}>
          {items?.length > 0 &&
            items?.map((item) => {
              return <li>{item}</li>;
            })}
        </ul>
      </div>
    );
  };

  const renderTaxTimeline = () => {
    const columns = [
      {
        title: "Year",
        dataIndex: "year",
        key: "year",
      },
      {
        title: "Property Tax",
        dataIndex: "property_tax",
        key: "property_tax",
      },
      {
        title: "Land",
        dataIndex: "land",
        key: "land",
      },
      {
        title: "Additions",
        dataIndex: "additions",
        key: "additions",
      },
      {
        title: "Assessed value",
        dataIndex: "assessed_value",
        key: "assessed_value",
      },
    ];

    const data = [
      {
        key: `${taxDet.id}`,
        year: `${taxDet.taxYear}`,
        property_tax: `$${taxDet.taxAnnualAmount?.toLocaleString()}`,
        land: `${taxDet.land}`,
        additions: `${taxDet.additions}`,
        assessed_value: `${taxDet.assessedValue}`,
      },
    ];
    return (
      <div className="property-details-page__sales-and-tax-history__timeline">
        <Table columns={columns} dataSource={data} pagination={false} />
      </div>
    );
  };

  const renderSchools = () => {
    return (
      <div className="property-details-page__school-information__school-info">
        <div className="property-details-page__school-information__header">
          <p className="property-details-page__school-information__rating">
            GreatSchools Rating
          </p>
          <p className="property-details-page__school-information__school">
            School
          </p>
        </div>
        {schoolInfo?.length > 0 &&
          schoolInfo
            ?.slice(0, showAllSchool ? schoolInfo?.length : 3)
            ?.map((item) => {
              return (
                <SchoolInformationSummary
                  rating={item.rating || 0}
                  schoolName={item.name}
                  schoolType={item.level}
                  schoolUrl={item[`overview-url`]}
                  type={item.type}
                  distance={item.distance.toFixed(2)}
                />
              );
            })}
      </div>
    );
  };
  const handleViewProperty = async (
    prop_id: any,
    openHouseKey: number,
    listingId: number
  ) => {
    let otherPropBuyerId = -1;
    const isViewedProp: boolean = param.get("isViewed") === "false" ? false : true;
    if (userState?.userType === UserType.BUYER) {
      otherPropBuyerId = userState?.buyer?.buyerId;
    } else {
      otherPropBuyerId = Number(param.get("id"));
    }

    const queryUrl: any = parse(history?.location?.search);

    let url: any = "";
    if (queryUrl?.type) {
      url = !userState?.isLoggedIn
        ? `/properties/${prop_id}?type=${queryUrl?.type}&listingId=${listingId}&openHouseKey=${openHouseKey}&isViewed=${isViewedProp}`
        : `/properties/${prop_id}?type=${queryUrl?.type}&id=${otherPropBuyerId}&listingId=${listingId}&openHouseKey=${openHouseKey}&isViewed=${isViewedProp}`;
    } else {
      url = !userState?.isLoggedIn
        ? `/properties/${prop_id}&listingId=${listingId}&openHouseKey=${openHouseKey}&isViewed=${isViewedProp}`
        : `/properties/${prop_id}?id=${otherPropBuyerId}&listingId=${listingId}&openHouseKey=${openHouseKey}&isViewed=${isViewedProp}`;
    }
    setRedirectUrl(url);
  };

  const handleRightClick = (
    prop_id: any,
    openHouseKey?: number,
    listingId?: number
  ) => {
    let url = "";
    if (openHouseKey || openHouseKey !== null) {
      url = `/properties/${prop_id}?type=open_house&listingId=${listingId}&openHouseKey=${openHouseKey}`;
    } else {
      url = `/properties/${prop_id}&listingId=${listingId}`;
    }
    setRedirectUrl(url);
  };

  const renderOtherProperties = (item: any) => {
    return (
      <a
        href={redirectUrl}
        onClick={() => {
          handleViewProperty(
            item?.prop_id,
            item?.openHouseKey,
            item?.listingId
          );
        }}
        onContextMenu={() => {
          handleRightClick(item?.prop_id, item?.openHouseKey, item?.listingId);
        }}>
        <CustomCard
          screen={screens}
          onClick={() => {
            window.scrollTo({ top: 0 });
            handleViewProperty(
              item?.prop_id,
              item?.openHouseKey,
              item?.listingId
            );
          }}>
          {item?.openHouseKey !== null ? (
            <OpenHouseOtherPropDiv>
              <p>OPEN HOUSE</p>
            </OpenHouseOtherPropDiv>
          ) : (
            <></>
          )}
          {item?.startTime !== null && item?.endTime !== null ? (
            <StartEndOtherPropDiv>
              <p>
                {moment(item?.startTime).tz("America/New_York").format("ddd")},{" "}
                {moment(item?.startTime)
                  .tz("America/New_York")
                  .format("hh:mm a")}
                -
                {moment(item?.endTime).tz("America/New_York").format("hh:mm a")}
              </p>
            </StartEndOtherPropDiv>
          ) : (
            <></>
          )}
          <Row>
            <img
              width="100%"
              height="220px"
              src={item?.imageURL ?? ""}
              alt="Discord"
            />
          </Row>

          <Row justify="space-between">
            <Col>
              <b>
                {item?.price?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 3,
                })}
              </b>
            </Col>
            <Col></Col>
          </Row>
          <Row></Row>
          <Row justify="space-between">
            <Col>{item.bedrooms} beds</Col>
            <Col>{item.bathrooms + item.bathsFull} bath</Col>
            <Col>
              {item.area?.toLocaleString("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 3,
              })}{" "}
              sqft
            </Col>
          </Row>
          <Row>{item?.fulladd}</Row>
          <Row>
            {item?.city}, {item?.state} {item?.postalCode}
          </Row>
          <CustomDivider />
        </CustomCard>
      </a>
    );
  };

  const showSalesTab = () => {
    setIsShowingSalesTab(true);
  };

  const showTaxTab = () => {
    setIsShowingSalesTab(false);
  };
  const backHandler = () => {
    if (globalStateProp === "checkProp") {
      setGlobalState("prop");
    } else if (globalStateProp === "form") {
      setGlobalState("form");
    }
    const type = param.get("type") ?? "";
    const filterType = param.get("filter") ?? "";
    const buyerId = param.get("id") ?? "";
    const buyerDashboard = param.get("buyerDashboard");

    if (filterType === "yes") {
      history.push(
        `/properties?type=${type}&buyerId=${buyerId}&filter=${filterType}`
      );
    } else if (buyerDashboard?.length > 0) {
      if (userState.userType === UserType.BUYER) {
        history.push(`/buy?buyerDashboard=${buyerDashboard}`);
      } else {
        history.push(
          `/dashboard?buyerId=${param.get(
            "id"
          )}&buyerDashboard=${buyerDashboard}`
        );
      }
    } else {
      history.goBack();
    }
  };
  const PricePerSqrt = (sqrtPrice: any, area: any) => {
    if (!sqrtPrice || !area) {
      return "N/A";
    } else {
      let ans = 0;

      ans += sqrtPrice / area;
      ans = parseInt(ans?.toString());
      return ans?.toLocaleString("en-US", {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      });
    }
  };

  const handleSharePropModal = () => {
    setSharePropModal(!sharePropModal);
  };

  const data = propDetail?.lotSizeArea;
  const handleChange = (value: any) => {
    if (value === "Acres") {
      setArea1(data);
    }
    if (value === "sqft") {
      if (data !== "N/A") {
        const c = Number(data) * 43560;
        const b = parseFloat(c?.toString())?.toFixed(0);
        setArea1(b);
      }
    }
  };
  useEffect(() => {
    const google_map_val = document.getElementById("custom_map_div");
    if (google_map_val) {
      google_map_val.addEventListener("click", () => {
        setIsZoomed(!isZoomed);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.getElementById("custom_map_div")]);

  const handleModal = () => {
    if (isListed) {
      setListedAgentModal(!listedAgentModal);
    } else {
      setNotListedAgentModal(!notListedAgentModal);
    }
  };

  // print other properties
  return (
    <div className="property-details-page">
      <div className="property-details-page-header">
        {screens.xl ? (
          <div className="property-details-page-comment_section">
            <div>
              {isLoading && <CustomSpinner />}
              <CustomBackPara onClick={backHandler}>Back</CustomBackPara>
              {userState?.isLoggedIn &&
                userState?.userType === UserType?.BUYER &&
                userState?.buyer?.isLinked ? (
                <PropertyCommentSection
                  propertyId={parseInt(mlsId) ?? 0}
                  agentId={agentId ?? 0}
                  agentName={agentName ?? "N/A"}
                  agentPhone={agentPhone ?? "N/A"}
                  propertyType={propDetail?.type ?? "N/A"}
                  mlsId={propDetail?.listingId ?? "N/A"}
                  latitude={geoLoc?.lat ?? 0}
                  longitude={geoLoc?.lng ?? 0}
                  country={address?.country ?? "N/A"}
                  imageUrl={propDetail?.imageURL ?? "N/A"}
                  listPrice={propDetail?.listPrice ?? 0}
                  streetNumber={Number(address?.streetNumber) ?? 0}
                  streetName={address?.streetName ?? "N/A"}
                  city={address?.city ?? "N/A"}
                  state={address?.state ?? "N/A"}
                  postalCode={address?.postalCode ?? "N/A"}
                  bedrooms={propDetail?.bedrooms ?? 0}
                  bathrooms={propDetail?.bathsFull ?? 0}
                  area={Number(propDetail?.area) ?? 0}
                  bathsHalf={propDetail?.bathsHalf ?? 0}
                  opneHouse={param?.get("type") ?? propertyType ?? "type"}
                  setOpenHouseOnCommentProp={setOpenHouseOnCommentProp}
                  propertyDetails={propertyDetails}
                  openHouseStartTime={openHouseStartTime}
                  openHouseEndTime={openHouseEndTime}
                  openHouseId={openHouseId}
                  openHouseKey={openHouseKey}
                />
              ) : (
                <></>
              )}
            </div>
            <div>
              {userState?.isLoggedIn &&
                userState?.userType === UserType?.AGENT &&
                buyerId ? (
                <PropertyCommentSection
                  propertyId={parseInt(mlsId) ?? 0}
                  agentId={agentId ?? 0}
                  buyerId={buyerId}
                  buyerName={buyerName}
                  agentName={agentName ?? "N/A"}
                  buyerPhone={buyerPhone ?? "N/A"}
                  agentPhone={agentPhone ?? "N/A"}
                  propertyType={propDetail?.type ?? "N/A"}
                  mlsId={propDetail?.listingId ?? "N/A"}
                  latitude={geoLoc?.lat ?? 0}
                  longitude={geoLoc?.lng ?? 0}
                  country={address?.country ?? "N/A"}
                  imageUrl={propDetail?.imageURL ?? "N/A"}
                  listPrice={propDetail?.listPrice ?? 0}
                  streetNumber={Number(address?.streetNumber) ?? 0}
                  streetName={address?.streetName ?? "N/A"}
                  city={address?.city ?? "N/A"}
                  state={address?.state ?? "N/A"}
                  postalCode={address?.postalCode ?? "N/A"}
                  bedrooms={propDetail?.bedrooms ?? 0}
                  bathrooms={propDetail?.bathsFull ?? 0}
                  area={Number(propDetail?.area) ?? 0}
                  bathsHalf={propDetail?.bathsHalf ?? 0}
                  opneHouse={param?.get("type") ?? propertyType ?? "type"}
                  setOpenHouseOnCommentProp={setOpenHouseOnCommentProp}
                  propertyDetails={propertyDetails}
                  openHouseStartTime={openHouseStartTime}
                  openHouseEndTime={openHouseEndTime}
                  openHouseId={openHouseId}
                  openHouseKey={openHouseKey}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className=" property-details-page-image_section">
          <ImageGalleryDiv>
            <ImageGallery
              showThumbnails={true}
              slideOnThumbnailOver={true}
              startIndex={0}
              showPlayButton={true}
              showIndex={true}
              items={galleryImage}
            />
          </ImageGalleryDiv>

          {param?.get("type")?.toString() === "open_house" ||
            openHouseOnCommentProp === "open_house" ||
            isOpenHouse ? (
            <div>
              <div>
                <CustomOpenHouseDiv>
                  <p>OPEN HOUSE</p>
                </CustomOpenHouseDiv>
              </div>
              {/* {moment(openHouseStartTime)
                .tz("America/New_York")
                .format("ddd") === "Invalid date" ||
                moment(openHouseStartTime)
                  .tz("America/New_York")
                  .format("hh:mm a") === "Invalid date" ||
                moment(openHouseEndTime)
                  .tz("America/New_York")
                  .format("hh:mm a") === "Invalid date" ? (
                <></>
              ) : (
                <div
                  style={{
                    background: "#eb752f",
                    borderRadius: "14px",
                    padding: "4px 8px",
                    position: "absolute",
                    top: isMobile ? "52px" : "8px",
                    left: isMobile ? "6px" : "144px",
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                  }}>
                  <p style={{ color: "white" }}>
                    {moment(openHouseStartTime)
                      .tz("America/New_York")
                      .format("h:mm a")
                      .split(" ")[1] ===
                      moment(openHouseEndTime)
                        .tz("America/New_York")
                        .format("h:mm a")
                        .split(" ")[1]
                      ? moment(openHouseStartTime)
                        .tz("America/New_York")
                        .minutes() === Number("00")
                        ? moment(openHouseEndTime)
                          .tz("America/New_York")
                          .minutes() === Number("00")
                          ? moment(openHouseStartTime)
                            .tz("America/New_York")
                            .format("ddd") +
                          ", " +
                          moment(openHouseStartTime)
                            .tz("America/New_York")
                            .format("h") +
                          " - " +
                          moment(openHouseEndTime)
                            .tz("America/New_York")
                            .format("h a")
                          : moment(openHouseStartTime)
                            .tz("America/New_York")
                            .format("ddd") +
                          ", " +
                          moment(openHouseStartTime)
                            .tz("America/New_York")
                            .format("h") +
                          " - " +
                          moment(openHouseEndTime)
                            .tz("America/New_York")
                            .format("h:mm a")
                        : moment(openHouseEndTime)
                          .tz("America/New_York")
                          .minute() === Number("00")
                          ? moment(openHouseStartTime)
                            .tz("America/New_York")
                            .format("ddd") +
                          ", " +
                          moment(openHouseStartTime)
                            .tz("America/New_York")
                            .format("h:mm ") +
                          " - " +
                          moment(openHouseEndTime)
                            .tz("America/New_York")
                            .format("h a")
                          : moment(openHouseStartTime)
                            .tz("America/New_York")
                            .format("ddd") +
                          ", " +
                          moment(openHouseStartTime)
                            .tz("America/New_York")
                            .format("h:mm ") +
                          " - " +
                          moment(openHouseEndTime)
                            .tz("America/New_York")
                            .format("h:mm a")
                      : moment(openHouseStartTime)
                        .tz("America/New_York")
                        .minute() === Number("00")
                        ? moment(openHouseEndTime)
                          .tz("America/New_York")
                          .minute() === Number("00")
                          ? moment(openHouseStartTime)
                            .tz("America/New_York")
                            .format("ddd") +
                          ", " +
                          moment(openHouseStartTime)
                            .tz("America/New_York")
                            .format("h a") +
                          " - " +
                          moment(openHouseEndTime)
                            .tz("America/New_York")
                            .format("h a")
                          : moment(openHouseStartTime)
                            .tz("America/New_York")
                            .format("ddd") +
                          ", " +
                          moment(openHouseStartTime)
                            .tz("America/New_York")
                            .format("h a") +
                          " - " +
                          moment(openHouseEndTime)
                            .tz("America/New_York")
                            .format("h:mm a")
                        : moment(openHouseEndTime)
                          .tz("America/New_York")
                          .minute() === Number("00")
                          ? moment(openHouseStartTime)
                            .tz("America/New_York")
                            .format("ddd") +
                          ", " +
                          moment(openHouseStartTime)
                            .tz("America/New_York")
                            .format("h:mm a") +
                          " - " +
                          moment(openHouseEndTime)
                            .tz("America/New_York")
                            .format("h a")
                          : moment(openHouseStartTime)
                            .tz("America/New_York")
                            .format("ddd") +
                          ", " +
                          moment(openHouseStartTime)
                            .tz("America/New_York")
                            .format("h:mm a") +
                          " - " +
                          moment(openHouseEndTime)
                            .tz("America/New_York")
                            .format("h:mm a")}
                  </p> */}

                  {/* </CustomOpenHouseTimingDiv> */}
                {/* </div>
              )} */}


            </div>
          ) : (
            <></>
          )}

          <div className="property-details-page__about">
            <h3 style={{ paddingBottom: "15px" }}>About the Property</h3>
            <p>{remarks}</p>
          </div>

          <div className="property-details-page__basic-details">
            <h3 style={{ paddingBottom: "15px" }}>Basic Details</h3>
            <div className="property-details-page__basic-details__container">
              <ul>
                <li>
                  Listing Price:{" "}
                  {Number(listPrice).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 3,
                  })}
                </li>
                <li>Status: {propDetail.mls.status}</li>
                <li>Property Type: {propDetail.type}</li>
                <li>Property SubType: {propDetail.subTypeText}</li>
              </ul>
              <ul>
                <li>Bedrooms: {propDetail.bedrooms}</li>
                <li>Community: {address.city}</li>
              </ul>
              <ul>
                <li>Buyer Brokerage Compensation: {propDetail.brokerage}</li>
                {/* <li>Time on web/app: 3 days</li> */}
                <li>
                  Bath: {propDetail.bathsFull} full, {propDetail.bathsHalf}{" "}
                  partial
                </li>
                <li>MLS #: {propDetail?.listingId ?? "N/A"} </li>
              </ul>
            </div>
          </div>
          <div className="property-details-page__property-details">
            <h3 style={{ paddingBottom: "15px" }}>Property Details</h3>
            <div className="property-details-page__property-details__group">
              <h4>Interior Features</h4>
              <div className="property-details-page__details-container">
                <div className="property-details-page__details-container__column">
                  {renderPropertyDetailItem("Bedroom Details", [
                    `# of bedrooms: ${propDetail.bedrooms}`,
                  ])}
                  {renderPropertyDetailItem("Interior Features", [
                    `${propDetail.interior}`,
                  ])}
                  {renderPropertyDetailItem("Equipment", [
                    `${propDetail.laundryfeat}`,
                  ])}
                  {renderPropertyDetailItem("Fireplace Details", [
                    `# of fireplaces: ${propDetail.fireplaces}`,
                  ])}
                </div>
                <div className="property-details-page__details-container__column">
                  {renderPropertyDetailItem("Bathroom Details", [
                    `# of baths(full): ${propDetail.bathsFull}`,
                    `# of baths(3/4): ${propDetail.bathsThreeQuarter}`,
                    `# of baths(1/2): ${propDetail.bathsHalf}`,
                  ])}
                  {renderPropertyDetailItem("Flooring Details", [
                    `${propDetail.flooring}`,
                  ])}
                </div>
                <div className="property-details-page__details-container__column">
                  {renderPropertyDetailItem("Room Information", ["N/A"])}
                  {renderPropertyDetailItem("Heating", [
                    `${propDetail?.heating ?? "N/A"}`,
                  ])}
                  {renderPropertyDetailItem("Cooling", [
                    `${propDetail?.cooling ?? "N/A"}`,
                  ])}
                </div>
              </div>
            </div>
            <div className="property-details-page__property-details__group">
              <h4>
                Virtual Tour, Parking /Garage, Exterior Features, School /
                Neighbourhood
              </h4>
              <div className="property-details-page__details-container">
                <div className="property-details-page__details-container__column">
                  {renderPropertyDetailItem("Virtual Tour", [
                    `${propDetail && (propDetail?.virtual_tour ?? 0)}`,
                  ])}
                  {renderPropertyDetailItem("Parking Details", [
                    `# of covered spaces: ${propDetail?.parking?.spaces} `,
                    `Description: ${propDetail?.parking?.description || "N/A"}`,
                  ])}
                </div>
                <div className="property-details-page__details-container__column">
                  {renderPropertyDetailItem("Building Information", [
                    "Built on site",
                    `${propDetail?.foundation}`,
                    `${propDetail?.roof}`,
                  ])}
                </div>
                <div className="property-details-page__details-container__column">
                  {renderPropertyDetailItem("School Information", [
                    `School District: ${schoolInfo?.length > 0 ? schoolInfo?.[0]?.city : "N/A"
                    }`,
                  ])}
                </div>
              </div>
            </div>
            <div className="property-details-page__property-details__group">
              <h4>Utilities, Financing, Location Details</h4>
              <div className="property-details-page__details-container">
                <div className="property-details-page__details-container__column">
                  {renderPropertyDetailItem("Utilities Information", ["N/A"])}
                </div>
                <div className="property-details-page__details-container__column">
                  {renderPropertyDetailItem("Financial Information", [
                    "Cash out, conventional",
                    `Annual taxes: ${taxDet?.taxAnnualAmount?.toLocaleString(
                      "en-US",
                      {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3,
                      }
                    )}`,
                    `Tax year: ${taxDet?.taxYear}`,
                  ])}
                </div>
                <div className="property-details-page__details-container__column">
                  {renderPropertyDetailItem("Location Information", [
                    `${propDetail?.directions}`,
                  ])}
                </div>
              </div>
            </div>
            <div className="property-details-page__property-details__group">
              <h4>Listing Information</h4>
              <div className="property-details-page__details-container">
                <div className="property-details-page__details-container__column">
                  {renderPropertyDetailItem("Listing Information", ["N/A"])}
                </div>
                <div className="property-details-page__details-container__column">
                  {renderPropertyDetailItem("Buyer Brokerage Compensation", [
                    `${propDetail.brokerage}`,
                  ])}
                </div>
              </div>
            </div>
          </div>
          <div className="property-details-page__sales-and-tax-history">
            <CustomHeading style={{ paddingBottom: "15px" }}>
              Sales and{" "}
              <span style={{ borderBottom: "3px solid #C2D500" }}>
                {" "}
                Tax History
              </span>
            </CustomHeading>
            <div className="property-details-page__tabs">
              <div className="property-details-page__tabs__buttons">
                <div
                  className={`property-details-page__tabs__button ${isShowingSalesTab &&
                    "property-details-page__tabs__button--selected"
                    }`}
                  onClick={showSalesTab}>
                  <p>Sales</p>
                </div>
                <div
                  className={`property-details-page__tabs__button ${!isShowingSalesTab &&
                    "property-details-page__tabs__button--selected"
                    }`}
                  onClick={showTaxTab}>
                  <p>Tax</p>
                </div>
              </div>
              <div
                className={`property-details-page__sales-tab ${isShowingSalesTab ? "visible" : "hidden"
                  }`}>
                <div className="property-details-page__sales-summary">
                  <p>Status: Listed ({propDetail.mls.status})</p>

                  <p>N/A</p>
                </div>
              </div>
              <div
                className={`property-details-page__tax-tab ${isShowingSalesTab ? "hidden" : "visible"
                  }`}>
                {renderTaxTimeline()}
              </div>
            </div>
          </div>
          <Row>
            <div className="property-details-page__financial-information">
              <CustomHeading style={{ paddingBottom: "15px" }}>
                Financial{" "}
                <span style={{ borderBottom: "3px solid #C2D500" }}>
                  {" "}
                  Information
                </span>
              </CustomHeading>
              <div className="property-details-page__tabs">
                <div className="property-details-page__tabs__buttons">
                  <div className="property-details-page__tabs__button property-details-page__tabs__button--selected">
                    <p>Payment Calculator</p>
                  </div>
                </div>
                <div className="property-details-page__payment-calculator-tab visible">
                  <div className="property-details-page__payment-calculator__container">
                    <div className="property-details-age__payment-calculator__property-price property-details-age__payment-calculator__item">
                      <p>Property Price</p>
                      <p>
                        {Number(listPrice).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 3,
                        })}
                      </p>
                    </div>
                    <div className="property-details-age__payment-calculator__price-per-sqft property-details-age__payment-calculator__item">
                      <p>Price per sqft</p>
                      <p>
                        {area !== "N/A" ? PricePerSqrt(sqrtPrice, area) : "N/A"}
                      </p>
                    </div>
                    <div className="property-details-age__payment-calculator__annual-taxes property-details-age__payment-calculator__item">
                      <p>Annual Taxes</p>
                      <p>
                        {taxDet.taxAnnualAmount?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 3,
                        })}
                        ({taxDet.taxYear})
                      </p>
                    </div>
                    <div className="property-details-age__paSales and Tax Historyyment-calculator__down-payment property-details-age__payment-calculator__item">
                      <p>Down Payment</p>
                      <p>N/A</p>
                    </div>
                    <div className="property-details-age__payment-calculator__loan-type property-details-age__payment-calculator__item">
                      <p>Loan Type</p>
                      <p>N/A</p>
                    </div>
                    <div className="property-details-age__payment-calculator__interest-rate property-details-age__payment-calculator__item">
                      <p>Interest Rate</p>
                      <p>N/A</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
          <div className="property-details-page__school-information">
            <h3 style={{ paddingBottom: "15px" }}>School Information</h3>
            <p>
              This home is within the{" "}
              {schoolInfo?.length > 0 ? schoolInfo?.[0]?.city : "N/A"} Public
              Schools. {schoolInfo?.length > 0 ? schoolInfo?.[0]?.city : "Massachusetts"}'s enrollment policy is not based solely on
              geography. Please check the school district website to see all
              schools serving this home.
            </p>
            {renderSchools()}
            {!showAllSchool ? (
              <p
                className="property-details-page__school-information__see-more"
                onClick={() => {
                  setShowAllSchool(!showAllSchool);
                }}>
                See more
              </p>
            ) : (
              <p
                className="property-details-page__school-information__see-more"
                onClick={() => {
                  window.scrollTo({ top: 2400 });
                  setShowAllSchool(!showAllSchool);
                }}>
                See less
              </p>
            )}
            <p className="property-details-page__school-information__asterisc">
              <sup>*</sup>School data provided by GreatSchools. School service boundaries
              are intended to be used as reference only. To verify enrollment
              eligibility for a property, contact school directly.
              <br />
              <img src={GreatSchoolLogo} alt="GreatSchools.org brand logo" className="GreatSchoolsLogo" onClick={() => { window.open("https://www.greatschools.org") }} />
              <sup>**</sup>Source: <a href="https://www.greatschools.org" target="_blank" rel="noopener noreferrer">GreatSchools.org</a> © {new Date().getFullYear()}. All rights reserved.
            </p>
          </div>

          <div className="property-details-page__other-properties">
            <h2>
              Other Properties in {address.city}, {address.country}
            </h2>
            <CustomRowOtherProperty style={{ cursor: "pointer" }}>
              {otherProperties.length >= 1 &&
                otherProperties.map((item: any) => renderOtherProperties(item))}
            </CustomRowOtherProperty>
          </div>
        </div>
        <div className="property_detail_google_map_div">
          <CustomSharerow justify="space-between">
            <Col span={screens.xl ? 7 : 1.5}>
              <div
                className="price_and_icon"
                style={{ display: "flex", alignItems: "center" }}>
                <CustomPriceHeading>
                  {Number(listPrice).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 3,
                  })}
                </CustomPriceHeading>

                {isListed ? <img src={iconConnected} alt="home icon" /> : <></>}
              </div>
            </Col>
            <Col span={screens.xl ? 7 : 1.5}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  minWidth: isResponsive ? "230%" : "",
                }}>
                <img
                  role="button"
                  src={shareImg}
                  alt="share icon"
                  onClick={handleSharePropModal}
                />
                {userState?.userType === UserType?.AGENT ? (
                  buyerId ? (
                    <>
                      <div
                        onClick={
                          param.get("isViewed") === "false"
                            ? toggleRequested
                            : () => { }
                        }>
                        <Tooltip
                          placement="topRight"
                          title={
                            param.get("isViewed") === "false"
                              ? "Remove from Requested Showings"
                              : ""
                          }>
                          {checkIsRequested && (
                            <img
                              role={
                                param.get("isViewed") === "false"
                                  ? "button"
                                  : ""
                              }
                              src={requestShowingOn}
                              alt="Showing On Button"
                            />
                          )}
                        </Tooltip>
                        <Tooltip
                          placement="topRight"
                          title={
                            param.get("isViewed") === "false"
                              ? "Add to Requested Showings"
                              : ""
                          }>
                          {!checkIsRequested && (
                            <img
                              role={
                                param.get("isViewed") === "false"
                                  ? "button"
                                  : ""
                              }
                              src={requestShowingOff}
                              alt="Showing Off Button"
                            />
                          )}
                        </Tooltip>
                      </div>
                      <div
                        className="property_detail_favourite_button"
                        onClick={
                          param.get("isViewed") === "false"
                            ? toggleFavorite
                            : () => { }
                        }>
                        <Tooltip
                          placement="topRight"
                          title={
                            param.get("isViewed") === "false"
                              ? "Remove from Favorites"
                              : ""
                          }>
                          {checkIsFavorite ? (
                            <img
                              role={
                                param.get("isViewed") === "false"
                                  ? "button"
                                  : ""
                              }
                              src={favoriteOn}
                              style={{
                                opacity: checkIsFavorite ? 1 : 0,
                              }}
                              alt="Favorite On Button"
                            />
                          ) : (
                            <></>
                          )}
                        </Tooltip>
                        <Tooltip
                          placement="topRight"
                          title={
                            param.get("isViewed") === "false"
                              ? "Add to Favorites"
                              : ""
                          }>
                          {!checkIsFavorite ? (
                            <img
                              role={
                                param.get("isViewed") === "false"
                                  ? "button"
                                  : ""
                              }
                              src={favoriteOff}
                              style={{
                                opacity: checkIsFavorite ? 0 : 1,
                              }}
                              alt="Favorite Off Button"
                            />
                          ) : (
                            <></>
                          )}
                        </Tooltip>
                      </div>
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  <>
                    <div
                      className="property_detail_requested_button"
                      onClick={toggleRequested}>
                      <Tooltip
                        placement="topRight"
                        title={"Remove from Requested Showings"}>
                        {checkIsRequested ? (
                          <img
                            role="button"
                            src={requestShowingOn}
                            alt="Showing On Button"
                          />
                        ) : (
                          <></>
                        )}
                      </Tooltip>
                      <Tooltip
                        placement="topRight"
                        title={"Add to Requested Showings"}>
                        {!checkIsRequested ? (
                          <img
                            role="button"
                            src={requestShowingOff}
                            alt="Showing Off Button"
                          />
                        ) : (
                          <></>
                        )}
                      </Tooltip>
                    </div>
                    <div
                      className="property_detail_favourite_button"
                      onClick={toggleFavorite}>
                      <Tooltip
                        placement="topRight"
                        title={"Remove from Favorites"}>
                        {checkIsFavorite ? (
                          <img
                            role="button"
                            src={favoriteOn}
                            alt="Favorite On Button"
                          />
                        ) : (
                          <></>
                        )}
                      </Tooltip>
                      <Tooltip placement="topRight" title={"Add to Favorites"}>
                        {!checkIsFavorite ? (
                          <img
                            role="button"
                            src={favoriteOff}
                            alt="Favorite Off Button"
                          />
                        ) : (
                          <></>
                        )}
                      </Tooltip>
                    </div>
                  </>
                )}
              </div>
            </Col>
          </CustomSharerow>
          <Row justify="space-between">
            <Col span={screens.xl ? 7 : 2}>
              <b>
                {area !== "N/A"
                  ? Number(area)?.toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 3,
                  })
                  : area}{" "}
                sqft
              </b>
            </Col>
            <Col span={screens.xl ? 7 : 2}>{propDetail.bedrooms} bed</Col>
            <Col span={screens.xl ? 7 : 2}>
              {propDetail.bathrooms + propDetail.bathsFull} bath
            </Col>
          </Row>
          {userState?.userType === UserType?.AGENT && buyerId && isListed ? (
            <>
              <CustomViewCalenderPara onClick={findAnAgentModalHandler}>
                view property calendar
              </CustomViewCalenderPara>
            </>
          ) : (
            <></>
          )}

          <Row justify="start">
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={24}
              className="property_detail_google_map_column">
              {geoLoc && (
                <GoogleMapReact
                  lat={geoLoc.lat}
                  lng={geoLoc.lng}
                  isZoomed={isZoomed}
                  direction={address.fullAddress}
                />
              )}
            </Col>
            <Col span={screens.xl ? 10 : 12} className="mark_location_details">
              <h4
                style={{ fontSize: "24px", fontWeight: "bold", width: "100%" }}>
                Location
              </h4>
              <CustomPara>
                {area !== "N/A"
                  ? Number(area)?.toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 3,
                  })
                  : "N/A"}{" "}
                sqft <br />
                {"  "}
                {address?.streetNumber} {"  "} {address?.streetName}
              </CustomPara>
              <CustomPara>
                {address?.city}, {address?.state} {address?.postalCode}
              </CustomPara>
            </Col>
          </Row>
          {!screens.xl ? (
            <div>
              <div>
                {userState?.isLoggedIn &&
                  userState?.userType === UserType?.BUYER &&
                  userState?.buyer?.isLinked ? (
                  <PropertyCommentSection
                    propertyId={parseInt(mlsId)}
                    agentId={agentId}
                    agentName={agentName}
                    buyerId={buyerId}
                    buyerName={buyerName}
                    buyerPhone={buyerPhone}
                    agentPhone={agentPhone ?? "N/A"}
                    propertyType={propDetail?.type ?? "N/A"}
                    mlsId={propDetail?.listingId ?? "N/A"}
                    latitude={geoLoc?.lat ?? 0}
                    longitude={geoLoc?.lng ?? 0}
                    country={address?.country ?? "N/A"}
                    imageUrl={propDetail?.imageURL ?? "N/A"}
                    listPrice={propDetail?.listPrice ?? 0}
                    streetNumber={Number(address?.streetNumber) ?? 0}
                    streetName={address?.streetName ?? "N/A"}
                    city={address?.city ?? "N/A"}
                    state={address?.state ?? "N/A"}
                    postalCode={address?.postalCode ?? "N/A"}
                    bedrooms={propDetail?.bedrooms ?? 0}
                    bathrooms={propDetail?.bathsFull ?? 0}
                    area={Number(propDetail?.area) ?? 0}
                    bathsHalf={propDetail?.bathsHalf ?? 0}
                    opneHouse={param?.get("type") ?? propertyType ?? "type"}
                    setOpenHouseOnCommentProp={setOpenHouseOnCommentProp}
                    propertyDetails={propertyDetails}
                    openHouseStartTime={openHouseStartTime}
                    openHouseEndTime={openHouseEndTime}
                    openHouseId={openHouseId}
                    openHouseKey={openHouseKey}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div>
                {userState.isLoggedIn &&
                  userState.userType === UserType.AGENT ? (
                  <PropertyCommentSection
                    propertyId={parseInt(mlsId)}
                    agentId={agentId}
                    agentName={agentName}
                    buyerId={buyerId}
                    buyerName={buyerName}
                    buyerPhone={buyerPhone}
                    agentPhone={agentPhone ?? "N/A"}
                    propertyType={propDetail?.type ?? "N/A"}
                    mlsId={propDetail?.listingId ?? "N/A"}
                    latitude={geoLoc?.lat ?? 0}
                    longitude={geoLoc?.lng ?? 0}
                    country={address?.country ?? "N/A"}
                    imageUrl={propDetail?.imageURL ?? "N/A"}
                    listPrice={propDetail?.listPrice ?? 0}
                    streetNumber={Number(address?.streetNumber) ?? 0}
                    streetName={address?.streetName ?? "N/A"}
                    city={address?.city ?? "N/A"}
                    state={address?.state ?? "N/A"}
                    postalCode={address?.postalCode ?? "N/A"}
                    bedrooms={propDetail?.bedrooms ?? 0}
                    bathrooms={propDetail?.bathsFull ?? 0}
                    area={Number(propDetail?.area) ?? 0}
                    bathsHalf={propDetail?.bathsHalf ?? 0}
                    opneHouse={param?.get("type") ?? propertyType ?? "type"}
                    setOpenHouseOnCommentProp={setOpenHouseOnCommentProp}
                    propertyDetails={propertyDetails}
                    openHouseStartTime={openHouseStartTime}
                    openHouseEndTime={openHouseEndTime}
                    openHouseId={openHouseId}
                    openHouseKey={openHouseKey}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
          <CustomDiv>Property type: {propDetail.type}</CustomDiv>
          <CustomDiv>Status: {propDetail.mls.status}, For Sale</CustomDiv>
          <CustomDiv>
            Price per sqft:{" "}
            {area !== "N/A" ? PricePerSqrt(sqrtPrice, area) : "N/A"}
          </CustomDiv>
          <CustomDiv>Year Built: {yearBuilt}</CustomDiv>
          {area1 && (
            <CustomDiv>
              Lot size: {area1 ? area1 : "N/A"}
              <Select
                style={{
                  width: 100,
                }}
                bordered={false}
                onChange={handleChange}
                defaultValue="Acres">
                <Option value="Acres">Acres</Option>
                <Option value="sqft">sqft</Option>
              </Select>
            </CustomDiv>
          )}

          <CustomDiv>Listed on NWMLS</CustomDiv>

          {userState?.isLoggedIn && userState?.userType === UserType.AGENT ? (
            <Row
              style={{ width: screens.xl ? "90%" : "95%" }}
              onClick={handleModal}
              role="button">
              <Col span={screens.xl ? 5 : 7}>
                <Avatar
                  src={
                    isListed &&
                      (listingAgentActivatedPlan === "Navihome Advanced Plan" ||
                        listingAgentActivatedPlan === "Navihome Premimum Plan")
                      ? listingAgentImg
                      : ""
                  }
                  style={{ display: "grid", placeItems: "center" }}
                  size={38}
                  icon={
                    isListed &&
                      (listingAgentActivatedPlan === "Navihome Advanced Plan" ||
                        listingAgentActivatedPlan === "Navihome Premimum Plan") ? (
                      ""
                    ) : (
                      <UserOutlined />
                    )
                  }
                  className="avatar"
                />
              </Col>
              <Col
                span={screens.xl ? 17 : 17}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <span style={{ fontWeight: "bold", fontSize: "13px" }}>
                  {isListed
                    ? listingAgentName
                    : propDetail?.agent?.firstName +
                    " " +
                    propDetail?.agent?.lastName || "N/A"}

                  <br />
                  <span style={{ fontWeight: "normal", fontSize: "12px" }}>
                    {isListed ? listingAgentComp : propDetail?.office?.name}
                  </span>
                </span>

                {isListed && (
                  <span style={{ display: "flex" }}>
                    <img
                      src={iconEmail}
                      alt="iconEmail"
                      style={{ marginRight: "8px" }}
                    />
                  </span>
                )}
                <span style={{ display: "flex" }}>
                  <img src={iconPhone} alt="iconPhone" />
                </span>
              </Col>
              <CustomDivider />
              {isFindAnAgentModal && (
                <FindAnAgentModal
                  isShowing={isFindAnAgentModal}
                  closeAction={findAnAgentModalHandler}
                />
              )}
            </Row>
          ) : (
            <>
              {!userState?.isLoggedIn ? (
                <Button
                  text="CONTACT AGENT"
                  type={ButtonType?.PRIMARY}
                  clickHandler={() => setIsModalVisible(true)}
                  className="notification-buyer-invitation__button--accept"
                />
              ) : (
                <></>
              )}
            </>
          )}

          {isOpenHouse && <CustomParaOpenHouse>Open House</CustomParaOpenHouse>}

          {isOpenHouse &&
            openHousePropDetail?.data?.map((item: any) => {
              return (
                <div style={{ marginBottom: "1rem" }}>
                  <CustomParaOpenHouse>
                    {moment(item?.startTime)
                      .tz("America/New_York")
                      .format("ddd, MMM DD") ?? "N/A"}
                  </CustomParaOpenHouse>
                  <p>{`${moment(item?.startTime)
                    .tz("America/New_York")
                    .format("hh:mm a")} - ${moment(item?.endTime)
                      .tz("America/New_York")
                      .format("hh:mm a")} `}</p>
                </div>
              );
            })}
          {sharePropModal && (
            <PropertyShareModal
              isShowing={sharePropModal}
              closeAction={handleSharePropModal}
              imageurl={imageSrc ?? ""}
              propertyAddress={`${address?.streetNumber}  ${address?.streetName}
                                ${address?.city}, ${address?.state} ${address?.postalCode}`}
              listingId={propDetail?.listingId ?? ""}
              listingAgentName={
                propDetail?.agent?.firstName +
                " " +
                propDetail?.agent?.lastName || "N/A"
              }
              mlsId={propDetail?.mlsId}
            />
          )}
        </div>
      </div>
      {(param?.get("type")?.toString() || openHouseOnCommentProp) ===
        "open_house" ? (
        !screens.xl ? (
          <CustomBackPara
            style={{
              position: "absolute",
              left: "6px",
              top: isMobile ? "15rem" : "11rem",
              marginLeft: isSm ? null : "2rem",
              zIndex: "1",
              fontSize: "1.8rem",
              fontWeight: "bold",
              color: "white",
            }}
            onClick={backHandler}>
            Back
          </CustomBackPara>
        ) : null
      ) : (
        <>
          {!screens.xl ? (
            <CustomBackPara
              style={{
                position: "absolute",
                left: "6px",
                top: "7rem",
                marginLeft: isSm ? null : "2rem",
                zIndex: "1",
                fontSize: "1.8rem",
                fontWeight: "bold",
                color: "white",
              }}
              onClick={backHandler}>
              Back
            </CustomBackPara>
          ) : null}
        </>
      )}
      {isModalVisible ? (
        <AgentConnectingModal
          isShowing={isModalVisible}
          closeAction={() => {
            setIsModalVisible(false);
          }}
          setIsShowingLoginModal={setIsShowingLoginModal}
        />
      ) : (
        <></>
      )}
      {isShowFindAnAgentConfirmModal && (
        <FindAnAgentConfirmModal
          isShowing={isShowFindAnAgentConfirmModal}
          closeAction={confirmModalHandler}
          text="Since you are not conneted with an agent, you need to connect with an agent."
          yesText="FIND AN AGENT"
          noText="INVITE YOUR AGENT"
          pageType="FindAnAgent"
        />
      )}
      {isShowNoPopertyConfirmModal && <FindAnAgentConfirmModal
        isShowing={isShowNoPopertyConfirmModal}
        closeAction={confirmNoPropertyModalHandler}
        text="This property listing is no longer available. Do you want to remove it?"
        yesText="YES"
        noText="No"
        yesClickHandler={confirmYesPropertyModalHandler}
        tab={param.get('buyerDashboard') ?? ""}
        tourId={param.get('tourId') ?? ""}
        buyerId={userState.userType === UserType.BUYER ? userState?.buyer?.buyerId : param.get("id") ?? ""}
        propertyId={mlsId}
        pageType="PropertyDetailsPage"
      />}
      {isShowingLoginModal && (
        <LogInModal
          closeAction={() => {
            setIsShowingLoginModal(false);
          }}
          isShowing={isShowingLoginModal}
          setIsShowingForgotPasswordModal={setIsShowingForgotPasswordModal}
        />
      )}
      {isShowingForgotPasswordModal && (
        <ForgotPasswordModal
          closeAction={() => {
            setIsShowingForgotPasswordModal(false);
          }}
          isShowing={isShowingForgotPasswordModal}
          setIsShowingLoginModal={setIsShowingLoginModal}
        />
      )}
      {listedAgentModal && (
        <ListAgentModal
          isShowing={listedAgentModal}
          closeAction={() => {
            setListedAgentModal(!listedAgentModal);
          }}
          item={propertyDetails}
          agentEmail={listingAgentEmail}
          agentPhone={listingAgentPhone}
          modeOfContact={modeOfContact}
          agentPhoto={listingAgentImg}
          userName={listingAgentName}
          listingAgentId={listingAgentId}
          listingAgentName={listingAgentName}
          listAgentSName={listingAgentSellerName}
          listingAgentActivatedPlan={listingAgentActivatedPlan}
        />
      )}
      {notListedAgentModal && (
        <NotListAgentModal
          item={propertyDetails}
          isShowing={notListedAgentModal}
          closeAction={() => {
            setNotListedAgentModal(!notListedAgentModal);
          }}
          agentPhone={agentNotListedPhone}
          userName={userName}
        />
      )}
    </div>
  );
};
export default PropertyDetailsPage;
