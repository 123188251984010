import React from 'react';
import { Spinner } from 'reactstrap';
import styled from '@emotion/styled';
import {Spin} from 'antd'

const SpinnerContainer = styled.div`
  position: fixed;
  background: transparent;
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: rgb(223 224 228 / 29%);
  z-index: 999;
`;
const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 40%;
  color: #365073;
  left: 50%;
  font-size:14px;
  width: 2.5rem;
  height: 2.5rem;
`;
const CustomSpinner = (props: any) => {
  return (
    <SpinnerContainer>
      <StyledSpinner {...props} children="" />
    </SpinnerContainer>
  );
};
export default CustomSpinner;
