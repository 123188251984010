import React, { useState, useContext, useRef } from "react";
import { Row, Col } from "antd";
import CompButton, { ButtonType } from "../../components/Button";
import UserContext, { UserType } from "../../contexts/UserContext";
import MyCalendar from "./MyCalendar";
import CathyCalendar from "./CathyCalendar";
import styled from "@emotion/styled";
const CustomContainer = styled.div`
  margin: 10px;
  padding: 5px;
`;
const CustomheadingFirst = styled.h1`
  color: #004876;
  font-weight: 800;
  font-size: 15px;
  padding-bottom: 10px;
`;

const CustomPara = styled.p`
  font: 800;
  font-size: 15px;
  padding-bottom: 10px;
`;

const CustomButton = styled(CompButton)`
  font-size: 10px;
`;
// create Props
interface CalendarUiProps {
  setDateTime: (value: string) => void;
  // setStartTime: (value: string) => void;
  // setEndTime: (value: string) => void;
  setTourCalModal: (value: boolean) => void;
  agentName?: string;
  buyerName?: string;
  setStartTimeForTimeStamp?: any;
  formDataEdited?: any;
  setFormDataEdited?: any;
}

const CalendarUi: React.FC<CalendarUiProps> = ({
  setDateTime,
  formDataEdited,
  setFormDataEdited,
  setTourCalModal,
  agentName,
  buyerName,
  setStartTimeForTimeStamp,
}) => {
  const [allInputData, setAllInputData] = useState<any>("");
  const [isOn, setIsOn] = useState(true);
  let calendarRef = useRef(null);
  let calendarRef1 = useRef(null);

  let dayChange = (value: string) => {
    switch (value) {
      case "next":
        calendarRef?.current?.getApi().next();
        calendarRef1?.current?.getApi().next();

        break;
      case "prev":
        calendarRef?.current?.getApi().prev();
        calendarRef1?.current?.getApi().prev();

        break;
    }
  };
  const { userState } = useContext(UserContext);

  return (
    <CustomContainer>
      <CustomheadingFirst>Tour date and time</CustomheadingFirst>
      <CustomPara>Schedule your tour based on your availability</CustomPara>
      <Row justify="space-around">
        {userState?.userType === UserType.AGENT && (
          <Col
            xs={{ span: 22 }}
            sm={{ span: 20 }}
            md={{ span: 24 }}
            lg={{ span: 11 }}>
            <CathyCalendar
              setAllInputData={setAllInputData}
              setStartTimeForTimeStamp={setStartTimeForTimeStamp}
              agentName={agentName}
              calendarRef1={calendarRef1}
              dayChange={dayChange}
              allInputData={allInputData}
              isOn={isOn}
            />
          </Col>
        )}
        <Col
          xs={{ span: 22 }}
          sm={{ span: 20 }}
          md={{ span: 24 }}
          lg={{ span: userState?.userType === UserType.AGENT ? 11 : 24 }}>
          {" "}
          <MyCalendar
            setAllInputData={setAllInputData}
            setStartTimeForTimeStamp={setStartTimeForTimeStamp}
            buyerName={buyerName}
            allInputData={allInputData}
            calendarRef={calendarRef}
            dayChange={dayChange}
            setIsOn={setIsOn}
          />
        </Col>
      </Row>
      <div
        style={{
          width: "100%",
          textAlign: "center",
          marginTop: "5rem",
        }}>
        <CustomButton
          type={ButtonType.PRIMARY}
          text="Continue"
          clickHandler={() => {
            setDateTime(allInputData);
            setFormDataEdited({ ...formDataEdited, dateTime: allInputData })
            setTourCalModal(false);
          }}
          width={20}
          className="Calendar_ui_continue__button"
        />
      </div>
    </CustomContainer>
  );
};

export default CalendarUi;
