import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import AccountSetting from "../components/Account/AccountAgentSettingPage";
import styled from "@emotion/styled";
import UserHeader from "../components/notificationAgentPage/UserHeader";
import { activatedPlan } from "../utils/base";
import UserContext from "../contexts/UserContext";

const CustomBackDiv = styled.p`
padding: 10px;
display: inline-block;
cursor: pointer;
color: #000000;
&:hover {
  text-decoration: underline;
}
`;

const AccountSettingsAgentPage: React.FC = () => {
  let history = useHistory();
  const { userState } = useContext(UserContext);

  const backButtonHandler = () => {
    history.goBack();
  };

  return (
    <div>
      {activatedPlan.includes(userState?.agent?.subscription_type) && (
        <UserHeader />
      )}
      <CustomBackDiv onClick={backButtonHandler}>Back</CustomBackDiv>
      <div className="account-settings-agent-page">
        <div className="account-settings-agent-page__container">
          <AccountSetting />
        </div>
      </div>
    </div>
  );
};

export default AccountSettingsAgentPage;
