import { useEffect, useState } from 'react';
import iconConnected from '../../assets/images/icon-connected.svg';
import { checkIsListedPropertyService } from '../../services/NavihomeService';
import AddPropertyModal from './addPropertyModal';
import InviteSellerModal from './InviteSellerModal';

export interface PropertyCardProps {
  listingId: number;
  agentMlsId: number;
  propertyId: number;
  image: string;
  price: number;
  type: string;
  beds: number;
  baths: number;
  squareFootage: number;
  address1: string;
  address2: string;
  setHomePage: any;
  sellerProperties: any;
  city: string;
  getAllListedProperty: () => void;
}

const PropertyCard: React.FC<PropertyCardProps> = ({
  listingId,
  agentMlsId,
  propertyId,
  image,
  price,
  type,
  beds,
  baths,
  squareFootage,
  address1,
  address2,
  setHomePage,
  sellerProperties,
  city,
  getAllListedProperty,
}) => {
  const [isOpenModal, setIsModalOpen] = useState<boolean>(false);
  const [openInviteSellerModal, setOpenInviteSellerModal] =
    useState<boolean>(false);
  const [isListed, setIsListed] = useState<boolean>(false);
  const handleViewProperty = async () => {
    setIsModalOpen(true);
  };
  const checkIfListed = async () => {
    try {
      const response: any = await checkIsListedPropertyService(propertyId);
      setIsListed(response?.success);
    } catch (error) {
      console.log('ERROR: ', error);
    }
  };

  useEffect(() => {
    checkIfListed();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="">
      <div className="homepage-agent-page-listing__listed-container">
        <h3>Property Search</h3>
      </div>
      <div className="homepage-agent-page-listing__listed-container">
        <div
          onClick={() => {
            handleViewProperty();
          }}
          className="property-card"
        >
          <img
            className="property-card__image"
            src={image}
            alt="Property"
            onClick={handleViewProperty}
          />
          <div className="property-card__info">
            <div className="property-card__price">
              <p>{price?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 3,
              })}</p>
              {isListed && <img src={iconConnected} alt="Connected" />}
            </div>

            <p className="property-card__type">{type}</p>xao
            <p className="property-card__beds">{beds} beds</p>
            <p className="property-card__baths">{baths} baths</p>
            <p className="property-card__sqft">{squareFootage} sqft</p>

            <p className="property-card__address">
              {address1}
              <br />
              {address2}
            </p>
          </div>
        </div>
      </div>
      {isOpenModal && (
        <AddPropertyModal
          listingId={listingId}
          agentMlsId={agentMlsId}
          propertyId={propertyId}
          setOpenInviteSellerModal={setOpenInviteSellerModal}
          isOpenModal={isOpenModal}
          closeAction={() => {
            setIsModalOpen(false);
          }}
          setHomePage={setHomePage}
          sellerProperties={sellerProperties}
          getAllListedProperty={getAllListedProperty}
          city={city}
        />
      )}
      {openInviteSellerModal && (
        <InviteSellerModal
          isShowing={openInviteSellerModal}
          closeAction={() => {
            setOpenInviteSellerModal(false);
            setHomePage(true);
          }}
          setHomePage={setHomePage}
          propertyId={propertyId}
          mlsId={listingId}
        />
      )}
    </div>
  );
};

export default PropertyCard;
