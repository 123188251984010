import minusButton from '../assets/images/button-minus.svg';
import plusButton from '../assets/images/button-plus.svg';
import buttonAdd from '../assets/find-an-agent-page/Union 25.svg'

interface CounterFieldProps {
  value: number;
  increment: number;
  minValue: number;
  maxValue: number;
  setValueHandler: (value: number) => void;
}

const CounterField: React.FC<CounterFieldProps> = ({ value, increment, minValue, maxValue, setValueHandler }) => {
  const subtractHandler = () => {
    if (value - increment >= minValue) {
      setValueHandler(value - increment);
    }
  }

  const addHandler = () => {
    if (value + increment <= maxValue) {
      setValueHandler(value + increment);
    }
  }

  return (
    <div className="counter-field">
      <div className="counter-field__frame">
        <div className="counter-field__button counter-field__button--minus" onClick={subtractHandler}><img src={minusButton} alt="Minus Button" /></div>
        <p className="counter-field__value">{value}</p>
        <div className="counter-field__button counter-field__button--plus" onClick={addHandler}><img src={plusButton} alt="Plus Button" /></div>
      </div>
    </div>
  );
};

export default CounterField;