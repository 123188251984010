import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

interface PreLoginNavbarPropsModel {
  renderNavSecondary?: (setIsNavExpanded: any) => JSX.Element;
  renderNavPrimary?: (setIsNavExpanded: any) => JSX.Element;
  renderNavResponsive: (setIsNavExpanded: any) => JSX.Element;
}

const PreLoginNavbar: React.FC<PreLoginNavbarPropsModel> = ({ renderNavPrimary, renderNavSecondary, renderNavResponsive }): JSX.Element => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 996 });
  // const isresponsive = useMediaQuery({ maxWidth: 576 });

  return (
    <>
      <nav className="navigation">
        <a href="/" className="brand-name">
          {renderNavSecondary(setIsNavExpanded)}
        </a>
        <button
          className="hamburger"
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}
        >
          {/* icon from heroicons.com */}
          {isNavExpanded ? (
            <>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" style={{ color: "white" }}>
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </>
          ) : (
            <>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="white">
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </>
          )}
        </button>
        <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
          {!isMobile ? <ul>{renderNavPrimary(setIsNavExpanded)}</ul> : <ul>{renderNavResponsive(setIsNavExpanded)}</ul>}
        </div>
      </nav>
    </>
  );
};

export default PreLoginNavbar;
