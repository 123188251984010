/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from "@emotion/styled";
import { Form, Select } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory, useLocation } from "react-router-dom";
import Button, { ButtonType } from "../components/Button";
import MiniCalendar from "../components/MiniCalendar";
import PropertyDetailsPage from "../components/SellBuyerPage/propertyDetailsPage";
import InviteSellerModal from "../components/agentHomePage/InviteSellerModal";
import ListingsUpcomingShowings from "../components/agentHomePage/listingsUpcomingShowings";
import BuyerDetails from "../components/dashboardAgentPage/BuyerDetails";
import { notification } from "../components/notification";
import UserHeader from "../components/notificationAgentPage/UserHeader";
import CalendarMain from "../components/propertyDetailsAgentPage/calendar/calendarMain";
import NotificationMain from "../components/propertyDetailsAgentPage/notifications/notificationMain";
import PrefrencesMain from "../components/propertyDetailsAgentPage/prefrences/prefrencesMain";
import ShowingsMain from "../components/propertyDetailsAgentPage/showings/showingsMain";
import StatisticsMain from "../components/propertyDetailsAgentPage/statistics/statisticsMain";
import CustomSpinner from "../components/spinner/CustomSpinner";
import PropertyFiltersContext from "../contexts/PropertyFiltersContext";
import UserContext from "../contexts/UserContext";
import {
  checkIsSellerInvitedService,
  completedShowingsPropertyStatsSingle,
  completedShowingsPropertyStatsTour,
  createAgentNotification,
  createBuyerNotificationById,
  getAddedProperty,
  getAgentNotifications,
  getAllSellerUpcomingTours,
  getListedSellerDetails,
  getPropertyDetailsService,
  getViewPropertyCalendarByPropID,
  postMessageToUser,
  removePropertyFromListedService,
  unlinkSellerFromAgentService,
  updateAgentNotification,
} from "../services/NavihomeService";
import { activatedPlan } from "../utils/base";
import { CustomDashboardTitle, CustomTitleContainer, CustomTitleContainerRight } from "./DashboardAgentPage";
import FindAnAgentConfirmModal from "../modals/FindAnAgentConfirmModal";

enum DashboardTab {
  OVERVIEW = "OVERVIEW",
  PROPERTY_DETAILS = "Property Details",
  PROPERTY_STATISTICS = "Property Statistics",
  PROPERTY_SHOWING = "Property Showing",
  PREFERENCES = "Preferences",
  PROPERTY_CALENDAR = "Property Calendar",
  NOTIFICATIONS = "Notifications",
}

const CustomSpan = styled.span`
  font-size: 18px;
  cursor: pointer;
  color: black;
  &:hover {
    font-weight: 600;
    text-decoration: underline;
  }
`;
const CustomBackDiv = styled.div`
  display: inline-block;
  padding: 12px;
`;
const Upcominghead = styled.h3``;
const PropertyDetailsAgentPage: React.FC = () => {
  const location: any = useLocation();
  const { userState } = useContext(UserContext);
  const [isSellerInvited, setIsSellerInvited] = useState<boolean>(false);
  const [isShowingInviteSellerModal, setIsShowingInviteSellerModal] =
    useState<boolean>(false);
  let [isLoading, setIsLoading] = useState(false);
  let [agentNotifications, setAgentNotifications] = useState<Array<any>>([]);
  let [isNotifications, setIsNotifications] = useState<boolean>(false);
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(DashboardTab.OVERVIEW);
  const [sellerDetail, setSellerDetail] = useState<any>(null);
  const [propMlsId, setPropMlsId] = useState<number>(null);
  const [sellerName, setSellerName] = useState<string>("");
  const [sellerEmail, setSellerEmail] = useState<string>("");
  const [sellerId, setSellerId] = useState<number>(0);
  const [sellerPhone, setSellerPhnoe] = useState<string>("");
  const [sellerPreferredContact, setSellerPreferredContact] =
    useState<string>("");
  const [listedPropertyId, setListedPropertyId] = useState<number>(0);
  const [listedPropertyMlsId, setListedPropertyMlsId] = useState<number>(0);
  const [isPropertyDetails, setIsPropertyDetails] = useState<boolean>(false);
  const [componentSize, setComponentSize] = useState<SizeType | "default">(
    "default"
  );
  const [previousTourData, setPreviousTourData] = useState<Array<any>>([]);
  const [isShowFindAnAgentConfirmModal, setIsShowFindAnAgentConfirmModal] =
  useState<boolean>(false);
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let param = useQuery();


  const {
    setCreateNotificationsInfo,
    setGetNotificationsUserEmail,
    setGetNotificationsUserId,
  } = useContext(PropertyFiltersContext);
  type SizeType = Parameters<typeof Form>[0]["size"];

  const [tours, setTours] = useState<any>({});
  const [upcomingStartEndDate, setUpcomingStartEndDate] = useState<any>({
    startDate: null,
    endDate: null,
  });

  const isMD = useMediaQuery({ maxWidth: 1070 });
  const { Option } = Select;

  const tableDataHandler = (viewProp: Array<any>) => {
    viewProp?.sort((a: any, b: any) => {
      return +new Date(a?.startDate) - +new Date(b?.startDate);
    });
    let upcomingTour = [];
    if (viewProp?.length > 0) {
      for (let i = 0; i < viewProp?.length; i++) {
        let date: any,
          dayOfWeek: string,
          time: string,
          timeOfDay: string,
          statusPrimary: string,
          propCalCurrZone: any,
          client: string,
          saleAgent: string,
          responseData: any;

        propCalCurrZone = viewProp[i]?.startTime?.split(" ")?.[1] ?? "N/A";

        const stDay: number = new Date(viewProp[i]?.startDate).getUTCDate();
        const stMonth: number = new Date(viewProp[i]?.startDate).getMonth();
        const stYear: number = new Date(viewProp[i]?.startDate).getFullYear();

        const tempDate: string = `${stMonth + 1}-${stDay}-${stYear}`;
        date = moment(tempDate, "MM-DD-YYYY").format("LL");
        dayOfWeek = moment(tempDate, "MM-DD-YYYY").format("dddd");
        // date = moment(viewProp[i]?.startDate).format("LL");
        // dayOfWeek = moment(viewProp[i]?.startDate).format("dddd");
        time = `${moment(viewProp[i]?.startTime, "HH:mm").format(
          "hh:mm a"
        )} - ${moment(viewProp[i]?.endTime, "HH:mm").format("hh:mm a")}`;
        timeOfDay =
          propCalCurrZone?.toLowerCase() === "am" ? "Morning" : "Evening";
        client = viewProp?.[i]?.viewPropertyCalendarId
          ? viewProp?.[i]?.client
          : viewProp?.[i]?.client ?? "N/A";
        saleAgent = viewProp?.[i]?.viewPropertyCalendarId
          ? viewProp?.[i]?.agent?.name ?? ""
          : viewProp?.[i]?.agentName;
        statusPrimary = viewProp[i]?.isAccepted ? "Accepted" : "Requested";
        responseData = viewProp[i];
        const newDataObj = {
          date: date,
          dayOfWeek: dayOfWeek,
          time: time,
          timeOfDay: timeOfDay,
          client: client,
          saleAgent: saleAgent,
          statusPrimary: statusPrimary,
          responseData: responseData,
        };
        upcomingTour.push(newDataObj);
      }
    }
    return upcomingTour;
  }
  const completedShowingsPropertyStats = async () => {
    try {
      setIsLoading(true);
      let viewProp: any = [];
      const query = history?.location?.pathname?.split("/")?.[2];
      const propertyId = parseInt(query) ?? null;
      const completedShowingsPropertyStatsSingleRes: any = await completedShowingsPropertyStatsSingle(propertyId);
      const completedShowingsPropertyStatsTourRes: any = await completedShowingsPropertyStatsTour(propertyId, "completed");
      viewProp = [...completedShowingsPropertyStatsSingleRes, ...completedShowingsPropertyStatsTourRes];
      const tableData: Array<any> = tableDataHandler(viewProp)
      setPreviousTourData(tableData)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log("Error", error);
    }
  }

  const getEventDetails = async () => {
    setIsLoading(true);
    try {
      if (!userState?.agent?.token) {
        setIsLoading(false);
        return notification("User State Agent Token Not Found!", "error");
      }
      const token = userState?.agent?.token;
      const query = history?.location?.pathname?.split("/")?.[2];
      const propertyId = parseInt(query) ?? null;

      let currDate = moment().format("YYYY-MM-DD");
      let viewProp: any = [];

      const newToursData: any = await getAllSellerUpcomingTours(
        propertyId,
        currDate,
        token,
        "Agent"
      );
      const newTourPropCal: any = await getViewPropertyCalendarByPropID(
        propertyId,
        currDate,
        token,
        "Agent"
      );

      viewProp = [...newToursData, ...newTourPropCal];


      const tableData: Array<any> = tableDataHandler(viewProp)
      setTours(tableData);

      setIsLoading(false);
    } catch (error) {
      console.log("Error", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const query: any = history?.location?.pathname?.split("/")?.[2];
    const currTab: string = param.get("currTab") ?? null;

    if (currTab === "calendar") setCurrentTab(DashboardTab.PROPERTY_CALENDAR)
    setListedPropertyId(Number(query));
    getSellerDetails(Number(query));
    getListedPropertyDetails(Number(query));
    setPropMlsId(query);
    checkIsSellerInvited(query);
    getEventDetails();
    completedShowingsPropertyStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      location?.state?.notificationArr &&
      location?.state?.notificationArr?.length > 0
    ) {
      selectNotifications("useEffect");
      setTimeout(async () => {
        await Promise.all(
          location?.state?.notificationArr?.map(async (id: number) => {
            await updateNotification(id);
          })
        );

        getNotifications();
      }, 10000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history?.location?.search]);

  const getSellerDetails = async (mlsId: number) => {
    const { propertyDetails }: any = await getAddedProperty(
      Number(mlsId),
      userState?.agent?.token
    );
    setListedPropertyMlsId(propertyDetails?.[0]?.listingId);

    if (propertyDetails?.[0]?.sellerEmail) {
      const data: any = await getListedSellerDetails(
        propertyDetails?.[0]?.sellerEmail
      );
      setSellerName(data?.name);
      setSellerEmail(data?.email);
      setSellerId(data?.buyerId);
      setSellerPreferredContact(data?.preferredContact);
      setSellerPhnoe(data?.phone);
      setSellerDetail(data);
    }
  };

  const getListedPropertyDetails = async (propertyId: number) => {
    setIsLoading(true);
    try {
      const propertyDetails: any = await getPropertyDetailsService(propertyId);

      if (propertyDetails?.length === 0) {
        setIsPropertyDetails(true);
      } else {
        setIsPropertyDetails(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }

    return;
  };

  const removeListedProperty = async () => {
    try {
      await removePropertyFromListedService(
        listedPropertyId,
        userState?.agent?.agentId,
        userState?.agent?.token
      );
      if (sellerId){
        await unlinkSellerFromAgentService(userState?.agent?.token, sellerId);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };
  const showConfirmModalHandler = () => {
    setIsShowFindAnAgentConfirmModal(!isShowFindAnAgentConfirmModal);
  };
  //199493310
  const unlinkSellerServices = async () => {
    setIsLoading(true);
    try {
      if (!listedPropertyId) {
        setIsLoading(false);
        return;
      }
      removeListedProperty();
      if (sellerId) {
        await createBuyerNotificationById(
          sellerId,
          `Your Agent ${userState?.agent?.name} removed listing property mls#${listedPropertyMlsId} and disconnect as listing agent.`,
          "removed",
          userState?.agent?.token,
          "buyer",
          0
        );
        await createAgentNotification(
          sellerId,
          userState?.agent?.token,
          "removed",
          `You have successfully removed listing property mls#${listedPropertyMlsId} and disconnect with ${sellerName}`,
          userState?.agent?.name,
          userState?.agent?.email
        );
        if (
          userState?.agent?.preferredContact === "CONTACT_RADIO_BOTH" ||
          userState?.agent?.preferredContact === "CONTACT_RADIO_PHONE"
        ) {
          postMessageToUser(
            userState?.agent?.phone ?? "",
            `You have successfully removed listing property mls#${listedPropertyMlsId}`
          );
        }
        if (
          sellerPreferredContact === "CONTACT_RADIO_BOTH" ||
          sellerPreferredContact === "CONTACT_RADIO_PHONE"
        ) {
          postMessageToUser(
            sellerPhone ?? "",
            `Your Agent ${userState?.agent?.name} removed you from their service list.` ??
            ""
          );
        }
        setCreateNotificationsInfo(String(Math.floor(Math.random() * 100 + 1)));
        setGetNotificationsUserId(sellerId);
        setGetNotificationsUserEmail(userState?.agent?.email);
      }
      notification("Listed Property Removed Successfully!", "success");
      setIsLoading(false);
      showConfirmModalHandler()
      history?.push('/listings');
    } catch (error) {
      setIsLoading(false);
      console.error("ERROR: ", error);
    }
  };

  const updateNotification = async (notificationId: number) => {
    setIsLoading(true);
    try {
      await updateAgentNotification(
        notificationId,
        "read",
        userState.agent.token
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getNotifications = async () => {
    setIsLoading(true);
    const query = history?.location?.pathname?.split("/")?.[2];
    const propertyId = parseInt(query) ?? null;

    try {
      const notifications: any = await getAgentNotifications(
        userState?.agent?.token,
        propertyId,
        sellerEmail
      );
      const filteredNotifications: any = notifications.filter(
        (item: any) => item?.type === "buyerAgent" || item?.type === "seller"
      );

      if (filteredNotifications?.length > 0) {
        setAgentNotifications(filteredNotifications);
      } else {
        setIsNotifications(true);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const checkIsSellerInvited = async (mlsId: any) => {
    const seller: any = await checkIsSellerInvitedService(mlsId);
    setIsSellerInvited(seller);
  };

  const inviteSellerModalHandler = () => {
    setIsShowingInviteSellerModal(!isShowingInviteSellerModal);
  };

  const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size);
  };

  const renderTabs = () => {
    return (
      <div className="dashboard-buyer-page__tabs">
        {isMD ? (
          <>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 24 }}
              layout="horizontal"
              initialValues={{ size: componentSize }}
              onValuesChange={onFormLayoutChange}
              size={componentSize as SizeType}>
              <Form.Item>
                <Select onChange={onTabChange} value={currentTab}>
                  <Option value="overview">Overview</Option>
                  <Option value="propertyDetails">Property Details</Option>
                  <Option value="propertyStatistics">
                    Property Statistics
                  </Option>
                  <Option value="propertyShowing">Previous Showings</Option>
                  <Option value="preferences">Preferences</Option>
                  <Option value="propertyCalendar">Property Calendar</Option>
                  <Option value="notifications">Notifications</Option>
                </Select>
              </Form.Item>
            </Form>
          </>
        ) : (
          <>
            <div
              className={`dashboard-buyer-page__tab ${currentTab === DashboardTab.OVERVIEW &&
                "dashboard-buyer-page__tab--selected"
                }`}
              onClick={selectOverview}>
              <p>Overview</p>
            </div>
            <div
              className={`dashboard-buyer-page__tab ${currentTab === DashboardTab.PROPERTY_DETAILS &&
                "dashboard-buyer-page__tab--selected"
                }`}
              onClick={selectPropertyDetails}>
              <p>Property Details</p>
            </div>
            <div
              className={`dashboard-buyer-page__tab ${currentTab === DashboardTab.PROPERTY_STATISTICS &&
                "dashboard-buyer-page__tab--selected"
                }`}
              onClick={selectPropertyStatistics}>
              <p>Property Statistics</p>
            </div>
            <div
              className={`dashboard-buyer-page__tab ${currentTab === DashboardTab.PROPERTY_SHOWING &&
                "dashboard-buyer-page__tab--selected"
                }`}
              onClick={selectPropertyShowing}>
              <p>Previous Showings</p>
            </div>
            <div
              className={`dashboard-buyer-page__tab ${currentTab === DashboardTab.PREFERENCES &&
                "dashboard-buyer-page__tab--selected"
                }`}
              onClick={selectPreferences}>
              <p>Preferences</p>
            </div>
            <div
              className={`dashboard-buyer-page__tab ${currentTab === DashboardTab.PROPERTY_CALENDAR &&
                "dashboard-buyer-page__tab--selected"
                }`}
              onClick={selectPropertyCalendar}>
              <p>Property Calendar</p>
            </div>
            <div
              className={`dashboard-buyer-page__tab ${currentTab === DashboardTab.NOTIFICATIONS &&
                "dashboard-buyer-page__tab--selected"
                }`}
              onClick={() => {
                selectNotifications("page");
              }}>
              <p>Notifications</p>
            </div>
          </>
        )}
      </div>
    );
  };

  const onTabChange = (value: string) => {
    switch (value) {
      case "overview":
        selectOverview();
        break;
      case "propertyDetails":
        selectPropertyDetails();
        break;
      case "propertyStatistics":
        selectPropertyStatistics();
        break;
      case "propertyShowing":
        selectPropertyShowing();
        break;
      case "preferences":
        selectPreferences();
        break;
      case "propertyCalendar":
        selectPropertyCalendar();
        break;
      case "notifications":
        selectNotifications("page");
        break;
      default:
        return;
    }
  };

  const renderTitle = () => {
    var pageTitle = "";
    switch (currentTab) {
      case DashboardTab?.OVERVIEW:
        pageTitle = "Overview";
        break;
      case DashboardTab?.PROPERTY_DETAILS:
        pageTitle = "Property Details";
        break;
      case DashboardTab?.PROPERTY_STATISTICS:
        pageTitle = "Property Statistics";
        break;
      case DashboardTab?.PROPERTY_SHOWING:
        pageTitle = "Previous Showings";
        break;
      case DashboardTab?.PREFERENCES:
        pageTitle = "Preferences";
        break;
      case DashboardTab?.PROPERTY_CALENDAR:
        pageTitle = "Property Calendar";
        break;
      case DashboardTab?.NOTIFICATIONS:
        pageTitle = "Notifications";
        break;
      default:
        break;
    }
    return (
      
      <div className="dashboard-agent-page_main_div">
        <CustomTitleContainer>
          <div>
            <CustomDashboardTitle>
              {sellerDetail?.name?.length > 0 && pageTitle?.length <= 8
                ? sellerDetail?.name
                : ""}{" "}
              {pageTitle}
            </CustomDashboardTitle>
          </div>
          <CustomTitleContainerRight>

            {currentTab === DashboardTab.OVERVIEW && sellerDetail && (
              <BuyerDetails
                name={sellerDetail?.name}
                email={sellerDetail?.email}
                phone={sellerDetail?.phone}
                imageUrl={sellerDetail?.avatarUrl}
              />
            )}
          </CustomTitleContainerRight>
        </CustomTitleContainer>
      </div>
    );
  };

  const renderOverviewContent = () => {
    return (
      <div className={"dashboard-buyer-page__overview-listing-propertydetail"}>
        <div className="dashboard-buyer-page__overview__cover">
          <div className="dashboard-buyer-page__overview__cover-content">
            <h1>
              Sell this home with <br />
              CONFIDENCE
            </h1>
          </div>
        </div>
        <div className="dashboard-buyer-page__overview__calendar-container">
          {isSellerInvited ? (
            <>
              <h3>
                {sellerDetail?.name?.length > 0
                  ? `${sellerDetail?.name?.split(" ")?.[0]}'s`
                  : ""}{" "}
                Calendar
              </h3>
              <div className="dashboard-buyer-page__overview__calendar-card">
                <MiniCalendar
                  setUpcomingStartEndDate={setUpcomingStartEndDate}
                  month={""}
                />
              </div>
            </>
          ) : (
            <div className="dashboard-buyer-page__overview__calendar-card">
              <p style={{ paddingBottom: "24px" }}>
                To view Seller calendar,
                <br /> Invite Seller now
              </p>
              <Button
                type={ButtonType.PRIMARY}
                text={"INVITE SELLER"}
                clickHandler={inviteSellerModalHandler}
              />
            </div>
          )}
          {/* </Col> */}
        </div>

        <div className="dashboard-buyer-page__overview__upcoming-tours">
          <Upcominghead>Upcoming Showings</Upcominghead>
          <div className="dashboard-buyer-page__overview__upcoming-tours-table">
            <ListingsUpcomingShowings
              tours={tours}
              upcomingTourDate={""}
              upcomingStartEndDate={upcomingStartEndDate}
              type="card"
              userState={userState}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderPropertDetails = () => {
    const mlsId: any = history?.location?.pathname?.split("/")[2];
    return <PropertyDetailsPage mlsId={mlsId} propertyType={""} />;
  };

  const renderPropertyStatistics = () => {
    const mlsId = history.location.pathname.split("/")[2];
    return (
      <StatisticsMain mlsId={Number(mlsId)} token={userState?.agent?.token} />
    );
  };
  const renderPropertyShowing = () => {
    return <ShowingsMain tours={previousTourData} />;
  };
  const renderPreferences = () => {
    return <PrefrencesMain />;
  };
  const renderPropertyCalendar = () => {
    return (
      <CalendarMain
        sellerName={sellerName}
        sellerId={sellerId}
        propMlsId={propMlsId}
      />
    );
  };
  const renderNotifications = () => {
    if (isNotifications)
      return (
        <p style={{ textAlign: "center", marginTop: "30px" }}>
          No Notifications.
        </p>
      );
    else return <NotificationMain agentNotifications={agentNotifications} />;
  };
  const renderTabContent = () => {
    switch (currentTab) {
      case DashboardTab.OVERVIEW:
        return renderOverviewContent();
      case DashboardTab.PROPERTY_DETAILS:
        return renderPropertDetails();
      case DashboardTab.PROPERTY_STATISTICS:
        return renderPropertyStatistics();
      case DashboardTab.PROPERTY_SHOWING:
        return renderPropertyShowing();
      case DashboardTab.PREFERENCES:
        return renderPreferences();
      case DashboardTab.PROPERTY_CALENDAR:
        return renderPropertyCalendar();
      case DashboardTab.NOTIFICATIONS:
        return renderNotifications();
      default:
        return "";
    }
  };
  const selectOverview = () => {
    setCurrentTab(DashboardTab.OVERVIEW);
  };
  const selectPropertyDetails = () => {
    setCurrentTab(DashboardTab.PROPERTY_DETAILS);
    renderPropertDetails();
  };
  const selectPropertyStatistics = () => {
    setCurrentTab(DashboardTab.PROPERTY_STATISTICS);
  };
  const selectPropertyShowing = () => {
    setCurrentTab(DashboardTab.PROPERTY_SHOWING);
  };
  const selectPreferences = () => {
    setCurrentTab(DashboardTab.PREFERENCES);
  };

  const selectPropertyCalendar = () => {
    setCurrentTab(DashboardTab.PROPERTY_CALENDAR);
  };

  const selectNotifications = (value?: string) => {

    if (agentNotifications?.length <= 0) getNotifications();
    setCurrentTab(DashboardTab.NOTIFICATIONS);
  };


  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname.split("/")[2]]);
  useEffect(() => {
  }, [upcomingStartEndDate]);
  return (
    <div className="">
      {isLoading && <CustomSpinner />}
      {activatedPlan.includes(userState?.agent?.subscription_type) && (
      <UserHeader />
      )}
      <CustomBackDiv>
        <Link to="/">
          <CustomSpan>Back</CustomSpan>
        </Link>
      </CustomBackDiv>
      <div className="property-details-agent-page">
        <div style={{ display: "inline-block", width: "100%" }}>{renderTitle()}</div>
        <div className="dashboard-buyer-page__container">
          {renderTabs()}
          <div className="property-details-page__current-tab-content">
            {isPropertyDetails ? (
              <div
                style={
                  isMD
                    ? {
                      width: "100%",
                      textAlign: "center",
                      paddingTop: "20px",
                    }
                    : {
                      width: "100%",
                      textAlign: "center",
                      paddingTop: "50px",
                    }
                }>
                <h2>This property in no longer available!</h2>
                <a onClick={showConfirmModalHandler}>Do you want to remove it?</a>
              </div>
            ) : (
              renderTabContent()
            )}
          </div>
        </div>
        {isShowingInviteSellerModal && (
          <InviteSellerModal
            closeAction={inviteSellerModalHandler}
            isShowing={isShowingInviteSellerModal}
            propertyId={listedPropertyId}
            setHomePage={false}
            mlsId={listedPropertyMlsId}
          />
        )}
        {isShowFindAnAgentConfirmModal && (
        <FindAnAgentConfirmModal
          isShowing={isShowFindAnAgentConfirmModal}
          closeAction={()=> setIsShowFindAnAgentConfirmModal(false)}
          yesClickHandler={unlinkSellerServices}
          text="Are you sure to remove this property and unlink seller?"
          yesText="Yes"
          noText="No"
          agentId={0}
          type="listing property"
          propertyId={0}
          pageType='Listing Property Dashboard'
        />
      )}
      </div>
    </div>
  );
};

export default PropertyDetailsAgentPage;
