import React, { useState } from "react";
import ScheduleBodyFour from "../components/Schedules/ScheduleFour/scheduleBody";
import ScheduleHeaderFour from "../components/Schedules/ScheduleFour/scheduleHeader";
import ScheduleBody from "../components/Schedules/ScheduleOne/scheduleBody";
import ScheduleHeader from "../components/Schedules/ScheduleOne/scheduleHeader";
import ScheduleBodyThree from "../components/Schedules/ScheduleThree/scheduleBody";
import ScheduleHeaderThree from "../components/Schedules/ScheduleThree/scheduleHeader";
import ScheduleBodyTwo from "../components/Schedules/ScheduleTwo/scheduleBody";
import ScheduleHeaderTwo from "../components/Schedules/ScheduleTwo/scheduleHeader";

const HomeSchedule = () => {
  const [isScheduleShowOne, setIsScheduleShowOne] = useState<boolean>(true);
  const [isScheduleShowTwo, setIsScheduleShowTwo] = useState<boolean>(false);
  const [isScheduleShowThree, setIsScheduleShowThree] = useState<boolean>(false);
  const [isScheduleShowFour, setIsScheduleShowFour] = useState<boolean>(false);

  const getScheduleShow = (schedule: string) => {
    switch (schedule) {
      case "scheduleOne":
        setIsScheduleShowOne(true);
        setIsScheduleShowTwo(false);
        setIsScheduleShowThree(false);
        setIsScheduleShowFour(false);
        break;
      case "scheduleTwo":
        setIsScheduleShowOne(false);
        setIsScheduleShowTwo(true);
        setIsScheduleShowThree(false);
        setIsScheduleShowFour(false);
        break;
      case "scheduleThree":
        setIsScheduleShowOne(false);
        setIsScheduleShowTwo(false);
        setIsScheduleShowThree(true);
        setIsScheduleShowFour(false);
        break;
      case "scheduleFour":
        setIsScheduleShowOne(false);
        setIsScheduleShowTwo(false);
        setIsScheduleShowThree(false);
        setIsScheduleShowFour(true);
        break;
      default:
        setIsScheduleShowOne(true);
        setIsScheduleShowTwo(false);
        setIsScheduleShowThree(false);
        setIsScheduleShowFour(false);
        break;
    }
  };
  return (
    <>
      {isScheduleShowOne && (
        <>
          <ScheduleHeader getScheduleShow={getScheduleShow} />
          <ScheduleBody />
        </>
      )}
      {isScheduleShowTwo && (
        <>
          <ScheduleHeaderTwo getScheduleShow={getScheduleShow} />
          <ScheduleBodyTwo />
        </>
      )}
      {isScheduleShowThree && (
        <>
          <ScheduleHeaderThree getScheduleShow={getScheduleShow} />
          <ScheduleBodyThree />
        </>
      )}
      {isScheduleShowFour && (
        <>
          <ScheduleHeaderFour getScheduleShow={getScheduleShow} />
          <ScheduleBodyFour />
        </>
      )}
    </>
  );
};

export default HomeSchedule;
