import React, { useEffect, useState, useContext } from 'react';
import ResetPasswordModal from '../modals/ResetPasswordModal';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import UserContext from '../contexts/UserContext';
import PropertyFiltersContext from '../contexts/PropertyFiltersContext';
const CustomDiv = styled.div`
  padding: 50px;
`;

const CustomHeading = styled.h1`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #c2cc30;
  }
`;

const ResetPasswordPage = () => {
  const [isShowingResetPasswordModal, setIsShowingResetPasswordModal] =
    useState<boolean>(true);

  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [userType, setUserType] = useState<string>('');

  const { logOut, userState } = useContext(UserContext);
  const {clearFilters } = useContext(PropertyFiltersContext);

  const resetHandler = () => {
    setIsShowingResetPasswordModal(!isShowingResetPasswordModal);
  };
  useEffect(() => {
    const query: any = history?.location?.hash?.split('=') ?? '';
    setEmail(query?.[1]);
    setUserType(query?.[3]);
    if (userState?.isLoggedIn) {
      clearFilters();
      logOut()
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history?.location?.search]);
  return (
    <CustomDiv>
      <CustomHeading onClick={resetHandler}>Click To Reset!</CustomHeading>
      {isShowingResetPasswordModal && (
        <ResetPasswordModal
          userEmail={email}
          userType={userType}
          closeAction={resetHandler}
          isShowing={isShowingResetPasswordModal}
        />
      )}
    </CustomDiv>
  );
};
export default ResetPasswordPage;
