import styled from "@emotion/styled";
import React from "react";
import { useHistory } from "react-router-dom";
import Button, { ButtonType } from "../../components/Button";

interface NotificationsAboutAgentCardPropsModel {
  buyerId: number;
  propertyId: number;
  notificationId: number;
  borderColor: string;
  notification: string;
  time: string | [string, number?];
  isAcceptAndRejectButton: boolean;
  isNotificationButton: boolean;
  borderButtomColor: string;
  //   didAccept?: (notificationId: number, buyerId: number) => void;
  //   didReject?: (notificationId: number, buyerId: number) => void;
  isFavorite: boolean;
  isRequested: boolean;
  isComment: boolean;
  isRead: boolean;
  selectFavoriteProperties: any;
  selectRequestedShowings: any;
  selectPropertyComments: any;
}

const CutomNotificationsAgentContent = styled.div<{
  borderColor: string;
  borderButtomColor: string;
}>`
  border-left: 0px solid ${(p) => p.borderColor};
  border-bottom: 2px solid ${(p) => p.borderButtomColor};
  padding: 22px 2px 22px 36px;
  display: flex;
  margin-top: 12px;
  justify-content: space-between;
  cursor: pointer;
  @media (max-width: 576px) {
    flex-direction: column;
    padding: 18px 12px 12px 12px;
  }
`;

const NotificationsAboutAgentCard: React.FC<
  NotificationsAboutAgentCardPropsModel
> = ({
  buyerId,
  borderColor,
  notification,
  time,
  isAcceptAndRejectButton,
  isNotificationButton,
  borderButtomColor,
  isFavorite,
  isRequested,
  isComment,
  isRead,
  selectFavoriteProperties,
  selectRequestedShowings,
  propertyId
}) => {
    let history = useHistory();

    let goToProperty = (notification: string, buyerId: number) => {

      history.push(`/properties/${propertyId}?id=${buyerId}`);
      // let isPropertyIdExist = notification
      //   .split(" ")
      //   .filter((ntf) => ntf[0] === "#");
      // if (isPropertyIdExist.length > 0) {
      //   var result: any;
      //   if (isPropertyIdExist[0].charAt(isPropertyIdExist[0].length - 1) == ".") {
      //     result = isPropertyIdExist[0].slice(1, -1);
      //   } else {
      //     result = isPropertyIdExist[0].slice(1);
      //   }
      //   if(propertyId > 0){

      //   history.push(`/properties/${propertyId}?id=${buyerId}`);
      //   }
      //   else{
      //   history.push(`/properties/${result}?id=${buyerId}`);
      //   }
      // }
    };
    return (
      <>
        <CutomNotificationsAgentContent
          borderColor={borderColor}
          borderButtomColor={borderButtomColor}
        >
          <div className="notifications-agent-content-heading">
            {isRead ? (
              <h4>{notification}</h4>
            ) : (
              <h4 style={{ fontWeight: "bold", color: "black" }}>
                {notification}
              </h4>
            )}
            <p>{time}</p>
          </div>
          {isAcceptAndRejectButton && (
            <div className="notifications-agent-content-button-main">
              <Button
                type={ButtonType.WHITE}
                text={"REJECT"}
                clickHandler={() => {
                  // didReject(notificationId, buyerId);
                }}
                className="notifications-agent-content-button notifications-agent-content-button-reject"
              />
              <Button
                type={ButtonType.PRIMARY}
                text={"ACCEPT"}
                clickHandler={() => {
                  // didAccept(notificationId, buyerId);
                }}
                className="notifications-agent-content-button notifications-agent-content-button-accept"
              />
            </div>
          )}

          {isNotificationButton && (
            <Button
              type={ButtonType.PRIMARY}
              text={"NOTIFICATIONS"}
              clickHandler={() => { }}
              className="notifications-agent-content-button"
            />
          )}
          {isFavorite && (
            <Button
              type={ButtonType.PRIMARY}
              text={"Favorite Properties"}
              clickHandler={() => {
                selectFavoriteProperties();
              }}
              className="notifications-agent-content-button"
            />
          )}
          {isRequested && (
            <Button
              type={ButtonType.PRIMARY}
              text={"Requested Showings"}
              clickHandler={() => {
                selectRequestedShowings();
              }}
              className="notifications-agent-content-button"
            />
          )}
          {isComment && (
            <Button
              type={ButtonType.PRIMARY}
              text={"View Comment"}
              clickHandler={() => {
                // selectPropertyComments();
                goToProperty(notification, buyerId);
              }}
              className="notifications-agent-content-button"
            />
          )}
        </CutomNotificationsAgentContent>
      </>
    );
  };

export default NotificationsAboutAgentCard;

// import Button, { ButtonType } from "./Button";
// import bannerAgent from '../assets/images/banner-agent.svg';
// import avatarAgentTemplate from '../assets/images/avatar-agent-template.svg';
// import iconEmail from '../assets/images/icon-email.svg';
// import iconPhone from '../assets/images/icon-phone.svg';
// import iconWebsite from '../assets/images/icon-website.svg';
// import { Fragment, useContext, useEffect, useState } from "react";
// import { acceptBuyerInvitationService, getAgentInfoService, rejectBuyerInvitationService, Agent } from "../services/NavihomeService";
// import UserContext from "../contexts/UserContext";

// interface NotificationBuyerInvitationProps {
//   agentId: number,
//   didAccept: () => void;
//   didReject: () => void;
// }

// const NotificationBuyerInvitation: React.FC<NotificationBuyerInvitationProps> = ({ agentId, didAccept, didReject }) => {
//   const { userState } = useContext(UserContext);
//   const [agent, setAgent] = useState<Agent>(null);

//   const getAgentInfo = async () => {
//     const agent = await getAgentInfoService(userState.buyer.token, agentId);
//     setAgent(agent);
//   }

//   useEffect(() => {
//     getAgentInfo();
//   }, []);

//   const rejectInvitation = async () => {
//     await rejectBuyerInvitationService(userState.buyer.token, agentId);
//     didReject();
//   }

//   const acceptInvitation = async () => {
//     await acceptBuyerInvitationService(userState.buyer.token, agentId);
//     didAccept();
//   }

//   const renderNotification = () => {
//     return (
//       <Fragment>
//         <div className="notification-buyer-invitation__info">
//           <p>{`Agent ${agent.name} of ${agent.company} has sent you a request to connect with you. Please accept the request if you wish to coordinate calendars, schedule tours with John Doe.`}</p>
//         </div>
//         <div className="notification-buyer-invitation__buttons">
//           <Button
//             text="ACCEPT INVITATION"
//             type={ButtonType.PRIMARY}
//             clickHandler={acceptInvitation}
//             className="notification-buyer-invitation__button--accept"
//           />
//           <Button
//             text="REJECT INVITATION"
//             type={ButtonType.WHITE}
//             clickHandler={rejectInvitation}
//           />
//         </div>
//         <div className="notification-buyer-invitation__agent-info">
//           <div className="notification-buyer-invitation__agent-info__banner">
//             <img src={bannerAgent} alt="Banner Agent" />
//           </div>
//           <div className="notification-buyer-invitation__agent-info__avatar">
//             <img src={avatarAgentTemplate} alt="Avatar Agent" />
//           </div>
//           <div className="notification-buyer-invitation__agent-info__text-info">
//             <p className="notification-buyer-invitation__agent-info__text-info__name">{agent.name}</p>
//             <div>
//               <img src={iconEmail} alt="Email icon" />
//               <p>{agent.email}</p>
//             </div>
//             <div>
//               <img src={iconPhone} alt="Phone icon" />
//               <p>{agent.phone}</p>
//             </div>
//             <div>
//               <img src={iconWebsite} alt="Website icon" />
//               <p>{""}</p>
//             </div>
//           </div>
//         </div>
//       </Fragment>
//     );
//   }

//   return (
//     <div className="notification-buyer-invitation">
//       {agent && renderNotification()}
//     </div>
//   );
// };

// export default NotificationBuyerInvitation;
