import styled from '@emotion/styled';
import validator from 'email-validator';
import { useContext, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import buttonClose from '../assets/images/button-close.svg';
import Button, { ButtonType } from '../components/Button';
import { notification } from '../components/notification';
import CustomSpinner from '../components/spinner/CustomSpinner';
import TextField, {
  TextFieldIconType,
  TextFieldType
} from '../components/TextField';
import PropertyFiltersContext from '../contexts/PropertyFiltersContext';
import UserContext, { UserType } from '../contexts/UserContext';
import {
  getAgentAccountSettings,
  getAgentInforEmailService,
  inviteAgentByBuyer,
  inviteAgentByBuyerServices,
  inviteAgentService,
  postMessageToUser
} from '../services/NavihomeService';

interface InviteAgentModalProps {
  isShowing: boolean;
  closeAction: () => void;
  inviteYourAgent?: boolean;
}

const CustomModalTitle = styled.h2`
  color: #004876;
  font-weight: bold;
  margin: 0px;
`;

const InviteAgentModal: React.FC<InviteAgentModalProps> = ({
  isShowing,
  closeAction,
  inviteYourAgent,
}) => {
  const { userState } = useContext(UserContext);
  const { setCreateNotificationsInfo, setGetNotificationsUserId } = useContext(
    PropertyFiltersContext
  );
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const history = useHistory();

  const inviteAgent = async () => {
    if (!name) return notification('Please Enter Name!', 'error');
    if (!email) return notification('Please Enter Email!', 'error');
    if(!message) return notification('Please Enter Message!', 'error')
    if (!validator.validate(email)) {
      return notification('Please Enter Valid Email.', 'error');
    }
    setIsLoading(true);

    try {
      const response = await inviteAgentService(
        name,
        email,
        message,
        userState?.agent.token
      );
      if (!response) {
        setIsLoading(false);

        return notification('This agent is already registered.', 'error');
      }
      setIsLoading(false);
      closeAction();
      return notification('Agent Invited Successfully!', 'success');
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  let connectAgent = async (name: string, email: string, message: string) => {
    try {
      setIsLoading(true);
      const success: any = await inviteAgentByBuyer(
        0,
        'invite',
        message,
        0,
        name,
        email,
        userState?.buyer?.buyerId
      );
      if (success === '') {
        if (
          userState?.buyer?.preferredContact === 'CONTACT_RADIO_BOTH' ||
          userState?.buyer?.preferredContact === 'CONTACT_RADIO_PHONE'
        ) {
          // TO DO
          // postMessageToUser(
          //   formatPhoneNumberInToE164(agentPhone) ?? '',
          //   message ?? ''
          // );
        }
        setIsLoading(false);
        return notification(`Request sent successfully.`, 'success');
      } else {
        console.log(success);
        setIsLoading(false);
        return notification('An error occcured', 'error');
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return notification(`${error}`, 'error');
    }
  };
  let sendNotificationToAgent = async () => {
    const token = userState?.agent?.token;
    setIsLoading(true);
    try {
      // const result: any = await createAgentNotificationByMail(
      //   email,
      //   `${userState?.agent?.name.toUpperCase()} wants to connect with you. Accept the request to connect with agent.`,
      //   "invite",
      //   token
      // );
      // if (result?.error) {
      //   setIsLoading(false);
      //   return notification(result?.message, "error");
      // }
      // setIsLoading(false);
      // closeAction();
      return;
    // eslint-disable-next-line no-unreachable
    } catch (error) {
      setIsLoading(false);
    }
  };

  let inviteAgentByBuyerService = async (
    name: string,
    email: string,
    BuyerMessage: string
  ) => {
    if (!name) return notification('Please Enter Name!', 'error');
    if (!email) return notification('Please Enter Email!', 'error');
    if (!message) return notification('Please Enter Message!', 'error');
    if (!validator.validate(email)) {
      return notification('Please Enter Valid Email!', 'error');
    }
    setIsLoading(true);
    try {
      
      const agent: any = await getAgentInforEmailService(email);
      if (!agent) {
        const success: any = await inviteAgentByBuyerServices(
          name,
          email,
          BuyerMessage,
          userState?.buyer?.token,
          userState?.buyer?.name,
        );
        if (success.success) {
          setIsLoading(false);
          closeAction();
          return notification(success.message, 'success');
          // connectAgent()
        }
        setIsLoading(false);
        closeAction();
        // return notification('Agent not found with this email.', 'error');
      }

      const agentId = agent?.agentId;
      const phone = agent?.phone;
      let messageType: string = 'No';
      const preferredContact: string = agent?.preferredContact;
      const agentAccountSettingsResult: any = await getAgentAccountSettings(agentId);
      if (agentAccountSettingsResult?.length > 0) {
        const lastIndex: number = agentAccountSettingsResult?.length - 1;
        messageType = agentAccountSettingsResult?.[lastIndex]?.notifyinviteSaleAgent;
      }

      const result: any = await inviteAgentByBuyer(
        agentId,
        'invite',
        BuyerMessage,
        0,
        name,
        email,
        userState?.buyer?.buyerId,
        (preferredContact === 'CONTACT_RADIO_BOTH' || preferredContact === 'CONTACT_RADIO_EMAIL') ? messageType ?? "Not Real Time" : "Not Real Time"
      );
      if (!result?.error) {
        if (messageType === "Realtime" && (
         preferredContact === 'CONTACT_RADIO_BOTH' ||
         preferredContact === 'CONTACT_RADIO_PHONE')
        ) {
          postMessageToUser(
            phone,
            `Hello, I love using this app! I think you should get on board to make your life simpler. ${userState?.buyer?.name.toUpperCase()} wants to connect with you. Accept the request to connect with agent.`
          );
        }
        setCreateNotificationsInfo(String(Math.floor(Math.random() * 100 + 1)));
        setGetNotificationsUserId(agentId);
        setIsLoading(false);
        closeAction();
        // sendNotificationToAgent()
        notification(`Request sent successfully.`, 'success');
        history.push('/buy');
        return;
      } else {
        setIsLoading(false);
        return notification('An error occcured', 'error');
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return notification(`${error}`, 'error');
    }
  };
  const modalClassName = isShowing
    ? 'invite-agent-modal u-visible'
    : 'invite-agent-modal u-hidden';
  const cardClassName = isShowing
    ? 'invite-agent-modal__card animation__pop-in'
    : 'invite-agent-modal__card animation__pop-out';
  const isMobile = useMediaQuery({ maxWidth: 500 });

  return (
    <div className={modalClassName}>
      <div className={cardClassName}>
        {isLoading && <CustomSpinner />}
        <div className="invite-agent-modal__row">
          {inviteYourAgent ? (
            <CustomModalTitle>
              Invite your
              <span style={{ borderBottom: '3px solid #C2D500' }}> agent</span>
            </CustomModalTitle>
          ) : (
            <CustomModalTitle>
              Invite new
              <span style={{ borderBottom: '3px solid #C2D500' }}> agent</span>
            </CustomModalTitle>
          )}

          <img
            className="invite-agent-modal__close-button"
            src={buttonClose}
            onClick={closeAction}
            alt="Close button"
          />
        </div>
        {/* <p className="invite-agent-modal__subtitle">Get your real estate friends on board too!</p> */}
        <div className="invite-agent-modal__column">
          <TextField
            value={name}
            setValue={setName}
            placeholder="Jane Doe"
            label="Name"
            type={TextFieldType.ICON_AND_LABEL}
            icon={TextFieldIconType.USER}
            isRequired={true}
            width={isMobile ? undefined : 38.4}
            className="invite-agent-modal__name-field"
          />
          <TextField
            value={email}
            setValue={setEmail}
            placeholder="janedoe@hotmail.com"
            label="Email"
            type={TextFieldType.ICON_AND_LABEL}
            icon={TextFieldIconType.EMAIL}
            isRequired={true}
            width={isMobile ? undefined : 38.4}
            className="invite-agent-modal__email-field"
          />
          <TextField
            value={message}
            setValue={setMessage}
            placeholder="Hello, I love using this app! I think you should get on board to make your life simpler."
            label="Invite message"
            type={TextFieldType.BIG}
            icon={TextFieldIconType.EMAIL}
            isRequired={true}
            width={isMobile ? undefined : 38.4}
            className="invite-agent-modal__message-field"
          />
          <Button
            type={ButtonType.PRIMARY}
            text="SEND INVITE"
            clickHandler={() => {
              if (userState?.userType === UserType?.BUYER) {
                inviteAgentByBuyerService(
                  name,
                  email,
                  `${message?.length >  0 ? `${message}.` :''} ${userState?.buyer?.name.toUpperCase()} wants to connect with you. Accept the request to help them buy their next home`
                );
              }
              if (userState?.userType === UserType?.AGENT) inviteAgent();
            }}
            width={isMobile ? undefined : 18.5}
            className="invite-agent-modal__button"
          />
        </div>
      </div>
    </div>
  );
};

export default InviteAgentModal;
