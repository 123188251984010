import styled from '@emotion/styled';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button, { ButtonType } from '../../components/Button';
import { notification } from '../../components/notification';
import AgentContext from '../../contexts/AgentContext';
import {
  checkIfBuyerIsLinkedWithAgent,
  getBuyerInfoService,
} from '../../services/NavihomeService';
import UserContext from '../../contexts/UserContext';
import { io, Socket } from 'socket.io-client';

interface NotificationsAgentCardPropsModel {
  buyerId: number;
  notificationId: number;
  borderColor: string;
  agentNotification: string;
  time: string | [string, number?];
  isAcceptAndRejectButton: boolean;
  didAccept: (notificationId: number, buyerId: number) => void;
  didReject: (notificationId: number, buyerId: number) => void;
  notfGroup: any;
  isNotificationButton?: boolean;
  borderButtomColor?: string;
  type?: string;
  propertyId?: number;
}

const CutomNotificationsAgentContent = styled.div<{
  borderColor: string;
  borderButtomColor: string;
}>`
  border-left: 14px solid ${(p) => p.borderColor};
  border-bottom: 2px solid ${(p) => p.borderButtomColor};
  padding: 22px 2px 22px 36px;
  display: flex;
  margin-top: 12px;
  cursor: pointer;
  justify-content: space-between;
  @media (max-width: 576px) {
    flex-direction: column;
    padding: 18px 12px 12px 12px;
  }
`;
const CustomPropH1 = styled.h1<{
  borderColor: string;
  borderButtomColor: string;
}>`
  border-left: 14px solid ${(p) => p.borderColor};
  border-bottom: 2px solid ${(p) => p.borderButtomColor};
  @media (max-width: 820px) {
  }
`;
const NotificationsAgentCard: React.FC<NotificationsAgentCardPropsModel> = ({
  buyerId,
  notificationId,
  borderColor,
  agentNotification,
  time,
  type,
  propertyId,
  isAcceptAndRejectButton,
  didAccept,
  didReject,
  notfGroup,
  isNotificationButton,
  borderButtomColor,
}) => {
  let history = useHistory();
  const [socket, setSocket] = useState<Socket>();
  const { userState } = useContext(UserContext);
  const { selectBuyer } = useContext(AgentContext);
  return (
    <>
      <CutomNotificationsAgentContent
        borderColor={borderColor}
        borderButtomColor={borderButtomColor}
        onClick={() => {
          // goToProperty(agentNotification, buyerId);
        }}
      >
        <div className="notifications-agent-content-heading">
          <h4>{agentNotification}</h4>
          <p>{time}</p>
        </div>
        {isAcceptAndRejectButton && (
          <div className="notifications-agent-content-button-main">
            <Button
              type={ButtonType.WHITE}
              text={'REJECT'}
              clickHandler={() => {
                didReject(notificationId, buyerId);
              }}
              className="notifications-agent-content-button notifications-agent-content-button-reject"
            />
            <Button
              type={ButtonType.PRIMARY}
              text={'ACCEPT'}
              clickHandler={() => {
                didAccept(notificationId, buyerId);
                // recieve();
              }}
              className="notifications-agent-content-button notifications-agent-content-button-accept"
            />
          </div>
        )}
        {isNotificationButton && (
          <Button
            type={ButtonType.PRIMARY}
            text={'NOTIFICATIONS'}
            clickHandler={async () => {
              // handleNotificationUpdate();

              if (type === 'buyerAgent' || type === 'seller') {
                history.push({
                  pathname: `/propertydetails/${propertyId}`,
                  search: `?buyerId=${buyerId}`,
                  state: { notificationArr: notfGroup },
                });
                return;
              }

              const token = userState?.agent?.token;
              const buyer: any = await getBuyerInfoService(token, buyerId);
              selectBuyer(buyerId, buyer?.name, buyer?.phone, buyer?.email, buyer?.preferredContact);

              if (buyer?.agentName !== undefined) {
                history.push({
                  pathname: `/dashboard`,
                  search: `?buyerId=${buyerId}&name=${buyer?.name}`,
                  state: { notificationArr: notfGroup },
                });
              } else {
                return notification(
                  'Buyer not connected with your profile anymore',
                  'error'
                );
              }

              // history.push("/dashboard?buyerId=" + buyerId);
            }}
            className="notifications-agent-content-button"
          />
        )}
      </CutomNotificationsAgentContent>
    </>
  );
};

export default NotificationsAgentCard;
