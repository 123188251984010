import styled from "@emotion/styled";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid"; // a plugin!
import { Button, Popover, Select } from "antd";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import UserContext from "../../contexts/UserContext";
import CalendarModal from "../../modals/CalendarModal";
import { agentCalendarChangeView, getAgentAccountSettings, patchCalendarView, updateBestRoutePropertyCalendar, updateViewPropertyCalendar } from "../../services/NavihomeService";
import { requestedProperty } from "../AgentCalendar";
import CustomSpinner from "../spinner/CustomSpinner";
import CalendarSchedulePopover from "./calendarPopovers/calendarSchdeulePopover";
import RescheduleModel from "./calendarPopovers/rescheduleModel";
import "./styles/agentCalendar.css";
const { Option } = Select;

interface IAgentCalendarProps {
  myClients: Array<{
    name: string;
    color: string;
    buyerId: number;
    email: string;
    imageUrl: string;
    phone: number;
  }>;
  view: string;
  setView: (value: string) => void;
  agentAccount: any;
  setAgentAccountSettings: (value: any) => void;
  offCalDays: any;
  setOffCalDays: (value: any) => void;
  offCalHours: any;
  setOffCalHours: (value: any) => void;
  viewPropertyReqEvents: Array<any>;
  requestedProperty: requestedProperty[];
  loadBuyers: () => void;
  upCommingTours: string[];
  dateValue?: string | undefined;
  clickedDay?: string | undefined;
  calResponse?: any;
  setCurrentView: any;
  currentMonth: boolean;
  setStartToEnd: any;
  setMonthName?: any;
  monthName?: any;
  setNextMonthFromMain?: (val: any) => void;
  setPrevMonthFromMain?: (val: any) => void;
  getAllViewpropertyCalendar?: () => void;
  setMonth?: (val: any) => void;
  month?: any;
}
export interface rescheduleModel {
  open: boolean;
  buyerId: number | undefined;
  token: string | undefined;
  propertyId: number | undefined;
}

export const ScrollDiv = styled.div`
  .fc {
    height: 80vh;
    width: 100%;
    overflow-y: auto;
  }
  .fc-daygrid-day-events{
    // background:#FADAFD;
    padding:2px 2px 6px 2px;
    // border-radius:12px;
  }
  .fc-daygrid-event-harness{
    padding:6px 0;
  }
  .fc-daygrid-event{
    background:white;
     border: none;
  }
 
  
  .fc-event-end 
`;

const AgentCalendarMain: React.FunctionComponent<IAgentCalendarProps> = ({
  myClients,
  loadBuyers,
  dateValue,
  clickedDay,
  setCurrentView,
  setStartToEnd,
  viewPropertyReqEvents,
  getAllViewpropertyCalendar,
  setNextMonthFromMain,
  setPrevMonthFromMain,
  setMonth,
  month,
  view,
  setView,
  agentAccount,
  offCalHours
}) => {
  const { userState } = useContext(UserContext);
  
  const [isShowingCalendarModal, setIsShowingCalendarModal] = useState<boolean>(false);
  const [recievedArg, setRecievedArg] = useState<any>([]);
  const [scheduledEvents, setScheduledEvents] = useState([]);
  const [isRescheduleRequestEvent, setIsRescheduleRequestEvent] = useState<boolean>(false);
  const [isViewChanged, setIsViewChanged] = useState<boolean>(false);
  const [eventFilter, setEventFilter] = useState([]);
  let calendarRef = useRef(null);

  let [type, setType] = useState("all");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modelData, setModalData] = useState<rescheduleModel>({
    open: false,
    buyerId: undefined,
    token: undefined,
    propertyId: undefined
  });
  const isCalentitle = useMediaQuery({ maxWidth: 652 });
  useEffect(() => {
    calendarRef?.current?.getApi()?.changeView(view);
  }, [view]);

  let changeView = async (value: string) => {
    setIsViewChanged(true);
    switch (value) {
      case "timeGridDay":
        calendarRef?.current?.getApi()?.changeView("timeGridDay");
        break;
      case "timeGridWeek":
        calendarRef?.current?.getApi()?.changeView("timeGridWeek");
        break;
      case "dayGridMonth":
        calendarRef?.current?.getApi()?.changeView("dayGridMonth");
        break;
    }
    setView(value);
    let accountsettingsfromdb = await getAgentAccountSettings(userState?.agent?.agentId);
    if (Object?.keys(accountsettingsfromdb)?.length > 0) {
      await patchCalendarView(userState?.agent?.token, userState?.agent?.agentId, value);
      setCurrentView(value);
    } else {
      await agentCalendarChangeView(userState?.agent?.token, userState?.agent?.agentId, value, {});
      setCurrentView(value);
    }
    calendarRef?.current?.getApi()?.gotoDate(new Date(clickedDay).toISOString());
    setCurrentView(value);
  };

  let changeValue = async () => {
    let nextMonth: any = await calendarRef?.current?.getApi()?.view?.title;
    let nextMonthForWeek: any = await calendarRef?.current?.getApi()?.view?.activeEnd;
    switch (calendarRef?.current?.getApi()?.view?.type) {
      case "timeGridWeek":
        setMonth(new Date(nextMonthForWeek));
        break;
      case "dayGridMonth":
        /* Don't remove the comment
        const month = nextMonth.substring(0, nextMonth.indexOf(' ')); // "72"
        const year = nextMonth.substring(nextMonth.indexOf(' ') + 1);
        const today = new Date().getDate();
        const date = (
          today +
          '/' +
          moment().month(month).format('M') +
          '/' +
          year
        ).toString();
        const newDate = moment(date).format('LL');
        setMonth(new Date(newDate));
        */
        break;
      case "timeGridDay":
        setMonth(new Date(nextMonth));
        break;
    }
  };

  let dayChange = (value: string) => {
    switch (value) {
      case "next":
        calendarRef?.current?.getApi()?.next();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let nextMonth: any = calendarRef?.current?.getApi()?.view?.title;
        setNextMonthFromMain(true);
        break;
      case "prev":
        calendarRef?.current?.getApi()?.prev();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let prevMonth = calendarRef?.current?.getApi()?.view?.title;
        setPrevMonthFromMain(true);
        break;
      case "Today":
        calendarRef?.current?.getApi()?.today();
        calendarRef?.current?.getApi()?.changeView("timeGridDay");
        setView("timeGridDay");
    }
  };

  let filterEvents = async (value: string) => {
    switch (value) {
      case "all":
        // setEvents(events);
        break;
      case "listing":
        // setIsLoading(true);
        try {
          const filteredListings = viewPropertyReqEvents?.filter((item: any) => item?.agentType?.toLowerCase() === "buyeragent");
          setEventFilter(filteredListings?.length > 0 ? filteredListings : []);
          // setIsLoading(false);
        } catch (error) {
          console.error("Error: ", error);
          setIsLoading(false);
        }
        break;
      case "sale":
        // setIsLoading(true);
        try {
          const filteredSale = viewPropertyReqEvents?.filter((item: any) => item?.agentType?.toLowerCase() === "listingagent");
          setScheduledEvents(filteredSale?.length > 0 ? filteredSale : []);
          // setIsLoading(false);
        } catch (error) {
          console.log("Error", error);
          setIsLoading(false);
        }
        break;
    }
    // setIsLoading(false);
    setType(value);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [recurrenceModalVisible, setRecurrenceModalVisible] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [recurrenceModalData, setRecurrenceModalData] = useState({
    start: "",
    end: "",
    title: ""
  });

  let eventClickHandler = (info: any) => {
    switch (info?.event?._def.publicId) {
      case "blank":
        setRecurrenceModalData((data) => ({
          ...data,
          title: info.event._def.title
        }));
        setRecurrenceModalVisible(true);
        break;
    }
  };

  const calanderModalHandler = () => {
    setIsShowingCalendarModal(!isShowingCalendarModal);
  };

  const closeAction = () => {
    setModalData({
      open: false,
      buyerId: undefined,
      propertyId: undefined,
      token: undefined
    });
  };

  const getModalData = async (
    values: any,
    customStartDate?: any,
    customEndDate?: any,
    type?: string,
    eventId?: number,
    agentId?: number,
    buyerId?: number,
    updateSender?: string,
    eventType?: string
  ) => {
    const SDate: any = moment(values?.StartDate).format("MM/DD/YYYY");
    const STime: any = moment(values?.StartTime).format("HH:mm:ss A");
    const Edate: any = moment(values?.EndDate).format("MM/DD/YYYY");
    const Etime: any = moment(values?.EndTime).format("HH:mm:ss A");
    const Description: string = values?.Description;
    const Rstatus: string = values?.RecurringStatus;
    const recStartDate: any = moment(customStartDate).format("MM/DD/YYYY");
    const recEndDate: any = moment(customEndDate).format("MM/DD/YYYY");

    let putPayload: any = {};
    try {
      setIsLoading(true);
      let response: any;
      if (eventType === "Scheduled Property") {
        putPayload = {
          bestRouteId: eventId,
          agentId: agentId > 0 ? agentId : userState.agent.agentId,
          startDate: SDate,
          endDate: Edate,
          startTime: STime,
          endTime: Etime,
          description: Description,
          sender: updateSender
        };
        response = await updateBestRoutePropertyCalendar(putPayload, userState?.agent?.token);
      } else {
        putPayload = {
          viewPropertyCalendarId: eventId,
          agentId: agentId > 0 ? agentId : userState.agent.agentId,
          startDate: SDate,
          endDate: Edate,
          startTime: STime,
          endTime: Etime,
          recuringStatus: Rstatus,
          description: Description,
          recuringStartDate: recStartDate,
          recuringEndDate: recEndDate,
          sender: updateSender
        };
        response = await updateViewPropertyCalendar(putPayload, userState?.agent?.token);
      }

      if (response) {
        setIsLoading(false);
        getAllViewpropertyCalendar();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console?.log("error", error);
    }
  };

  const getDayName = (dayIndex: number) => {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return days[dayIndex];
  };

  useEffect(() => {
    setPrevMonthFromMain(false);
    setNextMonthFromMain(false);
    if (dateValue) {
      if (clickedDay) {
        calendarRef?.current?.getApi()?.gotoDate(new Date(clickedDay).toISOString());
      } else {
        calendarRef?.current?.getApi()?.gotoDate(new Date(dateValue).toISOString());
      }
    }
  }, [dateValue, setPrevMonthFromMain, setNextMonthFromMain, isViewChanged, clickedDay]);

  const scheduleEditEventModalHandler = () => {
    setIsRescheduleRequestEvent(!isRescheduleRequestEvent);
  };

  let isOffHours = (date: Date) => {
    let day = date?.toDateString()?.split(" ")?.[0];
    if (agentAccount?.markWeekends?.includes(day)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {isLoading && <CustomSpinner />}
      <div className="calendarButtons">
        <div className="calendarButtonLeft">
          <Button
            className="viewButton"
            onClick={() => {
              setStartToEnd(false);
              dayChange("prev");
              changeValue();
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
          </Button>
          <Button
            className="viewButton"
            onClick={() => {
              setStartToEnd(false);
              dayChange("next");
              changeValue();
            }}
          >
            <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
          </Button>
          <Button
            className="viewButton"
            onClick={() => {
              setStartToEnd(false);
              dayChange("Today");
              changeValue();
            }}
          >
            Today
          </Button>
        </div>
        <div></div>
        <div className="calendarButtonRight">
          <Select className="typeSelect" value={type} onChange={(e) => filterEvents(e)}>
            <Option value="all">All</Option>
            <Option value="listing">Listing</Option>
            <Option value="sale">Sale</Option>
          </Select>
          <Select className="viewSelect" value={view} onChange={(e) => changeView(e)}>
            <Option value="timeGridWeek">Week</Option>
            <Option value="timeGridDay">Day</Option>
            <Option value="dayGridMonth">Month</Option>
          </Select>
        </div>
      </div>
      {view ? (
        <ScrollDiv>
          <FullCalendar
            height={"auto"}
            plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
            allDaySlot={false}
            eventDisplay="block"
            initialView={view.toString()}
            initialDate={month}
            nowIndicator={true}
            editable={false}
            all-day={false}
            droppable={true}
            eventStartEditable={false}
            eventDurationEditable={true}
            weekends={true}
            displayEventTime={true}
            eventOverlap={false}
            handleWindowResize={true}
            events={type !== "sale" ? (type !== "all" ? eventFilter : viewPropertyReqEvents) : type === "sale" && scheduledEvents}
            selectable={false}
            eventMaxStack={3}
            selectMirror={true}
            moreLinkClick="month"
            dayMaxEvents={true}
            headerToolbar={{
              left: null,
              center: isCalentitle ? null : "title",
              right: null
            }}
            eventClick={(info) => {
              eventClickHandler(info);
            }}
            eventContent={(arg: any) => {
              const showingType: string = arg?.event?._def?.extendedProps?.showingType?.length > 0 ? arg?.event?._def?.extendedProps?.showingType : "Property Scheduled";
              let showingTypeTitle: string = "PS",
                buyerAgentName = arg?.event?._def?.extendedProps?.buyerAgentName?.split(" ")?.[0];
              if (showingType === "Lockbox") {
                showingTypeTitle = "LB";
              } else if (showingType === "Agent accompained") {
                showingTypeTitle = "AA";
              }
              return (
                <>
                  {arg?.event?.title === "View Property" ? (
                    <Popover
                      trigger="hover"
                      overlayInnerStyle={{
                        border: "1.4px solid #004876",
                        borderRadius: "10px"
                      }}
                      content={
                        <>
                          <CalendarSchedulePopover
                            argRecieved={setRecievedArg}
                            extendedProps={arg?.event?._def?.extendedProps}
                            setIsRescheduleRequestEvent={setIsRescheduleRequestEvent}
                            getEventDetails={getAllViewpropertyCalendar}
                            arg={arg}
                            propMlsId={"propMlsId"}
                            eventType="View Property"
                            calendarType="Property Calendar"
                            setEventFilter={() => {}}
                            // propMlsId={arg?.event?._def?.extendedProps?.pid}
                          />
                        </>
                      }
                    >
                      {arg?.event?._def?.extendedProps?.isAccepted ? (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            inset: "50px 0% -300px",
                            backgroundColor: arg?.backgroundColor?.length > 0 ? arg?.backgroundColor : "#465E14" ?? "green",
                            zIndex: 1,
                            padding: "1px 3px",
                            whiteSpace: "normal",
                            borderRadius: "6px",
                            borderLeft: "1px solid #ccc",
                            borderTop: "1px solid #ccc",
                            overflow: "hidden",
                            textDecoration: arg?.event?._def?.extendedProps?.strike
                          }}
                        >
                          <p
                            style={{
                              color: arg?.event?._def?.extendedProps?.buyerAgentName === userState?.agent?.name ? "#fff" : "#fff",
                              fontSize: "15px"
                            }}
                          >
                            {showingTypeTitle}: {buyerAgentName} {moment(arg?.event.start).format("hh:mm a")} |Accepted
                          </p>
                        </div>
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            inset: "50px 0% -300px",
                            backgroundColor: "#505050",
                            zIndex: 1,
                            padding: "1px 3px",
                            whiteSpace: "normal",
                            borderRadius: "6px",
                            borderLeft: "1px solid #ccc",
                            borderTop: "1px solid #ccc",
                            overflow: "hidden",
                            textDecoration: arg?.event?._def?.extendedProps?.strike
                          }}
                        >
                          <p style={{ color: "#fff", fontSize: "15px" }}>
                            {showingTypeTitle}: {buyerAgentName} {moment(arg?.event.start).format("hh:mm a")} |Requested
                          </p>
                        </div>
                      )}
                    </Popover>
                  ) : (
                    <Popover
                      trigger="hover"
                      overlayInnerStyle={{
                        border: "1.4px solid #004876",
                        borderRadius: "10px"
                      }}
                      content={
                        <>
                          <CalendarSchedulePopover
                            argRecieved={setRecievedArg}
                            extendedProps={arg?.event?._def?.extendedProps}
                            setIsRescheduleRequestEvent={setIsRescheduleRequestEvent}
                            getEventDetails={getAllViewpropertyCalendar}
                            arg={arg}
                            propMlsId={"propMlsId"}
                            eventType={arg?.event?._def?.extendedProps?.isAccepted ? "Scheduled Property Accepted" : "Scheduled Property Requested"}
                            calendarType="Property Calendar"
                            setEventFilter={() => {}}
                          />
                        </>
                      }
                    >
                      {arg?.event?._def?.title === "Scheduled Property" && arg?.event?._def?.extendedProps?.isAccepted ? (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            inset: "50px 0% -300px",
                            backgroundColor: arg?.backgroundColor?.length > 0 ? arg?.backgroundColor : "#004876",
                            zIndex: 1,
                            padding: "1px 3px",
                            whiteSpace: "normal",
                            borderRadius: "6px",
                            borderLeft: "1px solid #ccc",
                            borderTop: "1px solid #ccc",
                            overflow: "hidden",
                            textDecoration: arg?.event?._def?.extendedProps?.strike
                          }}
                        >
                          <p style={{ color: "#fff", fontSize: "15px" }}>
                            {showingTypeTitle}: {buyerAgentName} {moment(arg?.event.start).format("hh:mm A")} |MLS #{arg?.event?._def?.extendedProps?.listingId}
                          </p>
                        </div>
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            inset: "50px 0% -300px",
                            backgroundColor: "#505050",
                            zIndex: 1,
                            padding: "1px 3px",
                            whiteSpace: "normal",
                            borderRadius: "6px",
                            borderLeft: "1px solid #ccc",
                            borderTop: "1px solid #ccc",
                            overflow: "hidden",
                            textDecoration: arg?.event?._def?.extendedProps?.strike
                          }}
                        >
                          <p style={{ color: "#fff", fontSize: "15px" }}>
                            {showingTypeTitle}: {buyerAgentName} {moment(arg?.event.start).format("hh:mm A")} |REQUEST
                          </p>
                        </div>
                      )}
                    </Popover>
                  )}
                </>
              );
            }}
            dayHeaderContent={(arg) => {
              return (
                <div
                  style={{
                    margin: "10px !important",
                    padding: "5px !important"
                  }}
                >
                  {arg?.view?.type !== "dayGridMonth" && (
                    <div>
                      <div
                        style={{
                          color: "#000000"
                        }}
                      >
                        {getDayName(new Date(arg?.date)?.getUTCDay())}
                      </div>
                      <div
                        style={{
                          color: "#000000"
                        }}
                      >
                        {new Date(arg?.date)?.getUTCMonth() + 1}/{new Date(arg?.date).getUTCDate()}
                      </div>
                    </div>
                  )}
                  {arg?.view?.type === "dayGridMonth" && (
                    <div>
                      <div
                        style={{
                          color: "#000000"
                        }}
                      >
                        {getDayName(new Date(arg?.date)?.getUTCDay())}
                      </div>
                      <div
                        style={{
                          color: "#000000"
                        }}
                      ></div>
                    </div>
                  )}
                </div>
              );
            }}
            allDayText=""
            buttonText={{
              today: "Today",
              month: "Month",
              week: "Week",
              day: "Day"
            }}
            dayCellClassNames={(arg) => {
              const argDay = new Date(arg?.date).getUTCDate();
              const argMonth = new Date(arg?.date).getUTCMonth();
              const ClickDay = new Date(clickedDay).getUTCDate();
              const ClickMonth = new Date(clickedDay).getUTCMonth();
              if (argDay === ClickDay && argMonth === ClickMonth) return "redColor";
              if (isOffHours(arg?.date)) return "calendarDayClass";
            }}
            ref={calendarRef}
            slotLaneClassNames={(arg) => {
              let time = arg?.date?.toTimeString()?.split(" ")[0];
              let isTime = time < offCalHours?.startTime || time > offCalHours?.endTime;
              let day = arg?.date?.toDateString()?.split(" ")?.[0];
              if (isTime && !agentAccount?.markWeekends?.includes(day)) {
                return ["offHours"];
              } else {
                return ["timeGrid"];
              }
            }}
          />
        </ScrollDiv>
      ) : (
        <></>
      )}
      {isRescheduleRequestEvent && (
        <CalendarModal
          arg={recievedArg}
          isShowing={isRescheduleRequestEvent}
          Sdate={recievedArg?.event?._def?.extendedProps?.Sdate}
          Stime={recievedArg?.event?._def?.extendedProps?.Stime}
          Edate={recievedArg?.event?._def?.extendedProps?.Edate}
          Etime={recievedArg?.event?._def?.extendedProps?.Etime}
          title={recievedArg?.event?.title}
          rStatus={recievedArg?.event?._def?.extendedProps?.rStatus}
          rEdate={recievedArg?.event?._def?.extendedProps?.rEdate}
          eventId={recievedArg?.event?._def?.extendedProps?.eventId}
          closeAction={scheduleEditEventModalHandler}
          getModalData={getModalData}
          type="viewUpdate"
          setIsRescheduleRequestEvent={setIsRescheduleRequestEvent}
          eventType="update"
          updateSender="listingAgent"
        />
      )}
      {isShowingCalendarModal && (
        <CalendarModal isShowing={isShowingCalendarModal} closeAction={calanderModalHandler} getModalData={getModalData} eventType="create" setIsRescheduleRequestEvent={setIsRescheduleRequestEvent} />
      )}
      {modelData?.open && <RescheduleModel isShowing={modelData} closeAction={closeAction} loadBuyers={loadBuyers} />}
    </>
  );
};

export default AgentCalendarMain;
