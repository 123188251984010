import { useContext, useEffect, useRef, useState } from 'react';
import triangle from '../assets/images/icon-triangle.svg';
import PropertyFiltersContext from '../contexts/PropertyFiltersContext';
import CounterField from './CounterField';

interface BedsBathSelectFieldProps {
  getBeds: number;
  setBeds: (value: number) => void;
  getBaths: number;
  setBaths: (value: number) => void;
  getHalfBaths: number;
  setHalfBaths: (value: number) => void;
  handleChange: () => void;
  searchFieldHandler: ()=> void;
}

const BedBathSelectField: React.FC<BedsBathSelectFieldProps> = ({getBeds, setBeds, getBaths, setBaths, getHalfBaths, setHalfBaths, handleChange, searchFieldHandler }) => {

  const [isExpanded, setIsExpanded] = useState(false);
  const [didChange, setDidChange] = useState(false);
  const ref = useRef(null);
  const { propertyFiltersState } = useContext(
    PropertyFiltersContext
  );
  const {beds, baths,halfBaths } = propertyFiltersState;

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsExpanded(false);
      }
    }
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick)
    }

  }, []);

  useEffect(() => {
    if (beds === 1 && baths === 1 && halfBaths === 0) {
      setDidChange(false);
    } else {
      setDidChange(true);

    }
  }, [beds, baths, halfBaths]);


  useEffect(() => {
    if (!isExpanded) {
      handleChange();
    }
  }, [isExpanded]);

  // const expand = () => {
  //   setIsExpanded(true);
  // }

  const handleSetBeds = (value: number) => {
    setBeds(value);
    setDidChange(true);
  }
  const expand2 = () => {
    setIsExpanded(!isExpanded);
  };
  const handleSetBaths = (value: number) => {
    setBaths(value);
    setDidChange(true);
  }

  const handleSetHalfBaths = (value: number) => {
    setHalfBaths(value);
    setDidChange(true);
  }

  const height = isExpanded ? "24.2rem" : "4.8rem";
  const valueColor = didChange ? "var(--color-black" : "var(--color-grey-2";
  const zIndex = isExpanded ? 1000 : 10;

  const label = didChange ? `>${getBeds}bed, >${getBaths + (getHalfBaths / 2)}bath` : "Bed and Bath";

  return (
    <div className="bed-bath-select-field__container" onClick={searchFieldHandler}>
      <div ref={ref} className="bed-bath-select-field"  style={{ height: height, zIndex: zIndex }}>
        <div className="bed-bath-select-field__label" onClick={expand2}>
          <p style={{ color: valueColor }}>{label}</p>
          <img className={isExpanded ? "select-field__arrow select-field__arrow--rotated " : "select-field__arrow "} src={triangle} alt="Triangle" style={{ top: "2rem" }} />
        </div>
        <div className="bed-bath-select-field__content"  style= {{overflow: "hidden"}}>
          <p>Beds</p>
          <CounterField value={getBeds} setValueHandler={handleSetBeds} increment={1} minValue={1} maxValue={10} />
          <p>Baths</p>
          <CounterField value={getBaths} setValueHandler={handleSetBaths} increment={1} minValue={1} maxValue={10} />
          <p>Half Baths</p>
          <CounterField value={getHalfBaths} setValueHandler={handleSetHalfBaths} increment={1} minValue={0} maxValue={1} />
        </div>
      </div>
    </div>
  );
};

export default BedBathSelectField;