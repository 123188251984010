import React, { useEffect, useState, useContext } from 'react';
import iconSearch from '../assets/images/icon-search.svg';

import PropertyFilterContext from '../contexts/PropertyFiltersContext';

interface SearchFieldProps {
  placeholder: string;
  searchAction: (query: string) => void;
  className?: string;
  defaultValue?: string;
  onChange?: (text: string) => void;
  onClick?: (value: boolean) => void;

}

const SearchField: React.FC<SearchFieldProps> = ({
  placeholder,
  searchAction,
  className,
  onChange = (text: string) => {},
  defaultValue,
  onClick
}) => {
  const [value, setValue] = useState('');

  const { propertyFiltersState } = useContext(PropertyFilterContext);

  const { query } = propertyFiltersState;

  useEffect(() => {
    if (defaultValue) setValue(defaultValue);
  }, [defaultValue]);
  useEffect(() => {
    if (query.length === 0) {
      setValue('');
    }
  }, [query]);

  const handleChange = (event: any) => {
    onChange(event.target.value);
    setValue(event.target.value);
  };

  const handleSearchAction = () => {
    searchAction(value);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      searchAction(value);
    }
  };

  return (
    <div
      className={
        className === undefined ? 'search-field' : `search-field ${className}`
      }
    >
      <img
        className="search-field__icon"
        src={iconSearch}
        alt="TextField Icon"
        onClick={handleSearchAction}
      />
      <input
        type="text"
        value={value || ''}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        placeholder={placeholder}
        className="search-field__input"
      />
    </div>
  );
};

export default SearchField;
