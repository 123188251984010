/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from "@emotion/styled";
import { Col, Row } from "antd";
import GoogleMapReact from "google-map-react";
import React, { useContext, useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useHistory } from "react-router-dom";
import { useGlobal } from "reactn";
import iconCalendarWhite from "../assets/images/icon-calendar-white.svg";
import iconCalendar from "../assets/images/icon-calendar.svg";
import iconListedBlue from "../assets/images/icon-listed-blue.svg";
import iconListed from "../assets/images/icoon-listed.svg";
import Button, { ButtonType } from "../components/Button";
import { notification } from "../components/notification";
import AgentContext from "../contexts/AgentContext";
import UserContext, { UserType } from "../contexts/UserContext";
import DiscardTourModal from "../modals/EndingTour";
import Regina from "../modals/GoogleMapModals/ReginaGeorge";
import RoutingTourModal from "../modals/GoogleMapModals/RouteTour";
import ScheduleTourModal from "../modals/GoogleMapModals/schedulingStatus";
import {
  AddLinkedProperties,
  getLinkedPropertyForMap,
  getPropertyDetailsService,
  updateUpcomingTourDetails,
} from "../services/NavihomeService";
import { AnyReactComponent } from "./GoogleMap/PropertyMap";
import CustomSpinner from "./spinner/CustomSpinner";
import TourDetailsForm from "./TourDetailsForm";
import TourPropertyDetails from "./TourPropertyDetails";

const mocdata = {
  first_name: "MCG",

  last_name: "AI",

  organization: "Mysuru",

  travel_time: 5,

  locations: [
    {
      cityNameKey: "844 Babcock St Boston, MA 02215",

      placeURL: "",

      latitude: 42.3496754,

      longitude: -71.1213792,

      regionID: 1,

      open_time: "2022-03-11 10:00",

      close_time: "2022-03-11 15:00",

      service_time: 0,

      position: 0,
    },
    {
      cityNameKey: "15 Cazenove 3, Boston, MA 02116",

      placeURL: "",

      latitude: 42.347147,

      longitude: -71.07287629999999,

      regionID: 1,

      open_time: "2022-03-11 09:00",

      close_time: "2022-03-11 13:00",

      service_time: 20,

      position: 1,
    },
    {
      cityNameKey: "140 Shawmut Unit 2B, Boston, MA 02118",

      placeURL: "",

      latitude: 42.3459644,

      longitude: -71.0657809,

      regionID: 1,

      open_time: "2022-03-11 11:00",

      close_time: "2022-03-11 15:00",

      service_time: 20,

      position: 1,
    },
    {
      cityNameKey: "63 Burbank St 11, Boston, MA 02115",

      placeURL: "",

      latitude: 42.3446601,

      longitude: -71.08886290000001,

      regionID: 1,

      open_time: "2022-03-11 12:05",

      close_time: "2022-03-11 13:10",

      service_time: 20,

      position: 1,
    },
    {
      cityNameKey: "80-82 Fenwood Rd 811, Boston, MA 02115",

      placeURL: "",

      latitude: 42.3351787,

      longitude: -71.1077755,

      regionID: 1,

      open_time: "2022-03-11 14:00",

      close_time: "2022-03-11 15:00",

      service_time: 20,

      position: 1,
    },
    {
      cityNameKey: "69 Park Dr 5, Boston, MA 02215",

      placeURL: "",

      latitude: 42.342681,

      longitude: -71.0954282,

      regionID: 1,

      open_time: "2022-03-11 08:00",

      close_time: "2022-03-11 11:00",

      service_time: 20,

      position: 1,
    },
    {
      cityNameKey: "17 Holyoke St 1, Boston, MA 02116",

      placeURL: "",

      latitude: 42.3449176,

      longitude: -71.0777013,

      regionID: 1,

      open_time: "2022-03-11 10:00",

      close_time: "2022-03-11 12:00",

      service_time: 20,

      position: 1,
    },
    {
      cityNameKey: "4 Centre Pl 1, Boston, MA 02119",

      placeURL: "",

      latitude: 42.3292326,

      longitude: -71.0950162,

      regionID: 1,

      open_time: "2022-03-11 10:00",

      close_time: "2022-03-11 11:00",

      service_time: 20,

      position: 1,
    },
    {
      cityNameKey: "95 Gainsborough St Unit U 308, Boston, MA 02115",

      placeURL: "",

      latitude: 42.3426227,

      longitude: -71.0888361,

      regionID: 1,

      open_time: "2022-03-11 12:10",

      close_time: "2022-03-11 15:10",

      service_time: 20,

      position: 1,
    },
    {
      cityNameKey: "844 Babcock St Boston, MA 02215",

      placeURL: "",

      latitude: 42.3496754,

      longitude: -71.1213792,

      regionID: 1,

      open_time: "2022-03-11 10:10",

      close_time: "2022-03-11 15:10",

      service_time: 0,

      position: 100,
    },
  ],
};

const LeftButton = styled.div<{ selected: boolean }>`
  background-color: ${(props) => (props?.selected ? "#fff" : "#004876")};
  /* color: ${(props) => (props?.selected ? "#fff" : "#004876")}; */
  height: 100%;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  text-align: center;
`;
const RightButton = styled.div<{ selected: boolean }>`
  background-color: ${(props) => (props?.selected ? "#fff" : "#004876")};
  /* color: ${(props) => (!props?.selected ? "#004876" : "#fff")}; */
  height: 100%;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
  text-align: center;
`;
const CustomDiv = styled.div<any>`
  position: absolute;
  top: 10px;
  right: 5%;
  /* transform: translateX(-50%); */
  z-index: 10;
  width: 300px;
`;
const Input: any = styled.input`
  width: 60%;
  height: 2.8rem;
  font-size: 10px;
  font-weight: bold;
  padding-left: 2rem;
  padding-right: 0.2rem;
  box-shadow: 0rem 0.3rem 0.6rem rgba(0, 0, 0, 0.2);
  border-radius: 0.8rem;
  border: 0.1rem solid #365073;
  vertical-align: top;
`;

const TourDetails = () => {
  const history = useHistory();
  const [selected, Setselected] = useState<string>("form");
  const { agentState } = useContext(AgentContext);

  const [isTourModalShow, setIsTourModalShow] = useState(false);
  const [isDiscardTourModal, setIsDiscardTourModal] = useState(false);
  const [isScheduleTour, setIsScheduleTour] = useState(false);
  const [agentModal, setAgentModal] = useState<boolean>(false);
  const { userState } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [linkedProperties, setLinkedProperties] = useState([]);
  const [buyerSideAgentName, setBuyerSideAgentName] = useState<string>("");
  const [agentIdForLinkedBuyer, setAgentIdForLinkedBuyer] = useState<number>();
  const [startLocation, setStartLocation] = useState<any>({
    lat: null,
    lng: null,
  });
  const [endLocation, setEndLocation] = useState<any>({ lat: null, lng: null });
  const [buyerGlobalId, setBuyerGlobalId] = useGlobal<any>("buyerId");

  const [address, setAddress] = useState<any>("");

  const [startrouteLocation, setStartRouteLocation] =
    useGlobal<any>("startroutedetail");
  const [endrouteLocation, setEndRouteLocation] =
    useGlobal<any>("endroutedetail");
  const [bestRoute, setBestRoute] = useState<any>([]);
  const [locations, setLocations] = useState<any>([]);
  const [formData, setFormData] = useState({
    dateTime: null,
    timePerProp: null,
    startAdd: null,
    startAddCity: null,
    endingAdd: null,
    endingAddCity: null,
    selectedProperties: null,
    area: null,
    propertyStatus: null,
    buyerId: null,
    agentId: null,
    id: null,
  });
  const handleRouteTourModal = () => {
    setIsTourModalShow(!isTourModalShow);
  };

  const handleToggling = async () => {
    setIsLoading(true);
    const AllLatLng: any = [];
    let token = "";
    if (userState?.userType === UserType.AGENT) {
      token = userState?.agent?.token;
    } else {
      token = userState?.buyer?.token;
    }

    try {
      if (locations?.length > 0) {
        const response: any = await AddLinkedProperties(
          "Test",
          "Test",
          mocdata?.organization,
          mocdata?.travel_time,
          locations,
          token
        );

        response?.shortest_path?.split("->")?.map((data: any) => {
          let cityName = data.replace(/[{()}]/g, "");

          for (let ind = 0; ind < response?.locations?.length; ind++) {
            if (response?.locations?.[ind]?.cityNameKey === cityName) {
              const latlng = {
                lat: response?.locations?.[ind]?.latitude,
                lng: response?.locations?.[ind]?.longitude,
                city: response?.locations?.[ind]?.cityNameKey,
              };
              AllLatLng.push(latlng);
              break;
            }
          }
        });
        setBestRoute(AllLatLng);
        setIsLoading(false);
        return notification("Your tour has been optimized", "success");
      }
    } catch (err) {
      setIsLoading(false);
      console.log("error==>", err);
    }
  };

  const handleSaveTourStatus = async () => {
    // here we call  api of update status on a particular id
    let token = "";
    if (userState?.userType === UserType.AGENT) {
      token = userState?.agent?.token;
    } else {
      token = userState?.buyer?.token;
    }
    try {
      const response = await updateUpcomingTourDetails(
        formData.id,
        { status: "saved" },
        token
      );
      // if (response) {

      // }

      return notification("Your tour has saved", "success");
    } catch (err) {
      console.log("error==>", err);
    }
  };

  const handleScheduleTourStatus = async () => {
    //  here we call api of Schedule status on a particular id
    let token = "";
    if (userState?.userType === UserType.AGENT) {
      token = userState?.agent?.token;
    } else {
      token = userState?.buyer?.token;
    }

    setIsScheduleTour(!isScheduleTour);
    try {
      await updateUpcomingTourDetails(
        formData.id,
        { status: "scheduled" },
        token
      );
    } catch (err) {
      console.log("error==>", err);
    }
  };

  const handleDiscardAndExit = () => {
    setIsDiscardTourModal(!isDiscardTourModal);
    // history.push('/dashboard')
  };

  const getALLLinkedPropertiesForBuyer = async (buyerId: any) => {
    setIsLoading(true);
    let token = "";
    if (userState?.userType === UserType.AGENT) {
      token = userState?.agent?.token;
    } else {
      token = userState?.buyer?.token;
    }
    try {
      if (!buyerId) {
        setIsLoading(false);
        return;
      }
      if (!token) {
        setIsLoading(false);
        return notification("token not found ", "error");
      }

      const properties = await getLinkedPropertyForMap(buyerId, token);
      if (properties?.length <= 0) {
        setIsLoading(false);
        return;
      }
      let listings = [];
      let dataForRoute = [];

      const startAddress = {
        cityNameKey: "844 Babcock St Boston, MA 02215",

        placeURL: "",

        latitude: 42.3496754,

        longitude: -71.1213792,

        regionID: 1,

        open_time: "2022-03-11 10:00",

        close_time: "2022-03-11 15:00",

        service_time: 0,

        position: 0,
      };

      const endAddress = {
        cityNameKey: "844 Babcock St Boston, MA 02215",

        placeURL: "",

        latitude: 42.3496754,

        longitude: -71.1213792,

        regionID: 1,

        open_time: "2022-03-11 10:10",

        close_time: "2022-03-11 15:10",

        service_time: 0,

        position: 100,
      };

      // push starting address object
      // dataForRoute.push(startAddress)

      for (let i = 0; i < properties.length; i++) {
        const linkprop = await getPropertyDetailsService(
          properties[i].propertyId
        );

        const routeObj = {
          cityNameKey: `${linkprop?.address?.streetNumber ?? ""} ${
            linkprop?.address?.streetName ?? ""
          }, ${linkprop?.address?.city ?? ""}, ${
            linkprop?.address?.state ?? ""
          } ${linkprop?.address?.postalCode ?? ""}`,
          placeURL: "",
          latitude: Number(`${linkprop?.geo?.lat}`),
          longitude: Number(`${linkprop?.geo?.lng}`),
          regionID: 1,
          open_time: "2022-03-11 10:00",
          close_time: "2022-03-11 15:00",
          service_time: Number(
            `${formData?.timePerProp?.split(" ")?.[0] ?? 15}`
          ),
          position: 1,
        };
        dataForRoute.push(routeObj);

        listings.push(linkprop);
      }
      // push endlocation object to array
      // dataForRoute.push(endAddress)

      setLinkedProperties(listings);
      setLocations(dataForRoute);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      return notification("something went wrong ", "error");
    }
  };
  const getAgentIdFromBuyer = async (buyerId: any) => {
    setIsLoading(true);
    let token = "";
    let agentId = -1;
    if (userState?.userType === UserType.AGENT) {
      token = userState?.agent?.token;
    } else {
      token = userState?.buyer?.token;
    }
    try {
      if (!buyerId) {
        setIsLoading(false);
        return;
      }
      if (!token) {
        setIsLoading(false);
        return;
      }

      if (userState?.userType === UserType.AGENT) {
        agentId = userState?.agent?.agentId;
        setAgentIdForLinkedBuyer(agentId);
        setIsLoading(false);
        return;
      } else {
        setBuyerSideAgentName(userState?.buyer?.buyerAgentName);
        setAgentIdForLinkedBuyer(userState?.buyer?.buyerAgentId);
        setIsLoading(false);
        return;
      }
    } catch (err) {
      setIsLoading(false);
      return notification("something went wrong ", "error");
    }
  };
  useEffect(() => {
    if (startrouteLocation) {
      localStorage.setItem("startroute", JSON.stringify(startrouteLocation));
    }
    if (endrouteLocation) {
      localStorage.setItem("endroute", JSON.stringify(endrouteLocation));
    }
  }, [startrouteLocation, endrouteLocation]);
  useEffect(() => {
    let buyerId = -1;
    if (userState?.userType === UserType.AGENT) {
      buyerId = agentState?.buyerId;
    } else {
      buyerId = userState?.buyer?.buyerId;
    }
    getALLLinkedPropertiesForBuyer(buyerId);
    getAgentIdFromBuyer(buyerId);
  }, [buyerGlobalId]);

  const renderTourDetails = () => {
    let agentName = " ";
    let buyerName = " ";

    if (userState?.userType === UserType.AGENT) {
      agentName = userState?.agent?.name;
      buyerName = agentState?.buyerName;
    } else {
      agentName = buyerSideAgentName;
      buyerName = userState?.buyer?.name;
    }
    const ToggleLeftData = () => {
      Setselected('prop');
    };
    const ToggleRightData = () => {
      Setselected('form');
    };

    const getMapOptions = (maps: any) => {
      return {
        mapTypeControl: true,
        mapTypeId: maps.MapTypeId.ROADMAP,
        mapTypeControlOptions: {
          style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: maps.ControlPosition.BOTTOM_CENTER,
          mapTypeIds: [
            maps.MapTypeId.ROADMAP,
            maps.MapTypeId.SATELLITE,
            maps.MapTypeId.HYBRID,
          ],
        },
      };
    };

    const apiIsLoaded = (map: any, maps: any) => {
      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer({
        draggable: true,
        map,
      });

      directionsRenderer.setMap(map);
      directionsRenderer.setOptions({
        polylineOptions: {
          strokeColor: "#707070",
        },
      });

      const startMarker = new google.maps.Marker({
        position: startrouteLocation
          ? startrouteLocation
          : JSON.parse(localStorage.getItem("startroute")),
        label: {
          color: "#fff",
          fontWeight: "bold",
          text: "S",
          fontSize: "25px",
        },
        map: map,
      });
      startMarker.setMap(map);

      const endMarker = new google.maps.Marker({
        position: endrouteLocation
          ? endrouteLocation
          : JSON.parse(localStorage.getItem("endroute")),
        label: {
          color: "#fff",
          fontWeight: "bold",
          text: "E",
          fontSize: "25px",
        },
        map: map,
      });

      endMarker.setMap(map);

      const val: any = directionsService.route(
        {
          origin: startrouteLocation
            ? startrouteLocation
            : JSON.parse(localStorage.getItem("startroute")),
          destination: endrouteLocation
            ? endrouteLocation
            : JSON.parse(localStorage.getItem("endroute")),
          travelMode: google.maps.TravelMode.DRIVING,
          waypoints: bestRoute.map((item: any) => {
            return {
              location: new google.maps.LatLng(item?.lat, item?.lng),
              stopover: false,
            };
          }),
        },
        (result: any, status: any) => {
          if (status === google.maps.DirectionsStatus.OK) {
            directionsRenderer.setDirections(result);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    };
    const HandleChangeAdd = async (data: any) => {
      setAddress(data);
    };

    const HandleSelectAdd = async (data: any) => {
      const result = await geocodeByAddress(data);

      const latlng = await getLatLng(result[0]);

      setAddress(data);
    };

    return (
      <>
        {/* this row is used  for buyer name tour and buttons like route tour and save tour */}
        {isLoading && <CustomSpinner />}
        <Row
          style={{
            marginTop: "4rem",
            marginLeft: "1rem",
            marginRight: "1rem",
          }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 6 }}>
            <div className="buyer_name_back_button">
              <p onClick={() => history.goBack()}>Back</p>
            </div>
            <div style={{ display: "flex" }}>
              <div className="buer_name_tour_details">
                <h2>{agentState.buyerName}-Tours</h2>
              </div>
              <div className="main_div_of_toggling_button">
                <LeftButton
                  selected={selected === "form"}
                  onClick={ToggleLeftData}>
                  <img
                    src={selected === "form" ? iconCalendar : iconCalendarWhite}
                    alt="location"
                    role="button"
                  />
                  {/* Setselected(true) */}
                </LeftButton>
                <RightButton
                  selected={selected === "prop"}
                  onClick={ToggleRightData}>
                  <img
                    src={selected === "prop" ? iconListedBlue : iconListed}
                    alt="calendar"
                    role="button"
                  />
                </RightButton>
              </div>
            </div>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 18 }}>
            <div className="button_of_buyer_tour_details ">
              <div>
                <h3 style={{ fontWeight: "bold", marginTop: "1rem" }}>
                  {linkedProperties.length} properties
                </h3>
              </div>
              <div className="buyer_tour_details_discard_exit">
                <Button
                  type={ButtonType.WHITE}
                  text="DISCARD&EXIT"
                  clickHandler={handleDiscardAndExit}
                  className="common_button_buyer_tour_details"
                />
              </div>
              <div className="buyer_tour_details_save_tour">
                <Button
                  type={ButtonType.PRIMARY}
                  text="SAVE TOUR"
                  clickHandler={handleSaveTourStatus}
                  className="common_button_buyer_tour_details"
                />
              </div>
              <div className="buyer_tour_details_route_tour">
                <Button
                  type={ButtonType.PRIMARY}
                  text="ROUTE TOUR"
                  clickHandler={handleRouteTourModal}
                  className="common_button_buyer_tour_details"
                />
              </div>
              <div className="buyer_tour_details_schedule_tour">
                <Button
                  type={ButtonType.PRIMARY}
                  text="SCHEDULE TOUR"
                  clickHandler={handleScheduleTourStatus}
                  className="common_button_buyer_tour_details"
                />
              </div>
            </div>
          </Col>
        </Row>
        {isTourModalShow && (
          <RoutingTourModal
            handleToggling={handleToggling}
            // setRouteButton={setRouteButton}
            isShowing={isTourModalShow}
            closeAction={handleRouteTourModal}
          />
        )}
        {isDiscardTourModal && (
          <DiscardTourModal
            isShowing={isDiscardTourModal}
            closeAction={handleDiscardAndExit}
          />
        )}
        {isScheduleTour && (
          <ScheduleTourModal
            isShowing={isScheduleTour}
            closeAction={() => {
              setIsScheduleTour(!isScheduleTour);
              // setAgentModal(!agentModal)
            }}
            linkedPropertiesData={linkedProperties}
            setLinkedPropertiesData={setLinkedProperties}
            setAgentModal={setAgentModal}
            setIsScheduleTour={setIsScheduleTour}
          />
        )}
        {agentModal && (
          <>
            <Regina
              isShowing={agentModal}
              closeAction={() => {
                setAgentModal(!agentModal);
              }}
              // setIsModalVisible={setIsModalVisible}
            />
          </>
        )}

        {/* this row is used for a form  and map */}
        <Row
          style={{ marginTop: "5rem", justifyContent: "space-between" }}
          gutter={[16, 16]}>
          {/* this columns is used to show the components in toggle form  */}
          <Col xs={24} sm={24} md={24} lg={7}>
            {selected === "prop" ? (
              <TourPropertyDetails
                buyerGlobalId={buyerGlobalId}
                linkedProperties={linkedProperties}
                setLinkedProperties={setLinkedProperties}
              />
            ) : (
              <TourDetailsForm
                agentName={agentName}
                buyerName={buyerName}
                setFormData={setFormData}
                setStartLocation={setStartLocation}
                setEndLocation={setEndLocation}
                buyerIdForAddress={agentState.buyerId}
                buyerSideAgentId={agentIdForLinkedBuyer}
                setEliminatePropertyFromTheTour={()=>{}}
                setFilteredLinkedProperties={()=>{}}
                setFormDataEdited={()=>{}}
                setIsFormDataChanged={()=>{}}
                setIsOutTimeProperty={()=>{}}
                setLinkedPropertiesData={()=>{}}
                setSaveTourClicked={()=>{}}
                setScheduleStatus={()=>{}}
                setStartTimeForTimeStamp={()=>{}}
              />
            )}
          </Col>
          {/* this column is used to show the map */}
          <Col xs={24} sm={24} md={24} lg={17}>
            <>
              <CustomDiv className="places-container">
                <PlacesAutocomplete
                  value={address}
                  onChange={HandleChangeAdd}
                  onSelect={HandleSelectAdd}>
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <Input
                        {...getInputProps({
                          placeholder: "Enter Place",
                        })}
                      />
                      <div>
                        {loading && <div>Loading....</div>}
                        {suggestions.map((suggestion) => {
                          const style = suggestion.active
                            ? {
                                backgroundColor: "#d4d4d4",
                                cursor: "pointer",
                                border: "1px solid black",
                                borderRadius: "5px",
                              }
                            : {
                                backgroundColor: "rgba(255,255,255,0.7)",
                                cursor: "pointer",
                                fontSize: "1.2rem",
                                borderBottom: "1px solid #d4d4d4",
                              };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                style,
                              })}>
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </CustomDiv>
              <div style={{ height: "100vh" }}>
                {bestRoute.length > 0 && (
                  <GoogleMapReact
                    // style={{ height: '30vh', width: '100%' }}
                    bootstrapURLKeys={{
                      key: process.env.REACT_APP_GOOGLE_API_KEY,
                    }}
                    center={{
                      lat: linkedProperties?.[0]?.geo?.lat ?? 42.300756,
                      lng: linkedProperties?.[0]?.geo?.lng ?? -71.518006,
                    }}
                    // lat: 42.300756, lng: -71.518006
                    defaultZoom={8}
                    options={getMapOptions}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => {
                      apiIsLoaded(map, maps);
                    }}>
                    {linkedProperties?.map((data: any, index: any) => {
                      return (
                        <AnyReactComponent
                          id={data.mlsId}
                          lat={data?.geo?.lat}
                          lng={data?.geo?.lng}
                          city={data?.address?.city || "N/A"}
                          county={data?.geo?.county || "N/A"}
                          photos={data?.photos?.[0] || "N/A"}
                          streetNumber={data?.address?.streetNumber || "N/A"}
                          streetName={data?.address?.streetName || "N/A"}
                          state={data?.address?.state || "N/A"}
                          postalCode={data?.address?.postalCode || "N/A"}
                          listPrice={data?.listPrice || "N/A"}
                          subTypeText={data?.property?.subTypeText || "N/A"}
                          bedrooms={data?.property?.bedrooms || "N/A"}
                          bathrooms={
                            data?.property?.bathsFull +
                              0.5 * data?.property?.bathsHalf ?? 1
                          }
                          area={data?.property?.area || "N/A"}
                          text={"marker"}
                          listingId={data?.listingId}
                          role={data?.role}
                          setIsAllAccepted={() => {}}
                          setPropId={()=>{}}
                          setTourPropPage={()=>{}}
                          setShowMap={()=>{}}
                          setRunMap={()=>{}}
                          setUseManual={()=>{}}
                          formData={formData}
                        />
                      );
                    })}
                  </GoogleMapReact>
                )}
                {bestRoute.length === 0 && (
                  <GoogleMapReact
                    // style={{ height: '30vh', width: '100%' }}
                    bootstrapURLKeys={{
                      key: process.env.REACT_APP_GOOGLE_API_KEY,
                    }}
                    center={{
                      lat: linkedProperties?.[0]?.geo?.lat ?? 42.300756,
                      lng: linkedProperties?.[0]?.geo?.lng ?? -71.518006,
                    }}
                    // lat: 42.300756, lng: -71.518006
                    defaultZoom={10}
                    options={getMapOptions}
                    yesIWantToUseGoogleMapApiInternals>
                    {linkedProperties?.map((data: any, index: any) => {
                      return (
                        <AnyReactComponent
                          id={data.mlsId}
                          lat={data?.geo?.lat}
                          lng={data?.geo?.lng}
                          city={data?.address?.city || "N/A"}
                          county={data?.geo?.county || "N/A"}
                          photos={data?.photos?.[0] || "N/A"}
                          streetNumber={data?.address?.streetNumber || "N/A"}
                          streetName={data?.address?.streetName || "N/A"}
                          state={data?.address?.state || "N/A"}
                          postalCode={data?.address?.postalCode || "N/A"}
                          listPrice={data?.listPrice || "N/A"}
                          subTypeText={data?.property?.subTypeText || "N/A"}
                          bedrooms={data?.property?.bedrooms || "N/A"}
                          bathrooms={
                            data?.property?.bathsFull +
                              0.5 * data?.property?.bathsHalf ?? 1
                          }
                          area={data?.property?.area || "N/A"}
                          text={"marker"}
                          listingId={data?.listingId}
                          role={data?.role}
                          setIsAllAccepted={() => {}}
                          setPropId={()=>{}}
                          setTourPropPage={()=>{}}
                          setShowMap={()=>{}}
                          setRunMap={()=>{}}
                          setUseManual={()=>{}}
                          formData={formData}
                        />
                      );
                    })}
                  </GoogleMapReact>
                )}
              </div>
            </>
          </Col>
        </Row>
      </>
    );
  };
  return <div className="tour_details">{renderTourDetails()}</div>;
};

export default TourDetails;
