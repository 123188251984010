import styled from "@emotion/styled";
import React, { useState, useEffect, Fragment, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropertyCard from "../components/PropertyCard";
import CustomSpinner from "../components/spinner/CustomSpinner";
import GoogleMapReact from "google-map-react";
import { AnyReactComponent } from "./GoogleMap/PropertyMap";
import UserContext, { UserType } from "../contexts/UserContext";
import AgentContext from "../contexts/AgentContext";

import {
  getUpcomingTourDetailsByFormId
} from "../services/NavihomeService";
import moment from "moment";

const CustomMainDiv = styled.div`
  width: 100%;
  @media screen and (max-width: 1400px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
const CustomBackDiv = styled.div`
  padding: 15px;
`;
const CustomMapDiv = styled.div`
  display: flex;
  max-width: 136.6rem;
  /* background-color: blue; */
  margin: 0 auto;
  /* justify-content: space-around; */

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
const CustomTitleDiv = styled.div`
  max-width: 136.6rem;
  margin: 15px auto;
  /* font-size: 4rem; */
`;
const CustomTitlePara = styled.div`
  font-size: 3.5rem;
  color: #004876;
`;
const CustomBuyerDetailDiv = styled.div`
  width: 35%;
  margin-right: 15px;
  /* background-color: red; */

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const CustomBackSpan = styled.span`
  cursor: pointer;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  &:hover {
    text-decoration: underline;
    font-weight: 600;
  }
`;
const CustomPropertyCardDiv = styled.div`
  width: 65%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;

  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
const CustomParaSpan = styled.span`
  cursor: pointer;
  color: #004876;
  font-weight: 600;
`;
const CustomPara = styled.p`
  margin: 10px 0;
  text-align: left;
`;
const CustomHeading = styled.h2`
  margin: 20px 0;
  /* background-color: aqua; */
`;

interface BuyerTourDetailProp { }

const BuyerTourDetail: React.FC<BuyerTourDetailProp> = () => {
  const [isLoader, setIsLoader] = useState(false);
  const { userState } = useContext(UserContext);
  const { agentState } = useContext(AgentContext);
  const [saveButtonData, setSaveButtonData] = useState<any>([]);
  const [tourDate, setTourDate] = useState<Date | string>(new Date());
  const [bestRouteProperties, setbestRouteProperties] = useState<any>([]);
  const [area, setArea] = useState<string>('')
  const history = useHistory();

  const [sourceLocation, setSourceLocation] = useState<any>({
    lat: null,
    lng: null,
  })

  const [endLocation, setEndLocation] = useState<any>({
    lat: null,
    lng: null,
  })


  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let param = useQuery();
  const renderLinkedProperties = () => {
    let propStatus = param.get('status');

    const cards = bestRouteProperties?.map((listing: any) => {
      return (
        <PropertyCard
          property_type=""
          propertyId={listing?.mlsId}
          image={listing?.photos[0]}
          price={listing?.listPrice}
          type={listing?.property?.type}
          beds={listing?.property?.bedrooms}
          baths={
            listing?.property?.bathsFull + 0.5 * listing?.property?.bathsHalf
          }
          squareFootage={listing?.property?.area}
          latitude={listing?.geo?.lat}
          longitude={listing?.geo?.lng}
          country="United States"
          state={listing?.address?.state}
          city={listing?.address?.city}
          streetName={listing?.address?.streetName}
          streetNumber={listing?.address?.streetNumber}
          postalCode={listing?.address?.postalCode}
          bathsHalf={listing?.property?.bathsHalf}
          mlsId={listing?.mlsId}
          openHouse={propStatus === 'Open House' ? 'open_house' : ""}
          feature=""
          selectFavoriteProperties={() => {
          }}
          selectRequestedShowings={() => {
          }}
          selectPropertyComments={() => {
          }}
          setTourPropPage={() => {
          }}
          setUnlinkPropHeader={() => {
          }}
          openHouseStartTime={null}
          openHouseEndTime={null}
          propertyDetails={listing}
          isPropViewed="Yes"
        />
      );
    });
    return <Fragment>{cards}</Fragment>;
  };
  const getMapOptions = (maps: any) => {
    return {
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID,
        ],
      },
    };
  };

  // to show marker and connect with each other 

  const apiIsLoaded = (map: any, maps: any) => {

    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer({
      draggable: true,
      map,
    });

    directionsRenderer.setMap(map);
    directionsRenderer.setOptions({
      polylineOptions: {
        strokeColor: '#707070',
      },
    });
    const startMarker = new google.maps.Marker({
      position: sourceLocation,
      map: map,
    });
    startMarker.setMap(map);
    const startLoc: any =
      typeof sourceLocation === 'object'
        ? sourceLocation
        : sourceLocation
          ? JSON?.parse(sourceLocation)
          : {};

    const endLoc: any =
      typeof endLocation === 'object'
        ? endLocation
        : endLocation
          ? JSON?.parse(endLocation)
          : {};
    directionsService.route(
      {
        origin: startLoc,
        destination: endLoc,
        travelMode: google.maps.TravelMode.DRIVING,
        // unitSystem: google.maps.UnitSystem.METRIC,
        waypoints: bestRouteProperties?.map((item: any, index: number) => {
          // best_route_index = index + 1

          return {
            location: new google.maps.LatLng(
              item?.geo?.lat
                ? item?.geo?.lat
                : item?.lat,
              item?.geo?.lng
                ? item?.geo?.lng
                : item?.lng
            ),
            stopover: false,
          };
        }),
      },
      (result: any, status: any) => {
        if (status === google.maps.DirectionsStatus.OK) {
          directionsRenderer.setDirections(result);
        } else {
          console.log(`error fetching directions ${result}`);
        }
      }
    );
  };

  const backButtonHandler = () => {
    localStorage?.setItem('page', 'previousTour');
    const buyerDashboard = param.get("buyerDashboard");
    if (buyerDashboard?.length > 0) {
      if (userState.userType === UserType.BUYER) {
        history.push(`/buy?buyerDashboard=${buyerDashboard}`);
      } else {
        history.push(
          `/dashboard?buyerId=${param.get("buyerId")}&buyerDashboard=${buyerDashboard}`
        );
      }
    } else {
      history.goBack();
    }
    // history.goBack();
  };

  const getUpcomingTourDetails = async (formId: any) => {
    setIsLoader(true)
    let token = "";
    if (userState?.userType === UserType.AGENT) {
      token = userState?.agent?.token;
    } else {
      token = userState?.buyer?.token;
    }
    const data: any = await getUpcomingTourDetailsByFormId(formId, token);

    if (data && Object?.keys(data)?.length > 0) {
      setSourceLocation(data?.startrouteLocation);
      setEndLocation(data?.endrouteLocation)
      setSaveButtonData(data);
      const local1 = moment(data?.dateTime?.split('T')?.[0], 'YYYY-MM-DD').local();
      let dateTimeTemp = moment(local1, "YYYY-MM-DD").format("YYYY-MM-DD");
      setTourDate(dateTimeTemp)
      let propArea: string = data?.bestRouteProperties?.[0]?.propertyDetails ? JSON.parse(data?.bestRouteProperties?.[0]?.propertyDetails)?.address?.city : "N/A";
      setArea(propArea);

      let savePropArr: any = [];

      for (let i = 0; i < data?.bestRouteProperties?.length; i++) {
        const item = data?.bestRouteProperties[i];
        const propDetails = item?.propertyDetails ? JSON.parse(item?.propertyDetails) : null;
        // const SavedProp = await getPropertyDetailsService(item?.propertyId);
        savePropArr.push(propDetails);
      }
      setbestRouteProperties(savePropArr);
      setIsLoader(false)
    }
  };

  useEffect(() => {
    let formId = param?.get("formId");


    if (formId !== null) {
      getUpcomingTourDetails(formId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {isLoader && <CustomSpinner />}
      {
        <CustomMainDiv className="main_div_buyer_tour_detail">
          {/* {isLoader && <CustomSpinner />} */}
          <CustomBackDiv className="buyer_tour_detail_back_button">
            <CustomBackSpan onClick={backButtonHandler}>Back</CustomBackSpan>
          </CustomBackDiv>
          <CustomTitleDiv className="buyer_tour_detail_header">
            <CustomTitlePara>
              {agentState?.buyerName}-Tour,{" "}
              {moment(tourDate).format("MMMM Do, yyyy")}
            </CustomTitlePara>
          </CustomTitleDiv>

          <CustomMapDiv>
            <CustomBuyerDetailDiv>
              <CustomHeading>Tour Details</CustomHeading>
              {saveButtonData?.role === 'Buyer' && <CustomPara>
                This tour was {saveButtonData?.status} by{" "}
                {agentState?.buyerName?.toUpperCase()}
              </CustomPara>}

              <CustomPara>
                <CustomParaSpan>Date:{" "}</CustomParaSpan>{" "}
                {moment(tourDate).format("MMMM Do, yyyy")}
              </CustomPara>
              <CustomPara>
                <CustomParaSpan>Time:{" "}</CustomParaSpan>
                {saveButtonData?.time}
              </CustomPara>
              <CustomPara>
                <CustomParaSpan>Area:{" "}</CustomParaSpan>
                {area}
              </CustomPara>
              <CustomHeading>Tour Route</CustomHeading>

              <div style={{ height: "50vh" }}>
                <GoogleMapReact
                  // style={{ height: '30vh', width: '100%' }}
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_API_KEY,
                  }}
                  center={{ lat: bestRouteProperties?.[0]?.geo?.lat, lng: bestRouteProperties?.[0]?.geo?.lng }}
                  defaultZoom={15}
                  options={getMapOptions}
                  onGoogleApiLoaded={({ map, maps }) => {
                    apiIsLoaded(map, maps);
                  }}
                >
                  {bestRouteProperties?.map((data: any) => {
                    return (
                      <AnyReactComponent
                        id={data.mlsId}
                        lat={data?.geo?.lat}
                        lng={data?.geo?.lng}
                        city={data?.address?.city || "N/A"}
                        county={data?.geo?.county || "N/A"}
                        photos={data?.photos?.[0] || "N/A"}
                        streetNumber={data?.address?.streetNumber || "N/A"}
                        streetName={data?.address?.streetName || "N/A"}
                        state={data?.address?.state || "N/A"}
                        postalCode={data?.address?.postalCode || "N/A"}
                        listPrice={data?.listPrice || "N/A"}
                        subTypeText={data?.property?.subTypeText || "N/A"}
                        bedrooms={data?.property?.bedrooms || "N/A"}
                        bathrooms={
                          data?.property?.bathsFull +
                          0.5 * data?.property?.bathsHalf ?? 1
                        }
                        area={data?.property?.area || "N/A"}
                        // text={"marker"}
                        listingId={data?.listingId}
                        formId={param?.get('formId') ? param?.get('formId') : null}
                        setIsAllAccepted={() => { }}
                        setPropId={() => { }}
                        setTourPropPage={() => { }}
                        setShowMap={() => { }}
                        setRunMap={() => { }}
                        setUseManual={() => { }}
                        formData={{}}
                      />
                    );
                  })}
                </GoogleMapReact>

                {/* <GoogleMapTour
                  bestRoute={0}
                  linkedProperties={saveLinkedProperties}
                  saveButtonData={saveButtonData}
                  statusType={statusType}
                /> */}
              </div>
            </CustomBuyerDetailDiv>
            <CustomPropertyCardDiv>
              {renderLinkedProperties()}
            </CustomPropertyCardDiv>
          </CustomMapDiv>
        </CustomMainDiv>
      }
    </>
  );
};

export default BuyerTourDetail;
