/* eslint-disable react-hooks/exhaustive-deps */
import styled from "@emotion/styled";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CustomSpinner from "../components/spinner/CustomSpinner";
import UserContext, { UserType } from "../contexts/UserContext";

const CustomDiv = styled.div`
  padding: 50px;
`;
const CustomHeading = styled.h1`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #c2cc30;
  }
`;

const LoginPage = () => {
  const history = useHistory();
  const { changeUserType } = useContext(UserContext);
  const [userType, setUserType] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const query: any = history?.location?.hash?.split("=") ?? "";
    const type = query?.[3] ?? "";
    const seller = query?.[5] ?? "";
    const id = query?.[7] ?? "";
    const name = query?.[9] ?? "";
    setUserType(type);
    verifyHandler(type, seller, id, name);
  }, [history?.location?.search]);

  const verifyHandler = async (
    type: string,
    seller?: string,
    id?: string,
    name?: string
  ) => {
    setIsLoading(true);
    if (type === "buyer") {
      if (seller?.toLocaleLowerCase() === "seller")
        history.push(`/register?type=seller&id=${id}`);
      else history.push("/register");
      setIsLoading(false);
    }
    if (type === "agent") {
      setTimeout(() => {
        changeUserType(UserType.AGENT);
        if (seller?.toLocaleLowerCase() === "seller")
          history.push(`/register?type=seller&buyerId=${id}&name=${name}`);
        else history.push("/register");
        setIsLoading(false);
      }, 2000);
    }
    if (type === "agentLogin") {
      setTimeout(() => {
        changeUserType(UserType.AGENT);
        history.push("/");
        setIsLoading(false);
      }, 2000);
    }
    setIsLoading(false);
  };

  return (
    <CustomDiv>
      {isLoading && <CustomSpinner />}
      <CustomHeading
        onClick={() => {
          verifyHandler(userType);
        }}>
        Register!
      </CustomHeading>
    </CustomDiv>
  );
};

export default LoginPage;
