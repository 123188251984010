import { Col, Row } from "antd";
// import MiniCalendar from '../../MiniCalendar'
// import ModernCalendar from '../../ModernCalendar';
import styled from "@emotion/styled";
import { useContext, useState } from "react";
import UserContext from "../../../contexts/UserContext";
import ModernCalendar from "../../ModernCalendar";
import PreviousShowings from "../../agentHomePage/previousShowings";
import CustomSpinner from "../../spinner/CustomSpinner";
import SaleAgentModal from "./saleAgentModal";
import "./styles/showings.css";

const CustomPara = styled.p`
  font-size: 12px;
  margin-top: 10px;
`;

interface IShowingsMainProps {
  tours?:any
}

const ShowingsMain: React.FunctionComponent<IShowingsMainProps> = ({tours}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [saleRecord, setSaleRecord] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userState } = useContext(UserContext);
  const [upcomingStartEndDate, setUpcomingStartEndDate] = useState<any>({
    startDate: null,
    endDate: null,
  });

  return isLoading ? (
    <CustomSpinner />
  ) : (
    <div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={7}>
          <ModernCalendar
            setTableData ={()=>{}}
            setUpcomingStartEndDate={setUpcomingStartEndDate}
            style={{ paddingLeft: "0px !important" }}
            data="previous_show"
          />
          <CustomPara>
            Scrolling through months on the calendar will display details of the
            tours completed in that month.
          </CustomPara>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={17}style={{ padding: "0px 16px", overflow: "auto" }}>
          {/* <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            bordered={false}
          /> */}
          <PreviousShowings
          tours={tours}
          upcomingTourDate={''}
          upcomingStartEndDate={upcomingStartEndDate}
          type="card"
          userState={userState}
          />
        </Col>
      </Row>
      {isModalOpen && (
        <SaleAgentModal
          isModalOpen={isModalOpen}
          saleRecord={saleRecord}
          closeAction={() => {
            setIsModalOpen(!isModalOpen);
          }}
        />
      )}
    </div>
  );
};

export default ShowingsMain;
