import { Modal } from 'antd'
import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import Button, { ButtonType } from '../components/Button'
interface PropertyDetailsProps {
  setIsShowingLoginModal?: (value: boolean) => void
  closeAction?: () => void
  isShowing: boolean
}
const AgentConnectingModal: React.FC<PropertyDetailsProps> = ({
  isShowing,
  setIsShowingLoginModal,
  closeAction,
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(isShowing)
  const history = useHistory()
  const isMobile = useMediaQuery({ maxWidth: 350 })
  return (
    <Modal
      centered
      visible={isModalVisible}
      onCancel={() => {
        setIsModalVisible(false)
        closeAction()
      }}
      footer={null}
      width={'auto'}
    >
      <p
        style={{
          fontWeight: 'bold',
          color: '#365073',
          fontSize: '25px',
        }}
      >
        Get
        <span style={{ borderBottom: '3px solid #C2D500' }}> in touch</span>
      </p>
      <br />
      <p>
        Ask your agent to get in touch with the listing <br />
        agent of this property to schedule a showing
      </p>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: isMobile ? 'column' : 'row',
          position: 'relative',
          width: '100%',
          marginTop: '20px',
        }}
      >
        <Button
          text="Sign Up"
          type={ButtonType?.PRIMARY}
          clickHandler={() => {
            setIsModalVisible(false)
            history.push('/register')
          }}
          className="notification-buyer-invitation__button--accept"
        />
        <span> </span>
        <Button
          text="Login"
          type={ButtonType?.PRIMARY}
          clickHandler={() => {
            setIsShowingLoginModal(true)
            setIsModalVisible(false)
            closeAction()
          }}
          className="notification-buyer-invitation__button--accept"
        />
      </div>
    </Modal>
  )
}
export default AgentConnectingModal
