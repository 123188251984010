import styled from "@emotion/styled";
import { useContext, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import buttonClose from "../assets/images/button-close.svg";
import forgotpassword from "../assets/images/Featured icon.svg";
import Button, { ButtonType } from "../components/Button";
import { notification } from "../components/notification";
import CustomSpinner from "../components/spinner/CustomSpinner";
import TextField, { TextFieldIconType, TextFieldType } from "../components/TextField";
import UserContext, { UserType } from "../contexts/UserContext";
import { sendResetForgotPasswordLinkForAgentService, sendResetForgotPasswordLinkForBuyerService } from "../services/NavihomeService";

interface ForgotPasswordModalPropsModel {
  isShowing: boolean;
  closeAction: () => void;
  setIsShowingLoginModal: any;
  setIsShowingTemporaryPasswordModal?: any;
  closeQuickModal?: () => void;
}
const Customheading = styled.h1`
  font-size: 19px;
  @media screen and (max-width: 420px) {
    font-size: 16px;
  }
`;

const ForgotPasswordModal: React.FC<ForgotPasswordModalPropsModel> = ({ isShowing, closeAction, setIsShowingLoginModal, closeQuickModal }): JSX.Element => {
  const { userState } = useContext(UserContext);
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isForgotModalShowing, setIsForgotModalShowing] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 500 });
  const handleLogIn = async () => {
    closeAction();
    setIsShowingLoginModal(true);
  };
  const closeLoginModal = () => {
    closeAction();
    closeQuickModal();
  };

  const handleForgotPassword = async () => {
    setIsForgotModalShowing(true);
    setIsLoading(true);
    if (email === "") {
      notification("Please enter your email.", "error");
      setIsLoading(false);
      return;
    }
    try {
      if (userState.userType === UserType.BUYER) {
        await sendResetForgotPasswordLinkForBuyerService(email);
      }

      if (userState.userType === UserType.AGENT) {
        await sendResetForgotPasswordLinkForAgentService(email);
      }
      setIsLoading(false);
      closeAction();
      notification("Reset instructions will be sent to your email shortly!", "success");
    } catch (error) {
      setIsLoading(false);
      notification(`${error}`, "error");
      console.log("ERROR", error);
    }
  };

  const goToRegister = () => {
    closeAction();
    history.push("/register");
  };

  const modalClassName = isShowing ? "forgot_password-modal u-visible" : "forgot_password-modal u-hidden";
  const cardClassName = isShowing ? "forgot_password-modal__card animation__pop-in" : "forgot_password-modal__card animation__pop-out";

  return (
    <div className={modalClassName}>
      <div className={cardClassName}>
        {isLoading && <CustomSpinner />}
        <div className="forgot_password-modal__row">
          <Customheading>
            Forgot p<span style={{ borderBottom: "3px solid #C2D500" }}>assword?</span>
          </Customheading>
          <img className="forgot_password-modal__close-button" src={buttonClose} onClick={closeLoginModal} alt="Close button" />
        </div>
        <div>
          <div className="forgot_password-logo">
            <img className="img-fluid" src={forgotpassword} onClick={closeAction} alt="Close button" />
            <p>No worries, we'll send you reset instructions.</p>
          </div>
        </div>
        <div className="forgot_password-modal__column">
          <p className="forgot_password-modal__error-message">{isForgotModalShowing ? userState.loginError : ""}</p>
          <TextField
            value={email}
            setValue={setEmail}
            placeholder="Enter Your Email"
            label="Email"
            type={TextFieldType.ICON_AND_LABEL}
            icon={TextFieldIconType.EMAIL}
            isRequired={true}
            width={isMobile ? 26.4 : 38.4}
            className="forgot_password-modal__email-field"
          />
          <Button type={ButtonType.PRIMARY} text="Reset Password" clickHandler={handleForgotPassword} width={24.5} className="forgot_password-modal__button" />
        </div>
        <div className="forgot_password-modal-login">
          <p className="login-modal__forgot-password" onClick={handleLogIn}>
            Login
          </p>

          <p style={{ marginTop: "6px" }}>
            Don't have an account?{" "}
            <span className="login-modal__register-now" onClick={goToRegister}>
              Register now
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
