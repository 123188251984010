import { CaretDownFilled } from "@ant-design/icons";
import styled from "@emotion/styled";
import {
  Avatar, Checkbox, Col, Collapse, Grid,
  Popover, Row, TimePicker, Typography
} from "antd";
import moment, { Moment } from "moment";
import { useContext, useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { SketchPicker } from "react-color";
import { useMediaQuery } from "react-responsive";
import "../assets/css/pages/agentcalendar.css";
import "../assets/css/pages/calendar-agent-page.css";
import { notification } from "../components/notification";
import UserContext from "../contexts/UserContext";
import {
  getAgentAccountSettings, getLinkedBuyersService, patchAgentAccountSettings,
  updateBuyerColor
} from "../services/NavihomeService";
import {
  CustomCheckboxGroups,
  CustomCheckboxs
} from "./Account/AccountAgentSettingPage";
import AgentCalendarMain from "./AgentCalendar/AgentCalendarMain";
import { getAllViewPropertyByAgetntId } from "./AgentCalendar/ulits";
import Button, { ButtonType } from "./Button";
import MiniCalendar from "./MiniCalendar";
import CustomSpinner from "./spinner/CustomSpinner";

const { Text } = Typography;
const { Panel } = Collapse;
const { useBreakpoint } = Grid;

const CustomClientRow = styled(Row)`
  padding: 5px 0px;
`;
const CustomCaretDownFilled = styled(CaretDownFilled)`
  color: #004876 !important;
`;
const CustomCollapse = styled(Collapse)`
  border-bottom: 1px solid #c2d500;
  margin-top: 5px;
  & .ant-collapse-header {
    font-weight: 600;
    color: #004876 !important;
    padding-left: 0px !important;
    background-color: #fff !important;
  }
  & .ant-collapse-content-box {
    padding-top: 0px !important;
    padding-left: 0px !important;
    background-color: #fff !important;
  }
`;
const CustomCheckbox = styled(Checkbox)`
  margin-top: 10px;

  .ant-checkbox-inner {
    background-color: #fff;
    border-color: #004876;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #004876;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: " ";
  }
`;

const CustomText = styled(Text)`
  font-size: 12px;
`;

const CustomRow = styled(Row)`
  margin-top: 10px !important;
`;
const CustomRow2 = styled(Row)`
  margin-top: 10px !important;
  display: flex;
  justifycontent: space-between;
  @media screen and (max-width: 1199px) {
    width: 30%;
  }
  @media screen and (max-width: 859px) {
    width: 100%;
  }
`;

const CustomWorkingHoursDiv = styled.div`
  .ant-picker-input > input {
    margin-left: 3px;
  }
  .ant-picker {
    margin-left: 6px;
  }
`;

export interface myCients {
  name: string;
  color: string;
  buyerId: number;
  email: string;
  imageUrl: string;
  phone: number;
}
export interface requestedProperty {
  propertyId: number;
  buyerId: number;
  agentId: number;
  propertyType: string;
  dataTime: string;
  address: object;
}
export interface calanderSettings {
  workingHoursfrom: string;
  workingHourTo: string;
  markWeekends: string;
  markSame: boolean;
  CalendarView?: string;
}

const AgentCalendar = (props: any) => {
  const [upCommingTours, setUpcomingTours] = useState<string[]>([]);
  const [requestedProperty, setRequestedProperty] =
    useState<requestedProperty[]>();
  const [nextmonth, setNextMonthFromMain] = useState<boolean>(false);
  const [prevmonth, setPrevMonthFromMain] = useState<boolean>(false);
  useEffect(() => {}, [
    prevmonth,
    nextmonth,
    setPrevMonthFromMain,
    setPrevMonthFromMain,
  ]);
  const [myClients, setMyClients] = useState<myCients[]>();
  const [dateValue, setDateValue] = useState<string>("");
  const [clickedDay, setClickedDay] = useState<string>("");
  const [starttoend, setStartToEnd] = useState<boolean>(false);
  const [currentview, setCurrentView] = useState("timeGridWeek");
  let [view, setView] = useState("timeGridWeek");
  const [agentAccount, setAgentAccountSettings] = useState({
    markWeekends: [],
    workingHoursFrom: "",
    workingHoursTo: "",
  });
  let [offCalDays, setOffCalDays] = useState([]);
  let [offCalHours, setOffCalHours] = useState({
    startTime: "",
    endTime: "",
  });
  const [calenderSetting, setCalenderSetting] = useState<calanderSettings>({
    workingHourTo: "",
    workingHoursfrom: "",
    markWeekends: "",
    markSame: false,
    CalendarView: "",
  });
  const [calResponse, setCalResponse] = useState([]);
  const screen = useBreakpoint();
  const { userState } = useContext(UserContext);
  const [viewPropertyReqEvents, setViewPropertyReqEvents] = useState<
    Array<any>
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  let [month, setMonth] = useState<Date>(new Date());
  const [monthName, setMonthName] = useState("");
  const [upcomingStartEndDate, setUpcomingStartEndDate] = useState<any>({
    startDate: null,
    endDate: null,
  });
  const loadBuyers = async () => {
    setIsLoading(true);
    try {
      if (!userState?.agent?.token) {
        setIsLoading(false);
        return notification("User State Agent Token Not Found!", "error");
      }
      const token = userState?.agent?.token;
      let response = await getAgentAccountSettings(userState.agent.agentId);

      if (
        Object?.keys(response)?.length == 0 ||
        response?.[0]?.["CalendarView"] == undefined ||
        Object.values(response?.[0]?.["CalendarView"])?.length == 0
      ) {
        setView("timeGridWeek");
      } else {
        setView(response?.[0]?.["CalendarView"] ?? "timeGridWeek");
      }
      let accountSettingData = response?.[0];
      let weekends = accountSettingData?.markWeekends?.split(",");

      setAgentAccountSettings({
        ...agentAccount,
        markWeekends: weekends,
        workingHoursFrom: accountSettingData?.workingHoursfrom,
        workingHoursTo: accountSettingData?.workingHourTo,
      });
      setIsLoading(false);

      const data: any = response[response?.length - 1];
      if (data) {
        setCalenderSetting({
          workingHourTo: data?.workingHourTo,
          workingHoursfrom: data?.workingHoursfrom,
          markWeekends: data?.markWeekends,
          markSame: data?.markSame,
          CalendarView: data?.currentview,
        });
      }
      const linkedBuyers = await getLinkedBuyersService(token);
      if (linkedBuyers?.length <= 0) {
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      return linkedBuyers;
    } catch (error) {
      setIsLoading(false);
    }
  };
  let getOffDays = async () => {
    let results = agentAccount?.markWeekends?.map((day: any) => {
      switch (day) {
        case "Sun":
          return 0;
        case "Mon":
          return 1;
        case "Tue":
          return 2;
        case "Wed":
          return 3;
        case "Thu":
          return 4;
        case "Fri":
          return 5;
        case "Sat":
          return 6;
      }
    });
    return results;
  };

  useEffect(() => {
    let getBuyers = async () => {
      let data = await loadBuyers();
      return data;
    };
    getBuyers()
      .then((value: any) => {
        if (value?.length > 0) {
          let clients = value?.map((buyer: any, i: number) => {
            return { ...buyer };
          });
          setMyClients(clients?.length > 0 ? clients : []);
          getAllViewpropertyCalendar(clients?.length > 0 ? clients : []);
        }
      })
      .catch((err) => {
        return err;
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getOffDays().then((value: any) => {
      let offDays = [0, 1, 2, 3, 4, 5, 6];
      if (value?.length > 0) {
        let offDaysArr = offDays.filter((day) => day == value.includes(day));
        setOffCalDays(offDays);
      }
    });
    let startTime = agentAccount?.workingHoursFrom;
    let endTime =
      agentAccount?.workingHoursTo?.split(" ")?.[4] == undefined
        ? agentAccount?.workingHoursTo
        : agentAccount?.workingHoursTo?.split(" ")?.[4];
    setOffCalHours((offCalHours) => ({
      ...offCalHours,
      startTime: startTime,
      endTime: endTime,
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentAccount]);

  const isCheckCalendar = (e: any) => {
    setCalenderSetting((prevState) => ({
      ...prevState,
      markSame: e.target.checked,
    }));
  };

  const isMobile = useMediaQuery({ maxWidth: 318 });
  const onChangeFrom = (time: Moment, timeString: string) => {
    setCalenderSetting((prevState) => ({
      ...prevState,
      workingHoursfrom: moment(time).format("HH:mm"),
    }));
  };
  const onChangeTo = (time: Moment, timeString: string) => {
    setCalenderSetting((prevState) => ({
      ...prevState,
      workingHourTo: moment(time).format("HH:mm"),
    }));
  };

  const markWeekends = (e: any) => {
    let str = e.toString();
    setCalenderSetting({ ...calenderSetting, markWeekends: str });
  };

  const updateCalenderSetting = async () => {
    if (!userState?.agent?.token) {
      setIsLoading(false);
      return notification("User State Agent Token Not Found!", "error");
    }
    setIsLoading(true);
    const token = userState?.agent?.token;
    if (calenderSetting?.CalendarView?.length <= 0)
      calenderSetting.CalendarView = currentview;

    const d = new Date();
    let day = moment(d).format("dddd").substring(0, 3);
    if (calenderSetting?.markWeekends?.length <= 0)
      calenderSetting.markWeekends = String(day);

    if (calenderSetting?.workingHoursfrom?.length <= 0) {
      setIsLoading(false);
      return notification("Please select start working hours", "error");
    }

    if (calenderSetting?.workingHourTo?.length <= 0) {
      setIsLoading(false);
      return notification("Please select end working hours", "error");
    }

    const result: any = await patchAgentAccountSettings(
      token,
      calenderSetting,
      userState?.agent?.agentId
    );

    let accountSettingData = result?.[0];
    let weekends = accountSettingData?.markWeekends?.split(",");

    setAgentAccountSettings({
      ...agentAccount,
      markWeekends: weekends,
      workingHoursFrom: accountSettingData?.workingHoursfrom,
      workingHoursTo: accountSettingData?.workingHourTo,
    });
    setIsLoading(false);
    const data: any = result[result?.length - 1];
    if (data) {
      setCalenderSetting({
        workingHourTo: data?.workingHourTo,
        workingHoursfrom: data?.workingHoursfrom,
        markWeekends: data?.markWeekends,
        markSame: data?.markSame,
        CalendarView: data?.currentview,
      });
      setCalResponse(result);
      notification("Updated successfully", "success");
      setIsLoading(false);
    }
    setIsLoading(false);
    getAllViewpropertyCalendar();
  };

  const getAllViewpropertyCalendar = async (clients?: Array<any>) => {
    try {
      setIsLoading(true);
      const acceptedOnly: any = await getAllViewPropertyByAgetntId(
        userState?.agent?.agentId,
        userState?.agent?.token,
        "calendar",
        userState
      );

      acceptedOnly?.forEach((item: any) => {
        (clients?.length > 0 ? clients : myClients)?.forEach((ele: any) => {
          if (ele.buyerId === item.buyerId) {
            item["color"] = ele?.color ?? "#008476";
          }
        });
      });

      setViewPropertyReqEvents(acceptedOnly);
      setIsLoading(false);
    } catch (error) {
      console.log("ERROR: ", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllViewpropertyCalendar();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && <CustomSpinner />}
      <Row justify="space-between">
        <Col
          span={screen.xl ? 5 : 24}
          style={{ marginTop: screen.xl ? "60px" : "20px" }}>
          <MiniCalendar
            setDateValue={setDateValue}
            setClickedDay={setClickedDay}
            setStartToEnd={setStartToEnd}
            setUpcomingStartEndDate={setUpcomingStartEndDate}
            setNextMonthFromMain={nextmonth}
            setPrevMonthFromMain={prevmonth}
            month={month}
          />
          <CustomCollapse
            bordered={false}
            expandIconPosition={"right"}
            expandIcon={() => <CustomCaretDownFilled />}>
            <Panel header="My Clients" key="1">
              <div style={{ height: "20vh", overflowY: "scroll" }}>
                {myClients?.map((client: any, index) => {
                  return (
                    <CustomClientRow justify="space-between">
                      <CustomText>{client?.name}</CustomText>
                      <Popover
                        content={
                          <SketchPicker
                            color={client?.color}
                            onChange={async (val) => {
                              setIsLoading(true);
                              try {
                                const payload = {
                                  colorCode: val.hex,
                                  buyerId: client.buyerId,
                                  agentId: userState?.agent?.agentId,
                                };
                                const temp = [...myClients];
                                temp[index]["color"] = val.hex;
                                setMyClients(temp);
                                const res: any = await updateBuyerColor(
                                  payload
                                );
                                if (res?.success) getAllViewpropertyCalendar();
                                setIsLoading(false);
                              } catch (error) {
                                setIsLoading(false);
                                console.error("ERROR: ", error);
                              }
                            }}
                          />
                        }
                        title="Title">
                        <Avatar
                          size={20}
                          style={{
                            backgroundColor: client?.color,
                            cursor: "pointer",
                          }}
                        />
                      </Popover>
                    </CustomClientRow>
                  );
                })}
              </div>
            </Panel>
          </CustomCollapse>
          <CustomCollapse
            bordered={false}
            expandIconPosition={"right"}
            expandIcon={() => <CustomCaretDownFilled />}>
            <Panel header="Calendar Settings " key="1">
              <Row className="subTitle">Mark your weekends: </Row>
              <Row>
                <CustomCheckboxGroups
                  onChange={(e: any) => markWeekends(e)}
                  value={calenderSetting.markWeekends.split(",")}>
                  <CustomCheckboxs value="Mon">M</CustomCheckboxs>
                  <CustomCheckboxs value="Tue">T</CustomCheckboxs>
                  <CustomCheckboxs value="Wed">W</CustomCheckboxs>
                  <CustomCheckboxs value="Thu">TH</CustomCheckboxs>
                  <CustomCheckboxs value="Fri">F</CustomCheckboxs>
                  <CustomCheckboxs value="Sat">Sa</CustomCheckboxs>
                  <CustomCheckboxs value="Sun">S</CustomCheckboxs>
                </CustomCheckboxGroups>
              </Row>
              <CustomRow className="subTitle">
                Mark your working hours:
              </CustomRow>
              <Row>
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "space-between",
                    width: "30%",
                  }}>
                  <CustomWorkingHoursDiv>
                    <TimePicker
                      allowClear={true}
                      use12Hours
                      format="hh:mm a"
                      onChange={onChangeFrom}
                      style={{
                        borderRadius: "5px",
                        border: "1px solid #004876",
                        width: "120px",
                      }}
                      defaultValue={
                        calenderSetting?.workingHoursfrom
                          ? moment(calenderSetting?.workingHoursfrom, "hh:mm a")
                          : moment(moment(), "hh:mm")
                      }
                    />
                  </CustomWorkingHoursDiv>
                  <CustomWorkingHoursDiv>
                    <TimePicker
                      allowClear={true}
                      use12Hours
                      format="hh:mm a"
                      onChange={onChangeTo}
                      style={{
                        borderRadius: "5px",
                        border: "1px solid #004876",
                        width: "120px",
                      }}
                      defaultValue={
                        calenderSetting?.workingHourTo
                          ? moment(calenderSetting?.workingHourTo, "hh:mm a")
                          : moment(moment(), "hh:mm")
                      }
                    />
                  </CustomWorkingHoursDiv>
                </div>
              </Row>
              <CustomRow2>
                <CustomCheckbox
                  checked={calenderSetting.markSame}
                  onChange={isCheckCalendar}
                  style={{ marginBottom: "10px" }}>
                  {" "}
                  Mark the same for all my properties
                </CustomCheckbox>
                <Button
                  type={ButtonType.PRIMARY}
                  clickHandler={() => updateCalenderSetting()}
                  text="Save"
                />
              </CustomRow2>
            </Panel>
          </CustomCollapse>
        </Col>
        <Col span={screen.xl ? 18 : 24}>
          <AgentCalendarMain
            myClients={myClients}
            requestedProperty={requestedProperty}
            loadBuyers={loadBuyers}
            upCommingTours={upCommingTours}
            calResponse={calResponse}
            dateValue={dateValue}
            clickedDay={clickedDay}
            setCurrentView={setCurrentView}
            currentMonth={starttoend}
            setStartToEnd={setStartToEnd}
            setMonthName={setMonthName}
            monthName={monthName}
            setNextMonthFromMain={setNextMonthFromMain}
            setPrevMonthFromMain={setPrevMonthFromMain}
            setMonth={setMonth}
            month={month}
            viewPropertyReqEvents={viewPropertyReqEvents}
            getAllViewpropertyCalendar={getAllViewpropertyCalendar}
            view={view}
            setView={setView}
            agentAccount={agentAccount}
            setAgentAccountSettings={setAgentAccountSettings}
            offCalDays={offCalDays}
            offCalHours={offCalHours}
            setOffCalDays={setOffCalDays}
            setOffCalHours={setOffCalHours}
          />
        </Col>
      </Row>
    </>
  );
};
export default AgentCalendar;
