import { useState, useContext } from 'react'
import UserContext, { UserType } from '../contexts/UserContext'
import buttonClose from '../assets/images/button-close.svg'
import TextField, {
  TextFieldType,
  TextFieldIconType,
} from '../components/TextField'
import Button, { ButtonType } from '../components/Button'
import Checkbox, { CheckboxType } from '../components/Checkbox'
import { useHistory } from 'react-router'
import {
  sendForgotPasswordForBuyerService,
  sendForgotPasswordForAgentService,
} from '../services/NavihomeService'
import { useMediaQuery } from 'react-responsive'
import { notification } from '../components/notification'
import CustomSpinner from '../components/spinner/CustomSpinner'
import QuickBuyerRegistrationModal from '../modals/QuickBuyerRegistrationModal'
import ForgotPasswordModal from '../modals/forgotPasswordModal';
import styled from '@emotion/styled'
interface SaveSearchModalProps {
  isShowing: boolean
  closeAction: () => void
  setIsShowingForgotPasswordModal: any
}
const Customheading = styled.h1`
font-size: 19px;
`
const Custompara = styled.p`
margin-left: 3rem;
margin-top: 1rem;
`
const SaveSearchModal: React.FC<SaveSearchModalProps> = ({
  isShowing,
  closeAction,
  setIsShowingForgotPasswordModal,
}) => {
  const { userState, logIn } = useContext(UserContext)
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [keepMeSignedIn, setkeepMeSignedIn] = useState(false)
  const [isQuickRegistrationModal, setIsQuickRegistrationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [isDatabaseError, setIsDatabaseError] = useState(false)


  const isMobile = useMediaQuery({ maxWidth: 500 })

  const handleLogIn = async () => {
    setIsDatabaseError(true);
    try {
      if (!email || !password)
        return notification('Please Enter Input field.', 'error')
      setIsLoading(true)
      const success = await logIn(email, password)
      if (!success) {
        setIsLoading(false)
        console.log("Error:", success);
        return;
        // return notification('Something Went Wrong!', 'error')
      }
      setIsLoading(false)
      closeAction()
      history.push('/')
    } catch (error) {
      return notification('Something Went Wrong', 'error')
    }
  }
  const handleOpenForgotPasswordModal = () => {
    closeAction()
    setIsShowingForgotPasswordModal(true)
  }

  const handleForgotPassword = async () => {
    if (email === '') {
      notification('Please enter your email.', 'error')
      return
    }

    if (userState.userType === UserType.BUYER) {
      await sendForgotPasswordForBuyerService(email)
    }

    if (userState.userType === UserType.AGENT) {
      await sendForgotPasswordForAgentService(email)
    }
    closeAction()
    notification(
      'A temporary password will be sent to your email shortly!',
      'success',
    )
  }
  const handleCloseLoginModal = () => {
    setIsQuickRegistrationModal(!isQuickRegistrationModal);
    closeAction()
  };
  // const goToRegister = () => {
  //   closeAction()
  //   history.push('/register')
  // }

  const modalClassName = isShowing
    ? 'login-modal u-visible'
    : 'login-modal u-hidden'
  const cardClassName = isShowing
    ? 'requesting-modal__card animation__pop-in'
    : 'requesting-modal__card animation__pop-out'

  return (
    <div className={modalClassName}>
      <div className={cardClassName}>
        <div className="login-modal__row">
          {isLoading && <CustomSpinner />}
          <Customheading>Save
            <span style={{ borderBottom: "3px solid #C2D500" }}> Search?</span>
          </Customheading>
          <img
            className="login-modal__close-button"
            src={buttonClose}
            onClick={closeAction}
            alt="Close button"
          />
        </div>
        <Custompara>
          Sign in first. Don't have an account?{' '}
          <span
            style={{ color: '#EB752F' }}
            className="login-modal__register-now"
            onClick={() => {
              setIsQuickRegistrationModal(true);
            }}
          >
            Register now
          </span>
        </Custompara>
        <div className="login-modal__column">
          <p className="login-modal__error-message">{isDatabaseError ? userState?.loginError : ""}</p>
          <TextField
            value={email}
            setValue={setEmail}
            placeholder="janedoe@hotmail.com"
            label="Email"
            type={TextFieldType.ICON_AND_LABEL}
            icon={TextFieldIconType.EMAIL}
            isRequired={true}
            width={isMobile ? 26.4 : 38.4}
            className="login-modal__email-field"
          />
          <TextField
            value={password}
            setValue={setPassword}
            placeholder="••••••••"
            label="Password"
            type={TextFieldType.PASSWORD}
            icon={TextFieldIconType.PASSWORD}
            isRequired={true}
            width={isMobile ? 26.4 : 38.4}
            className="login-modal__password-field"
          />
          <Checkbox
            type={CheckboxType.SMALL}
            value={keepMeSignedIn}
            setValue={setkeepMeSignedIn}
            text="Keep me signed in"
          />
          <Button
            type={ButtonType.PRIMARY}
            text="SIGN IN"
            clickHandler={handleLogIn}
            width={18.5}
            className="login-modal__button"
          />
          <p
            className="login-modal__forgot-password"
            onClick={handleOpenForgotPasswordModal}
          >
            Forgot Password?
          </p>
        </div>
      </div>
      {isQuickRegistrationModal && (
        <QuickBuyerRegistrationModal
          isShowing={isQuickRegistrationModal}
          closeAction={handleCloseLoginModal}
          setIsShowingLoginModal={!setIsShowingForgotPasswordModal}
        />
      )}
    </div>
  )
}
export default SaveSearchModal
