import { Table } from "antd";
import { Fragment, useContext, useEffect, useState } from "react";
import type { TableRowSelection } from "antd/lib/table/interface";
import type { ColumnsType } from "antd/lib/table";
import moment from "moment";
import AgentContext from "../contexts/AgentContext";
import UserContext, { UserType } from "../contexts/UserContext";
import { useHistory } from "react-router-dom";
import { notification } from "./notification";

interface TourSummaryItemData {
  date: string;
  dayOfWeek: string;
  time: string;
  timeOfDay: string;
  client: string;
  properties: number;
  statusPrimary: string;
  statusSecondary: string;
  formId: number;
  responseData?: any;
  saleAgent?: string;
  type?: string;
  value?: string;
}

interface ToursSummaryProps {
  tours: TourSummaryItemData[];
  upcomingTourDate?: any;
  source?: string;
  upcomingStartEndDate?: any;
  type?: string;
}

interface DataType {
  key: React.Key;
  date: any;
  time: any;
  client: string;
  properties: any;
  status: any;
}

const ToursSummary: React.FC<ToursSummaryProps> = ({
  type,
  tours,
  source,
  upcomingStartEndDate,
}) => {

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date",
      render: (_, { date }) => (
        <div>
          <div>{date?.date}</div>
          <div>{date?.dayOfWeek}</div>
        </div>
      ),
    },
    {
      title: "Time",
      dataIndex: "time",
      render: (_, { time }) => (
        <div>
          <div>{time?.time}</div>
          <div>{time?.timeOfDay}</div>
        </div>
      ),
    },
    {
      title: "Client",
      dataIndex: "client",
    },
    {
      title: type === "home" ? "Details" : "Properties",
      dataIndex: "properties",
      render: (_, { properties }) => (
        <div>
          <div>{properties?.type === "Showing" ? "" : properties?.type}</div>
          <div>
            {properties?.type === "Listing" ? (
              <>
                <span style={{ textDecoration: "underline", color: "#EB752F" }}>
                  {properties?.value === userState?.agent?.name
                    ? "You"
                    : properties?.value}
                </span>{" "}
                - Sale Agent
              </>
            ) : (
              <span>
                {properties?.value === 1
                  ? "1 Property"
                  : `${properties?.value} properties`}
              </span>
            )}
          </div>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, { status }) => (
        <div>
          <div>{status?.statusPrimary}</div>
          <div>{status?.statusSecondary}</div>
        </div>
      ),
    },
  ];
  const [data, setData]: any = useState([]);
  const [defaultData, setDefaultData]: any = useState();
  const { userState } = useContext(UserContext);
  const { selectBuyer } = useContext(AgentContext);

  const history = useHistory();

  const handleBuyerTourDetail = (event: any) => {


    let propId: any = event?.responseData?.mlsId,
      userBuyerId: any = event?.responseData?.buyerId,
      propListingId: any = event?.responseData?.listingId;
    if (event?.properties?.type === "Showing") {

      let url = '';
      let isPropertiesPageText = 'no';

      url = `/properties/${propId}?type=''&id=${userBuyerId}&listingId=${propListingId}&openHouseKey=''&filter=''${isPropertiesPageText}&buyerDashboard=''&isViewed=${false}`

      history.push(url);
      // history.push(`/properties/${propId}`);
      return;
    }

    if (event?.properties?.type === "Listing") {
      let propId = event?.responseData?.mlsId;
      history.push(`/propertydetails/${propId}`);
      return;
    }
    
    let buyerId = -1;
    let name = "", phone = "", email = "", preferredContact = "";
    let routeStatus = event?.responseData?.status;
    buyerId = event?.responseData?.buyerId
      ? event?.responseData?.buyerId
      : event?.responseData?.buyer?.buyerId;
    name = event?.responseData?.buyer?.name;
    phone = event?.responseData?.buyer?.phone;
    email = event?.responseData?.buyer?.email;
    preferredContact = event?.responseData?.buyer?.preferredContact;
    selectBuyer(buyerId, name, phone, email, preferredContact);

    const propertyCount = event?.status?.statusPrimary.split("/");
    const acceptedPropertyCount = propertyCount?.[0];
    const totalPropertyCount = propertyCount?.[1]?.split(" ")?.[0];

    if (event?.formId === null && event?.responseData?.viewPropertyCalendarId) {
      let propId = event?.responseData?.mlsId;
      history.push(`/properties/${propId}`);
      return;
    }

    if (source === "previousTour") {
      history.push(
        `/tourdetail?buyerId=${buyerId}&formId=${event?.formId}&statusType=${event?.responseData?.saveTourStatus}`
      );
    }
    if (acceptedPropertyCount === totalPropertyCount) {
      history.push(
        `/common?buyerId=${buyerId}&formId=${event?.formId}&statusType=${event?.responseData?.saveTourStatus}&routeStatus=${routeStatus}&allAccepted=allAccepted&role=${event?.responseData?.role}`
      );
    } else {
      history.push(
        `/common?buyerId=${buyerId}&formId=${event?.formId}&statusType=${event?.responseData?.saveTourStatus}&routeStatus=${routeStatus}&allAccepted=notAllAccepted&role=${event?.responseData?.role}`
      );
    }
  };

  useEffect(() => {
    if (upcomingStartEndDate?.startDate !== null) {
      setDefaultData([]);
      if (tours?.length > 0) {
        let finalArr: any = [];
        tours.map((item, i) => {
          let bool2 =
            moment(item?.date).isBetween(
              upcomingStartEndDate?.startDate,
              upcomingStartEndDate?.endDate
            ) ||
            moment(item?.date).isSame(upcomingStartEndDate?.startDate) ||
            moment(item?.date).isSame(upcomingStartEndDate?.endDate);
          // if (!upcomingTourDate || upcomingTourDate === item.date) {
          // if(item?.date>=upcomingStartEndDate?.startDate && item?.date<=upcomingStartEndDate?.endDate){
          if (bool2) {
            finalArr.push({
              key: i,
              date: { date: item?.date, dayOfWeek: item?.dayOfWeek },
              time: { time: item?.time, timeOfDay: item?.timeOfDay },
              client: item?.client,
              properties:
                item?.type === "Tour"
                  ? {
                    type: "Tour",
                    value: item?.properties,
                  }
                  : item?.type === "Listing"
                    ? {
                      type: "Listing",
                      value: item?.saleAgent,
                    }
                    : {
                      type: "Showing",
                      value: 1,
                    },
              status: {
                statusPrimary: item?.statusPrimary,
                statusSecondary: item?.statusSecondary,
              },
              formId: item?.formId,
              responseData: item?.responseData,
            });
          }
          return finalArr;
        });
        setData(finalArr);
      }
    }
  }, [tours, upcomingStartEndDate]);

  useEffect(() => {
    if (tours?.length > 0) {
      let finalArr: any = [];

      let currDate = moment().format("LL").toString();
      tours.forEach((item, i) => {

        let curr_future_date = moment(item?.date).isSameOrAfter(currDate);
        if (curr_future_date) {
          finalArr.push({
            key: i,
            date: { date: item?.date, dayOfWeek: item?.dayOfWeek },
            time: { time: item?.time, timeOfDay: item?.timeOfDay },
            client: item?.client,
            properties:
              item?.type === "Tour"
                ? {
                  type: "Tour",
                  value: item?.properties,
                }
                : item?.type === "Listing"
                  ? {
                    type: "Listing",
                    value: item?.saleAgent,
                  }
                  : {
                    type: "Showing",
                    value: 1,
                  },
            status: {
              statusPrimary: item?.statusPrimary,
              statusSecondary: item?.statusSecondary,
            },
            formId: item?.formId,
            responseData: item?.responseData,
          });
        }
      });
      setDefaultData(finalArr);
    }
  }, [tours]);

  return (
    <div className="">
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: (event: any) => {
              handleBuyerTourDetail(record);
            },
          };
        }}
        style={{ width: "100% !important", cursor: "pointer" }}
        columns={columns}
        dataSource={data?.length > 0 ? data : defaultData}
        pagination={{ defaultCurrent: 1, pageSize: 5 }}
      />
    </div>
  );
};

export default ToursSummary;
