import React, { useReducer } from 'react';

enum AgentActionType {
  SAVE_TO_LOCAL_STORAGE = 'SAVE_TO_LOCAL_STORAGE',
  LOAD_FROM_LOCAL_STORAGE = 'LOAD_FROM_LOCAL_STORAGE',
  SELECT_BUYER = 'SELECT_BUYER',
  CLEAR_BUYER = 'CLEAR_BUYER'
}


type AgentAction =
  | { type: AgentActionType.SAVE_TO_LOCAL_STORAGE }
  | { type: AgentActionType.LOAD_FROM_LOCAL_STORAGE }
  | { type: AgentActionType.SELECT_BUYER, payload: { buyerId: number, buyerName: string, buyerPhone: string, buyerEmail: string, buyerPreferredContact: string } }
  | { type: AgentActionType.CLEAR_BUYER }

export interface AgentState {
  buyerId: number | null,
  buyerName: string | null,
  buyerPhone: string | null,
  buyerEmail: string | null,
  buyerPreferredContact: string | null,
}

type AgentContextType = {
  agentState: AgentState;
  restoreAgentContext: () => void;
  selectBuyer: (buyerId: number, buyerName: string, buyerPhone: string, buyerEmail: string, buyerPreferredContact: string) => void;
  clearBuyer: () => void;
}

const initialState: AgentState = {
  buyerId: null,
  buyerName: null,
  buyerPhone: null,
  buyerEmail: null,
  buyerPreferredContact: null,
}

const AgentContext = React.createContext<AgentContextType | undefined>(undefined);

const reducer = (state: AgentState, action: AgentAction): AgentState => {
  const storage = window.localStorage;
  switch (action.type) {
    case AgentActionType.SAVE_TO_LOCAL_STORAGE:
      storage.setItem('agentContext', JSON.stringify(state));
      return { ...state };
    case AgentActionType.LOAD_FROM_LOCAL_STORAGE:
      const data = storage.getItem('agentContext');
      if (data) {
        const parsedData = JSON.parse(data);
        return { ...parsedData };
      }
      return { ...state };
    case AgentActionType.SELECT_BUYER:
      return { ...state, buyerId: action.payload.buyerId, buyerName: action.payload.buyerName, buyerPhone: action.payload.buyerPhone, buyerEmail: action.payload.buyerEmail, buyerPreferredContact: action.payload.buyerPreferredContact }
    case AgentActionType.CLEAR_BUYER:
      return { ...initialState };
    default:
      return state;
  }
}

export const AgentProvider: React.FC = ({ children }) => {
  const [agentState, dispatch] = useReducer(reducer, initialState);

  const restoreAgentContext = () => {
    dispatch({ type: AgentActionType.LOAD_FROM_LOCAL_STORAGE });
  }

  const selectBuyer = (buyerId: number, buyerName: string, buyerPhone: string, buyerEmail: string, buyerPreferredContact: string) => {
    dispatch({ type: AgentActionType.SELECT_BUYER, payload: { buyerId, buyerName, buyerPhone, buyerEmail, buyerPreferredContact } });
    dispatch({ type: AgentActionType.SAVE_TO_LOCAL_STORAGE });
  }

  const clearBuyer = () => {
    dispatch({ type: AgentActionType.CLEAR_BUYER });
    dispatch({ type: AgentActionType.SAVE_TO_LOCAL_STORAGE });
  }

  return (
    <AgentContext.Provider value={{ agentState, restoreAgentContext, selectBuyer, clearBuyer }}>
      {children}
    </AgentContext.Provider >
  );
};

export default AgentContext;