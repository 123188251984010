/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from "@emotion/styled";
import { Col, Row } from "antd";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import BuyerCalendar from "../components/BuyerCalendar";
import MiniCalendar from "../components/MiniCalendar";
const CalendarBuyerPage: React.FC = () => {
  const CustomBackP = styled.p`
    display: inline-block;
    margin-left: 12px;
    margin-top: 16px;
    cursor: pointer;
    font-size: 17px;
    &:hover {
      text-decoration: underline;
      font-weight: 600;
    }
  `;
  const [buyerdateValue, setBuyerDateValue] = useState<string>("");
  const [clickedDay, setClickedDay] = useState<string>("");
  const [buyerstarttoend, setBuyerStartToEnd] = useState<boolean>(false);
  const [presentview, setPresentView] = useState<string>(" ");
  let [month, setMonth] = useState<Date>(new Date());
  const [upcomingStartEndDate, setUpcomingStartEndDate] = useState<any>({
    startDate: null,
    endDate: null,
  });
  const history = useHistory();
  return (
    <>
      <CustomBackP
        onClick={() => {
          history.push("/");
        }}
      >
        Back
      </CustomBackP>
      <div className="calendar-agent-page__container">
        <h3>My Calendar</h3>
        <br />
        <br />
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={5}
            className="calendar-agent-top"
          >
            <MiniCalendar
              setDateValue={setBuyerDateValue}
              setClickedDay={setClickedDay}
              setStartToEnd={setBuyerStartToEnd}
              setUpcomingStartEndDate={setUpcomingStartEndDate}
              month={month}
            />
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={19}>
            <div>
              <BuyerCalendar
                clickedDay={clickedDay}
                currentMonth={buyerstarttoend}
                dateValue={buyerdateValue}
                setPresentView={setPresentView}
                setMonth={setMonth}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CalendarBuyerPage;
