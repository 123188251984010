import { UserOutlined } from '@ant-design/icons';
import { Avatar, Col, Input, Modal, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Button, { ButtonType } from '../../components/Button';
import iconEmail from '../../assets/images/icon-email.svg';
import iconPhone from '../../assets/images/icon-phone.svg';
import validator from 'email-validator';
import { notification } from '../../components/notification';
import {
  listedAgentShareMail,
  postMessageToUser,
} from '../../services/NavihomeService';
import UserContext, { UserType } from '../../contexts/UserContext';
import { useHistory, useLocation } from 'react-router';
import AgentContext from '../../contexts/AgentContext';
const { TextArea } = Input;

interface ReginaPropsModel {
  isShowing: boolean;
  closeAction: () => void;
  item?: any;
  agentEmail?: string;
  agentPhone?: string;
  modeOfContact?: string;
  agentPhoto?: string;
  userName?: string;
  listingAgentId?: number | string;
  listingAgentName?: string;
  listAgentSName?: string;
  listingAgentActivatedPlan?: string;
}
const Regina: React.FC<ReginaPropsModel> = ({
  item,
  isShowing,
  closeAction,
  agentEmail,
  agentPhone,
  modeOfContact,
  agentPhoto,
  userName,
  listingAgentId,
  listingAgentName,
  listAgentSName,
  listingAgentActivatedPlan
}) => {
  const history = useHistory();
  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let param = useQuery();
  const { agentState } = useContext(AgentContext)

  const handleOk = () => {
    closeAction();
  };
  const [message, setMessage] = useState<string>('');
  const { userState } = useContext(UserContext);
  const isMobile = useMediaQuery({ maxWidth: 500 });
  const messageHandler = async () => {
    if (!validator.validate(agentEmail)) {
      return notification('Please enter the correct email format.', 'error');
    }
    let token = '';
    let username = '';
    if (userState?.userType === UserType.AGENT) {
      token = userState?.agent?.token;
      username = userState?.agent?.name;
    } else {
      token = userState?.buyer?.token;
      username = userState?.buyer?.name;
    }

    if (modeOfContact === 'CONTACT_RADIO_BOTH') {
      // use aws mail service for mail
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const result = await listedAgentShareMail(
          agentEmail,
          message,
          username,
          token
        );

        // send message to phone number

        postMessageToUser(agentPhone, message);

        closeAction();
      } catch (error) {
        console.error(error);
      }

      // use plivo to send message on number
    } else if (modeOfContact === 'CONTACT_RADIO_EMAIL') {
      // use aws mail service

      try {
         await listedAgentShareMail(
          agentEmail,
          message,
          username,
          token
        );
        closeAction();
      } catch (error) {
        console.error(error);
      }
    } else {
      // use plivo to send message on number
      postMessageToUser(agentPhone, message);
    }
    closeAction();
  };

  const handleViewPropertyCal = () => {

    let mlsId = item?.mlsId,
      listingId = item?.listingId,
      buyerId = item?.buyerId ?? agentState?.buyerId,
      upComingListingAgentId = item?.listingAgentId ?? listingAgentId,
      upComingListingAgentSellerName = item?.client ?? listAgentSName,
      upComingListingAgentName = item?.listingAgent?.name ?? listingAgentName;
    if (item?.hasOwnProperty('isListed')) {

      history.push(
        `/agentBuyerPropertyCalendar/${mlsId}/?query=${buyerId}&listingId=${listingId}&listingAgentId=${upComingListingAgentId}&client=${upComingListingAgentSellerName}&listingAgent=${upComingListingAgentName}`
      );
    } else {
      let buyerId = param.get('id') ? param.get('id') : agentState?.buyerId;
      history.push(
        `/agentBuyerPropertyCalendar/${mlsId}/?query=${buyerId}&listingId=${listingId}&listingAgentId=${upComingListingAgentId}&client=${upComingListingAgentSellerName}&listingAgent=${upComingListingAgentName}`
      );
    }
  };
  return (
    <>
      <Modal
        centered
        visible={isShowing}
        onOk={handleOk}
        onCancel={handleOk}
        width={450}
        footer={false}
      >
        <Row>
          <h1>{userName}</h1>
        </Row>
        <Row style={{ marginTop: '8px' }}>
          <h5 style={{ color: 'black', textAlign: 'left' }}>
            {userName} is the seller agent of the property on
          </h5>
          <h5 style={{ color: 'black', textAlign: 'left' }}>
            {item?.address?.streetNumber} {item?.address?.streetName}{' '}
            {item?.address?.city}, {item?.address?.state}{' '}
            {item?.address?.postalCode}
          </h5>
          <br />
        </Row>

        <Row style={{ marginTop: '10px' }}>
          <Col xs={24} sm={24} md={24} lg={7} style={{ marginBottom: '10px' }}>
            <Avatar size={100} src={listingAgentActivatedPlan === 'Navihome Advanced Plan' || listingAgentActivatedPlan === 'Navihome Premimum Plan' ? agentPhoto : ''} icon={listingAgentActivatedPlan === 'Navihome Advanced Plan' || listingAgentActivatedPlan === 'Navihome Premimum Plan' ? '' : <UserOutlined />} shape={'circle'} />
          </Col>
          <Col span={17} style={{ marginBottom: '10px' }}>
            <div style={{ display: 'flex', marginBottom: '10px' }}>
              <img
                src={iconPhone}
                alt="Phone"
                style={{ marginRight: '10px' }}
              />

              <h5>
                {/* {'+1'} */}
                {'+'}
                {agentPhone}
              </h5>
            </div>
            <div style={{ display: 'flex' }}>
              <img
                src={iconEmail}
                alt="Email"
                style={{ marginRight: '10px' }}
              />
              <h5>{agentEmail}</h5>
            </div>
            {userState?.userType === UserType?.AGENT && (
              <p
                style={{
                  marginTop: '10px',
                  color: 'coral',
                  textDecorationLine: 'underline',
                  cursor: 'pointer',
                }}
                onClick={handleViewPropertyCal}
              >
                View Property Calendar
              </p>
            )}
          </Col>
        </Row>
        <h5 style={{ textAlign: 'left' }}>Message to listing agent:</h5>
        <TextArea
          rows={4}
          value={message}
          onChange={(e) => {
            setMessage(e?.target?.value);
          }}
          style={{
            border: 'true',
            borderColor: 'blue',
            boxShadow: '2px 2px 2px grey',
            borderRadius: '5px',
            marginBottom: '10px',
          }}
          placeholder="Hello , my clients would like to view the property on 3430,38 ave W, seattle, WA 98199, on March 21,2021 from 10:50 am - 11:10 am, Awaiting your confirmation"
        />
        {/* <Row style={{alignContent:'center'}}>
          <Button type="primary" shape="round" style={{ marginLeft: '30%', marginTop: '2px' }} onClick={handleOk}>
            SEND MESSAGE
          </Button>
        </Row> */}
        {/* <Row style={{ marginLeft: '30%', marginTop: '5%', width: '60%' }}> */}
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Button
            type={ButtonType.PRIMARY}
            text="SEND MESSAGE"
            clickHandler={messageHandler}
            className="route_tour-modal__button"
            width={isMobile ? '100%' : '18.5rem'}
          />
        </div>
        {/* </Row> */}
      </Modal>
    </>
  );
};

export default Regina;
