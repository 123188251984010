interface SelectFieldOptionProps {
  value: string;
  didSelectValue: (value: string) => void;
}

const SelectFieldOption: React.FC<SelectFieldOptionProps> = ({ value, didSelectValue }) => {
  const handleClick = () => {
    didSelectValue(value);
  }

  return (
    <li className="select-field__option" onClick={handleClick}>{value}</li>
  )
}

export default SelectFieldOption;