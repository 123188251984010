import { Fragment, useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import SearchField from "../../components/SearchField";
import CustomSpinner from "../../components/spinner/CustomSpinner";
import UserContext from "../../contexts/UserContext";
import SearchIcon from "../../assets/images/icon-search.svg";
import { getALLSellerListedProperties } from "../../services/NavihomeService";
import SellerPropetirsCard from "./sellerProperties";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";

interface ISaleAgenteProps {
  totalListedProperty: Array<any>
}

const CustomHeading = styled.h1`
  font-size: 32px;
  @media screen and (max-width: 432px) {
    font-size: 28px;
  }
  @media screen and (max-width: 283px) {
    font-size: 24px;
  }
  @media screen and (max-width: 440px) {
    padding-bottom: 10px;
  }
`;
const Listings: React.FC<ISaleAgenteProps> = ({totalListedProperty}) => {
  const { userState } = useContext(UserContext);
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const [filteredProperties, setFilteredProperties] = useState([]);
  // const [totalListedProperty, setTotalListedProperty] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchedText, setSearchedText] = useState<string>("");
  const [redirectUrl,setRedirectUrl] = useState<string>('');
  const history = useHistory();



  const handleFilterProperty = async (query: string) => {
    setSearchedText(query);
    const searchedProperties: any = totalListedProperty?.filter((item: any) => {
      if (
        item?.listPrice?.toString()?.includes(query?.toString()) ||
        query?.toString()?.includes(item?.listPrice?.toString())
      )
        return item;
      if (
        item?.address?.city?.toUpperCase()?.includes(query?.toUpperCase()) ||
        query?.toUpperCase()?.includes(item?.address?.city?.toUpperCase())
      )
        return item;
      if (
        item?.address?.streetName
          ?.toUpperCase()
          ?.includes(query?.toUpperCase()) ||
        query?.toUpperCase()?.includes(item?.address?.streetName?.toUpperCase())
      )
        return item;
      if (
        item?.property?.type?.toUpperCase()?.includes(query?.toUpperCase()) ||
        query?.toUpperCase()?.includes(item?.property?.type?.toUpperCase())
      )
        return item;
      if (
        item?.address?.postalCode?.includes(query) ||
        query?.includes(item?.address?.postalCode)
      )
        return item;
      if (
        item?.mlsId?.toString()?.includes(query?.toString()) ||
        query?.toString()?.includes(item?.mlsId?.toString())
      )
        return item;
      if (
        item?.listingId?.toString()?.includes(query?.toString()) ||
        query?.toString()?.includes(item?.listingId?.toString())
      )
        return item;
      if (
        item?.property?.type?.includes(query) ||
        query?.includes(item?.property?.type)
      )
        return item;
      if (!query) return item;
    });
    setFilteredProperties(searchedProperties);
  };

  const renderPropertyCalendar = (mlsId: number) => {
    /* Redirect in Property details page */
    let url;
    url = `/propertydetails/${mlsId}`
    setRedirectUrl(url);
    // history.push(`/propertydetails/${mlsId}`);
  };

  const handleRightClick = (mlsId:number)=>{
    let url;
    url = `/properties/${mlsId}`
    setRedirectUrl(url)

  }

  const renderListedProperties = () => {
    const cards = (
      searchedText?.length > 0 ? filteredProperties : totalListedProperty
    ).map((listing) => {
      return (
        <a href={redirectUrl}
        onClick={()=>renderPropertyCalendar(listing?.mlsId ?? null)}
        onContextMenu={()=>handleRightClick(listing?.mlsId ?? null)}
        >
        {/* <div
          className="px-2"
          onClick={() => renderPropertyCalendar(listing?.mlsId ?? null)}> */}
          <SellerPropetirsCard
            propertyId={listing?.mlsId ?? null}
            image={listing?.photos?.[0] ?? ""}
            price={listing?.listPrice ?? null}
            type={listing?.property?.type ?? ""}
            beds={listing?.property?.bedrooms ?? null}
            baths={
              listing?.property?.bathsFull + 0.5 * listing?.property?.bathsHalf
            }
            squareFootage={listing?.property?.area ?? null}
            address1={`${listing?.address?.streetNumber ?? null} ${
              listing?.address?.streetName ?? null
            }`}
            address2={`${listing?.address?.city ?? null}, ${
              listing?.address?.state ?? null
            } ${listing?.address?.postalCode ?? null}`}
          />
        {/* </div> */}
        </a>
      );
    });
    return <Fragment>{cards}</Fragment>;
  };

  return (
    <div>
      {isLoading && <CustomSpinner />}
      <div className="homepage-agent-page-listing__listed-container listed-searchresponsive">
        {history.location.pathname === "/listings" ? (
          <CustomHeading>
            Seller
            <span style={{ borderBottom: "3px solid #C2D500" }}>
              {" "}
              Properties
            </span>
          </CustomHeading>
        ) : (
          <CustomHeading>
            My Listed
            <span style={{ borderBottom: "3px solid #C2D500" }}>
              {" "}
              Properties
            </span>
          </CustomHeading>
        )}

        {isMobile ? (
          <>
            {/* <img
              className=""
              src={SearchIcon}
              style={{ cursor: 'pointer' }}
              alt="TextField Icon"
              onClick={() => {}}
            /> */}
            <SearchField
              placeholder="Enter property listing id / street address"
              searchAction={handleFilterProperty}
              className="homepage-agent-page-listing__buyers-container-search_field"
            />
          </>
        ) : (
          <>
            <SearchField
              placeholder="Enter property listing id / street address"
              searchAction={handleFilterProperty}
              className="homepage-agent-page-listing__buyers-container-search_field"
            />
          </>
        )}
      </div>
      {searchedText?.length > 0 && filteredProperties?.length <= 0 && (
        <p className="homepage-agent-page-listing__listed-container">
          No properties found with this input.
        </p>
      )}

      <div className="render-listed-properties">
        <div className="render-listed-properties-card">
          {totalListedProperty?.length > 0 && renderListedProperties()}
        </div>
      </div>
    </div>
  );
};

export default Listings;
