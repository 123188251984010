import { Link } from "react-router-dom";
import React, { useState } from "react";
import facebookLogo from "../assets/images/logo-facebook.svg";
import linkedinLogo from "../assets/images/logo-linkedin.svg";
import twitter from "../assets/images/104501_twitter_bird_icon.svg";
import Navihome from "../assets/images/Navihome-LogoColor.svg";
import validator from "validator";
import TextField, {
  TextFieldIconType,
  TextFieldType,
} from "../components/TextField";
import Button, { ButtonType } from "../components/Button";
import styled from "@emotion/styled";
import { notification } from "../components/notification";

const CustomDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: 768px) {
    justify-content: center !important;
  }
`;
const CustomLink =styled(Link)`
&:hover {
  text-decoaration: none;
  color: #C2CC30;
}
`;

const Footer: React.FC<any> = (): JSX.Element => {
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState("");
  const handleToggling = () => {};
  return (
    <footer className="footer">
      <div className="footer__container">
        <div>
          <div className="footer_mail_input">
            <ul>
              <li>
                <h4>Join our mailing list</h4>
              </li>
              <li>
                <p>Don't miss out on the latest updates from us. Email:</p>
              </li>
              <li className="footer_mail_input_button">
                {/* textField */}
                <TextField
                  value={email}
                  setValue={setEmail}
                  placeholder="janedoe@hotmail.com"
                  label=""
                  type={TextFieldType.ICON_AND_LABEL}
                  icon={TextFieldIconType.EMAIL}
                  isRequired={false}
                  width={28}
                  className="footer__mail_input w-100"
                />
                <Button
                  type={ButtonType.ORANGE_BUTTON}
                  text="SUBMIT"
                  clickHandler={() => {
                    if (!validator.isEmail(email)) {
                      return notification(
                        "Please enter a valid email address",
                        "error"
                      );
                    } else {
                      handleToggling();
                      return notification(
                        "Email successfully submitted",
                        "success"
                      );
                    }
                  }}
                  className="footer_mail_button"
                />
              </li>
            </ul>
          </div>
        </div>
        <div
          className="middleFooter"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="footer__company">
            <ul>
              <li>
                <h4>COMPANY</h4>
              </li>
              <li>
                <CustomLink to="/">Home</CustomLink>
              </li>
              <li>
                <CustomLink to="/">About Us</CustomLink>
              </li>
              <li>
                <CustomLink to="/">Press and Media</CustomLink>
              </li>
              <li>
                <CustomLink to="/">Investors</CustomLink>
              </li>
            </ul>
          </div>
          <div className="footer__help">
            <ul>
              <li>
                <h4>HELP AND SUPPORT</h4>
              </li>
              <li>
                <CustomLink to="/">FAQs</CustomLink>
              </li>
              <li>
                <CustomLink to="/">Privacy Policy</CustomLink>
              </li>
              <li>
                <CustomLink to="/">Terms of use</CustomLink>
              </li>
            </ul>
          </div>
          <div className="footer__contact">
            <ul>
              <li>
                <h4>CONTACT US</h4>
              </li>
              <li>
                <p>123 Main Street, Boston, MA, 02108</p>
              </li>
              <li>
                <p>Sales / Support: +1 (234) 567 8910</p>
              </li>
              <li>
                <CustomLink to="/">Email us: support@navihome.com</CustomLink>
              </li>
              <li>
                <CustomLink to="/">Careers</CustomLink>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div className="footer__social-media">
            <CustomDiv
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <img
                className="twitter_logo"
                src={twitter}
                alt="twitter Logo"
                height={40}
                width={40}
              />
              <img
                className="facebook_logo"
                src={facebookLogo}
                alt="Facebook Logo"
                height={40}
                width={40}
              />
              <img
                src={linkedinLogo}
                alt="LinkedIn Logo"
                height={40}
                width={40}
              />
            </CustomDiv>

            <div className="footerRightPara">
              <Link to="/" className="footer__logo">
                <img
                  style={{ width: "168px", paddingBottom: "27px" }}
                  src={Navihome}
                  alt="NaviHomeLogo"
                />
              </Link>
              <p>
                <Link to="/">
                  © Copyrights NaviHome {currentYear}. All rights reserved
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
