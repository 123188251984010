import { useHistory } from "react-router";
import buttonClose from "../assets/images/button-close.svg";
import illustration from "../assets/images/illustration-thank-you.svg";
import Button, { ButtonType } from "../components/Button";
import facebookLogo from "../assets/images/logo-facebook-1.svg";
import twitter from "../assets/images/104501_twitter_bird_icon.svg";
import linkedinLogo from "../assets/images/logo-linkedin-1.svg";
import styled from "@emotion/styled";
import { useMediaQuery } from "react-responsive";

const CustomGettingStartedHeading = styled.h1`
font-size: 22px;
@media screen and (max-width: 420px){
  font-size: 19px;
}
`;
interface RequestDemoConfirmationModalProps {
  isShowing: boolean;
  closeAction: () => void;
}

const RequestDemoConfirmationModal: React.FC<
  RequestDemoConfirmationModalProps
> = ({ isShowing, closeAction }) => {
  const history = useHistory();

  const goBackToHome = () => {
    history.goBack();
  };

  const modalClassName = isShowing
    ? "request-demo-confirmation-modal visible"
    : "request-demo-confirmation-modal hidden";
  const cardClassName = isShowing
    ? "request-demo-confirmation-modal__card animation__pop-in"
    : "request-demo-confirmation-modal__card animation__pop-out";

  const isMobile = useMediaQuery({ maxWidth: 450 });

  return (
    <div className={modalClassName}>
      <div
        className={cardClassName}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="request-demo-row">
          <CustomGettingStartedHeading className="request-demo-confirmation-modal__title">
            Thank you for 
            <span style={{ borderBottom: "3px solid #C2D500" }}>
             {' '}submitting!
            </span>
          </CustomGettingStartedHeading>
          <img
            className="request-demo-confirmation-modal__close-button"
            src={buttonClose}
            onClick={closeAction}
            alt="Close button"
          />
        </div>

        <div className="request-demo-confirmation-modal__content">
          <img
            className="request-demo-confirmation-modal__image"
            src={illustration}
            alt="Placeholder"
          />
          <div className="requestdemo-second-div">
            <p>
              Thank you for submitting the form, we will get back to you as soon
              as possible. We have recieved your query.
              <br/>
              <br />
              However, we are experiencing a large volume of these. We are
              working to quickly get to them all. We may be delayed in our
              response. Sorry for the inconvenience.
              <br />
              <br />
              Thank you for understanding!
            </p>
            <div className="button-div">
              <Button
                type={ButtonType.PRIMARY_NARROW}
                text={"BACK TO HOMEPAGE"}
                clickHandler={goBackToHome}
                className={"request-demo-confirmation-modal__home-button"}
              />
            </div>

            <div className="request-demo-confirmation-modal__reach-us">
              <p>Reach out to us on:</p>
              <div className="request-demo-confirmation-modal__reach-us__logos">
                <img src={facebookLogo} alt="Facebook Logo" />
                <img
                  style={{ backgroundColor: "#365073" }}
                  src={twitter}
                  alt="Twitter Logo"
                />
                <img src={linkedinLogo} alt="LinkedIn Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestDemoConfirmationModal;
