/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Button, { ButtonType } from "../../components/Button";
import BuyerPreviewCard from "../../components/BuyerPreviewCard";
import { notification } from "../../components/notification";
import { useHistory } from "react-router-dom";
import SearchField from "../../components/SearchField";
import CustomSpinner from "../../components/spinner/CustomSpinner";
import ToursSummary from "../../components/ToursSummary";
import UserContext from "../../contexts/UserContext";
import InviteAgentModal from "../../modals/InviteAgentModal";
import InviteBuyerModal from "../../modals/InviteBuyerModal";
import MiniCalendar from "../../components/MiniCalendar";
import PropertyFiltersContext from "../../contexts/PropertyFiltersContext";
import { getLinkedBuyersService, unlinkBuyerFromAgentService } from "../../services/NavihomeService";
import MYListedProperty from "./mySellerProperties";
import UserHeader from "../notificationAgentPage/UserHeader";
import { activatedPlan } from "../../utils/base";
import styled from "@emotion/styled";

interface HomePageProps {
  isShowings?: boolean;
  agentUpcomingTour?: Array<any>;
  listingAgentUpcomingTour?: Array<any>;
  totalListedProperty?: Array<any>;
  getAllListedProperty?: () => void;
}

const CustomHeading = styled.h1`
  font-size: 32px;
  @media screen and (max-width: 432px) {
    font-size: 28px;
  }
  @media screen and (max-width: 283px) {
    font-size: 24px;
  }
`;
const Calendiv = styled.h3`
  @media screen and (max-width: 327px) {
    &:after {
      display: none;
    }
  }
`;
const Mybuyerheading = styled.h1`
  font-size: 32px;
  @media screen and (max-width: 432px) {
    font-size: 28px;
  }
  @media screen and (max-width: 283px) {
    font-size: 24px;
  }
  @media screen and (max-width: 440px) {
    padding-bottom: 10px;
  }
`;

const CustomBuyerCol = styled(Col)<{
  color: string;
}>`
  padding-top: 6px;
  &:hover {
    border-radius: 12px;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
  }
`;
const HomePage: React.FC<HomePageProps> = ({ isShowings, listingAgentUpcomingTour, agentUpcomingTour, totalListedProperty, getAllListedProperty }) => {
  const { userState } = useContext(UserContext);
  const [buyers, setBuyers] = useState([]);
  const [isPlanActive, setIsPlanActive] = useState<boolean>(false);
  const [filteredBuyers, setFilteredBuyers] = useState([]);
  const [isShowingInviteAgentModal, setIsShowingInviteAgentModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowingInviteBuyerModal, setIsShowingInviteBuyerModal] = useState(false);
  const [isBuyerShowing, setIsBuyerShowing] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [upcomingTourDate, setUpcomingTourDate] = useState<any>(null);
  const [upcomingStartEndDate, setUpcomingStartEndDate] = useState<any>({
    startDate: null,
    endDate: null
  });
  // You can take new Date() in place of null to get current date

  const { propertyFiltersState, setMessageSocketCaller, setNotificationsCount } = useContext(PropertyFiltersContext);

  const { state } = propertyFiltersState;
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: 576 });
  // ACTIONS
  const loadBuyers = async () => {
    try {
      if (!userState?.agent?.token) {
        return notification("User State Agent Token Not Found!", "error");
      }
      const token = userState?.agent?.token;
      const linkedBuyers = await getLinkedBuyersService(token);
      setBuyers(linkedBuyers);
    } catch (error) {
      console.log(error);
    }
  };

  const unlinkBuyer = async (buyerId: number, customMessage: string) => {
    const token = userState.agent.token;

    const res: any = await unlinkBuyerFromAgentService(token, buyerId, customMessage);

    setMessageSocketCaller(String(Math.floor(Math.random() * 100 + 1)));
    setNotificationsCount(buyerId);

    const filteredBuyers = buyers.filter((buyer) => {
      return buyer.buyerId !== buyerId;
    });

    setBuyers(filteredBuyers);
  };

  const showInviteAgentModal = () => {
    setIsShowingInviteAgentModal(true);
  };

  const closeInviteAgentModal = () => {
    setIsShowingInviteAgentModal(false);
  };

  const showInviteBuyerModal = () => {
    setIsShowingInviteBuyerModal(true);
  };

  const closeInviteBuyerModal = () => {
    setIsShowingInviteBuyerModal(false);
    setBuyers([]);
    loadBuyers();
  };

  const searchAction = (query: string) => {
    setSearchText(query);
    const filterBuyers = buyers.filter((item) => item.name.toUpperCase().includes(query.toUpperCase()));

    setFilteredBuyers(filterBuyers);
  };

  useEffect(() => {
    loadBuyers();
  }, [state]);

  useEffect(() => {
    loadBuyers();
    const currentMonth = new Date().getMonth();
    const expireMonth = new Date(userState.agent.expireAt).getMonth();
    const currentYear = new Date().getFullYear();
    const expireYear = new Date(userState.agent.expireAt).getFullYear();
    const currentDay = new Date().getDate();
    const expireDay = new Date(userState.agent.expireAt).getDate();
    if (currentMonth === expireMonth && currentDay <= expireDay) {
      setIsPlanActive(true);
    } else if (currentMonth < expireMonth && currentDay >= expireDay) {
      setIsPlanActive(true);
    } else if (currentMonth > expireMonth && currentYear < expireYear) {
      setIsPlanActive(true);
    } else if (currentMonth < expireMonth && currentDay <= expireDay && currentMonth === 1) {
      setIsPlanActive(true);
    }
  }, []);

  const renderBuyers = () => {
    return (
      <div className="">
        <Row>
          {(searchText?.length > 0 ? filteredBuyers : buyers)?.map((item) => {
            return (
              <CustomBuyerCol color={item?.color} xs={24} sm={24} md={12} lg={12} xl={8}>
                <BuyerPreviewCard
                  unlinkBuyer={unlinkBuyer}
                  buyerId={item?.buyerId}
                  imageUrl={item?.imageUrl}
                  name={item?.name}
                  phone={item?.phone}
                  email={item?.email}
                  key={item?.buyerId}
                  avatarUrl={item?.avatarUrl}
                  setIsBuyerShowing={setIsBuyerShowing}
                  preferredContact={item?.preferredContact ?? ""}
                  color={item?.color}
                />
              </CustomBuyerCol>
            );
          })}
        </Row>
      </div>
    );
  };

  return (
    <div className="homepage-agent-page">
      {isLoading && <CustomSpinner />}
      {activatedPlan.includes(userState?.agent?.subscription_type) && <UserHeader />}
      <div className="homepage-agent-page__cover">
        <div className="homepage-agent-page__cover-content">
          <h2>Help people find their dream home faster</h2>
          <p>Maximize your productivity! Use advanced AI technology to help your clients find their dream home, and route and schedule property showing tours more seamlessly.</p>
          {isShowings ? (
            <>
              <Button type={ButtonType.ORANGE_BUTTON} text={"INVITE BUYERS"} clickHandler={showInviteBuyerModal} />
            </>
          ) : (
            <>
              <Button
                type={ButtonType.ORANGE_BUTTON}
                text={"INVITE AGENTS"}
                // className="show-invite-agent-modal-button"
                clickHandler={showInviteAgentModal}
              />
            </>
          )}
        </div>
      </div>
      <div className="homepage-agent-page__container">
        <div className="homepage-agent-page__calendar-container">
          <Calendiv>My Calendar</Calendiv>
          <div className="homepage-agent-page__calendar-card" style={{ marginTop: "20px" }}>
            <MiniCalendar setUpcomingTourDate={setUpcomingTourDate} setUpcomingStartEndDate={setUpcomingStartEndDate} month={""} />
          </div>
        </div>
        <div className="homepage-agent-page__upcoming-tours-container">
          <CustomHeading>
            Upcoming <span style={{ borderBottom: "3px solid #C2D500" }}>{history?.location?.pathname === "/" || history?.location?.pathname === "/showings" ? "Schedule" : "Tours"}</span>
          </CustomHeading>
          <div style={{ marginTop: "20px" }}>
            <ToursSummary tours={agentUpcomingTour} upcomingTourDate={upcomingTourDate} upcomingStartEndDate={upcomingStartEndDate} type={history?.location?.pathname === "/" ? "home" : "showing"} />
          </div>
        </div>
      </div>
      <div className="homepage-agent-page__buyers-container searchfield-responsive">
        <Mybuyerheading>
          My
          <span style={{ borderBottom: "3px solid #C2D500" }}> Buyers</span>
        </Mybuyerheading>
        {isMobile ? (
          <>
            <SearchField placeholder="Enter Buyer name " searchAction={searchAction} className="homepage-agent-page__buyers-container-search_field" />
          </>
        ) : (
          <>
            <SearchField placeholder="Enter Buyer name " searchAction={searchAction} className="homepage-agent-page__buyers-container-search_field" />
          </>
        )}
      </div>
      <div className="homepage-agent-page__buyers-container-buyers">
        {searchText?.length > 0 ? filteredBuyers?.length > 0 ? <p style={{ paddingBottom: "16px" }}>{filteredBuyers?.length} Buyers</p> : <p>linked buyers not found with this name!</p> : <></>}
        {buyers?.length <= 0 && searchText?.length <= 0 ? <p>There are no linked buyers.</p> : renderBuyers()}
      </div>
      {!isShowings && buyers?.length <= 0 ? (
        <div className="homepage-agent-page__invite-buyers">
          <Button type={ButtonType.PRIMARY} text={"INVITE BUYERS"} clickHandler={showInviteBuyerModal} />
        </div>
      ) : (
        <></>
      )}

      {!isShowings && <MYListedProperty totalListedProperty={totalListedProperty} />}

      {isShowingInviteAgentModal && <InviteAgentModal isShowing={isShowingInviteAgentModal} closeAction={closeInviteAgentModal} />}
      {isShowingInviteBuyerModal && <InviteBuyerModal isShowing={isShowingInviteBuyerModal} closeAction={closeInviteBuyerModal} />}
    </div>
  );
};

export default HomePage;
