import { Col, DatePicker, Form, Row, TimePicker } from "antd";
import moment, { MomentInput } from "moment";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";

interface IAppProps {
  dragInfo: {
    startTime: Date;
    endTime: string;
    eventId: number | string;
    realEndTime: Date;
    realStartTime: string | Date;
  };
}
const style = { background: "white", padding: "8px 0" };
const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

const FullDatePicker: any = styled(DatePicker)``;

const DragConfig: React.FunctionComponent<IAppProps> = ({ dragInfo }) => {
  let [eventInfo, setEventInfo] = useState<{
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
  }>({
    startDate: dragInfo?.startTime?.toDateString(),
    endDate: dragInfo?.realEndTime?.toDateString(),
    startTime: dragInfo?.startTime?.toTimeString(),
    endTime: dragInfo?.realEndTime?.toTimeString(),
  });

  useEffect(() => {
    setEventInfo((eventInfo) => ({
      ...eventInfo,
      startDate: dragInfo?.startTime?.toDateString(),
    endDate: dragInfo?.realEndTime?.toDateString(),
    startTime: dragInfo?.startTime?.toTimeString(),
    endTime: dragInfo?.realEndTime?.toTimeString(),
    }));
  }, [dragInfo]);

  const dateFormat = "YYYY-MM-DD";

  return (
    <>
      <div>
        <Form>
          <p>
            <h4>Reschedule Event {dragInfo?.eventId}</h4>
            <Form.Item>
              <Row gutter={[16, 24]}>
                <Col span={5}>
                  <div style={style}>
                    Date*
                    <input
                      value={new Date(eventInfo?.startDate)?.toDateString()}
                    />
                  </div>
                </Col>
                <Col span={5}>
                  <div style={style}>
                    Time*
                    <input
                      value={
                        new Date(eventInfo?.startTime)
                          ?.toTimeString()
                          ?.split(" ")?.[0]
                      }
                    />
                  </div>
                </Col>
                <h1 style={{ alignContent: "center" }}>
                  <img
                    src="https://img.icons8.com/external-royyan-wijaya-detailed-outline-royyan-wijaya/24/000000/external-arrow-arrow-line-royyan-wijaya-detailed-outline-royyan-wijaya-13.png"
                    style={{
                      color: "blue",
                      alignContent: "center",
                      marginTop: "150%",
                      marginLeft: "-1%",
                    }}
                  />
                </h1>
                <Col span={5}>
                  <div style={style}>
                    Date*
                    <input
                      value={moment(eventInfo?.endDate)?.format('"MMM Do YY"')}
                      style={{
                        borderRadius: "5px",
                        boxShadow: "2px 2px 2px grey",
                        border: "solid",
                        borderColor: "darkblue",
                        borderWidth: "thin",
                      }}
                    />
                  </div>
                </Col>
                <Col span={5}>
                  <div style={style}>
                    Time**
                    <input
                      value={
                        new Date(eventInfo?.endTime)
                          ?.toTimeString()
                          ?.split(" ")?.[0]
                      }
                    />
                  </div>
                </Col>
              </Row>
            </Form.Item>
          </p>
        </Form>
      </div>
    </>
  );
};

export default DragConfig;
