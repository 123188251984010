import { useState, useContext, useRef, useEffect } from 'react';
import UserContext, { UserType } from '../contexts/UserContext';
import buttonClose from '../assets/images/button-close.svg';
import TextField, {
  TextFieldType,
  TextFieldIconType,
} from '../components/TextField';
import Button, { ButtonType } from '../components/Button';
import Checkbox, { CheckboxType } from '../components/Checkbox';
import { useHistory } from 'react-router';
import {
  sendForgotPasswordForBuyerService,
  sendForgotPasswordForAgentService,
  resendEmailLinkService,
} from '../services/NavihomeService';
import { useMediaQuery } from 'react-responsive';
import { notification } from '../components/notification';
import CustomSpinner from '../components/spinner/CustomSpinner';
import styled from '@emotion/styled';

interface LogInModalProps {
  isShowing: boolean;
  closeAction: () => void;
  setIsShowingForgotPasswordModal: any;
  closeQuickModal?: () => void;
  // errorMessage: boolean
}
const Customheading = styled.h1`
  font-size: 19px;
  @media screen and (max-width: 420px) {
    font-size: 16px;
  }
`;
const CustomAnchor = styled.a`
  margin-top: 0;
  padding-top: 0;
`;
const LogInModal: React.FC<LogInModalProps> = ({
  isShowing,
  closeAction,
  setIsShowingForgotPasswordModal,
  closeQuickModal,
}) => {
  const { userState, logIn } = useContext(UserContext);
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [isForgotModalShowing, setIsForgotModalShowing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 500 });

  const closeLoginModal = () => {
    closeAction();
    closeQuickModal();
  };
  const handleLogIn = async () => {
    setIsForgotModalShowing(true);
    try {
      if (!email || !password)
        return notification(
          'Please enter a valid email and password.',
          'error'
        );
      setIsLoading(true);
      const success = await logIn(email, password);
      if (!success) {
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      closeAction();
      history.push('/');
    } catch (error) {
      console.log(error);
    }
  };
  const handleOpenForgotPasswordModal = () => {
    closeAction();
    setIsShowingForgotPasswordModal(true);
  };

  useEffect(() => {
    if (email?.length == 0 || password?.length == 0) {
      setIsForgotModalShowing(false);
    }
  }, [email, password]);

  const handleForgotPassword = async () => {
    if (email === '') {
      notification('Please enter your email.', 'error');
      return;
    }

    if (userState.userType === UserType.BUYER) {
      await sendForgotPasswordForBuyerService(email);
    }

    if (userState.userType === UserType.AGENT) {
      await sendForgotPasswordForAgentService(email);
    }

    closeAction();
    notification(
      'A temporary password will be sent to your email shortly!',
      'success'
    );
  };

  const goToRegister = () => {
    closeAction();
    history.push('/register');
  };

  let resendEmailLinkHandler = async () => {
    setIsLoading(true);
    try {
      if (userState.userType === UserType.BUYER) {
        await resendEmailLinkService(email, 'buyer');
      } else {
        await resendEmailLinkService(email, '');
      }

      setIsLoading(false);
      notification(
        'Verification link has been resent to your registered email.',
        'success'
      );
      closeAction();
      history.push('/');
    } catch (error) {
      console.log('ERROR', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (email?.length == 0 || password?.length == 0) {
      setIsForgotModalShowing(false);
    }
  }, [email, password]);
  const modalClassName = isShowing
    ? 'login-modal u-visible'
    : 'login-modal u-hidden';
  const cardClassName = isShowing
    ? 'login-modal__card animation__pop-in'
    : 'login-modal__card animation__pop-out';

  return (
    <div className={modalClassName}>
      <div className={cardClassName}>
        <div className="login-modal__row">
          {isLoading && <CustomSpinner />}
          <Customheading>
            Wel
            <span style={{ borderBottom: '3px solid #C2D500' }}>come Back</span>
          </Customheading>
          <img
            className="login-modal__close-button"
            src={buttonClose}
            onClick={closeLoginModal}
            alt="Close button"
          />
        </div>
        <div className="login-modal__column">
          <p className="login-modal__error-message">
            {isForgotModalShowing ? userState?.loginError?.length == 22 ? 'Please enter a valid email': userState?.loginError : ''}
          </p>
          {isForgotModalShowing &&
          userState?.loginError === 'Please verify your account!' ? (
            <CustomAnchor
              className="login-modal__forgot-password"
              onClick={resendEmailLinkHandler}
            >
              Resend Link
            </CustomAnchor>
          ) : (
            <></>
          )}
          <TextField
            value={email}
            setValue={setEmail}
            placeholder="janedoe@hotmail.com"
            label="Email"
            type={TextFieldType.ICON_AND_LABEL}
            icon={TextFieldIconType.EMAIL}
            isRequired={true}
            width={isMobile ? 26.4 : 38.4}
            className="login-modal__email-field"
          />
          <TextField
            value={password}
            setValue={setPassword}
            placeholder="••••••••"
            label="Password"
            type={TextFieldType.PASSWORD}
            icon={TextFieldIconType.PASSWORD}
            isRequired={true}
            width={isMobile ? 26.4 : 38.4}
            className="login-modal__password-field"
          />
          <Checkbox
            type={CheckboxType.SMALL}
            value={rememberMe}
            setValue={setRememberMe}
            text="Remember me"
          />
          <Button
            type={ButtonType.PRIMARY}
            text="SIGN IN"
            clickHandler={handleLogIn}
            width={18.5}
            className="login-modal__button"
          />
          <p
            className="login-modal__forgot-password"
            onClick={handleOpenForgotPasswordModal}
          >
            Forgot Password?
          </p>
          <p>
            Don't have an account?{' '}
            <span className="login-modal__register-now" onClick={goToRegister}>
              Register now
            </span>
          </p>
        </div>
      </div>
      {/* {isForgotModalShowing && (
          <ForgotPasswordModal
            isShowingForgotModal={isForgotModalShowing}
            closeAction={handleCloseForgotPasswordModal}
          />
        )} */}
      {/* {isShowingForgotPasswordModal && <ForgotPasswordModal isShowing={isShowingLoginModal} closeAction={handleCloseLoginModal} />} */}
    </div>
  );
};

export default LogInModal;
