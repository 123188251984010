import { Button } from "antd";
import { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Col, Input, Radio, Row } from "antd";
import "./styles/prefrences.css";
import styled from "@emotion/styled";
import UserContext from "../../../contexts/UserContext";
import {
  getPreferencesAgentType,
  postPreferenceAgentType,
  putPreferencesAgentType,
  updateListedSellerProperty,
} from "../../../services/NavihomeService";
import CustomSpinner from "../../../components/spinner/CustomSpinner";
import { notification } from "../../../components/notification/index";
import IconTime from "../../../assets/images/Icon - time.svg";
const { TextArea } = Input;
const CustomTextArea: any = styled(TextArea)`
  border-radius: 6px;
`;
const CustomInput = styled(Input)`
  border-radius: 6px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const CustomActionButton = styled(Button)`
  border-radius: 10px;
  padding: 0px 20px;
`;
const CustomSaveButton = styled(Button)`
  color: #fff;
  background: #365073;
  border-radius: 10px;
  padding: 0px 20px;
  &:hover {
    background: #c2cc30;
    border-color: none;
  }
`;
const CustomOtherNoticeDiv = styled.div`
  &:after {
    font-size: 14px;
    position: absolute;
    top: 6rem;
    left: 3rem;
    transition: all 0.05s ease-in-out;
  }
`;
const CustomOtherDurationDiv = styled.div`
  &:after {
    font-size: 14px;
    position: absolute;
    top: 6rem;
    left: 3rem;
    transition: all 0.05s ease-in-out;
  }
`;

const CustomDiv = styled.div`
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 13px;
`

const PrefrencesMain = () => {
  const [value, setValue] = useState({
    showingType: "",
    acceptRequest: "",
    minNoticePeriod: "",
    minOther: "",
    maxDurationShowing: "",
    maxOther: "",
    confirmationMessage: "",
    rejectionMessage: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [checkForPost, setCheckForPost] = useState(false);
  const { userState } = useContext(UserContext);
  const history = useHistory();
  const mlsId = history.location.pathname.split("/")[2];

  const [isAcceptAllRequests, setIsAcceptAllRequests] = useState<boolean>(true)

  const handleChange = (value: any, key: any) => {
    if (key === 'showingType')
      setIsAcceptAllRequests(false);
    if (key === "minNoticePeriod") {
      setValue((prevState) => ({ ...prevState, minOther: "" }));
      setValue((prevState) => ({ ...prevState, [key]: value }));
    } else if (key === "maxDurationShowing") {
      setValue((prevState) => ({ ...prevState, maxOther: "" }));
      setValue((prevState) => ({ ...prevState, [key]: value }));
    } else {
      setValue((prevState) => ({ ...prevState, [key]: value }));
    }
  };
  let token = userState.agent.token;

  const saveHandler = () => {
    const payload = {
      showingType: value?.showingType,
      acceptRequest: value?.acceptRequest,
      minNoticePeriod:
        value?.minNoticePeriod === "Other"
          ? value?.minOther === "1" ? '1 hr' : `${value?.minOther} hrs`
          : value?.minNoticePeriod,
      maxDurationShowing:
        value?.maxDurationShowing === "Other"
          ? value.maxOther === "1" ? '1 hr' : `${value?.maxOther} hrs`
          : value?.maxDurationShowing,
      confirmationMessage: value?.confirmationMessage,
      rejectionMessage: value?.rejectionMessage,
      mlsId: mlsId ? Number(mlsId) : "",
      agentId: userState?.agent?.agentId,
    };

    if (checkForPost) {
      try {
        setIsLoading(true);
        postPreferenceAgentType(payload, token).then(async (res) => {
          const InsertId = res?.data?.raw?.insertId;
          await updateListedSellerProperty(Number(mlsId), userState?.agent?.agentId, InsertId);
          notification("Data Saved Successfully", "success");
          setIsLoading(false);
        });
      } catch (err) {
        notification("Error in POST API", "error");
      }
    } else {
      setIsLoading(true);
      putPreferencesAgentType(payload, token, mlsId).then(async (res) => {
        notification("Data Updated Successfully", "success");
        setIsLoading(false);
      });
    }
  };

  const GetPreferenceData = async () => {
    setIsLoading(true);
    const response = await getPreferencesAgentType(token, mlsId);
    if (Object?.keys(response?.data)?.length > 0) {
      let tempArray: any = [];
      tempArray?.push({
        showingType: response?.data?.showingType,
        acceptRequest: response?.data?.acceptRequest,
        minNoticePeriod: response?.data?.minNoticePeriod,
        maxDurationShowing: response?.data?.maxDurationShowing,
        minOther:
          response?.data?.minNoticePeriod === "30 min" ||
            response?.data?.minNoticePeriod === "1 hr" ||
            response?.data?.minNoticePeriod === "24 hr"
            ? ""
            : response?.data?.minNoticePeriod?.split(" ")?.[0],
        maxOther:
          response?.data?.maxDurationShowing === "30 min" ||
            response?.data?.maxDurationShowing === "1 hr" ||
            response?.data?.maxDurationShowing === "24 hr"
            ? ""
            : response?.data?.maxDurationShowing?.split(" ")?.[0],
        confirmationMessage: response?.data?.confirmationMessage,
        rejectionMessage: response?.data?.rejectionMessage,
        mlsId: response?.data?.mlsId,
        agentId: response?.data?.agentId,
      });
      setValue(tempArray[0]);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setCheckForPost(true);
    }
  };

  useEffect(() => {
    GetPreferenceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? (
    <CustomSpinner />
  ) : (
    <div>
      <div className="prefrenceCont">
        <div className="prefrenceTitle">Showing Type :</div>
        <Row>
          <Col xs={20} sm={10} md={10} lg={10} xl={8}>
            <div className="prefrenceRadio">
              <Radio.Group
                onChange={(e) => handleChange(e.target.value, "showingType")}
                value={value.showingType}
              >
                <Radio value={"Lockbox"} className="radioStyles" style={{ fontWeight: 'bold' }}>
                  Lockbox
                </Radio>
                <Radio value={"Agent accompained"} className="radioStyles" style={{ fontWeight: 'bold' }}>
                  Agent accompained
                </Radio>
              </Radio.Group>
            </div>
          </Col>
        </Row>
        <div className="prefrenceText">
          1. Lockbox: For lockbox showing, you can either provide a lockbox code
          or ask the agents to call you when they are at property. All showing
          requests will automatically be accepted. This is ideal for properties
          that are currently not occupied.
        </div>
        <div className="prefrenceText">
          2. Agent accompained: For agent accompained showings, you will have to
          accept every showing request. This is ideal for properties that are
          occupied currently.
        </div>
      </div>
      <div className="prefrenceCont">
        <div className="prefrenceTitle">Accept all requests?</div>
        <Row>
          <Col xs={20} sm={10} md={10} lg={10} xl={8}>
            <div className="prefrenceRadio">
              <Radio.Group
                onChange={(e) => handleChange(e.target.value, "acceptRequest")}
                value={value.acceptRequest}
                disabled={value?.acceptRequest?.length > 0 ? false : isAcceptAllRequests}
              >
                <Radio value={"Yes"} className="radioStyles" style={{ fontWeight: 'bold' }}>
                  Yes
                </Radio>
                <Radio
                  value={"Within available period"}
                  className="radioStyles"
                  style={{ fontWeight: 'bold' }}
                >
                  Within available period
                </Radio>
                <Radio value={"No"} className="radioStyles" style={{ fontWeight: 'bold' }}>
                  No
                </Radio>
              </Radio.Group>
            </div>
          </Col>
        </Row>
        <CustomDiv>
          If you choose "YES",all showing request will be accepted and get added to your calendar without sending the request to you as notifications.
        </CustomDiv>
        <CustomDiv>
          If you choose "WITHIN AVAILABLE PERIODS",showing requests sent within the available times marked by you will get added to your calendar automatically,and others will get sent to you.
        </CustomDiv>
        <CustomDiv>
          If you choose "NO",all showing requests,including one within periods of time you marked as available will be sent to you as notifications and will get added to your calendar only if you accept them.
        </CustomDiv>
      </div>
      <div className="prefrenceCont">
        <div className="prefrenceTitle">Minimum notice period :</div>
        <Row>
          <Col xs={20} sm={10} md={10} lg={10} xl={8}>
            <div className="prefrenceRadio">
              <Radio.Group
                onChange={(e) =>
                  handleChange(e.target.value, "minNoticePeriod")
                }
                value={
                  value.minNoticePeriod === "30 min" ||
                    value.minNoticePeriod === "1 hr" ||
                    value.minNoticePeriod === "24 hr"
                    ? value.minNoticePeriod
                    : value.minNoticePeriod
                      ? (value.minNoticePeriod = "Other")
                      : ""
                }
              >
                <Radio value={"30 min"} className="radioStyles">
                  30 min
                </Radio>
                <Radio value={"1 hr"} className="radioStyles">
                  1 hr
                </Radio>
                <Radio value={"24 hr"} className="radioStyles">
                  24 hrs
                </Radio>
                <Radio value={"Other"} className="radioStyles">
                  Other
                </Radio>
              </Radio.Group>
            </div>
            <CustomOtherNoticeDiv className="otherCont">
              <div className="prefrenceText">If other</div>
              <CustomInput
                type="number"
                min="1"
                max="24"
                id="hr"
                // eslint-disable-next-line jsx-a11y/alt-text
                prefix={<img src={IconTime} />}
                suffix="hr"
                placeholder="Enter Hour"
                value={value.minOther}
                disabled={value.minNoticePeriod !== "Other"}
                onChange={(e) => handleChange(e.target.value, "minOther")}
              ></CustomInput>
            </CustomOtherNoticeDiv>
          </Col>
        </Row>
      </div>
      <div className="prefrenceCont">
        <div className="prefrenceTitle">Maximum duration of showing :</div>
        <Row>
          <Col xs={20} sm={10} md={10} lg={10} xl={8}>
            <div className="prefrenceRadio">
              <Radio.Group
                onChange={(e) =>
                  handleChange(e.target.value, "maxDurationShowing")
                }
                value={
                  value.maxDurationShowing === "30 min" ||
                    value.maxDurationShowing === "1 hr" ||
                    value.maxDurationShowing === "24 hr"
                    ? value.maxDurationShowing
                    : value.maxDurationShowing
                      ? (value.maxDurationShowing = "Other")
                      : ""
                }
              >
                <Radio value={"30 min"} className="radioStyles">
                  30 min
                </Radio>
                <Radio value={"1 hr"} className="radioStyles">
                  1 hr
                </Radio>
                <Radio value={"24 hr"} className="radioStyles">
                  24 hrs
                </Radio>
                <Radio value={"Other"} className="radioStyles">
                  Other
                </Radio>
              </Radio.Group>
            </div>
            <CustomOtherDurationDiv className="otherCont">
              <div className="prefrenceText">If other</div>
              <CustomInput
                type="number"
                min="1"
                max="24"
                suffix="hr"
                // eslint-disable-next-line jsx-a11y/alt-text
                prefix={<img src={IconTime} />}
                placeholder=""
                value={value.maxOther}
                disabled={value.maxDurationShowing !== "Other"}
                onChange={(e) => handleChange(e.target.value, "maxOther")}
              ></CustomInput>
            </CustomOtherDurationDiv>
          </Col>
        </Row>
      </div>
      <div className="prefrenceCont">
        <div className="prefrenceTitle">Message to Sale Agents :</div>
        <Row>
          <Col xs={22} sm={22} md={16} lg={16} xl={8}>
            <div className="messageCont">
              <div className="prefrenceText">
                Confirmation message to sale agents :
              </div>
              <CustomTextArea
                rows={4}
                // eslint-disable-next-line jsx-a11y/alt-text
                value={value.confirmationMessage}
                onChange={(e: any) =>
                  handleChange(e.target.value, "confirmationMessage")
                }
                placeholder="Hey, thanks for scheduling a showing. Please call me when you're at the property and I will give you the lockbox code for access. Have a nice day."
                maxLength={250}
              />
            </div>
            <div className="messageCont">
              <div className="prefrenceText">
                Rejection message to sale agents :
              </div>
              <CustomTextArea
                rows={4}
                value={value.rejectionMessage}
                onChange={(e: any) =>
                  handleChange(e.target.value, "rejectionMessage")
                }
                placeholder="Hey, please schedule a showing based on the calendar available for this property as the seller is busy at other times. Thanks."
                maxLength={250}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Row className="custom-button">
        <div>
          <Link to="/">
            <CustomActionButton size="middle">CANCEL</CustomActionButton>
          </Link>
          &nbsp;&nbsp;&nbsp;
          <CustomSaveButton size="middle" onClick={saveHandler}>
            SAVE AND UPDATE
          </CustomSaveButton>
        </div>
      </Row>
    </div>
  );
};

export default PrefrencesMain;
