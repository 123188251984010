/* eslint-disable @typescript-eslint/no-unused-vars */
import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Button, { ButtonType } from "../components/Button";
import AboutAgent from "../components/FindAnAgentPage/aboutAgent";
import FindAnAgentPageCard from "../components/FindAnAgentPage/findAnAgentCard";
import { notification } from "../components/notification";
import SearchField from "../components/SearchField";
import CustomSpinner from "../components/spinner/CustomSpinner";
import UserContext from "../contexts/UserContext";
import { getActivatedSubscriptionPlan, getAllAgents, getSearchedAgents } from "../services/NavihomeService";
import moment from "moment";

const FindAnAgentPage = () => {
  const [isMain, setIsMain] = useState(true);
  const isSM = useMediaQuery({ maxWidth: 768 });
  const [singleAgentDetails, setSingleAgentDetails] = useState<any>(null);
  const [agents, setAgents] = useState<Array<string>>([]);
  const [filteredAgents, setFilteredAgents] = useState<any>([]);
  const [noSearchData, setNoSearchData] = useState<boolean>(false);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchComplete, setSearchComplete] = useState<boolean>(false);
  const { userState } = useContext(UserContext);
  const [count, setCount] = useState<number>(10);
  const [isContent, setIsContent] = useState<boolean>(false);
  const searchAction = async (query: string) => {
    setSearchText(query);
    setSearchComplete(true);
    setIsLoading(true);
    try {
      const searchedRes: any = await getSearchedAgents(query);

      const filtereddata: any = await Promise.all(
        searchedRes?.map(async (ele: any) => {

          if (ele?.agentId !== 0) {
            const from = new Date(),
              toDate = ele?.registeredAt,
              countDaysAgent: number =
                moment(from, "YYYY-MM-DD")
                  .startOf("day")
                  .diff(moment(toDate, "YYYY-MM-DD").startOf("day"), "days") - 1;

            if (countDaysAgent <= 30) {
              ele["subscription_type"] = "Navihome Advanced Plan";
              return ele;
            } else {
              const paymentDetails: any = await getActivatedSubscriptionPlan(ele?.agentId, userState?.buyer?.token);
              if (paymentDetails) {
                let { activePlan, canceledDate } = paymentDetails,
                  activePlanName: string = "Navihome Free Plan";
                const to = canceledDate;
                const countDays: number =
                  moment(from, "YYYY-MM-DD")
                    .startOf("day")
                    .diff(moment(to, "YYYY-MM-DD").startOf("day"), "days") - 1;
                if (countDays < 0)
                  activePlanName = activePlan;
                ele["subscription_type"] = activePlanName;
                return ele;
              } else {
                ele["subscription_type"] = "Navihome Free Plan";
                return ele;
              }
            }
          }
        })
      );

      if (filtereddata?.length > 0) {
        setFilteredAgents(filtereddata);
        setNoSearchData(false);
      } else {
        setFilteredAgents([]);
        setNoSearchData(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const goToSingleAngent = () => {
    setIsMain(!isMain);
  };

  const getAllAgentsForBuyers = async (count: number, isLoadmore: boolean) => {
    setIsLoading(true);
    try {

      const data: any = await getAllAgents(userState?.buyer?.token, count);

      const filtereddata: any = await Promise.all(
        data?.map(async (ele: any) => {
          if (ele?.agentId !== 0) {
            const from = new Date(),
              toDate = ele?.registeredAt,
              countDaysAgent: number =
                moment(from, "YYYY-MM-DD")
                  .startOf("day")
                  .diff(moment(toDate, "YYYY-MM-DD").startOf("day"), "days") - 1;

            if (countDaysAgent <= 30) {
              ele["subscription_type"] = "Navihome Advanced Plan";
              return ele;
            } else {
              const paymentDetails: any = await getActivatedSubscriptionPlan(ele?.agentId, userState?.buyer?.token);
              if (paymentDetails) {
                let { activePlan, canceledDate } = paymentDetails,
                  activePlanName: string = "Navihome Free Plan";
                const to = canceledDate;
                const countDays: number =
                  moment(from, "YYYY-MM-DD")
                    .startOf("day")
                    .diff(moment(to, "YYYY-MM-DD").startOf("day"), "days") - 1;
                if (countDays < 0)
                  activePlanName = activePlan;
                ele["subscription_type"] = activePlanName;
                return ele;
              } else {
                ele["subscription_type"] = "Navihome Free Plan";
                return ele;
              }
            }
          }
          // if (ele?.agentId !== 0) {
          //   const paymentDetails: any = await getActivatedSubscriptionPlan(ele?.agentId, userState?.buyer?.token);
          //   let { activePlan } = paymentDetails;
          //   let activePlanName: string = activePlan ?? '';
          //   if (!paymentDetails && activePlan !== 'Navihome Advanced Plan' && activePlan !== 'Navihome Premimum Plan' && activePlan !== 'Navihome Basic Plan') {
          //     const from = new Date();
          //     const to = ele?.expireAt;
          //     // Math.abs(
          //     const countDays: number =
          //       moment(from, "YYYY-MM-DD")
          //         .startOf("day")
          //         .diff(moment(to, "YYYY-MM-DD").startOf("day"), "days") - 1;

          //     if (countDays < 0)
          //       activePlanName = "Navihome Advanced Plan";
          //   }
          //   ele["subscription_type"] = activePlanName ? activePlanName : "Navihome Free Plan";
          //   return ele;
          // }
        })
      );

      if (filtereddata?.length <= 0) {
        setIsLoading(false);
        return notification(agents.length > 0 ? "More Agents Not Found!" : "Agents Not Found!", "error");
      }
      if (isLoadmore) {
        setAgents([...agents, ...filtereddata]);
      } else {
        setAgents(filtereddata);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const loadMoreAgents = () => {
    setCount(count + 10);
    setIsLoading(true);
    getAllAgentsForBuyers(count + 10, true);
  };

  let type =
    singleAgentDetails?.address !== null
      ? `${singleAgentDetails?.address?.homeStreetAddress} - ${singleAgentDetails?.address?.homeCityAndZip} , ${singleAgentDetails?.address?.officeStreetAddress} - ${singleAgentDetails?.address?.officeCityAndZip}`
      : "---";

  useEffect(() => {
    getAllAgentsForBuyers(10, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {isMain ? (
        <>
          {isLoading && <CustomSpinner />}
          <div className="find-agent-page">
            <div className="find-agent-page__cover">
              <div className="find-agent-page__discover">
                <h1 className="u-margin-bottom-large">
                  Find an agent and discover
                  {!isSM && <br />} your DREAM HOME now!
                </h1>
                <SearchField placeholder="Find Agents with name, mls, phone, email..." searchAction={searchAction} className="find-agent-page-search_field" />
              </div>
            </div>
            <div>
              <div className="find-agent-page-search_div">
                {searchText?.length > 0 ? (
                  <>
                    {noSearchData ? (
                      <span style={{ background: "red", fontSize: "13px", color: "white", padding: "3px", borderRadius: "6px" }}>No agents with this query!</span>
                    ) : (
                      <span style={{ fontSize: "13px", padding: "3px", borderRadius: "6px" }}>
                        {filteredAgents?.length > 0 ? filteredAgents?.length === 1 ? "1 Agent" : `${filteredAgents?.length} Agents` : ''}
                      </span>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
              {(searchText?.length > 0 ? filteredAgents : agents)?.map((item: any, index: any) => {
                return (
                  <FindAnAgentPageCard
                    key={index}
                    imageUrl={item?.imageUrl || ""}
                    agentName={item?.name || ""}
                    // agentType={item || ""}
                    companyLogo={item?.moreinfo?.imgurl || ""}
                    mlsAgentId={item?.mlsId || ""}
                    agentPhone={item?.phone}
                    agentEmail={item?.email}
                    agentWeb={item?.moreinfo?.url || ""}
                    agentAbout={item?.moreinfo?.bio || ""}
                    avatarUrl={item?.avatarUrl || ""}
                    agentTagline={item?.moreinfo?.tagline || ""}
                    agentPreferredContact={item?.preferredContact || ""}
                    goToSingleAngent={() => {
                      setSingleAgentDetails(item);
                      goToSingleAngent();
                    }}
                    agentSubscription_type={item?.subscription_type}
                  />
                );
              })}
            </div>
            <Row
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Col>
                {searchText.length <= 0 && <Button type={ButtonType.PRIMARY} text={"SEE MORE"} clickHandler={loadMoreAgents} className="find-agent-page-see_more-button" />}

                {/* )} */}
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <>
          {isLoading && <CustomSpinner />}
          {singleAgentDetails && !isContent && (
            <AboutAgent
              agentName={singleAgentDetails?.name || ""}
              agentPreferredContact={singleAgentDetails?.preferredContact || ""}
              imageUrl={singleAgentDetails?.avatarUrl || ""}
              agentPhone={singleAgentDetails?.phone || ""}
              agentAbout={singleAgentDetails?.moreinfo?.bio || "---"}
              agentWeb={singleAgentDetails?.moreinfo?.url || "---"}
              agentTagline={singleAgentDetails?.moreinfo?.tagline || "---"}
              agentCompanyLogo={singleAgentDetails?.moreinfo?.imgurl}
              agentEmail={singleAgentDetails?.email || ""}
              agentLocation={type || "---"}
              agentCompany={singleAgentDetails?.company || "---"}
              agentId={singleAgentDetails?.agentId || ""}
              avatarUrl={singleAgentDetails?.avatarUrl || ""}
              goToSingleAngent={goToSingleAngent}
              agentSubscription_type={singleAgentDetails?.subscription_type}
              designationsAndCertifications={singleAgentDetails?.certificate?.url}
            />
          )}
        </>
      )}
    </>
  );
};

export default FindAnAgentPage;
