/* eslint-disable use-isnan */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import styled from "@emotion/styled";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from "@fullcalendar/rrule";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Button, Col, Modal, Popover, Row } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import UserContext, { UserType } from "../../contexts/UserContext";
import CalendarModal from "../../modals/CalendarModal";
import {
  checkIsListedPropertyService,
  completedShowingsPropertyStatsTour,
  createBuyerNotificationById,
  deletePropertyCalendarByID,
  getAgentAccountSettings,
  getCalendarId,
  getCronofyAccessToken,
  getFreeSlotsPropertyCalendar,
  getPreferencesAgentType,
  getPropertyDetailsService,
  getViewPropertyCalenderById,
  insertCronofyCalendarEvents,
  inviteAgentByBuyer,
  postMessageToUser,
  postViewPropertyCalendar
} from "../../services/NavihomeService";
import { insertEventToGoogleCalendar } from "../../utils/syncEvent";
import { getSignedInUserEmail } from "../Account/GoogleService";
import { ScrollDiv } from "../AgentCalendar/AgentCalendarMain";
import CalendarRequestPopover from "../AgentCalendar/calendarPopovers/calendarRequestPopover";
import { getAllViewPropertyByAgetntId } from "../AgentCalendar/ulits";
import CustomSpinner from "../spinner/CustomSpinner";
import ToggleSwitch from "../ToggleSwitch";
import Availability from "./Availability";
import DragConfig from "./dragConfig";
import "./Main.css";
import "./Popover.css";
import dayjs from "dayjs";
import { notification } from "../notification";

const CustomBackDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 34px 16px 0;
  margin-left: -20px;
`;
const CustomBackSpan = styled.span`
  cursor: pointer;
  font-size: 17px;
  &:hover {
    text-decoration: underline;
    font-weight: 600;
  }
`;

function Calendar() {
  const history = useHistory();
  const { userState } = useContext(UserContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDragModalVisible, setIsDragModalVisible] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState<any>({});
  const [isShowingCalendarModal, setIsShowingCalendarModal] =
    useState<boolean>(false);
  const [isOn, setIsOn] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [calenderData, setCalenderData] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [Stime, setStime] = useState<any>();
  const [Sdate, setSdate] = useState<Date>(new Date());
  const [Etime, setEtime] = useState<any>();
  const [Edate, setEdate] = useState<Date>(new Date());
  const [hovered, setHovered] = useState(false);
  const [prefferedarray, setPreffered] = useState<any>({});
  const [showingType, setShowingType] = useState<string>("");
  const [acceptedRequest, setAcceptedRequest] = useState<string>("");
  const [calendarpropertyId, setcalendarpropertyId] = useState(null);
  const [description, setDescription] = useState<string>("");
  const [agentAccountSettings, setAgentAccountSettings] = useState({
    markWeekends: [],
    workingHoursFrom: "",
    workingHoursTo: "",
  });
  const [selectedpopover, setSelectedPopover] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [propertyPrice, setPropertyPrice] = useState<any>("");
  const [buyerId, setBuyerId] = useState<string>("");
  const [mlsId, setMlsId] = useState<string>("");
  let [view, setView] = useState("timeGridWeek");
  const [propertyFullAddress, setPropertyFullAddress] = useState<any>(" ");
  const [propertyAddress, setPropertyAddress] = useState<any>(" ");
  const [keyID, setKeyID] = useState<any>();
  const [eventFilter, setEventFilter] = useState<Array<any>>([]);
  const [isListed, setIsListed] = useState<Boolean>(false);
  const [listingAgentId, setListingAgentId] = useState<number>(0);
  const [listingAgentSellerName, setListingAgentSellerName] =
    useState<string>("");
  const [listingAgentSellerPhone, setListingAgentSellerPhone] =
    useState<string>("");
  const [listingAgentName, setListingAgentName] = useState<string>("");
  const [listingAgentEmail, setListingAgentEmail] = useState<string>("");
  const [listingAgentComp, setListingAgentComp] = useState<string>("");
  const [listingAgentImg, setListingAgentImg] = useState<string>("");
  const [listingAgentPhone, setListingAgentPhone] = useState<string>("");
  const [listingAgentPreffCont, setListingAgentPreffCont] =
    useState<string>("");

  useEffect(() => {
    const query = history?.location;

    const getMmlsId = query.pathname?.split("/")?.[2]?.split("&")?.[0] ?? null;
    const getBuyerId = param.get("query") ?? null;
    setMlsId(getMmlsId);
    setBuyerId(getBuyerId);
    getListedPropertiesStatus(Number(getMmlsId));
  }, [history?.location]);

  useEffect(() => {
    getAccountDetails();
  }, [isOn]);

  const [values, setValues] = useState({
    date1: "",
    time1: "",
    date2: "",
    time2: "",
    recurringStatus: "",
    description: "",
  });

  let [offCalHours, setOffCalHours] = useState({
    startTime: "",
    endTime: "",
  });
  let calendarRef = useRef(null);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let param = useQuery();


  const getAccountDetails = async (upComingListingAgentIdget?: any) => {
    setIsLoading(true);
    try {
      const upComingListingAgentId = param.get("listingAgentId") ?? upComingListingAgentIdget ?? listingAgentId;

      if (upComingListingAgentId && !isOn) {
        await getAgentAccountSettings(
          Number(upComingListingAgentId)
        ).then((value: any) => {
          let data = value?.[0];
          let weekends = data?.markWeekends?.split(",");
          setAgentAccountSettings((agentAccountSettings) => ({
            ...agentAccountSettings,
            markWeekends: weekends,
            workingHoursFrom: data?.workingHoursfrom,
            workingHoursTo: data?.workingHourTo,
          }));
          setOffCalHours((offCalHours) => ({
            ...offCalHours,
            startTime: data?.workingHoursfrom,
            endTime: data?.workingHourTo,
          }));
          setView(data?.CalendarView);
        });
      } else {
        await getAgentAccountSettings(
          userState?.agent?.agentId
        ).then((value: any) => {
          let data = value?.[0];
          let weekends = data?.markWeekends?.split(",");
          setAgentAccountSettings((agentAccountSettings) => ({
            ...agentAccountSettings,
            markWeekends: weekends,
            workingHoursFrom: data?.workingHoursfrom,
            workingHoursTo: data?.workingHourTo,
          }));
          setOffCalHours((offCalHours) => ({
            ...offCalHours,
            startTime: data?.workingHoursfrom,
            endTime: data?.workingHourTo,
          }));
          setView(data?.CalendarView);
        });
      }

      setIsLoading(false);
    } catch (error) {
      console.log("ERROR: ", error);
      setIsLoading(false);
    }
  };

  let dayChange = (value: string) => {
    switch (value) {
      case "next":
        calendarRef?.current?.getApi().next();
        break;
      case "prev":
        calendarRef?.current?.getApi().prev();
        break;
      case "Today":
        calendarRef?.current?.getApi()?.today();
        break;
    }
  };

  const checkIfListed = async (listingId: number) => {
    try {
      const response: any = await checkIsListedPropertyService(listingId);



      if (response?.success) {
        setIsListed(response?.success);
        setListingAgentId(response?.data?.agentId ?? 0);
        setListingAgentSellerName(response?.data?.name ?? "");
        setListingAgentName(response?.data?.agentName ?? "");
        setListingAgentSellerPhone(response?.data?.sellerPhone);
        setListingAgentEmail(response?.data?.agentEmail);
        setListingAgentComp(response?.data?.agent?.company);
        setListingAgentImg(response?.data?.agent?.avatarUrl);
        setListingAgentPhone(response?.data?.agent?.phone);
        setListingAgentPreffCont(response?.data?.agent?.preferredContact);
        getAccountDetails(response?.data?.agentId);
      }
      console.error("Error:", response);
    } catch (error) {
      console.error("Error: ", error);
    }
  };
  useEffect(() => {
    // getOffDaysHandler();
    const query = history?.location;
    const getMmlsId = query?.pathname?.split("/")?.[2]?.split("&")?.[0] ?? null;
    if (getMmlsId) checkIfListed(Number(getMmlsId) ?? 0);
    // getUnavailableDetails();
    getAddedAvailability();
    getPropertyPrice(Number(getMmlsId));
  }, []);



  const getDayName = (dayIndex: number) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    return days[dayIndex];
  };
  let isOffHours = (date: Date) => {
    let day = date?.toDateString()?.split(" ")?.[0];
    if (agentAccountSettings?.markWeekends?.includes(day)) {
      return true;
    } else {
      return false;
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setConfirmLoading(true);
  };

  const handleDragOk = () => {
    setIsDragModalVisible(false);
  };

  const calanderModalHandler = () => {
    setIsShowingCalendarModal(!isShowingCalendarModal);
  };

  const selectHandler = (args: any) => {

    // let startDate = moment(args?.start)?.format("MM-DD-YYYY");
    // let currentDate = moment()?.format("MM-DD-YYYY");
    // if(startDate>=currentDate){

    // if (moment(startDate).isSameOrAfter(currentDate)) {
    let startDate = moment.utc(args?.start, "MM-DD-YYYY").format("MM-DD-YYYY");
    let currentDate = moment(moment(), "MM-DD-YYYY").format("MM-DD-YYYY");
    const date = moment(startDate, "MM-DD-YYYY").format("MM-DD-YYYY")

    if (date >= currentDate) {
      setSdate(args?.start);
      setStime(moment(args?.start).format("HH:mm a"));
      setEdate(args?.end);
      setEtime(moment(args?.end).format("HH:mm"));
      setIsShowingCalendarModal(true);
    } else {
      return notification("Please select future date", "error");
    }

  };

  const handleDragCancel = () => {
    setIsDragModalVisible(false);
  };
  const handleCancel = (): void => {
    setIsModalVisible(false);
    setHovered(false);
  };

  const handleHoverChange = (visible: boolean) => {
    // setHovered(visible);
  };

  const getPropertyPrice = async (propId: number) => {
    const propertyDetail: any = await getPropertyDetailsService(propId);

    if (propertyDetail) {
      setPropertyDetails(propertyDetail);
      setPropertyPrice(propertyDetail?.listPrice);
      setPropertyAddress(
        `${propertyDetail?.property?.area ?? ""} ${propertyDetail?.address?.streetNumber ?? ""
        } ${propertyDetail?.address?.streetName ?? ""} ${propertyDetail?.address?.city ?? ""
        }`
      );
      setPropertyFullAddress(
        `${propertyDetail?.property?.area ?? ""} ${propertyDetail?.address?.streetNumber ?? ""
        } ${propertyDetail?.address?.streetName ?? ""} ${propertyDetail?.address?.city ?? ""
        }, ${propertyDetail?.address?.state ?? ""} ${propertyDetail?.address?.postalCode ?? ""
        }`
      );
    }
  };
  const getListedPropertiesStatus = async (getMlsId: number) => {
    let response = await getPreferencesAgentType(
      userState?.agent?.token,
      getMlsId
    );
    if (response?.data) {
      setPreffered(response.data);
      setShowingType(response?.data?.showingType);
      setAcceptedRequest(response?.data?.acceptRequest);
    } else {
      console.log("ERROR:", response);
    }
  };

  const [dragInfo, setDragInfo] = useState<{
    startTime: Date;
    endTime: string;
    realStartTime: string;
    realEndTime: Date;
    eventId: string;
  }>({
    startTime: null,
    endTime: "",
    realStartTime: "",
    realEndTime: null,
    eventId: "",
  });

  const getModalData = async (values: any, start: any, end: any) => {
    const SDate: any = moment(values?.StartDate).format("MM/DD/YYYY");
    const mlsId = history?.location?.pathname?.split("/")?.[2];

    let propAddress: string = null;

    if (propertyDetails) {
      propAddress = `${propertyDetails?.address?.full} ${propertyDetails?.address?.city}, ${propertyDetails?.address?.state} ${propertyDetails?.address?.postalCode}`
    }

    let isAccepted: boolean = false;
    let upComingListingAgentId = param.get("listingAgentId") ?? listingAgentId;
    // this is for Yes option
    if (showingType === "Agent accompained" && acceptedRequest === "Yes") {
      isAccepted = true;
    }
    if (showingType === "Lockbox" && acceptedRequest === "Yes") {
      isAccepted = true;
    }

    // this is for within available period
    if (
      (showingType === "Agent accompained" &&
        acceptedRequest === "Within available period") ||
      (showingType === "Lockbox" &&
        acceptedRequest === "Within available period")
    ) {
      const freeSlotsData: any = await getFreeSlotsPropertyCalendar(
        Number(mlsId),
        Number(upComingListingAgentId)
      );
      freeSlotsData.forEach((item: any) => {

        const sDay: number = new Date(item?.startDate).getUTCDate();
        const sMonth: number = new Date(item?.startDate).getMonth();
        const sYear: number = new Date(item?.startDate).getFullYear();
        const tempDate: string = `${sMonth + 1}-${sDay}-${sYear}`;
        const local1 = moment(tempDate, 'MM-DD-YYYY').local();
        const momentFormat1 = moment(local1, 'YYYY-MM-DD').format('YYYY-MM-DD');
        let dateCheck2 =
          moment(momentFormat1).format("MM/DD/YYYY") ===
          moment(values?.StartDate).format("MM/DD/YYYY");
        let timeCheck =
          moment(values?.StartTime).isSameOrAfter(
            moment(item?.startTime, "hh:mma")
          ) &&
          moment(values?.EndTime).isSameOrBefore(
            moment(item?.endTime, "hh:mma")
          );
        if (dateCheck2 && timeCheck) {
          isAccepted = true;
          return;
        }
      });
    }

    const calendarEvents: any = [
      {
        agentId: userState?.agent?.agentId,
        mlsId: parseInt(mlsId) ?? null,
        // startDate: moment(values?.StartDate).utc(),
        startDate: moment(values?.StartDate).format("MM/DD/YYYY"),
        // endDate: moment(values?.EndDate).utc(),
        endDate: moment(values?.EndDate).format("MM/DD/YYYY"),
        startTime: moment(values?.StartTime).format("HH:mm:ss A"),
        endTime: moment(values?.EndTime).format("HH:mm:ss A"),
        recuringStatus: "Does not repeat",
        description: values?.Description,
        buyerId: buyerId,
        isAccepted: isAccepted,
        recuringStartDate: moment(values?.StartDate).format("MM/DD/YYYY"),
        recuringEndDate: moment(values?.EndDate).format("MM/DD/YYYY"),
        type: "Requested",
        sender: "agent",
        isRejected: false,
        propertyDetails: JSON.stringify(propertyDetails),
        agentAgentId: userState?.agent?.agentId,
        buyerBuyerId: buyerId,
        client: param.get("client") ? param.get("client") : listingAgentSellerName,
        listingAgentName: param.get("listingAgent") ? param.get("listingAgent") : listingAgentName,
        isFavorite: param.get("fav") === "true" ? true : false,
        isRequested: param.get("req") === "true" ? true : false,
        systemSchedule: isAccepted,
        showingType: showingType,
        clientPhone: param.get("clientPh") ? param.get("clientPh") : listingAgentSellerPhone,
      },
    ];

    setIsLoading(true);
    try {
      if (userState?.userType === UserType?.AGENT) {
        // let email = await getSignedInUserEmail();

        let messageType: string = "NO";
        const upComingListingAgentPreferredContact: string = param.get("LAPreffCont") ?? listingAgentPreffCont,
          upComingListingAgentId: number = Number(param.get("listingAgentId")) ?? listingAgentId,
          upComingListingAgentPhone: string = param?.get("LAPhone") ?? listingAgentPhone,
          agentAccountSettingsResult: any = await getAgentAccountSettings(
            upComingListingAgentId
          );

        if (agentAccountSettingsResult?.length > 0) {
          const lastIndex: number = agentAccountSettingsResult?.length - 1;
          messageType =
            agentAccountSettingsResult?.[lastIndex]
              ?.notifyShowingRequestListedProperty;
        }
        if (isAccepted) {
          const accessToken:any = await getCronofyAccessToken(userState?.agent?.agentId);
          console.log("accessToken------", accessToken);
          await createBuyerNotificationById(
            Number(buyerId),
            `Your Agent ${userState?.agent?.name?.toUpperCase()} has scheduled a property for viewing for ${propAddress} | #${param?.get(
              'listingId'
            ) ?? propertyDetails?.listingId} on ${moment(SDate).format("MMMM Do, yyyy")} from ${moment(values?.StartTime).format("hh:mm a")} - ${moment(values?.EndTime).format("hh:mm a")}.`,
            'accepted',
            userState?.agent?.token,
            'buyer',
            Number(mlsId)
          );

            const startDateTime = `${moment(values?.StartDate).format(
              "YYYY-MM-DD"
            )} ${values?.StartTime}`;
            const endDateTime = `${moment(values?.EndDate).format(
              "YYYY-MM-DD"
            )} ${values?.EndTime}`;
            const calendaerId:any  = await getCalendarId(accessToken?.accessToken ?? "");

          const calBody: any = {
              events: {
                event_id: Math.random() * 100,
                summary: "View Property",
                description: "Navihome Calendar Event",
                location: {description: `${propertyDetails?.address?.streetNumber} ${propertyDetails?.address?.streetName}, ${propertyDetails?.address?.city}, 
                ${propertyDetails?.address?.state}, ${propertyDetails?.address?.postalCode}`},
                start: {
                  time: moment().format(),
                  tzid: "America/Los_Angeles",
                },
                end: {
                  time: moment().add(1,'days').format(),
                  tzid: "America/Los_Angeles",
                },
              },
              calendarId: calendaerId?.calendars?.[0]?.calendar_id,
              accessToken: accessToken?.accessToken ?? ""
            };
            console.log('calendaerId-========', values,calBody);
            insertCronofyCalendarEvents(calBody);
        }
        calendarEvents[0]['listingAgentEmail'] = listingAgentEmail;
        calendarEvents[0]['buyerAgentName'] = userState?.agent?.name;
        calendarEvents[0]["messageType"] = upComingListingAgentPreferredContact === "CONTACT_RADIO_BOTH" || upComingListingAgentPreferredContact === "CONTACT_RADIO_EMAIL" ? messageType : "No";
        await postViewPropertyCalendar(
          calendarEvents,
          userState?.agent?.agentId,
          userState?.agent?.token
        );

        if (userState?.agent?.agentId !== upComingListingAgentId) {
          await inviteAgentByBuyer(
            upComingListingAgentId,
            "buyerAgent",
            `${userState?.agent?.name?.toUpperCase()} is requesting a viewing for ${propAddress} on ${moment(SDate).format("MMMM Do, yyyy")} from ${moment(values?.StartTime).format("hh:mm a")} - ${moment(values?.EndTime).format("hh:mm a")} with their client`,
            Number(mlsId),
            '',
            '',
            Number(param.get("query")),
            "Not Real Time",
            '',
            '',
          );

          if (
            messageType === "Realtime" &&
            (upComingListingAgentPreferredContact === "CONTACT_RADIO_BOTH" ||
              upComingListingAgentPreferredContact === "CONTACT_RADIO_PHONE")
          ) {
            postMessageToUser(
              upComingListingAgentPhone,
              `${userState?.agent?.name?.toUpperCase()} is requesting a viewing for ${propAddress} on ${moment(SDate).format("MMMM Do, yyyy")} from ${moment(values?.StartTime).format("hh:mm a")} - ${moment(values?.EndTime).format("hh:mm a")} with their client.`
            );
          }
        } else {
          await inviteAgentByBuyer(
            userState?.agent?.agentId,
            "buyerAgent",
            `You have requested a property #${param?.get(
              "listingId"
            )} for showing on ${moment(SDate).format("MMMM Do, yyyy")}`,
            Number(mlsId),
            "",
            "",
            Number(param.get("query"))
          );
          postMessageToUser(
            userState?.agent?.phone,
            `You have requested a property #${param?.get(
              "listingId"
            )} for showing on ${moment(SDate).format("MMMM Do, yyyy")}.`
          );
        }

        getAddedAvailability();
        getAllEvents();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console?.log("error", error);
    }
  };

  const getAddedAvailability = async () => {
    setIsLoading(true);
    try {
      let tempEventArray: any = [], eventsData: any = [];
      const mlsId = history?.location?.pathname?.split("/")?.[2];
      const viewProp: any = await getViewPropertyCalenderById(
        parseInt(mlsId),
        userState?.agent?.token
        // userState?.agent?.agentId
      );
      const tourProp: any = await completedShowingsPropertyStatsTour(
        parseInt(mlsId),
        "property-calendar"
      );
      
      const AcceptedArray: any = viewProp?.filter((item: any) => {
        if (
          (item?.type?.toLowerCase() === "requested" &&
            userState?.agent?.agentId === item?.agentId) ||
          (item?.type?.toLowerCase() === "requested" && item?.isAccepted)
        )
          return item;
        else if (item?.type?.toLowerCase() !== "requested") return item;
      });
      eventsData =[...AcceptedArray, ...tourProp]
      eventsData?.map((item: any) => {
        const stYear: any = new Date(item?.startDate)?.getFullYear();
        const stmonth: any = new Date(item?.startDate).getMonth();
        // const stDay: any = dayjs(item?.startDate).date();
        const stDay: any = new Date(item?.startDate).getUTCDate();;
        let stHours: any = Number(item?.startTime.split(":")[0]);
        let mins: any = Number(item?.startTime.split(":")[1]);
        const endmonth: any = new Date(item?.endDate).getMonth();
        // let endDay: any = dayjs(item?.endDate).date();
        let endDay: any = new Date(item?.endDate).getUTCDate();
        let endHours: any = Number(item?.endTime.split(":")[0]);
        let endmins: any = Number(item?.endTime.split(":")[1]);
        if (item?.startTime?.split(" ")?.[1]?.toLowerCase() === "pm" && stHours < 12)
          stHours += 12;
        if (item?.endTime?.split(" ")?.[1]?.toLowerCase() === "pm" && endHours < 12)
          endHours += 12;
        if (mins === "0") mins = "00";
        if (endmins === "0") endmins = "00";

        if (
          Number(stDay) < Number(endDay) &&
          Number(stHours) < Number(endHours)
        ) {
          endDay -= 1;
        }

        const propertyDetails: any = item?.propertyDetails
          ? JSON?.parse(item?.propertyDetails)
          : {};

        tempEventArray.push({
          id: item?.type,
          title: item?.description ?? null,
          groupId: "Calendar Event",
          start: new Date(
            Number(stYear),
            Number(stmonth),
            Number(stDay),
            Number(stHours),
            Number(mins),
            0
          ),
          end: new Date(
            Number(stYear),
            Number(endmonth),
            Number(endDay),
            Number(endHours),
            Number(endmins),
            0
          ),
          recurstart: new Date(
            Number(stYear),
            Number(stmonth),
            Number(stDay),
            Number(stHours),
            Number(mins),
            0
          ),
          recurend: new Date(Number(stYear), 8, 5, 10, 30, 0),
          rStatus: item?.recuringStatus,
          eventId: item?.viewPropertyCalendarId,
          mlsId: item?.mlsId,
          Stime: item?.startTime,
          Sdate: item?.startDate,
          Etime: item?.endTime,
          Edate: item?.endDate,
          rEdate: item?.recuringEndDate,
          name: propertyDetails?.agent?.firstName,
          fullName:
            propertyDetails?.agent?.firstName +
            " " +
            propertyDetails?.agent?.lastName,
          eventType: "requestedP",
          agentName: "userState?.agent?.name",
          phone: propertyDetails?.agent?.contact?.office,
          address: propertyDetails?.address?.full,
          streetName: propertyDetails?.address?.streetName,
          streetNumber: propertyDetails?.address?.streetNumber,
          city: propertyDetails?.address?.city,
          state: propertyDetails?.address?.state,
          buyerId: item?.buyerId,
          country: propertyDetails?.address?.country,
          postalCode: propertyDetails?.address?.postalCode,
          pid: propertyDetails?.mlsId ? propertyDetails?.mlsId : item?.mlsId,
          color: "colorChange",
          isAccepted: item?.isAccepted,
          isRejected: item?.isRejected,
          type: item?.type,
          listingId: propertyDetails?.listingId,
          agentId: item?.agentId ?? 0,
          buyerName: item?.buyer?.name ?? "",
          buyerPhone: item?.buyer?.phone ?? "",
          buyerEmail: item?.buyer?.email ?? "",
          buyerAgentName: item?.listingAgentName ?? "",
          buyerAgentPhone: item?.agent?.phone ?? "",
          buyerAgentEmail: item?.agent?.email ?? "",
          client: item?.buyer?.name ?? "",
          clientPhone: item?.buyer?.phone ?? "",
        });

      });
      setCalenderData(tempEventArray);
      setIsLoading(false);
    } catch (error) {
      console.log("ERROR", error);
      setIsLoading(false);
    }
  };
  const deleteAvailabilityByEventId = async (valueID: number) => {
    setIsLoading(true);
    const token = userState?.agent?.token;
    await deletePropertyCalendarByID(valueID, token);
    getAddedAvailability();
    setIsLoading(false);
  };
  const backButtonHandler = () => {

    const formId = param.get("formId");
    const buyerId = param.get("buyerId");
    if (formId) {
      history.push(`/common?buyerId=${buyerId}&formId=${formId}&statusType=beforeRouteCreated&routeStatus=Saved&allAccepted=notAllAccepted&role=Agent`)
    } else {
      history.goBack();
    }
  };

  const getAllEvents = async () => {
    try {
      setIsLoading(true);
      const events: any = await getAllViewPropertyByAgetntId(
        userState?.agent?.agentId,
        userState?.agent?.token,
        "calendar",
        userState
      );
      const acceptedOnly = events?.filter(
        (item: any) => item?.isAccepted || item?.mlsId === Number(mlsId)
      );
      console.log("setEventFiltersetEventFiltersetEventFilter: ", acceptedOnly?.length);

      setEventFilter(acceptedOnly);
      setIsLoading(false);
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  return (
    <>
      <div className="div_preference">
        <div className="property-price">
          {isLoading && <CustomSpinner />}
          <CustomBackDiv>
            <CustomBackSpan onClick={backButtonHandler}>Back</CustomBackSpan>
          </CustomBackDiv>
          <div className="amount">
            <span>
              {Number(propertyPrice)?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 3,
              })}{" "}
              - Property{" "}
            </span>
            <span className="line">Calendar</span>
          </div>
        </div>
        <div
          className="calendarButtonRight"
          onClick={() => {
            if (!isOn && eventFilter?.length <= 0) getAllEvents();
          }}>
          <p>My Schedule</p>
          <ToggleSwitch
            isOn={isOn}
            setIsOn={setIsOn}
            yesText="YES"
            noText="My Schedule"
            key="KEY"
            children="CHILDREN"
          />
        </div>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}>
          <div className="div_availability">
            <div className="availability-section">
              <div className="availability-heading">
                <p>
                  You can find listing agent's availability on this calendar.
                </p>
              </div>
              <div className="availability-radio">
                <div className="circle c1">
                  <p className="heading">Unavailable</p>
                </div>
                <div className="circle c2">
                  <p className="heading">Preferred</p>
                </div>
                <div className="circle c3">
                  <p className="heading">Available</p>
                </div>
              </div>
            </div>
            <div className="listing-section">
              <p>
                Please note that the listing agent has set the following
                prefrences for this property:
              </p>
              <ul className="ul">
                <li className="li">
                  <span className="ul_circle" />
                  <span>
                    Showing Type:{" "}
                    {prefferedarray?.showingType
                      ? prefferedarray?.showingType
                      : "N/A"}
                    {""}
                  </span>
                </li>

                <li className="li">
                  <span className="ul_circle" />
                  Minimum notice period of{" "}
                  {prefferedarray?.minNoticePeriod
                    ? prefferedarray?.minNoticePeriod
                    : "N/A"}{" "}
                  {/* hours */}
                </li>
                <li className="li">
                  <span className="ul_circle" />
                  Maximum showing duration of{" "}
                  {prefferedarray?.maxDurationShowing
                    ? prefferedarray?.maxDurationShowing
                    : "N/A"}
                  {""}
                </li>
              </ul>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}>
          {isLoading && <CustomSpinner />}
          <div
            className="calendarButtons"
            style={{ alignContent: "space-between", marginTop: "-2rem" }}>
            <div
              className="calendarButtonLeft"
              style={{ marginBottom: "10px" }}>
              <Button
                className="viewButton"
                onClick={() => {
                  dayChange("prev");
                }}>
                <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
              </Button>
              <Button
                className="viewButton"
                onClick={() => {
                  dayChange("next");
                }}>
                <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
              </Button>
              <Button
                className="viewButton"
                onClick={() => {
                  dayChange("Today");
                }}>
                Today
              </Button>
            </div>
          </div>

          <ScrollDiv>
            <FullCalendar
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                rrulePlugin,
              ]}
              headerToolbar={{
                left: "",
                center: "",
                right: "",
              }}
              eventClick={(info) => {
                info.jsEvent.preventDefault();
              }}
              eventDisplay="block"
              selectable={true}
              select={selectHandler}
              allDaySlot={false}
              eventMaxStack={3}
              selectMirror={true}
              moreLinkClick="day"
              editable={false}
              droppable={true}
              eventStartEditable={false}
              eventResizableFromStart={true}
              eventDurationEditable={true}
              dayMaxEvents={true}
              all-day={false}
              displayEventTime={true}
              initialView={view?.toString()}
              nowIndicator={true}
              weekends={true}
              handleWindowResize={true}
              events={isOn ? eventFilter : calenderData}
              eventColor="#378006"
              eventOverlap={false}
              eventContent={(arg: any) => {
                return (
                  <>
                    {(
                      isOn
                        ? arg.event?._def?.extendedProps?.pid !== Number(mlsId) || arg.event?._def?.extendedProps?.buyerId !== Number(param.get("query"))
                        : arg.event?._def?.extendedProps?.type !==
                        "Available" &&
                        (arg.event?._def?.extendedProps?.agentId !== userState?.agent?.agentId || (arg.event?._def?.extendedProps?.agentId === userState?.agent?.agentId && arg.event?._def?.extendedProps?.buyerId !== Number(param.get("query"))))
                    ) ? (
                      <>
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            inset: "50px 0% -300px",
                            backgroundColor: arg?.event?.title
                              ? "#d9d9d9"
                              : "#9B0C04",
                            zIndex: 1,
                            padding: "0 4px",
                            whiteSpace: "normal",
                            borderRadius: "10px",
                            borderLeft: "1px solid white",
                            borderTop: "1px solid white",
                            overflow: "hidden"
                          }}>
                          <></>
                        </div>
                      </>
                    ) : (
                      <>
                        {arg.event?._def?.extendedProps?.type ===
                          "Available" ? (
                          <>
                            <Popover
                              trigger="hover"
                              overlayInnerStyle={{
                                border: "1.4px solid green",
                                borderRadius: "10px",
                              }}
                              content={
                                <>
                                  <div>
                                    <>
                                      <div className="content">
                                        <p className="content-heading">
                                          {arg?.event?.title}
                                        </p>
                                        <span className="date">
                                          {moment(arg?.event?.start).format(
                                            "Do MMMM"
                                          )}{" "}
                                          &bull;{" "}
                                          {moment(arg?.event?.start).format(
                                            "hh:mm a"
                                          )}{" "}
                                          -{" "}
                                          {moment(arg?.event?.end).format(
                                            "hh:mm a"
                                          )}
                                        </span>
                                        <div className="every-week">
                                          <p>
                                            {
                                              arg?.event?._def?.extendedProps
                                                ?.rStatus
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  </div>
                                </>
                              }
                              placement="top">
                              <div
                                onClick={(e) => {
                                  setSelectedPopover(
                                    arg?.event?._def?.extendedProps?.eventId
                                  );
                                  setHovered(true);
                                }}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  inset: "50px 0% -300px",
                                  backgroundColor: "#C2D500",
                                  zIndex: 1,
                                  padding: "0 1px",
                                  whiteSpace: "normal",
                                  borderRadius: "10px",
                                  borderLeft: "1px solid white",
                                  borderTop: "1px solid white",
                                  overflow: "hidden"
                                }}>
                                <p>Available</p>
                              </div>
                            </Popover>
                          </>
                        ) : (
                          <>
                            <Popover
                              trigger="click"
                              onVisibleChange={handleHoverChange}
                              overlayInnerStyle={{
                                border: "1.4px solid #004876",
                                borderRadius: "10px",
                                paddingLeft: "0%",
                                width: "342px",
                                height: "fit-content",
                              }}
                              style={{ width: "30%", height: "50%" }}
                              content={
                                <>
                                  <CalendarRequestPopover
                                    getEventDetails={(e: any) => {
                                      deleteAvailabilityByEventId(e);
                                    }}
                                    arg={arg}
                                    listingAgentId={Number(
                                      param?.get("listingAgentId")
                                    ) ?? listingAgentId}
                                    buyerId={Number(param?.get("query"))}
                                  />
                                </>
                              }
                              placement="left">
                              {arg?.event?._def?.extendedProps?.isAccepted ? (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    inset: "50px 0% -300px",
                                    backgroundColor: "green",
                                    zIndex: 1,
                                    padding: "1px 3px",
                                    whiteSpace: "normal",
                                    borderRadius: "6px",
                                    borderLeft: "1px solid #ccc",
                                    borderTop: "1px solid #ccc",
                                    overflow: 'hidden',
                                    textDecoration:
                                      arg?.event?._def?.extendedProps?.strike,
                                  }}>
                                  <p
                                    style={{ color: "#fff", fontSize: "15px" }}>
                                    {arg?.event?.title}{" "}
                                    {moment(arg?.event.start).format("hh:mm a")}{" "}
                                    |Accepted
                                  </p>
                                </div>
                              ) : arg?.event?._def?.extendedProps
                                ?.isRejected ? (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    inset: "50px 0% -300px",
                                    backgroundColor: "#9B0C04",
                                    zIndex: 1,
                                    padding: "1px 3px",
                                    whiteSpace: "normal",
                                    borderRadius: "6px",
                                    textDecoration:
                                      arg?.event?._def?.extendedProps?.strike,
                                    overflow: 'hidden'
                                  }}>
                                  <p
                                    style={{
                                      color: "#fff",
                                      fontSize: "15px",
                                      textDecoration: "line-through",
                                    }}>
                                    {arg?.event?.title}{" "}
                                    {moment(arg?.event.start).format("hh:mm a")}{" "}
                                    |Rejected
                                  </p>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    inset: "50px 0% -300px",
                                    backgroundColor: "#505050",
                                    zIndex: 1,
                                    padding: "1px 3px",
                                    whiteSpace: "normal",
                                    borderRadius: "6px",
                                    borderLeft: "1px solid #ccc",
                                    borderTop: "1px solid #ccc",
                                    overflow: "hidden",
                                    textDecoration:
                                      arg?.event?._def?.extendedProps?.strike,
                                  }}>
                                  <p
                                    style={{ color: "#fff", fontSize: "15px" }}>
                                    {arg?.event?.title}{" "}
                                    {moment(arg?.event.start).format("hh:mm a")}{" "}
                                    |Requested
                                  </p>
                                </div>
                              )}
                            </Popover>
                          </>
                        )}
                      </>
                    )}
                  </>
                );
              }}
              dayCellClassNames={(arg) => {
                if (isOffHours(arg ? arg?.date : null)) {
                  return "calendarDayClass";
                }
              }}
              height={"auto"}
              ref={calendarRef}
              slotLaneClassNames={(arg) => {
                let time = arg?.date?.toTimeString()?.split(" ")[0];
                let isTime =
                  time < offCalHours?.startTime || time > offCalHours?.endTime;
                let day = arg?.date?.toDateString()?.split(" ")?.[0];
                if (
                  isTime &&
                  !agentAccountSettings?.markWeekends?.includes(day)
                ) {
                  return ["offHours"];
                } else {
                  return ["timeGrid"];
                }
              }}
              eventDragStop={(info: any) => {
                setIsDragModalVisible(true);
                setcalendarpropertyId(
                  info?.event?._def?.extendedProps?.eventId
                );
              }}
              eventDrop={(eventDropInfo: any) => {
                let id =
                  eventDropInfo?.draggedEl?.fcSeg?.eventRange?.def
                    ?.extendedProps?.eventId;
                let startTimeStamp = new Date(eventDropInfo?.event?.start);
                let endTimeStamp = new Date(eventDropInfo?.event?.end);
                setDragInfo({
                  startTime: startTimeStamp,
                  endTime: endTimeStamp.toISOString(),
                  realStartTime: moment(
                    eventDropInfo?.event?._instance?.range?.start
                  ).format("MMMM Do YYYY"),
                  realEndTime: endTimeStamp,
                  eventId: id,
                });
              }}
              drop={(dropInfo) => { }}
              eventReceive={(info) => { }}
              dayHeaderContent={(arg) => {
                return (
                  <>
                    <div
                      style={{
                        margin: "10px !important",
                        padding: "5px !important",
                      }}>
                      {arg?.view?.type !== "dayGridMonth" && (
                        <div>
                          <div
                            style={{
                              color: "#000000",
                            }}>
                            {getDayName(new Date(arg?.date)?.getUTCDay())}
                          </div>
                          <div
                            style={{
                              color: "#000000",
                            }}>
                            {new Date(arg?.date)?.getMonth() + 1}/
                            {new Date(arg?.date).getUTCDate()}
                          </div>
                        </div>
                      )}
                      {arg?.view?.type == "dayGridMonth" && (
                        <div>
                          <div
                            style={{
                              color: "#000000",
                            }}>
                            {getDayName(new Date(arg?.date)?.getUTCDay())}
                          </div>
                          <div
                            style={{
                              color: "#000000",
                            }}></div>
                        </div>
                      )}
                    </div>
                  </>
                );
              }}
            />
          </ScrollDiv>
        </Col>
      </Row>
      {isModalVisible === true && (
        <div>
          <Modal
            centered
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            width={"auto"}>
            <Availability
              isNew={isNew}
              isEdit={isEdit}
              values={values}
              keyID={keyID}
              Sdate={Sdate}
              Stime={Stime}
              Edate={Edate}
              Etime={Etime}
              description={description}
              setValues={setCalenderData}
              geteventData={getAddedAvailability}
              setIsModalVisible={setIsModalVisible}
              buyerId={buyerId}
              propertyDetails={propertyDetails}
            />
          </Modal>
        </div>
      )}
      {isShowingCalendarModal && (
        <CalendarModal
          isShowing={isShowingCalendarModal}
          Sdate={Sdate}
          Stime={Stime}
          Edate={Edate}
          Etime={Etime}
          closeAction={calanderModalHandler}
          getModalData={getModalData}
          eventType="request"
          setIsRescheduleRequestEvent={() => { }}
        />
      )}
      {calendarpropertyId && dragInfo && isDragModalVisible === true && (
        <div>
          <Modal
            centered
            visible={isDragModalVisible}
            onOk={handleDragOk}
            onCancel={handleDragCancel}
            footer={null}
            width={"auto"}
            destroyOnClose={true}
            maskClosable={false}>
            <DragConfig
              dragInfo={dragInfo}
              calendarpropertyId={calendarpropertyId}
              setIsDragModalVisible={setIsDragModalVisible}></DragConfig>
          </Modal>
        </div>
      )}
    </>
  );
}

export default Calendar;
