import Button, { ButtonType } from "./Button";
import bannerAgent from '../assets/images/banner-agent.svg';
import avatarAgentTemplate from '../assets/images/avatar-agent-template.svg';
import iconEmail from '../assets/images/icon-email.svg';
import iconPhone from '../assets/images/icon-phone.svg';
import iconWebsite from '../assets/images/icon-website.svg';


interface NotificationAgentInviteProps {
  text: string,
  agentId: number,
  agentName: string,
  agentEmail: string,
  agentPhone: string,
  agentWebsite: string

}

const NotificationAgentInvite: React.FC<NotificationAgentInviteProps> = ({ text, agentId, agentName, agentEmail, agentPhone, agentWebsite }) => {

  const rejectInvite = async () => {

  }

  const acceptInvite = async () => {

  }

  

  return (
    <div className="notification-agent-invite">
      <div className="notification-agent-invite__info">
          <p>{text}</p>
        </div>
        <div className="notification-agent-invite__buttons">
          <Button 
            text="ACCEPT INVITE"
            type={ButtonType.PRIMARY}
            clickHandler={rejectInvite}
            className="notification-agent-invite__button--accept"
          />
          <Button 
            text="REJECT INVITE"
            type={ButtonType.WHITE}
            clickHandler={rejectInvite}
          />
      </div>
      <div className="notification-agent-invite__agent-info">
        <div className="notification-agent-invite__agent-info__banner">
          <img src={bannerAgent} alt="Banner Agent" />
        </div>
        <div className="notification-agent-invite__agent-info__avatar">
          <img src={avatarAgentTemplate} alt="Avatar Agent" />
        </div>
        <div className="notification-agent-invite__agent-info__text-info">
          <p className="notification-agent-invite__agent-info__text-info__name">{agentName}</p>
          <div>
            <img src={iconEmail} alt="Email icon" />
            <p>{agentEmail}</p>
          </div>
          <div>
            <img src={iconPhone} alt="Phone icon" />
            <p>{agentPhone}</p>
          </div>
          <div>
            <img src={iconWebsite} alt="Website icon" />
            <p>{agentWebsite}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationAgentInvite;