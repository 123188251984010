/* eslint-disable react-hooks/exhaustive-deps */
import styled from "@emotion/styled";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import iconMarked from "../assets/images/icon-marked.svg";
import iconNotMarked from "../assets/images/icon-not-marked.svg";
import Button, { ButtonType } from "../components/Button";
import UserContext from "../contexts/UserContext";
import { activateFreePlan, getActivatedSubscriptionPlan, makePaymentsTest } from "../services/NavihomeService";
import FindAnAgentConfirmModal from "../modals/FindAnAgentConfirmModal";
import { Modal } from "antd";

const CustomGettingStartedHeading = styled.h1`
  font-weight: 600;
  color: #004876, @media screen and (max-width: 768px) {
    font-size: 28px !important;
    margin-left: 20px;
  }
  @media screen and (max-width: 1368px) {
    margin-left: 20px;
  }
  @media screen and (max-width: 576px) {
    font-size: 20px !important;
    margin-left: 20px;
    margin-top: 20px;
    :after {
      display: none;
    }
  }
`;
const Customcontent = styled.div`
  @media screen and (max-width: 970px) {
    margin-left: 10px;
  }
`;
const CustomBackP = styled.p`
  margin-left: 12px;
  margin-top: 16px;
  cursor: pointer;
  font-size: 17px;
  &:hover {
    text-decoration: underline;
    font-weight: 600;
  }
`;

const CustomDiv = styled.div<{
  styleProps: boolean;
}>`
  background-color: ${(p) => (p.styleProps ? "#c2d500" : "#D6D6D6")};
  position: relative;
`;

const PricingPage: React.FC = () => {
  const history = useHistory();
  const [isFreeDaysRemaining, setIsFreeDaysRemaining] = useState<boolean>(false);
  const [plan, setPlan] = useState<any>({ planName: "", status: "", activePlan: "" });
  const { userState } = useContext(UserContext);
  const [downGradeModalShow, setDownGradeModalShow] = useState<boolean>(false);
  const [downGradeModalText, setDownGradeModalText] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [paymentPlan, setPaymentPlan] = useState<string>("");
  const [freeConfirmation, setFreeConfirmation] = useState<boolean>(false);
  const selectPlan = (type: string, plan: string) => {
    setPaymentPlan(plan);
    setType(type);
    if (type === "Navihome Premimum Plan" && userState.agent.subscription_type === "Navihome Advanced Plan") {
      setDownGradeModalShow(true);
      setDownGradeModalText(`You will be downgraded to Premium Plan after ${moment(userState.agent.expireAt).format("MMMM Do, yyyy")}. Are you sure you want to continue?`);
    } else if (type === "Navihome Basic Plan" && (userState.agent.subscription_type === "Navihome Advanced Plan" || userState.agent.subscription_type === "Navihome Premimum Plan")) {
      setDownGradeModalShow(true);
      setDownGradeModalText(`You will be downgraded to Basic Plan after ${moment(userState.agent.expireAt).format("MMMM Do, yyyy")}. Are you sure you want to continue?`);
    } else if (type === "Navihome Free Plan") {
      setFreeConfirmation(true);
      setDownGradeModalText(`You will be downgraded to Free Plan after ${moment(userState.agent.expireAt).format("MMMM Do, yyyy")}. Are you sure you want to continue?`);
    } else {
      history.push({ pathname: "/checkout", search: `?type=${type}&plan=${plan}&mode=upgrade&isFreeDaysRemaining=${isFreeDaysRemaining}` });
    }
  };

  const selectBackButtun = () => {
    history.goBack();
  };

  const makePaymentsTestService = async () => {
    try {
      const response = await makePaymentsTest(userState.agent.name, userState.agent.token);
      console.log("responseresponse", response);
    } catch (error) {
      console.log("Error: ", error);

    }
  }
  const feeDaysCountHandler = async () => {
    const response = await getActivatedSubscriptionPlan(userState?.agent?.agentId, userState?.agent?.token);
    const { planName, canceledDate, activePlan } = response;
    const cancelDate = moment(canceledDate).format("YYYY-MM-DD");
    const cDate = moment().format("YYYY-MM-DD");
    const status = cancelDate === cDate ? "ACTIVE" : "INACTIVE";
    setPlan({ planName, status, activePlan });
    const from = new Date();
    const to = userState?.agent?.registeredAt;
    const count = Math.abs(moment(from, "YYYY-MM-DD").startOf("day").diff(moment(to, "YYYY-MM-DD").startOf("day"), "days")) + 1;

    console.log("responseresponse", response, "count", count, "User Details", userState?.agent);

    if (count <= 30) {
      setIsFreeDaysRemaining(true);
    }
  };
  const SignUpAsAgent = () => {
    history.push("/register");
  };
  const selectFreePlan = async () => {
    const response = await activateFreePlan(userState?.agent?.agentId, userState?.agent?.token);
    if (response) setFreeConfirmation(false);
  };

  const yesClickHandler = () => {
    history.push({ pathname: "/checkout", search: `?type=${type}&plan=${paymentPlan}&mode=downgrade&isFreeDaysRemaining=${isFreeDaysRemaining}` });
  };

  useEffect(() => {
    if (userState?.agent?.agentId) {
      feeDaysCountHandler();
    }
  }, []);

  return (
    <>
      <CustomBackP onClick={selectBackButtun}>Back</CustomBackP>
      <div className="pricing-page">
        <CustomGettingStartedHeading onClick={() => makePaymentsTestService()}>
          <span>Choose a plan that meets your busine</span>
          <span style={{ borderBottom: "3px solid #C2D500" }}>ss needs!</span>
        </CustomGettingStartedHeading>
        <div className="pricing-page-table">
          <Customcontent className="pricing-page__grid pricing-page__grid_table_content">
            <div className="pricing-page__pricing-plan-details">
              <p className="pricing-page__plan-type" style={{ fontSize: "20px" }}>
                Features available
              </p>
            </div>
            <CustomDiv styleProps={isFreeDaysRemaining} className="pricing-page__pricing-plan-details pricing-page__pricing-plan-details--grey">
              <p className="pricing-page__plan-type">Advanced</p>
              {isFreeDaysRemaining && (
                <span style={{ position: "absolute", right: "3px", top: "3px", background: "#fff", color: "#004876", fontSize: "12px", fontWeight: "bold", borderRadius: "20px", padding: "2px 4px" }}>
                  FREE TRIAL
                </span>
              )}
              <p className="pricing-page__plan-price">
                <span className="price-tag">$49.99</span> / month
              </p>
              <Button
                type={
                  (plan?.planName || userState?.agent?.subscription_type) === "Navihome Advanced Plan"
                    ? isFreeDaysRemaining
                      ? ButtonType.BLUE_BUTTON
                      : ButtonType.BROWN_SMALL
                    : ButtonType.BLUE_BUTTON
                }
                text={
                  userState.isLoggedIn ? ((plan?.planName || userState?.agent?.subscription_type) === "Navihome Advanced Plan" ? (isFreeDaysRemaining ? "SELECT" : "SELECTED") : "SELECT") : "Sign Up"
                }
                clickHandler={
                  userState.isLoggedIn
                    ? (plan?.planName || userState?.agent?.subscription_type) === "Navihome Advanced Plan"
                      ? isFreeDaysRemaining
                        ? () => selectPlan("Navihome Advanced Plan", "49.99")
                        : null
                      : () => selectPlan("Navihome Advanced Plan", "49.99")
                    : SignUpAsAgent
                }
              />
            </CustomDiv>
            <div className="pricing-page__pricing-plan-details">
              <p className="pricing-page__plan-type">Premium</p>
              <p className="pricing-page__plan-price">
                <span className="price-tag">$39.99</span> / month
              </p>
              <Button
                type={(plan?.planName || userState?.agent?.subscription_type) === "Navihome Premimum Plan" ? ButtonType.BROWN_SMALL : ButtonType.BLUE_BUTTON}
                text={userState.isLoggedIn ? ((plan?.planName || userState?.agent?.subscription_type) === "Navihome Premimum Plan" ? "SELECTED" : "SELECT") : "Sign Up"}
                clickHandler={
                  userState.isLoggedIn
                    ? (plan?.planName || userState?.agent?.subscription_type) === "Navihome Premimum Plan"
                      ? null
                      : () => selectPlan("Navihome Premimum Plan", "39.99")
                    : SignUpAsAgent
                }
              />
            </div>
            <div className="pricing-page__pricing-plan-details pricing-page__pricing-plan-details--grey">
              <p className="pricing-page__plan-type">Basic</p>
              <p className="pricing-page__plan-price">
                <span>$29.99</span> / month
              </p>
              <Button
                type={(plan?.planName || userState?.agent?.subscription_type) === "Navihome Basic Plan" ? ButtonType.BROWN_SMALL : ButtonType.BLUE_BUTTON}
                text={userState.isLoggedIn ? ((plan?.planName || userState?.agent?.subscription_type) === "Navihome Basic Plan" ? "SELECTED" : "SELECT") : "Sign Up"}
                clickHandler={
                  userState.isLoggedIn ? ((plan?.planName || userState?.agent?.subscription_type) === "Navihome Basic Plan" ? null : () => selectPlan("Navihome Basic Plan", "29.99")) : SignUpAsAgent
                }
              />
            </div>
            <div
              className={`pricing-page__pricing-plan-details ${userState.isLoggedIn && (plan?.planName || userState?.agent?.subscription_type) === "Navihome Free Plan" && "free-plan-background-color"
                }`}
            >
              <p className="pricing-page__plan-type">Free</p>
              <p className="pricing-page__plan-price">
                <span>$0.00</span> / month
              </p>
              <Button
                type={(plan?.planName || userState?.agent?.subscription_type) === "Navihome Free Plan" ? ButtonType.BROWN_SMALL : ButtonType.BLUE_BUTTON}
                text={userState.isLoggedIn ? ((plan?.planName || userState?.agent?.subscription_type) === "Navihome Free Plan" ? "SELECTED" : "SELECT") : "Sign Up"}
                clickHandler={userState.isLoggedIn ? () => selectPlan("Navihome Free Plan", "0") : SignUpAsAgent}
              />
            </div>
            <div className="pricing-page__cell">
              <p>Advertising to gain more clients.</p>
            </div>
            <div className="pricing-page__cell--grey">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell">
              <img src={iconNotMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell--grey">
              <img src={iconNotMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell">
              <img src={iconNotMarked} alt="Checkmark" />
            </div>

            <div className="pricing-page__cell">
              <p>White Label (Custom Brand the app with your name, logo, profile photo and color scheme).</p>
            </div>
            <div className="pricing-page__cell--grey">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell--grey">
              <img src={iconNotMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell">
              <img src={iconNotMarked} alt="Checkmark" />
            </div>

            <div className="pricing-page__cell">
              <p>Schedule multiple open house property viewings.</p>
            </div>
            <div className="pricing-page__cell--grey">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell--grey">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell">
              <img src={iconNotMarked} alt="Checkmark" />
            </div>

            <div className="pricing-page__cell">
              <p>Route and schedule property viewings for multiple properties (no open house required).</p>
            </div>
            <div className="pricing-page__cell--grey">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell--grey">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell">
              <img src={iconNotMarked} alt="Checkmark" />
            </div>

            <div className="pricing-page__cell">
              <p>Set listing property availability windows.</p>
            </div>
            <div className="pricing-page__cell--grey">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell--grey">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell">
              <img src={iconMarked} alt="Checkmark" />
            </div>

            <div className="pricing-page__cell">
              <p>Sync MLS listings to the app.</p>
            </div>
            <div className="pricing-page__cell--grey">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell--grey">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell">
              <img src={iconMarked} alt="Checkmark" />
            </div>

            <div className="pricing-page__cell">
              <p>Schedule property viewings for your clients automatically (one at-a-time).</p>
            </div>
            <div className="pricing-page__cell--grey">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell--grey">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell">
              <img src={iconMarked} alt="Checkmark" />
            </div>

            <div className="pricing-page__cell">
              <p>Create property favorite lists for your client.</p>
            </div>
            <div className="pricing-page__cell--grey">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell--grey">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell">
              <img src={iconMarked} alt="Checkmark" />
            </div>

            <div className="pricing-page__cell">
              <p>Send your client property recommendations.</p>
            </div>
            <div className="pricing-page__cell--grey">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell--grey">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell">
              <img src={iconMarked} alt="Checkmark" />
            </div>

            <div className="pricing-page__cell">
              <p>Invite buyers, sellers and other agents to join the app.</p>
            </div>
            <div className="pricing-page__cell--grey">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell--grey">
              <img src={iconMarked} alt="Checkmark" />
            </div>
            <div className="pricing-page__cell">
              <img src={iconMarked} alt="Checkmark" />
            </div>
          </Customcontent>
        </div>
        {downGradeModalShow && (
          <FindAnAgentConfirmModal
            closeAction={() => setDownGradeModalShow(false)}
            isShowing={downGradeModalShow}
            text={downGradeModalText}
            yesText="Ok"
            yesClickHandler={yesClickHandler}
            noText="Cancel"
            pageType="PricingPage"
          />
        )}
        {freeConfirmation && (
          <Modal
            onOk={() => selectFreePlan()}
            destroyOnClose={true}
            visible={freeConfirmation}
            width={680}
            centered={true}
            title={"Free Plan"}
            onCancel={() => {
              setFreeConfirmation(false);
            }}
          >
            <p style={{ fontSize: "18px" }}>{`You will be downgraded to Free Plan after ${moment(userState.agent.expireAt).format("MMMM Do, yyyy")}. Are you sure you want to continue?`}</p>
          </Modal>
        )}
      </div>
    </>
  );
};

export default PricingPage;
