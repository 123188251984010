import AccountDetail from "../components/Account/AccountAgentPage";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import UserHeader from "../components/notificationAgentPage/UserHeader";
import { activatedPlan } from "../utils/base";
import { useContext } from "react";
import UserContext from "../contexts/UserContext";

const CustomBackDiv = styled.p`
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  color: #000000;
  &:hover {
    // font-weight: 600;
    text-decoration: underline;
  }
`;

const AccountDetailsAgentPage: React.FC = () => {
  let history = useHistory();
 const {userState} =useContext(UserContext);
  const backButtonHandler = () => {
    history.goBack();
  };

  return (
    <>
    {activatedPlan?.includes(userState?.agent?.subscription_type) && (
    <UserHeader />
      )}
      <CustomBackDiv onClick={backButtonHandler}>Back</CustomBackDiv>
      <div className="account-settings-agent-page">
        <div className="account-settings-agent-page__container">
          <AccountDetail />
        </div>
      </div>
    </>
  );
};

export default AccountDetailsAgentPage;
