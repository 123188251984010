import Button, { ButtonType } from "./Button";

interface NotificationGroupProps {
  text: string,
  timestamp: string,
  buttonHandler: () => void;
}

const NotificationGroup: React.FC<NotificationGroupProps> = ({ text, timestamp, buttonHandler }) => {

  return (
    <div className="notification-group">
      <div className="notification-group__info-container">
        <div className="notification-group__color-flag"></div>
        <div className="notification-group__info">
          <p className="notification-group__info-text">{text}</p>
          <p className="notification-group__timestamp">{timestamp}</p>
        </div>
      </div>
      <div className="notification-group__buttons">
        <Button 
          text="NOTIFICATIONS"
          type={ButtonType.PRIMARY}
          clickHandler={buttonHandler}
        />
      </div>
    </div>
  );
};

export default NotificationGroup;