/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState } from "react";

import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  TimePicker
} from "antd";
import "./styles/calendarModal.css";

import styled from "@emotion/styled";
import moment from "moment";
import { useHistory } from "react-router-dom";
import CustomSpinner from "../../../../components/spinner/CustomSpinner";
import UserContext from "../../../../contexts/UserContext";
import {
  getViewPropertyCalendarByID,
  postViewPropertyCalendar
} from "../../../../services/NavihomeService";
import CustomRecurrenceModal from "../calendarModals/customRecurrenceModal";

const dateFormatList = ["MM/DD/YYYY", "MM/DD/YY"];
const style = { background: "white", padding: "8px 0" };
const { Option } = Select;
const { TextArea } = Input;

const FullDatePicker: any = styled(DatePicker)``;
const CustomButton = styled(Button)`
  color: #ffff;
  background: #365073;
  border-radius: 10px;
  :hover {
    background: #c2cc30;
    borer-color: none;
  }
`;

interface IAppProps {
  isNew?: boolean;
  isEdit?: boolean;
  keyID?: number;
  values?: any;
  setValues: any;
  setIsModalVisible?: any;
  avaibilityModalVisible: boolean;
  setAvaibilityModalVisible: Function;
  availabilityModalData: {
    title: string;
  };
}

const CalendarAvailabilityModal: React.FunctionComponent<IAppProps> = ({
  isNew,
  isEdit,
  keyID,
  values,
  setValues,
  setIsModalVisible,
  avaibilityModalVisible,
  setAvaibilityModalVisible,
  availabilityModalData,
}) => {
  const history = useHistory();
  const { userState } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [customRecurrenceModal, setCustomRecurrenceModal] = useState(false);
  let [recurrenceModalData, setRecurrenceModalData] = useState({
    start: "",
    end: "",
    title: "",
  });
  const [form]: any = Form.useForm();
  // const mlsId = history.location.pathname.split('/')[2]

  const recurringData = [
    "Does not Repeat",
    "Daily",
    "Weekly",
    "Monthly",
    "Custom",
  ];

  const getAddedAvailability = async (mlsId: number) => {
    try {
      const token = userState.agent.token;
      if (isEdit) {
        setIsLoading(true);
        const result: any = await getViewPropertyCalendarByID(
          Number(mlsId),
          token
        );
        let data = result.filter((item: any) => {
          if (item.viewPropertyCalendarId === keyID) {
            return item;
          }
        });
        setValues({
          ...values,
          date1: data[0]?.startDate,
          time1: data[0]?.startTime,
          date2: data[0]?.endDate,
          time2: data[0]?.endTime,
          recurringStatus: data[0]?.recuringStatus,
          description: data[0]?.description,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (values: any) => {

    setIsModalVisible(false);
    try {
    
      const mlsId = history.location.pathname.split("/")[2];

      const SDate: any = moment(values?.StartDate).format("MMMM DD, YYYY");
      const STime: any = moment(values?.StartTime).format("hh:mm:ss");
      const Edate: any = moment(values?.EndDate).format("MMMM DD, YYYY");
      const Etime: any = moment(values?.EndTime).format("hh:mm:ss");
      const Description: string = values?.Description;
      const calendarEvents: any = [
        {
          agentId: userState?.agent?.agentId,
          mlsId: parseInt(mlsId) ?? null,
          startDate: moment(values?.StartDate).format('MM/DD/YYYY'),
          endDate: moment(values?.EndDate).format('MM/DD/YYYY'),
          startTime: moment(values?.StartTime).format('HH:mm:ss A'),
          endTime: moment(values?.EndTime).format('HH:mm:ss A'),
          recuringStatus: values?.RecurringStatus,
          description: values?.Description,
          buyerId: 57,
          isAccepted: false,
          recuringStartDate: moment(values?.StartDate).format('MM/DD/YYYY'),
          recuringEndDate: moment(values?.EndDate).format('MM/DD/YYYY'),
          type: 'Requested',
          sender: 'agent',
          isRejected: false,
          propertyDetails: "",
        },
      ];
     
      setIsLoading(true);

      const res = await postViewPropertyCalendar(
        calendarEvents,
        userState?.agent?.agentId,
        userState?.agent?.token
      );
      
      if (res) {
        setAvaibilityModalVisible(false);
        try {
          getAddedAvailability(Number(mlsId));
        } catch (err) {
          console.log(err);
          return err;
        }
      }
      setIsModalVisible(false);
      setIsLoading(false);
    } catch (err) {
      setIsModalVisible(false);
      setIsLoading(false);
      return { err };
    }
  };
  return (
    <Modal
      centered
      visible={avaibilityModalVisible}
      onOk={() => {
        setAvaibilityModalVisible(!avaibilityModalVisible);
      }}
      onCancel={() => {
        setAvaibilityModalVisible(!avaibilityModalVisible);
      }}
      footer={null}
      width={"auto"}
    >
      <>
        {isLoading && <CustomSpinner />}
        <h1>Calendar Availability </h1>
        <br></br>
        <p>
          <Form form={form} onFinish={onFinish}>
            <p>
              <h4>Date and time:</h4>
              <Form.Item>
                <Row gutter={[16, 24]}>
                  <Col span={5}>
                    <div style={style}>
                      Date*
                      <Form.Item
                        required
                        name="StartDate"
                        rules={[{ required: true }]}
                        // initialValue={moment(
                        // 		values.date1
                        //   ).format("YYYY-MM-DD")}
                      >
                        <FullDatePicker
                          onChange={() => {
                            setValues((preState: any) => ({
                              ...preState,
                              date1: moment()?.format(""),
                            }));
                          }}
                          value={moment(values?.date1)}
                          // value={
                          // 	isEdit
                          // 		? moment(values.date1).format("YYYY-MM-DD")
                          // 		: values.date1
                          // }
                          format={dateFormatList}
                          style={{
                            borderRadius: "5px",
                            boxShadow: "1px 1px 1px grey ",
                            border: "solid",
                            borderColor: "darkblue",
                            borderWidth: "thin",
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={5}>
                    <div style={style}>
                      Time*
                      <Form.Item
                        name="StartTime"
                        required
                        rules={[{ required: true }]}
                        // initialValue={values.time1}
                      >
                        <TimePicker
                          use12Hours
                          format="h:mm a"
                          onChange={() => {
                            setValues((preState: any) => ({
                              ...preState,
                              time1: moment().format(),
                            }));
                          }}
                          value={values?.time1}
                          style={{
                            borderRadius: "5px",
                            boxShadow: "2px 2px 2px grey",
                            border: "solid",
                            borderColor: "darkblue",
                            borderWidth: "thin",
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <h1 style={{ alignContent: "center" }}>
                    <img
                      src="https://img.icons8.com/external-royyan-wijaya-detailed-outline-royyan-wijaya/24/000000/external-arrow-arrow-line-royyan-wijaya-detailed-outline-royyan-wijaya-13.png"
                      style={{
                        color: "blue",
                        alignContent: "center",
                        marginTop: "150%",
                        marginLeft: "-1%",
                      }}
                    />
                  </h1>
                  <Col span={5}>
                    <div style={style}>
                      Date*
                      <Form.Item
                        name="EndDate"
                        required
                        rules={[{ required: true }]}
                        // initialValue={values.date2}
                      >
                        <FullDatePicker
                          onChange={() => {
                            setValues((preState: any) => ({
                              ...preState,
                              date2: moment().format(""),
                            }));
                          }}
                          value={values?.date2}
                          style={{
                            borderRadius: "5px",
                            boxShadow: "2px 2px 2px grey",
                            border: "solid",
                            borderColor: "darkblue",
                            borderWidth: "thin",
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={5}>
                    <div style={style}>
                      Time*
                      <Form.Item
                        name="EndTime"
                        required
                        rules={[{ required: true }]}
                        // initialValue={values.time2}
                      >
                        <TimePicker
                          use12Hours
                          format="h:mm a"
                          onChange={() => {
                            setValues((preState: any) => ({
                              ...preState,
                              time2: moment().format(),
                            }));
                          }}
                          value={values?.time2}
                          style={{
                            borderRadius: "5px",
                            boxShadow: "2px 2px 2px grey",
                            border: "solid",
                            borderColor: "darkblue",
                            borderWidth: "thin",
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </Form.Item>
            </p>
            <p>
              <h4>Recurring Status:</h4>
              <Form.Item name={"recurringstatus"}>
                <Row>
                  <Select
                    className="width100"
                    placeholder={"Recurring status"}
                    showSearch
                    // style={{ width: '100%' }}
                    value={values?.recurringStatus}
                    onChange={(value) => {
                      if (value === "Custom") {
                        setCustomRecurrenceModal(!customRecurrenceModal);
                      }
                      setValues((preState: any) => ({
                        ...preState,
                        recurringStatus: value,
                      }));
                    }}
                  >
                    {recurringData.length > 0
                      ? recurringData.map((item, index) => {
                          return (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          );
                        })
                      : null}
                  </Select>
                </Row>
              </Form.Item>
            </p>
            <p>
              <br></br>
              <h4>Description:</h4>
              <Form.Item
                name={"Description"}
                rules={[{ required: true }]}
                initialValue={values?.description}
              >
                <TextArea
                  rows={4}
                  cols={4}
                  style={{
                    borderRadius: "5px",
                    boxShadow: "2px 2px 2px grey ",
                    border: "solid",
                    borderColor: "darkblue",
                    borderWidth: "thin",
                  }}
                  // value={values.description}
                  placeholder="Add a description"
                  onChange={(e) => {
                    setValues((preState: any) => ({
                      ...preState,
                      description: e.target.value,
                    }));
                  }}
                />
              </Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Form.Item>
                  <CustomButton
                    htmlType="submit"
                    className="common_button_buyer_tour_details"
                  >
                    Add Availability
                  </CustomButton>
                </Form.Item>
              </div>
            </p>
          </Form>
        </p>

        {customRecurrenceModal && (
          <CustomRecurrenceModal
            recurrenceModalVisible={customRecurrenceModal}
            setRecurrenceModalVisible={setCustomRecurrenceModal}
            recurrenceModalData={recurrenceModalData}
          />
        )}
      </>
    </Modal>
  );
};

export default CalendarAvailabilityModal;
