import { useState, useContext, useRef, useEffect } from 'react';
import UserContext, { UserType } from '../contexts/UserContext';
import buttonClose from '../assets/images/button-close.svg';
import MaskGroup from '../assets/find-an-agent-page/Mask Group 1.png';
import TextField, {
  TextFieldType,
  TextFieldIconType,
} from '../components/TextField';
import Button, { ButtonType } from '../components/Button';
import Checkbox, { CheckboxType } from '../components/Checkbox';
import { useHistory } from 'react-router';
import {
  sendForgotPasswordForBuyerService,
  sendForgotPasswordForAgentService,
  registerBuyerService,
  resetForgotPasswordForBuyerService,
  resetForgotPasswordForAgentService,
} from '../services/NavihomeService';
import { useMediaQuery } from 'react-responsive';
import { notification } from '../components/notification';
import styled from '@emotion/styled';
import CustomSpinner from '../components/spinner/CustomSpinner';

const CustomSpan = styled.span`
  text-decoration: underline;
  color: #eb752f;
`;
const CustomRegHeading = styled.h3`
  font-size: 24px;
  @media screen and (max-width: 600px) {
    font-size: 20px;
    margin-bottom: 32px;
  }
`;
interface QuickBuyerRegistrationModalPropsModel {
  isShowing: boolean;
  closeAction: () => void;
  userEmail: string;
  userType: string;
}

const ResetPasswordModal: React.FC<QuickBuyerRegistrationModalPropsModel> = ({
  isShowing,
  closeAction,
  userType,
  userEmail,
}): JSX.Element => {
  const { userState, logIn, registerBuyer, changeUserType } =
    useContext(UserContext);
  const [email, setEmail] = useState(userEmail ?? '');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordRequiredLength, setIsPasswordRequiredLength] =
    useState(false);
  const [doesPasswordContainUppercase, setDoesPasswordContainUppercase] =
    useState(false);
  const [doesPasswordContainLowercase, setDoesPasswordContainLowercase] =
    useState(false);
  const [doesPasswordContainNumer, setDoesPasswordContainNumber] =
    useState(false);
  const history = useHistory();

  const isMobile = useMediaQuery({ maxWidth: 500 });
  const handleLogIn = async () => {
    closeAction();
  };

  useEffect(() => {
    var tempIsPasswordRequiredLength = password.length >= 8 ? true : false;
    var tempDoesPasswordContainUppercase = false;
    var tempDoesPasswordContainLowercase = false;
    var tempDoesPasswordContainNumer = false;

    for (var i = 0; i < password.length; i++) {
      const char = password.charAt(i);
      if (!tempDoesPasswordContainUppercase) {
        tempDoesPasswordContainUppercase = /^[A-Z]*$/.test(char);
      }
      if (!tempDoesPasswordContainLowercase) {
        tempDoesPasswordContainLowercase = /^[a-z]*$/.test(char);
      }
      if (!tempDoesPasswordContainNumer) {
        tempDoesPasswordContainNumer = /^[0-9]*$/.test(char);
      }
    }

    setIsPasswordRequiredLength(tempIsPasswordRequiredLength);
    setDoesPasswordContainUppercase(tempDoesPasswordContainUppercase);
    setDoesPasswordContainLowercase(tempDoesPasswordContainLowercase);
    setDoesPasswordContainNumber(tempDoesPasswordContainNumer);
  }, [password]);

  const handleResetPassword = async () => {
    if (userEmail === '') {
      return notification('Please enter email.', 'error');
    }
    if (password === '') {
      return notification('Please enter password.', 'error');
    }
    if (confirmPassword === '') {
      return notification('Please enter confirm password.', 'error');
    }
    if (password !== confirmPassword) {
      return notification("Passwords don't match.", 'error');
    }
    if (!isPasswordRequiredLength) {
      notification("Passwords must be at least 8 characters long.", "error");
      return;
    }
    if (!doesPasswordContainUppercase) {
      notification(
        "Passwords must contain at least 1 UPPERCASE letter.",
        "error"
      );
      return;
    }
    if (!doesPasswordContainLowercase) {
      notification(
        "Passwords must contain at least 1 lowercase letter.",
        "error"
      );
      return;
    }
    if (!doesPasswordContainNumer) {
      return notification(
        "Passwords must contain at least 1 number character.",
        "error"
      );
    }

    try {
      setIsLoading(true);
      if (userType == 'buyer') {
        const result = await resetForgotPasswordForBuyerService(
          userEmail,
          password
        );
        if (result) {
          setIsLoading(false);
          closeAction();
          history.push('/buy');
          return notification('Reset Password Successfully.', 'success');
        }
        setIsLoading(false);
        return;
      }

      if (userType == 'agent') {

        const response = await resetForgotPasswordForAgentService(
          userEmail,
          password
        );
        if (response) {
          setIsLoading(false);
          closeAction();
          changeUserType(UserType.AGENT);
          history.push('/');
          return notification('Reset Password Successfully.', 'success');
        }
        setIsLoading(false);
        return;
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return notification(`${error}`, 'error');
    }
  };

  const modalClassName = isShowing
    ? 'quick-registration-modal u-visible'
    : 'quick-registration-modal u-hidden';
  const cardClassName = isShowing
    ? 'quick-registration-modal__card animation__pop-in'
    : 'quick-registration-modal__card animation__pop-out';

  const renderPasswordRequirements = () => {
    return (
      <div className="register-buyer-page__password-filters">
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;• is at least 8 characters long:
          {isPasswordRequiredLength ? (
            <span className="green">✔</span>
          ) : (
            <span className="red">✗</span>
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;• contains 1 UPPERCASE letter:
          {doesPasswordContainUppercase ? (
            <span className="green">✔</span>
          ) : (
            <span className="red">✗</span>
          )}
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;• contains 1 lowercase letter:
          {doesPasswordContainLowercase ? (
            <span className="green">✔</span>
          ) : (
            <span className="red">✗</span>
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;• contains 1 number character:
          {doesPasswordContainNumer ? (
            <span className="green">✔</span>
          ) : (
            <span className="red">✗</span>
          )}
        </p>
      </div>
    );
  };

  return (
    <div className={modalClassName}>
      {isLoading && <CustomSpinner />}
      <div className={cardClassName}>
        <div className="quick-registration-modal__row">
          {/* <h2>RESET PASSWORD</h2> */}
          <CustomRegHeading>RESET PASSWORD</CustomRegHeading>
          <img
            className="quick-registration-modal__close-button"
            src={buttonClose}
            onClick={closeAction}
            alt="Close button"
          />
        </div>

        <div
          style={{
            paddingTop: '10px',
            paddingLeft: '10px',
            paddingRight: '10px',
            marginLeft: '5px',
            paddingBottom: '10px',
          }}
        >
          <p>To reset your password, enter below your new password:</p>
        </div>
        <div style={{ marginLeft: '16px', marginTop: '5px' }}>
          <p>Ensure that the password you create is strong:</p>
          {renderPasswordRequirements()}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            paddingRight: '3rem',
          }}
        >
          <p className="quick-registration-modal__error-message">
            {userState.loginError}
          </p>

          <TextField
            value={userEmail}
            setValue={setEmail}
            placeholder="Enter Your Email"
            label="Email"
            type={TextFieldType.ICON_AND_LABEL}
            icon={TextFieldIconType.EMAIL}
            isRequired={true}
            width={isMobile ? 26.4 : 38.4}
            isDisabled={true}
            // className="quick-registration-modal__email-field"
          />
          <TextField
            value={password}
            setValue={setPassword}
            placeholder="••••••••"
            label="Create Password"
            type={TextFieldType.PASSWORD}
            icon={TextFieldIconType.PASSWORD}
            isRequired={true}
            width={isMobile ? undefined : 38.4}
            className="register-buyer-page__form-item"
          />
          <TextField
            value={confirmPassword}
            setValue={setConfirmPassword}
            placeholder="••••••••"
            label="Confirm Password"
            type={TextFieldType.PASSWORD}
            icon={TextFieldIconType.PASSWORD}
            isRequired={true}
            width={isMobile ? undefined : 38.4}
            className="register-buyer-page__form-item"
          />

          <Button
            type={ButtonType.PRIMARY}
            text="RESET PASSWORD"
            clickHandler={handleResetPassword}
            width={24.5}
            className="reset-password-modal__button"
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordModal;
