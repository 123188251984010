/* eslint-disable react-hooks/exhaustive-deps */
import styled from "@emotion/styled";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useHistory } from "react-router-dom";
import UserContext from "../contexts/UserContext";
import { getAgentInfoService, getPreviousShowingsAgentType } from "../services/NavihomeService";

const CustomCalendarNew = styled(Calendar)`
  border: none;
  border-radius: 8px;
  margin: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  & .react-calendar__navigation {
    color: #fff;
    font-size: 15px;
    background: #004876;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &.react-calendar {
    color: black;
  }
  .react-calendar__tile--now {
    background-color: #dfd2c3;
  }
  .react-calendar__tile--rangeStart {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background: #6f48eb;
    color: white;
  }
  .react-calendar__tile--rangeEnd {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background: #6f48eb;
    color: white;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575 !important;
  }
`;

const ModernCalendar = (props: any) => {
  const { userState } = useContext(UserContext);
  const [isSelect, setIsSelect] = useState(false);
  let token = userState.agent.token;
  const history = useHistory();
  const mlsId = history.location.pathname.split("/")[2];

  const [value, onChange] = useState(new Date());
  const [start, setStart] = useState(new Date().toString());
  const [end, setend] = useState(new Date().toString());
  const handleChange = (value: any) => {
    setIsSelect(true);
    onChange(value);
    setStart(moment(value).startOf("week").toString());
    setend(moment(value).endOf("week").toString());
    props?.setUpcomingStartEndDate({
      startDate: moment(value).startOf("week").format("LL").toString(),
      endDate: moment(value).endOf("week").format("LL").toString()
    });
  };

  const getAgentInfo = async (agentId: number) => {
    try {
      const response: any = await getAgentInfoService(token, agentId);
      return { name: response?.name, sellerEmail: response?.email };
    } catch (err) {
      console.log(err);
    }
  };
  const getPreviousShowingsTableData = async (start: any, end: any) => {
    const response = await getPreviousShowingsAgentType(token, mlsId, start, end);
    let tempArray: any = [];
    if (response?.data) {
      let saleAgentDetails = {
        name: response?.data?.saleAgent[0]?.name,
        saleAgentEmail: response?.data?.saleAgent[0]?.sellerEmail,
        message: response?.data?.saleAgent[0]?.message
      };
      await Promise.all(
        response?.data?.linkedProperty?.map(async (item: any, i: number) => {
          return tempArray.push({
            key: item?.agentId + i,
            date: {
              date: moment(item?.scheduleDate).format("MMMM DD, YYYY"),
              day: moment(item?.scheduleDate).format("dddd")
            },
            time: {
              time: moment(item?.scheduleDate).format("hh:mm:ss A")
            },
            seller: (await getAgentInfo(item.agentId)).name,
            saleAgentEmail: saleAgentDetails.saleAgentEmail,
            sale_agent: saleAgentDetails.name,
            status: item?.status === true ? "Completed" : "Pending"
          });
        })
      );
    }
    props?.setTableData([...tempArray]);
  };

  useEffect(() => {
    const curr = new Date(); // get current date
    const first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    const last = first + 8; // last day is the first day + 6
    const firstday = new Date(curr.setDate(first)).toUTCString();
    const lastday = new Date(curr.setDate(last)).toUTCString();
    if (isSelect) {
      getPreviousShowingsTableData(start, end);
    } else {
      getPreviousShowingsTableData(firstday, lastday);
    }
  }, [value, start, end, isSelect]);

  return (
    <>
      <CustomCalendarNew
        calendarType="US"
        showNavigation={true}
        showWeekNumbers={false}
        next2Label={null}
        prev2Label={null}
        onChange={(value: any) => handleChange(value)}
        value={(start && end) !== new Date().toString() ? [new Date(start), new Date(end)] : new Date()}
        maxDetail="month"
      />
    </>
  );
};
export default ModernCalendar;
