import styled from "@emotion/styled";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import AgentImg from "../assets/find-an-agent-page/Group 1163.png";
import buttonClose from "../assets/images/button-close.svg";
import Button, { ButtonType } from "../components/Button";
import InviteAgentModal from "./InviteAgentModal";
const CustomGettingStartedHeading = styled.h1`
color: #004876,
  @media screen and (max-width: 768px) {
    font-size: 28px !important;
    margin-left: 20px
  }
  @media screen and (max-width: 1368px) {
    margin-left: 20px
  }
  @media screen and (max-width: 576px) {
    font-size: 18px !important; 
    margin-left: 20px

    :after {
      display: none;
    }
  }
  position: relative;
  bottom: 3%;
`;
interface FindAnAgentPropsModel {
  isShowing: boolean;
  closeAction: () => void;
  text?: string;
}

const FindAnAgentModal: React.FC<FindAnAgentPropsModel> = ({
  isShowing,
  closeAction,
  text,
}): JSX.Element => {
  const isMobile = useMediaQuery({ maxWidth: 610 });
  const [isShowingInviteAnAgentModal, setIsShowingInviteAnAgentModal] =
    useState(false);
  const handleFindAnAgent = () => {
    closeAction();
  };


  const closeInviteAnAgentModalHandler = () => {
    setIsShowingInviteAnAgentModal(!isShowingInviteAnAgentModal);
  };


  const openInviteAnAgentModalHandler = () => {
    setIsShowingInviteAnAgentModal(!isShowingInviteAnAgentModal);
  };

  const modalClassName = isShowing
    ? "find-an-agent-modal-modal u-visible"
    : "find-an-agent-modal-modal u-hidden";
  const cardClassName = isShowing
    ? "find-an-agent-modal-modal__card animation__pop-in"
    : "find-an-agent-modal-modal__card animation__pop-out";

  return (
    <div className={modalClassName}>
      <div className={cardClassName}>
        <div className="find-an-agent-modal-modal__row">
          {text === "calendar" ? (
            <CustomGettingStartedHeading style={{ fontWeight: "600" }}>
              <span>Find an ag</span>
              <span style={{ borderBottom: "3px solid #C2D500" }}>
                ent first!
              </span>
            </CustomGettingStartedHeading>
          ) : (
            <h3> Find an agent to schedule showings!</h3>
          )}
          <img
            className="find-an-agent-modal-modal__close-button"
            src={buttonClose}
            onClick={closeAction}
            alt="Close button"
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="find-an-agent-modal-modal__content">
          <div className="find-an-agent-modal-logo">
            <img src={AgentImg} alt="..." />
          </div>
          <div className="find-an-agent-modal-para">
            <p>Connect with an agent to get started using this feature.</p>
            {!isMobile && (
              <>
                {" "}
                <br />
              </>
            )}
            <br />
            <p>
              If you are already working with an agent, invite them to join the
              app and connect directly with you.
            </p>
            {!isMobile && (
              <>
                <br />
              </>
            )}
            <br />

            <p>
              If you don't already have an agent, browse through our list of
              registered agents to connect with one today.
            </p>
            {!isMobile && (
              <>
                <br />
                <br />
              </>
            )}
            <br />
            <div className="find-an-agent-modal-button-div">
              {/* <Link to="/find-agent"> */}
              <Button
                type={ButtonType.WHITE}
                text={"INVITE YOUR AGENT"}
                clickHandler={openInviteAnAgentModalHandler}
                className="find-an-agent-modal-invite-your-agent-button"
              />
              {/* </Link> */}
              <Link to="/find-agent?type=buyer">
                <Button
                  type={ButtonType.FIND_AN_AGENT}
                  text={"FIND AN AGENT"}
                  className="find-an-agent-modal-invite-your-agent-button"
                  clickHandler={handleFindAnAgent}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      {isShowingInviteAnAgentModal && (
        <InviteAgentModal
          isShowing={isShowingInviteAnAgentModal}
          closeAction={closeInviteAnAgentModalHandler}
          inviteYourAgent={true}
        />
      )}
    </div>
  );
};

export default FindAnAgentModal;
