export const parsePriceToString = (price: number) => {
  if (price >= 1000000) {
    const millions = Math.trunc(price / 1000000);
    var hundreds = Math.trunc((price % 1000000) / 1000);
    var hString = '0';

    if (hundreds >= 10) {
      hString = `0${hundreds}`;
    }
    if (hundreds >= 100) {
      hString = `${hundreds}`;
    } 
    return `${millions}.${hString}M`;
  }
  return `${Math.abs(price / 1000)}K`;
}