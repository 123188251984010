import { useEffect, useRef, useState, useContext } from "react";
import triangle from "../assets/images/icon-triangle.svg";
import PropertyFiltersContext from "../contexts/PropertyFiltersContext";
import PropertyTypeSelectFieldOption from "./PropertyTypeSelectFieldOption";
import { useHistory, useLocation } from "react-router";
import buttonClose from "../assets/images/button-close.svg";
import singlyFamily from "../assets/css/components/Group 981.svg";
import multiFamily from "../assets/css/components/Group 982.svg";
import commercial from "../assets/css/components/Group 983.svg";
import rental from "../assets/css/components/Group 984.svg";
import mobile from "../assets/css/components/Group 985.svg";
import land from "../assets/css/components/Group 986.svg";
import other from "../assets/css/components/Group 987.svg";
import condo from "../assets/css/components/Group 966.svg";
interface PropertyTypeSelectFieldProps {
  text?: string;
  handleChange: () => void;
  setIsPropertyClear?: (value: boolean) => void;
  searchFieldHandler: () => void;
}

const PropertyTypeSelectField: React.FC<PropertyTypeSelectFieldProps> = ({
  text,
  handleChange,
  setIsPropertyClear,
  searchFieldHandler,
}) => {

  const [label, setLabel] = useState("Property Type");
  const [isExpanded, setIsExpanded] = useState(false);
  const [didChange, setDidChange] = useState(false);
  const [propertyArr, setPropertyArr] = useState<Array<string>>([]);
  const ref = useRef(null);
  const { search } = useLocation();
  const history = useHistory();
  const { propertyFiltersState, setPropertyType } = useContext(
    PropertyFiltersContext
  );
  const { propertyType } = propertyFiltersState;

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsExpanded(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (propertyType.length === 0) {
      setLabel("Property Type");
      setPropertyArr([]);
    } else {
      const arrayList = propertyType.split("&type=");
      setPropertyArr(arrayList);
      setLabel(arrayList.join(", "));
    }
  }, [propertyType]);

  const propertyTypelist = [
    { value: "condominium", text: "Condo", imageUrl: condo },
    { value: "singlefamily", text: "Single Family", imageUrl: singlyFamily },
    { value: "multifamily", text: "Multi Family", imageUrl: multiFamily },
    { value: "residential", text: "RES", imageUrl: commercial },
    { value: "rental", text: "RNT", imageUrl: rental },
    { value: "commercial", text: "CRE", imageUrl: commercial },
    { value: "mobilehome", text: "Mobile", imageUrl: mobile },
    { value: "land", text: "Land", imageUrl: land },
    //  { value: "commercial&type=residential&type=rental", text:'Other', imageUrl: other}
  ];

  // const expand = () => {
  //   setIsExpanded(true);
  // };
  useEffect(() => {
    if (!isExpanded) {
      handleChange();
    }
  }, [isExpanded]);

  const expand2 = () => {
    setIsExpanded(!isExpanded);
  };
  const updateValues = (arrayList: Array<string>) => {

    if (arrayList.length > 0) {
      const str = arrayList.join("&type=");
      // setLabel(str.replaceAll('&type=', ','));
      setPropertyType(str);
      setPropertyArr(arrayList);
    } else {
      setLabel("Property Type");
      setPropertyType("");
      setPropertyArr([]);
    }
  };

  const handleSelectValue = (value: string) => {
    try {
      if (!propertyArr.includes(value)) {
        propertyArr.push(value);
        updateValues(propertyArr);
      } else {
        const filterPropertyArr = propertyArr.filter((item) => item != value);
        updateValues(filterPropertyArr);
      }
      // setIsExpanded(false);
      setDidChange(true);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleRemoveValue = (value: string) => {};

  const height = isExpanded ? "36rem" : "4.8rem";
  const valueColor =
    didChange && label !== "Property Type"
      ? "var(--color-black"
      : "var(--color-grey-2";
  const zIndex = isExpanded ? 1000 : 10;

  return (
    <div
      className="property-type-select-field__container"
      onClick={searchFieldHandler}
    >
      <div
        ref={ref}
        className="property-type-select-field"
        style={{ height: height, zIndex: zIndex }}
      >
        <div
          className="property-type-select-field__current-option"
          onClick={expand2}
        >
          <p
            style={{
              color: valueColor,
              overflowWrap: "anywhere",
              lineHeight: "1.05",
            }}
          >
            {text?.length > 0 && text}
            {label.split(", ")?.length > 2 ? (
              <>{`${label.split(", ")?.[0]}, ${label.split(", ")?.[1]}...+${
                label.split(", ")?.length - 2
              } more`}</>
            ) : (
              <>{label}</>
            )}
          </p>
          <img
            className={
              isExpanded
                ? "select-field__arrow select-field__arrow--rotated "
                : "select-field__arrow "
            }
            src={triangle}
            alt="Triangle"
            style={{ top: "2rem" }}
          />
        </div>
        <div className="property-type-select-field__options">
          <ul>
            {propertyTypelist.map((item) => {
              return (
                <PropertyTypeSelectFieldOption
                  value={item?.value}
                  text={item?.text}
                  didSelectValue={handleSelectValue}
                  didRemoveValue={handleRemoveValue}
                  imageUrl={item?.imageUrl}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PropertyTypeSelectField;
