import React from "react";
import LandingBuyerPageBuy from "../components/landingBuyerPage/LandingBuyerPageBuy";


const PreLoginBuyerPageBuy = () =>{
    return(
        <>
        <LandingBuyerPageBuy />
        </>
    )
}

export default PreLoginBuyerPageBuy