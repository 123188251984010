import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import Button, { ButtonType } from "../components/Button";
import Checkbox, { CheckboxType } from "../components/Checkbox";
import iconLocation from "../assets/images/icon-location.svg";
import ContactRadio, { ContactRadioType } from "../components/ContactRadio";
import PhoneInput from "react-phone-input-2";
import TextField, {
  TextFieldIconType,
  TextFieldType,
} from "../components/TextField";
import { Select } from "antd";
import { useMediaQuery } from "react-responsive";
import { notification } from "../components/notification";
import styled from "@emotion/styled";
import "react-phone-input-2/lib/material.css";
import {
  postMessageToUser,
  registerAgentService,
  getPlacebySearch,
  inviteAgentByBuyer,
  createActivatedSubscriptionPlan,
} from "../services/NavihomeService";
import CustomSpinner from "../components/spinner/CustomSpinner";
import ThankyouModal from "../modals/ThankyouModal";
import ResendVerifyLinkModal from "../modals/ResendVerifyLinkModal";
import moment from "moment";
interface RegisterAgentPageProp {
  agentId: boolean;
}
const { Option } = Select;
const CustomPhoneInput = styled(PhoneInput)`
  height: 8.5rem;
  padding: 0px;
  border-radius: 5px;
  &.react-tel-input .country-list .search-box {
    text-transform: capitalize;
  }
`;
const CustomImage = styled.img`
  top: 11px;
  position: absolute;
  z-index: 1;
  left: 7px;
`;

const CustomHeadingDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-bottom: 16px;
  align-items: center;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;
const CustomBackP = styled.p`
  margin-left: 12px;
  margin-top: 16px;
  cursor: pointer;
  font-size: 17px;
`;

const Registerheading = styled.h1`
  font-size: 32px;
  font-weight: 600;
  @media screen and (max-width: 576px) {
    font-size: 28px;
  }
`;

const CustomSelect = styled(Select)`
  height: 190px;
  width: 65%;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
  box-shadow: 0px 1px 5px #888888;
  border-radius: 8px;
  & .ant-select-selector {
    height: 100%;
    align-items: start;
    justify-content: start;
    border-radius: 8px !important;
    border: 1px solid #004876 !important;
  }
  & .ant-select-selection-item {
    border-radius: 8px !important;
    border: 1px solid #004876 !important;
    height: 30px;
    align-items: center;
    justify-content: center;
  }
  & .ant-select-selection-item-remove {
    display: flex;
    align-items: center;
    color: #004876 !important;
    margin-left: 5px;
    font-size: 14px;
  }
  .ant-select-selection-placeholder {
    color: #6e6d6d;
    font-weight: bold;
    margin-top: -79px;
    padding-left: 19px;
  }
  .ant-select-selection-overflow {
    padding-top: 3px;
  }
  &.ant-select-multiple .ant-select-selection-search {
    margin-left: 2.2rem;
    top: -3px;
  }
`;
const Subheading = styled.h3`
  font-size: 2rem;
  font-weight: 600;
  @media screen and (max-width: 357px) {
    &:after {
      display: none;
    }
  }
`;

const RegisterSaleAgentPage: React.FC<RegisterAgentPageProp> = (agentId) => {
  const [name, setName] = useState("");
  const [registeredAgentName, setRegisteredAgentName] = useState<string>("");
  const [company, setCompany] = useState("");
  const [mlsId, setMlsId] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isMlsidVerified, setIsMlsidVerified] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [preferredContact, setPreferredContact] = useState(
    ContactRadioType.EMAIL
  );
  const [coverageAreas, setCoverageAreas] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordRequiredLength, setIsPasswordRequiredLength] =
    useState(false);
  const [doesPasswordContainUppercase, setDoesPasswordContainUppercase] =
    useState(false);
  const [doesPasswordContainLowercase, setDoesPasswordContainLowercase] =
    useState(false);
  const [doesPasswordContainNumer, setDoesPasswordContainNumber] =
    useState(false);
  const [keepMeSignedIn, setKeepMeSignedIn] = useState(false);
  const [isShowingThankyouModal, setIsShowingThankyouModal] =
    useState<boolean>(false);
  const [isShowingVerifyLinkModal, setIsShowingVerifyLinkModal] =
    useState<boolean>(false);
  const [availablePlaces, setAvailablePlaces] = useState<any>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [islogocheck, setIslogocheck] = useState(true);

  const history = useHistory();

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let param = useQuery();

  const isMobile = useMediaQuery({ maxWidth: 890 });
  const isXS = useMediaQuery({ maxWidth: 600 });

  const countDays = () => {
    const from = new Date();
    from.setDate(from.getDate() + 20); // Set now + 30 days as the new date
  };

  useEffect(() => {
    countDays();
  }, []);

  const handleRegister = async () => {
    if (name === "") {
      notification("Please enter full name.", "error");
      return;
    }
    if (!agentId) {
      notification(
        "Sorry the agent Does not exist, Please enter a valid agentId",
        "error"
      );
    }
    if (company === "") {
      notification("Please enter company name.", "error");
      return;
    }
    if (mlsId === "" || mlsId.length < 8) {
      notification("Please enter MLS ID.", "error");
      return;
    }
    if (email === "") {
      notification("Please enter email.", "error");
      return;
    }
    if (phone === "") {
      notification("Please enter phone number.", "error");
      return;
    }
    if (password === "") {
      notification("Please enter password.", "error");
      return;
    }
    if (confirmPassword === "") {
      notification("Please enter confirm password.", "error");
      return;
    }
    if (password !== confirmPassword) {
      notification("Passwords don't match.", "error");
      return;
    }

    if (!isPasswordRequiredLength) {
      notification("Passwords must be at least 8 characters long.", "error");
      return;
    }

    if (!doesPasswordContainUppercase) {
      notification(
        "Passwords must contain at least 1 UPPERCASE letter.",
        "error"
      );
      return;
    }

    if (!doesPasswordContainLowercase) {
      notification(
        "Passwords must contain at least 1 lowercase letter.",
        "error"
      );
      return;
    }

    if (!doesPasswordContainNumer) {
      return notification(
        "Passwords must contain at least 1 number character.",
        "error"
      );
    }
    if (!isMlsidVerified)
      return notification("Please verify agent id", "error");

    const from = new Date();
    from.setDate(from.getDate() + 29);
    try {
      setIsLoading(true);
      const success: any = await registerAgentService(
        email,
        password,
        phone,
        name,
        mlsId,
        company,
        preferredContact,
        coverageAreas,
        from
      );



      if (success?.error) {
        setIsLoading(false);
        console.error(success);
        const errorValue = success?.msg?.split(" ");
        setRegisteredAgentName(success?.name);

        if (errorValue?.[7] === "verified.") {
          setIsShowingVerifyLinkModal(true);
        }

        // return notification(`${success?.msg}`, "error");
      } else {
         await createActivatedSubscriptionPlan(
          success?.raw?.insertId,
          `free-${success?.raw?.insertId}-sub-${moment().format("YYYY-MM-DD")}-id`,
          `free-${success?.raw?.insertId}-plan-${moment().format("YYYY-MM-DD")}-id`,
          "ACTIVE",
          new Date(),
          4999,
          "Navihome Advanced Plan",
          "Navihome Advanced Plan",
          from,
          mlsId,
          email);
        if (
          preferredContact === "CONTACT_RADIO_BOTH" ||
          preferredContact === "CONTACT_RADIO_PHONE"
        ) {
          postMessageToUser(
            phone,
            `Thank you for registering at Navihome. An email has been sent to your registered email (${email}). Please verify and login.`
          );
        }
        if (
          param?.get("type")?.toLocaleLowerCase() === "seller" &&
          success?.raw?.affectedRows > 0
        ) {
          const buyerId: string = param.get("buyerId"),
            buyerName: string = param.get("name");

          await inviteAgentByBuyer(
            success?.raw?.insertId,
            "invite",
            `${buyerName.toUpperCase()} wants to connect with you. Accept the request to help them buy their next home`,
            0,
            name,
            email,
            Number(buyerId)
          );
        }
        setIsLoading(false);
        setIsShowingThankyouModal(true);
        handlerModalTimeout();
      }
    } catch (error: any) {
      setIsLoading(false);
      console.error(error);
      return notification(
        `${error?.toString()?.includes("email must be an email")
          ? " Please enter a valid email!"
          : error
        } `,
        "error"
      );
    }
  };

  const handleCloseThankyouModal = () => {
    setIsShowingThankyouModal(!isShowingThankyouModal);
    history.push("/");
  };
  const handleCloseVerifyLinkModal = () => {
    setIsShowingVerifyLinkModal(!isShowingVerifyLinkModal);
    history.push("/");
  };

  const handlerModalTimeout = () => {
    setTimeout(() => {
      handleCloseThankyouModal();
    }, 4000);
  };

  const searchPlaceByText = async (text: any) => {
    if (text.trim()) {
      setIsDropdownOpen(true);
      getPlacebySearch(text).then((res: any) => {
        const allPlaces: any = [];
        res.predictions.map((place: any) =>
          allPlaces.push({ name: place.structured_formatting.main_text })
        );
        setAvailablePlaces(allPlaces);
      });
    } else {
      setIsDropdownOpen(false);
    }
  };

  const renderPasswordRequirements = () => {
    return (
      <div className="register-agent-page__password-filters">
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;• is at least 8 characters long:{" "}
          {isPasswordRequiredLength ? (
            <span className="green">✔</span>
          ) : (
            <span className="red">✗</span>
          )}
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;• contains 1 UPPERCASE letter:{" "}
          {doesPasswordContainUppercase ? (
            <span className="green">✔</span>
          ) : (
            <span className="red">✗</span>
          )}
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;• contains 1 lowercase letter:{" "}
          {doesPasswordContainLowercase ? (
            <span className="green">✔</span>
          ) : (
            <span className="red">✗</span>
          )}
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;• contains 1 number character:{" "}
          {doesPasswordContainNumer ? (
            <span className="green">✔</span>
          ) : (
            <span className="red">✗</span>
          )}
        </p>
      </div>
    );
  };

  useEffect(() => {
    var tempIsPasswordRequiredLength = password?.length >= 8 ? true : false;
    var tempDoesPasswordContainUppercase = false;
    var tempDoesPasswordContainLowercase = false;
    var tempDoesPasswordContainNumer = false;

    for (var i = 0; i < password?.length; i++) {
      const char = password.charAt(i);
      if (!tempDoesPasswordContainUppercase) {
        tempDoesPasswordContainUppercase = /^[A-Z]*$/.test(char);
      }
      if (!tempDoesPasswordContainLowercase) {
        tempDoesPasswordContainLowercase = /^[a-z]*$/.test(char);
      }
      if (!tempDoesPasswordContainNumer) {
        tempDoesPasswordContainNumer = /^[0-9]*$/.test(char);
      }
    }

    setIsPasswordRequiredLength(tempIsPasswordRequiredLength);
    setDoesPasswordContainUppercase(tempDoesPasswordContainUppercase);
    setDoesPasswordContainLowercase(tempDoesPasswordContainLowercase);
    setDoesPasswordContainNumber(tempDoesPasswordContainNumer);
  }, [password]);

  return (
    <>
      <CustomBackP
        onClick={() => {
          // history.push("/");
          history.goBack();
        }}>
        Back
      </CustomBackP>
      {isLoading && <CustomSpinner />}
      <div className="register-agent-page">
        <div className="register-agent-page__content">
          <div className="register-agent-page__heading">
            <CustomHeadingDiv>
              <Registerheading>
                Register as an
                <span style={{ borderBottom: "3px solid #C2D500" }}>
                  {" "}
                  agent
                </span>
              </Registerheading>

              {!isXS && (
                <Button
                  disable={
                    name &&
                      phone &&
                      mlsId &&
                      company &&
                      password &&
                      confirmPassword &&
                      coverageAreas
                      ? false
                      : true
                  }
                  type={ButtonType.REGISTER_BUTTON}
                  text="Register"
                  clickHandler={handleRegister}
                  className="register-agent-page__register-button"
                />
              )}
            </CustomHeadingDiv>

            <p style={{ paddingBottom: "30px" }}>
              Create your account now and we'll help you plan showings without
              the hassle of following up, route and schedule tours for your
              buyers and even increase our outreach!
            </p>
          </div>
          <form>
            <div className="register-agent-page__form-content">
              <div className="register-agent-page__form-content--left">
                <div className="register-agent-page__form-content-section">
                  <Subheading>General Information</Subheading>
                  <p>Sign up with the information per your MLS registration</p>
                  <TextField
                    value={name}
                    setValue={setName}
                    placeholder="Jane Doe"
                    label="Name"
                    type={TextFieldType.ICON_AND_LABEL}
                    icon={TextFieldIconType.USER}
                    isRequired={true}
                    width={isMobile ? undefined : 38.4}
                    className="register-agent-page__form-item"
                  />
                  <TextField
                    value={company}
                    setValue={setCompany}
                    placeholder="ABC Realty"
                    label="Company"
                    type={TextFieldType.ICON_AND_LABEL}
                    icon={TextFieldIconType.COMPANY}
                    isRequired={true}
                    width={isMobile ? undefined : 38.4}
                    className="register-agent-page__form-item"
                  />
                  <TextField
                    value={mlsId}
                    setValue={setMlsId}
                    placeholder="1234567"
                    label="MLS ID"
                    type={TextFieldType.ICON_AND_LABEL}
                    icon={TextFieldIconType.USER}
                    isRequired={true}
                    width={isMobile ? undefined : 38.4}
                    className="register-agent-page__form-item"
                    setIsverifiedMlsId={setIsMlsidVerified}
                  />
                </div>
                <div className="register-agent-page__form-content-section">
                  <Subheading>Contact Information</Subheading>
                  <p>
                    You will receive reminders and notifications based on the
                    contact information below. You can update your contact
                    preferences after you sign in
                  </p>
                  <TextField
                    value={email}
                    setValue={setEmail}
                    placeholder="janedoe@hotmail.com"
                    label="Email"
                    type={TextFieldType.ICON_AND_LABEL}
                    icon={TextFieldIconType.EMAIL}
                    isRequired={true}
                    width={isMobile ? undefined : 38.4}
                    className="register-agent-page__form-item"
                  />
                  <div className="register-agent-page__form-item">
                    <h5
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        position: "relative",
                        top: "10px",
                        color: "black",
                      }}>
                      Phone Number*
                    </h5>
                    <CustomPhoneInput
                      value={phone}
                      inputStyle={{
                        display: "flex",
                        justifyContent: "flex-start",
                        bottom: "-2rem",
                        right: "-7rem",
                        height: "5.2rem",
                        width: isMobile ? "100%" : "38.4rem",
                        marginTop: "20px",
                        border: "1px solid #365073 ",
                        boxShadow: "0px 1px 5px #888888",
                        borderRadius: "8px",
                      }}
                      inputClass="register-agent-page__form-item"
                      inputProps={{
                        setValue: { setPhone },
                      }}
                      onChange={(phone: any) => {
                        if (phone.length >= 10) {
                          setPhone(phone);
                        } else {
                          setPhone("");
                        }
                      }}
                      dropdownStyle={{
                        width: "20rem",
                        position: "relative",
                        bottom: "21rem",
                        overflowX: "hidden",
                      }}
                      country={"us"}
                      specialLabel=""
                      buttonStyle={{
                        position: "absolute",
                        bottom: "45.5rem",
                        height: "auto",
                        top: "2.5rem",
                      }}
                      placeholder="+1 (404) 123 4567"
                      disableDropdown={false}
                      autoFormat={true}
                      enableSearch={true}
                    />
                  </div>
                  <ContactRadio
                    value={preferredContact}
                    setValue={setPreferredContact}
                  />
                </div>
              </div>
              <div className="register-agent-page__form-content--right">
                <div
                  className="register-agent-page__form-content-section"
                  style={{ position: "relative" }}>
                  <Subheading>Coverage Areas</Subheading>
                  <p>
                    Specify your coverage area to help buyers see your profile
                    while searching for agents. You can enter zip codes,
                    neighborhoods and regions within your city. This information
                    will only be visible to you.
                  </p>
                  <p
                    style={{
                      marginTop: "15px",
                      display: "block",
                      marginBottom: ".3rem",
                      fontFamily: "var(--font-family-subtitle-2A)",
                      fontSize: "var(--font-size-subtitle-2A)",
                      fontWeight: "500",
                    }}>
                    Coverage Areas- ZIP, Neighborhood
                  </p>
                  <div style={{ position: "relative" }}>
                    {islogocheck ? <CustomImage src={iconLocation} /> : ""}
                  </div>
                  {coverageAreas?.length !== 0 ? (
                    <CustomSelect
                      style={{ marginTop: "5px" }}
                      mode="multiple"
                      value={coverageAreas}
                      placeholder="Enter City, ZIP, Neighborhood"
                      onSearch={searchPlaceByText}
                      onChange={(areas: any) => {
                        setCoverageAreas(areas);
                        if (areas.length === 0) {
                          setIslogocheck(true);
                        } else {
                          setIslogocheck(false);
                        }
                      }}
                      onClick={(e) => { }}
                      onSelect={() => setIsDropdownOpen(false)}
                      open={isDropdownOpen}
                      className="register-agent-page__form-item">
                      {availablePlaces.map((place: any) => {
                        return (
                          <Option value={place?.name} label={place?.name}>
                            {place?.name}
                          </Option>
                        );
                      })}
                    </CustomSelect>
                  ) : (
                    <CustomSelect
                      style={{ marginTop: "5px" }}
                      mode="multiple"
                      placeholder="Enter City, ZIP, Neighborhood"
                      onSearch={searchPlaceByText}
                      onChange={(areas: any) => {
                        setCoverageAreas(areas);
                        if (areas.length === 0) {
                          setIslogocheck(true);
                        } else {
                          setIslogocheck(false);
                        }
                      }}
                      onClick={(e) => { }}
                      onSelect={() => setIsDropdownOpen(false)}
                      open={isDropdownOpen}
                      className="register-agent-page__form-item">
                      {availablePlaces.map((place: any) => {
                        return (
                          <Option value={place?.name} label={place?.name}>
                            {place?.name}
                          </Option>
                        );
                      })}
                    </CustomSelect>
                  )}
                </div>
                <div
                  className="register-agent-page__create-password"
                  style={{ marginTop: "-8px" }}>
                  <Subheading>Create Password</Subheading>
                  <p>Ensure that the password you create is strong:</p>
                  {renderPasswordRequirements()}
                  <TextField
                    value={password}
                    setValue={setPassword}
                    placeholder="••••••••"
                    label="Create Password"
                    type={TextFieldType.PASSWORD}
                    icon={TextFieldIconType.PASSWORD}
                    isRequired={true}
                    width={isMobile ? undefined : 38.4}
                    className="register-agent-page__form-item"
                  />
                  <TextField
                    value={confirmPassword}
                    setValue={setConfirmPassword}
                    placeholder="••••••••"
                    label="Confirm Password"
                    type={TextFieldType.PASSWORD}
                    icon={TextFieldIconType.PASSWORD}
                    isRequired={true}
                    width={isMobile ? undefined : 38.4}
                    className="register-agent-page__form-item"
                  />
                  <Checkbox
                    type={CheckboxType.SMALL}
                    value={keepMeSignedIn}
                    setValue={setKeepMeSignedIn}
                    text="Keep me signed in"
                  />
                </div>
              </div>
              {isXS && (
                <Button
                  disable={
                    name &&
                      phone &&
                      mlsId &&
                      company &&
                      password &&
                      confirmPassword &&
                      coverageAreas
                      ? false
                      : true
                  }
                  type={ButtonType.REGISTER_BUTTON}
                  text="Register"
                  clickHandler={handleRegister}
                  className="register-agent-page__register-button"
                />
              )}
            </div>
          </form>
        </div>
      </div>
      {isShowingThankyouModal && (
        <ThankyouModal
          isShowing={isShowingThankyouModal}
          closeAction={handleCloseThankyouModal}
        />
      )}
      {isShowingVerifyLinkModal && (
        <ResendVerifyLinkModal
          isShowing={isShowingVerifyLinkModal}
          closeAction={handleCloseVerifyLinkModal}
          email={email}
          name={registeredAgentName}
        />
      )}
    </>
  );
};

export default RegisterSaleAgentPage;
