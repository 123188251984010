import React from "react";
import EmptyDashboardTab from "../components/EmptyDashboardTab/emptyDashboardTab";


const PreLoginBuyerPageSell = () =>{
    const title = 'In order to sell your home, sign in and ask your listing agent to add you to the listed property. By doing this, you can see how your property is performing, and keep tab of all showings of your home.';
    return(
        <>
        <EmptyDashboardTab isButton={true} buttonText='SIGN IN' isPreSell={true}  title={title}/>
        </>
    )
}

export default PreLoginBuyerPageSell;