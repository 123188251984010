import styled from '@emotion/styled';
import { Link, useHistory } from 'react-router-dom';
import FeeTriaIMG from '../assets/find-an-agent-page/FeeTriaIMG.png';
import buttonClose from '../assets/images/button-close.svg';
import Button, { ButtonType } from '../components/Button';

interface FreeTrialModalPropsModel {
  isShowing: boolean;
  closeAction: () => void;
  remainingDays: number;
  isPlanExpire: boolean;
}

const CustomHeading = styled.h3`
  font-weight: 510;
  font-size: 22px;
  @media screen and (max-width: 576px) {
    font-size: 18px;
  }
  @media screen and (max-width: 476px) {
    // padding-top: 10px
    font-size: 16px;
  }
  &:after {
    display: none;
  }
`;
const CustomPara = styled.p`
  @media screen and (min-width: 810px) {
    padding-bottom: 3rem;
  }
  @media screen and (max-width: 810px) {
    padding-bottom: 1rem;
  }
`;
const ImageDiv = styled.div`
  @media screen and (max-width: 576px) {
    display: flex;
    justify-content: center;
  }
`;
const FreeTrialModal: React.FC<FreeTrialModalPropsModel> = ({
  isShowing,
  closeAction,
  remainingDays,
  isPlanExpire,
}): JSX.Element => {

  const history = useHistory();
  const handleContinue = () => {
    closeAction();
  };
  const handlePricingPlans = () => {
    history.push('/pricing');
  };

  const modalClassName = isShowing
    ? 'find-an-agent-modal-modal u-visible'
    : 'find-an-agent-modal-modal u-hidden';
  const cardClassName = isShowing
    ? 'find-an-agent-modal-modal__card animation__pop-in'
    : 'find-an-agent-modal-modal__card animation__pop-out';

  return (
    <div className={modalClassName}>
      <div className={cardClassName}>
        <div className="find-an-agent-modal-modal__row">
          <div style={{ display: 'flex' }}>
            {isPlanExpire ? (
              <CustomHeading>
                Your Pro Trial of NaviHome expired {remainingDays} days ago!
                Thanks for using Navihome!
              </CustomHeading>
            ) : (
              <CustomHeading>
                Only {remainingDays} days until your Free Pro Trial ends Thanks
                for using Navihome!
              </CustomHeading>
            )}
          </div>
          <img
            className="find-an-agent-modal-modal__close-button"
            src={buttonClose}
            onClick={closeAction}
            alt="Close button"
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div className="find-an-agent-modal-modal__content">
          <ImageDiv className="freetrial-agent-modal-logo">
            <img src={FeeTriaIMG} alt="..." />
          </ImageDiv>
          <div className="freetrial-agent-modal-para">
            {isPlanExpire ? (
              <CustomPara>
                Your Pro Free Trial of NaviHome expired {remainingDays} days ago, you
                will be charged $49.99 on your credit card monthly.
              </CustomPara>
            ) : (
              <CustomPara>
                You have only {remainingDays} days till the free trial for Advanced Plan ends. After this you will be downgraded to the free version of the app unless you choose your plan. To continue using the Advanced Plan, please enter your credit card details and you will be charged $49.99 on your credit card at the end of your free trial plan.
              </CustomPara>
            )}

            <CustomPara>
              The Advanced plan allows you to access all features of NaviHome, 
              from syncing your listings with MLS to routing and scheduling multiple houses in one tour.
               It also allows you to brand the app with your details. And most importantly,
                promotes your profile when buyers are looking for agents and helps you connect with them.
            </CustomPara>
            <div className="freetrial-agent-modal-button-div">
              <Link to="/pricing">
                <Button
                  type={ButtonType.WHITE}
                  text={'PRICING PLANS'}
                  clickHandler={handlePricingPlans}
                  className="freetrial-agent-modal-invite-your-agent-button"
                />
              </Link>
              <Button
                type={ButtonType.FIND_AN_AGENT}
                text={'CONTINUE'}
                className="freetrial-agent-modal-invite-your-agent-button"
                clickHandler={handleContinue}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeTrialModal;
