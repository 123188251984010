/* eslint-disable use-isnan */
/* eslint-disable no-new-wrappers */
/* eslint-disable array-callback-return */
import moment from "moment";
import dayjs from "dayjs";
import {
  checkIsListedPropertyServiceForListingAgent,
  getAgentBuyerPropertyCalendar,
  getAgentBuyerPropertyCalendarByBuyerId,
  getALLSellerListedProperties,
  getBestRoute,
  getAllScheduledUpcommingTours
} from "../../services/NavihomeService";
import { myCients, requestedProperty } from "../AgentCalendar";

export const addRequestedPropertyEvent = (requestedProperties: requestedProperty[], myclients: myCients[], tempArray: any, userState: any, baseUrl: string) => {
  requestedProperties.forEach((ele: any, index) => {
    myclients?.forEach((cli: any) => {
      if (cli?.buyerId === ele?.buyerId) {
        let status;
        if (ele.accept === false && ele.initialStatus === false) {
          status = "rejected";
        } else if (ele.accept && ele.initialStatus === false) {
          status = "accepted";
        } else if (ele.accept === false && ele.initialStatus) {
          status = "requested";
        }
        tempArray.push({
          id: status,
          accept: ele.accept,
          initialStatus: ele.initialStatus,
          buyerId: cli.buyerId,
          start: moment(ele.dataTime).format("YYYY-MM-DD hh:mm:ss"),
          end: moment(ele.dataTime).format("YYYY-MM-DD hh:mm:ss"),
          // allDay: true,
          allDay: false,
          editable: false,
          contentHeight: 600,

          extendedProps: {
            dateTime: moment(ele.dataTime).format("YYYY-MM-DD hh:mm:ss"),
            name: cli?.name,
            fullName: cli?.name,
            eventType: "requestedP",
            agentName: userState?.agent?.name,
            phone: cli?.phone,
            address: ele?.address?.full,
            pid: ele?.propertyId,
            url: `${baseUrl}${ele?.address?.city?.trim()?.toLowerCase()}/${ele?.propertyId}`,
            file: "Props12345.pdf",
            type: ele?.propertyType
          }
        });
        // setEvents([...events, data]);
      }
    });
  });
  return tempArray;
};

export const addUpcommigToursEvent = (upCommingTours: string[], myclients: myCients[], tempArray: any, userState: any, baseUrl: string) => {
  upCommingTours.forEach((ele: any, index) => {
    myclients?.forEach((cli: any) => {
      if (cli?.buyerId === ele?.buyerId) {
        tempArray.push({
          id: "mls",
          buyerId: cli.buyerId,
          start: ele.dateTime,
          end: ele.dateTime,
          allDay: false,
          editable: false,

          // allDay: true,
          extendedProps: {
            dateTime: moment(ele.dateTime).format("YYYY-MM-DD hh:mm:ss"),
            name: cli?.name,
            eventType: "upCommingTour",
            fullName: cli?.name,
            agentName: userState?.agent?.name,
            phone: cli?.phone,
            address: ele?.address?.full,
            pid: ele?.propertyId,
            url: `${baseUrl}${ele?.address?.city?.trim()?.toLowerCase()}/${ele?.propertyId}`,
            file: "Props12345.pdf",
            type: ele?.propertyType
          }
        });
        // setEvents([...events, data]);
      }
    });
  });
};

export const getUpcommingTours = async (agentId: number, token: string) => {
  const upCommingTour: string[] = null;
  const upCommingTourArray: any = [];
  const ff = upCommingTour?.sort((a: any, b: any) => {
    return new Date(b["createdAt"]).getTime() - new Date(a["createdAt"]).getTime();
  });
  const filterUpcommingTours = ff?.filter((ele: any, index: any, array) => array.findIndex((t: any) => t["buyerId"] === ele["buyerId"]) === index);
  await Promise.all(
    filterUpcommingTours?.map(async (item: any) => {
      let dateTime;
      const result: any = null;
      if (result?.length > 0) {
        for (let i = 0; i < result.length; i++) {
          const time = item?.timePerProperty?.split(" ")[0];
          dateTime = moment(dateTime ? dateTime : item.dateTime)
            .add(time, "minutes")
            .format("YYYY-MM-DD hh:mm:ss");
          upCommingTourArray.push({
            agentId: item.agentId,
            buyerId: item.buyerId,
            dateTime: dateTime,
            propertyId: result[i]["propertyId"]
          });
        }
      }
    })
  );

  const newUpCommingTour: any = await Promise.all(
    upCommingTourArray?.map(async (ele: any) => {
      if (ele?.propertyId !== 0) {
        const Pdetails: any = {};
        ele.address = Pdetails.address;
        return ele;
      }
    })
  );

  return newUpCommingTour;
};

const getAllScheduledUpcommingToursForAgentService = async (token: string, type: string) => {
  try {
    return await getAllScheduledUpcommingTours(token, type);
  } catch (error) {
    console.error(error);
  }
};

export const getAllListedProperty = (listingId: number, agentId: number) =>
  new Promise(async (resolve) => {
    try {
      const response: any = await checkIsListedPropertyServiceForListingAgent(listingId, agentId);
      return resolve(response);
    } catch (error) {
      console.log("ERROR: ", error);
      return resolve([]);
    }
  });

export const getALLSellerListedPropertiesService = async (agentId: number, token: string) => {
  try {
    const response: any = await getALLSellerListedProperties(agentId, token);
    const listingRes = response?.map((item: any) => {
      return getAllListedProperty(item?.mlsId, agentId);
    });

    const filteredListings = await Promise.all(listingRes);
    return filteredListings;
  } catch (error) {
    console.log("ERROR: ", error);
    return [];
  }
};

export const getAllViewPropertyByAgetntId = async (agentId: number, token: string, type: string, userState: any, calendarType?: string) => {
  const tempEventArray: any = [];
  try {
    let viewProp: any = [];
    if (type !== "upcoming") {
      const eventType: string = calendarType === "property calendar" ? "all" : "Requested";
      viewProp = await getAgentBuyerPropertyCalendar(agentId, eventType);
      
      viewProp?.map((item: any) => {
        const stYear: any = new Date(item?.startDate)?.getFullYear();
        const stmonth: any = new Date(item?.startDate).getMonth();
        const stDay: any = new Date(item?.startDate).getUTCDate();
        // const stDay: any = dayjs(item?.startDate).date();
        let stHours: any = Number(item?.startTime?.split(":")[0]);
        let mins: any = Number(item?.startTime?.split(":")[1]);
        const endmonth: any = new Date(item?.endDate).getMonth();
        let endDay: any = new Date(item?.endDate).getUTCDate();
        // let endDay: any = dayjs(item?.endDate).date();
        let endHours: any = Number(item?.endTime?.split(":")[0]);
        let endmins: any = Number(item?.endTime?.split(":")[1]);

        if (item?.startTime?.split(" ")?.[1]?.toLowerCase() === "pm" && stHours < 12) stHours += 12;
        if (item?.endTime?.split(" ")?.[1]?.toLowerCase() === "pm" && endHours < 12) endHours += 12;
        if (mins === "0") mins = "00";
        if (endmins === "0") endmins = "00";
        const propertyDetails: any = item?.propertyDetails ? JSON?.parse(item?.propertyDetails) : {};
        if (Number(stDay) < Number(endDay) && Number(stHours) < Number(endHours)) {
          endDay -= 1;
        }
        tempEventArray.push({
          id: item?.type,
          title: "View Property",
          groupId: "Calendar Event",
          start: new Date(Number(stYear), Number(stmonth), Number(stDay), Number(stHours), Number(mins), 0),
          end: new Date(Number(stYear), Number(endmonth), Number(endDay), Number(endHours), Number(endmins), 0),
          recurstart: new Date(Number(stYear), Number(stmonth), Number(stDay), Number(stHours), Number(mins), 0),
          recurend: new Date(Number(stYear), 8, 5, 10, 30, 0),
          rStatus: item?.recuringStatus,
          eventId: item?.viewPropertyCalendarId,
          mlsId: item?.mlsId,
          Stime: item?.startTime,
          Sdate: item?.startDate,
          Etime: item?.endTime,
          Edate: item?.endDate,
          rEdate: item?.recuringEndDate,
          isAccepted: item?.isAccepted,
          isRejected: item?.isRejected,
          name: propertyDetails?.agent?.firstName,
          fullName: propertyDetails?.agent?.firstName + " " + propertyDetails?.agent?.lastName,
          eventType: "requestedP",
          agentName: "userState?.agent?.name",
          phone: propertyDetails?.agent?.contact?.office,
          address: propertyDetails?.address?.full,
          streetName: propertyDetails?.address?.streetName,
          streetNumber: propertyDetails?.address?.streetNumber,
          city: propertyDetails?.address?.city,
          state: propertyDetails?.address?.state,
          country: propertyDetails?.address?.country,
          postalCode: propertyDetails?.address?.postalCode,
          pid: propertyDetails?.mlsId ? propertyDetails?.mlsId : item?.mlsId,
          type: item?.type,
          file: "Props12345.pdf",
          listingId: propertyDetails?.listingId,
          agentType: "listingAgent",
          agentId: item?.agentId ?? 0,
          buyerId: item?.buyerId ?? 0,
          sender: item.sender,
          buyerAgentName: item?.listingAgentName ?? "",
          client: item?.buyer?.name ?? "",
          clientPhone: item?.buyer?.phone ?? "",
          propertyListType: "buyerAgent",
          description: item?.description,
          showingType: item?.showingType
        });

      });
    }

    let listedPropertyRequest: any = [];
    if (type !== "showing") {
      listedPropertyRequest = await getALLSellerListedPropertiesService(agentId, token);

      // const listedPropertyRequest: any = []
      listedPropertyRequest?.forEach((item: any) => {
        if (item?.length > 0) {
          item?.map((item: any) => {
            const stYear: any = new Date(item?.startDate)?.getFullYear();
            const stmonth: any = new Date(item?.startDate).getMonth();
            const stDay: any = new Date(item?.startDate).getUTCDate();
            // const stDay: any = dayjs(item?.startDate).date();
            let stHours: any = Number(item?.startTime?.split(":")[0]);
            let mins: any = Number(item?.startTime?.split(":")[1]);
            const endmonth: any = new Date(item?.endDate).getMonth();
            let endDay: any = new Date(item?.endDate).getUTCDate();
            // let endDay: any = dayjs(item?.endDate).date();
            let endHours: any = Number(item?.endTime?.split(":")[0]);
            let endmins: any = Number(item?.endTime?.split(":")[1]);

            if (item?.startTime?.split(" ")?.[1]?.toLowerCase() === "pm" && stHours < 12) stHours += 12;
            if (item?.endTime?.split(" ")?.[1]?.toLowerCase() === "pm" && endHours < 12) endHours += 12;
            if (mins === "0") mins = "00";
            if (endmins === "0") endmins = "00";
            const propertyDetails: any =item?.propertyDetails ? JSON?.parse(item?.propertyDetails) : {};

            if (Number(stDay) < Number(endDay) && Number(stHours) < Number(endHours)) {
              endDay -= 1;
            }
            tempEventArray.push({
              id: item?.type,
              title: "View Property",
              groupId: "Calendar Event",
              start: new Date(Number(stYear), Number(stmonth), Number(stDay), Number(stHours), Number(mins), 0),
              end: new Date(Number(stYear), Number(endmonth), Number(endDay), Number(endHours), Number(endmins), 0),
              recurstart: new Date(Number(stYear), Number(stmonth), Number(stDay), Number(stHours), Number(mins), 0),
              recurend: new Date(Number(stYear), 8, 5, 10, 30, 0),
              rStatus: item?.recuringStatus,
              eventId: item?.viewPropertyCalendarId,
              mlsId: item?.mlsId,
              Stime: item?.startTime,
              Sdate: item?.startDate,
              Etime: item?.endTime,
              Edate: item?.endDate,
              rEdate: item?.recuringEndDate,
              isAccepted: item?.isAccepted,
              isRejected: item?.isRejected,
              name: propertyDetails?.agent?.firstName,
              fullName: propertyDetails?.agent?.firstName + " " + propertyDetails?.agent?.lastName,
              eventType: "requestedP",
              agentName: "userState?.agent?.name",
              phone: propertyDetails?.agent?.contact?.office,
              address: propertyDetails?.address?.full,
              streetName: propertyDetails?.address?.streetName,
              streetNumber: propertyDetails?.address?.streetNumber,
              city: propertyDetails?.address?.city,
              state: propertyDetails?.address?.state,
              country: propertyDetails?.address?.country,
              postalCode: propertyDetails?.address?.postalCode,
              pid: propertyDetails?.mlsId,
              type: item?.type,
              file: "Props12345.pdf",
              listingId: propertyDetails?.listingId,
              buyerId: item?.buyerId,
              agentType: "buyerAgent",
              agentId: item?.agentId ?? 0,
              buyerName: item?.buyer?.name ?? "",
              buyerPhone: item?.buyer?.phone ?? "",
              buyerEmail: item?.buyer?.email ?? "",
              buyerAgentName: item?.agent?.name ?? "",
              buyerAgentPhone: item?.agent?.phone ?? "",
              buyerAgentEmail: item?.agent?.email ?? "",
              client: item?.client ?? "",
              clientPhone: item?.clientPhone ?? "",
              propertyListType: "listingAgent",
              description: item?.description,
              showingType: item?.showingType
            });
          });
        }
      });
    }

    let scheduledProperties: any = [];
    if (type !== "showing") {
      scheduledProperties = await getAllScheduledUpcommingToursForAgentService(token, type);

      scheduledProperties?.map((item: any) => {
        const stYear: any = new Date(item?.startDate)?.getFullYear();
        const stmonth: any = new Date(item?.startDate)?.getMonth();
        const stDay: any = new Date(item?.startDate)?.getUTCDate();
        const eYear: any = new Date(item?.endDate)?.getFullYear();
        const endmonth: any = new Date(item?.endDate)?.getMonth();
        const endDay: any = new Date(item?.endDate)?.getUTCDate();
        let stHours: any = new Number(item?.startTime?.split(':')?.[0]);
        let stMins: any = new Number(
          item?.startTime?.split(':')?.[1]?.split(' ')?.[0]
        );
        let endHours: any = new Number(item?.endTime?.split(':')?.[0]);
        let endMins: any = new Number(
          item?.endTime?.split(':')?.[1]?.split(' ')?.[0]
        );
        if (item?.startTime?.split(' ')?.[1]?.toLowerCase() === 'pm' && stHours < 12)
          stHours += 12;
        if (item?.endTime?.split(' ')?.[1]?.toLowerCase() === 'pm' && endHours < 12)
          endHours += 12;
        if (stMins === '0') stMins = '00';
        if (endMins === '0') endMins = '00';

        let propDetailsForBestRoute: any = null;
        if (item?.propertyDetails) {
          propDetailsForBestRoute = JSON?.parse(item?.propertyDetails);
        }
        const fullName = propDetailsForBestRoute?.agent?.firstName + " " + propDetailsForBestRoute?.agent?.lastName;
        tempEventArray?.push({
          // id: item?.bestRouteId,
          id: item?.status,
          title: "Scheduled Property",
          start: new Date(Number(stYear), Number(stmonth), Number(stDay), Number(stHours), Number(stMins), 0),
          end: new Date(Number(eYear), Number(endmonth), Number(endDay), Number(endHours), Number(endMins), 0),
          Stime: item?.startTime,
          Sdate: item?.startDate,
          Etime: item?.endTime,
          Edate: item?.endDate,
          rEdate: item?.recuringEndDate,
          eventId: item?.bestRouteId,
          isAccepted: item?.isAccepted,
          isRejected: item?.isRejected,
          name: propDetailsForBestRoute?.agent?.firstName,
          fullName: propDetailsForBestRoute?.agent?.firstName + " " + propDetailsForBestRoute?.agent?.lastName,
          eventType: "Scheduled Property",
          agentName: "userState?.agent?.name",
          phone: propDetailsForBestRoute?.agent?.contact?.office,
          address: propDetailsForBestRoute?.address?.full,
          streetName: propDetailsForBestRoute?.address?.streetName,
          streetNumber: propDetailsForBestRoute?.address?.streetNumber,
          city: propDetailsForBestRoute?.address?.city,
          state: propDetailsForBestRoute?.address?.state,
          country: propDetailsForBestRoute?.address?.country,
          postalCode: propDetailsForBestRoute?.address?.postalCode,
          pid: propDetailsForBestRoute?.mlsId,
          duration: item?.propertyDuration,
          type: item?.type,
          file: "Props12345.pdf",
          listingId: propDetailsForBestRoute?.listingId,
          agentType: item?.isListed && item?.listingAgentId === agentId ? "buyerAgent" : "listingAgent",
          agentId: item?.agentId ?? 0,
          buyerId: item?.buyerId ?? 0,
          sender: item?.sender ?? "",
          buyerAgentName: item?.listingAgentId !== userState?.agent?.agentId ? (item?.listingAgentName ? item?.listingAgentName : fullName) : item?.agentName ? item?.agentName : fullName,
          isListed: item?.isListed ?? "",
          client: item?.listingAgentId === userState?.agent?.agentId ? item?.client : item?.buyer?.name,
          clientPhone: item?.listingAgentId === userState?.agent?.agentId ? item?.clientPhone : item?.buyer?.phone,
          mlsId: propDetailsForBestRoute?.mlsId,
          role: item?.role ? item?.role : null,
          systemSchedule: item?.systemSchedule,
          showingType: item?.showingType
        });
      });
    }

    return tempEventArray;
  } catch (error) {
    console.log("Error:", error);
    return [];
  }
};

export const scheduledPropertyEvents = async (buyerId: number, token: string) => {
  const tempScheduledEventArray: any = [];
  try {
    const ScheduledResult: any = await getBestRoute(buyerId, token);
    // ScheduledResult?.map((item: any) => {
    const stmonth: any = new Date(ScheduledResult?.dateTime)?.getMonth();
    const stDay: any = new Date(ScheduledResult?.dateTime)?.getUTCDate();
    const stHours: any = new Date(ScheduledResult?.dateTime)?.getHours();
    const mins: any = new Date(ScheduledResult?.dateTime)?.getMinutes();
    const endmonth: any = new Date(ScheduledResult?.dateTime)?.getMonth();
    const endDay: any = new Date(ScheduledResult?.dateTime)?.getUTCDate();
    const endHours: any = new Date(ScheduledResult?.dateTime)?.getHours();
    const endmins: any = new Date(ScheduledResult?.dateTime)?.getMinutes();
    tempScheduledEventArray.push({
      id: "Scheduled",
      title: "Scheduled Property",
      start: new Date(2022, Number(stmonth), Number(stDay), Number(stHours), Number(mins), 0),
      end: new Date(2022, Number(endmonth), Number(endDay), Number(endHours), Number(endmins), 0),
      startTime: String(ScheduledResult?.time)?.split("-")?.[0],
      endTime: String(ScheduledResult?.time)?.split("-")?.[1]
    });
    return tempScheduledEventArray;
  } catch (error) {
    console.log("Error", error);
    return [];
  }
};

export const getViewPropertyEventsByBuyerId = async (buyerId: number) => {
  const viewPropertyEvents: any = await getAgentBuyerPropertyCalendarByBuyerId(buyerId);
  const tempRequestedeventArray: any = [];
  try {
    viewPropertyEvents?.map((item: any) => {
      const stYear: any = new Date(item?.startDate)?.getFullYear();
      const stmonth: any = new Date(item?.startDate)?.getMonth();
      // const stDay: any = new Date(item?.startDate)?.getDay();
      const stDay: any = dayjs(item?.startDate)?.date();
      let stHours: any = Number(item?.startTime?.split(":")[0]);
      let stmins: any = Number(item?.startTime?.split(":")[1]);
      const endYear: any = new Date(item?.endDate)?.getFullYear();
      const endmonth: any = new Date(item?.endDate)?.getMonth();
      // const endDay: any = new Date(item?.endDate)?.getDay();
      const endDay: any = dayjs(item?.endDate)?.date();
      let endHours: any = Number(item?.endTime?.split(":")?.[0]);
      let endmins: any = Number(item?.endTime?.split(":")?.[1]);
      console.log("Time Format", item?.endTime?.split(" ")?.[1]);
      
      if (item?.startTime?.split(" ")?.[1]?.toLowerCase() === "pm" && stHours < 12) stHours += 12;
      if (item?.endTime?.split(" ")?.[1]?.toLowerCase() === "pm" && endHours < 12) endHours += 12;
      if (stmins === "0") stmins = "00";
      if (endmins === "0") endmins = "00";
      tempRequestedeventArray.push({
        id: "viewProperty",
        title: "View Property",
        start: new Date(Number(stYear), Number(stmonth), Number(stDay), Number(stHours), Number(stmins), 0),
        end: new Date(Number(endYear), Number(endmonth), Number(endDay), Number(endHours), Number(endmins), 0)
        // startTime: String(item?.startTime),
        // endTime: String(item?.endTime),
      });
    });
    return tempRequestedeventArray;
  } catch (error) {
    console.log("Error", error);
  }
};
