import buttonClose from "../assets/images/button-close.svg";
import iconCalendar from "../assets/images/icon-calendar.svg";
import SelectField, { SelectFieldType } from "../components/SelectField";
import Button, { ButtonType } from "../components/Button";
import checkboxOn from "../assets/images/button-checkbox-on.svg";
import checkboxOff from "../assets/images/button-checkbox-off.svg";
import styled from "@emotion/styled";
import moment from "moment";
import { useState, useEffect } from "react";
import { notification } from "../components/notification";
import { DatePicker } from "antd";

const CustomModalTitle = styled.h2`
  color: #004876;
  font-weight: bold;
  margin: 0px;
`;

const CustomDate = styled(DatePicker)`
  .anticon {
    display: inline-block;
    color: #004876;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .ant-picker-suffix {
    display: flex;
    flex: none;
    align-self: center;
    margin-left: 4px;
    color: rgba(0, 0, 0, 0.25);
    line-height: 1;
    pointer-events: none;
    position: relative;
    right: 2rem;
  }
`;

const CustomeDate2 = styled(DatePicker)`
  .anticon {
    display: inline-block;
    color: #004876;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .ant-picker-suffix {
    display: flex;
    flex: none;
    align-self: center;
    margin-left: 4px;
    color: rgba(0, 0, 0, 0.25);
    line-height: 1;
    pointer-events: none;
    position: relative;
    right: 2rem;
  }
`;

interface QuickBuyerRegistrationModalPropsModel {
  isShowing: boolean;
  customStartDate: any;
  setCustomStartDate: any;
  customEndDate?: any;
  setCustomEndDate: any;
  fromCustomStart: (values: any) => void;
  setDaysOfWeek: (values: Array<string>) => void;
  fromCustomEnd: (values: any) => void;
  setRecurringStatus: any;
  setRecurringTitle: any;
  recurringStatus?: any;
  closeAction: () => void;
  form: any;
}

const CustomRecurrenceModal: React.FC<
  QuickBuyerRegistrationModalPropsModel
> = ({
  isShowing,
  customStartDate,
  setCustomStartDate,
  customEndDate,
  fromCustomStart,
  fromCustomEnd,
  setDaysOfWeek,
  setCustomEndDate,
  setRecurringStatus,
  recurringStatus,
  setRecurringTitle,
  closeAction,
  form,
}): JSX.Element => {
  const [changeStatus, setChangeStatus] = useState<string>("");
  const [changeEndDate, setChangeEndDate] = useState<string>("");
  const [selectedEndDate, setSelectedEndDate] = useState<any>();
  const [initialDate, setInitialDate] = useState<any>(customStartDate);
  const [weekDays, setWeekDays] = useState<Array<any>>([
    { day: "Sunday", value: "0", isChecked: true },
    { day: "Monday", value: "1", isChecked: true },
    { day: "Tuesday", value: "2", isChecked: false },
    { day: "Wednesday", value: "3", isChecked: true },
    { day: "Thursday", value: "4", isChecked: true },
    { day: "Friday", value: "5", isChecked: false },
    { day: "Saturday", value: "6", isChecked: false },
  ]);

  const handleInputChange = (event: any) => {
 
    if (
      moment(event).format("MM/DD/YYYY") <
      moment(initialDate).format("MM/DD/YYYY")
    ) {
      notification(
        "Recurrence Date should be greater than or equal to the Start date",
        "error"
      );
      return;
    }
    setCustomStartDate(moment(customStartDate).format("MM/DD/YYYY"));
    moment(event).format("YYYY-MM-DD") != "Invalid date"
      ? setCustomStartDate(moment(event).format("YYYY-MM-DD"))
      : setCustomStartDate(moment(event?.target?.value).format("MM/DD/YYYY"));

    fromCustomStart(moment(event).format("YYYY-MM-DD"));
  };
  const handleEndInputChange = (event: any) => {
    setSelectedEndDate(moment(event).format("YYYY-MM-DD"));
   
    setCustomEndDate(moment(event).format("YYYY-MM-DD"));
    setChangeEndDate("endDate");
    fromCustomEnd(event?.target?.value);
  };
  const saveCustomHandler = () => {
    if (customStartDate?.length <= 0) {
      notification("select start Date", "error");
      return;
    }
    if (changeEndDate?.length <= 0 || changeEndDate == undefined) {
      notification("select end Date", "error");
      return;
    }
    if (customStartDate > selectedEndDate) {
      notification("End Date should be greater than the Start date", "error");
      return;
    }

    closeAction();
  };

  const checkedClick = (index: number) => {

    const daysValue: any = weekDays.filter((item) => {
      if (item.value != index.toString()) return item.value;
    });

    setDaysOfWeek(daysValue);

    const objIndex = weekDays.findIndex((obj) => obj.value == index.toString());
    weekDays[objIndex].isChecked = !weekDays[objIndex].isChecked;
    renderCheckBox();
    return weekDays;
  };

  const renderCheckBox = () => {
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          margin: "20px 0 0 50px",
        }}
      >
        {weekDays.map((item, index) => {
          return (
            <div style={{ display: "flex", marginLeft: "12px" }}>
              <p>{item?.day}</p>
              <div
                onClick={() => {
                  checkedClick(index);
                }}
              >
                {item.isChecked ? (
                  <img
                    src={checkboxOn}
                    alt="Visited On Button"
                    style={{ marginLeft: "12px" }}
                  />
                ) : (
                  <img
                    src={checkboxOff}
                    alt="Visited Off Button"
                    style={{ marginLeft: "12px" }}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const valueHandler = (value: string) => {
    form.setFieldsValue({ RecurringStatus: value });
    setRecurringTitle(value);
    getRecurringMode(value.toLocaleLowerCase());
    setRecurringStatus(value);
  };

  const getRecurringMode = (mode: string) => {
    switch (mode) {
      case "daily":
        setChangeStatus("day");
        break;
      case "weekly":
        setChangeStatus("week");
        break;
      case "monthly":
        setChangeStatus("month");
        break;
      default:
        setChangeStatus("month");
        break;
    }
  };

  useEffect(() => {
    getRecurringMode(recurringStatus.toLocaleLowerCase());
  }, []);

  const modalClassName = isShowing
    ? "custom-recurrence-modal u-visible"
    : "custom-recurrence-modal u-hidden";
  const cardClassName = isShowing
    ? "custom-recurrence-modal__card animation__pop-in"
    : "custom-recurrence-modal__card animation__pop-out";

  return (
    <div className={modalClassName}>
      <div className={cardClassName}>
        <div className="custom-recurrence-modal__row">
          <CustomModalTitle>
            Custom{" "}
            <span style={{ borderBottom: "3px solid #C2D500" }}>
              recurrence
            </span>
          </CustomModalTitle>
          <img
            className="custom-recurrence-modal__close-button"
            src={buttonClose}
            onClick={closeAction}
            alt="Close button"
          />
        </div>
        <div className="custom-recurrence-modal__column">
          <div className="custom-recurrence-modal__text-field">
            <label className="text-field__label">Start *</label>
            <img
              className="text-field__icon"
              src={iconCalendar}
              alt="TextField Icon"
            />
            <CustomDate
              value={moment(customStartDate)}
              format={"MM/DD/YYYY"}
              onChange={handleInputChange}
              className="text-field__input text-field__input--with-icon"
              style={{ width: "38.4rem" }}
            />
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <div>
              <p style={{ marginTop: "22px" }}>Repeat every*</p>
              <p style={{ color: "black" }}>
                <SelectField
                  value={recurringStatus}
                  setValue={valueHandler}
                  sortProperties={() => {
                  }}
                  placeholder={"Day"}
                  options={["Daily", "Weekly", "Monthly"]}
                  optionWidth={12.8}
                  optionsHeight={8}
                  handleChange={() => {}}
                  type={SelectFieldType.TALL}
                  className={"select-repeat-option"}
                />
              </p>
            </div>
          </div>
          <div className="property-card__button" onClick={() => {}}></div>
          <div
            className="custom-recurrence-modal__text-field"
            style={{ marginTop: "30px" }}
          >
            <label className="text-field__label">End *</label>
            <img
              className="text-field__icon"
              src={iconCalendar}
              alt="TextField Icon"
            />
            <CustomeDate2
              value={customEndDate}
              onChange={handleEndInputChange}
              format={"MM/DD/YYYY"}
              className="text-field__input text-field__input--with-icon"
              style={{ width: "38.4rem" }}
            />
          </div>
          <p style={{ marginTop: "18px", paddingBottom: "28px" }}>
            Occurs every {changeStatus} starting{" "}
            {moment(customStartDate).format("MMMM Do, yyyy")}{" "}
          </p>

          <div
            style={{
              justifyContent: "center",
              width: "100%",
              display: "flex",
              padding: "",
            }}
          >
            <Button
              type={ButtonType.PRIMARY}
              text="SAVE"
              clickHandler={saveCustomHandler}
              width={24.5}
              className="custom-recurrence-save-button"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomRecurrenceModal;
