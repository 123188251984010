import React, { useReducer } from 'react';
import { SavedSearch } from '../services/NavihomeService';

enum PropertyFiltersActionType {
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  SET_CITY = 'SET_CITY',
  SET_STATE = 'SET_STATE',
  SET_QUERY = 'SET_QUERY',
  SET_MIN_PRICE = 'SET_MIN_PRICE',
  SET_MAX_PRICE = 'SET_MAX_PRICE',
  SET_BEDS = 'SET_BEDS',
  SET_BATHS = 'SET_BATHS',
  SET_HALF_BATHS = 'SET_HALF_BATHS',
  SET_MIN_SQUARE_FOOTAGE = 'SET_MIN_SQUARE_FOOTAGE',
  SET_MAX_SQUARE_FOOTAGE = 'SET_MAX_SQUARE_FOOTAGE',
  SET_MIN_LOT_SIZE = 'SET_MIN_LOT_SIZE',
  SET_MAX_LOT_SIZE = 'SET_MAX_LOT_SIZE',
  SET_HEATING_COOLING = 'SET_HEATING_COOLING',
  SET_MIN_YEAR_BUILT = 'SET_MIN_YEAR_BUILT',
  SET_MAX_YEAR_BUILT = 'SET_MAX_YEAR_BUILT',
  SET_MIN_YEAR_CONVERTED = 'SET_MIN_YEAR_CONVERTED',
  SET_MAX_YEAR_CONVERTED = 'SET_MAX_YEAR_CONVERTED',
  SET_PARKING_SPACES = 'SET_PARKING_SPACES',
  SET_GARAGES = 'SET_GARAGES',
  SET_OFF_STREET_PARKING = 'SET_OFF_STREET_PARKING',
  SET_PROPERTY_TYPE = 'SET_PROPERTY_TYPE',
  SET_SORT_TYPE = 'SET_SORT_TYPE',
  SET_NAME = 'SET_NAME',
  SET_NOTIFICATIONSCOUNT = 'SET_NOTIFICATIONSCOUNT',
  SET_COOLING = 'SET_COOLING',
  SET_ACTIVATEDPLAN = 'SET_ACTIVATEDPLAN',
  SET_SOCKETCALL = 'SET_SOCKETCALL',
  SET_MESSAGESOCKETCALLER = 'SET_MESSAGESOCKETCALLER',
  SET_CREATENOTIFICATIONSINFO = 'SET_CREATENOTIFICATIONSINFO',
  SET_RECEIVENOTIFICATIONSINFO = 'SET_RECEIVENOTIFICATIONSINFO',
  SET_GETNOTIFICATIONSUSERID = 'SET_GETNOTIFICATIONSUSERID',
  SET_GETNOTIFICATIONSUSEREMAIL = 'SET_GETNOTIFICATIONSUSEREMAIL',
  SET_NEW_LISTING = "SET_NEW_LISTING",
  SET_TOUR_CHECKED_VALUE = "SET_TOUR_CHECKED_VALUE",
  SET_SINGLE_TOUR_ID = "SET_SINGLE_TOUR_ID",
  SET_SCHEDULED_TOUR_ID = "SET_SCHEDULED_TOUR_ID",
  SET_VIEWED_PROP_ID = "SET_VIEWED_PROP_ID",
  SET_VIEWED_FAV_PROP_ID = "SET_VIEWED_FAV_PROP_ID",
  SET_UPCOMING_LISTING_ID = "SET_UPCOMING_LISTING_ID",
  SET_UPCOMING_PROP_ID = "SET_UPCOMING_PROP_ID",
  SET_TOUR_TYPE = "SET_TOUR_TYPE",

}

type PropertyFiltersAction =
  | { type: PropertyFiltersActionType.CLEAR_FILTERS }
  | { type: PropertyFiltersActionType.SET_CITY; payload: { value: string } }
  | { type: PropertyFiltersActionType.SET_STATE; payload: { value: string } }
  | { type: PropertyFiltersActionType.SET_QUERY; payload: { value: string } }
  | {
    type: PropertyFiltersActionType.SET_MIN_PRICE;
    payload: { value: number };
  }
  | {
    type: PropertyFiltersActionType.SET_MAX_PRICE;
    payload: { value: number };
  }
  | { type: PropertyFiltersActionType.SET_BEDS; payload: { value: number } }
  | { type: PropertyFiltersActionType.SET_BATHS; payload: { value: number } }
  | {
    type: PropertyFiltersActionType.SET_HALF_BATHS;
    payload: { value: number };
  }
  | {
    type: PropertyFiltersActionType.SET_MIN_SQUARE_FOOTAGE;
    payload: { value: number };
  }
  | {
    type: PropertyFiltersActionType.SET_MAX_SQUARE_FOOTAGE;
    payload: { value: number };
  }
  | {
    type: PropertyFiltersActionType.SET_MIN_LOT_SIZE;
    payload: { value: number };
  }
  | {
    type: PropertyFiltersActionType.SET_MAX_LOT_SIZE;
    payload: { value: number };
  }
  | {
    type: PropertyFiltersActionType.SET_HEATING_COOLING;
    payload: { value: string };
  }
  | {
    type: PropertyFiltersActionType.SET_MIN_YEAR_BUILT;
    payload: { value: number };
  }
  | {
    type: PropertyFiltersActionType.SET_MAX_YEAR_BUILT;
    payload: { value: number };
  }
  | {
    type: PropertyFiltersActionType.SET_MIN_YEAR_CONVERTED;
    payload: { value: number };
  }
  | {
    type: PropertyFiltersActionType.SET_MAX_YEAR_CONVERTED;
    payload: { value: number };
  }
  | {
    type: PropertyFiltersActionType.SET_PARKING_SPACES;
    payload: { value: number };
  }
  | { type: PropertyFiltersActionType.SET_GARAGES; payload: { value: number } }
  | {
    type: PropertyFiltersActionType.SET_OFF_STREET_PARKING;
    payload: { value: number };
  }
  | {
    type: PropertyFiltersActionType.SET_PROPERTY_TYPE;
    payload: { value: string };
  }
  | {
    type: PropertyFiltersActionType.SET_SORT_TYPE;
    payload: { value: string };
  }
  | {
    type: PropertyFiltersActionType.SET_NAME;
    payload: { value: string };
  }
  | {
    type: PropertyFiltersActionType.SET_NOTIFICATIONSCOUNT;
    payload: { value: number };
  }
  | {
    type: PropertyFiltersActionType.SET_COOLING;
    payload: { value: string };
  }
  | {
    type: PropertyFiltersActionType.SET_ACTIVATEDPLAN;
    payload: { value: string };
  }
  | {
    type: PropertyFiltersActionType.SET_SOCKETCALL;
    payload: { value: string };
  }
  | {
    type: PropertyFiltersActionType.SET_MESSAGESOCKETCALLER;
    payload: { value: string };
  }
  | {
    type: PropertyFiltersActionType.SET_CREATENOTIFICATIONSINFO;
    payload: { value: string };
  }
  | {
    type: PropertyFiltersActionType.SET_RECEIVENOTIFICATIONSINFO;
    payload: { value: string };
  }
  | {
    type: PropertyFiltersActionType.SET_GETNOTIFICATIONSUSERID;
    payload: { value: number };
  } | {
    type: PropertyFiltersActionType.SET_GETNOTIFICATIONSUSEREMAIL;
    payload: { value: string };
  } | {
    type: PropertyFiltersActionType.SET_NEW_LISTING;
    payload: { value: string };
  } | {
    type: PropertyFiltersActionType.SET_TOUR_CHECKED_VALUE;
    payload: { value: string };
  } | {
    type: PropertyFiltersActionType.SET_SINGLE_TOUR_ID;
    payload: { value: number };
  } | {
    type: PropertyFiltersActionType.SET_SCHEDULED_TOUR_ID;
    payload: { value: number };
  } | {
    type: PropertyFiltersActionType.SET_VIEWED_PROP_ID;
    payload: { value: number };
  } | {
    type: PropertyFiltersActionType.SET_UPCOMING_LISTING_ID;
    payload: { value: number };
  } | {
    type: PropertyFiltersActionType.SET_UPCOMING_PROP_ID;
    payload: { value: number };
  } | {
    type: PropertyFiltersActionType.SET_VIEWED_FAV_PROP_ID;
    payload: { value: number };
  } | {
    type: PropertyFiltersActionType.SET_TOUR_TYPE;
    payload: { value: string };
  };
interface PropertyFiltersState {
  cooling: string;
  city: string;
  state: string;
  query: string;
  minPrice: number;
  maxPrice: number;
  beds: number;
  baths: number;
  halfBaths: number;
  minSquareFootage: number;
  maxSquareFootage: number;
  minLotSize: number;
  maxLotSize: number;
  heatingCooling: string;
  minYearBuilt: number;
  maxYearBuilt: number;
  minYearConverted: number;
  maxYearConverted: number;
  parkingSpaces: number;
  garages: number;
  offStreetParking: number;
  propertyType: string;
  sortType: string;
  name: string;
  notificationsCount: number;
  activatedPlan: string;
  socketCall: string;
  messageSocketCaller: string;
  createNotificationsInfo: string;
  receiveNotificationsInfo: string;
  getNotificationsUserId: number;
  getNotificationsUserEmail: string;
  newListing: string;
  tourCheckedValue: string;
  scheduledTourId: number;
  singleTourId: number;
  upcomingListingId: number;
  upcomingPropId: number;
  viewedFavPropId: number;
  viewedPropId: number;
  tourType: string
}

type PropertyFiltersContextType = {
  propertyFiltersState: PropertyFiltersState;
  getFiltersQueryString: () => string;
  getFiltersForSavedSearch: () => SavedSearch;
  clearFilters: () => void;
  setCity: (value: string) => void;
  setState: (value: string) => void;
  setQuery: (value: string) => void;
  setMinPrice: (value: number) => void;
  setMaxPrice: (value: number) => void;
  setBeds: (value: number) => void;
  setBaths: (value: number) => void;
  setHalfBaths: (value: number) => void;
  setMinSquareFootage: (value: number) => void;
  setMaxSquareFootage: (value: number) => void;
  setMinLotSize: (value: number) => void;
  setMaxLotSize: (value: number) => void;
  setHeatingCooling: (value: string) => void;
  setMinYearBuilt: (value: number) => void;
  setMaxYearBuilt: (value: number) => void;
  setMinYearConverted: (value: number) => void;
  setMaxYearConverted: (value: number) => void;
  setParkingSpaces: (value: number) => void;
  setGarages: (value: number) => void;
  setOffStreetParking: (value: number) => void;
  setPropertyType: (value: string) => void;
  setSortType: (value: string) => void;
  setName?: (value: string) => void;
  setNotificationsCount?: (value: number) => void;
  setCooling?: (value: string) => void;
  setActivatedPlan?: (value: string) => void;
  setSocketCall?: (value: string) => void;
  setMessageSocketCaller?: (value: string) => void;
  setCreateNotificationsInfo: (value: string) => void;
  setReceiveNotificationsInfo: (value: string) => void;
  setGetNotificationsUserId: (value: number) => void;
  setGetNotificationsUserEmail: (value: string) => void;
  setNewListing: (value: string) => void;
  setTourCheckedValue: (value: string) => void;
  setScheduledTourId: (value: number) => void;
  setSingleTourId: (value: number) => void;
  setUpcomingListingId: (value: number) => void;
  setUpcomingPropId: (value: number) => void;
  setViewedFavPropId: (value: number) => void;
  setViewedPropId: (value: number) => void;
  setTourType: (value: string) => void;
};

const initialState: PropertyFiltersState = {
  city: 'Houston',
  state: 'Texas',
  query: '',
  minPrice: 0,
  maxPrice: 0,
  beds: 1,
  baths: 1,
  halfBaths: 0,
  minSquareFootage: 0,
  maxSquareFootage: 0,
  minLotSize: 0,
  maxLotSize: 0,
  heatingCooling: '',
  minYearBuilt: 0,
  maxYearBuilt: 0,
  minYearConverted: 0,
  maxYearConverted: 0,
  parkingSpaces: 0,
  garages: 0,
  offStreetParking: 0,
  propertyType: '',
  cooling: '',
  sortType: '',
  name: '',
  notificationsCount: 0,
  activatedPlan: '',
  socketCall: '',
  messageSocketCaller: ' ',
  createNotificationsInfo: '',
  receiveNotificationsInfo: '',
  getNotificationsUserId: 0,
  getNotificationsUserEmail: '',
  newListing: '',
  tourCheckedValue: '',
  scheduledTourId: 0,
  singleTourId: 0,
  upcomingListingId: 0,
  upcomingPropId: 0,
  viewedFavPropId: 0,
  viewedPropId: 0,
  tourType: ''
};

const PropertyFiltersContext = React.createContext<
  PropertyFiltersContextType | undefined
>(undefined);

const reducer = (
  state: PropertyFiltersState,
  action: PropertyFiltersAction
): PropertyFiltersState => {
  switch (action.type) {
    case PropertyFiltersActionType.CLEAR_FILTERS:
      return initialState;
    case PropertyFiltersActionType.SET_CITY:
      return { ...state, city: action.payload.value };
    case PropertyFiltersActionType.SET_STATE:
      return { ...state, state: action.payload.value };
    case PropertyFiltersActionType.SET_QUERY:
      return { ...state, query: action.payload.value };
    case PropertyFiltersActionType.SET_MIN_PRICE:
      return { ...state, minPrice: action.payload.value };
    case PropertyFiltersActionType.SET_MAX_PRICE:
      return { ...state, maxPrice: action.payload.value };
    case PropertyFiltersActionType.SET_BEDS:
      return { ...state, beds: action.payload.value };
    case PropertyFiltersActionType.SET_BATHS:
      return { ...state, baths: action.payload.value };
    case PropertyFiltersActionType.SET_HALF_BATHS:
      return { ...state, halfBaths: action.payload.value };
    case PropertyFiltersActionType.SET_MIN_SQUARE_FOOTAGE:
      return { ...state, minSquareFootage: action.payload.value };
    case PropertyFiltersActionType.SET_MAX_SQUARE_FOOTAGE:
      return { ...state, maxSquareFootage: action.payload.value };
    case PropertyFiltersActionType.SET_MIN_LOT_SIZE:
      return { ...state, minLotSize: action.payload.value };
    case PropertyFiltersActionType.SET_MAX_LOT_SIZE:
      return { ...state, maxLotSize: action.payload.value };
    case PropertyFiltersActionType.SET_HEATING_COOLING:
      return { ...state, heatingCooling: action.payload.value };
    case PropertyFiltersActionType.SET_MIN_YEAR_BUILT:
      return { ...state, minYearBuilt: action.payload.value };
    case PropertyFiltersActionType.SET_MAX_YEAR_BUILT:
      return { ...state, maxYearBuilt: action.payload.value };
    case PropertyFiltersActionType.SET_MIN_YEAR_CONVERTED:
      return { ...state, minYearConverted: action.payload.value };
    case PropertyFiltersActionType.SET_MAX_YEAR_CONVERTED:
      return { ...state, maxYearConverted: action.payload.value };
    case PropertyFiltersActionType.SET_PARKING_SPACES:
      return { ...state, parkingSpaces: action.payload.value };
    case PropertyFiltersActionType.SET_GARAGES:
      return { ...state, garages: action.payload.value };
    case PropertyFiltersActionType.SET_OFF_STREET_PARKING:
      return { ...state, offStreetParking: action.payload.value };
    case PropertyFiltersActionType.SET_PROPERTY_TYPE:
      return { ...state, propertyType: action.payload.value };
    case PropertyFiltersActionType.SET_SORT_TYPE:
      return { ...state, sortType: action.payload.value };
    case PropertyFiltersActionType.SET_NAME:
      return { ...state, name: action.payload.value };
    case PropertyFiltersActionType.SET_NOTIFICATIONSCOUNT:
      return { ...state, notificationsCount: action.payload.value };
    case PropertyFiltersActionType.SET_COOLING:
      return { ...state, cooling: action.payload.value };
    case PropertyFiltersActionType.SET_ACTIVATEDPLAN:
      return { ...state, activatedPlan: action.payload.value };
    case PropertyFiltersActionType.SET_SOCKETCALL:
      return { ...state, socketCall: action.payload.value };
    case PropertyFiltersActionType.SET_MESSAGESOCKETCALLER:
      return { ...state, messageSocketCaller: action.payload.value };
    case PropertyFiltersActionType.SET_CREATENOTIFICATIONSINFO:
      return { ...state, createNotificationsInfo: action.payload.value };
    case PropertyFiltersActionType.SET_RECEIVENOTIFICATIONSINFO:
      return { ...state, receiveNotificationsInfo: action.payload.value };
    case PropertyFiltersActionType.SET_GETNOTIFICATIONSUSERID:
      return { ...state, getNotificationsUserId: action.payload.value };
    case PropertyFiltersActionType.SET_GETNOTIFICATIONSUSEREMAIL:
      return { ...state, getNotificationsUserEmail: action.payload.value };
    case PropertyFiltersActionType.SET_NEW_LISTING:
      return { ...state, newListing: action.payload.value };
    case PropertyFiltersActionType.SET_TOUR_CHECKED_VALUE:
      return { ...state, tourCheckedValue: action.payload.value };
    case PropertyFiltersActionType.SET_SCHEDULED_TOUR_ID:
      return { ...state, scheduledTourId: action.payload.value };
    case PropertyFiltersActionType.SET_SINGLE_TOUR_ID:
      return { ...state, singleTourId: action.payload.value };
    case PropertyFiltersActionType.SET_UPCOMING_LISTING_ID:
      return { ...state, upcomingListingId: action.payload.value };
    case PropertyFiltersActionType.SET_UPCOMING_PROP_ID:
      return { ...state, upcomingPropId: action.payload.value };
    case PropertyFiltersActionType.SET_VIEWED_FAV_PROP_ID:
      return { ...state, viewedFavPropId: action.payload.value };
    case PropertyFiltersActionType.SET_VIEWED_PROP_ID:
      return { ...state, viewedPropId: action.payload.value };
    case PropertyFiltersActionType.SET_TOUR_TYPE:
      return { ...state, tourType: action.payload.value }
    default:
      return state;
  }
};

export const PropertyFiltersProvider: React.FC = ({ children }) => {
  const [propertyFiltersState, dispatch] = useReducer(reducer, initialState);

  const getFiltersQueryString = () => {
    const {
      city,
      state,
      query,
      minPrice,
      maxPrice,
      beds,
      baths,
      halfBaths,
      minSquareFootage,
      maxSquareFootage,
      minLotSize,
      maxLotSize,
      minYearBuilt,
      maxYearBuilt,
      heatingCooling,
      minYearConverted,
      maxYearConverted,
      garages,
      parkingSpaces,
      propertyType,
      sortType,
      cooling,
    } = propertyFiltersState;

    var queryString = `city=${encodeURI(city)}&state=${encodeURI(state)}`;

    if (query !== initialState.query) {
      queryString = queryString.concat(`&query=${encodeURI(query)}`);
    }
    if (propertyType !== initialState.propertyType) {
      queryString = queryString.concat(`&type=${encodeURI(propertyType)}`);
    }
    if (sortType !== initialState.sortType) {
      queryString = queryString.concat(`&sort=${encodeURI(sortType)}`);
    }

    if (minPrice !== initialState.minPrice) {
      queryString = queryString.concat(`&min_price=${minPrice}`);
    }

    if (maxPrice !== initialState.maxPrice) {
      queryString = queryString.concat(`&max_price=${maxPrice}`);
    }

    if (beds !== initialState.beds) {
      queryString = queryString.concat(`&beds=${beds}`);
    }
    if (baths !== initialState.baths) {
      queryString = queryString.concat(`&baths=${baths}`);
    }

    if (halfBaths !== initialState.halfBaths) {
      queryString = queryString.concat(`&half_baths=${halfBaths}`);
    }
    if (minSquareFootage !== initialState.minSquareFootage) {
      queryString = queryString.concat(
        `&min_square_footage=${minSquareFootage}`
      );
    }
    if (maxSquareFootage !== initialState.maxSquareFootage) {
      queryString = queryString.concat(
        `&max_square_footage=${maxSquareFootage}`
      );
    }
    if (minYearBuilt !== initialState.minYearBuilt) {
      queryString = queryString.concat(`&min_year_built=${minYearBuilt}`);
    }
    if (maxYearBuilt !== initialState.maxYearBuilt) {
      queryString = queryString.concat(`&max_year_built=${maxYearBuilt}`);
    }
    if (garages !== initialState.garages) {
      queryString = queryString.concat(`&garages=${garages}`);
    }
    if (parkingSpaces !== initialState.parkingSpaces) {
      queryString = queryString.concat(`&parking_spaces=${parkingSpaces}`);
    }
    if (cooling !== initialState.cooling) {
      queryString = queryString.concat(`&cooling=${cooling}`);
    }
    if (heatingCooling !== initialState.heatingCooling) {
      queryString = queryString.concat(`&heating=${heatingCooling}`);
    }
    if (minLotSize !== initialState.minLotSize) {
      queryString = queryString.concat(`&min_lot_size=${minLotSize}`);
    }
    if (maxLotSize !== initialState.maxLotSize) {
      queryString = queryString.concat(`&max_lot_size=${maxLotSize}`);
    }
    if (minYearConverted !== initialState.minYearBuilt) {
      queryString = queryString.concat(
        `&min_year_converted=${minYearConverted}`
      );
    }
    if (maxYearConverted !== initialState.maxYearConverted) {
      queryString = queryString.concat(`&max_year_converted=${maxYearBuilt}`);
    }
    return queryString;
  };

  const getFiltersForSavedSearch = () => {
    const {
      city,
      state,
      query,
      minPrice,
      maxPrice,
      beds,
      baths,
      halfBaths,
      minSquareFootage,
      maxSquareFootage,
      minLotSize,
      maxLotSize,
      minYearBuilt,
      maxYearBuilt,
      minYearConverted,
      maxYearConverted,
      heatingCooling,
      garages,
      parkingSpaces,
      offStreetParking,
      propertyType,
      sortType,
    } = propertyFiltersState;

    var savedSearch: SavedSearch = {
      city,
      state,
      minPrice,
      maxPrice,
      beds,
      baths,
      halfBaths,
      minSquareFootage,
      maxSquareFootage,
      minLotSize,
      maxLotSize,
      minYearBuilt,
      maxYearBuilt,
      minYearConverted,
      maxYearConverted,
      heatingCooling,
      garages,
      parkingSpaces,
      offStreetParking,
      propertyType,
      sortType,
    };

    if (query !== initialState.query) {
      savedSearch.query = query;
    }

    if (minPrice !== initialState.minPrice) {
      savedSearch.minPrice = minPrice;
    }

    if (maxPrice !== initialState.maxPrice) {
      savedSearch.maxPrice = maxPrice;
    }

    if (beds !== initialState.beds) {
      savedSearch.beds = beds;
    }

    if (baths !== initialState.baths) {
      savedSearch.baths = baths;
    }

    if (halfBaths !== initialState.halfBaths) {
      savedSearch.halfBaths = halfBaths;
    }
    if (sortType !== initialState.sortType) {
      savedSearch.sortType = sortType;
    }
    return savedSearch;
  };

  const clearFilters = () => {
    dispatch({ type: PropertyFiltersActionType.CLEAR_FILTERS });
  };

  const setCity = (value: string) => {
    dispatch({ type: PropertyFiltersActionType.SET_CITY, payload: { value } });
  };

  const setState = (value: string) => {
    dispatch({ type: PropertyFiltersActionType.SET_STATE, payload: { value } });
  };

  const setQuery = (value: string) => {
    dispatch({ type: PropertyFiltersActionType.SET_QUERY, payload: { value } });
  };

  const setMinPrice = (value: number) => {
    dispatch({
      type: PropertyFiltersActionType.SET_MIN_PRICE,
      payload: { value },
    });
  };

  const setMaxPrice = (value: number) => {
    dispatch({
      type: PropertyFiltersActionType.SET_MAX_PRICE,
      payload: { value },
    });
  };

  const setBeds = (value: number) => {
    dispatch({ type: PropertyFiltersActionType.SET_BEDS, payload: { value } });
  };

  const setBaths = (value: number) => {
    dispatch({ type: PropertyFiltersActionType.SET_BATHS, payload: { value } });
  };

  const setHalfBaths = (value: number) => {
    dispatch({
      type: PropertyFiltersActionType.SET_HALF_BATHS,
      payload: { value },
    });
  };

  const setMinSquareFootage = (value: number) => {
    dispatch({
      type: PropertyFiltersActionType.SET_MIN_SQUARE_FOOTAGE,
      payload: { value },
    });
  };

  const setMaxSquareFootage = (value: number) => {
    dispatch({
      type: PropertyFiltersActionType.SET_MAX_SQUARE_FOOTAGE,
      payload: { value },
    });
  };

  const setMinLotSize = (value: number) => {
    dispatch({
      type: PropertyFiltersActionType.SET_MIN_LOT_SIZE,
      payload: { value },
    });
  };

  const setMaxLotSize = (value: number) => {
    dispatch({
      type: PropertyFiltersActionType.SET_MAX_LOT_SIZE,
      payload: { value },
    });
  };

  const setHeatingCooling = (value: string) => {
    dispatch({
      type: PropertyFiltersActionType.SET_HEATING_COOLING,
      payload: { value },
    });
  };

  const setMinYearBuilt = (value: number) => {
    dispatch({
      type: PropertyFiltersActionType.SET_MIN_YEAR_BUILT,
      payload: { value },
    });
  };

  const setMaxYearBuilt = (value: number) => {
    dispatch({
      type: PropertyFiltersActionType.SET_MAX_YEAR_BUILT,
      payload: { value },
    });
  };

  const setMinYearConverted = (value: number) => {
    dispatch({
      type: PropertyFiltersActionType.SET_MIN_YEAR_CONVERTED,
      payload: { value },
    });
  };

  const setMaxYearConverted = (value: number) => {
    dispatch({
      type: PropertyFiltersActionType.SET_MAX_YEAR_CONVERTED,
      payload: { value },
    });
  };

  const setParkingSpaces = (value: number) => {
    dispatch({
      type: PropertyFiltersActionType.SET_PARKING_SPACES,
      payload: { value },
    });
  };

  const setGarages = (value: number) => {
    dispatch({
      type: PropertyFiltersActionType.SET_GARAGES,
      payload: { value },
    });
  };

  const setOffStreetParking = (value: number) => {
    dispatch({
      type: PropertyFiltersActionType.SET_OFF_STREET_PARKING,
      payload: { value },
    });
  };

  const setPropertyType = (value: string) => {
    dispatch({
      type: PropertyFiltersActionType.SET_PROPERTY_TYPE,
      payload: { value },
    });
  };

  const setSortType = (value: string) => {
    dispatch({
      type: PropertyFiltersActionType.SET_SORT_TYPE,
      payload: { value },
    });
  };

  const setNewListing = (value: string) => {
    dispatch({
      type: PropertyFiltersActionType.SET_NEW_LISTING,
      payload: { value },
    });
  };

  const setTourCheckedValue = (value: string) => {
    dispatch({
      type: PropertyFiltersActionType.SET_TOUR_CHECKED_VALUE,
      payload: { value },
    });
  };

  const setName = (value: string) => {
    dispatch({
      type: PropertyFiltersActionType.SET_NAME,
      payload: { value },
    });
  };

  const setNotificationsCount = (value: number) => {
    dispatch({
      type: PropertyFiltersActionType.SET_NOTIFICATIONSCOUNT,
      payload: { value },
    });
  };

  const setCooling = (value: string) => {
    dispatch({
      type: PropertyFiltersActionType.SET_COOLING,
      payload: { value },
    });
  };

  const setActivatedPlan = (value: string) => {
    dispatch({
      type: PropertyFiltersActionType.SET_ACTIVATEDPLAN,
      payload: { value },
    });
  };

  const setSocketCall = (value: string) => {
    dispatch({
      type: PropertyFiltersActionType.SET_SOCKETCALL,
      payload: { value },
    });
  };

  const setMessageSocketCaller = (value: string) => {
    dispatch({
      type: PropertyFiltersActionType.SET_MESSAGESOCKETCALLER,
      payload: { value },
    });
  };

  const setCreateNotificationsInfo = (value: string) => {
    dispatch({
      type: PropertyFiltersActionType.SET_CREATENOTIFICATIONSINFO,
      payload: { value },
    });
  };
  const setReceiveNotificationsInfo = (value: string) => {
    dispatch({
      type: PropertyFiltersActionType.SET_RECEIVENOTIFICATIONSINFO,
      payload: { value },
    });
  };
  const setGetNotificationsUserId = (value: number) => {
    dispatch({
      type: PropertyFiltersActionType.SET_GETNOTIFICATIONSUSERID,
      payload: { value },
    });
  };

  const setGetNotificationsUserEmail = (value: string) => {
    dispatch({
      type: PropertyFiltersActionType.SET_GETNOTIFICATIONSUSEREMAIL,
      payload: { value },
    });
  };

  const setScheduledTourId = (value: number) => {
    dispatch({
      type: PropertyFiltersActionType.SET_SCHEDULED_TOUR_ID,
      payload: { value },
    });
  };

  const setSingleTourId = (value: number) => {
    dispatch({
      type: PropertyFiltersActionType.SET_SINGLE_TOUR_ID,
      payload: { value },
    });
  };

  const setUpcomingListingId = (value: number) => {
    dispatch({
      type: PropertyFiltersActionType.SET_UPCOMING_LISTING_ID,
      payload: { value },
    });
  };

  const setUpcomingPropId = (value: number) => {
    dispatch({
      type: PropertyFiltersActionType.SET_UPCOMING_PROP_ID,
      payload: { value },
    });
  };
  const setViewedFavPropId = (value: number) => {
    dispatch({
      type: PropertyFiltersActionType.SET_VIEWED_FAV_PROP_ID,
      payload: { value },
    });
  };
  const setViewedPropId = (value: number) => {
    dispatch({
      type: PropertyFiltersActionType.SET_VIEWED_PROP_ID,
      payload: { value },
    });
  };
  const setTourType = (value: string) => {
    dispatch({
      type: PropertyFiltersActionType.SET_TOUR_TYPE,
      payload: { value },
    });
  };


  return (
    <PropertyFiltersContext.Provider
      value={{
        propertyFiltersState,
        getFiltersQueryString,
        getFiltersForSavedSearch,
        clearFilters,
        setCity,
        setState,
        setQuery,
        setMinPrice,
        setMaxPrice,
        setBeds,
        setBaths,
        setHalfBaths,
        setMinSquareFootage,
        setMaxSquareFootage,
        setMinLotSize,
        setMaxLotSize,
        setHeatingCooling,
        setMinYearBuilt,
        setMaxYearBuilt,
        setMinYearConverted,
        setMaxYearConverted,
        setParkingSpaces,
        setGarages,
        setOffStreetParking,
        setPropertyType,
        setSortType,
        setName,
        setNotificationsCount,
        setCooling,
        setActivatedPlan,
        setSocketCall,
        setMessageSocketCaller,
        setCreateNotificationsInfo,
        setGetNotificationsUserId,
        setReceiveNotificationsInfo,
        setGetNotificationsUserEmail,
        setNewListing,
        setTourCheckedValue,
        setScheduledTourId,
        setSingleTourId,
        setUpcomingListingId,
        setUpcomingPropId,
        setViewedFavPropId,
        setViewedPropId,
        setTourType
      }}
    >
      {children}
    </PropertyFiltersContext.Provider>
  );
};

export default PropertyFiltersContext;
