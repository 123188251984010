import styled from "@emotion/styled";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Button, DatePicker, Popover, TimePicker } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Close from "../../assets/CalendarPopover/Button - Close.svg";
import { notification } from "../../components/notification";
import AgentContext from "../../contexts/AgentContext";
import UserContext, { UserType } from "../../contexts/UserContext";
import { getBuyerCalendarEvents } from "../../services/NavihomeService";
import dayjs from 'dayjs';

interface MyCalendarProps {
  setAllInputData?: (value: string) => void;
  buyerName?: string;
  calendarRef?: any;
  dayChange?: any;
  setStartTimeForTimeStamp?: any;
  setShowEventBuyerCal?: any;
  allInputData?: any;
  showEventAgentCal?: boolean;
  setShowEventAgentCal?: any;
  setIsOn?: (val: boolean) => void;
}
const CustomDatePicker: any = styled(DatePicker)`
  &.ant-picker {
    border-radius: 10px !important;
    border: 1px solid #004876;
    /* background-color: red !important; */
    width: 100%;
  }
  .ant-picker-input > input {
    margin-left: 1rem;
  }
`;
const HeaderWrapper = styled.div`
  .fc .fc-toolbar.fc-header-toolbar {
    background: #365073;
    margin-bottom: 0;
  }
`;

const CustomheadingThird = styled.p`
  color: #004876;
  font-weight: 800;
  padding-bottom: 10px;
`;
const ButtonWrapper = styled.div`
  .fc-button-primary {
    background: #365073 !important;
    border-color: #365073 !important;
  }
`;

const HederTitleWrapper = styled.div`
  .fc-toolbar-title {
    color: white !important;
  }
  .fc-icon {
    color: white !important;
  }
`;
const DayColorWrapper = styled.div`
  .fc-col-header .fc-col-header-cell-cushion {
    color: black;
  }
`;
const CustomTimePicker = styled(TimePicker.RangePicker)`
  &.ant-picker-range {
    padding-left: 20px !important;
    /* background-color: yellow !important; */
  }
  .ant-picker-suffix {
    position: absolute;
    left: 1%;
    /* background-color: red; */
    /* color: yellow; */
  }
  &.ant-picker {
    border-radius: 8px !important;
    border: 1px solid #004876;
    /* background-color: red !important; */
  }
`;
const CustomDiv = styled.div`
  border-radius: 20px;
  top: 50px;
  right: 50px;
  /* border: 4px solid blue; */
`;
const CustomDateDiv = styled.div`
  margin-bottom: 10px;
`;

const CustomPara = styled.p`
  font-weight: 400;
`;
const CustomPopover = styled(Popover)`
  position: absolute;
  top: 40rem !important;
  left: 35rem !important;
  /* background-color: red !important; */
  @media screen and (max-width: 650px) {
    top: 40rem !important;
    left: 15rem !important;
  }
`;

const MyCalendar: React.FC<MyCalendarProps> = ({
  setAllInputData,
  buyerName,
  calendarRef,
  dayChange,
  setStartTimeForTimeStamp,
  allInputData,
  setIsOn,
}): JSX.Element => {
  const [newEvent, setEvent] = useState([]);
  const [popOverVisible, setPopOverVisible] = useState(false);
  const [tempAllInputData, setTempAllInputData] = useState<Array<any>>([]);
  const [Stime, setStime] = useState<any>("");
  const [Etime, setEtime] = useState<any>("");
  const [Edate, setEdate] = useState<Date>(new Date());
  let [offCalHours, setOffCalHours] = useState({
    startTime: "0",
    endTime: "23:59",
  });
  const [agentAccountSettings, setAgentAccountSettings] = useState({
    markWeekends: [],
    workingHoursFrom: "",
    workingHoursTo: "",
  });


  const { userState } = useContext(UserContext);
  const { agentState } = useContext(AgentContext);
  const isResponsive = useMediaQuery({ maxWidth: 340 });

  let isOffHours = (date: Date) => {
    let day = date?.toDateString()?.split(" ")?.[0];
    if (agentAccountSettings?.markWeekends?.includes(day)) {
      return true;
    } else {
      return false;
    }
  };

  const getBuyerAvaialbility = async () => {
    let arr: any = [];
    let buyerId = -1;
    let token = "";

    if (userState?.userType === UserType.AGENT) {
      buyerId = agentState?.buyerId;
      token = userState?.agent?.token;
    } else {
      buyerId = userState?.buyer?.buyerId;
      token = userState?.buyer?.token;
    }
    const calendarEvent: any = await getBuyerCalendarEvents(
      buyerId,
      // agentState?.buyerId,
      // userState?.buyer.token,
      token
    );
    calendarEvent?.map((item: any) => {
      const stYear: any = new Date(item?.startDate).getFullYear();
      const stmonth: any = new Date(item?.startDate).getMonth();
      const stDay: any = dayjs(item?.startDate).date();

      let stHours: any = Number(item?.startTime.split(":")[0]);
      let mins: any = Number(item?.startTime.split(":")[1]);

      const endmonth: any = new Date(item?.endDate).getMonth();
      const endDay: any = dayjs(item?.endDate).date();

      let endHours: any = Number(item?.endTime.split(":")[0]);
      let endmins: any = Number(item?.endTime.split(":")[1]);

      if (item?.startTime?.split(" ")?.[1]?.toLowerCase() === "pm" && stHours < 12)
        stHours += 12;
      if (item?.endTime?.split(" ")?.[1]?.toLowerCase() === "pm" && endHours < 12)
        endHours += 12;
      if (mins === "0") mins = "00";
      if (endmins === "0") endmins = "00";
      if (
        stmonth == NaN ||
        stDay == NaN ||
        stHours == NaN ||
        mins == NaN ||
        endDay == NaN ||
        endmonth == NaN ||
        endHours == NaN ||
        endmins == NaN
      ) {
        console.warn("ERROR");
      } else {
        arr?.push({
          id: "Calendar Event",
          title: item?.title ?? "null",
          start: new Date(
            Number(stYear),
            Number(stmonth),
            Number(stDay),
            Number(stHours),
            Number(mins),
            0
          ),
          end: new Date(
            Number(stYear),
            Number(endmonth),
            Number(endDay),
            Number(endHours),
            Number(endmins),
            0
          ),
          recurstart: new Date(
            Number(stYear),
            Number(stmonth),
            Number(stDay),
            Number(stHours),
            Number(mins),
            0
          ),
          recurend: new Date(
            Number(stYear),
            Number(stmonth),
            Number(stDay),
            Number(stHours),
            Number(mins),
            0
          ),
          rStatus: item?.recurringStatus,
          eventId: item?.calendarId,
          Stime: item?.startTime,
          Sdate: item?.startDate,
          Etime: item?.endTime,
          Edate: item?.endDate,
          rEdate: item?.recuringEndDate,
          color: "green",
        });
      }
    });
    setTempAllInputData(arr);
    setEvent(arr);
  };

  const content = (
    <CustomDiv>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "5px",
        }}>
        <p>Tour Details</p>
        <img
          src={Close}
          style={{
            color: "#004876",
            cursor: "pointer",
          }}
          onClick={() => {
            setPopOverVisible(false);
            setEdate(null);
            setEtime(null);
            setAllInputData("");
            setEvent(tempAllInputData);
            setIsOn(true);
          }}
        />
      </div>
      <CustomDateDiv>
        <CustomPara>
          Date <sup>*</sup>
        </CustomPara>
        <CustomDatePicker
          value={Edate ? moment(Edate, "MM-DD-YYYY") : null}
          format={"MM-DD-YYYY"}
          onChange={(value: any) => {
            dateChange(value);
          }}
        />
      </CustomDateDiv>

      <div>
        <CustomPara>
          Time <sup>*</sup>
        </CustomPara>
        <CustomTimePicker
          value={[moment(Stime, "HH:mm:A"), moment(Etime, "HH:mm:A")]}
          format={"hh:mm A"}
          onChange={(value) => {
            timeOnChange(value);
          }}
        />
      </div>
    </CustomDiv>
  );

  const timeOnChange = (value: any) => {
    setStime(value ? moment(value?.[0]?._d, "HH:mm a") : null);
    setEtime(value ? moment(value?.[1]?._d, "HH:mm a") : null);
  };
  const dateChange = (value: any) => {
    let dateString = moment(value).format("MM-DD-YYYY");
    setEdate(value ? new Date(dateString) : null);
  };

  useEffect(() => {
    if (Stime && Etime && Edate) {
      let startDate = moment(Stime,"MM-DD-YYYY").format("MM-DD-YYYY");
      let currentDate = moment(moment(),"MM-DD-YYYY").format("MM-DD-YYYY");
      const date = moment(startDate,"MM-DD-YYYY").format("MM-DD-YYYY")

      if ( date >= currentDate) {
        let endDate = moment(Etime).format("MM-DD-YYYY");
        if (startDate !== endDate) {
          setAllInputData(
            moment(Stime).format("MM-DD-YYYY") +
            "," +
            moment(Stime).format("hh:mm a") +
            "-" +
            moment(Stime).endOf("day").format("hh:mm a")
          );
          setIsOn(false);
        } else {
          setAllInputData(
            moment(Edate).format("MM-DD-YYYY") +
            "," +
            moment(Stime).format("hh:mm a") +
            "-" +
            moment(Etime).format("hh:mm a")
          );
          setIsOn(false);
        }
        setStartTimeForTimeStamp(moment(Stime).format("HH:mm"));
      } else {
        return notification("Please select future date", "error");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Stime, Etime, Edate]);

  const getDayName = (dayIndex: number) => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
    return days[dayIndex];
  };
  const selectHandler = (args: any) => {
    let eDate: any = moment(args?.end, "MM-DD-YYYY");
    setEdate(eDate ? eDate : null);
    setStime(moment(args?.start, "HH:mm a"));
    setEtime(moment(args?.end, "HH:mm a"));
    setPopOverVisible(true);
  };
  useEffect(() => {
    getBuyerAvaialbility();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let year = Number(moment(allInputData, "MM-DD-YYYY").year());
    let month = Number(moment(allInputData, "MM-DD-YYYY").month());
    let date = Number(moment(allInputData, "MM-DD-YYYY").date());
    let sTime = allInputData?.split(",")?.[1]?.split("-")?.[0];
    let eTime = allInputData?.split(",")?.[1]?.split("-")?.[1];
    let startTimeHour = moment(sTime, "h:mm:ss A").format("HH");
    let startTimeMin = moment(sTime, "h:mm:ss A").format("mm");
    let endTimeHour = moment(eTime, "h:mm:ss A").format("HH");
    let endTimeMin = moment(eTime, "h:mm:ss A").format("mm");
    let arr: any = [];
    let data = {
      title: "",
      start: new Date(
        year,
        Number(month),
        Number(date),
        Number(startTimeHour),
        Number(startTimeMin),
        0
      ),
      end: new Date(
        year,
        Number(month),
        Number(date),
        Number(endTimeHour),
        Number(endTimeMin),
        0
      ),
    };
    arr?.push(data);

    if (allInputData?.length > 0) setEvent(arr);
    else setEvent(tempAllInputData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allInputData]);

  return (
    <div>
      <CustomheadingThird>{buyerName}</CustomheadingThird>

      <HeaderWrapper>
        <ButtonWrapper>
          <HederTitleWrapper>
            <DayColorWrapper>
              <div
                className="calendarButtons"
                style={{ position: "relative", marginTop: "15px" }}>
                <Button
                  className="viewButton myBuyerCalendarPrevButton "
                  onClick={() => {
                    // setStartToEnd(false);
                    dayChange("prev");
                  }}>
                  <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
                </Button>
                <Button
                  className="viewButton myBuyerCalendarNextButton"
                  onClick={() => {
                    // setStartToEnd(false);
                    dayChange("next");
                  }}>
                  <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                </Button>
                {/* </div> */}

                {/* <div></div>
                <div className="calendarButtonRight"></div> */}
              </div>
              <FullCalendar
                events={newEvent}
                editable={true}
                // disableDragging={true}
                eventStartEditable={false}
                eventColor="#ffcba4"
                // dragScroll={false}
                // droppable={false}
                eventResizableFromStart={true}
                height={400}
                selectMirror={true}
                select={selectHandler}
                selectable={true}
                dayMaxEventRows={true}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="timeGridWeek"
                dayHeaderFormat={{
                  weekday: "short",
                  day: "numeric",
                }}
                dayCellClassNames={(arg) => {
                  if (isOffHours(arg?.date)) {
                    return "calendarDayClass";
                  }
                }}
                allDaySlot={false}
                views={{
                  day: {
                    titleFormat: { month: "numeric" },
                  },
                }}
                headerToolbar={{
                  start: "",
                  center: "title",
                  end: "",
                }}
                titleFormat={{
                  month: "long",
                  year: "numeric",
                }}
                dayHeaderContent={(arg) => {
                  return (
                    <div
                      style={{
                        margin: "10px !important",
                        padding: "5px !important",
                      }}>
                      {arg?.view?.type !== "dayGridMonth" && (
                        <div>
                          <div
                            style={{
                              color: "#000000",
                            }}>
                            {getDayName(new Date(arg?.date)?.getUTCDay())}
                          </div>
                          <div
                            style={{
                              color: "#000000",
                            }}>
                            {new Date(arg?.date)?.getUTCDate()}
                            {/* /
                            {new Date(arg?.date)?.getMonth() + 1} */}
                          </div>
                        </div>
                      )}
                      {arg?.view?.type == "dayGridMonth" && (
                        <div>
                          <div
                            style={{
                              color: "#000000",
                            }}>
                            {getDayName(new Date(arg?.date)?.getUTCDay())}
                          </div>
                          <div
                            style={{
                              color: "#000000",
                            }}></div>
                        </div>
                      )}
                    </div>
                  );
                }}
                eventContent={(arg: any) => {
                  return (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        inset: "50px 0% -300px",
                        // EB752F
                        backgroundColor: `${arg?.backgroundColor === "green" ? "#C2D500" : ""
                          }`,
                        zIndex: 1,
                        padding: "0 1px",
                        whiteSpace: "normal",
                        borderRadius: "10px",
                        color: "#000",
                        textAlign: "center",
                      }}>
                      {arg?.backgroundColor === "green" && <>Free</>}
                    </div>
                  );
                }}
                ref={calendarRef}
                slotLaneClassNames={(arg) => {
                  let time = arg?.date?.toTimeString().slice(0, 2);
                  let isTime =
                    time < offCalHours?.startTime ||
                    time > offCalHours?.endTime;
                  let day = arg?.date?.toDateString()?.split(" ")?.[0];
                  if (
                    isTime &&
                    !agentAccountSettings?.markWeekends?.includes(day)
                  ) {
                    return ["offHours"];
                  } else {
                    return ["timeGrid"];
                  }
                }}
              />


              <CustomPopover
                // placement="top"
                // title="Tour Details"
                content={content}
                trigger="click"
                visible={popOverVisible}
                overlayInnerStyle={{
                  width: isResponsive ? "80%" : "90%",
                  border: "2px solid #004876",
                  borderRadius: "15px",
                }}></CustomPopover>
            </DayColorWrapper>
          </HederTitleWrapper>
        </ButtonWrapper>
      </HeaderWrapper>
      {/* {isDateAndTimeModal && (
        <DateAndTimeModal
          isShowing={isDateAndTimeModal}
          sTime={sTime}
          eTime={eTime}
          eDate={eDate}
          closeAction={calanderModalHandler}
          getModalData={function (
            values: any,           
            customEndDate: any
          ): void {
            throw new Error("Function not implemented.");
          }}
        />
      )} */}
    </div>
  );
};

export default MyCalendar;
