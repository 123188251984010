import { Col, Form, Row, Select } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import EmptyDashboardTab from '../../components/EmptyDashboardTab/emptyDashboardTab';
import MiniCalendar from '../../components/MiniCalendar';
import CustomSpinner from '../../components/spinner/CustomSpinner';
import UserContext from '../../contexts/UserContext';
import {
  getAllSellerUpcomingTours,
  getPropertyDetailsService,
  getSellProperty,
  getSellUpcommingTours,
  getViewPropertyCalendarByID,
  getViewPropertyCalendarByPropID,
} from '../../services/NavihomeService';
import StatisticsMain from '../propertyDetailsAgentPage/statistics/statisticsMain';
import UpcomingToursSummary from './upcomingTour';
import PropertyDetailsPage from './propertyDetailsPage';
import moment from 'moment';
import PropertyDetailsForSellerPage from './propertyDetailsForSeller';
import FindAnAgentConfirmModal from '../../modals/FindAnAgentConfirmModal';
import ToursSummarySeller from './SellerUpcomingTours';

enum DashboardTab {
  OVERVIEW = 'OVERVIEW',
  PROPERTY_DETAILS = 'Property Details',
  PROPERTY_STATISTICS = 'Property Statistics',
}
interface DashboardAgentPagePropsModel {
  isBuy: boolean;
  isSell: boolean;
}
type SizeType = Parameters<typeof Form>[0]['size'];
const DashboardBuyerPage: React.FC<DashboardAgentPagePropsModel> = ({
  isBuy,
  isSell,
}): JSX.Element => {
  const { userState } = useContext(UserContext);
  const [currentTab, setCurrentTab] = useState(DashboardTab.OVERVIEW);
  const [isBuyerLinked, setIsBuyerLinked] = useState<boolean>(false);
  const [isPropertyDetails, setIsPropertyDetails] = useState<boolean>(false);
  const [agentDetails, setAgentDetails] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sellMlsId, setSellMlsId] = useState(null);
  const [sellListingId, setSellListingId] = useState(null);
  const [sellerAgentId, setSellerAgentId] = useState(null);
  const [upcomingTour, setUpcomingTour] = useState([]);
  const [showComponent, setShowComponent] = useState<boolean>(false);
  const [isShowFindAnAgentConfirmModal, setIsShowFindAnAgentConfirmModal] =
    useState<boolean>(false);
  const [upcomingTourDate, setUpcomingTourDate] = useState<any>(null);
  const [upcomingStartEndDate, setUpcomingStartEndDate] = useState<any>({
    startDate: null,
    endDate: null
  })
  const [componentSize, setComponentSize] = useState<SizeType | 'default'>(
    'default'
  );
  const isMD = useMediaQuery({ maxWidth: 1070 });
  const isSm = useMediaQuery({ maxWidth: 576 });
  const { Option } = Select;

  const sellTitle =
    'In order to sell your home, ask your listing agent to add you to the listed property. By doing this, you can see how your property is performing, and keep tab of all showings of your home.';

  const getProperty = async (mlsId: number) => {
    setIsLoading(true)

    if (mlsId) {
      setSellMlsId(mlsId);
      let token = userState?.buyer?.token
      let currDate = moment().format('YYYY-MM-DD');
      let newArr: any = [];

      const newToursData: any = await getAllSellerUpcomingTours(mlsId, currDate, token, 'Buyer');
      const newTourPropCal: any = await getViewPropertyCalendarByPropID(mlsId, currDate, token, 'Buyer');

      newArr = [...newToursData, ...newTourPropCal];

      const sortedTours: Array<any> = newArr?.sort((a: any, b: any) => +new Date(a?.startDate) - +new Date(b?.startDate))

      let upcomingTour = [];
      if (newArr && newArr?.length > 0) {
        for (let i = 0; i < newArr?.length; i++) {
          let greet = (moment(newArr[i]?.startTime, 'hh:mm A').format('hh:mm A')?.split(' ')?.[1]) === 'AM' ? 'Morning' : 'Evening', date, dayOfWeek;
          const stDay: number = new Date(newArr[i]?.startDate).getUTCDate();
          const stMonth: number = new Date(newArr[i]?.startDate).getMonth();
          const stYear: number = new Date(newArr[i]?.startDate).getFullYear();

          const tempDate: string = `${stMonth + 1}-${stDay}-${stYear}`;

          // console.log("Given date: ", tempDate);


          date = moment(tempDate, "MM-DD-YYYY").format("LL");
          dayOfWeek = moment(tempDate, "MM-DD-YYYY").format("dddd");
          const dataobj = {
            // date: moment(newArr[i]?.startDate).format('LL') || 'N/A',
            // dayOfWeek: moment(newArr[i]?.startDate).format('dddd') || 'N/A',

            date: date || 'N/A',
            dayOfWeek: dayOfWeek || 'N/A',

            time: `${moment(newArr[i]?.startTime, 'hh:mm A').format('hh:mm A')}-${moment(newArr[i]?.endTime, 'hh:mm A').format('hh:mm A')}` || 'N/A',
            timeOfDay: greet ?? null,
            statusPrimary: newArr[i]?.isAccepted ? 'Accepted' : 'Requested',
          };

          upcomingTour.push(dataobj);
        }
      }

      setUpcomingTour(upcomingTour);
      setIsLoading(false)
    }
  };

  const getSellerProperty = async () => {
    const { mlsId, listingId, agentId } = await getSellProperty(
      userState?.buyer?.Email,
      userState?.buyer?.token
    );
    if (mlsId) {
      setSellMlsId(mlsId);
      setSellListingId(listingId);
      setSellerAgentId(agentId);
      getProperty(mlsId);
      const propertyDetails: any = await getPropertyDetailsService(mlsId);

      if (propertyDetails?.length === 0) {
        setIsPropertyDetails(true);
      } else {
        setIsPropertyDetails(false);
      }
    }

    return;
  };

  useEffect(() => {
    checkIBuyersLinked();
    getSellerProperty();
    // getBuyerInfo();
    const toRef = setTimeout(() => {
      setShowComponent(true);
      clearTimeout(toRef);
    }, 1000);
  }, []);
  const renderRemider = () => {
    return (
      <div style={{ minHeight: '57.3rem' }}>
        <EmptyDashboardTab
          title={sellTitle}
          isLoggedInSell={true}
          isButton={true}
          buttonText="NOTIFICATIONS"
        />
      </div>
    );
  };

  // const generateGreetings = (dateTime: any) => {
  //   let currentHour: any = moment(dateTime).format('HH');

  //   if (currentHour >= 3 && currentHour < 12) {
  //     return 'Morning';
  //   } else if (currentHour >= 12 && currentHour < 15) {
  //     return 'Afternoon';
  //   } else if (currentHour >= 15 && currentHour < 20) {
  //     return 'Evening';
  //   } else if (currentHour >= 20 && currentHour < 3) {
  //     return 'Night';
  //   } else {
  //     return 'Morning';
  //   }
  // };

  const removerSellerHandler = async () => {
    setIsShowFindAnAgentConfirmModal(!isShowFindAnAgentConfirmModal);
  };

  const confirmModalHandler = () => {
    setIsShowFindAnAgentConfirmModal(!isShowFindAnAgentConfirmModal);
  };
  const checkIBuyersLinked = () => {
    try {
      setIsBuyerLinked(userState?.buyer?.isLinked);
    } catch (error) {
      console.log(error);
    }
  };

  const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size);
  };

  // On Tab Change
  const onTabChange = (value: string) => {
    switch (value) {
      case 'overview':
        selectOverview();
        break;
      case 'propertydetails':
        selectPropertyDetails();
        break;
      case 'propertystatistics':
        selectPropertyStatistics();
        break;
      default:
        return;
    }
  };

  const selectOverview = () => {
    setCurrentTab(DashboardTab.OVERVIEW);
  };

  const selectPropertyDetails = () => {
    setCurrentTab(DashboardTab.PROPERTY_DETAILS);
  };

  const selectPropertyStatistics = () => {
    setCurrentTab(DashboardTab.PROPERTY_STATISTICS);
  };

  const renderTitle = () => {
    var pageTitle = '';
    switch (currentTab) {
      case DashboardTab?.OVERVIEW:
        pageTitle = 'Property Overview';
        break;
      case DashboardTab?.PROPERTY_DETAILS:
        pageTitle = 'Property Details';
        break;
      case DashboardTab?.PROPERTY_STATISTICS:
        pageTitle = 'Property Statistics';
        break;
      default:
        break;
    }
    return <h3 className="dashboard-buyer-page__title">{`${pageTitle}`}</h3>;
  };

  const renderTabs = () => {
    return (
      <div className="dashboard-buyer-page__tabs">
        {isMD ? (
          <>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 24 }}
              layout="horizontal"
              initialValues={{ size: componentSize }}
              onValuesChange={onFormLayoutChange}
              size={componentSize as SizeType}
            >
              <Form.Item>
                <Select onChange={onTabChange} value={currentTab}>
                  <Option value="overview">Overview</Option>
                  <Option value="propertydetails">Property Details</Option>
                  <Option value="propertystatistics">
                    Property Statistics
                  </Option>
                </Select>
              </Form.Item>
            </Form>
          </>
        ) : (
          <>
            <div
              className={`dashboard-buyer-page__tab ${currentTab === DashboardTab.OVERVIEW &&
                'dashboard-buyer-page__tab--selected'
                }`}
              onClick={selectOverview}
            >
              <p>Overview</p>
            </div>
            <div
              className={`dashboard-buyer-page__tab ${currentTab === DashboardTab.PROPERTY_DETAILS &&
                'dashboard-buyer-page__tab--selected'
                }`}
              onClick={selectPropertyDetails}
            >
              <p>Property Details</p>
            </div>
            <div
              className={`dashboard-buyer-page__tab ${currentTab === DashboardTab.PROPERTY_STATISTICS &&
                'dashboard-buyer-page__tab--selected'
                }`}
              onClick={selectPropertyStatistics}
            >
              <p>Property Statistics</p>
            </div>
          </>
        )}
      </div>
    );
  };

  const renderOverviewContent = () => {
    return (
      <div className="dashboard-buyer-page__overview">
        <div className="dashboard-buyer-page__overview__cover">
          <div className="dashboard-buyer-page__overview__cover-content">
            <h1>
              Sell this home with <br />
              Confidence
            </h1>
          </div>
        </div>

        <div className="dashboard-buyer-page__overview__calendar-container">
          <h3>Calendar</h3>
          <div className="dashboard-buyer-page__overview__calendar-card">
            <MiniCalendar
              setUpcomingTourDate={setUpcomingTourDate}
              setUpcomingStartEndDate={setUpcomingStartEndDate}
              month={''}
            />
          </div>
        </div>
        <div className="dashboard-buyer-page__overview__upcoming-tours">
          <h3 className="h3-after-display-none">Upcoming Showings</h3>
          <div className="dashboard-buyer-page__overview__upcoming-tours-table123">
            {/* <UpcomingToursSummary
              tours={upcomingTour}
              isBuyerLinked={userState?.buyer?.isLinked}
              upcomingTourDate={upcomingTourDate}
              upcomingStartEndDate={upcomingStartEndDate}
            /> */}
            <ToursSummarySeller
              tours={upcomingTour}
              isBuyerLinked={userState?.buyer?.isLinked}
              upcomingTourDate={upcomingTourDate}
              upcomingStartEndDate={upcomingStartEndDate}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderPropertyDetailsContent = () => {
    return (
      <>
        {sellMlsId && (
          <PropertyDetailsPage mlsId={sellMlsId || null} propertyType={null} />
        )}
      </>
    );
  };
  const renderPropertyStatisticsContent = () => {
    return (
      <div>
        {sellMlsId && (
          <StatisticsMain
            mlsId={sellMlsId || null}
            token={userState?.buyer?.token}
          />
        )}
      </div>
    );
  };
  //   setIsLoading(true);
  const renderTabContent = () => {
    switch (currentTab) {
      case DashboardTab.OVERVIEW:
        return renderOverviewContent();
      case DashboardTab.PROPERTY_DETAILS:
        return renderPropertyDetailsContent();
      case DashboardTab.PROPERTY_STATISTICS:
        return renderPropertyStatisticsContent();
      default:
        return '';
    }
  };
  //   setIsLoading(false);
  return (
    <div className="dashboard-buyer-page123">
      {isBuy === undefined ? (
        <EmptyDashboardTab isHome={true} />
      ) : (
        <>
          {isSell ? (
            sellMlsId ? (
              <>
                {renderTitle()}
                <Row style={isSm ? { display: 'block' } : {}}>
                  <Col sm={24} md={24} lg={5} xl={5}>
                    {renderTabs()}
                  </Col>
                  <Col sm={24} md={24} lg={19} xl={19}>
                    {isPropertyDetails ? (
                      <div
                        style={
                          isMD
                            ? {
                              width: '100%',
                              textAlign: 'center',
                              paddingTop: '20px',
                            }
                            : {
                              width: '100%',
                              textAlign: 'center',
                              paddingTop: '50px',
                            }
                        }
                      >
                        <h2>This property in no longer available!</h2>
                        <p style={{ color: "blue" }} onClick={removerSellerHandler}>
                          Do you want to remove it?
                        </p>
                      </div>
                    ) : (
                      <>
                        {currentTab === DashboardTab.PROPERTY_DETAILS ? (
                          <PropertyDetailsForSellerPage
                            mlsId={sellMlsId}
                            propertyType={null}
                          />
                        ) : (
                          renderTabContent()
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              showComponent && renderRemider()
            )
          ) : (
            <>
              {renderTitle()}
              <div className="dashboard-buyer-page__container">
                {renderTabs()}
                <div className="dashboard-buyer-page__current-tab-content">
                  {renderTabContent()}
                  {isLoading && <CustomSpinner />}
                </div>
              </div>
            </>
          )}
        </>
      )}
      {isShowFindAnAgentConfirmModal && (
        <FindAnAgentConfirmModal
          isShowing={isShowFindAnAgentConfirmModal}
          closeAction={confirmModalHandler}
          text="Are you sure to remove this property and listing agent?"
          yesText="Yes"
          noText="No"
          agentId={sellerAgentId}
          type="seller"
          propertyId={sellMlsId}
          pageType='DashboardBuyerPage'
        />
      )}
    </div>
  );
};
export default DashboardBuyerPage;
