import { Fragment, useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PropertyCard from "../../components/PropertyCard";
import SearchField from "../../components/SearchField";
import CustomSpinner from "../../components/spinner/CustomSpinner";
import PropertyFiltersContext from "../../contexts/PropertyFiltersContext";
import {
  Listing,
  getPropertiesAffordableService,
  getPropertiesLuxuryService,
  getPropertiesNewListingsService,
  getPropertiesOpenHousesService,
} from "../../services/NavihomeService";

const LandingBuyerPageBuy = () => {
  const history = useHistory();
  const [openHouses, setOpenHouses] = useState<Listing[]>([]);
  const [newListings, setNewListings] = useState<Listing[]>([]);
  const [affordableHomes, setAffordableHomes] = useState<Listing[]>([]);
  const [luxutyHomes, setLuxuryHomes] = useState<Listing[]>([]);
  const [isLoader, setIsLoader] = useState(false);
  const cityNameOpen= "";
  const cityNameNew= "Boston";
  const cityNameAff= "Springfield";
  const cityNameLux= "Medford";
  const { setQuery } = useContext(PropertyFiltersContext);

  const searchAction = (query: string) => {
    setQuery(query);
    history.push("/properties?query=" + query);
  };

  const getOpenHouses = async () => {
    setIsLoader(true);
    try {
      const open_houses = await getPropertiesOpenHousesService();
      if (open_houses?.length > 3) {
        setOpenHouses(open_houses?.slice(0, 3));
        setIsLoader(false);
        return;
      }

      setOpenHouses(open_houses);
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      console.log(error);
    }
  };

  const getNewListings = async () => {
    setIsLoader(true);
    try {
      const listings = await getPropertiesNewListingsService("Boston");
      if (listings?.length > 3) {
        setNewListings(listings?.slice(0, 3));
        setIsLoader(false);
        return;
      }
      setNewListings(listings);
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      console.log(error);
    }
  };

  const getAffordableHomes = async () => {
    setIsLoader(true);
    try {
      const listings: any = await getPropertiesAffordableService("springfield"); 
      
      if (listings?.length > 3) {
        setAffordableHomes(listings?.slice(0, 3));
        setIsLoader(false);
        return;
      }

      setAffordableHomes(listings);
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      console.log(error);
    }
  };

  const getLuxuryHomes = async () => {
    setIsLoader(true);
    try {
      const listings = await getPropertiesLuxuryService("MedFord");
      if (listings?.length > 3) {
        setLuxuryHomes(listings?.slice(0, 3));
        setIsLoader(false);
        return;
      }
      setLuxuryHomes(listings);
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getOpenHouses();
    getNewListings();
    getAffordableHomes();
    getLuxuryHomes();
  }, []);

  const renderOpenHouses = () => {
    const cards = openHouses?.map((listing: any) => {
      return (
        <PropertyCard
          property_type="open_house"
          propertyId={listing?.mlsId ?? 0}
          propertyDetails={listing ? listing : " "}
          image={listing?.photos?.length > 0 ? listing?.photos[0] : ""}
          price={listing?.listPrice}
          type={listing?.property?.type}
          beds={listing?.property?.bedrooms}
          baths={listing?.property?.bathsFull}
          squareFootage={listing?.property?.area}
          mlsId={listing?.listingId}
          latitude={listing?.geo?.lat ?? 0}
          longitude={listing?.geo?.lng ?? 0}
          country="United State"
          streetNumber={Number(listing?.address?.streetNumber) ?? 0}
          streetName={listing?.address?.streetName ?? "N/A"}
          city={listing?.address?.city ?? "N/A"}
          state={listing?.address?.state ?? "N/A"}
          postalCode={listing?.address?.postalCode ?? "N/A"}
          bathsHalf={listing?.property?.bathsHalf ?? 0}
          openHouse="open_house"
          feature=""
          openHouseData={listing?.openHouseId ?? ""}
          openHouseStartTime={listing?.startTime ?? ""}
          openHouseEndTime={listing?.endTime ?? ""}
          listing={listing?.listingId ?? ""}
          selectFavoriteProperties={() => {
          }}
          selectRequestedShowings={() => {
          }}
          selectPropertyComments={() => {
          }}
          setTourPropPage={() => {
          }}
          openHouseKey={listing?.openHouseKey ?? null}
        />
      );
    });
    return <Fragment>{cards}</Fragment>;
  };

  const renderNewListings = () => {
    const cards = newListings?.map((listing: any) => {
      return (
        <PropertyCard
          property_type="new_listing"
          propertyId={listing?.mlsId ?? 0}
          propertyDetails={listing ? listing : " "}
          image={listing?.photos?.length > 0 ? listing?.photos[0] : ""}
          price={listing?.listPrice}
          type={listing?.property?.type}
          beds={listing?.property?.bedrooms}
          baths={listing?.property?.bathsFull}
          squareFootage={listing?.property?.area}
          mlsId={listing?.listingId}
          latitude={listing?.geo?.lat ?? 0}
          longitude={listing?.geo?.lng ?? 0}
          country={"United State" ?? "N/A"}
          streetNumber={Number(listing?.address?.streetNumber) ?? 0}
          streetName={listing?.address?.streetName ?? "N/A"}
          city={listing?.address?.city ?? "N/A"}
          state={listing?.address?.state ?? "N/A"}
          postalCode={listing?.address?.postalCode ?? "N/A"}
          bathsHalf={listing?.property?.bathsHalf ?? 0}
          openHouse="new_listing"
          feature=""
          selectFavoriteProperties={() => {
          }}
          selectRequestedShowings={() => {
          }}
          selectPropertyComments={() => {
          }}
          setTourPropPage={() => {
          }}
        />
      );
    });
    return <Fragment>{cards}</Fragment>;
  };

  const renderAffordableHomes = () => {
    const cards = affordableHomes?.map((listing: any) => {
      return (
        <PropertyCard
          property_type="affordable_homes"
          propertyDetails={listing ? listing : " "}
          propertyId={listing?.mlsId ?? 0}
          image={listing?.photos?.length > 0 ? listing?.photos[0] : ""}
          price={listing?.listPrice}
          type={listing?.property?.type}
          beds={listing?.property?.bedrooms}
          baths={listing?.property?.bathsFull}
          squareFootage={listing?.property?.area}
          mlsId={listing?.listingId}
          latitude={listing?.geo?.lat ?? 0}
          longitude={listing?.geo?.lng ?? 0}
          country={"United State" ?? "N/A"}
          streetNumber={Number(listing?.address?.streetNumber) ?? 0}
          streetName={listing?.address?.streetName ?? "N/A"}
          city={listing?.address?.city ?? "N/A"}
          state={listing?.address?.state ?? "N/A"}
          postalCode={listing?.address?.postalCode ?? "N/A"}
          bathsHalf={listing?.property?.bathsHalf ?? 0}
          openHouse="new_listing"
          feature=""
          selectFavoriteProperties={() => {
          }}
          selectRequestedShowings={() => {
          }}
          selectPropertyComments={() => {
          }}
          setTourPropPage={() => {
          }}
        />
      );
    });
    return <Fragment>{cards}</Fragment>;
  };

  const renderLuxuryHomes = () => {
    const cards = luxutyHomes?.map((listing: any) => {
      return (
        <PropertyCard
          property_type="luxury_homes"
          propertyId={listing?.mlsId}
          propertyDetails={listing ? listing : " "}
          image={listing?.photos?.[0]}
          price={listing?.listPrice}
          type={listing?.property?.type}
          beds={listing?.property?.bedrooms}
          baths={
            listing?.property?.bathsFull + 0.5 * listing?.property?.bathsHalf
          }
          squareFootage={listing?.property?.area}
          feature=""
          selectFavoriteProperties={() => {
          }}
          selectRequestedShowings={() => {
          }}
          selectPropertyComments={() => {
          }}
          setTourPropPage={() => {
          }}
          country="USA"
          state={listing?.address?.state ?? ""}
          city={listing?.address?.city ?? ""}
          streetName={listing?.address?.streetName ?? ""}
          streetNumber={listing?.address?.streetNumber ?? 0}
          postalCode={listing?.address?.postalCode ?? ""}
          bathsHalf={listing?.property?.bathsHalf ?? 0.5}
          openHouse="luxury_homes"
          mlsId={listing?.listingId}
          latitude={listing?.geo?.lat}
          longitude={listing?.geo?.lng}
        />
      );
    });
    return <Fragment>{cards}</Fragment>;
  };

  return (
    <>
      <div className="landing-buyer-page">
        {isLoader && <CustomSpinner />}
        <div className="landing-buyer-page__cover">
          <div className="landing-buyer-page__discover">
            <h1 className="u-margin-bottom-large">Discover your dream home</h1>
            <SearchField
              placeholder="Enter neighbourhood, city, ZIP code"
              searchAction={searchAction}
            />
          </div>
        </div>
      </div>
      <div className="landing-buyer-page__previews__container">
        <div className="landing-buyer-page__preview">
          <div className="landing-buyer-page__preview__header">
            <h3>Open Houses</h3>
            <Link
              to={`/properties?city=${cityNameOpen}&type=open_house`}
              className="text-link"
            >
              <span>View all</span>
            </Link>
          </div>
          <div className="landing-buyer-page__preview__content">
            {renderOpenHouses()}
          </div>
        </div>
        <div className="landing-buyer-page__preview">
          <div className="landing-buyer-page__preview__header">
            {newListings && <h3>New Listings in {cityNameNew ?? " "}</h3>}
            <Link to={`/properties?city=${cityNameNew}`} className="text-link">
              <span
                onClick={() => {
                  setQuery(cityNameNew);
                }}
              >
                View all
              </span>
            </Link>
          </div>
          <div className="landing-buyer-page__preview__content">
            {renderNewListings()}
          </div>

          <div className="landing-buyer-page__preview">
            <div className="landing-buyer-page__preview__header">
              {affordableHomes && (
                <h3>Affordable Homes in {cityNameAff ?? " "}</h3>
              )}
              <Link
                to={`/properties?city=${cityNameAff}`}
                className="text-link"
              >
                <span
                  onClick={() => {
                    setQuery(cityNameAff);
                  }}
                >
                  View all
                </span>
              </Link>
            </div>
            <div className="landing-buyer-page__preview__content">
              {renderAffordableHomes()}
            </div>

            <div className="landing-buyer-page__preview">
              <div className="landing-buyer-page__preview__header">
                {luxutyHomes && <h3>Luxury Homes in {cityNameLux ?? " "}</h3>}
                <Link
                  to={`/properties?city=${cityNameLux}`}
                  className="text-link"
                >
                  <span
                    onClick={() => {
                      setQuery(cityNameLux);
                    }}
                  >
                    View all
                  </span>
                </Link>
              </div>
              <div className="landing-buyer-page__preview__content">
                {renderLuxuryHomes()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingBuyerPageBuy;
