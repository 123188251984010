/* eslint-disable jsx-a11y/alt-text */
import { Fragment, useContext, useState } from "react";
import Button, { ButtonType } from "../../components/Button";
import { Row, Col, Modal } from "antd";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import addProperty from "../../assets/images/for listing sale.png";
import styled from "@emotion/styled";
import UserContext from "../../contexts/UserContext";
import { createSellerProperties } from "../../services/NavihomeService";
import CustomSpinner from "../spinner/CustomSpinner";

interface IAddSellerPropertyProps {
  isOpenModal: boolean;
  closeAction: () => void;
  listingId: number;
  agentMlsId: number;
  propertyId: number;
  setOpenInviteSellerModal: any;
  setHomePage: any;
  sellerProperties: any;
  getAllListedProperty: () => void;
  city: string;
}
const CustomCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
`;
const CustomRegHeading = styled.h1`
  font-size: 22px;
  @media screen and (max-width: 420px) {
    font-size: 19px;
  }
`;
const AddSellerPropertyModal: React.FC<IAddSellerPropertyProps> = ({
  isOpenModal,
  closeAction,
  propertyId,
  listingId,
  agentMlsId,
  setOpenInviteSellerModal,
  setHomePage,
  getAllListedProperty,
  sellerProperties,
  city,
}) => {
  const { userState } = useContext(UserContext);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleCancel = () => {
    closeAction();
    setHomePage(true);
  };
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const handleAddProperty = async () => {
    try {
      setIsLoading(true);
      const payload = {
        agentId: userState?.agent?.agentId,
        mlsId: propertyId,
        listingId: listingId,
        agentmlsId: agentMlsId,
        sellerEmail: "",
        name: "",
        message: "",
        propertyDetails: Object?.keys(sellerProperties)?.length > 0 ? JSON.stringify(sellerProperties) : {},
        agentName: userState?.agent?.name,
        agentEmail: userState?.agent?.email,
        city: city,
      };

      const response = await createSellerProperties(
        payload,
        userState?.agent.token
      );

      if (response) {
        setOpenInviteSellerModal(true);
        getAllListedProperty();
        closeAction();
        setIsLoading(false)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false);
      console.log(error);

    }
  };

  return (
    <Fragment>
      <Modal
        visible={isOpenModal}
        footer={null}
        width={isMobile ? 500 : 850}
        onCancel={handleCancel}
      >
        <div className="homepage-agent-page-listing__listed-container">
        {isLoading && <CustomSpinner />}
          <CustomRegHeading>
            Add this property to your{" "}
            <span style={{ borderBottom: "3px solid #C2D500" }}>
              listings?
            </span>
          </CustomRegHeading>
        </div>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="seller-image">
              <img src={addProperty} title="add" />
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="seller-information-modal">
              Are you sure you want to add this property to your listings? By doing so, you are consenting to our terms and conditions for listing properties. When you list your property on Navihome, you can:
            </div>
            <div className="seller-information-modal">
              -Set property availability schedule
            </div>
            <div className="seller-information-modal">
              -Set property viewing preferences
            </div>
            <div className="seller-information-modal">
              -Invite the seller to connect in the app to view and track property statistics
            </div>
            <div className="seller-information-modal">
              -View and connect with agents of buyers who request showings for the listing
            </div>
            <div className="seller-information-modal">
              Would you like to add it to your set of seller properties?
            </div>
          </Col>
        </Row>
        <Row>
          <CustomCol xs={24}>
            <Button
              type={ButtonType.WHITE}
              text={"CANCEL"}
              clickHandler={handleCancel}
              className="add-seller-property-modal-button-cancel1"
            />
            <Button
              type={ButtonType.FIND_AN_AGENT}
              text={"ADD PROPERTY"}
              className="find-an-agent-modal-confirm-find-an-agent-button"
              clickHandler={handleAddProperty}
            />
          </CustomCol>
        </Row>
      </Modal>
    </Fragment>
  );
};
export default AddSellerPropertyModal;