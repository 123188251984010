import { useState, useContext, useRef } from "react";
import UserContext, { UserType } from "../contexts/UserContext";
import buttonClose from "../assets/images/button-close.svg";
import MaskGroup from "../assets/find-an-agent-page/Mask Group 1.png";
import TextField, {
  TextFieldType,
  TextFieldIconType,
} from "../components/TextField";
import Button, { ButtonType } from "../components/Button";
import Checkbox, { CheckboxType } from "../components/Checkbox";
import { useHistory } from "react-router";
import {
  sendForgotPasswordForBuyerService,
  sendForgotPasswordForAgentService,
  registerBuyerService,
} from "../services/NavihomeService";
import { useMediaQuery } from "react-responsive";
import { notification } from "../components/notification";
import styled from "@emotion/styled";
import CustomSpinner from "../components/spinner/CustomSpinner";
import validator from "email-validator";

const CustomSpan = styled.span`
  text-decoration: underline;
  color: #eb752f;
`;

const CustomModalTitle = styled.h1`
  color: #004876;
  font-weight: bold;
  margin: 0px;
`;

interface QuickBuyerRegistrationModalPropsModel {
  isShowing: boolean;
  name: string;
  email: string;
  setIsLoading: any;
  customMessage: string;
  closeAction: () => void;
  unlinkThisBuyer: () => void;
  setCustomMessage?:(val: string)=> void;
}

const RemoveBuyerModal: React.FC<QuickBuyerRegistrationModalPropsModel> = ({
  isShowing,
  name,
  email,
  setIsLoading,
  closeAction,
  customMessage,
  setCustomMessage,
  unlinkThisBuyer,
}): JSX.Element => {
  const { userState, logIn, registerBuyer } = useContext(UserContext);
  const history = useHistory();
  const [buyerName, setName] = useState<string>("");
  const [buyerEmail, setEmail] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);

  const isMobile = useMediaQuery({ maxWidth: 500 });

  const removeBuyerHandler = () => {
    if (!validator.validate(email)) {
      return notification("Please enter Valid Email.", "error");
    }
    if (customMessage?.length <= 0) {
      return notification("Please Enter Message!", "error");
    }
    setIsLoading(true);
    unlinkThisBuyer();
    setIsError(false);
    // return notification("This Buyer is Successfully removed", "success");
  };

  const modalClassName = isShowing
    ? "quick-registration-modal u-visible"
    : "quick-registration-modal u-hidden";
  const cardClassName = isShowing
    ? "quick-registration-modal__card animation__pop-in"
    : "quick-registration-modal__card animation__pop-out";

  return (
    <div className={modalClassName}>
      <div className={cardClassName}>
        <div className="quick-registration-modal__row">
          <CustomModalTitle>
            Remove
            <span style={{ borderBottom: "4px solid #C2D500" }}> Buyer?</span>
          </CustomModalTitle>

          <img
            className="quick-registration-modal__close-button"
            src={buttonClose}
            onClick={closeAction}
            alt="Close button"
          />
        </div>
        <div>
          {/* <div className="quick-registration-logo"> */}
          <p className="quick-registration-modal__subtitle">
            Are you sure you want to remove the buyer?
          </p>
          {isError && <p style={{ color: "red" }}>Please Enter Message!</p>}
          {/* </div> */}
        </div>
        <div className="quick-registration-modal__column">
          {userState.loginError && (
            <p className="quick-registration-modal__error-message">
              {userState.loginError}
            </p>
          )}
          <TextField
            value={name}
            setValue={setName}
            placeholder="Jane Doe"
            label="Name"
            type={TextFieldType.ICON_AND_LABEL}
            icon={TextFieldIconType.USER}
            isRequired={true}
            isDisabled={true}
            width={isMobile ? undefined : 38.4}
            className="remove-buyer-modal__name-field"
          />
          <TextField
            value={email}
            setValue={setEmail}
            placeholder="Enter Your Email"
            label="Email"
            type={TextFieldType.ICON_AND_LABEL}
            icon={TextFieldIconType.EMAIL}
            isRequired={true}
            isDisabled={true}
            width={isMobile ? 26.4 : 38.4}
            className="remove-buyer-modal__name-field"
          />
          <TextField
            value={customMessage}
            setValue={setCustomMessage}
            placeholder="Hello, since you mentioned that you no longer need my services, I am removing you from my list of buyers. If you're buying a home again, you know who to reach out to!"
            label="Message to buyer"
            type={TextFieldType.BIG}
            icon={TextFieldIconType.EMAIL}
            isRequired={true}
            width={isMobile ? undefined : 38.4}
            className="remove-buyer-modal__name-field"
          />
          <Button
            type={ButtonType.PRIMARY}
            text="REMOVE BUYER"
            clickHandler={removeBuyerHandler}
            width={24.5}
            className="remove-buyer-modal__button"
          />
        </div>
      </div>
    </div>
  );
};

export default RemoveBuyerModal;
