import { useState, useContext, useRef, useEffect } from "react";
import UserContext, { UserType } from "../contexts/UserContext";
import buttonClose from "../assets/images/button-close.svg";
import MaskGroup from "../assets/find-an-agent-page/Mask Group 1.png";
import TextField, {
  TextFieldType,
  TextFieldIconType,
} from "../components/TextField";
import Button, { ButtonType } from "../components/Button";
import Checkbox, { CheckboxType } from "../components/Checkbox";
import { useHistory } from "react-router";
import {
  sendForgotPasswordForBuyerService,
  sendForgotPasswordForAgentService,
  registerBuyerService,
  resetForgotPasswordForBuyerService,
  resetForgotPasswordForAgentService,
  getBuyerInfoService,
} from "../services/NavihomeService";
import { useMediaQuery } from "react-responsive";
import { notification } from "../components/notification";
import styled from "@emotion/styled";
import CustomSpinner from "../components/spinner/CustomSpinner";
import PropertyFiltersContext from "../contexts/PropertyFiltersContext";

const CustomP = styled.p`
  color: #eb752f;
`;
const CustomRegHeading = styled.h1`
font-size: 24px;
@media screen and (max-width: 600px) {
  font-size: 20px;
  margin-bottom: 32px;
}
`;
interface ChangePasswordModalPropsModel {
  isShowing: boolean;
  email: string;
  password: string;
  closeAction: () => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalPropsModel> = ({
  isShowing,
  email,
  password,
  closeAction,
}): JSX.Element => {
  const { userState, logIn, changeUserType, logOut } = useContext(UserContext);
  const {  clearFilters } = useContext(PropertyFiltersContext);
  const history = useHistory();
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  // const [email, setEmail] = useState<string>('');
  const [isPasswordRequiredLength, setIsPasswordRequiredLength] =
    useState(false);
  const [doesPasswordContainUppercase, setDoesPasswordContainUppercase] =
    useState(false);
  const [doesPasswordContainLowercase, setDoesPasswordContainLowercase] =
    useState(false);
  const [doesPasswordContainNumer, setDoesPasswordContainNumber] =
    useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const isMobile = useMediaQuery({ maxWidth: 500 });

  const handleRegister = async () => {
    if (currentPassword === "") {
      return notification("Please enter your current password.", "error");
    }

    if (currentPassword !== password.toString()) {
      return notification("Current password wrong", "error");
    }
    if (newPassword === currentPassword) {
      return notification(
        "You entered old password. Please enter new password",
        "error"
      );
    }
    if (newPassword === "") {
      return notification("Please enter password.", "error");
    }
    if (confirmPassword === "") {
      return notification("Please enter confirm password.", "error");
    }
    if (newPassword !== confirmPassword) {
      return notification("Passwords don't match.", "error");
    }
    if (!isPasswordRequiredLength) {
      notification("Passwords must be at least 8 characters long.", "error");
      return;
    }
    if (!doesPasswordContainUppercase) {
      notification(
        "Passwords must contain at least 1 UPPERCASE letter.",
        "error"
      );
      return;
    }
    if (!doesPasswordContainLowercase) {
      notification(
        "Passwords must contain at least 1 lowercase letter.",
        "error"
      );
      return;
    }
    if (!doesPasswordContainNumer) {
      return notification(
        "Passwords must contain at least 1 number character.",
        "error"
      );
    }
    try {
      setIsLoading(true);
      switch (userState.userType) {
        case UserType.BUYER:
          const result = await resetForgotPasswordForBuyerService(
            email,
            newPassword
          );
          if (result) {
            clearFilters();
            setIsLoading(false);
            closeAction();
            logOut();
            history.push("/buy");
            return notification("You have successfully changed your password. Please login again.", "success");
          }
          setIsLoading(false);
          return;

        case UserType.AGENT:
          const response = await resetForgotPasswordForAgentService(
            email,
            newPassword
          );
          if (response) {
            clearFilters();
            logOut();
            setIsLoading(false);
            closeAction();
            changeUserType(UserType.AGENT);
            history.push("/");
            return notification("You have successfully changed your password. Please login again.", "success");
          }

          setIsLoading(false);
          return;
        default:
          setIsLoading(false);
          return;
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return notification(`${error}`, "error");
    }
  };

  useEffect(() => {
    var tempIsPasswordRequiredLength = newPassword.length >= 8 ? true : false;
    var tempDoesPasswordContainUppercase = false;
    var tempDoesPasswordContainLowercase = false;
    var tempDoesPasswordContainNumer = false;

    for (var i = 0; i < newPassword.length; i++) {
      const char = newPassword.charAt(i);
      if (!tempDoesPasswordContainUppercase) {
        tempDoesPasswordContainUppercase = /^[A-Z]*$/.test(char);
      }
      if (!tempDoesPasswordContainLowercase) {
        tempDoesPasswordContainLowercase = /^[a-z]*$/.test(char);
      }
      if (!tempDoesPasswordContainNumer) {
        tempDoesPasswordContainNumer = /^[0-9]*$/.test(char);
      }
    }

    setIsPasswordRequiredLength(tempIsPasswordRequiredLength);
    setDoesPasswordContainUppercase(tempDoesPasswordContainUppercase);
    setDoesPasswordContainLowercase(tempDoesPasswordContainLowercase);
    setDoesPasswordContainNumber(tempDoesPasswordContainNumer);
  }, [newPassword]);

  /////////
  const renderPasswordRequirements = () => {
    return (
      <div className="register-buyer-page__password-filters">
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;• is at least 8 characters long:
          {isPasswordRequiredLength ? (
            <span className="green">✔</span>
          ) : (
            <span className="red">✗</span>
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;• contains 1 UPPERCASE letter:
          {doesPasswordContainUppercase ? (
            <span className="green">✔</span>
          ) : (
            <span className="red">✗</span>
          )}
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;• contains 1 lowercase letter:
          {doesPasswordContainLowercase ? (
            <span className="green">✔</span>
          ) : (
            <span className="red">✗</span>
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;• contains 1 number character:
          {doesPasswordContainNumer ? (
            <span className="green">✔</span>
          ) : (
            <span className="red">✗</span>
          )}
        </p>
      </div>
    );
  };

  const modalClassName = isShowing
    ? "change-password-modal u-visible"
    : "change-password-modal u-hidden";
  const cardClassName = isShowing
    ? "change-password-modal__card animation__pop-in"
    : "change-password-modal__card animation__pop-out";

  return (
    <div className={modalClassName}>
      {isLoading && <CustomSpinner />}
      <div className={cardClassName}>
        <div className="change-password-modal__row">
          <CustomRegHeading>Change
            <span style={{ borderBottom: "3px solid #C2D500" }}> Password?</span>
          </CustomRegHeading>

          <img
            className="change-password-modal__close-button"
            src={buttonClose}
            onClick={closeAction}
            alt="Close button"
          />
        </div>
        <p className="change-password-modal__error-message">
          {userState.loginError}
        </p>

        <div style={{ marginLeft: "10px", marginTop: "5px" }}>
          <p>Ensure that the password you create is strong:</p>
          {renderPasswordRequirements()}
        </div>
        <div className="change-password-modal__column">
          <TextField
            value={currentPassword}
            setValue={setCurrentPassword}
            placeholder="••••••••"
            label="Current Password"
            type={TextFieldType.PASSWORD}
            icon={TextFieldIconType.PASSWORD}
            isRequired={true}
            width={isMobile ? undefined : 38.4}
            className="register-buyer-page__form-item"
          />
          <TextField
            value={newPassword}
            setValue={setNewPassword}
            placeholder="••••••••"
            label="Create Password"
            type={TextFieldType.PASSWORD}
            icon={TextFieldIconType.PASSWORD}
            isRequired={true}
            width={isMobile ? undefined : 38.4}
            className="register-buyer-page__form-item"
          />
          <TextField
            value={confirmPassword}
            setValue={setConfirmPassword}
            placeholder="••••••••"
            label="Confirm Password"
            type={TextFieldType.PASSWORD}
            icon={TextFieldIconType.PASSWORD}
            isRequired={true}
            width={isMobile ? undefined : 38.4}
            className="register-buyer-page__form-item"
          />
          <Button
            type={ButtonType.PRIMARY}
            text="SUBMIT"
            clickHandler={handleRegister}
            width={24.5}
            className="change-password-modal_button"
          />
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
