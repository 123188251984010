import "antd/dist/antd.css";
import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppContextProvider } from "./contexts/AppContextProvider";
import Footer from "./layout/Footer";
import Header from "./layout/Header";
import PageContent from "./layout/PageContent";
import ForgotPasswordModal from "./modals/forgotPasswordModal";
import LogInModal from "./modals/LoginModal";

const App: React.FC = () => {
  const [isShowingLoginModal, setIsShowingLoginModal] = useState(false);
  const [isShowingForgotPasswordModal, setIsShowingForgotPasswordModal] =
    useState(false);
  const handleCloseLoginModal = () => {
    setIsShowingLoginModal(false);
  };
  const handleCloseForgotPasswordModal = () => {
    setIsShowingForgotPasswordModal(false);
  };

  return (
    <AppContextProvider>
      <BrowserRouter>
        <ToastContainer />
        <Header
          setIsShowingLoginModal={setIsShowingLoginModal}
          isShowingLoginModal={isShowingLoginModal}
        />
        {/* <PropertyDetailsPage setIsShowingLoginModal={setIsShowingLoginModal} /> */}
        <PageContent setIsShowingLoginModal={setIsShowingLoginModal} />
        <Footer />
        {isShowingLoginModal && (
          <LogInModal
            isShowing={isShowingLoginModal}
            closeAction={handleCloseLoginModal}
            setIsShowingForgotPasswordModal={setIsShowingForgotPasswordModal}
          />
        )}
        {isShowingForgotPasswordModal && (
          <ForgotPasswordModal
            isShowing={isShowingForgotPasswordModal}
            closeAction={handleCloseForgotPasswordModal}
            setIsShowingLoginModal={setIsShowingLoginModal}
          />
        )}
      </BrowserRouter>
      </AppContextProvider>
  );
};

export default App;
