import {
  EditOutlined,
  HomeOutlined,
  MailOutlined,
  UserOutlined
} from '@ant-design/icons';
import styled from '@emotion/styled';
import {
  Alert,
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Spin,
  Upload,
  message
} from 'antd';
import * as EmailValidator from 'email-validator';
import React, { useContext, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import '../../assets/css/pages/account-agent-details-agent-page.css';
import PropertyFiltersContext from '../../contexts/PropertyFiltersContext';
import UserContext from '../../contexts/UserContext';
import ChangePasswordModal from '../../modals/ChangePasswordModal';
import ThankyouModal from '../../modals/ThankyouModal';
import {
  getBuyerAddressInfoService,
  getBuyerInfoService,
  postMessageToUser,
  updateBuyerInfoService,
  uploadImage,
} from '../../services/NavihomeService';
import { notification } from '../notification';
import CustomSpinner from '../spinner/CustomSpinner';

const CustomSaveButton = styled(Button)`
  color: #fff;
  background: #004876;
  border-radius: 10px;
  padding: 0px 20px;
`;
const CustomSpin = styled(Spin)`
  .ant-spin-dot-item {
    background-color: #c2d500;
  }
  .ant-spin-text {
    background-color: #c2d500;
  }
`;
const CustomActionButton = styled(Button)`
  border-radius: 10px;
  padding: 0px 20px;
`;
const CustomPhoneInput = styled(PhoneInput)`
  height: 2rem;
  padding: 0px;
  border-radius: 5px;
  &.react-tel-input .country-list .search-box {
    text-transform: capitalize;
  }
  top: -7px;
`;
const CustomAnchorTag = styled.a`
  color: #eb752f;
  border-bottom: 1px solid #eb752f;
  text-decoration: none;
  &:hover {
    border-bottom: 1px solid blue;
  }
`;

const CustomInput = styled(Input)`
  border-radius: 10px;
  border: 1px solid #004876;
`;

const CustomRow = styled(Row)`
  text-align: center;
`;
const CustomUserOutlined = styled(UserOutlined)`
  color: #004876;
`;

const CustomMailOutlined = styled(MailOutlined)`
  color: #004876;
`;
const CustomHomeOutlined = styled(HomeOutlined)`
  color: #004876;
`;
const CustomAlert = styled(Alert)`
  margin-bottom: 10px;
  width: 50%;
`;

const CustomParagraph = styled.p`
  margin: 10px 0px !important;
`;
const CustomParagraph1 = styled.p`
  margin: 10px 0px !important;
  padding-bottom: 2rem;
  padding-top: 3px;
`;
const CustomParagraph2 = styled.p`
  margin: 10px 0px !important;
  padding-top: 2.5rem;
`;


const AccountDetailsBuyerPage: React.FC = () => {
  const [buyerInfo, setBuyerInfo] = useState<any>(null);
  const [buyerAddressInfo, setBuyerAddressInfo] = useState<any>(null);
  const [agentUpdated,] = useState<any>();
  const [spinState, setSpinState] = useState<number>(0);
  const [avatarUrl, setAvatarUrl] = useState<string>(null);
  const [, setImageRecieved] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { userState, logOut } = useContext(UserContext);
  const { setName, clearFilters } = useContext(PropertyFiltersContext);
  const [isShowingNotificationModal, setIsShowingNotificationModal] =
    useState<boolean>(false);
  const isShowAlert = false;
  const [phone, setPhone] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowingChangePasswordModal, setIsShowingChangePasswordModal] =
    useState(false);
  const isRes = useMediaQuery({ minWidth: 1200 });
  const history = useHistory();
  const getAddress = async () => {
    setIsLoading(true);
    try {
      getBuyerAddressInfoService(
        userState.buyer.token,
        userState.buyer.buyerId
      ).then((res) => {
        setBuyerAddressInfo(res);
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
      console.log('ERROR', error);
    }
  };
  const getBuyer = async () => {
    setIsLoading(true);
    try {
      getBuyerInfoService(
        userState?.buyer?.token,
        userState?.buyer?.buyerId
      )?.then((res: any) => {
        setIsLoading(false);
        setPhone(res?.phone)
        setBuyerInfo(res);
      });
    } catch (error) {
      setIsLoading(false);
      console.log('ERROR', error);
    }
  };

  useEffect(() => {
    getAddress();
    getBuyer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (buyerAddressInfo == null) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }

  }, [buyerAddressInfo]);

  const updateBuyerInfo = () => {
    setIsLoading(true);
    try {
      let formData = { ...form.getFieldsValue() };
      if (formData?.email !== undefined && formData?.email !== buyerInfo?.email) {
        if (!EmailValidator.validate(formData?.email)) {
          setIsLoading(false);
          return notification('Email format not correct.', 'error');
        }
      }

      if (avatarUrl) {
        formData = { ...formData, avatarUrl };
      }
      if (phone?.length > 0) formData.phone = phone;

      updateBuyerInfoService(
        userState.buyer.token,
        userState.buyer.buyerId,
        formData
      ).then((res: any) => {
        if (!res?.success) {
          setIsLoading(false);
          return notification('Email already in used.', 'error');
        }
        setName(String(Math.floor(Math.random() * 100 + 1)));
        if (formData?.email) {
          setIsLoading(false);
          if (
            userState?.buyer?.preferredContact === 'CONTACT_RADIO_BOTH' ||
            userState?.buyer?.preferredContact === 'CONTACT_RADIO_PHONE'
          ) {
            postMessageToUser(
              buyerInfo?.phone,
              `Your account has been changed for your Navihome. A link has been sent to your registered email. Please verify and login.`
            );
          }

          setIsShowingNotificationModal(true);
          handlerModalTimeout();
        } else {
          setIsLoading(false);
          return notification('Account Updated Successfully!', 'success');
        }
      });
    } catch (error) {
      setIsLoading(false);
      console.log('ERROR', error);
    }
  };

  function getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => reject(error);
    });
  }

  function beforeUpload(file: any) {
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/TIFF';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG/TIFF file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setSpinState(0);
      setImageRecieved(false);
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPng && isLt2M;
  }

  const handleChange = async (info: any, key: string) => {
    if (key === 'avatar1' && info?.file?.size < 2097152) setSpinState(1);
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'error') {
      const fd = new FormData();
      fd.append('file', info.file.originFileObj);
      const locationUrl: any = await uploadImage(fd, userState?.buyer?.token);
      if (key === 'avatar1') setAvatarUrl(locationUrl.Location);
      if (locationUrl.Location && key === 'avatar1') {
        setSpinState(0);
        setImageRecieved(true);
      }
    }
  };

  const changePasswordModalHandler = () => {
    setIsShowingChangePasswordModal(!isShowingChangePasswordModal);
  };
  const notificationModalHandler = () => {
    setIsShowingNotificationModal(!isShowingNotificationModal);
    clearFilters();
    logOut();
    history.push('/buy');
  };
  const handlerModalTimeout = () => {
    setTimeout(() => {
      notificationModalHandler();
    }, 4000);
  };
  return (
    <div className="account-detail-agent-page">
      {isLoading && <CustomSpinner />}
      {buyerInfo ? (
        <div>
          {isShowAlert ? (
            <Row justify="center">
              <CustomAlert
                message={agentUpdated?.msg}
                type={agentUpdated?.success ? 'success' : 'error'}
                showIcon
              />
            </Row>
          ) : null}
          <Row justify="space-between">
            <h3>Account Details</h3>
            <div className="save-button-css">
              <CustomActionButton
                size="middle"
                onClick={() => {
                  history.push('/dashboard');
                }}
              >
                CANCEL
              </CustomActionButton>
              &nbsp;&nbsp;&nbsp;
              <CustomSaveButton size="middle" onClick={updateBuyerInfo}>
                SAVE AND UPDATE
              </CustomSaveButton>
            </div>
          </Row>

          <Divider />

          <Row gutter={32}>
            <Col xl={6} sm={24} xs={24}>
              <Row justify="center">
                {spinState ? (
                  <span>
                    <CustomSpin
                      style={{ marginLeft: '30px', color: '#C2D500' }}
                    />
                  </span>
                ) : (
                  <Avatar
                    src={avatarUrl ? `${avatarUrl}` : buyerInfo?.avatarUrl}
                    size={{
                      xs: 64,
                      sm: 80,
                      md: 100,
                      lg: 124,
                      xl: 216,
                      xxl: 280,
                    }}
                    icon={<UserOutlined />}
                    className="avatar"
                  />
                )}
              </Row>
              <CustomRow justify="center">
                <Upload
                  name="avatar"
                  accept="image/*"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={(info) => handleChange(info, 'avatar1')}
                >
                  <span>
                    <EditOutlined style={{ color: '#C2D500' }} />
                    Edit your profile picture
                    <CustomParagraph>
                      Upload a picture of size: 400 x 400px
                    </CustomParagraph>
                  </span>
                </Upload>
              </CustomRow>
            </Col>
            <Col xl={9} sm={24} xs={24}>
              <h3>General Information</h3>
              <CustomParagraph1>
                Please update your contact information below if it has changed. To change your password, click on the link at the bottom.
              </CustomParagraph1>

              <Form form={form} layout="vertical">
                <Form.Item label="Name" name="name" required>
                  <CustomInput
                    size="large"
                    defaultValue={buyerInfo?.name || ''}
                    prefix={<CustomUserOutlined />}
                  />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  required
                  style={{ paddingTop: isRes ? '1.8rem' : null }}
                >
                  <CustomInput
                    size="large"
                    defaultValue={buyerInfo?.email || ''}
                    disabled={false}
                    prefix={<CustomMailOutlined />}
                  />
                </Form.Item>
                <Form.Item
                  label="Phone Number"
                  name="phone"
                  style={{ position: 'relative', top: isRes ? '1.8rem' : null }}
                >
                  <div>
                    <CustomPhoneInput
                      value={phone}
                      inputStyle={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        bottom: '-2rem',
                        right: '-7rem',
                        height: '4.2rem',
                        width: '100%',
                        marginTop: '-3px',
                        border: '1px solid #365073 ',
                        borderRadius: '8px',
                      }}
                      inputProps={{
                        setValue: { setPhone },
                      }}
                      onChange={(phone: any) => {
                        if (phone?.length >= 10) {
                          setPhone(phone);
                        } else {
                          setPhone("");
                        }
                      }}
                      inputClass="register-agent-page__form-item"
                      dropdownStyle={{
                        width: '20rem',
                        position: 'relative',
                        bottom: '21rem',
                        overflowX: 'hidden',
                      }}
                      country={'us'}
                      specialLabel=""
                      buttonStyle={{
                        position: 'absolute',
                        bottom: '45.5rem',
                        height: 'auto',
                        top: '2.1rem',
                      }}
                      placeholder="+1 (404) 123 4567"
                      disableDropdown={false}
                      autoFormat={true}
                      enableSearch={true}
                    />
                  </div>
                </Form.Item>
                <CustomParagraph2>
                  <h3 style={{ paddingBottom: '7px' }}>Change Password</h3>
                  <span className="upgradeNow">
                    <CustomAnchorTag onClick={changePasswordModalHandler}>
                      Click here
                    </CustomAnchorTag>
                  </span>{' '}
                  to change your password.
                </CustomParagraph2>
              </Form>
            </Col>

            <Col xl={9} sm={24} xs={24}>
              <Form form={form} layout="vertical">
                <h3>Address Details</h3>
                <CustomParagraph>
                  Update your home and office address below. When you or your agent schedule showings, you can automatically choose one of these addresses as the starting or ending point.
                </CustomParagraph>
                <Form.Item
                  label="Home Address"
                  name="homeStreetAddress"
                >
                  <CustomInput
                    size="large"
                    defaultValue={buyerAddressInfo?.homeStreetAddress ?? ''}
                    placeholder="Street Address"
                    prefix={<CustomHomeOutlined />}
                  />
                </Form.Item>
                <Form.Item name="homeCityAndZip" >
                  <CustomInput
                    size="large"
                    defaultValue={buyerAddressInfo?.homeCityAndZip ?? ''}
                    placeholder="City and Zip Code"
                  />
                </Form.Item>
                <Form.Item
                  label="Office Address"
                  name="officeStreetAddress"
                >
                  <CustomInput
                    size="large"
                    defaultValue={buyerAddressInfo?.officeStreetAddress ?? ''}
                    placeholder="Street Address"
                    prefix={<CustomUserOutlined />}
                  />
                </Form.Item>
                <Form.Item name="officeCityAndZip" >
                  <CustomInput
                    size="large"
                    defaultValue={buyerAddressInfo?.officeCityAndZip ?? ''}
                    placeholder="City and Zip Code"
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      ) : null}
      {isShowingChangePasswordModal && (
        <ChangePasswordModal
          isShowing={isShowingChangePasswordModal}
          closeAction={changePasswordModalHandler}
          email={buyerInfo?.email ?? ''}
          password={buyerInfo?.password ?? ''}
        />
      )}
      {isShowingNotificationModal && (
        <ThankyouModal
          isShowing={isShowingNotificationModal}
          closeAction={notificationModalHandler}
          text="Account updated successfully"
        />
      )}
    </div>
  );
};

export default AccountDetailsBuyerPage;
