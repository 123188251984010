// import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button } from 'antd';
interface ScheduleHeaderProps{
    getScheduleShow: (schedule: string) => void
}

const ScheduleHeader: React.FC<ScheduleHeaderProps> = ({getScheduleShow}):JSX.Element => {
  return (
    <>
      <div className="notification-page-user_header">
        <div className="notification-page-user_image">
          <div>
            <Avatar icon={<UserOutlined />} />
          </div>
          <p>Schedule Three</p>
        </div>
        <div className="notification-page-user_media">
          <div className="notification-page-user_media-phone">
            <Button style={{color: 'white'}} type="text" onClick={()=>{getScheduleShow('scheduleOne')}}>Schedule One</Button>
          </div>
        </div>

        <div className="notification-page-user_media">
          <div className="notification-page-user_media-phone">
            <Button style={{color: 'white'}} type="text" onClick={()=>{getScheduleShow('scheduleTwo')}}>
              Schedule Two
            </Button>
          </div>
        </div>
        <div className="notification-page-user_media">
          <div className="notification-page-user_media-phone">
            <Button style={{color: 'white'}} type="text" onClick={()=>{getScheduleShow('scheduleFour')}}>Schedule Four</Button>
          </div>
        </div>

        <div className="notification-page-user_logo">
          <div className="notification-page-user_media-logo">
            <div>
              <Avatar icon={<UserOutlined />} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleHeader;
