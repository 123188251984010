import styled from '@emotion/styled';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { notification } from '../components/notification';
import CustomSpinner from '../components/spinner/CustomSpinner';
import UserContext, { UserType } from '../contexts/UserContext';
import ThankyouModal from '../modals/ThankyouModal';

import {
  updateAgentVerifyService,
  updateBuyerVerifyService,
} from '../services/NavihomeService';

const CustomDiv = styled.div`
  padding: 50px;
`;
const CustomHeading = styled.h1`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #c2cc30;
  }
`;

const VerifyPage = () => {
  const history = useHistory();
  const { changeUserType, logIn, logOut } = useContext(UserContext);
  const [userType, setUserType] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [isShowingThankyouModal, setIsShowingThankyouModal] =
    useState<boolean>(false);


  useEffect(() => {
    const query: any = history?.location?.hash?.split('=') ?? '';
    try {
      setUserType(query?.[3]);
      setIsLoading(true);
      setMessage(query?.[3]);
      setTimeout(() => {
        verifyHandler(query?.[1] ?? '', query?.[3] ?? '', query?.[5] ?? '', query?.[7] ?? '', query?.[9] ?? '', query?.[11] ?? '');
      }, 3000);
    } catch (error) {
      setIsLoading(false);
      console.log('ERROR: ', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history?.location?.search]);

  const handleCloseThankyouModal = () => {
    setIsShowingThankyouModal(false);
  };
  const handlerModalTimeout = (userType: string) => {
    setTimeout(() => {
      handleCloseThankyouModal();

      if (userType === 'buyer') history.push('/buy');
      if (userType === 'agent' || userType === 'agentLogin') {
        changeUserType(UserType.AGENT);
        history.push('/');
      }
      setIsLoading(false)
    }, 3000);
  };

  const verifyHandler = async (email?: string, type?: string, upComingType?: string, upComingTab?: string, upComingID?: string, secondUpCommingId?: string) => {
    setIsLoading(true);
    try {
      if (userType === 'buyer' || type === 'buyer') {
        let verifyBuyer: any = null;
        if (upComingType === 'Login') {
          verifyBuyer = await updateBuyerVerifyService(email, upComingType);
        } else {
          verifyBuyer = await updateBuyerVerifyService(email, 'Not Login');
        }
        if (verifyBuyer?.success && verifyBuyer?.password) {
          const loginSuccess: any = await logIn(email, verifyBuyer?.password);
          if (loginSuccess) {
            setIsLoading(false);
            history.push(`/buy?buyerDashboard=${upComingTab}`);
          }
          setIsLoading(false);
        } else if (verifyBuyer?.success) {
          setMessage(verifyBuyer?.msg);
          setIsLoading(false);
          setIsShowingThankyouModal(true);
          handlerModalTimeout(type);
          return;
        } else {
          setIsLoading(false);
          return notification('Something went wrong!', 'error');
        }
      }
      if (userType === 'agent' || type === 'agent') {
        let verifyAgent: any = null;
        if (upComingType === 'Login') {
          const getInfo: any = localStorage.getItem('userContext') ? JSON.parse(localStorage.getItem('userContext')) : {};
          setIsLoading(false);
          if (getInfo?.isLoggedIn && getInfo?.agent?.email === email) {
            if (upComingTab === "calendar") {
              history.push(`/${upComingTab}`);
            } else if (upComingTab === "property-calendar") {
              history.push(`/propertydetails/${upComingID}?currTab=calendar`);
            } else if (upComingTab === "view-route") {
              history.push(`/common?buyerId=${upComingID}&formId=${secondUpCommingId}&statusType=afterRouteCreated&routeStatus=Scheduled&allAccepted=allAccepted&role=Buyer`)
            } else {
              history.push(`/dashboard?buyerId=${upComingID}&buyerDashboard=${upComingTab}&setBuyerInfo=Yes`);
            }
            return;
          } else {
            setIsLoading(false);
            logOut();
            notification('Please login!', 'warning')
            changeUserType(UserType.AGENT);
            history.push(`/`);
            return;
          }
        }
        verifyAgent = await updateAgentVerifyService(email, 'Not Login');
        if (verifyAgent?.success) {
          setMessage(verifyAgent?.msg);
          setIsLoading(false);
          setIsShowingThankyouModal(true);
          handlerModalTimeout(type);
          return;
        } else {
          setIsLoading(false);
          return notification('Something went wrong!', 'error');
        }
      }
      if (userType === 'agentLogin' || type === 'agentLogin') {
        handlerModalTimeout(type);
        return;
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('ERROR!', error);
    }
  };

  return (
    <CustomDiv>
      {isLoading && <CustomSpinner />}
      <CustomHeading
        onClick={() => {
          verifyHandler();
        }}
      >
        Please Wait!
      </CustomHeading>
      {isShowingThankyouModal && (
        <ThankyouModal
          isShowing={isShowingThankyouModal}
          closeAction={handleCloseThankyouModal}
          message={message}
          text="Thanks! You have successfully verified your email"
        />
      )}
    </CustomDiv>
  );
};

export default VerifyPage;
