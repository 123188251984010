import React, { useReducer } from 'react';
import {
  checkIfBuyerIsLinkedWithAgent, getActivatedSubscriptionPlan, logInAgentService, logInBuyerService, registerAgentService, registerBuyerService, registerForVisitPropService, updatePaymentExpireDate, updateSubscriptionPlan, updateUserTourFormData
} from '../services/NavihomeService';

enum UserActionType {
  SAVE_TO_LOCAL_STORAGE = 'SAVE_TO_LOCAL_STORAGE',
  LOAD_FROM_LOCAL_STORAGE = 'LOAD_FROM_LOCAL_STORAGE',
  CHANGE_USER_TYPE = 'CHANGE_USER_TYPE',
  IS_LOGGING_IN = 'IS_LOGGING_IN',
  LOG_IN_BUYER_SUCCESS = 'LOG_IN_BUYER_SUCCESS',
  LOG_IN_AGENT_SUCCESS = 'LOG_IN_SALE_AGENT_SUCCESS',
  SAVE_LOGIN = 'SALE_LOGIN',
  LOG_IN_ERROR = 'LOG_IN_ERROR',
  LOG_OUT = 'LOG_OUT',
  IS_REGISTERING = 'IS_REGISTERING',
  REGISTER_BUYER_SUCCESS = 'REGISTER_BUYER_SUCCESS',
  REGISTER_AGENT_SUCCESS = 'REGISTER_AGENT_SUCCESS',
  REGISTER_ERROR = 'REGISTER_ERROR',
  PROPERTY_BACK = 'PROPERTY_BACK',
}

export enum UserType {
  BUYER = 'BUYER',
  AGENT = 'AGENT',
}

type UserAction =
  | { type: UserActionType.SAVE_TO_LOCAL_STORAGE }
  | {
    type: UserActionType.LOAD_FROM_LOCAL_STORAGE;
    payload: { state: UserState };
  }
  | { type: UserActionType.CHANGE_USER_TYPE; payload: { userType: UserType } }
  | { type: UserActionType.IS_LOGGING_IN }
  | {
    type: UserActionType.LOG_IN_BUYER_SUCCESS;
    payload: {
      buyerId: number;
      token: string;
      name: string;
      Email: string;
      phone: string;
      preferredContact: string;
      isLinked: boolean;
      avatarUrl: string;
      comsumerType: string;
      Password: string;
      buyerAgentName: string;
      buyerAgentId: number;
      buyerAgentEmail: string;
      buyerAgentPhone: string;
      buyerAgentAvatarUrl: string;
      buyerAgentWebsite: string;
      buyerAgentCompanyLogo: string;
      sellerAgentId: number;
      sellerAgentEmail: string;
      sellerAgentPhone: string;
      sellerAgentAvatarUrl: string;
      sellerAgentWebsite: string;
      sellerAgentCompanyLogo: string;
      sellerAgentName: string;
      sellerListingId: number;
      buyerAgentPreferredContact: string;
      agentSubType: string;
      sellerAgentSubType: string;
    };
  }
  | {
    type: UserActionType.LOG_IN_AGENT_SUCCESS;
    payload: {
      agentId: number;
      token: string;
      name: string;
      company: string;
      mlsId: string;
      phone: string;
      preferredContact: string;
      subscription_type: string;
      status: string;
      created_at: Date;
      email: string;
      expireAt: Date;
      password: string;
      registeredAt: Date;
    };
  }
  | {
    type: UserActionType.SAVE_LOGIN;
    payload: { email: string; password: string };
  }
  | { type: UserActionType.LOG_IN_ERROR; payload: { error: string } }
  | { type: UserActionType.LOG_OUT }
  | { type: UserActionType.IS_REGISTERING }
  | {
    type: UserActionType.REGISTER_BUYER_SUCCESS;
    payload: {
      buyerId: number;
      token: string;
      name: string;
      Email: string;
      phone: string;
      preferredContact: string;
      isLinked?: boolean;
      avatarUrl?: string;
      comsumerType?: string;
      Password?: string;
      buyerAgentName?: string;
      buyerAgentId?: number;
      buyerAgentEmail?: string;
      buyerAgentPhone?: string;
      buyerAgentAvatarUrl?: string;
      buyerAgentWebsite?: string;
      buyerAgentCompanyLogo?: string;
      sellerAgentId?: number;
      sellerAgentEmail?: string;
      sellerAgentPhone: string;
      sellerAgentAvatarUrl: string;
      sellerAgentWebsite: string;
      sellerAgentCompanyLogo: string;
      sellerAgentName: string;
      sellerListingId: number;
      buyerAgentPreferredContact?: string
      agentSubType?: string;
      sellerAgentSubType?: string;
    };
  }
  | {
    type: UserActionType.REGISTER_AGENT_SUCCESS;
    payload: {
      agentId: number;
      token: string;
      name: string;
      company: string;
      mlsId: string;
      phone: string;
      preferredContact: string;
      subscription_type?: string;
      status?: string;
      created_at?: Date;
      email: string;
      expireAt: Date;
      password: string;
      registeredAt: Date;
    };
  }
  | {
    type: UserActionType.REGISTER_ERROR;
    payload: { error: string };
  }
  | {
    type: UserActionType.PROPERTY_BACK;
    payload: {
      isBack: boolean;
    };
  };

export interface UserState {
  userType: UserType;
  login: {
    email: string;
    password: string;
  };
  buyer: {
    buyerId: number;
    token: string;
    name: string;
    Email: string;
    phone: string;
    preferredContact: string;
    isLinked?: boolean;
    avatarUrl?: string;
    comsumerType?: string;
    Password?: string;
    buyerAgentName?: string;
    buyerAgentId?: number;
    buyerAgentEmail?: string;
    buyerAgentPhone?: string;
    buyerAgentAvatarUrl?: string;
    buyerAgentWebsite?: string;
    buyerAgentCompanyLogo?: string;
    sellerAgentId?: number;
    sellerAgentEmail?: string;
    sellerAgentPhone: string;
    sellerAgentAvatarUrl: string;
    sellerAgentWebsite: string;
    sellerAgentCompanyLogo: string;
    sellerAgentName: string;
    sellerListingId: number;
    buyerAgentPreferredContact?: string;
    agentSubType?: string;
    sellerAgentSubType?: string;
  } | null;
  agent: {
    agentId: number;
    token: string;
    name: string;
    company: string;
    mlsId: string;
    phone: string;
    preferredContact: string;
    subscription_type?: string;
    status?: string;
    created_at?: Date;
    email: string;
    expireAt: Date;
    password: string;
    registeredAt: Date;
  } | null;
  isLoggingIn: boolean;
  isLoggedIn: boolean;
  isRegistering: boolean;
  loginError: string | null;
  registerError: string | null;
  back: boolean;
}

type UserContextType = {
  userState: UserState;
  restoreUserContext: () => void;
  changeUserType: (usertType: UserType) => void;
  logIn: (email: string, password: string) => Promise<boolean>;
  logOut: () => void;
  registerBuyer: (
    email: string,
    password: string,
    phone: string,
    name: string,
    preferredContact: string,
    preferredType: string,
    isLinked?: boolean
  ) => Promise<any>;
  registerAgent: (
    email: string,
    password: string,
    phone: string,
    name: string,
    mlsId: string,
    company: string,
    preferredContact: string,
    subscription_type?: string,
    status?: string,
    created_at?: Date
  ) => Promise<boolean>;
  upcomingtour: (
    // formId: number,
    // buyerId: number,
    dateTime: Date,
    time: string,
    timePerProperty: string,
    startAdd: string,
    startAddCity: string,
    endingAdd: string,
    endingAddCity: string,
    selectedProperties: number,
    area: string,
    PropertyStatus: string,
    buyerId: number,
    saveTourStatus: string,
    firstActiveTab: string,
    secondActiveTab: string,
    startrouteLocation: string,
    endrouteLocation: string,
    agentId: number,
    formId: any,
    token: string,
    role: string,
    // timeZone: string
  ) => Promise<any>;
  // propertyIsBack: (isBack: boolean) => void
};

const initialState: UserState = {
  userType: UserType.BUYER,
  login: null,
  buyer: null,
  agent: null,
  isLoggedIn: false,
  isLoggingIn: false,
  isRegistering: false,
  loginError: null,
  registerError: null,
  back: false,
};

const initialStateBuyer: UserState = {
  ...initialState,
  userType: UserType.BUYER,
};

const initialStateSaleAgent: UserState = {
  ...initialState,
  userType: UserType.AGENT,
};

const UserContext = React.createContext<UserContextType | undefined>(undefined);

const reducer = (state: UserState, action: UserAction): UserState => {
  const storage = window.localStorage;
  switch (action.type) {
    case UserActionType.SAVE_TO_LOCAL_STORAGE:
      storage.setItem('userContext', JSON.stringify(state));
      return { ...state };
    case UserActionType.LOAD_FROM_LOCAL_STORAGE:
      return { ...action.payload.state };
    case UserActionType.CHANGE_USER_TYPE:
      return { ...initialState, userType: action.payload.userType };

    case UserActionType.IS_LOGGING_IN:
      return { ...initialState, userType: state.userType, isLoggingIn: true };

    case UserActionType.LOG_IN_BUYER_SUCCESS:
      return {
        ...initialStateBuyer,
        buyer: action.payload,
        isLoggedIn: true,
        login: state.login,
      };

    case UserActionType.LOG_IN_AGENT_SUCCESS:
      return {
        ...initialStateSaleAgent,
        agent: action.payload,
        isLoggedIn: true,
        login: state.login,
      };

    case UserActionType.SAVE_LOGIN:
      return {
        ...state,
        login: {
          email: action.payload.email,
          password: action.payload.password,
        },
      };

    case UserActionType.LOG_IN_ERROR:
      return {
        ...initialState,
        userType: state.userType,
        loginError: action.payload.error,
      };

    case UserActionType.LOG_OUT:
      return { ...initialState };

    case UserActionType.IS_REGISTERING:
      return {
        ...initialState,
        userType: state.userType,
        isRegistering: true,
        login: state.login,
      };

    case UserActionType.REGISTER_BUYER_SUCCESS:
      return {
        ...initialStateBuyer,
        buyer: action.payload,
        isLoggedIn: true,
        login: state.login,
      };

    case UserActionType.REGISTER_AGENT_SUCCESS:
      return {
        ...initialStateSaleAgent,
        agent: action.payload,
        isLoggedIn: true,
        login: state.login,
      };

    case UserActionType.REGISTER_ERROR:
      return {
        ...initialState,
        userType: state.userType,
        registerError: action.payload.error,
      };

    case UserActionType.PROPERTY_BACK:
      return {
        ...initialState,
        back: action.payload.isBack,
      };

    default:
      return state;
  }
};

export const UserProvider: React.FC = ({ children }) => {
  const [userState, dispatch] = useReducer(reducer, initialState);

  const restoreUserContext = async () => {
    const storage = window.localStorage;
    const data = storage.getItem('userContext');
    if (data) {
      var parsedData: UserState = data ? JSON.parse(data) : {};
      if (parsedData?.isLoggedIn) {
        switch (parsedData?.userType) {
          case UserType.BUYER:
            const buyerData = await logInBuyerService(
              parsedData?.login?.email,
              parsedData?.login?.password
            );
            parsedData.buyer.token = buyerData?.token ?? '';
            break;
          case UserType.AGENT:
            const agentData = await logInAgentService(
              parsedData?.login?.email,
              parsedData?.login?.password
            );
            parsedData.agent.token = agentData?.token ?? '';
            break;
          default:
            break;
        }
      }
      dispatch({
        type: UserActionType.LOAD_FROM_LOCAL_STORAGE,
        payload: { state: parsedData },
      });
      dispatch({ type: UserActionType.SAVE_TO_LOCAL_STORAGE });
    }
  };

  const changeUserType = (userType: UserType) => {
    dispatch({ type: UserActionType.CHANGE_USER_TYPE, payload: { userType } });
  };

  const logIn = async (email: string, password: string): Promise<boolean> => {
    dispatch({ type: UserActionType.IS_LOGGING_IN });

    if (userState.userType === UserType.BUYER) {
      try {
        const {
          buyerId,
          token,
          name,
          Email,
          phone,
          preferredContact,
          avatarUrl,
          comsumerType,
          Password,
          buyerAgentName,
          buyerAgentId,
          buyerAgentEmail,
          buyerAgentPhone,
          buyerAgentAvatarUrl,
          buyerAgentWebsite,
          buyerAgentCompanyLogo,
          sellerAgentId,
          sellerAgentEmail,
          sellerAgentPhone,
          sellerAgentAvatarUrl,
          sellerAgentWebsite,
          sellerAgentCompanyLogo,
          sellerAgentName,
          sellerListingId,
          buyerAgentPreferredContact,
          agentSubType,
          sellerAgentSubType,
        } = await logInBuyerService(email, password);
        const linkedAgent: any = await checkIfBuyerIsLinkedWithAgent(
          token,
          buyerId
        );
        const { isLinked } = linkedAgent;
        dispatch({
          type: UserActionType.LOG_IN_BUYER_SUCCESS,
          payload: {
            buyerId,
            token,
            name,
            Email,
            phone,
            preferredContact,
            isLinked,
            avatarUrl,
            comsumerType,
            Password,
            buyerAgentName,
            buyerAgentId,
            buyerAgentEmail,
            buyerAgentPhone,
            buyerAgentAvatarUrl,
            buyerAgentWebsite,
            buyerAgentCompanyLogo,
            sellerAgentId,
            sellerAgentEmail,
            sellerAgentPhone,
            sellerAgentAvatarUrl,
            sellerAgentWebsite,
            sellerAgentCompanyLogo,
            sellerAgentName,
            sellerListingId,
            buyerAgentPreferredContact,
            agentSubType,
            sellerAgentSubType,
          },
        });
        dispatch({
          type: UserActionType.SAVE_LOGIN,
          payload: { email, password },
        });
        dispatch({ type: UserActionType.SAVE_TO_LOCAL_STORAGE });
        return true;
      } catch (error) {
        if (error instanceof Error) {
          dispatch({
            type: UserActionType.LOG_IN_ERROR,
            payload: { error: error.message },
          });
        }
        return false;
      }
    }

    if (userState.userType === UserType.AGENT) {
      try {
        const {
          agentId,
          token,
          name,
          company,
          mlsId,
          phone,
          preferredContact,
          expireAt,
          registeredAt,
        } = await logInAgentService(email, password);
        // const from = new Date();
        // const to = expireAt;
        // let expireAtDate = expireAt;
        // const count: number =
        //   moment(from, "YYYY-MM-DD")
        //     .startOf("day")
        //     .diff(moment(to, "YYYY-MM-DD").startOf("day"), "days") - 1;
        const response: any = await getActivatedSubscriptionPlan(agentId, token);
        let { activePlan, canceledDate, subscriptionId } = response;      

        let activePlanName: string = activePlan || "Navihome Free Plan",
        expireAtDate = canceledDate || new Date(),
          created_at = registeredAt,
          status='COMPLETED';

          if(subscriptionId?.includes('free') && activePlan === 'Navihome Free Plan'){
            status='NOT COMPLETED';
          }

        // if (count >= 0) {
        //   // const response: any = await getActivatedSubscriptionPlan(agentId, token);
        //   if (response) {
        //     activePlanName = activePlan;
        //     if (response && activePlan !== "Navihome Free Plan") {
        //       startDateCount =
        //         moment(from, "YYYY-MM-DD")
        //           .startOf("day")
        //           .diff(moment(toStartDate, "YYYY-MM-DD").startOf("day"), "days") - 1;
        //       if (startDateCount < 0) {
        //         activePlanName = activePlan;
        //         expireAtDate = canceledDate;
        //         await updatePaymentExpireDate(userState?.agent?.token, userState?.agent?.agentId);
        //       } else {
        //         activePlanName = "Navihome Free Plan";
        //         await updateSubscriptionPlan(payment_subscription_id, "INACTIVE", "Navihome Free Plan", "Navihome Free Plan", 0);
        //       }
        //     }
        //   } else {
        //     activePlanName = "Navihome Free Plan";
        //   }
        // }

        dispatch({
          type: UserActionType.LOG_IN_AGENT_SUCCESS,
          payload: {
            agentId,
            token,
            name,
            company,
            mlsId,
            phone,
            preferredContact,
            subscription_type: activePlanName,
            status,
            created_at,
            email,
            expireAt: expireAtDate,
            password,
            registeredAt,
          },
        });
        dispatch({
          type: UserActionType.SAVE_LOGIN,
          payload: { email, password },
        });
        dispatch({ type: UserActionType.SAVE_TO_LOCAL_STORAGE });
        return true;
      } catch (error) {
        if (error instanceof Error) {
          console.log('Error', error);
          dispatch({
            type: UserActionType.LOG_IN_ERROR,
            payload: { error: error.message },
          });
        }
        return false;
      }
    }
  };

  const logOut = () => {
    dispatch({ type: UserActionType.LOG_OUT });
    dispatch({ type: UserActionType.SAVE_TO_LOCAL_STORAGE });
  };

  const registerBuyer = async (
    email: string,
    password: string,
    phoneNumber: string,
    name: string,
    preferredContact: string,
    preferredType: string
  ): Promise<any> => {
    dispatch({ type: UserActionType.IS_REGISTERING });

    try {
      let isLinked = false,
        buyerAgentName = '',
        buyerAgentId = 0,
        buyerAgentEmail = '',
        buyerAgentPhone = '',
        buyerAgentPreferredContact = '',
        buyerAgentAvatarUrl = '',
        buyerAgentWebsite = '',
        buyerAgentCompanyLogo = '',
        sellerAgentId = 0,
        sellerAgentEmail = '',
        sellerAgentPhone = '',
        sellerAgentAvatarUrl = '',
        sellerAgentWebsite = '',
        sellerAgentCompanyLogo = '',
        sellerAgentName = '',
        sellerListingId = 0,
        agentSubType = '',
        sellerAgentSubType = '';
      const { buyerId, token, Email, phone } = await registerBuyerService(
        email,
        password,
        phoneNumber,
        name,
        preferredContact,
        preferredType
      );
      dispatch({
        type: UserActionType.REGISTER_BUYER_SUCCESS,
        payload: {
          buyerId,
          token,
          name,
          Email,
          phone,
          preferredContact,
          isLinked,
          buyerAgentName,
          buyerAgentId,
          buyerAgentEmail,
          buyerAgentPhone,
          buyerAgentAvatarUrl,
          buyerAgentWebsite,
          buyerAgentCompanyLogo,
          sellerAgentId,
          sellerAgentEmail,
          sellerAgentPhone,
          sellerAgentAvatarUrl,
          sellerAgentWebsite,
          sellerAgentCompanyLogo,
          sellerAgentName,
          sellerListingId,
          buyerAgentPreferredContact,
          agentSubType,
          sellerAgentSubType
        },
      });
      dispatch({
        type: UserActionType.SAVE_LOGIN,
        payload: { email, password },
      });
      dispatch({ type: UserActionType.SAVE_TO_LOCAL_STORAGE });
      return {
        error: false,
        message: 'Buyer Registered Successfully!',
      };
    } catch (error) {
      if (error instanceof Error) {
        dispatch({
          type: UserActionType.REGISTER_ERROR,
          payload: { error: error.message },
        });
        return {
          error: true,
          message: error.message,
        };
      }
      dispatch({
        type: UserActionType.REGISTER_ERROR,
        payload: { error: 'Unknown Error Occured.' },
      });
      return {
        error: true,
        message: 'Unknown Error Occured.',
      };
    }
  };

  const registerAgent = async (
    email: string,
    password: string,
    phone: string,
    name: string,
    mlsId: string,
    company: string,
    preferredContact: string
  ): Promise<boolean> => {
    dispatch({ type: UserActionType.IS_REGISTERING });

    try {
      const { agentId, token } = await registerAgentService(
        email,
        password,
        phone,
        name,
        company,
        mlsId,
        preferredContact
      );
      let subscription_type = '',
        status = '',
        created_at = null,
        expireAt = null,
        registeredAt = new Date();
      dispatch({
        type: UserActionType.REGISTER_AGENT_SUCCESS,
        payload: {
          agentId,
          token,
          name,
          company,
          mlsId,
          phone,
          preferredContact,
          subscription_type,
          status,
          created_at,
          email,
          expireAt,
          password,
          registeredAt,
        },
      });
      dispatch({
        type: UserActionType.SAVE_LOGIN,
        payload: { email, password },
      });
      dispatch({ type: UserActionType.SAVE_TO_LOCAL_STORAGE });
      return true;
    } catch (error) {
      console.log('error is ', error);
      if (error instanceof Error) {
        dispatch({
          type: UserActionType.REGISTER_ERROR,
          payload: { error: error.message },
        });
      }
      dispatch({
        type: UserActionType.REGISTER_ERROR,
        payload: { error: 'Unknown Error Occured.' },
      });
      return false;
    }
  };

  const upcomingtour = async (
    dateTime: Date,
    time: string,
    timePerProperty: string,
    startAdd: string,
    startAddCity: string,
    endingAdd: string,
    endingAddCity: string,
    selectedProperties: number,
    area: string,
    PropertyStatus: string,
    buyerId: number,
    saveTourStatus: string,
    firstActiveTab: string,
    secondActiveTab: string,
    startrouteLocation: string,
    endrouteLocation: string,
    agentId: number,
    formId: any,
    token: string,
    role: string,
  ): Promise<any> => {
    try {

      if (!formId) {
        const data: any = await registerForVisitPropService(
          dateTime,
          time,
          timePerProperty,
          startAdd,
          startAddCity,
          endingAdd,
          endingAddCity,
          selectedProperties,
          area,
          PropertyStatus,
          buyerId,
          saveTourStatus,
          firstActiveTab,
          secondActiveTab,
          startrouteLocation,
          endrouteLocation,
          agentId,
          token,
          role
        );
        return data;
      } else {

        const data: any = await updateUserTourFormData(
          dateTime,
          time,
          timePerProperty,
          startAdd,
          startAddCity,
          endingAdd,
          endingAddCity,
          selectedProperties,
          area,
          PropertyStatus,
          buyerId,
          saveTourStatus,
          firstActiveTab,
          secondActiveTab,
          startrouteLocation,
          endrouteLocation,
          agentId,
          formId,
          token,
          role
        );
        return data;
      }
    } catch (err) {
      return { err };
    }
  };

  return (
    <UserContext.Provider
      value={{
        userState,
        restoreUserContext,
        changeUserType,
        logIn,
        logOut,
        registerBuyer,
        registerAgent,
        upcomingtour
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
