/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, Fragment, useContext } from 'react';
import { useGlobal } from 'reactn';
import GoogleMapReact from 'google-map-react';
import { AnyReactComponent } from '.././GoogleMap/PropertyMap';
import UserContext, { UserType } from '../../contexts/UserContext';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

const CustomDiv = styled.div<any>`
  position: absolute;
  top: 3.5rem;
  right: 5%;
  /* transform: translateX(-50%); */
  z-index: 10;
  width: 300px;
`;

const CustomInputDiv = styled.div`
  &.aria-expanded: false;
`;

const Input: any = styled.input`
  width: 60%;
  height: 2.8rem;
  font-size: 10px;
  font-weight: bold;
  /* padding-top: 2rem; */
  padding-left: 2rem;
  padding-right: 0.2rem;
  box-shadow: 0rem 0.3rem 0.6rem rgba(0, 0, 0, 0.2);
  border-radius: 0.8rem;
  border: 0.1rem solid #365073;
  vertical-align: top;
`;

const CustomMapDiv = styled.div<any>`
  height: 100vh;
  padding-top: 26px;
  padding-right: 10px;
  padding-left: 10px;
`;

interface GoogleMapTourProps {
  linkedProperties?: any;
  linkedPropertiesData?: any;
  formId?: string;
  bestRoute?: any;
  showmap?: any;
  manualChangeProperties?: any;
  useManual?: any;
  numberingMarker?: any;
  setUseManual?: any;
  saveButtonData?: any;
  statusType?: string;
  serviceTime?: any;
  startTime?: any;
  endTime?: any;
  setIsAllAccepted: (val: boolean) => void;
  showReScheduleModal?: boolean;
  sourceLocation?: any;
  destinationLocation?: any;
  setLinkedPropertiesData?: any;
  setTourPropPage?: any;
  setShowMap?: any;
  setRunMap?: any;
  setPropId?: any;
  setPropDataDetails?: any;
  formData?: any;
  handleTogglingClicked?: string;
  filteredLinkedProperties?: any;
  linkedPropertiesOutOfBestRoute?: any;
  unlinkedPropertyClicked?: boolean;
  rescheduledPropData?: Array<any>;
  setIsRemoveScheduledPropClicked?: (val: string) => void;
  setRescheduledPropData?: (val: Array<any>) => void;
  selectedPropertiesCount?: number;
  setIsShowSchedulignStatusModal?: (val: boolean) => void;
  setGetDirectionsPropertyClicked?: (val: string) => void;
  setPropListingId?: (val: string) => void;
}

const GoogleMapTour: React.FC<GoogleMapTourProps> = ({
  linkedPropertiesData,
  setLinkedPropertiesData,
  formId,
  bestRoute,
  showmap,
  useManual,
  numberingMarker,
  setUseManual,
  saveButtonData,
  statusType,
  serviceTime,
  showReScheduleModal,
  sourceLocation,
  destinationLocation,
  setTourPropPage,
  setShowMap,
  setRunMap,
  setPropId,
  setPropDataDetails,
  formData,
  selectedPropertiesCount,
  filteredLinkedProperties,
  rescheduledPropData,
  setRescheduledPropData,
  setIsAllAccepted,
  setIsShowSchedulignStatusModal,
  setGetDirectionsPropertyClicked,
  setIsRemoveScheduledPropClicked,
  setPropListingId,
}) => {
  const [address, setAddress] = useState<any>('');
  const [linkedPropertiesDataMap, setLinkedPropertiesDataMap] = useState<
    Array<any>
  >([]);
  const { userState } = useContext(UserContext);
  const [bestRouteUpdated, setBestRouteUpdated] = useState<Array<any>>([]);

  const history = useHistory();
  const [location, setLocation] = useState<any>({
    lat: null,
    lng: null,
  });
  const [startrouteLocation, setStartRouteLocation] =
    useGlobal<any>('startroutedetail');
  const [endrouteLocation, setEndRouteLocation] =
    useGlobal<any>('endroutedetail');

  const HandleChangeAdd = async (data: any) => {
    setAddress(data);
  };

  useEffect(() => {
    setBestRouteUpdated(
      linkedPropertiesDataMap?.length > 0
        ? linkedPropertiesDataMap
        : filteredLinkedProperties
    );
  }, [linkedPropertiesDataMap, filteredLinkedProperties, bestRoute]);

  useEffect(() => {
    setLinkedPropertiesDataMap(
      filteredLinkedProperties?.length > 0
        ? filteredLinkedProperties
        : linkedPropertiesData
    );
  }, [filteredLinkedProperties, linkedPropertiesData]);

  const HandleSelectAdd = async (data: any) => {
    const result = await geocodeByAddress(data);
    const latlng = await getLatLng(result[0]);
    setLocation({
      lat: latlng?.lat,
      lng: latlng?.lng,
    });
    setAddress(data);
  };

  const getMapOptions = (maps: any) => {
    return {
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID,
        ],
      },
    };
  };

  const apiIsLoaded = (map: any, maps: any) => {
    
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer({
      draggable: true,
      map,
    });
    // const directionsDisplay = new google.maps.DirectionsRenderer({
    //   suppressMarkers: true,
    // })
    // map = new google.maps.Map(document.getElementById('map-canvas'), null)
    // directionsDisplay.setMap(map)

    directionsRenderer.setMap(map);
    directionsRenderer.setOptions({
      polylineOptions: {
        strokeColor: '#707070',
      },
    });
    // here we have to change the starting address lat lng
    // const origin = {
    //   lat: 42.244195,
    //   lng: -71.71987,
    // }
    // lat: 42.244195, lng: -71.71987

    //  here we have to change ending address lat lng
    // const destination = {
    //   lat: 42.146709,
    //   lng: -71.523084,
    // }

    // marker is set on above starting marker
    // const marker = new google.maps.Marker({
    //   position: location,
    //   map: map,
    // })

    const startMarker = new google.maps.Marker({
      position: location,
      map: map,
    });
    startMarker.setMap(map);

    // marker is set on above destination marker

    // const endMarker = new google.maps.Marker({
    //   position: endrouteLocation
    //     ? endrouteLocation
    //     : JSON.parse(localStorage.getItem('endroute')),
    //   label: {
    //     color: '#000',
    //     fontWeight: 'bold',
    //     text: 'E',
    //     fontSize: '25px',
    //   },
    //   map: map,
    // })

    // endMarker.setMap(map)
    // we can use state to toggle  between manaula routing and optimized routing
    // for manual routing we have to paas all the info draga and drop again and again

    /*  directionService.route(
    {
   origin: startrouteLocation
          ? startrouteLocation
          : JSON.parse(localStorage.getItem('startroute')),
        destination: endrouteLocation
          ? endrouteLocation
          : JSON.parse(localStorage.getItem('endroute')),
        travelMode: google.maps.TravelMode.DRIVING,

        it render every time whenthere is change in tour ordering
       waypoints: manualLocation.map((item: any, index: number) => {
         
          manual_route_index = index + 1

         
          return {
            location: new google.maps.LatLng(item?.lat, item?.lng),
            stopover: false,
          }
        }),
      },
      (result: any, status: any) => {

        if (status === google.maps.DirectionsStatus.OK) {
          directionsRenderer.setDirections(result)
        } else {
          console.error(`error fetching directions ${result}`)
        }
      },
    }
    )*/
    // this is for optimized routing
    // these checks for Undefined json error
    // let startLoc:any;
    // if(sourceLocation){

    const startLoc: any =
      typeof sourceLocation === 'object'
        ? sourceLocation
        : sourceLocation
          ? JSON?.parse(sourceLocation)
          : {};

    const endLoc: any =
      typeof destinationLocation === 'object'
        ? destinationLocation
        : destinationLocation
          ? JSON?.parse(destinationLocation)
          : {};
    const origin: string = statusType === 'afterRouteCreated'
      ? startLoc
        ? startLoc
        : JSON.parse(saveButtonData?.startrouteLocation)
      : startrouteLocation
        ? startrouteLocation
        : JSON.parse(localStorage.getItem('startroute'));

    const destination: string = statusType === 'afterRouteCreated'
      ? endLoc
        ? endLoc
        : JSON.parse(saveButtonData?.endrouteLocation)
      : endrouteLocation
        ? endrouteLocation
        : JSON.parse(localStorage.getItem('endroute'));
        console.log("API load", origin,"origin", destination, "destination", bestRoute, "bestRoute");

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
        // unitSystem: google.maps.UnitSystem.METRIC,
        waypoints: bestRoute?.map((item: any, index: number) => {
          // best_route_index = index + 1

          return {
            location: new google.maps.LatLng(
              useManual
                ? item?.geo?.lat
                  ? item?.geo?.lat
                  : item?.lat
                : item?.lat
                  ? item?.lat
                  : item?.geo?.lat,
              useManual
                ? item?.geo?.lng
                  ? item?.geo?.lng
                  : item?.lng
                : item?.lng
                  ? item?.lng
                  : item?.geo?.lng
            ),
            stopover: false,
          };
        }),
      },
      (result: any, status: any) => {
        if (status === google.maps.DirectionsStatus.OK) {
          directionsRenderer.setDirections(result);
        } else {
          console.log(`error fetching directions ${result}`);
        }
      }
    );
  };

  useEffect(() => {

    if (startrouteLocation) {
      localStorage.setItem('startroute', JSON.stringify(startrouteLocation));
    }
    if (endrouteLocation) {
      localStorage.setItem('endroute', JSON.stringify(endrouteLocation));
    }
  }, [startrouteLocation, endrouteLocation, showmap]);

  return (
    <Fragment>
      <CustomDiv className="places-container">
        <PlacesAutocomplete
          value={address}
          onChange={HandleChangeAdd}
          onSelect={HandleSelectAdd}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <CustomInputDiv>
              <Input
                {...getInputProps({
                  placeholder: 'Enter Place',
                })}
              />
              <div>
                {loading && <div>Loading....</div>}
                {suggestions.map((suggestion) => {
                  const style = suggestion.active
                    ? {
                      backgroundColor: '#d4d4d4',
                      cursor: 'pointer',
                      border: '1px solid black',
                      borderRadius: '5px',
                    }
                    : {
                      backgroundColor: 'rgba(255,255,255,0.7)',
                      cursor: 'pointer',
                      fontSize: '1.2rem',
                      borderBottom: '1px solid #d4d4d4',
                    };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        style,
                      })}
                    >
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </CustomInputDiv>
          )}
        </PlacesAutocomplete>
      </CustomDiv>
      <CustomMapDiv>
        {bestRoute?.length > 0 ? (
          <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals
            // style={{ height: '30vh', width: '100%' }}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_API_KEY,
            }}
            center={{
              lat: linkedPropertiesData?.[0]?.geo?.lat ?? 42.300756,
              lng: linkedPropertiesData?.[0]?.geo?.lng ?? -71.518006,
            }}
            // lat: 42.300756, lng: -71.518006
            defaultZoom={8}
            onGoogleApiLoaded={({ map, maps, ref }) => {
              apiIsLoaded(map, maps);
            }}
            options={getMapOptions}
          >
            {bestRouteUpdated?.map((myVal: any, index: number) => {
              return (
                <AnyReactComponent
                  property_type={
                    userState?.userType === UserType.BUYER &&
                      history?.location?.pathname.includes('/common')
                      ? 'open_house'
                      : ''
                  }
                  id={myVal?.mlsId}
                  lat={myVal?.geo?.lat}
                  lng={myVal?.geo?.lng}
                  city={myVal?.address?.city || 'N/A'}
                  county={myVal?.geo?.county || 'N/A'}
                  photos={myVal?.photos?.[0] || 'N/A'}
                  streetNumber={myVal?.address?.streetNumber || 'N/A'}
                  streetName={myVal?.address?.streetName || 'N/A'}
                  state={myVal?.address?.state || 'N/A'}
                  postalCode={myVal?.address?.postalCode || 'N/A'}
                  listPrice={myVal?.listPrice || 'N/A'}
                  subTypeText={myVal?.property?.subTypeText || 'N/A'}
                  bedrooms={myVal?.property?.bedrooms || 'N/A'}
                  bathrooms={
                    myVal?.property?.bathsFull +
                    0.5 * myVal?.property?.bathsHalf ?? 1
                  }
                  area={myVal?.property?.area || 'N/A'}
                  text={<div>44</div>}
                  isOutOfTheBox={myVal?.isOutOfTheBox}
                  isSearchLocBox={myVal?.isSearchLocBox}
                  setLinkedPropertiesDataMap={setLinkedPropertiesDataMap}
                  linkedPropertiesData={linkedPropertiesData}
                  filteredLinkedProperties={filteredLinkedProperties}
                  setLinkedPropertiesData={setLinkedPropertiesData}
                  linkedPropertiesDataMap={linkedPropertiesDataMap}
                  setTourPropPage={setTourPropPage}
                  setShowMap={setShowMap}
                  setRunMap={setRunMap}
                  setUseManual={setUseManual}
                  setPropId={setPropId}
                  bestRoute={bestRoute}
                  propertyId={myVal?.mlsId}
                  setPropDataDetails={setPropDataDetails}
                  propertyDetails={myVal}
                  setIsRemoveScheduledPropClicked={
                    setIsRemoveScheduledPropClicked
                  }
                  setRescheduledPropData={setRescheduledPropData}
                  rescheduledPropData={rescheduledPropData}
                  formData={formData}
                  index={
                    myVal?.isSearchLocBox === 'YES' ||
                      myVal?.isOutOfTheBox === 'YES'
                      ? null
                      : index + 1
                  }
                  numberingMarker={numberingMarker}
                  listingId={myVal?.listingId}
                  formId={formId}
                  serviceTime={serviceTime}
                  startTime={myVal?.startTime}
                  endTime={myVal?.endTime}
                  isAccepted={myVal?.isAccepted}
                  isRejected={myVal?.isRejected}
                  isListed={myVal?.isListed}
                  listingAgent={myVal?.listingAgent}
                  bestRouteId={myVal?.bestRouteId}
                  propData={myVal}
                  showReScheduleModal={showReScheduleModal}
                  role={myVal?.role}
                  setIsAllAccepted={setIsAllAccepted}
                  selectedPropertiesCount={selectedPropertiesCount}
                  setIsShowSchedulignStatusModal={
                    setIsShowSchedulignStatusModal
                  }
                  setGetDirectionsPropertyClicked={
                    setGetDirectionsPropertyClicked
                  }
                  setPropListingId={setPropListingId}
                />
              );
            })}
          </GoogleMapReact>
        ) : null}

        {bestRoute?.length === 0 && (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_API_KEY,
            }}
            center={{
              lat: linkedPropertiesData?.[0]?.geo?.lat ?? 42.300756,
              lng: linkedPropertiesData?.[0]?.geo?.lng ?? -71.518006,
            }}
            defaultZoom={10}
            options={getMapOptions}
            yesIWantToUseGoogleMapApiInternals
          >
            {linkedPropertiesData?.map((data: any) => {
              return (
                <AnyReactComponent
                  property_type={data?.propertyCategory}
                  id={data?.mlsId}
                  lat={data?.geo?.lat}
                  lng={data?.geo?.lng}
                  city={data?.address?.city || 'N/A'}
                  county={data?.geo?.county || 'N/A'}
                  photos={data?.photos?.[0] || 'N/A'}
                  streetNumber={data?.address?.streetNumber || 'N/A'}
                  streetName={data?.address?.streetName || 'N/A'}
                  state={data?.address?.state || 'N/A'}
                  postalCode={data?.address?.postalCode || 'N/A'}
                  listPrice={data?.listPrice || 'N/A'}
                  subTypeText={data?.property?.subTypeText || 'N/A'}
                  bedrooms={data?.property?.bedrooms || 'N/A'}
                  bathrooms={
                    data?.property?.bathsFull +
                    0.5 * data?.property?.bathsHalf ?? 1
                  }
                  area={data?.property?.area || 'N/A'}
                  text={'marker'}
                  numberingMarker={numberingMarker}
                  listingId={data?.listingId}
                  formId={formId}
                  serviceTime={serviceTime}
                  startTime={data?.startTime}
                  endTime={data?.endTime}
                  bestRoute={bestRoute}
                  isAccepted={data?.isAccepted}
                  isRejected={data?.isRejected}
                  isListed={data?.isListed}
                  listingAgent={data?.listingAgent}
                  propData={data}
                  setRescheduledPropData={setRescheduledPropData}
                  rescheduledPropData={rescheduledPropData}
                  showReScheduleModal={showReScheduleModal}
                  role={data?.role}
                  propertyId={data?.mlsId}
                  setIsRemoveScheduledPropClicked={
                    setIsRemoveScheduledPropClicked
                  }
                  formData={formData}
                  setIsAllAccepted={setIsAllAccepted}
                  selectedPropertiesCount={selectedPropertiesCount}
                  setIsShowSchedulignStatusModal={
                    setIsShowSchedulignStatusModal
                  }
                  setGetDirectionsPropertyClicked={
                    setGetDirectionsPropertyClicked
                  }
                  setPropListingId={setPropListingId}
                  setPropId={setPropId}
                  setTourPropPage={setTourPropPage}
                  setShowMap={setShowMap}
                  setRunMap={setRunMap}
                  setUseManual={setUseManual}
                />
              );
            })}
          </GoogleMapReact>
        )}
      </CustomMapDiv>
    </Fragment>
  );
};
export default GoogleMapTour;
