import { Col, Row } from "antd";
import { useEffect, useState } from "react";

import { completedShowingsPropertyStatsSingle, completedShowingsPropertyStatsTour, getTotalViewsCount, totalFavoritesSpecificProperty } from "../../../services/NavihomeService";
import CustomSpinner from "../../spinner/CustomSpinner";
import "./styles/statistics.css";
interface IStatisticsMainProps {
  mlsId: number;
  token: string;
}

const StatisticsMain: React.FunctionComponent<IStatisticsMainProps> = ({ mlsId, token }) => {

  const [totalFavoriteProperty, setTotalFavoriteProperty] = useState<any>({});
  const [totalViews, setTotalViews] = useState<number>(0);
  const [completedShowings, setCompletedShowings] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const getTotalFavorites = async () => {
    const totalProperty: any = await totalFavoritesSpecificProperty(mlsId, token);
    setTotalFavoriteProperty(totalProperty)

  }
  const getTotalViews = async () => {
    try {
      const res: any = await getTotalViewsCount(mlsId);
      setTotalViews(res?.count);
      // setTotalFavoriteProperty({viewed: res});
    } catch (error) {
      console.log("ERROR: ", error);

    }
  }
  const completedShowingsPropertyStats = async () => {
    try {
      setIsLoading(true);
      const completedShowingsPropertyStatsSingleRes: any = await completedShowingsPropertyStatsSingle(mlsId);
      const completedShowingsPropertyStatsTourRes: any = await completedShowingsPropertyStatsTour(mlsId,"completed");    
      console.log("test", completedShowingsPropertyStatsSingleRes,completedShowingsPropertyStatsSingleRes);
        
      setCompletedShowings(completedShowingsPropertyStatsSingleRes?.length > 0 ? completedShowingsPropertyStatsSingleRes?.length : 0 + completedShowingsPropertyStatsTourRes?.length > 0 ? completedShowingsPropertyStatsTourRes?.length : 0)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log("Error", error);
    }
  }
  useEffect(() => {
    getTotalFavorites();
    getTotalViews();
    completedShowingsPropertyStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div >
      {isLoading &&  <CustomSpinner/>}
      <Row>
        <Col xs={24} sm={6} md={6} lg={6} xl={6}>
          <div className="statCont">
            <div className="statContInside">
              <div className="statInfo">{totalViews ?? 0}</div>
              <div className="statTitle">Total Views</div>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={6} md={6} lg={6} xl={6}>
          <div className="statCont">
            <div className="statContInside">

              <div className="statInfo">{totalFavoriteProperty?.foverites ?? 0}</div>
              <div className="statTitle">Total Favorites</div>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={6} md={6} lg={6} xl={6}>
          <div className="statCont">
            <div className="statContInside">

              <div className="statInfo">{totalFavoriteProperty?.requested ?? 0}</div>
              <div className="statTitle">Requested Showings</div>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={6} md={6} lg={6} xl={6}>
          <div className="statCont">
            <div className="statContInside">
              <div className="statInfo">{completedShowings}</div>
              <div className="statTitle">Completed Showings</div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default StatisticsMain;
