import React,{useContext, useState} from 'react'
import Button, { ButtonType } from '../../components/Button'
import { useHistory } from 'react-router-dom'
import globalimage from '../../assets/find-an-agent-page/Group 1641.png'
import styled from '@emotion/styled'
import FindAnAgentModal from '../../modals/findAnAgentModal'
import UserContext from '../../contexts/UserContext'

const CustomDiv = styled.div`
  // background-color:yellow;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`
export const CustomLeftDiv = styled.div`
  align-self: center;
  padding-right: 20px;
  @media screen and (max-width: 600px) {
    padding-right: 0;
  }
`
export const CustomRightDiv = styled.div`
  @media screen and (max-width: 600px) {
    padding: 16px;
    display: flex;
    width: 100%;
    justify-content: center;
  }
`
export const CustomImage = styled.img`
  width: 450px;
  height: 450px;
  @media screen and (max-width: 860px) {
    width: 350px;
    height: 350px;
  }
  @media screen and (max-width: 600px) {
    width: 450px;
    height: 450px;
  }
  @media screen and (max-width: 500px) {
    width: 390px;
    height: 390px;
  }
  @media screen and (max-width: 400px) {
    width: 280px;
    height: 280px;
  }
`
const CustomDashboardHeading = styled.h1`
  font-size: 46px;
  font-weight: 600px;
`
const CustomPara = styled.p`
  font-size: 19px;
`
interface EmptyDashboardTabPropsModel {
  title?: string
  isBuyerLinked?: boolean
  isButton?: boolean
}
const EmptyDashboardForUnlinkedBuyer: React.FC<EmptyDashboardTabPropsModel> = ({
  title,
  isBuyerLinked,
  isButton,
}): JSX.Element => {
  const history = useHistory()
  const { userState,} = useContext(UserContext)
  const [isAgentModal, setIsAgentModal] = useState(false)

  const scheduleTour = () => {
    if (
      userState?.isLoggedIn &&
      userState?.userType === "BUYER" &&
      !isBuyerLinked
    ) {
      setIsAgentModal(!isAgentModal);
      return;
    }  
  };
  const closeAction2 = ()=> {
    setIsAgentModal(!isAgentModal);
  }
  return (
    <CustomDiv className="empty-dashboard-page">
      <CustomLeftDiv>
        {!isBuyerLinked && (
          <>
            <CustomPara>{title}</CustomPara>
            <br />
            <br />
            {isButton ? (
              <></>
            ) : (
              <Button
                type={ButtonType.PRIMARY}
                text={'FIND AN AGENT'}
                clickHandler={scheduleTour}
              />
            )}
          </>
        )}
        {isAgentModal && (
        <FindAnAgentModal
          isShowing={isAgentModal}
          closeAction={closeAction2}
          text="calendar"
        />
      )}
      </CustomLeftDiv>
      <CustomRightDiv>
        <CustomImage src={globalimage} alt="image with a key" />
      </CustomRightDiv>
    </CustomDiv>
  )
}

export default EmptyDashboardForUnlinkedBuyer
