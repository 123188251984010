import { message, Popconfirm } from "antd";
import moment from "moment";
import React, { useContext } from "react";
import Delete from "../../../assets/CalendarPopover/Button - Delete.svg";
import Edit from "../../../assets/CalendarPopover/Button - Edit.svg";
import UserContext from "../../../contexts/UserContext";
import { deletePropertyCalendarByID } from "../../../services/NavihomeService";
import "./styles/calendarPopover.css";

interface ICalendarSchedulePopoverProps {
  args: any;
  calenderEvent?: () => void;
  setSelectRecurringInstanceId: (value: any) => void;
  setIsShowingViewCalendarModal?: (value: boolean) => void;
  setIsAvailablePopoverVisible?: (value: boolean) => void;
  setEditClicked?: (value: boolean) => void;
  handleVisibleChange?: Function;
}

const PropertyCalendarPopoverContent: React.FunctionComponent<
  ICalendarSchedulePopoverProps
> = ({
  args,
  calenderEvent,
  setIsAvailablePopoverVisible,
  setIsShowingViewCalendarModal,
  setSelectRecurringInstanceId,
  setEditClicked,
  handleVisibleChange,
}) => {

  const { userState } = useContext(UserContext);
  const confirm = async (eventId: number) => {
    try {
      const result = await deletePropertyCalendarByID(
        eventId,
        userState?.agent?.token
      );
      setIsAvailablePopoverVisible(false);
      calenderEvent();
      message.success("Event Deleted!");
    } catch (error) {
      console.log("ERROR:", error);
    }
  };

  const cancel = (e: React.MouseEvent<HTMLElement>) => {
    message.error("Click on No");
  };
  return (
    <div className="noBase" style={{ padding: "0" }}>
      <div className="content">
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <>
            <img
              src={Edit}
              alt="edit"
              onClick={() => {
                handleVisibleChange(args, true);
                setEditClicked(true);
                setIsShowingViewCalendarModal(true);
                setIsAvailablePopoverVisible(false);
                // setEditClicked(true);
              }}
              style={{
                color: "#004876",
                cursor: "pointer",
              }}
            />
            <Popconfirm
              title="Are you sure to delete this event?"
              onConfirm={() => {
                confirm(args?.event?._def?.extendedProps?.eventId);
                // confirm(args?.event?._def?.extendedProps?.viewPropertyCalendarId)
              }}
              onCancel={cancel}
              okText="Yes"
              cancelText="No">
              <img
                src={Delete}
                alt="delete"
                style={{ margin: "0 18px", cursor: "pointer" }}
              />
            </Popconfirm>

            {/* <img
              src={Close}
              style={{ color: '#004876', cursor: 'pointer' }}
              onClick={() => setIsAvailablePopoverVisible(false)}
            /> */}
          </>
          {/* <img src={Edit} alt="edit" style={{ cursor: 'pointer' }} /> */}
        </div>
        <p className="content-heading">
          {args?.event?.title?.length > 0 ? <>{args?.event?.title}</> : <></>}
        </p>
        <span className="date">
          {moment
            .utc(args?.event?._instance?.range?.start, "DD-MM-YYYY")
            .format("Do MMMM")}{" "}
          &bull; {moment(args?.event?.start).format("h:mm A")}-
          {moment(args?.event?.end).format("h:mm A")}
        </span>
        <div className="every-week">
          {args?.event?._def?.extendedProps?.rStatus}
        </div>
      </div>
    </div>
  );
};

export default PropertyCalendarPopoverContent;
