import { ThemeProvider } from './ThemeContext';
import { UserProvider } from './UserContext';
import { PropertyFiltersProvider } from './PropertyFiltersContext';
import { AgentProvider } from './AgentContext';

const combineProviders = (...providers: React.FC[]): React.FC => {
  return providers.reduce((AccumulatedProviders, CurrentProvider) => {
    return ({ children }: React.ComponentProps<React.FC>): JSX.Element => {
      return (
        <AccumulatedProviders>
          <CurrentProvider>{children}</CurrentProvider>
        </AccumulatedProviders>
      );
    }
  });
}

const providers = [
  ThemeProvider,
  UserProvider,
  AgentProvider,
  PropertyFiltersProvider
]

export const AppContextProvider = combineProviders(...providers);


