import 'react-toastify/dist/ReactToastify.css';
import {toast, ToastOptions, TypeOptions} from 'react-toastify';

export const notification = (message: string, type: TypeOptions) => {
  const requestOption: ToastOptions = {
    position: 'top-center',
    autoClose: 4000,
    type: type,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    theme: 'colored',
    bodyStyle:({
        fontSize: '20px',
        borderRadius:'10px'
    })
  };
  toast(message, requestOption);
};