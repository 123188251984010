import React from 'react';
import DashboardBuyerPage from './DashboardBuyerPage';

const BuyBuyerPage = () => {
  return (
    <>
       <DashboardBuyerPage isBuy ={true} isSell = {false} />
    </>
  );
};

export default BuyBuyerPage;
