import { useState } from "react";
import validator from "email-validator";
import Button, { ButtonType } from "../components/Button";
import Checkbox, { CheckboxType } from "../components/Checkbox";
import TextField, { TextFieldIconType, TextFieldType } from "../components/TextField";
import PhoneInput from "react-phone-input-2";
import styled from "@emotion/styled";
import { useHistory } from "react-router";
import capcha from "../assets/images/logo-captcha.png";
import RequestDemoConfirmationModal from "../modals/RequestDemoConfirmationModal";
import { useMediaQuery } from "react-responsive";
import { notification } from "../components/notification";
const RequestDemoPage: React.FC = () => {
  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [enquiryDetails, setEnquiryDetails] = useState("");
  const [didCheckCheckbox, setDidCheckCheckbox] = useState(false);
  const [isShowingConfirmationModal, setIsShowingConfirmationModal] = useState(false);
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: 890 });

  const CustomRegHeading = styled.h1`
    font-size: 24px;
    font-weight: 600;
    @media screen and (max-width: 768px) {
      font-size: 28px !important;
    }
    @media screen and (max-width: 576px) {
      font-size: 22px !important;
      :after {
        display: none;
      }
    }
  `;
  const CustomPhoneInput = styled(PhoneInput)`
    height: 7rem;
    padding: 0px;
    border-radius: 5px;
    &.react-tel-input .country-list .search-box {
      text-transform: capitalize;
    }
  `;
  const CustomBackP = styled.p`
    display: inline-block;
    margin-left: 12px;
    margin-top: 16px;
    cursor: pointer;
    font-size: 17px;
    &:hover {
      text-decoration: underline;
      font-weight: 600;
    }
  `;
  const submitDemoRequest = () => {
    if (name === "") {
      return notification("Please enter your Name.", "error");
    }
    if (emailId === "") {
      return notification("Please enter Email.", "error");
    }
    if (!validator.validate(emailId)) {
      return notification("Please enter Valid Email.", "error");
    }
    if (phoneNumber === "") {
      return notification("Please enter  Phone Number.", "error");
    }
    if (didCheckCheckbox === false) {
      return notification("Please verify, I'm not a robot.", "error");
    }
    setIsShowingConfirmationModal(true);
  };

  const handleConfirmationModalClose = () => {
    setIsShowingConfirmationModal(false);
  };

  return (
    <>
      <CustomBackP
        onClick={() => {
          history.push("/dashboard");
        }}
      >
        Back
      </CustomBackP>
      <div className="request-demo-page__main">
        <div className="request-demo-page__container">
          <div className="request-demo-page__navihome-demo">
            <CustomRegHeading style={{ marginBottom: "8rem" }}>
              <span>NaviHome </span>
              <span style={{ borderBottom: "3px solid #C2D500" }}>Demo</span>
            </CustomRegHeading>

            <iframe
              style={{ borderRadius: "25px", marginRight: "50px" }}
              className="request-demo-page__video"
              width="100%"
              height="680"
              src="https://www.youtube.com/embed/4X_IW3kqr3k"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="request-demo-page__find-out-more">
            <CustomRegHeading>
              <span>Find </span>
              <span style={{ borderBottom: "3px solid #C2D500" }}>out more</span>
            </CustomRegHeading>
            <br />
            <p>
              Watch the demo to see how you can navigate and make the most of our app. Get started with our free trial to explore more! Have any questions before you start using NaviHome? Reach out to
              use so we can help you get started!
            </p>
            <br />
            <br />
            <form className="form_tag_request_demo">
              <TextField
                value={name}
                setValue={setName}
                placeholder="Jane Doe"
                label="Name"
                type={TextFieldType.ICON_AND_LABEL}
                icon={TextFieldIconType.USER}
                isRequired={true}
                width={isMobile ? undefined : 38.4}
              />
              <TextField
                value={emailId}
                setValue={setEmailId}
                placeholder="janedoe@hotmail.com"
                label="Email"
                type={TextFieldType.ICON_AND_LABEL}
                icon={TextFieldIconType.EMAIL}
                isRequired={true}
                width={isMobile ? undefined : 38.4}
              />

              <div>
                <h5
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    position: "relative",
                    top: "10px",
                    color: "black",
                    fontSize: "14px"
                  }}
                >
                  Phone Number*
                </h5>
                <CustomPhoneInput
                  value={phoneNumber}
                  inputStyle={{
                    display: "flex",
                    justifyContent: "flex-start",
                    bottom: "-2rem",
                    right: "-7rem",
                    height: "5.2rem",
                    width: "100%",
                    marginTop: "14px",
                    border: "1px solid #365073 ",
                    boxShadow: "0px 1px 5px #888888",
                    borderRadius: "8px"
                  }}
                  inputClass="register-agent-page__form-item"
                  inputProps={{
                    setValue: { setPhoneNumber }
                  }}
                  onChange={(phoneNumber: any) => {
                    if (phoneNumber.length >= 10) {
                      setPhoneNumber(phoneNumber);
                    } else {
                      setPhoneNumber("");
                    }
                  }}
                  dropdownStyle={{
                    width: "20rem",
                    position: "relative",
                    bottom: "21rem",
                    overflowX: "hidden"
                  }}
                  country={"us"}
                  specialLabel=""
                  buttonStyle={{
                    position: "absolute",
                    bottom: "45.5rem",
                    height: "auto",
                    top: "2.5rem"
                  }}
                  placeholder="+1 (404) 123 4567"
                  disableDropdown={false}
                  autoFormat={true}
                  enableSearch={true}
                />
              </div>

              <TextField
                value={enquiryDetails}
                setValue={setEnquiryDetails}
                placeholder="Anything you would like to know?"
                label="Inquiry Details"
                type={TextFieldType.BIG}
                icon={TextFieldIconType.PHONE}
                isRequired={false}
                width={isMobile ? undefined : 38.4}
              />
              <div className="request-demo-page__captcha">
                <Checkbox type={CheckboxType.REGULAR} value={didCheckCheckbox} setValue={setDidCheckCheckbox} text="I am not a robot" />
                <img src={capcha} alt="Captcha Logo" />
              </div>
              <div className="request-demo-page__submit-b">
                <Button className="request-demo-page__submit-button" type={ButtonType.PRIMARY} text="Submit" clickHandler={submitDemoRequest} />
              </div>
            </form>
          </div>
        </div>
        <RequestDemoConfirmationModal isShowing={isShowingConfirmationModal} closeAction={handleConfirmationModalClose} />
      </div>
    </>
  );
};

export default RequestDemoPage;
