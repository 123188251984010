/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { UserOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Avatar, Button, Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory, useLocation } from "react-router-dom";
import Category from "../../assets/find-an-agent-page/Icon - Company.svg";
import Email from "../../assets/find-an-agent-page/Icon - Email.svg";
import Location from "../../assets/find-an-agent-page/Icon - Location.svg";
import Web from "../../assets/find-an-agent-page/Icon - Website.svg";
import Phone from "../../assets/find-an-agent-page/Phone icon.svg";
import { notification } from "../../components/notification";
import PropertyFiltersContext from "../../contexts/PropertyFiltersContext";
import UserContext from "../../contexts/UserContext";
import FindAnAgentConfirmModal from "../../modals/FindAnAgentConfirmModal";
import {
  getAgentAccountSettings,
  getCertificates,
  inviteAgentByBuyer,
  postMessageToUser,
} from "../../services/NavihomeService";
import ConnButton, { ButtonType } from "../Button";
import CustomSpinner from "../spinner/CustomSpinner";

interface AboutAgentPropsModel {
  agentName: string;
  agentAbout?: string;
  imageUrl?: string;
  designationsAndCertifications?: string;
  agentPhone?: string;
  agentEmail?: string;
  agentWeb?: string;
  agentLocation?: string;
  agentCompany?: string;
  agentId?: number;
  avatarUrl?: string;
  goToSingleAngent: (val: number) => void;
  agentTagline?: string;
  agentPreferredContact?: string;
  agentCompanyLogo?: string;
  agentSubscription_type?: string;
}

const CutomWebDetailsDiv = styled.div`
  display: flex;
  margin: 10px 4px;
  align-items: center;
`;

const CustomHeading = styled.h1`
  font-size: 32px;
  font-weight: 600;
  @media screen and (max-width: 1376px) {
    &:after {
      display: none;
    }
  }
`;
const CustomAboutPara = styled.p`
  padding: 24px 0;
  @media screen and (max-width: 502px) {
    padding: 5px 0;
  }
`;
const CustomTaglinePara = styled.p`
  padding: 24px 0;
  color: #eb752f;
  font-weight: 600;
  @media screen and (max-width: 502px) {
    padding: 5px 0;
  }
`;
const CustomDetailedDiv = styled.div`
  padding: 0px 18px;
`;
const CustomDetailRow = styled.div`
  @media screen and (min-width: 992px) {
    display: flex;
    justify-content: center;
  }
`;

const CustomDetailedPara = styled.div`
  margin-left: 20px;
  margin-bottom: 5px;
  padding: 0px;
  font-size: 16px;
`;

const CustomBackSpan = styled.div`
  display: inline-block;
  font-size: 17px;
  margin-left: 16px;
  margin-top: 16px;
  &:hover {
    // font-weight: 600;
    text-decoration: underline;
  }
`;
const CustomGettingStartedHeading = styled.h1`
display : flex;
justify-content: right;
color: #004876;
font-size: 20px;
  @media screen and (max-width: 768px) {
    margin-left: 20px
  }
  @media screen and (max-width: 1368px) {
    margin-left: 20px
  }
  @media screen and (max-width: 576px) {
    margin-left: 20px
    :after {
      display: none;
    }
  }
  position: relative;
  bottom: 3%;
`;
const CustomRow = styled(Row)`
  display: flex;
  justify-content: end;
  margin-bottom: 5rem;
  margin-top: 2rem;
`;
const CustomRow2 = styled(Row)`
  margin-bottom: 5rem;
  margin-top: 2rem;
`;
const CustomDiv = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: 750px) {
    flex-direction: column;
  }
`;

const ConnectButton = styled(Button)`
  background-color: var(--color-primary);
  border-radius: 0.7rem;
  border: none;
  height: 3.8rem;
  font-size: 1.7rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem var(--color-shadow);
  padding: 0rem 3rem;

  .p {
    color: var(--color-white);
    font-weight: 400;
  }
  :hover {
    background-color: var(--color-secondary);
  }

  @media screen and(max-width:820px) {
    margin-top: 10px;
  }
  @media (max-width: 576px) {
    width: 100%;
    text-align: center;
  }
`;
const AboutAgent: React.FC<AboutAgentPropsModel> = ({
  agentName,
  agentAbout,
  imageUrl,
  designationsAndCertifications,
  agentPhone,
  agentEmail,
  agentWeb,
  agentLocation,
  agentCompany,
  agentCompanyLogo,
  agentId,
  avatarUrl,
  goToSingleAngent,
  agentTagline,
  agentSubscription_type,
  agentPreferredContact,
}) => {

  const [isBuyerLinked, setIsBuyerLinked] = useState<boolean>(false);
  const { userState } = useContext(UserContext);
  const [certificateInfo, setCertificateInfo] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isConn, setIsConn] = useState<boolean>(false);
  const {
    setSocketCall,
    setCreateNotificationsInfo,
    setGetNotificationsUserId,
  } = useContext(PropertyFiltersContext);
  const [isShowFindAnAgentConfirmModal, setIsShowFindAnAgentConfirmModal] =
    useState<boolean>(false);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let param = useQuery();

  const history = useHistory();
  const isXS = useMediaQuery({ maxWidth: 576 });
  const checkIBuyersLinked = () => {
    setIsLoading(true);
    try {
      setIsBuyerLinked(userState?.buyer?.isLinked);
      setIsLoading(false);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getAllCertificationsAndDesignations: any = async () => {
    setIsLoading(true);
    try {
      await getCertificates(agentId, userState.buyer.token).then((res) => {

        setCertificateInfo(res);
        setIsLoading(false);
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };


  const backButtonHandler = () => {
    if (userState?.buyer?.isLinked) history.push("/buy");
    else {
      goToSingleAngent(agentId);
    }
  };

  let connectAgent = async (name: string, email: string, message: string) => {
    try {
      setIsLoading(true);

      let preferredContact: string = agentPreferredContact ?? "",
        messageType: string = "No";
      const agentAccountSettingsResult: any = await getAgentAccountSettings(
        agentId
      );
     
      if (agentAccountSettingsResult?.length > 0) {
        const lastIndex: number = agentAccountSettingsResult?.length - 1;
        messageType =
          agentAccountSettingsResult?.[lastIndex]?.notifyinviteSaleAgent;
      }
      const result: any = await inviteAgentByBuyer(
        agentId,
        "invite",
        message,
        0,
        name,
        email,
        userState?.buyer?.buyerId,
        preferredContact === "CONTACT_RADIO_BOTH" ||
          preferredContact === "CONTACT_RADIO_EMAIL"
          ? messageType ?? "Not Real Time"
          : "Not Real Time"
      );
      if (!result.error) {
        setCreateNotificationsInfo(String(Math.floor(Math.random() * 100 + 1)));
        setGetNotificationsUserId(agentId);
        if (
          messageType === "Realtime" &&
          (preferredContact === "CONTACT_RADIO_BOTH" ||
            preferredContact === "CONTACT_RADIO_PHONE")
        ) {
          postMessageToUser(agentPhone ?? "", message ?? "");
        }
        setIsLoading(false);
        setIsConn(true);

        return notification(`Request sent successfully.`, "success");
      } else {
        setIsLoading(false);
        return;
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return;
    }
  };

  const confirmModalHandler = () => {
    setIsShowFindAnAgentConfirmModal(!isShowFindAnAgentConfirmModal);
  };

	useEffect(() => {
    checkIBuyersLinked();
    getAllCertificationsAndDesignations();
  }, []);
  return (
    <>
      <CustomBackSpan onClick={backButtonHandler} style={{ cursor: "pointer" }}>
        Back
      </CustomBackSpan>

      <CustomDiv className="find-an-agent-page-about_agent-main">
        {isLoading && <CustomSpinner />}
        {agentName?.length > 0 ? (
          <>
            <Row
              className="find-an-agent-page-about_agent"
              style={{ minWidth: "80%" }}>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <CustomRow2>
                  <Col>
                    <CustomHeading style={{ paddingLeft: "20px" }}>
                      {agentName.length > 0 ? agentName : "..."}
                    </CustomHeading>
                  </Col>
                </CustomRow2>
                <Row style={{ marginBottom: "4rem", justifyContent: "center" }}>
                  <Avatar
                    // size={isXS ? 138 : 172}
                    size={{
                      xs: 124,
                      sm: 216,
                      md: 216,
                      lg: 216,
                      xl: 280,
                      xxl: 280,
                    }}
                    icon={
                      imageUrl !== undefined && (agentSubscription_type === 'Navihome Advanced Plan' || agentSubscription_type === 'Navihome Premimum Plan') ? (
                        <img src={imageUrl} alt=".." />
                      ) : (
                        <UserOutlined />
                      )
                    }
                  />
                </Row>
                <CustomDetailRow>
                  <CustomDetailedDiv>
                    <CutomWebDetailsDiv>
                      <img src={Phone} alt="..." />
                      <CustomDetailedPara>+{agentPhone}</CustomDetailedPara>
                    </CutomWebDetailsDiv>
                    <CutomWebDetailsDiv>
                      <img src={Email} alt="..." />
                      <CustomDetailedPara>{agentEmail}</CustomDetailedPara>
                    </CutomWebDetailsDiv>
                    <CutomWebDetailsDiv>
                      <img src={Location} alt="..." />
                      <CustomDetailedPara>{agentLocation}</CustomDetailedPara>
                    </CutomWebDetailsDiv>
                    <CutomWebDetailsDiv>
                      <img src={Web} alt="..." />
                      <CustomDetailedPara>{agentWeb.length > 0 && (agentSubscription_type === 'Navihome Advanced Plan' || agentSubscription_type === 'Navihome Premimum Plan') ? agentWeb : '...'}</CustomDetailedPara>
                    </CutomWebDetailsDiv>
                    <CutomWebDetailsDiv>
                      <img src={Category} alt="..." />
                      <CustomDetailedPara>{agentCompany}</CustomDetailedPara>
                    </CutomWebDetailsDiv>
                  </CustomDetailedDiv>
                </CustomDetailRow>
              </Col>

              <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                <CustomRow>
                  {!isXS &&
                    isBuyerLinked &&
                    userState?.buyer?.comsumerType?.toLowerCase() !== "seller" &&
                    userState?.buyer?.buyerAgentName?.length > 0 &&
                    param.get("type") !== "sell" && (
                      <Col>
                        <ConnButton
                          type={ButtonType.PRIMARY}
                          text={"REMOVE AGENT"}
                          clickHandler={() => {
                            setIsShowFindAnAgentConfirmModal(true);
                          }}
                          className="find-agent-page-connect_now-button"
                        />
                      </Col>
                    )}
                  {!isXS &&
                    isBuyerLinked &&
                    userState?.buyer?.comsumerType?.toLowerCase() ===
                    "seller" &&
                    param.get("type") === "buy" && (
                      <Col>
                        <ConnButton
                          type={ButtonType.PRIMARY}
                          text={"REMOVE AGENT"}
                          clickHandler={() => {
                            setIsShowFindAnAgentConfirmModal(true);
                          }}
                          className="find-agent-page-connect_now-button"
                        />
                      </Col>
                    )}
                  {!isXS &&
                    !isBuyerLinked &&
                    userState?.buyer?.comsumerType?.toLowerCase() !==
                    "seller" && (
                      <Col>
                        <ConnectButton
                          type="primary"
                          onClick={() => {
                            // recieve(String(Math.floor(Math.random() * 10) + 10));
                            setSocketCall(String(Math.random() * 10) + 10);
                            // recieve();
                            // SocketEventCreator()
                            if (!agentName || !agentEmail) {
                              history.push("/find-agent?type=buyer");
                            } else {
                              connectAgent(
                                agentName,
                                agentEmail,
                                `${userState?.buyer?.name.toUpperCase()} wants to connect with you. Accept the request to help them buy their next home`
                              );
                            }
                          }}
                          disabled={isConn}>
                          CONNECT NOW
                        </ConnectButton>
                        {/* <ConnButton
                          type={ButtonType.PRIMARY}
                          text={'CONNECT NOW'}
                          disable={true}
                          clickHandler={() => {
                            // recieve(String(Math.floor(Math.random() * 10) + 10));
                            setSocketCall(String(Math.random() * 10) + 10);
                            // recieve();
                            // SocketEventCreator()
                            if (!agentName || !agentEmail) {
                              history.push('/find-agent?type=buyer');
                            } else {
                              connectAgent(
                                agentName,
                                agentEmail,
                                `${userState?.buyer?.name.toUpperCase()} wants to connect with you. Accept the request to help them buy their next home`
                              );
                            }
                          }}
                          className="find-agent-page-connect_now-button"
                        /> */}
                      </Col>
                    )}
                  {!isXS &&
                    isBuyerLinked &&
                    param?.get("type") === "buyer" &&
                    (userState?.buyer?.comsumerType?.toLowerCase() ===
                      "seller" ||
                      userState?.buyer?.buyerAgentName?.length <= 0) && (
                      <Col>
                        <ConnButton
                          type={ButtonType.PRIMARY}
                          text={"CONNECT NOW"}
                          isDisabled={isConn}
                          clickHandler={() => {
                            connectAgent(
                              agentName,
                              agentEmail,
                              `${userState?.buyer?.name.toUpperCase()} wants to connect with you. Accept the request to help them buy their next home`
                            );
                          }}
                          className="find-agent-page-connect_now-button"
                        />
                      </Col>
                    )}
                </CustomRow>

                <Row justify="space-between" style={{ display: 'flex', alignItems: 'center' }}>
                  <Col>
                    <CustomGettingStartedHeading>
                      <span>About t</span>
                      <span style={{ borderBottom: "3px solid #C2D500" }}>
                        he Agent
                      </span>
                    </CustomGettingStartedHeading></Col>
                  <Col>
                    {agentCompanyLogo && (agentSubscription_type === 'Navihome Advanced Plan' || agentSubscription_type === 'Navihome Premimum Plan') ? <> <img
                      src={agentCompanyLogo ?? ""}
                      alt=".."
                      style={{
                        height: "90px",
                        width: "auto",
                        marginRight: "2px",
                      }}
                    /></> : <></>}
                  </Col>

                </Row>
                {agentTagline && (agentSubscription_type === 'Navihome Advanced Plan') ? <Row>
                  <CustomTaglinePara>{agentTagline}</CustomTaglinePara>
                </Row> : <></>}



                <Row>
                  <CustomAboutPara>{agentAbout}</CustomAboutPara>
                </Row>

                <Row>
                  {certificateInfo?.length > 0 && (agentSubscription_type === 'Navihome Advanced Plan' || agentSubscription_type === 'Navihome Premimum Plan') && (
                    <div>
                      <h3 style={{ marginBottom: "3rem" }}>
                        Designations and Certifications
                      </h3>
                      {/* {designationsAndCertifications?.map((item, index) => {
                      return (
                        <> */}
                      {certificateInfo?.map((item: any) => (
                        <img
                          src={item?.url ?? ""}
                          alt=".."
                          style={{
                            height: "90px",
                            width: "190px",
                            marginRight: "2px",
                          }}
                        />
                      ))}

                      {/* </>
                      );
                    })} */}
                    </div>
                  )}
                </Row>
              </Col>
              {isXS &&
                !isBuyerLinked &&
                userState?.buyer?.comsumerType?.toLowerCase() !== "seller" && (
                  <>
                    <ConnectButton
                      type="primary"
                      onClick={() => {
                        connectAgent(
                          agentName,
                          agentEmail,
                          `${userState?.buyer?.name.toUpperCase()} wants to connect with you. Accept the request to help them buy their next home`
                        );
                      }}
                      disabled={isConn}>
                      CONNECT NOW
                    </ConnectButton>
                  </>
                )}
              {isXS &&
                isBuyerLinked &&
                userState?.buyer?.comsumerType?.toLowerCase() !== "seller" &&
                userState?.buyer?.buyerAgentName?.length > 0 &&
                param.get("type") !== "sell" && (
                  <ConnButton
                    type={ButtonType.PRIMARY}
                    text={"REMOVE AGENT"}
                    clickHandler={() => {
                      setIsShowFindAnAgentConfirmModal(true);
                    }}
                    className="find-agent-page-connect_now-button"
                  />
                )}
              {isXS &&
                isBuyerLinked &&
                userState?.buyer?.comsumerType?.toLowerCase() === "seller" &&
                param.get("type") === "buy" && (
                  <ConnButton
                    type={ButtonType.PRIMARY}
                    text={"REMOVE AGENT"}
                    clickHandler={() => {
                      setIsShowFindAnAgentConfirmModal(true);
                    }}
                    className="find-agent-page-connect_now-button"
                  />
                )}
              {isXS &&
                isBuyerLinked &&
                param?.get("type") === "buyer" &&
                (userState?.buyer?.comsumerType?.toLowerCase() === "seller" ||
                  userState?.buyer?.buyerAgentName?.length <= 0) && (
                  <ConnButton
                    type={ButtonType.PRIMARY}
                    text={"CONNECT NOW"}
                    isDisabled={isConn}
                    clickHandler={() => {
                      connectAgent(
                        agentName,
                        agentEmail,
                        `${userState?.buyer?.name.toUpperCase()} wants to connect with you. Accept the request to help them buy their next home`
                      );
                    }}
                    className="find-agent-page-connect_now-button"
                  />
                )}
            </Row>
          </>
        ) : (
          <Row
            justify="center"
            style={{
              padding: "20px 0",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}>
            <Col>
              <h1>No Agent found!</h1>
              {param.get("type") !== "sell" && (
                <Link to="/find-agent?type=buyer">
                  <a href="#" style={{ textDecoration: "underline" }}>
                    Find an agent
                  </a>
                </Link>
              )}
            </Col>
          </Row>
        )}

        {isShowFindAnAgentConfirmModal && (
          <FindAnAgentConfirmModal
            isShowing={isShowFindAnAgentConfirmModal}
            closeAction={confirmModalHandler}
            text="Are you sure to remove this agent?"
            yesText="Yes"
            noText="No"
            agentId={agentId}
            pageType="AboutAgent"
          />
        )}
      </CustomDiv>
    </>
  );
};

export default AboutAgent;
