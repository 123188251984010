import AccountDetail from '../components/Account/AccountBuyerPage'

const AccountDetailsBuyerPage: React.FC = () => {
  return (
    <div className="account-details-buyer-page">
      <div className="account-details-buyer-page__container">
        <AccountDetail />
      </div>
    </div>
  )
}

export default AccountDetailsBuyerPage
