// import { useHistory } from 'react-router-dom'
import React from 'react'

export interface SchoolInformationSummaryProps {
  rating: number | string;
  schoolName: string;
  schoolType: string;
  distance: string;
  schoolUrl?: string;
  type: string;
  className?: string;
}

const SchoolInformationSummary: React.FC<SchoolInformationSummaryProps> = ({
  rating,
  schoolName,
  schoolType,
  distance,
  schoolUrl,
  type,
  className,
}) => {
  // const history = useHistory()
  const handleRouting = (schoolUrl: any) => {
    // logic to find full form of state of any schoolstate
    window.open(schoolUrl)
  }
  const calcschool = (schoolType: any) => {
    const data = schoolType.split(',')
    let standard = String(data[0]) + ' to ' + String(data[data.length - 1])
    return standard
  }
  const renderRating = () => {
    let numericRating = String(rating);
    let ratingImage;

    if (isNaN(parseFloat(numericRating)) || parseFloat(numericRating) < 1 || parseFloat(numericRating) > 10) {
      numericRating = 'NA';
    }

    ratingImage = require(`../assets/images/rating_large_${numericRating}.png`); // Adjust the path as needed

    return (
      <img
        className="GreatSchoolsRating"
        src={ratingImage}
        alt={`${schoolName} GreatSchools Rating: ${rating}`}
        onClick={() => handleRouting(schoolUrl)}
      />
    );
  };

  return (
    <div
      className={
        className === undefined
          ? 'school-information-summary'
          : `school-information-summary ${className}}`
      }
    >
       <div className="GreatSchoolSummary">
        {renderRating()}
      </div>
      <div>
        <a style={{ display: "block", justifyContent: "left" }}
          className="school-information-summary__school"
          href={schoolUrl} target="_blank" rel="noopener noreferrer"
        >
          <p className="school-information-summary__school-name">{schoolName}</p>
          <p className="school-information-summary__school-type">
            {type} &bull; {calcschool(schoolType)} &bull; dist - {distance} miles
          </p>
        </a>
      </div>
    </div>
  )
}

export default SchoolInformationSummary
