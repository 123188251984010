import styled from "@emotion/styled";
import { Collapse } from "antd";
import { useState } from "react";
import SearchField from "../components/SearchField";

const CustomSearch = styled.div`
  margin-bottom: 3rem;
`;
const CustomHead = styled.h1`
  font-size: 25px;
  padding-left: 1.8rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-weight: 600;
`;

const HelpPage: React.FC = () => {
  const [text, setText] = useState<string>("-1%2^3_4");

  const mockData = [
    {
      question: "Lorem ipsum dolor sit amet consectetur, adipisicing elit Newton.",
      answer:
        "Lorem ipsum dolor Lexington sit amet consectetur, adipisicing elit. Eius corrupti rerum nobis. Exercitationem nihil ipsum facilis beatae ducimus molestias praesentium neque esse? Nostrum praesentium ex nobis est repellendus, illum libero."
    },
    {
      question: "Lorem ipsum dolor sit amet consectetur, adipisicing elit consectetur, adipisicing elit Boston.",
      answer:
        "Lorem ipsum dolor Acton  sit amet consectetur, adipisicing elit. Eius corrupti rerum nobis. Exercitationem nihil ipsum facilis beatae ducimus molestias praesentium neque esse? Nostrum praesentium ex nobis est repellendus, illum libero."
    },
    {
      question: "Lorem ipsum consectetur, adipisicing elit Medford.",
      answer:
        "Lorem ipsum dolor Weymouth sit amet consectetur, adipisicing elit Medford. Eius corrupti rerum nobis. Exercitationem nihil ipsum facilis beatae ducimus molestias praesentium neque esse? Nostrum praesentium ex nobis est repellendus, illum libero."
    }
  ];
  const searchAction = (query: string) => {
    setText(query);
    // document.getElementsByName
  };
  const { Panel } = Collapse;
  const onChange = (key: any) => {
    // setText(key)
  };

  const getVerifiedTextHandler = (query: string, startIndex?: number) => {
    return (
      <>
        {query.slice(0, startIndex)}
        <span style={{ background: "#F2EA88" }}>{query.slice(startIndex, startIndex + text.length)}</span>
        {query.slice(startIndex + text.length)}
      </>
    );
  };
  return (
    <div className="calendar-agent-page__container">
      <div className="search-section">
        <div>
          <CustomHead>
            How can w<span style={{ borderBottom: "3px solid #C2D500" }}>e help you?</span>
          </CustomHead>
          <CustomSearch>
            <SearchField placeholder="Write a question or problem" searchAction={searchAction} onChange={searchAction} />
          </CustomSearch>
        </div>
      </div>
      <div className="questions-section">
        {mockData.map((item, index) => {
          return (
            <Collapse defaultActiveKey={[index + 1]} onChange={onChange}>
              <Panel
                header={
                  item.question.toLocaleLowerCase().includes(text) && text?.length > 0
                    ? getVerifiedTextHandler(item.question, item.question.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()))
                    : item.question
                }
                key={index + 1}
              >
                <p>
                  {item.answer.toLocaleLowerCase().includes(text.toLocaleLowerCase()) && text?.length > 0
                    ? getVerifiedTextHandler(item.answer, item.answer.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()))
                    : item.answer}
                </p>
              </Panel>
            </Collapse>
          );
        })}
      </div>
    </div>
  );
};

export default HelpPage;
