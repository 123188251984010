import AccountSetting from "../components/Account/AccountBuyerSettingPage";

const AccountSettingsBuyerPage: React.FC = () => {
  return (
    <div className="account-settings-buyer-page">
      <div className="account-settings-buyer-page__container">
        <AccountSetting />
      </div>
    </div>
  );
};

export default AccountSettingsBuyerPage;
