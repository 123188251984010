import { useContext } from "react";
import AgentCalendar from "../components/AgentCalendar";
import UserHeader from "../components/notificationAgentPage/UserHeader";
import UserContext from "../contexts/UserContext";
import { activatedPlan } from "../utils/base";

const CalendarAgentPage: React.FC = () => {
  const { userState } = useContext(UserContext);
  return (
    <div>
      {activatedPlan?.includes(userState?.agent?.subscription_type) && <UserHeader />}
      <div className="calendar-agent-page__container">
        <h3>My Calendar</h3>
        <AgentCalendar />
      </div>
    </div>
  );
};

export default CalendarAgentPage;
