import { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import validator from 'email-validator'
import Button, { ButtonType } from '../components/Button'
import { Row, Col, Modal } from 'antd'
import { useMediaQuery } from 'react-responsive'
import UserContext, { UserType } from '../contexts/UserContext'
import TextField, {
  TextFieldIconType,
  TextFieldType,
} from '../components/TextField'
import { Input } from 'antd'
import styled from '@emotion/styled'
import { PropertyShareMail } from '../services/NavihomeService'
import { notification } from '../components/notification'
import CustomSpinner from '../components/spinner/CustomSpinner';
import copy from "copy-to-clipboard";
const { TextArea } = Input
interface PropertyShareModalProps {
  isShowing: boolean
  closeAction: () => void
  imageurl?: string
  propertyAddress?: string
  listingId?: any
  listingAgentName?: string
  mlsId?: string | number
}

const CustomPara = styled.p`
  padding-top: 5px;
  font-size: 12px;
`
const CustomSharePara = styled.p`
  font-size: 12px;
  color: blue;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`
const CustomButton = styled(Button)`
  padding: 12px 10px;
  .p {
    color: white !important;
  }
`
const CustomTextField = styled(TextField)`
  width: 100%;
`

const PropertyShareModal: React.FC<PropertyShareModalProps> = ({
  isShowing,
  closeAction,
  imageurl,
  propertyAddress,
  listingId,
  listingAgentName,
  mlsId,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { userState } = useContext(UserContext)
  const [userEmail, setUserEmail] = useState('')
  const [recepientMail, setRecepientMail] = useState('')
  const [message, setMessage] = useState('')
  const [propertyLink, setPropertyLink] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(isShowing);

  const handleCancel = () => {
    closeAction()
    setIsModalVisible(false)
  }
  const CopyToClipBoard = async (e: any) => {
    let origin = window?.location.origin;
    let prop_link = `${origin}/properties/${mlsId}`;
    copy(prop_link);
    return notification('Property Link Copied!', 'success')
  }
  const cardWidth = useMediaQuery({ maxWidth: 1100 });
  const handleMail = async () => {
    let userMail = ''
    if (userState?.userType === UserType.AGENT) {
      userMail = userState?.login?.email
    } else {
      userMail = userState?.login?.email

      if (!userMail) {
        userMail = userEmail
      }
    }
    for (let mail of recepientMail.split(',')) {
      const finalMail = mail.trim()     // it is used to remove space from front and back
      if (!validator.validate(finalMail)) {
        return notification(`Please enter the correct recipient's email ${mail}.`, `error`)
      }
    }

    if (!validator.validate(userEmail)) {
      return notification(`Please enter the correct user's email.`, `error`)
    }
    if (!recepientMail)
      return notification(`Please Enter Recipient's Email`, 'error')
    if (!userMail) return notification(`Please Enter User Email`, 'error')
    setIsLoading(true)
    try {
      const result = await PropertyShareMail(
        recepientMail,
        userState?.userType === UserType.AGENT ? userState?.agent?.name : userState?.isLoggedIn ? userState?.buyer?.name : userMail,
        propertyLink,
        message,
        imageurl,
        propertyAddress,
        listingId,
        listingAgentName,
      )
      if (!result) {
        setIsLoading(false)
        return notification("Email not sent successfully", "error");
      }
      closeAction()
      setIsLoading(false)
      return notification("Email sent successfully", "success");
    } catch (err) {
      console.log(err)
      return err
    }
  }
  useEffect(() => {
    if (userState?.isLoggedIn) {
      setUserEmail(userState?.login?.email)
      window?.location?.href.includes('/sell')
        ? setPropertyLink(`${window?.location.origin}/properties/${mlsId}`)
        : setPropertyLink(window?.location?.href)
    } else {
      setPropertyLink(window?.location?.href)
    }
  }, [userState])
  return (
    <Modal
      title="Email this home"
      visible={isModalVisible}
      footer={null}
      width={cardWidth ? undefined : '30%'}
      onCancel={handleCancel}
    >
      {isLoading && <CustomSpinner />}
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} style={{ marginBottom: '10px' }}>
          <CustomTextField
            value={recepientMail}
            setValue={setRecepientMail}
            label="Recipient's email"
            placeholder={'Enter email'}
            type={TextFieldType.ICON_AND_LABEL}
            icon={TextFieldIconType.EMAIL}
            isRequired={true}
          />
          <CustomPara>Separate multiple addresses with a comma.</CustomPara>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} style={{ marginBottom: '10px' }}>
          <CustomTextField
            // value={dateTime}
            value={userEmail}
            setValue={setUserEmail}
            placeholder={'Enter email'}
            label="Your email"
            type={TextFieldType.ICON_AND_LABEL}
            icon={TextFieldIconType.EMAIL}
            isRequired={true}
          />
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} style={{ marginBottom: '15px' }}>
          <label>Include message</label>
          <TextArea
            rows={3}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value)
            }}
            style={{
              borderColor: 'blue',
              borderRadius: '5px',
              boxShadow: '2px 2px 2px grey ',
            }}
            placeholder="Check out this home I found on Navihome"
          />
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} style={{ marginBottom: '10px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <CustomButton
              type={ButtonType.PRIMARY}
              text="Send email"
              clickHandler={handleMail}
              className="property_share_modal__form_button"
            />
            <CustomSharePara onClick={CopyToClipBoard}>
              Get Shareable Link
            </CustomSharePara>
          </div>
        </Col>
      </Row>
    </Modal>
  )
}
export default PropertyShareModal
