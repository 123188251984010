/* eslint-disable jsx-a11y/alt-text */
import { Avatar, Card, Col, Modal, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { UserOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import iconEmail from '../../assets/images/icon-email.svg';
import iconPhone from '../../assets/images/icon-phone.svg';
import Button, { ButtonType } from '../../components/Button';
import cal from './images/imgpsh_fullsize_anim.png';
import requestShowingOn from '../../assets/images/button-request-showing-on.svg';
import requestShowingOff from '../../assets/images/button-request-showing-off.svg';
import favoriteOn from '../../assets/images/button-favorite-on.svg';
import favoriteOff from '../../assets/images/button-favorite-off.svg';
import homeWithClickq from '../../assets/CalendarPopover/Mask Group 609.svg';
import buttonRemoveProperty from '../../assets/images/button-remove-property.svg';
import Requested from '../../assets/CalendarPopover/Group 1212.svg';
import Accepted from '../../assets/CalendarPopover/Group 1217.svg';
import UserContext, { UserType } from '../../contexts/UserContext';
import AgentContext from '../../contexts/AgentContext';
import { removeLinkedPropertyForBuyer } from '../../services/NavihomeService';
import CustomSpinner from '../../components/spinner/CustomSpinner';
import moment from 'moment';
import NotListedAgentModal from './Kevinheardsib';
import ListedAgentModal from './ReginaGeorge';
// import '../index.css'

const CustomCardDiv = styled.div<{ isAccepted?: boolean }>`
  width: 20px;
  background: ${(props) => (props.isAccepted ? '#bcf61f' : '#ff8237')};
  /* #c2d500; */
  border-radius: 7px;
  padding: 4px 8px;
  position: absolute;
  top: 6px;
  text-align: center;
  left: 0.2rem;
  font-size: 10px;
  color: ${(props) => (props.isAccepted ? '#000' : '#fff')};
  width: 85%;
  @media screen and (max-width: 1650px) {
    width: 84%;
  }
  @media screen and (max-width: 1450px) {
    width: 83%;
  }
  @media screen and (max-width: 1350px) {
    width: 80%;
  }
  @media screen and (max-width: 1200px) {
    width: 91%;
  }
  @media screen and (max-width: 800px) {
    width: 90%;
  }
  @media screen and (max-width: 750px) {
    width: 86%;
  }
  @media screen and (max-width: 576px) {
    width: 94%;
  }
  @media screen and (max-width: 312px) {
    width: 90%;
  }
`;

const CustomImage = styled.img<{
  color?: any;
}>`
  margin-left: 5px;
`;

const CustomColumn = styled(Col)`
  overflow-y: auto;
  max-height: 350px;
`;

interface ScheduleTourProps {
  isShowing: boolean;
  closeAction: () => void;
  linkedPropertiesData?: any;
  filteredLinkedProperties?: any;
  setShowMap?: any;
  setUnlinkPropHeader?: any;
  setTourPropPage?: any;
  setLinkedPropertiesData?: (linkedProp: any) => void;
  setAgentModal?: any;
  setIsScheduleTour?: any;
  setselected?: (val: string) => void;
  setRunMap?: (val: boolean) => void;
  bestRoute?: Array<any>;
  useManual?: boolean;
  formData?: any;
  handleTogglingClicked?: string;
  setScheduleStatus?: (val: boolean) => void;
  manualChangeProperties?: Array<any>;
  linkedPropertiesOutOfBestRoute?: Array<any>;
  setSchedulingStatusClicked?: (val: string) => void;
  scheduleTourClicked?: string;
}

const SchedulesModal: React.FC<ScheduleTourProps> = ({
  isShowing,
  closeAction,
  linkedPropertiesData,
  filteredLinkedProperties,
  setLinkedPropertiesData,
  setAgentModal,
  setIsScheduleTour,
  setShowMap,
  setselected,
  setUnlinkPropHeader,
  setTourPropPage,
  setRunMap,
  bestRoute,
  useManual,
  formData,
  handleTogglingClicked,
  manualChangeProperties,
  setScheduleStatus,
  scheduleTourClicked,
  setSchedulingStatusClicked,
  linkedPropertiesOutOfBestRoute,
}) => {
  const { userState } = useContext(UserContext);
  const { agentState } = useContext(AgentContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [listedAgent, setListedAgent] = useState(false);
  const [listingAgentActivatedPlan, setListingAgentActivatedPlan] = useState<string>('');
  const [agentEmail, setAgentEmail] = useState<string>('');
  const [agentPhone, setAgentPhone] = useState<string>('');
  const [agentPhoto, setAgentPhoto] = useState<string>('');
  const [prefferedCont, setPrefferedCont] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [item, setItem] = useState<Array<any>>([]);

  const [notListedAgent, setNotListedAgent] = useState(false);
  const [bestRouteUpdated, setBestRouteUpdated] = useState<Array<any>>([]);
  const [outOfBestRouteProperties, setOutOfBestRouteProperties] = useState<
    Array<any>
  >([]);

  const handleOk = () => {
    if (scheduleTourClicked === 'Clicked') {
      closeAction();
      setSchedulingStatusClicked('Clicked');
    } else {
      closeAction();
      setSchedulingStatusClicked('Not Clicked');
    }
  };

  const handleCancel = () => {
    closeAction();
  };

  const unlinkproperty = async (propertyId: any) => {
    // here we call unlinked property api
    // if (!userState.isLoggedIn) {
    //   notification(
    //     'you need to login before you can perform this action.',
    //     'warning',
    //   )
    //   return
    // }

    let token = '';
    let buyerId = -1;

    switch (userState.userType) {
      case UserType.BUYER:
        buyerId = userState?.buyer?.buyerId;
        token = userState?.buyer?.token;
        break;

      case UserType.AGENT:
        buyerId = agentState?.buyerId;
        token = userState?.agent?.token;
        break;
      default:
        break;
    }
    await removeLinkedPropertyForBuyer(propertyId, buyerId, token);
    setShowMap(false);
    setUnlinkPropHeader(true);
    setTourPropPage(false);
    setselected('prop');
    setRunMap(false);
    setScheduleStatus(true);
    const filterproperty = linkedPropertiesData?.filter((item: any) => {
      return (item?.propertyId ? item?.propertyId : item?.mlsId) !== propertyId;
    });
    closeAction();
    setLinkedPropertiesData(filterproperty);
  };

  const handlepopup = () => {
    if (scheduleTourClicked === 'Clicked') {
      closeAction();
      setSchedulingStatusClicked('Clicked');
    } else {
      closeAction();
      setSchedulingStatusClicked('Not Clicked');
    }
  };


  const handleAgentModal = (item?: any) => {
    setItem(item);
    if (item?.isListed === true || item?.isListed === '1') {
      setUserName(item?.listingAgent?.name);
      setListedAgent(true);
      setAgentEmail(item?.listingAgent?.email);
      setAgentPhone(item?.listingAgent?.phone);
      setAgentPhoto(item?.listingAgent?.avatarUrl);
      setPrefferedCont(item?.listingAgent?.preferredContact);
      setListingAgentActivatedPlan(item?.listingAgentActivatedPlan);
    } else {
      setUserName(`${item?.agent?.firstName} ${item?.agent?.lastName}`);
      setAgentPhone(item?.agent?.contact?.office);
      setNotListedAgent(true);
    }
  };

  useEffect(() => {

    const tempArr: any =
      manualChangeProperties?.length > 0
        ? manualChangeProperties
        : filteredLinkedProperties?.length > 0
          ? filteredLinkedProperties
          : linkedPropertiesData;
    const lastIndex: number =
      tempArr?.length - linkedPropertiesOutOfBestRoute?.length;
    const tempFilteredLinkedProperties: Array<any> = tempArr?.slice(
      0,
      lastIndex
    );
    setBestRouteUpdated(
      manualChangeProperties?.length > 0 ? manualChangeProperties : linkedPropertiesOutOfBestRoute?.length > 0
        ? tempFilteredLinkedProperties
        : tempArr
    );
    setOutOfBestRouteProperties(manualChangeProperties?.length > 0 ? [] : linkedPropertiesOutOfBestRoute);
    if (tempFilteredLinkedProperties?.length > 0 || tempArr?.length > 0) {
      setIsLoading(false);
    } else {
      setTimeout(() => {
        setIsLoading(false)
      }, 20000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredLinkedProperties, manualChangeProperties]);

  // useEffect(() => {
  //   if (filteredLinkedProperties?.length > 0) {
  //     setIsLoading(false);
  //   }
  // }, [filteredLinkedProperties]);

  const isMobile = useMediaQuery({ maxWidth: 500 });
  return (
    <>
      <Modal
        visible={isShowing}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={false}
      >
        {isLoading && <CustomSpinner />}
        <Row>
          <h2>Scheduling Status</h2>
        </Row>
        <br />
        <Row style={{ display: 'flex', alignItems: 'center' }}>
          <Col xs={24} sm={24} md={24} lg={10}>
            <h5 style={{ color: 'black', float: 'left' }}>
              Showing requests have been sent to the agents. You <br />
              <br /> can view the status here and on the time stamps
            </h5>
            <img src={cal} style={{ float: 'left', width: '100%' }} />
          </Col>
          <CustomColumn xs={24} sm={24} md={24} lg={14}>
            {bestRouteUpdated?.map((item: any) => {
              return (
                <>
                  <Card
                    bodyStyle={{ padding: '0' }}
                    style={{ borderBottom: '4px solid #004876' }}
                    bordered={false}
                    hoverable={true}
                  >
                    <Row>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={7}
                      // style={{ background: 'red' }}
                      >
                        <img
                          className="tour_detail_property_card wrap_new_image"
                          src={item?.photos?.[0]}
                          alt="Property"
                          width="136px"
                          height="140px"
                        />
                        <CustomCardDiv
                          isAccepted={item?.isAccepted ? true : false}
                        >
                          {moment(item?.startTime, 'hh:mm a').format('hh:mm a')}{' '}
                          - {moment(item?.endTime, 'hh:mm a').format('hh:mm a')}
                        </CustomCardDiv>
                      </Col>

                      <Col xs={24} sm={24} md={24} lg={17}>
                        <Row>
                          <Col
                            span={24}
                            style={{ display: 'flex', marginBottom: '5px' }}
                            className="property_price_and_icons"
                          >
                            <div style={{ display: 'flex' }}>
                              <p>{`${item?.listPrice.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 3,
                              }) ?? 'N/A'
                                }`}</p>
                              {(item?.isListed === true ||
                                item?.isListed === '1') && (
                                  <CustomImage
                                    src={homeWithClickq}
                                    alt="home image"
                                  />
                                )}
                              {item?.isRequested ? (
                                <CustomImage
                                  src={requestShowingOn}
                                  alt="star image"
                                />
                              ) : (
                                <CustomImage
                                  src={requestShowingOff}
                                  alt="star image"
                                />
                              )}
                              {item?.isFavorite ? (
                                <CustomImage
                                  src={favoriteOn}
                                  alt="favourite image"
                                />
                              ) : (
                                <CustomImage
                                  src={favoriteOff}
                                  alt="favourite image"
                                />
                              )}
                            </div>
                            <div style={{ marginLeft: 'auto' }}>
                              {!item?.isAccepted && (
                                <CustomImage
                                  src={buttonRemoveProperty}
                                  alt="favourite image"
                                  role={'button'}
                                  onClick={() => {
                                    unlinkproperty(item?.mlsId);
                                  }}
                                />
                              )}

                              <CustomImage
                                src={item?.isAccepted ? Accepted : Requested}
                                alt="favourite image"
                              />
                              <span
                                style={{
                                  fontWeight: '600',
                                  marginLeft: '12px',
                                  marginRight: '6px',
                                }}
                              >
                                {item?.isAccepted
                                  ? item?.systemSchedule
                                    ? 'System-scheduled'
                                    : 'Scheduled'
                                  : 'Requested'}
                              </span>
                            </div>
                          </Col>
                          <Col
                            style={{ marginBottom: '8px' }}
                            span={24}
                            className="property_address"
                          >
                            <p className="property_first_name">
                              {item?.address?.streetNumber}{' '}
                              {item?.address?.streetName}
                            </p>
                            <p className="property_last_name">
                              {item?.address?.city},{' '}{item?.address?.state}{' '}
                              {item?.address?.postalCode}
                            </p>
                          </Col>

                          <Col span={24}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                              className="avtar_agent_name_icons"
                            >
                              <div
                                style={{ display: 'flex' }}
                                className="wrap_avatar_and_agent_name"
                                onClick={() => {
                                  handleAgentModal(item);
                                }}
                              >
                                <div className="property_card_avatar">
                                  <Avatar
                                    size="large"
                                    src={
                                      (item?.isListed === true || item?.isListed === '1') || (item?.listingAgentActivatedPlan === 'Navihome Advanced Plan' || item?.listingAgentActivatedPlan === 'Navihome Premimum Plan')
                                        ? item?.listingAgent?.avatarUrl
                                        : ''
                                    }
                                    icon={
                                      item?.isListed === false ||
                                        item?.isListed === '0' ? (
                                        <UserOutlined />
                                      ) : (
                                        ''
                                      )
                                    }
                                  />
                                </div>
                                <div
                                  style={{ marginLeft: '10px' }}
                                  className="property_agent_name"
                                >
                                  {item?.isListed === true ||
                                    item?.isListed === '1' ? (
                                    <>
                                      <span style={{ fontWeight: 'bold' }}>
                                        {item?.listingAgent?.name}
                                      </span>
                                      <br />
                                      {item?.listingAgent?.company}
                                    </>
                                  ) : (
                                    <>
                                      <span style={{ fontWeight: 'bold' }}>
                                        {' '}
                                        {item?.agent?.firstName}{' '}
                                        {item?.agent?.lastName}
                                      </span>{' '}
                                      <br />
                                      {item?.office?.name}
                                    </>
                                  )}
                                </div>
                              </div>

                              <div>
                                {item?.isListed === true ||
                                  item?.isListed === '1' ? (
                                  <img
                                    style={{ marginRight: '10px' }}
                                    src={iconEmail}
                                    alt="Email"
                                  />
                                ) : (
                                  <></>
                                )}

                                <img src={iconPhone} alt="Phone" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    {/* <div className="main_div" style={{ backgroundColor: 'red' }}>
                    <div>
                      <img
                        className="tour_detail_property_card"
                        src={item?.photos?.[0]}
                        alt="Property"
                        width="136px"
                        height="136px"
                      />
                    </div>
                    <h4 style={{ marginLeft: '160px', marginTop: '-150px' }}>
                      <b>{`$${item?.listPrice ?? 'N/A'}`}</b>
                      <br />
                      {item?.address?.streetNumber},{item?.address?.streetName},
                      <br />
                      {item?.address?.city},{item?.address?.state}
                      {item?.address?.postalCode}
                    </h4>
                    {/* <Badge status="warning" text='Requested' style={{float:'right'}}/>
              <Badge status="warning" style={{float:'right'}}/> */}
                    {/* <br />
                    <div onClick={agentModalHander}>
                      <Avatar
                        size="large"
                        src={Reg}
                        style={{ marginLeft: '160px', marginBottom: '-59px' }}
                      />
                      <h4
                        style={{ marginLeft: '200px', marginBottom: '-39px' }}
                      >
                        {item?.agent?.firstName} {item?.agent?.lastName}
                        <br />
                        Smith Reality Inc
                      </h4>
                      <div>
                        <img
                          src={iconEmail}
                          alt="mail_icon"
                          style={{ marginRight: '10px' }}
                        />

                        <img src={iconPhone} alt="phone_icon" />
                      </div>
                    </div>
                  </div> */}
                  </Card>
                </>
              );
            })}
            {outOfBestRouteProperties?.length > 0 && (
              <>
                {bestRoute?.length > 0 ? (
                  <>
                    <h2 style={{ marginTop: '20px', marginBottom: '20px' }}>
                      Out of Best Route:
                    </h2>
                  </>
                ) : (
                  <></>
                )}

                {outOfBestRouteProperties?.map((item: any) => {
                  return (
                    <>
                      <Card
                        bodyStyle={{ padding: '0' }}
                        style={{ borderBottom: '4px solid #004876' }}
                        bordered={false}
                        hoverable={true}
                      >
                        <Row>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={7}
                          // style={{ background: 'red' }}
                          >
                            <img
                              className="tour_detail_property_card wrap_new_image"
                              src={item?.photos?.[0]}
                              alt="Property"
                              width="136px"
                              height="140px"
                            />
                            {useManual && <CustomCardDiv
                              isAccepted={item?.isAccepted ? true : false}
                            >
                              {moment(item?.startTime, 'hh:mm a').format(
                                'hh:mm a'
                              )}{' '}
                              -{' '}
                              {moment(item?.endTime, 'hh:mm a').format(
                                'hh:mm a'
                              )}
                            </CustomCardDiv>}
                          </Col>

                          <Col xs={24} sm={24} md={24} lg={17}>
                            <Row>
                              <Col
                                span={24}
                                style={{ display: 'flex', marginBottom: '5px' }}
                                className="property_price_and_icons"
                              >
                                <div style={{ display: 'flex' }}>
                                  <p>{`${item?.listPrice.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 3,
                                  }) ?? 'N/A'
                                    }`}</p>
                                  {(item?.isListed === true ||
                                    item?.isListed === '1') && (
                                      <CustomImage
                                        src={homeWithClickq}
                                        alt="home image"
                                      />
                                    )}
                                  {item?.isRequested ? (
                                    <CustomImage
                                      src={requestShowingOn}
                                      alt="star image"
                                    />
                                  ) : (
                                    <CustomImage
                                      src={requestShowingOff}
                                      alt="star image"
                                    />
                                  )}
                                  {item?.isFavorite ? (
                                    <CustomImage
                                      src={favoriteOn}
                                      alt="favourite image"
                                    />
                                  ) : (
                                    <CustomImage
                                      src={favoriteOff}
                                      alt="favourite image"
                                    />
                                  )}
                                </div>
                                <div style={{ marginLeft: 'auto' }}>
                                  {!item?.isAccepted && (
                                    <CustomImage
                                      src={buttonRemoveProperty}
                                      alt="favourite image"
                                      role={'button'}
                                      onClick={() => {
                                        unlinkproperty(item?.mlsId);
                                      }}
                                    />
                                  )}

                                  <CustomImage
                                    src={
                                      item?.isAccepted ? Accepted : Requested
                                    }
                                    alt="favourite image"
                                  />
                                  <span
                                    style={{
                                      fontWeight: '600',
                                      marginLeft: '12px',
                                      marginRight: '6px',
                                    }}
                                  >
                                    {item?.isAccepted
                                      ? 'Accepted'
                                      : 'Requested'}
                                  </span>
                                </div>
                              </Col>
                              <Col
                                style={{ marginBottom: '8px' }}
                                span={24}
                                className="property_address"
                              >
                                <p className="property_first_name">
                                  {item?.address?.streetNumber}{' '}
                                  {item?.address?.streetName},
                                </p>
                                <p className="property_last_name">
                                  {item?.address?.city},{item?.address?.state}
                                  {item?.address?.postalCode}
                                </p>
                              </Col>

                              <Col span={24}>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                  className="avtar_agent_name_icons"
                                >
                                  <div
                                    style={{ display: 'flex' }}
                                    className="wrap_avatar_and_agent_name"
                                    onClick={() => {
                                      handleAgentModal(item);
                                    }}
                                  >
                                    <div className="property_card_avatar">
                                      <Avatar
                                        size="large"
                                        src={
                                          (item?.isListed === true || item?.isListed === '1') && (item?.listingAgentActivatedPlan === 'Navihome Advanced Plan' || item?.listingAgentActivatedPlan === 'Navihome Premimum Plan')
                                            ? item?.listingAgent?.avatarUrl
                                            : ''
                                        }
                                        icon={
                                          item?.isListed === false ||
                                            item?.isListed === '0' ? (
                                            <UserOutlined />
                                          ) : (
                                            ''
                                          )
                                        }
                                      />
                                    </div>
                                    <div
                                      style={{ marginLeft: '10px' }}
                                      className="property_agent_name"
                                    >
                                      {item?.isListed === true ||
                                        item?.isListed === '1' ? (
                                        <>
                                          <span style={{ fontWeight: 'bold' }}>
                                            {item?.listingAgent?.name}
                                          </span>
                                          <br />
                                          {item?.listingAgent?.company}
                                        </>
                                      ) : (
                                        <>
                                          <span style={{ fontWeight: 'bold' }}>
                                            {item?.agent?.firstName}{' '}
                                            {item?.agent?.lastName}
                                          </span>{' '}
                                          <br />
                                          {item?.office?.name}
                                        </>
                                      )}
                                    </div>
                                  </div>

                                  <div>
                                    {item?.isListed === true ||
                                      item?.isListed === '1' ? (
                                      <img
                                        style={{ marginRight: '10px' }}
                                        src={iconEmail}
                                        alt="Email"
                                      />
                                    ) : (
                                      <></>
                                    )}

                                    <img src={iconPhone} alt="Phone" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </>
                  );
                })}
              </>
            )}
          </CustomColumn>
        </Row>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Button
            type={ButtonType.PRIMARY}
            text="DONE"
            clickHandler={handlepopup}
            className="route_tour-modal__button"
            width={isMobile ? '100%' : 18.5}
          />
        </div>
      </Modal>
      {listedAgent && (
        <ListedAgentModal
          item={item}
          isShowing={listedAgent}
          closeAction={() => {
            setListedAgent(!listedAgent);
          }}
          agentEmail={agentEmail}
          agentPhone={agentPhone}
          modeOfContact={prefferedCont}
          agentPhoto={agentPhoto}
          userName={userName}
          listingAgentActivatedPlan={listingAgentActivatedPlan}
        />
      )}

      {notListedAgent && (
        <NotListedAgentModal
          item={item}
          isShowing={notListedAgent}
          closeAction={() => {
            setNotListedAgent(!notListedAgent);
          }}
          agentPhone={agentPhone}
          userName={userName}
        />
      )}
    </>
  );
};

export default SchedulesModal;
