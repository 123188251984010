import { useState, useContext } from 'react';
import Button, { ButtonType } from '../components/Button';
import { Row, Col, Modal } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import UserContext from '../contexts/UserContext';
import {
  resendEmailLinkService,
  unlinkBuyerFromAgentService,
} from '../services/NavihomeService';
import CustomSpinner from '../components/spinner/CustomSpinner';
import { notification } from '../components/notification';
interface RoutingTourModalProps {
  isShowing: boolean;
  email: string;
  name: string;
  closeAction: () => void;
}

const CustomCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
`;
const ResendVerifyLinkModal: React.FC<RoutingTourModalProps> = ({
  isShowing,
  email,
  name,
  closeAction,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(isShowing);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const handleCancel = () => {
    closeAction();
    setIsModalVisible(false);
  };
  const { userState, logIn } = useContext(UserContext);
  const isMobile = useMediaQuery({ maxWidth: 500 });
  let resendEmailLinkHandler = async () => {
    setIsLoading(true);
    try {
      await resendEmailLinkService(email, name);
    //   closeAction();
    //   setIsModalVisible(false);
      history.push('/');
      setIsLoading(false);
      return notification('Verification link has been resent to your registered email.', 'success');
    } catch (error) {
      console.log('ERROR', error);
      setIsLoading(false);
    }
  };

  const handlepopup = () => {
    closeAction();
  };

  return (
    <Modal
      visible={isModalVisible}
      footer={null}
      width={680}
      title="Please Verify Your Account!"
      onCancel={handleCancel}
    >
      {isLoading && <CustomSpinner />}
      <p>Email is already in use and not verified.</p>
      <Row style={{ marginTop: '16px' }}>
        <CustomCol xs={24}>
          <Button
            type={ButtonType.WHITE}
            text="Cancel"
            clickHandler={handleCancel}
            className="find-an-agent-modal-confirm-invite-your-agent-button"
          />
          <Button
            type={ButtonType.PRIMARY}
            text="Resend Link"
            className="find-an-agent-modal-confirm-find-an-agent-button"
            clickHandler={resendEmailLinkHandler}
          />
        </CustomCol>
      </Row>
    </Modal>
  );
};
export default ResendVerifyLinkModal;
