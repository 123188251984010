import styled from "@emotion/styled";
import { useContext, useState } from "react";
import { useMediaQuery } from "react-responsive";
import viewed from "../assets/CalendarPopover/Group 1180.png";
import buttonClose from "../assets/images/button-close.svg";
import Button, { ButtonType } from "../components/Button";
import CustomSpinner from "../components/spinner/CustomSpinner";
import UserContext from "../contexts/UserContext";
import {
  addPropertyToViewedFavoritesForBuyerService,
  addPropertyToViewedForBuyerService,
  removePropertyFromFavoritesForBuyerService,
  removePropertyFromRequestedForBuyerService,
} from "../services/NavihomeService";
const CustomGettingStartedHeading = styled.h1`
  color: #004876, @media screen and (max-width: 768px) {
    font-size: 28px !important;
    margin-left: 20px;
  }
  @media screen and (max-width: 1368px) {
    margin-left: 20px;
  }
  @media screen and (max-width: 576px) {
    font-size: 18px !important;
    margin-left: 0px;
    // margin-top: 10px
  }
  position: relative;
  bottom: 3%;
`;
const CustomPara = styled.p` 
  @media screen and (min-width: 811px) {
    margin-top:5rem;
  }
;
`;
interface VisitedModalPropsModel {
  isShowing: boolean;
  isFavorite: boolean;
  isRequested: boolean;
  propertyId: number;
  buyerId: number;
  property_type: string;
  text?: string;
  addViewedProperties?: any;
  addViewedFavProperties?: any;
  setIsVisited: (value: boolean) => void;
  closeAction: () => void;
  getFavoriteProperties: () => void;
  getRequestedProperties: () => void;
  selectFavoriteProperties: () => void;
  selectRequestedShowings: () => void;
  selectPropertyComments: () => void;
  linkedBuyerName: string;
}

const VisitedModal: React.FC<VisitedModalPropsModel> = ({
  text,
  isShowing,
  propertyId,
  buyerId,
  isFavorite,
  isRequested,
  addViewedProperties,
  addViewedFavProperties,
  selectFavoriteProperties,
  selectRequestedShowings,
  closeAction,
  setIsVisited,
  linkedBuyerName,
}): JSX.Element => {
  const isMobile = useMediaQuery({ maxWidth: 610 });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userState } = useContext(UserContext);
  const handleFindAnAgent = () => {
    closeAction();
  };

  const handleYesButton = async () => {
    setIsLoading(true);
    try {
      const token = userState?.agent?.token;
      if (isFavorite) {
        await removePropertyFromFavoritesForBuyerService(
          propertyId,
          buyerId,
          token
        );
        if (isRequested) {
          await removePropertyFromRequestedForBuyerService(
            propertyId,
            buyerId,
            token
          );
          selectFavoriteProperties();
        }
        await addPropertyToViewedFavoritesForBuyerService(
          propertyId,
          buyerId,
          token,
          addViewedFavProperties
        );
        addPropertyToViewedForBuyerService(
          propertyId,
          buyerId,
          userState?.agent?.token,
          addViewedProperties
        );

        closeAction();
        selectFavoriteProperties();
        setTimeout(() => {
          setIsVisited(true);
        }, 2000);
        return;
      }
      if (isRequested) {
        await removePropertyFromRequestedForBuyerService(
          propertyId,
          buyerId,
          token
        );
        addPropertyToViewedForBuyerService(
          propertyId,
          buyerId,
          userState?.agent?.token,
          addViewedProperties
        );
        closeAction();
        selectRequestedShowings();
        setIsLoading(false);
        setTimeout(() => {
          setIsVisited(true);
        }, 2000);
        return;
      }
      addPropertyToViewedForBuyerService(
        propertyId,
        buyerId,
        userState?.agent?.token,
        addViewedProperties
      );
      closeAction();
      setIsLoading(false);
      setIsVisited(true);
    } catch (error) {
      console.log("ERROR", error);
      closeAction();
      setIsLoading(false);
    }
  };

  const modalClassName = isShowing
    ? "find-an-agent-modal-modal u-visible"
    : "find-an-agent-modal-modal u-hidden";
  const cardClassName = isShowing
    ? "find-an-agent-modal-modal__card animation__pop-in"
    : "find-an-agent-modal-modal__card animation__pop-out";

  return (
    <div className={modalClassName}>
      {isLoading && <CustomSpinner />}
      <div className={cardClassName}>
        <div className="find-an-agent-modal-modal__row">
          <CustomGettingStartedHeading>
            Viewed this
            <span style={{ borderBottom: "3px solid #C2D500" }}>
              {" "}
              property?
            </span>
          </CustomGettingStartedHeading>
          <img
            className="find-an-agent-modal-modal__close-button"
            src={buttonClose}
            onClick={closeAction}
            alt="Close button"
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="find-an-agent-modal-modal__content">
          <div className="find-an-agent-modal-logo">
            <img src={viewed} alt="..." />
          </div>
          <div className="find-an-agent-modal-para">
            <p>
              <p>
                Are you sure you viewed this property with {linkedBuyerName}?
                This was not scheduled on NaviHome.
              </p>
            </p>
            <br />
            {!isMobile && (
              <>
                <br />
              </>
            )}
            <CustomPara>
              <p>
                Please confirm that you have viewed it before committing to the
                action, as it is irreversible.
              </p>
            </CustomPara>
            {!isMobile && (
              <>
                <br />
              </>
            )}
            <br />
            <br />
            <div className="visied-modal-button-div">
              <Button
                type={ButtonType.WHITE}
                text={"CONFIRM"}
                clickHandler={handleYesButton}
                className="visied-modal-invite-your-agent-button"
              />

              <Button
                type={ButtonType.FIND_AN_AGENT}
                text={"CANCEL"}
                className="find-an-agent-modal-find-an-agent-button"
                clickHandler={handleFindAnAgent}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitedModal;
