import { Col, Row } from 'antd';
import {
  Fragment,
  useContext,
  useEffect,
  useState
} from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobal } from 'reactn';
import Button, { ButtonType } from '../components/Button';
import PropertyCard from '../components/PropertyCard';
import SearchField from '../components/SearchField';
import { notification } from '../components/notification';
import CustomSpinner from '../components/spinner/CustomSpinner';
import AgentContext from '../contexts/AgentContext';
import UserContext, { UserType } from '../contexts/UserContext';
import {
  filterOpenHousesProperties,
  getFavoritePropertiesForBuyerService,
  getLinkedPropertyForMap,
  getPropertyDetailsService,
  getRequestedPropertiesForBuyerService
} from '../services/NavihomeService';
import { activatedPlan } from '../utils/base';
import TourDetails from './TourDetails';
import TourDetailsForm from './TourDetailsForm';
import UserHeader from './notificationAgentPage/UserHeader';

//Create Interface

const NewTourDetails: React.FC = () => {
  const { agentState } = useContext(AgentContext);
  const history = useHistory();
  const { userState } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const [reqSearchText, setReqSearchText] = useState('');
  const [requestedProperties, setRequestedProperties] = useState([]);


  const [linkedProperties, setLinkedProperties] = useState([]);
  const [filteredRequestedProperties, setFilteredRequestedProperties] =
    useState([]);

  const [buyerGlobalId, setBuyerGlobalId] = useGlobal<any>('buyerId');
  const [linkedLoading, setLinkedLoading] = useState(false);

  const [formData, setFormData] = useState({
    id: null,
    dateTime: null,
    timePerProp: null,
    startAdd: null,
    startAddCity: null,
    endingAdd: null,
    endingAddCity: null,
    selectedProperties: null,
    area: null,
    propertyStatus: null,
    agentId: null,
  });
  const getRequestedProperties = async (buyerId: any) => {
    setIsLoading(true);
    let token = '';
    if (userState?.userType === UserType.AGENT) {
      token = userState?.agent?.token;
    } else {
      token = userState?.buyer?.token;
    }
    try {
      if (!buyerId) {
        setIsLoading(false);
        return;
      }
      const properties = await getRequestedPropertiesForBuyerService(
        buyerId,
        token,
        "No"
      );

      var listings: any = [];
      for (var i = 0; i < properties.length; i++) {
        const listing = await getPropertyDetailsService(
          properties[i]?.propertyId
        );
        if (listing?.listPrice) {
          listings.push(listing);
        }
      }
      let response: any = await getFavoriteProperties(buyerId);

      const requestedFav = listings?.filter(
        (item: any) =>
          response.findIndex(
            (favItem: { mlsId: number }) => favItem?.mlsId === item?.mlsId
          ) > -1
      );
      for (let i = 0; i < requestedFav?.length; i++) {
        const requestedIndex = listings.findIndex(
          (listing: any) => listing?.mlsId === requestedFav?.[i]?.mlsId
        );
        listings.splice(requestedIndex, 1);
        const favIndex = response?.findIndex(
          (res: { mlsId: number }) => res?.mlsId === requestedFav?.[i]?.mlsId
        );
        response.splice(favIndex, 1);
      }
      let newPropArr = [...requestedFav, ...listings, ...response];
      if (userState?.userType === UserType.BUYER) {
        const propPromise = newPropArr.map(async (ind: any) => {
          return new Promise(async (resolve) => {
            const listingId: number = Number(ind?.listingId);
            const dataval = await filterOpenHousesProperties(listingId);
            if (dataval?.success) {
              return resolve(ind);
            }
            return resolve('');
          });
        });

        const openHouseArr = await Promise.all(propPromise);

        if (openHouseArr?.length > 0) {

          setRequestedProperties(openHouseArr);
        }
      } else {
        setRequestedProperties(newPropArr);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      return notification('Something Went Wrong!', 'error');
    }
  };

  const getFavoriteProperties = async (buyerId: any) => {
    let token = '';
    if (userState?.userType === UserType.AGENT) {
      token = userState?.agent?.token;
    } else {
      token = userState?.buyer?.token;
    }
    const properties = await getFavoritePropertiesForBuyerService(
      buyerId,
      token,
      "No"
    );
    var listings = [];

    for (var i = 0; i < properties.length; i++) {
      const listing = await getPropertyDetailsService(properties[i].propertyId);
      if (listing?.listPrice) {
        listings?.push(listing);
      }
    }

    return listings;
  };

  const getAllLinkedPorpertiesForBuyer = async (buyerId: any) => {
    setLinkedLoading(true);
    let token = '';
    if (userState?.userType === UserType.AGENT) {
      token = userState?.agent?.token;
    } else {
      token = userState?.buyer?.token;
    }
    try {
      if (!buyerId) {
        setLinkedLoading(false);
        return;
      }
      if (!userState?.agent?.token) {
        setLinkedLoading(false);
        return;
      }
      const properties = await getLinkedPropertyForMap(buyerId, token);
      if (properties?.length <= 0) {
        setLinkedLoading(false);
        return;
      }
      let listings = [];

      for (let i = 0; i < properties.length; i++) {
        const linkprop = await getPropertyDetailsService(
          properties[i].propertyId
        );
        if (linkprop?.listPrice) {
          listings.push(linkprop);
        }
      }
      setLinkedProperties(listings);
      setLinkedLoading(false);
    } catch (err) {
      setLinkedLoading(false);
      return notification('something went wrong ', 'error');
    }
  };

  const reqSearchAction = (query: string) => {
    setReqSearchText(query);
    if (requestedProperties.length <= 0) {
      return <></>;
    }
    const filteredproperty: any = requestedProperties?.filter((item) => {
      if (item?.listPrice?.toString()?.includes(query)) {
        return item;
      }
      if (item?.property?.bedrooms === query) {
        return item;
      }
      if (item?.property?.area?.toString()?.includes(query)) {
        return item;
      }
      if (item?.address?.city?.toUpperCase()?.includes(query?.toUpperCase())) {
        return item;
      }
      if (
        item?.address?.streetName?.toUpperCase()?.includes(query?.toUpperCase())
      ) {
        return item;
      }
      if (
        item?.address?.streetNumber
          ?.toString()
          ?.toUpperCase()
          ?.includes(query?.toUpperCase())
      ) {
        return item;
      }
      if (item?.address?.state?.toUpperCase()?.includes(query?.toUpperCase())) {
        return item;
      }
      if (item?.property?.type?.toUpperCase()?.includes(query?.toUpperCase())) {
        return item;
      }
      if (item?.address?.postalCode?.includes(query)) {
        return item;
      }
      if (item?.property?.type?.includes(query)) {
        return item;
      }
    });
    setFilteredRequestedProperties(filteredproperty);
  };
  const renderListedProperties = () => {
    if (requestedProperties.length <= 0) {
      return (
        <>
          <h4>This buyer doesn't have any properties</h4>
        </>
      );
    }
    const cards = (
      reqSearchText.length > 0
        ? filteredRequestedProperties
        : requestedProperties
    )?.map((listing) => {
      if (listing?.length === 0) {
        return <></>;
      }
      return (
        <div className="px-2">
          <PropertyCard
            isadded={
              linkedProperties?.find((item) => item?.mlsId === listing?.mlsId)
                ? false
                : true
            }
            propertyId={listing?.mlsId}
            image={listing?.photos?.length > 0 ? listing?.photos[0] : ''}
            price={listing?.listPrice}
            type={listing?.property?.type}
            beds={listing?.property?.bedrooms}
            baths={
              listing?.property?.bathsFull + 0.5 * listing?.property?.bathsHalf
            }
            squareFootage={listing?.property?.area}
            latitude={listing?.geo?.lat}
            longitude={listing?.geo?.lng}
            country={listing?.address?.country}
            state={listing?.address?.state}
            city={listing?.address?.city}
            streetName={listing?.address?.streetName}
            streetNumber={listing?.address?.streetNumber}
            postalCode={listing?.address?.postalCode}
            bathsHalf={listing?.property?.bathsHalf}
            mlsId={listing?.listingId}
            openHouse="type"
            feature=""
            setCount={()=>{}}
            upcomingBuyerId={history?.location?.search?.split('=')?.[1] ?? ''}
            agentIdForBuyer={userState?.buyer?.buyerAgentId}
            propertyDetails={listing ? listing : ' '}
            isBuyerLinked={
              userState?.userType === UserType.AGENT ? true : userState?.buyer?.isLinked
            }
            property_type={
              userState?.userType === UserType.BUYER &&
              history?.location?.pathname.includes('/newtourpage')
                ? 'open_house'
                : ''
            }
            selectFavoriteProperties={() => {
            }}
            selectRequestedShowings={() => {
            }}
            selectPropertyComments={() => {
            }}
            setTourPropPage={() => {
            }}
            setUnlinkPropHeader={() => {
            }}
          />
        </div>
      );
    });
    return <Fragment>{cards}</Fragment>;
  };
  

  useEffect(() => {
    if (buyerGlobalId) {
      localStorage.setItem('buyerGlobalId', buyerGlobalId);
    }
    const buyerId = localStorage.getItem('buyerGlobalId');
    getRequestedProperties(buyerGlobalId ? buyerGlobalId : buyerId);
    getAllLinkedPorpertiesForBuyer(buyerGlobalId ? buyerGlobalId : buyerId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (buyerGlobalId) {
      localStorage.setItem('buyerGlobalId', buyerGlobalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);
  const renderNewTourDetails = () => {
    let agentName = '';
    let buyerName = '';

    if (userState?.userType === UserType.AGENT) {
      agentName = userState?.agent?.name;
      buyerName = agentState?.buyerName;
    } else {
      agentName = userState?.buyer?.buyerAgentName;
      buyerName = userState?.buyer?.name;
    }
    return (
      <>
        {(isLoading || linkedLoading) && <CustomSpinner />}

        {/* this is first row  */}
        <Row gutter={[10, 10]} justify="center">
          {/* {/ this column is used for name /} */}
          <Col
            xs={{ span: 24 }}
            sm={{ span: 10 }}
            md={{ span: 12 }}
            lg={{ span: 6 }}
          >
            <h1>{agentState.buyerName}-Tour</h1>
          </Col>

          {/* {/ this column ise used for search bar and button /} */}

          <Col
            xs={{ span: 20 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
          >
            <div className="new_tour_details_searchfield">
              <SearchField
                placeholder="Enter neighbourhood,ZIP code "
                searchAction={reqSearchAction}
                className="newtour_details_search field"
              />
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 10 }}
            style={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <div className="new_tour_details_route_button">
              <Button
                type={ButtonType.PRIMARY}
                text={'ROUTE TOUR'}
                clickHandler={() => {}}
              />
            </div>
            {/* {/ </div> /} */}
          </Col>
        </Row>
        {/* this is second row  for form and  properties */}
        <Row>
          {/* this is for tour detail form  */}
          <Col
            xs={{ span: 24 }}
            sm={{ span: 10 }}
            md={{ span: 12 }}
            lg={{ span: 6 }}
            style={{ paddingLeft: '0' }}
          >
            <TourDetailsForm
              agentName={agentName}
              buyerName={buyerName}
              setFormData={setFormData}
              setStartLocation={()=>{}}
              setEndLocation={()=>{}}
              buyerIdForAddress={agentState.buyerId}
              buyerSideAgentId={userState.buyer.buyerAgentId}
              setEliminatePropertyFromTheTour={()=>{}}
              setFilteredLinkedProperties={()=>{}}
              setFormDataEdited={()=>{}}
              setIsFormDataChanged={()=>{}}
              setIsOutTimeProperty={()=>{}}
              setLinkedPropertiesData={()=>{}}
              setSaveTourClicked={()=>{}}
              setScheduleStatus={()=>{}}
              setStartTimeForTimeStamp={()=>{}}
            />
          </Col>
          {/* this is for properties */}
          <Col
            xs={{ span: 24 }}
            sm={{ span: 10 }}
            md={{ span: 12 }}
            lg={{ span: 18 }}
          >
            <div className="render-listed-properties-card new_tour_detail_listed_prop">
              {renderListedProperties()}
            </div>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      {activatedPlan.includes(userState?.agent?.subscription_type) && (
        <>{userState.userType === UserType.AGENT && <UserHeader />}</>
      )}

      {/* here we have to use TourDetail component */}
      {history?.location?.pathname === 'googlemap' ? (
        <TourDetails />
      ) : (
        <div className="new_tour_details">{renderNewTourDetails()}</div>
      )}
    </>
  );
};

export default NewTourDetails;
