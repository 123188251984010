/* eslint-disable jsx-a11y/alt-text */
import { EditOutlined, FileImageOutlined, GlobalOutlined, HomeOutlined, MailOutlined, StarOutlined, UserOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Affix, Avatar, Button, Col, Form, Grid, Input, Popconfirm, Radio, Row, Select, Upload, message } from "antd";
import * as EmailValidator from "email-validator";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory } from "react-router-dom";
import "../../assets/css/pages/account-agent-details-agent-page.css";
import PropertyFiltersContext from "../../contexts/PropertyFiltersContext";
import UserContext, { UserType } from "../../contexts/UserContext";
import ChangePasswordModal from "../../modals/ChangePasswordModal";
import ThankyouModal from "../../modals/ThankyouModal";
import {
  certificates,
  deleteCertificates, getAgentAddressInfoService,
  getAgentCoverageAreaService,
  getAgentInfoService, getCertificates,
  getPlacebySearch,
  getmoreinformationService,
  postMessageToUser, updateAgentInfoService,
  uploadImage
} from "../../services/NavihomeService";
import { notification } from "../notification";
import CustomSpinner from "../spinner/CustomSpinner";

const { TextArea } = Input;
const { useBreakpoint } = Grid;
const { Option } = Select;

const CustomSaveButton = styled(Button)`
  color: #fff;
  background: #004876;
  border-radius: 10px;
  padding: 0px 20px;
`;

const CustomActionButton = styled(Button)`
  border-radius: 10px;
  padding: 0px 20px;
`;

const CustomAnchorTag = styled.a`
  color: #eb752f;
  border-bottom: 1px solid #eb752f;
  text-decoration: none;
  &:hover {
    border-bottom: 1px solid blue;
  }
`;

const CustomInput = styled(Input)`
  border-radius: 10px;
  border: 1px solid #004876;
`;

const CustomInput1 = styled(Input)`
  border-radius: 10px;
  margin-top: -4px;
  border: 1px solid #004876;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: red;
  }
  :-ms-input-placeholder {
    color: red;
  }
`;

const CustomTextArea = styled(TextArea)`
  border-radius: 10px;
  border: 1px solid #004876;
`;

const CustomRow = styled(Row)`
  text-align: center;
`;

const CustomUserOutlined = styled(UserOutlined)`
  color: #004876;
`;

const CustomStarOutlined = styled(StarOutlined)`
  color: #004876;
`;

const CustomGlobalOutlined = styled(GlobalOutlined)`
  color: #004876;
`;

const CustomMailOutlined = styled(MailOutlined)`
  color: #004876;
`;

const CustomHomeOutlined = styled(HomeOutlined)`
  color: #004876;
`;

const CustomSelect = styled(Select)`
  height: 130px;
  width: 100%;
  & .ant-select-selector {
    height: 100%;
    align-items: start;
    justify-content: start;
    border-radius: 10px !important;
    border: 1px solid #004876 !important;
  }
  & .ant-select-selection-item {
    border-radius: 10px !important;
    border: 1px solid #004876 !important;
    height: 30px;
    align-items: center;
    justify-content: center;
  }
  & .ant-select-selection-item-remove {
    display: flex;
    align-items: center;
    color: #004876 !important;
    margin-left: 5px;
    font-size: 14px;
  }
`;

const CustomSelectRow = styled(Row)`
  bottom: 0px;
  width: 100%;
`;

const CustomParagraph = styled.p`
  margin: 10px 0px !important;
`;

const CustomErrParagraph = styled.p`
  margin: 10px 0px !important;
  // color: #c5960a;
  color: #c78f6e;
`;

const CustomPhoneInput = styled(PhoneInput)`
  height: 8.5rem;
  padding: 0px;
  border-radius: 5px;
  &.react-tel-input .country-list .search-box {
    text-transform: capitalize;
  }
`;

const CustomCol = styled(Col)`
  padding-top: 16px;
`;


const AccountDetailsAgentPage: React.FC = () => {
  const [agentInfo, setAgentInfo] = useState<any>(null);
  const { setName, clearFilters } = useContext(PropertyFiltersContext);
  const [moreInfo, setMoreInfo] = useState<any>(null);
  const [certificateInfo, setCertificateInfo] = useState<any>(null);
  const [moreInfoValue, setMoreInfoValue] = useState<boolean>(false);
  const [agentAddressInfo, setAgentAddressInfo] = useState<any>(null);
  const [editUrl, setEditUrl] = useState<string>(null);
  const [avatarUrl, setAvatarUrl] = useState<string>(null);
  const [spinState, setSpinState] = useState<number>(0);
  const [countDays, setCountDays] = useState<number>(0);
  const [countFreeDays, setFreeCountDays] = useState<number>(0);
  const [spinAvatar1State, setAvatar1SpinState] = useState<number>(0);
  const [agentAddressInfoValue, setAgentAddressInfoValue] = useState<boolean>(false);
  const [agentCoverageInfo, setAgentCoverageInfo] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadProfPicErr, setUploadProfPicErr] = useState<boolean>(false);
  const [uploadCertificatesErr, setUploadCertificatesErr] = useState<boolean>(false);
  const [uploadCompLogoErr, setUploadCompLogoErr] = useState<boolean>(false);
  const [bioErr, setBioErr] = useState<boolean>(false);
  const [webErr, setWebErr] = useState<boolean>(false);
  const [taglineErr, setTagLineErr] = useState<boolean>(false);
  const [isShowingNotificationModal, setIsShowingNotificationModal] = useState<boolean>(false);
  const history = useHistory();
  const [form] = Form.useForm();
  const { userState, logOut, changeUserType, logIn } = useContext(UserContext);
  const radioButtonOptions = [
    { label: "Email", value: "CONTACT_RADIO_EMAIL" },
    { label: "Phone", value: "CONTACT_RADIO_PHONE" },
    { label: "Both", value: "CONTACT_RADIO_BOTH" }
  ];
  const [phone, setPhone] = useState<string>('')
  const [availablePlaces, setAvailablePlaces] = useState<any>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const screens = useBreakpoint();
  const [isShowingChangePasswordModal, setIsShowingChangePasswordModal] = useState<boolean>(false);
  const [isspinAvatar3State, setIsAvatar3SpinState] = useState<boolean>(false);

  const isMobile = useMediaQuery({ maxWidth: 890 });
  const isResponsive = useMediaQuery({ maxWidth: 576 });
  const isProfile = useMediaQuery({ maxWidth: 1199 });

  const getAgentInfo = async () => {
    setIsLoading(true);
    try {
      const res: any = await getAgentInfoService(userState.agent.token, userState.agent.agentId);
      const from = new Date();
      const to = userState?.agent?.expireAt;
      const count = Math.abs(moment(from, "YYYY-MM-DD").startOf("day").diff(moment(to, "YYYY-MM-DD").startOf("day"), "days")) + 1;

      const freeStartDate = userState?.agent?.registeredAt;
      const freeDayCount = Math.abs(moment(from, "YYYY-MM-DD").startOf("day").diff(moment(freeStartDate, "YYYY-MM-DD").startOf("day"), "days")) + 1;
      setFreeCountDays(freeDayCount > 0 ? freeDayCount : 0)
      setCountDays(count > 0 ? count : 0);

      setPhone(res?.phone);
      setAgentInfo(res);
      setIsLoading(false);
    } catch (error) {
      console.log("Error", error);
      setIsLoading(false);
    }
  };

  const getmoreinformation = async () => {
    setIsLoading(true);
    try {
      await getmoreinformationService(userState.agent.agentId).then((res) => {
        setMoreInfo(res);
        setMoreInfoValue(true);
        setIsLoading(false);
      });
    } catch (error) {
      console.log("Error", error);
      setIsLoading(false);
    }
  };

  const getAgentAddressInfo = async () => {
    setIsLoading(true);
    try {
      await getAgentAddressInfoService(userState.agent.agentId).then((res) => {
        setAgentAddressInfo(res);
        setAgentAddressInfoValue(true);
        setIsLoading(false);
      });
    } catch (error) {
      console.log("Error", error);
      setIsLoading(false);
    }
  };

  const getAgentCoverageArea = async () => {
    setIsLoading(true);
    try {
      await getAgentCoverageAreaService(userState.agent.token, userState.agent.agentId).then((res) => {
        setAgentCoverageInfo(res);
        setIsLoading(false);
      });
    } catch (error) {
      console.log("Error", error);
      setIsLoading(false);
    }
  };

  const getCertificate = async () => {
    await getCertificates(userState.agent.agentId, userState.agent.token)
      .then((res) => {
        setCertificateInfo(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error: ", err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCertificate();
    getAgentInfo();
    getmoreinformation();
    getAgentAddressInfo();
    getAgentCoverageArea();

    if (userState?.agent?.subscription_type !== 'Navihome Advanced Plan') {
      setTagLineErr(true);
    }
    if (userState?.agent?.subscription_type !== 'Navihome Advanced Plan' && userState?.agent?.subscription_type !== 'Navihome Premimum Plan') {
      setUploadProfPicErr(true);
      setUploadCertificatesErr(true);
      setUploadCompLogoErr(true);
    }
    if (userState?.agent?.subscription_type !== 'Navihome Advanced Plan' && userState?.agent?.subscription_type !== 'Navihome Premimum Plan' && userState?.agent?.subscription_type !== 'Navihome Basic Plan') {
      setWebErr(true);
      setBioErr(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateAgentInfo = () => {
    try {
      setIsLoading(true);
      let formData = { ...form.getFieldsValue() };
      if (avatarUrl) {
        formData = { ...formData, avatarUrl };
      }
      if (formData.bio || formData.url || formData.Tagline || formData.bio === "" || formData.url === "" || formData.Tagline === "" || editUrl) {
        const moreinformation = {
          bio: formData?.bio,
          url: formData?.url,
          tagline: formData?.Tagline,
          imgurl: editUrl
        };
        if (!editUrl) {
          delete moreinformation.imgurl;
        }
        if (!formData.bio) {
          delete moreinformation.bio;
        }
        if (!formData.url) {
          delete moreinformation.url;
        }
        if (!formData.Tagline) {
          delete moreinformation.tagline;
        }

        formData.moreinformation = { ...moreinformation };
        delete formData.bio;
        delete formData.url;
        delete formData.Tagline;
      }
      if (formData?.email !== undefined && formData?.email !== agentInfo?.email) {
        if (!EmailValidator.validate(formData?.email)) {
          setIsLoading(false);
          return notification("Email format not correct.", "error");
        }
      }
      if (avatarUrl) {
        formData = { ...formData, avatarUrl };
      }
      if (formData?.coverageArea?.length === 0) {
        setIsLoading(false);
        return notification("Please select coverage areas.", "warning");
      }
      if (phone?.length > 0) formData.phone = phone;
      updateAgentInfoService(userState?.agent?.token, userState?.agent?.agentId, formData)
        .then(async (res) => {
          if (!res?.success) {
            setIsLoading(false);
            return;
          }
          setName(String(Math.floor(Math.random() * 100 + 1)));
          if (formData?.preferredContact && formData?.preferredContact !== agentInfo?.preferredContact) {
            logOut();
            await logIn(userState?.agent?.email, userState?.agent?.password);
          }
          if (formData?.email) {
            if (agentInfo?.preferredContact === "CONTACT_RADIO_BOTH" || agentInfo?.preferredContact === "CONTACT_RADIO_PHONE") {
              postMessageToUser(agentInfo?.phone, `Your account has been changed for your Navihome. A link has been sent to your registered email. Please verify and login.`);
            }

            setIsShowingNotificationModal(true);
            setIsLoading(false);
            handlerModalTimeout();
          } else {
            setIsLoading(false);
            return notification("Account Updated Successfully!", "success");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("ERROR", error);
        });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("ERROR: ", error);
    }
  };
  const searchPlaceByText = async (text: any) => {
    if (text.trim()) {
      setIsDropdownOpen(true);
      getPlacebySearch(text).then((res: any) => {
        const allPlaces: any = [];
        res.predictions.map((place: any) => allPlaces.push({ name: place.structured_formatting.main_text }));
        setAvailablePlaces(allPlaces);
      });
    } else {
      setIsDropdownOpen(false);
    }
  };

  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }
  const changePasswordModalHandler = () => {
    setIsShowingChangePasswordModal(!isShowingChangePasswordModal);
  };
  const notificationModalHandler = () => {
    setIsShowingNotificationModal(!isShowingNotificationModal);
    clearFilters();
    logOut();
    changeUserType(UserType.AGENT);
    history.push("/");
  };

  const handleChange = async (info: any, key: string) => {
    if (key === "avatar2" && info?.file?.size / 1024 / 1024 < 2) setSpinState(1);
    if (key === "avatar1" && info?.file?.size / 1024 / 1024 < 2) setAvatar1SpinState(1);
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "error") {
      const fd = new FormData();
      fd.append("file", info.file.originFileObj);
      const locationUrl: any = await uploadImage(fd, userState?.agent?.token);
      if (key === "avatar2") setEditUrl(locationUrl.Location);
      if (key === "avatar1") setAvatarUrl(locationUrl.Location);
      if (locationUrl.Location && key === "avatar2") setSpinState(0);
      if (locationUrl.Location && key === "avatar1") setAvatar1SpinState(0);
    }
  };
  const clickChange = async (info: any, key: string) => {
    if (key === "avatar3") {
      setIsAvatar3SpinState(true);
    }
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "error") {
      const fd = new FormData();
      fd.append("file", info.file.originFileObj);
      const locationUrl: any = await uploadImage(fd, userState?.agent?.token);
      if (locationUrl.Location && key === "avatar3") {
        const data = await certificates(locationUrl.Location, "", userState?.agent?.agentId, userState?.agent?.token);
        if (data) {
          setIsAvatar3SpinState(false);
          getCertificate();
        }
      }
    }
  };
  const confirmCertDelete = async (e: React.MouseEvent<HTMLElement>, url: any) => {
    setIsLoading(true);
    try {
      await deleteCertificates(url?.agentId, url?.certificateDesignationsId, userState.agent.token);
      message.success("Certificate deleted!");
      getCertificate();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const cancel = (e: React.MouseEvent<HTMLElement>) => {
    message.error("Click on No");
  };

  const handlerModalTimeout = () => {
    setTimeout(() => {
      notificationModalHandler();
    }, 4000);
  };

  return (
    <div className="account-detail-agent-page">
      {(isspinAvatar3State || isLoading) && (
        <span>
          <CustomSpinner />
        </span>
      )}
      {agentInfo && agentAddressInfoValue && moreInfoValue ? (
        <div>
          <Row justify="space-between" className="lading-sale-agent-page__offer__container_warning">
            <h3>Account Details</h3>
            {countFreeDays <= 30 && (
              <span>Your Navihome Advanced Free Trial will end in {countDays} days!</span>
            )}

            <div className="agent-update-button">
              <Link to="/">
                <CustomActionButton size="middle">CANCEL</CustomActionButton>
              </Link>
              &nbsp;&nbsp;&nbsp;
              <CustomSaveButton size="middle" onClick={updateAgentInfo}>
                SAVE AND UPDATE
              </CustomSaveButton>
            </div>
          </Row>
          <Row gutter={32}>
            <Col xl={6} sm={24} xs={24} className="image-margin">
              <>
                {!isProfile ? (
                  <Affix offsetTop={80} offsetBottom={5}>
                    <Row justify="center">
                      {spinAvatar1State ? (
                        <span>
                          <CustomSpinner style={{ marginLeft: "30px" }} />
                        </span>
                      ) : (
                        <Avatar
                          src={avatarUrl ? `${avatarUrl}` : agentInfo?.avatarUrl}
                          size={{
                            xs: 64,
                            sm: 80,
                            md: 100,
                            lg: 124,
                            xl: 216,
                            xxl: 280
                          }}
                          icon={<UserOutlined />}
                        // className="avatar"
                        />
                      )}
                    </Row>
                    <CustomRow justify="center">
                      <Upload
                        name="avatar"
                        accept="image/*"
                        className="avatar-uploader"
                        showUploadList={false}
                        disabled={uploadProfPicErr}
                        beforeUpload={beforeUpload}
                        onChange={(info) => handleChange(info, "avatar1")}
                      >
                        <span>
                          <EditOutlined style={{ color: "#C2D500" }} />
                          Edit your profile picture
                        </span>
                      </Upload>
                      <CustomParagraph>Upload a picture of size: 400 x 400px</CustomParagraph>
                    </CustomRow>
                    {uploadProfPicErr && (
                      <CustomErrParagraph>
                        Your profile picture will be displayed on your profile only if you <CustomAnchorTag href="/pricing">Upgrade Now</CustomAnchorTag>
                      </CustomErrParagraph>
                    )}
                  </Affix>
                ) : (
                  <>
                    <Row justify="center">
                      {spinAvatar1State ? (
                        <span>
                          <CustomSpinner style={{ marginLeft: "30px" }} />
                        </span>
                      ) : (
                        <Avatar
                          src={avatarUrl ? `${avatarUrl}` : agentInfo?.avatarUrl}
                          size={{
                            xs: 64,
                            sm: 80,
                            md: 100,
                            lg: 124,
                            xl: 216,
                            xxl: 280
                          }}
                          icon={<UserOutlined />}
                          className="avatar"
                        />
                      )}
                    </Row>
                    <CustomRow justify="center">
                      <Upload
                        name="avatar"
                        accept="image/*"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        disabled={uploadProfPicErr}
                        onChange={(info) => handleChange(info, "avatar1")}
                      >
                        <span>
                          <EditOutlined style={{ color: "#C2D500" }} />
                          Edit your profile picture
                        </span>
                      </Upload>
                      <CustomParagraph>Upload a picture of size: 400 x 400px</CustomParagraph>
                    </CustomRow>
                    {uploadProfPicErr && (
                      <CustomErrParagraph>
                        Your profile picture will be displayed on your profile only if you <CustomAnchorTag href="/pricing">Upgrade Now</CustomAnchorTag>
                      </CustomErrParagraph>
                    )}
                  </>
                )}
              </>
            </Col>
            <Col xl={9} sm={24} xs={24}>
              <h3>General Information</h3>
              <CustomParagraph>
                Verify or update your information below. This helps us confirm your account. If your MLSID is incorrect or has changed, please contact us by emailing info@navihome.com.
              </CustomParagraph>
              <Form form={form} layout="vertical">
                <Form.Item label="Name" name="name" required>
                  <CustomInput size="large" defaultValue={agentInfo.name} disabled={true} prefix={<CustomUserOutlined />} />
                </Form.Item>
                <Form.Item label="Company" name="company" required>
                  <CustomInput size="large" defaultValue={agentInfo.company} placeholder="Doe Real Estate agency" prefix={<CustomUserOutlined />} />
                </Form.Item>
                <Form.Item label="MLS ID" name="mlsId" required>
                  <CustomInput size="large" defaultValue={agentInfo.mlsld} disabled={true} placeholder="1234567" prefix={<CustomUserOutlined />} />
                </Form.Item>
                <h3>Change Password</h3>
                <CustomParagraph>
                  <span className="upgradeNow">
                    <CustomAnchorTag onClick={changePasswordModalHandler}>Click here</CustomAnchorTag>
                    <span style={{ paddingLeft: "5px" }}> to change your password</span>
                  </span>
                </CustomParagraph>
                <h3 style={{ marginTop: "15px" }}>Coverage Area</h3>
                <CustomParagraph>
                  Specify your coverage area to help buyers see your profile while searching for the agents. You can enter zip codes, neighborhoods and regions within your city. This information will
                  only be visible to you.
                </CustomParagraph>

                <CustomSelectRow style={{ position: screens.xl ? "absolute" : "relative" }}>
                  {typeof agentCoverageInfo?.city === "string" ? (
                    <Form.Item label="Coverage Areas - ZIP, Neighborhoods etc" name="coverageArea" className="coveragecontent">
                      <CustomSelect mode="multiple" defaultValue={JSON.parse(agentCoverageInfo?.city)} onSearch={searchPlaceByText} onSelect={() => setIsDropdownOpen(false)} open={isDropdownOpen}>
                        {availablePlaces.map((place: any) => {
                          return (
                            <Option value={place.name} label={place.name}>
                              {place.name}
                            </Option>
                          );
                        })}
                      </CustomSelect>
                    </Form.Item>
                  ) : (
                    <></>
                  )}
                </CustomSelectRow>
              </Form>
            </Col>

            <Col xl={9} sm={24} xs={24}>
              <h3>Contact Information</h3>
              <CustomParagraph>Verify your contact information below to ensure you get text and/or email reminders and notifications.</CustomParagraph>
              <Form form={form} layout="vertical">
                <Form.Item label="Email" name="email" required>
                  <CustomInput size="large" defaultValue={agentInfo?.email} placeholder="John Doe" prefix={<CustomMailOutlined />} />
                </Form.Item>
                <Form.Item label="Phone Number" name="phone" required style={{ marginBottom: "-25px" }}>
                  <div>
                    <CustomPhoneInput
                      value={phone}
                      inputStyle={{
                        display: "flex",
                        justifyContent: "flex-start",
                        bottom: "-2rem",
                        right: "-7rem",
                        height: "4.3rem",
                        width: isMobile ? "100%" : "100%",
                        marginTop: "-4px",
                        border: "1px solid #365073 ",
                        borderRadius: "11px"
                      }}
                      inputClass="register-agent-page__form-item"
                      dropdownStyle={{
                        width: "20rem",
                        position: "relative",
                        bottom: "21rem",
                        overflowX: "hidden"
                      }}
                      inputProps={{
                        setValue: { setPhone },
                      }}
                      onChange={(phone: any) => {
                        if (phone?.length >= 10) {
                          setPhone(phone);
                        } else {
                          setPhone("");
                        }
                      }}
                      country={"us"}
                      specialLabel=""
                      buttonStyle={{
                        position: "absolute",
                        bottom: "45.5rem",
                        height: "auto",
                        top: "2.1rem"
                      }}
                      placeholder="+1 (404) 123 4567"
                      disableDropdown={false}
                      autoFormat={true}
                      enableSearch={true}
                    />
                  </div>
                </Form.Item>
                <div style={{ display: "flex" }}>
                  <span style={{ paddingTop: "4.2px" }}>Preferred mode of contact: </span>
                  <Form.Item label="" name="preferredContact" style={{ paddingLeft: "6px" }}>
                    <Radio.Group defaultValue={agentInfo?.preferredContact} options={radioButtonOptions} />
                  </Form.Item>
                </div>

                <h3 style={{ marginTop: "20px" }}>Address Details</h3>
                <CustomParagraph>
                  Update your home and office address below. When you or your agent schedule showings, you can automatically choose one of these addresses as the starting or ending point.
                </CustomParagraph>
                <Form.Item label="Home Address" name="homeStreetAddress">
                  <CustomInput size="large" defaultValue={agentAddressInfo?.homeStreetAddress || ""} placeholder="Street Address" prefix={<CustomHomeOutlined />} />
                </Form.Item>
                <Form.Item name="homeCityAndZip">
                  <CustomInput size="large" defaultValue={agentAddressInfo?.homeCityAndZip || ""} placeholder="City and Zip Code" />
                </Form.Item>
                <Form.Item label="Office Address" name="officeStreetAddress">
                  <CustomInput size="large" defaultValue={agentAddressInfo?.officeStreetAddress || ""} placeholder="Street Address" prefix={<CustomUserOutlined />} />
                </Form.Item>
                <Form.Item name="officeCityAndZip">
                  <CustomInput size="large" defaultValue={agentAddressInfo?.officeCityAndZip || ""} placeholder="City and Zip Code" />
                </Form.Item>
                {/* </>
                )} */}
              </Form>
            </Col>
            <>
              {" "}
              <Col xl={18} sm={24} xs={24} offset={screens.xl ? 6 : 0}>
                <h3>More Information</h3>
                <Form form={form} layout="vertical">
                  <Form.Item name="bio" label="Bio">
                    <CustomTextArea
                      defaultValue={moreInfo?.bio}
                      placeholder="Highlight information about yourself and your expertise here to help clients and prospective clients know you."
                      rows={6}
                      disabled={bioErr}
                    />
                  </Form.Item>
                </Form>
                {bioErr && (
                  <CustomErrParagraph>
                    Your bio will be displayed only if you <CustomAnchorTag href="/pricing">Upgrade Now</CustomAnchorTag>
                  </CustomErrParagraph>
                )}

                <CustomParagraph>Certificates and Designations</CustomParagraph>
                <Row>
                  {certificateInfo &&
                    certificateInfo?.map((url: any) => (
                      <div style={{ position: "relative", float: "left" }}>
                        <Popconfirm title="Are you sure to delete this certificate?" onConfirm={(e: any) => confirmCertDelete(e, url)} onCancel={cancel} okText="Yes" cancelText="No">
                          <Button
                            style={{
                              position: "absolute",
                              top: "2px",
                              right: "2px",
                              zIndex: 100
                            }}
                            type="text"
                            id="clear"
                          // onClick={() => deleteCertificate(url)}
                          >
                            x
                          </Button>
                        </Popconfirm>

                        <img
                          src={url?.url ?? ""}
                          id="output"
                          style={{
                            height: "80px",
                            width: "80px",
                            margin: "20px"
                          }}
                        />
                      </div>
                    ))}
                </Row>
                <CustomRow>
                  <Upload
                    name="companyLogo"
                    accept="image/*"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    disabled={uploadCertificatesErr}
                    onChange={(info) => clickChange(info, "avatar3")}
                  >
                    <span style={{ display: "flex", justifyContent: "left" }}>
                      <EditOutlined
                        style={{
                          color: "#C2D500",
                          display: "flex",
                          justifyContent: "left"
                        }}
                      />
                      Upload your Certificates
                    </span>
                  </Upload>
                </CustomRow>
                {uploadCertificatesErr && (
                  <CustomErrParagraph>
                    Your certificates will be displayed only if you <CustomAnchorTag href="/pricing">Upgrade Now</CustomAnchorTag>
                  </CustomErrParagraph>
                )}

                <CustomParagraph>
                  Personalize the app by adding information below to display on your profile - this will help when clients are searching for agents. This information will display on your profile with
                  the Premium plan and above.
                  <span className="upgradeNow">
                    <CustomAnchorTag href="/pricing"> Check your plan and upgrade now</CustomAnchorTag>
                  </span>{" "}
                  to have these details displayed on your profile.{" "}
                </CustomParagraph>
              </Col>
              <CustomCol span={screens.xl ? 9 : 24} offset={screens.xl ? 6 : 0}>
                <Row justify={screens.xl ? "start" : "center"}>
                  {spinState ? (
                    <span>
                      <CustomSpinner />
                    </span>
                  ) : (
                    <Avatar
                      src={editUrl ? `${editUrl}` : moreInfo?.imgurl}
                      style={{
                        width: isResponsive ? "200px" : "300px",
                        height: isResponsive ? "115px" : "160px",
                        borderRadius: "7px"
                      }}
                      icon={<FileImageOutlined />}
                      shape="square"
                      className="avatar"
                    />
                  )}
                </Row>
                <CustomRow justify={screens.xl ? "start" : "center"}>
                  <Upload
                    name="companyLogo"
                    accept="image/*"
                    className="avatar-uploader"
                    showUploadList={false}
                    disabled={uploadCompLogoErr}
                    beforeUpload={beforeUpload}
                    onChange={(info) => handleChange(info, "avatar2")}
                  >
                    <div
                      style={{
                        paddingLeft: isResponsive ? "" : "32px",
                        paddingTop: "7px"
                      }}
                    >
                      <span>
                        <EditOutlined style={{ color: "#C2D500" }} />
                        Edit your company logo
                        <br />
                        Upload a picture of size: 970 x 530px
                      </span>
                    </div>
                  </Upload>
                  {uploadCompLogoErr && (
                    <CustomErrParagraph>
                      Your company logo will be displayed only if you <CustomAnchorTag href="/pricing">Upgrade Now</CustomAnchorTag>
                    </CustomErrParagraph>
                  )}
                </CustomRow>
              </CustomCol>
              <Col span={screens.xl ? 9 : 24}>
                <Form form={form} layout="vertical">
                  <Form.Item name="url" label="Website URL">
                    <CustomInput defaultValue={moreInfo?.url} size="large" placeholder="www.abcrealty.com" disabled={webErr} prefix={<CustomGlobalOutlined />} />
                  </Form.Item>
                </Form>
                {webErr && (
                  <CustomErrParagraph>
                    Your website will be displayed only if you <CustomAnchorTag href="/pricing">Upgrade Now</CustomAnchorTag>
                  </CustomErrParagraph>
                )}
              </Col>
              <Col span={screens.xl ? 18 : 24} offset={screens.xl ? 6 : 0}>
                <Form form={form} layout="vertical" style={{ paddingTop: "7px" }}>
                  <Form.Item name="Tagline" label="Tag line">
                    <CustomInput1
                      size="large"
                      defaultValue={moreInfo?.tagline}
                      placeholder="Honest and professional agent who has 15 years of experience in North Shore luxury properties.I'll find your dream home today."
                      disabled={taglineErr}
                      prefix={<CustomStarOutlined />}
                    />
                  </Form.Item>
                </Form>
                {taglineErr && (
                  <CustomErrParagraph>
                    Your tagline will be displayed only if you <CustomAnchorTag href="/pricing">Upgrade Now</CustomAnchorTag>
                  </CustomErrParagraph>
                )}
              </Col>
            </>
          </Row>
        </div>
      ) : (
        <></>
      )}
      {isShowingChangePasswordModal && (
        <ChangePasswordModal isShowing={isShowingChangePasswordModal} closeAction={changePasswordModalHandler} email={agentInfo?.email ?? ""} password={agentInfo?.password ?? ""} />
      )}
      {isLoading && <CustomSpinner />}
      {isShowingNotificationModal && <ThankyouModal isShowing={isShowingNotificationModal} closeAction={notificationModalHandler} text="Account updated successfully" />}
    </div>
  );
};

export default AccountDetailsAgentPage;
