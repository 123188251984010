/* eslint-disable jsx-a11y/img-redundant-alt */
import styled from '@emotion/styled';
import { Col, Popover, Row } from 'antd';
import GoogleMapReact from 'google-map-react';
import React, { useContext, useEffect, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useHistory } from 'react-router-dom';
import PropertyAdd from '../../assets/images/button-add-property-detail.png';
import homeWithClick from '../../assets/images/home_with_right.svg';
import ReSchedule from '../../modals/GoogleMapModals/rescheduingModal';
import {
  UpdateTimeStampBestRoute,
  createBuyerNotificationById,
  deleteScheduledPropertyByID,
  getSearchLocBoxPropertiesByCity,
  removeLinkedPropertyForBuyer,
  updateAgentScheduledStatusService,
  updateIsAllAcceptedUpcomingToursService,
} from '../../services/NavihomeService';
import MarkerSvg from '../GoogleMap/MarkerSvg';

import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router';
import UserContext, { UserType } from '../../contexts/UserContext';
import { insertEventToGoogleCalendar } from '../../utils/syncEvent';
import { notification } from '../notification';
import CustomSpinner from '../spinner/CustomSpinner';

const CustomMapDiv = styled.div`
  height: 150px;
  width: 100%;
  @media screen and (max-width: 1200px) {
    height: 300px;
  }
  @media screen and (max-width: 768px) {
    height: 400px;
  }
`;

const CustomDiv = styled.div<any>`
  position: absolute;
  top: 10px;
  right: 5%;
  /* transform: translateX(-50%); */
  z-index: 10;
  width: 300px;
`;

const Input: any = styled.input`
  width: 60%;
  height: 2.8rem;
  font-size: 10px;
  font-weight: bold;
  padding-left: 2rem;
  padding-right: 0.2rem;
  box-shadow: 0rem 0.3rem 0.6rem rgba(0, 0, 0, 0.2);
  border-radius: 0.8rem;
  border: 0.1rem solid #365073;
  vertical-align: top;
`;

const CustomPara: any = styled.p`
  font-size: 10px;
  /* background-color: #f2f226; */
`;

const CustomtimePara: any = styled.p<{ isAccepted: boolean }>`
  font-size: 10px;
  background-color: ${(props) => (props.isAccepted ? '#bcf61f' : '#ff8237')};
  color: ${(props) => (props.isAccepted ? '#000' : '#fff')};
  padding: 5px 5px;
  border-radius: 10px;
`;

const CustomImage: any = styled.img`
  margin-left: 4px;
`;

const CustomNumberDiv: any = styled.div`
  padding: 10px;
  margin-right: 5px;
  height: 20px;
  width: 20px;
  background: white;
  border-radius: 50%;
  color: black;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
`;

const CustomConLockPara: any = styled.p`
  font-size: 12px;
  color: coral;
  cursor: pointer;
  border-bottom: 3px solid coral;
`;

const CustomConLockParaConfirmed: any = styled.p`
  font-size: 12px;
  color: #c2d500;
  border-bottom: 3px solid #c2d500;
`;

const CustomRemovePara: any = styled.p`
  font-size: 12px;
  color: grey;
  cursor: pointer;
  border-bottom: 3px solid grey;
`;


export const AnyReactComponent = ({
  property_type,
  id,
  city,
  county,
  photos,
  streetNumber,
  streetName,
  state,
  postalCode,
  listPrice,
  subTypeText,
  bedrooms,
  bathrooms,
  area,
  index,
  numberingMarker,
  listingId,
  formId,
  serviceTime,
  startTime,
  endTime,
  isAccepted,
  isRejected,
  isListed,
  listingAgent,
  bestRouteId,
  propData,
  showReScheduleModal,
  isOutOfTheBox,
  isSearchLocBox,
  setLinkedPropertiesData,
  setLinkedPropertiesDataMap,
  linkedPropertiesData,
  linkedPropertiesDataMap,
  setTourPropPage,
  setShowMap,
  setRunMap,
  role,
  formData,
  bestRoute,
  setUseManual,
  setPropId,
  propertyId,
  propertyDetails,
  setIsAllAccepted,
  setPropDataDetails,
  rescheduledPropData,
  setRescheduledPropData,
  selectedPropertiesCount,
  filteredLinkedProperties,
  setIsShowSchedulignStatusModal,
  setGetDirectionsPropertyClicked,
  setIsRemoveScheduledPropClicked,
  setPropListingId,

}: any) => {
  const history = useHistory();
  const [reSchedule, setReSchedule] = useState<boolean>(false);
  const mls = history.location.pathname.split('/')[2];
  let location: any = [
    '/sell',
    '/propertydetails/' + mls,
    '/properties/' + mls,
  ];
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const propAgentName = '';
  const [listedAgent, setListedAgent] = useState<boolean>(false);
  const [showReSchAndConfirm, setShowReSchAndConfirm] =
    useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [agentEmail, setAgentEmail] = useState<string>('');
  const [agentPhone, setAgentPhone] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [agentPhoto, setAgentPhoto] = useState<string>('');
  const [isShowSearchLocBox, setIsShowSearchLocBox] = useState<boolean>(true);
  const [reScheduleTime, setReScheduleTime] = useState('');
  const [isNotEditable, setIsNotEditable] = useState<boolean>(false);

  const { userState } = useContext(UserContext);
  const { search, pathname } = useLocation();

  let markerLocation: any = [
    '/sell',
    '/propertydetails/' + mls,
    '/properties/' + mls,
    '/properties',
  ];

  function useQuery() {
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let param = useQuery();

  const addSearchLocBoxProperty = (e: any) => {
    e.stopPropagation();
    let tempPropsDetails: any = [];
    let newObj: any = propertyDetails;
    newObj['isSearchLocBox'] = 'NO';
    newObj['isOutOfTheBox'] = 'NO';
    newObj['isSearchLocBoxProperty'] = 'YES';

    tempPropsDetails.push(newObj);
    setPropDataDetails(tempPropsDetails);
    setPropId(propertyId);
    setTourPropPage(true);
    setShowMap(false);
    setRunMap(false);
    setUseManual(false);
  };

  const handleClick = (e: any) => {

    e.stopPropagation();
    const url = property_type
      ? property_type === 'open_house'
        ? `/properties/${id}?type=${property_type}&listingId=${listingId}`
        : `/properties/${id ? id : mls}`
      : property_type === 'open_house'
        ? `/properties/${id ? id : mls}?listingId=${listingId}`
        : `/properties/${id ? id : mls}`;
    const path = history.location.pathname;
    if (path !== '/sell' && !path.includes('/propertydetails') && !path.includes('/common')) {
      history.push(url);
    }
  };

  const handleReScheduledPropTime = async () => {
    //update time stamp of this propertyID and on this formId
    if (reScheduleTime === '') {
      return notification('Please select time ', 'error');
    }
    setIsLoading(true);
    try {
      let timePerProp = serviceTime?.split(' ')?.[0];
      let timeDur = serviceTime?.split(' ')?.[1];

      if (timeDur === 'min') {
        timePerProp = Number(timePerProp);
      } else {
        timePerProp = Number(timePerProp) * 60;
      }

      let ReScheduleStartTime = moment(reScheduleTime, 'hh:mm:ss A')?.format(
        'hh:mm:ss A'
      );
      let ReScheduleEndTime = moment(reScheduleTime, 'hh:mm:ss A')
        ?.add(timePerProp, 'minutes')
        ?.format('hh:mm:ss A');

      await UpdateTimeStampBestRoute(
        id,
        formId,
        ReScheduleStartTime,
        ReScheduleEndTime,
        userState.userType === UserType.AGENT ? 'Agent' : 'Buyer'
      );
      if (userState.userType === UserType.AGENT) {
        await updateIsAllAcceptedUpcomingToursService(
          userState?.agent?.token,
          formId,
          'Rejected'
        );
        setIsAllAccepted(false);
      }

      let rescheduledPropDataArr = [...rescheduledPropData];
      const index4: number = rescheduledPropDataArr?.findIndex(
        (item: any) => (item?.propertyId || item?.mlsId) === id
      );

      if (index4 > -1) {
        rescheduledPropDataArr.splice(index4, 1);
      }

      let tempRescheduledArr: any = [];
      const newRescheduledObj: any = {
        propertyId: id,
        reScheduleStartTime: ReScheduleStartTime,
        reScheduleEndTime: ReScheduleEndTime,
      };

      tempRescheduledArr.push(newRescheduledObj);
      setRescheduledPropData([
        ...rescheduledPropDataArr,
        ...tempRescheduledArr,
      ]);

      //1 Linked Properties Data
      let arr = [...linkedPropertiesData];
      const index = arr?.findIndex(
        (item) => (item?.propertyId || item?.mlsId) === id
      );

      if (index > -1) {
        arr.splice(index, 1);
      }
      const data: any = linkedPropertiesData[index];
      data['startTime'] = ReScheduleStartTime;
      data['endTime'] = ReScheduleEndTime;
      data['isAccepted'] =
        userState.userType === UserType.AGENT
          ? false
          : param.get('routeStatus') === 'Scheduled'
            ? true
            : false;
      linkedPropertiesData[index] = data;

      //2 Filtered Linked Properties
      let arr2 = [...filteredLinkedProperties];
      const index2 = arr2?.findIndex(
        (item) => (item?.propertyId || item?.mlsId) === id
      );

      if (index2 > -1) {
        arr.splice(index2, 1);
      }
      const data2: any = filteredLinkedProperties[index2];
      data2['startTime'] = ReScheduleStartTime;
      data2['endTime'] = ReScheduleEndTime;
      data2['isAccepted'] =
        userState.userType === UserType.AGENT
          ? false
          : param.get('routeStatus') === 'Scheduled'
            ? true
            : false;
      filteredLinkedProperties[index2] = data2;

      //3 Linked Properties Data Map
      let arr3 = [...linkedPropertiesDataMap];
      const index3 = arr3?.findIndex(
        (item) => (item?.propertyId || item?.mlsId) === id
      );

      if (index3 > -1) {
        arr3.splice(index3, 1);
      }
      const data3: any = linkedPropertiesDataMap[index3];
      data3['startTime'] = ReScheduleStartTime;
      data3['endTime'] = ReScheduleEndTime;
      data3['isAccepted'] =
        userState.userType === UserType.AGENT
          ? false
          : param.get('routeStatus') === 'Scheduled'
            ? true
            : false;
      linkedPropertiesDataMap[index3] = data3;

      setReSchedule(!reSchedule);
      setIsRemoveScheduledPropClicked(
        String(Math.floor(Math.random() * 100 + 1))
      );
      setIsLoading(false);
      notification('Time updated successfully!', 'success');
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleReScheduleModal = (e: any) => {
    e.stopPropagation();
    setReSchedule(!reSchedule);
  };

  const handleRemove = async (e: any) => {
    setIsLoading(true);
    let token = '';
    let buyerId: number = Number(param.get('buyerId'));
    if (userState?.userType === UserType.AGENT) {
      token = userState?.agent?.token;
    } else {
      token = userState?.buyer?.token;
      buyerId = userState?.buyer?.buyerId;
    }
    e?.stopPropagation();

    // delete this from best route
    await deleteScheduledPropertyByID(bestRouteId, token);

    // delete this from linked
    await removeLinkedPropertyForBuyer(id, buyerId, token);
    let arr = [...linkedPropertiesData];
    const index = arr?.findIndex(
      (item) => (item?.propertyId || item?.mlsId) === id
    );

    if (index > -1) {
      arr.splice(index, 1);
    }
    //update is isAllAccepted
    // await updateIsAllAcceptedUpcomingToursService(
    //   userState?.agent?.token,
    //   formId,
    //   'Rejected'
    // );

    // setIsAllAccepted(false);
    // await createBuyerNotificationById(
    //   linkedPropertiesDataMap?.[0]?.buyerId,
    //   `${userState?.agent?.name?.toUpperCase()} buyer agent has requested ${
    //     data?.selectedProperties
    //   } properties for showing on ${moment(
    //     linkedPropertiesDataMap?.[0]?.startDate
    //   ).format('MMMM Do, yyyy')} from ${data?.time}`,
    //   'Comment',
    //   userState?.agent?.token,
    //   'buyer',
    //   linkedPropertiesDataMap?.[0]?.propertyId
    // );

    setIsLoading(false);
    setLinkedPropertiesData(arr);
    setIsRemoveScheduledPropClicked('YES');
    setIsLoading(false);
  };

  const handleConfirm = async (e: any) => {
    e?.stopPropagation();
    setIsLoading(true);
    setGetDirectionsPropertyClicked('NO');
    try {
      await updateAgentScheduledStatusService(bestRouteId, true, false);

      //1 Linked Properties Data
      let arr = [...linkedPropertiesData];
      const index = arr?.findIndex(
        (item) => (item?.propertyId || item?.mlsId) === id
      );

      if (index > -1) {
        arr.splice(index, 1);
      }
      const data: any = linkedPropertiesData[index];
      data['isAccepted'] = true;
      linkedPropertiesData[index] = data;

      if (data?.isAccepted) {
        // let email = await getSignedInUserEmail();
        let email = null;
        if (email) {
          const startDateTime = `${moment(data?.startDate).format('YYYY-MM-DD')} ${data?.startTime}`;
          const endDateTime = `${moment(data?.endDate).format('YYYY-MM-DD')} ${data?.endTime}`;
          const calendarEvent: any = {
            summary: "View Property",
            location: `${data?.address?.streetNumber} ${data?.address?.streetName}, ${data?.address?.city}, ${data?.address?.state}, ${data?.address?.postalCode}`,
            start: {
              dateTime: moment(startDateTime),
              timeZone: "America/Los_Angeles",
            },
            end: {
              dateTime: moment(endDateTime),
              timeZone: "America/Los_Angeles",
            },
          };
          insertEventToGoogleCalendar({ calendarEvent });
        }
      }

      //2 Filtered Linked Properties
      let arr2 = [...filteredLinkedProperties];
      const index2 = arr2?.findIndex(
        (item) => (item?.propertyId || item?.mlsId) === id
      );

      if (index2 > -1) {
        arr.splice(index2, 1);
      }

      const data2: any = filteredLinkedProperties[index2];
      data['isAccepted'] = true;
      filteredLinkedProperties[index2] = data2;

      //3 Linked Properties Data Map
      let arr3 = [...linkedPropertiesDataMap];
      const index3 = arr3?.findIndex(
        (item) => (item?.propertyId || item?.mlsId) === id
      );

      if (index3 > -1) {
        arr3.splice(index3, 1);
      }

      const data3: any = linkedPropertiesDataMap[index3];
      data3['isAccepted'] = true;
      linkedPropertiesDataMap[index3] = data3;

      //check is All best route properties request accepted

      let isAllAcceptedCount: number = 0;
      for (let i = 0; i < linkedPropertiesDataMap?.length; i++) {
        if (linkedPropertiesDataMap?.[i]?.isAccepted) {
          isAllAcceptedCount++;
        }
      }

      if (
        isAllAcceptedCount === selectedPropertiesCount &&
        userState?.userType === UserType.AGENT
      ) {

        //update is isAllAccepted
        await updateIsAllAcceptedUpcomingToursService(
          userState?.agent?.token,
          formId,
          'Accepted'
        );

        setIsAllAccepted(true);
        setIsShowSchedulignStatusModal(false);
        const local1 = moment(formData?.dateTime?.split(',')?.[0], 'MM-DD-YYYY HH:mm:ss').local();
        let tourDate = moment(local1, "YYYY-MM-DD").format("YYYY-MM-DD");
        await createBuyerNotificationById(
          linkedPropertiesDataMap?.[0]?.buyerId,
          `Your Agent ${userState?.agent?.name?.toUpperCase()} has scheduled ${linkedPropertiesDataMap?.length
          } properties for showing on ${moment(tourDate).format("MMMM Do, yyyy")} from ${formData?.dateTime?.split(",")?.[1]}.`,
          'Comment',
          userState?.agent?.token,
          'buyer',
          linkedPropertiesDataMap?.[0]?.propertyId
        );
      }

      setIsRemoveScheduledPropClicked(
        String(Math.floor(Math.random() * 100 + 1))
      );

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleGetDirectionsButton = (e: any) => {
    e?.stopPropagation();

    console.log("param", param, pathname);

    setShowMap(false);
    setPropId(propertyId);
    setRunMap(false);
    setUseManual(false);
    setTourPropPage(true);
    setPropListingId(listingId);
    setGetDirectionsPropertyClicked(
      param.get('routeStatus') === 'Scheduled' ? 'Scheduled' : 'YES'
    );
  };

  const handleSearchLockBox = async (e: any) => {
    e?.stopPropagation();
    setIsLoading(true);
    setGetDirectionsPropertyClicked('NO');
    try {
      const searchLocBoxProperties: any = await getSearchLocBoxPropertiesByCity(
        city
      );
      if (searchLocBoxProperties && searchLocBoxProperties?.length > 0) {
        const arr2 = searchLocBoxProperties?.filter(
          (item: any) =>
            linkedPropertiesData.findIndex(
              (requested: any) =>
                (requested?.propertyId
                  ? requested?.propertyId
                  : requested?.mlsId) ===
                (item?.propertyId ? item?.propertyId : item?.mlsId)
            ) === -1
        );

        let arr: any = [];
        arr2?.forEach((element: any) => {
          let newObj = element?.propertyDetails
            ? JSON.parse(element?.propertyDetails)
            : {};
          newObj['isSearchLocBox'] = 'YES';
          newObj['agentId'] = element?.agentId;
          newObj['buyerId'] = 0;
          newObj['isFavorite'] = false;
          newObj['isRequested'] = false;
          newObj['isListed'] = true;
          newObj['listingAgentId'] = element?.agentId;
          newObj['listingAgent'] = element?.agent;
          newObj['client'] = element?.name;
          newObj['agentName'] = userState?.agent?.name;
          newObj['listingAgentEmail'] = element?.agent?.email;
          newObj['showingType'] =
            element?.listedPropertyPreferences?.showingType;
          newObj['acceptedRequest'] =
            element?.listedPropertyPreferences?.acceptRequest;
          newObj['role'] = 'Agent';
          arr.push(newObj);
        });

        setLinkedPropertiesDataMap([...linkedPropertiesDataMap, ...arr]);
        setIsShowSearchLocBox(false);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const content = (
    <div>
      {loading && <CustomSpinner />}
      <Row style={{ borderBottom: '3px solid #004876' }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={9}>
          <img src={photos} alt="photo of property" height={72} width={72} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={15}>
          <Row style={{ padding: ' 10px' }}>
            <Col span={24}>
              <div style={{ display: 'flex' }}>
                {isSearchLocBox === 'YES' ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '0',
                      margin: '0',
                      width: '100%',
                    }}
                  >
                    <CustomPara>
                      {listPrice?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3,
                      })}
                      {(isListed === true || isListed === '1') && (
                        <CustomImage
                          src={homeWithClick}
                          height="12px"
                          width="12px"
                          alt="home"
                        />
                      )}
                    </CustomPara>
                    <img
                      className="buyer-preview-card__add-button"
                      src={PropertyAdd}
                      alt="add button"
                      onClick={addSearchLocBoxProperty}
                      style={{ float: 'right', cursor: 'pointer' }}
                    />
                  </div>
                ) : (
                  <CustomPara>
                    {listPrice?.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 3,
                    })}
                  </CustomPara>
                )}

                {/* {!history?.location?.pathname?.includes('/properties') ? ():(<></>) */}
                {isSearchLocBox === 'YES' ? (
                  <></>
                ) : (
                  <>
                    {(isListed === true || isListed === '1') && (
                      <CustomImage
                        src={homeWithClick}
                        height="12px"
                        width="12px"
                        alt="home"
                      />
                    )}
                  </>
                )}
              </div>
            </Col>
            <Col span={24}>
              <p style={{ fontSize: '10px' }}>{subTypeText}</p>
            </Col>
            <Col span={24}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>
                  <CustomPara>{bedrooms} Beds</CustomPara>
                </div>
                <div>
                  <CustomPara> {bathrooms} Baths</CustomPara>
                </div>
                <div>
                  <CustomPara>{area} Sqft</CustomPara>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      {!history?.location?.pathname?.includes('/properties') ? (
        <Row
          justify="space-between"
          style={{ borderBottom: '3px solid #004876', padding: '5px' }}
        >
          <div>
            <CustomPara>
              {streetNumber} {streetName}
            </CustomPara>
            <CustomPara>
              {city}, {state} {postalCode}
            </CustomPara>
          </div>

          {pathname === "/tourdetail" ? <></> :

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CustomPara
                style={{
                  color: 'coral',
                  borderBottom: '3px solid coral',
                  cursor: 'pointer',
                }}
                onClick={isSearchLocBox === 'YES' ? addSearchLocBoxProperty : handleGetDirectionsButton}
              >
                View Property
              </CustomPara>
            </div>
          }
        </Row>
      ) : (
        <></>
      )}

      {isSearchLocBox === 'YES' ? (
        <></>
      ) : (
        <>
          {(!history?.location?.pathname?.includes('/properties') ||
            showReSchAndConfirm) &&
            bestRoute?.length > 0 && isOutOfTheBox !== 'YES' ? (
            // || showReScheduleModal
            // && (param?.get('routeStatus')==='scheduled')
            <Row
              justify="space-between"
              style={{
                borderBottom: '3px solid #004876',
                padding: '5px',
              }}
            >
              <div>
                <CustomtimePara
                  isAccepted={
                    typeof isAccepted === 'boolean'
                      ? isAccepted
                      : isAccepted === '1'
                        ? true
                        : false
                  }
                >
                  {moment(startTime, 'hh:mm a').format('hh:mm a')} -{' '}
                  {moment(endTime, 'hh:mm a').format('hh:mm a')}
                </CustomtimePara>
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                {isOutOfTheBox === 'YES' || isNotEditable ? (
                  <></>
                ) : (
                  <CustomPara
                    onClick={handleReScheduleModal}
                    style={{
                      color: 'coral',
                      cursor: 'pointer',
                      borderBottom: '3px solid coral',
                    }}
                  >
                    Reschedule
                  </CustomPara>
                )}
              </div>
            </Row>
          ) : (
            <></>
          )}
          {!history?.location?.pathname?.includes('/properties') &&
            showReSchAndConfirm ? (
            //  && (role === 'Agent' || role === null || role === 'undefined') ? (
            <Row
              justify="space-between"
              style={{
                borderBottom: '3px solid #004876',
                padding: '5px',
              }}
            >
              <Col>
                <CustomRemovePara onClick={handleRemove}>
                  Remove
                </CustomRemovePara>
              </Col>
              {isOutOfTheBox === 'YES' ? (
                <></>
              ) : (
                <>
                  <Col>
                    {typeof isAccepted == 'boolean' ? (
                      isAccepted ? (
                        <CustomConLockParaConfirmed>
                          Confirmed
                        </CustomConLockParaConfirmed>
                      ) : (
                        <CustomConLockPara onClick={handleConfirm}>
                          Confirm?
                        </CustomConLockPara>
                      )
                    ) : isAccepted !== '0' ? (
                      <CustomConLockParaConfirmed>
                        Confirmed
                      </CustomConLockParaConfirmed>
                    ) : (
                      <CustomConLockPara onClick={handleConfirm}>
                        Confirm?
                      </CustomConLockPara>
                    )}
                  </Col>
                  <Col>
                    {isShowSearchLocBox && (
                      <CustomConLockPara onClick={handleSearchLockBox}>
                        SearchLockBox
                      </CustomConLockPara>
                    )}
                  </Col>
                </>
              )}
            </Row>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );

  useEffect(() => {
    let userRole: any = param.get('role') ?? '';
    if (userState?.userType === UserType.AGENT && userRole === 'Buyer') {
      setIsNotEditable(true);
    } else if (userState?.userType === UserType.BUYER && userRole === 'Agent') {
      setIsNotEditable(true);
    }
    if (
      param?.get('statusType') === 'afterRouteCreated' &&
      param?.get('routeStatus') === 'Scheduled' &&
      userState.userType === UserType.AGENT &&
      role === 'Agent'
    ) {
      setShowReSchAndConfirm(true);
    } else if (isOutOfTheBox === 'YES') {
      setShowReSchAndConfirm(true);
    }

    if (isListed === true || isListed === '1') {
      setUserName(propData?.listingAgent?.name);
      setAgentEmail(propData?.listingAgent?.email);
      setAgentPhoto(propData?.listingAgent?.avatarUrl);
      setAgentPhone(propData?.listingAgent?.phone);
      setListedAgent(true);
    } else {
      setUserName(`${propData?.agent?.firstName} ${propData?.agent?.lastName}`);
      setAgentPhone(propData?.agent?.contact?.office);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {
        <div style={{ cursor: 'pointer' }} onClick={handleClick}>
          <Popover
            placement="right"
            content={
              location.includes(history?.location?.pathname) ? null : content
            }
            title={null}
            overlayStyle={{
              width: isMobile ? '40%' : '15%',
              padding: '0px',
            }}
            overlayClassName="popover_custom"
          >
            {markerLocation?.includes(history?.location?.pathname) ? (
              <></>
            ) : (
              <div style={{ position: 'absolute', right: '3px' }}>
                {(numberingMarker ||
                  (param.get('formId')?.length > 0 &&
                    param.get('statusType') === 'afterRouteCreated')) && (
                    <CustomNumberDiv>{index}</CustomNumberDiv>
                  )}
              </div>
            )}
            <div style={{ height: '30px' }}>
              <MarkerSvg
                isAccepted={false}
                isRejected={false}
                type={'save'}
                isSearchLocBox={isSearchLocBox}
                isOutOfTheBox={isOutOfTheBox}
              />
            </div>
          </Popover>
        </div>
      }
      {reSchedule && (
        <>
          <ReSchedule
            address={`${streetNumber} ${streetName} ${city},${' '}${state}${' '}${postalCode}`}
            propAgentName={propAgentName}
            listedAgent={listedAgent}
            propertyId={id}
            listingId={listingId}
            isListed={isListed}
            formId={formId}
            serviceTime={serviceTime}
            isShowing={reSchedule}
            userName={userName}
            userEmail={agentEmail}
            userPhone={agentPhone}
            userPhoto={agentPhoto}
            propData={propData}
            closeAction={() => {
              setReSchedule(!reSchedule);
            }}
            handleReScheduledPropTime={handleReScheduledPropTime}
            setReScheduleTime={setReScheduleTime}
          />
        </>
      )}
    </>
  );
};

interface SimpleMapProps {
  lat: number | string;
  lng: number | string;
  direction: string;
  isZoomed: boolean;
}

export default function SimpleMap(props: SimpleMapProps) {
  const history = useHistory();
  const mls = history.location.pathname.split('/')[2];
  let location: any = [
    '/sell',
    '/propertydetails/' + mls,
    '/properties/' + mls,
    '/common',
  ];

  const [address, setAddress] = useState<any>('');
  const [geoloc, setGeoLoc] = useState({
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    if (props?.lat && props?.lng) {
      setGeoLoc({
        lat: Number(props.lat),
        lng: Number(props.lng),
      });
    } else {
      console.error('data is not present ');
    }
  }, [props]);
  const defaultProps: any = {
    center: {
      lat: props?.lat || 0,
      lng: props?.lng || 0,
    },
    zoom: 18,
  };
  const HandleChangeAdd = async (data: any) => {
    setAddress(data);
  };

  const HandleSelectAdd = async (data: any) => {
    setAddress(data);
  };

  const getMapOptions = (maps: any) => {
    return {
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.DROPDOWN_MENU,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID,
        ],
      },
      controlSize: 25,
      fullscreenControl: true,
      mapTypeControl: props.isZoomed,
    };
  };
  return (
    // Important! Always set the container height explicitly
    <div>
      <CustomDiv className="places-container">
        {location?.includes(history?.location?.pathname) ? null : (
          <PlacesAutocomplete
            value={address}
            onChange={HandleChangeAdd}
            onSelect={HandleSelectAdd}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <Input
                  {...getInputProps({
                    placeholder: 'Enter Place',
                  })}
                />
                <div>
                  {loading && <div>Loading....</div>}
                  {suggestions.map((suggestion) => {
                    const style = suggestion.active
                      ? {
                        backgroundColor: '#d4d4d4',
                        cursor: 'pointer',
                        border: '1px solid black',
                        borderRadius: '5px',
                      }
                      : {
                        backgroundColor: 'rgba(255,255,255,0.7)',
                        cursor: 'pointer',
                        fontSize: '1.2rem',
                        borderBottom: '1px solid #d4d4d4',
                      };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          style,
                        })}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        )}
      </CustomDiv>
      <CustomMapDiv id="custom_map_div">
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
          center={geoloc}
          defaultZoom={defaultProps.zoom}
          options={getMapOptions}
        >
          <AnyReactComponent
            lat={geoloc?.lat}
            lng={geoloc?.lng}
            text={props?.direction}
            setIsAllAccepted={() => { }}
            setPropId={() => { }}
            setTourPropPage={() => { }}
            setShowMap={() => { }}
            setRunMap={() => { }}
            setUseManual={() => { }}
            formData={{}}
          />
        </GoogleMapReact>
      </CustomMapDiv>
    </div>
  );
}
