/* eslint-disable jsx-a11y/alt-text */
import styled from "@emotion/styled";
import { Button, Col, DatePicker, Form, Input, Row, TimePicker } from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import UserContext from "../../contexts/UserContext";
import { postViewPropertyCalendar, updateViewPropertyCalendar } from "../../services/NavihomeService";
import CustomSpinner from "../spinner/CustomSpinner";
import "./calendarModal.css";

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
const style = { background: "white", padding: "8px 0" };
const { TextArea } = Input;

const FullDatePicker: any = styled(DatePicker)``;
const CustomButton = styled(Button)`
  color: #ffff;
  background: #365073;
  border-radius: 10px;
  :hover {
    background: #c2cc30;
    borer-color: none;
  }
`;

interface IAppProps {
  isNew: boolean;
  isEdit: boolean;
  keyID?: number;
  values: any;
  setValues: any;
  Sdate?: Date;
  Stime?: any;
  Edate?: Date;
  Etime?: any;
  description?: string;
  buyerId: string;
  setIsModalVisible: any;
  geteventData: any;
  propertyDetails: any;
}
interface FormInitialValues {
  StartDate: moment.Moment;
  StartTime?: any;
  EndDate?: moment.Moment;
  EndTime?: moment.Moment;
  Description?: string;
  RecurringStatus?: string;
}

const Availability: React.FunctionComponent<IAppProps> = ({
  isNew,
  isEdit,
  keyID,
  values,
  setValues,
  Sdate,
  Stime,
  Edate,
  Etime,
  buyerId,
  description,
  setIsModalVisible,
  geteventData,
  propertyDetails
}) => {
  const history = useHistory();
  const { userState } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [form]: any = Form.useForm();
  const [initialValues, setInitialValues] = useState<FormInitialValues>({
    StartDate: moment(Sdate),
    StartTime: moment(Stime, "HH:mm"),
    EndDate: moment(Edate),
    EndTime: moment(Etime, "HH:mm"),
    Description: description,
    RecurringStatus: "Does not repeat"
  });
  const onFinish = async (values: any) => {
    try {
      const mlsId = history?.location?.pathname?.split?.("/")?.[2];
      const SDate: any = moment(values?.StartDate).format("MMMM DD, YYYY");
      const STime: any = moment(values?.StartTime).format("HH:mm:ss");
      const Edate: any = moment(values?.EndDate).format("MMMM DD, YYYY");
      const Etime: any = moment(values?.EndTime).format("HH:mm:ss");
      const Description: string = values?.Description;

      let putPayload = {
        viewPropertyCalendarId: keyID,
        agentId: userState.agent.agentId,
        mlsId: Number(mlsId),
        startDate: SDate,
        endDate: Edate,
        startTime: STime,
        endTime: Etime,
        recuringStatus: values?.recurringStatus,
        description: Description
      };
      if (isNew) {
        setIsLoading(false);
        const res = await postViewPropertyCalendar([], userState?.agent?.agentId, userState?.agent?.token);
        if (res) {
          try {
            geteventData(mlsId);
          } catch (err) {
            return err;
          }
        }
        setIsModalVisible(false);
        setIsLoading(false);
      } else if (isEdit) {
        setIsLoading(false);
        const token = userState.agent.token;
        await updateViewPropertyCalendar(putPayload, token);
        geteventData(Number(mlsId));
        setIsModalVisible(false);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      return { err };
    }
  };
  return (
    <>
      {isLoading && <CustomSpinner />}
      <h1>Send Showing Request?</h1>
      <br></br>
      <p>
        <Form form={form} onFinish={onFinish} layout="vertical" initialValues={initialValues}>
          <p>
            <h4>Date and time:</h4>
            <Form.Item>
              <Row gutter={[16, 24]}>
                <Col span={5}>
                  <div style={style}>
                    <Form.Item label="Date" required name="StartDate" rules={[{ required: true, message: "Enter Starting Date" }]}>
                      <FullDatePicker
                        onChange={() => {
                          setValues((preState: any) => ({
                            ...preState,
                            date1: moment().format("l")
                          }));
                        }}
                        value={moment(values.date1)}
                        format={dateFormatList}
                        style={{
                          borderRadius: "5px",
                          boxShadow: "1px 1px 1px grey ",
                          border: "solid",
                          borderColor: "darkblue",
                          borderWidth: "thin"
                        }}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={5}>
                  <div style={style}>
                    <Form.Item label="Time" name="StartTime" required rules={[{ required: true, message: "Enter Starting Time" }]}>
                      <TimePicker
                        use12Hours
                        format="hh:mm a"
                        onChange={() => {
                          setValues((preState: any) => ({
                            ...preState,
                            time1: moment().format()
                          }));
                        }}
                        value={values.time1}
                        style={{
                          borderRadius: "5px",
                          boxShadow: "2px 2px 2px grey",
                          border: "solid",
                          borderColor: "darkblue",
                          borderWidth: "thin"
                        }}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <h1 style={{ alignContent: "center" }}>
                  <img
                    src="https://img.icons8.com/external-royyan-wijaya-detailed-outline-royyan-wijaya/24/000000/external-arrow-arrow-line-royyan-wijaya-detailed-outline-royyan-wijaya-13.png"
                    style={{
                      color: "blue",
                      alignContent: "center",
                      marginTop: "150%",
                      marginLeft: "-1%"
                    }}
                  />
                </h1>
                <Col span={5}>
                  <div style={style}>
                    <Form.Item label="Date" name="EndDate" required rules={[{ required: true, message: "Enter End Date" }]}>
                      <FullDatePicker
                        onChange={() => {
                          setValues((preState: any) => ({
                            ...preState,
                            date2: moment().format("l")
                          }));
                        }}
                        format={dateFormatList}
                        value={values.date2}
                        style={{
                          borderRadius: "5px",
                          boxShadow: "2px 2px 2px grey",
                          border: "solid",
                          borderColor: "darkblue",
                          borderWidth: "thin"
                        }}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={5}>
                  <div style={style}>
                    <Form.Item label="Time" name="EndTime" required rules={[{ required: true, message: "Enter End Time" }]}>
                      <TimePicker
                        use12Hours
                        format="h:mm a"
                        onChange={() => {
                          setValues((preState: any) => ({
                            ...preState,
                            time2: moment().format()
                          }));
                        }}
                        value={values.time2}
                        style={{
                          borderRadius: "5px",
                          boxShadow: "2px 2px 2px grey",
                          border: "solid",
                          borderColor: "darkblue",
                          borderWidth: "thin"
                        }}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </Form.Item>
          </p>
          <p>
            <br></br>
            <h4>Description:</h4>
            <Form.Item name={"Description"} rules={[{ required: true, message: "Enter Description" }]} initialValue={values.description}>
              <TextArea
                rows={4}
                cols={4}
                style={{
                  borderRadius: "5px",
                  boxShadow: "2px 2px 2px grey ",
                  border: "solid",
                  borderColor: "darkblue",
                  borderWidth: "thin"
                }}
                placeholder="Add a description"
                onChange={(e) => {
                  setValues((preState: any) => ({
                    ...preState,
                    description: e.target.value
                  }));
                }}
              />
            </Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Form.Item>
                <CustomButton htmlType="submit" className="common_button_buyer_tour_details">
                  SEND REQUEST
                </CustomButton>
              </Form.Item>
            </div>
          </p>
        </Form>
      </p>
    </>
  );
};

export default Availability;
