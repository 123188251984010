/* eslint-disable no-useless-computed-key */
import styled from "@emotion/styled";
import {
  Col,
  Divider,
  Radio,
  Row,
  Switch
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DeleteIllus from "../../assets/accountIllus/AccountDelete.svg";
import LogoutIllus from "../../assets/accountIllus/AccountLogout.svg";
import PauseIllus from "../../assets/accountIllus/AccountPause.svg";
import { useLocation } from "react-router";
import "../../assets/css/pages/account-settings-agent-page.css";
import { notification } from "../../components/notification";
import CustomSpinner from "../../components/spinner/CustomSpinner";
import PropertyFiltersContext from "../../contexts/PropertyFiltersContext";
import UserContext, { UserType } from "../../contexts/UserContext";
import validator from "validator";
import {
  buyerAccountSettings,
  createCronofyService,
  createUserEmailsService,
  getBuyerAccountSettings,
  getCronofyReqTokenService,
  getUserEmailByIdService,
  putBuyerAccountSettings,
  updateBuyerPrefferedContact,
} from "../../services/NavihomeService";
import AccountModal from "./AccountModal";
import GoogleEventComponent from "./GoogleEventComponent";
import TextField, {
  TextFieldIconType,
  TextFieldType,
} from "../TextField";
import Button, { ButtonType } from "../Button";
const CustomDivider = styled(Divider)`
  background: #004876;
  margin: 18px 0px;
`;

export const CustomAddCol = styled(Col)`
  border-radius: 10px;
  display: flex;
  gap:12px;
  justify-content: space-between;
  align-items: center;

`;
const CustomSwitch = styled(Switch)`
  margin-left: 10px;
  border: #c2d500;
  font-size: 17px;
`;


const Accountsetting = styled.h1`
  font-size: 25px;
  font-weight: bolder;

`;
const Notificationsetting = styled.h1`
  font-size: 25px;
  font-weight: bolder;
  margin-bottom: 30px;

`;
const Otheraction = styled.h1`
  font-size: 25px;
  font-weight: bolder;
  margin-bottom: 30px;

`;

const AccountSettingAgentPage: React.FC = () => {
  const [weeklyRemindState, setWeeklyRemindState] = useState("No");
  const [email, setEmail] = useState("");
  const notificationOptions = ["Email", "Phone", "Both"];
  const notificationSavedSearch = ["Yes", "No"];
  const notificationRequestedShowings = ["Yes", "No"];
  const notificationFavoriteProp = ["Yes", "No"];
  const notificationCommentOnReqFavProp = ["Yes", "No"];
  const notificationUpcomingTour = ["48 hours prior", "24 hours prior"];
  const [checkLength, setLength] = useState(false);
  const { userState, changeUserType, logOut, logIn } = useContext(UserContext);
  const { clearFilters } = useContext(PropertyFiltersContext);
  const [objData, setObjData] = useState({
    weeklyRemind: false,
    notifiedOn: "",
    notifyUpcomingTour: "",
    notifyUpcomingShowing: "",
    notifyRejectShowingRequest: "",
    notifynotRespondShowingRequest: "",
    notifyShowingRequestListedProperty: "",
    notifySavedSearch: "",
    notifyRequestedShowing: "",
    notifyFavoriteProp: "",
    notifyCommentForReqFavProp: "",
    notifyForUpcomingTour: "",
    buyerId: userState.buyer.buyerId,
  });
  const history = useHistory();
  const cronofyClientId = "JjMr0DO7uRL31jVr9M0FS6szZeSXTnbQ";
  const cronofyRedirectUri = "http://localhost:3000/account-settings";
  const cronofyOauthUrl = "https://app.cronofy.com/oauth/authorize";
  const cronofyScope = "read_events create_event delete_event";
  const cronofyState = "cR0n0Fy";


  const onChangeWeeklyRemind = (checked: boolean) => {
    checked ? setWeeklyRemindState("Yes") : setWeeklyRemindState("No");

    setObjData((prevState) => ({
      ...prevState,
      ["weeklyRemind"]: checked,
    }));
  };

  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const [isPauseModalVisible, setIsPauseModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userContOpt, setUserContOpt] = useState('');

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let param = useQuery();

  const handleOk = () => {
    clearFilters();
    logOut();
    if (userState.userType === UserType.AGENT) {
      changeUserType(UserType.AGENT);
      history.push("/");
    }
    history.push("/");
  };

  const showLogoutModal = () => {
    setIsLogoutModalVisible(true);
  };

  const showDeleteModal = () => {
    setIsDeleteModalVisible(true);
  };
  const handleRadioChange = (e: any, key: any) => {
    if (e?.target?.value === 'Phone') {
      setUserContOpt('Phone')
    }
    else if (e?.target?.value === 'Email') {
      setUserContOpt('Email')
    }
    else if (e?.target?.value === 'Both') {
      setUserContOpt('Both')
    }
    setObjData((prevState) => ({ ...prevState, [key]: e?.target?.value }));
  };
  const handleSave = async () => {
    if (checkLength === false) {
      buyerAccountSettings(userState?.buyer?.token, objData)
        .then((res) => {
          notification("Data saved successfully", "success");
        })
        .catch((err) => {
          notification("Something Went Wrong", "warning");
        });
    } else {
      const payload = {
        weeklyRemind: objData?.weeklyRemind,
        notifiedOn: objData?.notifiedOn,
        notifyUpcomingTour: objData?.notifyUpcomingTour,
        notifyUpcomingShowing: objData?.notifyUpcomingShowing,
        notifyRejectShowingRequest: objData?.notifyRejectShowingRequest,
        notifynotRespondShowingRequest: objData?.notifynotRespondShowingRequest,
        notifyShowingRequestListedProperty:
          objData?.notifyShowingRequestListedProperty,
        notifySavedSearch: objData?.notifySavedSearch,
        notifyRequestedShowing: objData?.notifyRequestedShowing,
        notifyFavoriteProp: objData?.notifyFavoriteProp,
        notifyCommentForReqFavProp: objData?.notifyCommentForReqFavProp,
        notifyForUpcomingTour: objData?.notifyForUpcomingTour,
        buyerId: userState?.buyer?.buyerId,
      };

      const updateRes: any = await putBuyerAccountSettings(
        userState?.buyer?.token,
        payload,
        userState?.buyer?.buyerId
      )
      if (updateRes) {
        let buyerId = -1;
        let buyerEmail = '';
        let prefferedCont = '';
        buyerId = userState?.buyer?.buyerId;
        buyerEmail = userState?.buyer?.Email;

        if (objData?.notifiedOn === 'Email') {
          prefferedCont = 'CONTACT_RADIO_EMAIL'
        }
        else if (objData?.notifiedOn === 'Phone') {
          prefferedCont = 'CONTACT_RADIO_PHONE'
        }
        else {
          prefferedCont = 'CONTACT_RADIO_BOTH'
        }

        const updateContact: any = await updateBuyerPrefferedContact(buyerId, buyerEmail, prefferedCont)
        if (updateContact) {
          logOut()
          await logIn(userState?.buyer?.Email, userState?.buyer?.Password);
          setIsLoading(false)
        }
        notification("Data updated successfully", "success");
      }
      else {
        setIsLoading(false)
        notification("Something Went Wrong", "warning");
      }

    }
  };

  const initiateCronofyOAuth = () => {
    try {
      // Define the OAuth authorization URL
      const authorizationUrl = `${cronofyOauthUrl}?client_id=${cronofyClientId}&redirect_uri=${cronofyRedirectUri}&scope=${cronofyScope}&response_type=code&state=${cronofyState}`;

      // Return the authorization URL to use in the redirection
      return {
        authorizationUrl,
      };
    } catch (error) {
      throw new Error('Failed to initiate Cronofy OAuth');
    }
  };


  const addEmailHandler = async () => {
    try {
      setIsLoading(true)
      const postResponse = await createUserEmailsService(userState.buyer.buyerId, userState.buyer.name, email, userState.userType);
      console.log("response createUserEmailsHandler", postResponse);
      if (postResponse) {
        setEmail('');
        // notification(
        //   "Email successfully submitted.",
        //   "success"
        // );
        const response = initiateCronofyOAuth();
        console.log("response initiateCronofyOAuth", response);
        window.location.href = response.authorizationUrl;
        // history.push(response.authorizationUrl)
      }
      else {
        setIsLoading(false)
        return notification('Failed to add the email.', 'error');
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error);

      setIsLoading(false)
      return notification(
        "Failed to add the email.",
        "error"
      );
    }

  }

  const handleCronofyCallback = async () => {
    const receivedCode = param.get('code');
    const receivedState = param.get('state');
    if (receivedState !== cronofyState) {
      return notification('Invalid state parameter. Possible CSRF attack.', 'error');
    }
    if (receivedCode) {
      try {
        const cronofyTokenReq:any = await getCronofyReqTokenService(receivedCode );
        if(cronofyTokenReq?.access_token){
        const response = await createCronofyService(userState.buyer.buyerId, userState.buyer.name, cronofyTokenReq?.access_token, cronofyTokenReq?.expires_in, cronofyTokenReq?.refresh_token, cronofyTokenReq?.sub, cronofyTokenReq?.linking_profile?.profile_id, cronofyTokenReq?.linking_profile?.profile_name, cronofyTokenReq?.linking_profile?.provider_service, "Buyer");
        console.log("response createCronofyHandler", cronofyTokenReq, response, receivedCode);
        if (response) {
          return notification('Tokens are successfully saved in your DB', 'success')
        } else {
          return notification('Failed to exchange Cronofy code for tokens.', 'error');
        }
      }
      } catch (error) {
        console.log(error);
        return notification('Failed to exchange Cronofy code for tokens.', 'error');
      }
    }
  };


  const getEmailList = async (id: number) => {
    try {
      setIsLoading(true)
      const response: any = await getUserEmailByIdService(id);
      console.log("response getUserEmailsHandler", response);
      if (response?.personal_email) {
        setEmailList([response.personal_email]);
        setEmail('');
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error);
      setIsLoading(false)
      return notification('Failed to add the email.', 'error');
    }

  }



  useEffect(() => {
    if (userState?.buyer?.preferredContact === "CONTACT_RADIO_EMAIL") {
      setUserContOpt('Email')
    }
    else if (userState?.buyer?.preferredContact === "CONTACT_RADIO_PHONE") {
      setUserContOpt('Phone')
    }
    else {
      setUserContOpt('Both')
    }
    async function fetchMyApi() {
      setIsLoading(true);
      let response = await getBuyerAccountSettings(
        userState.buyer.token,
        userState.buyer.buyerId
      );
      let data = response[response?.length - 1 ?? -1];
      if (data !== undefined) {
        setObjData(data);
        setWeeklyRemindState(data?.weeklyRemind ? "Yes" : "No");
        setLength(true);
      }
      setIsLoading(false);
    }
    fetchMyApi();
    getEmailList(userState.buyer.buyerId);
    if (window.location.search.includes('code')) {
      handleCronofyCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? <CustomSpinner /> :
        <div
          className="account-setting-agent-page"
          style={{ fontSize: "17px" }}
        >
          <Row justify="space-between">
            <Accountsetting>Account  <span style={{ borderBottom: "3px solid #C2D500" }}>
              Settings
            </span></Accountsetting>
            <div className="save-button-css-buyersetting">
              <Button
                type={ButtonType.PRIMARY}
                text="CANCEL"
                clickHandler={() => {
                  history.push("/dashboard");
                }}
                className="footer_mail_button"
              />
              &nbsp;&nbsp;&nbsp;
              <Button
                type={ButtonType.PRIMARY}
                text="SAVE AND UPDATE"
                clickHandler={handleSave}
                className="footer_mail_button"
              />
            </div>
          </Row>
          <Divider />

          <Row justify="space-around" gutter={32}>
            <Col span={24}>
              <Row justify="space-between">
                In addition to app notifications, I would also like to receive notificaions via:
                <Radio.Group
                  onChange={(e) => handleRadioChange(e, "notifiedOn")}
                  value={userContOpt}  //objData?.notifiedOn
                  options={notificationOptions}
                />
              </Row>
              <CustomDivider />
              <Row justify="space-between">
                Remind me weekly to update my calendar.
                <span>
                  {weeklyRemindState}
                  <CustomSwitch
                    style={{
                      background: objData?.weeklyRemind ? "#c2d500" : "#afb1af",
                    }}
                    checked={objData?.weeklyRemind}
                    size="small"
                    onChange={onChangeWeeklyRemind}
                    disabled={userState?.buyer?.isLinked ? false : true}
                  />
                </span>
              </Row>
              <CustomDivider />
            </Col>
            <Col span={24}>
              <Notificationsetting>Notification <span style={{ borderBottom: "3px solid #C2D500" }}>
              {" "}Settings
              </span></Notificationsetting>
              <Row justify="space-between">
                Notify me when my agent adds or modifies my "Saved Searches"
                <Radio.Group
                  onChange={(e) =>
                    handleRadioChange(e, "notifySavedSearch")
                  }
                  value={objData?.notifySavedSearch}
                  options={notificationSavedSearch}
                />
              </Row>
              <CustomDivider />
              <Row justify="space-between">
                Notify me when my agent adds properties to "Requested Showings”
                <Radio.Group
                  onChange={(e) =>
                    handleRadioChange(e, "notifyRequestedShowing")
                  }
                  value={objData?.notifyRequestedShowing}
                  options={notificationRequestedShowings}
                />
              </Row>
              <CustomDivider />
              <Row justify="space-between">
                Notify me when my agent adds to my "Favorite Properties".
                <Radio.Group
                  onChange={(e) =>
                    handleRadioChange(e, "notifyFavoriteProp")
                  }
                  value={objData?.notifyFavoriteProp}
                  options={notificationFavoriteProp}

                />
              </Row>
              <CustomDivider />
              <Row justify="space-between">
                Notify me when agent comments on a property I have favorited or requested a showing.
                <Radio.Group
                  onChange={(e) =>
                    handleRadioChange(e, "notifyCommentForReqFavProp")
                  }
                  value={objData?.notifyCommentForReqFavProp}
                  options={notificationCommentOnReqFavProp}
                />
              </Row>
              <CustomDivider />
              <Row justify="space-between">
                Notify me before an upcoming tour.
                <Radio.Group
                  onChange={(e) =>
                    handleRadioChange(e, "notifyForUpcomingTour")
                  }
                  value={objData?.notifyForUpcomingTour}
                  options={notificationUpcomingTour}
                />
              </Row>
              <CustomDivider />
            </Col>
            <Col span={24}>
              <Otheraction>Other<span style={{ borderBottom: "3px solid #C2D500" }}>
              {" "}Actions
              </span></Otheraction>
              <Row justify="center" style={{ gap: "20px" }}>
                <CustomAddCol span={24}>
                  <TextField
                    value={email}
                    setValue={setEmail}
                    placeholder="janedoe@hotmail.com"
                    label=""
                    type={TextFieldType.ICON_AND_LABEL}
                    icon={TextFieldIconType.EMAIL}
                    isRequired={false}
                    width={28}
                    isDisabled={emailList.length > 0}
                    className="footer__mail_input"
                  />
                  <Button
                    type={ButtonType.PRIMARY}
                    text="ADD EMAIL"
                    clickHandler={() => {
                      if (!validator.isEmail(email)) {
                        return notification(
                          "Please enter a valid email address",
                          "error"
                        );
                      } else {
                        addEmailHandler()
                      }
                    }}
                    // clickHandler={()=>handleAddEmail}
                    className="footer_mail_button"
                    isDisabled={emailList.length > 0}
                  />
                </CustomAddCol>
                {emailList.length > 0 && <CustomAddCol span={24}>
                  {emailList.map((item) => <div>{item}</div>)}
                  {/* <Button
                    type={ButtonType.PRIMARY}
                    text="REMOVE EMAIL"
                    clickHandler={() => {

                    }}
                    className="footer_mail_button"
                  /> */}
                </CustomAddCol>}

              </Row>
              <CustomDivider />
              <Row justify="space-between">
                Logout of the account.
                <Button
                  type={ButtonType.PRIMARY}
                  text="LOG OUT"
                  clickHandler={showLogoutModal}
                  className="footer_mail_button"
                />
              </Row>
              <CustomDivider />

              <Row justify="space-between">
                Delete your account permanently.
                <Button
                  type={ButtonType.PRIMARY}
                  text="DELETE ACCOUNT"
                  clickHandler={showDeleteModal}
                  className="footer_mail_button"
                />
              </Row>
              <CustomDivider />
            </Col>
          </Row>
          <AccountModal
            isModalVisible={isLogoutModalVisible}
            setIsModalVisible={setIsLogoutModalVisible}
            callBack={() => {
              clearFilters();
              logOut();
              if (userState.userType === UserType.AGENT) {
                changeUserType(UserType.AGENT);
                history.push("/");
              }
              history.push("/");
            }}
            name="Log Out"
            illus={LogoutIllus}
          ></AccountModal>
          <AccountModal
            isModalVisible={isPauseModalVisible}
            setIsModalVisible={setIsPauseModalVisible}
            callBack={() => {
              handleOk();
              // setIsPauseModalVisible(false)
            }}
            name="Pause Account"
            illus={PauseIllus}
          ></AccountModal>
          <AccountModal
            isModalVisible={isDeleteModalVisible}
            setIsModalVisible={setIsDeleteModalVisible}
            callBack={() => {
            }}
            name="Delete Account"
            illus={DeleteIllus}
          ></AccountModal>
        </div>
      }
    </>
  );
};
export default AccountSettingAgentPage;
