import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal, Row, Col } from 'antd';
import map from './images/map.png';
import Button, { ButtonType } from '../../components/Button';
import { useMediaQuery } from 'react-responsive';
import styled from '@emotion/styled';

const CustomHeading = styled.h3`
  text-decoration: none;
  ::after {
    display: none;
  }
`;
const CustomPara = styled.p`
  padding-bottom: 15px;
  line-height: 180%;
`;
const CustomHead = styled.p`
  padding-bottom: 4px;
  line-height: 180%;
`;
const CustomDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;

  @media (max-width: 576px) {
    flex-direction: column;
    /* justify-content: center; */
    width: 100% !important;
    .route_tour-modal__button {
      width: 100% !important;
    }
  }
`;
const CustomHeading1 = styled.h1`
  font-size: 24px;
  @media screen and (max-width: 420px) {
    font-size: 21px;
  }
`;
interface RoutingTourProps {
  handleToggling: (val: string) => void;
  // setRouteButton: any
  isShowing: boolean;
  closeAction: () => void;
  setShowMap?: any;
  setRunApiOnLoad?: any;
  setScheduleStatus?: any;
  setIsTourModalShow?: any;
  setRunMap?: any;
  // setDashboardState?: any
  setDragDropWorking?: any;
  setNumberingMarker?: any;
  setUnlinkPropHeader?: any;
  setShowReScheduleModal?: any;
  SetShowTimeBar?: any;
  setTourPropPage?: any;
  setselected?: any
  setIsUnlinkedPropertyClicked?: any;
  setBestRoute?: (val: Array<any>) => void;
  setHandleTogglingClicked?: any
}

const RoutingTour: React.FC<RoutingTourProps> = ({
  handleToggling,
  // setRouteButton,
  isShowing,
  closeAction,
  setShowMap,
  setRunApiOnLoad,
  setScheduleStatus,
  setIsTourModalShow,
  setRunMap,
  setDragDropWorking,
  setNumberingMarker,
  setUnlinkPropHeader,
  setShowReScheduleModal,
  SetShowTimeBar,
  setTourPropPage,
  setselected,
  setIsUnlinkedPropertyClicked,
  setBestRoute,
  setHandleTogglingClicked
  // setDashboardState,
}) => {
  const history = useHistory();
  const [visible, setVisible] = useState(isShowing);
  const isMobile = useMediaQuery({ maxWidth: 500 });
  const isCard = useMediaQuery({ maxWidth: 991 });

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let param = useQuery();

  const handleLater = () => {
    setShowMap(true);
    handleToggling('Clicked');
    setVisible(false);
    setNumberingMarker(true);
    setShowReScheduleModal(true);
    SetShowTimeBar(true);
    closeAction();
  };
  const handleCancel = () => {
    // setVisible(false)
    setIsTourModalShow(false);
    setScheduleStatus(true);
    setRunMap(false);
    setDragDropWorking(false);
    // setShowMap(false)
    // closeAction()
  };

  const handlepopup = () => {
    closeAction();
    setShowMap(false);
    setUnlinkPropHeader(true);

    setTourPropPage(false);
    setselected('prop');
    setIsUnlinkedPropertyClicked(true);
    setRunMap(false);
    setScheduleStatus(true);
    setBestRoute([]);
    if (param.get('statusType') === 'afterRouteCreated') {
      setHandleTogglingClicked('Clicked');
    }
  };


  return (
    <>
      {/* <Button
        type={ButtonType.PRIMARY}
        text="Show routing the tour modal"
        clickHandler={() => {
          setVisible(true)
        }}
        className="route_tour-modal__button"
        width={isMobile ? '100%' : 18.5}
      /> */}
      <Modal
        centered
        visible={visible}
        onCancel={handleCancel}
        width={isCard ? undefined : 1000}
        bodyStyle={{ height: isCard ? undefined : '600px' }}
        footer={false}
      >
        <Row>
          <CustomHeading1>
            Routing Th
            <span style={{ borderBottom: '3px solid #C2D500' }}>e Tour</span>
          </CustomHeading1>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <img src={map} style={{ float: 'left', width: '100%' }} />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            style={{ flexDirection: 'column', marginTop: isCard ? '' : '5rem' }}
          >
            <CustomPara>
            The tour is being routed. You may edit the route to suite your preferences, however, keep in mind that this route has been optimized to take into account:
            </CustomPara>
            {/* <CustomHead>Including:</CustomHead> */}
            <CustomHead>
            -shortest distance between properties
            </CustomHead>
            <CustomHead>
            -overlapping availability based on the property calendar, travel time and your schedule
            </CustomHead>
          </Col>
        </Row>
        <Row
          style={{ alignContent: 'center', justifyContent: 'space-between' }}
        >
          <div>
            <CustomHeading>Do you want to edit?</CustomHeading>
          </div>
          <CustomDiv>
            <Button
              type={ButtonType.PRIMARY}
              text="YES"
              clickHandler={handlepopup}
              className="route_tour-modal__button"
              width={isMobile ? '100%' : 12.5}
            />
            <Button
              type={ButtonType.PRIMARY}
              text="NO"
              clickHandler={handleLater}
              className="route_tour-modal__button"
              width={isMobile ? '100%' : 12.5}
            />
          </CustomDiv>
        </Row>
      </Modal>
    </>
  );
};

export default RoutingTour;
