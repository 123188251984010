interface ToggleSwitchProps {
  isOn: boolean;
  setIsOn: (value: boolean) => void;
  yesText?: string;
  noText?: string;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  isOn,
  setIsOn,
  yesText,
  noText,
}) => {
  const toggle = () => {
    setIsOn(!isOn);
  };

  return (
    <div className="toggle-switch" onClick={toggle}>
      <p>{isOn ? "on" : "off"}</p>
      {/* {(yesText.length > 0 || noText.length > 0) ?   <p>{isOn ? `${yesText}` : `${noText}`}</p> : <p>{isOn ? "Yes" : "No"}</p> } */}
      <div
        className={`toggle-switch__toggle-frame ${
          isOn && "toggle-switch__toggle-frame--on"
        }`}
      >
        <div
          className={`toggle-switch__control ${
            isOn && "toggle-switch__control--on"
          }`}
        ></div>
      </div>
    </div>
  );
};

export default ToggleSwitch;

// interface ToggleSwitchProps {
//   isOn: boolean;
//   setIsOn:(value: boolean) => void;
//   yesText?: string;
//   noText?: string;
// }

// const ToggleSwitch: React.FC<ToggleSwitchProps> = ({isOn, setIsOn}) => {

//   const toggle = () => {
//     setIsOn(!isOn);
//   }

//   return (
//     <div className="toggle-switch" onClick={toggle}>
//       <p>{isOn ? "Yes" : "No"}</p>
//       <div className={`toggle-switch__toggle-frame ${isOn && "toggle-switch__toggle-frame--on"}`}>
//         <div className={`toggle-switch__control ${isOn && "toggle-switch__control--on"}`}></div>
//       </div>
//     </div>
//   );
// };

// export default ToggleSwitch;
