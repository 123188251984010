import React, { Fragment, useContext, useEffect } from 'react'
import { useLocation, Redirect, Route, Switch } from 'react-router-dom'
import BuyerTourDetail from '../components/buyerTourDetail'
import CommonComp from '../components/CommonTour/CommonTourComp'
import BuyerHomePage from '../components/dashboardBuyerPage/buyerHomePage'
import NewTourDetails from '../components/NewTourDetail'
import TourDetails from '../components/TourDetails'
import TourDetailsForm from '../components/TourDetailsForm'
import AgentContext from '../contexts/AgentContext'
import UserContext, { UserType } from '../contexts/UserContext'
import CalendarUi from '../modals/CalendarComponent/CalendarUi'
import ModalDisplay from '../modals/GoogleMapModals/modaldisplaypages'
import AccountDetailsAgentPage from '../pages/AccountDetailsAgentPage'
import AccountDetailsBuyerPage from '../pages/AccountDetailsBuyerPage'
import AccountSettingsAgentPage from '../pages/AccountSettingsAgentPage'
import AccountSettingsBuyerPage from '../pages/AccountSettingsBuyerPage'
import AgentBuyerPropertyCalendar from '../pages/AgentBuyerPropertyCalender'
import AgentInfoPage from '../pages/AgentInfoPage'
import BuyBuyerPage from '../pages/BuyBuyerPage'
import CalendarAgentPage from '../pages/CalendarAgentPage'
import CalendarBuyerPage from '../pages/CalendarBuyerPage'
import CheckoutPage from '../pages/CheckoutPage'
import DashboardAgentPage from '../pages/DashboardAgentPage'
import FindAnAgent from '../pages/FindAnAgentPage'
import HelpPage from '../pages/HelpPage'
import HomepageAgentPage from '../pages/HomepageAgentPage'
import HomeSchedule from '../pages/HomeSchedule'
import LandingAgentPage from '../pages/LandingAgentPage'
import LandingBuyerPage from '../pages/LandingBuyerPage'
import ListingsAgentPage from '../pages/ListingsAgentPage'
import LoginPage from '../pages/LoginPage'
import NotificationsAgentPage from '../pages/NotificationsAgentPage'
import NotificationsBuyerPage from '../pages/NotificationsBuyerPage'
import PreLoginBuyerPageBuy from '../pages/PreLoginBuyerPageBuy'
import PreLoginBuyerPageSell from '../pages/PreLoginBuyerPageSell'
import PricingPage from '../pages/PricingPage'
import PropertiesPage from '../pages/PropertiesPage'
import PropertyDetailsAgentPage from '../pages/PropertyDetailsAgentPage'
import PropertyDetailsPage from '../pages/PropertyDetailsPage'
import RegisterAgentPage from '../pages/RegisterAgentPage'
import RegisterBuyerPage from '../pages/RegisterBuyerPage'
import RequestDemoPage from '../pages/RequestDemoPage'
import ResetPasswordPage from '../pages/ResetPasswordPage'
import ScratchPage from '../pages/ScratchPage'
import SellBuyerPage from '../pages/SellBuyerPage'
import ShowingsAgentPage from '../pages/ShowingsAgentPage'
import VerifyPage from '../pages/VerifyPage'
import SurveyPage from '../pages/SurveyPage'
import SurveyForm from '../components/Survey/survey-form'
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';
interface PageContentPropsModel {
  setIsShowingLoginModal: any
}


const PageContent: React.FC<PageContentPropsModel> = ({
  setIsShowingLoginModal,
}): JSX.Element => {
  const { userState, restoreUserContext } = useContext(UserContext)
  const { restoreAgentContext } = useContext(AgentContext)

  useEffect(() => {
    restoreUserContext()
    restoreAgentContext()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const renderConditionalRoutes = () => {
    switch (userState.userType) {
      case UserType.BUYER:
        if (userState.isLoggedIn) {
          return (
            <Fragment>
              {/* <Route path="/" exact component={DashboardBuyerPage} /> */}
              <Route exact path="/">
                <Redirect to="/buy" />
              </Route>
              <Route path="/dashboard" exact component={BuyerHomePage} />
              <Route
                path="/account-details"
                exact
                component={AccountDetailsBuyerPage}
              />
              <Route
                path="/notifications"
                exact
                component={NotificationsBuyerPage}
              />
              <Route path="/find-agent" exact component={FindAnAgent} />
              <Route path="/buy" exact component={BuyBuyerPage} />
              <Route path="/sell" exact component={SellBuyerPage} />
              <Route path="/calendar" exact component={CalendarBuyerPage} />
              <Route path="/help" exact component={HelpPage} />
              <Route path="/newtourpage" exact component={NewTourDetails} />
              <Route path="/common" exact component={CommonComp} />

              <Route
                path="/account-settings"
                exact
                component={AccountSettingsBuyerPage}
              />
              <Route path="/agent-info" exact component={AgentInfoPage} />
              <Route path="/googlemap" exact component={TourDetails} />
              <Route path="/tourdetail" exact component={BuyerTourDetail} />
            </Fragment>
          )
        }

        return (
          <Fragment>
            <Route path="/form" exact component={TourDetailsForm} />
            <Route exact path="/">
              <Redirect to="/buy" />
            </Route>
            <Route path="/home" exact component={LandingBuyerPage} />
            <Route path="/login" exact component={LoginPage} />
            <Route path="/buy" exact component={PreLoginBuyerPageBuy} />
            <Route path="/sell" exact component={PreLoginBuyerPageSell} />
            <Route path="/verify" exact component={VerifyPage} />
            <Route path="/reset-password" exact component={ResetPasswordPage} />

            {/* <Route component={NotFound} /> */}
          </Fragment>
        )
      case UserType.AGENT:
        if (userState.isLoggedIn) {
          return (
            <Fragment>
              <Route exact path="/buy">
                <Redirect to="/" />
              </Route>
              <Route path="/" exact component={HomepageAgentPage} />
              <Route path="/dashboard" exact component={DashboardAgentPage} />
              <Route
                path="/account-details"
                exact
                component={AccountDetailsAgentPage}
              />
              <Route
                path="/account-settings"
                exact
                component={AccountSettingsAgentPage}
              />
              <Route
                path="/notifications"
                exact
                component={NotificationsAgentPage}
              />
              <Route path="/showings" exact component={ShowingsAgentPage} />
              <Route path="/listings" exact component={ListingsAgentPage} />
              <Route path="/calendar" exact component={CalendarAgentPage} />
              <Route path="/help" exact component={HelpPage} />
              <Route path="/newtourpage" exact component={NewTourDetails} />
              <Route path="/common" exact component={CommonComp} />
              <Route path="/googlemap" exact component={TourDetails} />
              <Route
                path="/propertydetails"
                component={PropertyDetailsAgentPage}
              />
              <Route
                path="/agentBuyerPropertyCalendar"
                component={AgentBuyerPropertyCalendar}
              />
              <Route path="/modals" component={ModalDisplay} />
              <Route path="/calendarmodal" component={CalendarUi} />
              <Route path="/tourdetail" exact component={BuyerTourDetail} />
            </Fragment>
          )
        }
        return (
          <Fragment>
            <Route path="/" exact component={LandingAgentPage} />
          </Fragment>
        )
      default:
        return
    }
  }

  return (
    <main className="page-content">
      {renderConditionalRoutes()}
      <HubspotProvider>
        <Switch>
          <Route path="/request-demo" exact component={RequestDemoPage} />
          <Route path="/pricing" exact component={PricingPage} />
          <Route path="/schedule" exact component={HomeSchedule} />
          <Route path="/survey" exact component={SurveyPage} />
          <Route path="/survey-form" exact component={SurveyForm} />
          <Route
            path="/register"
            exact
            component={
              userState.userType === UserType.BUYER
                ? RegisterBuyerPage
                : RegisterAgentPage
            }
          />
          <Route
            path="/properties/:mlsId"
            exact
            component={PropertyDetailsPage}
          />
          <Route path="/properties" exact component={PropertiesPage} />
          <Route path="/scratch" exact component={ScratchPage} />
          {userState.userType === UserType.AGENT && (
            <Route path="/checkout" exact component={CheckoutPage} />
          )}
          {/* <Route component={PageNotFound} /> */}
        </Switch>
      </HubspotProvider>
    </main>
  )
}

export default PageContent
