import { useState, useContext, useRef, useEffect } from 'react'
import UserContext, { UserType } from '../../../contexts/UserContext'
import buttonClose from '../../../assets/images/button-close.svg'
import TextField, {
    TextFieldType,
    TextFieldIconType,
} from '../../../components/TextField'
import Button, { ButtonType } from '../../../components/Button'
import Checkbox, { CheckboxType } from '../../../components/Checkbox'
import { useHistory } from 'react-router'
import { useMediaQuery } from 'react-responsive'
import { notification } from '../../../components/notification'
import CustomSpinner from '../../../components/spinner/CustomSpinner'
import { Avatar, Input } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import iconPhone from '../../../assets/images/Icon - phone.svg';
import iconEmail from '../../../assets/images/icon-email.svg';



interface SaleAgentModalProps {
    isModalOpen: boolean
    closeAction: () => void
    saleRecord: any

}

const SaleAgentModal: React.FC<SaleAgentModalProps> = ({
    isModalOpen,
    closeAction,
    saleRecord

}) => {
    const { userState, logIn } = useContext(UserContext)
    const history = useHistory()
    const [message, setMessage] = useState('')
    const [password, setPassword] = useState('')
    const [rememberMe, setRememberMe] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const isMobile = useMediaQuery({ maxWidth: 500 })

    const handleSendMessage = async () => {
        try {
            closeAction()

        } catch (error) {
            console.log(error);

        }
    }
    const modalClassName = isModalOpen
        ? 'login-modal u-visible'
        : 'login-modal u-hidden'
    const cardClassName = isModalOpen
        ? 'login-modal__card animation__pop-in'
        : 'login-modal__card animation__pop-out'

    return (
        <div className={modalClassName}>
            <div className={cardClassName}>
                <div className="login-modal__row">
                    {isLoading && <CustomSpinner />}
                    <h1>{saleRecord?.sale_agent ?? ""}</h1>
                    <img
                        className="login-modal__close-button"
                        src={buttonClose}
                        onClick={closeAction}
                        alt="Close button"
                    />
                </div>
                <div className="login-modal__row">
                    <p>{saleRecord?.sale_agent ?? ""} is a sale agent who visited this property on {saleRecord?.date?.date ?? null}</p>
                </div>
                <div className="sale-agent-modal__row">
                    <div className="modal__row">
                        <Avatar
                            size={{ xs: 50, sm: 50, md: 50, lg: 60, xl: 80, xxl: 100 }}
                            icon={<UserOutlined />}
                            className="avatar"
                        />
                    </div>
                    <div  >
                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                            <span className="modal__row-details">  <img src={iconPhone} alt="Phone icon" />&nbsp; &nbsp;{saleRecord?.phone ?? "N/A"} </span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                            <span className="modal__row-details"><img src={iconEmail} alt="Phone icon" />&nbsp; &nbsp;{saleRecord?.saleAgentEmail ?? "N/A"} </span>
                        </div>
                        {/* <p className="modal__row">View Profile </p> */}

                    </div>

                </div>
                <div className="login-modal__column">
                    <p className="login-modal__error-message">{userState.loginError}</p>
                    <TextField
                        value={message}
                        setValue={setMessage}
                        placeholder=""
                        label="Message to sale agent:"
                        type={TextFieldType.BIGMESSAGE}
                        icon={TextFieldIconType.EMAIL}
                        isRequired={false}
                        width={isMobile ? 26.4 : 38.4}
                        className="login-modal__email-field"
                    />
                    <Button
                        type={ButtonType.PRIMARY}
                        text="SEND MESSAGE"
                        clickHandler={handleSendMessage}
                        width={20}
                        className="login-modal__button"
                    />


                </div>
            </div>
        </div >
    )
}

export default SaleAgentModal
