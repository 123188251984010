import 'antd/dist/antd.css';
import React, { useState, useContext } from 'react';
import { Avatar } from 'antd';
import { UserOutlined, PhoneOutlined, MailTwoTone } from '@ant-design/icons';
import { Modal, Row, Col, Anchor, TimePicker } from 'antd';
import reschedule from './images/reschedule.png';
import { useMediaQuery } from 'react-responsive';
import Button, { ButtonType } from '../../components/Button';
import UserContext, { UserType } from '../../contexts/UserContext';
import styled from '@emotion/styled';
import { useHistory, useLocation } from 'react-router-dom';

const CustomRegHeading = styled.h1`
  font-size: 24px;
  @media screen and (max-width: 600px) {
    font-size: 20px;
    margin-bottom: 32px;
  }
`;

const CustomDiv = styled.div`
  @media screen and (min-width: 990px) {
    margin-left: 60%;
    padding-top: 1%;
  }
  @media screen and (max-width: 991px) {
    padding-top: 1%;
    display: flex;
    justify-content: center;
  }
`;
const Phoneoutlineddiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ReScheduleProps {
  address?: string;
  propAgentName?: string;
  listedAgent?: boolean;
  propertyId?: number;
  formId?: string;
  serviceTime?: any;
  startTime?: any;
  endTime?: any;
  isShowing: boolean;
  userName?: string;
  userEmail?: string;
  userPhone?: string;
  userPhoto?: string;
  listingId?:number;
  isListed?:string;
  propData?:any
  closeAction: () => void;
  handleReScheduledPropTime: () => void;
  setReScheduleTime: (val: string) => void;
}
const Rescheduling: React.FC<ReScheduleProps> = ({
  address,
  propAgentName,
  listedAgent,
  propertyId,
  formId,
  serviceTime,
  startTime,
  endTime,
  isShowing,
  userName,
  userEmail,
  userPhone,
  userPhoto,
  listingId,
  isListed,
  propData,
  closeAction,
  handleReScheduledPropTime,
  setReScheduleTime,
}) => {
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(isShowing);
  const { userState } = useContext(UserContext);
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChange = (time: any, timeString: any) => {
    setReScheduleTime(timeString);
  };

  const handleSchedule = async () => {
    handleReScheduledPropTime();
    // //update time stamp of this propertyID and on this formId
    // if (reScheduleTime === '') {
    //   return notification('Please select time ', 'error');
    // }
    // let timePerProp = serviceTime?.split(' ')?.[0];
    // let timeDur = serviceTime?.split(' ')?.[1];
    // if (timeDur === 'min') {
    //   timePerProp = Number(timePerProp);
    // } else {
    //   timePerProp = Number(timePerProp) * 60;
    // }
    // let ReScheduleStartTime = moment(reScheduleTime, 'hh:mm:ss A')?.format(
    //   'hh:mm:ss A'
    // );
    // let ReScheduleEndTime = moment(reScheduleTime, 'hh:mm:ss A')
    //   ?.add(timePerProp, 'minutes')
    //   ?.format('hh:mm:ss A');
    // const response = await UpdateTimeStampBestRoute(
    //   propertyId,
    //   formId,
    //   ReScheduleStartTime,
    //   ReScheduleEndTime
    // );

    setIsModalVisible(false);
  };
  const isMobile = useMediaQuery({ maxWidth: 500 });

  const handleViewPropertyCal = () => {
    let mlsId = propertyId;
      let buyerId = propData?.buyerId;
      let listingAgentId = propData?.listingAgentId;
      let listingAgentSellerName = propData?.client;
      let listingAgentName = propData?.listingAgent?.name;
      history.push(
        `/agentBuyerPropertyCalendar/${mlsId}/?query=${buyerId}&listingId=${listingId}&listingAgentId=${listingAgentId}&client=${listingAgentSellerName}&listingAgent=${listingAgentName}`
      );
  };
  return (
    <>
      <Modal
        // title="Reschedule Showing"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={{ borderBlockStyle: 'inset' }}
        width={900}
        style={{ color: 'blue' }}
        footer={false}
      >
        <Row>
          <CustomRegHeading>
            Reschedule{' '}
            <span style={{ borderBottom: '3px solid #C2D500' }}>Showing?</span>
          </CustomRegHeading>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <img
              src={reschedule}
              alt="cancellation image"
              width="100%"
              style={{ float: 'left' }}
            />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <h4 style={{ color: 'black', marginBottom: '20px', float: 'left' }}>
              <p style={{ paddingBottom: '20px' }}>
                {userName} is the seller agent for the property on
              </p>
              <p style={{ paddingBottom: '10px' }}>{address}</p>
            </h4>

            {userState?.userType === UserType?.AGENT ? (
              <Row justify="start" style={{ width: '100%' }}>
                <Col
                  xs={24}
                  sm={6}
                  md={6}
                  lg={10}
                  // style={{
                  //   display: "flex",
                  //   justifyContent: "center",
                  //   paddingRight: "6px",
                  // }}
                >
                  <Avatar
                    size={{
                      xs: 65,
                      sm: 65,
                      md: 65,
                      lg: 65,
                      xl: 85,
                      xxl: 120,
                    }}
                    icon={!listedAgent ? <UserOutlined /> : ''}
                    src={listedAgent ? userPhoto : ''}
                    style={{ display: 'grid', placeItems: 'center' }}
                  />
                </Col>
                <Col
                  xs={24}
                  sm={18}
                  md={18}
                  lg={14}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                    }}
                  >
                    <Phoneoutlineddiv>
                      <PhoneOutlined />
                    </Phoneoutlineddiv>
                    <div>
                      <h5 style={{ marginLeft: '8px', color: 'blue' }}>
                        {userPhone}
                      </h5>
                    </div>
                  </div>
                  {listedAgent && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                      }}
                    >
                      <div>
                        <MailTwoTone />
                      </div>
                      <div>
                        <h5 style={{ marginLeft: '8px', color: 'blue' }}>
                          {userEmail}
                        </h5>
                      </div>
                    </div>
                  )}
                  {userState?.userType === UserType?.AGENT && (isListed ==='1') ? (
                   <p
                   style={{
                     marginTop: '10px',
                     color: 'coral',
                     textDecorationLine: 'underline',
                     cursor: 'pointer',
                   }}
                   onClick={handleViewPropertyCal}
                 >
                   View Property Calendar
                 </p>
                  ):(<></>)}
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row style={{ float: 'left', marginTop: '5%' }}>
              <p>
                You may reschedule the property by contacting the agent directly
                and confirm it here:
              </p>
            </Row>
            <Row style={{ float: 'left', marginTop: '5%', paddingTop: '20px' }}>
              <h4 style={{ color: 'black' }}>Confirm time for the showing*</h4>
              <TimePicker
                use12Hours
                format="h:mm a"
                onChange={onChange}
                showNow={false}
                style={{
                  border: 'true',
                  borderBlockWidth: 'thin',
                  borderRadius: '5px',
                  boxShadow: '2px 2px 2px gray',
                  float: 'left',
                  width: '90%',
                }}
              />
            </Row>
            {/* <div
              style={{
                alignContent: "center",
                marginTop: "2%",
                marginLeft: "-25%",
              }}
            ></div> */}
          </Col>
        </Row>

        <CustomDiv>
          <Button
            type={ButtonType.PRIMARY}
            text="DONE"
            clickHandler={handleSchedule}
            className="route_tour-modal__button"
            width={isMobile ? '100%' : 18.5}
          />
        </CustomDiv>
        {/* </Row> */}
        {/* <Row> */}
        {/* <Buttonbr
            type="primary"
            shape="round"
            style={{ marginLeft: "50%", marginTop: "2px" }}
            onClick={handleOk}
          >
            Done
          </Buttonbr> */}
        {/* </Row> */}
      </Modal>
    </>
  );
};

export default Rescheduling;
