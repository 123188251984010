import { Avatar } from "antd";

export interface AgentTestimonialProps {
  name: string;
  avatar: string;
  text: string;
  agentInfo: string;
}

const AgentTestimonial: React.FC<AgentTestimonialProps> = ({ name, avatar, text, agentInfo}) => {
  return (
    <div className="agent-testimonial">
      <div className="agent-testimonial__container">
      <Avatar size={154} icon={ <img className="agent-testimonial__container-image" src={require(`../assets/images/${avatar}`).default} alt="Agent Avatar"/>} />
        {/* <img className="agent-testimonial__avatar" src={require(`../assets/images/${avatar}`).default} alt="Agent Avatar"/> */}
        <p className="agent-testimonial__text">{text}</p>
        <p className="agent-testimonial__name f-subtitle-1">{name}</p>
        <p className="agent-testimonial__agent-info f-subtitle-2" style={{color: "#000"}}>{agentInfo}</p>
      </div>
    </div>
  );
};

export default AgentTestimonial;