/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import styled from "@emotion/styled";
import { Col, Form, Row, Select } from "antd";
import dayjs from "dayjs";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import Button, { ButtonType } from "../components/Button";
import EmptyDashboardTab from "../components/EmptyDashboardTab/emptyDashboardTab";
import MiniCalendar from "../components/MiniCalendar";
import PreviousSummaryBuyer from "../components/PreviousSummaryBuyer";
import PropertyCard from "../components/PropertyCard";
import SavedSearchCard from "../components/SavedSearchCard";
import SearchField from "../components/SearchField";
import ToursSummaryBuyer from "../components/ToursSummaryBuyer";
import BuyerDetails from "../components/dashboardAgentPage/BuyerDetails";
import { notification } from "../components/notification";
import UserHeader from "../components/notificationAgentPage/UserHeader";
import NotificationsAboutAgentCard from "../components/notificationAgentPage/notificationAboutBuyerCard";
import CustomSpinner from "../components/spinner/CustomSpinner";
import AgentContext from "../contexts/AgentContext";
import PropertyFiltersContext from "../contexts/PropertyFiltersContext";
import UserContext, { UserType } from "../contexts/UserContext";
import FindAnAgentConfirmModal from "../modals/FindAnAgentConfirmModal";
import {
  SavedSearch,
  deleteSavedSearchService,
  getAgentNotifications,
  getAllPreviousTourDetailsData,
  getAllPropertyCommentsForBuyerFromAgentService,
  getAllUpcomingScheduledData,
  getAllupcomingTourDetailsData,
  getBuyerInfoService,
  getFavoritePropertiesForBuyerService,
  getPreviousViewPropertyCalendarByBuyerID,
  getRequestedPropertiesForBuyerService,
  getSavedSearchesService,
  getTourAllAcceptedProp,
  getViewPropertyCalendarByBuyerID,
  getViewPropertyCalendarScheduledEvent,
  getViewedFavoritesPropertiesForBuyerService,
  getViewedPropertiesForBuyerService,
  updateAgentNotification,
} from "../services/NavihomeService";
import { activatedPlan } from "../utils/base";

const { Option } = Select;

TimeAgo.addDefaultLocale(en);

const CustomPara = styled.p`
  font-size: 12px;
  margin-top: 10px;
`;

const CustomBackPara = styled.p`
  cursor: pointer;
  display: inline-block;
  margin-top: 16px;
  padding-left: 12px;
  &:hover {
    text-decoration: underline;
    font-weight: 600;
  }
`;

export const CustomTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  @media (max-width: 820px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    width: auto;
  }
`;

export const CustomTitleContainerRight = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 820px) {
    width: 100%;
    justify-content: flex-start !important;
    align-items: left;
  }
`;

export const CustomDashboardTitle = styled.h3`
  font-weight: bold;
  font-size: 30px;
  @media screen and (max-width: 453px) {
    &:after {
      display: none;
    }
  }
`;

const CustomDiv = styled.div`
  margin-right: 5rem;
`;

enum DashboardTab {
  OVERVIEW = "OVERVIEW",
  REQUESTED_SHOWINGS = "Requested Showings",
  SCHEDULED_SHOWINGS = "Scheduled Showings",
  SAVED_SEARCHES = "Saved Searches",
  FAVORITE_PROPERTIES = "Favorite Properties",
  PROPERTY_COMMENTS = "Property Comments",
  PREVIOUS_TOURS = "Previous Tours",
  VIEWED_PROPERTIES = "Viewed Properties",
  VIEWED_FAVORITES = "Viewed Favorites",
  NOTIFICATIONS = "Notifications",
}

type SizeType = Parameters<typeof Form>[0]["size"];

const DashboardAgentPage: React.FC = () => {
  const { agentState, selectBuyer } = useContext(AgentContext);
  const { userState } = useContext(UserContext);
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(DashboardTab.OVERVIEW);
  const [requestedProperties, setRequestedProperties] = useState([]);
  const [commentedProperties, setCommentedProperties] = useState([]);
  const [viewedRequestedProperties, setViewedRequestedProperties] = useState(
    []
  );
  const [favoriteProperties, setFavoriteProperties] = useState([]);
  const [viewedFavoriteProperties, setViewedFavoriteProperties] = useState([]);
  const [savedSearches, setSavedSearches] = useState<SavedSearch[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [buyerId, setBuyerId] = useState<number>(0);
  const [upcomingTourDetail, setUpcomingTourDetail] = useState<any>({});
  const [prevTourDetail, setPrevTourDetail] = useState<any>([]);
  const [buyerName, setBuyerName] = useState<string>("");
  const [buyerEmail, setBuyerEmail] = useState<string>("");
  const [buyerPhone, setBuyerPhone] = useState<string>("");
  const { setQuery } = useContext(PropertyFiltersContext);
  const [agentNotifications, setAgentNotifications] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState<Array<any>>([]);
  const timeAgo = new TimeAgo("en-US");
  const isMD = useMediaQuery({ maxWidth: 1070 });
  const [componentSize, setComponentSize] = useState<SizeType | "default">(
    "default"
  );
  const { pathname } = useLocation();
  const [buyerImageUrl, setBuyerImageUrl] = useState<string>("");
  const [upcomingTourDate, setUpcomingTourDate] = useState<any>(null);
  const [upcomingStartEndDate, setUpcomingStartEndDate] = useState<any>({
    startDate: null,
    endDate: null,
  });
  const [isShowingConfirmModal, setIsShowingConfirmModal] =
    useState<boolean>(false);

  const [upcomingBuyerPhone, setUpcomingBuyerPhone] = useState<string>("");

  const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size);
  };

  const location: any = useLocation();

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let param = useQuery();
  const [isContent, setIsContent] = useState(false);

  //ACTIONS

  useEffect(() => {
    const upcomingBuyerId = param.get("buyerId");

    if (
      location?.state?.notificationArr &&
      location?.state?.notificationArr?.length > 0
    ) {
      selectNotifications();
      setTimeout(async () => {
        await Promise.all(
          location?.state?.notificationArr?.map(async (id: number) => {
            await updateNotification(id, Number(upcomingBuyerId));
          })
        );

        // await location?.state?.notificationArr?.forEach(
        // 	(id: number) => {
        // 		updateNotification(id);
        // 	}
        // );
        // getNotificationsGroup();
        // getReadNotifications(parseInt(upcomingBuyerId[1]) ?? null);
      }, 10000);
    }
  }, [history?.location?.search]);

  useEffect(() => {
    window.onpageshow = function (event: any) {
      if (event.persisted) {
        window.location.reload();
      }
    };

    const queryTab: string = param.get("buyerDashboard");
    const setBuyerInfo: string = param.get("setBuyerInfo");
    getBuyerInfo(setBuyerInfo ?? 'No');

    const paramBuyerId: string = param?.get("buyerId");
    const page: string = localStorage.getItem("page");
    // let buyerPhone = param.get("phone") ?? "";
    let buyerPhone = userState?.userType === UserType?.BUYER ? userState?.buyer?.phone : agentState?.buyerPhone;
    setUpcomingBuyerPhone(buyerPhone);
    getUpcomingTour(Number(paramBuyerId), null);
    if (page === "previousTour") {
      setCurrentTab(DashboardTab.PREVIOUS_TOURS);
      getPreviousTour(paramBuyerId);
      // getUpcomingTour(buyerId, 'previousTour');
      localStorage.removeItem("page");
    }
    if (queryTab === "requested") {
      setCurrentTab(DashboardTab.REQUESTED_SHOWINGS);
      getRequestedProperties();
    } else if (queryTab === "scheduled") {
      setCurrentTab(DashboardTab.SCHEDULED_SHOWINGS);
      getALLLinkedPropertiesForBuyer();
    } else if (queryTab === "favorite") {
      setCurrentTab(DashboardTab.FAVORITE_PROPERTIES);
      getFavoriteProperties();
    } else if (queryTab === "comments") {
      setCurrentTab(DashboardTab.PROPERTY_COMMENTS);
      getAllPropertyComments();
    } else if (queryTab === "viewed") {
      setCurrentTab(DashboardTab.VIEWED_PROPERTIES);
      getViewedRequestedProperties();
    } else if (queryTab === "viewedFavorite") {
      setCurrentTab(DashboardTab.VIEWED_FAVORITES);
      getViewedFavoritesProperties();
    } else if (queryTab === "saved") {
      setCurrentTab(DashboardTab.SAVED_SEARCHES);
      getSavedSearches(Number(paramBuyerId));
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, currentTab]);

  const getUpcomingTour = async (buyerId: any, prev?: string) => {
    setIsLoading(true);
    let zones = moment.tz.guess();
    try {
      const token = userState?.agent?.token;

      if (!token) {
        setIsLoading(false);
        return notification("Unauthorized!", "error");
      }

      if (!buyerId) {
        setIsLoading(false);
        return;
      }
      let upcomingTour = [];
      const allUpcomingTourDetails: any = await getAllupcomingTourDetailsData(
        buyerId,
        token,
        "Agent",
        "Dashboard",
        "Not Scheduled Showings"
      );

      console.log("allUpcomingTourDetails: ", allUpcomingTourDetails);


      if (allUpcomingTourDetails?.length > 0) {
        for (let i = 0; i < allUpcomingTourDetails?.length; i++) {
          let currHourTime: any,
            bestRouteArea: null,
            acceptedPropertyCount = 0;
          if (allUpcomingTourDetails[i]?.formId) {
            currHourTime = allUpcomingTourDetails[i]?.time
              ?.split("-")?.[0]
              ?.split(" ")?.[1];
            bestRouteArea = allUpcomingTourDetails[i]?.bestRouteProperties?.[0]
              ?.propertyDetails
              ? JSON.parse(
                allUpcomingTourDetails[i]?.bestRouteProperties?.[0]
                  ?.propertyDetails
              )?.address?.city
              : "";

            if (allUpcomingTourDetails[i]?.bestRouteProperties?.length > 0) {
              allUpcomingTourDetails?.[i]?.bestRouteProperties?.forEach(
                (element: any) => {
                  if (element?.isAccepted && element.startTime && element.endTime) {
                    acceptedPropertyCount++;
                  }
                }
              );
            }
          }

          let date: any,
            dayOfWeek,
            time,
            timeOfDay,
            area,
            properties,
            statusPrimary,
            statusSecondary,
            formId,
            res;

          let stillUtc = moment
            .tz(allUpcomingTourDetails[i]?.dateTime, zones)
            .utc();

          //Converting in local time zone
          date = moment(stillUtc, "MM-DD-YYYY").format("LL");
          dayOfWeek = moment
            .tz(allUpcomingTourDetails[i]?.dateTime, zones)
            .utc()
            .format("dddd");
          time = allUpcomingTourDetails[i]?.time;

          timeOfDay = currHourTime === "am" ? "Morning" : "Evening";

          area = bestRouteArea ?? "N/A";
          properties = allUpcomingTourDetails[i]?.selectedProperties;

          statusPrimary = `${acceptedPropertyCount}/${allUpcomingTourDetails[i]?.selectedProperties} ${allUpcomingTourDetails[i]?.status}`;
          statusSecondary =
            allUpcomingTourDetails[i]?.role === "Agent"
              ? `Agent ${allUpcomingTourDetails[i]?.status}`
              : `Buyer ${allUpcomingTourDetails[i]?.status} OH`;

          formId = allUpcomingTourDetails[i]?.formId;
          res = allUpcomingTourDetails[i];

          const newDataObj = {
            date: date,
            dayOfWeek: dayOfWeek,
            time: time,
            timeOfDay: timeOfDay,
            area: area,
            properties: properties,
            statusPrimary:
              allUpcomingTourDetails[i]?.status === "Scheduled"
                ? statusPrimary
                : "Saved",
            statusSecondary: statusSecondary,
            formId: formId,
            responseData: res,
            // acceptedPropertyCount: acceptedPropertyCount,
            // totalPropertyCount : newArr[i]?.selectedProperties
          };
          upcomingTour.push(newDataObj);
        }
      }

      const viewPropertyCalendar: any = await getViewPropertyCalendarByBuyerID(
        buyerId,
        token,
        "Agent",
        "Dashboard"
      );

      if (viewPropertyCalendar?.length > 0) {
        for (let i = 0; i < viewPropertyCalendar?.length; i++) {
          let startTime: any,
            endTime: any,
            propCalCurrZone: any,
            propCalArea: null;

          if (viewPropertyCalendar[i]?.viewPropertyCalendarId) {
            startTime = viewPropertyCalendar[i]?.startTime;
            endTime = viewPropertyCalendar[i]?.endTime;
            startTime = moment(startTime, "HH:mm").format("hh:mm a");
            endTime = moment(endTime, "HH:mm").format("hh:mm a");
            propCalCurrZone = startTime?.split(" ")?.[1];
            propCalArea = viewPropertyCalendar[i]?.propertyDetails
              ? JSON.parse(viewPropertyCalendar[i]?.propertyDetails)?.address
                ?.city
              : "";
          }

          let date: any,
            dayOfWeek,
            time,
            timeOfDay,
            area,
            properties,
            statusPrimary,
            res,
            stillUtc = moment
              .tz(viewPropertyCalendar[i]?.startDate, zones)
              .utc();
          //Converting in local time zone

          const stDay: number = new Date(viewPropertyCalendar[i]?.startDate).getUTCDate();
          const stMonth: number = dayjs(viewPropertyCalendar[i]?.startDate).month();
          const stYear: number = dayjs(viewPropertyCalendar[i]?.startDate).year();

          const tempDate: string = `${stMonth + 1}-${stDay}-${stYear}`;
          date = moment(tempDate, "MM-DD-YYYY").format("LL");
          dayOfWeek = moment(tempDate, "MM-DD-YYYY").format("dddd");

          // date = moment(stillUtc, "MM-DD-YYYY").format("LL");
          // dayOfWeek = moment.tz(viewPropertyCalendar[i]?.startDate, zones).utc().format("dddd");
          time = `${startTime}-${endTime}`;
          timeOfDay = propCalCurrZone === "am" ? "Morning" : "Evening";

          area = propCalArea ?? "N/A";
          properties = "1";

          statusPrimary = viewPropertyCalendar[i]?.isAccepted
            ? "Accepted"
            : "Requested";
          res = viewPropertyCalendar[i];

          const newDataObj = {
            date: date,
            dayOfWeek: dayOfWeek,
            time: time,
            timeOfDay: timeOfDay,
            area: area,
            properties: properties,
            statusPrimary: statusPrimary,
            statusSecondary: "",
            formId: 0,
            responseData: res,
            // acceptedPropertyCount: acceptedPropertyCount,
            // totalPropertyCount : newArr[i]?.selectedProperties
          };
          upcomingTour.push(newDataObj);
        }
      }

      upcomingTour?.sort((a: any, b: any) => {
        return +new Date(a?.date) - +new Date(b?.date);
      });

      setUpcomingTourDetail(upcomingTour);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("error", err);
    }
  };

  const getPreviousTour = async (buyerId: any) => {
    setIsLoading(true);
    try {
      const token = userState?.agent?.token;
      if (!token) {
        setIsLoading(false);
        return notification("Unauthorized!", "error");
      }
      if (!buyerId) {
        setIsLoading(false);
        return;
      }
      let response: any;
      let propertyCalc: any;
      let newArr: any = [];

      let currDate = moment().format("YYYY-MM-DD");
      response = await getAllPreviousTourDetailsData(buyerId, currDate, token);
      propertyCalc = await getPreviousViewPropertyCalendarByBuyerID(
        buyerId,
        currDate,
        token,
        "viewed property"
      );
      newArr = [...response, ...propertyCalc];
      let prevTour = [];
      if (newArr?.length > 0) {
        for (let i = 0; i < newArr?.length; i++) {
          let startTime: any,
            endTime: any,
            currHourTime: any,
            propCalCurrZone: any,
            propCalArea: null,
            bestRouteArea: null,
            role: string;
          if (newArr[i]?.formId) {
            currHourTime = newArr[i]?.time?.split("-")?.[0]?.split(" ")?.[1];
            bestRouteArea = newArr[i]?.bestRouteProperties?.[0]?.propertyDetails
              ? JSON.parse(newArr[i]?.bestRouteProperties?.[0]?.propertyDetails)
                ?.address?.city
              : "N/A";
            role = newArr[i]?.role;
          }

          if (newArr[i]?.viewPropertyCalendarId) {
            startTime = newArr[i]?.startTime;
            endTime = newArr[i]?.endTime;
            startTime = moment(startTime, "HH:mm").format("hh:mm a");
            endTime = moment(endTime, "HH:mm").format("hh:mm a");
            propCalCurrZone = startTime?.split(" ")?.[1];
            propCalArea = newArr[i]?.propertyDetails
              ? JSON.parse(newArr[i]?.propertyDetails)?.address?.city
              : "N/A";
          }

          let date: any,
            dayOfWeek,
            time,
            timeOfDay,
            area,
            properties,
            statusPrimary,
            statusSecondary,
            formId,
            res;

          let correctDate = newArr[i]?.formId
            ? moment.tz(newArr[i]?.dateTime, moment.tz.guess()).utc()
            : moment.tz(newArr[i]?.startDate, moment.tz.guess()).utc();

          date = moment(correctDate, "MM-DD-YYYY").format("LL");
          dayOfWeek = moment(correctDate).format("dddd");
          time = newArr[i]?.formId
            ? newArr[i]?.time
            : `${startTime}-${endTime}`;
          timeOfDay = newArr[i]?.formId
            ? currHourTime === "am"
              ? "Morning"
              : "Evening"
            : propCalCurrZone === "am"
              ? "Morning"
              : "Evening";

          area = newArr[i]?.formId
            ? bestRouteArea ?? "N/A"
            : propCalArea ?? "N/A";
          properties = newArr[i]?.formId ? newArr[i]?.selectedProperties : "1";

          statusPrimary =
            role === 'Buyer'
              ? "Self-Scheduled"
              : "Scheduled";
          statusSecondary =
            role === 'Buyer'
              ? "Open House"
              : "Agent viewing";

          formId = newArr[i]?.formId ? newArr[i]?.formId : null;
          res = newArr[i];

          const newDataObj = {
            date: date,
            dayOfWeek: dayOfWeek,
            time: time,
            timeOfDay: timeOfDay,
            area: area,
            properties: properties,
            statusPrimary: statusPrimary,
            statusSecondary: statusSecondary,
            formId: formId,
            responseData: res,
          };
          prevTour.push(newDataObj);
        }
      }
      prevTour?.sort((a, b) => +new Date(a?.date) - +new Date(b?.date))
      setPrevTourDetail(prevTour);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error("error", err);
    }
  };

  const updateNotification = async (
    notificationId: number,
    buyerId: number
  ) => {
    setIsLoading(true);
    try {
      await updateAgentNotification(
        notificationId,
        "read",
        userState.agent.token
      );
      getNotifications(buyerId);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getBuyerInfo = async (setBuyerInfo?: string) => {
    const upcomingBuyerId = history?.location?.search?.split("=");
    const urlParams = new URLSearchParams(history?.location?.search);
    const urlBuyerId = urlParams.get("buyerId");

    if (upcomingBuyerId?.length > 0) {
      const buyerDetails: any = await getBuyerInfoService(
        userState?.agent?.token,
        parseInt(upcomingBuyerId[1])
      );

      if (setBuyerInfo === "Yes") {
        selectBuyer(parseInt(urlBuyerId), buyerDetails?.name, buyerDetails?.phone, buyerDetails?.email, buyerDetails?.preferredContact);
      }
      setBuyerName(buyerDetails?.name);
      setBuyerEmail(buyerDetails?.email);
      setBuyerPhone(buyerDetails?.phone);
      setBuyerImageUrl(buyerDetails?.avatarUrl ?? "");
      getNotifications(parseInt(upcomingBuyerId?.[1]) ?? null);
      // setBuyerId(parseInt(upcomingBuyerId?.[1]))
      setBuyerId(parseInt(urlBuyerId));
    }
  };

  const searchAction = (query: string) => {
    setQuery(query);
    // `/properties?query=${query}&buyerId=${buyerId}&name=${buyerName}&phone=${buyerPhone}`
    history.push(
      `/properties?query=${query}&buyerId=${buyerId}`
    );
  };

  const gotoDashboard = () => {
    history.push("/");
  };

  const scheduleTour = () => {
    // history.push('/newtourpage?buyerId=' + buyerId)
    if (
      userState?.agent?.subscription_type === "Navihome Advanced Plan" ||
      userState?.agent?.subscription_type === "Navihome Premimum Plan" ||
      userState?.agent?.subscription_type === "Navihome Basic Plan"
    ) {
      history.push("/common?buyerId=" + buyerId);
    } else {
      setIsShowingConfirmModal(true);
      return;
    }
  };

  const getRequestedProperties = async () => {
    setIsLoading(true);
    setIsContent(true);
    try {
      const properties = await getRequestedPropertiesForBuyerService(
        Number(param?.get("buyerId")),
        userState.agent.token,
        "No"
      );
      // var listings = []
      // for (var i = 0; i < properties?.length; i++) {
      //   const listing = await getPropertyDetailsService(
      //     properties[i]?.propertyId,
      //   )
      //   if (listing?.listPrice) {
      //     listings.push(listing)
      //   }
      // }
      setRequestedProperties(properties?.length > 0 ? properties : []);
      setIsLoading(false);
      setIsContent(false);
    } catch (error) {
      setIsLoading(false);
      setIsContent(false);
      console.log("ERROR");
    }
  };

  const getFavoriteProperties = async () => {
    setIsLoading(true);
    setIsContent(true);
    try {
      const token = userState.agent.token;
      if (!token) {
        setIsLoading(false);
        setIsContent(false);
        return notification("Unauthorized", "error");
      }
      if (!param?.get("buyerId")) {
        setIsLoading(false);
        setIsContent(false);
        return notification("Buyer Id Not Found!", "error");
      }
      const properties = await getFavoritePropertiesForBuyerService(
        Number(param?.get("buyerId")),
        token,
        "No"
      );
      setFavoriteProperties(properties?.length > 0 ? properties : []);
      setIsLoading(false);
      setIsContent(false);
    } catch (error) {
      setIsLoading(false);
      setIsContent(false);
      console.log("ERROR: ", error);
      return;
    }
  };

  const getViewedRequestedProperties = async () => {
    setIsLoading(true);
    setIsContent(true);
    try {
      if (!param.get("buyerId")) {
        setIsLoading(false);
        setIsContent(false);
        return;
      }

      if (!userState?.agent?.token) {
        setIsLoading(false);
        setIsContent(false);
        return notification("Agent Token Not Found!", "error");
      }

      let propArr: any = [];
      let newArr: any = [];
      let currDate = moment().format("YYYY-MM-DD");

      // it's pull data from viewed property table

      let properties: any = getViewedPropertiesForBuyerService(
        Number(param.get("buyerId")),
        userState.agent.token
      );

      // it's pull data from view Property Calendar table

      let propertyCalc: any = getPreviousViewPropertyCalendarByBuyerID(
        Number(param.get("buyerId")),
        currDate,
        userState?.agent?.token,
        "viewed property"
      );

      // it's pull data from user upcoming tours table

      let routeProp: any = getTourAllAcceptedProp(
        Number(param.get("buyerId")),
        currDate,
        userState.agent.token
      );

      const result = await Promise.all([properties, propertyCalc, routeProp]);

      newArr = result.flat(Infinity);


      newArr?.forEach((item: any) => {
        if (item?.hasOwnProperty("formId")) {
          propArr?.push(...item?.bestRouteProperties);
        } else {
          propArr?.push(item);
        }
      });

      let updatedArr: any = [];
      const newData = Array.from(
        new Set(
          propArr?.map((propId: any) => propId?.propertyId || propId?.mlsId)
        )
      ).map((val: any) => {
        const itemFound = propArr?.find(
          (item: any) => (item?.propertyId || item?.mlsId) === val
        );
        if (itemFound) {
          updatedArr.push(itemFound);
          return itemFound;
        }
      });

      setViewedRequestedProperties(updatedArr?.length > 0 ? updatedArr : []);
      setIsLoading(false);
      setIsContent(false);
    } catch (error) {
      setIsLoading(false);
      setIsContent(false);
      console.log(error);
    }
  };

  const getViewedFavoritesProperties = async () => {
    setIsLoading(true);
    setIsContent(true);
    try {
      if (!param.get("buyerId")) {
        setIsLoading(false);
        setIsContent(false);
        return;
      }

      if (!userState?.agent?.token) {
        setIsLoading(false);
        setIsContent(false);
        return notification("Agent Token Not Found!", "error");
      }

      let propArr: any = [];
      let newArr: any = [];
      let currDate = moment().format("YYYY-MM-DD");

      // it's fetched from viewed Favorite table
      let propFav: any = getViewedFavoritesPropertiesForBuyerService(
        Number(param.get("buyerId")),
        userState?.agent?.token
      );

      // it's fetched for single Properties from viewed property calendar

      let propCalFav = getPreviousViewPropertyCalendarByBuyerID(
        Number(param.get("buyerId")),
        currDate,
        userState?.agent?.token,
        "viewed favorite"
      );

      // it's fetched from routing

      let routePropFav: any = getTourAllAcceptedProp(
        Number(param.get("buyerId")),
        currDate,
        userState?.agent?.token
      );

      const result = await Promise.all([propFav, propCalFav, routePropFav]);

      newArr = result.flat(Infinity);

      newArr?.forEach((item: any) => {
        if (item?.hasOwnProperty("formId")) {
          propArr?.push(...item?.bestRouteProperties);
        } else {
          propArr?.push(item);
        }
      });

      let updatedArr: any = [];
      const newData = Array.from(
        new Set(
          propArr?.map((propId: any) => propId?.propertyId || propId?.mlsId)
        )
      ).map((val: any) => {
        const itemFound = propArr?.find((item: any) => {
          if (item?.bestRouteId)
            return (
              (item?.propertyId || item?.mlsId) === val &&
              item?.hasOwnProperty("isFavorite") &&
              item?.isFavorite
            );
          else return (item?.propertyId || item?.mlsId) === val;
        });
        if (itemFound) {
          updatedArr.push(itemFound);
          return itemFound;
        }
      });

      setIsLoading(false);
      setIsContent(false);
      setViewedFavoriteProperties(updatedArr?.length > 0 ? updatedArr : []);
    } catch (error) {
      setIsLoading(false);
      setIsContent(false);
      console.log(error);

      // return notification('Something Went Wrong!', 'error');
    }
  };

  const getAllPropertyComments = async () => {
    setIsLoading(true);
    setIsContent(true);
    try {
      const token = userState?.agent?.token;
      if (!token) {
        // setIsLoading(false);
        setIsContent(false);
        return notification("unauthorized", "error");
      }
      if (!param.get("buyerId")) {
        setIsLoading(false);
        setIsContent(false);
        return;
      }
      const comments: any =
        await getAllPropertyCommentsForBuyerFromAgentService(
          token,
          Number(param.get("buyerId"))
        );

      const obj: any = {};
      const filteredComments: any = [];
      comments?.map((item: any) => {
        if (obj[item?.propertyId]) {
          obj[item?.propertyId] = [...obj[item?.propertyId], item];
        } else {
          obj[item?.propertyId] = [item];
          filteredComments?.push(item);
        }
      });

      setCommentedProperties(filteredComments);
      setIsLoading(false);
      setIsContent(false);
    } catch (error) {
      setIsLoading(false);
      setIsContent(false);
      console.log("ERROR", error);
    }
  };

  const getSavedSearches = async (paramBuyerId?: number) => {
    setIsLoading(true);
    setIsContent(true);
    try {
      const token = userState?.agent?.token;
      const tempBuyerId = buyerId > 0 ? buyerId : paramBuyerId ?? null;

      if (!token) {
        setIsLoading(false);
        setIsContent(false);
        return notification("unauthorized", "error");
      }
      if (!tempBuyerId) {
        setIsLoading(false);
        setIsContent(false);
        return;
      }
      if (token && tempBuyerId) {
        const searches = await getSavedSearchesService(token, tempBuyerId);
        const searchesFiltered = searches.sort((a: any, b: any) => {
          return +new Date(b?.createdAt) - +new Date(a?.createdAt);
        })
        setIsLoading(false);
        setIsContent(false);
        setSavedSearches(searchesFiltered);
      }
    } catch (error) {
      setIsLoading(false);
      setIsContent(false);
      console.log("ERROR : ", error);
    }
  };

  const deleteSavedSearch = async (savedSearchId: number) => {
    setIsLoading(true);
    try {
      const token = userState.agent.token;
      await deleteSavedSearchService(token, savedSearchId);
      setIsLoading(false);
      getSavedSearches();
    } catch (error) {
      setIsLoading(false);
      console.log("ERROR", error);
    }
  };

  const getNotifications = async (tokenBuyerId: number) => {
    setIsContent(true);
    try {
      const token = userState?.agent?.token;
      if (!token) {
        setIsContent(false);
        return notification("Unauthorized!", "error");
      }
      const notifications: any = await getAgentNotifications(
        token,
        0,
        "",
        tokenBuyerId
      );
      const filteredNotifications: any =
        notifications?.length > 0 &&
        notifications?.filter(
          (item: any) =>
            item.type !== "invite" && item?.buyerId === tokenBuyerId
        );
      setAgentNotifications(filteredNotifications);
      setIsContent(false);
    } catch (error) {
      setIsContent(false);
      console.log(error);
      return notification("Something Went Wrong!", "error");
    }
  };

  const getALLLinkedPropertiesForBuyer = async () => {
    setIsLoading(true);
    setIsContent(true);
    try {
      const token = userState?.agent?.token;
      if (!token) {
        setIsLoading(false);
        setIsContent(false);
        return notification("Unauthorized!", "error");
      }

      if (!param.get("buyerId")) {
        setIsLoading(false);
        setIsContent(false);
        return;
      }

      let buyerId: number = Number(param.get("buyerId")),
        propertyDetails: any = [];

      const scheduled_data: any = await getAllUpcomingScheduledData(
        buyerId,
        token
      );

      if (scheduled_data?.length > 0) {
        scheduled_data.forEach((item: any) => {
          const propertyDetailsTemp = item?.propertyDetails ? JSON.parse(item?.propertyDetails) : {};
          propertyDetailsTemp["tourType"] = "tour-property";
          propertyDetailsTemp["tourId"] = item?.bestRouteId;
          propertyDetails?.push(propertyDetailsTemp);
        });
      }

      const propertyCalc: any = await getViewPropertyCalendarScheduledEvent(
        buyerId,
        token
      );
      if (propertyCalc?.length > 0) {
        propertyCalc.forEach((item: any) => {
          const propertyDetailsTemp = item?.propertyDetails ? JSON.parse(item?.propertyDetails) : {};
          propertyDetailsTemp["tourType"] = "view-property";
          propertyDetailsTemp["tourId"] = item?.viewPropertyCalendarId;
          if (item?.propertyDetails) {
            propertyDetails?.push(propertyDetailsTemp);
          }
        });
      }

      let listings: any = [],
        newArr: any = [];

      propertyDetails?.map((item: any) => {
        const index = newArr?.findIndex(
          (arrObj: any) => arrObj?.mlsId === item?.mlsId
        );
        if (index === -1) {
          newArr?.push(item);
        }
      });

      for (var i = 0; i < newArr?.length; i++) {
        const listing: any = newArr[i];
        if (listing?.listPrice) {
          listings.push(listing);
        }
      }

      setIsLoading(false);
      setIsContent(false);
      setSelectedProperties(listings);
    } catch (err) {
      setIsLoading(false);
      setIsContent(false);
      console.log("ERROR: ", err);
    }
  };

  const selectOverview = () => {
    setCurrentTab(DashboardTab.OVERVIEW);
    getUpcomingTour(buyerId, null);
  };

  const selectRequestedShowings = () => {
    setCurrentTab(DashboardTab.REQUESTED_SHOWINGS);
    getRequestedProperties();
  };

  const selectScheduledShowings = () => {
    setCurrentTab(DashboardTab.SCHEDULED_SHOWINGS);
    getALLLinkedPropertiesForBuyer();
  };

  const selectSavedSearches = () => {
    setCurrentTab(DashboardTab.SAVED_SEARCHES);
    getSavedSearches();
  };

  const selectFavoriteProperties = () => {
    setCurrentTab(DashboardTab.FAVORITE_PROPERTIES);
    getFavoriteProperties();
  };

  const selectPropertyComments = () => {
    setCurrentTab(DashboardTab.PROPERTY_COMMENTS);
    getAllPropertyComments();
  };

  const selectPreviousTours = () => {
    setCurrentTab(DashboardTab.PREVIOUS_TOURS);
    getUpcomingTour(buyerId, "previousTour");
    getPreviousTour(buyerId);
  };

  const selectViewedProperties = () => {
    setCurrentTab(DashboardTab.VIEWED_PROPERTIES);
    getViewedRequestedProperties();
  };

  const selectViewedFavorites = () => {
    setCurrentTab(DashboardTab.VIEWED_FAVORITES);
    getViewedFavoritesProperties();
  };

  const selectNotifications = () => {
    setCurrentTab(DashboardTab.NOTIFICATIONS);
  };

  const onTabChange = (value: string) => {
    switch (value) {
      case "overview":
        selectOverview();
        break;
      case "requestedShowings":
        selectRequestedShowings();
        break;
      case "scheduledShowings":
        selectScheduledShowings();
        break;
      case "savedSearches":
        selectSavedSearches();
        break;
      case "favoriteProperties":
        selectFavoriteProperties();
        break;
      case "propertyComments":
        selectPropertyComments();
        break;
      case "previousTours":
        selectPreviousTours();
        break;
      case "viewedProperties":
        selectViewedProperties();
        break;
      case "viewedFavorites":
        selectViewedFavorites();
        break;
      case "notifications":
        selectNotifications();
        break;
      default:
        return;
    }
  };

  // RENDERERS

  const renderRequestedProperties = () => {
    const cards = requestedProperties?.map((listing) => {
      return (
        <PropertyCard
          propertyId={listing?.propertyId ?? 0}
          image={listing?.imageUrl?.length > 0 ? listing?.imageUrl : ""}
          propertyDetails={listing ? listing : " "}
          price={listing?.listPrice ?? 0}
          type={listing?.propertyType ?? ""}
          beds={listing?.bedrooms ?? 1}
          baths={listing?.bathrooms ?? 1}
          squareFootage={listing?.area ?? 0}
          mlsId={listing?.mlsId ? listing?.mlsId : listing?.listingId}
          latitude={listing?.latitude ?? 0}
          longitude={listing?.longitude ?? 0}
          country={listing?.country ?? "United States"}
          streetNumber={Number(listing?.streetNumber) ?? 0}
          streetName={listing?.streetName ?? "N/A"}
          city={listing?.city ?? "N/A"}
          state={listing?.state ?? "N/A"}
          postalCode={listing?.postalCode ?? "N/A"}
          bathsHalf={listing?.bathsHalf ?? 0}
          openHouse="Listing Property"
          feature=""
          linkedBuyerId={buyerId}
          upcomingBuyerId={buyerId?.toString() ?? ""}
          getRequestedProperties={getRequestedProperties}
          selectFavoriteProperties={selectFavoriteProperties}
          selectRequestedShowings={selectRequestedShowings}
          selectPropertyComments={selectPropertyComments}
          setTourPropPage={() => { }}
          openHouseStartTime={listing?.openHouseStartTime}
          openHouseEndTime={listing?.openHouseEndTime}
          openHouseKey={listing?.openHouseKey}
          openHouseId={listing?.openHouseId}
          openHouseData={listing?.openHouseId}
          linkedBuyerName={buyerName}
          listing={listing?.mlsId ?? ""}
          isBuyerDashboard="requested"
          setIsPropListed={() => { }}
          upcomingBuyerPhone={upcomingBuyerPhone}
          buyerEmail={userState?.userType === UserType?.BUYER ? userState?.buyer?.Email : agentState?.buyerEmail}
        />
      );
    });
    return (
      <>
        {isContent ? (
          <></>
        ) : (
          <>
            {requestedProperties?.length > 0 ? (
              <Fragment>{cards}</Fragment>
            ) : (
              <EmptyDashboardTab title="" />
            )}
          </>
        )}
      </>
    );
  };

  const renderViewedProperty = () => {
    const cards = viewedRequestedProperties?.map((item) => {
      let listing = item?.propertyDetails
        ? JSON?.parse(item?.propertyDetails)
        : {};
      return (
        <PropertyCard
          propertyId={
            listing?.propertyId ? listing?.propertyId : listing?.mlsId
          }
          image={
            listing?.imageUrl?.length > 0
              ? listing?.imageUrl
              : listing?.photos?.[0]
          }
          propertyDetails={listing ? listing : " "}
          price={listing?.listPrice ?? 0}
          type={listing?.propertyType ?? ""}
          beds={
            listing?.bedrooms ? listing?.bedrooms : listing?.property?.bedrooms
          }
          baths={
            listing?.bathrooms
              ? listing?.bathrooms
              : listing?.property?.bathsFull ?? 0
          }
          squareFootage={
            listing?.area ? listing?.area : listing?.property?.area
          }
          mlsId={listing?.mlsId ? listing?.mlsId : listing?.listingId}
          latitude={listing?.latitude ? listing?.latitude : listing?.geo?.lat}
          longitude={
            listing?.longitude ? listing?.longitude : listing?.geo?.lng
          }
          country={listing?.country ? listing?.country : listing?.geo?.county}
          streetNumber={
            Number(listing?.streetNumber)
              ? Number(listing?.streetNumber)
              : Number(listing?.address?.streetNumber) ?? 0
          }
          streetName={
            listing?.streetName
              ? listing?.streetName
              : listing?.address?.streetName ?? "N/A"
          }
          city={listing?.city ? listing?.city : listing?.address?.city ?? "N/A"}
          state={
            listing?.state ? listing?.state : listing?.address?.state ?? "N/A"
          }
          postalCode={
            listing?.postalCode
              ? listing?.postalCode
              : listing?.address?.postalCode ?? "N/A"
          }
          bathsHalf={
            listing?.bathsHalf
              ? listing?.bathsHalf
              : listing?.property?.bathsHalf ?? 0
          }
          feature=""
          linkedBuyerId={buyerId}
          upcomingBuyerId={buyerId?.toString() ?? ""}
          selectFavoriteProperties={selectFavoriteProperties}
          selectRequestedShowings={selectRequestedShowings}
          selectPropertyComments={selectPropertyComments}
          setTourPropPage={() => { }}
          openHouseStartTime={
            listing?.openHouseStartTime ? listing?.openHouseStartTime : ""
          }
          openHouseEndTime={
            listing?.openHouseEndTime ? listing?.openHouseEndTime : ""
          }
          openHouseKey={listing?.openHouseKey ? listing?.openHouseKey : ""}
          openHouseId={
            listing?.openHouseId ? listing?.openHouseId : listing?.openHouseId
          }
          openHouseData={listing?.openHouseId}
          linkedBuyerName={buyerName}
          listing={listing?.mlsId ? listing?.mlsId : listing?.listingId ?? ""}
          openHouse="Listing Property"
          isBuyerDashboard="viewed"
          setIsPropListed={() => { }}
          upcomingBuyerPhone={upcomingBuyerPhone}
          isPropViewed="Yes"
          buyerEmail={userState?.userType === UserType?.BUYER ? userState?.buyer?.Email : agentState?.buyerEmail}
          tourId={item.hasOwnProperty("bestRouteId") ? item.bestRouteId : item.hasOwnProperty("viewedPropertyId") ? item.viewedPropertyId : item.viewPropertyCalendarId}
          tourType={item.hasOwnProperty("bestRouteId") ? "tour-property" : item.hasOwnProperty("viewedPropertyId") ? "viewed-property" : "view-property"}
        />
      );
    });
    return (
      <>
        {isContent ? (
          <></>
        ) : (
          <>
            {viewedRequestedProperties?.length > 0 ? (
              <Fragment>{cards}</Fragment>
            ) : (
              <EmptyDashboardTab title="" />
            )}
          </>
        )}
      </>
    );
  };

  const renderViewedFavorites = () => {
    const cards = viewedFavoriteProperties?.map((item) => {
      let listing = item?.propertyDetails
        ? JSON?.parse(item?.propertyDetails)
        : {};
      return (
        <PropertyCard
          propertyId={
            listing?.propertyId ? listing?.propertyId : listing?.mlsId
          }
          image={
            listing?.imageUrl?.length > 0
              ? listing?.imageUrl
              : listing?.photos?.[0]
          }
          propertyDetails={listing ? listing : " "}
          price={listing?.listPrice ?? 0}
          type={listing?.propertyType ?? ""}
          beds={
            listing?.bedrooms ? listing?.bedrooms : listing?.property?.bedrooms
          }
          baths={
            listing?.bathrooms
              ? listing?.bathrooms
              : listing?.property?.bathsFull ?? 0
          }
          squareFootage={
            listing?.area ? listing?.area : listing?.property?.area
          }
          mlsId={listing?.mlsId ? listing?.mlsId : listing?.listingId}
          latitude={listing?.latitude ? listing?.latitude : listing?.geo?.lat}
          longitude={
            listing?.longitude ? listing?.longitude : listing?.geo?.lng
          }
          country={listing?.country ? listing?.country : listing?.geo?.county}
          streetNumber={
            Number(listing?.streetNumber)
              ? Number(listing?.streetNumber)
              : Number(listing?.address?.streetNumber) ?? 0
          }
          streetName={
            listing?.streetName
              ? listing?.streetName
              : listing?.address?.streetName ?? "N/A"
          }
          city={listing?.city ? listing?.city : listing?.address?.city ?? "N/A"}
          state={
            listing?.state ? listing?.state : listing?.address?.state ?? "N/A"
          }
          postalCode={
            listing?.postalCode
              ? listing?.postalCode
              : listing?.address?.postalCode ?? "N/A"
          }
          bathsHalf={
            listing?.bathsHalf
              ? listing?.bathsHalf
              : listing?.property?.bathsHalf ?? 0
          }
          feature=""
          linkedBuyerId={buyerId}
          upcomingBuyerId={buyerId?.toString() ?? ""}
          selectFavoriteProperties={selectFavoriteProperties}
          selectRequestedShowings={selectRequestedShowings}
          selectPropertyComments={selectPropertyComments}
          setTourPropPage={() => { }}
          openHouseStartTime={
            listing?.openHouseStartTime ? listing?.openHouseStartTime : ""
          }
          openHouseEndTime={
            listing?.openHouseEndTime ? listing?.openHouseEndTime : ""
          }
          openHouseKey={listing?.openHouseKey ? listing?.openHouseKey : ""}
          openHouseId={
            listing?.openHouseId ? listing?.openHouseId : listing?.openHouseId
          }
          openHouseData={listing?.openHouseId}
          linkedBuyerName={buyerName}
          listing={listing?.mlsId ? listing?.mlsId : listing?.listingId ?? ""}
          isBuyerDashboard="viewedFavorite"
          setIsPropListed={() => { }}
          upcomingBuyerPhone={upcomingBuyerPhone}
          isPropViewed="Yes"
          buyerEmail={userState?.userType === UserType?.BUYER ? userState?.buyer?.Email : agentState?.buyerEmail}
          tourId={item.hasOwnProperty("bestRouteId") ? item.bestRouteId : item.hasOwnProperty("viewedFavoritesId") ? item.viewedFavoritesId : item.viewPropertyCalendarId}
          tourType={item.hasOwnProperty("bestRouteId") ? "tour-property" : item.hasOwnProperty("viewedFavoritesId") ? "viewed-property" : "view-property"}
        />
      );
    });
    return (
      <>
        {isContent ? (
          <></>
        ) : (
          <>
            {viewedFavoriteProperties?.length > 0 ? (
              <Fragment>{cards}</Fragment>
            ) : (
              <EmptyDashboardTab title="" />
            )}
          </>
        )}
      </>
    );
  };

  const renderFavoriteProperties = () => {
    const cards = favoriteProperties?.map((listing: any) => {
      return (
        <PropertyCard
          propertyId={listing?.propertyId ?? 0}
          image={listing?.imageUrl?.length > 0 ? listing?.imageUrl : ""}
          propertyDetails={listing ? listing : " "}
          price={listing?.listPrice ?? 0}
          type={listing?.propertyType ?? ""}
          beds={listing?.bedrooms ?? 1}
          baths={listing?.bathrooms ?? 1}
          squareFootage={listing?.area ?? 0}
          mlsId={listing?.mlsId  ? listing?.mlsId: listing?.listingId ?? 0}
          latitude={listing?.longitude ?? 0}
          longitude={listing?.longitude ?? 0}
          country={listing?.country ?? "N/A"}
          streetNumber={Number(listing?.streetNumber) ?? 0}
          streetName={listing?.streetName ?? "N/A"}
          city={listing?.city ?? "N/A"}
          state={listing?.state ?? "N/A"}
          postalCode={listing?.postalCode ?? "N/A"}
          bathsHalf={listing?.bathsHalf ?? 0}
          openHouse="Listing Property"
          feature=""
          linkedBuyerId={buyerId}
          upcomingBuyerId={buyerId?.toString() ?? ""}
          getFavoriteProperties={getFavoriteProperties}
          selectFavoriteProperties={selectFavoriteProperties}
          selectRequestedShowings={selectRequestedShowings}
          selectPropertyComments={selectPropertyComments}
          setTourPropPage={() => { }}
          openHouseStartTime={listing?.openHouseStartTime}
          openHouseEndTime={listing?.openHouseEndTime}
          openHouseKey={listing?.openHouseKey}
          openHouseId={listing?.openHouseId}
          openHouseData={listing?.openHouseId}
          linkedBuyerName={buyerName}
          listing={listing?.mlsId ?? ""}
          isBuyerDashboard="favorite"
          upcomingBuyerPhone={upcomingBuyerPhone}
          buyerEmail={userState?.userType === UserType?.BUYER ? userState?.buyer?.Email : agentState?.buyerEmail}
        />
      );
    });
    return (
      <>
        {isContent ? (
          <></>
        ) : (
          <>
            {favoriteProperties?.length > 0 ? (
              <Fragment>{cards}</Fragment>
            ) : (
              <EmptyDashboardTab title="" />
            )}
          </>
        )}
      </>
    );
  };

  const renderSavedSearches = () => {
    const cards = savedSearches?.map((search) => {
      return (
        <SavedSearchCard
          data={search ?? null}
          deleteSavedSearch={deleteSavedSearch}
          searchedId={search?.savedSearchId ?? null}
          buyerId={buyerId ?? null}
        />
      );
    });
    return (
      <>
        {isContent ? (
          <></>
        ) : (
          <>
            {savedSearches?.length > 0 ? (
              <Fragment>{cards}</Fragment>
            ) : (
              <EmptyDashboardTab title="" />
            )}
          </>
        )}
      </>
    );
  };

  const renderScheduledShowings = () => {
    const cards = selectedProperties?.map((listing) => {
      return (
        <PropertyCard
          propertyId={listing?.mlsId ?? 0}
          image={listing?.photos?.length > 0 ? listing?.photos[0] : ""}
          propertyDetails={listing ? listing : " "}
          price={listing?.listPrice}
          type={listing?.property?.type}
          beds={listing?.property?.bedrooms}
          baths={listing?.property?.bathsFull}
          squareFootage={listing?.property?.area}
          mlsId={listing.listingId}
          latitude={listing?.geo?.lat ?? 0}
          longitude={listing?.geo?.lng ?? 0}
          country={listing?.address?.country ?? "N/A"}
          streetNumber={Number(listing?.address?.streetNumber) ?? 0}
          streetName={listing?.address?.streetName ?? "N/A"}
          city={listing?.address?.city ?? "N/A"}
          state={listing?.address?.state ?? "N/A"}
          postalCode={listing?.address?.postalCode ?? "N/A"}
          bathsHalf={listing?.property?.bathsHalf ?? 0}
          openHouse="new_listing"
          feature=""
          linkedBuyerId={buyerId}
          upcomingBuyerId={buyerId?.toString() ?? ""}
          selectFavoriteProperties={selectFavoriteProperties}
          selectRequestedShowings={selectRequestedShowings}
          selectPropertyComments={selectPropertyComments}
          setTourPropPage={() => { }}
          openHouseStartTime={listing?.openHouseStartTime}
          openHouseEndTime={listing?.openHouseEndTime}
          openHouseKey={listing?.openHouseKey}
          openHouseId={listing?.openHouseId}
          openHouseData={listing?.openHouseId}
          linkedBuyerName={buyerName}
          isBuyerDashboard="scheduled"
          setIsPropListed={() => { }}
          upcomingBuyerPhone={upcomingBuyerPhone}
          buyerEmail={userState?.userType === UserType?.BUYER ? userState?.buyer?.Email : agentState?.buyerEmail}
          tourType={listing?.tourType ?? ""}
          tourId={listing?.tourId ?? ""}
        />
      );
    });
    return (
      <>
        {isContent ? (
          <></>
        ) : (
          <>
            {selectedProperties?.length > 0 ? (
              <Fragment>{cards}</Fragment>
            ) : (
              <EmptyDashboardTab title="" />
            )}
          </>
        )}
      </>
    );
  };

  const renderPropertyComments = () => {
    const cards = commentedProperties?.map((listing: any, index: any) => {
      return (
        <PropertyCard
          propertyId={listing?.propertyId ?? 0}
          image={listing?.imageUrl?.length > 0 ? listing?.imageUrl : ""}
          propertyDetails={listing ? listing : " "}
          price={listing?.listPrice ?? 0}
          type={listing?.propertyType ?? ""}
          beds={listing?.bedrooms ?? 0}
          baths={listing?.bathrooms ?? 0}
          squareFootage={listing?.area ?? 0}
          mlsId={listing?.mlsId ?? 0}
          latitude={listing?.latitude ?? 0}
          longitude={listing?.longitude ?? 0}
          country={listing?.country ?? "N/A"}
          streetNumber={Number(listing?.streetNumber) ?? 0}
          streetName={listing?.streetName ?? "N/A"}
          city={listing?.city ?? "N/A"}
          state={listing?.state ?? "N/A"}
          postalCode={listing?.postalCode ?? "N/A"}
          bathsHalf={listing?.bathsHalf ?? 0}
          openHouse="Listing Property"
          feature=""
          linkedBuyerId={buyerId}
          upcomingBuyerId={buyerId?.toString() ?? ""}
          selectFavoriteProperties={selectFavoriteProperties}
          selectRequestedShowings={selectRequestedShowings}
          selectPropertyComments={selectPropertyComments}
          setTourPropPage={() => { }}
          linkedBuyerName={buyerName}
          openHouseStartTime={listing?.openHouseStartTime}
          openHouseEndTime={listing?.openHouseEndTime}
          openHouseKey={listing?.openHouseKey}
          openHouseId={listing?.openHouseId}
          openHouseData={listing?.openHouseId}
          listing={listing?.mlsId ?? ""}
          isBuyerDashboard="comments"
          setIsPropListed={() => { }}
          upcomingBuyerPhone={upcomingBuyerPhone}
          buyerEmail={userState?.userType === UserType?.BUYER ? userState?.buyer?.Email : agentState?.buyerEmail}
        />
      );
    });
    return (
      <>
        {isContent ? (
          <></>
        ) : (
          <>
            {commentedProperties?.length > 0 ? (
              <Fragment>{cards}</Fragment>
            ) : (
              <EmptyDashboardTab title="" />
            )}
          </>
        )}
      </>
    );
  };

  const renderTitle = () => {
    var pageTitle = "";
    switch (currentTab) {
      case DashboardTab.OVERVIEW:
        pageTitle = "Overview";
        break;
      case DashboardTab.REQUESTED_SHOWINGS:
        pageTitle = "Requested Showings";
        break;
      case DashboardTab.SCHEDULED_SHOWINGS:
        pageTitle = "Scheduled Showings";
        break;
      case DashboardTab.SAVED_SEARCHES:
        pageTitle = "Saved Searches";
        break;
      case DashboardTab.FAVORITE_PROPERTIES:
        pageTitle = "Favorite Properties";
        break;
      case DashboardTab.PROPERTY_COMMENTS:
        pageTitle = "Property Comments";
        break;
      case DashboardTab.PREVIOUS_TOURS:
        pageTitle = "Previous Tours";
        break;
      case DashboardTab.VIEWED_PROPERTIES:
        pageTitle = "Viewed Properties";
        break;
      case DashboardTab.VIEWED_FAVORITES:
        pageTitle = "Viewed Favorites";
        break;
      case DashboardTab.NOTIFICATIONS:
        pageTitle = "Notifications";
        break;
      default:
        break;
    }
    return (
      <div className="dashboard-agent-page_main_div">
        <CustomTitleContainer>
          <div>
            <CustomDashboardTitle>
              {currentTab === DashboardTab.OVERVIEW && `${buyerName} -`}{" "}
              {pageTitle}
            </CustomDashboardTitle>
            {/* <h3 className="dashboard-agent-page__title">{`${agentState.buyerName} - ${pageTitle}`}</h3> */}
          </div>
          <CustomTitleContainerRight>
            {currentTab === DashboardTab.REQUESTED_SHOWINGS && (
              <Button
                type={ButtonType.PRIMARY}
                text={"SCHEDULE TOUR"}
                clickHandler={scheduleTour}
              />
            )}
            {currentTab === DashboardTab.OVERVIEW && (
              <BuyerDetails
                name={buyerName}
                email={buyerEmail}
                phone={buyerPhone}
                imageUrl={buyerImageUrl}
              />
            )}
          </CustomTitleContainerRight>
        </CustomTitleContainer>
      </div>
    );
  };

  const renderTabs = () => {
    return (
      <div className="dashboard-agent-page__tabs">
        {isMD ? (
          <>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 24 }}
              layout="horizontal"
              initialValues={{ size: componentSize }}
              onValuesChange={onFormLayoutChange}
              size={componentSize as SizeType}>
              <Form.Item>
                <Select onChange={onTabChange} value={currentTab}>
                  <Option value="overview">Overview</Option>
                  <Option value="requestedShowings">Requested Showings</Option>
                  <Option value="scheduledShowings">Scheduled Showings</Option>
                  <Option value="savedSearches">Saved Searches</Option>
                  <Option value="favoriteProperties">
                    Favorite Properties
                  </Option>
                  <Option value="propertyComments">Property Comments</Option>
                  <Option value="previousTours" onClick={selectPreviousTours}>
                    Previous Tours
                  </Option>

                  <Option value="viewedProperties">Viewed Properties</Option>
                  <Option value="viewedFavorites">Viewed Favorites</Option>
                  <Option value="notifications">Notifications</Option>
                </Select>
              </Form.Item>
            </Form>
          </>
        ) : (
          <>
            <div
              className={`dashboard-agent-page__tab ${currentTab === DashboardTab.OVERVIEW &&
                "dashboard-agent-page__tab--selected"
                }`}
              onClick={selectOverview}>
              <p>Overview</p>
            </div>
            <div
              className={`dashboard-agent-page__tab ${currentTab === DashboardTab.REQUESTED_SHOWINGS &&
                "dashboard-agent-page__tab--selected"
                }`}
              onClick={selectRequestedShowings}>
              <p>Requested Showings</p>
            </div>
            <div
              className={`dashboard-agent-page__tab ${currentTab === DashboardTab.SCHEDULED_SHOWINGS &&
                "dashboard-agent-page__tab--selected"
                }`}
              onClick={selectScheduledShowings}>
              <p>Scheduled Showings</p>
            </div>
            <div
              className={`dashboard-agent-page__tab ${currentTab === DashboardTab.SAVED_SEARCHES &&
                "dashboard-agent-page__tab--selected"
                }`}
              onClick={selectSavedSearches}>
              <p>Saved Searches</p>
            </div>
            <div
              className={`dashboard-agent-page__tab ${currentTab === DashboardTab.FAVORITE_PROPERTIES &&
                "dashboard-agent-page__tab--selected"
                }`}
              onClick={selectFavoriteProperties}>
              <p>Favorite Properties</p>
            </div>
            <div
              className={`dashboard-agent-page__tab ${currentTab === DashboardTab.PROPERTY_COMMENTS &&
                "dashboard-agent-page__tab--selected"
                }`}
              onClick={selectPropertyComments}>
              <p>Property Comments</p>
            </div>
            <div
              className={`dashboard-agent-page__tab ${currentTab === DashboardTab.PREVIOUS_TOURS &&
                "dashboard-agent-page__tab--selected"
                }`}
              onClick={selectPreviousTours}>
              <p>Previous Tours</p>
            </div>
            <div
              className={`dashboard-agent-page__tab ${currentTab === DashboardTab.VIEWED_PROPERTIES &&
                "dashboard-agent-page__tab--selected"
                }`}
              onClick={selectViewedProperties}>
              <p>Viewed Properties</p>
            </div>
            <div
              className={`dashboard-agent-page__tab ${currentTab === DashboardTab.VIEWED_FAVORITES &&
                "dashboard-agent-page__tab--selected"
                }`}
              onClick={selectViewedFavorites}>
              <p>Viewed Favorites</p>
            </div>
            <div
              className={`dashboard-agent-page__tab ${currentTab === DashboardTab.NOTIFICATIONS &&
                "dashboard-agent-page__tab--selected"
                }`}
              onClick={selectNotifications}>
              <p>Notifications</p>
            </div>
          </>
        )}
      </div>
    );
  };

  const renderOverviewContent = () => {
    return (
      <div className="dashboard-agent-page__overview">
        <div className="dashboard-agent-page__overview__cover">
          <div className="dashboard-agent-page__overview__cover-content">
            <h1>
              Find {agentState?.buyerName} their
              <br />
              DREAM HOME
            </h1>
            <SearchField
              placeholder="Enter neighbourhood, city, ZIP code"
              searchAction={searchAction}
            />
          </div>
        </div>
        <div className="dashboard-agent-page__overview__calendar-container">
          <h3> {agentState?.buyerName?.split(" ")?.[0]} Calendar</h3>
          <div className="dashboard-agent-page__overview__calendar-card">
            <MiniCalendar
              setUpcomingTourDate={setUpcomingTourDate}
              setUpcomingStartEndDate={setUpcomingStartEndDate}
              month={null}
            />
          </div>
        </div>
        <div className="dashboard-agent-page__overview__upcoming-tours">
          <h3>Upcoming Tours</h3>
          <ToursSummaryBuyer
            tours={upcomingTourDetail}
            isAgentSide={true}
            upcomingTourDate={upcomingTourDate}
            upcomingStartEndDate={upcomingStartEndDate}
          />
          <div className="dashboard-agent-page__button">
            <Button
              type={ButtonType.PRIMARY}
              text={"SCHEDULE TOUR"}
              clickHandler={scheduleTour}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderRequestedShowingsContent = () => {
    return (
      <>
        <div
          className={
            requestedProperties?.length > 0 &&
            "dashboard-agent-page__requested-showings"
          }>
          {renderRequestedProperties()}
        </div>
      </>
    );
  };

  const renderScheduledShowingsContent = () => {
    return (
      <div
        className={
          selectedProperties?.length > 0 &&
          "dashboard-agent-page__requested-showings"
        }>
        {renderScheduledShowings()}
      </div>
    );
  };

  const renderSavedSearchesContent = () => {
    return (
      <div className="dashboard-agent-page__saved-searches">
        {renderSavedSearches()}
      </div>
    );
  };

  const renderFavoritePropertiesContent = () => {
    return (
      <div
        className={
          favoriteProperties?.length > 0 &&
          "dashboard-agent-page__favorite-properties"
        }>
        {renderFavoriteProperties()}
      </div>
    );
  };

  const renderPropertyCommentsContent = () => {
    return (
      <div
        className={
          commentedProperties?.length > 0 &&
          "dashboard-agent-page__property-comments"
        }>
        {renderPropertyComments()}
      </div>
    );
  };

  const renderPreviousToursContent = () => {
    return (
      <>
        <Row>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <CustomDiv>
              <MiniCalendar
                setUpcomingTourDate={setUpcomingTourDate}
                setUpcomingStartEndDate={setUpcomingStartEndDate}
                month={null}
              />
              <CustomPara>
                Scrolling through months on the calendar will display details of
                the tours completed in that month.
              </CustomPara>
            </CustomDiv>
          </Col>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <div className="dashboard-buyer-page__overview__upcoming-tours-table">
              <PreviousSummaryBuyer
                tours={prevTourDetail}
                isAgentSide={true}
                upcomingTourDate={upcomingTourDate}
                source="previousTour"
                upcomingStartEndDate={upcomingStartEndDate}
              />
            </div>
          </Col>
        </Row>
      </>
    );
  };

  const renderViewedPropertiesContent = () => {
    return (
      <div
        className={
          viewedRequestedProperties?.length > 0 &&
          "dashboard-agent-page__requested-showings"
        }>
        {renderViewedProperty()}
      </div>
    );
  };

  const renderViewedFavoritesContent = () => {
    return (
      <div
        className={
          viewedFavoriteProperties?.length > 0 &&
          "dashboard-agent-page__requested-showings"
        }>
        {renderViewedFavorites()}
      </div>
    );
  };

  const renderNotificationsContent = () => {
    return (
      <div className="dashboard-agent-page__notifications">
        {agentNotifications?.length > 0 ? (
          <div>
            {agentNotifications
              .sort((a: any, b: any) => {
                return +new Date(b?.createdAt) - +new Date(a?.createdAt);
              })
              .map((item: any) => {
                return (
                  <NotificationsAboutAgentCard
                    buyerId={item?.buyerId}
                    notificationId={item?.id}
                    borderColor={"black"}
                    notification={item?.message}
                    time={timeAgo?.format(new Date(item?.createdAt))}
                    isAcceptAndRejectButton={item?.type === "invite"}
                    isNotificationButton={item?.isNotificationButton}
                    borderButtomColor={item?.borderButtonColor}
                    // didAccept={didAccept}
                    // didReject={didReject}
                    isFavorite={
                      item?.type === "Favorite" ||
                      item?.type === "favorite" ||
                      item?.type === "liked"
                    }
                    isRequested={item?.type === "requested"}
                    isComment={item?.type === "Comment"}
                    isRead={item?.isRead}
                    selectFavoriteProperties={selectFavoriteProperties}
                    selectRequestedShowings={selectRequestedShowings}
                    selectPropertyComments={selectPropertyComments}
                    propertyId={item?.propertyId}
                  />
                );
              })}
          </div>
        ) : (
          <EmptyDashboardTab title="No Notifications" />
        )}
      </div>
    );
  };

  const renderTabContent = () => {
    switch (currentTab) {
      case DashboardTab.OVERVIEW:
        return renderOverviewContent();
      case DashboardTab.REQUESTED_SHOWINGS:
        return renderRequestedShowingsContent();
      case DashboardTab.SCHEDULED_SHOWINGS:
        return renderScheduledShowingsContent();
      case DashboardTab.SAVED_SEARCHES:
        return renderSavedSearchesContent();
      case DashboardTab.FAVORITE_PROPERTIES:
        return renderFavoritePropertiesContent();
      case DashboardTab.PROPERTY_COMMENTS:
        return renderPropertyCommentsContent();
      case DashboardTab.PREVIOUS_TOURS:
        return renderPreviousToursContent();
      case DashboardTab.VIEWED_PROPERTIES:
        return renderViewedPropertiesContent();
      case DashboardTab.VIEWED_FAVORITES:
        return renderViewedFavoritesContent();
      case DashboardTab.NOTIFICATIONS:
        return renderNotificationsContent();
      default:
        return "";
    }
  };

  return (
    <div className="dashboard-agent-page">
      {activatedPlan.includes(userState?.agent?.subscription_type) && (
        <UserHeader />
      )}
      <div className="dashboard-agent-page_back-button">
        <CustomBackPara onClick={gotoDashboard}>Back</CustomBackPara>
      </div>
      <div className="dashboard-agent-page_title">
        {renderTitle()}
        <div className="dashboard-agent-page__container">
          {renderTabs()}
          <div className="dashboard-agent-page__current-tab-content">
            {isLoading && <CustomSpinner />}
            {renderTabContent()}
          </div>
        </div>
      </div>

      {isShowingConfirmModal && (
        <FindAnAgentConfirmModal
          isShowing={isShowingConfirmModal}
          closeAction={() => {
            setIsShowingConfirmModal(!isShowingConfirmModal);
          }}
          noText="Close"
          text="Choose a plan that meets your business needs!"
          yesText="Upgrade"
          pageType="DashboardAgentPage"
        />
      )}
    </div>
  );
};

export default DashboardAgentPage;
