import iconConnected from "../../assets/images/icon-connected.svg";

export interface PropertyCardProps {
  propertyId: number;
  image: string;
  price: number;
  type: string;
  beds: number;
  baths: number;
  squareFootage: number;
  address1: string;
  address2: string;
}

const PropertyCard: React.FC<PropertyCardProps> = ({
  image,
  price,
  type,
  beds,
  baths,
  squareFootage,
  address1,
  address2,
}) => {
  return (
    <div className="homepage-agent-page-listing__listed-container">
      <div className="property-card">
        <img className="property-card__image" src={image} alt="Property" />
        <div className="property-card__info">
          <div className="property-card__price">
            <p>
              {price?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 3,
              }) ?? ""}
            </p>
            <img src={iconConnected} alt="Connected" />
          </div>
          <p className="property-card__type">{type}</p>
          <p className="property-card__beds">{beds} beds</p>
          <p className="property-card__baths">{baths} baths</p>
          <p className="property-card__sqft">
            {squareFootage?.toLocaleString("en-US", {
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 3,
              })} sqft
          </p>
          <p className="property-card__address">
            {address1}
            <br />
            {address2}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;
