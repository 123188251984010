import moment from "moment";
import {
  publishTheCalenderEvent
} from "../components/Account/GoogleService";
import { syncBuyerEvent } from "../services/NavihomeService";

export interface ICalendarEventProps {
  calendarEvent?: {
    summary: string;
    location: string;
    start?: {
      dateTime: Date;
      timeZone: string;
    };
    end?: {
      dateTime: Date;
      timeZone: string;
    };
  };
}

export const insertEventToGoogleCalendar = ({
  calendarEvent,
}: ICalendarEventProps) => {
  const { summary, location, start, end } = calendarEvent;

  const event = {
    summary,
    location,
    description: "Navihome scheduled sync event",
    start,
    end,
    reminders: {
      useDefault: false,
      overrides: [
        { method: "email", minutes: 24 * 60 },
        { method: "popup", minutes: 10 },
      ],
    },
  };
  if (event) {
    publishTheCalenderEvent(event);
  }
};

export const getNotSyncedEventstoGoogleSync = async (events: any) => {
  // let email = await getSignedInUserEmail();
  let email: any = null;
  const toSycnedEvent = events?.filter(
    (item: any) =>
      item?.role === "Agent" && item?.buyerGoogleEventSynced === false
  );
  toSycnedEvent?.map(async(event: any) => {

    if (email) {
      await syncBuyerEvent(event?.bestRouteId, true);
      const startDateTime = `${moment(event?.Sdate).format("YYYY-MM-DD")} ${
        event?.Stime
      }`;
      const endDateTime = `${moment(event?.Edate).format("YYYY-MM-DD")} ${
        event?.Etime
        }`;
      const calendarEvent: any = {
        summary: "View Property",
        location: `${event?.streetNumber} ${event?.streetName}, ${event?.city}, ${event?.state}, ${event?.postalCode}`,
        start: {
          dateTime: moment(startDateTime),
          timeZone: "America/Los_Angeles",
        },
        end: {
          dateTime: moment(endDateTime),
          timeZone: "America/Los_Angeles",
        },
      };
      insertEventToGoogleCalendar({ calendarEvent });
    }
  });
};