import styled from "@emotion/styled";
import { Col, Modal, Row } from "antd";
import moment from "moment";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Button, { ButtonType } from "../components/Button";
import CustomSpinner from "../components/spinner/CustomSpinner";
import PropertyFiltersContext from "../contexts/PropertyFiltersContext";
import UserContext, { UserType } from "../contexts/UserContext";
import {
  createBuyerNotificationById,
  deleteScheduledToursService,
  inviteAgentByBuyer,
  postMessageToUser,
  unlinkBuyerFromAgentService,
  unlinkSellerFromAgentService,
  updateSellerDetails,
  removePropertyFromFavoritesForBuyerService,
  removePropertyFromRequestedForBuyerService,
  removePropertyFromViewedForBuyerService,
  removePropertyFromViewedFavoritesForBuyerService,
  deletePropertyCalendarByID,
  deleteScheduledPropertyByID
} from "../services/NavihomeService";
import InviteAgentModal from "./InviteAgentModal";
interface RoutingTourModalProps {
  isShowing: boolean;
  text: string;
  yesText: string;
  noText: string;
  agentId?: number;
  type?: string;
  propertyId?: number;
  closeAction: () => void;
  pageType: string
  formId?: number;
  token?: string;
  listingAgentName?: string;
  buyerAgentEmail?: string;
  handleDiscardAndExit?: () => void;
  linkedPropertiesData?: Array<any>;
  buyerId?: string | number;
  dateTime?: any;
  formTime?: any;
  formData?: any;
  buyerDetails?: any;
  yesClickHandler?: () => void;
  tab?: string;
  id?: string;
  tourId?: string;
}

const CustomCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
`;
const FindAnAgentConfirmModal: React.FC<RoutingTourModalProps> = ({
  text,
  type,
  noText,
  yesText,
  agentId,
  isShowing,
  propertyId,
  closeAction,
  formId,
  token,
  listingAgentName,
  buyerAgentEmail,
  handleDiscardAndExit,
  formData,
  tab,
  yesClickHandler,
  linkedPropertiesData,
  buyerId,
  buyerDetails,
  tourId,
  pageType
}) => {
  const [isModalVisible, setIsModalVisible] = useState(isShowing);
  const [isLoading, setIsLoading] = useState(false);
  const { setMessageSocketCaller, setNotificationsCount, setCreateNotificationsInfo, propertyFiltersState } = useContext(PropertyFiltersContext);
  const { tourType, singleTourId, scheduledTourId } = propertyFiltersState;
  const [isShowingInviteAnAgentModal, setIsShowingInviteAnAgentModal] = useState(false);

  const { userState, logIn } = useContext(UserContext);
  const history = useHistory();

  const handleCancel = () => {
    closeAction();
    setIsModalVisible(false);
  };

  let sendNotificationToAgent = async (agentId: number, type: string) => {
    setIsLoading(true);
    try {
      if (type === "removed") {
        await inviteAgentByBuyer(agentId, type, `${userState?.buyer?.name} has removed you`, 0, "", "", userState?.buyer?.buyerId);
      } else if(type.toLowerCase() === 'rejected'){
        await inviteAgentByBuyer(agentId, type, `Your Agent ${userState?.buyer?.name} ${type} your invitation`, 0, "", "", userState?.buyer?.buyerId);
      }else{
        await inviteAgentByBuyer(agentId, type, `Your Agent ${userState?.buyer?.name} ${type} your invitation`, 0, "", "", userState?.buyer?.buyerId);
      }

      // const filteredNotifications =
      //   notifications.length > 0 &&
      //   notifications.filter((item: any) => !item.isRead && !item.isRejected);
      // setAgentNotifications(filteredNotifications);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  let disconnectAgent = async () => {
    setIsLoading(true);
    try {
      if (type === "seller") {
        let payload = {
          name: "",
          sellerEmail: "",
          message: ""
        };
        await unlinkSellerFromAgentService(userState?.buyer?.token, agentId);
        await updateSellerDetails(propertyId, payload, userState?.buyer?.token);
      } else {
        await unlinkBuyerFromAgentService(userState?.buyer?.token, agentId);
      }
      setMessageSocketCaller(String(Math.floor(Math.random() * 100 + 1)));
      setCreateNotificationsInfo(String(Math.floor(Math.random() * 100 + 1)));
      setNotificationsCount(agentId);
      sendNotificationToAgent(agentId, "removed");
      logIn(userState.buyer.Email, userState?.buyer?.Password);
      history.push("/buy");
      setIsLoading(false);
    } catch (error) {
      console.log("ERROR", error);
      setIsLoading(false);
    }
  };

  const deleteNotFoundProperty = async () => {
    switch (tab) {
      case "requested":
        await removePropertyFromRequestedForBuyerService(
          propertyId,
          userState.userType === UserType.AGENT ? Number(buyerId) : userState.buyer.buyerId,
          userState.userType === UserType.AGENT ? userState.agent.token : userState.buyer.token
        );
        break;
      case "favorite":
        await removePropertyFromFavoritesForBuyerService(
          propertyId,
          userState.userType === UserType.AGENT ? Number(buyerId) : userState.buyer.buyerId,
          userState.userType === UserType.AGENT ? userState.agent.token : userState.buyer.token
        );
        break;
      case "viewed":
        if (tourType === "tour-property") {
          await deleteScheduledPropertyByID(tourId || scheduledTourId);
        } else if (tourType === "view-property") {
          await deletePropertyCalendarByID(tourId.length > 0 ? tourId : singleTourId, "", "message time");
        } else {
          await removePropertyFromViewedForBuyerService(propertyId, userState.userType === UserType.AGENT ? buyerId : userState.buyer.buyerId);
        }
        break;
      case "viewedFavorite":
        if (tourType === "tour-property") {
          await deleteScheduledPropertyByID(tourId.length > 0 ? tourId : scheduledTourId);
        } else if (tourType === "view-property") {
          await deletePropertyCalendarByID(tourId.length > 0 ? tourId : singleTourId, "", "message time");
        } else {
          await removePropertyFromViewedFavoritesForBuyerService(propertyId, userState.userType === UserType.AGENT ? buyerId : userState.buyer.buyerId);
        }
        break;
      case "scheduled": case "previousTour":
        if (tourType === "tour-property") {
          await deleteScheduledPropertyByID(tourId);
        } else {
          await deletePropertyCalendarByID(tourId.length > 0 ? tourId : singleTourId, "", "message time");
        }
        break;
      default:
        handleCancel();
        yesClickHandler();
        break;
    }
    handleCancel();
    yesClickHandler();
  }

  const handleFindAnAgent = async () => {
    switch (pageType) {
      case "FindAnAgent":
        handleCancel();
        history.push("/find-agent?type=buyer")
        break;
      case "RouteTourHeader":
        handleOk();
        break;
      case "AboutAgent":
        disconnectAgent();
        break;
      case "Listing Property Dashboard":
        yesClickHandler();
        break;
      case "SellBuyerPage":
        yesClickHandler();
        handleCancel();
        break;
      case "DashboardBuyerPage":
        disconnectAgent();
        break;
      case "DashboardAgentPage":
        handleCancel();
        history.push("/pricing");
        break;
      case "PricingPage":
        yesClickHandler();
        handleCancel();
        break;
      case "PropertyDetailsPage":
        deleteNotFoundProperty();
        break;
      default:
        handleCancel()
        break;
    }
  };

  const handleOk = async () => {
    setIsLoading(true);

    try {
      console.log("Cancel tour");
      
      const res: any = await deleteScheduledToursService(token, formId, listingAgentName, buyerAgentEmail, type);

      //For Agent Itself
      if (userState?.userType === UserType?.AGENT) {
        await inviteAgentByBuyer(
          userState?.agent?.agentId,
          "rejected",
          `${buyerDetails?.name.toUpperCase()} tour that was scheduled on ${moment(formData?.dateTime).format("dddd")}, ${moment(formData?.dateTime)?.format("MMMM Do, yyyy")} at ${formData?.dateTime.split(",")?.[1]
          } has been cancelled`,
          linkedPropertiesData?.[0]?.mlsId,
          userState?.agent?.name,
          "",
          buyerId
        );
        if (userState?.agent?.preferredContact === "CONTACT_RADIO_BOTH" || userState?.agent?.preferredContact === "CONTACT_RADIO_PHONE") {
          postMessageToUser(
            userState?.agent?.phone,
            `${buyerDetails?.name.toUpperCase()} tour that was scheduled on ${moment(formData?.dateTime).format("dddd")}, ${moment(formData?.dateTime)?.format("MMMM Do, yyyy")} has been cancelled`
          );
        }

        //For Buyer
        await createBuyerNotificationById(
          Number(buyerId),
          `Your Agent ${userState?.agent?.name.toUpperCase()} has cancelled your tour that was scheduled on ${moment(formData?.dateTime).format("dddd")}, ${moment(formData?.dateTime)?.format(
            "MMMM Do, yyyy"
          )} at ${formData?.dateTime.split(",")?.[1]}.`,
          "removed",
          token,
          "buyer",
          0
        );
        await postMessageToUser(
          buyerDetails?.phone,
          `${userState?.agent?.name.toUpperCase()} has cancelled your tour that was scheduled on ${moment(formData?.dateTime).format("dddd")}, ${moment(formData?.dateTime)?.format("MMMM Do, yyyy")}`
        );
      } else {
        // For Buyer Itself
        await createBuyerNotificationById(
          userState?.buyer?.buyerAgentId,
          `Your OH showings for ${moment(formData?.dateTime).format("dddd")}, ${moment(formData?.dateTime)?.format("MMMM Do, yyyy")} at ${formData?.dateTime.split(",")?.[1]} has been cancelled.`,
          "removed",
          userState?.buyer?.token,
          "buyer",
          0
        );
        await postMessageToUser(
          userState?.buyer?.phone,
          `Your OH showings for ${moment(formData?.dateTime).format("dddd")}, ${moment(formData?.dateTime)?.format("MMMM Do, yyyy")} has been cancelled.`
        );
      }

      setMessageSocketCaller(String(Math.floor(Math.random() * 100 + 1)));
      setNotificationsCount(Number(buyerId));
      closeAction();
      setIsLoading(false);
      handleDiscardAndExit();
    } catch (error) { }
    setIsModalVisible(false);
    setIsLoading(false);
  };

  const closeInviteAnAgentModalHandler = () => {
    closeAction();
    setIsModalVisible(!isModalVisible);
    setIsShowingInviteAnAgentModal(!isShowingInviteAnAgentModal);
  };

  const openInviteAnAgentModalHandler = () => {
    if (noText.toLocaleLowerCase() === "no" || noText.toLocaleLowerCase() === "close") {
      closeAction();
      setIsModalVisible(false);
    } else if (noText.toLocaleUpperCase() === "INVITE YOUR AGENT") {
      setIsShowingInviteAnAgentModal(!isShowingInviteAnAgentModal);
    } else {
      closeAction();
      setIsModalVisible(false);
    }
  };

  return (
    <Modal visible={isModalVisible} footer={null} width={680} centered={true} title={text} onCancel={handleCancel} closable={false}>
      {isLoading && <CustomSpinner />}
      <Row>
        <CustomCol xs={24}>
          <Button type={ButtonType.WHITE} text={noText} clickHandler={openInviteAnAgentModalHandler} className="find-an-agent-modal-confirm-invite-your-agent-button" />
          <Button type={ButtonType.PRIMARY} text={yesText} className="find-an-agent-modal-confirm-find-an-agent-button" clickHandler={handleFindAnAgent} />
        </CustomCol>
      </Row>
      {isShowingInviteAnAgentModal && <InviteAgentModal isShowing={isShowingInviteAnAgentModal} closeAction={closeInviteAnAgentModalHandler} inviteYourAgent={true} />}
    </Modal>
  );
};
export default FindAnAgentConfirmModal;
