import { Col, Row } from "antd";
import React, { useContext, useState } from "react";
import UserContext, { UserType } from "../../../contexts/UserContext";
import {
  createBuyerNotificationById,
  deletePropertyCalendarByID,
  deleteScheduledPropertyByID,
  getAgentAccountSettings,
  getCronofyAccessToken,
  insertCronofyCalendarEvents,
  inviteAgentByBuyer,
  postMessageToUser,
  updateAgentScheduledStatusService,
  updateAgentStatusService,
} from "../../../services/NavihomeService";
import Button, { ButtonType } from "../../Button";
import "./styles/calendarPopover.css";

import moment from "moment";
import Location from "../../../assets/find-an-agent-page/Icon - Location.svg";
import User from "../../../assets/find-an-agent-page/Icon - User.svg";
import Path from "../../../assets/find-an-agent-page/Icon link.svg";
import { insertEventToGoogleCalendar } from "../../../utils/syncEvent";
import CustomSpinner from "../../spinner/CustomSpinner";

interface ICalendarSchedulePopoverProps {
  extendedProps?: {
    name?: string;
    phone?: string;
    address?: string;
    pid?: number;
    url?: string;
    file?: string;
    agentName?: string;
    fullName?: string;
    accept?: boolean;
    initialStatus?: boolean;
    isRejected?: boolean;
    isAccepted?: boolean;
    viewPropertyCalendarId?: boolean;
  };
  timeText?: string;
  dateText?: any;
  backgroundColor?: any;
  id?: string;
  token?: string;
  loadBuyers?: () => void;
  setModalData?: (
    open: boolean,
    token: string,
    buyerId: string,
    propertyId: number
  ) => void;
  arg: any;
  getEventDetails?: () => void;
  setIsRescheduleRequestEvent?: (value: boolean) => void;
  argRecieved?: (value: any) => void;
  propMlsId?: any;
  eventType?: string;
  calendarType?: string;
  setEventFilter?: (value: Array<any>) => void;
}
const CalendarSchedulePopover: React.FunctionComponent<
  ICalendarSchedulePopoverProps
> = ({
  backgroundColor,
  getEventDetails,
  setIsRescheduleRequestEvent,
  argRecieved,
  arg,
  eventType,
  setEventFilter,
}) => {
    const link = process.env.REACT_APP_PROPERTY_URL_LOCAL;
    const { extendedProps, title } = arg?.event?._def;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { userState } = useContext(UserContext);
    const showingType: string =
      extendedProps?.showingType?.length > 0
        ? extendedProps?.showingType
        : "Property Scheduled";
    let showingTypeTitle: string = "PS";
    if (showingType === "Lockbox") {
      showingTypeTitle = "LB";
    } else if (showingType === "Agent accompained") {
      showingTypeTitle = "AA";
    }

    const buttonClickedHandler = async (text: string, eventType: string) => {
      switch (text) {
        case "Accept":
          // let email = await getSignedInUserEmail();
          let email = null;
          const accessToken = getCronofyAccessToken(userState?.agent?.agentId);
          console.log("accessToken------", accessToken);
          console.warn("Synced EMAIL", email);
          debugger;
          if (email && eventType !== "Scheduled Property") {
            // const calendarEvent: any = {
            //   summary: title,
            //   location: `${extendedProps?.streetNumber} ${extendedProps?.streetName}, ${extendedProps?.city}, ${extendedProps?.state}`,
            //   start: {
            //     dateTime: moment(extendedProps?.Sdate),
            //     timeZone: "America/Los_Angeles",
            //   },
            //   end: {
            //     dateTime: moment(extendedProps?.Edate),
            //     timeZone: "America/Los_Angeles",
            //   },
            // };
            // insertEventToGoogleCalendar({ calendarEvent });
            // const calBody: any = {
            //   events: {
            //     event_Id: Math.random() * 100,
            //     summary: title,
            //     description: "Navihome Calendar Event",
            //     location: { description: `${extendedProps?.streetNumber} ${extendedProps?.streetName}, ${extendedProps?.city}, ${extendedProps?.state}` },
            //     start: {
            //       time: moment(extendedProps?.Sdate),
            //       tzid: "America/Los_Angeles",
            //     },
            //     end: {
            //       time: moment(extendedProps?.Edate),
            //       tzid: "America/Los_Angeles",
            //     },
            //   },
            //   calendarId: "",
            //   accessToken: ""
            // };
            // insertCronofyCalendarEvents(calBody);
          }
          setIsLoading(true);
          if (eventType === "Scheduled Property") {
            await updateAgentScheduledStatusService(
              extendedProps?.eventId,
              true,
              false
            );
          } else {
            await updateAgentStatusService(extendedProps?.eventId, true, false);
          }
          setEventFilter([]);
          if (extendedProps?.sender === "listingAgent") {
            await createBuyerNotificationById(
              Number(extendedProps?.buyerId),
              `Your Agent ${userState.agent.name?.toUpperCase()} has scheduled a property for viewing for ${extendedProps?.address} ${extendedProps?.city}, ${extendedProps?.state} ${extendedProps?.postalCode} | #${extendedProps?.listingId
              } for showing on ${moment(arg?.event?.start).format(
                "MMMM Do, yyyy"
              )}  ${moment(arg?.event?.start).format("h:mm A")} - ${moment(arg?.event?.end).format("hh:mm A")}.`,
              "accepted",
              userState?.agent?.token,
              "buyer",
              Number(extendedProps?.mlsId)
            );
            if (
              extendedProps?.buyerPrefferedMode === "CONTACT_RADIO_BOTH" ||
              extendedProps?.buyerPrefferedMode === "CONTACT_RADIO_PHONE"
            ) {
              postMessageToUser(
                extendedProps?.buyerPhone,
                `Your Agent ${extendedProps?.buyerAgentName?.toUpperCase()} has scheduled a property MLS #${extendedProps?.listingId
                } for showing on ${moment(extendedProps?.Sdate).format(
                  "MMMM Do, yyyy"
                )}.`
              );
            }
          } else {
            await createBuyerNotificationById(
              Number(extendedProps?.buyerId),
              `Your Agent ${extendedProps?.buyerAgentName?.toUpperCase()} has scheduled a property for viewing for ${extendedProps?.address} ${extendedProps?.city}, ${extendedProps?.state} ${extendedProps?.postalCode} | #${extendedProps?.listingId
              } for showing on ${moment(arg?.event?.start).format(
                "MMMM Do, yyyy"
              )}  ${moment(arg?.event?.start).format("h:mm A")} - ${moment(arg?.event?.end).format("hh:mm A")}.`,
              "accepted",
              userState?.agent?.token,
              "buyer",
              Number(extendedProps?.mlsId)
            );
            if (
              extendedProps?.buyerPrefferedMode === "CONTACT_RADIO_BOTH" ||
              extendedProps?.buyerPrefferedMode === "CONTACT_RADIO_PHONE"
            ) {
              postMessageToUser(
                extendedProps?.buyerPhone,
                `Your Agent ${extendedProps?.buyerAgentName?.toUpperCase()} has scheduled a property MLS #${extendedProps?.listingId
                } for showing on ${moment(extendedProps?.Sdate).format(
                  "MMMM Do, yyyy"
                )} from ${moment(extendedProps?.StartTime).format(
                  "hh:mm a"
                )} - ${moment(extendedProps?.EndTime).format("hh:mm a")}`
              );
            }
            if (userState?.userType === UserType.AGENT) {
              await inviteAgentByBuyer(
                Number(extendedProps?.agentId),
                "rejected",
                `Listing agent ${userState?.agent?.name?.toUpperCase()} has accepted your request MLS #${extendedProps?.listingId
                }`,
                Number(extendedProps?.mlsId),
                "",
                "",
                Number(extendedProps?.buyerId)
              );
            }
            if (
              extendedProps?.buyerAgentPrefferedMode === "CONTACT_RADIO_BOTH" ||
              extendedProps?.buyerAgentPrefferedMode === "CONTACT_RADIO_PHONE"
            ) {
              postMessageToUser(
                extendedProps?.buyerAgentPhone,
                `${userState?.agent?.name?.toUpperCase()} listing agent has accepted your request MLS #${extendedProps?.listingId
                }`
              );
            }
          }
          getEventDetails();
          setIsLoading(false);
          break;

        case "Rejected":
          setIsLoading(true);
          let messageType: string = 'No', deleteEventRes: any = null;
          const agentAccountSettingsResult: any = await getAgentAccountSettings(Number(extendedProps?.agentId));
          if (agentAccountSettingsResult?.length > 0) {
            const lastIndex: number = agentAccountSettingsResult?.length - 1;
            messageType = agentAccountSettingsResult?.[lastIndex]?.notifySavedSearchs;
          }

          if (eventType === "Scheduled Property") {
            await deleteScheduledPropertyByID(
              extendedProps?.eventId,
              userState?.agent?.token
            );
          } else {
            deleteEventRes = await deletePropertyCalendarByID(
              extendedProps?.eventId,
              userState?.agent?.token,
              messageType)
          }
          if (
            extendedProps?.agentType?.toLowerCase() === "buyeragent" &&
            userState.userType === UserType.AGENT
          ) {
            const preferredContact: string = deleteEventRes?.agent?.preferredContact;
            await inviteAgentByBuyer(
              Number(extendedProps?.agentId),
              "rejected",
              `Your request to view ${extendedProps?.address} ${extendedProps?.city}, ${extendedProps?.state} ${extendedProps?.postalCode} | MLS #${extendedProps?.listingId} on ${moment(arg?.event?.start)?.format('MMMM Do, yyyy')} at ${moment(arg?.event?.start).format("h:mm A")} has been declined by ${userState?.agent?.name?.toUpperCase()}`,
              Number(extendedProps?.mlsId),
              '',
              '',
              Number(extendedProps?.buyerId),
              "No",
              '',
              '',
            );

            if (
              messageType === "Realtime" && (preferredContact === "CONTACT_RADIO_BOTH" ||
                preferredContact === "CONTACT_RADIO_PHONE")
            ) {
              postMessageToUser(
                deleteEventRes?.agent?.phone,
                `Your request to view ${extendedProps?.address} ${extendedProps?.city}, ${extendedProps?.state} ${extendedProps?.postalCode}| MLS #${extendedProps?.listingId} on ${moment(arg?.event?.start).format('MMMM Do, yyyy')} at ${moment(arg?.event?.start).format("h:mm A")} has been declined by ${userState?.agent?.name?.toUpperCase()}.`
              );
            }
          } else if (userState.userType === UserType.AGENT) {
            await inviteAgentByBuyer(
              userState?.agent?.agentId,
              "rejected",
              `You have cancelled your showing request to view ${extendedProps?.address}
              ${extendedProps?.city}, ${extendedProps?.state}
              ${extendedProps?.postalCode} | MLS #${extendedProps?.listingId}`,
              Number(extendedProps?.mlsId),
              "",
              "",
              Number(extendedProps?.buyerId)
            );
          }

          getEventDetails();
          setIsLoading(false);
          break;

        case "Rescheduled":
          setIsLoading(true);
          setIsRescheduleRequestEvent(true);
          argRecieved(arg);
          if (extendedProps?.agentType?.toLowerCase() === "buyeragent") {
            if (userState?.userType === UserType.AGENT) {
              await inviteAgentByBuyer(
                Number(extendedProps?.agentId),
                "rejected",
                `Listing agent ${userState?.agent?.name?.toUpperCase()} has rescheduled your request MLS #${extendedProps?.listingId
                }. Accept the request to get started`,
                Number(extendedProps?.mlsId),
                "",
                "",
                Number(extendedProps?.buyerId)
              );
            }
            if (
              extendedProps?.buyerAgentPrefferedMode === "CONTACT_RADIO_BOTH" ||
              extendedProps?.buyerAgentPrefferedMode === "CONTACT_RADIO_PHONE"
            ) {
              postMessageToUser(
                extendedProps?.buyerAgentPhone,
                `${userState?.agent?.name?.toUpperCase()} listing agent rescheduled your request MLS #${extendedProps?.listingId
                }`
              );
            }
          }
          setIsLoading(false);
          getEventDetails();
          break;
        default:
          break;
      }
    };
    return (
      <>
        {isLoading && <CustomSpinner />}
        <div className="popoverContainer">
          <div className="popoverTitle">
            {`${showingType} (${showingTypeTitle}) |`}{" "}
            {extendedProps?.type?.toLowerCase() === "requested" &&
              !extendedProps?.isAccepted &&
              !extendedProps?.isRejected && <>Requested :</>}{" "}
            {extendedProps?.description ? extendedProps?.description : ""}{" "}
            {moment(arg?.event?.start).format("h:mm A")} |{" "}
            {extendedProps?.client?.length > 0 ? extendedProps?.client : ""}{" "}
            {extendedProps?.clientPhone?.length > 0
              ? `(${extendedProps?.clientPhone}) |`
              : ""}{" "}
            {extendedProps?.address?.length > 0 ? extendedProps?.address : "N/A"},{" "}
            {extendedProps?.city?.length > 0 ? extendedProps?.city : "N/A"} | MLS
            #{extendedProps?.listingId}
          </div>
          <div className="popoverDate">
            {moment(arg?.event?.start).format("Do MMMM")} &bull;{" "}
            {moment(arg?.event?.start).format("h:mm A")} -{" "}
            {moment(arg?.event?.end).format("hh:mm A")}
          </div>
          <div className="infoCont">
            <div className="infoIcon">
              <img src={User} alt="..." />
            </div>
            <div className="agentText">
              {extendedProps?.buyerAgentName
                ? extendedProps?.buyerAgentName
                : "N/A"}
            </div>
          </div>
          <div className="infoCont">
            <div className="infoIcon">
              <img src={Location} alt="..." />
            </div>
            <div className="infoText">
              {extendedProps?.address} <br />
              {extendedProps?.city}, {extendedProps?.state}{" "}
              {extendedProps?.postalCode}
            </div>
          </div>
          {eventType !== "Scheduled Property Requested" ? (
            <>
              <div className="infoCont">
                <div className="infoIcon">
                  <img src={Path} alt="..." />
                </div>
                <div className="infoText link url-container">
                  <a href={link + "/properties/" + extendedProps?.pid}>
                    {link + "/properties/" + extendedProps?.pid}
                  </a>
                </div>
              </div>
              <div className="infoContColor">
                <div className="infoCont">
                  <div className="infoIcon">
                    <img src={User} alt="..." />
                  </div>
                  <p style={{ marginLeft: "8px" }}>
                    {extendedProps?.client?.length > 0
                      ? extendedProps?.client
                      : "N/A"}
                  </p>
                </div>
                <div
                  className="agentColor"
                  style={{ backgroundColor: backgroundColor }}></div>
              </div>
            </>
          ) : (
            <></>
          )}

          {extendedProps?.type?.toLowerCase() === "requested" &&
            !extendedProps?.isAccepted &&
            !extendedProps?.isRejected && (
              <Row style={{ marginTop: "12px" }} justify="space-evenly">
                {(extendedProps?.agentType?.toLowerCase() === "buyeragent" ||
                  extendedProps?.sender === "listingAgent") && (
                    <Col sm={21} xs={21} md={21} lg={6} xl={6} xxl={6}>
                      <Button
                        type={ButtonType.PRIMARY_NARROW}
                        text="ACCEPT"
                        clickHandler={async () => {
                          buttonClickedHandler("Accept", extendedProps?.eventType);
                        }}
                      />
                    </Col>
                  )}
                <Col
                  style={{ paddingLeft: "4px" }}
                  sm={21}
                  xs={21}
                  md={21}
                  lg={10}
                  xl={10}
                  xxl={10}>
                  <Button
                    type={ButtonType.WHITE_NARROW}
                    text="RESCHEDULE"
                    clickHandler={() => {
                      buttonClickedHandler(
                        "Rescheduled",
                        extendedProps?.eventType
                      );
                    }}
                  />
                </Col>
                <Col sm={21} xs={21} md={21} lg={8} xl={8} xxl={8}>
                  <Button
                    type={ButtonType.PRIMARY_NARROW}
                    text={
                      extendedProps?.agentType?.toLowerCase() === "buyeragent"
                        ? "REJECT"
                        : "CANCEL"
                    }
                    clickHandler={async () => {
                      buttonClickedHandler("Rejected", extendedProps?.eventType);
                    }}
                  />
                </Col>
              </Row>
            )}
        </div>
      </>
    );
  };

export default CalendarSchedulePopover;