import iconPhone from '../assets/images/icon-phone.svg';
import iconEmail from '../assets/images/icon-email.svg';
import buttonDelete from '../assets/images/button-remove-property.svg';
import { useHistory } from 'react-router';
import { useContext, useState } from 'react';
import { useGlobal } from 'reactn';
import AgentContext from '../contexts/AgentContext';
import CustomSpinner from './spinner/CustomSpinner';
import RemoveBuyerModal from '../modals/RemoveBuyerModal';
import { notification } from './notification';
import {
  createAgentNotification,
  createBuyerNotificationById,
  postMessageToUser,
} from '../services/NavihomeService';
import UserContext from '../contexts/UserContext';
import PropertyFiltersContext from '../contexts/PropertyFiltersContext';
import { Avatar, Col, message, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { formatPhoneNumberInToE164 } from '../utils/base';

interface BuyerPreviewCardProps {
  buyerId: number;
  imageUrl: string;
  name: string;
  email: string;
  phone: string;
  setIsBuyerShowing: any;
  unlinkBuyer: (buyerId: number, customMessage: string) => void;
  avatarUrl?: string;
  preferredContact?: string;
  color: string;
}

const BuyerPreviewCard: React.FC<BuyerPreviewCardProps> = ({
  buyerId,
  imageUrl,
  name,
  email,
  phone,
  setIsBuyerShowing,
  avatarUrl,
  color,
  preferredContact,
  unlinkBuyer,
}) => {
  const [isUnlikeBuyerModalShowing, setIsUnlikeBuyerModalShowing] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customMessage, setCustomMessage] = useState<string>(`Hello, since you mentioned that you no longer need my services, I am removing you from my list of buyers. If you're buying a home again, you know who to reach out to!`);
  const history = useHistory();
  const { userState } = useContext(UserContext);
  const {
    setCreateNotificationsInfo,
    setGetNotificationsUserEmail,
    setGetNotificationsUserId,
  } = useContext(PropertyFiltersContext);
  const { selectBuyer } = useContext(AgentContext);
  const [buyerGlobalId, setBuyerGlobalId] = useGlobal<any>('buyerId');

  const goToDashboard = () => {
    selectBuyer(buyerId, name, phone, email, preferredContact);
    setBuyerGlobalId(buyerId);
    // history.push(`/dashboard?buyerId=${buyerId}&name=${name}&phone=${phone}&email=${email}`);
    history.push(`/dashboard?buyerId=${buyerId}`);
  };

  const unlinkThisBuyer = async () => {
    setIsLoading(true);
    try {
      if (!buyerId) {
        setIsLoading(false);
        return;
      }
      unlinkBuyer(buyerId, customMessage);
      await createBuyerNotificationById(
        buyerId,
        `Your Agent ${userState?.agent?.name} removed you from their service list.`,
        'removed',
        userState?.agent?.token,
        'buyer',
        0
      );
      await createAgentNotification(
        buyerId,
        userState?.agent?.token,
        'removed',
        `You have successfully removed ${name} from your list of buyers.`,
        userState?.agent?.name,
        userState?.agent?.email
      );
      if (
        userState?.agent?.preferredContact === 'CONTACT_RADIO_BOTH' ||
        userState?.agent?.preferredContact === 'CONTACT_RADIO_PHONE'
      ) {
        postMessageToUser(
          userState?.agent?.phone ?? '',
          `You successfully removed ${name} from service list.` ?? ''
        );
      }
      if (
        preferredContact === 'CONTACT_RADIO_BOTH' ||
        preferredContact === 'CONTACT_RADIO_PHONE'
      ) {
        postMessageToUser(
          phone ?? '',
          `Your Agent ${userState?.agent?.name} removed you from their service list.` ?? ''
        );
      }
      setCreateNotificationsInfo(String(Math.floor(Math.random() * 100 + 1)));
      setGetNotificationsUserId(buyerId);
      setGetNotificationsUserEmail(userState?.agent?.email);
      notification('User Removed Successfully!', 'success');
      closeAction();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('ERROR: ', error);
      closeAction();
      // return notification('Something Went Wrong!', 'error');
    }
  };
  const closeAction = () => {
    setIsUnlikeBuyerModalShowing(false);
  };
  const hadleDeleteModal = () => {
    setIsUnlikeBuyerModalShowing(!isUnlikeBuyerModalShowing);
  };

  return (
    <div className="" style={{ padding: '0px 20px' }}>
      <div
        style={{
          borderBottom: `4px solid ${color? color:'#365073'}`,
          paddingBottom: '20px',
          marginBottom: '20px',
        }}
      >
        {isLoading && <CustomSpinner />}
        <Row style={{ width: '100%', cursor: 'pointer' }}>
          <Col span={8}>
            {avatarUrl ? (
              <img
                className="buyer-preview-card__avatar"
                src={avatarUrl}
                width={100}
                height={100}
                alt="Agent Avatar"
                onClick={goToDashboard}
              />
            ) : (
              <Avatar
                size={100}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                icon={<UserOutlined />}
              />

              // <img
              //   className="buyer-preview-card__avatar"
              //   src={require(`../assets/images/${imageUrl}`).default}
              //   width={100}
              //   height={100}
              //   alt="Agent Avatar"
              //   onClick={goToDashboard}
              // />
            )}
          </Col>
          <Col xs={24} sm={12} md={14} lg={14} xl={14}>
            <div className="buyer-preview-card__info" onClick={goToDashboard}>
              <div className="buyer-preview-card__name">
                <p>{name}</p>
              </div>
              <div className="buyer-preview-card__phone">
                <img src={iconPhone} alt="iconPhone" />
                <p>{phone[0]==='+'?'':'+'}{phone}</p>
              </div>
              <div className="buyer-preview-card__email">
                <span>
                  <img src={iconEmail} />
                </span>
                <p>{email}</p>
              </div>
            </div>
            <img
              className="buyer-preview-card__delete-button"
              src={buttonDelete}
              onClick={hadleDeleteModal}
            />
            {isUnlikeBuyerModalShowing && (
              <RemoveBuyerModal
                isShowing={true}
                closeAction={closeAction}
                unlinkThisBuyer={unlinkThisBuyer}
                name={name ?? ''}
                email={email ?? ''}
                setIsLoading={setIsLoading}
                customMessage={customMessage}
                setCustomMessage={setCustomMessage}
              />
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BuyerPreviewCard;
