import { useState, useContext, useEffect } from "react";
import UserContext from "../contexts/UserContext";
import buttonClose from "../assets/images/button-close.svg";
import MaskGroup from "../assets/find-an-agent-page/Mask Group 1.png";
import TextField, {
  TextFieldType,
  TextFieldIconType,
} from "../components/TextField";
import Button, { ButtonType } from "../components/Button";
import Checkbox, { CheckboxType } from "../components/Checkbox";
import { useHistory } from "react-router";
import {
  registerBuyerService,
} from "../services/NavihomeService";
import { useMediaQuery } from "react-responsive";
import { notification } from "../components/notification";
import styled from "@emotion/styled";
import CustomSpinner from "../components/spinner/CustomSpinner";
import ThankyouModal from "./ThankyouModal";
import ForgotPasswordModal from "../modals/forgotPasswordModal";
import LogInModal from "../modals/LoginModal";

const CustomSpan = styled.span`
  text-decoration: underline;
  color: #eb752f;
`;

const CustomRegHeading = styled.h1`
  font-size: 22px;
  @media screen and (max-width: 420px) {
    font-size: 19px;
  }
`;

interface QuickBuyerRegistrationModalPropsModel {
  isShowing: boolean;
  closeAction: () => void;
  setIsShowingLoginModal: any;
}

const QuickBuyerRegistrationModal: React.FC<
  QuickBuyerRegistrationModalPropsModel
> = ({ isShowing, closeAction, setIsShowingLoginModal }): JSX.Element => {
  const { userState} = useContext(UserContext);
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordRequiredLength, setIsPasswordRequiredLength] =
    useState(false);
  const [doesPasswordContainUppercase, setDoesPasswordContainUppercase] =
    useState(false);
  const [doesPasswordContainLowercase, setDoesPasswordContainLowercase] =
    useState(false);
  const [doesPasswordContainNumer, setDoesPasswordContainNumber] =
    useState(false);
  const [keepMeSignedIn, setKeepMeSignedIn] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRobot, setIsRobot] = useState(false);
  const [isShowingThankyouModal, setIsShowingThankyouModal] =
    useState<boolean>(false);
  const [isVisibleLoginModal, setIsVisibleLoginModal] = useState(false);
  const [isShowingForgotPasswordModal, setIsShowingForgotPasswordModal] =
    useState(false);
  const [isDatabaseError, setIsDatabaseError] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 500 });
  const handleLogIn = async () => {
    setIsDatabaseError(true);
    setIsShowingLoginModal(true);
    closeAction();
  };

  const handleCloseLoginModal = () => {
    setIsVisibleLoginModal(!isVisibleLoginModal);
  };
  const handleCloseForgotPasswordModal = () => {
    setIsShowingForgotPasswordModal(!isShowingForgotPasswordModal);
  };

  const handleRegister = async () => {
    if (email === "") {
      return notification("Please enter your email.", "error");
    }
    if (password === "") {
      return notification("Please enter password.", "error");
    }
    if (confirmPassword === "") {
      return notification("Please enter confirm password.", "error");
    }
    if (password !== confirmPassword) {
      return notification("Passwords don't match.", "error");
    }
    if (isRobot === false) {
      return notification("Please verify, I'm not a robot.", "error");
    }
    if (!isPasswordRequiredLength) {
      notification("Passwords must be at least 8 characters long.", "error");
      return;
    }
    if (!doesPasswordContainUppercase) {
      notification(
        "Passwords must contain at least 1 UPPERCASE letter.",
        "error"
      );
      return;
    }
    if (!doesPasswordContainLowercase) {
      notification(
        "Passwords must contain at least 1 lowercase letter.",
        "error"
      );
      return;
    }
    if (!doesPasswordContainNumer) {
      return notification(
        "Passwords must contain at least 1 number character.",
        "error"
      );
    }

    try {
      setIsLoading(true);
      const success = await registerBuyerService(
        email,
        password,
        "+1",
        "user",
        "CONTACT_RADIO_PHONE"
      );
      if (success) {
        setIsLoading(false);
        closeAction();
        return notification("An email has been sent to your registered email. Please verify and login", "success");
      } else {
        setIsLoading(false);
        setIsShowingThankyouModal(true);
        handlerModalTimeout();
        // return notification('Thank you for registering. An email has been sent to your registered email ID. Please verify and login.', 'success');
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return notification('Please enter a valid email', "error");
    }
  };

  useEffect(() => {
    var tempIsPasswordRequiredLength = password.length >= 8 ? true : false;
    var tempDoesPasswordContainUppercase = false;
    var tempDoesPasswordContainLowercase = false;
    var tempDoesPasswordContainNumer = false;

    for (var i = 0; i < password.length; i++) {
      const char = password.charAt(i);
      if (!tempDoesPasswordContainUppercase) {
        tempDoesPasswordContainUppercase = /^[A-Z]*$/.test(char);
      }
      if (!tempDoesPasswordContainLowercase) {
        tempDoesPasswordContainLowercase = /^[a-z]*$/.test(char);
      }
      if (!tempDoesPasswordContainNumer) {
        tempDoesPasswordContainNumer = /^[0-9]*$/.test(char);
      }
    }

    setIsPasswordRequiredLength(tempIsPasswordRequiredLength);
    setDoesPasswordContainUppercase(tempDoesPasswordContainUppercase);
    setDoesPasswordContainLowercase(tempDoesPasswordContainLowercase);
    setDoesPasswordContainNumber(tempDoesPasswordContainNumer);
  }, [password]);

  const handleCloseThankyouModal = () => {
    setIsShowingThankyouModal(!isShowingThankyouModal);
    closeAction();
    history.push("/properties");
  };

  const handlerModalTimeout = () => {
    setTimeout(() => {
      handleCloseThankyouModal();
    }, 4000);
  };

  const modalClassName = isShowing
    ? "quick-registration-modal u-visible"
    : "quick-registration-modal u-hidden";
  const cardClassName = isShowing
    ? "quick-registration-modal__card animation__pop-in"
    : "quick-registration-modal__card animation__pop-out";

  const renderPasswordRequirements = () => {
    return (
      <div className="register-buyer-page__password-filters1">
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;• is at least 8 characters long:
          {isPasswordRequiredLength ? (
            <span className="green">✔</span>
          ) : (
            <span className="red">✗</span>
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;• contains 1 UPPERCASE letter:
          {doesPasswordContainUppercase ? (
            <span className="green">✔</span>
          ) : (
            <span className="red">✗</span>
          )}
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;• contains 1 lowercase letter:
          {doesPasswordContainLowercase ? (
            <span className="green">✔</span>
          ) : (
            <span className="red">✗</span>
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;• contains 1 number character:
          {doesPasswordContainNumer ? (
            <span className="green">✔</span>
          ) : (
            <span className="red">✗</span>
          )}
        </p>
      </div>
    );
  };

  return (
    <div className={modalClassName}>
      {isLoading && <CustomSpinner />}
      <div className={cardClassName}>
        <div className="quick-registration-modal__row">
          <CustomRegHeading>
            Quick r
            <span style={{ borderBottom: "3px solid #C2D500" }}>
              egistration
            </span>
          </CustomRegHeading>
          <img
            className="quick-registration-modal__close-button"
            src={buttonClose}
            onClick={closeAction}
            alt="Close button"
          />
        </div>
        <div>
          <div className="quick-registration-logo">
            <p>
              Creating an account will only take a minute. Already have an
              account?{" "}
              <CustomSpan style={{ cursor: "pointer" }} onClick={handleLogIn}>
                <span
                  className="link"
                  style={{
                    textDecoration: "underline",
                    color: "#EB752F",
                    marginLeft: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsVisibleLoginModal(true);
                  }}
                >
                  Sign in now
                </span>
              </CustomSpan>
            </p>
          </div>
        </div>
        <div style={{ marginLeft: "17px", marginTop: "5px" }}>
          <p>Ensure that the password you create is strong:</p>
          {renderPasswordRequirements()}
        </div>
        <div className="quick-registration-modal__column">
          <p className="quick-registration-modal__error-message">
            {/* {userState.loginError} */}
            {isDatabaseError ? userState?.loginError : ""}
          </p>
          <TextField
            value={email}
            setValue={setEmail}
            placeholder="janedoe@hotmail.com"
            label="Email"
            type={TextFieldType.ICON_AND_LABEL}
            icon={TextFieldIconType.EMAIL}
            isRequired={true}
            width={isMobile ? 26.4 : 38.4}
            // className="quick-registration-modal__email-field"
          />
          <TextField
            value={password}
            setValue={setPassword}
            placeholder="••••••••"
            label="Create Password"
            type={TextFieldType.PASSWORD}
            icon={TextFieldIconType.PASSWORD}
            isRequired={true}
            width={isMobile ? undefined : 38.4}
            className="register-buyer-page__form-item"
          />
          <TextField
            value={confirmPassword}
            setValue={setConfirmPassword}
            placeholder="••••••••"
            label="Confirm Password"
            type={TextFieldType.PASSWORD}
            icon={TextFieldIconType.PASSWORD}
            isRequired={true}
            width={isMobile ? undefined : 38.4}
            className="register-buyer-page__form-item"
          />
          <Checkbox
            type={CheckboxType.SMALL}
            value={keepMeSignedIn}
            setValue={setKeepMeSignedIn}
            text="Keep me signed in"
          />
          <div className="quick-registration-modal_robot-image">
            <Checkbox
              type={CheckboxType.SMALL}
              value={isRobot}
              setValue={setIsRobot}
              text="I am not a robot"
            />
            <img
              className="quick-registration-modal__close-button"
              src={MaskGroup}
              onClick={() => {}}
              alt="Close button"
            />
          </div>
          <Button
            type={ButtonType.PRIMARY}
            text="SUBMIT"
            clickHandler={handleRegister}
            width={24.5}
            className="quick-registration-modal__button"
          />
        </div>
      </div>
      {isVisibleLoginModal && (
        <LogInModal
          isShowing={isVisibleLoginModal}
          closeAction={handleCloseLoginModal}
          setIsShowingForgotPasswordModal={setIsShowingForgotPasswordModal}
          closeQuickModal={closeAction}
        />
      )}
      {isShowingForgotPasswordModal && (
        <ForgotPasswordModal
          isShowing={isShowingForgotPasswordModal}
          closeAction={handleCloseForgotPasswordModal}
          setIsShowingLoginModal={setIsVisibleLoginModal}
          closeQuickModal={closeAction}
        />
      )}
      {isShowingThankyouModal && (
        <ThankyouModal
          isShowing={isShowingThankyouModal}
          closeAction={handleCloseThankyouModal}
        />
      )}
    </div>
  );
};

export default QuickBuyerRegistrationModal;
