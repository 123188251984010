import radioOn from '../assets/images/button-radio-on.svg';
import radioOff from '../assets/images/button-radio-off.svg';
import {useMediaQuery} from 'react-responsive'

export enum ContactRadioType {
  EMAIL = 'CONTACT_RADIO_EMAIL',
  PHONE = 'CONTACT_RADIO_PHONE',
  BOTH = 'CONTACT_RADIO_BOTH'
}

interface RadioProps {
  value: ContactRadioType;
  setValue: (value: ContactRadioType) => void;
  className?: string;
}

const ContactRadio: React.FC<RadioProps> = ({value, setValue, className }) => {

  const isMobile = useMediaQuery({maxWidth: 576})
  const selectEmailHandler = () => {
    setValue(ContactRadioType.EMAIL);
  }

  const selectPhoneHandler = () => {
    setValue(ContactRadioType.PHONE);
  }

  const selectBothHandler = () => {
    setValue(ContactRadioType.BOTH);
  }


  return (
    <div className={className === undefined ? "contact-radio" : `contact-radio ${className}`}>
      <p className="f-heading">Preferred mode of contact:</p>
      <div className='contact-radio-div'>
      <div className="contact-radio__option" onClick={selectEmailHandler}>
        <img className="contact-radio__option--on" src={radioOn} alt="Radio On" style={{ opacity: value === ContactRadioType.EMAIL ? 1 : 0 }} />
        <img className="contact-radio__option--off" src={radioOff} alt="Radio Off" style={{ opacity: value === ContactRadioType.EMAIL ? 0 : 1 }} />
        <p>Email</p>
      </div>
      <div className="contact-radio__option" onClick={selectPhoneHandler}>
        <img className="contact-radio__option--on" src={radioOn} alt="Radio On" style={{ opacity: value === ContactRadioType.PHONE ? 1 : 0 }} />
        <img className="contact-radio__option--off" src={radioOff} alt="Radio Off" style={{ opacity: value === ContactRadioType.PHONE ? 0 : 1 }} />
        <p>Phone</p>
      </div>
      <div className="contact-radio__option" onClick={selectBothHandler}>
        <img className="contact-radio__option--on" src={radioOn} alt="Radio On" style={{ opacity: value === ContactRadioType.BOTH ? 1 : 0 }} />
        <img className="contact-radio__option--off" src={radioOff} alt="Radio Off" style={{ opacity: value === ContactRadioType.BOTH ? 0 : 1 }} />
        <p>Both</p>
      </div>
      </div>
    </div>
  );
};

export default ContactRadio;