

declare global {
  interface Window {
    gapi: any;
  }
}
const API_KEY = "AIzaSyAOMKaPauFlG4xlRAsoc0gnBw6kegrzyzw";
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];
const CLIENT_ID = "658514464171-qre8o9tfnlbabveqacstaivcd2gbs2i4.apps.googleusercontent.com";
const SCOPES = "https://www.googleapis.com/auth/calendar.events";

export function initClient(callback: any) {
  window.gapi.load("client:auth2", () => {
    try {
      window.gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
          plugin_name: "chat"
        })
        .then(
          () => {
            if (typeof callback === "function") {
              callback(true);
            }
          },
          function (error: any) {
            console.log("Error", error);
          }
        );
    } catch (error) {
      console.log(error);
    }
  });
}

export const checkSignInStatus = async () => {
  try {
    return await window.gapi.auth2.getAuthInstance().isSignedIn.listen((signedIn: any) => {
      console.log("signedIn-", signedIn);
      if (signedIn) {
        console.log("profile", window.gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile());
      }
    });

    // let status = await gapi.auth2.getAuthInstance().isSignedIn.get();
    // if (status) {
    //   return status;
    // }
  } catch (error) {
    console.log(error);
  }
};

export const signInToGoogle = async () => {
  try {
    let googleuser = await window.gapi.auth2.getAuthInstance().signIn({ prompt: "consent" });
    if (googleuser) {
      return true;
    }
  } catch (error) {
    console.log(error);
  }
};

export const signOutFromGoogle = () => {
  try {
    var auth2 = window.gapi.auth2.getAuthInstance();
    auth2.signOut().then(() => {
      auth2.disconnect();
    });
    return true;
  } catch (error) {
    console.log(error);
  }
};

export const getSignedInUserEmail = async () => {
  try {
    let status = await checkSignInStatus();
    console.log("statusTIME--", status.isActive);

    if (status?.isActive) {
        return window.gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile();
      //   var auth2 = gapi.auth2.getAuthInstance();
      //   var profile = auth2.currentUser.get().getBasicProfile();
      //   console.log("profile", profile);

      //   return profile.getEmail();
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

export const publishTheCalenderEvent = (event: any) => {
  try {

   setTimeout(()=>{ window.gapi.client.load("calendar", "v3", () => {
      var request = window.gapi.client.calendar.events.insert({
        calendarId: "primary",
        resource: event
      });

      request.execute((event: any) => {
        console.log("Event created: " + event.htmlLink);
      });
    });},1000)
  } catch (error) {
    console.log(error);
  }
};
