import { useState } from 'react';
import RequestNotification from './notificationsayouts/requestNotification';
import ShowingNotification from './notificationsayouts/showingNotification';
import UpdateNotification from './notificationsayouts/updateNotification';
import './styles/notifications.css';
interface NotificationMainPropsModel {
	agentNotifications: Array<any>;
}
const NotificationMain: React.FC<NotificationMainPropsModel> = ({
	agentNotifications = [],
}) => {
	const [notifications, setNotifications] =
		useState<Array<any>>(agentNotifications);
	// let notifications = [
	// 	{
	// 		type: 'request',
	// 		agent: 'Regina George',
	// 		date: 'May 19, 2022',
	// 		time: '13:00 - 14:00',
	// 		timestamp: '1 hr',
	// 	},
	// 	{
	// 		type: 'request',
	// 		agent: 'Regina George',
	// 		date: 'May 19, 2022',
	// 		time: '13:00 - 14:00',
	// 		timestamp: '1 hr',
	// 	},
	// 	{
	// 		type: 'showing',
	// 		agent: 'Regina George',
	// 		date: 'May 19, 2022',
	// 		time: '13:00 - 14:00',
	// 		timestamp: '1 hr',
	// 	},
	// 	{
	// 		type: 'update',
	// 		agent: 'Regina George',
	// 		date: 'May 19, 2022',
	// 		time: '13:00 - 14:00',
	// 		timestamp: '3 hrs',
	// 	},
	// 	{
	// 		type: 'update',
	// 		agent: 'Ken Adams',
	// 		date: 'May 19, 2022',
	// 		time: '13:00 - 14:00',
	// 		timestamp: '3 hrs',
	// 	},
	// 	{
	// 		type: 'request',
	// 		agent: 'Regina George',
	// 		date: 'May 19, 2022',
	// 		time: '13:00 - 14:00',
	// 		timestamp: '1 hr',
	// 	},
	// 	{
	// 		type: 'showing',
	// 		agent: 'Regina George',
	// 		date: 'May 19, 2022',
	// 		time: '13:00 - 14:00',
	// 		timestamp: '1 hr',
	// 	},

	// 	{
	// 		type: 'request',
	// 		agent: 'Regina George',
	// 		date: 'May 19, 2022',
	// 		time: '13:00 - 14:00',
	// 		timestamp: '1 hr',
	// 	},
	// 	{
	// 		type: 'showing',
	// 		agent: 'Regina George',
	// 		date: 'May 19, 2022',
	// 		time: '13:00 - 14:00',
	// 		timestamp: '1 hr',
	// 	},
	// ];

	return (
		<div>
			{agentNotifications?.map((notification) => (
				<>
					{/* {notification?.type === 'requested' && (
						<RequestNotification
							notification={notification}
						></RequestNotification>
					)} */}
					{/* {(notification?.type === 'favorite' || notification.type ==='liked') ||  notification?.type === 'buyerAgent' && ( */}
						<ShowingNotification
							notification={notification}
						></ShowingNotification>
					{/* // )} */}
					{/* {notification?.type === 'viewedRequest' && (
						<UpdateNotification
							notification={notification}
						></UpdateNotification>
					)}
					{(notification?.type === 'Comment' || notification?.type === 'Free Slot') && (
						<ShowingNotification
							notification={notification}
						></ShowingNotification>
					)}
					{notification?.type === 'seller' && (
						<UpdateNotification
							notification={notification}
						></UpdateNotification>
					)} */}
				</>
			))}
		</div>
	);
};

export default NotificationMain;
