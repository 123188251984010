import React from 'react';
import HomePage from './homepage';
interface showingProps {
  agentUpcomingTour?: Array<any>;
}
const Showings: React.FC<showingProps> = ({ agentUpcomingTour }) => {
  return (
    <>
      <HomePage isShowings={true} agentUpcomingTour={agentUpcomingTour} />
    </>
  );
};

export default Showings;
