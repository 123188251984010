import { useContext, useEffect, useState } from 'react';
import SchoolInformationSummary from '../../components/SchoolInformationSummary';
import GoogleMapReact from '../../components/GoogleMap/PropertyMap';
import iconConnected from '../../assets/images/icon-connected.svg';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import {
  getAgentInfoByMlsId,
  getPropertyDetailsService,
  getSchoolInfoOfProperty,
} from '../../services/NavihomeService';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import shareImg from '../../assets/images/button-share.svg';

import { notification } from '../../components/notification';
import { Row, Col, Grid, Divider, Table, Select } from 'antd';
import styled from '@emotion/styled';
import { useHistory } from 'react-router';
import UserContext, { UserType } from '../../contexts/UserContext';
import FindAnAgentModal from '../../modals/findAnAgentModal';
import PropertyShareModal from '../../modals/PropertyShareModal';
import CustomSpinner from '../spinner/CustomSpinner';
import { useMediaQuery } from 'react-responsive';
import GreatSchoolLogo from "../../assets/images/greatschool.png";

const { useBreakpoint } = Grid;

const CustomDiv = styled.p`
  font-size: 17px;
  margin: 10px 0px;
`;
const CustomDivider = styled(Divider)`
  background: #004876;
  margin: 18px 0px;
  border-bottom: 1px solid #004876;
`;
const CustomRow = styled(Row)`
  flex-flow: nowrap;
`;

const CustomGalleryDiv = styled.div`
  .image-gallery-index {
    font-size: 18px !important;
  }
`;
const CustomPriceHeading = styled.h2`
  margin-right: 10px;
`;
const CustomPara = styled.p`
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  width: 100%;
  word-break: keep-all;
`;

const CustomCOl = styled(Col)`
  div.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
  }
  @media screen and (max-width: 1200px) {
    div.sticky {
      position: static;
    }
  }
`;
const CustomHeading = styled.h1`
  padding-bottom: 15px;
  @media screen and (min-width: 1200px) {
    font-size: 1.75rem;
  }
  @media screen and (max-width: 1200px) {
    font-size: calc(1.3rem + 0.6vw);
  }
`;

interface PropertyDetailsPagePropsModel {
  mlsId?: number;
  propertyType?: string;
}

const PropertyDetailsForSellerPage: React.FC<PropertyDetailsPagePropsModel> = ({
  mlsId,
  propertyType,
}) => {
  const [isShowingSalesTab, setIsShowingSalesTab] = useState(true);
  const [imageSrc, setImageSrc] = useState('');
  const [galleryImage, setGalleryImage] = useState([]);
  const [schoolInfo, setSchoolInfo] = useState([]);
  const [showAllSchool, setShowAllSchool] = useState<boolean>(false);
  const [isBuyerLinked, setIsBuyerLinked] = useState<boolean>(false);
  const [isDetailsLoad, setIsDetailsLoad] = useState<boolean>(true);
  const [remarks, setRemarks] = useState('');
  const [listPrice, setListPrice] = useState('');
  const [sqrtPrice, setSqrtPrice] = useState(' ');
  const [sharePropModal, setSharePropModal] = useState<boolean>(false);
  const responsiveMap = useMediaQuery({ minWidth: 1200 });
  const isMD = useMediaQuery({ maxWidth: 1070 });
  const [geoLoc, setGeoLoc] = useState({
    lat: 0,
    lng: 0,
    direction: '',
  });
  const [address, setAddress] = useState({
    fullAddress: ' ',
    city: ' ',
    country: ' ',
    streetNumber: ' ',
    streetName: ' ',
    state: ' ',
    postalCode: ' ',
  });
  const [area, setArea] = useState('');
  const [area1, setArea1] = useState('');
  const [yearBuilt, setYearBuilt] = useState('');
  const [propDetail, setPropDetail] = useState({
    imageURL: '',
    bedrooms: 0,
    bathrooms: 0,
    bathsFull: 0,
    bathsHalf: 0,
    bathsThreeQuarter: ' ',
    heating: ' ',
    cooling: ' ',
    fireplaces: ' ',
    flooring: ' ',
    interior: ' ',
    laundryfeat: ' ',
    subTypeText: ' ',
    type: ' ',
    parking: {
      description: ' ',
      spaces: ' ',
    },
    mls: {
      status: ' ',
    },
    monthly_payment: ' ',
    brokerage: ' ',
    virtual_tour: 0,
    foundation: ' ',
    roof: ' ',
    directions: ' ',
    agent: {
      firstName: '',
      lastName: '',
    },
    listingId: null,
    mlsId: null,
    lotSizeArea: '',
    lotSizeAreaUnits: '',
    listPrice: 0,
    area: 0,
    convertedYr: '',
  });
  const [taxDet, setTaxDet] = useState({
    id: ' ',
    taxAnnualAmount: 0,
    taxYear: ' ',
    land: ' ',
    additions: ' ',
    assessedValue: ' ',
  });

  // state for goback button to show same state
  const screens = useBreakpoint();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  const { userState } = useContext(UserContext);
  const [isLoader, setIsLoader] = useState(false);
  const [isFindAnAgentModal, setIsFindAnAgentModal] = useState(false);

  const [isListed, setIsListed] = useState<Boolean>(false);
  const [agentMlsId, setAgentMlsId] = useState<string>('');
  const [agentListedEmail, setAgentListedEmail] = useState<string>('');
  const [agentListedPhone, setAgentListedPhone] = useState<string>('');
  const [agentNotListedPhone, setAgentNotListedPhone] = useState<string>('');
  const [listedAgent, setListedAgent] = useState<boolean>(false);
  const [notListedAgent, setNotListedAgent] = useState<boolean>(false);

  const [isCommon, setIsCommon] = useState<boolean>(false);
  const [isSell, setIsSell] = useState<boolean>(false);
  const [isPropDetail, setIsPropDetail] = useState<boolean>(false);

  const [modeOfContact, setModeOfContact] = useState<string>('');

  const schoolInfoData = async (lat: any, lng: any) => {
    try {
      const data = await getSchoolInfoOfProperty(lat, lng);
      setSchoolInfo(data.schools);
    } catch (err) {
      console.log('error of school info api ', err);
      return;
    }
  };

  const checkListedAgent = async () => {
    let token = '';
    if (userState?.userType === UserType.AGENT) {
      token = userState?.agent?.token;
    } else {
      token = userState?.buyer?.token;
    }
    try {
      const responseData: any = await getAgentInfoByMlsId(token, agentMlsId);

      if (responseData?.email) {
        setAgentListedEmail(responseData?.email);
        setAgentListedPhone(responseData?.phone);
        setModeOfContact(responseData?.preferredContact);
        setListedAgent(true);
        setNotListedAgent(false);
      } else {
        setListedAgent(false);
        setNotListedAgent(true);
      }
    } catch (error) {
      console.log(error);
      return;
    }
  };

  useEffect(() => {
    if (geoLoc.lat && geoLoc.lng) {
      schoolInfoData(geoLoc.lat, geoLoc.lng);
    }
  }, [geoLoc]);

  useEffect(() => {
    const currentTab = history?.location?.pathname?.split('/')?.[1];
    if (currentTab?.toLocaleLowerCase() === 'common') {
      setIsCommon(true);
    } else if (currentTab?.toLocaleLowerCase() === 'sell') {
      setIsSell(true);
    } else if (currentTab?.toLocaleLowerCase() === 'propertydetails') {
      setIsPropDetail(true);
    }
  }, []);

  useEffect(() => {
    if (agentMlsId && userState?.isLoggedIn) {
      checkListedAgent();
    }
  }, [agentMlsId]);

  const getPropertyDetails = async () => {
    setIsLoading(true);
    setIsDetailsLoad(true);
    try {
      const propertyDetails: any = await getPropertyDetailsService(mlsId);
      const images: any = propertyDetails?.photos?.map((item: any) => {
        return {
          original: item,
          thumbnail: item,
          thumbnailHeight: 100,
          thumbnailWidth: 200,
        };
      });
      setGalleryImage(images?.length > 0 ? images : []);
      setImageSrc(propertyDetails?.photos?.[0] ?? '');
      setRemarks(propertyDetails?.remarks ?? '');
      setListPrice(`${propertyDetails?.listPrice ?? ''}`);
      setSqrtPrice(propertyDetails?.listPrice);
      setPropDetail({
        imageURL: '',
        bedrooms: propertyDetails?.property?.bedrooms || 0,
        bathrooms: propertyDetails?.property?.bathrooms || 0,
        bathsFull: propertyDetails?.property?.bathsFull || 0,
        bathsHalf: propertyDetails?.property?.bathsHalf || 0,
        bathsThreeQuarter: propertyDetails?.property?.bathsThreeQuarter || 0,
        heating: propertyDetails?.property?.heating || 'N/A',
        cooling: propertyDetails?.property?.cooling || 'N/A',
        fireplaces: propertyDetails?.property?.fireplaces || 0,
        flooring: propertyDetails?.property?.flooring || 'N/A',
        interior: propertyDetails?.property?.interiorFeatures || 'N/A',
        laundryfeat: propertyDetails?.property?.laundryFeatures || 'N/A',
        subTypeText: propertyDetails?.property?.subTypeText,
        type: propertyDetails?.property?.type,
        parking: propertyDetails?.property?.parking,
        mls: propertyDetails?.mls || 'N/A',
        monthly_payment: 'N/A',
        brokerage: 'N/A',
        virtual_tour: propertyDetails?.virtualTourUrl || 'N/A',
        foundation: propertyDetails?.property?.foundation || 'N/A',
        roof: propertyDetails?.property?.roof || 'N/A',
        directions: propertyDetails?.geo?.directions || 'N/A',
        agent: propertyDetails?.agent || 'N/A',
        listingId: propertyDetails?.listingId || 'N/A',
        mlsId: propertyDetails?.mlsId || 'N/A',
        lotSizeArea: propertyDetails?.property?.lotSizeArea || 'N/A',
        lotSizeAreaUnits: propertyDetails?.property?.lotSizeAreaUnits || 'N/A',
        listPrice: 10,
        area: 10,
        convertedYr: propertyDetails?.property?.yearConverted,
      });
      setTaxDet({
        id: propertyDetails?.tax?.id || 'N/A',
        taxAnnualAmount:Number(propertyDetails?.tax?.taxAnnualAmount) || 0,
        taxYear: propertyDetails?.tax?.taxYear || 'N/A',
        land: propertyDetails?.tax?.land || 'N/A',
        additions: propertyDetails?.tax?.additions || 'N/A',
        assessedValue: propertyDetails?.tax?.assessedValue || 'N/A',
      });
      setGeoLoc({
        lat: propertyDetails?.geo?.lat ?? null,
        lng: propertyDetails?.geo?.lng ?? null,
        direction:
          propertyDetails?.geo?.directions + ' ' + propertyDetails?.geo?.county,
      });
      setAddress({
        fullAddress: propertyDetails?.address?.full ?? 'N/A',
        city: propertyDetails?.address?.city ?? 'N/A',
        country: propertyDetails?.address?.country ?? 'N/A',
        streetNumber: propertyDetails?.address?.streetNumberText ?? 'N/A',
        streetName: propertyDetails?.address?.streetName ?? 'N/A',
        state: propertyDetails?.address?.state ?? 'N/A',
        postalCode: propertyDetails?.address?.postalCode ?? 'N/A',
      });
      setYearBuilt(propertyDetails?.property.yearBuilt ?? 0); //TODO change
      setArea(propertyDetails?.property.area ?? 0); //TODO change
      setArea1(propertyDetails?.property?.lotSizeArea || 'N/A');
      setAgentMlsId(propertyDetails?.agent?.id);
      setAgentNotListedPhone(propertyDetails?.agent?.contact?.office);
      setIsLoading(false);
      setIsDetailsLoad(false);
    } catch (error) {
      console.log('Error: ', error);
      setIsLoading(false);
    }
  };

  // const getTotalViewsCount = async () => {
  //   try {
  //     const response = await totalViewsCountServices(mlsId);
  //     return response;
  //   } catch (error) {
  //     return notification("Something Went Wrong!", "error");
  //   }
  // };

  const checkIsBuyerLinked = () => {
    try {
      setIsBuyerLinked(userState?.buyer?.isLinked);
    } catch (error) {
      console.log('error is ', error);
    }
  };
  useEffect(() => {
    checkIsBuyerLinked();
    getPropertyDetails();

    // getTotalViewsCount();
  }, []);

  const findAnAgentModalHandler = () => {
    /* Redirect in Property details page */
    // history.push(`/propertydetails/${mlsId}`)
    history.push(`/agentBuyerPropertyCalendar/${mlsId}`);

    //   if buyer is not logged in
    if (!userState.isLoggedIn) {
      notification(
        'You need to login before you can perform this action.',
        'error'
      );
      return;
    }
    if (userState.isLoggedIn && userState.userType === UserType.AGENT) {
      return;
    }
    // if buyer is login but not linked with any agent
    if (
      userState.isLoggedIn &&
      !isBuyerLinked &&
      userState.userType === UserType.BUYER
    ) {
      setIsFindAnAgentModal(!isFindAnAgentModal);
      return;
    }
  };

  //RENDER

  const renderPropertyDetailItem = (name: string, items: string[]) => {
    return (
      <div className="property-details-page__property-details-item">
        <h5>{name}</h5>
        <ul style={{ paddingLeft: '0%' }}>
          {items.map((item) => {
            return <li>{item}</li>;
          })}
        </ul>
      </div>
    );
  };

  const renderTaxTimeline = () => {
    const columns = [
      {
        title: 'Year',
        dataIndex: 'year',
        key: 'year',
        // render: (text) => <a>{text}</a>,
      },
      {
        title: 'Property Tax',
        dataIndex: 'property_tax',
        key: 'property_tax',
      },
      {
        title: 'Land',
        dataIndex: 'land',
        key: 'land',
      },
      {
        title: 'Additions',
        dataIndex: 'additions',
        key: 'additions',
      },
      {
        title: 'Assessed value',
        dataIndex: 'assessed_value',
        key: 'assessed_value',
      },
    ];

    const data = [
      {
        key: `${taxDet?.id}`,
        year: `${taxDet?.taxYear}`,
        property_tax: `$${taxDet?.taxAnnualAmount?.toLocaleString()}`,
        land: `${taxDet?.land}`,
        additions: `${taxDet?.additions}`,
        assessed_value: `${taxDet?.assessedValue}`,
      },
    ];
    return (
      <div className="property-details-page__sales-and-tax-history__timeline">
        <Table columns={columns} dataSource={data} pagination={false} />
      </div>
    );
  };

  const renderSchools = () => {
    return (
      <div className="property-details-page__school-information__school-info">
        <div className="property-details-page__school-information__header">
          <p className="property-details-page__school-information__rating">
            GreatSchools Rating
          </p>
          <p className="property-details-page__school-information__school">
            School
          </p>
        </div>
        {schoolInfo?.length > 0 &&
          schoolInfo
            ?.slice(0, showAllSchool ? schoolInfo?.length : 3)
            ?.map((item) => {
              return (
                <SchoolInformationSummary
                  rating={item.rating || 0}
                  schoolName={item.name}
                  schoolType={item.level}
                  schoolUrl={item[`overview-url`]}
                  type={item.type}
                  distance={item.distance.toFixed(2)}
                />
              );
            })}
      </div>
    );
  };

  const showSalesTab = () => {
    setIsShowingSalesTab(true);
  };

  const showTaxTab = () => {
    setIsShowingSalesTab(false);
  };

  const PricePerSqrt = (sqrtPrice: any, area: any) => {
    if (!sqrtPrice || !area) {
      return 'N/A';
    } else {
      let ans = 0;

      ans += sqrtPrice / area;
      ans = parseInt(ans.toString());
      return ans?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      });
    }
  };

  const handleSharePropModal = () => {
    setSharePropModal(!sharePropModal);
  };

  const data = propDetail?.lotSizeArea;
  const handleChange = (value: any) => {
    if (value === 'Acres') {
      setArea1(data);
    }
    if (value === 'sqft') {
      if (data !== 'N/A') {
        const c = Number(data) * 43560;
        const b = parseFloat(c.toString()).toFixed(0);
        setArea1(b);
      }
    }
  };

  return (
    <>
      {isLoading && <CustomSpinner />}
      {isDetailsLoad ? (
        <></>
      ) : (
        <Row>
          <Col sm={24} md={24} lg={24} xl={18}>
            <CustomGalleryDiv>
              <ImageGallery
                showThumbnails={true}
                slideOnThumbnailOver={true}
                startIndex={0}
                showPlayButton={true}
                showIndex={true}
                items={galleryImage}
              />
            </CustomGalleryDiv>
            <div className="property-details-page__about">
              <CustomHeading>
                About t
                <span
                  style={{
                    borderBottom: '3px solid #C2D500',
                    paddingBottom: '3px',
                  }}
                >
                  he Property
                </span>
              </CustomHeading>
              <p>{remarks}</p>
            </div>

            <div className="property-details-page__basic-details">
              <CustomHeading>
                B
                <span
                  style={{
                    borderBottom: '3px solid #C2D500',
                    paddingBottom: '3px',
                  }}
                >
                  asic Details
                </span>{' '}
              </CustomHeading>
              <div className="property-details-page__basic-details__container">
                <ul>
                  <li>
                    Listing Price:{' '}
                    {Number(listPrice).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 3,
                    })}
                  </li>
                  <li>Status: {propDetail.mls.status}</li>
                  <li>Property Type: {propDetail.type}</li>
                  <li>Property subType: {propDetail.subTypeText}</li>
                </ul>
                <ul>
                  <li>Bedrooms: {propDetail.bedrooms}</li>
                  <li>Community: {address.city}</li>
                </ul>
                <ul>
                  <li>Buyer Brokerage Compensation: {propDetail.brokerage}</li>
                  <li>
                    Bath: {propDetail.bathsFull} full, {propDetail.bathsHalf}{' '}
                    partial
                  </li>
                  <li>MLS #: {propDetail?.listingId ?? 'N/A'} </li>
                </ul>
              </div>
            </div>
            <div className="property-details-page__property-details">
              <CustomHeading>
                Prop
                <span
                  style={{
                    borderBottom: '3px solid #C2D500',
                    paddingBottom: '3px',
                  }}
                >
                  erty Details
                </span>{' '}
              </CustomHeading>
              <div className="property-details-page__property-details__group">
                <h4>Interior Features</h4>
                <div className="property-details-page__details-container">
                  <div className="property-details-page__details-container__column">
                    {renderPropertyDetailItem('Bedroom Details', [
                      `# of bedrooms: ${propDetail.bedrooms}`,
                    ])}
                    {renderPropertyDetailItem('Interior Features', [
                      `${propDetail.interior}`,
                    ])}
                    {renderPropertyDetailItem('Equipment', [
                      `${propDetail.laundryfeat}`,
                    ])}
                    {renderPropertyDetailItem('Fireplace Details', [
                      `# of fireplaces: ${propDetail.fireplaces}`,
                    ])}
                  </div>
                  <div className="property-details-page__details-container__column">
                    {renderPropertyDetailItem('Bathroom Details', [
                      `# of baths(full): ${propDetail.bathsFull}`,
                      `# of baths(3/4): ${propDetail.bathsThreeQuarter}`,
                      `# of baths(1/2): ${propDetail.bathsHalf}`,
                    ])}
                    {renderPropertyDetailItem('Flooring Details', [
                      `${propDetail.flooring}`,
                    ])}
                  </div>
                  <div className="property-details-page__details-container__column">
                    {renderPropertyDetailItem('Room Information', ['N/A'])}
                    {renderPropertyDetailItem('Heating', [
                      `${propDetail?.heating ?? 'N/A'}`,
                    ])}
                    {renderPropertyDetailItem('Cooling', [
                      `${propDetail?.cooling ?? 'N/A'}`,
                    ])}
                  </div>
                </div>
              </div>
              <div className="property-details-page__property-details__group">
                <h4>
                  Virtual Tour, Parking /Garage, Exterior Features, School /
                  Neighbourhood
                </h4>
                <div className="property-details-page__details-container">
                  <div className="property-details-page__details-container__column">
                    {renderPropertyDetailItem('Virtual Tour', [
                      `${propDetail?.virtual_tour ?? ''}`,
                    ])}
                    {renderPropertyDetailItem('Parking Details', [
                      `# of covered spaces: ${propDetail?.parking?.spaces} `,
                      `Description: ${
                        propDetail?.parking?.description || 'N/A'
                      }`,
                    ])}
                  </div>
                  <div className="property-details-page__details-container__column">
                    {renderPropertyDetailItem('Building Information', [
                      'Built on site',
                      `${propDetail.foundation}`,
                      `${propDetail.roof}`,
                    ])}
                  </div>
                  <div className="property-details-page__details-container__column">
                    {renderPropertyDetailItem('School Information', [
                      `School District: ${
                        schoolInfo?.length > 0 ? schoolInfo?.[0]?.city : 'N/A'
                      }`,
                      //
                    ])}
                  </div>
                </div>
              </div>
              <div className="property-details-page__property-details__group">
                <h4>Utilities, Financing, Location Details</h4>
                <div className="property-details-page__details-container">
                  <div className="property-details-page__details-container__column">
                    {renderPropertyDetailItem('Utilities Information', ['N/A'])}
                  </div>
                  <div className="property-details-page__details-container__column">
                    {renderPropertyDetailItem('Financial Information', [
                      'Cash out, conventional',
                      `Annual taxes: ${taxDet.taxAnnualAmount.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3,
                      })}`,
                      `Tax year: ${taxDet.taxYear}`,
                    ])}
                  </div>
                  <div className="property-details-page__details-container__column">
                    {renderPropertyDetailItem('Location Information', [
                      `${propDetail.directions}`,
                    ])}
                  </div>
                </div>
              </div>
              <div className="property-details-page__property-details__group">
                <h4>Listing Information</h4>
                <div className="property-details-page__details-container">
                  <div className="property-details-page__details-container__column">
                    {renderPropertyDetailItem('Listing Information', ['N/A'])}
                  </div>
                  <div className="property-details-page__details-container__column">
                    {renderPropertyDetailItem('Buyer Brokerage Compensation', [
                      `${propDetail.brokerage}`,
                    ])}
                  </div>
                </div>
              </div>
            </div>
            <div className="property-details-page__sales-and-tax-history">
              <CustomHeading>
                Sales and{' '}
                <span
                  style={{
                    borderBottom: '3px solid #C2D500',
                    paddingBottom: '3px',
                  }}
                >
                  {' '}
                  Tax History
                </span>
              </CustomHeading>
              <div className="property-details-page__tabs">
                <div className="property-details-page__tabs__buttons">
                  <div
                    className={`property-details-page__tabs__button ${
                      isShowingSalesTab &&
                      'property-details-page__tabs__button--selected'
                    }`}
                    onClick={showSalesTab}
                  >
                    <p>Sales</p>
                  </div>
                  <div
                    className={`property-details-page__tabs__button ${
                      !isShowingSalesTab &&
                      'property-details-page__tabs__button--selected'
                    }`}
                    onClick={showTaxTab}
                  >
                    <p>Tax</p>
                  </div>
                </div>
                <div
                  className={`property-details-page__sales-tab ${
                    isShowingSalesTab ? 'visible' : 'hidden'
                  }`}
                >
                  <div className="property-details-page__sales-summary">
                    <p>Status: Listed ({propDetail.mls.status})</p>
                    <p>N/A</p>
                  </div>
                  {/* {renderSalesTimeline()} */}
                </div>
                <div
                  className={`property-details-page__tax-tab ${
                    isShowingSalesTab ? 'hidden' : 'visible'
                  }`}
                >
                  {renderTaxTimeline()}
                </div>
              </div>
            </div>
            <Row>
              <div className="property-details-page__financial-information">
                <CustomHeading>
                  Financial
                  <span
                    style={{
                      borderBottom: '3px solid #C2D500',
                      paddingBottom: '3px',
                    }}
                  >
                    {' '}
                    Information
                  </span>
                </CustomHeading>
                <div className="property-details-page__tabs">
                  <div className="property-details-page__tabs__buttons">
                    <div className="property-details-page__tabs__button property-details-page__tabs__button--selected">
                      <p>Payment Calculator</p>
                    </div>
                  </div>
                  <div className="property-details-page__payment-calculator-tab visible">
                    <div className="property-details-page__payment-calculator__container">
                      <div className="property-details-age__payment-calculator__property-price property-details-age__payment-calculator__item">
                        <p>Property Price</p>
                        <p>
                          {Number(listPrice).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 3,
                          })}
                        </p>
                      </div>
                      <div className="property-details-age__payment-calculator__price-per-sqft property-details-age__payment-calculator__item">
                        <p>Price per sqft</p>
                        <p>{PricePerSqrt(sqrtPrice, area)}</p>
                      </div>
                      <div className="property-details-age__payment-calculator__annual-taxes property-details-age__payment-calculator__item">
                        <p>Annual Taxes</p>
                        <p>
                          {taxDet.taxAnnualAmount.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 3,
                    })}({taxDet.taxYear})
                        </p>
                      </div>
                      <div className="property-details-age__paSales and Tax Historyyment-calculator__down-payment property-details-age__payment-calculator__item">
                        <p>Down Payment</p>
                        <p>N/A</p>
                      </div>
                      <div className="property-details-age__payment-calculator__loan-type property-details-age__payment-calculator__item">
                        <p>Loan Type</p>
                        <p>N/A</p>
                      </div>
                      <div className="property-details-age__payment-calculator__interest-rate property-details-age__payment-calculator__item">
                        <p>Interest Rate</p>
                        <p>N/A</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            <div className="property-details-page__school-information">
              <CustomHeading>
                School
                <span
                  style={{
                    borderBottom: '3px solid #C2D500',
                    paddingBottom: '3px',
                  }}
                >
                  {' '}
                  Information
                </span>
              </CustomHeading>
              <p>
                This home is within the{' '}
                {schoolInfo?.length > 0 ? schoolInfo?.[0]?.city : 'N/A'} Public
                Schools. {schoolInfo?.length > 0 ? schoolInfo?.[0]?.city : 'Massachusetts'}'s enrollment policy is not based solely on
                geography. Please check the school district website to see all
                schools serving this home.
              </p>
              {renderSchools()}
              {/* {!showAllSchool && (
            <p
              className="property-details-page__school-information__see-more"
              onClick={() => setShowAllSchool(true)}
            >
              See more
            </p>
          )} */}
              {!showAllSchool ? (
                <p
                  className="property-details-page__school-information__see-more"
                  onClick={() => {
                    setShowAllSchool(!showAllSchool);
                  }}
                >
                  See more
                </p>
              ) : (
                <p
                  className="property-details-page__school-information__see-more"
                  onClick={() => {
                    window.scrollTo({ top: 2600 });
                    setShowAllSchool(!showAllSchool);
                  }}
                >
                  See less
                </p>
              )}
              <p className="property-details-page__school-information__asterisc">
                * School data provided by GreatSchools. School service
                boundaries are intended to be used as reference only. To verify
                enrollment eligibility for a property, contact school directly.
                <br />
              <img src={GreatSchoolLogo} alt="GreatSchools.org brand logo" className="GreatSchoolsLogo" onClick={() => { window.open("https://www.greatschools.org") }} />
              <sup>**</sup>Source: <a href="https://www.greatschools.org" target="_blank" rel="noopener noreferrer">GreatSchools.org</a> © {new Date().getFullYear()}. All rights reserved.
            </p>
            </div>
          </Col>
          <CustomCOl
            sm={24}
            md={24}
            lg={24}
            xl={6}
            className="sticky"
            style={isMD ? { paddingRight: '44px' } : { paddingRight: '30px' }}
          >
            <div className="sticky" style={{ paddingLeft: '4px' }}>
              <Row justify="space-between">
                <Col style={{ display: 'flex' }}>
                  <CustomPriceHeading>
                    {Number(listPrice).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 3,
                    })}
                  </CustomPriceHeading>
                  <img
                    src={iconConnected}
                    style={{ marginLeft: '12px', alignSelf: 'center' }}
                    alt="home icon"
                  />
                </Col>
                <Col style={{ display: 'flex', alignSelf: 'center' }}>
                  <img
                    role="button"
                    width={20}
                    height={20}
                    src={shareImg}
                    alt="share icon"
                    onClick={handleSharePropModal}
                  />
                </Col>
              </Row>
              <CustomRow justify="start">
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
                  {geoLoc && (
                    <GoogleMapReact
                      lat={geoLoc?.lat}
                      lng={geoLoc?.lng}
                      direction={address?.fullAddress}
                      isZoomed={true}
                    />
                  )}
                </Col>
                <Col
                  span={screens.xl ? 10 : 12}
                  className="mark_location_details"
                >
                  <h4 style={{ fontSize: '24px', fontWeight: 'bold' }}>
                    Location
                  </h4>
                  <CustomPara>
                    {Number(area)?.toLocaleString('en-US', {
                      currency: 'USD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 3,
                    })}
                    {'  '}sqft <br />
                    {address?.streetNumber} {'  '} {address?.streetName}
                  </CustomPara>
                  <CustomPara>
                    {address?.city}, {address?.state}-{address?.postalCode}
                  </CustomPara>
                </Col>
                {/* </div> */}
              </CustomRow>
              <CustomDiv>Property type: {propDetail?.type}</CustomDiv>
              <CustomDiv>Status: {propDetail?.mls?.status}, For Sale</CustomDiv>
              <CustomDiv>
                Price per sqft: {PricePerSqrt(sqrtPrice, area)}
              </CustomDiv>
              <CustomDiv>
                Year Built: {yearBuilt}, converted {propDetail?.convertedYr}
              </CustomDiv>
              <CustomDiv>
                Lot size: {propDetail?.lotSizeArea}{' '}
                {propDetail?.lotSizeAreaUnits}
              </CustomDiv>
              <CustomDiv>Upcoming Open House: </CustomDiv>
              <CustomDiv>Last checked: </CustomDiv>
              <CustomDiv>Listed on NWMLS</CustomDiv>
              <Row style={{ width: screens.xl ? '90%' : '95%' }}>
                <CustomDivider />

                {isFindAnAgentModal && (
                  <FindAnAgentModal
                    isShowing={isFindAnAgentModal}
                    closeAction={findAnAgentModalHandler}
                  />
                )}
                {sharePropModal && (
                  <PropertyShareModal
                    isShowing={sharePropModal}
                    closeAction={handleSharePropModal}
                    imageurl={imageSrc ?? ''}
                    propertyAddress={`${address?.streetNumber}  ${address?.streetName}
                                ${address?.city}, ${address?.state} ${address?.postalCode}`}
                    listingId={propDetail?.listingId ?? ''}
                    listingAgentName={
                      propDetail?.agent?.firstName +
                        ' ' +
                        propDetail?.agent?.lastName || 'N/A'
                    }
                    mlsId={propDetail?.mlsId}
                  />
                )}
              </Row>
            </div>
          </CustomCOl>
        </Row>
      )}
    </>
  );
};

export default PropertyDetailsForSellerPage;
