import React, { useContext } from 'react';
import UserContext, { UserType } from '../../contexts/UserContext';
interface MarkerSvgProps {
  isAccepted?: boolean;
  isRejected?: boolean;
  type?: string;
  isSearchLocBox?: string;
  isOutOfTheBox?: string;
}
const MarkerSvg: React.FC<MarkerSvgProps> = ({
  isAccepted,
  isRejected,
  type,
  isSearchLocBox,
  isOutOfTheBox,
}) => {
  let color = '#004876';
  // #9B0C04

  if (isSearchLocBox === 'YES') {
    color = '#ff8237';
  } else if (isOutOfTheBox === 'YES') {
    color = '#9B0C04';
  } else if (type === 'Scheduled') {
    if (isAccepted) {
      color = '#004876';
    } else if (isRejected) {
      color = '#701642';
      //#a6e015'
    } else {
      color = '#ff8237';
    }
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.973"
      height="30"
      viewBox="0 0 23.973 40"
    >
      <g
        id="Group_732"
        data-name="Group 732"
        transform="translate(-1392 -377.999)"
      >
        <path
          id="Path_16"
          data-name="Path 16"
          d="M2009.211,869.485s-10.241-20.385-11.2-24-1.656-16,11.2-16,12.958,11.725,11.911,16S2009.211,869.485,2009.211,869.485Z"
          transform="translate(-605.549 -451.486)"
          fill={color}
        />
        {/* <!-- fill="#004876"  #ff8237 for orange--> */}
        <g
          id="Ellipse_13"
          data-name="Ellipse 13"
          transform="translate(1399.982 386)"
          fill="#fff"
          stroke="#fff"
          stroke-width="2"
        >
          <circle cx="4" cy="4" r="4" stroke="none" />
          <circle cx="4" cy="4" r="3" fill="none" />
        </g>
      </g>
    </svg>
  );
};

export default MarkerSvg;
