import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import buttonClose from '../assets/images/button-close.svg';
import Button, { ButtonType } from '../components/Button';
import { notification } from '../components/notification';
import CustomSpinner from '../components/spinner/CustomSpinner';
import TextField, {
  TextFieldIconType,
  TextFieldType,
} from '../components/TextField';
import UserContext from '../contexts/UserContext';
import PropertyFiltersContext from '../contexts/PropertyFiltersContext';
import {
  createBuyerNotificationByMail,
  inviteBuyerToAgentService,
  postMessageToUser,
} from '../services/NavihomeService';
import styled from '@emotion/styled';
import validator from 'email-validator';

interface InviteBuyerModalProps {
  isShowing: boolean;
  closeAction: () => void;
}

const CustomModalTitle = styled.h2`
  color: #004876;
  font-weight: bold;
  margin: 0px;
  padding-bottom: 5px;
`;

const InviteBuyerModal: React.FC<InviteBuyerModalProps> = ({
  isShowing,
  closeAction,
}) => {
  const { userState } = useContext(UserContext);
  const { setCreateNotificationsInfo, setGetNotificationsUserEmail } =
    useContext(PropertyFiltersContext);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('Hello! Connect with me on Navihome so I can help you schedule properties tours easily and effortlessly');
  const [isLoading, setIsLoading] = useState(false);
  const [isInput, setIsInput] = useState(false);
  const [isInputMessage, setIsInputMessage] = useState('');

  const inviteBuyer = async () => {
 
    const token = userState?.agent?.token;
    setIsLoading(true);
    try {
      const result: any = await inviteBuyerToAgentService(
        name,
        email,
        message,
        token,
        'buyer'
      );
      if (result?.error) {
        setIsLoading(false);
        return notification(`${result?.message}`, 'error');
      }
      await sendNotificationToBuyer();
      setIsLoading(false);
      closeAction();
      return notification(`Consumer Invited Successfully!`, 'success');
    } catch (error) {
      setIsLoading(false);
    }
  };

  let sendNotificationToBuyer = async () => {
    const token = userState?.agent?.token;
    setIsLoading(true);
    try {
      const result: any = await createBuyerNotificationByMail(
        email,
        `Buyer Agent ${userState?.agent?.name.toUpperCase()} wants to connect with you. Accept the request to connect with agent.`,
        'invite',
        token,
        'buyer',
        0
      );
      // await createBuyerNotificationByMail(
      //   email,
      //   `${message}. ${userState?.agent?.name.toUpperCase()} invites you to connect with agent.`,
      //   'comment',
      //   token,
      //   'buyer',
      //   0
      // );
      if (result?.error) {
        setIsLoading(false);
        // return notification(result?.message, 'error');
        return;
      }
      setGetNotificationsUserEmail(email);
      setCreateNotificationsInfo(String(Math.floor(Math.random() * 100 + 1)));
      setIsLoading(false);
      closeAction();
      return;
    } catch (error) {
      setIsLoading(false);
    }
  };

  const checkInputHandler = async () => {
    if (name?.length <= 0) {
      return notification('Please Enter Name!', 'error');
    }
    if (email?.length <= 0) {
      return notification('Please Enter Email!','error');
    }
    if (!validator.validate(email)) {
      return notification("Please Enter Valid Email!", "error");
    }
    if (message?.length <= 0) {
      return notification('Please Enter Message!', 'error');
    }
    await inviteBuyer();
  };

  const modalClassName = isShowing
    ? 'invite-buyer-modal u-visible'
    : 'invite-buyer-modal u-hidden';
  const cardClassName = isShowing
    ? 'invite-buyer-modal__card animation__pop-in'
    : 'invite-buyer-modal__card animation__pop-out';
  const isMobile = useMediaQuery({ maxWidth: 500 });

  return (
    <div className={modalClassName}>
      {isLoading && <CustomSpinner />}
      <div className={cardClassName}>
        <div className="invite-buyer-modal__row">
          <CustomModalTitle>
            Invite ne
            <span style={{ borderBottom: '4px solid #C2D500' }}>w buyers</span>
          </CustomModalTitle>
          <img
            className="invite-buyer-modal__close-button"
            src={buttonClose}
            onClick={closeAction}
            alt="Close button"
          />
        </div>
        <p className="invite-buyer-modal__subtitle">
          Connect with all your clients to schedule tours for them!
        </p>
        {/* {isInput && (
          <p className="invite-buyer-modal__subtitle" style={{ color: 'red' }}>
            {isInputMessage}
          </p>
        )} */}
        <div className="invite-buyer-modal__column">
          <TextField
            value={name}
            setValue={setName}
            placeholder="Jane Doe"
            label="Name"
            type={TextFieldType.ICON_AND_LABEL}
            icon={TextFieldIconType.USER}
            isRequired={true}
            width={isMobile ? undefined : 38.4}
            className="invite-buyer-modal__name-field"
          />
          <TextField
            value={email}
            setValue={setEmail}
            placeholder="janedoe@hotmail.com"
            label="Email"
            type={TextFieldType.ICON_AND_LABEL}
            icon={TextFieldIconType.EMAIL}
            isRequired={true}
            width={isMobile ? undefined : 38.4}
            className="invite-buyer-modal__email-field"
          />
          <TextField
            value={message}
            setValue={setMessage}
            placeholder="Hello! Connect with me on Navihome so I can help you schedule properties tours easily and effortlessly."
            label="Invite message"
            type={TextFieldType.BIG}
            icon={TextFieldIconType.EMAIL}
            isRequired={true}
            width={isMobile ? undefined : 38.4}
            className="invite-buyer-modal__message-field"
          />
          <Button
            type={ButtonType.PRIMARY}
            text="SEND INVITE"
            clickHandler={checkInputHandler}
            width={isMobile ? undefined : 18.5}
            className="invite-buyer-modal__button"
          />
        </div>
      </div>
    </div>
  );
};

export default InviteBuyerModal;
