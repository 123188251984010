import styled from '@emotion/styled';
import { useContext, useState } from 'react';
import MiniCalendar from '../../components/MiniCalendar';
import SearchField from '../../components/SearchField';
import { notification } from '../../components/notification';
import UserContext from '../../contexts/UserContext';
import {
  getListingAddedProperty,
  getSellerProperties
} from '../../services/NavihomeService';
import { activatedPlan } from '../../utils/base';
import UserHeader from '../notificationAgentPage/UserHeader';
import ListingsUpcomingShowings from './listingsUpcomingShowings';
import SellerPropertySection from './mySellerProperties';
import SellerPropertyCard from './sellerProperty';
interface HomepageSaleAgentPagePropsModel {
  isShowings?: boolean;
  isListings?: boolean;
  agentUpcomingTour: Array<any>;
  totalListedProperty: Array<any>;
  getAllListedProperty: () => void;
}
const CalendarHeading = styled.h3`
  margin-bottom: 26px;
  @media screen and (max-width: 327px) {
    &:after {
      display: none;
    }
  }
`;
const UpcomingHeading = styled.h1`
  margin-bottom: 20px;
  font-size: 32px;
  @media screen and (max-width: 432px) {
    font-size: 28px;
  }
  @media screen and (max-width: 283px) {
    font-size: 24px;
  }
`;

const Listings: React.FC<HomepageSaleAgentPagePropsModel> = ({
  agentUpcomingTour,
  totalListedProperty,
  getAllListedProperty,
}) => {
  const { userState } = useContext(UserContext);
  const [sellerProperties, setSellerProperties] = useState<any>(null);
  const [homePage, setHomePage] = useState(false);
  const [upcomingTourDate, setUpcomingTourDate] = useState<any>(null);

  const [upcomingStartEndDate, setUpcomingStartEndDate] = useState<any>({
    startDate: null,
    endDate: null,
  });

  const sellerPropertySearchAction = async (query: string) => {
    try {
      const listingId = Number(query);
      const { length } = await getListingAddedProperty(
        listingId,
        userState?.agent?.token
      );
      if (length === 0) {
        const response: any = await getSellerProperties(
          listingId,
          userState?.agent?.token
        );

        //response?.id = "bb700301"
        let id = 'bb700301';
        if (response && response.length > 0 && id === 'bb700301') {
          setHomePage(false);
          setSellerProperties(response?.[0]);
        } else {
          return notification(`Property doesn't exit!`, 'warning');
        }
      } else {
        return notification('Property already added!', 'warning');
      }
    } catch (err) {
      console.log('ERROR---', err);
    }
  };


  return (
    <div className="homepage-agent-page-listing">
      {activatedPlan?.includes(userState?.agent?.subscription_type) && (
        <UserHeader />
      )}
      <div className="homepage-agent-page-listing__cover">
        <div className="homepage-agent-page-listing-main__cover">
          <div className="homepage-agent-page-listing__cover-content">
            <h2>
              Help people sell their <br />
              homes faster!
            </h2>
            <p>
              Find properties you've listed on the MLS and sell homes faster!
            </p>
            <SearchField
              placeholder="Enter Listing Id"
              searchAction={sellerPropertySearchAction}
              className="homepage-agent-page-listing__buyers-container-search_field"
            />
          </div>
        </div>
      </div>
      {sellerProperties && !homePage ? (
        <SellerPropertyCard
          listingId={sellerProperties?.listingId ?? null}
          agentMlsId={sellerProperties?.agent?.id ?? null}
          propertyId={sellerProperties?.mlsId ?? null}
          image={sellerProperties?.photos?.[0] ?? ''}
          price={sellerProperties?.listPrice ?? null}
          type={sellerProperties?.property?.type ?? ''}
          beds={sellerProperties?.property?.bedrooms ?? 1}
          baths={
            sellerProperties?.property?.bathsFull +
            0.5 * sellerProperties?.property?.bathsHalf ?? 1
          }
          squareFootage={sellerProperties?.property?.area ?? null}
          address1={
            `${sellerProperties?.address?.streetNumber ?? ''} ${sellerProperties?.address?.streetName ?? ''
            }` ?? ''
          }
          address2={`${sellerProperties?.address?.city ?? ''}, ${sellerProperties?.address?.state ?? ''
            } ${sellerProperties?.address?.postalCode ?? ''}`}
          setHomePage={setHomePage}
          sellerProperties={sellerProperties}
          getAllListedProperty={getAllListedProperty}
          city={sellerProperties?.address?.city ?? ''}
        />
      ) : (
        <>
          <div className="homepage-agent-page-listing__container">
            <div className="homepage-agent-page-listing__calendar-container">
              <CalendarHeading>My Calendar</CalendarHeading>
              <div
                className="homepage-agent-page-listing__calendar-card"
                style={{
                  position: 'relative',
                  bottom: '0.3rem',
                  right: '0.1rem',
                }}
              >
                <MiniCalendar
                  setUpcomingTourDate={setUpcomingTourDate}
                  setUpcomingStartEndDate={setUpcomingStartEndDate}
                  month={''}
                />
              </div>
            </div>
            <div className="homepage-agent-page-listing__upcoming-tours-container">
              <UpcomingHeading>
                Upcoming
                <span style={{ borderBottom: '3px solid #C2D500' }}>
                  {' '}
                  Showings
                </span>
              </UpcomingHeading>
              <div className="homepage-agent-page-listing__upcoming-tours-container-table">
                <ListingsUpcomingShowings
                  tours={agentUpcomingTour}
                  upcomingTourDate={upcomingTourDate}
                  upcomingStartEndDate={upcomingStartEndDate}
                  type="home"
                  userState={userState}
                />
              </div>
            </div>
          </div>
          <SellerPropertySection totalListedProperty={totalListedProperty} />
        </>
      )}
    </div>
  );
};

export default Listings;
