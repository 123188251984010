import { useContext, useEffect, useState } from "react";
import placeholder from "../assets/images/placeholder-property-type.svg";
import PropertyFiltersContext from "../contexts/PropertyFiltersContext";
import UserContext from "../contexts/UserContext";

interface PropertyTypeSelectFieldOptionProps {
  value: string;
  imageUrl: string
  text: string
  didSelectValue: (value: string) => void;
  didRemoveValue: (value: string) => void;
}

const PropertyTypeSelectFieldOption: React.FC<
  PropertyTypeSelectFieldOptionProps
> = ({ value, didSelectValue, didRemoveValue, imageUrl, text }) => {
  const [isSelected, setIsSelected] = useState(false);
  const handleClick = () => {
    didSelectValue(value);
    setIsSelected(!isSelected);
  };
  const {
    propertyFiltersState
  } = useContext(PropertyFiltersContext);

  const { propertyType } = propertyFiltersState;

  
  useEffect(()=>{
    if(propertyType?.includes(value)){
      setIsSelected(true);
    }else{
      setIsSelected(false)
    }
  },[propertyType])

  return (
    <li className="property-type-select-field__option" onClick={handleClick}>
      <img src={imageUrl} alt="Property Type" />
      <p style={{ fontWeight: isSelected ? 400 : 300 }}>{text}</p>
    </li>
  );
};

export default PropertyTypeSelectFieldOption;
