/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import NotificationsAgentCard from '../components/notificationAgentPage/notificationAgentCard';
import UserHeader from '../components/notificationAgentPage/UserHeader';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import UserContext from '../contexts/UserContext';
import {
  createBuyerNotificationById,
  getAgentNotifications,
  getBuyerInfoService,
  linkBuyerWithAgent,
  updateAgentNotification,
  postMessageToUser,
} from '../services/NavihomeService';
import { notification } from '../components/notification';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import CustomSpinner from '../components/spinner/CustomSpinner';
import { useGlobal } from 'reactn';
import EmptyDashboardTab from '../components/EmptyDashboardTab/emptyDashboardTab';
import { activatedPlan } from '../utils/base';
import PropertyFiltersContext from '../contexts/PropertyFiltersContext';
TimeAgo.addDefaultLocale(en);

const CustomBackDiv = styled.p`
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  color: #000000;
  &:hover {
    // font-weight: 600;
    text-decoration: underline;
  }
`;

const NotificationsAgentPage: React.FC = () => {
  const history = useHistory();
  const { userState } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [agentNotifications, setAgentNotifications] = useState([]);
  const [globalState, setGlobalState] = useGlobal<any>('notificationBadge');
  const timeAgo = new TimeAgo('en-US');
  const {
    setMessageSocketCaller,
    setNotificationsCount,
    propertyFiltersState,
  } = useContext(PropertyFiltersContext);

  const { receiveNotificationsInfo } = propertyFiltersState;

  const getNotifications = async () => {
    setIsLoading(true);
    try {
      const notifications: any = await getAgentNotifications(
        userState?.agent?.token
      );

      const filteredNotifications =
        notifications?.length > 0 &&
        notifications?.filter((item: any) => !item?.isRead && !item.isRejected);

      let groupedNotifications: any = [];

      if (filteredNotifications?.length > 0) {
        await filteredNotifications?.forEach((notf: any, i: number) => {

          let notfGroup = filteredNotifications?.filter(
            (n: any) => n?.buyerId === notf?.buyerId
          );

          switch (true) {
            case notf?.type === 'removed' || notf?.type === 'rejected':
              groupedNotifications.push({
                buyerId: notf?.buyerId,
                id: notf?.id,
                buyerNotf: [notf],
                type: 'removed',
                createdAt: notf?.createdAt,
                message: notf?.message,
                colorCode: notf?.buyer?.color?.colorCode,
                isNotificationButton: false,
                propertyId: notf.propertyId
              });
              break;
            case i === 0 && notf?.type === 'seller':
              groupedNotifications.push({
                buyerId: notf?.buyerId,
                id: notf?.id,
                buyerNotf: notfGroup.map((nt: any) => nt?.id),
                type: 'seller',
                createdAt:
                  notfGroup?.length > 0 ? notfGroup[0]?.createdAt : new Date(),
                message: `You have ${notfGroup?.length
                  } notifications from seller ${notf?.buyer?.name?.toUpperCase()}`,
                colorCode: notf?.buyer?.color?.colorCode,
                isNotificationButton: true,
                propertyId: notf.propertyId
              });
              break;

            case i === 0 && notf?.type === 'buyerAgent':
              groupedNotifications.push({
                buyerId: notf?.buyerId,
                id: notf?.id,
                buyerNotf: notfGroup.map((nt: any) => nt?.id),
                type: 'buyerAgent',
                propertyId: notf?.propertyId,
                createdAt:
                  notfGroup?.length > 0 ? notfGroup[0]?.createdAt : new Date(),
                message: `You have ${notfGroup?.length
                  } notifications from buyer agent ${notf?.message.includes('buyer agent') ? `${notf?.message?.split('buyer agent')?.[0]?.toUpperCase()}` : notf?.message.includes('is requesting') ? `${notf?.message?.split('is requesting')?.[0]?.toUpperCase()}` : notf?.message.includes('You have') ? `${notf?.message?.split('You have')?.[0]?.toUpperCase()}` : ''}`,
                colorCode: notf?.buyer?.color?.colorCode,
                isNotificationButton: true,
              });
              break;

            case i === 0 && notf?.type !== 'invite':
              groupedNotifications.push({
                buyerId: notf?.buyerId,
                id: notf?.id,
                buyerNotf: notfGroup.map((nt: any) => nt?.id),
                type: 'group',
                createdAt:
                  notfGroup?.length > 0 ? notfGroup[0]?.createdAt : new Date(),
                message: `You have ${notfGroup?.length
                  } notifications from buyer ${notf?.buyer?.name?.toUpperCase()}`,
                colorCode: notf?.buyer?.color?.colorCode,
                isNotificationButton: true,
                propertyId: notf.propertyId
              });
              break;

            case notf?.type === 'invite':
              groupedNotifications.push({
                buyerId: notf?.buyerId,
                id: notf?.id,
                buyerNotf: [notf],
                type: 'invite',
                createdAt: notf?.createdAt,
                message: notf?.message,
                colorCode: notf?.buyer?.color?.colorCode,
                isNotificationButton: false,
                propertyId: notf.propertyId
              });
              break;
            case !groupedNotifications
              .map((nt: any) => nt?.buyerId)
              .includes(notf?.buyerId):
              if (notf?.type === 'buyerAgent') {
                groupedNotifications.push({
                  buyerId: notf?.buyerId,
                  id: notf?.id,
                  buyerNotf: notfGroup.map((nt: any) => nt?.id),
                  type: 'buyerAgent',
                  propertyId: notf?.propertyId,
                  createdAt:
                    notfGroup?.length > 0
                      ? notfGroup[0]?.createdAt
                      : new Date(),
                  message: `You have ${notfGroup?.length
                    } notifications from buyer agent ${notf?.message.includes('buyer agent') ? `${notf?.message?.split('buyer agent')?.[0]?.toUpperCase()}` : notf?.message.includes('is requesting') ? `${notf?.message?.split('is requesting')?.[0]?.toUpperCase()}` : notf?.message.includes('You have') ? `${notf?.message?.split('You have')?.[0]?.toUpperCase()}` : ''}`,
                  colorCode: notf?.buyer?.color?.colorCode,
                  isNotificationButton: true,
                });
              } else if (notf?.type === 'seller') {
                groupedNotifications.push({
                  buyerId: notf?.buyerId,
                  id: notf?.id,
                  buyerNotf: notfGroup.map((nt: any) => nt?.id),
                  type: 'seller',
                  createdAt:
                    notfGroup?.length > 0
                      ? notfGroup[0]?.createdAt
                      : new Date(),
                  message: `You have ${notfGroup?.length
                    } notifications from seller ${notf?.buyer?.name.toUpperCase()}`,
                  colorCode: notf?.buyer?.color?.colorCode,
                  isNotificationButton: true,
                  propertyId: notf.propertyId
                });
              } else {
                groupedNotifications.push({
                  buyerId: notf?.buyerId,
                  id: notf?.id,
                  buyerNotf: notfGroup.map((nt: any) => nt?.id),
                  type: 'group',
                  createdAt:
                    notfGroup?.length > 0
                      ? notfGroup[0]?.createdAt
                      : new Date(),
                  message: `You have ${notfGroup?.length
                    } notifications from buyer ${notf?.buyer?.name.toUpperCase()}`,
                  colorCode: notf?.buyer?.color?.colorCode,
                  isNotificationButton: true,
                  propertyId: notf.propertyId
                });
              }
              break;
            default:
              break;
          }
        });
      }
      setAgentNotifications(groupedNotifications);
      setGlobalState(groupedNotifications?.length);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      // return notification("Something Went Wrong!", "error");
    }
  };

  let connectBuyer = async (buyerId: number) => {
    await linkBuyerWithAgent(buyerId, userState?.agent?.token);
  };

  let sendNotificationToBuyer = async (buyerId: number, type: string) => {
    setIsLoading(true);
    try {
      if (type.toLowerCase() === "rejected") {
        await createBuyerNotificationById(
          buyerId,
          `Your Agent ${userState?.agent?.name} ${type} your invitation.`,
          type,
          userState?.agent?.token,
          'buyer',
          0
        );
      } else {
        await createBuyerNotificationById(
          buyerId,
          `Your Agent ${userState?.agent?.name} ${type} your invitation.`,
          type,
          userState?.agent?.token,
          'buyer',
          0
        );
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return notification('Something Went Wrong!', 'error');
    }
  };

  const sendMessageToBuyerPhone = async (phoneNo: string, message: string) => {
    try {
      postMessageToUser(phoneNo, message);
    } catch (error) {
      console.error("error", error);
    }
  }

  const didAccept = async (notificationId: number, buyerId: number) => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      const buyerInfo: any = await getBuyerInfoService(userState?.agent?.token, buyerId);
      if (buyerInfo?.agent?.name?.length > 0) {
        setIsLoading(false);
        if (buyerInfo?.agent?.name === userState?.agent?.name)
          return notification('This buyer is already conncected with you', 'error');
        return notification(`Buyer is already conncected with agent ${buyerInfo?.agent?.name?.toUpperCase()}`, 'error');
      }
      await updateAgentNotification(
        notificationId,
        'accept',
        userState?.agent?.token
      );
      connectBuyer(buyerId);
      sendNotificationToBuyer(buyerId, 'accepted');
      sendMessageToBuyerPhone(buyerInfo?.phone, `Your Agent ${userState?.agent?.name} accepted your invitation.`)
      setMessageSocketCaller(String(Math.floor(Math.random() * 100 + 1)));
      setNotificationsCount(buyerId);
      setIsLoading(false);
      getNotifications();
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return notification('Something Went Wrong!', 'error');
    }
  };

  const didReject = async (notificationId: number, buyerId: number) => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      const updateNotification: any = await updateAgentNotification(
        notificationId,
        'reject',
        userState.agent.token
      );
      sendNotificationToBuyer(buyerId, 'rejected');
      getNotifications();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return notification('Something Went Wrong!', 'error');
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    getNotifications();
  }, [receiveNotificationsInfo]);

  const backButtonHandler = () => {
    history.push('/');
  };

  return (
    <div className="notifications-agent-page-main">
      {activatedPlan.includes(userState?.agent?.subscription_type) && (
        <UserHeader />
      )}
      <CustomBackDiv onClick={backButtonHandler}>Back</CustomBackDiv>
      <div className="notifications-agent-page">
        <div className="notifications-agent-heading">
          <h3>Notifications</h3>
          {/* <button onClick={() => send()}>Call socket</button> */}
        </div>
        {isLoading ? (
          <div>
            <CustomSpinner />
          </div>
        ) : (
          <div>
            {agentNotifications?.length > 0 ? (
              <div>
                {agentNotifications?.map((item: any, index) => {
                  return (
                    <NotificationsAgentCard
                      buyerId={item?.buyerId}
                      notificationId={item?.id}
                      borderColor={item?.colorCode ?? '#004876'}
                      agentNotification={item?.message}
                      time={timeAgo?.format(new Date(item?.createdAt))}
                      isAcceptAndRejectButton={item?.type === 'invite'}
                      didAccept={didAccept}
                      didReject={didReject}
                      notfGroup={item?.buyerNotf}
                      isNotificationButton={item?.isNotificationButton}
                      borderButtomColor={item?.borderButtonColor}
                      key={index}
                      type={item?.type}
                      propertyId={item?.propertyId ?? 0}
                    />
                  );
                })}
              </div>
            ) : (
              <EmptyDashboardTab title="No Notifications" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationsAgentPage;
