/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from '@emotion/styled';
import { Button, Col, Row } from 'antd';
import moment from 'moment';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router-dom';
import iconCalendarWhite from '../../assets/images/icon-calendar-white.svg';
import iconCalendar from '../../assets/images/icon-calendar.svg';
import iconListedBlue from '../../assets/images/icon-listed-blue.svg';
import iconListed from '../../assets/images/icoon-listed.svg';
import CustomButton, { ButtonType } from '../../components/Button';
import { notification } from '../../components/notification';
import AgentContext from '../../contexts/AgentContext';
import UserContext, { UserType } from '../../contexts/UserContext';
import FindAnAgentConfirmModal from '../../modals/FindAnAgentConfirmModal';
import Regina from '../../modals/GoogleMapModals/ReginaGeorge';
import RoutingTourModal from '../../modals/GoogleMapModals/RouteTour';
import Cancel from '../../modals/GoogleMapModals/canceltour';
import ScheduleTourModal from '../../modals/GoogleMapModals/schedulingStatus';
import {
  AddLinkedProperties,
  deleteSavedTours,
  getAgentAccountSettings,
  getFreeSlotsPropertyCalendar,
  inviteAgentByBuyer,
  postBestRoute,
  postMessageToUser,
  updateBestRoute
} from '../../services/NavihomeService';
import { insertEventToGoogleCalendar } from '../../utils/syncEvent';
import CustomSpinner from '../spinner/CustomSpinner';

const LeftButton = styled.div<{ selected: boolean }>`
  background-color: ${(props) => (props?.selected ? '#fff' : '#004876')};
  /* color: ${(props) => (props?.selected ? '#fff' : '#004876')}; */
  height: 100%;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  text-align: center;
`;
const RightButton = styled.div<{ selected: boolean }>`
  background-color: ${(props) => (props?.selected ? '#fff' : '#004876')};
  /* color: ${(props) => (!props?.selected ? '#004876' : '#fff')}; */
  height: 100%;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
  text-align: center;
`;

const CustomRow = styled(Row)`
  flex: 1;
  margin-left: 1rem;
  margin-right: 1rem;
`;
const Buttondiv = styled.div`
  @media screen and (max-width: 554px) {
    display: grid;
    width: 95%;
  }
`;
const CustomHeading = styled.h1`
  font-size: 17.9px;
  font-weight: bold;
  margin-top: 1rem;
  margin-right: 100px;
`;

interface RouteTourHeaderProps {
  linkedProperties?: any;
  buyerGlobalId?: any;
  Setselected?: any;
  selected?: any;
  setBestRoute?: any;
  formData?: any;
  setShowMap?: any;
  setRunApiOnLoad?: any;
  setDashboardState?: any;
  setRunMap?: any;
  runMap?: boolean;
  agentIdForLinkedBuyer?: any;
  setDragDropWorking?: any;
  setNumberingMarker?: any;
  numberingMarker?: boolean;
  setUseManual?: any;
  useManual?: boolean;
  setTravelTime?: any;
  bestRoute?: any;
  statusType?: string;
  saveButtonData?: any;
  formId?: any;
  setUnlinkPropHeader?: any;
  isFormDataChanged?: boolean;
  linkedPropertiesData?: any;
  setShowReScheduleModal?: any;
  isAllAccepted?: boolean;
  isScheduledRouteUpdated?: boolean;
  isOutTimeProperty?: boolean;
  setIsOutTimeProperty?: (val: boolean) => void;
  eliminatePropertyFromTheTour?: number;
  setEliminatePropertyFromTheTour?: (val: number) => void;
  SetShowTimeBar?: any;
  setLinkedPropertiesData?: any;
  filteredLinkedProperties?: Array<any>;
  handleTogglingClicked?: string;
  setHandleTogglingClicked?: (val: string) => void;
  setFilteredLinkedProperties?: any;
  setLinkedPropertiesOutOfBestRoute?: any;
  scheduleStatus?: boolean;
  rescheduledPropData?: Array<any>;
  setScheduleStatus?: (val: boolean) => void;
  setIsUnlinkedPropertyClicked?: (val: boolean) => void;
  isRemoveScheduledPropClicked?: string;
  setIsAllAccepted?: (val: boolean) => void;
  setIsShowSchedulignStatusModal?: (val: boolean) => void;
  setIsRemoveScheduledPropClicked?: (val: string) => void;
  buyerDetails?: any;
  manualChangeProperties?: Array<any>;
  getDirectionsPropertyClicked?: string;
  setScheduleTourClicked?: (val: string) => void;
  setManualChangeProperties?: (val: Array<any>) => void;
  schedulingStatusClicked?: string;
  setTourPropPage?: any
  setselected?: any;
  setUseManualChange?: (val: string) => void;
  useManualChange: string;
}

const RouteTourHeader: React.FC<RouteTourHeaderProps> = ({
  linkedProperties,
  Setselected,
  selected,
  setBestRoute,
  formData,
  setShowMap,
  setRunApiOnLoad,
  runMap,
  setRunMap,
  agentIdForLinkedBuyer,
  setDragDropWorking,
  setNumberingMarker,
  numberingMarker,
  setUseManual,
  setTravelTime,
  statusType,
  bestRoute,
  saveButtonData,
  formId,
  useManual,
  buyerDetails,
  isAllAccepted,
  setUnlinkPropHeader,
  linkedPropertiesData,
  setShowReScheduleModal,
  isOutTimeProperty,
  setIsOutTimeProperty,
  isScheduledRouteUpdated,
  eliminatePropertyFromTheTour,
  setIsShowSchedulignStatusModal,
  setEliminatePropertyFromTheTour,
  SetShowTimeBar,
  setTourPropPage,
  setselected,
  filteredLinkedProperties,
  manualChangeProperties,
  scheduleStatus,
  useManualChange,
  setUseManualChange,
  setIsAllAccepted,
  setScheduleStatus,
  rescheduledPropData,
  handleTogglingClicked,
  setScheduleTourClicked,
  schedulingStatusClicked,
  setHandleTogglingClicked,
  setManualChangeProperties,
  setFilteredLinkedProperties,
  setLinkedPropertiesOutOfBestRoute,
  setIsUnlinkedPropertyClicked,
  isRemoveScheduledPropClicked,
  setIsRemoveScheduledPropClicked,
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { upcomingtour } = useContext(UserContext);
  const { agentState } = useContext(AgentContext);
  const { userState } = useContext(UserContext);
  const [isTourModalShow, setIsTourModalShow] = useState(false);
  const [isScheduleTour, setIsScheduleTour] = useState(false);
  const [agentModal, setAgentModal] = useState<boolean>(false);
  const [isNotEditable, setIsNotEditable] = useState<boolean>(false);
  const isRes = useMediaQuery({ maxWidth: 590 });
  const [locations, setLocations] = useState<any>([]);
  const [propLength, setPropLength] = useState<any>([]);
  const [bestRouteInfo, setBestRouteInfo] = useState<any>([]);
  const [isShowCancelTourModal, setIsShowCancelTourModal] =
    useState<boolean>(false);
  const [isShowCancelTourModalForBuyer, setIsShowCancelTourModalForBuyer] =
    useState<boolean>(false);
  const [saveBtnData, setSaveBtnData] = useState<boolean>(false);

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let param = useQuery();

  const ToggleData = () => {
    Setselected(selected === 'prop' ? 'form' : 'prop');
  };

  const handleRouteTourModal = async () => {
    // setScheduleStatus(!scheduleStatus)
    //  this state is used to disable Schedule Tour
    setScheduleStatus(false);
    setRunMap(true);
    setIsTourModalShow(!isTourModalShow);
    setDragDropWorking(true);
    setUseManual(false);
    setUnlinkPropHeader(true);
    setTourPropPage(false);
    setUseManualChange("false")
  };

  const getFreeSlotsForPropId = async (propId: number, agentId: number) => {
    return await getFreeSlotsPropertyCalendar(propId, agentId);
  };

  const handleToggling = async (type: string, locationsData?: Array<any>, useManualChangeValue?: string) => {
    if (type !== 'Clicked') { setHandleTogglingClicked('Not Clicked') }
    setIsLoading(true);
    const AllLatLng: any = [];
    let token = '';
    if (userState?.userType === UserType.AGENT) {
      token = userState?.agent?.token;
    } else {
      token = userState?.buyer?.token;
    }
    try {
      if (locations?.length > 1) {
        const tempBestRouteArr: any = [];
        const tempBestRouteArrUndefined: any = [];
        if (useManualChangeValue !== "true" || type === "Clicked") {
          const response: any = await AddLinkedProperties(
            'MCG',
            'Realstate',
            'Mysuru',
            5,
            locations,
            token
          );

          if (response?.name === 'Error') {
            console.error('something went wrong in api');
            return;
          } else {
            setTravelTime(response?.travel_time);
            response?.shortest_path?.split('->')?.map((data: any) => {
              let cityName = data.replace(/[{()}]/g, '');
              for (let ind = 0; ind < response?.locations?.length; ind++) {
                if (response?.locations?.[ind]?.cityNameKey === cityName) {
                  const latlng = {
                    lat: response?.locations?.[ind]?.latitude,
                    lng: response?.locations?.[ind]?.longitude,
                    city: response?.locations?.[ind]?.cityNameKey,
                    index: ind + 1,
                    // for now i'll take some random time inplace of null
                    time: response?.travel_time[ind + 1]
                      ? response?.travel_time[ind + 1]
                      : 2092,
                  };
                  AllLatLng.push(latlng);
                  break;
                }
              }
              // TODO : optimization on this logic most imp for higher number of properties
              //steps :- Traverse locations array and store cityname latitude and longitude in object
              //after that traverse on shorted string which is now an array using split function
              //now object takes O(1) time to find any cityname
              // if cityname is find than create an object and get lat and lng and store into array

              // response?.location?.map((val:any) => {
              //   if (val?.cityNameKey === cityName) {
              //     const latlng = {lat: val?.latitude, lng:val?.longitude }
              //     AllLatLng.push(latlng);
              //     }
              //   })
            });
            AllLatLng?.map((data: any, index: number) => {
              const myVal: any = linkedPropertiesData?.find(
                (val: any, indexVal: number) => {
                  if (
                    data?.lat === val?.geo?.lat &&
                    data?.lng === val?.geo?.lng
                  ) {
                    data['indexVal'] = indexVal + 1;
                    return val;
                  }
                }
              );
              if (myVal) {
                myVal['isOutOfTheBox'] = 'NO';
                tempBestRouteArr.push(myVal);
              }
            });
            linkedPropertiesData?.map((data: any, index: number) => {
              const myVal: any = AllLatLng?.find((val: any, indexVal: number) => {
                if (
                  (useManual
                    ? data?.geo?.lat
                      ? data?.geo?.lat
                      : data?.lat
                    : data?.lat
                      ? data?.lat
                      : data?.geo?.lat) === val?.lat
                    ? val?.lat
                    : val?.geo?.lat &&
                      (useManual
                        ? data?.geo?.lng
                          ? data?.geo?.lng
                          : data?.lng
                        : data?.geo?.lng
                          ? data?.geo?.lng
                          : data?.lng) === val?.lng
                      ? val?.lng
                      : val?.geo?.lng
                ) {
                  data['indexVal'] = indexVal + 1;
                  return val;
                }
              });
              if (!myVal) {
                data['isOutOfTheBox'] = 'YES';
                tempBestRouteArrUndefined.push(data);
              }
            });
          }
        }
        // use update api update form data
        // if(param.get('formId')){
        // }
        //TODO sort properties and set time stamp according to best route
        // if (type === 'Clicked') {

       
      


        let filteredTempBestRouteArr: any = useManualChangeValue === "true" && type !== "Clicked" ? [...linkedPropertiesData] : [
          ...tempBestRouteArr,
          ...tempBestRouteArrUndefined,
        ];

        setLinkedPropertiesOutOfBestRoute(useManualChangeValue === "true" && type !== "Clicked" ? [] : [...tempBestRouteArrUndefined]);
        if (type === 'Clicked' || handleTogglingClicked === 'Clicked') {
          let bestRouteResultData: any = [];
          let temptimestamp = '';
          for (let i = 0; i < filteredTempBestRouteArr?.length; i++) {
            let endtimestamp = '';
            let starttimestamp = '';
            let dataVal = '';

            //TODO time stamp
            if (formData?.dateTime && filteredTempBestRouteArr[i].isOutOfTheBox !== "YES") {
              dataVal = formData?.dateTime?.split(',')?.[1]?.split('-')?.[0];
              if (i === 0) {
                endtimestamp = moment(dataVal, 'HH:mm:ss A')
                  ?.add(30, 'minutes')
                  ?.add(
                    Number(formData?.timePerProp?.split(' ')?.[0]),
                    'minutes'
                  )
                  ?.format('HH:mm:ss A');
                temptimestamp = String(
                  moment(endtimestamp, 'HH:mm:ss A')
                    .add(Math.floor(Math.random() * 9) + 12, 'minutes')
                    .format('HH:mm:ss A')
                );
                starttimestamp = String(
                  moment(dataVal, 'HH:mm:ss A')
                    .add(30, 'minutes')
                    .format('HH:mm:ss A')
                );
              }
              if (i > 0) {
                starttimestamp = temptimestamp;
                const endproTime = temptimestamp;
                endtimestamp = moment(endproTime, 'HH:mm:ss A')
                  ?.add(
                    Number(formData?.timePerProp?.split(' ')?.[0]),
                    'minutes'
                  )
                  ?.format('HH:mm:ss A');
                temptimestamp = String(
                  moment(endtimestamp, 'HH:mm:ss A')
                    .add(Math.floor(Math.random() * 9) + 12, 'minutes')
                    .format('HH:mm:ss A')
                );
              }
            }
            const item = filteredTempBestRouteArr[i];
            let arr3 = [...rescheduledPropData];

            let myResult: any = null;
            const index3 = arr3?.findIndex(
              (item3: any) =>
                item3?.propertyId === (item?.propertyId || item?.mlsId)
            );
            if (index3 > -1) {
              myResult = {
                ...item,
                startTime: arr3[index3]?.reScheduleStartTime,
                endTime: arr3[index3]?.reScheduleEndTime,
              };
            } else {
              myResult = {
                ...item,
                startTime: starttimestamp,
                endTime: endtimestamp,
              };
            }

            bestRouteResultData.push(myResult);
          }
          setFilteredLinkedProperties(bestRouteResultData);
        } else {
          setFilteredLinkedProperties(filteredTempBestRouteArr);
        }
        if (type === 'Clicked' || handleTogglingClicked === 'Clicked') {
          const start = formData?.dateTime?.split(",").pop();
          const startTime = start?.split("-")[0];
          const endTime = formData?.dateTime?.split("-").pop();
          const startTimeTemp = moment(startTime, 'hh:mm A');
          const endTimeTemp = moment(endTime, 'hh:mm A');
          const minutesDifference = endTimeTemp.diff(startTimeTemp, 'minutes');
          let totalTime = Number(minutesDifference) - 30;
          let time = 0;
          let inTimeProperty: any = [],
            outTimeProperty: any = [];
          (filteredLinkedProperties?.length > 0 ? filteredLinkedProperties : linkedPropertiesData)?.map((property: any) => {
            time += Number(formData?.timePerProp.split(" ")?.[0]);
            if (totalTime >= time) {
              inTimeProperty.push(property);
            } else {
              outTimeProperty.push(property);
            }
            totalTime -= Math.floor(Math.random() * 9) + 12;
          });
          if (outTimeProperty?.length > 0) {
            setIsOutTimeProperty(true);
            setEliminatePropertyFromTheTour(outTimeProperty?.length);
          }
          else {
            setIsOutTimeProperty(false);
            setEliminatePropertyFromTheTour(0);
          }
        }

        console.log("AllLatLng", AllLatLng, "use", useManualChangeValue, "type", type);
        

        setBestRoute(useManualChangeValue !== "true" || type === "Clicked" ? [...AllLatLng] : [...linkedPropertiesData]);
        setBestRouteInfo(useManualChangeValue !== "true" || type === "Clicked" ? [...AllLatLng] : [...linkedPropertiesData]);
        setDragDropWorking(true);
        // setRunMap(true);
        setIsLoading(false);
        setManualChangeProperties([]);
        setIsUnlinkedPropertyClicked(false);
        setIsRemoveScheduledPropClicked('NO');
        if (type === 'Clicked') setScheduleStatus(false);
        if (
          param.get('formId')?.length > 0 &&
          param.get('statusType')?.length > 0
        ) {
          return;
        }
        return notification('Your tour has been optimized', 'success');

        // push travel time array to use in tour card
      } else {
        setShowMap(false);
        return notification(
          'You have to select more than one property',
          'error'
        );
      }
    } catch (err) {
      // setIsLoading(false);
      console.error('error', err);
    }
  };


  const handleSaveTourStatus = async () => {
    // here we call  api of update status on a particular id
    let token = '';
    let buyerId = -1;
    let role = '';
    if (userState?.userType === UserType.AGENT) {
      token = userState?.agent?.token;
      buyerId = agentState?.buyerId;
      role = 'Agent';
    } else {
      token = userState?.buyer?.token;
      buyerId = userState?.buyer?.buyerId;
      role = 'Buyer';
    }

    try {
      // todo check this data after completing the api
      setIsLoading(true);

      const startLoc: any =
        typeof formData?.startrouteLocation === 'object'
          ? JSON.stringify(formData?.startrouteLocation)
          : formData?.startrouteLocation;
      const endLoc: any =
        typeof formData?.endrouteLocation === 'object'
          ? JSON.stringify(formData?.endrouteLocation)
          : formData?.endrouteLocation;

      let linkedPropertiesTemp: any =
        manualChangeProperties?.length > 0
          ? manualChangeProperties
          : filteredLinkedProperties?.length > 0
            ? filteredLinkedProperties
            : linkedPropertiesData;

      //TODO after check formData field  if we already have that formId we can update the form Data and properties
      const data = await upcomingtour(
        formData?.dateTime?.split(',')?.[0],
        formData?.dateTime?.split(',')?.[1],
        formData?.timePerProp,
        formData?.startAdd,
        formData?.startAddCity,
        formData?.endingAdd,
        formData?.endingAddCity,
        linkedPropertiesTemp?.length,
        linkedPropertiesTemp?.[0]?.address?.city, // formData?.area,
        'Saved',
        buyerId,
        numberingMarker || param.get('statusType') === 'afterRouteCreated'
          ? 'afterRouteCreated'
          : 'beforeRouteCreated',
        formData?.activeTab,
        useManualChange === "true" ? useManualChange : JSON.stringify(useManual),
        startLoc,
        endLoc,
        userState?.userType === UserType.AGENT
          ? userState?.agent?.agentId
          : agentIdForLinkedBuyer,
        formId ? formId : null,
        token ?? '',
        role,
      );

      if (
        data?.raw?.affectedRows === 0 ||
        data?.affected === 0 ||
        data?.error
      ) {
        setShowMap(false);
        setIsLoading(false);
        return notification('Something Went Wrong.', 'error');
      }
      if (
        data?.generatedMaps?.[0]?.status !== null ||
        data?.raw?.affectedRows !== 0 ||
        data?.affected !== 0
      ) {
        //  here we have to call  routing
        let token: any = '';
        let buyerId: any = '';
        let agentId: any = '';

        if (userState?.userType === UserType.AGENT) {
          token = userState?.agent?.token;
          buyerId = agentState?.buyerId;
          agentId = userState?.agent?.agentId;
        } else {
          token = userState?.buyer?.token;
          buyerId = userState?.buyer?.buyerId;
          agentId = agentIdForLinkedBuyer;
        }

        // here we take a loop pass all the data in the array of objects
        let bestRouteResult: any = [];
        for (let i = 0; i < linkedPropertiesTemp?.length; i++) {
          let endtimestamp = '';
          let starttimestamp = '';

          //TODO time stamp

          const item = linkedPropertiesTemp[i];
          const local1 = moment(formData?.dateTime?.split(',')?.[0], 'MM-DD-YYYY HH:mm:ss').local();
          let tourDate = moment(local1, "YYYY-MM-DD").format("YYYY-MM-DD");

          const myResult = {
            propertyId: linkedPropertiesTemp?.[i]?.mlsId,
            agentId: agentId,
            buyerId: buyerId,
            createdAt: new Date(),
            status: 'Saved',
            formId:
              data?.generatedMaps?.[0]?.formId > 0
                ? data?.generatedMaps?.[0]?.formId
                : Number(param.get('formId')),
            latitude: linkedPropertiesTemp?.[i]?.geo?.lat,
            longitude: linkedPropertiesTemp?.[i]?.geo?.lng,
            userUpcomingToursFormId:
              data?.generatedMaps?.[0]?.formId > 0
                ? data?.generatedMaps?.[0]?.formId
                : param.get('formId'),
            timeStamp: '7:00 - 9:00', //change this with dynamic
            // startDate: new Date(tourDate),
            startDate: new Date(tourDate),
            startTime: linkedPropertiesTemp?.[i]?.startTime
              ? linkedPropertiesTemp?.[i]?.startTime
              : starttimestamp,
            endDate: new Date(tourDate),
            endTime: linkedPropertiesTemp?.[i]?.endTime
              ? linkedPropertiesTemp?.[i]?.endTime
              : endtimestamp,
            propertyDetails: JSON.stringify(item),
            propertyDuration: formData?.timePerProp,
            description: '',
            isAccepted: false,
            isRejected: false,
            type: 'Requested',
            sender: 'buyerAgent',
            isListed: item?.isListed ?? false,
            listingAgentId: item?.listingAgentId ?? '',
            client: item?.client ?? '',
            agentName: item?.agentName
              ? item?.agentName
              : userState?.agent?.name,
            listingAgentEmail: item?.listingAgentEmail ?? '',
            propertyRoutePosition: 10,
            isFavorite: item?.isFavorite ?? false,
            isRequested: item?.isRequested ?? false,
            openHouseStartTime: item?.openHouseStartTime
              ? item?.openHouseStartTime
              : null,
            openHouseEndTime: item?.openHouseEndTime
              ? item?.openHouseEndTime
              : null,
            role: role,
          };
          bestRouteResult.push(myResult);
        }

        // const getResponse: any = await getBestRoute(buyerId, token)
        //  above that data of form stored in db
        if (formId) {
          // to update best Route
          const UpdateData: any = await updateBestRoute(
            bestRouteResult,
            formId,
            token
          );

          // this is for updation
          if (userState?.userType === UserType.AGENT) {
            history.push('/dashboard?buyerId=' + buyerId);
          } else {
            history.push('/buy?buyerId=' + buyerId);
          }
          return notification('Your tour has saved successfully', 'success');
        } else {
          // to create best Route

          const res: any = await postBestRoute(bestRouteResult, token);

          setIsLoading(false);
          if (userState?.userType === UserType.AGENT) {
            if (schedulingStatusClicked === "Save Tour") {
              // `/agentBuyerPropertyCalendar/${mlsId}/?query=${buyerId}&listingId=${propDetail?.listingId}&listingAgentId=${listAgentId}&client=${listAgentSellerName}&listingAgent=${listingAgentName}&fav=${checkIsFavorite}&req=${checkIsRequested}`
              history.push(
                `/agentBuyerPropertyCalendar/196616622/?query=${buyerId}&formId=${bestRouteResult?.[0]?.formId}`
              );
            } else {
              history.push('/dashboard?buyerId=' + buyerId);
            }

          } else {
            history.push('/buy?buyerId=' + buyerId);
          }
          return notification('Your tour has saved successfully', 'success');
        }
      }
    } catch (err) {
      console.error('error==>', err);
    }
  };

  const handleScheduleTourStatus = async () => {
    //  here we call api of Schedule status on a particular id
    let token = '',
      buyerId = -1,
      role = '',
      buyerAgentName: string = userState.userType === UserType.BUYER ? userState?.buyer?.buyerAgentName : userState?.agent?.name;
    if (userState?.userType === UserType.AGENT) {
      token = userState?.agent?.token;
      buyerId = agentState?.buyerId;
      role = 'Agent';
    } else {
      token = userState?.buyer?.token;
      buyerId = userState?.buyer?.buyerId;
      role = 'Buyer';
    }

    try {

      setIsLoading(true);
      const startLoc: any =
        typeof formData?.startrouteLocation === 'object'
          ? JSON.stringify(formData?.startrouteLocation)
          : formData?.startrouteLocation;
      const endLoc: any =
        typeof formData?.endrouteLocation === 'object'
          ? JSON.stringify(formData?.endrouteLocation)
          : formData?.endrouteLocation;

      let filteredTempBestRouteArr: any =
        manualChangeProperties?.length > 0
          ? manualChangeProperties
          : filteredLinkedProperties?.length > 0
            ? filteredLinkedProperties
            : linkedPropertiesData;

      const data: any = await upcomingtour(
        formData?.dateTime?.split(',')?.[0],
        formData?.dateTime?.split(',')?.[1],
        formData?.timePerProp,
        formData?.startAdd,
        formData?.startAddCity,
        formData?.endingAdd,
        formData?.endingAddCity,
        filteredTempBestRouteArr?.length,
        linkedPropertiesData?.[0]?.address?.city, //formData?.area,
        'Scheduled',
        buyerId,
        'afterRouteCreated',
        formData?.activeTab,
        useManualChange === "true" ? useManualChange : JSON.stringify(useManual),
        startLoc,
        endLoc,
        userState?.userType === UserType.AGENT
          ? userState?.agent?.agentId
          : agentIdForLinkedBuyer,
        formId ? formId : null,
        token ?? '',
        role
      );

      if (
        data?.raw?.affectedRows === 0 ||
        data?.affected === 0 ||
        data?.error
      ) {
        setShowMap(false);
        setIsLoading(false);
        return notification('Something Went Wrong.', 'error');
      }
      if (
        data?.generatedMaps?.[0]?.status !== null ||
        data?.raw?.affectedRows !== 0 ||
        data?.affected !== 0
      ) {
        let token: any = '';
        let buyerId: any = '';
        let agentId: any = '';

        if (userState?.userType === UserType.AGENT) {
          token = userState?.agent?.token;
          buyerId = agentState?.buyerId ? agentState?.buyerId : Number(param.get('buyerId'));
          agentId = userState?.agent?.agentId;
        } else {
          token = userState?.buyer?.token;
          buyerId = userState?.buyer?.buyerId;
          agentId = userState?.buyer?.buyerAgentId
            ? userState?.buyer?.buyerAgentId
            : agentIdForLinkedBuyer;
        }


        let routeArr = [];
        let temptimestamp = '';
        let isListedArr = [];
        // let email = await getSignedInUserEmail();
        let email = null;
        for (let i = 0; i < filteredTempBestRouteArr?.length; i++) {
          let endtimestamp = '';
          let starttimestamp = '';

          let dataVal = '';
          let dateforAgentAcomm = '';


          if (formData?.dateTime && filteredTempBestRouteArr[i]?.isOutOfTheBox !== "YES") {
            dateforAgentAcomm = formData?.dateTime?.split(',')?.[0];
            dataVal = formData?.dateTime?.split(',')?.[1]?.split('-')?.[0];
            if (i === 0) {
              endtimestamp = moment(dataVal, 'HH:mm:ss A')
                ?.add(30, 'minutes')
                ?.add(Number(formData?.timePerProp?.split(' ')?.[0]), 'minutes')
                // ?.add(travelTime?.[index + 1], 'minutes')
                ?.format('HH:mm:ss A');
              temptimestamp = String(
                moment(endtimestamp, 'HH:mm:ss A')
                  .add(Math.floor(Math.random() * 9) + 12, 'minutes')
                  .format('HH:mm:ss A')
              );
              starttimestamp = String(
                moment(dataVal, 'HH:mm:ss A')
                  .add(30, 'minutes')
                  .format('HH:mm:ss A')
              );
            }
            if (i > 0) {
              starttimestamp = temptimestamp;

              const endproTime = temptimestamp;

              endtimestamp = moment(endproTime, 'HH:mm:ss A')
                ?.add(Number(formData?.timePerProp?.split(' ')?.[0]), 'minutes')
                // ?.add(
                //   travelTime?.[index + 1] - travelTime?.[index],
                //   'minutes',
                // )
                ?.format('HH:mm:ss A');
              temptimestamp = String(
                moment(endtimestamp, 'HH:mm:ss A')
                  .add(Math.floor(Math.random() * 9) + 12, 'minutes')
                  .format('HH:mm:ss A')
              );
            }
          }

          const item = filteredTempBestRouteArr[i];

          let listAgentName: string = item?.agent?.firstName +
            " " +
            item?.agent?.lastName;

          const local1 = moment(formData?.dateTime?.split(',')?.[0], 'MM-DD-YYYY HH:mm:ss').local();
          let tourDate = moment(local1, "YYYY-MM-DD").format("YYYY-MM-DD");
          if (item?.isListed) {
            isListedArr.push({
              listingAgentId: item?.listingAgentId,
              listingId: item?.listingId,
              buyerId: item?.buyerId,
              propertyId: item?.mlsId,
              startDate: tourDate,
              listingAgent: item?.listingAgent,
              startTime: moment(starttimestamp?.split(' ')?.[0], [
                'HH:mm:ss',
              ]).format('HH:mm A'),
              endTime: moment(endtimestamp?.split(' ')?.[0], [
                'HH:mm:ss',
              ]).format('HH:mm A'),
              address: `${item?.address?.full} ${item?.address?.city}, ${item?.address?.state} ${item?.address?.postalCode}`
            });
          }


          let routeObj = {
            propertyId: item?.mlsId,
            agentId: agentId,
            buyerId: buyerId,
            createdAt: new Date(),
            status: 'Scheduled',
            formId:
              data?.generatedMaps?.[0]?.formId > 0
                ? data?.generatedMaps?.[0]?.formId
                : Number(param.get('formId')),
            latitude: item?.geo?.lat
              ? item?.geo?.lat
              : item?.lat,
            longitude: item?.geo?.lng ? item?.geo?.lng : item?.lng,
            userUpcomingToursFormId:
              data?.generatedMaps?.[0]?.formId > 0
                ? data?.generatedMaps?.[0]?.formId
                : Number(param.get('formId')),
            startDate: new Date(tourDate),
            startTime: item?.startTime,
            timeStamp: '05:30 PM : 07:00 PM',
            endDate: new Date(tourDate),
            endTime: item?.endTime,
            propertyDetails: JSON.stringify(item),
            propertyDuration: formData?.timePerProp,
            description: 'Scheduled',
            isAccepted: item?.isAccepted
              ? item?.isAccepted
              : userState?.userType === UserType.BUYER
                ? true
                : false,
            isRejected: item?.isRejected ? item?.isRejected : false,
            type: 'Requested',
            sender: 'buyerAgent',
            isListed: item?.isListed ?? false,
            listingAgentId: item?.listingAgentId ?? '',
            client: item?.client ?? '',
            agentName: item?.agentName
              ? item?.agentName
              : userState?.agent?.name,
            listingAgentEmail: item?.listingAgentEmail ?? '',
            propertyRoutePosition: 10,
            isFavorite: item?.isFavorite ?? false,
            isRequested: item?.isRequested ?? false,
            openHouseStartTime: item?.openHouseStartTime
              ? item?.openHouseStartTime
              : null,
            openHouseEndTime: item?.openHouseEndTime
              ? item?.openHouseEndTime
              : null,
            role: role,
            systemSchedule: item?.systemSchedule,
            showingType: item?.showingType,
            clientPhone: item?.clientPhone,
            buyerBuyerId: buyerId,
            listingAgentName: item?.listingAgentName ? item?.listingAgentName : listAgentName
          };
          routeArr.push(routeObj);

          if (userState?.userType === UserType?.BUYER && email) {
            const startDateTime = `${moment(new Date(formData?.dateTime?.split(',')?.[0])).format('YYYY-MM-DD')} ${item?.startTime}`;
            const endDateTime = `${moment(new Date(formData?.dateTime?.split(',')?.[0])).format('YYYY-MM-DD')} ${item?.endTime}`;

            const calendarEvent: any = {
              summary: "View Property",
              location: `${item?.address?.streetNumber} ${item?.address?.streetName}, ${item?.address?.city}, ${item?.address?.state}, ${item?.address?.postalCode}`,
              start: {
                dateTime: moment(startDateTime),
                timeZone: "America/Los_Angeles",
              },
              end: {
                dateTime: moment(endDateTime),
                timeZone: "America/Los_Angeles",
              },
            };
            insertEventToGoogleCalendar({ calendarEvent });
          }
        }
        // this state for modal

        if (param.get('formId')?.length > 0) {
          // to update best Route
          const formId: number = Number(param.get('formId'));
          const updateRes: any = await updateBestRoute(routeArr, formId, token);


          if (userState.userType === UserType.BUYER) {
            let messageType: string = 'No';
            const agentAccountSettingsResult: any = await getAgentAccountSettings(Number(userState.buyer?.buyerAgentId));
            const preferredContact: string = userState.buyer?.buyerAgentPreferredContact;

            if (agentAccountSettingsResult?.length > 0) {
              const lastIndex: number = agentAccountSettingsResult?.length - 1;
              messageType = agentAccountSettingsResult?.[lastIndex]?.notifyOpenHoursTours;
            }
            await inviteAgentByBuyer(
              Number(userState.buyer?.buyerAgentId),
              "accepted",
              `Your Buyer ${userState?.buyer?.name?.toUpperCase()} scheduled Open House tour on ${moment(new Date(formData?.dateTime?.split(',')?.[0]))?.format("MMMM Do, yyyy")} from ${formData?.dateTime?.split(',')?.[1]}`,
              0,
              '',
              '',
              userState.buyer?.buyerId,
              "No",
              '',
              '',
            );

            if (
              messageType === "Realtime" && (preferredContact === "CONTACT_RADIO_BOTH" ||
                preferredContact === "CONTACT_RADIO_PHONE")
            ) {
              postMessageToUser(
                userState.buyer?.buyerAgentPhone,
                `Your Buyer ${userState?.buyer?.name?.toUpperCase()} scheduled Open House tour on ${moment(new Date(formData?.dateTime?.split(',')?.[0]))?.format("MMMM Do, yyyy")} from ${formData?.dateTime?.split(',')?.[1]}.`
              );
            }
          }
          if (isListedArr?.length > 0) {
            isListedArr?.forEach(async (item: any) => {
              const stime: string = item?.startTime?.split(' ')?.[0];
              const etime: string = item?.endTime?.split(' ')?.[0];
              if (
                param.get('routeStatus') === 'Scheduled' &&
                userState.userType === UserType.AGENT
              ) {
                await inviteAgentByBuyer(
                  item?.listingAgentId,
                  'buyerAgent',
                  `${userState?.agent?.name?.toUpperCase()} buyer agent rescheduled a requested property tour #${item?.listingId
                  } for showing on ${moment(item?.startDate).format(
                    'MMMM Do, yyyy'
                  )} from ${moment(stime, 'hh:mm A').format('hh:mm A')} - ${moment(etime, 'hh:mm A').format('hh:mm A')}`,
                  item?.propertyId,
                  '',
                  '',
                  item?.buyerId
                );

              } else if (userState?.userType === UserType.AGENT) {
                await inviteAgentByBuyer(
                  item?.listingAgentId,
                  'buyerAgent',
                  `Your Agent ${userState?.agent?.name?.toUpperCase()} is requesting a showing for property #${item?.listingId
                  } on ${moment(item?.startDate).format(
                    'MMMM Do, yyyy'
                  )} from ${moment(stime, 'hh:mm A').format('hh:mm A')} - ${moment(etime, 'hh:mm A').format('hh:mm A')}`,
                  item?.propertyId,
                  '',
                  '',
                  item?.buyerId
                );

              }
            });
          }

          setIsLoading(false);
          if (userState?.userType === UserType.AGENT) {
            history.push('/dashboard?buyerId=' + buyerId);
          } else {
            history.push('/buy?buyerId=' + buyerId);
          }
          if (
            param.get('formId')?.length > 0 &&
            param.get('statusType')?.length > 0
          ) {
            return;
          }
          return notification(
            'Your tour has been Scheduled successfully',
            'success'
          );
        } else {
          // to create best Route

          if (
            userState?.userType === UserType.AGENT
          ) {

            await postBestRoute(routeArr, token, userState?.buyer?.buyerAgentEmail,
              buyerAgentName,
              userState?.buyer?.name);
            let messageType: string = 'No';

            isListedArr?.forEach(async (item: any) => {
              const preferredContact: string = item?.listingAgent?.preferredContact;
              const agentAccountSettingsResult: any = await getAgentAccountSettings(item?.listingAgent?.agentId);
              if (agentAccountSettingsResult?.length > 0) {
                const lastIndex: number = agentAccountSettingsResult?.length - 1;
                messageType = agentAccountSettingsResult?.[lastIndex]?.notifyOpenHoursTours;
              }
              const stime: string = item?.startTime?.split(' ')?.[0];
              const etime: string = item?.endTime?.split(' ')?.[0];
              await inviteAgentByBuyer(
                item?.listingAgentId,
                'buyerAgent',
                `${userState?.agent?.name?.toUpperCase()} is requesting a viewing for property ${item?.address} on ${moment(item?.startDate).format(
                  'MMMM Do, yyyy'
                )} from ${moment(stime, 'hh:mm A').format('hh:mm A')} - ${moment(etime, 'hh:mm A').format('hh:mm A')} with their client`,
                item?.propertyId,
                '',
                '',
                item?.buyerId,
                "No",
                'buyerAgent',
                ''
              );
              if (
                messageType === "Realtime" && (preferredContact === "CONTACT_RADIO_BOTH" ||
                  preferredContact === "CONTACT_RADIO_PHONE")
              ) {

                postMessageToUser(
                  item?.listingAgent?.phone,
                  `${userState?.agent?.name?.toUpperCase()} is requesting a viewing for property ${item?.address} on ${moment(item?.startDate).format(
                    'MMMM Do, yyyy'
                  )} from ${moment(stime, 'hh:mm A').format('hh:mm A')} - ${moment(etime, 'hh:mm A').format('hh:mm A')} with their client.`
                );
              }
            });

          }

          if (userState.userType === UserType.BUYER) {
            let messageType: string = 'No';
            const agentAccountSettingsResult: any = await getAgentAccountSettings(Number(userState.buyer?.buyerAgentId));
            const preferredContact: string = userState.buyer?.buyerAgentPreferredContact;

            if (agentAccountSettingsResult?.length > 0) {
              const lastIndex: number = agentAccountSettingsResult?.length - 1;
              messageType = agentAccountSettingsResult?.[lastIndex]?.notifyOpenHoursTours;
            }

            await postBestRoute(routeArr, token, userState?.buyer?.buyerAgentEmail,
              buyerAgentName,
              (messageType === "Realtime" && (preferredContact === "CONTACT_RADIO_BOTH" || preferredContact === "CONTACT_RADIO_EMAIL")) ? userState?.buyer?.name : userState?.agent?.name
            );

            await inviteAgentByBuyer(
              Number(userState.buyer?.buyerAgentId),
              "accepted",
              `Your Buyer ${userState?.buyer?.name?.toUpperCase()} scheduled Open House tour on ${moment(new Date(formData?.dateTime?.split(',')?.[0]))?.format("MMMM Do, yyyy")} from ${formData?.dateTime?.split(',')?.[1]}`,
              0,
              '',
              '',
              userState.buyer?.buyerId,
              "No",
              '',
              '',
            );

            if (
              messageType === "Realtime" && (preferredContact === "CONTACT_RADIO_BOTH" ||
                preferredContact === "CONTACT_RADIO_PHONE")
            ) {
              postMessageToUser(
                userState.buyer?.buyerAgentPhone,
                `Your Buyer ${userState?.buyer?.name?.toUpperCase()} scheduled Open House tour on ${moment(new Date(formData?.dateTime?.split(',')?.[0]))?.format("MMMM Do, yyyy")} from ${formData?.dateTime?.split(',')?.[1]}.`
              );
            }

          }

          setIsLoading(false);
          if (userState?.userType === UserType.AGENT) {
            history.push('/dashboard?buyerId=' + buyerId);
          } else {
            history.push('/buy?buyerId=' + buyerId);
          }
          return notification(
            'Your tour has been Scheduled successfully',
            'success'
          );
        }
      }
    } catch (err) {
      console.error('error==>', err);
    }
  };

  const handleDiscardAndExit = () => {
    let buyerId = -1;
    if (userState?.userType === UserType.AGENT) {
      buyerId = agentState?.buyerId;
      history.push('/dashboard?buyerId=' + buyerId);
    } else {
      buyerId = userState?.buyer?.buyerId;
      history.push('/buy?buyerId=' + buyerId);
    }
  };

  const handleDeleteTour = async () => {
    try {
      if (param?.get('formId')) {
        let formId = Number(param.get('formId'));
        let token = '';
        if (userState?.userType === UserType.AGENT) {
          token = userState?.agent?.token;
        }
        else {
          token = userState?.buyer?.token;
        }
        const res = await deleteSavedTours(token, formId);
      }

      let buyerId = -1;
      if (userState?.userType === UserType.AGENT) {
        buyerId = Number(param.get('buyerId'));
        history.push('/dashboard?buyerId=' + buyerId);
      } else {
        buyerId = userState?.buyer?.buyerId;
        history.push('/buy?buyerId=' + buyerId);
      }

    }
    catch (error) {
      console.error(error);
    }

  }

  const handleCancelTour = () => {
    if (userState.userType === UserType.AGENT) {
      setIsShowCancelTourModal(true);
    } else {
      setIsShowCancelTourModalForBuyer(true);
    }
  };

  const getALLLinkedPropertiesForBuyer = async (
    buyerId: any,
    type?: string
  ) => {
    let token = '';
    if (userState?.userType === UserType.AGENT) {
      token = userState?.agent?.token;
    } else {
      token = userState?.buyer?.token;
    }
    try {
      if (!buyerId) {
        setIsLoading(false);
        return;
      }
      if (!token) {
        setIsLoading(false);
        return notification('token not found ', 'error');
      }
      if (linkedPropertiesData?.length <= 0) {
        setIsLoading(false);
        return;
      }
      setPropLength(linkedPropertiesData?.length);
      let dataForRoute = [];
      for (let i = 0; i < linkedPropertiesData?.length; i++) {
        const date = formData?.dateTime?.split(",")[0];
        const startTime = formData?.dateTime?.split(",")[1];
        const endTime = formData?.dateTime?.split(" ")[1];
        // const dateTime = moment(date).format("YYYY-MM-DD");
        const local1 = moment(date, 'MM-DD-YYYY').local();
        const dateTimeTemp = moment(local1, 'YYYY-MM-DD').format('YYYY-MM-DD');
        let propertOpenTime, propertyCloseTime;
        if (linkedPropertiesData?.[i]?.openHouseKey) {
          propertOpenTime = moment(linkedPropertiesData?.[i]?.openHouseStartTime)
            .tz("America/New_York")
            .format("HH:mm")?.split(" ")?.[0];

          propertyCloseTime = moment(linkedPropertiesData?.[i]?.openHouseEndTime)
            .tz("America/New_York")
            .format("HH:mm")?.split(" ")?.[0];
        } else {
          propertOpenTime = startTime?.split(" ")[0];
          propertyCloseTime = endTime?.split("-")[1]
        }
        if (linkedPropertiesData?.[i]?.geo?.lat !== undefined) {
          const routeObj = {
            cityNameKey: `${linkedPropertiesData?.[i]?.address?.streetNumber ?? ''
              } ${linkedPropertiesData?.[i]?.address?.streetName ?? ''}, ${linkedPropertiesData?.[i]?.address?.city ?? ''
              }, ${linkedPropertiesData?.[i]?.address?.state ?? ''} ${linkedPropertiesData?.[i]?.address?.postalCode ?? ''
              }`,
            placeURL: '',
            latitude: Number(`${linkedPropertiesData?.[i]?.geo?.lat}`),
            longitude: Number(`${linkedPropertiesData?.[i]?.geo?.lng}`),
            regionID: 1,
            open_time: `${dateTimeTemp} ${propertOpenTime}`,
            close_time: `${dateTimeTemp} ${propertyCloseTime}`,
            service_time: (formData?.timePerProp && formData?.timePerProp !== 'Select') ? Number(
              `${formData?.timePerProp?.split(' ')?.[0] ?? 15}`
            ) : Number(`${linkedPropertiesData?.[i]?.tourServiceTime?.split(' ')?.[0]}`),
            position: 1,
          };
          dataForRoute.push(routeObj);
        }

      }

      setLocations(dataForRoute);
      if (type === 'rescheduled') {
        // handleToggling('Not Clicked', dataForRoute);
        handleRouteTourModal();
      }
      // setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      return notification('something went wrong check111 ', 'error');
    }
  };
  useEffect(() => {
    if (
      statusType === 'afterRouteCreated' &&
      locations?.length > 0 &&
      bestRoute?.length === 0 &&
      bestRouteInfo?.length === 0
    ) {
      setScheduleStatus(false);
      setDragDropWorking(true);
      setUseManual(false);
      if (useManualChange === "true") {
        handleToggling('Not Clicked', [], useManualChange);
      } else {
        handleToggling('Not Clicked', [], "false");
      }

    }
  }, [statusType, locations]);

  useEffect(() => {

    if (schedulingStatusClicked === 'Clicked') {
      handleScheduleTourStatus();
    } else if (schedulingStatusClicked === 'Save Tour') {
      handleSaveTourStatus();
    }
  }, [schedulingStatusClicked]);

  useEffect(() => {
    let userRole: any = param.get('role') ?? '';
    if (userState?.userType === UserType.AGENT && userRole === 'Buyer') {
      setIsNotEditable(true);
    } else if (userState?.userType === UserType.BUYER && userRole === 'Agent') {
      setIsNotEditable(true);
    }
    if (
      param.get('statusType') === 'afterRouteCreated' &&
      param.get('routeStatus') === 'Scheduled'
    ) {
      setSaveBtnData(true);
    }
    if (
      param.get('statusType') === 'afterRouteCreated' &&
      param.get('routeStatus') === 'Saved'
    ) {
      setScheduleStatus(false);
    }
    if (param.get('allAccepted') === 'allAccepted') {
      setIsAllAccepted(true);
    }
  }, []);

  useEffect(() => {
    if (isRemoveScheduledPropClicked === 'YES') {
      let buyerIdForRoute = -1;
      if (userState?.userType === UserType.AGENT) {
        buyerIdForRoute = Number(param.get('buyerId'));
      } else {
        buyerIdForRoute = userState?.buyer?.buyerId;
      }
      getALLLinkedPropertiesForBuyer(buyerIdForRoute, 'rescheduled');
    }
  }, [isRemoveScheduledPropClicked]);

  useEffect(() => {
    let buyerIdForRoute = -1;
    if (userState?.userType === UserType.AGENT) {
      buyerIdForRoute = Number(param.get('buyerId'));
    } else {
      buyerIdForRoute = userState?.buyer?.buyerId;
    }
    getALLLinkedPropertiesForBuyer(buyerIdForRoute);
  }, [linkedPropertiesData, statusType, linkedProperties, formData]);

  //JSX rendering

  return (
    <Fragment>
      {isLoading && <CustomSpinner />}
      <CustomRow>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 6 }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: isRes ? 'space-between' : null,
              paddingRight: isRes ? '10px' : null,
            }}
          >
            <div className="buer_name_tour_details">
              <h2>
                {userState?.userType === UserType.AGENT
                  ? agentState?.buyerName
                  : userState?.buyer?.name}
                -Tours
              </h2>
            </div>
            <div className="main_div_of_toggling_button">
              <LeftButton selected={selected === 'prop'} onClick={ToggleData}>
                <img
                  src={selected === 'prop' ? iconCalendar : iconCalendarWhite}
                  alt="location"
                  role="button"
                />
                {/* Setselected(true) */}
              </LeftButton>
              <RightButton selected={selected === 'form'} onClick={ToggleData}>
                <img
                  src={selected === 'form' ? iconListedBlue : iconListed}
                  alt="calendar"
                  role="button"
                />
              </RightButton>
            </div>
          </div>
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 18 }}
        >
          <div className="button_of_buyer_tour_details ">
            <div>

              <CustomHeading>
                {statusType === 'afterRouteCreated'
                  ? saveButtonData?.bestRouteProperties?.length
                  : linkedPropertiesData?.length}
                <span style={{ borderBottom: '3px solid #C2D500' }}>
                  {' '}
                  properties
                </span>
              </CustomHeading>
            </div>

            {isAllAccepted ? (

              !isNotEditable ? (
                <>
                  <Buttondiv className="buyer_tour_details_discard_exit">
                    <CustomButton
                      type={ButtonType.WHITE}
                      text="CANCEL TOUR"
                      clickHandler={handleCancelTour}
                      className="common_button_buyer_tour_details"
                    />
                    {isScheduledRouteUpdated && (
                      <Button
                        type="primary"
                        onClick={handleScheduleTourStatus}
                        className="save--tour-button"
                      >
                        SAVE ROUTE
                      </Button>
                    )}
                  </Buttondiv>
                </>
              ) : (
                <></>
              )
            ) : (
              <>

                {!(param.get('routeStatus') === 'Scheduled') ? (<Buttondiv className="buyer_tour_details_save_tour">
                  <Button
                    type="primary"
                    onClick={handleDeleteTour}
                    className="save--tour-button"
                    disabled={false}
                  >
                    DELETE TOUR
                  </Button>
                </Buttondiv>) : (<></>)
                }

                <Buttondiv className="buyer_tour_details_discard_exit">
                  <CustomButton
                    type={ButtonType.WHITE}
                    text="DISCARD&EXIT"
                    clickHandler={handleDiscardAndExit}
                    className="common_button_buyer_tour_details"
                  />
                </Buttondiv>
                {!isNotEditable && (
                  <>
                    <Buttondiv className="buyer_tour_details_save_tour">
                      <Button
                        type="primary"
                        onClick={handleSaveTourStatus}
                        className="save--tour-button"
                        disabled={saveBtnData}
                      >
                        SAVE TOUR
                      </Button>
                    </Buttondiv>
                    <Buttondiv className="buyer_tour_details_route_tour">
                      <CustomButton
                        type={ButtonType.PRIMARY}
                        text="ROUTE TOUR"
                        clickHandler={handleRouteTourModal}
                        className="common_button_buyer_tour_details"
                      />
                    </Buttondiv>
                    <Buttondiv className="buyer_tour_details_schedule_tour">
                      <Button
                        type="primary"
                        onClick={async () => {


                          let filteredTempBestRouteArr: any =
                            manualChangeProperties?.length > 0
                              ? manualChangeProperties
                              : filteredLinkedProperties?.length > 0
                                ? filteredLinkedProperties
                                : linkedPropertiesData;

                          let temptimestamp = '';
                          for (let i = 0; i < filteredTempBestRouteArr?.length; i++) {
                            let endtimestamp = '';
                            let starttimestamp = '';

                            let dataVal = '';
                            let dateforAgentAcomm = '';
                            //TODO  time stamp
                            if (formData?.dateTime) {
                              dateforAgentAcomm = formData?.dateTime?.split(',')?.[0];
                              dataVal = formData?.dateTime?.split(',')?.[1]?.split('-')?.[0];


                              // test for within time period prefernce for particular property

                              if (i === 0) {
                                endtimestamp = moment(dataVal, 'HH:mm:ss A')
                                  ?.add(30, 'minutes')
                                  ?.add(Number(formData?.timePerProp?.split(' ')?.[0]), 'minutes')
                                  // ?.add(travelTime?.[index + 1], 'minutes')
                                  ?.format('HH:mm:ss A');
                                temptimestamp = String(
                                  moment(endtimestamp, 'HH:mm:ss A')
                                    .add(Math.floor(Math.random() * 9) + 12, 'minutes')
                                    .format('HH:mm:ss A')
                                );
                                starttimestamp = String(
                                  moment(dataVal, 'HH:mm:ss A')
                                    .add(30, 'minutes')
                                    .format('HH:mm:ss A')
                                );
                              }

                              if (i > 0) {
                                starttimestamp = temptimestamp;

                                const endproTime = temptimestamp;

                                endtimestamp = moment(endproTime, 'HH:mm:ss A')
                                  ?.add(Number(formData?.timePerProp?.split(' ')?.[0]), 'minutes')

                                  ?.format('HH:mm:ss A');
                                temptimestamp = String(
                                  moment(endtimestamp, 'HH:mm:ss A')
                                    .add(Math.floor(Math.random() * 9) + 12, 'minutes')
                                    .format('HH:mm:ss A')
                                );
                              }
                            }
                            const item: any = filteredTempBestRouteArr[i];
                            let systemSchedule = false;
                            let isAccepted = false;

                            if (item?.isListed || item?.isListed === 'true') {

                              if (
                                (item?.showingType === 'Agent accompained' &&
                                  item?.acceptedRequest === 'Yes') ||
                                (item?.showingType === 'Lockbox' &&
                                  item?.acceptedRequest === 'Yes')
                              ) {
                                systemSchedule = true;
                                isAccepted = true;
                              } else if (
                                (item?.showingType === 'Agent accompained' &&
                                  item?.acceptedRequest === 'Within available period') ||
                                (item?.showingType === 'Lockbox' &&
                                  item?.acceptedRequest === 'Within available period')
                              ) {
                                const freeSlotsData: any = await getFreeSlotsForPropId(
                                  item?.mlsId,
                                  item?.listingAgentId
                                );
                                if (freeSlotsData?.length > 0) {
                                  freeSlotsData.forEach(async (item: any) => {
                                    // moment(dateforAgentAcomm).format('MM/DD/YYYY'))
                                    const sDay: number = new Date(item?.startDate).getUTCDate();
                                    const sMonth: number = new Date(item?.startDate).getMonth();
                                    const sYear: number = new Date(item?.startDate).getFullYear();
                                    const tempDate: string = `${sMonth + 1}-${sDay}-${sYear}`;
                                    let dateCheck =
                                      moment(tempDate).format('MM/DD/YYYY') ===
                                      moment(dateforAgentAcomm).format('MM/DD/YYYY');
                                    let timeCheck =
                                      moment(starttimestamp, 'hh:mma').isSameOrAfter(
                                        moment(item?.startTime, 'hh:mma')
                                      ) &&
                                      moment(endtimestamp, 'hh:mma').isSameOrBefore(
                                        moment(item?.endTime, 'hh:mma')
                                      );

                                    if (dateCheck && timeCheck) {
                                      systemSchedule = true;
                                      isAccepted = true;
                                    }
                                  });
                                }
                              }
                            }

                            if (isAccepted) {
                              const propAddress: string = `${item?.address?.streetNumber} ${item?.address?.streetName}, ${item?.address?.city}, ${item?.address?.state}, ${item?.address?.postalCode}`;
                              //insert this property event into google calendar
                              // let email = await getSignedInUserEmail();
                              let email = null;
                              if (email) {
                                const startDateTime = `${moment(dateforAgentAcomm).format('YYYY-MM-DD')} ${moment(item?.startTime, 'hh:mm a').format('hh:mm a')}`;
                                const endDateTime = `${moment(dateforAgentAcomm).format('YYYY-MM-DD')} ${moment(item?.endTime, 'hh:mm a').format('hh:mm a')}`;
                                const calendarEvent: any = {
                                  summary: "View Property",
                                  location: `${propAddress}`,
                                  start: {
                                    dateTime: moment(startDateTime),
                                    timeZone: "America/Los_Angeles",
                                  },
                                  end: {
                                    dateTime: moment(endDateTime),
                                    timeZone: "America/Los_Angeles",
                                  },
                                };
                                insertEventToGoogleCalendar({ calendarEvent });
                              }
                            }
                            item.isAccepted = item?.isAccepted
                              ? item?.isAccepted
                              : userState?.userType === UserType.BUYER
                                ? true
                                : isAccepted;
                            item.systemSchedule = systemSchedule;

                            filteredTempBestRouteArr[i] = item;
                          }
                          setFilteredLinkedProperties(filteredTempBestRouteArr);
                          setScheduleTourClicked('Clicked');
                          setIsShowSchedulignStatusModal(true);
                        }}
                        className="schedule--tour-button"
                        disabled={scheduleStatus}
                      >
                        {param?.get('formId')?.length > 0 &&
                          param?.get('statusType') === 'afterRouteCreated' &&
                          param?.get('routeStatus') === 'Scheduled'
                          ? 'UPDATE SCHEDULED TOUR'
                          : 'SCHEDULE TOUR'}
                      </Button>
                    </Buttondiv>
                  </>
                )}
              </>
            )}
          </div>
        </Col>
      </CustomRow>

      {isTourModalShow && (
        <RoutingTourModal
          handleToggling={handleToggling}
          isShowing={isTourModalShow}
          closeAction={() => {
            handleRouteTourModal();
            setRunMap(!runMap);
          }}
          setShowMap={setShowMap}
          setRunApiOnLoad={setRunApiOnLoad}
          setScheduleStatus={setScheduleStatus}
          setIsTourModalShow={setIsTourModalShow}
          setRunMap={setRunMap}
          setDragDropWorking={setDragDropWorking}
          setNumberingMarker={setNumberingMarker}
          setUnlinkPropHeader={setUnlinkPropHeader}
          setShowReScheduleModal={setShowReScheduleModal}
          SetShowTimeBar={SetShowTimeBar}
          setTourPropPage={setTourPropPage}
          setselected={setselected}
          setIsUnlinkedPropertyClicked={setIsUnlinkedPropertyClicked}
          setBestRoute={setBestRoute}
          setHandleTogglingClicked={setHandleTogglingClicked}
        />
      )}

      {isScheduleTour && (
        <ScheduleTourModal
          isShowing={isScheduleTour}
          closeAction={() => {
            setIsScheduleTour(!isScheduleTour);
            // setAgentModal(!agentModal)
          }}
          linkedPropertiesData={linkedPropertiesData}
          setLinkedPropertiesData={() => { }}
          setAgentModal={setAgentModal}
          setIsScheduleTour={setIsScheduleTour}
        />
      )}

      {agentModal && (
        <>
          <Regina
            isShowing={agentModal}
            closeAction={() => {
              setAgentModal(!agentModal);
            }}
          />
        </>
      )}

      {isShowCancelTourModal && (
        <Cancel
          isShowing={isShowCancelTourModal}
          setIsShowing={setIsShowCancelTourModal}
          formId={formId}
          token={
            userState?.userType === UserType.AGENT
              ? userState?.agent?.token
              : userState?.buyer?.token
          }
          handleDiscardAndExit={handleDiscardAndExit}
          listingAgentName={
            userState?.userType === UserType.AGENT
              ? userState?.agent?.name
              : userState?.buyer?.name
          }
          buyerAgentEmail={
            userState?.userType === UserType.AGENT
              ? userState?.agent?.email
              : userState?.buyer?.Email
          }
          type={userState?.userType === UserType.AGENT ? 'Agent' : 'Buyer'}
          linkedPropertiesData={
            filteredLinkedProperties?.length > 0
              ? filteredLinkedProperties
              : linkedPropertiesData
          }
          buyerId={
            userState?.userType === UserType?.AGENT
              ? param.get('buyerId')
              : userState?.buyer?.buyerId
          }
          formData={formData}
          buyerDetails={buyerDetails}
        />
      )}

      {isShowCancelTourModalForBuyer && (
        <FindAnAgentConfirmModal
          isShowing={isShowCancelTourModalForBuyer}
          closeAction={() => {
            setIsShowCancelTourModalForBuyer(false);
          }}
          text="Are you sure, you want to cancel the tour?"
          noText="No"
          yesText="Cancel Tour"
          formId={formId}
          token={
            userState?.userType === UserType.AGENT
              ? userState?.agent?.token
              : userState?.buyer?.token
          }
          handleDiscardAndExit={handleDiscardAndExit}
          listingAgentName={
            userState?.userType === UserType.AGENT
              ? userState?.agent?.name
              : userState?.buyer?.name
          }
          buyerAgentEmail={
            userState?.userType === UserType.AGENT
              ? userState?.agent?.email
              : userState?.buyer?.Email
          }
          type={userState?.userType === UserType.AGENT ? 'Agent' : 'Buyer'}
          linkedPropertiesData={
            filteredLinkedProperties?.length > 0
              ? filteredLinkedProperties
              : linkedPropertiesData
          }
          buyerId={
            userState?.userType === UserType?.AGENT
              ? param.get('buyerId')
              : userState?.buyer?.buyerId
          }
          formData={formData}
          buyerDetails={buyerDetails}
          pageType='RouteTourHeader'
        />
      )}
    </Fragment>
  );
};
export default RouteTourHeader;
