import buttonClose from '../assets/images/button-close.svg';
import Verified from '../assets/find-an-agent-page/verified.png';
interface ThankyouModalPropsModel {
  text?: string;
  message?: string;
  isShowing: boolean;
  closeAction: () => void;
}

const ThankyouModal: React.FC<ThankyouModalPropsModel> = ({
  text,
  message,
  isShowing,
  closeAction,
}): JSX.Element => {
  const modalClassName = isShowing
    ? 'thankyou-modal u-visible'
    : 'thankyou-modal u-hidden';
  const cardClassName = isShowing
    ? 'thankyou-modal__card animation__pop-in'
    : 'thankyou-modal__card animation__pop-out';

  return (
    <div className={modalClassName}>
      <div className={cardClassName}>
        <div className="thankyou-modal__row">
          {text?.length > 0 ? (
            <h3>{text}</h3>
          ) : (
            <h3>Thank you for registering</h3>
          )}

          <img
            className="thankyou-modal__close-button"
            src={buttonClose}
            onClick={closeAction}
            alt="Close button"
          />
        </div>
        <div>
          <div className="thankyou-logo">
            {message?.length > 0 ? (
              <>
                <img width={30} height={30} src={Verified} alt="logo" />
                <p>{message}</p>
              </>
            ) : (
              <p>
                An email has been sent to your registered email. Please
                verify and login.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankyouModal;
