import React from "react";

import HomepageAgentPage from '../pages/HomepageAgentPage'

const ListingsAgentPage = () =>{
    return <>
    {/* <h1>Showing Agent Page</h1> */}
    <HomepageAgentPage isShowings ={true} isListings = {false} />
    </>
}
export default ListingsAgentPage;