/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import logoMastercard from '../assets/images/logo-mastercard.svg';
import logoVisa from '../assets/images/logo-visa.svg';
import Checkout from '../components/card/checkout';
import CustomSpinner from '../components/spinner/CustomSpinner';
import UserContext from '../contexts/UserContext';

const CheckoutPage: React.FC = () => {
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const { userState } = useContext(UserContext);
  const local1 = moment(userState?.agent?.expireAt, 'YYYY-MM-DD HH:mm:ss').local();
  let expireAt = moment(local1, "YYYY-MM-DD").format("YYYY-MM-DD");

  useEffect(() => {
    // let sqPaymentScript = document.createElement("script");
    // // sandbox: https://js.squareupsandbox.com/v2/paymentform
    // // production: https://js.squareup.com/v2/paymentform
    // sqPaymentScript.src = "https://sandbox.web.squarecdn.com/v1/square.js";
    // sqPaymentScript.type = "text/javascript";
    // sqPaymentScript.async = false;
    // sqPaymentScript.onload = () => {
    //   setLoad(true);
    // };
    // document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
    //Add Date
    //{moment(userState?.agent?.expireAt)
    // .add(30, 'days')
    //.format('MM-DD-YYYY')} 
    setIsLoader(true);
    setTimeout(() => {
      setIsLoader(false);
    }, 1500);
  }, []);
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  const subscriptionAmount = (type: string) => {
    switch (type) {
      case "Navihome Advanced Plan":
        return 49.99;
      case "Navihome Premimum Plan":
        return 39.99;
      case "Navihome Basic Plan":
        return 29.99;
      case "Navihome Free Plan":
        return 0.00;
      default:
        return null;
    }
  }
  return (
    <div className="checkout-page">
      {isLoader && <CustomSpinner />}
      <h2>Checkout</h2>
      <div className="checkout-page__grid">
        <div className="checkout-page__info">
          {query.get("isFreeDaysRemaining") === "true" ? <p>Your free trial expires on {moment(expireAt).format('MM-DD-YYYY')}. You will be downgraded to the free version of the app on {moment(expireAt).format('MM-DD-YYYY')} unless you enter in your credit card details below. Note that you will be billed <b>${query.get('plan')}</b> monthly, starting on {moment(expireAt).format('MM-DD-YYYY')}.</p> : <>{query.get('mode') === "upgrade" ? <p>You are currently on the {userState?.agent?.subscription_type === "Navihome Premimum Plan" ? "Navihome Premium Plan" : userState?.agent?.subscription_type} with a monthly charge of <b>${subscriptionAmount(userState?.agent?.subscription_type)}</b>. Your request to change your plan to {query.get('type') === "Navihome Premimum Plan" ? "Navihome Premium Plan" : query.get('type')} will take effect immediately. Please enter your credit card details below to confirm this change. You will be billed <b>${query.get('plan')}</b> monthly, starting on {moment().format('MM-DD-YYYY')}.</p> :
            <p>You are currently on the {userState?.agent?.subscription_type === "Navihome Premimum Plan" ? "Navihome Premium Plan" : userState?.agent?.subscription_type} with a monthly charge of <b>${subscriptionAmount(userState?.agent?.subscription_type)}</b>. Your request to change your plan to {query.get('type') === "Navihome Premimum Plan" ? "Navihome Premium Plan" : query.get('type')} will take effect on your next billing date. Please enter your credit card details below to confirm this change. You will be billed <b>${query.get('plan')}</b> monthly, starting on {moment(expireAt).format('MM-DD-YYYY')}.</p>}</>}
        </div>
        <div className="checkout-page__card-type">
          <p>Pay using Credit Card</p>
          <div className="checkout-page__cards">
            <img src={logoMastercard} alt="Mastercard logo" />
            <img src={logoVisa} alt="Visa logo" />
          </div>
        </div>
        <Checkout />
      </div>
    </div>
  );
};

export default CheckoutPage;
