import { useState, useContext, useEffect, Fragment } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import FindAnAgentModal from "../modals/findAnAgentModal";
import UserContext from "../contexts/UserContext";
import moment from "moment";
const CustomCalendar = styled(Calendar)`
  border: none;
  border-radius: 8px;
  margin: 12px;
  /* color: black !important; */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  & .react-calendar__navigation {
    color: #fff;
    /* color: black; */
    font-size: 15px;
    background: #004876;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &.react-calendar {
    /* box-sizing: border-box; */
    color: black;
  }
  .react-calendar__tile--now {
    color: black;
    /* border-top-left-radius: 8px;
    border-bottom-left-radius: 8px; */
    /* border-radius: 8px; */
    /* background-color: #004876; */
    background-color: #dfd2c3;
  }
  .react-calendar__tile--rangeStart {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background: #6f48eb;
    color: white;
  }
  .react-calendar__tile--rangeEnd {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background: #6f48eb;
    color: black;
    /* color: red; */
  }
  /* .react-calendar__month-view__days__day--weekend {
    color: gray !important;
  } */
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575 !important;
  }
`;

const MiniCalendar = (props: any) => {
  const { userState } = useContext(UserContext);
  const [isAgentModal, setIsAgentModal] = useState(false);
  const [grayWeek, setGrayWeek] = useState(true);
  const [fromprop, setFromProp] = useState<Date>(new Date());
  const [calDirection, setCalDirection] = useState<boolean>(true);
  const history = useHistory();
  const goToCalendar = () => {
    if (userState.isLoggedIn && userState.buyer && !props.isBuyerLinked) {
      setIsAgentModal(!isAgentModal);
      return;
    }
    if (props.data === "previous_show") {
      return;
    }
    history.push("/calendar");
  };
  const [value, onChange] = useState(new Date());
  const [start, setStart] = useState(new Date().toString());
  const [end, setend] = useState(new Date().toString());
  const [clickedDay, setClickedDay] = useState(new Date().toString());
  const handleChange = (value: any) => {
    if (props?.setUpcomingTourDate !== undefined) {
      props?.setUpcomingTourDate(moment(value).format("LL"));
    }
    props?.setUpcomingStartEndDate({
      startDate:moment(value).startOf("week").format("LL").toString(),
      endDate:moment(value).endOf("week").format("LL").toString(),
    })
    
    onChange(value);
    setStart(moment(value).startOf("week").toString());
    setend(moment(value).endOf("week").toString());
    setClickedDay(moment(value).format("llll").toString());
  };


  useEffect(() => {
    setFromProp(props?.month);
    setCalDirection(false);
  }, [props?.month]);

  useEffect(() => {
    if (props?.setDateValue || props?.setStartToEnd) {
      props?.setDateValue(start);
      props?.setClickedDay(clickedDay);
      props?.setStartToEnd(true);
    }
  }, [value, start, end, clickedDay]);
  return (
    <Fragment>
      {String(props?.month).length !== 0 && calDirection == false ? (
        <>
          <CustomCalendar
            calendarType="US"
            showNavigation={true}
            onActiveStartDateChange={(val: any) => {
              setCalDirection(true);
            }}
            next2Label={null}
            activeStartDate={fromprop != null ? fromprop : new Date()}
            showWeekNumbers={false}
            prev2Label={null}
            onChange={(value: any) => handleChange(value)}
            value={
              (start && end) !== new Date().toString()
                ? [new Date(start), new Date(end)]
                : new Date()
            }
            maxDetail="month"
            onViewChange={() => {
              setGrayWeek(false);
            }}
          />
        </>
      ) : String(props?.month).length == 0 || calDirection == true ? (
        <CustomCalendar
          calendarType="US"
          showNavigation={true}
          next2Label={null}
          showWeekNumbers={false}
          prev2Label={null}
          onChange={(value: any) => handleChange(value)}
          value={
            (start && end && clickedDay) !== new Date().toString()
              ? [new Date(start), new Date(end)]
              : new Date()
          }
          maxDetail="month"
          onViewChange={() => {
            setGrayWeek(false);
          }}
        />
      ) : (
        <></>
      )}
      {isAgentModal && (
        <FindAnAgentModal isShowing={isAgentModal} closeAction={goToCalendar} />
      )}
    </Fragment>
  );
};
export default MiniCalendar;
