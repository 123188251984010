/* eslint-disable array-callback-return */
import { Fragment, useState, useEffect, useContext } from 'react';
import BedBathSelectField from '../components/BedBathSelectField';
import Button, { ButtonType } from '../components/Button';
import PriceSelectField from '../components/PriceSelectField';
import PropertyCard from '../components/PropertyCard';
import SearchField from '../components/SearchField';
import { useGlobal } from 'reactn';
import PropertyTypeSelectField from '../components/PropertyTypeSelectField';
import SelectField, { SelectFieldType } from '../components/SelectField';
import PropertyFiltersModal from '../modals/PropertyFiltersModal';
import {
  createBuyerNotificationById,
  getAgentAccountSettings,
  getAllFavoritePropertieService,
  getAllViewedPropertieService,
  getBuyerAccountSettings,
  getPropertiesOpenHousesService,
  getPropertiesService,
  inviteAgentByBuyer,
  Listing,
  postMessageToUser,
  saveSearchService,
  updateSavedSearchService,
} from '../services/NavihomeService';
import { useHistory, useLocation } from 'react-router';
import PropertyFiltersContext from '../contexts/PropertyFiltersContext';
import UserContext, { UserType } from '../contexts/UserContext';
import AgentContext from '../contexts/AgentContext';
import { notification } from '../components/notification';
import iconLocation from '../assets/images/icon-location.svg';
import iconListed from '../assets/images/icoon-listed.svg';
import iconLocationWhite from '../assets/images/icon-location-white.svg';
import iconListedBlue from '../assets/images/icon-listed-blue.svg';
import GoogleMapReact from 'google-map-react';
import { AnyReactComponent } from '../components/GoogleMap/PropertyMap';
import { Col, Row, Grid } from 'antd';
import styled from '@emotion/styled';
import CustomSpinner from '../components/spinner/CustomSpinner';
import LogInModal from '../modals/LoginModal';
import SaveSearchModal from '../modals/SaveSearchModal';
import ForgotPasswordModal from '../modals/forgotPasswordModal';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
const LeftButton = styled.div<{ selected: boolean }>`
  background-color: ${(props) => (props?.selected ? '#fff' : '#004876')};
  height: 100%;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  text-align: center;
`;
const RightButton = styled.div<{ selected: boolean }>`
  background-color: ${(props) => (props?.selected ? '#fff' : '#004876')};
  height: 100%;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
  text-align: center;
`;

const CustomBackDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 34px 16px 0;
  margin-left: -20px;
`;
const CustomBackSpan = styled.span`
  cursor: pointer;
  font-size: 17px;
  @media screen and (max-width: 992px) {
    padding-left: 2.5rem;
  }
  &:hover {
    text-decoration: underline;
    font-weight: 600;
  }
`;

const { useBreakpoint } = Grid;
const CustomInnerCol = styled(Col)`
  margin: 6px;
`;

const PropertiesPage: React.FC = () => {
  const [sortBy, setSortBy] = useState('Sort By');
  const history = useHistory();
  const [isShowingMoreFilters, setIsShowingMoreFilters] = useState(false);
  const [selected, setselected] = useState<string>('form');
  const [sortByValue, setSortByValue] = useState<string>('');
  const [properties, setProperties] = useState<Listing[]>([]);
  const [filteredProperties, setFilteredProperties] = useState<Listing[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [filteredSearchText, setFilteredSearchText] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [isBuyerLinked, setIsBuyerLinked] = useState(false);
  const [upcomingBuyerId, setUpcomingBuyerId] = useState<string>('');
  const [upcomingBuyerPhone, setUpcomingBuyerPhone] = useState<string>('');
  const [firePlace, setFirePlace] = useState<string>('');
  const [count, setCount] = useState<number>(28);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [globalState, setGlobalState] = useGlobal<any>('propState');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [globalStateProp, setGlobalStateProp] =
    useGlobal<any>('checkpropstate');
  const [upcomingCityName, setUpcomingCityName] = useState<string>('');
  const [upcomingLinkedBuyerName, setUpcomingLinkedBuyerName] =
    useState<string>('');
  const [showEmptyText, setShowEmptyText] = useState<boolean>(false);
  const [latlngData, setLatlngData] = useState<any>({
    lat: 0,
    lng: 0,
  });
  const { userState } = useContext(UserContext);
  const { agentState } = useContext(AgentContext);
  const screens = useBreakpoint();

  const {
    propertyFiltersState,
    setMinPrice,
    setMaxPrice,
    setBeds,
    setQuery,
    setBaths,
    setSortType,
    setHalfBaths,
    getFiltersQueryString,
    getFiltersForSavedSearch,
    clearFilters,
  } = useContext(PropertyFiltersContext);

  const {
    baths,
    beds,
    halfBaths,
    minPrice,
    maxPrice,
    minSquareFootage,
    maxSquareFootage,
    minLotSize,
    maxLotSize,
    minYearBuilt,
    maxYearBuilt,
    minYearConverted,
    maxYearConverted,
    propertyType,
    query,
    parkingSpaces,
    garages,
    sortType,
    cooling,
    heatingCooling,
  } = propertyFiltersState;

  const [isShowingSaveSearchModal, setisShowingSaveSearchModal] =
    useState<boolean>(false);
  const [isShowingLoginModal, setIsShowingLoginModal] =
    useState<boolean>(false);
  const [isShowingForgotPasswordModal, setIsShowingForgotPasswordModal] =
    useState<boolean>(false);

  const [isSellTab, setIsSellTab] = useState<boolean>(false);
  const [isPropertyClear, setIsPropertyClear] = useState<boolean>(false);
  const Responsheading = useMediaQuery({ maxWidth: 991 });
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let param = useQuery();

  useEffect(() => {
    searchAction('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param.get('searchTextRandomValue')]);

  useEffect(() => {

    let sellType = param.get('type') ?? '';

    let buyerPhone = userState?.userType === UserType?.BUYER ? userState?.buyer?.phone : agentState?.buyerPhone;
    if (sellType === 'sell') setIsSellTab(true);
    const linkedBuyerId = param.get('buyerId');

    let linkedBuyerName = userState?.userType === UserType?.BUYER ? userState?.buyer?.name : agentState?.buyerName;

    const linkedCityName = param.get('city');

    setUpcomingCityName(linkedCityName ?? query);
    setUpcomingBuyerId(linkedBuyerId);
    setUpcomingBuyerPhone(buyerPhone);
    setUpcomingLinkedBuyerName(linkedBuyerName);
    checkIBuyersLinked();
    let filteredType = param.get('filter') ?? '';
    if (query?.length > 0 && filteredType !== 'yes') {
      setSearchText(query);
      const q: string = query;
      clearFilters();
      setQuery(q);
    } else if (filteredType !== 'yes') {
      clearFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setselected(globalState === 'prop' ? 'prop' : 'form');
  }, [globalState]);

  useEffect(() => {
    setSearchText(query);
  }, [query]);

  const checkIBuyersLinked = () => {
    try {
      setIsBuyerLinked(userState?.buyer?.isLinked);
    } catch (error) {
      console.log(error);
    }
  };

  const renderProperties = (isProperties: boolean, selectState: string) => {
    let cards: any = [];

    if (isProperties) {
      cards = (
        filteredSearchText?.length > 0
          ? filteredProperties
          : filteredProperties?.length > 0
            ? filteredProperties
            : properties
      )?.map((listing: any) => {
        return (
          <PropertyCard
            property_type={propertyType ?? ''}
            propertyDetails={listing ? listing : ' '}
            propertyId={listing?.mlsId ?? 0}
            image={listing?.photos?.length > 0 ? listing?.photos[0] : ''}
            price={listing?.listPrice}
            type={listing?.property?.type}
            beds={listing?.property?.bedrooms}
            baths={listing?.property?.bathsFull}
            squareFootage={listing?.property?.area}
            mlsId={listing?.listingId}
            latitude={listing?.geo?.lat ?? 0}
            longitude={listing?.geo?.lng ?? 0}
            country="United State"
            streetNumber={Number(listing?.address?.streetNumber) ?? 0}
            streetName={listing?.address?.streetName ?? 'N/A'}
            city={listing?.address?.city ?? 'N/A'}
            state={listing?.address?.state ?? 'N/A'}
            postalCode={listing?.address?.postalCode ?? 'N/A'}
            bathsHalf={listing?.property?.bathsHalf ?? 0}
            feature=""
            isSellTab={isSellTab}
            isBuyerLinked={isBuyerLinked}
            upcomingBuyerId={upcomingBuyerId}
            upcomingBuyerPhone={upcomingBuyerPhone}
            listing={listing?.listingId ?? ''}
            selectFavoriteProperties={() => {
            }}
            selectRequestedShowings={() => {
            }}
            selectPropertyComments={() => {
            }}
            openHouse={param?.get('type') ?? ''}
            setTourPropPage={() => {
            }}
            openHouseStartTime={listing?.startTime ?? ''}
            openHouseEndTime={listing?.endTime ?? ''}
            linkedBuyerName={upcomingLinkedBuyerName ?? ''}
            openHouseKey={listing?.openHouseKey ?? null}
            openHouseId={listing?.openHouseId}
            buyerEmail={userState?.userType === UserType?.BUYER ? userState?.buyer?.Email : agentState?.buyerEmail}
          />
        );
      });

      return <Fragment>{cards}</Fragment>;
    } else {
      const cards = (
        filteredSearchText?.length > 0
          ? filteredProperties
          : filteredProperties?.length > 0
            ? filteredProperties
            : properties
      )?.map((listing: any) => {
        return (
          <Col xs={24} sm={12} md={12} lg={12}>
            <div
              className="property_page_card_detail"
              style={{ padding: '5px' }}
            >
              <PropertyCard
                property_type={propertyType ?? ''}
                propertyId={listing?.mlsId ?? 0}
                propertyDetails={listing ? listing : ' '}
                image={listing?.photos?.length > 0 ? listing?.photos[0] : ''}
                price={listing?.listPrice}
                type={listing?.property?.type}
                beds={listing?.property?.bedrooms}
                baths={listing?.property?.bathsFull}
                squareFootage={listing?.property?.area}
                mlsId={listing?.listingId}
                latitude={listing?.geo?.lat ?? 0}
                longitude={listing?.geo?.lng ?? 0}
                country="United State"
                streetNumber={Number(listing?.address?.streetNumber) ?? 0}
                streetName={listing?.address?.streetName ?? 'N/A'}
                city={listing?.address?.city ?? 'N/A'}
                state={listing?.address?.state ?? 'N/A'}
                postalCode={listing?.address?.postalCode ?? 'N/A'}
                bathsHalf={listing?.property?.bathsHalf ?? 0}
                feature=""
                isSellTab={isSellTab}
                listing={listing?.listingId ?? ''}
                isBuyerLinked={isBuyerLinked}
                upcomingBuyerId={upcomingBuyerId}
                upcomingBuyerPhone={upcomingBuyerPhone}
                selectFavoriteProperties={() => {
                }}
                selectRequestedShowings={() => {
                }}
                selectPropertyComments={() => {
                }}
                openHouse={param?.get('type') ?? ''}
                setTourPropPage={() => {
                }}
                openHouseStartTime={listing?.startTime ?? ''}
                openHouseEndTime={listing?.endTime ?? ''}
                openHouseKey={listing?.openHouseKey}
                openHouseId={listing?.openHouseId}
                linkedBuyerName={upcomingLinkedBuyerName ?? ''}
                buyerEmail={userState?.userType === UserType?.BUYER ? userState?.buyer?.Email : agentState?.buyerEmail}
              />
            </div>
          </Col>
        );
      });

      return <Row>{cards}</Row>;
    }
  };
  const loadMoreProperties = () => {
    setCount(count + 28);
    setIsLoading(true);
    searchProperties(count + 28, true);
  };


  const searchProperties = async (
    count: number,
    isLoadmore?: boolean,
    sortBy?: string
  ) => {
    setIsLoading(true);
    try {
      var queryString = getFiltersQueryString();
      let listings: any = null;
      let openhouse = param.get('type');
      if (sortBy === 'clear') {
        queryString = '';
      }

      if (openhouse === 'open_house') {
        listings = await getPropertiesOpenHousesService(queryString, count);
        if (maxPrice > 1) {
          const filterMaxPrice = listings.filter(
            (item: any) => item.listPrice <= maxPrice
          );
          listings = filterMaxPrice;
        }
      } else {
        listings = await getPropertiesService(queryString, count);
      }

      if (listings?.length === 0) {
        setShowSeeMore(true);
      } else {
        setShowSeeMore(false);
      }

      setShowEmptyText(false);
      if (
        (listings?.length <= 0 || listings == null) &&
        properties?.length <= 0
      )
        setShowEmptyText(true);

      let filterListings = null;
      if (openhouse === 'open_house') {
        filterListings = listings?.filter(
          (listing: any) =>
            listing?.listPrice &&
            listing?.photos?.[0] &&
            listing?.endTime !== null
        );
      } else {
        if (
          query?.length > 0 &&
          !Number(query) &&
          sortBy !== 'clear' &&
          query?.length <= 20
        ) {
          filterListings = listings?.filter(
            (listing: any) =>
              listing?.listPrice &&
              listing?.photos?.[0]

          );
        } else {
          filterListings = listings?.filter(
            (listing: any) => listing?.listPrice && listing?.photos?.[0]
          );
        }
      }
      if (isLoadmore) {
        setProperties([...properties, ...filterListings]);
      } else {
        setProperties(filterListings);
      }

      if (
        (sortBy === 'Square Feet' || sortByValue === 'Square Feet') &&
        sortBy !== 'clear'
      ) {
        if (isLoadmore) {
          sortProperties(sortBy?.length > 0 ? sortBy : sortByValue, [
            ...properties,
            ...filterListings,
          ]);
        } else {
          sortProperties(
            sortBy?.length > 0 ? sortBy : sortByValue,
            filterListings
          );
        }
      }

      setLatlngData({
        lat: filterListings?.[0]?.geo?.lat || 0,
        lng: filterListings?.[0]?.geo?.lng || 0,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const searchAction = (query: string, listings?: Array<object>) => {
    setSearchText(query);
    searchProperties(null);
  };


  const searchTextHandlerForPropetyType = (
    getQuery: any,
    propertyDetails?: Array<object>,
    isSearchTextHandler?: boolean
  ) => {
    setFilteredSearchText(getQuery);

    const searchedProperties: any = (
      propertyDetails?.length > 0 ? propertyDetails : properties
    )?.filter((item: any) => {
      let queryString = false;
      if (
        item?.listingId?.includes(getQuery) ||
        item?.mlsId?.toString()?.includes(getQuery) ||
        item?.address?.city?.toUpperCase()?.includes(getQuery?.toUpperCase()) ||
        item?.address?.streetName
          ?.toUpperCase()
          ?.includes(getQuery?.toUpperCase()) ||
        item?.address?.streetNumber?.toString()?.includes(getQuery) ||
        item?.address?.postalCode.includes(getQuery)
      ) {
        queryString = true;
      }

      let type = false;
      if (
        (item?.property?.type?.toUpperCase() === 'RES' &&
          propertyType.includes('residential')) ||
        (item?.property?.type?.toUpperCase() === 'RNT' &&
          propertyType.includes('rental')) ||
        (item?.property?.type?.toUpperCase() === 'CRE' &&
          propertyType.includes('commercial')) ||
        (item?.property?.type?.toUpperCase() === 'MFL' &&
          propertyType.includes('multifamily'))
      ) {
        type = true;
      }

      //FIVE
      if (
        minPrice > 0 &&
        maxPrice > 0 &&
        beds > 1 &&
        baths > 1 &&
        query?.length > 0
      ) {
        if (
          type &&
          minPrice <= item?.listPrice &&
          maxPrice >= item?.listPrice &&
          beds <= item?.property?.bedrooms &&
          baths <=
          item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf &&
          queryString
        )
          return item;
        else return;
      }

      //FOUR
      if (minPrice > 0 && beds > 1 && baths > 1 && query?.length > 0) {
        if (
          type &&
          minPrice <= item?.listPrice &&
          beds <= item?.property?.bedrooms &&
          baths <=
          item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf &&
          queryString
        )
          return item;
        else return;
      }
      if (maxPrice > 0 && beds > 1 && baths > 1 && query?.length > 0) {
        if (
          type &&
          maxPrice >= item?.listPrice &&
          beds <= item?.property?.bedrooms &&
          baths <=
          item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf &&
          queryString
        )
          return item;
        else return item;
      }

      if (minPrice > 0 && maxPrice > 0 && baths > 1 && query?.length > 0) {
        if (
          type &&
          minPrice <= item?.listPrice &&
          maxPrice >= item?.listPrice &&
          baths <=
          item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf &&
          queryString
        )
          return item;
        else return;
      }

      if (minPrice > 0 && maxPrice > 0 && beds > 1 && query?.length > 0) {
        if (
          type &&
          minPrice <= item?.listPrice &&
          maxPrice >= item?.listPrice &&
          beds <= item?.property?.bedrooms &&
          queryString
        )
          return item;
        else return;
      }

      if (minPrice > 0 && maxPrice > 0 && beds > 1 && baths > 1) {
        if (
          type &&
          minPrice <= item?.listPrice &&
          maxPrice >= item?.listPrice &&
          beds <= item?.property?.bedrooms &&
          baths <= item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf
        )
          return item;
        else return;
      }

      //THREE
      if (beds > 1 && baths > 1 && query?.length > 0) {
        if (
          type &&
          beds <= item?.property?.bedrooms &&
          baths <=
          item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf &&
          queryString
        )
          return item;
        else return;
      }

      if (beds > 1 && baths > 1 && minPrice > 0) {
        if (
          type &&
          minPrice <= item?.listPrice &&
          beds <= item?.property?.bedrooms &&
          baths <= item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf
        )
          return item;
        else return;
      }

      if (beds > 1 && baths > 1 && maxPrice > 0) {
        if (
          type &&
          maxPrice >= item?.listPrice &&
          beds <= item?.property?.bedrooms &&
          baths <= item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf
        )
          return item;
        else return;
      }
      if (minPrice > 0 && maxPrice > 0 && query?.length > 0) {
        if (
          type &&
          minPrice <= item?.listPrice &&
          maxPrice >= item?.listPrice &&
          queryString
        )
          return item;
        else return;
      }
      if (minPrice > 0 && maxPrice > 0 && beds > 1) {
        if (
          type &&
          minPrice <= item?.listPrice &&
          maxPrice >= item?.listPrice &&
          beds <= item?.property?.bedrooms
        )
          return item;
        else return;
      }
      if (minPrice > 0 && maxPrice > 0 && baths > 1) {
        if (
          type &&
          minPrice <= item?.listPrice &&
          maxPrice >= item?.listPrice &&
          baths <= item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf
        )
          return item;
        else return;
      }

      if (beds > 1 && query?.length > 0 && minPrice > 0) {
        if (
          type &&
          minPrice <= item?.listPrice &&
          beds <= item?.property?.bedrooms &&
          queryString
        )
          return item;
        else return;
      }
      if (beds > 0 && query?.length > 0 && maxPrice > 0) {
        if (
          type &&
          maxPrice >= item?.listPrice &&
          beds <= item?.property?.bedrooms &&
          queryString
        )
          return item;
        else return;
      }

      if (baths > 1 && query?.length > 0 && minPrice > 0) {
        if (
          type &&
          minPrice <= item?.listPrice &&
          baths <=
          item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf &&
          queryString
        )
          return item;
        else return;
      }
      if (baths > 1 && query?.length > 0 && maxPrice > 0) {
        if (
          type &&
          maxPrice >= item?.listPrice &&
          baths <=
          item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf &&
          queryString
        )
          return item;
        else return;
      }

      //TWO
      if (minPrice > 0 || maxPrice > 0) {
        if (type && minPrice <= item?.listPrice && maxPrice >= item?.listPrice)
          return item;
        else return;
      }

      if (minPrice > 0 || baths > 1) {
        if (
          type &&
          baths <= item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf
        )
          return item;
        else return;
      }
      if (minPrice > 0 || beds > 1) {
        if (
          type &&
          minPrice <= item?.listPrice &&
          beds <= item?.property?.bedrooms
        )
          return item;
        else return;
      }
      if (minPrice > 0 || query?.length > 0) {
        if (type && minPrice <= item?.listPrice && queryString) return item;
        else return;
      }
      if (maxPrice > 0 || beds > 1) {
        if (
          type &&
          maxPrice >= item?.listPrice &&
          beds <= item?.property?.bedrooms
        )
          return item;
        else return;
      }
      if (maxPrice > 0 || baths > 1) {
        if (
          type &&
          maxPrice >= item?.listPrice &&
          baths <= item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf
        )
          return item;
        else return;
      }
      if (maxPrice > 0 || query?.length > 0) {
        if (type && maxPrice >= item?.listPrice && queryString) return item;
        else return;
      }
      if (beds > 1 || baths > 1) {
        if (
          type &&
          beds <= item?.property?.bedrooms &&
          baths <= item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf
        )
          return item;
        else return;
      }
      if (beds > 1 || query?.length > 0) {
        if (type && beds <= item?.property?.bedrooms && queryString)
          return item;
        else return;
      }
      if (baths > 1 || query?.length > 0) {
        if (
          type &&
          baths <=
          item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf &&
          queryString
        )
          return item;
        else return;
      }

      //ONE
      if (query?.length > 0 && !isSearchTextHandler) {
        if (type && queryString) return item;
        else return;
      }
      if (minPrice > 0) {
        if (type && minPrice <= item?.listPrice) return item;
        else return;
      }
      if (maxPrice > 0) {
        if (type && maxPrice >= item?.listPrice) return item;
        else return;
      }

      if (baths > 1) {
        if (
          type &&
          baths <= item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf
        )
          return item;
        else return;
      }
      if (beds > 1) {
        if (type && beds <= item?.property?.bedrooms) return item;
        else return;
      }

      if (propertyType?.length > 0) {
        if (type) return item;
        else return;
      }
    });
    if (propertyType?.length <= 0) {
      if (query?.length > 0) {
        searchTextHandler(query);
      } else if (minPrice > 0 || maxPrice > 0) {
        searchTextHandlerForListPrice(query?.length > 0 ? query : 'search');
      } else if (beds > 0 || baths > 0)
        searchTextHandlerForBedsAndBaths(query.length > 0 ? query : 'search');
      else setFilteredProperties([]);
    } else setFilteredProperties(searchedProperties);
  };

  const searchTextHandlerForBedsAndBaths = (
    getQuery: any,
    propertyDetails?: Array<object>,
    isSearchTextHandler?: boolean
  ) => {
    setFilteredSearchText(getQuery);
    const searchedProperties: any = (
      propertyDetails?.length > 0 ? propertyDetails : properties
    )?.filter((item: any) => {
      let price = false;
      if (beds > 1 && baths > 1) {
        if (
          beds <= item?.property?.bedrooms &&
          baths <= item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf
        )
          price = true;
        else return;
      } else if (beds > 1) {
        if (beds <= item?.property?.bedrooms) price = true;
        else return;
      } else {
        if (
          baths <=
          item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf
        )
          price = true;
        else return;
      }
      let queryString = false;
      if (
        item?.listingId?.includes(getQuery) ||
        item?.mlsId?.toString()?.includes(getQuery) ||
        item?.address?.city?.toUpperCase()?.includes(getQuery?.toUpperCase()) ||
        item?.address?.streetName
          ?.toUpperCase()
          ?.includes(getQuery?.toUpperCase()) ||
        item?.address?.streetNumber?.toString()?.includes(getQuery) ||
        item?.address?.postalCode.includes(getQuery)
      ) {
        queryString = true;
      }

      let type = false;
      if (
        (item?.property?.type?.toUpperCase() === 'RES' &&
          propertyType.includes('residential')) ||
        (item?.property?.type?.toUpperCase() === 'RNT' &&
          propertyType.includes('rental')) ||
        (item?.property?.type?.toUpperCase() === 'CRE' &&
          propertyType.includes('commercial')) ||
        (item?.property?.type?.toUpperCase() === 'MFL' &&
          propertyType.includes('multifamily'))
      ) {
        type = true;
      }

      //FOUR
      if (
        maxPrice > 1 &&
        minPrice > 1 &&
        propertyType?.length > 0 &&
        query?.length > 0
      ) {
        if (
          price &&
          minPrice <= item?.listPrice &&
          maxPrice >= item?.listPrice &&
          type &&
          queryString
        )
          return item;
        else return;
      }

      //THREE
      if (maxPrice > 0 && minPrice > 0 && propertyType?.length > 0) {
        if (
          price &&
          minPrice <= item?.listPrice &&
          maxPrice >= item?.listPrice &&
          type
        )
          return item;
        else return;
      }
      if (maxPrice > 0 && minPrice > 0 && query?.length > 0) {
        if (
          queryString &&
          price &&
          beds <= item?.property?.bedrooms &&
          baths <= item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf
        )
          return item;
        else return item;
      }

      if (query?.length > 0 && maxPrice > 0 && propertyType?.length > 0) {
        if (queryString && price && maxPrice <= item?.listPrice && type)
          return item;
        else return;
      }

      if (query?.length > 0 && minPrice > 1 && propertyType?.length > 0) {
        if (queryString && price && minPrice <= item?.listPrice && type)
          return item;
        else return;
      }

      //TWO
      if (minPrice > 0 && maxPrice > 1) {
        if (price && minPrice <= item?.listprice && maxPrice >= item?.listPrice)
          return item;
        else return;
      }

      if (minPrice > 1 && propertyType?.length > 0) {
        if (price && minPrice <= item?.listPrice && type) return item;
        else return;
      }

      if (minPrice > 1 && query?.length > 0) {
        if (price && minPrice <= item?.listPrice && queryString) return item;
        else return;
      }
      if (maxPrice > 1 && propertyType?.length > 0) {
        if (price && maxPrice >= item?.listPrice && type) return item;
        else return;
      }

      if (maxPrice > 1 && query?.length > 0) {
        if (queryString && price && maxPrice <= item?.listPrice) return item;
        else return;
      }
      if (query?.length > 0 && propertyType?.length > 0) {
        if (queryString && price && type) return item;
        else return;
      }

      //ONE
      if (minPrice > 1) {
        if (price && minPrice <= item?.listPrice) return item;
        else return;
      }
      if (maxPrice > 1) {
        if (price && baths >= item?.listPrice) return item;
        else return;
      }

      if (propertyType?.length > 0) {
        if (price && type) return item;
        else return;
      }
      if (query?.length > 0 && !isSearchTextHandler) {
        if (queryString && price) return item;
        else return;
      }

      if (price) return item;
      else return;
    });

    if (beds === 1 && baths === 1) {
      if (query?.length > 0) {
        searchTextHandler(query);
      } else if (minPrice > 0 || maxPrice > 0) {
        searchTextHandlerForListPrice(query?.length > 0 ? query : 'search');
      } else if (propertyType?.length > 0)
        searchTextHandlerForPropetyType(query.length > 0 ? query : 'search');
      else setFilteredProperties([]);
    } else setFilteredProperties(searchedProperties);
  };

  const searchTextHandlerForListPrice = (
    getQuery: any,
    propertyDetails?: Array<object>,
    isSearchTextHandler?: boolean
  ) => {
    setFilteredSearchText(getQuery);
    const searchedProperties: any = properties?.filter((item: any) => {
      let price = false;
      if (minPrice > 0 && maxPrice > 0) {
        if (minPrice <= item?.listPrice && maxPrice >= item?.listPrice) {
          price = true;
        } else return;
      } else if (minPrice > 0) {
        if (minPrice <= item?.listPrice) price = true;
        else return;
      } else {
        if (maxPrice >= item?.listPrice) price = true;
        else return;
      }
      let queryString = false;
      if (
        item?.listingId?.includes(getQuery) ||
        item?.mlsId?.toString()?.includes(getQuery) ||
        item?.address?.city?.toUpperCase()?.includes(getQuery?.toUpperCase()) ||
        item?.address?.streetName
          ?.toUpperCase()
          ?.includes(getQuery?.toUpperCase()) ||
        item?.address?.streetNumber?.toString()?.includes(getQuery) ||
        item?.address?.postalCode.includes(getQuery)
      ) {
        queryString = true;
      }

      let type = false;
      if (
        (item?.property?.type?.toUpperCase() === 'RES' &&
          propertyType.includes('residential')) ||
        (item?.property?.type?.toUpperCase() === 'RNT' &&
          propertyType.includes('rental')) ||
        (item?.property?.type?.toUpperCase() === 'CRE' &&
          propertyType.includes('commercial')) ||
        (item?.property?.type?.toUpperCase() === 'MFL' &&
          propertyType.includes('multifamily'))
      ) {
        type = true;
      }

      //FOUR
      if (
        beds > 1 &&
        baths > 1 &&
        propertyType?.length > 0 &&
        query?.length > 0
      ) {
        if (
          price &&
          beds <= item?.property?.bedrooms &&
          baths <=
          item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf &&
          type &&
          queryString
        )
          return item;
        else return;
      }

      //THREE
      if (beds > 1 && baths > 1 && propertyType?.length > 0) {
        if (
          price &&
          beds <= item?.property?.bedrooms &&
          baths <=
          item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf &&
          type
        )
          return item;
        else return;
      }
      if (beds > 1 && baths > 1 && query?.length > 0) {
        if (
          queryString &&
          price &&
          beds <= item?.property?.bedrooms &&
          baths <= item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf
        )
          return item;
        else return;
      }

      if (query?.length > 0 && baths > 1 && propertyType?.length > 0) {
        if (
          queryString &&
          price &&
          baths <=
          item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf &&
          type
        )
          return item;
        else return;
      }

      if (query?.length > 0 && beds > 1 && propertyType?.length > 0) {
        if (queryString && price && beds <= item?.property?.bedrooms && type)
          return item;
        else return;
      }

      //TWO

      if (beds > 1 && baths > 1) {
        if (
          price &&
          beds <= item?.property?.bedrooms &&
          baths <= item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf
        )
          return item;
        else return;
      }

      if (beds > 1 && propertyType?.length > 0) {
        if (price && beds <= item?.property?.bedrooms && type) return item;
        else return;
      }

      if (beds > 1 && query?.length > 0) {
        if (price && beds <= item?.property?.bedrooms && queryString)
          return item;
        else return;
      }
      if (baths > 1 && propertyType?.length > 0) {
        if (
          price &&
          baths <=
          item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf &&
          type
        )
          return item;
        else return;
      }

      if (baths > 1 && query?.length > 0) {
        if (
          queryString &&
          price &&
          baths <= item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf
        )
          return item;
        else return;
      }
      if (query?.length > 0 && propertyType?.length > 0) {
        if (queryString && price && type) return item;
        else return;
      }

      //ONE

      if (beds > 1) {
        if (price && beds <= item?.property?.bedrooms) return item;
        else return;
      }
      if (baths > 1) {
        if (
          price &&
          baths <= item?.property?.bathsFull + 0.5 * item?.property?.bathsHalf
        )
          return item;
        else return;
      }
      if (propertyType?.length > 0) {
        if (price && type) return item;
        else return;
      }

      if (query?.length > 0 && !isSearchTextHandler) {
        if (queryString && price) return item;
        else return;
      }
      if (price) return item;
      else return;
    });
    if (minPrice === 0 && maxPrice === 0) {
      if (query?.length > 0) {
        searchTextHandler(query);
      } else if (beds > 1 || baths > 1) {
        searchTextHandlerForBedsAndBaths(query?.length > 0 ? query : 'search');
      } else if (propertyType?.length > 0) {
        searchTextHandlerForPropetyType(query.length > 0 ? query : 'search');
      } else setFilteredProperties([]);
    } else setFilteredProperties(searchedProperties);
  };

  const searchTextHandler = (
    getQuery: any,
    propertyDetails?: Array<object>
  ) => {
    setQuery(getQuery);
  };

  const searchActionOnMoreFilter = (keyword?: string) => {
    let openhouse = param.get('type');
    if (
      openhouse === 'open_house' &&
      minPrice <= 1 &&
      propertyType?.length <= 0
    ) {
      if (beds > 1 || baths > 1)
        searchTextHandlerForBedsAndBaths(query.length > 0 ? query : 'search');

      if (firePlace?.length > 0) searchTextHandler(firePlace);
      if (
        minSquareFootage > 0 ||
        maxSquareFootage > 0 ||
        minLotSize > 0 ||
        maxLotSize > 0 ||
        cooling?.length > 0 ||
        heatingCooling?.length > 0 ||
        minYearBuilt > 1 ||
        maxYearBuilt > 1 ||
        minYearConverted > 1 ||
        maxYearConverted > 1 ||
        parkingSpaces > 0 ||
        garages > 0
      )
        searchActionOnMoreFilterOther();
    } else {
      searchProperties(null);
    }
  };

  const searchActionOnMoreFilterOther = () => {
    setFilteredSearchText(query?.length > 0 ? query : 'search');

    let isFilterApplied = false;

    if (
      query?.length > 0 ||
      minPrice > 0 ||
      maxPrice > 0 ||
      propertyType?.length > 0 ||
      beds > 1 ||
      baths > 1
    )
      isFilterApplied = true;
    const searchedProperties: any = (
      isFilterApplied ? filteredProperties : properties
    )?.filter((item: any) => {
      if (
        minSquareFootage > 0 &&
        maxSquareFootage > 0 &&
        minSquareFootage <= item?.property?.area &&
        maxSquareFootage >= item?.property?.area
      )
        return item;
      if (
        minLotSize > 0 &&
        minLotSize <= item?.property?.lotSizeArea &&
        maxLotSize > 0 &&
        maxLotSize >= item?.property?.lotSizeArea
      )
        return item;
      if (
        cooling?.length > 0 &&
        cooling?.toLocaleLowerCase() ===
        item?.property?.cooling?.toLocaleLowerCase()
      ) {
        return item;
      }
      if (
        heatingCooling?.length > 0 &&
        item?.property?.heating?.includes(heatingCooling)
      ) {
        return item;
      }
      if (
        minYearBuilt > 1 &&
        minYearBuilt <= item?.property?.yearBuilt &&
        maxYearBuilt > 1 &&
        maxYearBuilt >= item?.property?.yearBuilt
      )
        return item;
      if (parkingSpaces > 0 && parkingSpaces === item?.property?.parking?.spaces)
        return item;
      if (garages > 0 && garages === item?.property?.garageSpaces) return item;
    });
    setFilteredProperties(searchedProperties);
  };

  const showMoreFilters = () => {
    setIsShowingMoreFilters(true);
    searchFieldHandler();
  };

  const handleMoreFiltersClose = () => {
    setIsShowingMoreFilters(false);
  };
  const handleSaveSearchModal = () => {
    setisShowingSaveSearchModal(false);
  };

  const saveSearch = async () => {
    if (!userState?.isLoggedIn) {
      setisShowingSaveSearchModal(true);
      return;
    }
    let token = '',
      buyerPhone: string = '',
      messageType: string = 'No',
      preferredContact: string = '';

    if (userState?.userType === UserType?.BUYER) {
      token = userState?.buyer?.token;
      let searchedId = param.get('searchedId') ?? '';
      let openHouse = param.get('type') ?? '';
      const search: any = getFiltersForSavedSearch();
      search.city = query ?? '';
      search.state = 'MA';
      search.sortType = openHouse ?? '';
      search['agentName'] = userState?.buyer?.buyerAgentName;
      search['buyerName'] = userState?.buyer?.name ?? '';
      search['buyerEmail'] = 'Agent';
      search['agentEmail'] = userState?.buyer?.buyerAgentEmail ?? '';
      search['agentId'] = userState?.buyer?.buyerAgentId ?? 0;
      search['buyerId'] = userState?.buyer?.buyerId ?? 0;
      search['createdAt'] = new Date();
      setIsLoading(true);
      let messageType: string = 'No';
      const preferredContact: string = userState?.buyer?.buyerAgentPreferredContact;
      const agentAccountSettingsResult: any = await getAgentAccountSettings(userState?.buyer?.buyerAgentId);
      if (agentAccountSettingsResult?.length > 0) {
        const lastIndex: number = agentAccountSettingsResult?.length - 1;
        messageType = agentAccountSettingsResult?.[lastIndex]?.notifySavedSearchs;
      }
      if (searchedId) {
        await updateSavedSearchService(
          token,
          search,
          parseInt(searchedId),
          preferredContact === "CONTACT_RADIO_BOTH" || preferredContact === "CONTACT_RADIO_EMAIL" ? messageType ?? "Realtime" : "No"
        );
        await inviteAgentByBuyer(
          userState?.buyer?.buyerAgentId,
          'accepted',
          `Property search was modified by your Buyer ${userState?.buyer?.name?.toUpperCase()}`,
          100000000,
          '',
          '',
          userState?.buyer?.buyerId
        );
        if (messageType === "Realtime" && (preferredContact === "CONTACT_RADIO_BOTH" ||
          preferredContact === "CONTACT_RADIO_PHONE")) {
          postMessageToUser(
            userState?.buyer?.buyerAgentPhone,
            `Property search was modified by your Buyer ${userState?.buyer?.name.toUpperCase()}.`
          );
        }

        setIsLoading(false);
        notification('Searches updated successfully!', 'success');
      } else {
        const response = await saveSearchService(token, search, userState.buyer.buyerId, preferredContact === "CONTACT_RADIO_BOTH" || preferredContact === "CONTACT_RADIO_EMAIL" ? messageType ?? "Realtime" : "No"
        );
        if (
          response?.['status'] === 201 &&
          response?.['statusText'] === 'Created'
        ) {
          await inviteAgentByBuyer(
            userState?.buyer?.buyerAgentId,
            'accepted',
            `Property search was saved by your Buyer ${userState?.buyer?.name?.toUpperCase()}`,
            100000000,
            '',
            '',
            userState?.buyer?.buyerId
          );
          if (messageType === "Realtime" && (preferredContact === "CONTACT_RADIO_BOTH" ||
            preferredContact === "CONTACT_RADIO_PHONE")) {
            postMessageToUser(
              userState?.buyer?.buyerAgentPhone,
              `Property search was saved by your Buyer ${userState?.buyer?.name.toUpperCase()}.`
            );
          }
          setIsLoading(false);
          notification('Search has been saved.', 'success');
        } else {
          setIsLoading(false);
          notification(
            'Please select the filter properties and try again.',
            'error'
          );
        }
      }
    }

    if (userState?.userType === UserType?.AGENT) {
      token = userState?.agent?.token;
      let searchedId = param.get('searchedId') ?? '';
      let openHouse = param.get('type') ?? '';
      const search: any = getFiltersForSavedSearch();
      search.city = query ?? '';
      search.state = 'MA';
      search.sortType = openHouse ?? '';
      search['agentName'] = userState?.agent?.name;
      search['agentEmail'] = 'Buyer';
      search['buyerName'] = agentState?.buyerName ?? '';
      search['buyerEmail'] = agentState?.buyerEmail ?? '';
      search['agentId'] = userState?.agent?.agentId ?? '';
      search['createdAt'] = new Date();

      setIsLoading(true);
      buyerPhone = agentState?.buyerPhone ?? '';
      preferredContact = agentState?.buyerPreferredContact
      const buyerAccountSettingsResult: any = await getBuyerAccountSettings(
        userState?.agent?.token,
        agentState.buyerId)
      if (buyerAccountSettingsResult?.length > 0) {
        const lastIndex: number = buyerAccountSettingsResult?.length - 1;
        messageType = buyerAccountSettingsResult?.[lastIndex]?.notifySavedSearch;
      }
      if (searchedId) {
        const result = await updateSavedSearchService(
          token,
          search,
          parseInt(searchedId),
          preferredContact === "CONTACT_RADIO_BOTH" || preferredContact === "CONTACT_RADIO_EMAIL" ? messageType ?? "Realtime" : "No"
        );
        if (result) {

          await createBuyerNotificationById(
            agentState?.buyerId,
            `Property search was modified by your Agent ${userState?.agent?.name?.toUpperCase()}`,
            'Comment',
            userState?.agent?.token,
            'buyer',
            10000000,
            "No",
            userState?.agent?.name,
            'buyerName',
            'buyerMail',
          );
          if (messageType === "Yes" && (preferredContact === "CONTACT_RADIO_BOTH" ||
            preferredContact === "CONTACT_RADIO_PHONE")) {
            postMessageToUser(
              buyerPhone,
              `Property search was modified by your Agent ${userState?.agent?.name?.toUpperCase()}.`
            );
          }
        }
        setIsLoading(false);
        notification('Saved searches successfully updated!', 'success');
      } else {
        const response = await saveSearchService(
          token,
          search,
          parseInt(upcomingBuyerId),
          preferredContact === "CONTACT_RADIO_BOTH" || preferredContact === "CONTACT_RADIO_EMAIL" ? messageType ?? "No" : "No"
        );
        if (
          response?.['status'] === 201 &&
          response?.['statusText'] === 'Created'
        ) {

          await createBuyerNotificationById(
            agentState?.buyerId,
            `Property search was saved by your Agent ${userState?.agent?.name?.toUpperCase()}.`,
            'Comment',
            userState?.agent?.token,
            'buyer',
            10000000,
            "No",
            userState?.agent?.name,
            'buyerName',
            '',
          );

          if (messageType === "Yes" && (preferredContact === "CONTACT_RADIO_BOTH" ||
            preferredContact === "CONTACT_RADIO_PHONE")) {
            postMessageToUser(
              buyerPhone,
              `Property search was saved by your Agent ${userState?.agent?.name.toUpperCase()}.`
            );
          }
          setIsLoading(false);
          notification('Search has been saved.', 'success');
        } else {
          setIsLoading(false);
          notification(
            'Please select the filter properties and try again.',
            'error'
          );
        }
      }
    }

  };

  const sortProperties = async (
    value: string,
    propertyDetails?: Array<object>
  ) => {
    let openhouse = param.get('type');
    let propertyList;
    switch (value) {
      case 'Popularity':
        break;

      case 'Most Viewed':
        break;

      case 'listprice':
        if (openhouse === 'open_house') {
          setSortByValue(value);
          propertyList =
            filteredProperties?.length > 0
              ? filteredProperties
              : propertyDetails?.length > 0
                ? propertyDetails
                : properties;
          const lowToHigh: any = propertyList.sort((a: any, b: any) => {
            return a['listPrice'] - b['listPrice'];
          });
          setProperties(lowToHigh);
        }
        break;

      case '-listprice':
        if (openhouse === 'open_house') {
          setSortByValue(value);
          propertyList =
            filteredProperties?.length > 0
              ? filteredProperties
              : propertyDetails?.length > 0
                ? propertyDetails
                : properties;
          const highToLow: any = propertyList.sort((a: any, b: any) => {
            return b['listPrice'] - a['listPrice'];
          });
          setProperties(highToLow);
        }
        break;

      case 'Square Feet':
        setSortByValue(value);
        propertyList =
          propertyDetails?.length > 0 ? propertyDetails : properties;
        const squareFeet: any = propertyList.sort((a: any, b: any) => {
          return a['property']?.['area'] - b['property']?.['area'];
        });

        setProperties(squareFeet);
        break;

      case 'New Listing':
        setSortByValue(value);
        propertyList =
          filteredProperties?.length > 0
            ? filteredProperties
            : propertyDetails?.length > 0
              ? propertyDetails
              : properties;
        const newlisting: any = propertyList.sort((a: any, b: any) => {
          return (
            new Date(a['mls']?.['originalEntryTimestamp']).getTime() -
            new Date(b['mls']?.['originalEntryTimestamp']).getTime()
          );
        });
        setProperties(newlisting);
        break;
      default:
        setProperties(squareFeet);
        break;
    }
  };

  const ToggleLeftData = () => {
    setGlobalStateProp('checkProp');
    setselected(selected === 'prop' ? 'form' : 'prop');
  };

  const clearFiltersHandler = () => {
    setIsPropertyClear(true);
    setSortByValue('');
    clearFilters();
    setSearchText('');
    setFilteredSearchText('');
    setShowSeeMore(false);
    setSortType('');
    setSortBy('Sort By');
    setProperties([]);
    setFilteredProperties([]);
    setCount(28);
    setFirePlace('');
    searchProperties(null, false, 'clear');
  };

  const searchFieldHandler = () => {
    setSearchText('');
  };

  const getMapOptions = (maps: any) => {
    return {
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID,
        ],
      },
    };
  };

  const handleLoginModal = () => {
    setIsShowingLoginModal(!isShowingLoginModal);
  };

  const handleForgotPasswordModal = () => {
    setIsShowingForgotPasswordModal(!isShowingForgotPasswordModal);
  };

  return (
    <Fragment>
      {isLoading && <CustomSpinner />}
      <div className="properties-page">
        <CustomBackDiv>
          <CustomBackSpan
            onClick={() => {
              // clearFiltersHandler();
              let filteredType = param.get('filter') ?? '';
              let buyerDashboard = param.get("buyerDashboard") ?? "";


              if (filteredType === 'yes') {
                if (buyerDashboard === "saved") {
                  if (userState.userType === UserType.BUYER) {
                    history.push(`/buy?buyerDashboard=${buyerDashboard}`);
                  } else {
                    history.push(
                      `/dashboard?buyerId=${param.get(
                        "buyerId"
                      )}&buyerDashboard=${buyerDashboard}`
                    );
                  }
                } else {
                  history.push('/buy');
                }
              } else {
                history.goBack();
              }
            }}
          >
            Back
          </CustomBackSpan>
          {(minPrice > 0 ||
            maxPrice > 0 ||
            beds > 1 ||
            baths > 1 ||
            halfBaths > 0 ||
            propertyType?.length > 0 ||
            sortType?.length > 0 ||
            searchText?.length > 0 ||
            query?.length > 0 ||
            minSquareFootage > 0 ||
            maxSquareFootage > 0 ||
            minLotSize > 0 ||
            maxLotSize > 0 ||
            minYearBuilt > 0 ||
            maxYearBuilt > 0 ||
            minYearConverted > 0 ||
            maxYearConverted > 0 ||
            parkingSpaces > 0 ||
            garages > 0 ||
            cooling?.length > 0 ||
            heatingCooling?.length > 0 ||
            filteredSearchText?.length > 0 ||
            filteredProperties?.length > 0 ||
            firePlace?.length > 0 ||
            sortByValue?.length > 0) && (
              <CustomBackSpan onClick={clearFiltersHandler}>
                Clear Filter
              </CustomBackSpan>
            )}
        </CustomBackDiv>
        <Row justify="space-between">
          <Col>
            <h3
              style={
                Responsheading ? { marginLeft: '10px' } : { marginLeft: '0' }
              }
            >
              {userState?.isLoggedIn &&
                userState?.userType === UserType?.AGENT ? (
                <>
                  {upcomingBuyerId !== 'null' ? (
                    <>
                      {agentState?.buyerName} - Properties Search
                      {query ? '  in' : ''}{' '}
                      {query ??
                        history?.location?.search.split('=')?.[1] ??
                        upcomingCityName}
                    </>
                  ) : (
                    <>
                      Properties Search{query ? '  in' : ''}{' '}
                      {query ??
                        history?.location?.search.split('=')?.[1] ??
                        upcomingCityName}
                    </>
                  )}
                </>
              ) : (
                <>
                  Properties Search{query ? '  in' : ''}{' '}
                  {query ??
                    history?.location?.search.split('=')?.[1] ??
                    upcomingCityName}
                </>
              )}
            </h3>
          </Col>
          <Col>
            <div className="main_div_of_toggling_button_property_page">
              <LeftButton
                selected={selected === 'form'}
                onClick={ToggleLeftData}
              >
                <img
                  src={selected === 'form' ? iconLocation : iconLocationWhite}
                  alt="location"
                  role="button"
                />
              </LeftButton>
              <RightButton
                selected={selected === 'prop'}
                onClick={ToggleLeftData}
              >
                <img
                  src={selected === 'prop' ? iconListedBlue : iconListed}
                  alt="calendar"
                  role="button"
                />
              </RightButton>
            </div>
          </Col>
        </Row>
        <Row className="mt-5" justify="space-between">
          <Col
            xs={screens.xs && 24}
            style={screens.xs && { paddingRight: '12px' }}
          >
            <Row>
              <CustomInnerCol xs={screens.xs && 24}>
                <SearchField
                  placeholder="Search"
                  searchAction={searchAction}
                  className="properties-page-filter-search"
                  onChange={searchTextHandler}
                  defaultValue={searchText}
                />
                {filteredSearchText?.length > 0
                  ? filteredProperties?.length <= 0 && (
                    <p>
                      no properties found for{' '}
                      {filteredSearchText?.length < 20 && filteredSearchText}
                    </p>
                  )
                  : showEmptyText && (
                    <p>
                      no properties found{' '}
                      {query?.length > 0 &&
                        query?.length < 30 &&
                        `for ${query}`}
                    </p>
                  )}
                {(minPrice > 0 || maxPrice > 0) &&
                  minPrice > maxPrice &&
                  param.get('type') !== 'open_house' && (
                    <p
                      style={{
                        color: 'red',
                        fontSize: '14px',
                        marginTop: '6px',
                      }}
                    >
                      Max value should be equal or more than Min value.
                    </p>
                  )}
              </CustomInnerCol>
              <CustomInnerCol xs={screens.xs && 24}>
                <PriceSelectField
                  minimumPrice={propertyFiltersState?.minPrice}
                  setMinPrice={setMinPrice}
                  maximumPrice={propertyFiltersState?.maxPrice}
                  setMaxPrice={setMaxPrice}
                  searchFieldHandler={searchFieldHandler}
                  openHouse={param?.get('type')}
                  handleChange={() => {
                    searchProperties(null, false, '4');
                  }}
                />
              </CustomInnerCol>

              <CustomInnerCol xs={screens.xs && 24}>
                {isPropertyClear ? (
                  <PropertyTypeSelectField
                    searchFieldHandler={searchFieldHandler}
                    text=""
                    setIsPropertyClear={setIsPropertyClear}
                    handleChange={() => {
                      searchProperties(null, false, '3');
                    }}
                  />
                ) : (
                  <PropertyTypeSelectField
                    searchFieldHandler={searchFieldHandler}
                    handleChange={() => {
                      searchProperties(null, false, '3');
                    }}
                  />
                )}
              </CustomInnerCol>
              <CustomInnerCol xs={screens.xs && 24}>
                <BedBathSelectField
                  getBeds={propertyFiltersState?.beds}
                  setBeds={setBeds}
                  getBaths={propertyFiltersState?.baths}
                  setBaths={setBaths}
                  getHalfBaths={propertyFiltersState?.halfBaths}
                  setHalfBaths={setHalfBaths}
                  searchFieldHandler={searchFieldHandler}
                  handleChange={() => {
                    let openhouse = param.get('type');
                    if (openhouse !== 'open_house')
                      searchProperties(null, false, '2');
                    else {
                      if (properties?.length <= 0)
                        searchProperties(null, false, '2');
                      else
                        searchTextHandlerForBedsAndBaths(
                          query?.length > 0 ? query : 'search'
                        );
                    }
                  }}
                />
              </CustomInnerCol>

              <CustomInnerCol xs={screens.xs && 24}>
                <Button
                  type={ButtonType.GREEN_BORDER_BUTTON}
                  text="MORE FILTERS"
                  clickHandler={showMoreFilters}
                  className="properties-page-more_filter"
                />
              </CustomInnerCol>

              <CustomInnerCol xs={screens.xs && 24}>
                {userState?.userType === UserType?.AGENT &&
                  upcomingBuyerId !== 'null' && (
                    <Button
                      type={ButtonType.PRIMARY_NARROW}
                      text="SAVE SEARCH"
                      clickHandler={saveSearch}
                      className="properties-page-save_search"
                    />
                  )}
                {userState?.userType === UserType?.BUYER && (
                  <Button
                    type={ButtonType.PRIMARY_NARROW}
                    text="SAVE SEARCH"
                    clickHandler={saveSearch}
                    className="properties-page-save_search"
                  />
                )}
              </CustomInnerCol>
            </Row>
          </Col>

          <Col
            xs={screens.xs && 24}
            style={{ paddingRight: screens.xs ? '16px' : '24px' }}
          >
            <SelectField
              value={sortBy}
              setValue={setSortBy}
              sortProperties={() => { }}
              placeholder={'Sort By'}
              options={[
                'Popularity',
                'Most Viewed',
                'Cost: L - H',
                'Cost: H - L',
                'Square Feet',
                'New Listing',
              ]}
              optionWidth={12.8}
              optionsHeight={15}
              handleChange={async (value: any) => {

                if (value === 'Square Feet') {
                  searchProperties(0, false, value);
                } else if (value === 'Most Viewed') {
                  setIsLoading(true);
                  const data: any = await getAllViewedPropertieService();
                  const arr = [...properties];
                  const requestedFav: any = arr?.filter(
                    (item: any) =>
                      data.findIndex(
                        (favItem: { propertyId: number }) =>
                          favItem?.propertyId === item?.mlsId
                      ) > -1
                  );

                  for (let i = 0; i < requestedFav?.length; i++) {
                    const requestedIndex = arr.findIndex(
                      (listing: any) =>
                        (listing?.[i]?.propertyId || listing?.mlsId) ===
                        (requestedFav?.[i]?.propertyId ||
                          requestedFav?.[i]?.mlsId)
                    );
                    arr.splice(requestedIndex, 1);
                  }
                  setIsLoading(false);
                  setProperties([...requestedFav, ...arr]);


                } else if (value === 'Popularity') {
                  setIsLoading(true);
                  const data: any = await getAllFavoritePropertieService();
                  const arr = [...properties];
                  const requestedFav: any = arr?.filter(
                    (item: any) =>
                      data.findIndex(
                        (favItem: { propertyId: number }) =>
                          favItem?.propertyId === item?.mlsId
                      ) > -1
                  );

                  for (let i = 0; i < requestedFav?.length; i++) {
                    const requestedIndex = arr.findIndex(
                      (listing: any) =>
                        (listing?.[i]?.propertyId || listing?.mlsId) ===
                        (requestedFav?.[i]?.propertyId ||
                          requestedFav?.[i]?.mlsId)
                    );
                    arr.splice(requestedIndex, 1);
                  }
                  setIsLoading(false);
                  setProperties([...requestedFav, ...arr]);


                } else {
                  searchProperties(null, false, '1');
                }
              }}
              type={SelectFieldType.TALL}
              className={'u-margin-left-big'}
            />
          </Col>
        </Row>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={selected === 'prop' ? 12 : 24}
            style={{
              overflowY: selected === 'prop' ? 'auto' : 'hidden',
              height: selected === 'prop' ? '100vh' : '',
            }}
          >
            {selected === 'prop' && <>{renderProperties(false, 'prop')}</>}
            {selected !== 'prop' && (
              <div className={'properties-page__properties'}>
                {renderProperties(true, 'form')}
              </div>
            )}
          </Col>

          {selected === 'prop' && (
            <Col xs={24} sm={24} md={24} lg={12}>
              <div style={{ height: '100vh' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_API_KEY,
                  }}
                  center={{ lat: latlngData?.lat, lng: latlngData?.lng }}
                  defaultZoom={11}
                  yesIWantToUseGoogleMapApiInternals
                  options={getMapOptions}
                >
                  {properties?.map((data: any) => {
                    return (
                      <AnyReactComponent
                        property_type={
                          param?.get('type') === 'open_house'
                            ? 'open_house'
                            : ''
                        }
                        id={data?.mlsId}
                        lat={data?.geo?.lat}
                        lng={data?.geo?.lng}
                        city={data?.address?.city || 'N/A'}
                        county={data?.geo?.county || 'N/A'}
                        photos={data?.photos?.[0] || 'N/A'}
                        streetNumber={data?.address?.streetNumber || 'N/A'}
                        streetName={data?.address?.streetName || 'N/A'}
                        state={data?.address?.state || 'N/A'}
                        postalCode={data?.address?.postalCode || 'N/A'}
                        listPrice={data?.listPrice || 'N/A'}
                        subTypeText={data?.property?.subTypeText || 'N/A'}
                        bedrooms={data?.property?.bedrooms || 'N/A'}
                        bathrooms={
                          data?.property?.bathsFull +
                          0.5 * data?.property?.bathsHalf ?? 1
                        }
                        area={data?.property?.area || 'N/A'}
                        listingId={data?.listingId}
                        setIsAllAccepted={() => { }}
                        setPropId={()=>{}}
                        setTourPropPage={()=>{}}
                        setShowMap={()=>{}}
                        setRunMap={()=>{}}
                        setUseManual={()=>{}}
                        formData={{}}
                      />
                    );
                  })}
                </GoogleMapReact>
              </div>
            </Col>
          )}
        </Row>
        {!showSeeMore && properties?.length > 12 && (
          <div className="properties-page__load-more">
            <Button
              type={ButtonType.SECONDARY}
              text="SEE MORE"
              clickHandler={loadMoreProperties}
            />
          </div>
        )}
      </div>
      <PropertyFiltersModal
        isShowing={isShowingMoreFilters}
        applyFilters={searchActionOnMoreFilter}
        closeAction={handleMoreFiltersClose}
        handleChange={() => { }}
        setFirePlace={setFirePlace}
        firePlace={firePlace}
        openHouse={param?.get('type')}
      />
      {isShowingSaveSearchModal && (
        <SaveSearchModal
          isShowing={isShowingSaveSearchModal}
          closeAction={handleSaveSearchModal}
          setIsShowingForgotPasswordModal={handleForgotPasswordModal}
        />
      )}
      {isShowingLoginModal && (
        <LogInModal
          isShowing={isShowingLoginModal}
          closeAction={handleLoginModal}
          setIsShowingForgotPasswordModal={handleForgotPasswordModal}
        />
      )}
      {isShowingForgotPasswordModal && (
        <ForgotPasswordModal
          isShowing={isShowingForgotPasswordModal}
          closeAction={handleForgotPasswordModal}
          setIsShowingLoginModal={handleLoginModal}
        />
      )}
    </Fragment>
  );
};

export default PropertiesPage;
