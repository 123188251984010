import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import agentFeature1 from "../assets/images/landing-agent-feature-1.svg";
import triangleArrow from "../assets/images/triangle-arrow.svg";
import Button, { ButtonType } from "../components/Button";
import { createCronofyService, createUserEmailsService, deleteUserEmailsService, getUserEmailByIdService } from "../services/NavihomeService";


const CustomHeading = styled.h2`
  font-size: 61px;
  font-weight: 500;
  padding: 6px 0 18px 0;
  @media screen and (max-width: 1202px) {
    font-size: 42px;
  }
  @media screen and (max-width: 992px) {
    font-size: 32px !important;
  }
  @media screen and (max-width: 768px) {
    font-size: 30px !important;
  }
  position: relative;
  bottom: 10%;
`;
const CustomSmallHeading = styled.h2`
  font-size: 39px;
  font-weight: 500;
  @media screen and (max-width: 1202px) {
    font-size: 26px;
  }
  @media screen and (max-width: 992px) {
    font-size: 21px !important;
  }
  position: relative;
  bottom: 12%;
`;
const CustomGettingStartedHeading = styled.h1`
  font-size: 32px;
  margin-top: 85px;
  font-weight: 600;
  @media screen and (max-width: 600px) {
    margin-top: 5px;
    padding-bottom: 25px !important;
  }
  @media screen and (max-width: 768px) {
    font-size: 28px !important;
  }
  @media screen and (max-width: 576px) {
    font-size: 23px !important;
  }
  position: relative;
  bottom: 3%;
`;

interface LandingBuyerPagePropsModel {
  isBuy?: boolean;
  isSell?: boolean;
}
const LandingBuyerPage: React.FC<LandingBuyerPagePropsModel> = ({
  isBuy,
  isSell,
}) => {
  const history = useHistory();
  const goToRequestDemo = () => {
    history.push("/request-demo");
  };

  return (
    <>
      {isBuy === undefined ? (
        <div className="landing-agent-page">
          <div className="landing-agent-page__hero-section">
            <div className="landing-agent-page__hero-section__content">
              <CustomHeading>Schedule Showings.</CustomHeading>
              <CustomSmallHeading>
                Easily. Effortlessly. Immediately.
              </CustomSmallHeading>

              <p
                style={{
                  lineHeight: "2.1",
                  position: "relative",
                  bottom: "9%",
                }}>
                Eliminate back-and-forth communication with your agent to
                schedule showings for properties that you’re interested in
                purchasing. Effortlessly search for properties in target areas,
                create favorite lists and automatically notify your agent that
                you’re interested in viewing the property. Your agent can see
                your availability and your favorites real-time and creates
                showing tours based on your preferences.
              </p>

              <br />
              <p
                style={{
                  lineHeight: "2.1",
                  position: "relative",
                  bottom: "4%",
                }}>
                As a seller, eliminate the multiple phone calls to confirm your
                availability to show the property. Plus, monitor real-time stats
                and feedback on your property after showings occur.
              </p>
              <br />
              {/* <p>
                <span className="f-subtitle-1">
                  Navihome: The app designed with the agent in mind.
                </span>
              </p> */}
              <div style={{ position: "relative", bottom: "6%" }}>
                <Button
                  type={ButtonType.PRIMARY}
                  text={"HOW IT WORKS"}
                  clickHandler={goToRequestDemo}
                  className="landing-agent-page__how-it-works-button"
                />
              </div>
              <CustomGettingStartedHeading>
                <span>Getting started is eas</span>
                <span style={{ borderBottom: "4px solid #C2D500" }}>
                  y as 1-2-3
                </span>
              </CustomGettingStartedHeading>
            </div>

            <img
              className="landing-agent-page__hero-section__image"
              src={agentFeature1}
              alt="Agent Feature 1"
            />
          </div>
          <div className="landing-buyer-page__steps-section">
            <div className="landing-agent-page__steps-section__item">
              <p
                className="landing-agent-page__steps-section__number steps-section_number"
                style={{ position: "relative", bottom: "5%" }}>
                1
              </p>
              <img
                className="landing-agent-page__steps-section__arrow"
                src={triangleArrow}
                alt="Arrow"
              />
              <div className="landing-agent-page__steps-section__item__content">
                <p>
                  <h4 style={{ color: "black", lineHeight: "200%" }}>
                    Register and link with your agent or connect with one
                    directly in the app if you don’t already have one.
                  </h4>
                </p>
              </div>
            </div>
            <div className="landing-agent-page__steps-section__item">
              <p className="landing-agent-page__steps-section__number steps-section_number">
                2
              </p>
              <img
                className="landing-agent-page__steps-section__arrow"
                src={triangleArrow}
                alt="Arrow"
              />
              <div className="landing-agent-page__steps-section__item__content">
                <p>
                  <h4 style={{ color: "black", lineHeight: "200%" }}>
                    Search properties and create favorite lists – your agent
                    gets a live feed of your favorite properties.
                  </h4>
                </p>
              </div>
            </div>
            <div className="landing-agent-page__steps-section__item">
              <p className="landing-agent-page__steps-section__number steps-section_number">
                3
              </p>
              <img
                className="landing-agent-page__steps-section__arrow"
                src={triangleArrow}
                alt="Arrow"
              />
              <div className="landing-agent-page__steps-section__item__content">
                <p>
                  <h4 style={{ color: "black", lineHeight: "200%" }}>
                    Provide your availability. Your agent will automatically
                    schedule personalized tours and notify you with it!
                  </h4>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // <EmptyDashboardTab isHome={true} />
        <></>
      )}
    </>
  );
};

export default LandingBuyerPage;
