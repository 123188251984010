import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';


interface TourSummaryItemData {
  date: string;
  dayOfWeek: string;
  time: string;
  timeOfDay: string;
  client: string;
  properties: number;
  saleAgent: string;
  statusPrimary: string;
  statusSecondary: string;
  formId: number;
  responseData?: any;
  type?: string;
  value?: string;
}

interface ToursSummaryProps {
  tours: TourSummaryItemData[];
  upcomingTourDate?: any;
  upcomingStartEndDate?: any;
  type: string;
  userState?: any;
}

interface DataType {
  key: React.Key;
  date: any;
  time: any;
  seller: any;
  saleAgent: any;
  status: any;
}

const ListingsUpcomingShowings: React.FC<ToursSummaryProps> = ({
  type,
  tours,
  userState,
  upcomingStartEndDate,
}) => {
  const history = useHistory();

  const columns: ColumnsType<DataType> = [
    {
      title: 'Date',
      dataIndex: 'date',
      render: (_, { date }) => (
        <div>
          <div>{date?.date}</div>
          <div>{date?.dayOfWeek}</div>
        </div>
      ),
    },
    {
      title: 'Time',
      dataIndex: 'time',
      render: (_, { time }) => (
        <div>
          <div>{time?.time}</div>
          <div>{time?.timeOfDay}</div>
        </div>
      ),
    },
    {
      title: type === 'card' ? 'Seller' : 'Client',
      dataIndex: 'seller',
      render: (_, { seller }) => (
        <div>
          <div>{seller?.seller}</div>
        </div>
      ),
    },
    {
      title: 'Sale Agent',
      dataIndex: 'saleAgent',
      render: (_, { saleAgent }) => (
        <div>
          <span
            style={{
              textDecoration: 'underline',
              color: '#EB752F',
              fontSize: '16px',
            }}
          >
            {saleAgent?.saleAgent === userState?.agent?.name
              ? 'You'
              : saleAgent?.saleAgent}
          </span>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, { status }) => (
        <div>
          <div>{status?.statusPrimary}</div>
          <div>{status?.statusSecondary}</div>
        </div>
      ),
    },
  ];
  const [data, setData]: any = useState([]);
  const [defaultData, setDefaultData]: any = useState([]);

  const handleListingTourDetail = (event:any)=>{
     const propId = event?.responseData?.mlsId || event?.responseData?.propertyId;
     history.push(`/propertydetails/${propId}`)
     return;
  }

  useEffect(() => {
    if (tours?.length > 0) {
      let finalArr: any = [];
      tours?.map((item, i) => {
        let dateIsBettween =
          moment(item?.date).isBetween(
            upcomingStartEndDate?.startDate,
            upcomingStartEndDate?.endDate
          ) ||
          moment(item?.date).isSame(upcomingStartEndDate?.startDate) ||
          moment(item?.date).isSame(upcomingStartEndDate?.endDate);

        if (dateIsBettween) {
          finalArr.push({
            key: i,
            date: { date: item?.date, dayOfWeek: item?.dayOfWeek },
            time: { time: item?.time, timeOfDay: item?.timeOfDay },
            seller: { seller: item?.client ?? 'N/A' },
            saleAgent: { saleAgent: item?.saleAgent ?? 'N/A' },
            status: {
              statusPrimary: item?.statusPrimary,
            },
            responseData : item?.responseData,
          });
        }
        return finalArr;
      });
      setData(finalArr);
      setDefaultData([]);
    }
  }, [tours, upcomingStartEndDate]);

  useEffect(() => {
    if (tours?.length > 0) {
      let finalArr: any = [];
      let currDate = moment().format('LL').toString();
      tours.forEach((item, i) => {
        let curr_future_date = moment(item?.date).isSameOrAfter(currDate);
        if (curr_future_date) {
          finalArr.push({
            key: i,
            date: { date: item?.date, dayOfWeek: item?.dayOfWeek },
            time: { time: item?.time, timeOfDay: item?.timeOfDay },
            seller: { seller: item?.client ?? 'N/A' },
            saleAgent: { saleAgent: item?.saleAgent ?? 'N/A' },
            status: {
              statusPrimary: item?.statusPrimary,
            },
            responseData: item?.responseData,
          });
        }
      });
      setDefaultData(finalArr);
    }
  }, [tours]);

  return (
    <div className="">
      <Table
        onRow={(record, rowIndex) => {
        return {
          onClick: (event: any) => {
            handleListingTourDetail(record);
          },
        };
      }}
      style={{ width: '100% !important', cursor: 'pointer' }}
        columns={columns}
        dataSource={data?.length > 0 ? data : defaultData}
        pagination={{ defaultCurrent: 1, pageSize: 5 }}
      />
    </div>
  );
};

export default ListingsUpcomingShowings;
