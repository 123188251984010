/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import styled from "@emotion/styled";
import { Form, Row, Select } from "antd";
import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import { Link, useLocation } from 'react-router-dom';
import globalimage from "../assets/find-an-agent-page/Group 1641.png";
import Button, { ButtonType } from "../components/Button";
import EmptyDashboardForUnlinkedBuyer from "../components/EmptyDashboardTab/emptyDahboardForUnlinkedBuyer";
import EmptyDashboardTab from "../components/EmptyDashboardTab/emptyDashboardTab";
import MiniCalendar from "../components/MiniCalendar";
import PreviousSummaryBuyer from "../components/PreviousSummaryBuyer";
import PropertyCard from "../components/PropertyCard";
import SavedSearchCard from "../components/SavedSearchCard";
import SearchField from "../components/SearchField";
import ToursSummaryBuyer from "../components/ToursSummaryBuyer";
import { notification } from "../components/notification";
import SellerHeader from "../components/notificationAgentPage/SellerHeader";
import CustomSpinner from "../components/spinner/CustomSpinner";
import PropertyFiltersContext from "../contexts/PropertyFiltersContext";
import UserContext, { UserType } from "../contexts/UserContext";
import FindAnAgentModal from "../modals/findAnAgentModal";
import {
  Listing,
  SavedSearch,
  deleteSavedSearchService,
  getAllPreviousTourDetailsData,
  getAllPropertyCommentsForBuyerService,
  getAllUpcomingScheduledData,
  getAllupcomingTourDetailsData,
  getFavoritePropertiesForBuyerService,
  getPreviousViewPropertyCalendarByBuyerID,
  getPropertiesAffordableService,
  getPropertiesLuxuryService,
  getPropertiesNewListingsService,
  getPropertiesOpenHousesService,
  getRequestedPropertiesForBuyerService,
  getSavedSearchesService,
  getTourAllAcceptedProp,
  getViewPropertyCalendarByBuyerID,
  getViewPropertyCalendarScheduledEvent,
  getViewedFavoritesPropertiesForBuyerService,
  getViewedPropertiesForBuyerService,
  removeAllLinkedPropertyOfBuyer,
} from "../services/NavihomeService";
import LandingBuyerPage from "./LandingBuyerPage";

const CustomPara = styled.p`
  font-size: 12px;
  margin-top: 10px;
`;
const CustomButton = styled(Button)`
  margin-top: 10px;
`;

export const CustomRightDiv = styled.div`
  @media screen and (max-width: 600px) {
    padding: 16px;
    display: flex;
    width: 100%;
    justify-content: center;
  }
`;
export const CustomImage = styled.img`
  width: 450px;
  height: 450px;
  @media screen and (max-width: 860px) {
    width: 350px;
    height: 350px;
  }
  @media screen and (max-width: 600px) {
    width: 450px;
    height: 450px;
  }
  @media screen and (max-width: 500px) {
    width: 390px;
    height: 390px;
  }
  @media screen and (max-width: 400px) {
    width: 280px;
    height: 280px;
  }
`;
const LuxuryHeading = styled.h3`
  @media screen and (max-width: 380px) {
    &:after {
      display: none;
    }
  }
`;
const Affordableheading = styled.h3`
  @media screen and (max-width: 390px) {
    &:after {
      display: none;
    }
  }
`;
const Newlistingheading = styled.h3`
  @media screen and (max-width: 338px) {
    &:after {
      display: none;
    }
  }
`;
const Maindiv = styled.div`
  display: flex;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

enum DashboardTab {
  OVERVIEW = "OVERVIEW",
  REQUESTED_SHOWINGS = "Requested Showings",
  SCHEDULED_SHOWINGS = "Scheduled Showings",
  SAVED_SEARCHES = "Saved Searches",
  FAVORITE_PROPERTIES = "Favorite Properties",
  PROPERTY_COMMENTS = "Property Comments",
  PREVIOUS_TOURS = "Previous Tours",
  VIEWED_PROPERTIES = "Viewed Properties",
  VIEWED_FAVORITES = "Viewed Favorites",
  PROPERTY_DETAILS = "Property Details",
  PROPERTY_STATISTICS = "Property Statistics",
}
interface DashboardAgentPagePropsModel {
  isBuy: boolean;
  isSell: boolean;
}
type SizeType = Parameters<typeof Form>[0]["size"];
const DashboardBuyerPage: React.FC<DashboardAgentPagePropsModel> = ({
  isBuy,
  isSell,
}): JSX.Element => {
  const { userState } = useContext(UserContext);
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(DashboardTab.OVERVIEW);
  const [requestedProperties, setRequestedProperties] = useState([]);
  const [commentedProperties, setCommentedProperties] = useState([]);
  const [viewedRequestedProperties, setViewedRequestedProperties] = useState(
    []
  );
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, currentTab]);

  const [favoriteProperties, setFavoriteProperties] = useState([]);
  const [ViewedFavoriteProperties, setViewedFavoriteProperties] = useState([]);
  const [savedSearches, setSavedSearches] = useState<SavedSearch[]>([]);
  const [openHouses, setOpenHouses] = useState<Listing[]>([]);
  const [newListings, setNewListings] = useState<Listing[]>([]);
  const [affordableHomes, setAffordableHomes] = useState<Listing[]>([]);
  const [luxutyHomes, setLuxuryHomes] = useState<Listing[]>([]);
  const [agentId, setAgentId] = useState<number>(0);
  const [upcomingTourDetail, setUpcomingTourDetail] = useState<any>([]);
  const [prevTourDetails, setPrevTourDetails] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState<Array<any>>([]);
  const [componentSize, setComponentSize] = useState<SizeType | "default">(
    "default"
  );
  const cityNameOpen = "";
  const cityNameNew = "Boston";
  const cityNameAff = "Springfield";
  const cityNameLux = "Medford";
  const [linkedAgentInfo, setLinkedAgentInfo] = useState<any>({
    email: "",
    name: "",
    phone: "",
    avatarUrl: "",
    website: "",
    CompanyLogo: "",
  });

  const [isFindAgentModal, setisFindAgentModal] = useState(false);
  const [isAgentModal, setIsAgentModal] = useState(false);
  const [upcomingTourDate, setUpcomingTourDate] = useState<any>(null);
  const [upcomingStartEndDate, setUpcomingStartEndDate] = useState<any>({
    startDate: null,
    endDate: null,
  });
  const [isResLoaded, setIsResLoaded] = useState<boolean>(false);
  const isMD = useMediaQuery({ maxWidth: 1070 });
  const { Option } = Select;
  const { setQuery } = useContext(PropertyFiltersContext);
  const sellTitle =
    "In order to sell your home, ask your listing agent to add you to the listed property. By doing this, you can see how your property is performing, and keep tab of all showings of your home.";
  const noSchedule =
    "Since you are not connected with an agent, you do not have any scheduled showings. Connect with an agent now to start seeing homes you like!";
  const noRequest =
    "Since you are not connected with an agent, you do not have any requested showings . Connect with an agent now to start seeing homes you like!";
  const noPrevTours =
    "Since you are not connected with an agent, you do not have any previously scheduled tours. Connect with an agent now!";
  const noCommentProp =
    "Since you are not connected with an agent, you do not have any commented properties. Connect with an agent now!";
  const noViewedProp =
    "Connect with an agent now to make this feature available.";
  const noViewedFav =
    "Since you are not connected with an agent, you do not have any viewed and favorited properties. Connect with an agent now!";

  const searchAction = (query: string) => {
    setQuery(query);
    history.push(`/properties?query=${query}`);
  };
  const scheduleTour = () => {
    if (
      userState?.isLoggedIn &&
      userState?.userType === UserType?.BUYER &&
      (!userState?.buyer?.isLinked ||
        userState?.buyer?.buyerAgentName?.length <= 0)
    ) {
      setIsAgentModal(!isAgentModal);
      return;
    }
  };
  const closeAction2 = () => {
    setIsAgentModal(!isAgentModal);
  };

  const handleopenhouse = async () => {
    setIsLoading(true);
    let buyerId = userState?.buyer?.buyerId;
    let token = userState?.buyer?.token;
    await removeAllLinkedPropertyOfBuyer(buyerId, token);
    setIsLoading(false);
    history.push("/common?buyerId=" + buyerId);
  };

  const getAllPropertyComments = async () => {
    setIsLoading(true);
    try {
      const token = userState?.buyer?.token;
      if (!token) {
        setIsLoading(false);
        return notification("unauthorized", "error");
      }
      const comments: any = await getAllPropertyCommentsForBuyerService(
        userState?.buyer?.token
      );

      const newArr: any = [];

      comments?.map((item: any) => {
        const index = newArr?.findIndex(
          (arrObj: any) => arrObj?.propertyId === item?.propertyId
        );
        if (index === -1) {
          newArr?.push(item);
        }
      });

      if (newArr?.length > 0) {
        setCommentedProperties(newArr);
        setIsLoading(false);

        setIsResLoaded(false);
      } else {
        setIsResLoaded(true);
        setCommentedProperties([]);
        setIsLoading(false);

      }
    } catch (error) {
      setIsLoading(false);
      console.error("ERROR", error);
    }
  };

  const getRequestedProperties = async () => {
    setIsLoading(true);

    try {
      if (!userState?.buyer?.buyerId) {
        setIsLoading(false);

        return;
      }
      if (!userState?.buyer?.token) {
        setIsLoading(false);

        return notification("Buyer Token Not Found!", "error");
      }
      const getRequestedProperties: any =
        await getRequestedPropertiesForBuyerService(
          userState.buyer.buyerId,
          userState.buyer.token,
          "No"
        );


      if (getRequestedProperties?.length > 0) {
        setIsLoading(false);

        setRequestedProperties(getRequestedProperties);
        setIsResLoaded(false);
      } else {
        setIsResLoaded(true);
        setIsLoading(false);

        setRequestedProperties([]);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getViewedRequestedProperties = async () => {
    setIsLoading(true);

    try {
      if (!userState?.buyer?.buyerId) {
        setIsLoading(false);

        return;
      }
      if (!userState?.buyer?.token) {
        setIsLoading(false);

        return notification("Buyer Token Not Found!", "error");
      }
      let propArr: any = [];
      let newArr: any = [];
      let currDate = moment().format("YYYY-MM-DD");
      let properties: any = getViewedPropertiesForBuyerService(
        userState?.buyer?.buyerId,
        userState?.buyer?.token
      );
      let propertyCalc: any = getPreviousViewPropertyCalendarByBuyerID(
        userState?.buyer?.buyerId,
        currDate,
        userState?.buyer?.token,
        "viewed property"
      );
      let routeProp: any = getTourAllAcceptedProp(
        userState?.buyer?.buyerId,
        currDate,
        userState?.buyer?.token
      );

      const result = await Promise.all([properties, propertyCalc, routeProp]);

      newArr = result.flat(Infinity);

      newArr?.forEach((item: any) => {
        if (item?.hasOwnProperty("formId")) {
          propArr?.push(...item?.bestRouteProperties);
        } else {
          propArr?.push(item);
        }
      });

      let updatedArr: any = [];
      Array.from(
        new Set(
          propArr?.map((propId: any) => propId?.propertyId || propId?.mlsId)
        )
      ).map((val: any) => {
        const itemFound = propArr?.find(
          (item: any) => (item?.propertyId || item?.mlsId) === val
        );
        if (itemFound) {
          updatedArr.push(itemFound);
          return itemFound;
        }
      });

      setViewedRequestedProperties(updatedArr?.length > 0 ? updatedArr : []);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getViewedFavoritesProperties = async () => {
    setIsLoading(true);

    try {
      if (!userState?.buyer?.buyerId) {
        setIsLoading(false);

        return;
      }
      if (!userState?.buyer?.token) {
        setIsLoading(false);

        return notification("Buyer Token Not Found!", "error");
      }
      let propArr: any = [];
      let newArr: any = [];
      let currDate = moment().format("YYYY-MM-DD");
      let propFav: any = getViewedFavoritesPropertiesForBuyerService(
        userState?.buyer?.buyerId,
        userState?.buyer?.token
      );
      let propCalFav = getPreviousViewPropertyCalendarByBuyerID(
        userState?.buyer?.buyerId,
        currDate,
        userState?.buyer?.token,
        "viewed favorite"
      );
      let routePropFav: any = getTourAllAcceptedProp(
        userState?.buyer?.buyerId,
        currDate,
        userState?.buyer?.token
      );

      const result = await Promise.all([propFav, propCalFav, routePropFav]);

      newArr = result.flat(Infinity);

      newArr?.forEach((item: any) => {
        if (item?.hasOwnProperty("formId")) {
          propArr?.push(...item?.bestRouteProperties);
        } else {
          propArr?.push(item);
        }
      });

      let updatedArr: any = [];
      Array.from(
        new Set(
          propArr?.map((propId: any) => propId?.propertyId || propId?.mlsId)
        )
      ).map((val: any) => {
        const itemFound = propArr?.find((item: any) => {
          if (item?.bestRouteId)
            return (
              (item?.propertyId || item?.mlsId) === val &&
              item?.hasOwnProperty("isFavorite") &&
              item?.isFavorite
            );
          else return (item?.propertyId || item?.mlsId) === val;
        });
        if (itemFound) {
          updatedArr.push(itemFound);
          return itemFound;
        }
      });

      setIsLoading(false);
      setViewedFavoriteProperties(updatedArr?.length > 0 ? updatedArr : []);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getFavoriteProperties = async () => {
    setIsLoading(true);

    try {
      if (!userState?.buyer?.buyerId) {
        setIsLoading(false);

        return;
      }
      if (!userState?.buyer?.token) {
        setIsLoading(false);

        return notification("Buyer Token Not Found", "error");
      }
      const properties: any = await getFavoritePropertiesForBuyerService(
        userState?.buyer?.buyerId,
        userState?.buyer?.token,
        "No"
      );

      if (properties?.length > 0) {
        setFavoriteProperties(properties);
        setIsLoading(false);

        setIsResLoaded(false);
      } else {
        setFavoriteProperties([]);
        setIsResLoaded(true);
        setIsLoading(false);

      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getSavedSearches = async () => {
    setIsLoading(true);

    try {
      const token = userState?.buyer?.token;
      const tokenBuyerId = userState?.buyer?.buyerId;
      if (!token) {
        setIsLoading(false);

        return notification("unauthorized", "error");
      }
      if (!tokenBuyerId) {
        setIsLoading(false);

        return;
      }
      if (token && tokenBuyerId) {
        const searches: any = await getSavedSearchesService(
          userState.buyer.token,
          userState.buyer.buyerId
        );
        if (searches?.length > 0) {
          const searchesFiltered = searches.sort((a: any, b: any) => {
            return +new Date(b?.createdAt) - +new Date(a?.createdAt);
          })
          setIsLoading(false);

          setSavedSearches(searchesFiltered);
          setIsResLoaded(false);
        } else {
          setIsLoading(false);

          setSavedSearches([]);
          setIsResLoaded(true);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("ERR: ", error);
    }
  };

  const deleteSavedSearch = async (savedSearchId: number) => {
    setIsLoading(true);
    try {
      const token = userState.buyer.token;
      if (!token) {
        setIsLoading(false);
        return notification("Unauthorized", "error");
      }
      await deleteSavedSearchService(token, savedSearchId);
      setIsLoading(false);
      getSavedSearches();
    } catch (error) {
      setIsLoading(false);
      console.error("ERR: ", error);
    }
  };

  const getALLLinkedPropertiesForBuyer = async (buyerId: number) => {
    setIsLoading(true);

    try {
      const token = userState?.buyer?.token;
      if (!token) {
        setIsLoading(false);

        return notification("Unauthorized!", "error");
      }

      if (!buyerId) {
        setIsLoading(false);

        return;
      }

      let propertyDetails: any = [];

      const scheduled_data: any = await getAllUpcomingScheduledData(
        buyerId,
        token
      );

      if (scheduled_data?.length > 0) {
        scheduled_data.forEach((item: any) => {
          const propertyDetailsTemp = item?.propertyDetails ? JSON.parse(item?.propertyDetails) : {};
          propertyDetailsTemp["tourType"] = "tour-property";
          propertyDetailsTemp["tourId"] = item?.bestRouteId;
          propertyDetails?.push(propertyDetailsTemp);
        });
      }

      const propertyCalc: any = await getViewPropertyCalendarScheduledEvent(
        buyerId,
        token
      );
      if (propertyCalc?.length > 0) {
        propertyCalc.forEach((item: any) => {
          const propertyDetailsTemp = item?.propertyDetails ? JSON.parse(item?.propertyDetails) : {};
          propertyDetailsTemp["tourType"] = "view-property";
          propertyDetailsTemp["tourId"] = item?.viewPropertyCalendarId;
          if (item?.propertyDetails) {
            propertyDetails?.push(propertyDetailsTemp);
          }
        });
      }

      let listings: any = [],
        newArr: any = [];

      propertyDetails?.map((item: any) => {
        const index = newArr?.findIndex(
          (arrObj: any) => arrObj?.mlsId === item?.mlsId
        );
        if (index === -1) {
          newArr?.push(item);
        }
      });

      for (var i = 0; i < newArr?.length; i++) {
        const listing: any = newArr[i];
        if (listing?.listPrice) {
          listings.push(listing);
        }
      }

      setIsLoading(false);
      setSelectedProperties(listings);
    } catch (err) {
      setIsLoading(false);
      console.log("ERROR: ", err);
    }
  };

  const getUpcomingTour = async (offset: number, isLoadmore: boolean) => {
    setIsLoading(true);

    try {
      const token = userState?.buyer?.token;
      const buyerId = userState?.buyer?.buyerId;

      if (!token) {
        setIsLoading(false);

        console.error("Unauthorized", "error");
      }
      if (!buyerId) {
        setIsLoading(false);

        return;
      }

      let response: any;
      let newArr: any = [];
      let propertyCalc: any;
      setIsLoading(true);

      response = await getAllupcomingTourDetailsData(
        buyerId,
        token,
        "Buyer",
        "Dashboard",
        "Not Schdeduled Showings"
      );

      propertyCalc = await getViewPropertyCalendarByBuyerID(
        buyerId,
        token,
        "Buyer",
        "Dashboard"
      );

      newArr = [...propertyCalc, ...response];
      let upcomingTour = [];
      if (newArr?.length > 0) {
        for (let i = 0; i < newArr?.length; i++) {
          let startTime: any,
            endTime: any,
            currHourTime: any,
            propCalCurrZone: any,
            propCalArea: null,
            bestRouteArea: null,
            acceptedPropertyCount = 0;
          if (newArr[i]?.formId) {
            currHourTime = newArr[i]?.time?.split("-")?.[0]?.split(" ")?.[1];

            if (newArr[i]?.bestRouteProperties?.[0]?.propertyDetails) {
              bestRouteArea = JSON?.parse(
                newArr[i]?.bestRouteProperties?.[0]?.propertyDetails
              )?.address?.city;
            }

            if (newArr[i]?.bestRouteProperties?.length > 0) {
              newArr?.[i]?.bestRouteProperties?.forEach((element: any) => {
                if (element?.isAccepted && element.startTime && element.endTime) {
                  acceptedPropertyCount++;
                }
              });
            }
          }

          if (newArr[i]?.viewPropertyCalendarId) {
            startTime = newArr[i]?.startTime;
            endTime = newArr[i]?.endTime;
            startTime = moment(startTime, "HH:mm").format("hh:mm a");
            endTime = moment(endTime, "HH:mm").format("hh:mm a");
            propCalCurrZone = startTime?.split(" ")?.[1];
            propCalArea = JSON.parse(newArr[i]?.propertyDetails)?.address?.city;
          }
          let date: any,
            dayOfWeek,
            time,
            timeOfDay,
            area,
            properties,
            statusPrimary,
            statusSecondary,
            formId,
            res;
          let correctDate = newArr[i]?.formId
            ? moment.tz(newArr[i]?.dateTime, moment.tz.guess()).utc()
            : moment.tz(newArr[i]?.startDate, moment.tz.guess()).utc();
          date = moment(correctDate, "MM-DD-YYYY").format("LL");
          dayOfWeek = moment(correctDate).format("dddd");

          time = newArr[i]?.formId
            ? newArr[i]?.time
            : `${startTime}-${endTime}`;
          timeOfDay = newArr[i]?.formId
            ? currHourTime === "am"
              ? "Morning"
              : "Evening"
            : propCalCurrZone === "am"
              ? "Morning"
              : "Evening";

          area = newArr[i]?.formId
            ? bestRouteArea ?? "N/A"
            : propCalArea ?? "N/A";
          properties = newArr[i]?.formId ? newArr[i]?.selectedProperties : "1";

          statusPrimary = newArr[i]?.formId
            ? `${acceptedPropertyCount}/${newArr[i]?.selectedProperties} ${newArr[i]?.status}`
            : "1/1 Scheduled";
          statusSecondary = newArr[i]?.formId
            ? userState?.userType === UserType.AGENT
              ? `Agent ${newArr[i]?.status}`
              : `Buyer ${newArr[i]?.status} OH`
            : `Accepted`;

          formId = newArr[i]?.formId ? newArr[i]?.formId : null;
          res = newArr[i];
          const newDataObj = {
            date: date,
            dayOfWeek: dayOfWeek,
            time: time,
            timeOfDay: timeOfDay,
            area: area,
            properties: properties,
            statusPrimary: newArr[i]?.viewPropertyCalendarId
              ? "Accepted"
              : newArr[i]?.status === "Scheduled"
                ? statusPrimary
                : "Saved",
            statusSecondary: newArr[i]?.viewPropertyCalendarId
              ? null
              : newArr[i]?.role === "Agent"
                ? "Agent Scheduled"
                : statusSecondary,
            formId: formId,
            responseData: res,
            createdAt: newArr[i]?.formId
              ? moment(newArr[i]?.dateTime).format("LL")
              : moment(newArr[i]?.startDate),
          };
          upcomingTour.push(newDataObj);
        }
      }
      const sortedProperties: any = upcomingTour?.sort((a: any, b: any) => {
        return +new Date(a?.createdAt) - +new Date(b?.createdAt);
      });
      setUpcomingTourDetail(sortedProperties);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error("error", err);
    }
  };
  const getPreviousTour = async () => {
    setIsLoading(true);

    try {
      const token = userState?.buyer?.token;
      const buyerId = userState?.buyer?.buyerId;

      if (!token) {
        setIsLoading(false);

        console.error("Unauthorized", "error");
      }
      if (!buyerId) {
        setIsLoading(false);

        return;
      }

      let response: any;
      let newArr: any = [];
      let propertyCalc: any;
      setIsLoading(true);
      let currDate = moment().format("YYYY-MM-DD");

      response = await getAllPreviousTourDetailsData(buyerId, currDate, token);

      propertyCalc = await getPreviousViewPropertyCalendarByBuyerID(
        buyerId,
        currDate,
        token,
        "viewed property"
      );

      newArr = [...response, ...propertyCalc];

      let prevTour: any = [];
      if (newArr?.length > 0) {
        for (let i = 0; i < newArr?.length; i++) {
          let startTime: any,
            endTime: any,
            currHourTime: any,
            propCalCurrZone: any,
            propCalArea: null,
            bestRouteArea: null,
            role: string = '';


          if (newArr[i]?.formId) {
            currHourTime = newArr[i]?.time?.split("-")?.[0]?.split(" ")?.[1];
            bestRouteArea = newArr[i]?.bestRouteProperties?.[0]?.propertyDetails ? JSON.parse(
              newArr[i]?.bestRouteProperties?.[0]?.propertyDetails
            )?.address?.city : "N/A";
            role = newArr[i]?.role;
          }

          if (newArr[i]?.viewPropertyCalendarId) {
            startTime = newArr[i]?.startTime;
            endTime = newArr[i]?.endTime;
            startTime = moment(startTime, "HH:mm").format("hh:mm a");
            endTime = moment(endTime, "HH:mm").format("hh:mm a");
            propCalCurrZone = startTime?.split(" ")?.[1];
            propCalArea = newArr[i]?.propertyDetails ? JSON.parse(newArr[i]?.propertyDetails)?.address?.city : "N/A";
          }

          let date: any,
            dayOfWeek,
            time,
            timeOfDay,
            area,
            properties,
            statusPrimary,
            statusSecondary,
            formId,
            res;

          let correctDate = newArr[i]?.formId
            ? moment.tz(newArr[i]?.dateTime, moment.tz.guess()).utc()
            : moment.tz(newArr[i]?.startDate, moment.tz.guess()).utc();

          date = moment(correctDate, "MM-DD-YYYY").format("LL");
          dayOfWeek = moment(correctDate).format("dddd");
          time = newArr[i]?.formId
            ? newArr[i]?.time
            : `${startTime}-${endTime}`;
          timeOfDay = newArr[i]?.formId
            ? currHourTime === "am"
              ? "Morning"
              : "Evening"
            : propCalCurrZone === "am"
              ? "Morning"
              : "Evening";

          area = newArr[i]?.formId
            ? bestRouteArea ?? "N/A"
            : propCalArea ?? "N/A";
          properties = newArr[i]?.formId ? newArr[i]?.selectedProperties : "1";

          statusPrimary =
            role === "Buyer"
              ? "Self-Scheduled"
              : "Scheduled";
          statusSecondary =
            role === "Buyer"
              ? "Open House"
              : "Agent viewing";

          formId = newArr[i]?.formId ? newArr[i]?.formId : null;
          res = newArr[i];

          const newDataObj = {
            date: date,
            dayOfWeek: dayOfWeek,
            time: time,
            timeOfDay: timeOfDay,
            area: area,
            properties: properties,
            statusPrimary: statusPrimary,
            statusSecondary: statusSecondary,
            formId: formId,
            responseData: res,
          };
          prevTour.push(newDataObj);
        }
      }
      prevTour?.sort((a: any, b: any) => +new Date(a?.date) - +new Date(b?.date))
      setPrevTourDetails(prevTour);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error("error", err);
    }
  };


  const getBuyerInfo = async () => {
    setLinkedAgentInfo({
      email: userState.buyer.buyerAgentEmail,
      name: userState?.buyer?.buyerAgentName,
      phone: userState?.buyer?.buyerAgentPhone,
      avatarUrl: userState?.buyer?.buyerAgentAvatarUrl,
      website: userState?.buyer?.buyerAgentWebsite,
      CompanyLogo: userState?.buyer?.buyerAgentCompanyLogo,
    });
    setAgentId(userState?.buyer?.buyerAgentId);
  };

  const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size);
  };
  const onTabChange = (value: string) => {
    switch (value) {
      case "overview":
        selectOverview();
        break;
      case "requestedShowings":
        selectRequestedShowings();
        break;
      case "scheduledShowings":
        selectScheduledShowings();
        break;
      case "savedSearches":
        selectSavedSearches();
        break;
      case "favoriteProperties":
        selectFavoriteProperties();
        break;
      case "propertyComments":
        selectPropertyComments();
        break;
      case "previousTours":
        selectPreviousTours();
        break;
      case "viewedProperties":
        selectViewedProperties();
        break;
      case "viewedFavorites":
        selectViewedFavorites();
        break;
      default:
        return;
    }
  };
  const selectOverview = () => {
    setCurrentTab(DashboardTab.OVERVIEW);
    getUpcomingTour(3, false);
  };

  const selectRequestedShowings = () => {
    setCurrentTab(DashboardTab.REQUESTED_SHOWINGS);
    if (!userState?.buyer?.isLinked) {
      setisFindAgentModal(!isFindAgentModal);
      return;
    }
    getRequestedProperties();
  };

  const selectScheduledShowings = () => {
    setCurrentTab(DashboardTab.SCHEDULED_SHOWINGS);
    getALLLinkedPropertiesForBuyer(userState?.buyer?.buyerId);
  };

  const selectSavedSearches = () => {
    setCurrentTab(DashboardTab.SAVED_SEARCHES);
    getSavedSearches();
  };

  const selectFavoriteProperties = () => {
    setCurrentTab(DashboardTab.FAVORITE_PROPERTIES);
    getFavoriteProperties();
  };

  const selectPropertyComments = () => {
    setCurrentTab(DashboardTab.PROPERTY_COMMENTS);
    getAllPropertyComments();
  };

  const selectPreviousTours = () => {
    setUpcomingTourDetail([]);
    setCurrentTab(DashboardTab.PREVIOUS_TOURS);
    getPreviousTour();
  };

  const selectViewedProperties = () => {
    setCurrentTab(DashboardTab.VIEWED_PROPERTIES);
    getViewedRequestedProperties();
  };

  const selectViewedFavorites = () => {
    setCurrentTab(DashboardTab.VIEWED_FAVORITES);
    getViewedFavoritesProperties();
  };

  const selectPropertyDetails = () => {
    setCurrentTab(DashboardTab.PROPERTY_DETAILS);
    getViewedFavoritesProperties();
  };

  const selectPropertyStatistics = () => {
    setCurrentTab(DashboardTab.PROPERTY_STATISTICS);
    getViewedFavoritesProperties();
  };

  const getOpenHouses = async () => {
    setIsLoading(true);
    try {
      const open_houses = await getPropertiesOpenHousesService();
      if (open_houses?.length > 3) {
        if (
          open_houses[0]?.endTime !== null &&
          open_houses[1]?.endTime !== null &&
          open_houses[2]?.endTime !== null &&
          open_houses[0]?.photos?.length > 0 &&
          open_houses[1]?.photos?.length > 0 &&
          open_houses[2]?.photos?.length > 0
        ) {
          setOpenHouses(open_houses?.slice(0, 3));
        } else {
          setOpenHouses(open_houses?.slice(3, 6));
        }
        return;
      }

      setOpenHouses(open_houses);
    } catch (error) {
      setIsLoading(false);
      console.error(error);

      // return notification('Something Went Wrong!', 'error');
    }
  };

  const getNewListings = async () => {
    setIsLoading(true);
    try {
      const listings = await getPropertiesNewListingsService("Boston");

      if (listings?.length > 3) {
        setNewListings(listings?.slice(0, 3));
        // setIsLoading(false);
        return;
      }
      setNewListings(listings);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getAffordableHomes = async () => {
    setIsLoading(true);
    try {
      const listings = await getPropertiesAffordableService("springfield");

      if (listings?.length > 3) {
        setAffordableHomes(listings?.slice(0, 3));
        return;
      }
      setAffordableHomes(listings);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getLuxuryHomes = async () => {
    setIsLoading(true);
    try {
      const listings = await getPropertiesLuxuryService("MedFord");
      if (listings?.length > 5) {
        setLuxuryHomes(listings?.slice(2, 5));
        return;
      }
      setLuxuryHomes(listings);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const renderRequestedProperties = () => {
    const cards = requestedProperties?.map((listing) => {
      let data: any = null;
      if (listing && listing?.propertyDetails?.length > 0) {
        data = JSON?.parse(listing?.propertyDetails);
      }
      return (
        <>
          <PropertyCard
            agentId={agentId}
            propertyId={listing?.propertyId ?? 0}
            propertyDetails={listing ? listing : " "}
            image={listing?.imageUrl?.length > 0 ? listing?.imageUrl : ""}
            price={listing?.listPrice ?? 0}
            type={listing?.propertyType ?? ""}
            beds={listing?.bedrooms ?? 0}
            baths={listing?.bathrooms ?? 0}
            squareFootage={listing?.area ?? 0}
            mlsId={data?.listingId ?? 0}
            latitude={listing?.latitude ?? 0}
            longitude={listing?.longitude ?? 0}
            country={listing?.country ?? "N/A"}
            streetNumber={Number(listing?.streetNumber) ?? 0}
            streetName={listing?.streetName ?? "N/A"}
            city={listing?.city ?? "N/A"}
            state={listing?.state ?? "N/A"}
            postalCode={listing?.postalCode ?? "N/A"}
            bathsHalf={listing?.bathsHalf ?? 0}
            openHouse={listing?.opneHouse ?? ""}
            feature=""
            isBuyerLinked={userState?.buyer?.isLinked}
            selectFavoriteProperties={selectFavoriteProperties}
            selectRequestedShowings={selectRequestedShowings}
            selectPropertyComments={selectPropertyComments}
            openHouseStartTime={listing?.openHouseStartTime}
            openHouseEndTime={listing?.openHouseEndTime}
            openHouseKey={listing?.openHouseKey}
            openHouseId={listing?.openHouseId}
            openHouseData={listing?.openHouseId}
            listing={data?.listingId ?? ""}
            isBuyerDashboard="requested"
          />
        </>
      );
    });
    return (
      <>
        {userState?.buyer?.isLinked &&
          userState?.buyer?.buyerAgentName?.length > 0 ? (
          <>
            {requestedProperties?.length > 0 ? (
              <Fragment>{cards}</Fragment>
            ) : (
              <>
                {isResLoaded ? (
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <p
                      style={{
                        fontSize: "19px",
                        paddingLeft: "5px",
                        fontWeight: "10px",
                      }}>
                      No Requested Properties
                    </p>

                    <CustomRightDiv>
                      <CustomImage src={globalimage} alt="image with a key" />
                    </CustomRightDiv>
                  </Row>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        ) : (
          <EmptyDashboardForUnlinkedBuyer
            title={noRequest}
            isBuyerLinked={false}
          />
        )}
      </>
    );
  };

  const renderScheduledShowings = () => {
    const cards = selectedProperties?.map((listing) => {
      return (
        <PropertyCard
          agentId={agentId}
          propertyId={listing?.mlsId ?? 0}
          image={listing?.photos?.length > 0 ? listing?.photos[0] : ""}
          propertyDetails={listing ? listing : " "}
          price={listing?.listPrice}
          type={listing?.property?.type}
          beds={listing?.property?.bedrooms}
          baths={listing?.property?.bathsFull}
          squareFootage={listing?.property?.area}
          mlsId={listing?.listingId}
          latitude={listing?.geo?.lat ?? 0}
          longitude={listing?.geo?.lng ?? 0}
          country={listing?.address?.country ?? "N/A"}
          streetNumber={Number(listing?.address?.streetNumber) ?? 0}
          streetName={listing?.address?.streetName ?? "N/A"}
          city={listing?.address?.city ?? "N/A"}
          state={listing?.address?.state ?? "N/A"}
          postalCode={listing?.address?.postalCode ?? "N/A"}
          bathsHalf={listing?.property?.bathsHalf ?? 0}
          openHouse="new_listing"
          feature=""
          isBuyerLinked={userState?.buyer?.isLinked}
          selectFavoriteProperties={selectFavoriteProperties}
          selectRequestedShowings={selectRequestedShowings}
          selectPropertyComments={selectPropertyComments}
          listing={listing?.listingId ?? ""}
          isBuyerDashboard="scheduled"
          tourType={listing?.tourType ?? ""}
          tourId={listing?.tourId ?? ""}
        />
      );
    });
    return (
      <>
        {userState?.buyer?.isLinked &&
          userState?.buyer?.buyerAgentName?.length > 0 ? (
          <>
            {selectedProperties?.length > 0 ? (
              <Fragment>{cards}</Fragment>
            ) : (
              <>
                {isResLoaded ? (
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <p
                      style={{
                        fontSize: "19px",
                        paddingLeft: "5px",
                        fontWeight: "10px",
                      }}>
                      No Scheduled Showings
                    </p>

                    <CustomRightDiv>
                      <CustomImage src={globalimage} alt="image with a key" />
                    </CustomRightDiv>
                  </Row>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        ) : (
          <EmptyDashboardForUnlinkedBuyer
            title={noSchedule}
            isBuyerLinked={false}
          />
        )}
      </>
    );
  };

  const renderFavoriteProperties = () => {
    const cards = favoriteProperties?.map((listing: any) => {
      let data: any = null;

      if (listing && listing?.propertyDetails?.length > 0) {
        data = JSON?.parse(listing?.propertyDetails);
      }
      // const openHouseData = getOpenHouseTime_Details(data?.listingId)

      return (
        <PropertyCard
          agentId={agentId}
          propertyId={listing?.propertyId ?? 0}
          image={listing?.imageUrl?.length > 0 ? listing?.imageUrl : ""}
          propertyDetails={data ? data : " "}
          price={listing?.listPrice}
          type={listing?.type}
          beds={listing?.bedrooms}
          baths={listing?.bathrooms}
          squareFootage={listing?.area}
          mlsId={data?.listingId}
          latitude={listing?.lat ?? 0}
          longitude={listing?.lng ?? 0}
          country={listing?.country ?? "N/A"}
          streetNumber={Number(listing?.streetNumber) ?? 0}
          streetName={listing?.streetName ?? "N/A"}
          city={listing?.city ?? "N/A"}
          state={listing?.state ?? "N/A"}
          postalCode={listing?.postalCode ?? "N/A"}
          bathsHalf={listing?.bathsHalf ?? 0}
          openHouse={listing?.opneHouse}
          feature=""
          isBuyerLinked={userState?.buyer?.isLinked}
          selectFavoriteProperties={selectFavoriteProperties}
          selectRequestedShowings={selectRequestedShowings}
          selectPropertyComments={selectPropertyComments}
          openHouseStartTime={listing?.openHouseStartTime}
          openHouseEndTime={listing?.openHouseEndTime}
          openHouseKey={listing?.openHouseKey}
          openHouseId={listing?.openHouseId}
          openHouseData={listing?.openHouseId}
          listing={listing?.mlsId ? listing?.mlsId : data?.listingId}
          isBuyerDashboard="favorite"
        />
      );
    });
    return (
      <Fragment>
        {/* {userState?.buyer?.isLinked &&
        userState?.buyer?.buyerAgentName?.length > 0 ? ( */}
        <>
          {favoriteProperties?.length > 0 ? (
            <Fragment>{cards}</Fragment>
          ) : (
            <>
              {isResLoaded ? (
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <p
                    style={{
                      fontSize: "19px",
                      paddingLeft: "5px",
                      fontWeight: "10px",
                    }}>
                    No Favorite Properties
                  </p>

                  <CustomRightDiv>
                    <CustomImage src={globalimage} alt="image with a key" />
                  </CustomRightDiv>
                </Row>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      </Fragment>
    );
  };
  const renderPropertyComments = () => {
    const cards =
      commentedProperties?.length > 0 &&
      commentedProperties?.map((listing) => {
        return (
          <PropertyCard
            agentId={agentId}
            propertyId={listing?.propertyId ?? 0}
            image={listing?.imageUrl?.length > 0 ? listing?.imageUrl : ""}
            propertyDetails={listing ? listing : " "}
            price={listing?.listPrice ?? 0}
            type={listing?.propertyType ?? ""}
            beds={listing?.bedrooms ?? 0}
            baths={listing?.bathrooms ?? 0}
            squareFootage={listing?.area ?? 0}
            mlsId={listing?.mlsId ?? 0}
            latitude={listing?.latitude ?? 0}
            longitude={listing?.longitude ?? 0}
            country={listing?.country ?? "N/A"}
            streetNumber={Number(listing?.streetNumber) ?? 0}
            streetName={listing?.streetName ?? "N/A"}
            city={listing?.city ?? "N/A"}
            state={listing?.state ?? "N/A"}
            postalCode={listing?.postalCode ?? "N/A"}
            bathsHalf={listing?.bathsHalf ?? 0}
            openHouse={listing?.opneHouse}
            feature=""
            isBuyerLinked={userState?.buyer?.isLinked}
            selectFavoriteProperties={selectFavoriteProperties}
            selectRequestedShowings={selectRequestedShowings}
            selectPropertyComments={selectPropertyComments}
            openHouseStartTime={listing?.openHouseStartTime}
            openHouseEndTime={listing?.openHouseEndTime}
            openHouseKey={listing?.openHouseKey}
            openHouseId={listing?.openHouseId}
            openHouseData={listing?.openHouseId}
            listing={listing?.mlsId ?? ""}
            isBuyerDashboard="comments"
          />
        );
      });
    return (
      <>
        {userState?.buyer?.isLinked &&
          userState?.buyer?.buyerAgentName?.length > 0 ? (
          <>
            {commentedProperties?.length > 0 ? (
              <Fragment>{cards}</Fragment>
            ) : (
              <>
                {isResLoaded ? (
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <p
                      style={{
                        fontSize: "19px",
                        paddingLeft: "5px",
                        fontWeight: "10px",
                      }}>
                      No Commented Properties
                    </p>

                    <CustomRightDiv>
                      <CustomImage src={globalimage} alt="image with a key" />
                    </CustomRightDiv>
                  </Row>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        ) : (
          <EmptyDashboardForUnlinkedBuyer
            title={noCommentProp}
            isBuyerLinked={false}
          />
        )}
      </>
    );
  };

  const renderPreviousTours = () => {
    return (
      <>
        {userState?.buyer?.isLinked &&
          userState?.buyer?.buyerAgentName?.length > 0 ? (
          <Maindiv>
            <div className="dashboard-buyer-page__overview__calendar-container">
              <div
                className="dashboard-buyer-page__overview__calendar-card"
                style={{ marginRight: "18px" }}>
                <MiniCalendar
                  setUpcomingTourDate={setUpcomingTourDate}
                  setUpcomingStartEndDate={setUpcomingStartEndDate}
                  month={""}
                />
                <CustomPara>
                  Scrolling through months on the calendar will display details
                  of the tours completed in that month.
                </CustomPara>
              </div>
            </div>

            <div
              className="dashboard-buyer-page__overview__upcoming-tours"
              style={{ width: "100%" }}>
              <div className="dashboard-buyer-page__overview__upcoming-tours-table">
                <PreviousSummaryBuyer
                  tours={prevTourDetails}
                  isBuyerLinked={userState?.buyer?.isLinked}
                  upcomingTourDate={upcomingTourDate}
                  source="previousTour"
                  upcomingStartEndDate={upcomingStartEndDate}
                />
              </div>
              <Link to="/find-agent?type=buyer">
                {!userState?.buyer?.isLinked && (
                  <Button
                    type={ButtonType.PRIMARY}
                    text={"FIND AN AGENT"}
                    clickHandler={scheduleTour}
                  />
                )}
              </Link>
            </div>
          </Maindiv>
        ) : (
          <EmptyDashboardForUnlinkedBuyer
            title={noPrevTours}
            isBuyerLinked={false}
          />
        )}
      </>
    );
  };

  const renderViewedProperty = () => {
    const cards = viewedRequestedProperties?.map((item) => {
      let listing = item?.propertyDetails
        ? JSON?.parse(item?.propertyDetails)
        : {};
      return (
        <PropertyCard
          agentId={agentId}
          propertyId={
            listing?.propertyId ? listing?.propertyId : listing?.mlsId
          }
          propertyDetails={listing ? listing : " "}
          image={
            listing?.imageUrl?.length > 0
              ? listing?.imageUrl
              : listing?.photos?.[0]
          }
          price={listing?.listPrice ?? 0}
          type={listing?.propertyType ?? ""}
          beds={
            listing?.bedrooms ? listing?.bedrooms : listing?.property?.bedrooms
          }
          baths={
            listing?.bathrooms
              ? listing?.bathrooms
              : listing?.property?.bathsFull ?? 0
          }
          squareFootage={
            listing?.area ? listing?.area : listing?.property?.area
          }
          mlsId={listing?.mlsId ? listing?.mlsId : listing?.listingId}
          latitude={listing?.latitude ? listing?.latitude : listing?.geo?.lat}
          longitude={
            listing?.longitude ? listing?.longitude : listing?.geo?.lng
          }
          country={listing?.country ? listing?.country : listing?.geo?.county}
          streetNumber={
            Number(listing?.streetNumber)
              ? Number(listing?.streetNumber)
              : Number(listing?.address?.streetNumber) ?? 0
          }
          streetName={
            listing?.streetName
              ? listing?.streetName
              : listing?.address?.streetName ?? "N/A"
          }
          city={listing?.city ? listing?.city : listing?.address?.city ?? "N/A"}
          state={
            listing?.state ? listing?.state : listing?.address?.state ?? "N/A"
          }
          postalCode={
            listing?.postalCode
              ? listing?.postalCode
              : listing?.address?.postalCode ?? "N/A"
          }
          bathsHalf={
            listing?.bathsHalf
              ? listing?.bathsHalf
              : listing?.property?.bathsHalf ?? 0
          }
          openHouse={listing?.opneHouse ?? ""}
          feature=""
          isBuyerLinked={userState?.buyer?.isLinked || false}
          selectFavoriteProperties={selectFavoriteProperties}
          selectRequestedShowings={selectRequestedShowings}
          selectPropertyComments={selectPropertyComments}
          listing={listing?.mlsId ? listing?.mlsId : listing?.listingId ?? ""}
          isBuyerDashboard="viewed"
          setIsPropListed={() => { }}
          openHouseStartTime={item?.openHouseStartTime ?? null}
          openHouseEndTime={item?.openHouseEndTime ?? null}
          tourId={item.hasOwnProperty("bestRouteId") ? item.bestRouteId : item.hasOwnProperty("viewedPropertyId") ? item.viewedPropertyId : item.viewPropertyCalendarId}
          tourType={item.hasOwnProperty("bestRouteId") ? "tour-property" : item.hasOwnProperty("viewedPropertyId") ? "viewed-property" : "view-property"}
        />
      );
    });
    return (
      <>
        {userState?.buyer?.isLinked &&
          userState?.buyer?.buyerAgentName?.length > 0 ? (
          <>
            {viewedRequestedProperties?.length > 0 ? (
              <Fragment>{cards}</Fragment>
            ) : (
              <>
                {isResLoaded ? (
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <p
                      style={{
                        fontSize: "19px",
                        paddingLeft: "5px",
                        fontWeight: "10px",
                      }}>
                      No Viewed Properties
                    </p>

                    <CustomRightDiv>
                      <CustomImage src={globalimage} alt="image with a key" />
                    </CustomRightDiv>
                  </Row>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        ) : (
          <EmptyDashboardForUnlinkedBuyer
            title={noViewedProp}
            isBuyerLinked={false}
          />
        )}
      </>
    );
  };

  const renderViewedFavorites = () => {
    const cards = ViewedFavoriteProperties?.map((item) => {
      let listing = JSON?.parse(item?.propertyDetails);
      return (
        <PropertyCard
          agentId={agentId}
          propertyId={
            listing?.propertyId ? listing?.propertyId : listing?.mlsId
          }
          image={
            listing?.imageUrl?.length > 0
              ? listing?.imageUrl
              : listing?.photos?.[0]
          }
          propertyDetails={listing ? listing : " "}
          price={listing?.listPrice ?? 0}
          type={listing?.propertyType ?? ""}
          beds={
            listing?.bedrooms ? listing?.bedrooms : listing?.property?.bedrooms
          }
          baths={
            listing?.bathrooms
              ? listing?.bathrooms
              : listing?.property?.bathsFull ?? 0
          }
          squareFootage={
            listing?.area ? listing?.area : listing?.property?.area
          }
          mlsId={listing?.mlsId ? listing?.mlsId : listing?.listingId}
          latitude={listing?.latitude ? listing?.latitude : listing?.geo?.lat}
          longitude={
            listing?.longitude ? listing?.longitude : listing?.geo?.lng
          }
          country={listing?.country ? listing?.country : listing?.geo?.county}
          streetNumber={
            Number(listing?.streetNumber)
              ? Number(listing?.streetNumber)
              : Number(listing?.address?.streetNumber) ?? 0
          }
          streetName={
            listing?.streetName
              ? listing?.streetName
              : listing?.address?.streetName ?? "N/A"
          }
          city={listing?.city ? listing?.city : listing?.address?.city ?? "N/A"}
          state={
            listing?.state ? listing?.state : listing?.address?.state ?? "N/A"
          }
          postalCode={
            listing?.postalCode
              ? listing?.postalCode
              : listing?.address?.postalCode ?? "N/A"
          }
          bathsHalf={
            listing?.bathsHalf
              ? listing?.bathsHalf
              : listing?.property?.bathsHalf ?? 0
          }
          openHouse={listing?.opneHouse ?? ""}
          feature=""
          isBuyerLinked={userState?.buyer?.isLinked}
          selectFavoriteProperties={selectFavoriteProperties}
          selectRequestedShowings={selectRequestedShowings}
          selectPropertyComments={selectPropertyComments}
          listing={listing?.mlsId ? listing?.mlsId : listing?.listingId ?? ""}
          isBuyerDashboard="viewedFavorite"
          setIsPropListed={() => { }}
          openHouseStartTime={item?.openHouseStartTime ?? null}
          openHouseEndTime={item?.openHouseEndTime ?? null}
          tourId={item.hasOwnProperty("bestRouteId") ? item.bestRouteId : item.hasOwnProperty("viewedFavoritesId") ? item.viewedFavoritesId : item.viewPropertyCalendarId}
          tourType={item.hasOwnProperty("bestRouteId") ? "tour-property" : item.hasOwnProperty("viewedFavoritesId") ? "viewed-property" : "view-property"}
        />
      );
    });
    return (
      <>
        {userState?.buyer?.isLinked &&
          userState?.buyer?.buyerAgentName?.length > 0 ? (
          <>
            {ViewedFavoriteProperties?.length > 0 ? (
              <Fragment>{cards}</Fragment>
            ) : (
              <>
                {isResLoaded ? (
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <p
                      style={{
                        fontSize: "19px",
                        paddingLeft: "5px",
                        fontWeight: "10px",
                      }}>
                      No Viewed Favorite Properties
                    </p>

                    <CustomRightDiv>
                      <CustomImage src={globalimage} alt="image with a key" />
                    </CustomRightDiv>
                  </Row>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        ) : (
          <EmptyDashboardForUnlinkedBuyer
            title={noViewedFav}
            isBuyerLinked={false}
          />
        )}
      </>
    );
  };

  const renderSavedSearches = () => {
    const cards = savedSearches?.map((search) => {
      return (
        <SavedSearchCard
          data={search}
          deleteSavedSearch={deleteSavedSearch}
          searchedId={search?.savedSearchId}
        />
      );
    });
    return (
      <Fragment>
        <>
          {savedSearches?.length > 0 ? (
            <Fragment>{cards}</Fragment>
          ) : (
            <>
              {isResLoaded ? (
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <p
                    style={{
                      fontSize: "19px",
                      paddingLeft: "5px",
                      fontWeight: "10px",
                    }}>
                    No Saved Searches
                  </p>

                  <CustomRightDiv>
                    <CustomImage src={globalimage} alt="image with a key" />
                  </CustomRightDiv>
                </Row>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      </Fragment>
    );
  };

  const renderTitle = () => {
    var pageTitle = "";
    switch (currentTab) {
      case DashboardTab?.OVERVIEW:
        pageTitle = "Overview";
        break;
      case DashboardTab?.REQUESTED_SHOWINGS:
        pageTitle = "Requested Showings";
        break;
      case DashboardTab?.SCHEDULED_SHOWINGS:
        pageTitle = "Scheduled Showings";
        break;
      case DashboardTab?.SAVED_SEARCHES:
        pageTitle = "Saved Searches";
        break;
      case DashboardTab?.FAVORITE_PROPERTIES:
        pageTitle = "Favorite Properties";
        break;
      case DashboardTab?.PROPERTY_COMMENTS:
        pageTitle = "Property Comments";
        break;
      case DashboardTab?.PREVIOUS_TOURS:
        pageTitle = "Previous Tours";
        break;
      case DashboardTab?.VIEWED_PROPERTIES:
        pageTitle = "Viewed Properties";
        break;
      case DashboardTab?.VIEWED_FAVORITES:
        pageTitle = "Viewed Favorites";
        break;
      case DashboardTab?.PROPERTY_DETAILS:
        pageTitle = "Property Details";
        break;
      case DashboardTab?.PROPERTY_STATISTICS:
        pageTitle = "Property Statistics";
        break;
      default:
        break;
    }
    return <h3 className="dashboard-buyer-page__title">{`${pageTitle}`}</h3>;
  };

  const renderTabs = () => {
    return (
      <div className="dashboard-buyer-page__tabs">
        {isMD ? (
          <>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 24 }}
              layout="horizontal"
              initialValues={{ size: componentSize }}
              onValuesChange={onFormLayoutChange}
              size={componentSize as SizeType}>
              <Form.Item>
                <Select onChange={onTabChange} value={currentTab}>
                  <Option value="overview">Overview</Option>
                  <Option value="requestedShowings">Requested Showings</Option>
                  <Option value="scheduledShowings">Scheduled Showings</Option>
                  <Option value="savedSearches">Saved Searches</Option>
                  <Option value="favoriteProperties">
                    Favorite Properties
                  </Option>
                  <Option value="propertyComments">Property Comments</Option>
                  <Option value="previousTours" onClick={selectPreviousTours}>
                    Previous Tours
                  </Option>
                  <Option value="viewedProperties">Viewed Properties</Option>
                  <Option value="viewedFavorites">Viewed Favorites</Option>
                </Select>
              </Form.Item>
            </Form>
          </>
        ) : (
          <>
            {isSell ? (
              <>
                <div
                  className={`dashboard-buyer-page__tab ${currentTab === DashboardTab.OVERVIEW &&
                    "dashboard-buyer-page__tab--selected"
                    }`}
                  onClick={selectOverview}>
                  <p>Overview</p>
                </div>
                <div
                  className={`dashboard-buyer-page__tab ${currentTab === DashboardTab.PROPERTY_DETAILS &&
                    "dashboard-buyer-page__tab--selected"
                    }`}
                  onClick={selectPropertyDetails}>
                  <p>Property Details</p>
                </div>
                <div
                  className={`dashboard-buyer-page__tab ${currentTab === DashboardTab.PROPERTY_STATISTICS &&
                    "dashboard-buyer-page__tab--selected"
                    }`}
                  onClick={selectPropertyStatistics}>
                  <p>Property Statistics</p>
                </div>
              </>
            ) : (
              <>
                <div
                  className={`dashboard-buyer-page__tab ${currentTab === DashboardTab.OVERVIEW &&
                    "dashboard-buyer-page__tab--selected"
                    }`}
                  onClick={selectOverview}>
                  <p>Overview</p>
                </div>
                <div
                  className={`dashboard-buyer-page__tab ${currentTab === DashboardTab.REQUESTED_SHOWINGS &&
                    "dashboard-buyer-page__tab--selected"
                    }`}
                  onClick={selectRequestedShowings}>
                  <p>Requested Showings</p>
                </div>
                <div
                  className={`dashboard-buyer-page__tab ${currentTab === DashboardTab.SCHEDULED_SHOWINGS &&
                    "dashboard-buyer-page__tab--selected"
                    }`}
                  onClick={selectScheduledShowings}>
                  <p>Scheduled Showings</p>
                </div>
                <div
                  className={`dashboard-buyer-page__tab ${currentTab === DashboardTab.SAVED_SEARCHES &&
                    "dashboard-buyer-page__tab--selected"
                    }`}
                  onClick={selectSavedSearches}>
                  <p>Saved Searches</p>
                </div>

                <div
                  className={`dashboard-buyer-page__tab ${currentTab === DashboardTab.FAVORITE_PROPERTIES &&
                    "dashboard-buyer-page__tab--selected"
                    }`}
                  onClick={selectFavoriteProperties}>
                  <p>Favorite Properties</p>
                </div>
                <div
                  className={`dashboard-buyer-page__tab ${currentTab === DashboardTab.PROPERTY_COMMENTS &&
                    "dashboard-buyer-page__tab--selected"
                    }`}
                  onClick={selectPropertyComments}>
                  <p>Property Comments</p>
                </div>
                <div
                  className={`dashboard-buyer-page__tab ${currentTab === DashboardTab.PREVIOUS_TOURS &&
                    "dashboard-buyer-page__tab--selected"
                    }`}
                  onClick={selectPreviousTours}>
                  <p>Previous Tours</p>
                </div>
                <div
                  className={`dashboard-buyer-page__tab ${currentTab === DashboardTab.VIEWED_PROPERTIES &&
                    "dashboard-buyer-page__tab--selected"
                    }`}
                  onClick={selectViewedProperties}>
                  <p>Viewed Properties</p>
                </div>
                <div
                  className={`dashboard-buyer-page__tab ${currentTab === DashboardTab.VIEWED_FAVORITES &&
                    "dashboard-buyer-page__tab--selected"
                    }`}
                  onClick={selectViewedFavorites}>
                  <p>Viewed Favorites</p>
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  };

  const renderOpenHouses = () => {
    const cards = openHouses?.map((listing: any) => {
      return (
        <PropertyCard
          agentId={agentId}
          property_type="open_house"
          propertyId={listing?.mlsId ?? 0}
          image={listing?.photos?.length > 0 ? listing?.photos[0] : ""}
          propertyDetails={listing ? listing : " "}
          price={listing?.listPrice}
          type={listing?.property?.type}
          beds={listing?.property?.bedrooms}
          baths={listing?.property?.bathsFull}
          squareFootage={listing?.property?.area}
          mlsId={listing?.listingId}
          latitude={listing?.geo?.lat ?? 0}
          longitude={listing?.geo?.lng ?? 0}
          country="United State"
          streetNumber={Number(listing?.address?.streetNumber) ?? 0}
          streetName={listing?.address?.streetName ?? "N/A"}
          city={listing?.address?.city ?? "N/A"}
          state={listing?.address?.state ?? "N/A"}
          postalCode={listing?.address?.postalCode ?? "N/A"}
          bathsHalf={listing?.property?.bathsHalf ?? 0}
          openHouse="open_house"
          listing={listing?.listingId ?? ""}
          feature=""
          isBuyerLinked={userState?.buyer?.isLinked}
          openHouseData={listing?.openHouseId ?? ""}
          selectFavoriteProperties={() => { }}
          selectRequestedShowings={() => { }}
          selectPropertyComments={() => { }}
          setTourPropPage={() => { }}
          openHouseStartTime={listing?.startTime ?? ""}
          openHouseEndTime={listing?.endTime ?? ""}
          openHouseId={listing?.openHouseId}
          openHouseKey={String(listing?.openHouseKey)}
        />
      );
    });
    return <Fragment>{cards}</Fragment>;
  };

  const renderNewListings = () => {
    const cards = newListings?.map((listing: any) => {
      return (
        <PropertyCard
          agentId={agentId}
          propertyId={listing?.mlsId ?? 0}
          image={listing?.photos?.length > 0 ? listing?.photos[0] : ""}
          price={listing?.listPrice}
          type={listing?.property?.type}
          beds={listing?.property?.bedrooms}
          propertyDetails={listing ? listing : " "}
          baths={listing?.property?.bathsFull}
          squareFootage={listing?.property?.area}
          mlsId={listing?.listingId}
          latitude={listing?.geo?.lat ?? 0}
          longitude={listing?.geo?.lng ?? 0}
          country="United Stated"
          streetNumber={Number(listing?.address?.streetNumber) ?? 0}
          streetName={listing?.address?.streetName ?? "N/A"}
          city={listing?.address?.city ?? "N/A"}
          state={listing?.address?.state ?? "N/A"}
          postalCode={listing?.address?.postalCode ?? "N/A"}
          bathsHalf={listing?.property?.bathsHalf ?? 0}
          openHouse="new_listing"
          feature=""
          isBuyerLinked={userState?.buyer?.isLinked}
          selectFavoriteProperties={() => { }}
          selectRequestedShowings={() => { }}
          selectPropertyComments={() => { }}
          setTourPropPage={() => { }}
        />
      );
    });
    return <Fragment>{cards}</Fragment>;
  };

  const renderAffordableHomes = () => {
    const cards = affordableHomes?.map((listing: any) => {
      return (
        <PropertyCard
          agentId={agentId}
          property_type="affordable_homes"
          propertyDetails={listing ? listing : " "}
          propertyId={listing?.mlsId ?? 0}
          image={listing?.photos?.length > 0 ? listing?.photos[0] : ""}
          price={listing?.listPrice}
          type={listing?.property?.type}
          beds={listing?.property?.bedrooms}
          baths={listing?.property?.bathsFull}
          squareFootage={listing?.property?.area}
          mlsId={listing?.listingId}
          latitude={listing?.geo?.lat ?? 0}
          longitude={listing?.geo?.lng ?? 0}
          country="United State"
          streetNumber={Number(listing?.address?.streetNumber) ?? 0}
          streetName={listing?.address?.streetName ?? "N/A"}
          city={listing?.address?.city ?? "N/A"}
          state={listing?.address?.state ?? "N/A"}
          postalCode={listing?.address?.postalCode ?? "N/A"}
          bathsHalf={listing?.property?.bathsHalf ?? 0}
          openHouse="affordable_homes"
          feature=""
          isBuyerLinked={userState?.buyer?.isLinked}
          selectFavoriteProperties={() => { }}
          selectRequestedShowings={() => { }}
          selectPropertyComments={() => { }}
          setTourPropPage={() => { }}
        />
      );
    });
    return <Fragment>{cards}</Fragment>;
  };

  const renderLuxuryHomes = () => {
    const cards = luxutyHomes?.map((listing: any) => {
      return (
        <PropertyCard
          agentId={agentId}
          property_type="luxury_homes"
          propertyDetails={listing ? listing : " "}
          propertyId={listing?.mlsId ?? 0}
          image={listing?.photos?.length > 0 ? listing?.photos[0] : ""}
          price={listing?.listPrice}
          type={listing?.property?.type}
          beds={listing?.property?.bedrooms}
          baths={listing?.property?.bathsFull}
          squareFootage={listing?.property?.area}
          mlsId={listing?.listingId}
          latitude={listing?.geo?.lat ?? 0}
          longitude={listing?.geo?.lng ?? 0}
          country="United State"
          streetNumber={Number(listing?.address?.streetNumber) ?? 0}
          streetName={listing?.address?.streetName ?? "N/A"}
          city={listing?.address?.city ?? "N/A"}
          state={listing?.address?.state ?? "N/A"}
          postalCode={listing?.address?.postalCode ?? "N/A"}
          bathsHalf={listing?.property?.bathsHalf ?? 0}
          openHouse="luxury_homes"
          feature=""
          isBuyerLinked={userState?.buyer?.isLinked}
          selectFavoriteProperties={() => { }}
          selectRequestedShowings={() => { }}
          selectPropertyComments={() => { }}
          setTourPropPage={() => { }}
        />
      );
    });
    return <Fragment>{cards}</Fragment>;
  };

  const renderOverviewContent = () => {
    return (
      <div className="dashboard-buyer-page__overview">
        <div className="dashboard-buyer-page__overview__cover">
          <div className="dashboard-buyer-page__overview__cover-content">
            <h1>
              Find your <br />
              DREAM HOME
            </h1>
            <SearchField
              placeholder="Enter neighbourhood, city, ZIP code"
              searchAction={searchAction}
            />
          </div>
        </div>

        <div className="dashboard-buyer-page__overview__calendar-container">
          <h3>My Calendar</h3>
          <div className="dashboard-buyer-page__overview__calendar-card">
            <MiniCalendar
              isBuyerLinked={userState?.buyer?.isLinked}
              setUpcomingTourDate={setUpcomingTourDate}
              setUpcomingStartEndDate={setUpcomingStartEndDate}
              month={""}
            />
          </div>
        </div>
        <div className="dashboard-buyer-page__overview__upcoming-tours">
          <h3 className="h3-after-display-none">Upcoming Tours</h3>
          {userState?.buyer?.isLinked ? (
            <div className="dashboard-buyer-page__overview__upcoming-tours-table">
              <ToursSummaryBuyer
                tours={upcomingTourDetail}
                isBuyerLinked={userState?.buyer?.isLinked}
                upcomingTourDate={upcomingTourDate}
                upcomingStartEndDate={upcomingStartEndDate}
              />
            </div>
          ) : (
            <>
              <div className="dashboard-buyer-page__overview__upcoming-tours-table">
                <ToursSummaryBuyer
                  tours={upcomingTourDetail}
                  upcomingStartEndDate={upcomingStartEndDate}
                  isBuyerLinked={userState?.buyer?.isLinked}
                />
              </div>
              <p
                style={{
                  marginBottom: "4rem",
                  paddingBottom: "1rem",
                  fontSize: "18px",
                }}>
                Connect to an agent to view upcoming tour details.
              </p>
            </>
          )}

          {/* <Link to="/find-agent"> */}
          <div className="dashboardbuyerpage-button">
            {(!userState?.buyer?.isLinked ||
              userState?.buyer?.buyerAgentName?.length <= 0) && (
                <Button
                  type={ButtonType.PRIMARY}
                  text={"FIND AN AGENT"}
                  clickHandler={scheduleTour}
                />
              )}

            {userState?.buyer?.isLinked &&
              userState?.buyer?.buyerAgentName?.length > 0 && (
                <CustomButton
                  type={ButtonType.PRIMARY}
                  text={"SCHEDULE OPEN HOUSE"}
                  clickHandler={handleopenhouse}
                />
              )}
          </div>
        </div>

        <div className="dashboard-buyer-page__previews__container">
          <div className="dashboard-buyer-page__preview">
            <div className="dashboard-buyer-page__preview__header">
              <h3>Open Houses</h3>
              <Link
                to={`/properties?city=${cityNameOpen}&type=open_house`}
                className="text-link">
                <span>View all</span>
              </Link>
            </div>
            <div className="dashboard-buyer-page__preview__content">
              {renderOpenHouses()}
            </div>
          </div>

          <div className="dashboard-buyer-page__preview">
            <div className="dashboard-buyer-page__preview__header">
              {newListings && (
                <Newlistingheading>
                  New Listings in {cityNameNew ?? " "}
                </Newlistingheading>
              )}
              <Link
                to={`/properties?city=${cityNameNew}`}
                className="text-link">
                <span
                  onClick={() => {
                    setQuery(cityNameNew);
                  }}>
                  View all
                </span>
              </Link>
            </div>
            <div className="dashboard-buyer-page__preview__content">
              {renderNewListings()}
            </div>
          </div>

          <div className="dashboard-buyer-page__preview">
            <div className="dashboard-buyer-page__preview__header">
              {affordableHomes && (
                <Affordableheading>
                  Affordable Homes in {cityNameAff ?? " "}
                </Affordableheading>
              )}
              <Link
                to={`/properties?city=${cityNameAff}`}
                className="text-link">
                <span
                  onClick={() => {
                    setQuery(cityNameAff);
                  }}>
                  View all
                </span>
              </Link>
            </div>
            <div className="dashboard-buyer-page__preview__content">
              {renderAffordableHomes()}
            </div>
          </div>
          <div className="dashboard-buyer-page__preview">
            <div className="dashboard-buyer-page__preview__header">
              {luxutyHomes && (
                <LuxuryHeading>
                  Luxury Homes in {cityNameLux ?? " "}
                </LuxuryHeading>
              )}
              <Link
                to={`/properties?city=${cityNameLux}`}
                className="text-link">
                <span
                  onClick={() => {
                    setQuery(cityNameLux);
                  }}>
                  View all
                </span>
              </Link>
            </div>
            <div className="dashboard-buyer-page__preview__content">
              {renderLuxuryHomes()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderRequestedShowingsContent = () => {
    return (
      <div
        className={
          requestedProperties?.length > 0 &&
            userState?.buyer?.buyerAgentName?.length > 0
            ? "dashboard-buyer-page__requested-showings"
            : ""
        }>
        {renderRequestedProperties()}
      </div>
    );
  };

  const renderScheduledShowingsContent = () => {
    return (
      <div
        className={
          selectedProperties?.length > 0 &&
            userState?.buyer?.buyerAgentName?.length > 0
            ? "dashboard-buyer-page__requested-showings"
            : ""
        }>
        {renderScheduledShowings()}
      </div>
    );
  };

  const renderSavedSearchesContent = () => {
    return (
      <div className="dashboard-buyer-page__saved-searches">
        {renderSavedSearches()}
      </div>
    );
  };

  const renderFavoritePropertiesContent = () => {
    return (
      <div
        className={
          favoriteProperties?.length > 0 &&
          "dashboard-buyer-page__favorite-properties"
        }>
        {renderFavoriteProperties()}
      </div>
    );
  };

  const renderPropertyCommentsContent = () => {
    return (
      <div
        className={
          commentedProperties?.length > 0 &&
            userState?.buyer?.buyerAgentName?.length > 0
            ? "dashboard-buyer-page__property-comments"
            : ""
        }>
        {renderPropertyComments()}
      </div>
    );
  };

  const renderPreviousToursContent = () => {
    return (
      <div className="dashboard-buyer-page__previous-tours">
        {renderPreviousTours()}
      </div>
    );
  };

  const renderViewedPropertiesContent = () => {
    return (
      <div
        className={
          viewedRequestedProperties?.length > 0 &&
            userState?.buyer?.buyerAgentName?.length > 0
            ? "dashboard-buyer-page__viewed-properties"
            : ""
        }>
        {renderViewedProperty()}
      </div>
    );
  };

  const renderViewedFavoritesContent = () => {
    return (
      <div
        className={
          ViewedFavoriteProperties?.length > 0 &&
            userState?.buyer?.buyerAgentName?.length > 0
            ? "dashboard-buyer-page__viewed-favorites"
            : ""
        }>
        {renderViewedFavorites()}
      </div>
    );
  };
  const renderPropertyDetailsContent = () => {
    return (
      <div
        className={
          ViewedFavoriteProperties?.length > 0 &&
            userState?.buyer?.buyerAgentName?.length > 0
            ? "dashboard-buyer-page__viewed-favorites"
            : ""
        }>
        <h1>Property Details</h1>
      </div>
    );
  };
  const renderPropertyStatisticsContent = () => {
    return (
      <div
        className={
          ViewedFavoriteProperties?.length > 0 &&
            userState?.buyer?.buyerAgentName?.length > 0
            ? "dashboard-buyer-page__viewed-favorites"
            : ""
        }>
        <h1>Property Statistics</h1>
      </div>
    );
  };
  const renderTabContent = () => {
    switch (currentTab) {
      case DashboardTab.OVERVIEW:
        return renderOverviewContent();
      case DashboardTab.REQUESTED_SHOWINGS:
        return renderRequestedShowingsContent();
      case DashboardTab.SCHEDULED_SHOWINGS:
        return renderScheduledShowingsContent();
      case DashboardTab.SAVED_SEARCHES:
        return renderSavedSearchesContent();
      case DashboardTab.FAVORITE_PROPERTIES:
        return renderFavoritePropertiesContent();
      case DashboardTab.PROPERTY_COMMENTS:
        return renderPropertyCommentsContent();
      case DashboardTab.PREVIOUS_TOURS:
        return renderPreviousToursContent();
      case DashboardTab.VIEWED_PROPERTIES:
        return renderViewedPropertiesContent();
      case DashboardTab.VIEWED_FAVORITES:
        return renderViewedFavoritesContent();
      case DashboardTab.PROPERTY_DETAILS:
        return renderPropertyDetailsContent();
      case DashboardTab.PROPERTY_STATISTICS:
        return renderPropertyStatisticsContent();
      default:
        return "";
    }
  };
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let param = useQuery();

  useEffect(() => {
    getOpenHouses();
    getNewListings();
    getAffordableHomes();
    getLuxuryHomes();
    getBuyerInfo();
    getUpcomingTour(3, false);
    const queryTab: string = param.get("buyerDashboard");
    const page: string = localStorage.getItem("page");

    if (page === "previousTour" || queryTab === "previousTour") {
      setCurrentTab(DashboardTab.PREVIOUS_TOURS);
      getPreviousTour();
      localStorage.removeItem("page");
    }
    if (queryTab === "requested") {
      setCurrentTab(DashboardTab.REQUESTED_SHOWINGS);
      getRequestedProperties();
    } else if (queryTab === "scheduled") {
      setCurrentTab(DashboardTab.SCHEDULED_SHOWINGS);
      getALLLinkedPropertiesForBuyer(userState?.buyer?.buyerId);
    } else if (queryTab === "favorite") {
      setCurrentTab(DashboardTab.FAVORITE_PROPERTIES);
      getFavoriteProperties();
    } else if (queryTab === "comments") {
      setCurrentTab(DashboardTab.PROPERTY_COMMENTS);
      getAllPropertyComments();
    } else if (queryTab === "viewed") {
      setCurrentTab(DashboardTab.VIEWED_PROPERTIES);
      getViewedRequestedProperties();
    } else if (queryTab === "viewedFavorite") {
      setCurrentTab(DashboardTab.VIEWED_FAVORITES);
      getViewedFavoritesProperties();
    }
    else if (queryTab === "saved") {
      setCurrentTab(DashboardTab.SAVED_SEARCHES);
      getSavedSearches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {userState?.userType === UserType?.BUYER &&
        userState?.buyer?.isLinked &&
        history?.location?.pathname === "/buy" ? (
        <SellerHeader sellerDetail={linkedAgentInfo} agentSubscription_type={userState?.buyer?.agentSubType} />
      ) : (
        <></>
      )}
      <div className="dashboard-buyer-page">
        {isLoading && <CustomSpinner />}
        {isBuy === undefined ? (
          <LandingBuyerPage />
        ) : (
          <>
            {isSell ? (
              userState?.buyer?.isLinked ? (
                <>
                  {renderTitle()}
                  <div className="dashboard-buyer-page__container">
                    {renderTabs()}
                    <div className="dashboard-buyer-page__current-tab-content">
                      {renderTabContent()}
                    </div>
                  </div>
                </>
              ) : (
                <EmptyDashboardTab
                  title={sellTitle}
                  isLoggedInSell={true}
                  isButton={true}
                  buttonText="NOTIFICATIONS"
                />
              )
            ) : (
              <>
                {renderTitle()}
                <div className="dashboard-buyer-page__container">
                  {renderTabs()}
                  <div className="dashboard-buyer-page__current-tab-content">
                    {renderTabContent()}
                  </div>
                </div>
                {isFindAgentModal && userState?.buyer?.isLinked && (
                  <FindAnAgentModal
                    isShowing={isFindAgentModal}
                    closeAction={selectRequestedShowings}
                  />
                )}
                {isAgentModal && (
                  <FindAnAgentModal
                    isShowing={isAgentModal}
                    closeAction={closeAction2}
                    text="calendar"
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DashboardBuyerPage;
