import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import AgentContext from '../contexts/AgentContext'
import PropertyFiltersContext from '../contexts/PropertyFiltersContext'
import moment from 'moment'

interface PreviousSummaryItemData {
  date: string
  dayOfWeek: string
  time: string
  timeOfDay: string
  area: string
  properties: number
  statusPrimary: string
  statusSecondary: string
  formId: number
  responseData?: any
}

interface PreviousSummaryProps {
  tours: PreviousSummaryItemData[]
  isAgentSide?: boolean
  isBuyerLinked?: boolean
  upcomingTourDate?: any
  source?: string
  upcomingStartEndDate?: any
}

interface DataType {
  key: React.Key
  date: any
  time: any
  Area: string
  properties: string
  status: any
}

const PreviousSummary: React.FC<PreviousSummaryProps> = ({
  tours,
  upcomingStartEndDate,
}) => {

  const columns: ColumnsType<DataType> = [
    {
      title: 'Date',
      dataIndex: 'date',
      render: (_, { date }) => (
        <div>
          <div>{date?.date}</div>
          <div>{date?.dayOfWeek}</div>
        </div>
      ),
    },
    {
      title: 'Time',
      dataIndex: 'time',
      render: (_, { time }) => (
        <div>
          <div>{time?.time}</div>
          <div>{time?.timeOfDay}</div>
        </div>
      ),
    },
    {
      title: 'Area',
      dataIndex: 'area',
    },
    {
      title: 'Properties',
      dataIndex: 'properties',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, { status }) => (
        <div>
          <div>{status?.statusPrimary}</div>
          <div>{status?.statusSecondary}</div>
        </div>
      ),
    },
  ]


  // const data: DataType[] = [];
  const [data, setData]: any = useState();
  const [defaultData, setDefaultData]: any = useState();
  const { selectBuyer } = useContext(AgentContext);
  const { setTourType, setSingleTourId } = useContext(PropertyFiltersContext);
  const history = useHistory()
  const handleBuyerTourDetail = (event: any) => {
    let status = event?.status?.statusSecondary;
    //todo jb agent accept krega per property agr 4 select ki and 4 accept hui tb niche wali cond me add hoga  and usko route pe bhejnge
    let buyerId = -1,
      name = "", phone = "", email = "", preferredContact = "";
    buyerId = event?.responseData?.buyerId;
    name = event?.responseData?.buyer?.name
    phone = event?.responseData?.buyer?.phone
    email = event?.responseData?.buyer?.email
    preferredContact = event?.responseData?.buyer?.preferredContact
    selectBuyer(buyerId, name, phone, email, preferredContact);
    if (event?.formId === null && event?.responseData?.viewPropertyCalendarId) {
      setTourType("single-property");
      setSingleTourId(event?.responseData?.viewPropertyCalendarId)
      let propId = event?.responseData?.mlsId;
      history.push(`/properties/${propId}?id=${buyerId}&buyerDashboard=previousTour&listingId=${event?.responseData?.listingId}`)
      return;
    }
    setTourType("tour-property");
    history.push(
      `/tourdetail?buyerId=${buyerId}&formId=${event?.formId}&status=${status}&buyerDashboard=previousTour`,
    )
  }
  useEffect(() => {
    if (upcomingStartEndDate?.startDate !== null) {
      setDefaultData([])
      if (tours?.length > 0) {
        let finalArr: any = []
        tours.map((item, i) => {
          let bool2 = moment(item?.date).isBetween(upcomingStartEndDate?.startDate, upcomingStartEndDate?.endDate) || moment(item?.date).isSame(upcomingStartEndDate?.startDate) || moment(item?.date).isSame(upcomingStartEndDate?.endDate);
          if (bool2) {
            finalArr.push({
              key: i,
              date: { date: item?.date, dayOfWeek: item?.dayOfWeek },
              time: { time: item?.time, timeOfDay: item?.timeOfDay },
              area: item?.area,
              // properties: `${item?.properties} properties`,
              properties: String(item?.properties) === '1' ? `${item?.properties} property` : `${item?.properties} properties`,
              status: {
                statusPrimary: item?.statusPrimary,
                statusSecondary: item?.statusSecondary,
              },
              formId: item?.formId,
              responseData: item?.responseData,
            })
          }
          return finalArr
        })

        setData(finalArr)
      }
    }
  }, [tours, upcomingStartEndDate])

  useEffect(() => {
    if (tours?.length > 0) {
      let finalArr: any = []
      // we have to render future or current data  
      let currDate = moment().format('LL').toString();
      tours.forEach((item, i) => {
        let bool2 = moment(item?.date).isSameOrBefore(currDate);
        if (bool2) {
          finalArr.push({
            key: i,
            date: { date: item?.date, dayOfWeek: item?.dayOfWeek },
            time: { time: item?.time, timeOfDay: item?.timeOfDay },
            area: item?.area,
            properties: String(item?.properties) === '1' ? `${item?.properties} property` : `${item?.properties} properties`,
            status: {
              statusPrimary: item?.statusPrimary,
              statusSecondary: item?.statusSecondary,
            },
            formId: item?.formId,
            responseData: item?.responseData,
          })
        }
      })

      setDefaultData(finalArr)
    }
  }, [tours])

  return (
    <div style={{ width: '100%', padding: '0px 10px' }}>

      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: (event: any) => {
              handleBuyerTourDetail(record)
            },
          }
        }}
        style={{ width: '100% !important', cursor: 'pointer' }}
        columns={columns}
        dataSource={data?.length > 0 ? data : defaultData}
        pagination={{ defaultCurrent: 1, pageSize: 5 }}
      />
    </div>
  )
}

export default PreviousSummary
