/* eslint-disable jsx-a11y/alt-text */
import styled from "@emotion/styled";
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, TimePicker } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { notification } from "../components/notification";
import CustomRecurrenceModal from "./CustomRecurrenceModal";

const dateFormatList = ["MM/DD/YYYY", "MM/DD/YY"];
const style = { background: "white", padding: "8px 0" };
const { Option } = Select;
const { TextArea } = Input;

const FullDatePicker: any = styled(DatePicker)``;
const AntpickerIcon = styled.div`
  .ant-picker-suffix {
    display: flex;
    flex: none;
    align-self: center;
    margin-left: 4px;
    margin-right: 9px;
    color: rgb(20, 107, 201);
    line-height: 1;
    pointer-events: none;
    flex-direction: row-reverse;
  }
`;

const CustomButton = styled(Button)`
  color: #ffff;
  color: black;
  background: #c2d500;
  border-radius: 6px;
`;
interface ICalendarModalProps {
  isShowing: boolean;
  Sdate?: any;
  Stime?: any;
  Edate?: any;
  Etime?: any;
  title?: string;
  rStatus?: string;
  rSdate?: Date;
  rEdate?: Date;
  type?: string;
  eventId?: number;
  closeAction: () => void;
  setDaysOfWeek?: (value: Array<any>) => void;
  setEditClicked?: (value: boolean) => void;
  getModalData: (
    values: any,
    customStartDate: any,
    customEndDate: any,
    type: string,
    eventId: number,
    listingBuyerId?: number,
    buyerAgentId?: number,
    updateSender?: string,
    eventType?: string
  ) => void;
  arg?: any;
  setIsRescheduleRequestEvent?: (value: boolean) => void;
  eventType: string;
  updateSender?: string;
  scheduledEventType?: string;
  // setArgData?: any;
}

interface FormInitialValues {
  StartDate: moment.Moment;
  StartTime?: any;
  EndDate?: moment.Moment;
  EndTime?: moment.Moment;
  RecurringStatus?: string;
  Description?: string;
}
const CustomRegHeading = styled.h1`
  font-size: 22px;
  color: #365073;
  @media screen and (max-width: 420px) {
    font-size: 19px;
  }
`;
const CalendarModal: React.FC<ICalendarModalProps> = ({
  isShowing,
  Sdate,
  Stime,
  Edate,
  Etime,
  title,
  rStatus,
  rSdate,
  rEdate,
  type,
  eventId,
  setDaysOfWeek,
  closeAction,
  setEditClicked,
  getModalData,
  updateSender,
  setIsRescheduleRequestEvent,
  arg,
  eventType
}): JSX.Element => {
  const [startTime, setStrtTime] = useState<any>(Stime);
  const [startDate, setStartDate] = useState<any>();
  const [endTime, setEndTime] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [recurringStatus, setRecurringStatus] = useState<any>("abv");
  const [customStartDate, setCustomStartDate] = useState<any>(moment(Sdate).format("YYYY-MM-DD"));
  const [customEndDate, setCustomEndDate] = useState<any>(moment(rEdate).format("YYYY-MM-DD"));
  const [initialValues, setInitialValues] = useState<FormInitialValues>({
    StartDate: moment(Sdate),
    StartTime: moment(Stime, "HH:mm a"),
    EndDate: moment(Edate),
    EndTime: moment(Etime, "HH:mm a"),
    RecurringStatus: rStatus || "Does not repeat",
    Description: title
  });

  const [form] = Form.useForm();
  const OnStartTimeChange = (time: moment.Moment) => {
    setStrtTime(moment().format("h:mm a"));
  };

  const OnStartDateChange = (date: moment.Moment) => {
    setStartDate(moment().format("LL"));
    setCustomStartDate(moment(date).format("YYYY-MM-DD"));
  };
  const OnEndDateChange = (date: moment.Moment) => {
    setEndDate(moment().format(""));
  };
  const OnEndTimeChange = (time: moment.Moment) => {
    setEndTime(moment().format());
  };

  const onFinish = (values: any) => {
    if (moment(values.StartDate).format("MM/DD/YYYY") <= moment(values.EndDate).format("MM/DD/YYYY")) {
      if (moment(values.EndTime).format("HH:MM") < moment(values.StartTime).format("HH:MM")) {
        notification("The end time should be greater than the start date", "error");
        return;
      }
    } else if (
      moment(values.StartDate).format("MM/DD/YYYY") > moment(values.EndDate).format("MM/DD/YYYY") ||
      // eslint-disable-next-line no-mixed-operators
      moment(values.StartDate).format("MM/DD/YYYY").split("/")[2] > moment(values.EndDate).format("MM/DD/YYYY").split("/")[2] === true
    ) {
      notification("The End date should be greater than the start Date", "error");
      return;
    }

    getModalData(
      values,
      customStartDate,
      customEndDate,
      type,
      eventId,
      arg?.event?._def?.extendedProps?.agentId,
      arg?.event?._def?.extendedProps?.buyerId,
      updateSender,
      arg?.event?._def?.extendedProps?.eventType
    );
    closeAction();
    setVisible(false);
    setIsRescheduleRequestEvent(false);
  };
  const [visible, setVisible] = React.useState(isShowing);
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const [isShowingCustomRecurrenceModal, setIsShowingCustomRecurrenceModal] = useState<boolean>(false);

  const handleOk = () => {
    closeAction();
    setVisible(false);
    setConfirmLoading(true);
    setIsRescheduleRequestEvent(false);
  };
  const handleCancel = () => {
    closeAction();
    setVisible(false);
    setIsRescheduleRequestEvent(false);
  };

  const customRecurrenceModalHandler = (value: any) => {
    if (value === "Does not repeat") setRecurringStatus(value);
    else {
      setRecurringStatus(value);
      setIsShowingCustomRecurrenceModal(true);
    }
  };

  const isMounted = React.useRef(true);

  React.useEffect(() => {
    if (isMounted.current) {
      // form.setFieldsValue({"RecurringStatus":recurringStatus})
      return () => {
        isMounted.current = false;
      };
    }
  }, [recurringStatus]);

  return (
    <Modal visible={visible} onOk={handleOk} confirmLoading={confirmLoading} onCancel={handleCancel} footer={false} width={750} destroyOnClose={true}>
      {eventType === "request" && (
        <CustomRegHeading>
          Send Showing <span style={{ borderBottom: "3px solid #C2D500" }}>Request?</span>
        </CustomRegHeading>
      )}
      {eventType === "update" && (
        <CustomRegHeading>
          Update A<span style={{ borderBottom: "3px solid #C2D500" }}>vailability</span>
        </CustomRegHeading>
      )}

      {eventType === "create" && (
        <CustomRegHeading>
          Calendar A<span style={{ borderBottom: "3px solid #C2D500" }}>vailability</span>
        </CustomRegHeading>
      )}

      <br></br>
      <p>
        <Form form={form} onFinish={onFinish} initialValues={initialValues}>
          <p>
            <h4>Date and time:</h4>
            <Form.Item>
              <Row gutter={[16, 24]}>
                <Col span={5}>
                  <AntpickerIcon>
                    <div style={style}>
                      Date*
                      <Form.Item required name="StartDate" rules={[{ required: true }]}>
                        <FullDatePicker
                          onChange={OnStartDateChange}
                          value={startDate}
                          format={dateFormatList}
                          style={{
                            borderRadius: "5px",
                            boxShadow: "1px 1px 1px grey ",
                            border: "solid",
                            borderColor: "darkblue",
                            borderWidth: "thin"
                          }}
                        />
                      </Form.Item>
                    </div>
                  </AntpickerIcon>
                </Col>
                <Col span={5}>
                  <AntpickerIcon>
                    <div style={style}>
                      Time*
                      <Form.Item name="StartTime" required rules={[{ required: true }]}>
                        <TimePicker
                          use12Hours
                          format="h:mm a"
                          value={startTime}
                          // value={Stime}
                          onChange={OnStartTimeChange}
                          style={{
                            borderRadius: "5px",
                            boxShadow: "2px 2px 2px grey",
                            border: "solid",
                            borderColor: "darkblue",
                            borderWidth: "thin"
                          }}
                        />
                      </Form.Item>
                    </div>
                  </AntpickerIcon>
                </Col>
                <h1 style={{ alignContent: "center" }}>
                  <img
                    src="https://img.icons8.com/external-royyan-wijaya-detailed-outline-royyan-wijaya/24/000000/external-arrow-arrow-line-royyan-wijaya-detailed-outline-royyan-wijaya-13.png"
                    style={{
                      color: "blue",
                      alignContent: "center",
                      marginTop: "150%",
                      marginLeft: "-1%"
                    }}
                  />
                </h1>
                <Col span={5}>
                  <AntpickerIcon>
                    <div style={style}>
                      Date*
                      <Form.Item name="EndDate" required rules={[{ required: true }]}>
                        <FullDatePicker
                          onChange={OnEndDateChange}
                          value={endDate}
                          format={dateFormatList}
                          style={{
                            borderRadius: "5px",
                            boxShadow: "2px 2px 2px grey",
                            border: "solid",
                            borderColor: "darkblue",
                            borderWidth: "thin"
                          }}
                        />
                      </Form.Item>
                    </div>
                  </AntpickerIcon>
                </Col>
                <Col span={5}>
                  <AntpickerIcon>
                    <div style={style}>
                      Time*
                      <Form.Item name="EndTime" required rules={[{ required: true }]}>
                        <TimePicker
                          use12Hours
                          format="h:mm a"
                          onChange={OnEndTimeChange}
                          value={endTime}
                          style={{
                            borderRadius: "5px",
                            boxShadow: "2px 2px 2px grey",
                            border: "solid",
                            borderColor: "darkblue",
                            borderWidth: "thin"
                          }}
                        />
                      </Form.Item>
                    </div>
                  </AntpickerIcon>
                </Col>
              </Row>
            </Form.Item>
          </p>
          {eventType === "create" && (
            <p>
              <h4 style={{ marginBottom: "12px" }}>Recurring Status:</h4>
              <Form.Item name="RecurringStatus" required rules={[{ required: true, message: "Enter this feild" }]}>
                <Select
                  // defaultValue="Does not repeat"
                  // value={recurringStatus}
                  style={{
                    borderRadius: "5px",
                    boxShadow: "2px 2px 2px grey ",
                    border: "solid",
                    borderColor: "darkblue",
                    borderWidth: "thin",
                    width: "30%",
                    color: "black"
                  }}
                  onChange={customRecurrenceModalHandler}
                >
                  <Option value="Does not repeat">Does not repeat</Option>
                  <Option value="Daily">Daily</Option>
                  <Option value="Weekly">Weekly</Option>
                  <Option value="Monthly">Monthly</Option>
                </Select>
              </Form.Item>
            </p>
          )}

          <p>
            <br></br>
            <h4 style={{ marginBottom: "12px" }}>Description:</h4>
            <Form.Item name={"Description"} rules={[{ required: true }]}>
              <TextArea
                rows={4}
                cols={4}
                style={{
                  borderRadius: "5px",
                  boxShadow: "2px 2px 2px grey ",
                  border: "solid",
                  borderColor: "darkblue",
                  borderWidth: "thin"
                }}
                placeholder="Add a description"
              />
            </Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Form.Item>
                {eventType === "request" && (
                  <CustomButton
                    htmlType="submit"
                    className="common_button_buyer_tour_details"
                  >
                    SEND REQUEST
                  </CustomButton>
                )}
                {eventType === "update" && (
                  <CustomButton
                    htmlType="submit"
                    className="common_button_buyer_tour_details"
                  >
                    UPDATE AVAILABILITY
                  </CustomButton>
                )}

                {eventType === "create" && (
                  <CustomButton
                    htmlType="submit"
                    className="common_button_buyer_tour_details"
                  >
                    ADD AVAILABILITY
                  </CustomButton>
                )}
              </Form.Item>
            </div>
          </p>
        </Form>
      </p>
      {isShowingCustomRecurrenceModal && (
        <CustomRecurrenceModal
          isShowing={isShowingCustomRecurrenceModal}
          customStartDate={customStartDate}
          setCustomStartDate={setCustomStartDate}
          form={form}
          setCustomEndDate={setCustomEndDate}
          fromCustomStart={()=>{}}
          fromCustomEnd={()=>{}}
          setRecurringStatus={setRecurringStatus}
          setRecurringTitle={()=>{}}
          recurringStatus={recurringStatus}
          setDaysOfWeek={setDaysOfWeek}
          closeAction={() => {
            setIsShowingCustomRecurrenceModal(!isShowingCustomRecurrenceModal);
          }}
        />
      )}
    </Modal>
  );
};

export default CalendarModal;
