import { useState, useContext } from 'react'
import { useGlobal } from 'reactn'
import { useHistory } from 'react-router-dom'
import buttonClose from '../assets/images/button-close.svg'
import Button, { ButtonType } from '../components/Button'
import Checkbox, { CheckboxType } from '../components/Checkbox'
import PropertyFiltersContext from '../contexts/PropertyFiltersContext';
import { Row, Col } from 'antd'
// import {
//   sendForgotPasswordForBuyerService,
//   sendForgotPasswordForAgentService,
// } from '../services/NavihomeService'
import { useMediaQuery } from 'react-responsive'
import { notification } from '../components/notification'
import CustomSpinner from '../components/spinner/CustomSpinner'
import endingtourimg from '../assets/images/Group 1163.png'
import { queries } from '@testing-library/react'
import styled from '@emotion/styled'
const Buttondiv = styled.div`
@media screen and (max-width: 991px){
  display: flex;
  justify-content: center;
}
`
const CustomHeading = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  @media screen and (max-width: 420px){
    font-size: 21px;
  }
`
interface EndingTourModalProps {
  isShowing: boolean
  closeAction?: any
}
const EndingTour: React.FC<EndingTourModalProps> = ({
  isShowing,
  closeAction,
}) => {
  const history: any = useHistory()
  const [buyerGlobalId, setBuyerGlobalId] = useGlobal<any>('buyerId')
  const [DonotShow, setDonotShow] = useState<boolean>(false)

  const isMobile = useMediaQuery({ maxWidth: 500 })

  const { setTourCheckedValue } = useContext(PropertyFiltersContext);


  const handleCancel = () => {
    closeAction(false)
    setDonotShow(false)
  }

  const handlepopup = () => {
    // if (buyerGlobalId) {
    //   localStorage.setItem('buyerGlobalIdForMap', buyerGlobalId)
    // }
    // const buyerId = localStorage.getItem('buyerGlobalIdForMap')

    // history.push('/dashboard?buyerId=' + buyerId)
    if (DonotShow) setTourCheckedValue("Yes");
    else setTourCheckedValue("No")
    closeAction(false)
  }

  const modalClassName = isShowing
    ? 'ending_tour-modal u-visible'
    : 'ending_tour-modal u-hidden'
  const cardClassName = isShowing
    ? 'ending_tour__card animation__pop-in'
    : 'ending_tour__card animation__pop-out'

  return (
    <div className={modalClassName}>
      <div className={cardClassName}>
        <div className="ending_tour__row">
          <CustomHeading>Editing the tour</CustomHeading>
          <img
            className="ending_tour__close-button"
            src={buttonClose}
            onClick={handleCancel}
            alt="Close button"
          />
        </div>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <img
              className="ending_tour_image"
              src={endingtourimg}
              alt="ending_tour_image"
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} style={{ marginTop: "20px" }}>
            <p className="small_text">
              You can delete and add other properties to the tour.
            </p>
            <p className="large_text">
              You can also change the date and time of the tour before routing
              and scheduling it with other agents.
            </p>
            <div className="checkbox_and_button">
              <Checkbox
                type={CheckboxType.SMALL}
                value={DonotShow}
                setValue={setDonotShow}
                text="Do not show this message again"
              />
              <Buttondiv>
                <Button
                  type={ButtonType.PRIMARY}
                  text="OKAY"
                  clickHandler={handlepopup}
                  width={isMobile ? '100%' : 18.5}
                  className="ending_tour-modal__button"
                />
              </Buttondiv>
            </div >
          </Col>
        </Row>

        {/* <div className="login-modal__column">
          <p className="login-modal__error-message">{userState.loginError}</p>
        </div> */}
      </div>
      {/* {isForgotModalShowing && (
          <ForgotPasswordModal
            isShowingForgotModal={isForgotModalShowing}
            closeAction={handleCloseForgotPasswordModal}
          />
        )} */}
      {/* {isShowingForgotPasswordModal && <ForgotPasswordModal isShowing={isShowingLoginModal} closeAction={handleCloseLoginModal} />} */}
    </div>
  )
}
export default EndingTour


