import React from 'react'
import { Col, Row } from 'antd'
import styled from '@emotion/styled'
import OfficeImg from '../assets/images/Group_1183.png'
import HomeImg from '../assets/images/houseIcon.png'
import OtherImg from '../assets/images/Union_1.png'

const CustomRow = styled(Row)`
  width: 90%;
  border-bottom: 4px solid #004876;
  margin-left: 2.5rem;
`
const CustomDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  margin-top: 30px;
  padding: 0 12px;
`
const CustomtimStampDiv = styled.div`
  /* background: #eb752f; */
  background: #c2d500;
  border-radius: 14px;
  padding: 4px 8px;
  position: absolute;
  text-align: center;
  top: 6px;
  left: 25px;
  p {
    color: white !important;
  }
  @media screen and (max-width: 576px) {
    margin-top: 12px;
  }
`
const CustomImg = styled.img`
  height: 136px;
  width: 135px;
  @media screen and (min-width: 1200px){
    width: 100%;
  }
  @media screen and (max-width: 1200px){
    width: 100%;
  }
`

interface TourHomeOfficeCardprops {
  type?: string
  formData?: any
  tab?: string
  source?: string
  statusType?: string
}

const TourHomeOfficeCard: React.FC<TourHomeOfficeCardprops> = ({
  type,
  formData,
  tab,
  source,
  statusType,
}): JSX.Element => {
  return (
    <>
      <CustomRow>
        <Col xs={24} sm={24} md={24} lg={24} xl={10} style={{ background: '#D6D6D6' }}>
          {formData?.dateTime && (
            <CustomtimStampDiv>
              <p>
                {formData.formId ? formData?.time.split('-')[source === 'origin' ?0:1]:formData?.dateTime.split(',')[1].split('-')[source === 'origin' ?0:1] }
              </p>
             
            </CustomtimStampDiv>
          )}
          <CustomImg
            className="tour_detail_property_card"
            src={
              tab === 'office' ? OfficeImg : tab === 'home' ? HomeImg : OtherImg
            }
            alt="Property"
            width="132px"
            height="132px"
          />
        </Col>
        <Col  xs={24} sm={24} md={24} lg={24} xl={12}>
          <CustomDiv>
            {tab === 'office' ? (
              <h4>Office</h4>
            ) : tab === 'home' ? (
              <h4>Home</h4>
            ) : (
              <h4>Other Place</h4>
            )}
            {source === 'origin' ? (
              <p>{formData?.startAdd}</p>
            ) : (
              <p>{formData?.endingAdd}</p>
            )}
            {source === 'origin' ? (
              <p>{formData?.startAddCity}</p>
            ) : (
              <p>{formData?.endingAddCity}</p>
            )}
          </CustomDiv>
        </Col>
      </CustomRow>
    </>
  )
}

export default TourHomeOfficeCard
