import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AboutAgent from "../components/FindAnAgentPage/aboutAgent";
import CustomSpinner from "../components/spinner/CustomSpinner";
import UserContext from "../contexts/UserContext";
import {
  getBuyerInfoService,
  getSellerInfoService,
} from "../services/NavihomeService";

const AgentInfoPage = () => {
  const { userState } = useContext(UserContext);
  const [agent, setAgent] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isContent, setIsContent] = useState<boolean>(false);
  const [agentSubscription_type, setAgentSubscription_type] =
    useState<string>("");

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let param = useQuery();

  const getAgentInfo = async () => {
    setIsLoading(true);
    try {
      const agent: any = await getBuyerInfoService(
        userState?.buyer?.token,
        userState?.buyer?.buyerId
      );
      setAgent(agent);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getSellerAgentInfo = async () => {
    setIsLoading(true);
    try {
      const agentInfo: any = await getSellerInfoService(
        userState?.buyer?.token,
        userState?.buyer?.buyerId
      );
      setAgent(agentInfo);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const type = param.get("type");
    if (type === "sell") {
      setAgentSubscription_type(userState?.buyer?.sellerAgentSubType);
      getSellerAgentInfo();
    } else {
      setAgentSubscription_type(userState?.buyer?.agentSubType);
      getAgentInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let type =
    agent?.agent?.address !== null
      ? `${agent?.agent?.address?.homeStreetAddress} - ${agent?.agent?.address?.homeCityAndZip} , ${agent?.agent?.address?.officeStreetAddress} - ${agent?.agent?.address?.officeCityAndZip}`
      : "---";
  return (
    <>
      {isLoading || isContent ? (
        <CustomSpinner />
      ) : (
        <AboutAgent
          agentName={agent?.agentName || ""}
          imageUrl={agent?.agent?.avatarUrl || ""}
          agentPhone={agent?.agentPhone || ""}
          agentAbout={agent?.agent?.moreinfo?.bio || "---"}
          agentWeb={agent?.agent?.moreinfo?.url || ""}
          agentEmail={agent?.agentEmail || ""}
          agentId={agent?.agentId || agent?.agent?.agentId || null}
          agentLocation={type || ""}
          agentCompany={agent?.agentCompany ?? ""}
          goToSingleAngent={() => {}}
          agentTagline={agent?.agent?.moreinfo?.tagline || ""}
          designationsAndCertifications={agent?.agent?.certificate?.url || ""}
          agentSubscription_type={agentSubscription_type}
        />
      )}
    </>
  );
};

export default AgentInfoPage;
