import styled from '@emotion/styled';
import React, {useContext} from 'react';
import { useHistory } from 'react-router-dom';
import Button, { ButtonType } from '../Button';
import UserContext from '../../contexts/UserContext'

interface NotificationsBuyerCardPropsModel {
  agentId: number;
  buyerId: number;
  isRead: boolean;
  notificationId: number;
  borderColor: string;
  notification: string;
  consumerType: string; 
  propertyId: number;
  time: string | [string, number?];
  isAcceptAndRejectButton: boolean;
  isNotificationButton: boolean;
  borderButtomColor: string;
  type:string;
  didAccept: (notificationId: number, agentId: number, consumerType: string, propertyId: number) => void;
  didReject: (notificationId: number, agentId: number) => void;
}

const CutomNotificationsBuyerContent = styled.div<{
  borderColor: string;
  borderButtomColor: string;
}>`
  border-left: 14px solid ${(p) => p.borderColor};
  border-bottom: 2px solid ${(p) => p.borderButtomColor};
  padding: 22px 2px 22px 36px;
  display: flex;
  margin-top: 12px;
  justify-content: space-between;
  cursor: pointer;
  @media (max-width: 576px) {
    flex-direction: column;
    padding: 18px 12px 12px 12px;
  }
`;

const NotificationsBuyerCard: React.FC<NotificationsBuyerCardPropsModel> = ({
  agentId,
  buyerId,
  isRead,
  notificationId,
  borderColor,
  notification,
  time,
  type,
  consumerType, 
  propertyId,
  isAcceptAndRejectButton,
  isNotificationButton,
  borderButtomColor,
  didAccept,
  didReject,
}) => {
  const history = useHistory();  
  const {userState} = useContext(UserContext)

  let goToProperty = (notification: string) => {
    let isPropertyIdExist = notification
      .split(' ')
      .filter((ntf) => ntf[0] === '#');
    if (isPropertyIdExist.length > 0) {
      var result: any;
      if (isPropertyIdExist[0].charAt(isPropertyIdExist[0].length - 1) == '.') {
        result = isPropertyIdExist[0].slice(1, -1);
      } else {
        result = isPropertyIdExist[0].slice(1);
      }
      if(propertyId && type?.toLocaleLowerCase() === 'comment')
      history.push(`/properties/${propertyId}?id=${buyerId}`);
      else  if(result && type?.toLocaleLowerCase() === 'comment')
      history.push(`/properties/${result}?id=${buyerId}`);
      else
      return
    }
  };
  return (
    <>
      <CutomNotificationsBuyerContent
        borderColor={borderColor}
        borderButtomColor={borderButtomColor}
        onClick={() => {
          goToProperty(notification);
        }}
      >
        <div className="notifications-agent-content-heading">
          {isRead ? (
            <h4>{notification}</h4>
          ) : (
            <h4 style={{ fontWeight: 'bold', color: 'black' }}>
              {notification}
            </h4>
          )}{' '}
          <p>{time}</p>
        </div>
        {isAcceptAndRejectButton && (
          <div className="notifications-agent-content-button-main">
            <Button
              type={ButtonType.WHITE}
              text={'REJECT'}
              clickHandler={() => {
                didReject(notificationId, agentId);
              }}
              className="notifications-agent-content-button notifications-agent-content-button-reject"
            />
            <Button
              type={ButtonType.PRIMARY}
              text={'ACCEPT'}
              clickHandler={() => {
                didAccept(notificationId, agentId, consumerType, propertyId);
              }}
              className="notifications-agent-content-button notifications-agent-content-button-accept"
            />
          </div>
        )}
        {isNotificationButton && (
          <Button
            type={ButtonType.PRIMARY}
            text={'NOTIFICATIONS'}
            clickHandler={() => {}}
            className="notifications-agent-content-button"
          />
        )}
      </CutomNotificationsBuyerContent>
    </>
  );
};

export default NotificationsBuyerCard;
