import React, { useState } from "react";
import { Modal, Avatar, Anchor, Input, Row, Col } from "antd";
import {
  AntDesignOutlined,
  PhoneOutlined,
  MailTwoTone,
  UserOutlined,
} from "@ant-design/icons";
import Reg from "../images/Screenshot from 2022-05-04 16-47-22.png";
import Button, { ButtonType } from "../../components/Button";
import { useMediaQuery } from "react-responsive";
import InviteAgentModal from "../InviteAgentModal";
import iconPhone from '../../assets/images/icon-phone.svg';
import iconEmail from '../../assets/images/icon-email.svg';
import { postMessageToUser } from "../../services/NavihomeService";
import { truncate } from "fs";
import styled from "@emotion/styled";
const { Link } = Anchor;
const { TextArea } = Input;
// const handleClick = (
//     e: React.MouseEvent<HTMLElement>,
//     link: {
//       title: React.ReactNode;
//       href: string;
//     },
//   ) => {
//     e.preventDefault();
//   };

const CustomCol = styled(Col)`
  display: flex;
  // justify-content: center; 
  align-items: center;
`;
const CustomRow = styled(Row)`
  display: flex;
  justify-content: center;
`;
const CustomRow2 = styled(Row)`
  display: flex;
  @media screen and (max-width: 315px){
    flex-direction: column;
  }
`;
const Customheading = styled.h1``;
interface NotListedAgentModalProps {
  item?: any;
  isShowing: boolean;
  closeAction: () => void;
  agentPhone?: string;
  userName?:string
}
const NotListedAgentModal: React.FC<NotListedAgentModalProps> = ({
  item,
  isShowing,
  closeAction,
  agentPhone,
  userName,
}) => {
  const [visible, setVisible] = useState(false);
  const [isShowingInviteAgentModal, setIsShowingInviteAgentModal] =
    useState(false);
  const [message, setMessage] = useState<string>("");
  const handleOk = () => {
    closeAction();
  };
  const isMobile = useMediaQuery({ maxWidth: 500 });
  const handleMessage = () => {
    // here we have only one mode to send message on number using plivo
    // postMessageToUser(agentPhone, message)
    closeAction();
  };

  const handleInviteAgent = () => {
    setIsShowingInviteAgentModal(true);
    // closeAction()
  };
  return (
    <Modal
      centered
      visible={isShowing}
      onOk={handleOk}
      onCancel={handleOk}
      width={450}
      // height={500}
      footer={false}
    >
      <Row>
        <Customheading>
          {userName}
        </Customheading>
      </Row>
      <br />
      <Row>
        <h5 style={{ color: "black" }}>
          {userName} is the seller agent
          for the property on
        </h5>
        <br />
        <br />
        <h5 style={{ color: "black" }}>
          {item?.address?.streetNumber} {item?.address?.streetName}{" "}
          {item?.address?.city}, {item?.address?.state}{" "}
          {item?.address?.postalCode} is not on NaviHome
        </h5>
      </Row>
      <br />
      <Row>
        <Col span={7}>
          <Avatar
            size={{
              xs: 64,
              sm: 64,
              md: 64,
              lg: 64,
              xl: 80,
              xxl: 100,
            }}
            icon={<UserOutlined/>}
          />
        </Col>
        <CustomCol span={17}>
          <div>
            <div style={{ display: "inline-block" }}>
              {/* <PhoneOutlined /> */}
              <img src={iconPhone} alt="iconPhone" />
            </div>
            <div style={{ display: "inline-block" }}>
              <h5 style={{ marginLeft: "8px" }}>{'+1'}{agentPhone} </h5>
            </div>
          </div>
          <br />
          {/* <Button
            type={ButtonType.PRIMARY}
            text="Invite Agent"
            clickHandler={handleInviteAgent}
            className="route_tour-modal__button"
            width={isMobile ? '100%' : 18.5}
          /> */}
        </CustomCol>
      </Row>
      <br />
      <br />
      <h5 style={{ float: "left", color: "black" }}>
        Message to listing agent (Via Text):
      </h5>
      <br />
      <TextArea
        rows={4}
        value={message}
        onChange={(e) => {
          setMessage(e?.target?.value);
        }}
        bordered={true}
        style={{ borderColor: "blue", boxShadow: "2px 2px 2px grey" }}
        placeholder="Hello , my clients would like to view the property on 3430,38 ave W, seattle, WA 98199, on March 21,2021 from 10:50 am - 11:10 am, Awaiting your confirmation"
      />
      <br />
      <br />
      <CustomRow>
        <Button
          type={ButtonType.PRIMARY}
          text="SEND MESSAGE"
          clickHandler={handleMessage}
          className="route_tour-modal__button"
          width={isMobile ? "100%" : "18.5rem"}
        />
      </CustomRow>
      {isShowingInviteAgentModal && (
        <InviteAgentModal
          isShowing={isShowingInviteAgentModal}
          closeAction={() => {
            setIsShowingInviteAgentModal(false);
          }}
        />
      )}
    </Modal>
  );
};

export default NotListedAgentModal;
