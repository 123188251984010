import { useContext, useEffect, useRef, useState } from 'react';
import triangle from '../assets/images/icon-triangle.svg';
import PropertyFiltersContext from '../contexts/PropertyFiltersContext';
import TextField, { TextFieldIconType, TextFieldType } from './TextField';
import { notification } from '../components/notification';

interface PriceSelectFieldProps {
  minimumPrice: number;
  setMinPrice: (value: number) => void;
  maximumPrice: number;
  setMaxPrice: (value: number) => void;
  handleChange: () => void;
  searchFieldHandler?: () => void;
  openHouse?: string;
}

const PriceSelectField: React.FC<PriceSelectFieldProps> = ({
  minimumPrice,
  setMinPrice,
  maximumPrice,
  setMaxPrice,
  handleChange,
  openHouse,
  searchFieldHandler,
}) => {
  const [label, setLabel] = useState('');
  const [label2, setLabel2] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [didChange, setDidChange] = useState(false);
  const [minPrice1, setMinPrice1] = useState<any>();
  const [maxPrice1, setMaxPrice1] = useState<any>();
  const ref = useRef(null);

  const { propertyFiltersState, setPropertyType } = useContext(
    PropertyFiltersContext
  );
  const { minPrice, maxPrice } = propertyFiltersState;

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsExpanded(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (minPrice === 0) {
      setLabel('Price');
    } else {
      let min = minPrice.toString();
      let mn = numFormatter(min);
      setLabel(`$${mn}`);
    }
  }, [minPrice]);

  useEffect(() => {
    if (maxPrice === 0) {
      setLabel2('');
    } else {
      let max = maxPrice.toString();
      let mx = numFormatter(max);
      setLabel2(`$${mx}`);
    }
  }, [maxPrice]);

  useEffect(() => {
    if (!isExpanded) {
      handleChange();
    }
  }, [isExpanded]);

  // useEffect(() => {
  //   if (minimumPrice === 0 && maximumPrice === 0) {
  //     setLabel('');
  //     setLabel2('');
  //   }
  // }, [minimumPrice, maximumPrice]);

  // const expand = () => {
  //   setIsExpanded(true);
  // };
  const numFormatter = (num: any) => {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(0) + 'K';
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(0) + 'M';
    } else if (num < 1000) {
      return num;
    }
  };

  const handleSetMinPrice = (value: string) => {
    const rawValue = value.replace(/[^0-9]+/g, '');
    let numberValue = parseInt(rawValue);
    if (isNaN(numberValue)) {
      setMinPrice(0);
      return;
    }

    if (numberValue > 9999999999) {
      numberValue = Math.trunc(numberValue / 10);
    }
    setMinPrice(Math.min(numberValue, 9999999999));

    setDidChange(true);
    let min = numberValue.toString();
    let mn = numFormatter(min);
    setMinPrice1(mn);
    if (value?.length === 3) setLabel('');
    else setLabel(`$${mn}`);
  };

  const handleSetMaxPrice = (value: string) => {
    const rawValue = value.replace(/[^0-9]+/g, '');
    let numberValue = parseInt(rawValue);
    if (isNaN(numberValue)) {
      setMaxPrice(0);
      return;
    }
    if (numberValue > 9999999999) {
      numberValue = Math.trunc(numberValue / 10);
    }
    setMaxPrice(Math.min(numberValue, 9999999999));
    setDidChange(true);
    let max = numberValue.toString();
    let mx = numFormatter(max);
    setMaxPrice1(mx);
    if (value?.length === 3) setLabel2('');
    else setLabel2(`$${mx}`);
  };
  const expand2 = () => {
    setIsExpanded(!isExpanded);
  };

  // useEffect(()=>{
  //   if (minPrice1 > maxPrice1) {
  //     setMaxPrice(0);
  //     return notification("Maximum price should be greater or equal to minimum price!", "error");
  //   }
  // },[])

  const height = isExpanded ? '15.2rem' : '4.8rem';
  const valueColor = didChange ? 'var(--color-black' : 'var(--color-grey-2';
  const zIndex = isExpanded ? 1000 : 10;

  return (
    <div className="price-select-field__container" onClick={searchFieldHandler}>
      <div
        ref={ref}
        className="price-select-field"
        style={{ height: height, zIndex: zIndex }}
      >
        <div className="price-select-field__label" onClick={expand2}>
          {label || label2 ? (
            <p style={{ color: valueColor }}>
              {label ? label : '$0'}
              {label2 ? ' - ' : ''}
              {label2}
            </p>
          ) : (
            <p style={{ color: valueColor }}>{'Price'}</p>
          )}
          <div>
            <img
              className={
                isExpanded
                  ? 'select-field__arrow select-field__arrow--rotated '
                  : 'select-field__arrow '
              }
              src={triangle}
              alt="Triangle"
              style={{ top: '2rem' }}
            />
          </div>
        </div>
        <div
          className="price-select-field__content"
          style={{ overflow: 'hidden' }}
        >
          <TextField
            value={minimumPrice === 0 ? '' : `$ ${minimumPrice}`}
            setValue={handleSetMinPrice}
            placeholder="Min price"
            type={TextFieldType.SMALL}
            icon={TextFieldIconType.NONE}
            isRequired={false}
            width={11}
            className="properties-page-price_field"
          />
          {/* {openHouse === 'open_house' ? (
            <></>
          ) : (
            <> */}
              <p>to</p>
              <TextField
                value={maximumPrice === 0 ? '' : `$ ${maximumPrice}`}
                setValue={handleSetMaxPrice}
                placeholder="Max price"
                type={TextFieldType.SMALL}
                icon={TextFieldIconType.NONE}
                isRequired={false}
                width={11}
              />
            {/* </>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default PriceSelectField;
