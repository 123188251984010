// import { useContext } from 'react';
import { useHistory } from "react-router-dom";
import triangleArrow from "../assets/images/triangle-arrow.svg";
import agentFeature1 from "../assets/images/landing-agent-feature-1.svg";
import agentFeature2 from "../assets/images/landing-agent-feature-2.svg";
import agentFeature3 from "../assets/images/landing-agent-feature-3.svg";
import agentFeature4 from "../assets/images/landing-agent-feature-4.svg";
import agentFeature5 from "../assets/images/landing-agent-feature-5.svg";
import agentFeature6 from "../assets/images/landing-agent-feature-6.svg";
import AgentTestimonial from "../components/AgentTestimonial";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Button, { ButtonType } from "../components/Button";
import styled from "@emotion/styled";
import { useMediaQuery } from "react-responsive";

const CustomHeadTitle = styled.h4`
  font-size: 18px;
  font-weight: 600;
  @media screen and (max-width: 1202px) {
    font-size: 14px;
  }
`;
const CustomHeading = styled.h2`
  font-size: 54px;
  font-weight: 600;
  padding: 6px 0 18px 0;
  @media screen and (max-width: 1202px) {
    font-size: 42px;
  }
  @media screen and (max-width: 992px) {
    font-size: 32px !important;
  }
  @media screen and (max-width: 768px) {
    font-size: 30px !important;
  }
`;
const CustomSmallHeading = styled.h2`
  font-size: 39px;
  font-weight: 600;
  @media screen and (max-width: 1202px) {
    font-size: 26px;
  }
  @media screen and (max-width: 992px) {
    font-size: 21px !important;
  }
`;
const CustomYouGetHeading = styled.h3`
  font-size: 32px;
  font-weight: 600;
  margin-top: 10rem;
  margin-left: 2px;
  margin-bottom: 0;
  @media screen and (max-width: 992px) {
    font-size: 26px;
    margin-top: 8rem;
  }
  @media screen and (max-width: 1370px) {
    margin-left: .7rem;
  }
`;
const CustomYouGetTitle1 = styled.h5`
  font-size: 14px;
  font-weight: 600;
  @media screen and (max-width: 1202px) {
    font-size: 14px;
  }
  @media screen and (min-width: 1600px) {
    margin-left: 8.9rem;
  }
`;
const CustomYouGetTitle = styled.h5`
  font-size: 14px;
  font-weight: 600;
  @media screen and (max-width: 1202px) {
    font-size: 14px;
  }
`;
const CustomPara = styled.p`
  font-weight: 600;
  font-size: 18px;
  color: #365073;
`;
const CustomHighLightPara = styled.p`
  font-size: 2rem;
  margin-top: 15px;
`;
const Headingdown = styled.h1`
  font-size: x-large;
  margin-bottom: 12px;
  font-weight: 600;
  @media screen and (max-width: 331px) {
    &:after {
      display: none;
    }
  }
`;
const Underline = styled.ul`
  @media screen and (max-width: 1081px) {
    padding-left: 0%;
  }
`;
const Lineheading = styled.li`
  @media screen and (max-width: 1043px) {
    padding-left: 1rem;
  }
`;
// import UserContext from '../contexts/UserContext';

const LandingAgentPage: React.FC = () => {
  const history = useHistory();
  // const { userState } = useContext(UserContext);
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const isResponsive = useMediaQuery({ maxWidth: 1600 });

  const goToRequestDemo = () => {
    history.push("/request-demo");
  };
  const goToRegister = () => {
    history.push("/register");
  };

  return (
    <div className="landing-agent-page">
      <div className="landing-agent-page__hero-section">
        <div className="landing-agent-page__hero-section__content">
          <CustomHeadTitle>
            Navihome: When your superpower is selling, not scheduling.
          </CustomHeadTitle>
          <CustomHeading>Schedule Showings.</CustomHeading>
          <CustomSmallHeading>
            Easily. Effortlessly. Immediately
          </CustomSmallHeading>
          <p style={{ lineHeight: "2.1" }}>
            Eliminate the back-and-forth scheduling saga of calls, texts, and
            emails. Create quick and seamless experiences for home showings with
            just a few clicks and keystrokes!
            <br />
            Simplify scheduling and routing and free up your time.
          </p>
          <p>
            <span className="f-subtitle-1">
              Navihome: The app designed with the agent in mind.
            </span>
          </p>
          <Button
            type={ButtonType.PRIMARY}
            text={"HOW IT WORKS"}
            clickHandler={goToRequestDemo}
            className="landing-agent-page__how-it-works-button"
          />
          <p>It is like having a scheduling assistant 24/7.</p>
        </div>
        <img
          style={{ marginTop: "5px" }}
          className="landing-agent-page__hero-section__image"
          src={agentFeature1}
          alt="Agent Feature 1"
        />
      </div>
      <div className="landing-agent-page__steps-section">
        <div className="landing-agent-page__steps-section__item">
          <p className="landing-agent-page__steps-section__number steps-section_number">
            1
          </p>
          <img
            className="landing-agent-page__steps-section__arrow"
            src={triangleArrow}
            alt="Arrow"
          />
          <div className="landing-agent-page__steps-section__item__content">
            <CustomPara>Provide your availability</CustomPara>
            <p>Choose your scheduling parameters.</p>
            <p>Offer your buyers and listing agents easy scheduling.</p>
          </div>
        </div>
        <div className="landing-agent-page__steps-section__item">
          <p className="landing-agent-page__steps-section__number steps-section_number">
            2
          </p>
          <img
            className="landing-agent-page__steps-section__arrow"
            src={triangleArrow}
            alt="Arrow"
          />
          <div className="landing-agent-page__steps-section__item__content">
            <CustomPara>Request showings and optimize your route</CustomPara>
            <p>Navihome provides the optimal routing solution.</p>
          </div>
        </div>
        <div className="landing-agent-page__steps-section__item">
          <p className="landing-agent-page__steps-section__number steps-section_number">
            3
          </p>
          <img
            className="landing-agent-page__steps-section__arrow"
            src={triangleArrow}
            alt="Arrow"
          />
          <div className="landing-agent-page__steps-section__item__content">
            <CustomPara>Book with one click</CustomPara>
            <p>
              Click one button to request showings for all properties in the
              route.
            </p>
          </div>
        </div>
      </div>
      <div className="landing-agent-page__highlight-text-block">
        <p>Never lose a client again</p>
        <CustomHighLightPara>
          Leverage the power of our unparalleled technology. Avoid scheduling
          headaches. Create professional, enjoyable experiences for your buyers.
        </CustomHighLightPara>
      </div>
      <div className="landing-agent-page__you-get_heading">
        <CustomYouGetHeading>You get ...</CustomYouGetHeading>
      </div>

      <div className="landing-agent-page__you-get">
        <div className="landing-agent-page__you-get__item">
          <img
            src={agentFeature2}
            alt="Feature"
            className=""
            style={{ paddingLeft: isResponsive ? "" : "10rem" }}
          />
          <CustomYouGetTitle1>
            ... Calendar sync and viewing schedule of all stakeholders
          </CustomYouGetTitle1>
          <p  style={{ paddingLeft: isResponsive ? "" : "8rem" }}>
            • &nbsp;Display optimal timeframes for property view appointments
            for both listing agent accompanied &amp; unaccompanied showings.
          </p>
        </div>
        <div className="landing-agent-page__you-get__item">
          <img
            src={agentFeature3}
            alt="Feature"
            className=""
            style={{ paddingLeft: isResponsive ? "" : "6rem" }}
          />
          <CustomYouGetTitle>
            ... Engage your GPS to optimise your routing
          </CustomYouGetTitle>
          <p  style={{ paddingLeft: isResponsive ? "" : "58px" }}>
            • &nbsp;Location-awareness to book optimal routes when one or
            multiple properties are involved.
          </p>
        </div>
        <div className="landing-agent-page__you-get__item">
          <img
            src={agentFeature4}
            alt="Feature"
            className=""
            style={{ paddingLeft: isResponsive ? "" : "5rem" }}
          />
          <CustomYouGetTitle>... Page branding (optional)</CustomYouGetTitle>
          <p  style={{ paddingLeft: isResponsive ? "" : "51px" }}>
            • &nbsp;Add your name, logo, profile photo and contact details to
            create a personalized experience.
          </p>
        </div>
      </div>
      
      <div className="lading-agent-page__offer">
        <div className="lading-agent-page__offer__container">
          <p>30-DAY FREE TRIAL!</p>
          <div>
            <Button
              type={ButtonType.SIGN_ME_UP}
              text={"SIGN ME UP"}
              clickHandler={goToRegister}
            />
          </div>
        </div>
      </div>
      <div className="landing-agent-page__features">
        <div className="landing-agent-page__features__item__text">
          <CustomPara>Increase Appointment Reliablity</CustomPara>
          <p>
            Keep your buyers on track with automated reminders, confirmations,
            links to Google calendar, and simple rescheduling.
          </p>
        </div>
        <img src={agentFeature5} alt="Feature" />
        {!isMobile && <img src={agentFeature6} alt="Feature" />}
        <div className="landing-agent-page__features__item__text">
          <CustomPara>Invite Buyers to Browse</CustomPara>
          <p>
            Deepen your understanding of buyer preferences. See what they like
            in the portal, view which open houses they attend.
          </p>
        </div>
        {isMobile && <img src={agentFeature6} alt="Feature" />}
      </div>
      <div className="landing-agent-page__created-by">
        <p>Created by real estate agents for real estate agents</p>
      </div>
      <div className="landing-agent-page__ending-features">
        <div className="landing-agent-page__ending-features__text">
          <Headingdown>
            We have got y
            <span style={{ borderBottom: "3px solid #C2D500" }}>our back!</span>
          </Headingdown>

          <CustomPara style={{ fontSize: "22px" }}>
            Show more, Schedule less
          </CustomPara>
          <p>
            Navihome is the brainchild of successful real estate agents who
            understand scheduling challenges. It is time to put technology to
            work for us:
          </p>
          <Underline>
            <Lineheading>Minimize scheduling time</Lineheading>
            <Lineheading>Increase showings</Lineheading>
            <Lineheading>
              Offer professional scheduling experiences and reduce stress for
              buyers
            </Lineheading>
            <Lineheading>Reduce missed appointments</Lineheading>
            <Lineheading>
              Regain more time to focus on what is important: your clients
            </Lineheading>
          </Underline>
        </div>
        <div className="landing-agent-page__ending-feature__sign-up">
          <Button
            type={ButtonType.SIGN_ME_UP2}
            text={"SIGN ME UP"}
            clickHandler={goToRegister}
          />
        </div>
      </div>
      <div className="landing-agent-page__testimonials__container">
        <Headingdown>
          Trusted by Real Estate
          <span style={{ borderBottom: "3px solid #C2D500" }}> Agents</span>
        </Headingdown>
        <div className="landing-agent-page__testimonials">
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            showThumbs={false}
            showIndicators={true}
            showArrows={false}
            showStatus={false}
          >
            <div style={{ padding: "26px 0" }}>
              <AgentTestimonial
                name={"John Doe"}
                avatar={"avatar-john-doe.jpg"}
                text={
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                }
                agentInfo={"Realtor, Partner at Doe Real Estate Agency"}
              />
            </div>
            <div style={{ padding: "2px 0" }}>
              <AgentTestimonial
                name={"Regina George"}
                avatar={"avatar-regina-george.jpg"}
                text={
                  "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis."
                }
                agentInfo={"Realtor, George International Realty"}
              />
            </div>
            <div style={{ padding: "26px 0" }}>
              <AgentTestimonial
                name={"Diana Smith"}
                avatar={"avatar-diana-smith.jpg"}
                text={
                  "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                }
                agentInfo={"CEO, Sensing Local"}
              />
            </div>
            <div style={{ padding: "26px 0" }}>
              <AgentTestimonial
                name={"Nicholas Santiago"}
                avatar={"avatar-nicholas-santiago.jpg"}
                text={
                  "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                }
                agentInfo={"Vice President, Vici Properties"}
              />
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default LandingAgentPage;
