import { useEffect, useState } from 'react'
import type { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import { Table } from 'antd'

interface ITourSummarySeller {
  date: string
  dayOfWeek: string
  time: string
  timeOfDay: string
  statusPrimary: string
}

interface ToursSummarySellerProps {
  tours: ITourSummarySeller[]
  isAgentSide?: boolean
  isBuyerLinked?: boolean
  upcomingTourDate?: any
  upcomingStartEndDate?: any
}

interface DataType {
  Key: React.Key
  date: any
  time: any
  status: any
}

const ToursSummarySeller: React.FC<ToursSummarySellerProps> = ({
  tours,
  upcomingStartEndDate,
}) => {

  const columns: ColumnsType<DataType> = [
    {
      title: 'Date',
      dataIndex: 'date',
      render: (_, { date }) => (
        <div>
          <div>{date?.date}</div>
          <div>{date?.dayOfWeek}</div>
        </div>
      ),
    },
    {
      title: 'Time',
      dataIndex: 'time',
      render: (_, { time }) => (
        <div>
          <div>{time?.time}</div>
          <div>{time?.timeOfDay}</div>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, { status }) => (
        <div>
          <div>{status?.statusPrimary}</div>
          {/* <div>{status?.statusSecondary}</div> */}
        </div>
      ),
    },
  ]

  const [data, setData]: any = useState([]);
  const [defaultData, setDefaultData]: any = useState([]);

  useEffect(() => {
    if (upcomingStartEndDate?.startDate !== null) {
      setDefaultData([])
      if (tours?.length > 0) {
        let finalArr: any = []
        tours.map((item, i) => {
          let bool2 = moment(item?.date).isBetween(upcomingStartEndDate?.startDate, upcomingStartEndDate?.endDate) || moment(item?.date).isSame(upcomingStartEndDate?.startDate) || moment(item?.date).isSame(upcomingStartEndDate?.endDate);
          if (bool2) {
            finalArr.push({
              key: i,
              date: { date: item?.date, dayOfWeek: item?.dayOfWeek },
              time: { time: item?.time, timeOfDay: item?.timeOfDay },
              status: {
                statusPrimary: item?.statusPrimary,
              },
            })
          }
          return finalArr
        })

        setData(finalArr)
      }
    }
  }, [tours, upcomingStartEndDate])

  useEffect(() => {
    if (tours?.length > 0) {
      let finalArr: any = []
      // we have to render future or current data  
      let currDate = moment().format('LL').toString();
      tours.forEach((item, i) => {
        let bool2 = moment(item?.date).isSameOrAfter(currDate);
        if (bool2) {
          finalArr.push({
            key: i,
            date: { date: item?.date, dayOfWeek: item?.dayOfWeek },
            time: { time: item?.time, timeOfDay: item?.timeOfDay },
            status: {
              statusPrimary: item?.statusPrimary,
            },
          })
        }
      })

      setDefaultData(finalArr)
    }
  }, [tours])

  return (
    <div style={{ width: '100%', padding: '0px 10px' }}>
      <Table
        style={{ width: '100% !important', cursor: 'pointer' }}
        // rowSelection={rowSelection}
        columns={columns}
        dataSource={data?.length > 0 ? data : defaultData}
        pagination={{ defaultCurrent: 1, pageSize: 5 }}
      />
    </div>
  )
}

export default ToursSummarySeller
