import { useContext, useEffect, useRef, useState } from 'react';
import SelectFieldOption from './SelectFieldOption';
import triangle from '../assets/images/icon-triangle.svg';
import PropertyFiltersContext from '../contexts/PropertyFiltersContext';

export enum SelectFieldType {
  DEFAULT = 'SELECT_FIELD_DEFAULT',
  TALL = 'SELECT_FIELD_SMALL',
}

interface SelectFieldProps {
  value: string | number;
  placeholder: string;
  setValue: (value: string) => void;
  options: string[];
  optionWidth: number;
  optionsHeight: number;
  type: SelectFieldType;
  className?: string;
  handleChange?: (value: any) => void;
  sortProperties?: (value: string) => void;
}

const SelectField: React.FC<SelectFieldProps> = ({
  value,
  setValue,
  placeholder,
  options,
  optionWidth,
  optionsHeight,
  type,
  handleChange,
  className,
  sortProperties,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [didChange, setDidChange] = useState(false);
  const ref = useRef(null);

  const { setSortType } = useContext(PropertyFiltersContext);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsExpanded(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (!isExpanded) {
      handleChange(value);
    }
  }, [isExpanded]);

  const expand = () => {
    setIsExpanded(!isExpanded);
  };

  const didSelectValue = (value: string) => {
    setDidChange(true);
    // setIsExpanded(false);
    if (value === 'Cost: L - H') {
      setSortType('listprice');
      sortProperties('listprice');
    } else if (value === 'Cost: H - L') {
      setSortType('-listprice');
      sortProperties('-listprice');
    }else if (value === 'New Listing') {
      setSortType('-listdate');
      sortProperties('-listdate');
    } else if(value === 'Most Viewed'){
      // setSortType('listdateLost');
      // sortProperties('listdateLost');
      setSortType('');
      sortProperties('');
    }else if(value ==='Popularity'){
      setSortType('');
      sortProperties('');
    }else {
      setSortType('');
      sortProperties(value)};
    setValue(value);

    // setValue(value);
    // if (sortProperties) {
    //   sortProperties(value);
    // }
  };

  const defaultHight = type === SelectFieldType.TALL ? 4.8 : 3.2;
  const height = isExpanded ? optionsHeight + 5 : defaultHight;
  const valueColor = didChange ? 'var(--color-black' : 'var(--color-grey-2';
  const zIndex = isExpanded ? 1000 : 10;
  const labelTop = type === SelectFieldType.TALL ? '.8rem' : '0rem';
  const paddingLeft = type === SelectFieldType.TALL ? '1.5rem' : '0.7rem';

  return (
    <div
      className="select-field__container"
      style={{
        width: `${optionWidth}rem`,
        height: `${defaultHight}rem`,
        zIndex: zIndex,
      }}
    >
      <div
        ref={ref}
        className={
          className === undefined ? `select-field` : `select-field ${className}`
        }
        onClick={expand}
        style={{
          width: `${optionWidth}rem`,
          height: `${height}rem`,
          zIndex: zIndex,
        }}
      >
        <div
          className="select-field__current-option"
          style={{ top: labelTop, paddingLeft: paddingLeft }}
        >
          {/* <p style={{ color: valueColor }}>{value}</p> */}
          <p style={{ color: "black" }}>{value}</p>
          <img
            className={
              isExpanded
                ? 'select-field__arrow select-field__arrow--rotated'
                : 'select-field__arrow '
            }
            src={triangle}
            alt="Triangle"
          />
        </div>
        <div
          className="select-field__options"
          style={{ height: `${optionsHeight}rem`, paddingLeft: paddingLeft }}
        >
          <ul>
            {options.map((option) => {
              return (
                <SelectFieldOption
                  value={option}
                  didSelectValue={didSelectValue}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SelectField;
