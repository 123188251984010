import { message, Popconfirm } from "antd";
import React, { useContext, useState } from "react";
import {
  deleteEventBuyerService,
  updateEventByBuyer,
} from "../../../services/NavihomeService";
import UserContext, { UserType } from "../../../contexts/UserContext";
import Edit from "../../../assets/CalendarPopover/Button - Edit.svg";
import Delete from "../../../assets/CalendarPopover/Button - Delete.svg";

import "./styles/calendarPopover.css";
import moment from "moment";
import CalendarModal from "../../../modals/CalendarModal";

interface ICalendarSchedulePopoverProps {
  args: any;
  calenderEvent?: () => void;
  isBuyerSide: boolean;
  sellerName?: string;
  popoverState?: (value: boolean) => void;
}

const ConsumerCalendarEventPopover: React.FunctionComponent<
  ICalendarSchedulePopoverProps
> = ({ args, calenderEvent, isBuyerSide, sellerName, popoverState }) => {

  const { userState } = useContext(UserContext);
  const [isShowingCalendarModal, setIsShowingCalendarModal] =
    useState<boolean>(false);
  const [Stime, setStime] = useState<any>(
    args?.event?._def?.extendedProps?.Stime
  );
  const [Sdate, setSdate] = useState<Date>(
    args?.event?._def?.extendedProps?.Sdate
  );
  const [Etime, setEtime] = useState<any>(
    args?.event?._def?.extendedProps?.Etime
  );
  const [Edate, setEdate] = useState<Date>(
    args?.event?._def?.extendedProps?.Edate
  );
  const [rStatus, setRStatus] = useState<string>(
    args?.event?._def?.extendedProps?.rStatus
  );
  const [rEdate, setREdate] = useState<Date>(
    args?.event?._def?.extendedProps?.rEdate
  );

  const confirm = async (eventId: number) => {
    try {

      const result = await deleteEventBuyerService(
        userState?.buyer?.token,
        eventId
      );
      calenderEvent();
      message.success("Event Deleted!");
    } catch (error) {
      console.log("ERROR:", error);
    }
  };

  const calanderModalHandler = () => {
    setIsShowingCalendarModal(!isShowingCalendarModal);
  };

  const getModalData = async (
    values: any,
    customStartDate?: any,
    customEndDate?: any
  ) => {

    const SDate: any = moment(values?.StartDate).format("MM/DD/YYYY");
    const STime: any = moment(values?.StartTime).format("HH:mm:ss A");
    const Edate: any = moment(values?.EndDate).format("MM/DD/YYYY");
    const Etime: any = moment(values?.EndTime).format("HH:mm:ss A");
    const Description: string = values?.Description;
    const Rstatus: string = values?.RecurringStatus;
    const recStartDate: any = moment(customStartDate).format("MM/DD/YYYY");
    const recEndDate: any = moment(customEndDate).format("MM/DD/YYYY");
    try {
      if (userState?.userType === UserType?.BUYER) {
        const token = userState.buyer.token;
        const response = await updateEventByBuyer(
          args?.event?._def?.extendedProps?.eventId,
          SDate,
          STime,
          Edate,
          Etime,
          Description,
          Rstatus,
          token,
          recStartDate,
          recEndDate
        );
        if (response) {
          calenderEvent();
        }
      }
    } catch (error) {
      console?.log("error", error);
    }
  };

  const cancel = (e: React.MouseEvent<HTMLElement>) => {
    message.error("Click on No");
  };
  return (
    <div className="noBase" style={{ padding: "0" }}>
      <div className="content">
        {!isBuyerSide && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {/* <img src={Close} alt="close" style={{ cursor: 'pointer' }} /> */}
            <img
              src={Edit}
              alt="edit"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsShowingCalendarModal(true);
                popoverState(false);
              }}
            />
            <Popconfirm
              title="Are you sure to delete this event?"
              onConfirm={() => {
                confirm(args?.event?._def?.extendedProps?.eventId);
              }}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <img
                src={Delete}
                alt="delete"
                style={{ margin: "0 18px", cursor: "pointer" }}
              />
            </Popconfirm>
          </div>
        )}

        <p className="content-heading">
          {isBuyerSide && <>{sellerName} | </>}
          {args?.event?.title}
        </p>
        <span className="date">
          {moment(args?.event?.start).format("Do MMMM")}{" "}
          &bull; {moment(args?.event?.start).format("h:mm A")}-
          {moment(args?.event?.end).format("hh:mm A")}
        </span>
        <div className="every-week">
          {args?.event?._def?.extendedProps?.rStatus}
        </div>
      </div>
      {isShowingCalendarModal && (
        <CalendarModal
          isShowing={isShowingCalendarModal}
          Sdate={Sdate}
          Stime={Stime}
          Edate={Edate}
          Etime={Etime}
          title={args?.event?.title}
          rStatus={rStatus}
          rEdate={rEdate}
          closeAction={calanderModalHandler}
          getModalData={getModalData}
          eventType="update"
          setIsRescheduleRequestEvent={() => { }}
        />
      )}
    </div>
  );
};

export default ConsumerCalendarEventPopover;
