/* eslint-disable jsx-a11y/img-redundant-alt */
import 'antd/dist/antd.css';
import React, { useContext, useState } from 'react';
import UserContext, { UserType } from '../../contexts/UserContext';
import { Modal, Row, Col } from 'antd';
import cancelTour from './images/cancelTour.png';
import { useMediaQuery } from 'react-responsive';
import Button, { ButtonType } from '../../components/Button';
import TextField, {
  TextFieldIconType,
  TextFieldType,
} from '../../components/TextField';
import {
  createBuyerNotificationById,
  deleteScheduledToursService,
  inviteAgentByBuyer,
  postMessageToUser,
} from '../../services/NavihomeService';
import CustomSpinner from '../../components/spinner/CustomSpinner';
import moment from 'moment';
import PropertyFiltersContext from '../../contexts/PropertyFiltersContext';
import { notification } from '../../components/notification';

interface CancelModalProps {
  isShowing: boolean;
  setIsShowing: (value: boolean) => void;
  formId: number;
  token: string;
  listingAgentName: string;
  buyerAgentEmail: string;
  handleDiscardAndExit: () => void;
  type?: string;
  linkedPropertiesData?: Array<any>;
  buyerId?: string | number;
  dateTime?: any;
  formTime?: any;
  formData?: any;
  buyerDetails?: any;
}
const Cancel: React.FC<CancelModalProps> = ({
  isShowing,
  setIsShowing,
  formId,
  token,
  listingAgentName,
  buyerAgentEmail,
  handleDiscardAndExit,
  type,
  formData,
  dateTime,
  formTime,
  linkedPropertiesData,
  buyerId,
  buyerDetails,
}) => {
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);

  const [message, setMessage] = useState<string>(
    'Hey, Sorry for cancelling the showing, Sorry for the inconvenience will reschedule shortly'
  );
  const { userState } = useContext(UserContext);
  const {
    setMessageSocketCaller,
    setNotificationsCount,
  } = useContext(PropertyFiltersContext);

  const handleOk = async () => {
    setIsModalLoading(true);

    try {
      const res: any = await deleteScheduledToursService(
        token,
        formId,
        listingAgentName,
        buyerAgentEmail,
        type
      );

      //For Agent Itself
      if (userState?.userType === UserType?.AGENT) {
        await inviteAgentByBuyer(
          userState?.agent?.agentId,
          'rejected',
          `${buyerDetails?.name.toUpperCase()} tour that was scheduled on ${moment(
            formData?.dateTime
          ).format('dddd')}, ${moment(formData?.dateTime)?.format(
            'MMMM Do, yyyy'
          )} at ${formData?.dateTime.split(',')?.[1]} has been cancelled`,
          linkedPropertiesData?.[0]?.mlsId,
          userState?.agent?.name,
          '',
          buyerId
        );
        if (
          userState?.agent?.preferredContact === 'CONTACT_RADIO_BOTH' ||
          userState?.agent?.preferredContact === 'CONTACT_RADIO_PHONE'
        ) {
          postMessageToUser(
            userState?.agent?.phone,
            `${buyerDetails?.name.toUpperCase()} tour that was scheduled on ${moment(
              formData?.dateTime
            ).format('dddd')}, ${moment(formData?.dateTime)?.format(
              'MMMM Do, yyyy'
            )} has been cancelled`
          );
        }

        //For Buyer
        await createBuyerNotificationById(
          Number(buyerId),
          `Agent ${userState?.agent?.name.toUpperCase()} has cancelled your tour that was scheduled on ${moment(
            formData?.dateTime
          ).format('dddd')}, ${moment(formData?.dateTime)?.format(
            'MMMM Do, yyyy'
          )} at ${formData?.dateTime.split(',')?.[1]}.`,
          'removed',
          token,
          'buyer',
          0
        );
        await postMessageToUser(
          buyerDetails?.phone,
          `${userState?.agent?.name.toUpperCase()} has cancelled your tour that was scheduled on ${moment(
            formData?.dateTime
          ).format('dddd')}, ${moment(formData?.dateTime)?.format(
            'MMMM Do, yyyy'
          )}`
        );
      } else {
        // For Buyer Itself
        await createBuyerNotificationById(
          userState?.buyer?.buyerAgentId,
          `Your OH showings for ${moment(formData?.dateTime).format(
            'dddd'
          )}, ${moment(formData?.dateTime)?.format('MMMM Do, yyyy')} at ${formData?.dateTime.split(',')?.[1]
          } has been cancelled.`,
          'removed',
          userState?.buyer?.token,
          'buyer',
          0
        );
        await postMessageToUser(
          userState?.buyer?.phone,
          `Your OH showings for ${moment(formData?.dateTime).format(
            'dddd'
          )}, ${moment(formData?.dateTime)?.format(
            'MMMM Do, yyyy'
          )} has been cancelled.`
        );
      }
      setMessageSocketCaller(String(Math.floor(Math.random() * 100 + 1)));
      setNotificationsCount(Number(buyerId));
      setIsShowing(false);
      setIsModalLoading(false);
      handleDiscardAndExit();
      return notification("Tour Cancelled Successfully", 'success')
    } catch (error) { }
    setIsShowing(false);
    setIsModalLoading(false);
  };
  const handleCancel = () => {
    setIsShowing(false);
  };
  const isMobile = useMediaQuery({ maxWidth: 500 });
  return (
    <Modal
      visible={isShowing}
      onCancel={handleCancel}
      onOk={handleOk}
      // bodyStyle={{ borderBlockStyle: "inset" }}
      width={1000}
      style={{ color: 'blue' }}
      footer={false}
    >
      {isModalLoading && <CustomSpinner />}
      <Row>
        <Row>
          <h2>Cancel Tour ?</h2>
        </Row>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <img
            src={cancelTour}
            alt="cancellation image"
            style={{ width: '100%' }}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <h4>
            <p style={{ fontSize: '17px', lineHeight: '30px' }}>
              Are you sure you want to cancel this tour? Once you cancel it,a
              <br />
              message will be sent out to all seller agents regarding the
              <br />
              cancellation.
            </p>
          </h4>
          <br />
          <p style={{ fontSize: '17px' }}>This action is irreversible.</p>
          <br />
          <TextField
            value={message}
            setValue={setMessage}
            placeholder="Hey,sorry for cancelling the showing.Sorry for &#10the inconvenience,will reschedule shortly!"
            label="Message to seller agent :"
            type={TextFieldType.BIG}
            icon={TextFieldIconType.EMAIL}
            isRequired={false}
            width={isMobile ? undefined : 38.4}
            className="invite-buyer-modal__message-field"
          />
          <Row justify="center" style={{ paddingTop: '50px' }}>
            <Col>
              <Button
                type={ButtonType.PRIMARY}
                text="CANCEL TOUR"
                clickHandler={handleOk}
                className="route_tour-modal__button"
                width={isMobile ? '100%' : 18.5}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default Cancel;
