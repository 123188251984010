import styled from '@emotion/styled';
import { Button, Modal, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropertyFiltersContext from '../../contexts/PropertyFiltersContext';
import UserContext, { UserType } from '../../contexts/UserContext';
import {
  deleteAgentAccount,
  deleteBuyerAccount,
  deleteSubscriptionPaln,
  unlinkAllConsumerFromAgentService,
  updateSellerDetails
} from '../../services/NavihomeService';
import { notification } from '../notification';
import CustomSpinner from '../spinner/CustomSpinner';

const CustomModalButton = styled(Button)`
  background: #004876;
  border-radius: 10px;
  padding: 0px 40px;
  color: white;
`;

interface AccountModalProps {
  isModalVisible: boolean;
  setIsModalVisible: Function;
  callBack: Function;
  name: string;
  illus: string;
}

const AccountModal: React.FC<AccountModalProps> = ({
  isModalVisible,
  setIsModalVisible,
  callBack,
  name,
  illus,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userState, logOut, changeUserType } = useContext(UserContext);
  const { clearFilters, setMessageSocketCaller,
    setNotificationsCount, } = useContext(PropertyFiltersContext);
  const history = useHistory();

  const buyerAccountDelete = async () => {
    setIsLoading(true);
    try {
      if (userState?.buyer?.sellerAgentName?.length > 0) {
        let payload = {
          name: '',
          sellerEmail: '',
          message: 'Seller Deleted their account!',
        };
        await updateSellerDetails(userState?.buyer?.sellerListingId, payload, userState?.buyer?.token);
      }
      const res: any = await deleteBuyerAccount(userState?.buyer.token);
      if (res?.affected > 0) {
        clearFilters();
        logOut();
        setIsLoading(false);
        history.push('/buy');
        return notification('Your Account Deleted Successfully!', 'success');
      }
      setIsLoading(false);
    } catch (error) {
      console.log('ERROR: ', error);
      setIsLoading(false);
    }
  };
  const agentAccountDelete = async () => {

    setIsLoading(true);
    try {
      const result: any = await unlinkAllConsumerFromAgentService(
        userState.agent.token,
        userState.agent.name
      );
      if (result?.success) {
        const res: any = await deleteAgentAccount(
          userState?.agent?.agentId,
          userState?.agent?.token
        );
        if (res?.affected > 0) {
          await deleteSubscriptionPaln(userState?.agent?.agentId);
          clearFilters();
          logOut();
          changeUserType(UserType.AGENT);
          setIsLoading(false);
          setMessageSocketCaller(String(Math.floor(Math.random() * 100 + 1)));
          setNotificationsCount(userState?.agent?.agentId);
          history.push('/');
          return notification('Your Account Deleted Successfully!', 'success');
        }
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('ERROR: ', error);
    }
  };
  return (
    <Modal
      title={`${name} ?`}
      visible={isModalVisible}
      footer={null}
      onCancel={() => {
        setIsModalVisible(false);
      }}
    >
      {isLoading && <CustomSpinner />}
      <Row justify="center">
        <img src={illus} alt="" height="350" width="350" />
      </Row>
      <Row justify="center">
        <CustomModalButton
          onClick={() => {
            if (name.toLocaleLowerCase() === 'delete account') {
              if (userState.userType === UserType.AGENT) {
                agentAccountDelete();
              } else {
                buyerAccountDelete();
              }
            }
            callBack();
          }}
        >
          {name}
        </CustomModalButton>
      </Row>
    </Modal>
  );
};

export default AccountModal;
