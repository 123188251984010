/* eslint-disable jsx-a11y/alt-text */
import styled from '@emotion/styled';
import { Checkbox } from 'antd';
import { useContext, useEffect, useState } from 'react';
import condo from '../assets/css/components/Group 966.svg';
import singlyFamily from '../assets/css/components/Group 981.svg';
import multiFamily from '../assets/css/components/Group 982.svg';
import commercial from '../assets/css/components/Group 983.svg';
import rental from '../assets/css/components/Group 984.svg';
import mobile from '../assets/css/components/Group 985.svg';
import land from '../assets/css/components/Group 986.svg';
import other from '../assets/css/components/Group 987.svg';
import buttonClose from '../assets/images/button-close.svg';
import CounterField from '../components/CounterField';
import PriceField from '../components/PriceField';
import SelectField, { SelectFieldType } from '../components/SelectField';
import TextField, {
  TextFieldType
} from '../components/TextField';
import PropertyFiltersContext from '../contexts/PropertyFiltersContext';

interface PropertyFiltersModalProps {
  isShowing: boolean;
  applyFilters: (keywords: string) => void;
  closeAction: () => void;
  handleChange?: () => void;
  firePlace?: string;
  openHouse?: string;
  setFirePlace?: (value: string) => void;
}

const CustomCheckboxGroups = styled(Checkbox.Group)`
  width: 100%;

  // .ant-checkbox-wrapper-checked {
  //   color: white;
  //   background-color: #4285f4;
  //   border: #4285f4;
  //   height: 24px;
  //   width: 24px;
  //   display: inline-flex;
  //   justify-content: center;
  //   align-items: center;
  // }

  .ant-checkbox-wrapper {
    height: 50px;
    width: 150px;
    margin-top: 5px;

    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
`;

export const CustomCheckboxs = styled(Checkbox)`
  margin: 0 0 5px 8px;
  border-radius: 10px;
  font-size: 14px;
  border: 1px solid #e9eaec;

  .ant-checkbox {
    display: none;
  }
`;

const CustomSubHead = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #004876;
  padding-bottom: 5px;
`;
const CustomPara = styled.p`
  @media screen and (max-width: 380px) {
    padding-bottom: 18px;
  }
`;

const PropertyFiltersModal: React.FC<PropertyFiltersModalProps> = ({
  isShowing,
  applyFilters,
  closeAction,
  handleChange,
  setFirePlace,
  firePlace,
  openHouse,
}) => {
  const {
    propertyFiltersState,
    clearFilters,
    setQuery,
    setMinPrice,
    setMaxPrice,
    setBeds,
    setBaths,
    setHalfBaths,
    setMinSquareFootage,
    setMaxSquareFootage,
    setMinLotSize,
    setMaxLotSize,
    setHeatingCooling,
    setMinYearBuilt,
    setMaxYearBuilt,
    setMinYearConverted,
    setMaxYearConverted,
    setParkingSpaces,
    setGarages,
    setOffStreetParking,
    setPropertyType,
    setCooling,
  } = useContext(PropertyFiltersContext);

  const {
    minPrice,
    maxPrice,
    minSquareFootage,
    maxSquareFootage,
    minLotSize,
    maxLotSize,
    minYearBuilt,
    maxYearBuilt,
    minYearConverted,
    maxYearConverted,
  } = propertyFiltersState;

  const { propertyType } = propertyFiltersState;
  const [propertyArr, setPropertyArr] = useState<Array<string>>([]);
  const [errorText, setErrorText] = useState<string>('');
  const [isSqftMaximum, setIsSqftMaximum] = useState<boolean>(false);
  const [isYrBuiltMaximum, setIsYrBuiltMaximum] = useState<boolean>(false);
  const [isYrConvertedMaximum, setIsYrConvertedMaximum] =
    useState<boolean>(false);
  const [isLotSizeMaximum, setIsLotSizeMaximum] = useState<boolean>(false);
  const [isPriceMaximum, setIsPriceMaximum] = useState<boolean>(false);

  const [clickDisabled, setClickDisabled] = useState(false);

  const handleApplyFilters = () => {
    closeAction();
    applyFilters('');
  };

  const sqftArray = [
    'Any sqft',
    '500 sqft',
    '750 sqft',
    '1,000 sqft',
    '1,250 sqft',
    '1,500 sqft',
    '1,750 sqft',
    '2,000 sqft',
    '2,500 sqft',
    '3,000 sqft',
    '3,500 sqft',
    '4,000 sqft',
    '4,500 sqft',
    '5,000 sqft',
    '5,500 sqft',
    '6,000 sqft',
    '6,500 sqft',
    '7,000 sqft',
    '7,500 sqft',
    '8,000 sqft',
    '8,500 sqft',
    '9,000 sqft',
    '9,500 sqft',
    '10,000 sqft',
    '10,500 sqft',
    '11,000 sqft',
  ];

  const lotArray = [
    'Any sqft',
    '0.25 acres',
    '0.5 acres',
    '0.75 acres',
    '1 acre',
    '1.25 acres',
    '1.5 acres',
    '1.75 acres',
    '2 acres',
    '2.25 acres',
    '2.5 acres',
    '2.75 acres',
    '3 acres',
    '3.25 acres',
    '3.5 acres',
    '3.75 acres',
    '4 acres',
    '4.25 acres',
    '4.5 acres',
    '4.75 acres',
    '5 acres',
    '5.25 acres',
    '5.5 acres',
    '5.75 acres',
    '6 acres',
    'Any size',
  ];
  const heatingArray = [
    'Any type',
    'Central Heat',
    'Forced Air',
    'Natural Gas',
    'Individual',
    'Heat Pump',
    'Ductless',
    'Baseboard',
    'Oil',
    'Propane',
    'Electric Baseboard',
    'Electric',
    'Steam',
    'Central',
    'Fireplace',
    'Hot Water',
    'Fireplace(s)',
    'Unit Control',
  ];
  const coolingArray = [
    'Any type',
    'Central Air',
    'Window Unit(s)',
    'Wall Unit(s)',
    'None',
  ];
  const yearsArray = [
    'Any Year',
    '2023',
    '2022',
    '2021',
    '2020',
    '2019',
    '2018',
    '2017',
    '2016',
    '2015',
    '2010',
    '2005',
    '2000',
    '1990',
    '1980',
    '1970',
    '1960',
    '1950',
    '1940',
    '1930',
    '1920',
    '1910',
    '1900',
  ];

  const [selectOtherPropertyTypeArray, setSelectOtherPropertyTypeArray] =
    useState<Array<any>>([
      { imageUrl: condo, value: 'condominium', name: 'Condo' },
      { imageUrl: singlyFamily, value: 'singlefamily', name: 'Single Family' },
      { imageUrl: multiFamily, value: 'multifamily', name: 'Multi Family' },
      { imageUrl: commercial, value: 'commercial', name: 'commercial' },
      { imageUrl: mobile, value: 'residential', name: 'RES' },
      { imageUrl: rental, value: 'rental', name: 'RNT' },
      { imageUrl: land, value: 'land', name: 'Land' },
      { imageUrl: mobile, value: 'mobilehome', name: 'Mobile' },
    ]);

  const selectTotalPropertyTypeArray: any = [
    { imageUrl: commercial, name: 'Townhouse', value: 'townhouse' },
    { imageUrl: mobile, name: 'Duplex', value: 'duplex' },
    { imageUrl: singlyFamily, name: 'Motel', value: 'hotelmotel' },
    { imageUrl: singlyFamily, name: 'Rowhouse', value: 'Rowhouse' },
    { imageUrl: condo, name: 'Condominium', value: 'Condominium' },
    { imageUrl: commercial, name: 'Apartment', value: 'Apartment' },
    {
      imageUrl: singlyFamily,
      name: 'Single Family Residence',
      value: 'SingleFamilyResidence',
    },
  ];

  useEffect(() => {
    if (propertyType) {      
      let arr = [];
      arr = propertyType.includes('&type=')
        ? propertyType.split('&type=')
        : [propertyType];
      setPropertyArr(arr);
    } else {
      setPropertyArr([]);
    }
  }, [propertyType]);

  useEffect(() => {
    setIsPriceMaximum(false);
    setClickDisabled(false);
    setErrorText('');
    if (minPrice > maxPrice && openHouse != 'open_house') {
      setIsPriceMaximum(true);
      setClickDisabled(true);
      setErrorText('Price');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minPrice, maxPrice]);

  const renderOtherPropertyType = () => {    
    return selectOtherPropertyTypeArray?.map((item) => {
      return (
        <CustomCheckboxs
          value={item?.value}
          checked={propertyArr.includes(item?.value)}
          style={
            propertyArr.includes(item?.value)
              ? { background: '#c2d500', border: '#c2d500' }
              : {
                  background: '#fff',
                  color: 'black',
                  border: '1px solid #e9eaec',
                }
          }
          onChange={(e: any) => {
            handleSelectValue(e.target.value);
            // setType(value);
            // setIsSelected(!isSelected);
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <img
              width={20}
              style={{ marginRight: '5px' }}
              src={item?.imageUrl}
            />
            <span>{item?.name}</span>
          </div>
        </CustomCheckboxs>
      );
    });
  };

  const handleSelectValue = (value: any) => {    
    try {
      let arr = [...propertyArr];

      if (!arr.includes(value)) {
        arr.push(value);
      } else {
        const index = arr.findIndex((item: any) => item === value);
        if (index > -1) {
          arr.splice(index, 1);
        }
      }
      setPropertyType(arr?.join('&type='));
      if(arr)
        setPropertyArr(arr);
      else
      setPropertyArr([]);
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleSetMinSquareFootage = (value: string) => {
    const rawValue = value.replace(/[^0-9]+/g, '');
    let numberValue = parseInt(rawValue);
    if (isNaN(numberValue)) {
      setMinSquareFootage(0);
      return;
    }
    if (numberValue > 99999999) {
      numberValue = Math.trunc(numberValue / 10);
    }
    setMinSquareFootage(Math.min(numberValue, 99999999));
  };
  const handleSetMaxSquareFootage = (value: string) => {
    const rawValue = value.replace(/[^0-9]+/g, '');
    let numberValue = parseInt(rawValue);
    if (isNaN(numberValue)) {
      setMaxSquareFootage(0);
      return;
    }
    if (numberValue > 99999999) {
      numberValue = Math.trunc(numberValue / 10);
    }
    setMaxSquareFootage(Math.min(numberValue, 99999999));
  };

  const inputHandleChange = (value: string) => {
    if (isPriceMaximum)
      if (value !== 'Price') {
        setErrorText('Price');
        setClickDisabled(true);
      }
    if (isSqftMaximum)
      if (value !== 'Square Footage') {
        setErrorText('Square Footage');
        setClickDisabled(true);
      }
    if (isLotSizeMaximum)
      if (value !== 'Lot size') {
        setErrorText('Lot size');
        setClickDisabled(true);
      }
    if (isYrBuiltMaximum)
      if (value !== 'Year Built') {
        setErrorText('Year Built');
        setClickDisabled(true);
      }
    if (isYrConvertedMaximum)
      if (value !== 'Year Converted') {
        setErrorText('Year Converted');
        setClickDisabled(true);
      }
  };
  const handleSetMinLotSize = (value: string) => {
    let numberValue = parseFloat(value);
    if (isNaN(numberValue)) {
      setMinLotSize(0);
      return;
    }
    if (numberValue > 99999999) {
      numberValue = Math.trunc(numberValue / 10);
    }
    setMinLotSize(Math.min(numberValue, 99999999));
  };
  const handleSetMaxLotSize = (value: string) => {
    let numberValue = parseFloat(value);
    if (isNaN(numberValue)) {
      setMaxLotSize(0);
      return;
    }
    if (numberValue > 99999999) {
      numberValue = Math.trunc(numberValue / 10);
    }
    setMaxLotSize(Math.min(numberValue, 99999999));
  };
  const handleSetMinYearBuilt = (value: string) => {
    const rawValue = value.replace(/[^0-9]+/g, '');
    let numberValue = parseInt(rawValue);
    if (isNaN(numberValue)) {
      setMinYearBuilt(0);
      return;
    }
    if (numberValue > 99999999) {
      numberValue = Math.trunc(numberValue / 10);
    }
    setMinYearBuilt(Math.min(numberValue, 99999999));
  };
  const handleSetMaxYearBuilt = (value: string) => {
    const rawValue = value.replace(/[^0-9]+/g, '');
    let numberValue = parseInt(rawValue);
    if (isNaN(numberValue)) {
      setMaxYearBuilt(0);
      return;
    }
    if (numberValue > 99999999) {
      numberValue = Math.trunc(numberValue / 10);
    }
    setMaxYearBuilt(Math.min(numberValue, 99999999));
  };
  const handleSetMinYearConverted = (value: string) => {
    const rawValue = value.replace(/[^0-9]+/g, '');
    let numberValue = parseInt(rawValue);
    if (isNaN(numberValue)) {
      setMinYearConverted(0);
      return;
    }
    if (numberValue > 99999999) {
      numberValue = Math.trunc(numberValue / 10);
    }
    setMinYearConverted(Math.min(numberValue, 99999999));
  };
  const handleSetMaxYearConverted = (value: string) => {
    const rawValue = value.replace(/[^0-9]+/g, '');
    let numberValue = parseInt(rawValue);
    if (isNaN(numberValue)) {
      setMaxYearConverted(0);
      return;
    }
    if (numberValue > 99999999) {
      numberValue = Math.trunc(numberValue / 10);
    }
    setMaxYearConverted(Math.min(numberValue, 99999999));
  };

  const otherPropertyTypeHandler = () => {
    setSelectOtherPropertyTypeArray([
      ...selectOtherPropertyTypeArray,
      ...selectTotalPropertyTypeArray,
    ]);
  };
  const lessOtherPropertyTypeHandler = () => {
    setSelectOtherPropertyTypeArray(selectOtherPropertyTypeArray?.slice(0, 8));
  };

  const closeActionHandler = () => {
    closeAction();
    if (clickDisabled) {
      clearFilters();
    }
  };

  const modalClassName = isShowing
    ? 'property-filters-modal visible'
    : 'property-filters-modal hidden';
  const cardClassName = isShowing
    ? 'property-filters-modal__card animation__slide-in-from-right'
    : 'property-filters-modal__card animation__slide-out-to-right';
  return (
    <div className={modalClassName}>
      <div className={cardClassName}>
        <div className="property-filters-modal__header">
          <h1 style={{ color: '#004876', fontWeight: '600' }}>Filters</h1>
          <img
            className="property-filters-modal__close-button"
            src={buttonClose}
            onClick={closeActionHandler}
            alt="Close button"
          />
        </div>
        <div className="property-filters-modal__content">
          <div
            style={{
              paddingLeft: '10px',
              display: 'flex',
              fontSize: '18px',
              fontWeight: '500',
              color: '#004876',
            }}
          >
            <span style={{ paddingRight: '5px' }}>Basic</span>
            <span style={{ borderBottom: '3px solid #C2D500' }}>Filters</span>
          </div>
          {clickDisabled && isPriceMaximum && openHouse != 'open_house' && (
            <p style={{ paddingLeft: '8px', color: 'red' }}>
              Maximum {errorText} should be greater than Minimum {errorText}.
            </p>
          )}
          <div className="property-fitlers-modal__basic-filters">
            <div className="property-filters-modal__basic-filters--left">
              {openHouse === 'open_house' ? (
                <CustomSubHead>Minimum Price</CustomSubHead>
              ) : (
                <CustomSubHead>Price</CustomSubHead>
              )}
              <div
                className="property-filters-modal__price"
                style={{ fontWeight: '500' }}
              >
                <PriceField
                  value={propertyFiltersState.minPrice}
                  placeholder="Min price"
                  setValue={setMinPrice}
                />
                {/* {openHouse === 'open_house' ? (
                  <></>
                ) : (
                  <> */}
                    <CustomPara>to</CustomPara>
                    <PriceField
                      value={propertyFiltersState.maxPrice}
                      placeholder="Max price"
                      setValue={setMaxPrice}
                    />
                  {/* </>
                )} */}
              </div>
              <div className="property-filters-modal__counter">
                <CustomSubHead>Minimum Beds</CustomSubHead>
                <CounterField
                  value={propertyFiltersState.beds}
                  setValueHandler={setBeds}
                  increment={1}
                  minValue={1}
                  maxValue={10}
                />
              </div>
              <div className="property-filters-modal__counter">
                <CustomSubHead>Minimum Baths</CustomSubHead>
                <CounterField
                  value={propertyFiltersState.baths}
                  setValueHandler={setBaths}
                  increment={1}
                  minValue={1}
                  maxValue={10}
                />
              </div>
              <div className="property-filters-modal__counter">
                <CustomSubHead>Minimum Half-baths</CustomSubHead>
                <CounterField
                  value={propertyFiltersState.halfBaths}
                  setValueHandler={setHalfBaths}
                  increment={1}
                  minValue={0}
                  maxValue={1}
                />
              </div>
            </div>
            <div className="property-filters-modal__basic-filters--right">
              <CustomSubHead>Property type</CustomSubHead>

              <div>
                <CustomCheckboxGroups>
                  {renderOtherPropertyType()}

                  {selectOtherPropertyTypeArray?.length > 8 ? (
                    <>
                      <CustomCheckboxs
                        // value="Other"
                        onClick={lessOtherPropertyTypeHandler}
                      >
                        <div>
                          <img
                            width={20}
                            style={{ marginRight: '5px' }}
                            src={other}
                          />
                          <span>Less</span>
                        </div>
                      </CustomCheckboxs>
                    </>
                  ) : (
                    <>
                      <CustomCheckboxs
                        // value="Other"
                        onClick={otherPropertyTypeHandler}
                      >
                        <div>
                          <img
                            width={20}
                            style={{ marginRight: '5px' }}
                            src={other}
                          />
                          <span>Other</span>
                        </div>
                      </CustomCheckboxs>
                    </>
                  )}
                </CustomCheckboxGroups>
              </div>
            </div>
          </div>

          <div className="property-filters-modal__property-details">
            <div
              style={{
                paddingLeft: '10px',
                display: 'flex',
                fontSize: '18px',
                fontWeight: '500',
                color: '#004876',
              }}
            >
              <span>Property D</span>
              <span style={{ borderBottom: '3px solid #C2D500' }}>etails</span>
            </div>
            {clickDisabled && !isPriceMaximum && (
              <p style={{ paddingLeft: '8px', color: 'red' }}>
                Maximum {errorText} should be greater than Minimum {errorText}.
              </p>
            )}
            <div className="property-filters-modal__property-details__cells">
              <div className="property-filters-modal__property-details__cell">
                <CustomSubHead style={{ paddingLeft: '10px' }}>
                  Square Footage
                </CustomSubHead>
                <div className="property-filters-modal__property-details__cell-content">
                  <SelectField
                    value={
                      propertyFiltersState.minSquareFootage === 0
                        ? 'Min. sqft'
                        : `${propertyFiltersState?.minSquareFootage} sqft`
                    }
                    setValue={handleSetMinSquareFootage}
                    placeholder={'Min. sqft'}
                    options={sqftArray}
                    optionWidth={11.4}
                    optionsHeight={17.3}
                    handleChange={(e: any) => {
                      handleChange();
                      const sqft = e?.split('s')?.[0];
                      setErrorText('');
                      setClickDisabled(false);
                      setIsSqftMaximum(false);
                      inputHandleChange('Square Footage');
                      if (
                        Number(sqft) > maxSquareFootage &&
                        maxSquareFootage > 0
                      ) {
                        setErrorText('Square Footage');
                        setClickDisabled(true);
                        setIsSqftMaximum(true);
                      }
                    }}
                    type={SelectFieldType.DEFAULT}
                    sortProperties={() => {
                    }}
                  />
                  <SelectField
                    value={
                      propertyFiltersState.maxSquareFootage === 0
                        ? 'Max. sqft'
                        : `${propertyFiltersState.maxSquareFootage} sqft`
                    }
                    setValue={handleSetMaxSquareFootage}
                    placeholder={'Max. sqft'}
                    options={sqftArray}
                    optionWidth={11.4}
                    optionsHeight={17.3}
                    handleChange={(e: any) => {
                      handleChange();
                      const sqft = e?.split('s')?.[0];
                      setErrorText('');
                      setClickDisabled(false);
                      setIsSqftMaximum(false);
                      inputHandleChange('Square Footage');
                      if (Number(sqft) < minSquareFootage) {
                        setErrorText('Square Footage');
                        setClickDisabled(true);
                        setIsSqftMaximum(true);
                      }
                    }}
                    type={SelectFieldType.DEFAULT}
                    sortProperties={() => {
                    }}
                  />
                </div>
              </div>

              <div className="property-filters-modal__property-details__cell">
                <CustomSubHead style={{ paddingLeft: '10px' }}>
                  Lot size
                </CustomSubHead>
                <div className="property-filters-modal__property-details__cell-content">
                  <SelectField
                    value={
                      propertyFiltersState.minLotSize === 0
                        ? 'Min. size'
                        : `${propertyFiltersState.minLotSize} ${
                            propertyFiltersState.minLotSize > 100
                              ? 'sqft'
                              : 'acres'
                          }  `
                    }
                    setValue={handleSetMinLotSize}
                    placeholder={'Min. size'}
                    options={lotArray}
                    optionWidth={11.6}
                    optionsHeight={17.3}
                    handleChange={(e: any) => {
                      handleChange();
                      const sqft = e?.split('a')?.[0];
                      setErrorText('');
                      setClickDisabled(false);
                      setIsLotSizeMaximum(false);
                      inputHandleChange('Lot size');
                      if (Number(sqft) > maxLotSize && maxLotSize > 0) {
                        setErrorText('Lot size');
                        setClickDisabled(true);
                        setIsLotSizeMaximum(true);
                      }
                    }}
                    sortProperties={() => {
                    }}
                    type={SelectFieldType.DEFAULT}
                  />
                  <SelectField
                    value={
                      propertyFiltersState.maxLotSize === 0
                        ? 'Max. size'
                        : `${propertyFiltersState.maxLotSize} ${
                            propertyFiltersState.maxLotSize > 100
                              ? 'sqft'
                              : 'acres'
                          }`
                    }
                    setValue={handleSetMaxLotSize}
                    placeholder={'Max. size'}
                    options={lotArray}
                    optionWidth={11.6}
                    optionsHeight={17.3}
                    handleChange={(e: any) => {
                      handleChange();
                      const sqft = e?.split('a')?.[0];
                      setErrorText('');
                      setClickDisabled(false);
                      setIsLotSizeMaximum(false);
                      inputHandleChange('Lot size');
                      if (Number(sqft) < minLotSize) {
                        setErrorText('Lot size');
                        setClickDisabled(true);
                        setIsLotSizeMaximum(true);
                      }
                    }}
                    sortProperties={() => {
                    }}
                    type={SelectFieldType.DEFAULT}
                  />
                </div>
              </div>

              <div className="property-filters-modal__property-details__cell">
                <CustomSubHead style={{ paddingLeft: '10px' }}>
                  Heating
                </CustomSubHead>
                <div className="property-filters-modal__property-details__cell-content">
                  <SelectField
                    value={
                      propertyFiltersState.heatingCooling === ''
                        ? 'Any type'
                        : propertyFiltersState.heatingCooling
                    }
                    setValue={setHeatingCooling}
                    placeholder={'Any type'}
                    options={heatingArray}
                    optionWidth={12.4}
                    optionsHeight={17.2}
                    handleChange={handleChange}
                    sortProperties={() => {
                    }}
                    type={SelectFieldType.DEFAULT}
                  />
                </div>
              </div>
              <div className="property-filters-modal__property-details__cell">
                <CustomSubHead style={{ paddingLeft: '10px' }}>
                  Cooling
                </CustomSubHead>
                <div className="property-filters-modal__property-details__cell-content">
                  <SelectField
                    value={
                      propertyFiltersState.cooling === ''
                        ? 'Any type'
                        : propertyFiltersState.cooling
                    }
                    setValue={setCooling}
                    placeholder={'Any type'}
                    options={coolingArray}
                    optionWidth={12.7}
                    optionsHeight={12.5}
                    sortProperties={() => {
                    }}
                    handleChange={handleChange}
                    type={SelectFieldType.DEFAULT}
                  />
                </div>
              </div>
              <div className="property-filters-modal__property-details__cell">
                <CustomSubHead style={{ paddingLeft: '10px' }}>
                  Year Built
                </CustomSubHead>
                <div className="property-filters-modal__property-details__cell-content">
                  <SelectField
                    value={
                      propertyFiltersState.minYearBuilt === 0
                        ? 'Min Year'
                        : `${propertyFiltersState.minYearBuilt} Year`
                    }
                    setValue={handleSetMinYearBuilt}
                    placeholder={'Min Year'}
                    options={yearsArray}
                    optionWidth={10.4}
                    optionsHeight={17.3}
                    handleChange={(e: any) => {
                      // handleChange();
                      const sqft = e?.split('Y')?.[0];
                      setErrorText('');
                      setClickDisabled(false);
                      setIsYrBuiltMaximum(false);
                      inputHandleChange('Year Built');

                      if (Number(sqft) > maxYearBuilt && maxYearBuilt > 0) {
                        setErrorText('Year Built');
                        setClickDisabled(true);
                        setIsYrBuiltMaximum(true);
                      }
                    }}
                    sortProperties={() => {
                    }}
                    type={SelectFieldType.DEFAULT}
                  />
                  <SelectField
                    value={
                      propertyFiltersState.maxYearBuilt === 0
                        ? 'Max Year'
                        : `${propertyFiltersState.maxYearBuilt} Year`
                    }
                    setValue={handleSetMaxYearBuilt}
                    placeholder={'Max Year'}
                    options={yearsArray}
                    optionWidth={10.4}
                    optionsHeight={17.3}
                    sortProperties={() => {
                    }}
                    handleChange={(e: any) => {
                      // handleChange();
                      const sqft = e?.split('Y')?.[0];
                      setErrorText('');
                      setClickDisabled(false);
                      setIsYrBuiltMaximum(false);
                      inputHandleChange('Year Built');
                      if (Number(sqft) < minYearBuilt) {
                        setErrorText('Year Built');
                        setClickDisabled(true);
                        setIsYrBuiltMaximum(true);
                      }
                    }}
                    type={SelectFieldType.DEFAULT}
                  />
                </div>
              </div>
              <div className="property-filters-modal__property-details__cell">
                <CustomSubHead style={{ paddingLeft: '10px' }}>
                  Year Converted
                </CustomSubHead>
                <div className="property-filters-modal__property-details__cell-content">
                  <SelectField
                    value={
                      propertyFiltersState.minYearConverted === 0
                        ? 'Min Year'
                        : `${propertyFiltersState.minYearConverted} Year`
                    }
                    setValue={handleSetMinYearConverted}
                    placeholder={'Min Year'}
                    options={yearsArray}
                    optionWidth={10.4}
                    optionsHeight={17.3}
                    sortProperties={() => {
                    }}
                    handleChange={(e: any) => {
                      handleChange();
                      const sqft = e?.split('Y')?.[0];
                      setErrorText('');
                      setClickDisabled(false);
                      setIsYrConvertedMaximum(false);
                      inputHandleChange('Year Converted');
                      if (
                        Number(sqft) > maxYearConverted &&
                        maxYearConverted > 0
                      ) {
                        setErrorText('Year Converted');
                        setClickDisabled(true);
                        setIsYrConvertedMaximum(true);
                      }
                    }}
                    type={SelectFieldType.DEFAULT}
                  />
                  <SelectField
                    value={
                      propertyFiltersState.maxYearConverted === 0
                        ? 'Max Year'
                        : `${propertyFiltersState.maxYearConverted} Year`
                    }
                    setValue={handleSetMaxYearConverted}
                    placeholder={'Max Year'}
                    options={yearsArray}
                    optionWidth={10.4}
                    optionsHeight={17.3}
                    sortProperties={() => {
                    }}
                    handleChange={(e: any) => {
                      handleChange();
                      const sqft = e?.split('Y')?.[0];
                      setErrorText('');
                      setClickDisabled(false);
                      setIsYrConvertedMaximum(false);
                      inputHandleChange('Year Converted');
                      if (Number(sqft) < minYearConverted) {
                        setErrorText('Year Converted');
                        setClickDisabled(true);
                        setIsYrConvertedMaximum(true);
                      }
                    }}
                    type={SelectFieldType.DEFAULT}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="property-filters-modal__parking-details">
            <div
              style={{
                display: 'flex',
                fontSize: '18px',
                fontWeight: '500',
                color: '#004876',
              }}
            >
              <span>Parking D</span>
              <span style={{ borderBottom: '3px solid #C2D500' }}>etails</span>
            </div>
            <div className="property-filters-modal__parking-details__content">
              <div className="property-filters-modal__counter">
                <CustomSubHead>Parking Spaces</CustomSubHead>
                <CounterField
                  value={propertyFiltersState.parkingSpaces}
                  setValueHandler={setParkingSpaces}
                  increment={1}
                  minValue={0}
                  maxValue={10}
                />
              </div>
              <div className="property-filters-modal__counter">
                <CustomSubHead>Garage</CustomSubHead>
                <CounterField
                  value={propertyFiltersState.garages}
                  setValueHandler={setGarages}
                  increment={1}
                  minValue={0}
                  maxValue={10}
                />
              </div>
              <div className="property-filters-modal__counter">
                <CustomSubHead>Off-street Parking</CustomSubHead>
                <CounterField
                  value={propertyFiltersState.offStreetParking}
                  setValueHandler={setOffStreetParking}
                  increment={1}
                  minValue={0}
                  maxValue={10}
                />
              </div>
            </div>
          </div>
          <div className="property-filters-modal__keywords">
            <div>
              <h4 style={{ fontSize: '18px', color: '#004876' }}>Keywords</h4>
            </div>
            <div>
              <TextField
                value={firePlace}
                setValue={(e: any) => {
                  setFirePlace(e);
                  setQuery(e);
                }}
                placeholder="Enter keywords"
                type={TextFieldType.DEFAULT}
                isRequired={false}
                width={38.4}
                className="login-modal__email-field"
              />
            </div>
          </div>
        </div>
        <div
          className="property-filters-modal__apply-button"
          style={
            clickDisabled
              ? { cursor: 'not-allowed', opacity: '.7' }
              : { cursor: 'pointer' }
          }
          onClick={clickDisabled ? () => {} : handleApplyFilters}
        >
          <p
            className="f-heading-4"
            style={{ fontWeight: '700', fontSize: '15px' }}
          >
            APPLY FILTERS
          </p>
        </div>
      </div>
    </div>
  );
};

export default PropertyFiltersModal;
