import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button, { ButtonType } from '../../components/Button';
import { notification } from '../../components/notification';
import AgentContext from '../../contexts/AgentContext';
import UserContext, { UserType } from '../../contexts/UserContext';
import {
  addLinkedPropertyForBuyer,
  removeLinkedPropertyForBuyer
} from '../../services/NavihomeService';
import SearchField from '../SearchField';

const CustomRow = styled(Row)`
  justify-content: space-between;
  flex: 1;
  margin: 12px;
  @media screen and (min-width: 1100px) {
    margin-right: 25rem;
  }
  @media screen and (min-width: 900px) {
    margin-right: 10rem;
  }
`;
const CustomDiv = styled.div`
  @media screen and (max-width: 991px) {
    margin-top: 1rem;
  }
`;

const CustomButton = styled(Button)`
  &.button--white {
    cursor: pointer;
  }
  &.button--white p {
    font-size: 13px !important;
    /* color: orange !important; */
  }
  &.button--primary p {
    font-size: 13px !important ;
  }
`;

interface PropertyTourHeaderProps {
  setShowMap?: any;
  showmap?: any;
  setReqSearchText?: any;
  setFilteredRequestedProperties?: any;
  requestedProperties?: any;
  setLinkedProperties?: any;
  linkedProperties?: any;
  filteredRequestedProperties?: any;
  setAgentIdForLinkedBuyer?: any;
  agentIdForLinkedBuyer?: number;
  setBuyerSideAgentName?: any;
  setDashboardState?: any;
  setLinkedBuyer?: any;
  setSearchProperties?: any;
  setEditingTour?: any;
  unlinkPropHeader?: boolean;
  setTourPropPage?: any;
  tourPropPage?: boolean;
  propId?: any;
  showAddPropertyBtn?: boolean;
  setUnlinkPropHeader?: any;
  formData?: any;
  linkedPropertiesData?: any;
  setLinkedPropertiesData?: any;
  propDataDetails?: any;
  filteredLinkedProperties?: Array<any>;
  setFilteredLinkedProperties?: (val: Array<any>) => void;
  setGetDirectionsPropertyClicked?: (val: string) => void;
  setBestRoute?: (val: Array<any>) => void;
  getDirectionsPropertyClicked?: string;
  saveTourClicked?: string;
  setRunMap?: (val: boolean) => void;
  setUseManual?: (val: boolean) => void;
  setIsAllAccepted?: (val: boolean) => void;
  setisScheduledRouteUpdated?: (val: boolean) => void;
  setselected?: (val: string) => void;
  setIsUnlinkedPropertyClicked?: (val: boolean) => void;
  setScheduleStatus?: (val: boolean) => void;
  setHandleTogglingClicked?: (val: string) => void;
  setSchedulingStatusClicked?: (val: string) => void;
}
const PropertyTourHeader: React.FC<PropertyTourHeaderProps> = ({
  setShowMap,
  setRunMap,
  setLinkedProperties,
  linkedProperties,
  setAgentIdForLinkedBuyer,
  setBuyerSideAgentName,
  setDashboardState,
  setLinkedBuyer,
  setSearchProperties,
  setEditingTour,
  unlinkPropHeader,
  setTourPropPage,
  tourPropPage,
  propId,
  showAddPropertyBtn,
  setUnlinkPropHeader,
  formData,
  linkedPropertiesData,
  setLinkedPropertiesData,
  filteredLinkedProperties,
  setisScheduledRouteUpdated,
  setFilteredLinkedProperties,
  propDataDetails,
  setBestRoute,
  setselected,
  saveTourClicked,
  setIsUnlinkedPropertyClicked,
  setScheduleStatus,
  setHandleTogglingClicked,
  setSchedulingStatusClicked,
  getDirectionsPropertyClicked,
  setGetDirectionsPropertyClicked,
}) => {
  const history = useHistory();
  const { agentState } = useContext(AgentContext);
  const { userState } = useContext(UserContext);
  const [isNotEditable, setIsNotEditable] = useState<boolean>(false);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let param = useQuery();

  // const getPropDetails = async (propId: any) => {
  //   const data = await getPropertyDetailsService(propId);
  //   setPropDetails(data);
  // };

  useEffect(() => {
    let userRole: any = param.get('role') ?? '';
    if (userState?.userType === UserType.AGENT && userRole === 'Buyer') {
      setIsNotEditable(true);
    } else if (userState?.userType === UserType.BUYER && userRole === 'Agent') {
      setIsNotEditable(true);
    }
    // getPropDetails(propId);
  }, []);

  const checkFormData = () => {
    // To check date and time
    if (formData?.dateTime === null || !formData?.dateTime || JSON.stringify(formData?.dateTime).includes(undefined)) {
      return notification('Please Enter Date and Time', 'error');
    }


    // To check time per property
    if (formData?.timePerProp === null || !formData?.timePerProp || formData?.timePerProp === "Select") {
      return notification('Please select viewing time per property', 'error');
    }

    // To check starting address
    if (formData?.startAdd === null || !formData?.startAdd) {
      return notification('Please Enter starting address', 'error');
    }

    // To check starting address city and zip code
    if (formData?.startAddCity === null || !formData?.startAddCity) {
      return notification(
        'Please Enter start address city or zip code',
        'error'
      );
    }

    // To check Ending address
    if (formData?.endingAdd === null || !formData?.endingAdd) {
      return notification('Please Enter ending address', 'error');
    }

    // TO check Ending address City
    if (formData?.endingAddCity === null || !formData?.endingAddCity) {
      return notification(
        'Please Enter ending address city or zip code',
        'error'
      );
    }

    if (saveTourClicked === 'Save Tour') {
      setSchedulingStatusClicked("Save Tour");
      setDashboardState(false);
      setShowMap(true);
      return;
    }

    setEditingTour(true);
    // setRunLinkedProperty(true);
    setDashboardState(false);
    setShowMap(true);
  };

  const reqSearchAction = (query: string) => {
    setSearchProperties(query);

    // for now we have to search in additional properties

    // setReqSearchText(query)
    // if (requestedProperties.length <= 0) {
    //   return <></>
    // }
    // const filteredproperty: any = requestedProperties?.filter((item: any) => {
    //   if (item?.listPrice?.toString()?.includes(query)) {
    //     return item
    //   }
    //   if (item?.property?.bedrooms == query) {
    //     return item
    //   }
    //   if (item?.property?.area?.toString()?.includes(query)) {
    //     return item
    //   }
    //   if (item?.address?.city?.toUpperCase()?.includes(query?.toUpperCase())) {
    //     return item
    //   }
    //   if (
    //     item?.address?.streetName?.toUpperCase()?.includes(query?.toUpperCase())
    //   ) {
    //     return item
    //   }
    //   if (
    //     item?.address?.streetNumber
    //       ?.toString()
    //       ?.toUpperCase()
    //       ?.includes(query?.toUpperCase())
    //   ) {
    //     return item
    //   }
    //   if (item?.address?.state?.toUpperCase()?.includes(query?.toUpperCase())) {
    //     return item
    //   }
    //   if (item?.property?.type?.toUpperCase()?.includes(query?.toUpperCase())) {
    //     return item
    //   }
    //   if (item?.address?.postalCode?.includes(query)) {
    //     return item
    //   }
    //   if (item?.property?.type?.includes(query)) {
    //     return item
    //   }
    // })
    // setFilteredRequestedProperties(filteredproperty)

    //=====================
  };

  const getAgentIdFromBuyer = async (buyerId: any) => {
    // setIsLoading(true);
    let token = '';
    let agentId = -1;
    if (userState?.userType === UserType.AGENT) {
      token = userState?.agent?.token;
    } else {
      token = userState?.buyer?.token;
    }
    try {
      if (!buyerId) {
        // setIsLoading(false);
        return;
      }
      if (!token) {
        // setIsLoading(false);
        return;
      }

      if (userState?.userType === UserType.AGENT) {
        agentId = userState?.agent?.agentId;
        setAgentIdForLinkedBuyer(agentId);
        // setIsLoading(false);
        return;
      } else {
        setBuyerSideAgentName(userState?.buyer?.buyerAgentName);
        setAgentIdForLinkedBuyer(userState?.buyer?.buyerAgentId);
        // setIsLoading(false);
        return;
      }
    } catch (err) {
      // setIsLoading(false);
      return notification('something went wrong ', 'error');
    }
  };

  const getBuyerLinked = async (token: string, buyerId: number) => {
    try {
      setLinkedBuyer(userState?.buyer?.isLinked);
      return userState?.buyer?.isLinked;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  const cancelTour = () => {
    // eslint-disable-next-line no-lone-blocks
    let buyerId = -1;
    if (userState?.userType === UserType?.AGENT) {
      buyerId = agentState?.buyerId;
      history.push('/dashboard?buyerId=' + buyerId);
    } else {
      buyerId = userState?.buyer?.buyerId;
      history.push('/buy?buyerId=' + buyerId);
    }
  };

  const addToLinkedProp = async () => {

    // here we add linked property api
    if (!userState.isLoggedIn) {
      notification(
        'you need to login before you can perform this action.',
        'error'
      );
      return;
    }
    if (formData?.startAdd === undefined || formData?.endingAdd === undefined) {
      notification(
        'Please enter form details!',
        'warning'
      );
      return;
    }

    // setToggle(false)
    // if (setCount) {
    //   setCount((count: any) => count + 1)
    // }

    // let take token and buyerid and propertyid
    // property id is already define in property card
    // setIsLoading(true)

    let token = '';
    let buyerId = -1;
    let agentId = -1;

    const addLinked: any = propDataDetails?.[0];

    switch (userState?.userType) {
      case UserType.BUYER:
        buyerId = userState?.buyer?.buyerId;
        agentId = userState.buyer.buyerAgentId;
        token = userState?.buyer?.token;
        break;

      case UserType.AGENT:
        buyerId = agentState?.buyerId;
        agentId = userState?.agent?.agentId;
        token = userState?.agent?.token;
        break;
      default:
        break;
    }

    const addLinkedProp: any = {
      propertyType: propDataDetails?.[0]?.property?.type,
      agentId: agentId,
      latitude: propDataDetails?.[0]?.geo?.lat,
      longitude: propDataDetails?.[0]?.geo?.lng,
      country: propDataDetails?.[0]?.address?.country,
      imageUrl: propDataDetails?.[0]?.photos?.[0],
      listPrice: propDataDetails?.[0]?.listPrice,
      streetNumber: propDataDetails?.[0]?.address?.streetNumber,
      streetName: propDataDetails?.[0]?.address?.streetName,
      city: propDataDetails?.[0]?.address?.city,
      state: propDataDetails?.[0]?.address?.state,
      postalCode: propDataDetails?.[0]?.address?.postalCode,
      bedrooms: propDataDetails?.[0]?.property?.bedrooms,
      bathrooms: propDataDetails?.[0]?.property?.bathsFull,
      area: 0,
      bathsHalf: propDataDetails?.[0]?.property?.bathsHalf,
      propertyCategory: propDataDetails?.[0]?.property?.type,
      propertyDetails: JSON.stringify(propDataDetails?.[0]),
      openHouseStartTime: new Date(),
      openHouseEndTime: new Date(),
      openHouseId: '',
      openHouseKey: '',
      createdAt: new Date(),
      updatedAt: new Date(),
      isFavorite: false,
      isRequested: false,
      isListed: true,
      listingAgentId: 0,
    };

    if (addLinked?.bedrooms) {
      await addLinkedPropertyForBuyer(propId, buyerId, token, addLinked);
      addLinked['propertyId'] = propId;
    } else {
      await addLinkedPropertyForBuyer(propId, buyerId, token, addLinkedProp);
      addLinkedProp['propertyId'] = propId;
    }

    let newObj1: any = propDataDetails?.[0];

    newObj1['propertyId'] = propId;

    let arr1: any = propDataDetails?.[0]?.propertyDetails
      ? [propDataDetails?.[1]]
      : [propDataDetails?.[0]];
    setLinkedPropertiesData([...linkedPropertiesData, ...arr1]);
    setFilteredLinkedProperties([...filteredLinkedProperties, ...arr1]);

    setBestRoute([]);

    setRunMap(false);

    setShowMap(false);
    setselected('prop');
    setIsUnlinkedPropertyClicked(true);
    setScheduleStatus(true);

    let arr: any = [];
    let newObj: any;
    if (linkedProperties && linkedProperties.length > 0) {
      newObj = {
        ...newObj1,
        ...{
          openHouseStartTime: new Date(),
          openHouseEndTime: new Date(),
          isListed: true,
          listingAgentId: 1000,
        },
      };
      // arr = [...linkedProperties, listing];
      arr = [...linkedProperties, newObj];
    } else {
      let newObj = {
        ...newObj1,
        ...{
          openHouseStartTime: new Date(),
          openHouseEndTime: new Date(),
          isListed: false,
          listingAgentId: 100,
        },
      };
      // arr = [{...listing,...{'openHouseStartTime':openHouseStartTime,'openHouseEndTime':openHouseEndTime}}];
      arr = [newObj];
    }

    // setIsLoading(false);
    setLinkedProperties(arr);
    setGetDirectionsPropertyClicked('NO');
    // set state false to show the Property card body
    setTourPropPage(false);
    setUnlinkPropHeader(true);
    // setIsLoading(false)
    if (param.get('statusType') === 'afterRouteCreated') {
      setHandleTogglingClicked('Clicked');
    }



  };

  const backToMapHandler = () => {
    // setGetDirectionsPropertyClicked('NO');
    setTourPropPage(false);
    setShowMap(true);
    // setRunMap(false);
    // setUseManual(false);
    // setIsAllAccepted(false)
  };

  const removeToLinkedProp = async () => {
    if (!userState.isLoggedIn) {
      notification(
        'you need to login before you can perform this action.',
        'error'
      );
      return;
    }
    // setToggle(false)
    // if (setCount) {
    //   setCount((count: any) => count + 1)
    // }

    // let take token and buyerid and propertyid
    // property id is already define in property card

    let token = '';
    let buyerId = -1;
    let agentId = -1;

    switch (userState?.userType) {
      case UserType.BUYER:
        buyerId = userState?.buyer?.buyerId;
        agentId = userState.buyer.buyerAgentId;
        token = userState?.buyer?.token;
        break;

      case UserType.AGENT:
        buyerId = agentState?.buyerId;
        agentId = userState?.agent?.agentId;
        token = userState?.agent?.token;
        break;
      default:
        break;
    }

    await removeLinkedPropertyForBuyer(propId, buyerId, token);

    const arr = [...linkedProperties];

    let arr2 = [...linkedPropertiesData];

    let arr3 = [...filteredLinkedProperties];

    const removeIndex3 = arr3?.findIndex(
      (item) => (item?.propertyId || item?.mlsId) === propId
    );

    if (removeIndex3 > -1) {
      arr3.splice(removeIndex3, 1);
      setFilteredLinkedProperties(arr3);
    }

    const removeIndex2 = arr2?.findIndex(
      (item) => (item?.propertyId || item?.mlsId) === propId
    );

    if (removeIndex2 > -1) {
      arr2.splice(removeIndex2, 1);
      setLinkedPropertiesData(arr2);
    }

    const removeIndex = arr.findIndex((item) => item.mlsId === propId);

    if (removeIndex > -1) {
      arr.splice(removeIndex, 1);
      setLinkedProperties(arr);
    }

    // set state false to show the Property card body
    setTourPropPage(false);
    setUnlinkPropHeader(true);
    setGetDirectionsPropertyClicked('NO');
    setBestRoute([]);

    setRunMap(false);

    setShowMap(false);
    setselected('prop');
    setIsUnlinkedPropertyClicked(true);
    setScheduleStatus(true);
    if (param.get('statusType') === 'afterRouteCreated') {
      setHandleTogglingClicked('Clicked');
    }
  };

  const handleExplore = () => {
    if (getDirectionsPropertyClicked !== 'NO') {
      setisScheduledRouteUpdated(true);
    }
    setTourPropPage(false);
    setRunMap(false);
    setUnlinkPropHeader(true);
    //###########
    setShowMap(false);
    setselected('prop');
    setIsUnlinkedPropertyClicked(true);
    setRunMap(false);
    setScheduleStatus(true);
    setBestRoute([]);
    if (param.get('statusType') === 'afterRouteCreated') {
      setHandleTogglingClicked('Clicked');
    }
  };

  useEffect(() => {
    let buyerIdForProp = -1;
    if (userState?.userType === UserType.AGENT) {
      buyerIdForProp = agentState?.buyerId;
    } else {
      buyerIdForProp = userState?.buyer?.buyerId;
    }
    getAgentIdFromBuyer(buyerIdForProp);

    if (userState?.userType === UserType.BUYER) {
      getBuyerLinked(userState?.buyer?.token, userState?.buyer?.buyerId);
    }
  }, []);

  useEffect(() => {
    if (saveTourClicked === 'Save Tour') {
      checkFormData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveTourClicked]);
  return (
    <Fragment>
      {/* {isLoading && <CustomSpinner />} */}
      <CustomRow>
        {/* {/ this column is used for name /} */}
        <Col
          xs={{ span: 24 }}
          sm={{ span: 10 }}
          md={{ span: 12 }}
          lg={{ span: 6 }}
        >
          {tourPropPage && (
            <h1>
              {userState?.userType === UserType.AGENT
                ? agentState?.buyerName
                : userState?.buyer?.name}
              -Property Details
            </h1>
          )}

          {!tourPropPage && !unlinkPropHeader && (
            <h1>
              {userState?.userType === UserType.AGENT
                ? agentState?.buyerName
                : userState?.buyer?.name}
              -Tours
            </h1>
          )}
          {unlinkPropHeader && (
            <h1>
              {userState?.userType === UserType?.AGENT
                ? agentState?.buyerName
                : userState?.buyer?.name}
              - Edit Tours
            </h1>
          )}
        </Col>

        {!tourPropPage && (
          <div className="new_tour_details_searchfield">
            <SearchField
              placeholder="Enter neighbourhood,ZIP code "
              searchAction={reqSearchAction}
              // onChange={() => { }}
              // defaultValue={reqSearchAction}
              className="newtour_details_search field"
            />
          </div>
        )}

        {/* {/ this is for search bar   /} */}
        {/* {/ <div className="search_and_button_field_new_tour_details"> /} */}

        {/* {/ this is for properties added and removed /} */}

        {/* <p>{count}/6 properties</p> */}
        {/*this is for button  */}
        {!unlinkPropHeader && !tourPropPage && (
          <div className="new_tour_details_route_button">
            <Button
              type={ButtonType.PRIMARY}
              text={'ROUTE TOUR'}
              // className="show-invite-agent-modal-button"
              clickHandler={checkFormData}
            // submitForm
            />
          </div>
        )}

        {unlinkPropHeader && !tourPropPage ? (
          <div
            className="new_tour_details_route_button"
          // style={{ marginRight: '-20rem' }}
          >
            <Button
              type={ButtonType.WHITE}
              text={'CANCEL'}
              // className="show-invite-agent-modal-button"
              clickHandler={cancelTour}
            // submitForm
            />
          </div>
        ) : (
          <>
            {tourPropPage && !isNotEditable && (
              <div className="new_tour_details_route_button" style={{}}>
                <CustomButton
                  type={ButtonType.WHITE}
                  text={'EXPLORE MORE'}
                  // className="show-invite-agent-modal-button"
                  clickHandler={handleExplore}
                // submitForm
                />
              </div>
            )}
          </>
        )}

        {unlinkPropHeader && !tourPropPage ? (
          <CustomDiv className="new_tour_details_route_button">
            <Button
              type={ButtonType.PRIMARY}
              text={'SAVE'}
              // className="show-invite-agent-modal-button"
              clickHandler={checkFormData}
            // submitForm
            />
          </CustomDiv>
        ) : (
          <>
            {tourPropPage &&
              (showAddPropertyBtn || getDirectionsPropertyClicked !== 'NO' ? (
                <>
                  {getDirectionsPropertyClicked === 'Scheduled' ? (
                    <div className="new_tour_details_addProperty_button">
                      <CustomButton
                        type={ButtonType.PRIMARY}
                        text={'BACK'}
                        clickHandler={backToMapHandler}
                      // submitForm
                      />
                    </div>
                  ) : (
                    <>
                      {getDirectionsPropertyClicked === 'YES' ? (
                        <>
                          <div className="new_tour_details_addProperty_button">
                            <CustomButton
                              type={ButtonType.PRIMARY}
                              text={'REMOVE PROPERTY'}
                              clickHandler={removeToLinkedProp}
                            // submitForm
                            />
                          </div>
                          <div className="new_tour_details_addProperty_button">
                            <CustomButton
                              type={ButtonType.PRIMARY}
                              text={'BACK'}
                              clickHandler={backToMapHandler}
                            // submitForm
                            />
                          </div>
                        </>
                      ) : (
                        <div className="new_tour_details_addProperty_button">
                          <CustomButton
                            type={ButtonType.PRIMARY}
                            text={'REMOVE PROPERTY'}
                            clickHandler={removeToLinkedProp}
                          // submitForm
                          />
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <div className="new_tour_details_addProperty_button">
                  <CustomButton
                    type={ButtonType.PRIMARY}
                    text={'ADD PROPERTY'}
                    clickHandler={addToLinkedProp}
                  // submitForm
                  />
                </div>
              ))}
          </>
        )}
      </CustomRow>
    </Fragment>
  );
};
export default PropertyTourHeader;
