import React from "react";

import HomepageAgentPage from "../pages/HomepageAgentPage";

const ShowingsAgentPage = () => {
  return (
    <>
      <HomepageAgentPage isShowings={false} isListings={true} />
    </>
  );
};
export default ShowingsAgentPage;
