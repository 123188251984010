import { useState } from "react";
import NotificationGroup from "../components/NotificationGroup";
import NotificationBuyerInvite from "../components/NotificationBuyerInvite";
import ToggleSwitch from "../components/ToggleSwitch";
import NotificationAgentInvite from "../components/NotificationAgentInvite";

const ScratchPage: React.FC = () => {
  const [isOn, setIsOn] = useState(false);
  

  const doNothing = () => {

  }

  return (
    <div className="scratch-page">
      <ToggleSwitch 
        isOn={isOn}
        setIsOn={setIsOn}
      />
      <div className="scratch-page__grid">
        <NotificationGroup 
          text="You have 5 notifications from buyer, Cathy Griffins."
          timestamp="24 min"
          buttonHandler={doNothing}
        />
        <NotificationBuyerInvite 
          text="You have a Sale Agent invite from buyer, Dennis Feinstein."
          timestamp="9hrs"
          buyerId={1}
        />
        <NotificationAgentInvite 
          text="Agent John Doe of Doe Real Estate has sent you a request to connect with you. Please accept the request if you wish to coordinate calendars, schedule tours with John Doe."
          agentId={1}
          agentName="John Doe"
          agentEmail="johndoe@doer.com"
          agentPhone="+1 (206) 123 4567"
          agentWebsite="www.doer.com"
        />
      </div>
    </div>
  );
};

export default ScratchPage;