import { useContext, useEffect, useState } from "react";
import UserContext from "../contexts/UserContext";
import {
  acceptAgentRequestByBuyerServices,
  getAgentInfoService,
  getBuyerNotificationsByEmail,
  getBuyerNotificationsById,
  inviteAgentByBuyer,
  linkSellerWithAgent,
  postMessageToUser,
  updateBuyerNotification,
  updateSellerDetails,
} from "../services/NavihomeService";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { notification } from "../components/notification";
import NotificationsBuyerCard from "../components/notificationBuyerPage/notificationBuyerCard";
import CustomSpinner from "../components/spinner/CustomSpinner";
import { useGlobal } from "reactn";
import EmptyDashboardTab from "../components/EmptyDashboardTab/emptyDashboardTab";
import PropertyFiltersContext from "../contexts/PropertyFiltersContext";
TimeAgo.addDefaultLocale(en);

const NotificationsBuyerPage: React.FC = () => {
  const { userState, logIn } = useContext(UserContext);
  const [emailNotifications, setEmailNotifications] = useState<Array<any>>([]);
  const [idNotifications, setIdNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [buyerMail, setBuyerMail] = useState("");
  const timeAgo = new TimeAgo("en-US");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [globalStateBuyerNotification, setGlobalStateBuyerNotification] =
    useGlobal<any>("notificationBadgeBuyer"); // BUYER NOTIFICATIONS

  const {
    setCreateNotificationsInfo,
    setGetNotificationsUserId,
    propertyFiltersState,
  } = useContext(PropertyFiltersContext);
  const { receiveNotificationsInfo } = propertyFiltersState;

  const getBuyerInfo = async () => {
    setBuyerMail(userState?.buyer?.Email);
  };

  const getMailNotifications = async (email: string) => {
    setIsLoading(true);
    try {
      const notifications: any = await getBuyerNotificationsByEmail(
        userState?.buyer?.Email,
        userState.buyer.token
      );

      let filteredNotifications = [];

      if (notifications?.length > 0) {
        filteredNotifications = notifications?.filter(
          (item: any) => !item?.isRead && !item?.isRejected
        );
      }
      setEmailNotifications(filteredNotifications);
      setIsLoading(false);
      return filteredNotifications;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getIdNotifications = async () => {
    setIsLoading(true);
    try {
      const notifications: any = await getBuyerNotificationsById(
        userState.buyer.buyerId,
        userState.buyer.token
      );
      const filteredNotifications =
        notifications.length > 0 &&
        notifications.filter((item: any) => !item.isRejected);
      setIdNotifications(filteredNotifications);

      setIsLoading(false);
      return filteredNotifications;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  let sendNotificationToAgent = async (agentId: number, type: string) => {
    setIsLoading(true);
    let getType = type;
    let messageType = type;
    if (type === "seller") {
      getType = "removed";
      messageType = "accepted";
    }
    try {
      await inviteAgentByBuyer(
        agentId,
        getType,
        `Your Buyer ${userState?.buyer?.name} ${messageType} your invitation`,
        0,
        "",
        "",
        userState?.buyer?.buyerId
      );
      setCreateNotificationsInfo(String(Math.floor(Math.random() * 100 + 1)));
      setGetNotificationsUserId(agentId);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  let getNotifications = async () => {
    let idNotfArray: any = [];
    await getMailNotifications(buyerMail).then(async (mailNotf) => {
      await getIdNotifications().then((idNotf) => {
        if (idNotf?.length > 0) {
          setGlobalStateBuyerNotification(
            mailNotf?.length +
              idNotf?.filter((item: any) => !item.isRead)?.length
          );
        } else {
          setGlobalStateBuyerNotification(mailNotf?.length);
        }
        const fitlerMailNotf = mailNotf?.filter(
          (item: any) => item?.type?.toLocaleLowerCase() !== "invite"
        );
        idNotfArray = [...idNotf, ...fitlerMailNotf];
      });
    });
    return [idNotfArray];
  };

  let connectBuyer = async (
    agentId: number,
    notificationId: number,
    propertyId: number
  ) => {
    try {
      // get details of agent using agentId

      const agentInfo = await getAgentInfoService(
        userState?.buyer?.token,
        agentId
      );

      await updateBuyerNotification(
        notificationId,
        "accept",
        userState?.buyer?.token
      );
      await acceptAgentRequestByBuyerServices(
        agentId,
        userState?.buyer?.buyerId
      );
      await sendNotificationToAgent(agentId, "accepted");
      sendMessageToAgentPhone(
        agentInfo?.phone,
        `Your Agent ${userState?.buyer?.name} accepted your invitation.`
      );
      logIn(userState?.buyer?.Email, userState?.buyer?.Password);
      setIsLoading(false);
    } catch (error) {
      console.log("ERROR: ", error);
      setIsLoading(false);
    }
  };

  let connectSeller = async (
    agentId: number,
    notificationId: number,
    propertyId: number
  ) => {
    try {
      const agentInfo = await getAgentInfoService(
        userState?.buyer?.token,
        agentId
      );

      await updateBuyerNotification(
        notificationId,
        "accept",
        userState?.buyer?.token
      );
      let payload = {
        name: userState?.buyer?.name,
        sellerEmail: userState?.buyer?.Email,
        message: "Seller Accepted the request",
        sellerPhone: userState?.buyer?.phone,
      };
      await linkSellerWithAgent(userState?.buyer?.buyerId, agentId, propertyId);
      await updateSellerDetails(propertyId, payload, userState?.buyer?.token);
      await sendNotificationToAgent(agentId, "seller");
      sendMessageToAgentPhone(
        agentInfo?.phone,
        `Your Agent ${userState?.buyer?.name} accepted your invitation.`
      );
      logIn(userState?.buyer?.Email, userState?.buyer?.Password);
      setIsLoading(false);
    } catch (error) {
      console.log("ERROR: ", error);
      setIsLoading(false);
    }
  };

  const sendMessageToAgentPhone = async (phoneNo: string, message: string) => {
    try {
      postMessageToUser(phoneNo, message);
    } catch (error) {
      console.error("error", error);
    }
  };

  const didAccept = async (
    notificationId: number,
    agentId: number,
    consumerType: string,
    propertyId: number
  ) => {
    if (
      userState?.buyer?.buyerAgentName?.length > 0 &&
      consumerType?.toLowerCase() === "buyer"
    ) {
      return notification(
        `You'r already connected with agent ${
          userState?.buyer?.buyerAgentName?.toUpperCase()?.split(" ")?.[0]
        }`,
        "error"
      );
    }
    if (
      userState?.buyer?.sellerAgentName?.length > 0 &&
      consumerType?.toLowerCase() === "seller"
    ) {
      return notification(
        `You'r already connected with agent ${
          userState?.buyer?.sellerAgentName?.toUpperCase()?.split(" ")?.[0]
        }`,
        "error"
      );
    }
    setIsLoading(true);
    try {
      if (consumerType?.toLowerCase() === "buyer") {
        await connectBuyer(agentId, notificationId, propertyId);
      } else {
        await connectSeller(agentId, notificationId, propertyId);
      }
    } catch (error) {
      console.log("ERROR: ", error);
      setIsLoading(false);
    }
  };

  const didReject = async (notificationId: number, agentId: number) => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      await updateBuyerNotification(
        notificationId,
        "reject",
        userState?.buyer?.token
      );
      sendNotificationToAgent(agentId, "rejected");

      getNotifications();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const updateNotification = async (notificationId: number) => {
    try {
      await updateBuyerNotification(
        notificationId,
        "read",
        userState?.buyer?.token
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (buyerMail) {
      getNotifications().then((value) => {
        if (value?.[0]?.length > 0) {
          setTimeout(async () => {
            await Promise.all(
              value?.[0]?.map(async (notf: any) => {
                await updateNotification(notf?.id);
              })
            );
            getNotifications();
          }, 10000);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerMail]);

  useEffect(() => {
    getBuyerInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getNotifications();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiveNotificationsInfo]);

  let getBorderColor = (type: string) => {
    switch (type) {
      case "invite":
        return "#004876";
      case "requested":
        return "#505050";
      case "liked" || "Favorite":
        return "#FFC056";
      case "accept" || "accepted":
        return "#2993E1";
      case "reject" || "rejected":
        return "#465E14";
      case "removed":
        return "#505050";
      case "Comment":
        return "#2993E1";
    }
  };
  // const Comined = emailNotifications.concat(idNotifications);
  // const message = Object.values(Comined).find(
  //   (message: string) => (message = message)
  // );

  return (
    <div className="notifications-buyer-page">
      <div className="notifications-buyer-page__container">
        <div className="notifications-buyer-page__heading">
          <h3>Notifications</h3>
        </div>

        {isLoading ? (
          <div>
            <CustomSpinner />
          </div>
        ) : (
          <div>
            {(emailNotifications?.length > 0 || idNotifications?.length > 0) ? (
                <div>
                 
                {emailNotifications?.length > 0 &&
                  emailNotifications
                    ?.sort((a: any, b: any) => {
                      return +new Date(b?.createdAt) - +new Date(a?.createdAt);
                    })
                    ?.map((item: any) => {
                      return (
                        <NotificationsBuyerCard
                          agentId={item?.agentId}
                          buyerId={item?.buyerId}
                          isRead={item?.isRead}
                          notificationId={item?.id}
                          borderColor={getBorderColor(item?.type)}
                          notification={item?.message}
                          time={timeAgo.format(new Date(item?.createdAt))}
                          isAcceptAndRejectButton={item?.type === "invite"}
                          isNotificationButton={item?.isNotificationButton}
                          borderButtomColor={item?.borderButtonColor}
                          didAccept={didAccept}
                          didReject={didReject}
                          consumerType={item?.consumerType}
                          propertyId={item?.propertyId}
                          type={item?.type}
                        />
                      );
                    })}
                {idNotifications?.length > 0 &&
                  idNotifications
                    .sort((a: any, b: any) => {
                      return +new Date(b?.createdAt) - +new Date(a?.createdAt);
                    })
                    .map((item: any) => {
                      return (
                        <NotificationsBuyerCard
                          agentId={item?.agentId}
                          buyerId={item?.buyerId}
                          isRead={item?.isRead}
                          notificationId={item?.id}
                          borderColor={getBorderColor(item?.type)}
                          notification={item?.message}
                          time={timeAgo.format(new Date(item?.createdAt))}
                          isAcceptAndRejectButton={item?.type === "invite"}
                          isNotificationButton={item?.isNotificationButton}
                          borderButtomColor={item?.borderButtonColor}
                          didAccept={didAccept}
                          didReject={didReject}
                          consumerType={item?.consumerType}
                          propertyId={item?.propertyId}
                          type={item?.type}
                        />
                      );
                    })}
              </div>
            ) : (
              <div>
                <EmptyDashboardTab title="No Notifications" />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationsBuyerPage;
