import { UserOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Avatar, Row } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";
import Phone from "../../assets/find-an-agent-page/Phone icon.svg";
import Email from "../../assets/find-an-agent-page/Icon - Email.svg";

interface BuyerDetailsPropsModel {
  name?: string;
  email?: string;
  phone?: string;
  imageUrl?: string;
}

const CustomDiv = styled.div`
  display: flex;
  align-items: center;
`;

const CutomWebDetailsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items:center;
  margin-bottom: 5px;
`;

const CustomH4 = styled.h4`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
`;

const BuyerDetails: React.FC<BuyerDetailsPropsModel> = ({ name, email, phone, imageUrl }) => {
  const isXS = useMediaQuery({ maxWidth: 576 });
  return (
    <>
      <CustomDiv className="">
        <Avatar size={isXS ? 96 : 96} icon={imageUrl != undefined ? <img src={imageUrl} alt=".." /> : <UserOutlined />} />
        <div style={{ marginLeft: "12px" }}>
          <CustomH4>{name}</CustomH4>
          <CutomWebDetailsDiv>
            <img src={Phone} alt="..." />
            <p style={{margin: '0px 0px 0px 20px'}}>{'+'}{phone}</p>
          </CutomWebDetailsDiv>
          <CutomWebDetailsDiv>
            <img src={Email} alt="..." />
            <p style={{margin: '0px 0px 0px 20px'}}>{email}</p>
          </CutomWebDetailsDiv>
        </div>
      </CustomDiv>
    </>
  );
};

export default BuyerDetails;
