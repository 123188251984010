import styled from "@emotion/styled";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SellerHeader from "../components/notificationAgentPage/SellerHeader";
import DashboardBuyerSellPage from "../components/SellBuyerPage/SellDashboardPage";
import UserContext, { UserType } from "../contexts/UserContext";
const ShellBuyerPage = () => {
  const CustomBackP = styled.p`
    display: inline-block;
    margin-left: 12px;
    margin-top: 16px;
    cursor: pointer;
    font-size: 17px;
    &:hover {
      text-decoration: underline;
      font-weight: 600;
    }
  `;

  const [linkedAgentInfo, setLinkedAgentInfo] = useState<any>({
    email: '',
    name: '',
    phone: '',
    avatarUrl: '',
    website: '',
    CompanyLogo: '',
  });

  useEffect(() => {
    getBuyerInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getBuyerInfo = async () => {
    setLinkedAgentInfo({
      email: userState.buyer.sellerAgentEmail,
      name: userState?.buyer?.sellerAgentName,
      phone: userState?.buyer?.sellerAgentPhone,
      avatarUrl: userState?.buyer?.sellerAgentAvatarUrl,
      website: userState?.buyer?.sellerAgentWebsite,
      CompanyLogo: userState?.buyer?.sellerAgentCompanyLogo,
    });
  };

  const history = useHistory();
  const { userState } = useContext(UserContext);


  return (
    <>
      {userState?.userType === UserType?.BUYER && userState?.buyer?.isLinked && userState?.buyer?.sellerAgentName ? (
        <SellerHeader sellerDetail={linkedAgentInfo} agentSubscription_type={userState?.buyer?.sellerAgentSubType} />
      ) : (
        <></>
      )}
      {userState?.buyer?.isLinked ? (
        <CustomBackP
          onClick={() => {
            history.push("/buy");
          }}
        >
          Back
        </CustomBackP>
      ) : (
        <></>
      )}

      <DashboardBuyerSellPage isBuy={false} isSell={true} />
    </>
  );
};

export default ShellBuyerPage;
