/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getAllViewPropertyByAgetntId } from "../components/AgentCalendar/ulits";
import Homepage from "../components/agentHomePage/homepage";
import Listings from "../components/agentHomePage/listings";
import Showings from "../components/agentHomePage/showings";
import CustomSpinner from "../components/spinner/CustomSpinner";
import UserContext from "../contexts/UserContext";
import FreeTrialModal from "../modals/FreeTrialModal";
import { getALLSellerListedProperties, getAllUpcomingTourDetailsAgentSide } from "../services/NavihomeService";

interface HomepageSaleAgentPagePropsModel {
  isShowings: boolean;
  isListings: boolean;
}

const HomepageSaleAgentPage: React.FC<HomepageSaleAgentPagePropsModel> = ({ isShowings, isListings }) => {
  const [isShowing, setIsShowing] = useState(true);
  const [isListing, setIsListing] = useState(true);
  const { userState } = useContext(UserContext);
  const [isPlanActive, setIsPlanActive] = useState<boolean>(false);
  const [isPlanExpire, setIsPlanExpire] = useState<boolean>(false);
  const [remainingDays, setRemainingDays] = useState<number>(-1);
  const [totalListedProperty, setTotalListedProperty] = useState([]);
  const [agentUpcomingTour, setAgentUpcomingTour] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const closeFreeTrialAgentModal = async () => {
    setIsPlanActive(!isPlanActive);
  };

  async function getAllListedProperty() {
    // setIsLoading(true);

    try {
      if (history?.location?.pathname === "/" || history?.location?.pathname === "/listings") {
        const response: any = await getALLSellerListedProperties(userState?.agent?.agentId, userState?.agent?.token);
        if (response && response?.length > 0) {
          var listings = [];
          for (var i = 0; i < response?.length; i++) {
            if (response && response?.[i]?.propertyDetails) {
              let property = JSON.parse(response?.[i]?.propertyDetails);
              listings?.push(property);
            }
          }
          setTotalListedProperty(listings);
          // setIsLoading(false);
        } else {
          // setIsLoading(false);
        }
      } else {
        // setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      // setIsLoading(false);
    }
  }

  const getAgentUpcomingTour = async () => {
    try {
      setIsLoading(true);
      let zones = moment.tz.guess();

      if (history?.location?.pathname === "/") {
        let upcomingTourHome: any = [];
        const response: any = await getAllUpcomingTourDetailsAgentSide(userState?.agent?.agentId, userState?.agent?.token);

        if (response) {
          for (let i = 0; i < response?.length; i++) {
            let currHourTime = response[i]?.time?.split("-")?.[0]?.split(" ")?.[1];
            let acceptedPropertyCount = 0;

            if (response[i]?.bestRouteProperties?.length > 0) {
              response?.[i]?.bestRouteProperties?.forEach((element: any) => {
                if (element?.isAccepted) {
                  acceptedPropertyCount++;
                }
              });
            }
            let statusPrimary = `${acceptedPropertyCount}/${response[i]?.selectedProperties} ${response[i]?.status}` || "N/A";
            let statusSecondary = response[i]?.role === "Agent" ? `Agent ${response[i]?.status}` : `Buyer ${response[i]?.status} OH`;
            const dataobj = {
              date: moment.tz(response[i]?.dateTime, zones).utc().format("LL") || "N/A",
              dayOfWeek: moment.tz(response[i]?.dateTime, zones).utc().format("dddd") || "N/A",
              time: response[i]?.time || "N/A",
              timeOfDay: currHourTime ? (currHourTime === "am" ? "Morning" : "Evening") : "N/A",
              client: response[i]?.buyer?.name || "N/A",
              properties: response[i]?.selectedProperties || "N/A",
              statusPrimary: response[i]?.status === "Scheduled" ? statusPrimary : "Saved",
              statusSecondary: statusSecondary,
              formId: response[i]?.formId,
              responseData: response[i],
              type: "Tour"
            };

            if (response[i]?.status === "Scheduled") {
              upcomingTourHome.push(dataobj);
            }
            //   we have to create our on arr
          }
        }

        const newArr: any = await getAllViewPropertyByAgetntId(userState?.agent?.agentId, userState?.agent?.token, "showing", userState);

        if (newArr?.length > 0) {
          for (let i = 0; i < newArr?.length; i++) {
            let date: any, dayOfWeek: string, time: string, timeOfDay: string, statusPrimary: string, propCalCurrZone: any, client: string, saleAgent: string, responseData: any;

            propCalCurrZone = newArr[i]?.Stime?.split(" ")?.[1] ?? "N/A";
            // let correctDate = moment.tz(newArr[i]?.Sdate, zones).utc();
            const stDay: number = new Date(newArr[i]?.Sdate).getUTCDate();
            const stMonth: number = dayjs(newArr[i]?.Sdate).month();
            const stYear: number = dayjs(newArr[i]?.Sdate).year();

            const tempDate: string = `${stMonth + 1}-${stDay}-${stYear}`;

            date = moment(tempDate, "MM-DD-YYYY").format("LL");
            dayOfWeek = moment(tempDate, "MM-DD-YYYY").format("dddd");
            // dayOfWeek = moment.tz(newArr[i]?.Sdate, zones).utc().format("dddd");
            time = `${moment(newArr[i]?.Stime, "HH:mm").format("hh:mm a")} - ${moment(newArr[i]?.Etime, "HH:mm").format("hh:mm a")}`;
            timeOfDay = propCalCurrZone?.toLowerCase() === "am" ? "Morning" : "Evening";
            client = newArr?.[i]?.client?.length > 0 ? newArr?.[i]?.client : "N/A";
            saleAgent = newArr?.[i]?.buyerAgentName?.length > 0 ? newArr?.[i]?.buyerAgentName : "N/A";
            statusPrimary = newArr[i]?.isAccepted ? "Accepted" : "Requested";
            responseData = newArr[i];
            const newDataObj = {
              date: date,
              dayOfWeek: dayOfWeek,
              time: time,
              timeOfDay: timeOfDay,
              client: client,
              saleAgent: saleAgent,
              statusPrimary: statusPrimary,
              responseData: responseData,
              type: "Showing"
            };
            upcomingTourHome.push(newDataObj);
          }

          setIsLoading(false);
        }

        const viewProp: any = await getAllViewPropertyByAgetntId(userState?.agent?.agentId, userState?.agent?.token, "upcoming", userState);

        if (viewProp?.length > 0) {
          for (let i = 0; i < viewProp?.length; i++) {
            let date: any, dayOfWeek: string, time: string, timeOfDay: string, statusPrimary: string, propCalCurrZone: any, client: string, saleAgent: string, responseData: any;

            propCalCurrZone = viewProp[i]?.Stime?.split(" ")?.[1] ?? "N/A";
            // let correctDate = moment.tz(viewProp[i]?.Sdate, zones).utc();
            const stDay: number = new Date(viewProp[i]?.Sdate).getUTCDate();
            const stMonth: number = dayjs(viewProp[i]?.Sdate).month();
            const stYear: number = dayjs(viewProp[i]?.Sdate).year();

            const tempDate: string = `${stMonth + 1}-${stDay}-${stYear}`;

            date = moment(tempDate, "MM-DD-YYYY").format("LL");
            dayOfWeek = moment(tempDate, "MM-DD-YYYY").format("dddd");
            // dayOfWeek = moment(viewProp[i]?.Sdate, zones).utc().format("dddd");
            time = `${moment(viewProp[i]?.Stime, "HH:mm").format("hh:mm a")} - ${moment(viewProp[i]?.Etime, "HH:mm").format("hh:mm a")}`;
            timeOfDay = propCalCurrZone?.toLowerCase() === "am" ? "Morning" : "Evening";
            client = viewProp?.[i]?.client?.length > 0 ? viewProp?.[i]?.client : "N/A";
            saleAgent = viewProp?.[i]?.buyerAgentName?.length > 0 ? viewProp?.[i]?.buyerAgentName : "N/A";
            statusPrimary = viewProp[i]?.isAccepted ? "Accepted" : "Requested";
            responseData = viewProp[i];
            const newDataObj = {
              date: date,
              dayOfWeek: dayOfWeek,
              time: time,
              timeOfDay: timeOfDay,
              client: client,
              saleAgent: saleAgent,
              statusPrimary: statusPrimary,
              responseData: responseData,
              type: "Listing"
            };
            upcomingTourHome.push(newDataObj);
          }

          //   we have to create our on arr
        }
        upcomingTourHome?.sort((a: any, b: any) => {
          return +new Date(a?.date) - +new Date(b?.date);
        });
        setAgentUpcomingTour(upcomingTourHome);
        setIsLoading(false);
      } else if (history?.location?.pathname === "/listings") {
        const newArr: any = await getAllViewPropertyByAgetntId(userState?.agent?.agentId, userState?.agent?.token, "upcoming", userState);

        let upcomingTourListings = [];
        if (newArr?.length > 0) {
          for (let i = 0; i < newArr?.length; i++) {
            let date: any, dayOfWeek: string, time: string, timeOfDay: string, statusPrimary: string, propCalCurrZone: any, client: string, saleAgent: string, responseData: any;

            propCalCurrZone = newArr[i]?.Stime?.split(" ")?.[1] ?? "N/A";
            // let correctDate = moment.tz(newArr[i]?.Sdate, zones).utc();
            const stDay: number = new Date(newArr[i]?.Sdate).getUTCDate();
            const stMonth: number = dayjs(newArr[i]?.Sdate).month();
            const stYear: number = dayjs(newArr[i]?.Sdate).year();

            const tempDate: string = `${stMonth + 1}-${stDay}-${stYear}`;

            date = moment(tempDate, "MM-DD-YYYY").format("LL");
            dayOfWeek = moment(tempDate, "MM-DD-YYYY").format("dddd");
            time = `${moment(newArr[i]?.Stime, "HH:mm").format("hh:mm a")} - ${moment(newArr[i]?.Etime, "HH:mm").format("hh:mm a")}`;
            timeOfDay = propCalCurrZone?.toLowerCase() === "am" ? "Morning" : "Evening";
            client = newArr?.[i]?.client?.length > 0 ? newArr?.[i]?.client : "N/A";
            saleAgent = newArr?.[i]?.buyerAgentName?.length > 0 ? newArr?.[i]?.buyerAgentName : "N/A";
            statusPrimary = newArr[i]?.isAccepted ? "Accepted" : "Requested";
            responseData = newArr[i];
            const newDataObj = {
              date: date,
              dayOfWeek: dayOfWeek,
              time: time,
              timeOfDay: timeOfDay,
              client: client,
              saleAgent: saleAgent,
              statusPrimary: statusPrimary,
              responseData: responseData
            };
            upcomingTourListings.push(newDataObj);
          }

          //   we have to create our on arr
        }
        upcomingTourListings?.sort((a: any, b: any) => {
          return +new Date(a?.date) - +new Date(b?.date);
        });
        setAgentUpcomingTour(upcomingTourListings?.length > 0 ? upcomingTourListings : []);
        setIsLoading(false);
      } else if (history?.location?.pathname === "/showings") {

        const response: any = await getAllUpcomingTourDetailsAgentSide(userState?.agent?.agentId, userState?.agent?.token);
        let upcomingTourShowings = [];
        if (response) {
          for (let i = 0; i < response?.length; i++) {
            let currHourTime = response[i]?.time?.split("-")?.[0]?.split(" ")?.[1];
            let acceptedPropertyCount = 0;

            if (response[i]?.bestRouteProperties?.length > 0) {
              response?.[i]?.bestRouteProperties?.forEach((element: any) => {
                if (element?.isAccepted) {
                  acceptedPropertyCount++;
                }
              });
            }
            let statusPrimary = `${acceptedPropertyCount}/${response[i]?.selectedProperties} ${response[i]?.status}` || "N/A";
            let statusSecondary = response[i]?.role === "Agent" ? `Agent ${response[i]?.status}` : `Buyer ${response[i]?.status} OH`;
            const dataobj = {
              date: moment.tz(response[i]?.dateTime, zones).utc().format("LL") || "N/A",
              dayOfWeek: moment.tz(response[i]?.dateTime, zones).utc().format("dddd") || "N/A",
              time: response[i]?.time || "N/A",
              timeOfDay: currHourTime ? (currHourTime === "am" ? "Morning" : "Evening") : "N/A",
              client: response[i]?.buyer?.name || "N/A",
              properties: response[i]?.selectedProperties || "N/A",
              statusPrimary: response[i]?.status === "Scheduled" ? statusPrimary : "Saved",
              statusSecondary: statusSecondary,
              formId: response[i]?.formId,
              responseData: response[i],
              type: "Tour"
            };

            upcomingTourShowings.push(dataobj);
            //   we have to create our on arr
          }
        }

        const newArr: any = await getAllViewPropertyByAgetntId(userState?.agent?.agentId, userState?.agent?.token, "showing", userState);
        if (newArr?.length > 0) {
          for (let i = 0; i < newArr?.length; i++) {
            let date: any, dayOfWeek: string, time: string, timeOfDay: string, statusPrimary: string, propCalCurrZone: any, client: string, saleAgent: string, responseData: any;

            propCalCurrZone = newArr[i]?.Stime?.split(" ")?.[1] ?? "N/A";
            // let correctDate = moment.tz(newArr[i]?.Sdate, zones).utc();

            const stDay: number = new Date(newArr[i]?.Sdate).getUTCDate();
            const stMonth: number = dayjs(newArr[i]?.Sdate).month();
            const stYear: number = dayjs(newArr[i]?.Sdate).year();

            const tempDate: string = `${stMonth + 1}-${stDay}-${stYear}`;

            date = moment(tempDate, "MM-DD-YYYY").format("LL");
            dayOfWeek = moment(tempDate, "MM-DD-YYYY").format("dddd");
            time = `${moment(newArr[i]?.Stime, "HH:mm").format("hh:mm a")} - ${moment(newArr[i]?.Etime, "HH:mm").format("hh:mm a")}`;
            timeOfDay = propCalCurrZone?.toLowerCase() === "am" ? "Morning" : "Evening";
            client = newArr?.[i]?.client?.length > 0 ? newArr?.[i]?.client : "N/A";
            saleAgent = newArr?.[i]?.buyerAgentName?.length > 0 ? newArr?.[i]?.buyerAgentName : "N/A";
            statusPrimary = newArr[i]?.isAccepted ? "Accepted" : "Requested";
            responseData = newArr[i];
            const newDataObj = {
              date: date,
              dayOfWeek: dayOfWeek,
              time: time,
              timeOfDay: timeOfDay,
              client: client,
              saleAgent: saleAgent,
              statusPrimary: statusPrimary,
              type: "Showing",
              responseData: responseData
            };
            upcomingTourShowings.push(newDataObj);
          }

          setIsLoading(false);
        }

        const filteredAccCurrentDate: any = upcomingTourShowings?.sort((a: any, b: any) => {
          return +new Date(a?.date) - +new Date(b?.date);
        });
        setAgentUpcomingTour(filteredAccCurrentDate?.length > 0 ? filteredAccCurrentDate : []);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    getAllListedProperty();
    getAgentUpcomingTour();

    const currentMonth = new Date().getMonth();
    const registerMonth = new Date(userState?.agent?.registeredAt)?.getMonth();
    const currentDay = new Date()?.getDate();
    const registerDay = new Date(userState?.agent?.registeredAt)?.getDate();
    const from = new Date();
    const to = userState?.agent?.registeredAt?.toString()?.split("T")?.[0];
    const count = Math.abs(moment(from, "YYYY-MM-DD").startOf("day").diff(moment(to, "YYYY-MM-DD").startOf("day"), "days")) + 1;    

    if (count >= 24 && count <= 30) {
      setRemainingDays(30 - count);
      setIsPlanActive(true);
    }
    if (userState?.agent?.status === 'NOT COMPLETED' && count > 30) {
      setRemainingDays(count);
      setIsPlanActive(true);
      setIsPlanExpire(true);
    }
  }, []);

  useEffect(() => {
    setIsShowing(isShowings ? false : true);
    setIsListing(isListings ? false : true);
  }, [isShowings, isListings]);

  const renderRemider = () => {
    return <FreeTrialModal isShowing={isPlanActive} closeAction={closeFreeTrialAgentModal} remainingDays={remainingDays} isPlanExpire={isPlanExpire} />;
  };

  return (
    <>
      {isLoading && <CustomSpinner />}
      {isShowings === undefined && (
        <Homepage totalListedProperty={totalListedProperty} listingAgentUpcomingTour={agentUpcomingTour} agentUpcomingTour={agentUpcomingTour} getAllListedProperty={getAllListedProperty} />
      )}
      {isShowing && isShowings !== undefined && <Showings agentUpcomingTour={agentUpcomingTour} />}
      {isListing && isShowings !== undefined && <Listings totalListedProperty={totalListedProperty} agentUpcomingTour={agentUpcomingTour} getAllListedProperty={getAllListedProperty} />}
      {isPlanActive && renderRemider()}
    </>
  );
};

export default HomepageSaleAgentPage;
