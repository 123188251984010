/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import styled from "@emotion/styled";
import { Button, Checkbox, Col, Divider, Modal, Radio, Row, Switch, TimePicker } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import moment, { Moment } from "moment";
import React, { useContext, useEffect, useState } from "react";
import validator from "validator";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory, useLocation } from "react-router-dom";
import DeleteIllus from "../../assets/accountIllus/AccountDelete.svg";
import LogoutIllus from "../../assets/accountIllus/AccountLogout.svg";
import PauseIllus from "../../assets/accountIllus/AccountPause.svg";
import "../../assets/css/pages/account-settings-agent-page.css";
import { notification } from "../../components/notification";
import CustomSpinner from "../../components/spinner/CustomSpinner";
import PropertyFiltersContext from "../../contexts/PropertyFiltersContext";
import UserContext, { UserType } from "../../contexts/UserContext";
import { PauseAgentAccount, agentAccountSettings, createCronofyService, createUserEmailsService, getAgentAccountSettings, getAgentById, getCronofyReqTokenService, getUserEmailByIdService, pauseSubscriptionPaln, putAgentAccountSettings, resumeSubscriptionPaln, updatePrefferedContact } from "../../services/NavihomeService";
import AccountModal from "./AccountModal";
import GoogleEventComponent from "./GoogleEventComponent";
import { CustomAddCol } from "./AccountBuyerSettingPage";
import TextField, { TextFieldIconType, TextFieldType } from "../TextField";
import Buttons, { ButtonType } from "../Button";
const CustomDivider = styled(Divider)`
  background: #004876;
  margin: 18px 0px;
`;
const CustomSaveButton = styled(Button)`
  color: #fff;
  background: #004876;
  border-radius: 10px;
  padding: 0px 20px;
`;
const CustomActionButton = styled(Button)`
  border-radius: 10px;
  padding: 0px 20px;
`;
const CustomSwitch = styled(Switch)`
  margin-left: 10px;
  border: #c2d500;
`;
const CustomCheckbox = styled(Checkbox)`
  margin-top: 10px;
  .ant-checkbox-checked .ant-checkbox-inner {
    background: #c2d500;
    border: #c2d500;
  }
`;

export const CustomCheckboxGroups = styled(Checkbox.Group)`
  /* width: 100%; */
  display: flex;
  @media screen and (max-width: 320px) {
    flex-wrap: wrap;
  }

  .ant-checkbox-wrapper-checked {
    color: white;
    background-color: #4285f4;
    border: #4285f4;
    height: 24px;
    width: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .ant-checkbox-wrapper {
    height: 30px;
    width: 30px;

    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
`;
export const CustomCheckboxs = styled(Checkbox)`
  margin: 0 5px;
  border-radius: 50%;
  font-size: 15px;
  border: 1px solid #e9eaec;

  .ant-checkbox {
    display: none;
  }
  &.ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
    background: #c2d500;
    border: #c2d500;
  }
`;
const Accountsetting = styled.h1`
  font-size: 25px;
  font-weight: bolder;
`;
const Calendarsettings = styled.h1`
  font-size: 25px;
  font-weight: bolder;
  margin-bottom: 30px;
`;
const Notificationsetting = styled.h1`
  font-size: 25px;
  font-weight: bolder;
  margin-bottom: 30px;
`;
const Otheraction = styled.h1`
  font-size: 25px;
  font-weight: bolder;
  margin-bottom: 30px;
`;

const AccountSettingAgentPage: React.FC = () => {
  const [updateCalendar, setUpdateCalendar] = useState("No");
  const [markUnavailable, setMarkUnavailable] = useState("No");
  const [isAccountPaused, setIsAccountPaused] = useState<boolean>(false);
  const notificationOptions = ["Email", "Phone", "Both"];
  const notificationTimingOptions = ["1 day", "1 hour", "Both", "Never"];
  const notificationRejectsShowingReqOptions = ["Realtime", "Never"];
  const notificationNotRespondShowingReqOptions = ["48 hours prior", "24 hours prior"];
  const notificationListedPropertyOptions = ["Realtime", "End of day", "Never"];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [calendarValue, setCalendarValue] = useState([]);
  const { userState, changeUserType, logOut, logIn } = useContext(UserContext);
  const { clearFilters } = useContext(PropertyFiltersContext);
  const [email, setEmail] = useState("");
  const [objData, setObjData] = useState({
    notifiedOn: "",
    updateCalendar: false,
    markWeekends: "",
    markSame: false,
    workingHoursfrom: "",
    workingHourTo: "",
    markSameWorkingHour: false,
    unavailable: false,
    notifyUpcomingTour: "",
    notifyUpcomingShowing: "",
    notifyRejectShowingRequest: "",
    notifynotRespondShowingRequest: "",
    notifyShowingRequestListedProperty: "",
    notifyCompletedTour: "",
    notifyRequestedShowings: "",
    notifySavedSearchs: "",
    notifyFavoritesProperties: "",
    notifyViwedProperties: "",
    notifyOpenHoursTours: "",
    notifyinviteSaleAgent: "",
    agentId: userState.agent.agentId
  });
  const [checkLength, setLength] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailList, setEmailList] = useState([]);
  const history = useHistory();
  const onChangeUpdateCalendar = (checked: boolean) => {
    checked ? setUpdateCalendar("Yes") : setUpdateCalendar("No");
    setObjData((prevState) => ({
      ...prevState,
      // eslint-disable-next-line no-useless-computed-key
      ["updateCalendar"]: checked
    }));
  };
  const onChangeUnavailable = (checked: boolean) => {
    checked ? setMarkUnavailable("Yes") : setMarkUnavailable("No");
    setObjData((prevState) => ({ ...prevState, ["unavailable"]: checked }));
  };

  const isCheckCalendar = (e: CheckboxChangeEvent) => {
    setObjData((prevState) => ({
      ...prevState,
      ["markSame"]: e.target.checked
    }));
  };
  const isCheckWorkinghours = (e: CheckboxChangeEvent) => {
    setObjData((prevState) => ({
      ...prevState,
      ["markSameWorkingHour"]: e.target.checked
    }));
  };
  const handleRadioChange = (e: any, key: any) => {
    setObjData((prevState) => ({ ...prevState, [key]: e.target.value }));
  };

  const handleNotifiedon = (e: any) => {
    if (e?.target?.value === "Phone") {
      setUserContOpt("Phone");
    } else if (e?.target?.value === "Email") {
      setUserContOpt("Email");
    } else {
      setUserContOpt("Both");
    }
    setObjData((prevState) => ({
      ...prevState,
      ["notifiedOn"]: e?.target?.value
    }));
  };

  const getAmTimeFormat = (value: string) => {
    switch (value) {
      case "00:00":
        return "12:00 am";
      case "01:00":
        return "1:00 am";
      case "02:00":
        return "2:00 am";
      case "03:00":
        return "3:00 am";
      case "04:00":
        return "4:00 am";
      case "05:00":
        return "5:00 am";
      case "06:00":
        return "6:00 am";
      case "07:00":
        return "7:00 am";
      case "08:00":
        return "8:00 am";
      case "09:00":
        return "9:00 am";
      case "10:00":
        return "10:00 am";
      case "11:00":
        return "11:00 am";
      default:
        return value;
    }
  };

  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const [isPauseModalVisible, setIsPauseModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isWaringModalVisible, setIsWarningModalVisible] = useState(false);
  const [amWorkingHours, setamWorkingHours] = useState<any>("");
  const [pmWorkingHours, setpmWorkingHours] = useState<any>("");
  const [userContOpt, setUserContOpt] = useState("");

  const cronofyClientId = "JjMr0DO7uRL31jVr9M0FS6szZeSXTnbQ";
  const cronofyRedirectUri = "http://localhost:3000/account-settings";
  const cronofyOauthUrl = "https://app.cronofy.com/oauth/authorize";
  const cronofyScope = "read_events create_event delete_event";
  const cronofyState = "cR0n0Fy";

  const handleOk = async () => {
    try {
      setIsWarningModalVisible(true);

      if (userState.userType === UserType.AGENT) {
      }
      // history.push("/");
      setIsWarningModalVisible(false);
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  const handleOkPause = async () => {
    try {
      setIsWarningModalVisible(true);

      if (userState.userType === UserType.AGENT) {
      }
      setIsWarningModalVisible(false);
      const res: any = await PauseAgentAccount(userState?.agent?.agentId, true, userState?.agent?.token);
      
      if (res?.success) {
        await pauseSubscriptionPaln(userState?.agent?.agentId);
        notification(`${res?.msg}`, "success");
        clearFilters();
        logOut();
        changeUserType(UserType.AGENT);
        history.push("/");
        return;
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };
  const handleOkUnpause = async () => {
    try {
      setIsWarningModalVisible(true);

      if (userState.userType === UserType.AGENT) {
      }
      setIsWarningModalVisible(false);
      const res: any = await PauseAgentAccount(userState?.agent?.agentId, false, userState?.agent?.token);
      if (res?.success) {
        await resumeSubscriptionPaln(userState?.agent?.agentId);
        notification(`Account Unpaused successfully`, "success");
        clearFilters();
        logOut();
        changeUserType(UserType.AGENT);
        history.push("/");
        return;
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };
  const handleCancel = () => {
    setIsWarningModalVisible(false);
  };
  const showLogoutModal = () => {
    setIsLogoutModalVisible(true);
  };
  const onChangeFrom = (time: Moment, timeString: string) => {
    setamWorkingHours(moment(time)?.format("HH:mm"));
    setObjData((prevState) => ({
      ...prevState,
      ["workingHoursfrom"]: moment(time)?.format("HH:mm")
    }));
  };
  const onChangeTo = (time: Moment, timeString: string) => {
    setpmWorkingHours(time);
    setObjData((prevState) => ({
      ...prevState,
      ["workingHourTo"]: moment(time)?.format("HH:mm")
    }));
  };
  const showPauseModal = () => {
    setIsPauseModalVisible(true);
  };

  const gotoModifyPaymentDetails = () => {
    history.push("/modify-payment");
  };
  const isMobile = useMediaQuery({ maxWidth: 1040 });
  const showDeleteModal = () => {
    setIsDeleteModalVisible(true);
  };
  const markWeekends = (e: any) => {
    let str = e.toString();
    setObjData({ ...objData, markWeekends: str });
  };
  const handleSave = async () => {
    if (!checkLength) {
      setIsLoading(true);
      agentAccountSettings(userState?.agent?.token, objData, userState?.agent?.agentId)
        .then((res) => {
          setIsLoading(false);
          setLength(true);
          notification("Data saved successfully", "success");
        })
        .catch((err) => {
          setIsLoading(false);
          notification("Something Went Wrong", "warning");
        });
    } else {
      try {
        setIsLoading(true);
        const payload = {
          notifiedOn: objData?.notifiedOn,
          updateCalendar: objData?.updateCalendar,
          markWeekends: objData?.markWeekends,
          markSame: objData?.markSame,
          workingHoursfrom: objData?.workingHoursfrom,
          workingHourTo: objData?.workingHourTo,
          markSameWorkingHour: objData?.markSameWorkingHour,
          unavailable: objData?.unavailable,
          notifyUpcomingTour: objData?.notifyUpcomingTour,
          notifyUpcomingShowing: objData?.notifyUpcomingShowing,
          notifyRejectShowingRequest: objData?.notifyRejectShowingRequest,
          notifynotRespondShowingRequest: objData?.notifynotRespondShowingRequest,
          notifyShowingRequestListedProperty: objData?.notifyShowingRequestListedProperty,
          notifyCompletedTour: objData?.notifyCompletedTour,
          notifyRequestedShowings: objData?.notifyRequestedShowings,
          notifySavedSearchs: objData?.notifySavedSearchs,
          notifyFavoritesProperties: objData?.notifyFavoritesProperties,
          notifyViwedProperties: objData?.notifyViwedProperties,
          notifyOpenHoursTours: objData?.notifyOpenHoursTours,
          notifyinviteSaleAgent: objData?.notifyinviteSaleAgent,
          agentId: userState.agent.agentId
        };
        const updateRes: any = await putAgentAccountSettings(userState?.agent?.token, payload, userState?.agent?.agentId);
        if (updateRes) {
          let userId = -1;
          let userEmail = "";
          let prefferedCont = "";
          userId = userState?.agent?.agentId;
          userEmail = userState?.agent?.email;

          if (objData?.notifiedOn === "Email") {
            prefferedCont = "CONTACT_RADIO_EMAIL";
          } else if (objData?.notifiedOn === "Phone") {
            prefferedCont = "CONTACT_RADIO_PHONE";
          } else {
            prefferedCont = "CONTACT_RADIO_BOTH";
          }
          const updateContact: any = await updatePrefferedContact(userId, userEmail, prefferedCont);
          if (updateContact) {
            await logOut();
            await logIn(userState?.agent?.email, userState?.agent?.password);
            setIsLoading(false);
          }
          notification("Data updated successfully", "success");
        } else {
          setIsLoading(false);
          notification("Something Went Wrong", "warning");
        }
      } catch (error) {
        setIsLoading(false);
        console.error("ERROR:", error);
        notification("Something Went Wrong", "warning");
      }
    }
  };

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let param = useQuery();

  const initiateCronofyOAuth = () => {
    try {
      // Define the OAuth authorization URL
      const authorizationUrl = `${cronofyOauthUrl}?client_id=${cronofyClientId}&redirect_uri=${cronofyRedirectUri}&scope=${cronofyScope}&response_type=code&state=${cronofyState}`;
      return {
        authorizationUrl,
      };
    } catch (error) {
      throw new Error('Failed to initiate Cronofy OAuth');
    }
  };

  const addEmailHandler = async () => {
    try {
      setIsLoading(true)
      const postResponse = await createUserEmailsService(userState.agent.agentId, userState.agent.name, email, userState.userType);
      console.log("response createUserEmailsHandler", postResponse);
      if (postResponse) {
        setEmail('');
        const response = initiateCronofyOAuth();
        window.location.href = response.authorizationUrl;
        // history.push(response.authorizationUrl)
      }
      else {
        setIsLoading(false)
        return notification('Failed to add the email.', 'error');
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error);

      setIsLoading(false)
      return notification(
        "Failed to add the email.",
        "error"
      );
    }

  }

  const handleCronofyCallback = async () => {
    const receivedCode = param.get('code');
    const receivedState = param.get('state');
    if (receivedState !== cronofyState) {
      return notification('Invalid state parameter. Possible CSRF attack.', 'error');
    }
    console.log('receivedCode', receivedCode);
    if (receivedCode) {
      try {
        const cronofyTokenReq:any = await getCronofyReqTokenService(receivedCode );
        console.log('cronofyTokenReq---===', cronofyTokenReq);
        if(cronofyTokenReq?.access_token){
        const response = await createCronofyService(userState.agent.agentId, userState.agent.name, cronofyTokenReq?.access_token, cronofyTokenReq?.expires_in, cronofyTokenReq?.refresh_token, cronofyTokenReq?.sub, cronofyTokenReq?.linking_profile?.profile_id, cronofyTokenReq?.linking_profile?.profile_name, cronofyTokenReq?.linking_profile?.provider_service, "Agent");
        console.log("response createCronofyHandler", cronofyTokenReq, response, receivedCode);
        if (response) {
          return notification('Tokens are successfully saved in your DB', 'success')
          // navigate('/home');
        } else {
          return notification('Failed to exchange Cronofy code for tokens.', 'error');
        }
      }
      } catch (error) {
        console.log(error);
        return notification('Failed to exchange Cronofy code for tokens.', 'error');
      }
    }
  };


  const getEmailList = async (id: number) => {
    try {
      setIsLoading(true)
      const response: any = await getUserEmailByIdService(id);
      console.log("response getUserEmailsHandler", response);
      if (response?.personal_email) {
        setEmailList([response.personal_email]);
        setEmail('');
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error);
      setIsLoading(false)
      return notification('Failed to add the email.', 'error');
    }

  }
  
  useEffect(() => {
    if (userState?.agent?.preferredContact === "CONTACT_RADIO_EMAIL") {
      setUserContOpt("Email");
    } else if (userState?.agent?.preferredContact === "CONTACT_RADIO_PHONE") {
      setUserContOpt("Phone");
    } else {
      setUserContOpt("Both");
    }

    async function fetchMyApi() {
      setIsLoading(true);
      let response = await getAgentAccountSettings(userState?.agent?.agentId);
      let responseAgent: any = await getAgentById(userState?.agent?.agentId, userState?.agent?.token);
      if (responseAgent?.data?.isPause) {
        setIsAccountPaused(true);
      }
      let data = response[response?.length - 1];

      if (data) {
        setObjData(data);
        setUpdateCalendar(data?.updateCalendar ? "Yes" : "No");
        setMarkUnavailable(data?.unavailable ? "Yes" : "No");
        setamWorkingHours(data?.workingHoursfrom);
        setpmWorkingHours(data?.workingHourTo);
        setLength(true);
        setCalendarValue(data.markWeekends.split(","));
      }
      setIsLoading(false);
    }
    fetchMyApi();
    getEmailList(userState.agent.agentId);
    if (window.location.search.includes('code')) {
      handleCronofyCallback();
    }
  }, []);
  return (
    <>
      {isLoading ? (
        <CustomSpinner />
      ) : (
        <div className="account-setting-agent-page" style={{ fontSize: "17px" }}>
          <Row justify="space-between">
            <Accountsetting>
              Account <span style={{ borderBottom: "3px solid #C2D500" }}> Settings</span>
            </Accountsetting>
            <div className="save-button-css-buyersetting">
              <Link to="/">
                <CustomActionButton size="middle">CANCEL</CustomActionButton>
              </Link>
              &nbsp;&nbsp;&nbsp;
              <CustomSaveButton onClick={handleSave} size="middle">
                SAVE AND UPDATE
              </CustomSaveButton>
            </div>
          </Row>
          <Divider />

          <Row justify="space-around" gutter={32}>
            <Col span={24}>
              <Row justify="space-between">
                In addition to app notifications, I would also like to receive notificaions via:
                <Radio.Group
                  onChange={(e) => handleNotifiedon(e)}
                  value={userContOpt} //{objData?.notifiedOn}
                  options={notificationOptions}
                />
              </Row>
              <CustomDivider />
              <Row justify="space-between">
                Send "update calendar" notifications to all my buyers and sellers once a week.
                <span>
                  {updateCalendar}
                  <CustomSwitch
                    style={{
                      background: objData.updateCalendar ? "#c2d500" : "#afb1af"
                    }}
                    checked={objData.updateCalendar}
                    size="small"
                    onChange={onChangeUpdateCalendar}
                  />
                </span>
              </Row>
              <CustomDivider />
            </Col>
            <Col span={24}>
              <Calendarsettings>
                Calendar
                <span style={{ borderBottom: "3px solid #C2D500" }}> Settings</span>
              </Calendarsettings>
              <Row className="subTitle">Mark your weekends:</Row>
              {/* <Row>{weekDays.map((day) => renderweekDays(day))}</Row> */}
              <Row>
                {" "}
                <CustomCheckboxGroups onChange={(e) => markWeekends(e)} value={objData.markWeekends.split(",")}>
                  <CustomCheckboxs value="Mon">M</CustomCheckboxs>
                  <CustomCheckboxs value="Tue">T</CustomCheckboxs>
                  <CustomCheckboxs value="Wed">W</CustomCheckboxs>
                  <CustomCheckboxs value="Thu">TH</CustomCheckboxs>
                  <CustomCheckboxs value="Fri">F</CustomCheckboxs>
                  <CustomCheckboxs value="Sat">Sa</CustomCheckboxs>
                  <CustomCheckboxs value="Sun">S</CustomCheckboxs>
                </CustomCheckboxGroups>
              </Row>
              <CustomCheckbox checked={objData.markSame} onChange={isCheckCalendar}>
                Mark the same for all my properties
              </CustomCheckbox>
              <CustomDivider />
              <Row className="subTitle">Mark your working hours:</Row>

              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "space-between",
                  width: "20%"
                }}
              >
                <TimePicker
                  use12Hours
                  format="hh:mm a"
                  onChange={onChangeFrom}
                  style={{
                    borderRadius: "5px",
                    border: "1px solid #004876",
                    width: "120px"
                  }}
                  defaultValue={amWorkingHours ? moment(getAmTimeFormat(amWorkingHours?.toString()), "hh:mm a") : moment()}
                />
                <TimePicker
                  use12Hours
                  format="hh:mm a"
                  onChange={onChangeTo}
                  style={{
                    borderRadius: "5px",
                    border: "1px solid #004876",
                    width: "120px"
                  }}
                  defaultValue={pmWorkingHours ? moment(pmWorkingHours, "hh:mm a") : moment()}
                />
              </div>
              <CustomCheckbox checked={objData.markSameWorkingHour} onChange={isCheckWorkinghours}>
                Mark the same for all my properties
              </CustomCheckbox>
              <CustomDivider />
              <Row justify="space-between">
                {/* Mark "unavailable" on all property calendars,if there are other commitments on my calendar. */}
                Mark "unavailable" on all property calendars if there are other commitments on my calendar
                <span>
                  {markUnavailable}
                  <CustomSwitch
                    style={{
                      background: objData.unavailable ? "#c2d500" : "#afb1af"
                    }}
                    checked={objData.unavailable}
                    defaultChecked
                    size="small"
                    onChange={onChangeUnavailable}
                  />
                </span>
              </Row>
              <CustomDivider />
            </Col>
            <Col span={24}>
              <Notificationsetting>
                Notification <span style={{ borderBottom: "3px solid #C2D500" }}> Settings</span>
              </Notificationsetting>
              <Row onChange={(e) => handleRadioChange(e, "notifyUpcomingTour")} justify="space-between">
                Notify me before an upcoming tour.
                <Radio.Group value={objData.notifyUpcomingTour} options={notificationTimingOptions} />
              </Row>
              <CustomDivider />
              <Row onChange={(e) => handleRadioChange(e, "notifyUpcomingShowing")} justify="space-between">
                Notify me before an upcoming showing.
                <Radio.Group value={objData.notifyUpcomingShowing} options={notificationTimingOptions} />
              </Row>
              <CustomDivider />
              <Row onChange={(e) => handleRadioChange(e, "notifyRejectShowingRequest")} justify="space-between">
                Notify me if a listing agent rejects a showing request.
                <Radio.Group value={objData.notifyRejectShowingRequest} options={notificationRejectsShowingReqOptions} />
              </Row>
              <CustomDivider />
              <Row onChange={(e) => handleRadioChange(e, "notifynotRespondShowingRequest")} justify="space-between">
                Notify me if a listing agent does not respond to a showing request.
                <Radio.Group value={objData.notifynotRespondShowingRequest} options={notificationNotRespondShowingReqOptions} />
              </Row>
              <CustomDivider />
              <Row onChange={(e) => handleRadioChange(e, "notifyShowingRequestListedProperty")} justify="space-between">
                Notify me if there's a showing request for any properties I have listed.
                <Radio.Group value={objData.notifyShowingRequestListedProperty} options={notificationListedPropertyOptions} />
              </Row>
              <CustomDivider />
              <Row onChange={(e) => handleRadioChange(e, "notifyCompletedTour")} justify="space-between">
                Notify me when I have completed a tour.
                <Radio.Group value={objData.notifyCompletedTour} options={notificationListedPropertyOptions} />
              </Row>
              <CustomDivider />
              <Row onChange={(e) => handleRadioChange(e, "notifyRequestedShowings")} justify="space-between">
                Notify me when my buyers add properties to "Requested Showings"
                <Radio.Group value={objData.notifyRequestedShowings} options={notificationListedPropertyOptions} />
              </Row>
              <CustomDivider />
              <Row onChange={(e) => handleRadioChange(e, "notifySavedSearchs")} justify="space-between">
                Notify me when my buyers add to or edit their "Saved Searches"
                <Radio.Group value={objData.notifySavedSearchs} options={notificationListedPropertyOptions} />
              </Row>
              <CustomDivider />
              <Row onChange={(e) => handleRadioChange(e, "notifyFavoritesProperties")} justify="space-between">
                Notify me when my buyers add to their "Favorite Properties"
                <Radio.Group value={objData.notifyFavoritesProperties} options={notificationListedPropertyOptions} />
              </Row>
              <CustomDivider />

              <Row onChange={(e) => handleRadioChange(e, "notifyOpenHoursTours")} justify="space-between">
                Notify me when my buyers schedule "Open House Tours"
                <Radio.Group value={objData.notifyOpenHoursTours} options={notificationListedPropertyOptions} />
              </Row>
              <CustomDivider />
              <Row onChange={(e) => handleRadioChange(e, "notifyinviteSaleAgent")} justify="space-between">
                Notify me when my new buyers send me invites to be their Sale Agent.
                <Radio.Group value={objData.notifyinviteSaleAgent} options={notificationListedPropertyOptions} />
              </Row>
              <CustomDivider />
            </Col>
            <Col span={24}>
              <Otheraction>
                Other
                <span style={{ borderBottom: "3px solid #C2D500" }}> Actions</span>
              </Otheraction>
              {/* <Row justify="center"> */}
                {/* <GoogleEventComponent /> */}
                <Row justify="center" style={{ gap: "20px" }}>
                <CustomAddCol span={24}>
                  <TextField
                    value={email}
                    setValue={setEmail}
                    placeholder="janedoe@hotmail.com"
                    label=""
                    type={TextFieldType.ICON_AND_LABEL}
                    icon={TextFieldIconType.EMAIL}
                    isRequired={false}
                    width={28}
                    isDisabled={emailList.length > 0}
                    className="footer__mail_input"
                  />
                  <Buttons
                    type={ButtonType.PRIMARY}
                    text="ADD EMAIL"
                    clickHandler={() => {
                      if (!validator.isEmail(email)) {
                        return notification(
                          "Please enter a valid email address",
                          "error"
                        );
                      } else {
                        addEmailHandler()
                      }
                    }}
                    // clickHandler={()=>handleAddEmail}
                    className="footer_mail_button"
                    isDisabled={emailList.length > 0}
                  />
                </CustomAddCol>
                {emailList.length > 0 && <CustomAddCol span={24}>
                  {emailList.map((item) => <div>{item}</div>)}
                  {/* <Buttons
                    type={ButtonType.PRIMARY}
                    text="REMOVE EMAIL"
                    clickHandler={() => {

                    }}
                    className="footer_mail_button"
                  /> */}
                </CustomAddCol>}

              </Row>
              {/* </Row> */}
              <CustomDivider />
              <Row justify="space-between">
              Upgrade plan so you can display your full profile and advertise your services so more buyers and sellers can find you.
                {/* Upgrade plan so you can display your full profile,advertise yourself so more buyers can find you. */}
                <Link to="/pricing">
                  <CustomSaveButton size="small">CHANGE MY PLAN</CustomSaveButton>
                </Link>
              </Row>
              <CustomDivider />
              {/* <Row justify="space-between">
                Update your Payment details
                <CustomActionButton size="small" onClick={gotoModifyPaymentDetails}>
                  MANAGE MY PAYMENT
                </CustomActionButton>
                <Modal title="Warning !!" visible={isWaringModalVisible} onOk={handleOk} onCancel={handleCancel}>
                  <p>Are you shure you want to pause your account? you won't be able to login again . Only the admin can resume your account</p>
                </Modal>
              </Row> */}
              {/* <CustomDivider /> */}
              <Row justify="space-between">
                Logout of the account.
                <CustomActionButton size="small" onClick={showLogoutModal}>
                  LOG OUT
                </CustomActionButton>
              </Row>
              <CustomDivider />
              {isAccountPaused ? (
                <Row justify="space-between">
                  Pause your account temporarily
                  <CustomActionButton size="small" onClick={showPauseModal}>
                    UNPAUSE ACCOUNT
                  </CustomActionButton>
                  <Modal title="Warning !!" visible={isWaringModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <p>Are you shure you want to pause your account? you won't be able to login again . Only the admin can resume your account</p>
                  </Modal>
                </Row>
              ) : (
                <Row justify="space-between">
                  Pause your account temporarily
                  <CustomActionButton size="small" onClick={showPauseModal}>
                    PAUSE ACCOUNT
                  </CustomActionButton>
                  <Modal title="Warning !!" visible={isWaringModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <p>Are you shure you want to pause your account? you won't be able to login again . Only the admin can resume your account</p>
                  </Modal>
                </Row>
              )}

              <CustomDivider />
              <Row justify="space-between">
                Delete your account permanently.
                <CustomActionButton size="small" onClick={showDeleteModal}>
                  DELETE ACCOUNT
                </CustomActionButton>
              </Row>
              <CustomDivider />
            </Col>
          </Row>

          <AccountModal
            isModalVisible={isLogoutModalVisible}
            setIsModalVisible={setIsLogoutModalVisible}
            callBack={() => {
              clearFilters();
              logOut();
              if (userState.userType === UserType.AGENT) {
                changeUserType(UserType.AGENT);
                history.push("/");
              }
              history.push("/");
            }}
            name="Log Out"
            illus={LogoutIllus}
          ></AccountModal>
          {isAccountPaused ? (
            <AccountModal
              isModalVisible={isPauseModalVisible}
              setIsModalVisible={setIsPauseModalVisible}
              callBack={() => {
                handleOkUnpause();
                setIsPauseModalVisible(false);
              }}
              name="Unpause Account"
              illus={PauseIllus}
            ></AccountModal>
          ) : (
            <AccountModal
              isModalVisible={isPauseModalVisible}
              setIsModalVisible={setIsPauseModalVisible}
              callBack={() => {
                handleOkPause();
                setIsPauseModalVisible(false);
              }}
              name="Pause Account"
              illus={PauseIllus}
            ></AccountModal>
          )}
          <AccountModal isModalVisible={isDeleteModalVisible} setIsModalVisible={setIsDeleteModalVisible} callBack={() => {}} name="Delete Account" illus={DeleteIllus}></AccountModal>
        </div>
      )}
    </>
  );
};
export default AccountSettingAgentPage;
