import axios, { AxiosError } from "axios";
import { notification } from "../components/notification";
import { UserState } from "../contexts/UserContext";
const baseURL = process.env.REACT_APP_BASE_URL;

type NavihomeServerError = {
  statusCode: number;
  message: string;
  error: string;
};

const parseError = (error: unknown): string => {
  if (axios.isAxiosError(error)) {
    const serverError = error as AxiosError<NavihomeServerError>;
    if (serverError && serverError.response) {
      return serverError.response.data.message;
    }
  }
};

export interface Listing {
  subTypeText: string;
  type: string;
  mlsId: number;
  property: {
    area: number;
    bathsFull: number;
    bathsHalf: number;
    bedrooms: number;
    yearBuilt: number;
    type: string;
  };
  address: {
    postalCode: string;
    streetNumber: number;
    streetName: string;
    city: string;
    state: string;
  };
  photos: string[];
  listDate: string;
  listPrice: number;
  mls: {
    daysOnMarket: number;
  };
  remarks: string;
  geo: {
    lat: number;
    lng: number;
  };
  openHouseKey?: number;
  openHouseId?: string;
  startTime?: string;
  endTime?: string;
}

export interface SavedSearch {
  savedSearchId?: number;
  city: string;
  state: string;
  query?: string | null;
  minPrice?: number | null;
  maxPrice?: number | null;
  beds?: number | null;
  baths?: number | null;
  halfBaths?: number | null;
  minSquareFootage?: number | null;
  maxSquareFootage?: number | null;
  minLotSize?: number | null;
  maxLotSize?: number | null;
  minYearBuilt?: number | null;
  maxYearBuilt?: number | null;
  minYearConverted?: number | null;
  maxYearConverted?: number | null;
  parkingSpaces?: number | null;
  garages?: number | null;
  offStreetParking?: number | null;
  heatingCooling?: string | null;
  propertyType?: string | null;
  sortType?: string | null;
  agentName?: string | null;
  agentEmail?: string | null;
  buyerName?: string | null;
  buyerEmail?: string | null;
  agentId?: number | null;
  buyerId?: number | null;
  createdAt?: Date;
}

export interface AddRequested {
  propertyTypeData?: string;
  propertyType?: string;
  agentId?: number;
  initialStatus?: boolean;
  accept?: boolean;
  mlsId?: number;
  latitude?: number;
  longitude?: number;
  country?: string;
  imageUrl: string;
  listPrice: number;
  streetNumber: number;
  streetName: string;
  city: string;
  state: string;
  postalCode: string;
  bedrooms?: number;
  bathrooms?: number;
  area: number;
  bathsHalf?: number;
  opneHouse?: string;
  propertyDetails?: any;
  openHouseStartTime?: Date;
  openHouseEndTime?: Date;
  openHouseId?: string;
  openHouseKey?: string;
  createdAt?: Date;
  updatedAt?: Date;
  messageType?: string | null;
  agentName?: string | null;
  agentEmail?: string | null;
  buyerName?: string | null;
  buyerEmail?: string | null;
  listingAgentName?: string | null;
}

export interface AddViewedProperties {
  propertyTypeData?: string;
  propertyType?: string;
  agentId?: number;
  initialStatus?: boolean;
  accept?: boolean;
  mlsId?: number;
  latitude?: number;
  longitude?: number;
  country?: string;
  imageUrl: string;
  listPrice: number;
  streetNumber: number;
  streetName: string;
  city: string;
  state: string;
  postalCode: string;
  bedrooms?: number;
  bathrooms?: number;
  area: number;
  bathsHalf?: number;
  opneHouse?: string;
  propertyDetails?: string;
  openHouseStartTime?: Date;
  openHouseEndTime?: Date;
  openHouseId?: string;
  openHouseKey?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface AddFavorite {
  propertyTypeData?: string;
  propertyType?: string;
  agentId?: number;
  mlsId?: number;
  latitude?: number;
  longitude?: number;
  country?: string;
  imageUrl: string;
  listPrice: number;
  streetNumber: number;
  streetName: string;
  city: string;
  state: string;
  postalCode: string;
  bedrooms?: number;
  bathrooms?: number;
  area: number;
  bathsHalf?: number;
  opneHouse?: string;
  openHouseStartTime?: Date;
  openHouseEndTime?: Date;
  openHouseId?: string;
  openHouseKey?: string;
  createdAt?: Date;
  updatedAt?: Date;
  messageType?: string | null;
  agentName?: string | null;
  agentEmail?: string | null;
  buyerName?: string | null;
  buyerEmail?: string | null;
  listingAgentName?: string | null;
}

export interface AddViewedFavProperties {
  propertyTypeData?: string;
  propertyType?: string;
  agentId?: number;
  mlsId?: number;
  latitude?: number;
  longitude?: number;
  country?: string;
  imageUrl: string;
  listPrice: number;
  streetNumber: number;
  streetName: string;
  city: string;
  state: string;
  postalCode: string;
  bedrooms?: number;
  bathrooms?: number;
  area: number;
  bathsHalf?: number;
  opneHouse?: string;
  propertyDetails?: string;
  openHouseStartTime?: Date;
  openHouseEndTime?: Date;
  openHouseId?: string;
  openHouseKey?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface Comments {
  comment: string;
  propertyType?: string;
  agentId?: number;
  mlsId?: number;
  latitude?: number;
  longitude?: number;
  country?: string;
  imageUrl: string;
  listPrice: number;
  streetNumber: number;
  streetName: string;
  city: string;
  state: string;
  postalCode: string;
  bedrooms?: number;
  bathrooms?: number;
  area: number;
  bathsHalf?: number;
  opneHouse?: string;
  propertyDetails: string;
  openHouseStartTime?: Date;
  openHouseEndTime?: Date;
  openHouseId?: string;
  openHouseKey?: string;
  createdAt?: Date;
  updatedAt?: Date;
  messageType?: string | null;
  agentName?: string | null;
  agentEmail?: string | null;
  buyerName?: string | null;
  buyerEmail?: string | null;
  listingAgentName?: string | null;
}

interface Buyer {
  buyerId: number;
  name: string;
  email: string;
  phone: string;
  imageUrl: string;
  color: string;
  comsumerType: string;
}

export interface BuyerColorCode {
  colorId: number;
  buyerId: number;
  agentId: number;
  colorCode: string;
}

export interface Agent {
  agentId: number;
  name: string;
  email: string;
  phone: string;
  company: string;
  imageUrl: string;
  avatarUrl: string;
  companyImageUrl?: string;
  companyUrl?: string;
  registeredAt?: Date;
  expireAt?: Date;
  agents?: any;
}

export interface Moreinfo {
  Id: number;
  bio: string;
  url: string;
  tagline: string;
  agentId: number;
}

export interface PaymentCard {
  card_id?: string;
  card_brand?: string | null;
  last_4?: number | null;
  exp_month?: number;
  exp_year?: number;
  cardholder_name?: string | null;
  customer_id?: string;
  merchant_id?: string;
  bin?: string;
  card_type?: string | null;
  agentId?: number;
  buyerId?: number;
  created_at?: Date;
}

export interface BuyerInvitation {
  agentId: number;
}

export interface LinkedProperty {
  propertyTypeData?: string;
  propertyType?: string;
  agentId?: number;
  latitude?: string;
  longitude?: string;
  country?: string;
  imageUrl: string;
  listPrice: number;
  streetNumber: number;
  streetName: string;
  city: string;
  state: string;
  postalCode: string;
  bedrooms?: number;
  bathrooms?: number;
  area: number;
  bathsHalf?: number;
  propertyCategory?: string;
  openHouseStartTime?: Date;
  openHouseEndTime?: Date;
  openHouseId?: string;
  openHouseKey?: string;
  createdAt?: Date;
  updatedAt?: Date;
  isFavorite?: boolean;
  isRequested?: boolean;
  isListed?: boolean;
  listingAgentId?: number;
}

const getConfigWithToken = (token: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};

// LOG IN ........................................................................................................................

export const logInBuyerService = async (email: string, password: string) => {
  try {
    const response: {
      data: UserState["buyer"];
    } = await axios.post(`${baseURL}/buyers/login`, { email, password });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

//Post Token
export const postBuyerTokenServices = async (token: string) => {
  try {
    const response: {
      data: UserState["buyer"];
    } = await axios.post(`${baseURL}/buyers/token`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const logInAgentService = async (email: string, password: string) => {
  try {
    const response: {
      data: UserState["agent"];
    } = await axios.post(`${baseURL}/agents/login`, { email, password });
    return response.data;
  } catch (error) {
    console.error(error);

    throw new Error(parseError(error));
  }
};

// FORGOT PASSWORD ........................................................................................................................

export const sendForgotPasswordForBuyerService = async (email: string) => {
  try {
    const response = await axios.post(`${baseURL}/buyers/forgot-password`, {
      email
    });
    return response.data;
  } catch (error) {
    console.error(error);

    throw new Error(parseError(error));
  }
};

export const sendForgotPasswordForAgentService = async (email: string) => {
  try {
    const response = await axios.post(`${baseURL}/agents/forgot-password`, {
      email
    });
    return response.data;
  } catch (error) {
    console.error(error);

    throw new Error(parseError(error));
  }
};

//SEND RESET PASSWORD LINK ........................................................................................................................

export const sendResetForgotPasswordLinkForBuyerService = async (email: string) => {
  try {
    const response = await axios.post(`${baseURL}/buyers/reset-password/link`, {
      email
    });
    return response.data;
  } catch (error) {
    console.error(error);

    throw new Error(parseError(error));
  }
};

export const sendResetForgotPasswordLinkForAgentService = async (email: string) => {
  try {
    const response = await axios.post(`${baseURL}/agents/reset-password/link`, {
      email
    });
    return response.data;
  } catch (error) {
    console.error(error);

    throw new Error(parseError(error));
  }
};

// RESET PASSWORD ........................................................................................................................

export const resetForgotPasswordForBuyerService = async (email: string, password: string) => {
  try {
    const response = await axios.post(`${baseURL}/buyers/reset-password`, {
      email,
      password
    });
    return response.data;
  } catch (error) {
    console.error(error);

    throw new Error(parseError(error));
  }
};

export const resetForgotPasswordForAgentService = async (email: string, password: string) => {
  try {
    const response = await axios.post(`${baseURL}/agents/reset-password`, {
      email,
      password
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

// REGISTER ........................................................................................................................

export const registerBuyerService = async (email: string, password: string, phone: string, name: string, preferredContact: string, preferredType?: string) => {
  try {
    const response: {
      data: UserState["buyer"];
    } = await axios.post(`${baseURL}/buyers`, {
      email,
      password,
      name,
      phone,
      preferredContact,
      preferredType
    });
    return response.data;
  } catch (error) {
    console.error(error);

    throw new Error(parseError(error));
  }
};

export const registerAgentService = async (
  email: string,
  password: string,
  phone: string,
  name: string,
  mlsId: string,
  company: string,
  preferredContact: string,
  coverageAreas?: string,
  expireAt?: Date
) => {
  try {
    const response: {
      data: UserState["agent"];
    } = await axios.post(`${baseURL}/agents`, {
      email,
      password,
      name,
      phone,
      mlsId,
      company,
      preferredContact,
      coverageAreas,
      expireAt
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

// BUYER FORM FOR VISTING PROPERTIES..............................................................................................

// POST FORM DETAILS API

export const registerForVisitPropService = async (
  // formId: number,
  // buyerId: number,
  dateTime: Date,
  time: string,
  timePerProperty: string,
  startAdd: string,
  startAddCity: string,
  endingAdd: string,
  endingAddCity: string,
  selectedProperties: number,
  area: string,
  PropertyStatus: string,
  buyerId: number,
  saveTourStatus: string,
  firstActiveTab: string,
  secondActiveTab: string,
  startrouteLocation: string,
  endrouteLocation: string,
  agentId: number,
  token: string,
  role: string
  // timeZone: string
) => {
  try {
    const response = await axios.post(
      `${baseURL}/visiting-properties/`,
      {
        dateTime,
        time,
        timePerProperty,
        startAdd,
        startAddCity,
        endingAdd,
        endingAddCity,
        selectedProperties,
        area,
        PropertyStatus,
        buyerId,
        saveTourStatus,
        firstActiveTab,
        secondActiveTab,
        startrouteLocation,
        endrouteLocation,
        agentId,
        role
      },
      getConfigWithToken(token)
    );
    return response.data;
  } catch (error) {
    console.error("route tour hrader error is ", error);
    return { error: true, message: "something went wrong" };
  }
};

// GET FORM DETAILS API

export const getupcomingTourDetails = async (buyerId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/visiting-properties/upcomingtour/${buyerId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const getupcomingToursDetails = async (buyerId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/visiting-properties/upcomingtours/${buyerId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

//get All Properties whose Tour Accepted
export const getTourAllAcceptedProp = async (buyerId: number, currDate: any, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/visiting-properties/all-accepted/buyer/${buyerId}/${currDate}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

// UPDATE USER TOUR FORM DATA

export const updateUserTourFormData = async (
  dateTime: Date,
  time: string,
  timePerProperty: string,
  startAdd: string,
  startAddCity: string,
  endingAdd: string,
  endingAddCity: string,
  selectedProperties: number,
  area: string,
  status: string,
  buyerId: number,
  saveTourStatus: string,
  firstActiveTab: string,
  secondActiveTab: string,
  startrouteLocation: string,
  endrouteLocation: string,
  agentId: number,
  formId: any,
  token: string,
  role: string
) => {
  try {
    const response = await axios.patch(
      `${baseURL}/visiting-properties/update-userTour/${formId}`,
      {
        dateTime,
        time,
        timePerProperty,
        startAdd,
        startAddCity,
        endingAdd,
        endingAddCity,
        selectedProperties,
        area,
        status,
        buyerId,
        saveTourStatus,
        firstActiveTab,
        secondActiveTab,
        startrouteLocation,
        endrouteLocation,
        agentId,
        role
      },
      getConfigWithToken(token)
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return { error: true, message: "something went wrong" };
  }
};

// PROPERTIES ....................................................................................................................
// To varify according to the properties mlsId=================

// GET ALL UPCOMING TOUR DETAILS

export const getAllupcomingTourDetailsData = async (buyerId: number, token: string, role: string, type: string, scheduledType: string) => {
  try {
    const response = await axios.get(`${baseURL}/visiting-properties/all/upcoming/tours/${buyerId}/${role}/${type}/${scheduledType}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

//GetAll Scheduled Tours
export const getAllUpcomingScheduledData = async (buyerId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/best-route-property/allScheduledProperties/${buyerId}`, getConfigWithToken(token));
    return response?.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

//GET ALL PREVIOUS TOURS
export const getAllPreviousTourDetailsData = async (buyerId: number, currDate: any, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/visiting-properties/allprevioustour/${buyerId}/${currDate}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

//GET ALL UPCOMING TOUR DETAILS AGENT SIDE
export const getAllUpcomingTourDetailsAgentSide = async (agentId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/visiting-properties/agentallupcomingtour/${agentId}`, getConfigWithToken(token));
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

//GET ALL UPCOMING TOUR DETAILS AGENT SIDE COMMON
export const getAllUpcomingTourDetailsAgentSideCommon = async (agentId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/visiting-properties/agentallupcomingtour/common/${agentId}`, getConfigWithToken(token));
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

//GET Upcoming Tour Details according to formId

export const getUpcomingTourDetailsByFormId = async (formId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/visiting-properties/allupcomingtour/showings/formId/${formId}`, getConfigWithToken(token));
    return response?.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

//Update isAllAccepted Upcoming Tours

export const updateIsAllAcceptedUpcomingToursService = async (token: string, formId: number, type: string) => {
  try {
    const response = await axios.put(`${baseURL}/visiting-properties/update/is-all-accepted/upcoming-tours/${formId}/${type}`, {}, getConfigWithToken(token));
    return response?.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

//GET All Seller upcoming tours according to mlsId

export const getAllSellerUpcomingTours = async (mlsId: number, currDate: any, token: string, role: string) => {
  try {
    const response = await axios.get(`${baseURL}/best-route-property/seller/upcoming/tours/${currDate}/${mlsId}/${role}`, getConfigWithToken(token));
    return response?.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

// UPDATE FORM DETAILS API
export const updateUpcomingTourDetails = async (id: number, dataToUpdate: any, token: string) => {
  try {
    const response = await axios.patch(`${baseURL}/visiting-properties/updatetour/${id}`, dataToUpdate, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

// PROPERTIES ....................................................................................................................
export const getPropertiesService = async (query: string, count?: number, open_house?: string) => {
  try {
    const response = await axios.get<Listing[]>(`${baseURL}/properties/limit/${count}/open/${open_house}/?${query}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const getPropertiesOpenHousesService = async (query?: string, count?: number, open_house?: string) => {
  try {
    const response = await axios.get<Listing[]>(`${baseURL}/properties/get/open-houses/${count}/?${query}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const filterOpenHousesProperties = async (listingId: number) => {
  try {
    const response: any = await axios.get(`${baseURL}/properties/get/filter_open_house/${listingId}`);
    return response?.data;
  } catch (error) {
    console.error(error);
    return;
  }
};
// Get OpenHouse By OpenHouseKey
export const getPropByOpenHouseKey = async (openHouseKey: number) => {
  try {
    const response: any = await axios.get(`${baseURL}/properties/getOpenHouse/${openHouseKey}`);
    return response?.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const getPropertiesNewListingsService = async (city: string) => {
  try {
    const response = await axios.get<Listing[]>(`${baseURL}/properties/new-listings/${city}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const getPropertiesAffordableService = async (city: string) => {
  try {
    const response = await axios.get<Listing[]>(`${baseURL}/properties/affordable/${city}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const getPropertiesLuxuryService = async (city: string) => {
  try {
    const response = await axios.get<Listing[]>(`${baseURL}/properties/luxury/${city}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const getPropertyDetailsService = async (propertyId: number) => {
  try {
    const response = await axios.get<Listing>(`${baseURL}/properties/${propertyId}`);
    return response?.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const getPropertiesByCity = async (cityName: string, count: number) => {
  try {
    const response = await axios.get<Listing>(`${baseURL}/properties/city/${cityName?.toLowerCase()}/limit/${count}`);
    return response?.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const getOpenHousesByCity = async (cityName: string, count: number) => {
  try {
    const response = await axios.get(`${baseURL}/properties/openHouse/city/${cityName}/limit/${count}`);
    return response?.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

// ADD PROPERTIES FOR ROUTING ON MAP
// POST Request to Update property detial
export const addLinkedPropertyForBuyer = async (propertyId: number, buyerId: number, token: string, addLinked?: LinkedProperty) => {
  try {
    const response = await axios.post(`${baseURL}/properties/${propertyId}/linked-properties/buyer/${buyerId}`, addLinked, getConfigWithToken(token));
    return response.data;
  } catch (err) {
    console.error(err);
    throw new Error(parseError(err));
  }
};
// REMOVE Particular PROPERTIES FOR ROUTING ON MAP
export const removeLinkedPropertyForBuyer = async (propertyId: number, buyerId: number, token: string) => {
  try {
    await axios.patch(`${baseURL}/properties/${propertyId}/remove-linked-properties/buyer/${buyerId}`, {}, getConfigWithToken(token));
  } catch (err) {
    console.error(err);
    throw new Error(parseError(err));
  }
};
// GET All properties
export const getLinkedPropertyForMap = async (buyerId: number, token: string) => {
  try {
    const response = await axios.get<
      { buyerId: number },
      {
        data: { propertyId: number }[];
      }
    >(`${baseURL}/properties/linked/buyer/${buyerId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

// remove All properties of a particular buyer

export const removeAllLinkedPropertyOfBuyer = async (buyerId: number, token: string) => {
  try {
    const response = await axios.patch(`${baseURL}/properties/remove-linkedProp/buyer/${buyerId}`, {}, getConfigWithToken(token));
    return response;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};
// GET SCHOOL INFO  OF PARTICULAR PROPERTY
export const getSchoolInfoOfProperty = async (lat: any, lng: any) => {
  try {
    const response = await axios.get<any>(`${baseURL}/properties/schoolinfo`, {
      params: {
        lat: lat,
        lng: lng
      }
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};
// FAVORITE PROPERTIES .......................................................................................
export const addPropertyToFavoritesForBuyerService = async (propertyId: number, buyerId: number, token: string, addFavorite?: AddFavorite) => {
  try {
    const response = await axios.post(`${baseURL}/properties/${propertyId}/add-favorites/buyer/${buyerId}`, addFavorite, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const removePropertyFromFavoritesForBuyerService = async (propertyId: number, buyerId: number, token: string) => {
  try {
    const response = await axios.patch(`${baseURL}/properties/${propertyId}/remove-favorites/buyer/${buyerId}`, {}, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const checkIfPropertyIsFavoriteForBuyerService = async (propertyId: number, buyerId: number, token: string) => {
  try {
    const response = await axios.get<{ propertyId: number; buyerId: number }, { data: { isFavorite: boolean } }>(
      `${baseURL}/properties/${propertyId}/favorite/buyer/${buyerId}`,
      getConfigWithToken(token)
    );
    return response.data.isFavorite;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

export const getFavoritePropertiesForBuyerService = async (buyerId: number, token: string, upcomingTours: string) => {
  try {
    const response = await axios.get<{ buyerId: number }, { data: { propertyId: number }[] }>(`${baseURL}/properties/favorites/buyer/${buyerId}/${upcomingTours}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

// REQUESTED PROPERTIES .......................................................................................
export const addPropertyToRequestedForBuyerService = async (propertyId: number, buyerId: number, token: string, addRequested?: AddRequested) => {
  try {
    // const result: any = await getBuyerInfoService(token, buyerId);
    // const agentId = result['agentId'];
    // addRequested.agentId = agentId ?? 0;
    const response = await axios.post(`${baseURL}/properties/${propertyId}/add-requested/buyer/${buyerId}`, addRequested, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const removePropertyFromRequestedForBuyerService = async (propertyId: number, buyerId: number, token: string) => {
  try {
    await axios.patch(`${baseURL}/properties/${propertyId}/remove-requested/buyer/${buyerId}`, {}, getConfigWithToken(token));
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const checkIfPropertyIsRequestedForBuyerService = async (propertyId: number, buyerId: number, token: string) => {
  try {
    const response = await axios.get<{ propertyId: number; buyerId: number }, { data: { isRequested: boolean } }>(
      `${baseURL}/properties/${propertyId}/requested/buyer/${buyerId}`,
      getConfigWithToken(token)
    );

    return response.data.isRequested;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

export const getRequestedPropertiesForBuyerService = async (buyerId: number, token: string, upcomingTours: string) => {
  try {
    const response = await axios.get<{ buyerId: number }, { data: { propertyId: any }[] }>(`${baseURL}/properties/requested/buyer/${buyerId}/${upcomingTours}`, getConfigWithToken(token));
    return response?.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};
//ATTACHED BUYERS WITH AGENT.....................................................................
export const getRequestedPropertiesForBuyerServices = async (agentId: number, token: string) => {
  try {
    const response = await axios.get<{ agentId: number }, { data: { propertyId: number }[] }>(`${baseURL}/properties/requested/buyers/${agentId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};
// INVITATIONS ........................................................................................................

export const getLinkedBuyersService = async (token: string, seller?: string) => {
  try {
    const response = await axios.get<{}, { data: Buyer[] }>(`${baseURL}/buyers/linked/with-agent/${seller}`, getConfigWithToken(token));
    return response?.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const inviteBuyerToAgentService = async (name: string, email: string, message: string, token: string, inviteTo: string) => {
  try {
    const response = await axios.post(`${baseURL}/buyers/invite`, { name, email, message, inviteTo }, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

export const inviteAgentService = async (name: string, email: string, message: string, token: string) => {
  try {
    const response = await axios.post(`${baseURL}/agents/new/invite`, { name, email, message }, getConfigWithToken(token));

    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

export const inviteAgentByBuyerServices = async (name: string, email: string, message: string, token: string, buyerName?: string) => {
  try {
    const response = await axios.post(`${baseURL}/buyers/new/agent/invite`, { name, email, message, buyerName }, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

export const unlinkBuyerFromAgentService = async (token: string, buyerId: number, customMessage?: string) => {
  try {
    const response = await axios.patch(`${baseURL}/buyers/${buyerId}/unlink`, { customMessage }, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

export const unlinkSellerFromAgentService = async (token: string, buyerId: number) => {
  try {
    const response = await axios.patch(`${baseURL}/buyers/seller/${buyerId}/unlink`, {}, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

export const unlinkAllConsumerFromAgentService = async (token: string, name: string) => {
  try {
    const response = await axios.patch(`${baseURL}/buyers/unlink/consumer/${name}`, {}, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

export const removePropertyFromListedService = async (propertyId: number, agentId: number, token: string) => {
  try {
    const response = await axios.patch(`${baseURL}/listedSellerProperty/${propertyId}/remove/listed/${agentId}`, {}, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const acceptBuyerInvitationService = async (token: string, agentId: number) => {
  try {
    const response = await axios.patch(`${baseURL}/buyers/agent/${agentId}/accept-invitation`, {}, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

export const rejectBuyerInvitationService = async (token: string, agentId: number) => {
  try {
    const response = await axios.patch(`${baseURL}/buyers/agent/${agentId}/reject-invitation`, {}, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};
// SAVED SEARCHES ........................................................................................................
export const saveSearchService = async (token: string, search: SavedSearch, buyerId?: number, messageType?: string) => {
  try {
    const response = await axios.post(`${baseURL}/saved-searches/create/searches/${buyerId ?? null}/${messageType ?? null}`, search, getConfigWithToken(token));
    return response;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

export const getSavedSearchesService = async (token: string, buyerId: number) => {
  try {
    const response = await axios.get<{}, { data: SavedSearch[] }>(`${baseURL}/saved-searches/buyer/${buyerId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};
export const deleteSavedSearchService = async (token: string, savedSearchId: number) => {
  try {
    const response = await axios.delete(`${baseURL}/saved-searches/${savedSearchId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};
export const updateSavedSearchService = async (token: string, search: SavedSearch, searchId: number, messageType: string) => {
  try {
    const response = await axios.put(`${baseURL}/saved-searches/buyer/update/${searchId}/${messageType}`, search, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};
// NOTIFICATIONS ........................................................................................................
export const getBuyerInvitationsService = async (token: string) => {
  try {
    const response = await axios.get<{}, { data: BuyerInvitation[] }>(`${baseURL}/buyers/invitations/buyer`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};
// buyer account settings /////////////////////////////////////////////////////////////////////
export const buyerAccountSettings = async (
  token: string,
  obj: any
  // CalendarView?: any
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${baseURL}/buyer-account-settings`, obj, getConfigWithToken(token));
      return resolve(response);
    } catch (err) {
      console.error(err);
      return reject(err);
    }
  });
};
export const putBuyerAccountSettings = async (token: string, obj: any, BuyerId: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.put(`${baseURL}/buyer-account-settings/update/setting/info/${BuyerId}`, obj, getConfigWithToken(token));
      return resolve(response);
    } catch (err) {
      console.error(err);
      return reject(err);
    }
  });
};
export const getBuyerAccountSettings: any = async (token: string, buyerId: number) => {
  try {
    const response = await axios.get(`${baseURL}/buyer-account-settings/account/setting/info/buyer/${buyerId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};
// agent account settings /////////////////////////////////////////////////////////////////////
export const agentAccountSettings = async (
  token: string,
  obj: any,
  agentId: number
  // changeView?: string
) => {
  try {
    const response = await axios.post(`${baseURL}/agent-account-settings/updateagntaccount/${agentId}`, obj, getConfigWithToken(token));
    return response;
  } catch (err) {
    console.error(err);
    // return reject(err);
    return err;
  }
};
export const putAgentAccountSettings = async (token: string, obj: any, agentId: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.put(`${baseURL}/agent-account-settings/update/setting/info/${agentId}`, obj, getConfigWithToken(token));
      return resolve(response);
    } catch (err) {
      console.error(err);
      return reject(err);
    }
  });
};
export const getAgentAccountSettings: any = async (agentId: number) => {
  try {
    const response = await axios.get(`${baseURL}/agent-account-settings/account/setting/info/${agentId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
// SALE AGENTS ........................................................................................................
export const getAgentInfoService = async (token: string, agentId: number) => {
  try {
    const response = await axios.get<{}, { data: Agent }>(`${baseURL}/agents/${agentId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};
export const getAgentInforEmailService = async (email: string) => {
  try {
    const response = await axios.get<{}, { data: Agent }>(`${baseURL}/agents/check-email/${email}`);
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};
export const getmoreinformationService = async (
  // token: string,
  agentId: number
) => {
  try {
    const response = await axios.get<{}, { data: Moreinfo }>(
      `${baseURL}/agents/moreinformation/${agentId}`
      // getConfigWithToken(token),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

export const getAgentAddressInfoService = async (
  // token: string,
  agentId: number
) => {
  try {
    const response = await axios.get<{}, { data: Agent }>(
      `${baseURL}/agents/address/${agentId}`
      // getConfigWithToken(token)
    );
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};
export const updateAgentInfoService = async (token: string, agentId: number, dataToUpdate: any) => {
  try {
    const response = await axios.put(`${baseURL}/agents/update/${agentId}`, dataToUpdate, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      msg: "Something went wrong, Please try again."
    };
  }
};

export const getAgentCalendarEvents = async () => {
  try {
    const response = await axios.get(`${baseURL}/calendar/events`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const deleteAgentCalendarEvent = async (eventId: string) => {
  try {
    const response = await axios.delete(`${baseURL}/calendar/deleteEvent`, {
      params: { eventId: eventId }
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};
export const getPlacebySearch = async (text: string) => {
  try {
    const response = await axios.get(`${baseURL}/google-api/getPlaces`, {
      params: { input: text }
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};
export const getAgentCoverageAreaService = async (token: string, agentId: number) => {
  try {
    const response = await axios.get<{}, { data: Agent }>(`${baseURL}/agents/coverageArea/${agentId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};
export const getAgentInfoByMlsId = async (token: string, agentMlsid: string) => {
  try {
    const response = await axios.get<{}, { data: Agent }>(`${baseURL}/agents/mlsId/${agentMlsid}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};
// GET All Agents
export const getAllAgents = async (token: string, offset: number) => {
  try {
    if (!token) return notification("Unauthorized!", "error");
    const response = await axios.get(`${baseURL}/agents/all/get/${offset}`, getConfigWithToken(token));

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// GET All Agents
export const getSearchedAgents = async (query: string) => {
  try {
    const response = await axios.get(`${baseURL}/agents/all/searched/get/${query}`);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const checkIfBuyerIsLinkedWithAgent = async (token: string, buyerId: number) => {
  try {
    if (!token) return;
    const response = await axios.get(`${baseURL}/buyers/check/linked/${buyerId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getBuyerInfoService = async (token: string, buyerId: number) => {
  try {
    if (!token) return notification("Unauthorized!", "error");
    if (!buyerId) {
      console.error("buyerId not found");
      return;
    }
    const response = await axios.get(`${baseURL}/buyers/${buyerId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getSellerInfoService = async (token: string, buyerId: number) => {
  try {
    if (!token) return notification("Unauthorized!", "error");
    if (!buyerId) {
      console.error("buyerId not found");
      return;
    }
    const response = await axios.get(`${baseURL}/buyers/seller/${buyerId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // return notification('Something Went Wrong!', 'error')
  }
};

export const getBuyerAddressInfoService = async (token: string, buyerId: number) => {
  try {
    const response = await axios.get(`${baseURL}/buyers/address/${buyerId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // return notification('Something Went Wrong!', 'error')
  }
};

export const updateBuyerInfoService = async (token: string, buyerId: number, dataToUpdate: any) => {
  try {
    const response = await axios.put(`${baseURL}/buyers/update/${buyerId}`, dataToUpdate, getConfigWithToken(token));

    return response.data;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};

// VIEWED PROPERTIES

//add to viewed properties
export const addPropertyToViewedForBuyerService = async (propertyId: number, buyerId: number, token: string, addViewedProperties?: AddViewedProperties) => {
  try {
    const response = await axios.post(`${baseURL}/properties/${propertyId}/add-viewed-property/buyer/${buyerId}`, addViewedProperties, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

// get viewed properties
export const getViewedPropertiesForBuyerService = async (buyerId: number, token: string) => {
  try {
    const response = await axios.get<{ buyerId: number }, { data: { propertyId: number }[] }>(`${baseURL}/properties/viewed-property/buyer/${buyerId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

// check Viewed Properties
export const checkIfPropertyIsViewedRequestedForBuyerService = async (propertyId: number, buyerId: number, token: string) => {
  try {
    const response = await axios.get<{ propertyId: number; buyerId: number }, { data: { isRequested: boolean } }>(
      `${baseURL}/properties/${propertyId}/check-viewed/buyer/${buyerId}`,
      getConfigWithToken(token)
    );
    return response.data.isRequested;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

// VIEWED FAVORITES PROPERTIES

//add to viewed Favorites properties
export const addPropertyToViewedFavoritesForBuyerService = async (propertyId: number, buyerId: number, token: string, addViewedFavProperties?: AddViewedFavProperties) => {
  try {
    const response = await axios.post(`${baseURL}/properties/${propertyId}/add-viewed-favorites/buyer/${buyerId}`, addViewedFavProperties, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

// get viewed favorites properties
export const getViewedFavoritesPropertiesForBuyerService = async (buyerId: number, token: string) => {
  try {
    const response = await axios.get<{ buyerId: number }, { data: { propertyId: number }[] }>(`${baseURL}/properties/viewed-favorite-property/buyer/${buyerId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

// check Viewed Favorites Properties
export const checkIfPropertyIsViewedFavoritesForBuyerService = async (propertyId: number, buyerId: number, token: string) => {
  try {
    const response = await axios.get<{ propertyId: number; buyerId: number }, { data: { isRequested: boolean } }>(
      `${baseURL}/properties/${propertyId}/check-viewed-favorites/buyer/${buyerId}`,
      getConfigWithToken(token)
    );
    return response.data.isRequested;
  } catch (error) {
    console.error(error);

    // throw new Error(parseError(error));
  }
};

// COMMENTS
//create
export const createPropertyCommentForBuyer = async (agentId: number, propertyId: number, token: string, comments: Comments) => {
  try {
    if (!token) return notification("Unauthorized!", "error");
    const response = await axios.post(`${baseURL}/properties/${propertyId}/add-comments/buyer/${agentId}`, comments, getConfigWithToken(token));

    return response.data;
  } catch (error) {
    console.error(error);
    // return notification('Something Went Wrong!', 'error');
  }
};

export const createPropertyCommentForAgent = async (buyerId: number, propertyId: number, token: string, comments: Comments) => {
  try {
    if (!token) return notification("Unauthorized!", "error");
    const response = await axios.post(`${baseURL}/properties/${propertyId}/add-comments/buyer/${buyerId}`, comments, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

//get
export const getPropertyCommentForBuyer = async (agentId: number, propertyId: number, token: string) => {
  try {
    if (!token) return notification("Unauthorized!", "error");
    const response = await axios.get(`${baseURL}/properties/${propertyId}/comments/buyer/${agentId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // return notification('Something Went Wrong!', 'error');
  }
};

export const getPropertyCommentForAgent = async (buyerId: number, propertyId: number, token: string) => {
  try {
    if (!token) return notification("Unauthorized!", "error");
    const response = await axios.get(`${baseURL}/properties/${propertyId}/comments/buyer/${buyerId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // return notification('Something Went Wrong!', 'error');
  }
};

// VERIFY UPDATE FOR AGENT LOGIN...................
export const updateAgentVerifyService = async (email: string, type: string) => {
  try {
    const response = await axios.put(`${baseURL}/agents/update/verify/${email}/${type}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};
// VERIFY UPDATE
export const updatePaymentExpireDate = async (token: string, agentId: number) => {
  try {
    const response = await axios.put(`${baseURL}/agents/update/expire/verify/${agentId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};

// VERIFY UPDATE FOR BUYER LOGIN.................................

export const updateBuyerVerifyService = async (email: string, type: string) => {
  try {
    const response = await axios.put(`${baseURL}/buyers/update/buyer/verify/${email}/${type}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};
// UPDATE PREFFERED CONTACT

export const updatePrefferedContact = async (userId: number, email: string, prefferedCont: string) => {
  try {
    const response = await axios.put(`${baseURL}/agents/update/prefferedCont/${userId}`, { email, prefferedCont });
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};

//UPDATE BUYER PREFFERED CONTACT

export const updateBuyerPrefferedContact = async (buyerId: number, buyerEmail: string, prefferedCont: string) => {
  try {
    const response = await axios.put(`${baseURL}/buyers/update/prefferedCont/${buyerId}`, { buyerEmail, prefferedCont });
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};

// UPLOAD IMAGES ........................................................................................................

export const uploadImage = async (file: any, token: string) => {
  try {
    const response = await axios.post(`${baseURL}/fileupload`, file, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

//GET TOTAL FAVORITES PROPERTY .........................................................................................

export const totalFavoritesSpecificProperty = async (mlsId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/properties/total/favorites/${mlsId}`, getConfigWithToken(token));

    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

// INVITE AGENT BY BUYER || CREATING AGENT NOTIFICATIONS WITH BUYER ROLE

export const inviteAgentByBuyer = async (
  agentId: number | string,
  type: string,
  message: string,
  propertyId: number,
  name?: string,
  email?: string,
  buyerId?: number | string,
  messageType?: string,
  emailType?: string,
  senderName?: string
) => {
  try {
    name = name?.length > 0 ? name : "user";
    email = email?.length > 0 ? email : "user@123gmail.com";

    const response = await axios.post(`${baseURL}/buyers/invite/exiting/agent/buyer/${agentId}/${buyerId}`, { name, email, message, type, propertyId, messageType, senderName, emailType });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
export const createEventByBuyer = async (buyerId: number, token: string, calendarEvents: any[]) => {
  try {
    if (!token) return notification("Unauthorized!", "error");
    const response = await axios.post(`${baseURL}/calendar/add-events/buyer/${buyerId}`, calendarEvents, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // return notification('Something Went Wrong!', 'error');
  }
};
export const updateEventByBuyer = async (
  eventId: number,
  startDate: Date,
  startTime: string,
  endDate: Date,
  endTime: string,
  title: string,
  recurringStatus: string,
  token: string,
  recuringStartDate?: Date,
  recuringEndDate?: Date
) => {
  try {
    if (!token) return notification("Unauthorized!", "error");

    const response = await axios.put(
      `${baseURL}/calendar/update-event/${eventId}`,
      {
        startDate,
        startTime,
        endDate,
        endTime,
        title,
        recurringStatus,
        recuringStartDate,
        recuringEndDate
      },
      getConfigWithToken(token)
    );
    return response.data;
  } catch (error) {
    console.error(error);
    // return notification('Something Went Wrong!', 'error');
  }
};

export const deleteEventBuyerService = async (token: string, eventId: number) => {
  try {
    if (!token) return notification("Unauthorized!", "error");
    const response = await axios.delete(`${baseURL}/calendar/delete-event/buyer/${eventId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
//  <! Please don't Delete this comment !>

// export const createEventByAgent = async (
//   buyerId:number,
//   startDate:Date,
//   startTime:string,
//   endDate:Date,
//   endTime:string,
//   description:string,
//   token: string
// ) => {
//   try {
//     if (!token) return notification("Unauthorized!", "error");
//     const response = await axios.post(
//       `${baseURL}/calendar/add-events/buyer/${buyerId}`,
//       { buyerId,
//         startDate,
//         startTime,
//         endDate,
//         endTime,
//         description },

// };
export const getBuyerColor = async (token: string, agentId: number) => {
  try {
    const response = await axios.get<{}, { data: BuyerColorCode[] }>(`${baseURL}/buyers/get-buyer-color/${agentId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

// CREATING AGENT NOTIFICATIONS WITH AGENT ROLE

export const createAgentNotification = async (buyerId: number | string, token: string, type: string, message: string, name?: string, email?: string) => {
  try {
    if (!token) return notification("Unauthorized!", "error");
    const response = await axios.post(`${baseURL}/buyers/notification/agent/${buyerId}`, { name, email, message, type }, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};
export const updateBuyerColor = async (payload: any) => {
  try {
    const response = await axios.put(`${baseURL}/buyers/update-buyer-color/${payload.buyerId}`, {
      colorCode: payload.colorCode,
      agentId: payload.agentId
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};

export const updateBuyer = async (buyerId: number, colorId: number) => {
  try {
    const response = await axios.patch(`${baseURL}/buyers/connect/${buyerId}/color/${colorId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
export const getRequestedPropertiesBuyer = async (agentId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/requested-properties/${agentId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

// GET AGENT NOTIFICATIONS

export const getAgentNotifications = async (token: string, propertyId?: number, email?: string, buyerId?: number) => {
  try {
    const tempEmail = email?.length > 0 ? email : "abc@123.com";
    const tempPropertyId = propertyId && propertyId > 0 ? propertyId : "-100";
    const tempBuyerId = buyerId && buyerId > 0 ? buyerId : "-100";

    const response = await axios.get(`${baseURL}/buyers/notifications/get/agent/${tempPropertyId}/${tempEmail}/${tempBuyerId}`, getConfigWithToken(token));

    return response?.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

// UPDATE AGENT NOTIFICATIONS

export const updateAgentNotification = async (notificationId: number, type: string, token: string) => {
  try {
    const response = await axios.put(`${baseURL}/buyers/notifications/agent/update/${notificationId}/${type}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// UPDATE BUYER NOTIFICATIONS

export const updateBuyerNotification = async (notificationId: number, type: string, token: string) => {
  try {
    const response = await axios.put(`${baseURL}/buyers/notifications/buyer/update/${notificationId}/${type}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Link BUYER WITH AGENT AFTER NOTIFICATION ACCEPTED BY AGENT

export const linkBuyerWithAgent = async (buyerId: number, token: string, type?: string) => {
  try {
    const response = await axios.patch(`${baseURL}/buyers/agent/${buyerId}/invitation`, { type }, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

//Accepet Agent Request by buyer

export const acceptAgentRequestByBuyerServices = async (agentId: number, buyerId: number) => {
  try {
    const response = await axios.patch(`${baseURL}/buyers/agent/${agentId}/connect/${buyerId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const linkSellerWithAgent = async (buyerId: number, agentId: number, sellerListingId: number) => {
  try {
    //Rmoveavble
    const response = await axios.patch(`${baseURL}/buyers/seller-agent/${buyerId}/invitation/${agentId}/${sellerListingId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Link BUYER WITH AGENT AFTER NOTIFICATION ACCEPTED BY BUYER

export const linkAgentWithBuyer = async (agentId: number, token: string) => {
  try {
    const response = await axios.patch(`${baseURL}/buyers/agent/${agentId}/invitation/buyer`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// INVITE BUYER BY AGENT || CREATING BUYER NOTIFICATIONS BY BUYER MAIL

export const createBuyerNotificationByMail = async (email: string, message: string, type: string, token: string, consumerType: string, propertyId: number) => {
  try {
    if (!token) return notification("Unauthorized!", "error");
    const response = await axios.post(`${baseURL}/buyers/notification/buyer/mail/${email}`, { message, type, consumerType, propertyId }, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // return notification('Something Went Wrong!', 'error');
  }
};
//get agent event
export const getBuyerCalendarEvents = async (buyerId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/calendar/all-events/${buyerId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};
// * Prefernece Agent Type : POST API

export const postPreferenceAgentType = async (payload: any, token: string) => {
  try {
    const response = await axios.post(`${baseURL}/listed-property-preference`, payload, getConfigWithToken(token));
    return response;
  } catch (error) {
    console.error(error);
  }
};
export const getFromViewPropertyCalendar = async (mlsId: number, token: string) => {
  try {
    const responce = await axios.get(`${baseURL}/propertyCalendar/all-events/${mlsId}`, getConfigWithToken(token));
    return responce;
  } catch (err) {
    console.error(err);
  }
};

export const postViewPropertyCalendar = async (calendarEvents: any[], agentId: number, token: string) => {
  try {
    const response = await axios.post(`${baseURL}/propertyCalendar/add-events/agent/${agentId}`, calendarEvents, getConfigWithToken(token));

    return response.data;
  } catch (error) {
    console.error(error);
    return { error: true, message: "something went wrong" };
  }
};

export const updateAgentStatusService = async (id: number, isAccepted: boolean, isRejected: boolean) => {
  try {
    const response = await axios.put(`${baseURL}/propertyCalendar/update/status/${id}`, { isAccepted, isRejected });

    return response.data;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};

export const updateAgentScheduledStatusService = async (id: number, isAccepted: boolean, isRejected: boolean) => {
  try {
    const response = await axios.put(`${baseURL}/best-route-property/update/scheduled/status/${id}`, { isAccepted, isRejected });

    return response.data;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};

export const updateViewPropertyCalendar = async (payload: any, token: string) => {
  try {
    const response = await axios.put(`${baseURL}/propertyCalendar/update/viewcalendar/${payload.viewPropertyCalendarId}`, payload, getConfigWithToken(token));

    return response.data;
  } catch (error) {
    console.error(error);
    return { error: true, message: "something went wrong" };
  }
};

// update Best Route Property Calendar
export const updateBestRoutePropertyCalendar = async (payload: any, token: string) => {
  try {
    const response = await axios.put(`${baseURL}/best-route-property/update/scheduled/event/${payload.bestRouteId}`, payload, getConfigWithToken(token));

    return response.data;
  } catch (error) {
    console.error(error);
    return { error: true, message: "something went wrong" };
  }
};
export const updateDgragEventData = async (dto: object, viewPropertyCalendarId: Number) => {
  try {
    const response = await axios.patch(` ${baseURL}/propertyCalendar/update/dragevents/info/${viewPropertyCalendarId}`, dto);

    return response.data;
  } catch (error) {
    console.error(error);
    return { error: true, message: "something went wrong" };
  }
};

export const createEventByAgent = async (
  agentId: number,
  startDate: Date,
  startTime: string,
  endDate: Date,
  endTime: string,
  title: string,
  recurringStatus: string,
  token: string,
  recuringStartDate?: Date,
  recuringEndDate?: Date
) => {
  try {
    if (!token) return notification("Unauthorized!", "error");
    const response = await axios.post(
      `${baseURL}/agentMain/add-events/agent/${agentId}`,
      {
        startDate,
        startTime,
        endDate,
        endTime,
        title,
        recurringStatus,
        recuringStartDate,
        recuringEndDate
      },
      getConfigWithToken(token)
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getViewPropertyCalendarByID = async (mlsId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/propertyCalendar/all-events/${mlsId}`, getConfigWithToken(token));

    return response?.data;
  } catch (error) {
    console.error(error);
    return { error: true, message: "something went wrong" };
  }
};
export const getViewPropertyCalendarByPropID = async (mlsId: number, currDate: any, token: string, role: string) => {
  try {
    const response = await axios.get(`${baseURL}/propertyCalendar/all-events/for-seller/${mlsId}/${currDate}/${role}`, getConfigWithToken(token));
    return response?.data;
  } catch (error) {
    console.error(error);
    return { error: true, message: "Something went wrong" };
  }
};
export const getViewPropertyCalendarByBuyerID = async (buyerId: number, token: string, role: string, type: string) => {
  try {
    const response = await axios.get(`${baseURL}/propertyCalendar/all-events/requested/buyer/${buyerId}/${role}/${type}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    return { error: true, message: "something went wrong" };
  }
};

export const getViewPropertyCalendarScheduledEvent = async (buyerId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/propertyCalendar/all-events/scheduled/buyer/${buyerId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    return { error: true, message: "something went wrong" };
  }
};
export const getPreviousViewPropertyCalendarByBuyerID = async (buyerId: number, currDate: any, token: string, type: string) => {
  try {
    const response = await axios.get(`${baseURL}/propertyCalendar/all-events/previousProp/buyer/${buyerId}/${currDate}/${type}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    return { error: true, message: "something went wrong" };
  }
};

export const deletePropertyCalendarByID = async (viewPropertyCalendarId: number | string, token?: string, messageType?: string) => {
  try {
    const response = await axios.delete(`${baseURL}/propertyCalendar/view-property-calendar/delete/event/${viewPropertyCalendarId}/${messageType ?? null}`);

    return response.data;
  } catch (error) {
    console.error(error);
    return { error: true, message: "something went wrong" };
  }
};

//Delete scheduled Property

export const deleteScheduledPropertyByID = async (bestRouteId: number | string, token?: string) => {
  try {
    const response = await axios.delete(`${baseURL}/best-route-property/delete/scheduled/event/${bestRouteId}`);
    return response?.data;
  } catch (error) {
    console.error(error);
    return { error: true, message: "something went wrong" };
  }
};

// * Prefernece Agent Type : GET API
export const postintoListedPreference: any = async (
  showingType: string,
  acceptRequest: string,
  minNoticePeriod: string,
  maxDurationShowing: string,
  confirmationMessage: string,
  rejectionMessage: string,
  mlsId: number,
  agentId: number,
  token: string
) => {
  try {
    const responce = await axios.post(
      `${baseURL}/listed-property-preference/postmlsid/${mlsId}`,
      {
        showingType,
        acceptRequest,
        minNoticePeriod,
        maxDurationShowing,
        confirmationMessage,
        rejectionMessage,
        agentId
      },
      getConfigWithToken(token)
    );
    return responce;
  } catch (err) {
    console.error(err);
  }
};

export const getPreferencesAgentType: any = async (token: string, mlsId: any) => {
  try {
    const response = await axios.get(`${baseURL}/listed-property-preference/preferences/info/${mlsId}`, getConfigWithToken(token));
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const putPreferencesAgentType = async (payload: any, token: string, mlsId: any) => {
  try {
    const response = await axios.put(`${baseURL}/listed-property-preference/update/setting/info/${mlsId}`, payload, getConfigWithToken(token));
    return response;
  } catch (error) {
    console.error(error);
  }
};

// CREATE BUYER NOTIFICATIONS BY BUYER ID

export const createBuyerNotificationById = async (
  buyerId: number,
  message: string,
  type: string,
  token: string,
  consumerType: string,
  propertyId: number,
  messageType?: string,
  agentName?: string,
  buyerName?: string,
  buyerEmail?: string
) => {
  try {
    if (!token) return notification("Unauthorized!", "error");
    const response = await axios.post(
      `${baseURL}/buyers/notification/buyer/id/${buyerId}`,
      { message, type, consumerType, propertyId, messageType, agentName, buyerName, buyerEmail },
      getConfigWithToken(token)
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

// GET BUYERS NOTIFICATION BY MAIL

export const getBuyerNotificationsByEmail = async (email: string, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/buyers/notifications/email/${email}`, getConfigWithToken(token));

    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

// GET BUYER NOTIFICATION BY ID

export const getBuyerNotificationsById = async (buyerId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/buyers/notifications/id/${buyerId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

// * Previous Showings GET API

export const getPreviousShowingsAgentType: any = async (token: string, mlsId: any, start: any, end: any) => {
  try {
    const response = await axios.get(`${baseURL}/properties/previous-showing/${mlsId}/${start}/${end}`, getConfigWithToken(token));
    return response;
  } catch (error) {
    console.error(error);
  }
};
// GET MLS SELLER PROPERTIES ---------------
export const getSellerProperties = async (mlsId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/properties/listing/${mlsId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

//POST SELLER PROPERTY ----------
export const createSellerProperties = async (payload: any, token: string) => {
  try {
    const response = await axios.post(`${baseURL}/listedSellerProperty`, payload, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

// GET ALL LISTED PROPERTY ----------
export const getALLSellerListedProperties = async (agentId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/listedSellerProperty/all-listed-properties/${agentId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

// GET ALL LOC BOX LISTED PROPERTIES By CITY NAME ----------
export const getSearchLocBoxPropertiesByCity = async (city: string) => {
  try {
    const response = await axios.get(`${baseURL}/listedSellerProperty/all/listed-properties/city/${city}`);
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

//GET ALL AGENT PREFFERED
export const getAgentPrefferedById = async (propertyId: number, agentId: number) => {
  try {
    const response = await axios.get(`${baseURL}/listedSellerProperty/agentPreffered/propId/${propertyId}/agentId/${agentId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// UPDATE SELLER  PROPERTY DETAILS----------
export const updateSellerDetails = async (mlsId: number, payload: any, token: string) => {
  try {
    const response = await axios.patch(
      `${baseURL}/listedSellerProperty/update/seller/details/${mlsId}`,
      payload
      // getConfigWithToken(token)
    );
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

//UPDATE Listed Prop Prefernce Id
export const updateListedSellerProperty = async (mlsId: number, agentId: number, insertId: number) => {
  try {
    const response = await axios.patch(
      `${baseURL}/listedSellerProperty/update/ListedPrefernceId/${mlsId}`,
      { agentId, insertId }
      // getConfigWithToken(token)
    );
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

// UPDATE SELLER  PROPERTY DETAILS----------
export const getAddedProperty = async (mlsId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/listedSellerProperty/added/property/${mlsId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

// UPDATE SELLER  PROPERTY DETAILS----------
export const getListingAddedProperty = async (listingId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/listedSellerProperty/listing/property/${listingId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

// UPDATE SELLER  PROPERTY DETAILS----------
export const getSellProperty = async (sellerEmail: string, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/listedSellerProperty/property/${sellerEmail}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

export const getAllUpcommingTours = async (agentId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/visiting-properties/get-upcomingtour/${agentId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    throw new Error(parseError(error));
  }
};

export const getAllScheduledUpcommingTours = async (token: string, type: string) => {
  try {
    const response = await axios.get(`${baseURL}/best-route-property/get/all/scheduled/upcomming/tours/${type}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    throw new Error(parseError(error));
  }
};

export const patchAgentAccountSettings = async (token: string, obj: any, agentId: number, currentView?: string) => {
  try {
    const response = await axios.patch(`${baseURL}/agent-account-settings/update/setting/info/${agentId}`, obj, getConfigWithToken(token));
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const updateRequestedProperty = async (payload: any, buyerId: number, token: string, propertyId: number) => {
  try {
    const response = await axios.put(`${baseURL}/requested-properties/update/${propertyId}/${buyerId}`, payload, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const getUpcommingToursLinkedPr = async (buyerId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/linked-properties/${buyerId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};
export const getRequestedPropertiesById = async (propertyId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/requested-properties/getby-id/${propertyId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

//get Best Route Properties By property Id

export const getBestRoutePropertiesById = async (propertyId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/best-route-property/bestRoute/get-by-id/listed/${propertyId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};
export const getLinkedpropertyById = async (propertyId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/linked-properties/property/${propertyId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};
export const getViewPropertyCalenderById = async (propertyId: number, token: string, agentId?: number) => {
  try {
    const response = await axios.get(`${baseURL}/propertyCalendar/all-events/${propertyId}/${agentId}`, getConfigWithToken(token));

    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const getFreeSlotsPropertyCalendar = async (mlsId: number, agentId: number) => {
  try {
    const response = await axios.get(`${baseURL}/propertyCalendar/free-slots/propId/${mlsId}/agentId/${agentId}`);
    return response?.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const getSellUpcommingTours = async (mlsId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/properties/sell/${mlsId}`, getConfigWithToken(token));
    return response?.data ?? null;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const getAllPropertyCommentsForBuyerService = async (token: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${baseURL}/properties/get/comments`, getConfigWithToken(token));
      return resolve(response?.data);
    } catch (err) {
      console.error(err);
      return reject(err);
    }
  });
};

export const getAllPropertyCommentsForBuyerFromAgentService = async (token: string, buyerId: number | string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${baseURL}/properties/get/comments/${buyerId}`, getConfigWithToken(token));
      return resolve(response?.data);
    } catch (err) {
      console.error(err);
      return reject(err);
    }
  });
};

//CHECK IS SELLER INVITED..........................

export const checkIsSellerInvitedService = async (propertyId: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${baseURL}/listedSellerProperty/check/seller/${propertyId}`);
      return resolve(response?.data);
    } catch (error) {
      console.error(error);
      return reject(error);
    }
  });
};

//CHECK IS LISTED PROPERTY..........................

export const checkIsListedPropertyService = async (propertyId: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${baseURL}/listedSellerProperty/check/listed/${propertyId}`);
      return resolve(response?.data);
    } catch (error) {
      console.error(error);
      return reject(error);
    }
  });
};

//CHECK IS LISTED PROPERTY FOR LISTING AGENT..........................

export const checkIsListedPropertyServiceForListingAgent = async (propertyId: number, agentId: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${baseURL}/propertyCalendar/check/${agentId}/listed/agent/${propertyId}`);
      return resolve(response?.data);
    } catch (error) {
      console.error(error);
      return reject(error);
    }
  });
};

// SEND MAIL BY PROPERTY SHARE

export const PropertyShareMail = async (
  recipientMail: string,
  userMail: string,
  propertyLink: string,
  message: string,
  imageurl: string,
  propertyAddress: string,
  listingId: any,
  listingAgentName: string
) => {
  try {
    const response = await axios.post(
      `${baseURL}/properties/shareMail/mail`,
      {
        recipientMail,
        userMail,
        propertyLink,
        message,
        imageurl,
        propertyAddress,
        listingId,
        listingAgentName
      }
      // getConfigWithToken(token),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    // return notification('Something Went Wrong!', 'error');
  }
};

/////////////////////////////Certifactes//
export const certificates = async (url: string, name: string, agentId: number, token: string) => {
  try {
    const response = await axios.post(
      `${baseURL}/certificate-designations`,
      {
        url,
        name,
        agentId
      },
      getConfigWithToken(token)
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const getCertificates = async (agentId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/certificate-designations/certificate/${agentId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const getListedSellerDetails = async (email: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${baseURL}/buyers/listed/buyer/details/${email}`);
      return resolve(response?.data);
    } catch (error) {
      console.error(error);
      return reject(error);
    }
  });
};
//Post Message

export const postMessageToUser = async (destination_Number: string, text: string) => {
  try {
    const response = await axios.post(`${baseURL}/plivo`, {
      destination_Number,
      text
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

interface A {
  cityNameKey: string;
  placeURL: string;
  latitude: number;
  longitude: number;
  regionID: number;
  open_time: string;
  close_time: string;
  service_time: number;
  position: number;
}

export const AddLinkedProperties = async (first_name: string, last_name: string, organization: string, travel_time: number, locations: A[], token: string) => {
  try {
    const response = await axios.post(
      `${baseURL}/properties/addproperty`,
      {
        first_name,
        last_name,
        organization,
        travel_time,
        locations
      },
      getConfigWithToken(token)
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const makePayments = async (payload: any, token: string) => {
  try {
    const response = await axios.post(
      `${baseURL}/square/create/subscription/plan`, //square/make/payment
      payload,
      getConfigWithToken(token)
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const makePaymentsTest = async (agentName: string, token: string) => {
  try {
    const response = await axios.post(
      `${baseURL}/square/create/subscription/test`, //square/make/payment
      {agentName},
      getConfigWithToken(token)
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

//Update Payment

export const updatePayments = async (payload: any, token: string) => {
  try {
    const response = await axios.post(`${baseURL}/square/update/payment`, payload, getConfigWithToken(token));
    return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

//GET PAYMENT CARD DETAILS

export const getPaymentCardDetailsServices = async (token: string) => {
  try {
    const responce = await axios.get(`${baseURL}/card/payment-details`, getConfigWithToken(token));
    return responce?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

//DELETE PAYMENT CARD DETAILS

export const removePaymentCardDetailsService = async (payment_card_id: number, token: string, agentId: number) => {
  try {
    const response = await axios.patch(`${baseURL}/card/remove/added/card/${payment_card_id}/${agentId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

//UPDATE PAYMENT CATD DETAILS

export const updatePaymentCardDetailsService = async (token: string, payment: PaymentCard, payment_card_id: number) => {
  try {
    const response = await axios.put(`${baseURL}/card/update/${payment_card_id}`, payment, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    // throw new Error(parseError(error));
  }
};

//UPDATE PAYMENT CATD PRIMARY

export const updatePrimaryCardServices = async (token: string, cardDetailsArr: any) => {
  try {
    const response = await axios.put(`${baseURL}/card/update/agent/primary`, cardDetailsArr, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};

export const fromSimplyRetsForMlsId = async (mlsId: string) => {
  try {
    const responce = await axios.get(
      `${baseURL}/properties/agents/${mlsId.toUpperCase()}`
      // getConfigWithToken(token)
    );
    return responce.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const patchCalendarView = async (token: string, agentId: number, currentView?: string) => {
  try {
    const response = await axios.patch(`${baseURL}/agent-account-settings/update/CalendarView/${agentId}`, { currentView: currentView }, getConfigWithToken(token));
    return response;
  } catch (err) {
    console.error(err);
    return err;
  }
};
export const patchBuyerCalendarView = async (token: string, buyerId: number, currentView?: string) => {
  try {
    const response = await axios.patch(
      `${baseURL}/buyer-account-settings/update/buyercalendarview/${buyerId}`,
      { currentView: currentView },
      // currentView,
      getConfigWithToken(token)
    );
    return response;
  } catch (err) {
    console.error(err);
    // return reject(err);
    return err;
  }
};
export const freePlanActivated = async (payload: any, token: string) => {
  try {
    const responce = await axios.post(`${baseURL}/payments`, payload, getConfigWithToken(token));
    return responce.data;
  } catch (error) {
    console.error(error);
  }
};

export const getActivatedPlan = async (agentId: number, token: string) => {
  try {
    const responce = await axios.get(`${baseURL}/payments/details/${agentId}`, getConfigWithToken(token));
    return responce.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateActivatedPlan = async (idpayment_card: number, token: string, type: string) => {
  try {
    const responce = await axios.put(`${baseURL}/payments/update/details/${idpayment_card}`, { type }, getConfigWithToken(token));
    return responce.data;
  } catch (error) {
    console.error(error);
  }
};

export const updatePaymentReminder = async (agentId: number, paymentReminder: boolean, token: string) => {
  try {
    const response = await axios.patch(`${baseURL}/agent-account-settings/update/paymentReminder/${agentId}`, { paymentReminder: paymentReminder }, getConfigWithToken(token));
    return response;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const getBestRoute = async (buyerId: number, token: string, status?: string) => {
  try {
    const upcomingStatus: string = status?.length > 0 ? status : "saved";
    const response = await axios.get(`${baseURL}/best-route-property/bestRoute/${buyerId}/${upcomingStatus}`, getConfigWithToken(token));
    return response?.data;
  } catch (error) {
    console.error(error);
  }
};

export const postBestRoute = async (bestRouteData: any[], token: string, buyerAgentEmail?: string, buyerAgentName?: string, buyerName?: string) => {
  try {
    if (!token) return notification("Unauthorized!", "error");
    const response = await axios.post(
      `${baseURL}/best-route-property/addBestProperty/${bestRouteData?.[0]?.formId}`,
      { bestRouteData, buyerAgentEmail, buyerAgentName, buyerName },
      getConfigWithToken(token)
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const updateBestRoute = async (bestRouteData: any[], formIdforUpdate: number, token: string) => {
  try {
    if (!token) return notification("Unauthorized!", "error");

    const response = await axios.patch(
      // `${baseURL}/best-route-property/updateBestProperty/${bestRouteData?.[0]?.formId}`
      `${baseURL}/best-route-property/updateBestProperty/${formIdforUpdate}`,
      bestRouteData,
      getConfigWithToken(token)
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const deleteBestRouteByFormId = async (propertyId: number, formId: number, token: string) => {
  try {
    if (!token) return notification("Unauthorized!", "error");

    const response = await axios.delete(`${baseURL}/best-route-property/deleteProperty/${propertyId}/formId/${formId}`, getConfigWithToken(token));
    return response?.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const UpdateTimeStampBestRoute = async (propertyId: number, formId: string, ReScheduleStartTime: string, ReScheduleEndTime: string, type: string) => {
  try {
    const response = await axios.patch(`${baseURL}/best-route-property/updateTimeStamp/scheduled/${propertyId}/${formId}`, { ReScheduleStartTime, ReScheduleEndTime, type });
    return response?.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const deleteAgentAccount = async (agentId: number, token: string) => {
  try {
    const response = await axios.delete(`${baseURL}/agents/delete-account/${agentId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    return { error: true, message: "something went wrong" };
  }
};

export const deleteBuyerAccount = async (token: string) => {
  try {
    const response = await axios.delete(`${baseURL}/buyers/delete-account`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    return { error: true, message: "something went wrong" };
  }
};

export const PauseAgentAccount = async (agentId: number, value: boolean, token: string) => {
  try {
    const response = await axios.put(`${baseURL}/agents/pause-account/${agentId}`, { value }, getConfigWithToken(token));
    return response?.data;
  } catch (error) {
    console.error(error);
  }
};
export const getAgentById = async (agentId: number, token?: string) => {
  try {
    const response = await axios.get(`${baseURL}/agents/get/single/agent/${agentId}`);
    return response;
  } catch (error) {
    console.error(error);
    return notification("Something Went Wrong!", "error");
  }
};

export const listedAgentShareMail = async (agentEmail: string, message: string, username: string, token: string) => {
  try {
    const response = await axios.post(
      `${baseURL}/agents/shareAgentMail/mail`,
      {
        agentEmail,
        message,
        username
      },
      getConfigWithToken(token)
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    return;
  }
};
export const buyerCalendarViewSetting = async (token: string, CalendarView: any, obj?: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${baseURL}/buyer-account-settings`, { CalendarView, obj }, getConfigWithToken(token));
      return resolve(response);
    } catch (err) {
      console.error(err);
      return reject(err);
    }
  });
};
export const agentCalendarChangeView = async (token: string, agentId: number, changeView: string, obj?: any) => {
  try {
    const response = await axios.post(`${baseURL}/agent-account-settings/updateagntaccount/${agentId}`, { changeView, obj }, getConfigWithToken(token));
    return response;
  } catch (err) {
    console.error(err);
    // return reject(err);
    return err;
  }
  // });
};
export const totalViewsCountServices = async (propertyId: number) => {
  try {
    const response = await axios.post(`${baseURL}/total-views`, { propertyId });
    return response.data;
  } catch (error) {
    return notification("Something Went Wrong!", "error");
  }
};

export const getTotalViewsCount = async (propertyId: number) => {
  try {
    const response = await axios.get(`${baseURL}/total-views/${propertyId}`);
    return response?.data;
  } catch (error) {
    console.error(error);
    return notification("Something Went Wrong!", "error");
  }
};

// get all view property calendar

export const getAgentBuyerPropertyCalendar = async (agentId: number, type: string) => {
  try {
    const response = await axios.get(`${baseURL}/propertyCalendar/eventagentid/${agentId}/${type}`);

    return response?.data;
  } catch (error) {
    console.error(error);
    return notification("Something went worng", "error");
  }
};

export const resendEmailLinkService = async (email: string, name: string) => {
  try {
    const response: any = await axios.post(`${baseURL}/agents/resend/link`, {
      email,
      name
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const getBestRouteByAgentId = async (agentId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/best-route-property/bestRouteByAgentId/${agentId}`, getConfigWithToken(token));
    return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getAgentBuyerPropertyCalendarByBuyerId = async (buyerId: number) => {
  try {
    const response = await axios.get(`${baseURL}/propertyCalendar/eventbuyerid/${buyerId}`);
    return response?.data;
  } catch (error) {
    console.error(error);
    return notification("Something went worng", "error");
  }
};

export const getPropertyOpenTimeAndCloseTime = async (propertyId: number, token: string) => {
  try {
    const response = await axios.get(`${baseURL}/properties/open_close_time/${propertyId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);

    throw new Error(parseError(error));
  }
};

// GET ALL VIEWED PROPERTIES

export const getAllViewedPropertieService = async () => {
  try {
    const response = await axios.get<Listing[]>(`${baseURL}/total-views/all/viewed`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

// GET ALL VIEWED PROPERTIES

export const getAllFavoritePropertieService = async (buyerId?: number) => {
  try {
    const response = await axios.get<Listing[]>(`${baseURL}/properties/all/favrites`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const getListingPropertyIdByBuyerServie = async (token: string, email: string) => {
  try {
    const response = await axios.get<Listing[]>(`${baseURL}/listedSellerProperty/listing/property/id/${email}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const checkIfPropertyIsLinkedService = async (propertyId: number, token: string, id: number) => {
  try {
    const response = await axios.get(`${baseURL}/linked-properties/${propertyId}/linked/property/${id}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteScheduledToursService = async (token: string, formId: number, listingAgentName: string, buyerAgentEmail: string, type: string) => {
  try {
    const response = await axios.delete(`${baseURL}/visiting-properties/delete/scheduled-event/${formId}/${listingAgentName}/${buyerAgentEmail}/type/${type}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const deleteSavedTours = async (token: string, formId: number) => {
  try {
    const response = await axios.delete(`${baseURL}/visiting-properties/delete/saved-event/${formId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const syncBuyerEvent = async (id: number, buyerGoogleEventSynced: boolean) => {
  try {
    const response = await axios.put(`${baseURL}/best-route-property/update/event/status/${id}`, { buyerGoogleEventSynced });

    return response.data;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};

export const deleteCertificates = async (agentId: number, certificateId: number, token: string) => {
  try {
    const response = await axios.delete(`${baseURL}/certificate-designations/certificate/delete/${agentId}/${certificateId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const getActivatedSubscriptionPlan = async (agentId: number, token: string) => {
  try {
    const responce = await axios.get(`${baseURL}/payment-subcription/subscription/${agentId}`);
    return responce.data;
  } catch (error) {
    console.error(error);
  }
};

export const createActivatedSubscriptionPlan = async (
  agentId: number,
  subscriptionId: string,
  planId: string,
  status: string,
  startDate: Date,
  price: number,
  planName: string,
  activePlan: string,
  canceledDate: Date,
  agentMlsId: string,
  agentEmail: string
) => {
  try {
    const responce = await axios.post(`${baseURL}/payment-subcription/create`, {
      agentId,
      subscriptionId,
      planId,
      status,
      startDate,
      price,
      planName,
      activePlan,
      canceledDate,
      agentMlsId,
      agentEmail
    });
    return responce.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateSubscriptionPlan = async (payment_subscription_id: number, status: string, planName: string, activePlan: string, price: number) => {
  try {
    const response = await axios.put(`${baseURL}/payment-subcription/update/expire/${payment_subscription_id}`, { status, planName, activePlan, price });
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};

export const pauseSubscriptionPaln = async (agentId: number) => {
  try {
    const response = await axios.post(`${baseURL}/square/pause-subscription-plan/${agentId}`);
    return response.data;
  } catch (error) {
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};

export const deleteSubscriptionPaln = async (agentId: number) => {
  try {
    const response = await axios.post(`${baseURL}/square/delete-subscription-plan/${agentId}`);
    return response.data;
  } catch (error) {
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};

export const resumeSubscriptionPaln = async (agentId: number) => {
  try {
    const response = await axios.post(`${baseURL}/square/resume-subscription-plan/${agentId}`);
    return response.data;
  } catch (error) {
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};

export const removePropertyFromViewedForBuyerService = async (propertyId: number | string, buyerId: number | string) => {
  try {
    const response = await axios.patch(`${baseURL}/properties/${propertyId}/remove-viewed-property/buyer/${buyerId}`);
    return response.data;
  } catch (error) {
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};

export const removePropertyFromViewedFavoritesForBuyerService = async (propertyId: number | string, buyerId: number | string) => {
  try {
    const response = await axios.patch(`${baseURL}/properties/${propertyId}/remove-viewed-favorites/buyer/${buyerId}`);
    return response.data;
  } catch (error) {
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};

export const completedShowingsPropertyStatsSingle = async (propertyId: number | string) => {
  try {
    const responce = await axios.get(`${baseURL}/propertyCalendar/completed/showings/property/stats/${propertyId}`);
    return responce.data;
  } catch (error) {
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};


export const completedShowingsPropertyStatsTour = async (propertyId: number | string, type: string) => {
  try {
    const responce = await axios.get(
      `${baseURL}/best-route-property/completed/showings/property/stats/${propertyId}/${type}`,
    );
    return responce.data;
  } catch (error) {
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};

export const activateFreePlan = async (agentId: number, token: string) => {
  try {
    const response = await axios.post(`${baseURL}/square/activate-free-plan/${agentId}`, getConfigWithToken(token));
    return response.data;
  } catch (error) {
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};

export const createCronofyService = async (
  user_id: number,
  name: string,
  accessToken: string,
  expiresIn: string,
  refreshToken: string,
  sub: string,
  profileId: string,
  profileName: string,
  providerService: string,
  role: string,
) => {
  try {
    const response = await axios.post(`${baseURL}/cronofyTokens/create`, {
      user_id,
      name,
      accessToken,
      expiresIn,
      refreshToken,
      sub,
      profileId,
      profileName,
      providerService,
      role
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const createUserEmailsService = async (
  user_id: number,
  name: string,
  personal_email: string,
  role: string
) => {
  try {
    const response = await axios.post(`${baseURL}/userEmails/create`, {
      user_id,
      name,
      personal_email,
      role
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const createUsersService = async (
  name: string,
  email: string,
  password: string,
  agentId: number,
  buyerId: number
) => {
  try {
    const response = await axios.post(`${baseURL}/userEmails/create`, {
      name,
      email,
      password,
      agentId,
      buyerId
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const deleteUserEmailsService = async (email: string) => {
  try {
    const response = await axios.delete(`${baseURL}/userEmails/delete/${email}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(parseError(error));
  }
};

export const getUserEmailByIdService = async (userId: number) => {
  try {
    const responce = await axios.get(
      `${baseURL}/userEmails/user/email/${userId}`,
    );
    return responce.data;
  } catch (error) {
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};

export const getCronofyReqTokenService = async (code: string) => {
  try {
    const responce = await axios.get(
      `${baseURL}/cronofyTokens/cronofy/token/${code}`,
    );
    return responce.data;
  } catch (error) {
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};

export const getCalendarId = async (access_token: string) => {
  try {
    const responce = await axios.get(
      `${baseURL}/cronofyTokens/cronofy/calendar/${access_token}`,
    );
    return responce.data;
  } catch (error) {
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};


export const insertCronofyCalendarEvents = async (body: any) => {
  try {
    const responce = await axios.post(
      `${baseURL}/cronofyTokens/cronofy/calendar/events`, body
    );
    return responce.data;
  } catch (error) {
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};

export const getCronofyAccessToken = async (user_id: number) => {
  try {
    console.log("user_id", user_id)
    const responce = await axios.get(
      `${baseURL}/cronofyTokens/cronofy/accessToken/${user_id}`,
    );
    return responce.data;
  } catch (error) {
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};

export const getSubscriptionPlanById = async (plan_id: string, token: string) => {
  try {
    console.log("getSubscriptionPlanById", plan_id)
    const responce = await axios.get(
      `${baseURL}/square/get-subscription-plan/${plan_id}`,
      getConfigWithToken(token)
    );
    return responce.data;
  } catch (error) {
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};

export const checkPendingSubscriptionService = async (agentId: number) => {
  try {
    const responce = await axios.get(
      `${baseURL}/payment-subcription/check-pending/status/${agentId}`,
    );
    return responce.data;
  } catch (error) {
    return {
      success: false,
      msg: "Something went wrong , Please try again ."
    };
  }
};