import PageContent from '../../layout/PageContent'
import Cancel from './canceltour'
import Kevin from './Kevinheardsib'
import Regina from './ReginaGeorge'
import Rescheduling from './rescheduingModal'
// import RoutingTour from './RouteTour'
// import SchedulesModal from './schedulingStatus'
const ModalDisplay = () => {
  return (
    <>
      <h1>Hello ths is the page for modal display </h1>
      {/* <Kevin /> */}
      {/* <Regina /> */}
      {/* <Rescheduling /> */}
      {/* <RoutingTour /> */}
      {/* <SchedulesModal /> */}
      {/* <Cancel /> */}
    </>
  )
}
export default ModalDisplay
