/* eslint-disable jsx-a11y/alt-text */
import styled from "@emotion/styled";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Button, DatePicker, Popover, TimePicker } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Close from "../../assets/CalendarPopover/Button - Close.svg";
import { getAllViewPropertyByAgetntId } from "../../components/AgentCalendar/ulits";
import { notification } from "../../components/notification";
import CustomSpinner from "../../components/spinner/CustomSpinner";
import UserContext from "../../contexts/UserContext";
import { getAgentAccountSettings } from "../../services/NavihomeService";
import "./styles.css";
interface CathyCalendarProps {
  setAllInputData?: (value: string) => void;
  agentName?: string;
  calendarRef1?: any;
  dayChange?: any;
  setStartTimeForTimeStamp?: any;
  allInputData?: any;
  setShowEventBuyerCal?: any;
  showEventBuyerCal?: boolean;
  setShowEventAgentCal?: any;
  isOn?: boolean;
}

const CustomDatePicker = styled(DatePicker)`
  &.ant-picker {
    border-radius: 10px !important;
    border: 1px solid #004876;
    // background-color: green !important;
    width: 100%;
  }
  .ant-picker-input > input {
    margin-left: 1rem;
  }
`;
const HeaderWrapper = styled.div`
  .fc .fc-toolbar.fc-header-toolbar {
    background: #365073;
    margin-bottom: 0;
  }
`;

const CustomheadingThird = styled.p`
  color: #004876;
  font-weight: 800;
  padding-bottom: 10px;
`;
const ButtonWrapper = styled.div`
  .fc-button-primary {
    background: #365073 !important;
    border-color: #365073 !important;
  }
`;

const HederTitleWrapper = styled.div`
  .fc-toolbar-title {
    color: white !important;
  }
  .fc-icon {
    color: white !important;
  }
`;
const DayColorWrapper = styled.div`
  .fc-col-header .fc-col-header-cell-cushion {
    color: black;
  }
`;

const CustomTimePicker = styled(TimePicker.RangePicker)`
  &.ant-picker-range {
    padding-left: 20px !important;
    /* background-color: yellow !important; */
  }
  .ant-picker-suffix {
    position: absolute;
    left: 1%;
    /* background-color: red; */
    /* color: yellow; */
  }
  &.ant-picker {
    border-radius: 8px !important;
    border: 1px solid #004876;
    /* background-color: red !important; */
  }
`;

const CustomDiv = styled.div`
  border-radius: 20px;
  top: 50px;
  right: 50px;
  /* border: 4px solid blue; */
`;
const CustomDateDiv = styled.div`
  margin-bottom: 10px;
`;

const CustomPara = styled.p`
  font-weight: 400;
`;
const CustomPopover = styled(Popover)`
  position: absolute;
  top: 40rem !important;
  left: 35rem !important;
  /* background-color: red !important; */
  @media screen and (max-width: 650px) {
    top: 40rem !important;
    left: 15rem !important;
  }
`;
const CustomButton = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  /* background-color: red; */
  /* width: 100%; */
`;

const CathyCalendar: React.FC<CathyCalendarProps> = ({
  setAllInputData,
  agentName = "",
  calendarRef1,
  dayChange,
  setStartTimeForTimeStamp,
  allInputData,
  isOn,
}) => {
  const { userState } = useContext(UserContext);
  let [offCalHours, setOffCalHours] = useState({
    startTime: "",
    endTime: "",
  });
  const [agentAccountSettings, setAgentAccountSettings] = useState({
    markWeekends: [],
    workingHoursFrom: "",
    workingHoursTo: "",
  });
  const [newEvent, setEvent] = useState<any>([]);
  const [popOverVisible, setPopOverVisible] = useState(false);
  const [Stime, setStime] = useState<any>("");
  const [Etime, setEtime] = useState<any>("");
  const [Edate, setEdate] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [eventFilter, setEventFilter] = useState<Array<any>>([]);
  const timeOnChange = (value: any) => {
    console.log(value, "timeOnChangetimeOnChange");
    
    setStime(value ? moment(value?.[0]?._d, "HH:mm a") : null);
    setEtime(value ? moment(value?.[1]?._d, "HH:mm a") : null);
  };
  const dateChange = (value: any) => {
    console.log(value, "timeOnChangetimeOnChange");
    let dateString = moment(value).format("MM-DD-YYYY");
    setEdate(value ? new Date(dateString) : null);
  };

  const isResponsive = useMediaQuery({ maxWidth: 340 });

  const content = (
    <CustomDiv>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "5px",
        }}>
        <p>Tour Details</p>
        <img
          src={Close}
          style={{
            color: "#004876",
            cursor: "pointer",
          }}
          onClick={() => {
            setPopOverVisible(false);
            setEdate(null);
            setEtime(null);
            setAllInputData("");
          }}
        />
      </div>
      <CustomDateDiv>
        <CustomPara>
          Date <sup>*</sup>
        </CustomPara>
        <CustomDatePicker
          value={Edate ? moment(Edate, "MM-DD-YYYY") : null}
          format={"MM-DD-YYYY"}
          onChange={(value) => {
            dateChange(value);
          }}
        />
      </CustomDateDiv>

      <div>
        <CustomPara>
          Time <sup>*</sup>
        </CustomPara>
        <CustomTimePicker
          value={[moment(Stime, "HH:mm:A"), moment(Etime, "HH:mm:A")]}
          format={"hh:mm A"}
          onChange={(value) => {
            timeOnChange(value);
          }}
        />
      </div>
    </CustomDiv>
  );

  let isOffHours = (date: Date) => {
    let day = date?.toDateString()?.split(" ")?.[0];
    if (agentAccountSettings?.markWeekends?.includes(day)) {
      return true;
    } else {
      return false;
    }
  };

  const getAllEvents = async () => {
    try {
      setIsLoading(true);
      const events: any = await getAllViewPropertyByAgetntId(
        userState?.agent?.agentId,
        userState?.agent?.token,
        "calendar",
        userState
      );
      const acceptedOnly = events?.filter((item: any) => item.isAccepted);
      setEventFilter(acceptedOnly);
      setIsLoading(false);
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  useEffect(() => {
    if (Stime && Etime && Edate) {
      let startDate = moment(Stime, "MM-DD-YYYY").format("MM-DD-YYYY");
      let currentDate = moment(moment(), "MM-DD-YYYY").format("MM-DD-YYYY");
      const date = moment(startDate, "MM-DD-YYYY").format("MM-DD-YYYY")

      if (date >= currentDate) {
        let endDate = moment(Etime).format("MM-DD-YYYY");
        if (startDate !== endDate) {

          setAllInputData(
            moment(Stime).format("MM-DD-YYYY") +
            "," +
            moment(Stime).format("hh:mm a") +
            "-" +
            moment(Stime).endOf("day").format("hh:mm a")
          );
        } else {
          // moment.utc(Edate).format("MM-DD-YYYY") +
          setAllInputData(
            moment(Edate).format("MM-DD-YYYY") +
            "," +
            moment(Stime).format("hh:mm a") +
            "-" +
            moment(Etime).format("hh:mm a")
          );
        }
        setStartTimeForTimeStamp(moment(Stime).format("HH:mm"));
      } else {
        return notification("Please select future date", "error");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Stime, Etime, Edate]);

  useEffect(() => {
    let startTime = agentAccountSettings?.workingHoursFrom
      ?.split(" ")?.[0]
      .split(":")?.[0];
    let endTime = agentAccountSettings?.workingHoursTo
      ?.split(" ")?.[0]
      .split(":")?.[0];

    setOffCalHours((offCalHours) => ({
      ...offCalHours,
      startTime: startTime,
      endTime: endTime,
    }));
  }, [agentAccountSettings]);

  useEffect(() => {
    getAgentAccountSettings(userState?.agent?.agentId).then((value: any) => {
      let data = value?.[0];
      let weekends = data?.markWeekends?.split(",");

      setAgentAccountSettings((agentAccountSettings) => ({
        ...agentAccountSettings,
        markWeekends: weekends,
        workingHoursFrom: data?.workingHoursfrom,
        workingHoursTo: data?.workingHourTo,
      }));
    });
    getAllEvents();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let year = Number(moment(allInputData, "MM-DD-YYYY").year());
    let month = Number(moment(allInputData, "MM-DD-YYYY").month());
    let date = Number(moment(allInputData, "MM-DD-YYYY").date());
    let sTime = allInputData?.split(",")?.[1]?.split("-")?.[0];
    let eTime = allInputData?.split(",")?.[1]?.split("-")?.[1];
    let startTimeHour = moment(sTime, "h:mm:ss A").format("HH");
    let startTimeMin = moment(sTime, "h:mm:ss A").format("mm");
    let endTimeHour = moment(eTime, "h:mm:ss A").format("HH");

    let endTimeMin = moment(eTime, "h:mm:ss A").format("mm");
    let arr: any = [];
    let data = {
      title: "",
      start: new Date(
        year,
        Number(month),
        Number(date),
        Number(startTimeHour),
        Number(startTimeMin),
        0
      ),
      end: new Date(
        year,
        Number(month),
        Number(date),
        Number(endTimeHour),
        Number(endTimeMin),
        0
      ),
    };
    arr?.push(data);
    setEvent(arr);
  }, [allInputData]);

  const getDayName = (dayIndex: number) => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
    return days[dayIndex];
  };
  const selectHandler = (args: any) => {
    let eDate: any = moment(args?.end, "MM-DD-YYYY");
    setEdate(eDate ? eDate : null);
    setStime(moment(args?.start, "HH:mm a"));
    setEtime(moment(args?.end, "HH:mm a"));
    setPopOverVisible(true);
  };

  return (
    <div>
      <CustomheadingThird>{agentName}</CustomheadingThird>
      {isLoading && <CustomSpinner />}
      <HeaderWrapper>
        <ButtonWrapper>
          <HederTitleWrapper>
            <DayColorWrapper>
              <CustomButton className="calendarButtons">
                <Button
                  className="viewButton cathyCalendarPrevButton"
                  onClick={() => {
                    dayChange("prev");
                  }}>
                  <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
                </Button>
                <Button
                  className="viewButton cathyCalendarNextButton"
                  onClick={() => {
                    dayChange("next");
                  }}>
                  <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                </Button>
              </CustomButton>
              <FullCalendar
                events={isOn ? eventFilter : newEvent}
                eventColor="#ffcba4"
                height={400}
                select={selectHandler}
                allDaySlot={false}
                selectable={true}
                eventClick={(e) => {
                  notification(
                    `Event: ${e.event.title}, Please select another slot.`,
                    "warning"
                  );
                }}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="timeGridWeek"
                headerToolbar={{
                  start: "",
                  center: "title",
                  end: "",
                }}
                titleFormat={{
                  month: "long",
                  year: "numeric",
                }}
                dayCellClassNames={(arg) => {
                  if (isOffHours(arg?.date)) {
                    return "calendarDayClass";
                  }
                }}
                slotLaneClassNames={(arg) => {
                  let time = arg?.date?.toTimeString().slice(0, 2);
                  let isTime =
                    time < offCalHours?.startTime ||
                    time > offCalHours?.endTime;
                  let day = arg?.date?.toDateString()?.split(" ")?.[0];
                  if (
                    isTime &&
                    !agentAccountSettings?.markWeekends?.includes(day)
                  ) {
                    return ["offHours"];
                  } else {
                    return ["timeGrid"];
                  }
                }}
                dayHeaderContent={(arg) => {
                  return (
                    <div
                      style={{
                        margin: "10px !important",
                        padding: "5px !important",
                      }}>
                      {arg?.view?.type !== "dayGridMonth" && (
                        <div>
                          <div
                            style={{
                              color: "#000000",
                            }}>
                            {getDayName(new Date(arg?.date)?.getUTCDay())}
                          </div>
                          <div
                            style={{
                              color: "#000000",
                            }}>
                            {new Date(arg?.date)?.getUTCDate()}
                          </div>
                        </div>
                      )}
                      {arg?.view?.type === "dayGridMonth" && (
                        <div>
                          <div
                            style={{
                              color: "#000000",
                            }}>
                            {getDayName(new Date(arg?.date)?.getUTCDay())}
                          </div>
                          <div
                            style={{
                              color: "#000000",
                            }}></div>
                        </div>
                      )}
                    </div>
                  );
                }}
                eventContent={(args: any) => (
                  <>
                    {args?.event?._def?.title === "View Property" ||
                      args?.event?._def?.title === "Scheduled Property" ? (
                      <>
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            inset: "50px 0% -300px",
                            backgroundColor: "#d9d9d9",
                            zIndex: 1,
                            padding: "0 4px",
                            whiteSpace: "normal",
                            borderRadius: "10px",
                            borderLeft: "1px solid white",
                            borderTop: "1px solid white",
                            overflow: "hidden"
                          }}>
                          <></>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
                dragScroll={true}
                droppable={true}
                drop={(dropInfo) => { }}
                eventResizableFromStart={true}
                eventDragStart={(info) => { }}
                eventDragStop={(info) => { }}
                selectMirror={true}
                ref={calendarRef1}
              />

              <CustomPopover
                content={content}
                trigger="click"
                visible={popOverVisible}
                overlayInnerStyle={{
                  width: isResponsive ? "80%" : "90%",
                  border: "2px solid #004876",
                  borderRadius: "15px",
                }}
              />
            </DayColorWrapper>
          </HederTitleWrapper>
        </ButtonWrapper>
      </HeaderWrapper>
    </div>
  );
};

export default CathyCalendar;
