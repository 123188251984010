/* eslint-disable jsx-a11y/alt-text */
import styled from "@emotion/styled";
import { Button, Col, Form, Input, Row } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { notification } from "../../components/notification";
import UserContext from "../../contexts/UserContext";
import { updateDgragEventData } from "../../services/NavihomeService";
interface IAppProps {
  dragInfo: {
    startTime: Date;
    endTime: string;
    eventId: number | string;
    realEndTime: Date;
    realStartTime: string | Date;
  };
  calendarpropertyId: number;
  setIsDragModalVisible: any;
}

const style = { background: "white", padding: "8px 0" };
const CustomButton = styled(Button)`
  color: #ffff;
  background: #365073;
  border-radius: 10px;
  :hover {
    background: #c2cc30;
    borer-color: none;
  }
  display: flex;
  justify-content: center;
}
`;
const DragConfig: React.FunctionComponent<IAppProps> = ({
  dragInfo,
  calendarpropertyId,
  setIsDragModalVisible,
}) => {
  const [form]: any = Form.useForm();
  let [eventInfo, setEventInfo] = useState<{
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
  }>({
    startDate: dragInfo?.startTime?.toDateString(),
    endDate: dragInfo?.realEndTime?.toDateString(),
    startTime: dragInfo?.startTime?.toTimeString(),
    endTime: dragInfo?.realEndTime?.toTimeString(),
  });

  useEffect(() => {
    form.setFieldsValue({
      startDate: moment(dragInfo?.startTime).format("L"),
      endDate: moment(dragInfo?.realEndTime).format("L"),
      startTime: moment(dragInfo?.startTime).format("LT"),
      endTime: moment(dragInfo?.realEndTime).format("LT"),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragInfo, setEventInfo]);

  const onFinish = async (values: any) => {
    try {
      const payload = {
        startDate: values?.startDate,
        startTime: values?.startTime,
        endDate: values?.endDate,
        endTime: values?.endTime,
      };

      const response = await updateDgragEventData(payload, calendarpropertyId);
      if (response) {
        setIsDragModalVisible(false);
        notification("Event updated successfully", "success");
      }
    } catch (err) {}
  };
  return (
    <>
      <div>
        <Form
          onFinish={onFinish}
          style={{ borderRadius: "5px" }}
          layout={"vertical"}
          form={form}
        >
          <p>
            <h4>Reschedule Event:</h4>
            <Form.Item>
              <Row gutter={[16, 24]}>
                <Col span={5}>
                  <div style={style}>
                    <Form.Item
                      label="Start Date"
                      name="startDate"
                      rules={[{ required: true, message: "Enter start date" }]}
                    >
                      <Input
                        style={{
                          borderRadius: "5px",
                          boxShadow: "1px 1px 1px grey ",
                          border: "solid",
                          borderColor: "darkblue",
                          borderWidth: "thin",
                        }}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={5}>
                  <div style={style}>
                    <Form.Item
                      label="Start Time"
                      name="startTime"
                      rules={[{ required: true, message: "Enter start time" }]}
                    >
                      <Input
                        value={eventInfo?.startTime}
                        style={{
                          borderRadius: "5px",
                          boxShadow: "2px 2px 2px grey",
                          border: "solid",
                          borderColor: "darkblue",
                          borderWidth: "thin",
                        }}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <h1 style={{ alignContent: "center" }}>
                  <img
                    src="https://img.icons8.com/external-royyan-wijaya-detailed-outline-royyan-wijaya/24/000000/external-arrow-arrow-line-royyan-wijaya-detailed-outline-royyan-wijaya-13.png"
                    style={{
                      color: "blue",
                      alignContent: "center",
                      marginTop: "150%",
                      marginLeft: "-1%",
                    }}
                  />
                </h1>
                <Col span={5}>
                  <div style={style}>
                    {/* Date* */}
                    <Form.Item
                      label="End Date"
                      name="endDate"
                      rules={[{ required: true, message: "Enter end date" }]}
                    >
                      <Input
                        style={{
                          borderRadius: "5px",
                          boxShadow: "2px 2px 2px grey",
                          border: "solid",
                          borderColor: "darkblue",
                          borderWidth: "thin",
                        }}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={5}>
                  <div style={style}>
                    <Form.Item
                      label="End Time"
                      name="endTime"
                      rules={[{ required: true, message: "Enter end time" }]}
                    >
                      <Input
                        style={{
                          borderRadius: "5px",
                          boxShadow: "2px 2px 2px grey",
                          border: "solid",
                          borderColor: "darkblue",
                          borderWidth: "thin",
                        }}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </Form.Item>
          </p>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col>
              <Form.Item>
                <CustomButton
                  htmlType="submit"
                  className="common_button_buyer_tour_details"
                >
                  SEND REQUEST
                </CustomButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default DragConfig;
