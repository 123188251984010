import AgentBuyerPropertyCalendar from "../components/AgentBuyerPropertyCalendar/AgentBuyerPropertyCalendar";

const AccountSettingsBuyerPage: React.FC = () => {
  return (
    <div className="calendar-agent-page__container">
      <AgentBuyerPropertyCalendar />
    </div>
  );
};

export default AccountSettingsBuyerPage;
