/* eslint-disable react-hooks/exhaustive-deps */
import styled from "@emotion/styled";
import { Modal } from "antd";
import React, { useContext, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation } from "react-router-dom";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import Button, { ButtonType } from "../../components/Button";
import { notification } from "../../components/notification";
import UserContext from "../../contexts/UserContext";
import { checkPendingSubscriptionService, makePayments } from "../../services/NavihomeService";
import TextField, { TextFieldIconType, TextFieldType } from "../TextField";
import CustomSpinner from "../spinner/CustomSpinner";
import "./form.css";

const CustomPaymentForm = styled(PaymentForm)`
  input {
    padding: 0 16px 0 68px;
    width: 32.9rem;
    height: 4.8rem;
    box-shadow: 0rem 0.3rem 0.6rem var(--color-shadow);
    border-radius: 0.8rem;
    border: 0.1rem solid var(--color-primary);
    vertical-align: top;
  }
`;

interface PaymentPageModel {
  cardholder_name?: string;
  exp_month?: number;
  exp_year?: number;
  last_4?: number;
}

const PaymentPage: React.FC<PaymentPageModel> = (props) => {
  const { userState, logIn } = useContext(UserContext);
  const history = useHistory();
  const [cardholderName, setCardholderName] = useState<string>("");
  const isMobile = useMediaQuery({ maxWidth: 430 });
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isLoader, setIsLoader] = useState<boolean>(false);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  const MyCustomButton: any = () => {
    const handleSubmit = () => {
      if (cardholderName === "") {
        return notification("Please enter your Cardholder's name.", "error");
      }
    };

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Button type={ButtonType.PRIMARY} text="CONTINUE" className="find-an-agent-modal-confirm-find-an-agent-button" clickHandler={handleSubmit} />
      </div>
    );
  };

  const continueCheckout = async (nonce: string, isUpdate?: boolean) => {

    try {
      setIsLoader(true);
      const res: any = await checkPendingSubscriptionService(userState.agent.agentId);
      if (res && res.activePlan) {
        setIsLoader(false);
        return notification(`Already you have a pending payment ${res.planName} subscription so you can't pay any other payment until active payment on ${(res?.startDate)?.split("T")?.[0]}`, "error")
      }
      const type = query.get("type");
      const amount = query.get("plan");
      const mode = query.get("mode");
      const payload = {
        cardholder_name: cardholderName,
        nonce: nonce,
        amount: Number(amount),
        agentId: userState?.agent?.agentId,
        agentName: userState?.agent?.name,
        agentEmail: userState?.agent?.email,
        agentCompany: userState?.agent?.company,
        agentPhone: userState?.agent?.phone,
        agentMlsId: userState?.agent?.mlsId,
        buyerId: 0,
        subscription_type: type,
        upgradePlan: mode === "upgrade" ? "upgrade_plan" : 'downgrade_plan'
      };
      // console.log('payloadpayload',payload);
      const response = await makePayments(payload, userState.agent.token);
      if (response?.id) {
        setIsModalVisible(true);
        setTimeout(() => {
          logIn(userState?.agent?.email, userState?.agent?.password);
        }, 100);
        setIsLoader(false);
      } else {
        setIsLoader(false);
        if (response?.code === "BAD_REQUEST") {
          return notification(`${"Please connect with support team" + " " + response?.detail}`, "error")
        } else {
          return notification("Please check your card details", "error")
        }
      }
    } catch (error) {
      console.log("errorerror", error);
    }
  };

  return (
    <div>
      {isLoader && <CustomSpinner />}

      <div>
        <TextField
          value={cardholderName}
          setValue={setCardholderName}
          placeholder="Jane Doe"
          label="Cardholder's name"
          type={TextFieldType.ICON_AND_LABEL}
          icon={TextFieldIconType.USER}
          isRequired={true}
          width={isMobile ? undefined : 38.4}
          className="checkout-page__cardholder-name"
        />
        <CustomPaymentForm
          applicationId={process.env.REACT_APP_SQUARE_APPLI_ID}
          locationId={process.env.REACT_APP_LOCATION_ID}
          cardTokenizeResponseReceived={(token, verifiedBuyer) => {
            if (cardholderName === "") {
              return notification("Please enter your Cardholder's name.", "error");
            }
            continueCheckout(token?.token, false);
          }}
        >
          <CreditCard
            buttonProps={{
              css: {
                backgroundColor: "#fff",
                fontSize: "14px",
                width: "40%",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#ffff"
                }
              }
            }}
          >
            <MyCustomButton />
          </CreditCard>
        </CustomPaymentForm>
      </div>
      {isModalVisible && (
        <Modal
          style={{ height: 480 }}
          centered={true}
          maskClosable={true}
          visible={isModalVisible}
          onCancel={() => {
            setIsModalVisible(false);
            history.push("/");
          }}
          footer={null}
        >
          <div style={{ marginTop: "20px" }}>
            <h1>Thank you for making the payment</h1>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type={ButtonType.PRIMARY_NARROW}
              text="CONTINUE"
              width={18.4}
              clickHandler={() => {
                history.push("/");
              }}
              className="checkout-page__continue-button"
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default PaymentPage;
