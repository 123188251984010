/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import checkboxOn from '../assets/images/button-checkbox-on.svg';
import checkboxOff from '../assets/images/button-checkbox-off.svg';
import requestShowingOn from '../assets/images/button-request-showing-on.svg';
import requestShowingOff from '../assets/images/button-request-showing-off.svg';
import favoriteOn from '../assets/images/button-favorite-on.svg';
import favoriteOff from '../assets/images/button-favorite-off.svg';
import iconConnected from '../assets/images/icon-connected.svg';
import buttonDelete from '../assets/images/button-remove-property.svg';
import PropertyAdd from '../assets/images/button-add-property-detail.png';
import { useHistory } from 'react-router';
import UserContext, { UserType } from '../contexts/UserContext';
import PropertyFiltersContext from '../contexts/PropertyFiltersContext';
import { useLocation } from 'react-router';
import {
  addPropertyToFavoritesForBuyerService,
  removePropertyFromFavoritesForBuyerService,
  removePropertyFromRequestedForBuyerService,
  checkIfPropertyIsFavoriteForBuyerService,
  addPropertyToRequestedForBuyerService,
  checkIfPropertyIsRequestedForBuyerService,
  addLinkedPropertyForBuyer,
  removeLinkedPropertyForBuyer,
  checkIfPropertyIsViewedRequestedForBuyerService,
  checkIfPropertyIsViewedFavoritesForBuyerService,
  inviteAgentByBuyer,
  checkIsListedPropertyService,
  createBuyerNotificationById,
  postMessageToUser,
  getAgentAccountSettings,
  getActivatedSubscriptionPlan,
} from '../services/NavihomeService';
import AgentContext from '../contexts/AgentContext';
import { notification } from './notification';
import { Popover } from 'antd';
import styled from '@emotion/styled';
import FindAnAgentConfirmModal from '../modals/FindAnAgentConfirmModal';
import LogInModal from '../modals/LoginModal';
import ForgotPasswordModal from '../modals/forgotPasswordModal';
import VisitedModal from '../modals/VisitedModal';
import RequestShowingModal from '../modals/RequestShowingModal';
import FavoriteShowingModal from '../modals/FavoriteShowingModal';
import moment from 'moment-timezone';
import CustomSpinner from './spinner/CustomSpinner';
import { getBuyerAccountSettings } from '../services/NavihomeService';

const CustomOpenHouseDiv = styled.div`
  background: #eb752f;
  border-radius: 14px;
  padding: 4px 8px;
  position: absolute;
  top: 6px;
  left: 6px;
  p {
    color: white !important;
  }
  @media screen and (max-width: 576px) {
    margin-top: 12px;
  }
`;
const CustomTimeStampDiv = styled.div`
  background: #eb752f;
  border-radius: 14px;
  padding: 4px 8px;
  position: absolute;
  top: 6px;
  right: 6px;
  width: fit-content;
  p {
    color: white !important;
  }
  @media screen and (max-width: 576px) {
    margin-top: 12px;
  }
  @media screen and (max-width: 1200px) {
    top: 4rem;
    left: 5px;
  }
`;

const removeFromFavContent = (
  <div>
    <p>Remove from Favorites</p>
  </div>
);
const addToFavContent = (
  <div>
    <p>Add to Favorites</p>
  </div>
);
const removeFromShowingsContent = (
  <div>
    <p>Remove from Requested Showings</p>
  </div>
);
const addToShowingsContent = (
  <div>
    <p>Add to Requested Showings</p>
  </div>
);


export interface PropertyCardProps {
  linkedProperties?: any;
  setLinkedProperties?: any;
  listing?: any;
  propertyId?: number;
  image?: string;
  price?: number;
  type?: string;
  beds?: number;
  baths?: number;
  squareFootage?: number;
  latitude?: any;
  longitude?: any;
  country?: string;
  state?: string;
  city?: string;
  streetName?: string;
  streetNumber?: number;
  postalCode?: string;
  bathsHalf?: number;
  mlsId?: number;
  openHouse?: string;
  feature: string;
  isadded?: boolean;
  setCount?: any;
  isBuyerLinked?: boolean;
  property_type?: string;
  linkedBuyerId?: number;
  upcomingBuyerId?: string;
  agentId?: number;
  agentIdForBuyer?: number;
  openHouseData?: string;
  setTourPropPage?: any;
  setShowMap?: any;
  setUnlinkPropHeader?: (value: boolean) => void;
  setPropId?: (value: any) => void;
  selectFavoriteProperties?: () => void;
  selectRequestedShowings?: () => void;
  selectPropertyComments?: () => void;
  getFavoriteProperties?: () => void;
  getRequestedProperties?: () => void;
  openHouseStartTime?: any;
  openHouseEndTime?: any;
  isSellTab?: boolean;
  setshowAddPropertyBtn?: any;
  propertyDetails: any;
  linkedBuyerName?: string;
  openHouseKey?: string;
  openHouseId?: string;
  tourPropPage?: boolean;
  SetPropStartTime?: any;
  SetPropEndTime?: any;
  linkedPropertiesData?: Array<any>;
  setLinkedPropertiesData?: (val: Array<any>) => void;
  filteredLinkedProperties?: Array<any>;
  setFilteredLinkedProperties?: (val: Array<any>) => void;
  isBuyerDashboard?: string;
  setIsPropListed?: any;
  setPropDataDetails?: any
  setPropListingId?: any
  setIsPropFav?: (value: boolean) => void;
  setIsPropReq?: (value: boolean) => void;
  upcomingBuyerPhone?: string;
  isPropViewed?: string;
  buyerEmail?: string;
  tourType?: string;
  tourId?: string;
}

const PropertyCard: React.FC<PropertyCardProps> = ({
  linkedProperties,
  setLinkedProperties,
  listing,
  propertyId,
  image,
  price,
  type,
  beds,
  baths,
  squareFootage,
  latitude,
  longitude,
  agentId,
  isadded,
  linkedBuyerId,
  property_type = '',
  upcomingBuyerId,
  agentIdForBuyer,
  getFavoriteProperties,
  getRequestedProperties,
  selectFavoriteProperties,
  selectPropertyComments,
  selectRequestedShowings,
  openHouse,
  setTourPropPage,
  isPropViewed,
  tourPropPage,
  setUnlinkPropHeader,
  setPropId,
  country,
  state,
  city,
  streetName,
  streetNumber,
  postalCode,
  bathsHalf,
  mlsId,
  isSellTab,
  openHouseId,
  openHouseStartTime,
  openHouseEndTime,
  setshowAddPropertyBtn,
  propertyDetails,
  linkedBuyerName,
  openHouseKey,
  SetPropStartTime,
  SetPropEndTime,
  linkedPropertiesData,
  setLinkedPropertiesData,
  isBuyerDashboard,
  setIsPropListed,
  setPropDataDetails,
  setPropListingId,
  setIsPropFav,
  setIsPropReq,
  upcomingBuyerPhone,
  filteredLinkedProperties,
  setFilteredLinkedProperties,
  buyerEmail,
  tourType,
  tourId
}) => {
  const [isVisited, setIsVisited] = useState<boolean>(false);
  const [isRequested, setIsRequested] = useState<boolean>(false);
  const [isLinked, setIsLinked] = useState<boolean>(true);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [isListed, setIsListed] = useState<boolean>(false);
  const [showingType, setShowingType] = useState<string>('');
  const [isAcceptedRequest, setIsAcceptedRequest] = useState<string>('');
  const [listingAgentId, setListingAgentId] = useState<number>(0);
  const [listingAgent, setListingAgent] = useState<any>(null);
  const [listingAgentEmail, setListingAgentEmail] = useState<string>('');
  const [listingAgentName, setListingAgentName] = useState<string>('');
  const [listingAgentSellerName, setListingAgentSellerName] =
    useState<string>('');
  const [listingAgentSellerPhone, setListingAgentSellerPhone] =
    useState<string>('');
  const [isShowingLoginModal, setIsShowingLoginModal] = useState(false);
  const [isShowingForgotPasswordModal, setIsShowingForgotPasswordModal] =
    useState(false);
  const [isViewedRequested, setIsViewedRequested] = useState(false);
  const [isRequestShowingModal, setIsRequestShowingModal] = useState(false);
  const [isFavoriteShowingModal, setIsFavoriteShowingModal] = useState(false);
  const [isViewedFavorite, setIsViewedFavorite] = useState(false);
  const [isShowFindAnAgentConfirmModal, setIsShowFindAnAgentConfirmModal] =
    useState(false);
  const [isShowingVisitedModal, setIsShowingVisitedModal] =
    useState<boolean>(false);
  const [redirectUrl, setRedirectUrl] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const history = useHistory();
  const { userState } = useContext(UserContext);
  const { agentState } = useContext(AgentContext);
  const { setTourType, setSingleTourId, setScheduledTourId } = useContext(PropertyFiltersContext)
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let param = useQuery();

  const addRequested: any = {
    propertyType: type ? type : 'RES',
    agentId: userState?.userType === UserType.AGENT ? userState?.agent?.agentId : userState?.buyer?.buyerAgentId,
    initialStatus: true,
    accept: true,
    mlsId: mlsId,
    latitude: latitude,
    longitude: longitude,
    country: country,
    imageUrl: image,
    listPrice: price,
    streetNumber: streetNumber,
    streetName: streetName,
    city: city,
    state: state,
    postalCode: postalCode,
    bedrooms: beds ?? 0,
    bathrooms: baths ?? 0,
    area: squareFootage ?? 0,
    bathsHalf: bathsHalf ?? 0,
    opneHouse: openHouse ? openHouse : property_type ? property_type : "new_listing",
    propertyDetails: Object?.keys(propertyDetails)?.length > 0 ? JSON.stringify(propertyDetails) : {},
    openHouseStartTime: openHouseStartTime ? openHouseStartTime : new Date(),
    openHouseEndTime: openHouseEndTime ? openHouseEndTime : new Date(),
    openHouseId: openHouseId ? openHouseId : '',
    openHouseKey: openHouseKey ? openHouseKey : '',
    createdAt: new Date(),
    updatedAt: new Date(),
    listingAgentName: propertyDetails?.agent?.firstName +
      " " +
      propertyDetails?.agent?.lastName,
  };

  const addViewedProperties = {
    propertyType: type ? type : 'res',
    agentId: userState?.userType === UserType.AGENT ? userState?.agent?.agentId : userState?.buyer?.buyerAgentId,
    initialStatus: true,
    accept: true,
    mlsId: mlsId,
    latitude: latitude,
    longitude: longitude,
    country: country,
    imageUrl: image,
    listPrice: price,
    streetNumber: streetNumber,
    streetName: streetName,
    city: city,
    state: state,
    postalCode: postalCode,
    bedrooms: beds ?? 0,
    bathrooms: baths ?? 0,
    area: squareFootage ?? 0,
    bathsHalf: bathsHalf ?? 0,
    opneHouse: openHouse ?? property_type ?? "new_listing",
    propertyDetails: Object?.keys(propertyDetails)?.length > 0 ? JSON.stringify(propertyDetails) : {},
    openHouseStartTime: openHouseStartTime ? openHouseStartTime : new Date(),
    openHouseEndTime: openHouseEndTime ? openHouseEndTime : new Date(),
    openHouseId: openHouseId ? openHouseId : '',
    openHouseKey: openHouseKey ? openHouseKey : '',
    createdAt: new Date(),
    updatedAt: new Date(),
  };

  const addViewedFavProperties = {
    propertyType: type ? type : 'res',
    agentId: userState?.userType === UserType.AGENT ? userState?.agent?.agentId : userState?.buyer?.buyerAgentId,
    initialStatus: true,
    accept: true,
    mlsId: 0,
    latitude: 0,
    longitude: 0,
    country: country,
    imageUrl: image,
    listPrice: price,
    streetNumber: streetNumber,
    streetName: streetName,
    city: city,
    state: state,
    postalCode: postalCode,
    bedrooms: beds ?? 0,
    bathrooms: baths ?? 0,
    area: squareFootage ?? 0,
    bathsHalf: bathsHalf ?? 0,
    opneHouse: openHouse ?? property_type ?? "new_listing",
    propertyDetails: Object?.keys(propertyDetails)?.length > 0 ? JSON.stringify(propertyDetails) : {},
    openHouseStartTime: openHouseStartTime ? openHouseStartTime : new Date(),
    openHouseEndTime: openHouseEndTime ? openHouseEndTime : new Date(),
    openHouseId: openHouseId ? openHouseId : '',
    openHouseKey: openHouseKey ? openHouseKey : '',
    createdAt: new Date(),
    updatedAt: new Date(),
  };

  const addFavorite: any = {
    propertyType: type ? type : 'RES',
    agentId: userState?.userType === UserType.AGENT ? userState?.agent?.agentId : userState?.buyer?.buyerAgentId,
    mlsId: mlsId,
    latitude: latitude,
    longitude: longitude,
    country: country || 'United States',
    imageUrl: image,
    listPrice: price,
    streetNumber: streetNumber,
    streetName: streetName,
    city: city,
    state: state,
    postalCode: postalCode,
    bedrooms: beds ?? 0,
    bathrooms: baths || 1,
    area: squareFootage ?? 0,
    bathsHalf: bathsHalf || 0,
    opneHouse: openHouse || property_type || 'new_listing',
    propertyDetails: Object?.keys(propertyDetails)?.length > 0 ? JSON.stringify(propertyDetails) : {},
    openHouseStartTime: openHouseStartTime ? openHouseStartTime : new Date(),
    openHouseEndTime: openHouseEndTime ? openHouseEndTime : new Date(),
    openHouseId: openHouseId ? openHouseId : '',
    openHouseKey: openHouseKey ? openHouseKey : '',
    createdAt: new Date(),
    updatedAt: new Date(),
    listingAgentName: propertyDetails?.agent?.firstName +
      " " +
      propertyDetails?.agent?.lastName,
  };

  const addLinked = {
    propertyType: type ? type : 'RES',
    agentId: agentId ? agentId : userState?.userType === UserType.AGENT ? userState?.agent?.agentId : userState?.buyer?.buyerAgentId,
    latitude: latitude ? latitude : '',
    longitude: longitude ? latitude : '',
    country: country || 'United States',
    imageUrl: image,
    listPrice: price,
    streetNumber: streetNumber > 0 ? streetNumber : 0,
    streetName: streetName ?? '',
    city: city ?? '',
    state: state,
    postalCode: postalCode,
    bedrooms: beds ?? 0,
    bathrooms: baths || 1,
    area: squareFootage ?? 0,
    bathsHalf: bathsHalf || 0,
    propertyCategory: openHouse || property_type || '',
    propertyDetails: Object?.keys(propertyDetails)?.length > 0 ? JSON.stringify(propertyDetails) : {},
    openHouseStartTime: openHouseStartTime ? openHouseStartTime : new Date(),
    openHouseEndTime: openHouseEndTime ? openHouseEndTime : new Date(),
    openHouseId: openHouseId ? openHouseId : '',
    openHouseKey: openHouseKey ? openHouseKey : '',
    createdAt: new Date(),
    updatedAt: new Date(),
    isFavorite: isFavorite,
    isRequested: true,
    isListed: isListed,
    listingAgentId: listingAgentId,
  };

  const toggleRequested = async () => {
    setIsShowFindAnAgentConfirmModal(true);
    if (!userState.isLoggedIn) {
      setIsRequestShowingModal(!isRequestShowingModal);
      return;
    }
    if (
      !userState?.buyer?.isLinked &&
      userState?.userType === UserType?.BUYER
    ) {
      return;
    }

    setIsLoading(true)

    let agentName = '', buyerName = '', agentEmail = '', buyerMail = '';


    const previousRequestedStatus = isRequested;
    setIsRequested(!isRequested);

    let token = '', buyerId = -1,
      agentPhone: string = '',
      buyerPhone: string = '',
      messageType: string = 'No',
      preferredContact: string = 'No';

    switch (userState.userType) {
      case UserType.BUYER:
        buyerId = userState.buyer.buyerId;
        token = userState.buyer.token;
        agentPhone = userState?.buyer?.buyerAgentPhone;
        buyerPhone = userState?.buyer?.phone;
        buyerName = userState?.buyer?.name;
        agentEmail = userState?.buyer?.buyerAgentEmail;
        agentName = userState?.buyer?.buyerAgentName;
        preferredContact = userState?.buyer?.buyerAgentPreferredContact
        const agentAccountSettingsResult: any = await getAgentAccountSettings(userState?.buyer?.buyerAgentId);
        if (agentAccountSettingsResult?.length > 0) {
          const lastIndex: number = agentAccountSettingsResult?.length - 1;
          messageType = agentAccountSettingsResult?.[lastIndex]?.notifyRequestedShowings;
        }
        addRequested["messageType"] = preferredContact === "CONTACT_RADIO_BOTH" || preferredContact === "CONTACT_RADIO_EMAIL" ? messageType : "not real time";
        addRequested["agentName"] = userState?.buyer?.buyerAgentName;
        addRequested["agentEmail"] = userState?.buyer?.buyerAgentEmail;
        addRequested["buyerName"] = userState?.buyer?.name;
        addRequested["buyerEmail"] = "Agent";

        break;
      case UserType.AGENT:
        buyerId = agentState.buyerId;
        token = userState.agent.token;
        agentPhone = userState?.agent?.phone;
        agentName = userState?.agent?.name;
        buyerMail = buyerEmail ? buyerEmail : agentState?.buyerEmail;
        buyerName = param.get('name') ? param.get('name') : agentState?.buyerName;
        buyerPhone = upcomingBuyerPhone ? upcomingBuyerPhone : agentState?.buyerPhone;
        preferredContact = agentState?.buyerPreferredContact
        const buyerAccountSettingsResult: any = await getBuyerAccountSettings(
          userState?.agent?.token,
          agentState.buyerId)
        if (buyerAccountSettingsResult?.length > 0) {
          const lastIndex: number = buyerAccountSettingsResult?.length - 1;
          messageType = buyerAccountSettingsResult?.[lastIndex]?.notifyRequestedShowing;
        }
        addRequested["messageType"] = preferredContact === "CONTACT_RADIO_BOTH" || preferredContact === "CONTACT_RADIO_EMAIL" ? messageType : "not real time";
        addRequested["agentName"] = userState?.agent?.name;
        addRequested["agentEmail"] = 'Buyer';
        addRequested["buyerName"] = agentState?.buyerName;
        addRequested["buyerEmail"] = agentState?.buyerEmail;
        break;
      default:
        break;
    }

    if (previousRequestedStatus) {      
      await removePropertyFromRequestedForBuyerService(
        propertyId,
        buyerId,
        token
      );
      selectRequestedShowings();
      setIsLoading(false)
      return;
    }

    await addPropertyToRequestedForBuyerService(
      propertyId,
      buyerId,
      token,
      addRequested
    );

    if (userState.userType === UserType.BUYER && (agentId || userState?.buyer?.buyerAgentId)) {
      const linkedAgentId = userState?.buyer?.buyerAgentId ? userState?.buyer?.buyerAgentId : agentId;

      if (messageType === "Realtime" && (preferredContact === "CONTACT_RADIO_BOTH" ||
        preferredContact === "CONTACT_RADIO_PHONE")) {
        postMessageToUser(
          agentPhone,
          `Your buyer ${userState?.buyer?.name?.toUpperCase()} added ${Number(streetNumber) ?? ""} ${streetName ?? ""} ${city ?? ""}, ${state ?? ""} ${postalCode ?? ""} | #${mlsId} valued at  ${price.toLocaleString(
            'en-US',
            {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 3,
            }
          )} to requested showing property list.`
        );
      }

      await inviteAgentByBuyer(
        linkedAgentId,
        'requested',
        `Your buyer ${userState?.buyer?.name?.toUpperCase()} added ${Number(streetNumber) ?? ""} ${streetName ?? ""} ${city ?? ""}, ${state ?? ""} ${postalCode ?? ""} | #${mlsId} valued at  ${price.toLocaleString(
          'en-US',
          {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 3,
          }
        )} to requested showing property list`,
        propertyId,
        '',
        '',
        userState?.buyer?.buyerId || buyerId
      );
    }

    if (userState.userType === UserType.AGENT) {

      await createBuyerNotificationById(
        buyerId,
        `Your Agent ${userState?.agent?.name.toUpperCase()} added ${Number(streetNumber) ?? ""} ${streetName ?? ""} ${city ?? ""}, ${state ?? ""} ${postalCode ?? ""} | #${mlsId} valued at ${price?.toLocaleString(
          'en-US',
          {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 3,
          }
        )} to your requested showing property list.`,
        'Comment',
        userState?.agent?.token,
        'buyer',
        propertyId,
        "No",
        '',
        '',
        '',
      );

      if (messageType === "Yes" && (preferredContact === "CONTACT_RADIO_BOTH" ||
        preferredContact === "CONTACT_RADIO_PHONE")) {
        postMessageToUser(
          agentState.buyerPhone,
          `Your Agent ${userState?.agent?.name.toUpperCase()} added ${Number(streetNumber) ?? ""} ${streetName ?? ""} ${city ?? ""}, ${state ?? ""} ${postalCode ?? ""} | #${mlsId} valued at ${price?.toLocaleString(
            'en-US',
            {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 3,
            }
          )} to your requested showing property list.`
        );
      }
    }

    setIsLoading(false)

  };

  const toggleFavorite = async () => {
    if (!userState.isLoggedIn) {
      setIsFavoriteShowingModal(!isFavoriteShowingModal);
      return;
    }

    setIsLoading(true);

    const previousFavoriteStatus = isFavorite;
    setIsFavorite(!isFavorite);

    var token = '';
    var buyerId = -1;
    let agentPhone: string = '',
      buyerPhone: string = '',
      messageType: string = 'No',
      preferredContact: string = 'No';

    switch (userState.userType) {
      case UserType.BUYER:
        buyerId = userState.buyer.buyerId;
        token = userState.buyer.token;
        agentPhone = userState?.buyer?.buyerAgentPhone;
        buyerPhone = userState?.buyer?.phone;
        preferredContact = userState?.buyer?.buyerAgentPreferredContact
        const agentAccountSettingsResult: any = await getAgentAccountSettings(userState?.buyer?.buyerAgentId);
        if (agentAccountSettingsResult?.length > 0) {
          const lastIndex: number = agentAccountSettingsResult?.length - 1;
          messageType = agentAccountSettingsResult?.[lastIndex]?.notifyFavoritesProperties;
        }
        addFavorite["messageType"] = preferredContact === "CONTACT_RADIO_BOTH" || preferredContact === "CONTACT_RADIO_EMAIL" ? messageType : "not real time";
        addFavorite["agentName"] = userState?.buyer?.buyerAgentName;
        addFavorite["agentEmail"] = userState?.buyer?.buyerAgentEmail;
        addFavorite["buyerName"] = userState?.buyer?.name;
        addFavorite["buyerEmail"] = 'Agent';
        break;
      case UserType.AGENT:
        buyerId = agentState.buyerId;
        token = userState.agent.token;
        agentPhone = userState?.agent?.phone;
        buyerPhone = agentState?.buyerPhone;
        preferredContact = agentState?.buyerPreferredContact
        const buyerAccountSettingsResult: any = await getBuyerAccountSettings(
          userState?.agent?.token,
          agentState.buyerId)
        if (buyerAccountSettingsResult?.length > 0) {
          const lastIndex: number = buyerAccountSettingsResult?.length - 1;
          messageType = buyerAccountSettingsResult?.[lastIndex]?.notifyFavoriteProp;
        }
        addFavorite["messageType"] = preferredContact === "CONTACT_RADIO_BOTH" || preferredContact === "CONTACT_RADIO_EMAIL" ? messageType : "No";
        addFavorite["agentName"] = userState?.agent?.name;
        addFavorite["agentEmail"] = 'Buyer';
        addFavorite["buyerName"] = agentState?.buyerName;
        addFavorite["buyerEmail"] = agentState?.buyerEmail;
        break;
      default:
        break;
    }

    if (previousFavoriteStatus) {
      await removePropertyFromFavoritesForBuyerService(
        propertyId,
        buyerId,
        token
      );
      selectFavoriteProperties();
      setIsLoading(false)
      // getFavoriteProperties();
      return;
    }

    await addPropertyToFavoritesForBuyerService(
      propertyId,
      buyerId,
      token,
      addFavorite
    );

    if (userState?.buyer?.buyerAgentId || agentId) {
      const linkedAgentId = userState?.buyer?.buyerAgentId ? userState?.buyer?.buyerAgentId : agentId;
      // to agent account setting

      if (messageType === "Realtime" && (preferredContact === "CONTACT_RADIO_BOTH" ||
        preferredContact === "CONTACT_RADIO_PHONE")) {
        postMessageToUser(
          agentPhone,
          `Your buyer ${userState?.buyer?.name?.toUpperCase()} added ${Number(streetNumber) ?? ""} ${streetName ?? ""} ${city ?? ""}, ${state ?? ""} ${postalCode ?? ""} | #${mlsId} valued at ${price.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 3,
          })} to their favorites list.`
        );
      }

      await inviteAgentByBuyer(
        linkedAgentId,
        'liked',
        `Your buyer ${userState?.buyer?.name
        } added ${Number(streetNumber) ?? ""} ${streetName ?? ""} ${city ?? ""}, ${state ?? ""} ${postalCode ?? ""} | #${mlsId} valued at ${price.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 3,
        })} to their favorites list`,
        propertyId,
        '',
        '',
        userState?.buyer?.buyerId || buyerId
      );
    }

    if (userState.userType === UserType.AGENT) {

      await createBuyerNotificationById(
        buyerId,
        `Your Agent ${userState?.agent?.name?.toUpperCase()} added ${Number(streetNumber) ?? ""} ${streetName ?? ""} ${city ?? ""}, ${state ?? ""} ${postalCode ?? ""} | #${mlsId} valued at ${price.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 3,
        })} to your favorities list.`,
        'Comment',
        userState?.agent?.token,
        'buyer',
        propertyId,
        "No",
        '',
        '',
        '',
      );

      if (messageType === "Yes" && (preferredContact === "CONTACT_RADIO_BOTH" ||
        preferredContact === "CONTACT_RADIO_PHONE")) {
        postMessageToUser(
          agentState.buyerPhone,
          `Your Agent ${userState?.agent?.name?.toUpperCase()} added ${Number(streetNumber) ?? ""} ${streetName ?? ""} ${city ?? ""}, ${state ?? ""} ${postalCode ?? ""} | #${mlsId} valued at ${price.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 3,
          })} to your favorities list.`
        );
      }
    }
    setIsLoading(false)
  };

  const toggleVisited = async () => {
    if (isViewedFavorite || isViewedRequested) return;
    setIsShowingVisitedModal(!isShowingVisitedModal);
  };

  const checkIfRequested = async () => {
    if (!userState.isLoggedIn) {
      return;
    }
    var mayBeRequested = false;

    switch (userState.userType) {
      case UserType.BUYER:
        mayBeRequested = await checkIfPropertyIsRequestedForBuyerService(
          propertyId,
          userState.buyer.buyerId,
          userState.buyer.token
        );
        break;
      case UserType.AGENT:
        mayBeRequested = await checkIfPropertyIsRequestedForBuyerService(
          propertyId,
          agentState.buyerId,
          userState.agent.token
        );
        break;
      default:
        break;
    }
    setIsRequested(mayBeRequested);
  };

  const checkIfFavorite = async () => {
    if (!userState.isLoggedIn) {
      return;
    }

    var mayBeFavorite = false;

    switch (userState.userType) {
      case UserType.BUYER:
        mayBeFavorite = await checkIfPropertyIsFavoriteForBuyerService(
          propertyId,
          userState.buyer.buyerId,
          userState.buyer.token
        );
        break;
      case UserType.AGENT:
        mayBeFavorite = await checkIfPropertyIsFavoriteForBuyerService(
          propertyId,
          agentState.buyerId,
          userState.agent.token
        );
        break;
      default:
        break;
    }

    setIsFavorite(mayBeFavorite);
  };

  const checkIfViewedRequested = async () => {
    if (!userState.isLoggedIn) {
      return;
    }

    var mayBeRequested = false;

    switch (userState.userType) {
      case UserType.BUYER:
        mayBeRequested = await checkIfPropertyIsViewedRequestedForBuyerService(
          propertyId,
          userState.buyer.buyerId,
          userState.buyer.token
        );
        break;
      case UserType.AGENT:
        mayBeRequested = await checkIfPropertyIsViewedRequestedForBuyerService(
          propertyId,
          agentState.buyerId,
          userState.agent.token
        );
        break;
      default:
        break;
    }
    if (mayBeRequested) {
      setIsRequested(mayBeRequested);
      setIsVisited(mayBeRequested);
    }
    setIsViewedRequested(mayBeRequested);
  };

  const checkIfViewedFavorite = async () => {
    if (!userState.isLoggedIn) {
      return;
    }

    var mayBeFavorite = false;

    switch (userState.userType) {
      case UserType.BUYER:
        mayBeFavorite = await checkIfPropertyIsViewedFavoritesForBuyerService(
          propertyId,
          userState.buyer.buyerId,
          userState.buyer.token
        );
        break;
      case UserType.AGENT:
        mayBeFavorite = await checkIfPropertyIsViewedFavoritesForBuyerService(
          propertyId,
          agentState.buyerId,
          userState.agent.token
        );
        break;
      default:
        break;
    }
    if (mayBeFavorite) {
      setIsVisited(mayBeFavorite);
      setIsFavorite(mayBeFavorite);
    }
    setIsViewedFavorite(mayBeFavorite);
  };

  const checkIfListed = async () => {
    try {
      const response: any = await checkIsListedPropertyService(propertyId);
      if (response?.success) {
        if (response?.data?.listedPropertyPreferences !== null) {
          setShowingType(
            response?.data?.listedPropertyPreferences?.showingType
          );
          setIsAcceptedRequest(
            response?.data?.listedPropertyPreferences?.acceptRequest
          );
        }

        setIsListed(response?.success);
        setIsPropListed(response?.success);
        setListingAgent(response?.data?.agent);
        setListingAgentId(response?.data?.agentId ?? 0);
        setListingAgentSellerName(response?.data?.name ?? '');
        setListingAgentEmail(response?.data?.agentEmail);
        setListingAgentName(response?.data?.agentName);
        setListingAgentSellerPhone(response?.data?.sellerPhone);
      }
    } catch (error) {
      console.log('ERROR: ', error);
    }
  };

  const confirmModalHandler = () => {
    setIsShowFindAnAgentConfirmModal(!isShowFindAnAgentConfirmModal);
  };

  const linkproperty = async () => {
    // here we add linked property api
    if (!userState.isLoggedIn) {
      notification(
        'you need to login before you can perform this action.',
        'error'
      );
      return;
    }

    // let take token and buyerid and propertyid
    // property id is already define in property card

    let token = '',
      buyerId = -1,
      agentId = -1,
      agentName = '',
      listingAgentActivatedPlan = 'Navihome Free Plan';
    setIsLinked(!isLinked);
    setIsLoading(true);

    switch (userState?.userType) {
      case UserType.BUYER:
        buyerId = userState?.buyer?.buyerId;
        agentId = agentIdForBuyer;
        agentName = userState?.buyer?.buyerAgentName;
        token = userState?.buyer?.token;
        addLinked['agentId'] = Number(agentIdForBuyer);
        break;

      case UserType.AGENT:
        buyerId = agentState?.buyerId;
        agentId = userState?.agent?.agentId;
        token = userState?.agent?.token;
        agentName = userState?.agent?.name;
        addLinked['agentId'] = userState?.agent?.agentId;
        break;
      default:
        break;
    }
    if (listingAgent) {
      const listingAgentActivatedPlans: any = await getActivatedSubscriptionPlan(listingAgentId, userState?.agent?.token);
      listingAgentActivatedPlan = listingAgentActivatedPlans?.activePlan ? listingAgentActivatedPlans?.activePlan : 'Navihome Free Plan'
    }

    await addLinkedPropertyForBuyer(propertyId, buyerId, token, addLinked);

    let newObj1: any = propertyDetails?.success
      ? propertyDetails?.data
      : propertyDetails;
    newObj1['agentId'] = agentId;
    newObj1['buyerId'] = buyerId;
    newObj1['propertyCategory'] = openHouse || property_type || '';
    newObj1['openHouseStartTime'] = propertyDetails?.success
      ? propertyDetails?.openHouseStartTime
      : openHouseStartTime
        ? openHouseStartTime
        : null;
    newObj1['openHouseEndTime'] = propertyDetails?.success
      ? propertyDetails?.openHouseEndTime
      : openHouseEndTime
        ? openHouseEndTime
        : null;
    newObj1['openHouseId'] = propertyDetails?.success
      ? propertyDetails?.openHouseId
      : openHouseId
        ? openHouseId
        : '';
    newObj1['openHouseKey'] = propertyDetails?.success
      ? propertyDetails?.openHouseKey
      : openHouseKey
        ? openHouseKey
        : '';
    newObj1['isFavorite'] = isFavorite;
    newObj1['isRequested'] = isRequested;
    newObj1['isListed'] = isListed;
    newObj1['listingAgentId'] = listingAgentId;
    newObj1['listingAgent'] = listingAgent;
    newObj1['client'] = listingAgentSellerName;
    newObj1['agentName'] = agentName;
    newObj1['listingAgentEmail'] = listingAgentEmail;
    newObj1['showingType'] = showingType;
    newObj1['clientPhone'] = listingAgentSellerPhone;
    newObj1['listingAgentName'] = listingAgentName;
    newObj1['acceptedRequest'] = isAcceptedRequest;
    newObj1['listingAgentActivatedPlan'] = listingAgentActivatedPlan;
    let arr1: any = [newObj1];
    setLinkedPropertiesData([...linkedPropertiesData, ...arr1]);

    let arr2: any = [newObj1];
    setFilteredLinkedProperties([...filteredLinkedProperties, ...arr2]);

    let arr = [];
    let newObj: any;
    if (linkedProperties && linkedProperties.length > 0) {
      newObj = {
        ...listing,
        ...{
          openHouseStartTime: openHouseStartTime,
          openHouseEndTime: openHouseEndTime,
          isListed: true,
          listingAgentId: 1000,
        },
      };
      // arr = [...linkedProperties, listing];
      arr = [...linkedProperties, newObj];
    } else {
      let newObj = {
        ...listing,
        ...{
          openHouseStartTime: openHouseStartTime,
          openHouseEndTime: openHouseEndTime,
          isListed: false,
          listingAgentId: 100,
        },
      };
      // arr = [{...listing,...{'openHouseStartTime':openHouseStartTime,'openHouseEndTime':openHouseEndTime}}];
      arr = [newObj];
    }
    setIsLoading(false);
    setLinkedProperties(arr);
  };

  const closeAction = () => {
    setIsRequestShowingModal(!isRequestShowingModal);
  };

  const closeAction2 = () => {
    setIsFavoriteShowingModal(!isFavoriteShowingModal);
  };

  const handleForgotPasswordModal = () => {
    setIsShowingForgotPasswordModal(!isShowingForgotPasswordModal);
  };

  const unlinkproperty = async () => {
    // here we call unlinked property api
    if (!userState.isLoggedIn) {
      notification(
        'you need to login before you can perform this action.',
        'error'
      );
      return;
    }

    let token = '';
    let buyerId = -1;
    setIsLinked(!isLinked);
    setIsLoading(true);
    switch (userState.userType) {
      case UserType.BUYER:
        buyerId = userState.buyer.buyerId;
        token = userState.buyer.token;
        break;

      case UserType.AGENT:
        buyerId = agentState.buyerId;
        token = userState.agent.token;
        break;
      default:
        break;
    }
    await removeLinkedPropertyForBuyer(propertyId, buyerId, token);
    let arr = [...linkedProperties];
    let arr2 = [...linkedPropertiesData];

    const index = arr?.findIndex(
      (item) => (item?.propertyId || item?.mlsId) === propertyId
    );
    const index2 = arr2?.findIndex(
      (item) => (item?.propertyId || item?.mlsId) === propertyId
    );

    if (index > -1) {
      arr.splice(index, 1);
    }
    if (index2 > -1) {
      arr2.splice(index2, 1);
    }
    setIsLoading(false);
    setLinkedProperties(arr);
    setLinkedPropertiesData(arr2);
    setFilteredLinkedProperties(arr2);
  };


  const handleViewProperty = async () => {
    let url = '';
    const isPropertiesPage = history?.location?.pathname;
    let isPropertiesPageText = 'no';
    if (isPropertiesPage === '/properties') {
      isPropertiesPageText = 'yes';
    }
    let param =
      upcomingBuyerId || linkedBuyerId
        ? `&id=${linkedBuyerId ? linkedBuyerId : upcomingBuyerId}`
        : '';

    if (history?.location?.pathname?.includes('/common')) {
      let token = '',
        buyerId = -1,
        agentId = -1,
        agentName = '',
        tempPropsDetails: any = [],
        listingAgentActivatedPlan = ''
      setIsLinked(!isLinked);
      setIsLoading(true);

      switch (userState?.userType) {
        case UserType.BUYER:
          buyerId = userState?.buyer?.buyerId;
          agentId = agentIdForBuyer;
          agentName = userState?.buyer?.buyerAgentName;
          token = userState?.buyer?.token;
          addLinked['agentId'] = Number(agentIdForBuyer);
          break;

        case UserType.AGENT:
          buyerId = agentState?.buyerId;
          agentId = userState?.agent?.agentId;
          token = userState?.agent?.token;
          agentName = userState?.agent?.name;
          addLinked['agentId'] = userState?.agent?.agentId;
          break;
        default:
          break;
      }
      if (listingAgent) {
        const listingAgentActivatedPlans: any = await getActivatedSubscriptionPlan(listingAgentId, userState?.agent?.token);
        listingAgentActivatedPlan = listingAgentActivatedPlans?.activePlan ? listingAgentActivatedPlans?.activePlan : 'Navihome Free Plan'
      }

      tempPropsDetails.push(addLinked);

      let newObj1: any = propertyDetails?.success
        ? propertyDetails?.data
        : propertyDetails;
      newObj1['agentId'] = agentId;
      newObj1['buyerId'] = buyerId;
      newObj1['openHouseStartTime'] = propertyDetails?.success
        ? propertyDetails?.openHouseStartTime
        : openHouseStartTime
          ? openHouseStartTime
          : null;
      newObj1['openHouseEndTime'] = propertyDetails?.success
        ? propertyDetails?.openHouseEndTime
        : openHouseEndTime
          ? openHouseEndTime
          : null;
      newObj1['openHouseId'] = propertyDetails?.success
        ? propertyDetails?.openHouseId
        : openHouseId
          ? openHouseId
          : '';
      newObj1['openHouseKey'] = propertyDetails?.success
        ? propertyDetails?.openHouseKey
        : openHouseKey
          ? openHouseKey
          : '';
      newObj1['isFavorite'] = isFavorite ?? false;
      newObj1['isRequested'] = isRequested ?? false;
      newObj1['isListed'] = isListed ?? false;
      newObj1['listingAgentId'] = listingAgentId ?? '';
      newObj1['listingAgent'] = listingAgent ?? '';
      newObj1['client'] = listingAgentSellerName ?? '';
      newObj1['agentName'] = agentName ?? '';
      newObj1['listingAgentEmail'] = listingAgentEmail ?? '';
      newObj1['showingType'] = showingType ?? '';
      newObj1['clientPhone'] = listingAgentSellerPhone;
      newObj1['listingAgentName'] = listingAgentName;
      newObj1['acceptedRequest'] = isAcceptedRequest ?? '';
      newObj1['listingAgentActivatedPlan'] = listingAgentActivatedPlan;

      tempPropsDetails.push(newObj1);

      setPropDataDetails(tempPropsDetails);

      setTourPropPage(true);
      setUnlinkPropHeader(false);
      setPropId(propertyId);
      SetPropStartTime(openHouseStartTime);
      SetPropEndTime(openHouseEndTime);
      setPropListingId(propertyDetails?.listingId);
      setIsPropFav(isFavorite);
      setIsPropReq(isRequested);
      const propIndex = linkedPropertiesData?.findIndex(
        (item: any) =>
          (item?.mlsId ? item?.mlsId : item?.propertyId) === propertyId
      );
      if (propIndex !== -1) {
        setshowAddPropertyBtn(true);
      } else {
        setshowAddPropertyBtn(false);
      }
      return;
    }

    let isViewed = false;

    if ((isViewedRequested || isViewedFavorite || isPropViewed === 'Yes')) {
      isViewed = true;
    };

    setTourType(tourType)
    if (tourType === "tour-property") setScheduledTourId(Number(tourId) ?? 0);
    else setSingleTourId(Number(tourId) ?? 0)

    if (linkedBuyerId !== undefined || upcomingBuyerId) {
      url = openHouse
        ? `/properties/${propertyId}?type=${openHouse}&param=${param}&listingId=${listing}&openHouseKey=${openHouseKey}&filter=${isPropertiesPageText}&buyerDashboard=${isBuyerDashboard ?? ''
        }&isViewed=${isViewed}`
        : `/properties/${propertyId}?type=''&param=${param}&listingId=${listing}&filter=${isPropertiesPageText}&buyerDashboard=${isBuyerDashboard ?? ''
        }&isViewed=${isViewed}`;
    } else {
      url = openHouse
        ? openHouse === 'open_house'
          ? `/properties/${propertyId}?type=${openHouse}&listingId=${listing}&openHouseKey=${openHouseKey}&filter=${isPropertiesPageText}&buyerDashboard=${isBuyerDashboard ?? ''
          }&isViewed=${isViewed}`
          : `/properties/${propertyId}?type=${openHouse}&filter=${isPropertiesPageText}&buyerDashboard=${isBuyerDashboard ?? ''
          }&isViewed=${isViewed}`
        : openHouse === 'open_house'
          ? `/properties/${propertyId}?listingId=${listing}&filter=${isPropertiesPageText}&buyerDashboard=${isBuyerDashboard ?? ''
          }&isViewed=${isViewed}`
          : `/properties/${propertyId}?filter=${isPropertiesPageText}&buyerDashboard=${isBuyerDashboard ?? ''
          }&isViewed=${isViewed}`;
    }

    if (!history.location.pathname.includes('/common')) {
      history.push(url)
    } else {
      setRedirectUrl(url);
      setTimeout(() => {
        setRedirectUrl("")
      }, 100)
    }
  };

  const handlerightViewProperty = () => {
    let url = '';
    const isPropertiesPage = history?.location?.pathname;
    let isPropertiesPageText = 'no';
    if (isPropertiesPage === '/properties') {
      isPropertiesPageText = 'yes';
    }
    let param =
      upcomingBuyerId || linkedBuyerId
        ? `&id=${linkedBuyerId ? linkedBuyerId : upcomingBuyerId}`
        : '';

    if (history?.location?.pathname?.includes('/common')) {
      let token = '';
      let buyerId = -1;
      let agentId = -1;
      let agentName = '';
      let tempPropsDetails: any = [];
      setIsLinked(!isLinked);
      setIsLoading(true);

      switch (userState?.userType) {
        case UserType.BUYER:
          buyerId = userState?.buyer?.buyerId;
          agentId = agentIdForBuyer;
          agentName = userState?.buyer?.buyerAgentName;
          token = userState?.buyer?.token;
          addLinked['agentId'] = Number(agentIdForBuyer);
          break;

        case UserType.AGENT:
          buyerId = agentState?.buyerId;
          agentId = userState?.agent?.agentId;
          token = userState?.agent?.token;
          agentName = userState?.agent?.name;
          addLinked['agentId'] = userState?.agent?.agentId;
          break;
        default:
          break;
      }

      tempPropsDetails.push(addLinked);

      let newObj1: any = propertyDetails?.success
        ? propertyDetails?.data
        : propertyDetails;
      newObj1['agentId'] = agentId;
      newObj1['buyerId'] = buyerId;
      newObj1['openHouseStartTime'] = propertyDetails?.success
        ? propertyDetails?.openHouseStartTime
        : openHouseStartTime
          ? openHouseStartTime
          : null;
      newObj1['openHouseEndTime'] = propertyDetails?.success
        ? propertyDetails?.openHouseEndTime
        : openHouseEndTime
          ? openHouseEndTime
          : null;
      newObj1['openHouseId'] = propertyDetails?.success
        ? propertyDetails?.openHouseId
        : openHouseId
          ? openHouseId
          : '';
      newObj1['openHouseKey'] = propertyDetails?.success
        ? propertyDetails?.openHouseKey
        : openHouseKey
          ? openHouseKey
          : '';
      newObj1['isFavorite'] = isFavorite ?? false;
      newObj1['isRequested'] = isRequested ?? false;
      newObj1['isListed'] = isListed ?? false;
      newObj1['listingAgentId'] = listingAgentId ?? '';
      newObj1['listingAgent'] = listingAgent ?? '';
      newObj1['client'] = listingAgentSellerName ?? '';
      newObj1['agentName'] = agentName ?? '';
      newObj1['listingAgentEmail'] = listingAgentEmail ?? '';
      newObj1['showingType'] = showingType ?? '';
      newObj1['clientPhone'] = listingAgentSellerPhone;
      newObj1['listingAgentName'] = listingAgentName;
      newObj1['acceptedRequest'] = isAcceptedRequest ?? '';

      tempPropsDetails?.push(newObj1);

      setPropDataDetails(tempPropsDetails);

      setTourPropPage(true);
      setUnlinkPropHeader(false);
      setPropId(propertyId);
      SetPropStartTime(openHouseStartTime);
      SetPropEndTime(openHouseEndTime);
      setPropListingId(propertyDetails?.listingId);
      setIsPropFav(isFavorite);
      setIsPropReq(isRequested);
      const propIndex = linkedPropertiesData?.findIndex(
        (item: any) =>
          (item?.mlsId ? item?.mlsId : item?.propertyId) === propertyId
      );
      if (propIndex !== -1) {
        setshowAddPropertyBtn(true);
      } else {
        setshowAddPropertyBtn(false);
      }
      return;
    }
    let isViewed = false;

    if ((isViewedRequested || isViewedFavorite || isPropViewed === 'Yes')) {
      isViewed = true;
    };

    if (linkedBuyerId !== undefined || upcomingBuyerId) {
      url = openHouse
        ? `/properties/${propertyId}?type=${openHouse}&param=${param}&listingId=${listing}&openHouseKey=${openHouseKey}&filter=${isPropertiesPageText}&buyerDashboard=${isBuyerDashboard ?? ''
        }&isViewed=${isViewed}`
        : `/properties/${propertyId}?type=''&param=${param}&listingId=${listing}&filter=${isPropertiesPageText}&buyerDashboard=${isBuyerDashboard ?? ''
        }&isViewed=${isViewed}`;
    } else {
      url = openHouse
        ? openHouse === 'open_house'
          ? `/properties/${propertyId}?type=${openHouse}&listingId=${listing}&openHouseKey=${openHouseKey}&filter=${isPropertiesPageText}&buyerDashboard=${isBuyerDashboard ?? ''
          }&isViewed=${isViewed}`
          : `/properties/${propertyId}?type=${openHouse}&filter=${isPropertiesPageText}&buyerDashboard=${isBuyerDashboard ?? ''
          }&isViewed=${isViewed}`
        : openHouse === 'open_house'
          ? `/properties/${propertyId}?listingId=${listing}&filter=${isPropertiesPageText}&buyerDashboard=${isBuyerDashboard ?? ''
          }&isViewed=${isViewed}`
          : `/properties/${propertyId}?filter=${isPropertiesPageText}&buyerDashboard=${isBuyerDashboard ?? ''
          }&isViewed=${isViewed}`;
    }
    // setRedirectUrl(`properties/${propertyId}`);
    setRedirectUrl(url);

    setTimeout(() => {
      setRedirectUrl("")
    }, 100)
  }

  useEffect(() => {
    checkIfRequested();
    checkIfFavorite();
    checkIfViewedRequested();
    checkIfViewedFavorite();
    checkIfListed();
  }, [propertyId]);

  return (
    <div className="property-card">
      {isLoading && <CustomSpinner />}
      {openHouse === 'open_house' || property_type === 'open_house' ? (
        <>
          <CustomOpenHouseDiv>
            <p>OPEN HOUSE</p>
          </CustomOpenHouseDiv>
          {openHouseStartTime !== null && openHouseEndTime !== null  && isBuyerDashboard !== "scheduled" && isBuyerDashboard !== "comments" && isBuyerDashboard !== "viewed" && isBuyerDashboard !== "viewedFavorite" && (
            <CustomTimeStampDiv>
              <p>
                {moment(openHouseStartTime)
                  .tz('America/New_York')
                  .format('h:mm a')
                  .split(' ')[1] ===
                  moment(openHouseEndTime)
                    .tz('America/New_York')
                    .format('h:mm a')
                    .split(' ')[1]
                  ? moment(openHouseStartTime)
                    .tz('America/New_York')
                    .minutes() === Number('00')
                    ? moment(openHouseEndTime)
                      .tz('America/New_York')
                      .minutes() === Number('00')
                      ? moment(openHouseStartTime)
                        .tz('America/New_York')
                        .format('ddd') +
                      ', ' +
                      moment(openHouseStartTime)
                        .tz('America/New_York')
                        .format('h') +
                      ' - ' +
                      moment(openHouseEndTime)
                        .tz('America/New_York')
                        .format('h a')
                      : moment(openHouseStartTime)
                        .tz('America/New_York')
                        .format('ddd') +
                      ', ' +
                      moment(openHouseStartTime)
                        .tz('America/New_York')
                        .format('h') +
                      ' - ' +
                      moment(openHouseEndTime)
                        .tz('America/New_York')
                        .format('h:mm a')
                    : moment(openHouseEndTime)
                      .tz('America/New_York')
                      .minute() === Number('00')
                      ? moment(openHouseStartTime)
                        .tz('America/New_York')
                        .format('ddd') +
                      ', ' +
                      moment(openHouseStartTime)
                        .tz('America/New_York')
                        .format('h') +
                      ' - ' +
                      moment(openHouseEndTime)
                        .tz('America/New_York')
                        .format('h a')
                      : moment(openHouseStartTime)
                        .tz('America/New_York')
                        .format('ddd') +
                      ', ' +
                      moment(openHouseStartTime)
                        .tz('America/New_York')
                        .format('h') +
                      ' - ' +
                      moment(openHouseEndTime)
                        .tz('America/New_York')
                        .format('h:mm a')
                  : moment(openHouseStartTime)
                    .tz('America/New_York')
                    .minute() === Number('00')
                    ? moment(openHouseEndTime).tz('America/New_York').minute() ===
                      Number('00')
                      ? moment(openHouseStartTime)
                        .tz('America/New_York')
                        .format('ddd') +
                      ', ' +
                      moment(openHouseStartTime)
                        .tz('America/New_York')
                        .format('h a ') +
                      ' - ' +
                      moment(openHouseEndTime)
                        .tz('America/New_York')
                        .format('h a')
                      : moment(openHouseStartTime)
                        .tz('America/New_York')
                        .format('ddd') +
                      ', ' +
                      moment(openHouseStartTime)
                        .tz('America/New_York')
                        .format('h:mm ') +
                      ' - ' +
                      moment(openHouseEndTime)
                        .tz('America/New_York')
                        .format('h:mm a')
                    : moment(openHouseEndTime).tz('America/New_York').minute() ===
                      Number('00')
                      ? moment(openHouseStartTime)
                        .tz('America/New_York')
                        .format('ddd') +
                      ', ' +
                      moment(openHouseStartTime)
                        .tz('America/New_York')
                        .format('h:mm a') +
                      ' - ' +
                      moment(openHouseEndTime)
                        .tz('America/New_York')
                        .format('h a')
                      : moment(openHouseStartTime)
                        .tz('America/New_York')
                        .format('ddd') +
                      ', ' +
                      moment(openHouseStartTime)
                        .tz('America/New_York')
                        .format('h:mm a') +
                      ' - ' +
                      moment(openHouseEndTime)
                        .tz('America/New_York')
                        .format('h:mm a')}
              </p>
            </CustomTimeStampDiv>
          )}
        </>
      ) : (
        <></>
      )}

      {
        redirectUrl ? <a href={redirectUrl} onClick={handleViewProperty} onContextMenu={handlerightViewProperty}>
          <img
            className="property-card__image"
            src={image}
            alt="Property"
          />
        </a> :
          <img
            className="property-card__image"
            src={image}
            onClick={handleViewProperty}
            onContextMenu={handlerightViewProperty}
            alt="Property"
          />
      }

      <div className="property-card__info">
        <div className="property-card__price">
          <p>
            {price?.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 3,
            })}
          </p>
          {isListed && <img src={iconConnected} alt="Connected" />}
        </div>
        {userState.userType === UserType.AGENT && upcomingBuyerId === 'null' ? (
          <></>
        ) : (
          <div className="property-card__buttons">
            {userState?.isLoggedIn &&
              userState.userType === UserType.AGENT &&
              !history?.location?.pathname?.includes('/common') && (
                <div
                  className={
                    isViewedRequested || isViewedFavorite || isPropViewed === 'Yes'
                      ? 'property-card__button cursorDisabled'
                      : 'property-card__button'
                  }
                  onClick={isViewedRequested || isViewedFavorite || isPropViewed === 'Yes' ? () => { } : toggleVisited}
                >
                  {(isViewedRequested || isViewedFavorite || isPropViewed === 'Yes') ? (
                    <>
                      <Popover>
                        <img src={checkboxOn} alt="Visited On Button" />{' '}
                      </Popover>
                    </>
                  ) : (
                    <>
                      <img src={checkboxOff} alt="Visited Off Button" />
                    </>
                  )}
                </div>
              )}

            {isSellTab ? (
              <></>
            ) : (
              <>
                <div
                  className={
                    isViewedRequested || isViewedFavorite || isPropViewed === 'Yes'
                      ? 'property-card__button cursorDisabled'
                      : 'property-card__button'
                  }
                  onClick={isViewedRequested || isViewedFavorite || isPropViewed === 'Yes' ? () => { } : toggleRequested}
                >
                  {isRequested ? (
                    <>
                      <Popover
                        content={
                          isViewedRequested || isViewedFavorite || isPropViewed === 'Yes' ? '' : removeFromShowingsContent
                        }
                      >
                        <img
                          src={requestShowingOn}
                          alt="Showing On Button"
                        />{' '}
                      </Popover>
                    </>
                  ) : (
                    <>
                      <Popover
                        content={isViewedRequested || isViewedFavorite || isPropViewed === 'Yes' ? '' : addToShowingsContent}
                      >
                        <img
                          src={requestShowingOff}
                          alt="Showing Off Button"
                        />{' '}
                      </Popover>
                    </>
                  )}
                </div>
                <div
                  className={
                    isViewedRequested || isViewedFavorite || isPropViewed === 'Yes'
                      ? 'property-card__button cursorDisabled'
                      : 'property-card__button'
                  }
                  onClick={isViewedRequested || isViewedFavorite || isPropViewed === 'Yes' ? () => { } : toggleFavorite}
                >
                  {isFavorite ? (
                    <>
                      <Popover
                        content={isViewedRequested || isViewedFavorite || isPropViewed === 'Yes' ? '' : removeFromFavContent}
                      >
                        <img
                          src={favoriteOn}
                          alt="Favorite On Button"
                        />{' '}
                      </Popover>
                    </>
                  ) : (
                    <>
                      <Popover content={isViewedRequested || isViewedFavorite || isPropViewed === 'Yes' ? '' : addToFavContent}>
                        <img
                          src={favoriteOff}
                          alt="Favorite Off Button"
                        />{' '}
                      </Popover>
                    </>
                  )}
                </div>
              </>

            )}
          </div>
        )}
        <p className="property-card__type">{type}</p>
        <p className="property-card__beds">{beds ?? 0} beds</p>
        {/* <p className="property-card__baths">{(baths > 0 ? bathsHalf > 0 ? baths + 0.5 : baths : 0) * (bathsHalf > 0 ? bathsHalf : 1)} baths</p> */}
        <p className="property-card__baths">{(baths > 0 ? bathsHalf > 0 ? baths + bathsHalf / 2 : baths : 0)} baths</p>
        <p className="property-card__sqft">
          {squareFootage ? squareFootage?.toLocaleString('en-US', {
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 3,
          }) : 0}{' '}
          sqft
        </p>

        <p className="property-card__address">
          {`${streetNumber > 0 ? streetNumber : ""} ${streetName ?? ''}` ?? ''}
          <br />
          {`${city ?? ''}, ${state ?? ''} ${postalCode ?? ''}`}
        </p>
        <p className="property-card__plus">
          {((userState.agent &&
            (history.location.pathname === '/newtourpage' ||
              history.location.pathname === '/common')) ||
            (userState.userType === UserType.BUYER &&
              (history.location.pathname === '/newtourpage' ||
                history.location.pathname === '/common'))) && (
              <>
                {isadded ? (
                  <img
                    className="buyer-preview-card__add-button"
                    src={PropertyAdd}
                    alt="add button"
                    onClick={linkproperty}
                  />
                ) : (
                  <img
                    className="buyer-preview-card__add-button"
                    src={buttonDelete}
                    alt="delete button"
                    onClick={unlinkproperty}
                  />
                )}
              </>
            )}
        </p>

        {!userState?.buyer?.isLinked &&
          userState?.isLoggedIn &&
          userState.userType === UserType.BUYER &&
          isShowFindAnAgentConfirmModal && (
            <FindAnAgentConfirmModal
              isShowing={isShowFindAnAgentConfirmModal}
              closeAction={confirmModalHandler}
              text="Since you are not conneted with an agent, you need to connect with an agent."
              yesText="FIND AN AGENT"
              noText="INVITE YOUR AGENT"
              pageType="FindAnAgent"
            />
          )}
        {isShowingLoginModal && (
          <LogInModal
            isShowing={isShowingLoginModal}
            setIsShowingForgotPasswordModal={setIsShowingForgotPasswordModal}
            closeAction={() => {
              setIsShowingLoginModal(!isShowingLoginModal);
            }}
          />
        )}
        {isShowingForgotPasswordModal && (
          <ForgotPasswordModal
            isShowing={isShowingForgotPasswordModal}
            closeAction={() => {
              setIsShowingForgotPasswordModal(!isShowingForgotPasswordModal);
            }}
            setIsShowingLoginModal={() => {
              setIsShowingLoginModal(!isShowingLoginModal);
            }}
          />
        )}
        {isRequestShowingModal && (
          <RequestShowingModal
            isShowing={isRequestShowingModal}
            closeAction={closeAction}
            setIsShowingForgotPasswordModal={handleForgotPasswordModal}
          />
        )}
        {isFavoriteShowingModal && (
          <FavoriteShowingModal
            isShowing={isFavoriteShowingModal}
            closeAction={closeAction2}
            setIsShowingForgotPasswordModal={handleForgotPasswordModal}
          />
        )}
        {isShowingVisitedModal && (
          <VisitedModal
            isFavorite={isFavorite}
            isRequested={isRequested}
            isShowing={isShowingVisitedModal}
            closeAction={toggleVisited}
            propertyId={propertyId}
            buyerId={agentState.buyerId}
            property_type={property_type}
            selectFavoriteProperties={selectFavoriteProperties}
            selectRequestedShowings={selectRequestedShowings}
            selectPropertyComments={selectPropertyComments}
            getFavoriteProperties={getFavoriteProperties}
            getRequestedProperties={getRequestedProperties}
            addViewedProperties={addViewedProperties}
            addViewedFavProperties={addViewedFavProperties}
            setIsVisited={setIsVisited}
            linkedBuyerName={linkedBuyerName}
          />
        )}
      </div>
    </div>
  );
};

export default PropertyCard;
