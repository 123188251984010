import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/notifications.css';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
TimeAgo.addDefaultLocale(en);

export interface IShowingNotificationProps {
  notification: any;
}

const ShowingNotification: React.FunctionComponent<
  IShowingNotificationProps
> = ({ notification }) => {
  const timeAgo = new TimeAgo('en-US');
  return (
    <div className="notification">
      <div>
        <FontAwesomeIcon
          icon={faBell}
          className="notificationIcon"
        ></FontAwesomeIcon>
      </div>
      <div>
        <div className="notfText">
          {/* You have an upcoming showing on {notification.date} from {notification.time} by <span className="agentText">{notification.agent}</span> */}
          {notification?.isRead ? (
            <>{notification?.message}</>
          ) : (
            <span style={{ fontWeight: 'bold', color: 'black' }}>
              {notification?.message}
            </span>
          )}
          {/* {notification?.message} */}
        </div>
        <div className="timestamp">
          {timeAgo?.format(new Date(notification?.createdAt))}
        </div>
      </div>
    </div>
  );
};

export default ShowingNotification;
