/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import styled from "@emotion/styled";
import { Col, Row } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useLocation } from "react-router-dom";
import UserContext, { UserType } from "../contexts/UserContext";
import TourHomeOfficeCard from "./TourHomeOfficeCard";
import ToursCard from "./ToursCard";

const CustomRow = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomCol = styled(Col) <{
  isColorRed?: false;
}>`
  position: relative;
  padding-left: 7px;
`;
const CustomColInnderDiv = styled.div<{
  isColorRed?: false;
}>`
  height: 30px;
  width: 30px;
  background-color: grey;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: absolute;
  left: 0px;
  top: 54px;
  z-index: 2;
`;

const SrcDesPara = styled.p`
  font-size: 2.4rem;
  font-weight: 700;
  color: white;
`;

interface TourPropertyDetailsProps {
  buyerGlobalId?: any;
  linkedProperties?: any;
  setLinkedProperties?: any;
  setShowMap?: any;
  setUnlinkPropHeader?: any;
  setTourPropPage?: any;
  bestRoute?: any;
  setManualChangeProperties?: any;
  setUseManual?: any;
  dragDropWorking?: any;
  numberingMarker?: any;
  useManual?: boolean;
  travelTime?: any;
  dateTime?: any;
  serviceTime?: string;
  startTimeForTimeStamp?: any;
  formData?: any;
  statusType?: any;
  linkedPropertiesData?: any;
  setLinkedPropertiesData?: any;
  filteredLinkedProperties?: Array<any>;
  saveButtonData?: any;
  setselected?: (val: string) => void;
  setRunMap?: (val: boolean) => void;
  setIsUnlinkedPropertyClicked?: (val: boolean) => void;
  setIsOutTimeProperty?: (val: boolean) => void;
  setEliminatePropertyFromTheTour?: (value: number) => void;
  SetShowTimeBar?: any;
  showTimeBar?: boolean;
  unlinkedPropertyClicked?: boolean;
  handleTogglingClicked?: string;
  setFilteredLinkedProperties?: (val: Array<any>) => void;
  setIsAllAccepted?: (val: boolean) => void;
  setScheduleStatus?: (val: boolean) => void;
  setisScheduledRouteUpdated?: (val: boolean) => void;
  setBestRoute?: (val: Array<any>) => void;
  setHandleTogglingClicked?: (val: string) => void;
}

const TourPropertyDetails: React.FC<TourPropertyDetailsProps> = ({
  buyerGlobalId,
  setLinkedProperties,
  setShowMap,
  setUnlinkPropHeader,
  setTourPropPage,
  setIsAllAccepted,
  setScheduleStatus,
  startTimeForTimeStamp,
  setManualChangeProperties,
  setUseManual,
  dragDropWorking,
  numberingMarker,
  useManual,
  travelTime,
  dateTime,
  serviceTime,
  formData,
  setselected,
  setRunMap,
  statusType,
  setBestRoute,
  setHandleTogglingClicked,
  setIsOutTimeProperty,
  setEliminatePropertyFromTheTour,
  linkedPropertiesData,
  setLinkedPropertiesData,
  filteredLinkedProperties,
  setisScheduledRouteUpdated,
  setFilteredLinkedProperties,
  SetShowTimeBar,
  showTimeBar,
  unlinkedPropertyClicked,
  setIsUnlinkedPropertyClicked
}: any) => {
  const [items, setItems] = useState([]);
  const [isNotEditable, setIsNotEditable] = useState<boolean>(false);

  const { userState } = useContext(UserContext);

  const [timeOutProperty, setTimeOutProperty] = useState([]);
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let param = useQuery();
  if (serviceTime?.split(" ")?.[1] === "min") {
    serviceTime = Number(serviceTime?.split(" ")?.[0]);
  } else {
    serviceTime = Number(serviceTime?.split(" ")?.[0]) * 60;
  }
  let startDate: any = null;
  if (param?.get("formId")) {
    startDate = dateTime?.split(",")?.[1]?.split("-")?.[0];
  }

  useEffect(() => {
    if (numberingMarker || (param.get("formId") && param.get("statusType") === "afterRouteCreated")) {
      // const date = formData?.dateTime?.split(",")[0];
      // const start = formData?.dateTime?.split(",").pop();
      // const startTime = start?.split("-")[0];
      // const endTime = formData?.dateTime?.split("-").pop();
      // const now = moment(date + " " + startTime);
      // const end = moment(date + " " + endTime);
      // const local1 = moment.utc(date + " " + startTime, 'MM-DD-YYYY HH:mm:ss').local();
      // const local2 = moment.utc(date + " " + endTime, 'MM-DD-YYYY HH:mm:ss').local();
      // const momentFormat1 = moment(local1, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
      // const momentFormat2 = moment(local2, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
      // const now = moment(momentFormat1);
      // const end = moment(momentFormat2);
      // const duration = moment.duration(now.diff(end), "minutes");
      // const durationFix = String(duration)?.split("T").pop();
      // let totalTime = Number(durationFix.slice(0, -4)) - 30;
      const start = formData?.dateTime?.split(",").pop();
      const startTime = start?.split("-")[0];
      const endTime = formData?.dateTime?.split("-").pop();
      const startTimeTemp = moment(startTime, 'hh:mm A');
      const endTimeTemp = moment(endTime, 'hh:mm A');
      const minutesDifference = endTimeTemp.diff(startTimeTemp, 'minutes');
      let totalTime = Number(minutesDifference) - 30;
      let time = 0;
      let inTimeProperty: any = [],
        outTimeProperty: any = [];
      (unlinkedPropertyClicked ? linkedPropertiesData : filteredLinkedProperties)?.map((property: any) => {
        time += serviceTime;


        if (totalTime >= time) {
          inTimeProperty.push(property);
        } else {
          outTimeProperty.push(property);
        }
        totalTime -= Math.floor(Math.random() * 9) + 12;
      });
      console.log("outTimePropertyoutTimePropertyoutTimeProperty", outTimeProperty?.length);

      // if (outTimeProperty?.length > 0) setIsOutTimeProperty(true);
      if (outTimeProperty?.length > 0 && outTimeProperty?.length < (unlinkedPropertyClicked ? linkedPropertiesData : filteredLinkedProperties)?.length) {
        // setIsOutTimeProperty(true);
        setEliminatePropertyFromTheTour(outTimeProperty?.length);
      } else {
        setIsOutTimeProperty(false);
        setEliminatePropertyFromTheTour(0);
      }
      setItems(inTimeProperty);
      setTimeOutProperty(outTimeProperty);
    } else {
      setItems(
        unlinkedPropertyClicked ? linkedPropertiesData : param.get("formId") !== undefined || param.get("statusType") === "beforeRouteCreated" ? linkedPropertiesData : filteredLinkedProperties
      );
    }
  }, [filteredLinkedProperties, linkedPropertiesData]);

  useEffect(() => {
    let role: any = param.get("role") ?? "";
    let routeStatus: any = param.get("routeStatus") ?? "";
    if ((userState?.userType === UserType.AGENT && role === "Buyer") || routeStatus === "Scheduled") {
      setIsNotEditable(true);
    }
    if ((userState?.userType === UserType.BUYER && role === "Agent") || routeStatus === "Scheduled") {
      setIsNotEditable(true);
    }
  }, []);

  const updateItems = (newItems: any) => {
    setItems(newItems);
    setLinkedProperties(newItems);
    setLinkedPropertiesData(newItems);
  };
  const grid = 8;

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    // setShowMap(false);
    const itemsNew = reorder(items, result.source.index, result.destination.index);

    let tempArr: any = [];

    itemsNew.forEach((element: any, index: number) => {
      let endtimestamp = "";
      let starttimestamp = "";

      let data = "";

      data = startDate ? startDate : startTimeForTimeStamp?.split(":")?.join("");

      if (index === 0) {
        endtimestamp = moment(data, "hh:mm A")
          ?.add(30, "minutes")
          ?.add(serviceTime, "minutes")
          // ?.add(travelTime?.[index + 1], 'minutes')
          ?.format("hh:mm A");
        temptimestamp = String(
          moment(endtimestamp, "hh:mm:a")
            .add(Math.floor(Math.random() * 9) + 12, "minutes")
            .format("hh:mm A")
        );
        starttimestamp = String(moment(data, "hh:mm A").add(30, "minutes").format("hh:mm A"));
      }
      if (index > 0) {
        starttimestamp = temptimestamp;
        const endproTime = temptimestamp;
        endtimestamp = moment(endproTime, "hh:mm A")
          ?.add(serviceTime, "minutes")
          // ?.add(
          //   travelTime?.[index + 1] - travelTime?.[index],
          //   'minutes',
          // )
          ?.format("hh:mm A");
        temptimestamp = String(
          moment(endtimestamp, "hh:mm:A")
            .add(Math.floor(Math.random() * 9) + 12, "minutes")
            .format("hh:mm A")
        );
      }

      element.startTime = starttimestamp;
      element.endTime = endtimestamp;
      element.isOutOfTheBox = "NO"

      tempArr.push(element);
    });

    const arr: any = timeOutProperty?.length > 0 ? [...tempArr, ...timeOutProperty] : itemsNew;
    // this itemsNew it change every time when we reorder so we can store it in state;
    if (param.get("routeStatus") === "Scheduled") {
      setisScheduledRouteUpdated(true);
    }
    setItems(itemsNew);
    setManualChangeProperties(arr);
    setFilteredLinkedProperties(arr);
    setLinkedPropertiesData(arr);
    setUseManual(true);
    // setShowMap(true);
  };
  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 0,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? "lightgreen" : "",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getListStyle = (isDraggingOver: any) => ({
    background: isDraggingOver ? "lightblue" : "",
    padding: 0,
    width: "100%"
  });

  let temptimestamp = "";
  const renderTourPropertyDetails = () => {
    return (
      <div
        style={{
          height: "80vh",
          overflowY: "auto",
          overflowX: "hidden",
          scrollbarWidth: "thin"
        }}
      >
        <CustomRow>
          <CustomCol span={24}>
            <CustomColInnderDiv>
              <SrcDesPara>{formData?.startAdd === formData?.endingAdd && formData?.startAddCity === formData?.endingAddCity ? "B" : "A"}</SrcDesPara>
            </CustomColInnderDiv>
            <TourHomeOfficeCard formData={formData} tab={formData?.activeTab ? formData?.activeTab : formData?.firstActiveTab} source="origin" statusType={statusType} />
          </CustomCol>
        </CustomRow>

        <DragDropContext
          onDragStart={() => {
            setUseManual(false);
          }}
          onDragEnd={onDragEnd}
        >
          <Droppable droppableId="droppable" isDropDisabled={isNotEditable}>
            {(provided: any, snapshot: any) => (
              <ul {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                <>
                  {items?.map((ind: any, index: any) => {
                    let propData: any = null;
                    if (ind && Object.keys(ind)?.length > 0) {
                      propData = ind;
                    }
                    return (
                      <Draggable
                        key={propData ? propData?.mlsId : ind?.mlsId}
                        draggableId={propData ? propData?.mlsId?.toString() : ind?.mlsId?.toString()}
                        index={index}
                        isDragDisabled={isNotEditable}
                      >
                        {(provided: any, snapshot: any) => {
                          let divProps: any = {};
                          if (dragDropWorking) {
                            divProps = {
                              ...provided.draggableProps,
                              ...provided.dragHandleProps
                            };
                          }
                          return (
                            <CustomRow gutter={[20, 20]} ref={provided.innerRef} {...divProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                              <CustomCol span={24}>
                                <CustomColInnderDiv>
                                  {numberingMarker || (param.get("formId") && param.get("statusType") === "afterRouteCreated") ? (
                                    <SrcDesPara>{useManual ? index + 1 : index + 1}</SrcDesPara>
                                  ) : (
                                    <SrcDesPara>{index + 1}</SrcDesPara>
                                  )}
                                </CustomColInnderDiv>
                                <ToursCard
                                  item={propData ? propData : ind}
                                  property={filteredLinkedProperties?.length > 0 ? filteredLinkedProperties : linkedPropertiesData}
                                  setLinkedPropertiesDataMain={setLinkedPropertiesData}
                                  linkedPropertiesDataMain={linkedPropertiesData}
                                  setFilteredLinkedPropertiesMain={setFilteredLinkedProperties}
                                  filteredLinkedPropertiesMain={filteredLinkedProperties}
                                  index={index}
                                  buyerGlobalId={buyerGlobalId}
                                  linkedProperties={items}
                                  linkedPropertiesData={items}
                                  setLinkedProperties={updateItems}
                                  setLinkedPropertiesData={updateItems}
                                  setShowMap={setShowMap}
                                  setUnlinkPropHeader={setUnlinkPropHeader}
                                  setTourPropPage={setTourPropPage}
                                  timeStamp={travelTime?.[index + 1] ?? "00:00 - 00:00"}
                                  // startTimeStamp={starttimestamp}
                                  startTimeStamp={ind?.startTime}
                                  // endTimeStamp={dateTime ? endtimestamp : null}
                                  endTimeStamp={ind?.endTime}
                                  numberingMarker={numberingMarker}
                                  setselected={setselected}
                                  setRunMap={setRunMap}
                                  isFavoriteProp={ind?.isFavorite}
                                  isRequestedProp={ind?.isRequested}
                                  isListed={ind?.isListed}
                                  isAccepted={ind?.isAccepted}
                                  isRejected={ind?.isRejected}
                                  SetShowTimeBar={SetShowTimeBar}
                                  showTimeBar={showTimeBar}
                                  setIsUnlinkedPropertyClicked={setIsUnlinkedPropertyClicked}
                                  setIsAllAccepted={setIsAllAccepted}
                                  setScheduleStatus={setScheduleStatus}
                                  setBestRoute={setBestRoute}
                                  setHandleTogglingClicked={setHandleTogglingClicked}
                                />
                              </CustomCol>
                            </CustomRow>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                </>

                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        <CustomRow>
          <CustomCol span={24}>
            <CustomColInnderDiv>
              <SrcDesPara>B</SrcDesPara>
            </CustomColInnderDiv>
            <TourHomeOfficeCard formData={formData} tab={formData?.activeTabOherButton ? formData?.activeTabOherButton : formData?.secondActiveTab} source="destination" statusType={statusType} />
          </CustomCol>
        </CustomRow>
        {timeOutProperty?.map((property: any, index: any) => {
          return (
            <CustomCol span={24}>
              <CustomColInnderDiv>
                <SrcDesPara>{items?.length + index + 1}</SrcDesPara>
              </CustomColInnderDiv>
              <ToursCard
                item={property}
                property={filteredLinkedProperties?.length > 0 ? filteredLinkedProperties : linkedPropertiesData}
                index={items?.length + index}
                buyerGlobalId={buyerGlobalId}
                linkedProperties={items}
                linkedPropertiesData={items}
                setLinkedProperties={updateItems}
                setLinkedPropertiesData={updateItems}
                setShowMap={setShowMap}
                setUnlinkPropHeader={setUnlinkPropHeader}
                setTourPropPage={setTourPropPage}
                timeStamp={travelTime?.[index + 1] ?? "00:00 - 00:00"}
                startTimeStamp={property?.startTime}
                endTimeStamp={property?.endTime}
                numberingMarker={numberingMarker}
                setselected={setselected}
                setRunMap={setRunMap}
                isFavoriteProp={property?.isFavorite}
                isRequestedProp={property?.isRequested}
                isListed={property?.isListed}
                isAccepted={property?.isAccepted}
                isRejected={property?.isRejected}
                SetShowTimeBar={SetShowTimeBar}
                showTimeBar={showTimeBar}
                setIsUnlinkedPropertyClicked={setIsUnlinkedPropertyClicked}
                setLinkedPropertiesDataMain={setLinkedPropertiesData}
                linkedPropertiesDataMain={linkedPropertiesData}
                setFilteredLinkedPropertiesMain={setFilteredLinkedProperties}
                filteredLinkedPropertiesMain={filteredLinkedProperties}
                setIsAllAccepted={setIsAllAccepted}
                setScheduleStatus={setScheduleStatus}
                setBestRoute={setBestRoute}
                setHandleTogglingClicked={setHandleTogglingClicked}
              />
            </CustomCol>
          );
        })}
      </div>
    );
  };
  return <div className="tour_property_details_card">{renderTourPropertyDetails()}</div>;
};
export default TourPropertyDetails;
