/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useContext, useRef } from "react";

import buttonClose from "../../../assets/images/button-close.svg";
import { useMediaQuery } from "react-responsive";
import styled from "@emotion/styled";
import Button, { ButtonType } from "../../Button";
import { DatePicker, Spin } from "antd";
import moment from "moment";
import "./styles/calendarPopover.css";
import { Label } from "reactstrap";
import { RangePickerProps } from "antd/lib/date-picker";
import { rescheduleModel } from "../AgentCalendarMain";
import { updateRequestedProperty } from "../../../services/NavihomeService";
import { notification } from "../../notification";
const CustomSpan = styled.span`
  text-decoration: underline;
  color: #eb752f;
`;

const CustomLabel = styled(Label)``;

const CustomDatePicker = styled(DatePicker)``;

interface QuickBuyerRegistrationModalPropsModel {
  isShowing: rescheduleModel;
  closeAction: () => void;
  loadBuyers: () => void;
}
const { RangePicker } = DatePicker;

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};
const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  // Can not select days before today and today
  return current && current < moment().endOf("day");
};

// const disabledDateTime = () => ({
//   disabledHours: () => range(0, 24).splice(4, 20),
//   disabledMinutes: () => range(30, 60),
//   disabledSeconds: () => [55, 56],
// });

const RescheduleModel: React.FC<QuickBuyerRegistrationModalPropsModel> = ({
  isShowing,
  closeAction,
  loadBuyers,
}): JSX.Element => {
  const [datetime, setDataTime] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const submitForm = async () => {
    setLoading(true);
    updateRequestedProperty({ dataTime: datetime }, isShowing.buyerId, isShowing.token, isShowing.propertyId)
      .then((res) => {
        setLoading(false);
        notification("Reschedule successfully", "success");
        loadBuyers();
        closeAction();
      })
      .catch((e) => {
        setLoading(false);
        notification("Something went worng.", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const modalClassName = isShowing.open ? "quick-registration-modals u-visible" : "quick-registration-modals u-hidden";
  const cardClassName = isShowing.open
    ? "quick-registration-modal_card animation__pop-in"
    : "quick-registration-modal_card animation__pop-out";

  return (
    // <Spin spinning={loading}>
    <div className={modalClassName}>
      <div className={cardClassName}>
        <div className="quick-registration-modal__row">
          <h2>RESCHEDULE REQUEST</h2>
          <img
            className="quick-registration-modal__close-button"
            src={buttonClose}
            onClick={closeAction}
            alt="Close button"
          />
        </div>
        <div className="quick-registration-modal_column">
          {/* <p className="quick-registration-modal__error-message">{userState.loginError}</p> */}
          <CustomLabel>Select the data and time</CustomLabel>
          {/* <DatePicker picker="week" /> */}
          <CustomDatePicker
            format="YYYY-MM-DD HH:mm:ss"
            showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
            disabledDate={disabledDate}
            onChange={(e) => {
              setDataTime(moment(e).format("YYYY-MM-DD HH:mm:ss"));
            }}
            // disabledTime={disabledDateTime}
          />
          <Button
            type={ButtonType.PRIMARY_NARROW}
            text="RESCHEDULE"
            clickHandler={submitForm}
            width={24.5}
            className="button-reschedule"
          />
        </div>
      </div>
    </div>
  );
};

export default RescheduleModel;
