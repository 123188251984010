import React, { useReducer } from 'react';

enum ThemeActionType {
  CHANGE_THEME = 'CHANGE_THEME'
}

export enum ThemeType {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM'
}

type ThemeAction =
  | { type: ThemeActionType.CHANGE_THEME, payload: { themeType: ThemeType } }

interface ThemeState {
  themeType: ThemeType,
  primaryColor: string,
  secondaryColor: string,
  tertiaryColor: string
}

type ThemeContextType = {
  themeState: ThemeState;
  changeTheme: (themeType: ThemeType) => void;
}

const initialState: ThemeState = {
  themeType: ThemeType.DEFAULT,
  primaryColor: "GREEN",
  secondaryColor: "",
  tertiaryColor: ""
}

const ThemeContext = React.createContext<ThemeContextType | undefined>(undefined);

const reducer = (state: ThemeState, action: ThemeAction): ThemeState => {
  switch (action.type) {
    case ThemeActionType.CHANGE_THEME:
      let primaryColor = "GREEN";
      if (action.payload.themeType === ThemeType.CUSTOM) {
        primaryColor = "BLUE"
      }
      return { ...state, themeType: action.payload.themeType, primaryColor }
    default:
      return state;
  }
}

export const ThemeProvider: React.FC = ({ children }) => {
  const [themeState, dispatch] = useReducer(reducer, initialState);

  const changeTheme = (themeType: ThemeType) => {
    dispatch({ type: ThemeActionType.CHANGE_THEME, payload: { themeType } })
  }

  return (
    <ThemeContext.Provider value={{ themeState, changeTheme }}>
      { children}
    </ThemeContext.Provider >
  );
};

export default ThemeContext;