/* eslint-disable jsx-a11y/alt-text */
import styled from '@emotion/styled';
import { Col, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import Button, { ButtonType } from '../../components/Button';
import TextField, {
  TextFieldIconType,
  TextFieldType,
} from '../../components/TextField';
import map from './images/map.png';

const CustomHeading = styled.h3`
  text-decoration: none;
  margin-bottom: 12px;
  ::after {
    display: none;
  }
`;
const CustomPara = styled.p`
  padding-bottom: 15px;
  line-height: 180%;
`;
const CustomHead = styled.p`
  padding-bottom: 4px;
  line-height: 180%;
`;
const CustomDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  padding-top: 30px;

  @media (max-width: 576px) {
    flex-direction: column;
    / justify-content: center; /
    width: 100% !important;
    .route_tour-modal__button {
      width: 100% !important;
    }
  }
`;
const CustomHeading1 = styled.h1`
  font-size: 24px;
  @media screen and (max-width: 420px) {
    font-size: 21px;
  }
`;

interface ExceedingTimeModalProps {
  isShowing: boolean;
  closeAction: () => void;
  formData: any;
  eliminatePropertyFromTheTour: number;
}

const ExceedingTimeModal: React.FC<ExceedingTimeModalProps> = ({
  isShowing,
  closeAction,
  formData,
  eliminatePropertyFromTheTour,
}) => {
  const [visible, setVisible] = useState(isShowing);
  const [dateTime, setDateTime] = useState<any>(formData?.dateTime || '');
  const isMobile = useMediaQuery({ maxWidth: 500 });
  const isCard = useMediaQuery({ maxWidth: 991 });

  const handleCancel = () => {
    setVisible(false);
    closeAction();
  };

  return (
    <Modal
      centered
      visible={visible}
      onCancel={handleCancel}
      width={isCard ? undefined : 1000}
      bodyStyle={{ height: isCard ? undefined : '600px' }}
      footer={false}
    >
      <Row>
        <CustomHeading1>Uh oh, we're running short of time</CustomHeading1>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <img src={map} style={{ float: 'left', width: '100%' }} />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          style={{ flexDirection: 'column', marginTop: isCard ? '' : '5rem' }}
        >
          <CustomPara>
            Since we're running a little short of time, we had to eliminate {eliminatePropertyFromTheTour} {''}
            {eliminatePropertyFromTheTour > 1 ? 'properties': 'property'} from the tour. This Property has been highlighted on the
            map. You may be able to include it in your tour by removing a
            property or a break from the tour.
          </CustomPara>
          <br />
          <br />
          <CustomPara>
            Alternatively, you may wish to extend the tour timings.
          </CustomPara>

          {/* <CustomHeading>Date and time*</CustomHeading> */}

          <TextField
            value={dateTime}
            setValue={() => {}}
            placeholder={'Enter date and time'}
            label="Date and Time"
            type={TextFieldType.ICON_AND_LABEL}
            icon={TextFieldIconType.CALENDAR}
            isRequired={true}
            className="tour_detail_form__date_time w-100"
            isDisabled={true}
          />

          <CustomDiv>
            <Button
              type={ButtonType.PRIMARY}
              text="DONE"
              clickHandler={handleCancel}
              className="route_tour-modal__button"
              width={isMobile ? '100%' : 12.5}
            />
          </CustomDiv>
        </Col>
      </Row>
    </Modal>
  );
};

export default ExceedingTimeModal;
