import { Button, Col, Input, Modal, Row, Select } from 'antd'
import * as React from 'react'
const { Option } = Select

interface ICustomRecurrenceModalProps {
  recurrenceModalVisible: boolean
  setRecurrenceModalVisible: Function
  recurrenceModalData: {
    title: string
  }
}

const CustomRecurrenceModal: React.FunctionComponent<ICustomRecurrenceModalProps> = ({
  recurrenceModalVisible,
  setRecurrenceModalVisible,
  recurrenceModalData,
}) => {
  return (
    <Modal
      centered
      visible={recurrenceModalVisible}
      onOk={() => {
        setRecurrenceModalVisible(!recurrenceModalVisible)
      }}
      onCancel={() => {
        setRecurrenceModalVisible(!recurrenceModalVisible)
      }}
      destroyOnClose={true}
      footer={null}
    >
      <div className="modalTitle">Calendar recurrence</div>
      <div className="modalContainer">
        <div>Start*</div>
        <div className="">
          <Input type="date" className="inputBorder"></Input>
        </div>
      </div>
      <div className="modalContainer">
        <div>Repeat Every</div>
        <div className="">
          <Row>
            <Col span={8}>
              <div>
                <div className="inputMargin">
                  <Input
                    type="text"
                    placeholder="1"
                    className="inputBorder "
                  ></Input>
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div>
                <div className="inputMargin">
                  <Select defaultValue="Day" className="width100 inputBorder">
                    <Option value="Day">Day</Option>
                    <Option value="Week">Week</Option>
                    <Option value="Month">Month</Option>
                  </Select>{' '}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="modalContainer">
        <div>End*</div>
        <div className="">
          <Input type="date" className="inputBorder"></Input>
        </div>
      </div>
      <div className="modalFooter">Occurs everyday starting May 17, 2022</div>
      <div className="buttonCont">
        <Button className="modalButton">Save</Button>
      </div>
    </Modal>
  )
}

export default CustomRecurrenceModal
