import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import SchoolInformationSummary from '../../components/SchoolInformationSummary'
import GoogleMapReact from '../../components/GoogleMap/PropertyMap'
import iconConnected from '../../assets/images/icon-connected.svg'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import {
  getAgentInfoByMlsId,
  getPropertyDetailsService,
  getSchoolInfoOfProperty,
  checkIfPropertyIsRequestedForBuyerService,
  checkIfPropertyIsFavoriteForBuyerService,
  checkIsListedPropertyService,
  filterOpenHousesProperties,
} from '../../services/NavihomeService'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import requestShowingOn from '../../assets/images/button-request-showing-on.svg'
import requestShowingOff from '../../assets/images/button-request-showing-off.svg'
import favoriteOn from '../../assets/images/button-favorite-on.svg'
import favoriteOff from '../../assets/images/button-favorite-off.svg'
import shareImg from '../../assets/images/button-share.svg'
import Button, { ButtonType } from '../../components/Button'
import iconPhone from '../../assets/images/icon-phone.svg'
import iconEmail from '../../assets/images/icon-email.svg'
import ListedAgentModal from '../../modals/GoogleMapModals/ReginaGeorge'
import NotListAgentModal from '../../modals/GoogleMapModals/Kevinheardsib';

import { notification } from '../../components/notification'
import {
  Row,
  Col,
  Grid,
  Avatar,
  Divider,
  Table,
  Select,
  Affix,
} from 'antd'
import { UserOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { useHistory } from 'react-router'
import UserContext, { UserType } from '../../contexts/UserContext'
import FindAnAgentModal from '../../modals/findAnAgentModal'
import PropertyShareModal from '../../modals/PropertyShareModal'
import CustomSpinner from '../spinner/CustomSpinner'
import { useMediaQuery } from 'react-responsive'
import moment from 'moment'
import FindAnAgentConfirmModal from '../../modals/FindAnAgentConfirmModal';
import GreatSchoolLogo from "../../assets/images/greatschool.png";

const { useBreakpoint } = Grid
const { Option } = Select

const CustomDiv = styled.div`
  font-size: 14px;
  margin: 10px 0px;
`
const CustomViewCalenderPara = styled.p`
  color: #eb752f;
  border-bottom: 1px solid #eb752f;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
`
const CustomDivider = styled(Divider)`
  background: #004876;
  margin: 18px 0px;
  border-bottom: 1px solid #004876;
`
const CustomRow = styled(Row)`
  flex-flow: nowrap;
`

const CustomGalleryDiv = styled.div`
  .image-gallery-index {
    font-size: 18px !important;
  }
`
const CustomPriceHeading = styled.h4`
  margin-right: 10px;
`
const CustomPara = styled.p`
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  width: 100%;
  word-break: keep-all;
`

const CustomHeading = styled.h1`
  padding-bottom: 15px;
  @media screen and (min-width: 1200px) {
    font-size: 1.75rem;
  }
  @media screen and (max-width: 1200px) {
    font-size: calc(1.3rem + 0.6vw);
  }
`
const CustomOpenHouseDiv = styled.div`
  background: #eb752f;
  border-radius: 14px;
  padding: 4px 8px;
  position: absolute;
  z-index: 3;
  top: 3rem !important;
  left: 26px !important;
  p {
    color: white !important;
  }
  @media screen and (max-width: 576px) {
    margin-top: 12px;
    top: 6px;
    left: 6px;
  }
`

const CustomOpenHouseParaDiv = styled.div`
  background: #eb752f;
  border-radius: 14px;
  padding: 4px 8px;
  position: absolute;
  top: 3rem;
  @media (max-width: 576px) {
    top: 4.2rem;
  }
  @media (max-width: 414px) {
    top: 8rem;
  }
  left: 144px;
  @media (max-width: 414px) {
    left: 2.5rem;
  }
  display: flex;
  flex-direction: row;
  @media (max-width: 414px) {
    flex-direction: column;
  }
`

const CustomParaOpenHouse = styled.p`
  font-weight: 600;
  font-size: 2rem;
`

interface PropertyDetailsPagePropsModel {
  mlsId?: number
  propertyType?: string
  startTime?: string
  endTime?: string
  isPropListed?: boolean
  propListingId?: string
  isPropReq?: boolean
  isPropFav?: boolean
  linkedPropertiesData?: Array<any>;
  setSaveTourClicked?: (val: string) => void;
}

const PropertyDetailsPage: React.FC<PropertyDetailsPagePropsModel> = ({
  mlsId,
  startTime,
  endTime,
  propListingId,
  setSaveTourClicked,
  linkedPropertiesData
}) => {
  const [isShowingSalesTab, setIsShowingSalesTab] = useState(true)
  const [imageSrc, setImageSrc] = useState('')
  const [galleryImage, setGalleryImage] = useState([])
  const [schoolInfo, setSchoolInfo] = useState([])
  const [showAllSchool, setShowAllSchool] = useState(false)
  const [checkIsRequested, setCheckIsRequested] = useState(false)
  const [checkIsFavorite, setCheckIsFavorite] = useState(false)
  const [isBuyerLinked, setIsBuyerLinked] = useState(false)
  const [remarks, setRemarks] = useState('')
  const [listPrice, setListPrice] = useState('')
  const [sqrtPrice, setSqrtPrice] = useState(' ')
  const [sharePropModal, setSharePropModal] = useState(false);
  const [isShowingConfirmModal, setIsShowingConfirmModal] = useState<boolean>(false);
  const responsiveMap = useMediaQuery({ minWidth: 1200 })
  const [geoLoc, setGeoLoc] = useState({
    lat: 0,
    lng: 0,
    direction: '',
  })
  const [address, setAddress] = useState({
    fullAddress: ' ',
    city: ' ',
    country: ' ',
    streetNumber: ' ',
    streetName: ' ',
    state: ' ',
    postalCode: ' ',
  })
  const [area, setArea] = useState('')
  const [area1, setArea1] = useState('')
  const [yearBuilt, setYearBuilt] = useState('')
  const [propDetail, setPropDetail] = useState({
    imageURL: '',
    bedrooms: 0,
    bathrooms: 0,
    bathsFull: 0,
    bathsHalf: 0,
    bathsThreeQuarter: ' ',
    heating: ' ',
    cooling: ' ',
    fireplaces: ' ',
    flooring: ' ',
    interior: ' ',
    laundryfeat: ' ',
    subTypeText: ' ',
    type: ' ',
    parking: {
      description: ' ',
      spaces: ' ',
    },
    mls: {
      status: ' ',
    },
    monthly_payment: ' ',
    brokerage: ' ',
    virtual_tour: 0,
    foundation: ' ',
    roof: ' ',
    directions: ' ',
    agent: {
      firstName: '',
      lastName: '',
    },
    listingId: null,
    mlsId: null,
    lotSizeArea: '',
    lotSizeAreaUnits: '',
    listPrice: 0,
    area: 0,
  })
  const [taxDet, setTaxDet] = useState({
    id: ' ',
    taxAnnualAmount: 0,
    taxYear: ' ',
    land: ' ',
    additions: ' ',
    assessedValue: ' ',
  })

  // state for goback button to show same state
  const screens = useBreakpoint()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const history = useHistory()
  const { userState } = useContext(UserContext)
  const [isFindAnAgentModal, setIsFindAnAgentModal] = useState(false)

  const [isListed, setIsListed] = useState<boolean>(false)
  const [item, setItem] = useState<object>({})
  const [notListedAgentName, setNotListedAgentName] = useState<string>('')
  const [notListedOffName, setNotListedOffName] = useState<string>('')
  const [avatarUrl, setAvatarUrl] = useState<string>('')
  const [listingAgentName, setListingAgentName] = useState<string>('')
  const [listingAgentComapny, setListingAgentCompany] = useState<string>('')
  const [listAgentSellerName, setListAgentSellerName] = useState('')
  const [listAgentId, setListAgentId] = useState('')
  const [listedAgentModal, setListedAgentModal] = useState<boolean>(false)
  const [notListedAgentModal, setNotListedAgentModal] = useState<boolean>(false)
  const [agentMlsId, setAgentMlsId] = useState<string>('')
  const [agentListedEmail, setAgentListedEmail] = useState<string>('')
  const [agentListedPhone, setAgentListedPhone] = useState<string>('')
  const [agentNotListedPhone, setAgentNotListedPhone] = useState<string>('')

  const [isCommon, setIsCommon] = useState<boolean>(false)
  const [isSell, setIsSell] = useState<boolean>(false)
  const [isPropDetail, setIsPropDetail] = useState<boolean>(false)

  const [modeOfContact, setModeOfContact] = useState<string>('')
  const [openHousePropDetail, setOpenHousePropDetail] = useState<any>([])
  const [isOpenHouse, setIsOpenHouse] = useState<boolean>(false)


  const schoolInfoData = async (lat: any, lng: any) => {
    try {
      const data = await getSchoolInfoOfProperty(lat, lng)
      setSchoolInfo(data.schools)
    } catch (err) {
      console.error('Error ', err)
      return
    }
  }

  const checkListedAgent = async () => {
    let token = ''
    if (userState?.userType === UserType.AGENT) {
      token = userState?.agent?.token
    } else {
      token = userState?.buyer?.token
    }
    try {
      const responseData: any = await getAgentInfoByMlsId(token, agentMlsId)
      if (responseData?.email) {
        setAgentListedEmail(responseData?.email)
        setAgentListedPhone(responseData?.phone)
        setModeOfContact(responseData?.preferredContact)
      } else {
      }
    } catch (error) {
      console.error(error)
      return
    }
  }

  const checkIfListed = async () => {
    try {
      const response: any = await checkIsListedPropertyService(mlsId)
      setIsListed(response?.success)
      if (response?.success) {
        setAvatarUrl(response?.data?.agent?.avatarUrl)
        setListingAgentName(response?.data?.agent?.name)
        setListingAgentCompany(response?.data?.agent?.company)
        setAgentListedEmail(response?.data?.agent?.email)
        setAgentListedPhone(response?.data?.agent?.phone)
        setModeOfContact(response?.data?.agent?.preferredContact)
        setListAgentId(response?.data?.agent?.agentId)
        setListAgentSellerName(response?.data?.name)
      }
    } catch (error) {
      console.log('ERROR: ', error)
    }
  }
  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let param = useQuery();
  const getOpenHouseTime = async () => {
    const result: any = await filterOpenHousesProperties(Number(propListingId))
    if (!result?.success) {
      setIsOpenHouse(true)
    }
    setOpenHousePropDetail(result)
  }


  useEffect(() => {
    if (geoLoc.lat && geoLoc.lng) {
      schoolInfoData(geoLoc.lat, geoLoc.lng)
    }
  }, [geoLoc])

  useEffect(() => {
    const currentTab = history?.location?.pathname?.split('/')?.[1]
    if (currentTab?.toLocaleLowerCase() === 'common') {
      setIsCommon(true)
    } else if (currentTab?.toLocaleLowerCase() === 'sell') {
      setIsSell(true)
    } else if (currentTab?.toLocaleLowerCase() === 'propertydetails') {
      setIsPropDetail(true)
    }
  }, [])

  useEffect(() => {
    if (agentMlsId && userState?.isLoggedIn) {
      checkListedAgent()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentMlsId])

  useEffect(() => {
    checkIfListed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mlsId])

  useEffect(() => {
    getOpenHouseTime()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPropertyDetails = async () => {
    setIsLoading(true)
    try {
      const propertyDetails: any = await getPropertyDetailsService(mlsId);
      setItem(propertyDetails);
      setNotListedAgentName(`${propertyDetails?.agent?.firstName}  ${propertyDetails?.agent?.lastName}`);
      setNotListedOffName(propertyDetails?.office?.name)
      const images: any = propertyDetails?.photos?.map((item: any) => {
        return {
          original: item,
          thumbnail: item,
          thumbnailHeight: 100,
          thumbnailWidth: 200,
        }
      })
      setGalleryImage(images?.length > 0 ? images : [])
      setImageSrc(propertyDetails?.photos?.[0] ?? '')
      setRemarks(propertyDetails?.remarks ?? '')
      setListPrice(`${propertyDetails?.listPrice ?? ''}`)
      setSqrtPrice(propertyDetails?.listPrice)
      setPropDetail({
        imageURL: '',
        bedrooms: propertyDetails?.property?.bedrooms || 0,
        bathrooms: propertyDetails?.property?.bathrooms || 0,
        bathsFull: propertyDetails?.property?.bathsFull || 0,
        bathsHalf: propertyDetails?.property?.bathsHalf || 0,
        bathsThreeQuarter: propertyDetails?.property?.bathsThreeQuarter || 0,
        heating: propertyDetails?.property?.heating || 'N/A',
        cooling: propertyDetails?.property?.cooling || 'N/A',
        fireplaces: propertyDetails?.property?.fireplaces || 0,
        flooring: propertyDetails?.property?.flooring || 'N/A',
        interior: propertyDetails?.property?.interiorFeatures || 'N/A',
        laundryfeat: propertyDetails?.property?.laundryFeatures || 'N/A',
        subTypeText: propertyDetails?.property?.subTypeText,
        type: propertyDetails?.property?.type,
        parking: propertyDetails?.property?.parking,
        mls: propertyDetails?.mls || 'N/A',
        monthly_payment: 'N/A',
        brokerage: 'N/A',
        virtual_tour: propertyDetails?.virtualTourUrl || 'N/A',
        foundation: propertyDetails?.property?.foundation || 'N/A',
        roof: propertyDetails?.property?.roof || 'N/A',
        directions: propertyDetails?.geo?.directions || 'N/A',
        agent: propertyDetails?.agent || 'N/A',
        listingId: propertyDetails?.listingId || 'N/A',
        mlsId: propertyDetails?.mlsId || 'N/A',
        lotSizeArea: propertyDetails?.property?.lotSizeArea || 'N/A',
        lotSizeAreaUnits: propertyDetails?.property?.lotSizeAreaUnits || 'N/A',
        listPrice: 10,
        area: 10,
      })
      setTaxDet({
        id: propertyDetails?.tax?.id || 'N/A',
        taxAnnualAmount: Number(propertyDetails?.tax?.taxAnnualAmount) || 0,
        taxYear: propertyDetails?.tax?.taxYear || 'N/A',
        land: propertyDetails?.tax?.land || 'N/A',
        additions: propertyDetails?.tax?.additions || 'N/A',
        assessedValue: propertyDetails?.tax?.assessedValue || 'N/A',
      })
      setGeoLoc({
        lat: propertyDetails?.geo?.lat ?? null,
        lng: propertyDetails?.geo?.lng ?? null,
        direction:
          propertyDetails?.geo?.directions + ' ' + propertyDetails?.geo?.county,
      })
      setAddress({
        fullAddress: propertyDetails?.address?.full ?? 'N/A',
        city: propertyDetails?.address?.city ?? 'N/A',
        country: propertyDetails?.address?.country ?? 'N/A',
        streetNumber: propertyDetails?.address?.streetNumberText ?? 'N/A',
        streetName: propertyDetails?.address?.streetName ?? 'N/A',
        state: propertyDetails?.address?.state ?? 'N/A',
        postalCode: propertyDetails?.address?.postalCode ?? 'N/A',
      })
      setYearBuilt(propertyDetails?.property.yearBuilt ?? 0) //TODO change
      setArea(propertyDetails?.property.area ?? 0) //TODO change
      setArea1(propertyDetails?.property?.lotSizeArea || 'N/A')
      setAgentMlsId(propertyDetails?.agent?.id)
      setAgentNotListedPhone(propertyDetails?.agent?.contact?.office)
      setIsLoading(false)
    } catch (error) {
      console.error('Error: ', error)
      setIsLoading(false)
    }
  }

  // const getTotalViewsCount = async () => {
  //   try {
  //     const response = await totalViewsCountServices(mlsId);
  //     return response;
  //   } catch (error) {
  //     return notification("Something Went Wrong!", "error");
  //   }
  // };

  const checkIfRequested = async (getMlsId: any, getBuyerId: any) => {
    if (!userState.isLoggedIn) {
      return;
    }

    var mayBeRequested = false;

    switch (userState.userType) {
      case UserType.BUYER:
        mayBeRequested = await checkIfPropertyIsRequestedForBuyerService(
          parseInt(getMlsId),
          userState.buyer.buyerId,
          userState.buyer.token
        );
        break;
      case UserType.AGENT:
        mayBeRequested = await checkIfPropertyIsRequestedForBuyerService(
          parseInt(getMlsId),
          parseInt(getBuyerId),
          userState.agent.token
        );
        break;
      default:
        break;
    }


    setCheckIsRequested(mayBeRequested);
  };

  const checkIfFavorite = async (getMlsId: any, getBuyerId: any) => {
    if (!userState.isLoggedIn) {
      return;
    }

    var mayBeFavorite = false;

    switch (userState.userType) {
      case UserType.BUYER:
        mayBeFavorite = await checkIfPropertyIsFavoriteForBuyerService(
          parseInt(getMlsId),
          userState.buyer.buyerId,
          userState.buyer.token
        );
        break;
      case UserType.AGENT:
        mayBeFavorite = await checkIfPropertyIsFavoriteForBuyerService(
          parseInt(getMlsId),
          parseInt(getBuyerId),
          userState.agent.token
        );
        break;
      default:
        break;
    }

    setCheckIsFavorite(mayBeFavorite);
  };

  const checkIsBuyerLinked = () => {
    try {
      setIsBuyerLinked(userState?.buyer?.isLinked)
    } catch (error) {
      console.error('error:-', error)
    }
  }


  useEffect(() => {
    checkIsBuyerLinked()
    getPropertyDetails()



    let upComingBuyerId: any = '';
    if (userState.userType === UserType.AGENT) {
      upComingBuyerId = param?.get('buyerId');
    } else {
      upComingBuyerId = userState?.buyer?.buyerId;
    }

    checkIfFavorite(
      mlsId ?? null,
      Number(upComingBuyerId) ?? null
    );
    checkIfRequested(
      mlsId ?? null,
      Number(upComingBuyerId) ?? null
    );
    // getTotalViewsCount();
  }, [mlsId])

  // useEffect(() => {
  //   const google_map_val = document.getElementById('custom_map_div')
  //   if (google_map_val) {
  //     google_map_val.addEventListener('click', () => {
  //       setIsZoomed(!isZoomed)
  //     })
  //   }

  // }, [document.getElementById('custom_map_div')])

  const viewPropertyCalendarClickHandler = () => {
    if (linkedPropertiesData?.length > 0 && param.get("routeStatus") !== "Scheduled") {
      setIsShowingConfirmModal(true);
      return
    }
    findAnAgentModalHandler()
  }

  const findAnAgentModalHandler = () => {
    /* Redirect in Property details page */
    // history.push(`/propertydetails/${mlsId}`)
    // ?query=599&listingId=73072748&listingAgentId=429&client=Test%20Seller%2013th%20Mar52&listingAgent=Test%20Agent%2013th%20Mar52&fav=false&req=false



    let buyerId: any = '';

    if (userState.userType === UserType.BUYER)
      buyerId = userState?.buyer?.buyerId;
    else
      buyerId = param?.get('buyerId');


    history.push(
      `/agentBuyerPropertyCalendar/${mlsId}/?query=${buyerId}&listingId=${propDetail?.listingId}&listingAgentId=${listAgentId}&client=${listAgentSellerName}&listingAgent=${listingAgentName}&fav=${checkIsFavorite}&req=${checkIsRequested}`
    );

    //   if buyer is not logged in
    if (!userState.isLoggedIn) {
      notification(
        'You need to login before you can perform this action.',
        'error',
      )
      return
    }
    if (userState.isLoggedIn && userState.userType === UserType.AGENT) {
      return
    }
    // if buyer is login but not linked with any agent
    if (
      userState.isLoggedIn &&
      !isBuyerLinked &&
      userState.userType === UserType.BUYER
    ) {
      setIsFindAnAgentModal(!isFindAnAgentModal)
      return
    }
  }
  const handleSharePropModal = () => {
    setSharePropModal(!sharePropModal)
  }

  const handleAgentModal = () => {
    if (isListed) {
      setListedAgentModal(true)
    }
    else {
      setNotListedAgentModal(true)
    }
  }

  const data = propDetail?.lotSizeArea
  const handleChange = (value: any) => {
    if (value === 'Acres') {
      setArea1(data)
    }
    if (value === 'sqft') {
      if (data !== 'N/A') {
        const c = Number(data) * 43560
        const b = parseFloat(c.toString()).toFixed(0)
        setArea1(b)
      }
    }
  }

  const yesClickHandler = () => {
    setSaveTourClicked("Save Tour")
    setIsShowingConfirmModal(false);
  }
  const noClickHandler = () => {
    setIsShowingConfirmModal(false);
    findAnAgentModalHandler();
  }

  //RENDER

  const renderPropertyDetailItem = (name: string, items: string[]) => {
    return (
      <div className="property-details-page__property-details-item">
        <h5>{name}</h5>
        <ul style={{ paddingLeft: '0%' }}>
          {items.map((item) => {
            return <li>{item}</li>
          })}
        </ul>
      </div>
    )
  }

  const renderTaxTimeline = () => {
    const columns = [
      {
        title: 'Year',
        dataIndex: 'year',
        key: 'year',
        // render: (text) => <a>{text}</a>,
      },
      {
        title: 'Property Tax',
        dataIndex: 'property_tax',
        key: 'property_tax',
      },
      {
        title: 'Land',
        dataIndex: 'land',
        key: 'land',
      },
      {
        title: 'Additions',
        dataIndex: 'additions',
        key: 'additions',
      },
      {
        title: 'Assessed value',
        dataIndex: 'assessed_value',
        key: 'assessed_value',
      },
    ]

    const data = [
      {
        key: `${taxDet.id}`,
        year: `${taxDet.taxYear}`,
        property_tax: `$${taxDet.taxAnnualAmount.toLocaleString()}`,
        land: `${taxDet.land}`,
        additions: `${taxDet.additions}`,
        assessed_value: `${taxDet.assessedValue}`,
      },
    ]
    return (
      <div className="property-details-page__sales-and-tax-history__timeline">
        <Table columns={columns} dataSource={data} pagination={false} />
      </div>
    )
  }

  const renderSchools = () => {
    return (
      <div className="property-details-page__school-information__school-info">
        <div className="property-details-page__school-information__header">
          <p className="property-details-page__school-information__rating">
            GreatSchools Rating
          </p>
          <p className="property-details-page__school-information__school">
            School
          </p>
        </div>
        {schoolInfo?.length > 0 &&
          schoolInfo
            ?.slice(0, showAllSchool ? schoolInfo?.length : 3)
            ?.map((item) => {
              return (
                <SchoolInformationSummary
                  rating={item.rating || 0}
                  schoolName={item.name}
                  schoolType={item.level}
                  schoolUrl={item[`overview-url`]}
                  type={item.type}
                  distance={item.distance.toFixed(2)}
                />
              );
            })}
      </div>
    );
  };

  const showSalesTab = () => {
    setIsShowingSalesTab(true)
  }

  const showTaxTab = () => {
    setIsShowingSalesTab(false)
  }

  const PricePerSqrt = (sqrtPrice: any, area: any) => {
    if (!sqrtPrice || !area) {
      return 'N/A'
    } else {
      let ans = 0

      ans += sqrtPrice / area
      ans = parseInt(ans.toString())
      return ans?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      })
    }
  }



  return (
    <>
      <div
        className={
          isPropDetail
            ? 'property-details-page1'
            : isSell
              ? 'property-details-page-sell'
              : isCommon
                ? ''
                : ''
        }
      >
        <div className="property-details-page-header">
          <div
            className={
              isPropDetail
                ? 'sell-property-details-page-image_section'
                : isSell
                  ? 'sell-property-details-page-image_section1'
                  : isCommon
                    ? 'sell-property-details-page-image_section3'
                    : ''
            }
          >
            {isLoading && <CustomSpinner />}
            <CustomGalleryDiv>
              <ImageGallery
                showThumbnails={true}
                slideOnThumbnailOver={true}
                startIndex={0}
                showPlayButton={true}
                showIndex={true}
                items={galleryImage}
              />
            </CustomGalleryDiv>
            {startTime !== null &&
              endTime !== null &&
              userState?.userType === UserType?.BUYER ? (
              <div style={{ background: 'red' }}>
                <CustomOpenHouseDiv>
                  <p>OPEN HOUSE</p>
                </CustomOpenHouseDiv>
                <CustomOpenHouseParaDiv>
                  <p style={{ color: 'white' }}>
                    {moment(startTime).tz('America/New_York').format('ddd')}, {''}
                    {moment(startTime)
                      .tz('America/New_York')
                      .format('hh:mm a')} -{' '}
                    {moment(endTime).tz('America/New_York').format('hh:mm a')}
                  </p>
                </CustomOpenHouseParaDiv>
              </div>
            ) : (
              <>
                {userState?.userType === UserType?.BUYER && !isOpenHouse ?
                  (
                    <div style={{ background: '' }}>
                      <CustomOpenHouseDiv>
                        <p>OPEN HOUSE</p>
                      </CustomOpenHouseDiv>

                      {/* <CustomOpenHouseParaDiv>
                        <p style={{ color: 'white' }}>
                          {moment(openHousePropDetail?.data?.[0]?.startTime).tz('America/New_York').format('ddd')}, {''}
                          {moment(openHousePropDetail?.data?.[0]?.startTime)
                            .tz('America/New_York')
                            .format('hh:mm a')} -{' '}
                          {moment(openHousePropDetail?.data?.[0]?.endTime).tz('America/New_York').format('hh:mm a')}
                        </p>
                      </CustomOpenHouseParaDiv> */}
                    </div>
                  )
                  :
                  (<>

                  </>)

                }
              </>
            )}
            <div className="property-details-page__about">
              <CustomHeading>
                About
                <span
                  style={{
                    borderBottom: '3px solid #C2D500',
                    paddingBottom: '3px',
                  }}
                >
                  {' '}
                  the Property
                </span>
              </CustomHeading>
              <p>{remarks}</p>
            </div>

            <div className="property-details-page__basic-details">
              <CustomHeading>
                B
                <span
                  style={{
                    borderBottom: '3px solid #C2D500',
                    paddingBottom: '3px',
                  }}
                >
                  asic Details
                </span>{' '}
              </CustomHeading>
              <div className="property-details-page__basic-details__container">
                <ul>
                  <li>
                    Listing Price:{' '}
                    {Number(listPrice).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 3,
                    })}
                  </li>
                  <li>Status: {propDetail.mls.status}</li>
                  <li>Property Type: {propDetail.type}</li>
                  <li>Property subType: {propDetail.subTypeText}</li>
                </ul>
                <ul>
                  <li>Bedrooms: {propDetail.bedrooms}</li>
                  <li>Community: {address.city}</li>
                </ul>
                <ul>
                  <li>Buyer Brokerage Compensation: {propDetail.brokerage}</li>
                  <li>
                    Bath: {propDetail.bathsFull} full, {propDetail.bathsHalf}{' '}
                    partial
                  </li>
                  <li>MLS #: {propDetail?.listingId ?? 'N/A'} </li>
                </ul>
              </div>
            </div>
            <div className="property-details-page__property-details">
              <CustomHeading>
                Prop
                <span
                  style={{
                    borderBottom: '3px solid #C2D500',
                    paddingBottom: '3px',
                  }}
                >
                  erty Details
                </span>{' '}
              </CustomHeading>
              <div className="property-details-page__property-details__group">
                <h4>Interior Features</h4>
                <div className="property-details-page__details-container">
                  <div className="property-details-page__details-container__column">
                    {renderPropertyDetailItem('Bedroom Details', [
                      `# of bedrooms: ${propDetail.bedrooms}`,
                    ])}
                    {renderPropertyDetailItem('Interior Features', [
                      `${propDetail.interior}`,
                    ])}
                    {renderPropertyDetailItem('Equipment', [
                      `${propDetail.laundryfeat}`,
                    ])}
                    {renderPropertyDetailItem('Fireplace Details', [
                      `# of fireplaces: ${propDetail.fireplaces}`,
                    ])}
                  </div>
                  <div className="property-details-page__details-container__column">
                    {renderPropertyDetailItem('Bathroom Details', [
                      `# of baths(full): ${propDetail.bathsFull}`,
                      `# of baths(3/4): ${propDetail.bathsThreeQuarter}`,
                      `# of baths(1/2): ${propDetail.bathsHalf}`,
                    ])}
                    {renderPropertyDetailItem('Flooring Details', [
                      `${propDetail.flooring}`,
                    ])}
                  </div>
                  <div className="property-details-page__details-container__column">
                    {renderPropertyDetailItem('Room Information', ['N/A'])}
                    {renderPropertyDetailItem('Heating', [
                      `${propDetail?.heating ?? 'N/A'}`,
                    ])}
                    {renderPropertyDetailItem('Cooling', [
                      `${propDetail?.cooling ?? 'N/A'}`,
                    ])}
                  </div>
                </div>
              </div>
              <div className="property-details-page__property-details__group">
                <h4>
                  Virtual Tour, Parking /Garage, Exterior Features, School /
                  Neighbourhood
                </h4>
                <div className="property-details-page__details-container">
                  <div className="property-details-page__details-container__column">
                    {renderPropertyDetailItem('Virtual Tour', [
                      `${propDetail?.virtual_tour ?? ''}`,
                    ])}
                    {renderPropertyDetailItem('Parking Details', [
                      `# of covered spaces: ${propDetail?.parking?.spaces} `,
                      `Description: ${propDetail?.parking?.description || 'N/A'}`,
                    ])}
                  </div>
                  <div className="property-details-page__details-container__column">
                    {renderPropertyDetailItem('Building Information', [
                      'Built on site',
                      `${propDetail.foundation}`,
                      `${propDetail.roof}`,
                    ])}
                  </div>
                  <div className="property-details-page__details-container__column">
                    {renderPropertyDetailItem('School Information', [
                      `School District: ${schoolInfo?.length > 0 ? schoolInfo?.[0]?.city : 'N/A'
                      }`,
                      //
                    ])}
                  </div>
                </div>
              </div>
              <div className="property-details-page__property-details__group">
                <h4>Utilities, Financing, Location Details</h4>
                <div className="property-details-page__details-container">
                  <div className="property-details-page__details-container__column">
                    {renderPropertyDetailItem('Utilities Information', ['N/A'])}
                  </div>
                  <div className="property-details-page__details-container__column">
                    {renderPropertyDetailItem('Financial Information', [
                      'Cash out, conventional',
                      `Annual taxes: ${taxDet.taxAnnualAmount.toLocaleString(
                        'en-US',
                        {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 3,
                        },
                      )}`,
                      `Tax year: ${taxDet.taxYear}`,
                    ])}
                  </div>
                  <div className="property-details-page__details-container__column">
                    {renderPropertyDetailItem('Location Information', [
                      `${propDetail.directions}`,
                    ])}
                  </div>
                </div>
              </div>
              <div className="property-details-page__property-details__group">
                <h4>Listing Information</h4>
                <div className="property-details-page__details-container">
                  <div className="property-details-page__details-container__column">
                    {renderPropertyDetailItem('Listing Information', ['N/A'])}
                  </div>
                  <div className="property-details-page__details-container__column">
                    {renderPropertyDetailItem('Buyer Brokerage Compensation', [
                      `${propDetail.brokerage}`,
                    ])}
                  </div>
                </div>
              </div>
            </div>
            <div className="property-details-page__sales-and-tax-history">
              <CustomHeading>
                Sales and{' '}
                <span
                  style={{
                    borderBottom: '3px solid #C2D500',
                    paddingBottom: '3px',
                  }}
                >
                  {' '}
                  Tax History
                </span>
              </CustomHeading>
              <div className="property-details-page__tabs">
                <div className="property-details-page__tabs__buttons">
                  <div
                    className={`property-details-page__tabs__button ${isShowingSalesTab &&
                      'property-details-page__tabs__button--selected'
                      }`}
                    onClick={showSalesTab}
                  >
                    <p>Sales</p>
                  </div>
                  <div
                    className={`property-details-page__tabs__button ${!isShowingSalesTab &&
                      'property-details-page__tabs__button--selected'
                      }`}
                    onClick={showTaxTab}
                  >
                    <p>Tax</p>
                  </div>
                </div>
                <div
                  className={`property-details-page__sales-tab ${isShowingSalesTab ? 'visible' : 'hidden'
                    }`}
                >
                  <div className="property-details-page__sales-summary">
                    <p>Status: Listed ({propDetail.mls.status})</p>
                    <p>N/A</p>
                  </div>
                  {/* {renderSalesTimeline()} */}
                </div>
                <div
                  className={`property-details-page__tax-tab ${isShowingSalesTab ? 'hidden' : 'visible'
                    }`}
                >
                  {renderTaxTimeline()}
                </div>
              </div>
            </div>
            <Row>
              <div className="property-details-page__financial-information">
                <CustomHeading>
                  Financial
                  <span
                    style={{
                      borderBottom: '3px solid #C2D500',
                      paddingBottom: '3px',
                    }}
                  >
                    {' '}
                    Information
                  </span>
                </CustomHeading>
                <div className="property-details-page__tabs">
                  <div className="property-details-page__tabs__buttons">
                    <div className="property-details-page__tabs__button property-details-page__tabs__button--selected">
                      <p>Payment Calculator</p>
                    </div>
                  </div>
                  <div className="property-details-page__payment-calculator-tab visible">
                    <div className="property-details-page__payment-calculator__container">
                      <div className="property-details-age__payment-calculator__property-price property-details-age__payment-calculator__item">
                        <p>Property Price</p>
                        <p>
                          {Number(listPrice).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 3,
                          })}
                        </p>
                      </div>
                      <div className="property-details-age__payment-calculator__price-per-sqft property-details-age__payment-calculator__item">
                        <p>Price per sqft</p>
                        <p>{PricePerSqrt(sqrtPrice, area)}</p>
                      </div>
                      <div className="property-details-age__payment-calculator__annual-taxes property-details-age__payment-calculator__item">
                        <p>Annual Taxes</p>
                        <p>
                          {taxDet.taxAnnualAmount.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 3,
                          })}
                          ({taxDet.taxYear})
                        </p>
                      </div>
                      <div className="property-details-age__paSales and Tax Historyyment-calculator__down-payment property-details-age__payment-calculator__item">
                        <p>Down Payment</p>
                        <p>N/A</p>
                      </div>
                      <div className="property-details-age__payment-calculator__loan-type property-details-age__payment-calculator__item">
                        <p>Loan Type</p>
                        <p>N/A</p>
                      </div>
                      <div className="property-details-age__payment-calculator__interest-rate property-details-age__payment-calculator__item">
                        <p>Interest Rate</p>
                        <p>N/A</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            <div className="property-details-page__school-information">
              <CustomHeading>
                School
                <span
                  style={{
                    borderBottom: '3px solid #C2D500',
                    paddingBottom: '3px',
                  }}
                >
                  {' '}
                  Information
                </span>
              </CustomHeading>
              <p>
                This home is within the{" "}
                {schoolInfo?.length > 0 ? schoolInfo?.[0]?.city : "N/A"} Public
                Schools. {schoolInfo?.length > 0 ? schoolInfo?.[0]?.city : "Massachusetts"}'s enrollment policy is not based solely on
                geography. Please check the school district website to see all
                schools serving this home.
              </p>
              {renderSchools()}
              {!showAllSchool ? (
                <p
                  className="property-details-page__school-information__see-more"
                  onClick={() => {
                    setShowAllSchool(!showAllSchool)
                  }}
                >
                  See more
                </p>
              ) : (
                <p
                  className="property-details-page__school-information__see-more"
                  onClick={() => {
                    window.scrollTo({ top: 2600 })
                    setShowAllSchool(!showAllSchool)
                  }}
                >
                  See less
                </p>
              )}
              <p className="property-details-page__school-information__asterisc">
                <sup>*</sup>School data provided by GreatSchools. School service boundaries
                are intended to be used as reference only. To verify enrollment
                eligibility for a property, contact school directly.
                <br />
                <img src={GreatSchoolLogo} alt="GreatSchools.org brand logo" className="GreatSchoolsLogo" onClick={() => { window.open("https://www.greatschools.org") }} />
                <sup>**</sup>Source: <a href="https://www.greatschools.org" target="_blank" rel="noopener noreferrer">GreatSchools.org</a> © {new Date().getFullYear()}. All rights reserved.
              </p>
            </div>
            {/* <div className="property-details-page__flood-risk">
            <h3>Flood Risk</h3>
            <p>
              Most homes have some risk of flooding. Learn more in these two
              independent assessments, FEMA and Flood Factor.
            </p>
            <div className="property-details-page__flood-risk__container">
              <div className="property-details-page__flood-risk__info">
                <div className="property-details-page__flood-risk__item">
                  <h4>Flood Factor: Minimal (1/10)</h4>
                  <p>
                    This property is unlikely to flood over the next 30 years.
                  </p>
                  <p>Learn more on floodfactor.com</p>
                </div>
                <div className="property-details-page__flood-risk__item">
                  <h4>Estimated FEMA Zone: X</h4>
                  <p>
                    FEMA designates Zone X as a low-to-moderate flood area. In
                    this zone, the risk of flooding is reduced, but not removed.
                  </p>
                  <p>Learn more on floodsmart.gov</p>
                </div>
                <div className="property-details-page__flood-risk__item">
                  <h4>Estimated FEMA Zone: X</h4>
                  <p>
                    Based on your estimated FEMA zone, flood insurance is not
                    required. FEMA always recommends considering insurance.
                  </p>
                  <p>Get an instant quote</p>
                </div>
              </div>
            </div>
          </div> */}
          </div>
          {responsiveMap ? (
            // <Affix offsetTop={70}>
            <Row
              className={
                isPropDetail
                  ? 'property_detail_google_map_div1'
                  : isSell
                    ? 'property_detail_google_map_div3'
                    : isCommon
                      ? 'property_detail_google_map_div2'
                      : ''
              }
            >
              <Col md={24} lg={24}>
                <Row justify="space-between">
                  <Col span={screens.xl ? 7 : 2}>
                    <div
                      className="price_and_icon"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <CustomPriceHeading>
                        {Number(listPrice).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 3,
                        })}
                      </CustomPriceHeading>
                      {isListed ? (
                        <img src={iconConnected} alt="home icon" />
                      ) : (
                        <></>
                      )}
                    </div>
                  </Col>
                  <Col span={screens.xl ? 7 : 2}>
                    {/* <div
                      className="main_div_for_request_favourite_button"
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    > */}
                    {/* <EmailShareButton
                  url={'https://www.npmjs.com/package/react-share'}
                  title="shareLink"
                  className="main_div_share_img"
                > */}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                      }}
                    >
                      <img
                        role="button"
                        src={shareImg}
                        alt="share icon"
                        onClick={handleSharePropModal}
                      />
                      {/* </EmailShareButton> */}
                      {!(history?.location?.pathname?.includes('/propertydetails'))
                        ?
                        (<>
                          {checkIsRequested ? (
                            <img src={requestShowingOn} alt="Showing On Button" />
                          ) : (
                            <img src={requestShowingOff} alt="Showing Off Button" />
                          )}
                        </>)
                        :
                        (<>
                        </>)
                      }

                      {!(history?.location?.pathname?.includes('/propertydetails'))
                        ?
                        (<>
                          {checkIsFavorite ? (
                            <img src={favoriteOn} alt="Favorite On Button" />
                          ) : (
                            <img src={favoriteOff} alt="Favorite Off Button" />
                          )}
                        </>)
                        :
                        (<></>)
                      }
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {isListed && userState?.userType === UserType?.AGENT && !(history?.location?.pathname.includes('/propertydetails')) ? (
                      <CustomViewCalenderPara onClick={viewPropertyCalendarClickHandler}>
                        view property calendar
                      </CustomViewCalenderPara>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col span={screens.xl ? 7 : 2}>
                    <b>
                      {Number(area)?.toLocaleString('en-US', {
                        // style: "currency",
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3,
                      })}{' '}
                      sqft
                    </b>
                  </Col>
                  <Col span={screens.xl ? 7 : 2}>
                    {propDetail.bedrooms} beds
                  </Col>
                  <Col span={screens.xl ? 7 : 2}>
                    {propDetail?.bathrooms + propDetail?.bathsFull} baths
                  </Col>
                </Row>

                <CustomRow justify="start">
                  <Col
                    xxl={12}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={24}
                  // className="property_detail_google_map_column"
                  >
                    {geoLoc && (
                      <GoogleMapReact
                        lat={geoLoc?.lat}
                        lng={geoLoc?.lng}
                        direction={address?.fullAddress}
                        isZoomed={true}
                      />
                    )}
                  </Col>
                  <Col
                    span={screens.xl ? 10 : 12}
                    className="mark_location_details"
                  >
                    <h4 style={{ fontSize: '24px', fontWeight: 'bold' }}>
                      Location
                    </h4>
                    <CustomPara>
                      {Number(area)?.toLocaleString('en-US', {
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3,
                      })}
                      {'  '}sqft <br />
                      {address?.streetNumber} {'  '} {address?.streetName}
                    </CustomPara>
                    <CustomPara>
                      {address?.city}, {address?.state} {address?.postalCode}
                    </CustomPara>
                  </Col>
                  {/* </div> */}
                </CustomRow>
                <CustomDiv>Property type: {propDetail.type}</CustomDiv>
                <CustomDiv>Status: {propDetail?.mls?.status}, For Sale</CustomDiv>
                <CustomDiv>
                  Price per sqft: {PricePerSqrt(sqrtPrice, area)}
                </CustomDiv>
                <CustomDiv>Year Built: {yearBuilt}</CustomDiv>
                {area1 && (
                  <CustomDiv>
                    Lot size: {area1 ? area1 : 'N/A'}
                    <Select
                      style={{
                        width: 100,
                      }}
                      bordered={false}
                      onChange={handleChange}
                      defaultValue="Acres"
                    >
                      <Option value="Acres">Acres</Option>
                      <Option value="sqft">sqft</Option>
                    </Select>
                  </CustomDiv>
                )}
                <CustomDiv>Listed on NWMLS</CustomDiv>
                <Row style={{ width: screens.xl ? '90%' : '100%' }}>
                  {/* <Col span={screens.xl ? 5 : 7}>
                  <Avatar
                    size={38}
                    icon={<UserOutlined />}
                    className="avatar"
                  />
                </Col> */}
                  {/* <Col
                  span={screens.xl ? 17 : 17}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span>
                    {propDetail?.agent?.firstName +
                      " " +
                      propDetail?.agent?.lastName || "N/A"}
                    {/* <br /> Realogics International */}
                  {/* </span>
                  {listedAgent && (
                  <span style={{ display: 'flex' }}>
                    <CustomMailOutlined
                      style={{ marginRight: "5px" }}
                      role="button"
                    />
                    <CustomPhoneOutlined />
                    
                  </span>
                  )}
                   {notListedAgent && (
                  <span style={{ display: 'flex' }}>
                    <CustomPhoneOutlined />
                  </span>
                )}
                </Col>  */}

                  {userState?.isLoggedIn &&
                    userState?.userType === UserType.AGENT && !(history?.location?.pathname.includes('/propertydetails')) ? (
                    <Row
                      style={{ width: screens.xl ? '90%' : '95%' }}
                      onClick={handleAgentModal}
                      role="button"
                    >
                      <Col span={screens.xl ? 5 : 7}>
                        <Avatar
                          style={{ display: 'grid', placeItems: 'center' }}
                          size={38}
                          icon={isListed === false ? <UserOutlined /> : ''}
                          src={isListed ? avatarUrl : ''}
                          className="avatar"
                        />
                      </Col>
                      <Col
                        span={screens.xl ? 17 : 17}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <span style={{ fontWeight: 'bold', fontSize: '13px' }}>
                          {isListed ? listingAgentName : notListedAgentName}
                          <br />
                          <span
                            style={{ fontWeight: 'normal', fontSize: '12px' }}
                          >
                            {isListed ? listingAgentComapny : notListedOffName}
                          </span>
                        </span>

                        {isListed ? (
                          <img
                            src={iconEmail}
                            alt="mail_icon"
                            style={{ marginRight: '10px' }}
                          />
                        ) : (
                          <></>
                        )}
                        {
                          <img
                            src={iconPhone}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                            alt="phone_icon"
                          />
                        }
                      </Col>
                      {/* <CustomDivider /> */}
                      {isFindAnAgentModal && (
                        <FindAnAgentModal
                          isShowing={isFindAnAgentModal}
                          closeAction={findAnAgentModalHandler}
                        />
                      )}
                    </Row>
                  ) : (
                    <>
                      {!userState?.isLoggedIn ? (
                        <Button
                          text="CONTACT AGENT"
                          type={ButtonType?.PRIMARY}
                          clickHandler={() => { }}
                          className="notification-buyer-invitation__button--accept"
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  <CustomDivider />
                  {userState?.userType === UserType.BUYER &&
                    <Col>
                      {isOpenHouse ? (
                        <p>No Open House for this property</p>
                      ) : (
                        <>
                          {userState?.userType === UserType.BUYER && (
                            <CustomParaOpenHouse>Open House</CustomParaOpenHouse>
                          )}

                          {userState?.userType === UserType.BUYER &&
                            openHousePropDetail?.data?.map((item: any) => {
                              return (
                                <div style={{ marginBottom: '1rem' }}>
                                  <CustomParaOpenHouse>
                                    {moment(item?.startTime)
                                      .tz('America/New_York')
                                      .format('ddd, MMM DD') ?? 'N/A'}
                                  </CustomParaOpenHouse>
                                  <p>{`${moment(item?.startTime)
                                    .tz('America/New_York')
                                    .format('hh:mm a')} - ${moment(item?.endTime)
                                      .tz('America/New_York')
                                      .format('hh:mm a')} `}</p>
                                </div>
                              )
                            })}
                        </>
                      )}
                    </Col>
                  }

                  {isFindAnAgentModal && (
                    <FindAnAgentModal
                      isShowing={isFindAnAgentModal}
                      closeAction={findAnAgentModalHandler}
                    />
                  )}
                </Row>
                {sharePropModal && (
                  <PropertyShareModal
                    isShowing={sharePropModal}
                    closeAction={handleSharePropModal}
                    imageurl={imageSrc ?? ''}
                    propertyAddress={`${address?.streetNumber}  ${address?.streetName}
                                ${address?.city}, ${address?.state} ${address?.postalCode}`}
                    listingId={propDetail?.listingId ?? ''}
                    listingAgentName={
                      propDetail?.agent?.firstName +
                      ' ' +
                      propDetail?.agent?.lastName || 'N/A'
                    }
                    mlsId={propDetail?.mlsId}
                  />
                )}
              </Col>
            </Row>
            // </Affix>
          ) : (
            <div style={{ width: '97%', marginLeft: '7px' }}>
              <Row justify="space-between">
                <Col span={2}>
                  <CustomPriceHeading>
                    {Number(listPrice).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 3,
                    })}
                  </CustomPriceHeading>
                </Col>
                <Col span={2}>
                  <img
                    role="button"
                    src={shareImg}
                    alt="share icon"
                    onClick={handleSharePropModal}
                  />
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={2}>
                  <b>
                    {Number(area)?.toLocaleString('en-US', {
                      currency: 'USD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 3,
                    })}{' '}
                    sqft
                  </b>
                </Col>
                <Col span={2}>{propDetail.bedrooms} beds</Col>
                <Col span={2}>
                  {propDetail.bathrooms + propDetail.bathsFull} baths
                </Col>
              </Row>
              <Row justify="start">
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={24}
                  className="property_detail_google_map_column"
                >
                  {geoLoc && (
                    <GoogleMapReact
                      lat={geoLoc.lat}
                      lng={geoLoc.lng}
                      direction={address.fullAddress}
                      isZoomed={false}
                    />
                  )}
                </Col>
                <div style={{ marginBottom: '10px' }}>
                  <Col
                    span={screens.xl ? 12 : 12}
                    className="mark_location_details"
                    style={{ maxWidth: '100%' }}
                  >
                    <h4 style={{ fontSize: '24px', fontWeight: 'bold' }}>
                      Location
                    </h4>
                    <CustomPara>
                      {Number(area).toLocaleString('en-US', {
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3,
                      })}
                      {'  '}sqft <br />
                      {address?.streetNumber} {'  '} {address?.streetName}
                    </CustomPara>
                    <CustomPara>
                      {address?.city}, {address?.state}-{address?.postalCode}
                    </CustomPara>
                  </Col>
                </div>
              </Row>
              <CustomDiv>Property type: {propDetail?.subTypeText}</CustomDiv>
              <CustomDiv>Status: {propDetail?.mls?.status}, For Sale</CustomDiv>
              <CustomDiv>
                Price per sqft: {PricePerSqrt(sqrtPrice, area)}
              </CustomDiv>
              <CustomDiv>Year Built: {yearBuilt}</CustomDiv>
              {area1 && (
                <CustomDiv>
                  Lot size: {area1 ? area1 : 'N/A'}
                  <Select
                    style={{
                      width: 100,
                    }}
                    bordered={false}
                    onChange={handleChange}
                    defaultValue="Acres"
                  >
                    <Option value="Acres">Acres</Option>
                    <Option value="sqft">sqft</Option>
                  </Select>
                </CustomDiv>
              )}
              <CustomDiv>Listed on NWMLS</CustomDiv>
              <Row style={{ width: screens.xl ? '90%' : '95%' }}>
                {/* <CustomDivider /> */}

                {isFindAnAgentModal && (
                  <FindAnAgentModal
                    isShowing={isFindAnAgentModal}
                    closeAction={findAnAgentModalHandler}
                  />
                )}
                {sharePropModal && (
                  <PropertyShareModal
                    isShowing={sharePropModal}
                    closeAction={handleSharePropModal}
                    imageurl={imageSrc ?? ''}
                    propertyAddress={`${address?.streetNumber}  ${address?.streetName}
                                ${address?.city}, ${address?.state} ${address?.postalCode}`}
                    listingId={propDetail?.listingId ?? ''}
                    listingAgentName={
                      propDetail?.agent?.firstName +
                      ' ' +
                      propDetail?.agent?.lastName || 'N/A'
                    }
                    mlsId={propDetail?.mlsId}
                  />
                )}
              </Row>
            </div>
          )}

          {listedAgentModal && (
            <ListedAgentModal
              item={item}
              isShowing={listedAgentModal}
              closeAction={() => {
                setListedAgentModal(!listedAgentModal)
              }}
              agentEmail={agentListedEmail}
              agentPhone={agentListedPhone}
              agentPhoto={avatarUrl}
              modeOfContact={modeOfContact}
              userName={listingAgentName}
              listingAgentId={listAgentId}
              listAgentSName={listAgentSellerName}
              listingAgentName={listingAgentName}
            />
          )}

          {notListedAgentModal && (
            <NotListAgentModal
              item={item}
              isShowing={notListedAgentModal}
              closeAction={() => {
                setNotListedAgentModal(!notListedAgentModal);
              }}
              agentPhone={agentNotListedPhone}
              userName={notListedAgentName}
            />
          )}
        </div>
      </div>
      {isShowingConfirmModal &&
        <FindAnAgentConfirmModal noText='No' text='Please save the route to avoid losing the details of the properties added to the route' yesText='Save' isShowing={isShowingConfirmModal} closeAction={noClickHandler} yesClickHandler={yesClickHandler} pageType='SellBuyerPage' />
      }
    </>
  )
}

export default PropertyDetailsPage
