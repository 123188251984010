import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import moment from 'moment';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useGlobal } from 'reactn';
import Button, { ButtonType } from '../../components/Button';
import PropertyDetailComp from '../../components/SellBuyerPage/propertyDetailsPage';
import { notification } from '../../components/notification';
import AgentContext from '../../contexts/AgentContext';
import PropertyFiltersContext from '../../contexts/PropertyFiltersContext';
import UserContext, { UserType } from '../../contexts/UserContext';
import EditingTour from '../../modals/EndingTour';
import ExceedingTimeModal from '../../modals/GoogleMapModals/exceedingTimeModal';
import SchedulesModal from '../../modals/GoogleMapModals/schedulingStatus';
import {
  checkIsListedPropertyService,
  createBuyerNotificationById,
  getFavoritePropertiesForBuyerService,
  getPropertiesByCity,
  getPropertiesOpenHousesService,
  getRequestedPropertiesForBuyerService,
  getUpcomingTourDetailsByFormId,
  updateIsAllAcceptedUpcomingToursService
} from '../../services/NavihomeService';
import { activatedPlan } from '../../utils/base';
import { insertEventToGoogleCalendar } from '../../utils/syncEvent';
import UserHeader from '.././notificationAgentPage/UserHeader';
import { getSignedInUserEmail } from '../Account/GoogleService';
import GoogleMapTour from '../CommonTour/GoogleMapTour';
import PropertyTourHeader from '../CommonTour/PropertyTourHeader';
import RouteTourHeader from '../CommonTour/RouteTourHeader';
import PropertyCard from '../PropertyCard';
import TourDetailsForm from '../TourDetailsForm';
import TourPropertyDetails from '../TourPropertyDetails';
import CustomSpinner from '../spinner/CustomSpinner';

const CustomRow = styled(Row)`
  width: 5%;
  span {
    padding: 10px;
    font-size: 15px;
    cursor: pointer;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const CustomAdditonalPara = styled.p`
  margin-top: 20px;
  font-size: 20px;
  color: #004876;
  font-weight: 600;
`;

const CustomProperties = styled.div`
  margin-right: 3rem;
  @media (max-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 790px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

const CustomProperties1 = styled.div`
  margin-right: 2px;
  @media (max-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 790px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;


const CommonTourComp: React.FC = () => {
  const history = useHistory();
  const [showmap, setShowMap] = useState(false);
  const { agentState } = useContext(AgentContext);
  const { userState } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [agentIdForLinkedBuyer, setAgentIdForLinkedBuyer] = useState<number>();
  const [buyerSideAgentName, setBuyerSideAgentName] = useState<string>('');
  const [buyerGlobalId, setBuyerGlobalId] = useGlobal<any>('buyerId');
  const [linkedbuyer, setLinkedBuyer] = useState<boolean>(false);
  const [isRemoveScheduledPropClicked, setIsRemoveScheduledPropClicked] =
    useState<string>('NO');
  const [rescheduledPropData, setRescheduledPropData] = useState<Array<any>>(
    []
  );
  const [unlinkedPropertyClicked, setIsUnlinkedPropertyClicked] =
    useState<boolean>(false);
  const [getDirectionsPropertyClicked, setGetDirectionsPropertyClicked] =
    useState<string>('NO');
  const [reqSearchText, setReqSearchText] = useState('');
  const [requestedProperties, setRequestedProperties] = useState([]);
  const [isRequestedCalled, setRequestedCalled] = useState(false);
  const [limit, setLimit] = useState<number>(28);
  const [filteredRequestedProperties, setFilteredRequestedProperties] =
    useState([]);
  const [linkedProperties, setLinkedProperties] = useState([]);
  const [linkedPropertiesData, setLinkedPropertiesData] = useState([]);
  const [selected, Setselected] = useState<string>('form');
  const [scheduleStatus, setScheduleStatus] = useState<boolean>(true);
  const [runMap, setRunMap] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    dateTime: null,
    timePerProp: null,
    startAdd: null,
    startAddCity: null,
    endingAdd: null,
    endingAddCity: null,
    selectedProperties: null,
    area: null,
    propertyStatus: null,
    agentId: null,
    activeTab: null,
    activeTabOherButton: null,
    startrouteLocation: null,
    endrouteLocation: null,
  });
  const [formDataEdited, setFormDataEdited] = useState({
    dateTime: null,
    timePerProp: null,
    startAdd: null,
    startAddCity: null,
    endingAdd: null,
    endingAddCity: null,
    selectedProperties: null,
    area: null,
    propertyStatus: null,
    agentId: null,
    activeTab: null,
    activeTabOherButton: null,
    startrouteLocation: null,
    endrouteLocation: null,
  });

  const [bestRoute, setBestRoute] = useState<any>([]);
  const [filteredLinkedProperties, setFilteredLinkedProperties] = useState<any>(
    []
  );

  const { propertyFiltersState } = useContext(PropertyFiltersContext);
  const { tourCheckedValue } = propertyFiltersState;
  const [linkedPropertiesOutOfBestRoute, setLinkedPropertiesOutOfBestRoute] =
    useState<any>([]);
  const [handleTogglingClicked, setHandleTogglingClicked] =
    useState<string>('Not Clicked');
  const [scheduleTourClicked, setScheduleTourClicked] =
    useState<string>('Not Clicked');
  const [schedulingStatusClicked, setSchedulingStatusClicked] =
    useState<string>('Not Clicked');
  const [dashboardState, setDashboardState] = useState<boolean>(true);
  const [searchProperties, setSearchProperties] = useState<string>(null);
  const [additionalProp, setAdditionalProp] = useState([]);
  const [agentName, setAgentName] = useState(null);
  const [buyerName, setBuyerName] = useState(null);
  const [buyerDetails, setBuyerDetails] = useState<any>(null);
  const [role, setRole] = useState<string>('');
  const [saveTourClicked, setSaveTourClicked] = useState<string>('');
  const [editingTour, setEditingTour] = useState(false);
  const [tourPropPage, setTourPropPage] = useState<boolean>(false);
  const [unlinkPropHeader, setUnlinkPropHeader] = useState(false);
  const [propId, setPropId] = useState<any>(null);
  const [isPropListed, setIsPropListed] = useState<boolean>(false);
  const [isPropReq, setIsPropReq] = useState<boolean>(false);
  const [isPropFav, setIsPropFav] = useState<boolean>(false);
  const [propListingId, setPropListingId] = useState<string>('');
  const [propStartTime, SetPropStartTime] = useState<any>(null);
  const [propEndTime, SetPropEndTime] = useState<any>(null);
  const [manualChangeProperties, setManualChangeProperties] = useState([]);
  const [useManual, setUseManual] = useState<boolean>(false);
  const [useManualChange, setUseManualChange] = useState<string>("false");
  const [dragDropWorking, setDragDropWorking] = useState<boolean>(false);
  const [numberingMarker, setNumberingMarker] = useState<boolean>(false);
  const [isAllAccepted, setIsAllAccepted] = useState<boolean>(false);
  const [isScheduledRouteUpdated, setisScheduledRouteUpdated] =
    useState<boolean>(false);
  const [selectedPropertiesCount, setSelectedPropertiesCount] =
    useState<number>(0);
  const [travelTime, setTravelTime] = useState([]);
  const [startTimeForTimeStamp, setStartTimeForTimeStamp] = useState<any>(
    moment().format('HH:mm')
  );
  const [saveButtonData, setSaveButtonData] = useState<any>(null);
  const [showAddPropertyBtn, setshowAddPropertyBtn] = useState<boolean>(false);
  const [showMsg, setShowMsg] = useState<boolean>(true);
  const [isOutTimeProperty, setIsOutTimeProperty] = useState<boolean>(false);
  const [eliminatePropertyFromTheTour, setEliminatePropertyFromTheTour] =
    useState<number>(0);
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const [isFormDataChanged, setIsFormDataChanged] = useState<boolean>(false);
  const [isShowSchedulignStatusModal, setIsShowSchedulignStatusModal] =
    useState<boolean>(false);
  const [showReScheduleModal, setShowReScheduleModal] =
    useState<boolean>(false);
  const [propDataDetails, setPropDataDetails] = useState<any>([]);

  const [showTimeBar, SetShowTimeBar] = useState<boolean>(true);

  const seeMoreHandler = () => {
    setLimit(limit + 10);
    additionalProperty(searchProperties, limit, true);
  };

  const handleBack = () => {
    let buyerId = -1;
    if (userState?.userType === UserType.AGENT) {
      buyerId = agentState?.buyerId;
    } else {
      buyerId = userState?.buyer?.buyerId;
    }

    // if property detail page show ho rha hai to
    if (tourPropPage) {
      setTourPropPage(false);
      return;
    }

    if (formId !== null && statusType) {
      // if (showmap === false) {
      if (userState?.userType === UserType.AGENT) {
        history.push('/dashboard?buyerId=' + buyerId);
      } else {
        history.push('/buy?buyerId=' + buyerId);
      }
      // }
      if (showmap === false) {
        setShowMap(!showmap);
      }
      return;
    }

    if (dashboardState || !showmap) {
      if (userState?.userType === UserType.AGENT) {
        history.push('/dashboard?buyerId=' + buyerId);
      } else {
        history.push('/buy?buyerId=' + buyerId);
      }
    } else {
      setShowMap(!showmap);
      setDashboardState(!dashboardState);
    }
  };

  const additionalProperty = async (
    cityName: string,
    countProp?: number,
    isLoadmore?: boolean
  ) => {
    try {
      setIsLoading(true);
      let AdditionalData: any;

      if (role === 'buyer') {
        const queryString: string = `city=Houston&state=Texas&query=${cityName}`;
        AdditionalData = await getPropertiesOpenHousesService(queryString, countProp);
      } else {
        AdditionalData = await getPropertiesByCity(cityName, countProp);
      }
      if (AdditionalData?.length <= 0) {
        setSeeMore(true);
      } else {
        setSeeMore(false);
      }

      if (isLoadmore) {
        setAdditionalProp([...additionalProp, ...AdditionalData]);
        setIsLoading(false);
      } else {
        const arr = AdditionalData.filter(
          (item: any) =>
            requestedProperties.findIndex(
              (data) =>
                data?.propertyId ===
                (role === 'buyer' ? item?.listing?.mlsId : item?.mlsId)
            ) === -1
        );
        // setAdditionalProp(AdditionalData)
        setAdditionalProp([...arr]);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('error', error);
      return;
    }
  };

  const renderStatic = () => {
    if (requestedProperties?.length <= 0 && !showMsg) {
      return (
        <>
          <h4 style={{ marginLeft: '10px' }}>
            This buyer doesn't have any properties
          </h4>
        </>
      );
    }

    const cards = (
      reqSearchText?.length > 0
        ? filteredRequestedProperties
        : requestedProperties
    )?.map((listing: any) => {
      let propertyDetailsData: any = null;
      if (
        listing &&
        listing?.propertyDetails?.length > 0 &&
        listing.propertyDetails?.success
      ) {
        propertyDetailsData = JSON.parse(
          listing?.propertyDetails?.data?.listing
        );
      } else if (listing && listing?.propertyDetails?.length > 0) {
        propertyDetailsData = JSON.parse(listing?.propertyDetails);
      }

      if (listing?.length === 0) {
        return <></>;
      }

      if (propertyDetailsData && !propertyDetailsData?.hasOwnProperty('privateRemarks')) {

        if (propertyDetailsData?.propertyDetails) {
          propertyDetailsData = JSON.parse(propertyDetailsData?.propertyDetails)
        }
        else {
          propertyDetailsData = propertyDetailsData?.data?.listing
        }
      }

      return (
        <div className="px-2">
          <PropertyCard
            linkedProperties={linkedProperties}
            setLinkedProperties={setLinkedProperties}
            setLinkedPropertiesData={setLinkedPropertiesData}
            linkedPropertiesData={linkedPropertiesData}
            filteredLinkedProperties={filteredLinkedProperties}
            setFilteredLinkedProperties={setFilteredLinkedProperties}
            listing={propertyDetailsData ? propertyDetailsData : listing}
            isadded={
              linkedPropertiesData?.find(
                (item) =>
                  (item?.propertyId ? item?.propertyId : item?.mlsId) ===
                  (listing?.propertyId ? listing?.propertyId : listing?.mlsId)
              )
                ? false
                : true
            }
            // propertyId={listing?.mlsId ?? 0}
            propertyDetails={
              propertyDetailsData
                ? propertyDetailsData
                : listing
                  ? listing
                  : ' '
            }
            image={
              listing?.imageUrl
                ? listing.imageUrl
                : listing?.photos?.length > 0
                  ? listing?.photos[0]
                  : ''
            }
            price={listing?.listPrice}
            type={listing?.property?.type}
            beds={
              listing?.bedrooms
                ? listing?.bedrooms
                : listing?.property?.bedrooms
            }
            baths={
              listing?.bathrooms
                ? listing?.bathrooms
                : listing?.property?.bathsFull
            }
            squareFootage={
              listing?.area ? listing.area : listing?.property?.area
            }
            mlsId={
              listing?.propertyId
                ? listing.propertyId
                : listing?.listingId ?? 'N/A'
            }
            latitude={
              propertyDetailsData
                ? propertyDetailsData?.geo?.lat
                : listing?.lat ?? 0
            }
            longitude={
              propertyDetailsData
                ? propertyDetailsData?.geo?.lng
                : listing?.geo?.lng ?? 0
            }
            country={
              listing?.country
                ? listing.country
                : listing?.address?.country ?? 'N/A'
            }
            streetNumber={
              Number(listing?.streetNumber)
                ? Number(listing?.streetNumber)
                : Number(listing?.address?.streetNumber) ?? 0
            }
            streetName={
              listing?.streetName
                ? listing.streetName
                : listing?.address?.streetName ?? 'N/A'
            }
            city={
              listing?.city ? listing.city : listing?.address?.city ?? 'N/A'
            }
            state={
              listing?.state ? listing.state : listing?.address?.state ?? 'N/A'
            }
            postalCode={
              listing?.postalCode
                ? listing?.postalCode
                : listing?.address?.postalCode ?? 'N/A'
            }
            bathsHalf={
              listing?.bathsHalf ? listing?.bathsHalf : listing?.bathsHalf ?? 0
            }
            openHouse={
              userState?.userType === UserType.BUYER &&
                history?.location?.pathname.includes('/common')
                ? 'open_house'
                : ''
            }
            feature=""
            propertyId={
              listing?.propertyId ? listing?.propertyId : listing?.mlsId
            }
            setCount={() => { }}
            upcomingBuyerId={history?.location?.search?.split('=')?.[1] ?? ''}
            agentIdForBuyer={agentIdForLinkedBuyer}
            isBuyerLinked={
              userState?.userType === UserType.AGENT ? true : linkedbuyer
            }
            property_type={
              userState?.userType === UserType.BUYER &&
                history?.location?.pathname.includes('/common')
                ? 'open_house'
                : ''
            }
            selectFavoriteProperties={() => { }}
            selectRequestedShowings={() => { }}
            selectPropertyComments={() => { }}
            setTourPropPage={setTourPropPage}
            tourPropPage={tourPropPage}
            setUnlinkPropHeader={setUnlinkPropHeader}
            setPropId={setPropId}
            setshowAddPropertyBtn={setshowAddPropertyBtn}
            openHouseStartTime={
              listing?.openHouseStartTime ? listing?.openHouseStartTime : null
            }
            openHouseEndTime={
              listing?.openHouseEndTime ? listing?.openHouseEndTime : null
            }
            SetPropStartTime={SetPropStartTime}
            SetPropEndTime={SetPropEndTime}
            setIsPropListed={setIsPropListed}
            setPropDataDetails={setPropDataDetails}
            setPropListingId={setPropListingId}
            setIsPropFav={setIsPropFav}
            setIsPropReq={setIsPropReq}
          />
        </div>
      );
    });
    return <Fragment>{cards}</Fragment>;
  };

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let param = useQuery();
  let statusType = param.get('statusType');
  let formId = param.get('formId');

  const getUpcomingTourDetails = async (formId: any) => {
    setIsLoading(true);
    try {
      let token = '';
      if (userState?.userType === UserType.AGENT) {
        token = userState?.agent?.token;
      } else {
        token = userState?.buyer?.token;
      }
      const data: any = await getUpcomingTourDetailsByFormId(formId, token);
      if (data && Object?.keys(data)?.length > 0 && data?.bestRouteProperties?.length > 0) {
        let savePropArr: any = [];
        let isAllAcceptedCount = 0;
        setUseManualChange(data?.secondActiveTab)
        setBuyerDetails(data?.buyer);

        setSelectedPropertiesCount(data?.selectedProperties);
        for (let i = 0; i < data?.bestRouteProperties?.length; i++) {
          let propertyId = data?.bestRouteProperties[i]?.propertyId;

          var listingAgent: any = null;
          if (data?.bestRouteProperties[i]?.isAccepted) isAllAcceptedCount++;
          const isListedRes: any = await checkIsListedPropertyService(
            propertyId
          );

          if (isListedRes?.success) {
            listingAgent = isListedRes?.data?.agent;
          }

          let newObj = data?.bestRouteProperties[i]?.propertyDetails
            ? JSON.parse(data?.bestRouteProperties[i]?.propertyDetails)
            : {};
          newObj['agentId'] = data?.bestRouteProperties[i]?.agentId;
          newObj['buyerId'] = data?.bestRouteProperties[i]?.buyerId;
          newObj['openHouseStartTime'] = data?.bestRouteProperties[i]
            ?.openHouseStartTime
            ? data?.bestRouteProperties[i]?.openHouseStartTime
            : null;
          newObj['openHouseEndTime'] = data?.bestRouteProperties[i]
            ?.openHouseEndTime
            ? data?.bestRouteProperties[i]?.openHouseEndTime
            : null;
          newObj['openHouseId'] = data?.bestRouteProperties[i]?.openHouseId
            ? data?.bestRouteProperties[i]?.openHouseId
            : '';
          newObj['openHouseKey'] = data?.bestRouteProperties[i]?.openHouseKey
            ? data?.bestRouteProperties[i]?.openHouseKey
            : '';
          newObj['isFavorite'] = data?.bestRouteProperties[i]?.isFavorite;
          newObj['isRequested'] = data?.bestRouteProperties[i]?.isRequested;
          newObj['isListed'] = data?.bestRouteProperties[i]?.isListed;
          newObj['isAccepted'] = data?.bestRouteProperties[i]?.isAccepted;
          newObj['isRejected'] = data?.bestRouteProperties[i]?.isRejected;
          newObj['listingAgent'] = listingAgent;
          newObj['listingAgentId'] =
            data?.bestRouteProperties[i]?.listingAgentId;
          newObj['client'] =
            data?.bestRouteProperties[i]?.listingAgentSellerName;
          newObj['agentName'] = agentName;
          newObj['listingAgentEmail'] =
            data?.bestRouteProperties[i]?.listingAgentEmail;
          newObj['startTime'] = data?.bestRouteProperties[i]?.startTime;
          newObj['startDate'] = data?.bestRouteProperties[i]?.startDate;
          newObj['endDate'] = data?.bestRouteProperties[i]?.endDate;
          newObj['endTime'] = data?.bestRouteProperties[i]?.endTime;
          newObj['bestRouteId'] = data?.bestRouteProperties[i]?.bestRouteId;
          newObj['role'] = data?.bestRouteProperties[i]?.role;
          newObj['systemSchedule'] =
            data?.bestRouteProperties[i]?.systemSchedule;
          newObj["tourServiceTime"] = data?.timePerProperty;
          savePropArr.push(newObj);


        }
        if (
          isAllAcceptedCount === data?.selectedProperties &&
          !data?.isAllAccepted &&
          userState?.userType === UserType.AGENT &&
          data?.role === 'Agent'
        ) {
          for (let i = 0; i < data?.bestRouteProperties?.length; i++) {
            const newObjs = data?.bestRouteProperties?.[i]?.propertyDetails;
            let email = await getSignedInUserEmail();
            if (email && data?.isAllAccepted) {
              const startDateTime = `${moment(newObjs?.startDate).format('YYYY-MM-DD')} ${newObjs?.startTime}`;
              const endDateTime = `${moment(newObjs?.endDate).format('YYYY-MM-DD')} ${newObjs?.endTime}`;

              const calendarEvent: any = {
                summary: "View Property",
                location: `${newObjs?.address?.streetNumber} ${newObjs?.address?.streetName}, ${newObjs?.address?.city}, ${newObjs?.address?.state}, ${newObjs?.address?.postalCode}`,
                start: {
                  dateTime: moment(startDateTime),
                  timeZone: "America/Los_Angeles",
                },
                end: {
                  dateTime: moment(endDateTime),
                  timeZone: "America/Los_Angeles",
                },
              };
              insertEventToGoogleCalendar({ calendarEvent });
            }
          }
          //update is isAllAccepted
          await updateIsAllAcceptedUpcomingToursService(
            token,
            formId,
            'Accepted'
          );

          setIsAllAccepted(true);
          setIsShowSchedulignStatusModal(false);
          const local1 = moment(data?.dateTime?.split('T')?.[0], 'YYYY-MM-DD HH:mm:ss').local();
          let tourDate = moment(local1, "YYYY-MM-DD").format("YYYY-MM-DD");
          await createBuyerNotificationById(
            data?.bestRouteProperties?.[0]?.buyerId,
            `Your Agent ${userState?.agent?.name?.toUpperCase()} has scheduled ${data?.selectedProperties
            } properties for showing on ${moment(tourDate).format("MMMM Do, yyyy")} from ${data?.time}.`,
            'Comment',
            userState?.agent?.token,
            'buyer',
            data?.bestRouteProperties?.[0]?.propertyId
          );
        }

        setLinkedProperties(savePropArr);
        setLinkedPropertiesData(savePropArr);
        if (
          statusType === 'afterRouteCreated' ||
          statusType === 'beforeRouteCreated'
        ) {
          setSaveButtonData(data);
          setShowMap(true);
          setFormDataEdited({
            dateTime: moment(saveButtonData?.dateTime).format('MM-DD-YYYY') + ',' + data?.time,
            timePerProp: data?.timePerProperty,
            startAdd: data?.startAdd,
            startAddCity: data?.startAddCity,
            endingAdd: data?.endingAdd,
            endingAddCity: data?.endingAddCity,
            selectedProperties: savePropArr?.length || null,
            area: null,
            propertyStatus: '',
            agentId:
              userState?.userType === UserType.AGENT
                ? userState?.agent?.agentId
                : userState?.buyer?.buyerAgentId || null,
            activeTab: data?.firstActiveTab,
            activeTabOherButton: data?.secondActiveTab,
            startrouteLocation: data?.startrouteLocation
              ? data?.startrouteLocation
              : null,
            endrouteLocation: data?.endrouteLocation
              ? data?.endrouteLocation
              : null,
          });
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let formId: any = param.get('formId');
    let buyerIdForLinkedProp: any = null;
    const routeStatus: string = param.get('routeStatus');
    const query: any = param.get('allAccepted');
    if (
      query === 'notAllAccepted' &&
      routeStatus === 'Scheduled' &&
      userState?.userType === UserType?.AGENT
    ) {
      setIsShowSchedulignStatusModal(true);
    }

    if (userState?.userType === UserType.AGENT) {
      buyerIdForLinkedProp = param.get('buyerId');
    } else {
      buyerIdForLinkedProp = userState?.buyer?.buyerId;
    }

    if (formId !== null && statusType !== null) {
      // user save properties and form detail without created any Route      
      setShowMap(true);
      getUpcomingTourDetails(Number(formId));
    }
    getRequestedProperties(buyerIdForLinkedProp);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchProperties?.length > 0) {
      additionalProperty(searchProperties);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProperties]);

  useEffect(() => {
    if (userState?.userType === UserType.AGENT) {
      setAgentName(userState?.agent?.name);
      setBuyerName(agentState?.buyerName);
      setRole('agent');
    } else {
      setAgentName(buyerSideAgentName);
      setBuyerName(userState?.buyer?.name);
      setRole('buyer');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState, agentState]);



  const getFavoriteProperties = async (buyerId: any) => {
    let token = '';
    if (userState?.userType === UserType.AGENT) {
      token = userState?.agent?.token;
    } else {
      token = userState?.buyer?.token;
    }
    const properties = await getFavoritePropertiesForBuyerService(
      buyerId,
      token,
      "Yes"
    );
    let listings: any = properties;

    return listings;
  };

  const getRequestedProperties = async (buyerId: any) => {
    // setIsLoading(true);
    let token = '';
    if (userState?.userType === UserType.AGENT) {
      token = userState?.agent?.token;
    } else {
      token = userState?.buyer?.token;
    }
    try {
      if (!buyerId) {
        setIsLoading(false);
        return;
      }
      if (!token) {
        setIsLoading(false);
        return notification('Token not found! ', 'error');
      }
      const properties: any = await getRequestedPropertiesForBuyerService(
        buyerId,
        token,
        "Yes"
      );
      let listings: any = properties;
      let response: any = await getFavoriteProperties(buyerId);
      let AllReqFav = [...listings, ...response];
      const uniqueIds: any = [];

      const unique = AllReqFav.filter((element) => {
        const isDuplicate = uniqueIds.includes(element?.propertyId);
        if (!isDuplicate) {
          uniqueIds.push(element?.propertyId);
          return true;
        }
        return false;
      });

      let newPropArr = [...unique];

      if (userState?.userType === UserType.BUYER) {

        // eslint-disable-next-line array-callback-return
        const openHouseArr = newPropArr?.filter((val: any) => {
          if (val?.opneHouse === "open_house") {
            return val;
          }
        });
        if (openHouseArr?.length > 0) {
          setRequestedProperties(openHouseArr);
          setShowMsg(false);
          setRequestedCalled(true);
          // setIsLoading(false);
        }
      } else {
        setRequestedProperties(newPropArr);
        setShowMsg(false);
        setRequestedCalled(true);
        // setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      return notification('Something Went Wrong!', 'error');
    }
  };



  useEffect(() => {
    if (
      linkedPropertiesData &&
      linkedPropertiesData?.length > 0 &&
      isRequestedCalled
    ) {
      const arr = linkedPropertiesData.filter(
        (item) =>
          requestedProperties.findIndex(
            (requested) =>
              (requested?.propertyId
                ? requested?.propertyId
                : requested?.mlsId) ===
              (item?.propertyId ? item?.propertyId : item?.mlsId)
          ) === -1
      );
      let finalArr = [];
      finalArr = [...requestedProperties, ...arr];
      setRequestedProperties(finalArr);
      setShowMsg(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formId,
    linkedPropertiesData,
    linkedProperties,
    statusType,
    isRequestedCalled,
  ]);
  return (
    <Fragment>
      {activatedPlan?.includes(userState?.agent?.subscription_type) && (
        <UserHeader />
      )}

      {isLoading && <CustomSpinner />}
      {/* this row is used for back button */}
      <CustomRow onClick={handleBack}>
        <span>Back</span>
      </CustomRow>
      {/* this Row is used for header */}
      <Row>
        {showmap ? (
          // Google map header

          <RouteTourHeader
            setBestRoute={setBestRoute}
            setFilteredLinkedProperties={setFilteredLinkedProperties}
            setLinkedPropertiesOutOfBestRoute={
              setLinkedPropertiesOutOfBestRoute
            }
            setIsUnlinkedPropertyClicked={setIsUnlinkedPropertyClicked}
            linkedProperties={linkedProperties}
            linkedPropertiesData={linkedPropertiesData}
            filteredLinkedProperties={filteredLinkedProperties}
            buyerGlobalId={buyerGlobalId}
            rescheduledPropData={rescheduledPropData}
            Setselected={Setselected}
            selected={selected}
            formData={formData}
            setShowMap={setShowMap}
            setRunMap={setRunMap}
            runMap={runMap}
            agentIdForLinkedBuyer={agentIdForLinkedBuyer}
            setDragDropWorking={setDragDropWorking}
            setNumberingMarker={setNumberingMarker}
            numberingMarker={numberingMarker}
            setUseManual={setUseManual}
            setTravelTime={setTravelTime}
            statusType={statusType ? statusType : null}
            bestRoute={bestRoute}
            handleTogglingClicked={handleTogglingClicked}
            setHandleTogglingClicked={setHandleTogglingClicked}
            useManual={useManual}
            saveButtonData={
              statusType === 'afterRouteCreated'
                ? {
                  ...saveButtonData,
                  bestRouteProperties: linkedPropertiesData,
                }
                : null
            }
            formId={formId ?? param.get('formId')}
            setUnlinkPropHeader={setUnlinkPropHeader}
            isFormDataChanged={isFormDataChanged}
            setShowReScheduleModal={setShowReScheduleModal}
            isAllAccepted={isAllAccepted}
            isOutTimeProperty={isOutTimeProperty}
            setIsOutTimeProperty={setIsOutTimeProperty}
            setEliminatePropertyFromTheTour={setEliminatePropertyFromTheTour}
            eliminatePropertyFromTheTour={eliminatePropertyFromTheTour}
            SetShowTimeBar={SetShowTimeBar}
            setLinkedPropertiesData={setLinkedPropertiesData}
            scheduleStatus={scheduleStatus}
            setScheduleStatus={setScheduleStatus}
            isRemoveScheduledPropClicked={isRemoveScheduledPropClicked}
            setIsRemoveScheduledPropClicked={setIsRemoveScheduledPropClicked}
            buyerDetails={buyerDetails}
            setIsAllAccepted={setIsAllAccepted}
            getDirectionsPropertyClicked={getDirectionsPropertyClicked}
            manualChangeProperties={manualChangeProperties}
            isScheduledRouteUpdated={isScheduledRouteUpdated}
            setIsShowSchedulignStatusModal={setIsShowSchedulignStatusModal}
            schedulingStatusClicked={schedulingStatusClicked}
            setScheduleTourClicked={setScheduleTourClicked}
            setManualChangeProperties={setManualChangeProperties}
            setTourPropPage={setTourPropPage}
            setselected={Setselected}
            useManualChange={useManualChange}
            setUseManualChange={setUseManualChange}
          // setDashboardState={setShowMap}
          // setRunApiOnLoad={setRunApiOnLoad}
          />
        ) : (
          <>
            {/* NewTourDetails header */}

            <PropertyTourHeader
              setShowMap={setShowMap}
              showmap={showmap}
              setDashboardState={setDashboardState}
              setReqSearchText={setReqSearchText}
              requestedProperties={requestedProperties}
              setFilteredRequestedProperties={setFilteredRequestedProperties}
              filteredRequestedProperties={filteredRequestedProperties}
              filteredLinkedProperties={filteredLinkedProperties}
              setFilteredLinkedProperties={setFilteredLinkedProperties}
              setLinkedProperties={setLinkedProperties}
              linkedProperties={linkedProperties}
              setLinkedPropertiesData={setLinkedPropertiesData}
              linkedPropertiesData={linkedPropertiesData}
              setAgentIdForLinkedBuyer={setAgentIdForLinkedBuyer}
              agentIdForLinkedBuyer={agentIdForLinkedBuyer}
              setBuyerSideAgentName={setBuyerSideAgentName}
              setLinkedBuyer={setLinkedBuyer}
              setSearchProperties={setSearchProperties}
              setEditingTour={setEditingTour}
              unlinkPropHeader={unlinkPropHeader}
              setTourPropPage={setTourPropPage}
              tourPropPage={tourPropPage}
              propId={propId}
              showAddPropertyBtn={showAddPropertyBtn}
              setUnlinkPropHeader={setUnlinkPropHeader}
              formData={formData}
              propDataDetails={propDataDetails}
              getDirectionsPropertyClicked={getDirectionsPropertyClicked}
              setGetDirectionsPropertyClicked={setGetDirectionsPropertyClicked}
              setRunMap={setRunMap}
              setUseManual={setUseManual}
              setIsAllAccepted={setIsAllAccepted}
              setisScheduledRouteUpdated={setisScheduledRouteUpdated}
              setBestRoute={setBestRoute}
              setselected={Setselected}
              setIsUnlinkedPropertyClicked={setIsUnlinkedPropertyClicked}
              setScheduleStatus={setScheduleStatus}
              setHandleTogglingClicked={setHandleTogglingClicked}
              saveTourClicked={saveTourClicked}
              setSchedulingStatusClicked={setSchedulingStatusClicked}
            />
          </>
        )}
      </Row>
      {/* this row is used for TourDetailForm and toggling between map and properties */}
      <Row>
        {/* this column is used for Tour Detail Form */}
        <Col
          xs={{ span: 24 }}
          sm={{ span: 10 }}
          md={{ span: 12 }}
          lg={{ span: 7 }}
          style={{ paddingLeft: '0' }}
        >
          {selected === 'prop' ? (
            <>
              <TourPropertyDetails
                buyerGlobalId={buyerGlobalId}
                linkedProperties={linkedProperties}
                linkedPropertiesData={linkedPropertiesData}
                filteredLinkedProperties={filteredLinkedProperties}
                setLinkedPropertiesData={setLinkedPropertiesData}
                setLinkedProperties={setLinkedProperties} //buyer Agent
                setShowMap={setShowMap}
                unlinkedPropertyClicked={unlinkedPropertyClicked}
                setIsUnlinkedPropertyClicked={setIsUnlinkedPropertyClicked}
                setUnlinkPropHeader={setUnlinkPropHeader}
                setTourPropPage={setTourPropPage}
                bestRoute={bestRoute}
                handleTogglingClicked={handleTogglingClicked}
                setManualChangeProperties={setManualChangeProperties}
                setUseManual={setUseManual}
                dragDropWorking={dragDropWorking}
                numberingMarker={numberingMarker}
                useManual={useManual}
                travelTime={travelTime}
                dateTime={formData?.dateTime}
                serviceTime={formData?.timePerProp}
                startTimeForTimeStamp={startTimeForTimeStamp}
                formData={formData}
                statusType={statusType}
                setselected={Setselected}
                setRunMap={setRunMap}
                saveButtonData={saveButtonData}
                setIsOutTimeProperty={setIsOutTimeProperty}
                setEliminatePropertyFromTheTour={
                  setEliminatePropertyFromTheTour
                }
                SetShowTimeBar={SetShowTimeBar}
                showTimeBar={showTimeBar}
                setFilteredLinkedProperties={setFilteredLinkedProperties}
                setIsAllAccepted={setIsAllAccepted}
                setScheduleStatus={setScheduleStatus}
                setisScheduledRouteUpdated={setisScheduledRouteUpdated}
                setBestRoute={setBestRoute}
                setHandleTogglingClicked={setHandleTogglingClicked}
              />
            </>
          ) : (
            // At this page we need all the api info and pass them to TourDetailsForm
            <TourDetailsForm
              agentName={agentName}
              buyerName={buyerName}
              setFormData={setFormData}
              setFormDataEdited={setFormDataEdited}
              formData={formData}
              formDataEdited={formDataEdited}
              selected={selected}
              buyerIdForAddress={
                agentState.buyerId
                  ? agentState?.buyerId
                  : userState?.buyer?.buyerId
              }
              buyerSideAgentId={agentIdForLinkedBuyer}
              setStartTimeForTimeStamp={setStartTimeForTimeStamp}
              saveButtonData={saveButtonData}
              setIsFormDataChanged={setIsFormDataChanged}
              linkedPropertiesData={linkedPropertiesData}
              setLinkedPropertiesData={setLinkedPropertiesData}
              bestRoute={bestRoute}
              handleTogglingClicked={handleTogglingClicked}
              useManual={useManual}
              setIsOutTimeProperty={setIsOutTimeProperty}
              setEliminatePropertyFromTheTour={setEliminatePropertyFromTheTour}
              setScheduleStatus={setScheduleStatus}
              filteredLinkedProperties={filteredLinkedProperties}
              setFilteredLinkedProperties={setFilteredLinkedProperties}
              isShowSchedulignStatusModal={isShowSchedulignStatusModal}
              isFormDataChanged={isFormDataChanged}
              setSaveTourClicked={setSaveTourClicked}
              setEndLocation={() => { }}
              setStartLocation={() => { }}
            />
          )}
        </Col>
        {/* this Column is used for Map and properties */}
        <Col
          xs={{ span: 24 }}
          sm={{ span: 14 }}
          md={{ span: 12 }}
          lg={{ span: 17 }}
        >
          {runMap || useManual ? (
            <>
              <GoogleMapTour
                bestRoute={useManual ? manualChangeProperties : bestRoute}
                handleTogglingClicked={handleTogglingClicked}
                linkedProperties={linkedPropertiesData}
                linkedPropertiesData={linkedPropertiesData}
                filteredLinkedProperties={filteredLinkedProperties}
                linkedPropertiesOutOfBestRoute={linkedPropertiesOutOfBestRoute}
                setRescheduledPropData={setRescheduledPropData}
                rescheduledPropData={rescheduledPropData}
                formId={formId ?? param.get('formId')}
                sourceLocation={formData?.startrouteLocation}
                destinationLocation={formData?.endrouteLocation}
                showmap={showmap}
                useManual={useManual}
                setUseManual={setUseManual}
                numberingMarker={numberingMarker}
                saveButtonData={statusType !== null ? saveButtonData : null}
                statusType={statusType}
                serviceTime={formData?.timePerProp}
                startTime={'01:02 PM'}
                endTime={'01:22 PM'}
                showReScheduleModal={showReScheduleModal}
                setLinkedPropertiesData={setLinkedPropertiesData}
                setTourPropPage={setTourPropPage}
                setShowMap={setShowMap}
                setRunMap={setRunMap}
                setPropId={setPropId}
                setPropDataDetails={setPropDataDetails}
                formData={formData}
                unlinkedPropertyClicked={unlinkedPropertyClicked}
                setIsRemoveScheduledPropClicked={
                  setIsRemoveScheduledPropClicked
                }
                selectedPropertiesCount={selectedPropertiesCount}
                setIsAllAccepted={setIsAllAccepted}
                setGetDirectionsPropertyClicked={
                  setGetDirectionsPropertyClicked
                }
                setPropListingId={setPropListingId}
              />
            </>
          ) : (
            <>
              {tourPropPage ? (
                <div style={{ marginTop: '25px' }}>
                  <PropertyDetailComp
                    mlsId={propId}
                    startTime={propStartTime}
                    endTime={propEndTime}
                    // propDataDetails = {propDataDetails}
                    // isListed={isListed}
                    isPropListed={isPropListed}
                    propListingId={propListingId}
                    isPropReq={isPropReq}
                    isPropFav={isPropFav}
                    linkedPropertiesData={linkedPropertiesData}
                    setSaveTourClicked={setSaveTourClicked}
                  />
                </div>
              ) : (
                <>
                  {showmap ? (
                    <>
                      <GoogleMapTour
                        bestRoute={
                          useManual ? manualChangeProperties : bestRoute
                        }
                        handleTogglingClicked={handleTogglingClicked}
                        linkedProperties={linkedProperties}
                        linkedPropertiesData={linkedPropertiesData}
                        filteredLinkedProperties={filteredLinkedProperties}
                        linkedPropertiesOutOfBestRoute={
                          linkedPropertiesOutOfBestRoute
                        }
                        setRescheduledPropData={setRescheduledPropData}
                        rescheduledPropData={rescheduledPropData}
                        showmap={showmap}
                        useManual={useManual}
                        numberingMarker={numberingMarker}
                        setUseManual={setUseManual}
                        saveButtonData={
                          statusType !== null ? saveButtonData : null
                        }
                        statusType={statusType}
                        serviceTime={formData?.timePerProp}
                        startTime={'01:02 PM'}
                        endTime={'01:22 PM'}
                        showReScheduleModal={showReScheduleModal}
                        setLinkedPropertiesData={setLinkedPropertiesData}
                        setTourPropPage={setTourPropPage}
                        setShowMap={setShowMap}
                        setRunMap={setRunMap}
                        setPropId={setPropId}
                        setPropDataDetails={setPropDataDetails}
                        formData={formData}
                        unlinkedPropertyClicked={unlinkedPropertyClicked}
                        setIsRemoveScheduledPropClicked={
                          setIsRemoveScheduledPropClicked
                        }
                        selectedPropertiesCount={selectedPropertiesCount}
                        setIsAllAccepted={setIsAllAccepted}
                        setIsShowSchedulignStatusModal={
                          setIsShowSchedulignStatusModal
                        }
                        setGetDirectionsPropertyClicked={
                          setGetDirectionsPropertyClicked
                        }
                        sourceLocation={formData?.startrouteLocation}
                        destinationLocation={formData?.endrouteLocation}
                        setPropListingId={setPropListingId}
                        formId={formId ?? param.get('formId')}
                      />
                    </>
                  ) : (
                    // render properties all requested and favoutires
                    <>
                      <CustomProperties1 className="render-listed-properties-card new_tour_detail_listed_prop ">
                        {renderStatic()}
                      </CustomProperties1>
                      {searchProperties?.length > 0 &&
                        additionalProp?.length > 0 && (
                          <CustomAdditonalPara>
                            Additional Matches
                          </CustomAdditonalPara>
                        )}
                      <CustomProperties className="render-listed-properties-card new_tour_detail_listed_prop ">
                        {searchProperties?.length > 0 &&
                          additionalProp?.map((item: any) => {
                            // role==='buyer' ? item?.listing?.mlsId : item?.mlsId
                            return (
                              <PropertyCard
                                linkedProperties={linkedProperties}
                                linkedPropertiesData={linkedPropertiesData}
                                setLinkedProperties={setLinkedProperties}
                                setLinkedPropertiesData={
                                  setLinkedPropertiesData
                                }
                                listing={
                                  item?.listing ? item?.listing : item
                                }
                                propertyDetails={
                                  item?.listing ? item?.listing : item
                                }
                                isadded={
                                  linkedPropertiesData?.find(
                                    (data) =>
                                      (data?.propertyId
                                        ? data?.propertyId
                                        : data?.mlsId) ===
                                      (item?.listing
                                        ? item?.listing?.mlsId
                                        : item?.mlsId)
                                  )
                                    ? false
                                    : true
                                }
                                propertyId={
                                  item?.listing
                                    ? item?.listing?.mlsId
                                    : item?.mlsId
                                }
                                image={
                                  item?.listing
                                    ? item?.listing?.photos[0]
                                    : item?.photos[0] ?? ''
                                }
                                streetName={
                                  item?.listing
                                    ? item?.listing?.address?.streetName
                                    : item?.address?.streetName ?? ''
                                }
                                streetNumber={
                                  item?.listing
                                    ? item?.listing?.address?.streetNumber
                                    : item?.address?.streetNumber
                                }
                                state={
                                  item?.listing
                                    ? item?.listing?.address?.state
                                    : item?.address?.state
                                }
                                city={
                                  item?.listing
                                    ? item?.listing?.address?.city
                                    : item?.address?.city
                                }
                                postalCode={
                                  item?.listing
                                    ? item?.listing?.address?.postalCode
                                    : item?.address?.postalCode
                                }
                                price={
                                  item?.listing
                                    ? item?.listing?.listPrice
                                    : item?.listPrice
                                }
                                type={
                                  item?.listing
                                    ? item?.listing?.property?.type
                                    : item?.property?.type
                                }
                                beds={
                                  item?.listing
                                    ? item?.listing?.property?.bedrooms
                                    : item?.property?.bedrooms
                                }
                                baths={
                                  item?.listing
                                    ? item?.listing?.property?.bathsFull
                                    : item.property.bathsFull
                                }
                                bathsHalf={
                                  item?.listing
                                    ? item?.listing?.property?.bathsHalf
                                    : item?.property?.bathsHalf
                                }
                                mlsId={
                                  item?.listing
                                    ? item?.listing?.listingId
                                    : item?.listingId
                                }
                                squareFootage={
                                  item?.listing
                                    ? item?.listing?.property?.area
                                    : item?.property?.area
                                }
                                latitude={
                                  item?.listing
                                    ? item?.listing?.geo?.lat
                                    : item?.geo?.lat
                                }
                                longitude={
                                  item?.listing
                                    ? item?.listing?.geo?.lng
                                    : item?.geo?.lng
                                }
                                feature=""
                                setCount={() => { }}
                                upcomingBuyerId={
                                  history?.location?.search?.split('=')?.[1] ??
                                  ''
                                }
                                agentIdForBuyer={agentIdForLinkedBuyer}
                                isBuyerLinked={
                                  userState?.userType === UserType.AGENT
                                    ? true
                                    : linkedbuyer
                                }
                                property_type={
                                  role === 'buyer' || item?.openHouseKey
                                    ? 'open_house'
                                    : null
                                }
                                setTourPropPage={setTourPropPage}
                                setUnlinkPropHeader={setUnlinkPropHeader}
                                setPropId={setPropId}
                                setshowAddPropertyBtn={setshowAddPropertyBtn}
                                openHouseStartTime={
                                  role === 'buyer' ? item?.startTime : null
                                }
                                openHouseEndTime={
                                  role === 'buyer' ? item?.endTime : null
                                }
                                openHouseKey={
                                  role === 'buyer' ? item?.openHouseKey : null
                                }
                                setIsPropListed={setIsPropListed}
                                setPropDataDetails={setPropDataDetails}
                                SetPropStartTime={SetPropStartTime}
                                SetPropEndTime={SetPropEndTime}
                                setPropListingId={setPropListingId}
                                setIsPropFav={setIsPropFav}
                                setIsPropReq={setIsPropReq}
                                filteredLinkedProperties={
                                  filteredLinkedProperties
                                }
                                setFilteredLinkedProperties={
                                  setFilteredLinkedProperties
                                }
                              />
                            );
                          })}
                      </CustomProperties>

                      {/* {count <= properties?.length && filteredSearchText?.length <= 0 && ( */}
                      {searchProperties?.length > 0 && !seeMore && (
                        <div className="properties-page__load-more">
                          <Button
                            type={ButtonType.SECONDARY}
                            text="SEE MORE"
                            clickHandler={seeMoreHandler}
                          />
                        </div>
                      )}
                      {/* )} */}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Col>
      </Row>
      {editingTour && tourCheckedValue !== "Yes" && (
        <EditingTour isShowing={editingTour} closeAction={setEditingTour} />
      )}
      {isShowSchedulignStatusModal && (
        <SchedulesModal
          isShowing={isShowSchedulignStatusModal}
          closeAction={() => {
            setIsShowSchedulignStatusModal(false);
          }}
          linkedPropertiesData={linkedPropertiesData}
          filteredLinkedProperties={filteredLinkedProperties}
          linkedPropertiesOutOfBestRoute={linkedPropertiesOutOfBestRoute}
          manualChangeProperties={manualChangeProperties}
          setLinkedPropertiesData={setLinkedPropertiesData}
          setShowMap={setShowMap}
          setUnlinkPropHeader={setUnlinkPropHeader}
          setTourPropPage={setTourPropPage}
          setselected={Setselected}
          setRunMap={setRunMap}
          bestRoute={bestRoute}
          handleTogglingClicked={handleTogglingClicked}
          useManual={useManual}
          formData={formData}
          setScheduleStatus={setScheduleStatus}
          setSchedulingStatusClicked={setSchedulingStatusClicked}
          scheduleTourClicked={scheduleTourClicked}
        />
      )}
      {isOutTimeProperty && (
        <ExceedingTimeModal
          isShowing={isOutTimeProperty}
          closeAction={() => {
            setIsOutTimeProperty(false);
            setEliminatePropertyFromTheTour(0);
          }}
          formData={formData}
          eliminatePropertyFromTheTour={eliminatePropertyFromTheTour}
        />
      )}
    </Fragment>
  );
};

export default CommonTourComp;
