/* eslint-disable use-isnan */
/* eslint-disable array-callback-return */
/* eslint-disable no-new-wrappers */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from "react";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import UserContext, { UserType } from "../contexts/UserContext";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import "../assets/css/pages/calendar-agent-page.css";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid"; // a plugin!
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Button, Modal, Popover, Select } from "antd";
import "./AgentCalendar/styles/agentCalendar.css";
import "../assets/css/pages/buyercalendar.css";
import {
  createEventByBuyer,
  getBuyerCalendarEvents,
  getViewPropertyCalendarByBuyerID,
  patchBuyerCalendarView,
  getBuyerAccountSettings,
  buyerCalendarViewSetting,
  inviteAgentByBuyer,
  getListingPropertyIdByBuyerServie,
  getAllupcomingTourDetailsData,
  getAllSellerUpcomingTours,
  getViewPropertyCalendarByPropID
} from "../services/NavihomeService";
import { Row, Col, Grid } from "antd";
import CalendarModal from "../modals/CalendarModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DragConfig from "./dragConfig/dragConfig";
import { useMediaQuery } from "react-responsive";
import ConsumerCalendarEventPopover from "./AgentCalendar/calendarPopovers/consumerCalendarEventPopovers";
import CustomSpinner from "./spinner/CustomSpinner";
import CalendarRequestPopover from "./AgentCalendar/calendarPopovers/calendarRequestPopover";
import styled from "@emotion/styled";
import { getNotSyncedEventstoGoogleSync } from "../utils/syncEvent";
const { useBreakpoint } = Grid;
const { Option } = Select;
interface IAgentCalendarProps {
  dateValue?: string | undefined;
  clickedDay?: string | undefined;
  currentMonth: boolean;
  setPresentView: any;
  setMonth?: (val: any) => void;
}

const CustomFullCalendarDiv = styled.div<{}>`
  .fc-daygrid-event-harness {
    // height:30px;
  }
`;
const BuyerCalendar: React.FunctionComponent<IAgentCalendarProps> = ({ dateValue, clickedDay, currentMonth, setPresentView, setMonth }) => {
  const [isShowingCalendarModal, setIsShowingCalendarModal] = useState(false);
  const [popOverId, setPopoverId] = useState<any>("");
  const { userState } = useContext(UserContext);
  const [eventsList, setEventsList] = useState<any>([]);
  const [monthview, setMonthView] = useState<boolean>(false);
  const [listingPropertyId, setListingPropertyId] = useState<number>(0);
  let calendarRef = useRef(null);
  const [view, setView] = useState("");
  const [hovered, setHovered] = useState<boolean>(false);
  const [colorChange, setColorChange] = useState({ selectedDate: new Date() });
  const [isDragModalVisible, setIsDragModalVisible] = useState(false);
  const [calendarPropertyId, setCalendarPropertyId] = useState<number>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [Stime, setStime] = useState<any>();
  const [Sdate, setSdate] = useState<Date>(new Date());
  const [Etime, setEtime] = useState<any>();
  const [Edate, setEdate] = useState<Date>(new Date());
  const isCalentitle = useMediaQuery({ maxWidth: 568 });

  const [dragInfo, setDragInfo] = useState<{
    startTime: Date;
    endTime: string;
    realStartTime: string;
    realEndTime: Date;
    eventId: number | string;
  }>({
    startTime: new Date(),
    endTime: "",
    realStartTime: "",
    realEndTime: new Date(),
    eventId: ""
  });
  const [daysOfWeek, setDaysOfWeek] = useState<Array<string>>(["0", "1", "3", "4", "5"]);

  const minTime = new Date();
  minTime.setHours(8, 30, 0);
  const maxTime = new Date();
  maxTime.setHours(20, 30, 0);
  const screen = useBreakpoint();
  let changeValue = async () => {
    let nextMonth: any = await calendarRef?.current?.getApi()?.view?.title;
    let nextMonthForWeek: any = await calendarRef?.current?.getApi()?.view?.activeEnd;
    switch (calendarRef?.current?.getApi()?.view?.type) {
      case "timeGridWeek":
        setMonth(new Date(nextMonthForWeek));
        break;
      case "dayGridMonth":
        // setMonth(new Date(nextMonth));
        break;
      case "timeGridDay":
        setMonth(new Date(nextMonth));
        break;
    }
    // let staticValue = nextMonth;
  };
  let dayChange = (value: string) => {
    switch (value) {
      case "next":
        calendarRef?.current?.getApi().next();
        let nextMonth: any = calendarRef?.current?.getApi()?.view?.title;
        break;
      case "prev":
        calendarRef?.current?.getApi().prev();
        let prevMonth = calendarRef?.current?.getApi()?.view?.title;
        break;
      case "Today":
        calendarRef?.current?.getApi()?.today();
        calendarRef?.current?.getApi()?.changeView("timeGridDay");
        setView("timeGridDay");
    }
  };

  const getDayName = (dayIndex: number) => {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    return days[dayIndex];
  };
  useEffect(() => {
    getBuyerAccountSettings(userState?.buyer?.token, userState?.buyer?.buyerId).then((value: any) => {
      if (Object?.keys(value)?.length === 0 || value?.[0]?.["CalendarView"] === undefined || Object.values(value?.[0]?.["CalendarView"])?.length === 0) {
        setView("timeGridWeek");
      } else {
        setView(value?.[0]?.["CalendarView"] ?? "timeGridWeek");
      }
    });
    calenderEvent();

    if (userState?.buyer?.sellerAgentName?.length > 0) {
      getListedPropertyId();
    }
  }, [setView]);

  let changeViewed = async (value: string) => {
    switch (value) {
      case "timeGridDay":
        calendarRef?.current?.getApi()?.changeView("timeGridDay");
        break;
      case "timeGridWeek":
        calendarRef?.current?.getApi()?.changeView("timeGridWeek");
        break;
      case "dayGridMonth":
        calendarRef?.current?.getApi()?.changeView("dayGridMonth");
        break;
    }
    let buyerData: any = await getBuyerAccountSettings(userState?.buyer?.token, userState?.buyer?.buyerId);
    if (Object?.keys(buyerData)?.length > 0) {
      patchBuyerCalendarView(userState?.buyer?.token, userState?.buyer?.buyerId, value);
    } else {
      buyerCalendarViewSetting(userState?.buyer?.token, value, {});
    }
    setView(value);
    setPresentView(value);
  };

  useEffect(() => {
    if (dateValue) {
      if (clickedDay) {
        calendarRef?.current?.getApi()?.gotoDate(new Date(clickedDay).toISOString());
      } else {
        calendarRef?.current?.getApi()?.gotoDate(new Date(dateValue).toISOString());
      }
    }
  }, [dateValue, currentMonth, clickedDay]);

  const handleDragOk = () => {
    setIsDragModalVisible(false);
  };

  const handleDragCancel = () => {
    setIsDragModalVisible(false);
  };

  const getListedPropertyId = async () => {
    const listingId: any = await getListingPropertyIdByBuyerServie(userState.buyer.token, userState.buyer.Email);
    setListingPropertyId(Number(listingId?.propertyId));
  };

  const calenderEvent = async () => {
    setIsLoading(true);
    try {
      let arr: any = [];

      let currDate = moment().format("YYYY-MM-DD");

      const newToursData: any = await getAllSellerUpcomingTours(userState.buyer.sellerListingId, currDate, userState?.buyer?.token, "Buyer");

      if (newToursData) {
        newToursData?.map((item: any) => {
          const stYear: any = new Date(item?.startDate)?.getFullYear();
          const stmonth: any = new Date(item?.startDate)?.getMonth();
          const stDay: any = new Date(item?.startDate)?.getUTCDate();
          const eYear: any = new Date(item?.endDate)?.getFullYear();
          const endmonth: any = new Date(item?.endDate)?.getMonth();
          const endDay: any = new Date(item?.endDate)?.getUTCDate();
          let stHours: any = new Number(item?.startTime?.split(":")?.[0]);
          let stMins: any = new Number(item?.startTime?.split(":")?.[1].split(" ")?.[0]);
          let endHours: any = new Number(item?.endTime?.split(":")?.[0]);
          let endMins: any = new Number(item?.endTime?.split(":")?.[1].split(" ")?.[0]);
          if (item?.startTime?.split(" ")?.[1]?.toLowerCase() === "pm" && stHours < 12) stHours += 12;
          if (item?.endTime?.split(" ")?.[1]?.toLowerCase() === "pm" && endHours < 12) endHours += 12;
          if (stMins === "0") stMins = "00";
          if (endMins === "0") endMins = "00";

          let propDetailsForBestRoute: any = null;
          if (item?.propertyDetails) {
            propDetailsForBestRoute = JSON?.parse(item?.propertyDetails);
          }
          const agentFullName: string = propDetailsForBestRoute?.agent?.firstName + " " + propDetailsForBestRoute?.agent?.lastName;
          arr?.push({
            id: item?.bestRouteId,
            title: "Scheduled Property",
            start: new Date(Number(stYear), Number(stmonth), Number(stDay), Number(stHours), Number(stMins), 0),
            end: new Date(Number(eYear), Number(endmonth), Number(endDay), Number(endHours), Number(endMins), 0),
            Stime: item?.startTime,
            Sdate: item?.startDate,
            Etime: item?.endTime,
            Edate: item?.endDate,
            rEdate: item?.recuringEndDate,
            name: propDetailsForBestRoute?.agent?.firstName,
            fullName: propDetailsForBestRoute?.agent?.firstName + " " + propDetailsForBestRoute?.agent?.lastName,
            eventType: "Scheduled Property",
            agentName: "userState?.agent?.name",
            phone: propDetailsForBestRoute?.agent?.contact?.office,
            address: propDetailsForBestRoute?.address?.full,
            streetName: propDetailsForBestRoute?.address?.streetName,
            streetNumber: propDetailsForBestRoute?.address?.streetNumber,
            city: propDetailsForBestRoute?.address?.city,
            state: propDetailsForBestRoute?.address?.state,
            country: propDetailsForBestRoute?.address?.country,
            postalCode: propDetailsForBestRoute?.address?.postalCode,
            pid: propDetailsForBestRoute?.mlsId,
            duration: item?.propertyDuration,
            isAccepted: item?.isAccepted,
            isRejected: item?.isRejected,
            role: "",
            listingId: propDetailsForBestRoute?.listingId,
            type: "Seller Property",
            buyerAgentName: item?.listingAgentName ? item?.listingAgentName : agentFullName ? agentFullName : userState?.buyer?.buyerAgentName,
            client: userState?.buyer?.buyerAgentName,
            clientPhone: userState?.buyer?.buyerAgentPhone
          });
        });
      }

      const result: any = await getAllupcomingTourDetailsData(userState?.buyer?.buyerId, userState?.buyer?.token, "Buyer", "Calendar", "Not Scheduled Showings");

      result?.forEach((getBestRoute: any) => {
        getBestRoute?.bestRouteProperties?.map((item: any) => {
          const stYear: any = new Date(item?.startDate)?.getFullYear();
          const stmonth: any = new Date(item?.startDate)?.getMonth();
          const stDay: any = new Date(item?.startDate)?.getUTCDate();
          // const stDay: any = dayjs(item?.startDate)?.date();
          const eYear: any = new Date(item?.endDate)?.getFullYear();
          const endmonth: any = new Date(item?.endDate)?.getMonth();
          const endDay: any = new Date(item?.endDate)?.getUTCDate();
          let stHours: any = new Number(item?.startTime?.split(":")?.[0]);
          let stMins: any = new Number(item?.startTime?.split(":")?.[1]?.split(" ")?.[0]);
          let endHours: any = new Number(item?.endTime?.split(":")?.[0]);
          let endMins: any = new Number(item?.endTime?.split(":")?.[1]?.split(" ")?.[0]);
          if (item?.startTime?.split(" ")?.[1]?.toLowerCase() === "pm" && stHours < 12) stHours += 12;
          if (item?.endTime?.split(" ")?.[1]?.toLowerCase() === "pm" && endHours < 12) endHours += 12;
          if (stMins === "0") stMins = "00";
          if (endMins === "0") endMins = "00";

          let propDetailsForBestRoute: any = null;
          if (item?.propertyDetails) {
            propDetailsForBestRoute = JSON?.parse(item?.propertyDetails);
          }
          const agentFullName: string = propDetailsForBestRoute?.agent?.firstName + " " + propDetailsForBestRoute?.agent?.lastName;
          if (item?.isAccepted) {
            arr?.push({
              id: item?.bestRouteId,
              title: "Scheduled Property",
              start: new Date(Number(stYear), Number(stmonth), Number(stDay), Number(stHours), Number(stMins), 0),
              end: new Date(Number(eYear), Number(endmonth), Number(endDay), Number(endHours), Number(endMins), 0),
              Stime: item?.startTime,
              Sdate: item?.startDate,
              Etime: item?.endTime,
              Edate: item?.endDate,
              rEdate: item?.recuringEndDate,
              name: propDetailsForBestRoute?.agent?.firstName,
              fullName: propDetailsForBestRoute?.agent?.firstName + " " + propDetailsForBestRoute?.agent?.lastName,
              eventType: "Scheduled Property",
              agentName: "userState?.agent?.name",
              phone: propDetailsForBestRoute?.agent?.contact?.office,
              address: propDetailsForBestRoute?.address?.full,
              streetName: propDetailsForBestRoute?.address?.streetName,
              streetNumber: propDetailsForBestRoute?.address?.streetNumber,
              city: propDetailsForBestRoute?.address?.city,
              state: propDetailsForBestRoute?.address?.state,
              country: propDetailsForBestRoute?.address?.country,
              postalCode: propDetailsForBestRoute?.address?.postalCode,
              pid: propDetailsForBestRoute?.mlsId,
              duration: item?.propertyDuration,
              isAccepted: item?.isAccepted,
              isRejected: item?.isRejected,
              role: getBestRoute?.role,
              listingId: propDetailsForBestRoute?.listingId,
              buyerAgentName: item?.listingAgentName ? item?.listingAgentName : agentFullName ? agentFullName : userState?.buyer?.buyerAgentName,
              client: userState?.buyer?.buyerAgentName,
              clientPhone: userState?.buyer?.buyerAgentPhone,
              buyerGoogleEventSynced: item?.buyerGoogleEventSynced,
              bestRouteId: item?.bestRouteId
            });
          }
        });
      });

      const calendarEvent: any = await getBuyerCalendarEvents(userState?.buyer.buyerId, userState?.buyer.token);

      calendarEvent?.map((item: any) => {
        const stYear: any = new Date(item?.startDate).getFullYear();
        const stmonth: any = new Date(item?.startDate).getMonth();
        const stDay: any = new Date(item?.startDate).getUTCDate();
        // const stDay: any = dayjs(item?.startDate).date();
        let stHours: any = Number(item?.startTime.split(":")[0]);
        let mins: any = Number(item?.startTime.split(":")[1]);
        const endmonth: any = new Date(item?.endDate).getMonth();
        const endDay: any = new Date(item?.endDate).getUTCDate();
        let endHours: any = Number(item?.endTime.split(":")[0]);
        let endmins: any = Number(item?.endTime.split(":")[1]);

        if (item?.startTime?.split(" ")?.[1]?.toLowerCase() === "pm" && stHours < 12) stHours += 12;
        if (item?.endTime?.split(" ")?.[1]?.toLowerCase() === "pm" && endHours < 12) endHours += 12;
        if (mins === "0") mins = "00";
        if (endmins === "0") endmins = "00";

        arr.push({
          id: "Calendar Event",
          title: item?.title ?? "null",

          start: new Date(Number(stYear), Number(stmonth), Number(stDay), Number(stHours), Number(mins), 0),
          end: new Date(Number(stYear), Number(endmonth), Number(endDay), Number(endHours), Number(endmins), 0),
          recurstart: new Date(Number(stYear), Number(stmonth), Number(stDay), Number(stHours), Number(mins), 0),
          recurend: new Date(Number(stYear), Number(endmonth), Number(endDay), Number(endHours), Number(endmins), 0),
          rStatus: item?.recurringStatus,
          eventId: item?.calendarId,
          Stime: item?.startTime,
          Sdate: item?.startDate,
          Etime: item?.endTime,
          Edate: item?.endDate,
          rEdate: item?.recuringEndDate,
          color: colorChange
        });
      });

      const token = userState?.buyer?.token;

      const newTourPropCal: any = await getViewPropertyCalendarByPropID(userState.buyer.sellerListingId, currDate, userState?.buyer?.token, "Buyer");

      newTourPropCal?.map((item: any) => {
        const stYear: any = new Date(item?.startDate).getFullYear(),
          stmonth: any = new Date(item?.startDate).getMonth(),
          // stDay: any = dayjs(item?.startDate).date();
          stDay: any = new Date(item?.startDate).getUTCDate();
        let stHours: any = Number(item?.startTime?.split(":")[0]),
          mins: any = Number(item?.startTime?.split(":")[1]),
          endmonth: any = new Date(item?.endDate).getMonth(),
          endDay: any = new Date(item?.endDate).getUTCDate(),
          endHours: any = Number(item?.endTime?.split(":")[0]),
          endmins: any = Number(item?.endTime?.split(":")[1]);
        if (item?.startTime?.split(" ")?.[1]?.toLowerCase() === "pm" && stHours < 12) stHours += 12;
        if (item?.endTime?.split(" ")?.[1]?.toLowerCase() === "pm" && endHours < 12) endHours += 12;
        if (mins === "0") mins = "00";
        if (endmins === "0") endmins = "00";

        if (Number(stDay) < Number(endDay) && Number(stHours) < Number(endHours)) {
          endDay -= 1;
        }

        let propDetails: any = null;
        if (item?.propertyDetails) {
          propDetails = JSON?.parse(item?.propertyDetails);
        }
        const agentFullName: string = propDetails?.agent?.firstName + " " + propDetails?.agent?.lastName;
        arr.push({
          id: "View Property",
          title: item?.description,
          start: new Date(Number(stYear), Number(stmonth), Number(stDay), Number(stHours), Number(mins), 0),
          end: new Date(Number(stYear), Number(endmonth), Number(endDay), Number(endHours), Number(endmins), 0),
          recurstart: new Date(Number(stYear), Number(stmonth), Number(stDay), Number(stHours), Number(mins), 0),
          recurend: new Date(Number(stYear), Number(endmonth), Number(endDay), Number(endHours), Number(endmins), 0),
          rStatus: item?.recuringStatus,
          eventId: item?.viewPropertyCalendarId,
          Stime: item?.startTime,
          Sdate: item?.startDate,
          Etime: item?.endTime,
          Edate: item?.endDate,
          rEdate: item?.recuringEndDate,
          name: propDetails?.agent?.firstName,
          fullName: propDetails?.agent?.firstName + " " + propDetails?.agent?.lastName,
          eventType: "requestedP",
          agentName: "userState?.agent?.name",
          phone: propDetails?.agent?.contact?.office,
          address: propDetails?.address?.full,
          streetName: propDetails?.address?.streetName,
          streetNumber: propDetails?.address?.streetNumber,
          city: propDetails?.address?.city,
          state: propDetails?.address?.state,
          country: propDetails?.address?.country,
          postalCode: propDetails?.address?.postalCode,
          pid: propDetails?.mlsId,
          color: colorChange,
          type: "Seller Property",
          mlsId: item?.mlsId,
          listingId: propDetails?.listingId,
          isAccepted: item?.isAccepted,
          isRejected: item?.isRejected,
          viewPropertyCalendarId: item?.viewPropertyCalendarId,
          buyerAgentName: item?.listingAgentName ? item?.listingAgentName : agentFullName ? agentFullName : userState?.buyer?.buyerAgentName,
          client: userState?.buyer?.buyerAgentName,
          clientPhone: userState?.buyer?.buyerAgentPhone
        });
      });

      const viewPropertyResult: any = await getViewPropertyCalendarByBuyerID(userState?.buyer?.buyerId, token, "Buyer", "Calendar");
      viewPropertyResult?.map((item: any) => {
        let stYear: any = new Date(item?.startDate).getFullYear(),
          stmonth: any = new Date(item?.startDate).getMonth(),
          stDay: any = new Date(item?.startDate).getUTCDate(),
          stHours: any = Number(item?.startTime?.split(":")[0]),
          mins: any = Number(item?.startTime?.split(":")[1]),
          endmonth: any = new Date(item?.endDate).getMonth(),
          endDay: any = new Date(item?.endDate).getUTCDate(),
          endHours: any = Number(item?.endTime?.split(":")[0]),
          endmins: any = Number(item?.endTime?.split(":")[1]);
        if (item?.startTime?.split(" ")?.[1]?.toLowerCase() === "pm" && stHours < 12) stHours += 12;
        if (item?.endTime?.split(" ")?.[1]?.toLowerCase() === "pm" && endHours < 12) endHours += 12;
        if (mins === "0") mins = "00";
        if (endmins === "0") endmins = "00";
        if (Number(stDay) < Number(endDay) && Number(stHours) < Number(endHours)) {
          endDay -= 1;
        }

        let propDetails: any = null;
        if (item?.propertyDetails) {
          propDetails = JSON?.parse(item?.propertyDetails);
        }
        const agentFullName: string = propDetails?.agent?.firstName + " " + propDetails?.agent?.lastName;
        arr.push({
          id: "View Property",
          title: item?.description,
          start: new Date(Number(stYear), Number(stmonth), Number(stDay), Number(stHours), Number(mins), 0),
          end: new Date(Number(stYear), Number(endmonth), Number(endDay), Number(endHours), Number(endmins), 0),
          recurstart: new Date(Number(stYear), Number(stmonth), Number(stDay), Number(stHours), Number(mins), 0),
          recurend: new Date(Number(stYear), Number(endmonth), Number(endDay), Number(endHours), Number(endmins), 0),
          rStatus: item?.recuringStatus,
          eventId: item?.viewPropertyCalendarId,
          Stime: item?.startTime,
          Sdate: item?.startDate,
          Etime: item?.endTime,
          Edate: item?.endDate,
          rEdate: item?.recuringEndDate,
          name: propDetails?.agent?.firstName,
          fullName: propDetails?.agent?.firstName + " " + propDetails?.agent?.lastName,
          eventType: "requestedP",
          agentName: "userState?.agent?.name",
          phone: propDetails?.agent?.contact?.office,
          address: propDetails?.address?.full,
          streetName: propDetails?.address?.streetName,
          streetNumber: propDetails?.address?.streetNumber,
          city: propDetails?.address?.city,
          state: propDetails?.address?.state,
          country: propDetails?.address?.country,
          postalCode: propDetails?.address?.postalCode,
          pid: propDetails?.mlsId,
          color: colorChange,
          type: "View Property",
          mlsId: item?.mlsId,
          listingId: propDetails?.listingId,
          isAccepted: item?.isAccepted,
          isRejected: item?.isRejected,
          viewPropertyCalendarId: item?.viewPropertyCalendarId,
          buyerAgentName: item?.listingAgentName ? item?.listingAgentName : agentFullName ? agentFullName : userState?.buyer?.buyerAgentName,
          client: userState?.buyer?.buyerAgentName,
          clientPhone: userState?.buyer?.buyerAgentPhone
        });
      });
      setIsLoading(false);
      getNotSyncedEventstoGoogleSync(arr);
      setEventsList(arr);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const calanderModalHandler = () => {
    setIsShowingCalendarModal(!isShowingCalendarModal);
  };

  const handleHoverChange = (visible: boolean) => {
    setHovered(visible);
  };

  const selectedDate = (args: any) => {
    setSdate(args?.start);
    setStime(moment(args?.start).format("HH:mm"));
    setEdate(args?.end);
    setEtime(moment(args?.end).format("HH:mm"));

    setIsShowingCalendarModal(true);
  };

  const getModalData = async (values: any, customStartDate?: any, customEndDate?: any) => {
    const calendarEvents: any = [
      {
        startDate: moment(values?.StartDate).format("MM/DD/YYYY"),
        startTime: moment(values?.StartTime).format("HH:mm:ss A"),
        endDate: moment(values?.EndDate).format("MM/DD/YYYY"),
        endTime: moment(values?.EndTime).format("HH:mm:ss A"),
        title: values?.Description,
        buyerId: userState?.buyer?.buyerId,
        recurringStatus: values?.RecurringStatus,
        recuringStartDate: moment(customStartDate).format("MM/DD/YYYY"),
        recuringEndDate: moment(customEndDate).format("MM/DD/YYYY")
      }
    ];

    const from = customStartDate;
    const to = customEndDate;
    let count: number = 0;

    let changeStatus = "";

    if (values?.RecurringStatus === "Daily") {
      changeStatus = "day";
      count = Math.abs(moment(from, "YYYY-MM-DD").startOf("day").diff(moment(to, "YYYY-MM-DD").startOf("day"), "days")) + 1;

      for (let i = 1; i < count; i++) {
        calendarEvents.push({
          startDate: moment(values?.StartDate).add(i, "days").format("MM/DD/YYYY"),
          startTime: moment(values?.StartTime).format("HH:mm:ss A"),
          endDate: moment(values?.EndDate).add(i, "days").format("MM/DD/YYYY"),
          endTime: moment(values?.EndTime).format("HH:mm:ss A"),
          title: values?.Description,
          buyerId: userState?.buyer?.buyerId,
          recurringStatus: values?.RecurringStatus,
          recuringStartDate: moment(customStartDate).format("MM/DD/YYYY"),
          recuringEndDate: moment(customEndDate).format("MM/DD/YYYY")
        });
      }
    } else if (values?.RecurringStatus === "Weekly") {
      changeStatus = "week";
      count = Math.abs(moment(from, "YYYY-MM-DD").startOf("day").diff(moment(to, "YYYY-MM-DD").startOf("weeks"), "weeks")) + 1;
      for (let i = 1; i < count; i++) {
        calendarEvents.push({
          startDate: moment(values?.StartDate).add(i, "weeks").format("MM/DD/YYYY"),
          startTime: moment(values?.StartTime).format("HH:mm:ss A"),
          endDate: moment(values?.EndDate).add(i, "weeks").format("MM/DD/YYYY"),
          endTime: moment(values?.EndTime).format("HH:mm:ss A"),
          title: values?.Description,
          buyerId: userState?.buyer?.buyerId,
          recurringStatus: values?.RecurringStatus,
          recuringStartDate: moment(customStartDate).format("MM/DD/YYYY"),
          recuringEndDate: moment(customEndDate).format("MM/DD/YYYY")
        });
      }
    } else if (values?.RecurringStatus === "Monthly") {
      changeStatus = "month";
      count = Math.abs(moment(from, "YYYY-MM-DD").startOf("day").diff(moment(to, "YYYY-MM-DD").startOf("day"), "months")) + 1;
      for (let i = 1; i < count; i++) {
        calendarEvents.push({
          startDate: moment(values?.StartDate).add(i, "months").format("MM/DD/YYYY"),
          startTime: moment(values?.StartTime).format("HH:mm:ss A"),
          endDate: moment(values?.EndDate).add(i, "months").format("MM/DD/YYYY"),
          endTime: moment(values?.EndTime).format("HH:mm:ss A"),
          title: values?.Description,
          buyerId: userState?.buyer?.buyerId,
          recurringStatus: values?.RecurringStatus,
          recuringStartDate: moment(customStartDate).format("MM/DD/YYYY"),
          recuringEndDate: moment(customEndDate).format("MM/DD/YYYY")
        });
      }
    } else {
      count = 0;
    }

    setIsLoading(true);
    try {
      if (userState?.userType === UserType?.BUYER) {
        const response: any = await createEventByBuyer(userState?.buyer?.buyerId, userState?.buyer?.token, calendarEvents);
        if (response) {
          setIsLoading(false);
          calenderEvent();
          if (userState?.buyer?.sellerAgentEmail) {
            await inviteAgentByBuyer(
              userState.buyer.sellerAgentId,
              "seller",
              `Seller ${userState?.buyer?.name?.toLocaleUpperCase()} has updated his calendar availability ${changeStatus?.length > 0 ? `occurs every ${changeStatus} starting ${moment(customStartDate).format("MMMM Do, yyyy")}` : `for ${moment(customStartDate)?.format("MMMM Do, yyyy")}`
              }, you may schedule yours accordingly`,
              listingPropertyId,
              "",
              userState?.buyer?.Email,
              userState?.buyer?.buyerId
            );
          }
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console?.log("error", error);
    }
  };

  return (
    <>
      {isLoading && <CustomSpinner />}
      <div style={{ overflow: "hidden" }}>
        <Row justify="space-between">
          <Col span={screen.xl ? 22 : 22} offset={2} className="buyercalander11">
            <div className="calendarButtons" style={{ alignContent: "space-between", marginTop: "5px" }}>
              <div className="calendarButtonLeft">
                <Button
                  className="viewButton"
                  onClick={() => {
                    dayChange("prev");
                    changeValue();
                  }}
                >
                  <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
                </Button>
                <Button
                  className="viewButton"
                  onClick={() => {
                    dayChange("next");
                    changeValue();
                  }}
                >
                  <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                </Button>
                <Button
                  className="viewButton"
                  onClick={() => {
                    dayChange("Today");
                    changeValue();
                  }}
                >
                  Today
                </Button>
              </div>
              <div className="calendarButtonRight">
                <Select value={view} onChange={(e) => changeViewed(e)}>
                  <Option value="timeGridWeek">Week</Option>
                  <Option value="timeGridDay">Day</Option>
                  <Option value="dayGridMonth">Month</Option>
                </Select>
              </div>
            </div>
            <CustomFullCalendarDiv>
              {view && (
                <FullCalendar
                  plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
                  dayCellClassNames={(arg) => {
                    const argDay = new Date(arg?.date).getUTCDate();
                    const argMonth = new Date(arg?.date).getMonth();
                    const ClickDay = new Date(clickedDay).getUTCDate();
                    const ClickMonth = new Date(clickedDay).getMonth();
                    if (argDay === ClickDay && argMonth === ClickMonth) return "redColor";
                  }}
                  nowIndicator={true}
                  headerToolbar={{
                    left: null,
                    center: isCalentitle ? null : "title",
                    end: null
                  }}
                  initialView={view.toString()}
                  ref={calendarRef}
                  events={eventsList}
                  droppable={true}
                  eventContent={(args: any, index: any) => {
                    var theDate = moment(args?.event?._def?.extendedProps?.recurstart).format("YYYY-MM-DD");
                    var startDate = moment(args?.event?._def?.extendedProps?.recurstart).format("YYYY-MM-DD");
                    var endDate = moment(args?.event?._def?.extendedProps?.recurend).format("YYYY-MM-DD");

                    return (
                      <>
                        {args?.event?._def?.publicId === "Calendar Event" && (
                          <div
                            style={{
                              paddingLeft: "6px",
                              background: "#EB752F",
                              borderRadius: "10px",
                              width: "100%"
                            }}
                            onMouseEnter={() => setPopoverId(args?.event?._instance?.range?.start)}
                          >
                            {popOverId === args?.event?._instance?.range?.start ? (
                              <Popover
                                trigger="hover"
                                zIndex={9999999999}
                                onVisibleChange={(v: boolean) => {
                                  handleHoverChange(v);
                                }}
                                overlayInnerStyle={{
                                  border: "1.4px solid #004876",
                                  borderRadius: "10px"
                                }}
                                visible={hovered}
                                content={
                                  <>
                                    <ConsumerCalendarEventPopover args={args} calenderEvent={calenderEvent} isBuyerSide={false} popoverState={setHovered} />
                                  </>
                                }
                                id={index}
                              >
                                <p>{(args?.view?.type).toString() === "dayGridMonth" && <>{args?.event?.title} |</>} Free</p>
                              </Popover>
                            ) : (
                              <p>Free</p>
                            )}
                          </div>
                        )}
                        {args?.event?._def?.title === "Scheduled Property" && (
                          <Popover
                            trigger="hover"
                            overlayInnerStyle={{
                              border: "1.4px solid #004876",
                              borderRadius: "10px"
                            }}
                            content={
                              <>
                                <CalendarRequestPopover getEventDetails={(e: any) => { }} arg={args} />
                              </>
                            }
                          >
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                inset: "50px 0% -300px",
                                backgroundColor: `${args?.event?._def?.extendedProps?.isAccepted ? (args?.event?._def?.extendedProps?.type === "Seller Property" ? "green" : "#C2D500") : "gray"} `,
                                zIndex: 1,
                                padding: "0 1px",
                                whiteSpace: "normal",
                                borderRadius: "10px",
                                overflow: "hidden"
                              }}
                            >
                              <p
                                style={{
                                  color: "#000",
                                  marginTop: "0",
                                  paddingTop: "0",
                                  paddingBottom: "12px",
                                  fontSize: "12px"
                                }}
                              >
                                {args?.event?._def?.extendedProps?.role === "Agent" ? (
                                  <>
                                    {userState?.buyer?.buyerAgentName?.split(" ")?.[0]} {moment(args?.event?.start).format("h:mm A")} S P #{args?.event?._def?.extendedProps?.listingId}
                                  </>
                                ) : (
                                  <>
                                    {args?.event?._def?.extendedProps?.type === "Seller Property" ? (
                                      <>
                                        {moment(args?.event?.start).format("h:mm A")} Seller SP # {args?.event?._def?.extendedProps?.listingId}
                                      </>
                                    ) : (
                                      <>
                                        OH {moment(args?.event?.start).format("h:mm A")} S P #{args?.event?._def?.extendedProps?.listingId}
                                      </>
                                    )}
                                  </>
                                )}
                              </p>
                            </div>
                          </Popover>
                        )}
                        {(args?.event?._def?.publicId).toString() === "Requested" && (
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              inset: "50px 0% -300px",
                              backgroundColor: "#C2D500",
                              zIndex: 1,
                              padding: "0 1px",
                              whiteSpace: "normal",
                              borderRadius: "10px",
                              borderLeft: "1px solid #ccc",
                              borderTop: "1px solid #ccc",
                              overflow: "hidded"
                            }}
                          >
                            <p>{moment(args?.event?.start).format("h:mm A")} | Showings</p>
                          </div>
                        )}
                        {args?.event?._def?.publicId?.toString() === "Upcoming Tours" && (
                          <>
                            {moment(args?.timeText).format("hh:mm:ss A")}
                            Upcoming Tours
                          </>
                        )}
                        {args?.event?._def?.publicId?.toString() === "View Property" && (
                          <Popover
                            trigger="hover"
                            overlayInnerStyle={{
                              border: "1.4px solid green",
                              borderRadius: "10px",
                              width: "342px",
                              height: "fit-content"
                            }}
                            content={
                              <>
                                <CalendarRequestPopover getEventDetails={(e: any) => { }} arg={args} />
                              </>
                            }
                            placement="top"
                          >
                            <div
                              style={{
                                color: "#fff",
                                borderRadius: "6px",
                                borderLeft: "1px solid #ccc",
                                borderTop: "1px solid #ccc",
                                width: "100%",
                                height: "100%",
                                inset: "50px 0% -300px",
                                backgroundColor: args?.event?._def?.extendedProps?.type === "Seller Property" ? "green" : "#004876",
                                zIndex: 1,
                                padding: "0 1px",
                                whiteSpace: "normal",
                                overflow: "hidden"
                              }}
                            >
                              {args?.event?._def?.extendedProps?.type === "Seller Property" ? (
                                <p
                                  style={{
                                    color: "white",
                                    fontSize: "12px",
                                    paddingBottom: "10px"
                                  }}
                                >
                                  {moment(args?.event?.start).format("h:mm A")} | Seller SP #{args?.event?._def?.extendedProps?.listingId}
                                </p>
                              ) : (
                                <p
                                  style={{
                                    color: "white",
                                    fontSize: "12px",
                                    paddingBottom: "10px"
                                  }}
                                >
                                  {moment(args?.event?.start).format("h:mm A")} | S P #{args?.event?._def?.extendedProps?.listingId}
                                </p>
                              )}
                            </div>
                          </Popover>
                        )}
                      </>
                    );
                  }}
                  selectable={true}
                  dayMaxEventRows={false}
                  eventMaxStack={3}
                  eventResizableFromStart={true}
                  select={selectedDate}
                  moreLinkClick="month"
                  dayHeaderContent={(arg) => {
                    return (
                      <>
                        <div
                          style={{
                            margin: "10px !important",
                            padding: "5px !important"
                          }}
                        >
                          {arg?.view?.type !== "dayGridMonth" && (
                            <div>
                              <div
                                style={{
                                  color: "#000000"
                                }}
                              >
                                {setMonthView(false)}
                                {getDayName(new Date(arg?.date)?.getUTCDay())}
                              </div>
                              <div
                                style={{
                                  color: "#000000"
                                }}
                              >
                                {new Date(arg?.date)?.getMonth() + 1}/{new Date(arg?.date).getUTCDate()}
                              </div>
                            </div>
                          )}
                          {arg?.view?.type === "dayGridMonth" && (
                            <div>
                              <div
                                style={{
                                  color: "#000000"
                                }}
                              >
                                {setMonthView(true)}
                                {getDayName(new Date(arg?.date)?.getUTCDay())}
                              </div>
                              <div
                                style={{
                                  color: "#000000"
                                }}
                              ></div>
                            </div>
                          )}
                        </div>
                      </>
                    );
                  }}
                  eventDragStop={(info: any) => {
                    setIsDragModalVisible(true);
                    setCalendarPropertyId(info?.event?._def?.extendedProps?.eventId);
                  }}
                  eventReceive={(info) => { }}
                  allDaySlot={false}
                />
              )}
            </CustomFullCalendarDiv>
          </Col>
        </Row>
      </div>
      {isShowingCalendarModal && (
        <CalendarModal
          isShowing={isShowingCalendarModal}
          Sdate={Sdate}
          Stime={Stime}
          Edate={Edate}
          Etime={Etime}
          closeAction={calanderModalHandler}
          getModalData={getModalData}
          setDaysOfWeek={setDaysOfWeek}
          eventType="create"
          setIsRescheduleRequestEvent={() => { }}
        />
      )}
      {calendarPropertyId && dragInfo && isDragModalVisible === true && (
        <div>
          <Modal centered visible={isDragModalVisible} onOk={handleDragOk} onCancel={handleDragCancel} footer={null} width={"auto"} destroyOnClose={true} maskClosable={false}>
            <DragConfig dragInfo={dragInfo}></DragConfig>
          </Modal>
        </div>
      )}
    </>
  );
};

export default BuyerCalendar;
