import React, { useState } from "react";
import iconUser from "../assets/images/icon-user.svg";
import iconEmail from "../assets/images/icon-email.svg";
import iconPhone from "../assets/images/icon-phone.svg";
import iconPassword from "../assets/images/icon-password.svg";
import iconCompany from "../assets/images/icon-company.svg";
import iconHouse from "../assets/images/icon-house.svg";
import iconLocation from "../assets/images/icon-location.svg";
// import iconNumber from "../assets/images/icon-number.svg";
import iconNumber from "../assets/CalendarPopover/Icon - Number.png";
import iconCalendar from "../assets/images/icon-calendar.svg";
import iconTime from "../assets/images/icon-time.svg";
import close from "../assets/images/close.png";
import quality from "../assets/images/quality (1).png";
import RegisterSaleAgentPage from "../pages/RegisterAgentPage";
import { fromSimplyRetsForMlsId } from "../services/NavihomeService";
import { response } from "express";
export enum TextFieldType {
  DEFAULT = "TEXT_FIELD_DEFAULT",
  SMALL = "TEXT_FIELD_SMALL",
  BIG = "TEXT_FIELD_BIG",
  ICON_AND_LABEL = "TEXT_FIELD_WITH_ICON_AND_LABEL",
  PASSWORD = "TEXT_FIELD_PASSWORD",
  BIGMESSAGE = "BIG_MESSAGE",
  PHONENUMBER = "PHONENUMBER",
}

export enum TextFieldIconType {
  NONE = "ICON_NONE",
  USER = "ICON_USER",
  EMAIL = "ICON_EMAIL",
  PHONE = "ICON_PHONE",
  PASSWORD = "ICON_PASSWORD",
  COMPANY = "ICON_COMPANY",
  HOUSE = "ICON_HOUSE",
  LOCATION = "ICON_LOCATION",
  NUMBER = "ICON_NUMBER",
  CALENDAR = "ICON_CALENDAR",
  TIME = "ICON_TIME",
}

interface TextFieldProps {
  value: string;
  setValue?: (value: string) => void;
  // imageRecieved:(value:any) =>void
  placeholder: string;
  type: TextFieldType;
  label?: string;
  icon?: TextFieldIconType;
  isRequired: boolean;
  width?: number;
  className?: string;
  isDisabled?: boolean;
  setIsverifiedMlsId?: (value: boolean) => void;
  onFocus?: (data: any) => void;
}

const TextField: React.FC<TextFieldProps> = ({
  value,
  setValue,
  placeholder,
  type,
  label,
  icon,
  isRequired,
  width,
  isDisabled,
  className,
  setIsverifiedMlsId,
  onFocus = (data) => {},
}) => {
  const handleOnFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFocus(false);
  };
  const [varifiedimg, setVarifiedimg] = useState<any>(" ");
  const [crossimage, setCrossimage] = useState<any>(" ");
  const [Return, setReturn] = useState<any>();
  const [mlsData, setMlsData] = useState<boolean>(false);
  const [varifiedMls, setVarifiedMls] = useState<any>("");
  const [initial, setInitial] = useState<any>(false);
  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue(event?.target?.value);
    if (label === "MLS ID" && (event?.target?.value).length == 8) {
      const dbresponse:any = await fromSimplyRetsForMlsId(
        String(event?.target?.value)
      );
      if (Object.keys(dbresponse).length > 0) {
        setMlsData(true);
        setValue(dbresponse[0]["id"]);
        setIsverifiedMlsId(true);
      } else if (Object.keys(dbresponse).length == 0) {
        setMlsData(false);
        setInitial(true);
        setIsverifiedMlsId(false);
      }
    }
    if (label === "MLS ID" && (event?.target?.value).length != 8) {
      setMlsData(false);
      // setInitial(true);
    }
    if (label === "MLS ID" && (event?.target?.value).length == 0) {
      setMlsData(false);
      setInitial(false);
    }
  };

  const handleTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setValue(event?.target?.value);
  };

  const renderLabel = () => {
    if (
      type === TextFieldType.ICON_AND_LABEL ||
      type === TextFieldType.BIG ||
      type === TextFieldType.PASSWORD ||
      type === TextFieldType.BIGMESSAGE ||
      type === TextFieldType.PHONENUMBER
    ) {
      return (
        <label className="text-field__label">
          {isRequired ? label + "*" : label}
        </label>
      );
    }
  };

  const renderIcon = () => {
    if (
      type === TextFieldType.ICON_AND_LABEL ||
      type === TextFieldType.BIG ||
      type === TextFieldType.PASSWORD ||
      type === TextFieldType.BIGMESSAGE ||
      type === TextFieldType.PHONENUMBER
    ) {
      let iconSrc = "";
      switch (icon) {
        case TextFieldIconType.USER: {
          iconSrc = iconUser;
          break;
        }
        case TextFieldIconType.EMAIL: {
          iconSrc = iconEmail;
          break;
        }
        case TextFieldIconType.PHONE: {
          iconSrc = iconPhone;
          break;
        }
        case TextFieldIconType.PASSWORD: {
          iconSrc = iconPassword;
          break;
        }
        case TextFieldIconType.COMPANY: {
          iconSrc = iconCompany;
          break;
        }
        case TextFieldIconType.HOUSE: {
          iconSrc = iconHouse;
          break;
        }
        case TextFieldIconType.LOCATION: {
          iconSrc = iconLocation;
          break;
        }
        case TextFieldIconType.NUMBER: {
          iconSrc = iconNumber;
          break;
        }
        case TextFieldIconType.CALENDAR: {
          iconSrc = iconCalendar;
          break;
        }
        case TextFieldIconType.TIME: {
          iconSrc = iconTime;
          break;
        }
        default: {
          break;
        }
      }
      return (
        <img className="text-field__icon" src={iconSrc} alt="TextField Icon" />
      );
    }
  };

  const renderInput = () => {
    let classNames = "text-field__input";
    switch (type) {
      case TextFieldType.SMALL: {
        classNames += " text-field__input--small";
        break;
      }
      case TextFieldType.ICON_AND_LABEL: {
        classNames += " text-field__input--with-icon";
        break;
      }
      case TextFieldType.PASSWORD: {
        classNames += " text-field__input--with-icon";
        break;
      }
      case TextFieldType.PHONENUMBER: {
        classNames += " text-field__input--with-icon";
        break;
      }
      default: {
        break;
      }
    }

    const height = type === TextFieldType.BIG ? "17rem" : "4.8rem";

    return type === TextFieldType.BIG ? (
      <textarea
        value={value}
        onChange={handleTextAreaChange}
        placeholder={placeholder}
        className={classNames}
        required={isRequired}
        style={{
          width: width === undefined ? "100%" : `${width}rem`,
          height: height,
        }}
      />
    ) : (
      <>
        {type === TextFieldType.BIGMESSAGE ? (
          <textarea
            value={value}
            onChange={handleTextAreaChange}
            placeholder={placeholder}
            className={classNames}
            required={isRequired}
            style={{
              width: width === undefined ? "100%" : `${width}rem`,
              height: "8rem",
            }}
          />
        ) : isDisabled ? (
          <input
            type={type === TextFieldType.PASSWORD ? "password" : "text"}
            value={value}
            onChange={handleInputChange}
            onFocus={handleOnFocus}
            placeholder={placeholder}
            className={classNames}
            disabled
            required={isRequired}
            style={{ width: width === undefined ? "100%" : `${width}rem` }}
          />
        ) : (
          <input
            type={type === TextFieldType.PASSWORD ? "password" : "text"}
            value={label === "MLS ID" ? value.toUpperCase() : value}
            maxLength={label === "MLS ID" && 8}
            src={iconCalendar}
            pattern={label === "MLS ID" && "[a-z,A-Z,0-9]+"}
            onChange={handleInputChange}
            onFocus={handleOnFocus}
            placeholder={placeholder}
            className={classNames}
            required={isRequired}
            style={{ width: width === undefined ? "100%" : `${width}rem` }}
          />
        )}

        {mlsData ? (
          <>
            <img
              src={quality}
              alt="calendar"
              style={{
                position: "absolute",
                height: "32px",
                right: "10px",
                top: "32px",
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                color: "green",
              }}
            >
              <p style={{ color: "green" }}>Agent ID Verified</p>
            </div>
          </>
        ) : label === "MLS ID" && initial ? (
          <>
            <img
              src={close}
              alt="close"
              style={{
                position: "absolute",
                height: "32px",
                right: "10px",
                top: "32px",
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                color: "red",
              }}
            >
              <p style={{ color: "red" }}>Agent ID doen't exist</p>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <>
      <div
        className={
          className === undefined ? "text-field" : `text-field ${className}`
        }
      >
        {renderLabel()}
        {renderIcon()}
        {renderInput()}
      </div>
    </>
  );
};

export default TextField;
