import styled from '@emotion/styled'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropertyFiltersContext from '../../contexts/PropertyFiltersContext'
import UserContext, { UserType } from '../../contexts/UserContext'
import {
  getAllupcomingTourDetailsData,
  getViewPropertyCalendarByBuyerID,
} from '../../services/NavihomeService'
import MiniCalendar from '../MiniCalendar'
import SearchField from '../SearchField'
import CustomSpinner from '../spinner/CustomSpinner'
import ToursSummaryBuyerHome from '../TourSummaryBuyerHomePage'

const CustomSearchField = styled(SearchField)`
  margin-top: 3rem;
`
const Calendiv = styled.h3`
  @media screen and (max-width: 327px) {
    &:after {
      display: none;
    }
  }
`
const CustomHeading = styled.h1`
  font-size: 32px;
  @media screen and (max-width: 432px) {
    font-size: 28px;
  }
  @media screen and (max-width: 283px) {
    font-size: 24px;
  }
`

const BuyerHomePage = () => {
  const [upcomingTourDetail, setUpcomingTourDetail] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const { userState } = useContext(UserContext)
  const history = useHistory()
  const { setQuery } = useContext(PropertyFiltersContext)
  const [upcomingStartEndDate, setUpcomingStartEndDate] = useState<any>({
    startDate: null,
    endDate: null,
  })
  const [upcomingTourDate, setUpcomingTourDate] = useState<any>(null)

  const searchAction = (query: string) => {
    setQuery(query)
    history.push('/properties?query=' + query)
  }

  const getUpcomingTour = async () => {
    setIsLoading(true)
    try {
      const token = userState?.buyer?.token
      const buyerId = userState?.buyer?.buyerId

      if (!token) {
        setIsLoading(false)
        console.error('Unauthorized', 'error')
      }
      if (!buyerId) {
        setIsLoading(false)
        return
      }

      let response: any
      let newArr: any = []
      let propertyCalc: any
      setIsLoading(true)

      response = await getAllupcomingTourDetailsData(
        buyerId,
        token,
        'Buyer',
        'Dashboard',
        "Not Scheduled Showings"
      )

      propertyCalc = await getViewPropertyCalendarByBuyerID(
        buyerId,
        token,
        'Buyer',
        'Dashboard',
      )

      newArr = [...propertyCalc, ...response]

      // use filtered and response data to represent on table
      let upcomingTour = []
      if (newArr?.length > 0) {
        for (let i = 0; i < newArr?.length; i++) {
          // debugger
          let startTime: any,
            endTime: any,
            currHourTime: any,
            propCalCurrZone: any,
            propCalArea: null,
            bestRouteArea: null,
            acceptedPropertyCount = 0
          if (newArr[i]?.formId) {
            currHourTime = newArr[i]?.time?.split('-')?.[0]?.split(' ')?.[1]

            if (newArr[i]?.bestRouteProperties?.[0]?.propertyDetails) {
              bestRouteArea = JSON?.parse(
                newArr[i]?.bestRouteProperties?.[0]?.propertyDetails,
              )?.address?.city
            }

            if (newArr[i]?.bestRouteProperties?.length > 0) {
              newArr?.[i]?.bestRouteProperties?.forEach((element: any) => {
                if (element?.isAccepted) {
                  acceptedPropertyCount++
                }
              })
            }
          }

          if (newArr[i]?.viewPropertyCalendarId) {
            startTime = newArr[i]?.startTime
            endTime = newArr[i]?.endTime
            startTime = moment(startTime, 'HH:mm').format('hh:mm a')
            endTime = moment(endTime, 'HH:mm').format('hh:mm a')
            propCalCurrZone = startTime?.split(' ')?.[1]
            propCalArea = JSON.parse(newArr[i]?.propertyDetails)?.address?.city
          }
          let date: any,
            dayOfWeek,
            time,
            timeOfDay,
            area,
            properties,
            statusPrimary,
            statusSecondary,
            formId,
            res;
          let correctDate = newArr[i]?.formId
            ? moment.tz(newArr[i]?.dateTime, moment.tz.guess()).utc()
            : moment.tz(newArr[i]?.startDate, moment.tz.guess()).utc();

          date = moment(correctDate, "MM-DD-YYYY").format("LL");
          dayOfWeek = moment(correctDate).format("dddd");

          time = newArr[i]?.formId ? newArr[i]?.time : `${startTime}-${endTime}`
          timeOfDay = newArr[i]?.formId
            ? currHourTime === 'am'
              ? 'Morning'
              : 'Evening'
            : propCalCurrZone === 'am'
              ? 'Morning'
              : 'Evening'

          area = newArr[i]?.formId
            ? bestRouteArea ?? 'N/A'
            : propCalArea ?? 'N/A'
          properties = newArr[i]?.formId ? newArr[i]?.selectedProperties : '1'

          statusPrimary = newArr[i]?.formId
            ? `${acceptedPropertyCount}/${newArr[i]?.selectedProperties} ${newArr[i]?.status}`
            : '1/1 Scheduled'
          statusSecondary = newArr[i]?.formId
            ? userState?.userType === UserType.AGENT
              ? `Agent ${newArr[i]?.status}`
              : `Buyer ${newArr[i]?.status} OH`
            : `Accepted`

          formId = newArr[i]?.formId ? newArr[i]?.formId : null
          res = newArr[i]
          const newDataObj = {
            date: date,
            dayOfWeek: dayOfWeek,
            time: time,
            timeOfDay: timeOfDay,
            area: area,
            properties: properties,
            statusPrimary: newArr[i]?.viewPropertyCalendarId
              ? 'Accepted'
              : newArr[i]?.status === 'Scheduled'
                ? statusPrimary
                : 'Saved',
            statusSecondary: newArr[i]?.viewPropertyCalendarId
              ? null
              : newArr[i]?.role === 'Agent'
                ? 'Agent Scheduled'
                : statusSecondary,
            formId: formId,
            responseData: res,
            createdAt: newArr[i]?.formId
              ? moment(newArr[i]?.dateTime).format('LL')
              : moment(newArr[i]?.startDate),
          }

          //  upcomingTour.push(dataobj);
          upcomingTour.push(newDataObj)
        }
      }
      const sortedProperties: any = upcomingTour?.sort((a: any, b: any) => {
        return +new Date(a?.createdAt) - +new Date(b?.createdAt)
      })
      // setUpcomingTourDetail(upcomingTour);
      setUpcomingTourDetail(sortedProperties)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.error('error', err)
    }
  }

  useEffect(() => {
    getUpcomingTour()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="buyer-home-page">
      {isLoading && <CustomSpinner />}

      {/* first div for image and text on that  image */}
      <div className="buyer-home-page-cover">
        <div className="buyer-home-page-cover-content">
          <h2>Discover your dream home</h2>
          <CustomSearchField
            placeholder="Enter neighbourhood, city, ZIP code"
            searchAction={searchAction}
          />
        </div>
      </div>

      {/* second div with 2 col */}

      <div className="buyer-home-page__container">
        <div className="buyer-home-page__calendar-container">
          <Calendiv>My Calendar</Calendiv>
          <div
            className="buyer-home-page__calendar-card"
            style={{ marginTop: '20px' }}
          >
            <MiniCalendar
              setUpcomingTourDate={setUpcomingTourDate}
              setUpcomingStartEndDate={setUpcomingStartEndDate}
              month={''}
            />
          </div>
        </div>
        <div className="buyer-home-page__upcoming-tours-container">
          <CustomHeading>
            Upcoming{' '}
            <span style={{ borderBottom: '3px solid #C2D500' }}>Schedule</span>
          </CustomHeading>
          <div
            className="buyer-home-page__upcoming-tours-container-table"
            style={{ marginTop: '20px' }}
          >
            <ToursSummaryBuyerHome
              tours={upcomingTourDetail}
              isBuyerLinked={userState?.buyer?.isLinked}
              upcomingTourDate={upcomingTourDate}
              upcomingStartEndDate={upcomingStartEndDate}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BuyerHomePage
