import React, { useState } from 'react';
import Button, { ButtonType } from '../../components/Button';
import { useHistory } from 'react-router-dom';
import globalimage from '../../assets/find-an-agent-page/Group 1641.png';
import GrouPImage from '../../assets/find-an-agent-page/Group 1404.png';
import styled from '@emotion/styled';
import ForgotPasswordModal from '../../modals/forgotPasswordModal';
import LogInModal from '../../modals/LoginModal';

const CustomDiv = styled.div`
  // background-color:yellow;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;
const CustomLeftDiv = styled.div`
  align-self: center;
  padding-right: 20px;
  @media screen and (max-width: 600px) {
    padding-right: 0;
  }
`;
const CustomRightDiv = styled.div`
  @media screen and (max-width: 600px) {
    padding: 16px;
    display: flex;
    width: 100%;
    justify-content: center;
  }
`;
const CustomImage = styled.img`
  width: 450px;
  height: 450px;
  @media screen and (max-width: 860px) {
    width: 350px;
    height: 350px;
  }
  @media screen and (max-width: 600px) {
    width: 450px;
    height: 450px;
  }
  @media screen and (max-width: 500px) {
    width: 390px;
    height: 390px;
  }
  @media screen and (max-width: 400px) {
    width: 280px;
    height: 280px;
  }
`;
const CustomDashboardHeading = styled.h1`
  font-size: 46px;
  font-weight: 600px;
`;
interface EmptyDashboardTabPropsModel {
  title?: string;
  isButton?: boolean;
  buttonText?: string;
  isHome?: boolean;
  isLoginSell?: boolean;
  isSell?: boolean;
  isPreSell?: boolean;
  isLoggedInSell?: boolean;
  isBuyerLinked?: boolean;
}
const EmptyDashboardTab: React.FC<EmptyDashboardTabPropsModel> = ({
  title,
  buttonText,
  isButton,
  isHome,
  isPreSell,
  isLoggedInSell,
  isBuyerLinked,
}): JSX.Element => {
  const history = useHistory();

  const [isShowingLoginModal, setIsShowingLoginModal] = useState(false);
  const [isShowingForgotPasswordModal, setIsShowingForgotPasswordModal] =
    useState(false);


  const handleCloseLoginModal = () => {
    setIsShowingLoginModal(!isShowingLoginModal);
  };
  const handleCloseForgotPasswordModal = () => {
    setIsShowingForgotPasswordModal(!isShowingForgotPasswordModal);
  };
  const gotoSignIn = () => {
    setIsShowingLoginModal(!isShowingLoginModal);
  };
  const gotoNotifications = () => {
    history.push('/notifications');
  };
  return (
    <CustomDiv className="empty-dashboard-page">
      <CustomLeftDiv>
        <p>{title}</p>
        <br />
        <br />
        {isHome && <CustomDashboardHeading>CONTENT TBD</CustomDashboardHeading>}
        {isButton && isPreSell && (
          <Button
            type={ButtonType.PRIMARY}
            text={buttonText}
            clickHandler={gotoSignIn}
          />
        )}
        {isButton && isLoggedInSell && (
          <Button
            type={ButtonType.PRIMARY}
            text={buttonText}
            clickHandler={gotoNotifications}
          />
        )}
      </CustomLeftDiv>
      <CustomRightDiv>
        {isHome ? (
          <CustomImage src={GrouPImage} alt="image with a key" />
        ) : (
          <CustomImage src={globalimage} alt="image with a key" />
        )}
      </CustomRightDiv>
      {isShowingLoginModal && (
        <LogInModal
          isShowing={isShowingLoginModal}
          closeAction={handleCloseLoginModal}
          setIsShowingForgotPasswordModal={setIsShowingForgotPasswordModal}
        />
      )}
      {isShowingForgotPasswordModal && (
        <ForgotPasswordModal
          isShowing={isShowingForgotPasswordModal}
          closeAction={handleCloseForgotPasswordModal}
          setIsShowingLoginModal={setIsShowingLoginModal}
        />
      )}
    </CustomDiv>
  );
};

export default EmptyDashboardTab;
