import { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from 'antd';
import type { TableRowSelection } from 'antd/lib/table/interface';
import type { ColumnsType } from 'antd/lib/table';
import AgentContext from '../contexts/AgentContext';
import UserContext, { UserType } from '../contexts/UserContext';
import moment from 'moment';

interface TourSummaryBuyerHomeItemData {
    date: string;
    dayOfWeek: string;
    time: string;
    timeOfDay: string;
    area: string;
    properties: number;
    statusPrimary: string;
    statusSecondary: string;
    formId: number;
    responseData?: any;
  }

  interface ToursSummaryBuyerHomeProps {
    tours: TourSummaryBuyerHomeItemData[];
    isAgentSide?: boolean;
    isBuyerLinked?: boolean;
    upcomingTourDate?: any;
    source?: string;
    upcomingStartEndDate?: any;
  }

  interface DataType {
    key: React.Key;
    date: any;
    time: any;
    Area: string;
    properties: string;
    status: any;
  }
  const ToursSummaryBuyerHome: React.FC<ToursSummaryBuyerHomeProps> = ({
    tours,
    source,
    upcomingStartEndDate,
  }) => {
    const columns: ColumnsType<DataType> = [
      {
        title: 'Date',
        dataIndex: 'date',
        render: (_, { date }) => (
          <div>
            <div>{date?.date}</div>
            <div>{date?.dayOfWeek}</div>
          </div>
        ),
      },
      {
        title: 'Time',
        dataIndex: 'time',
        render: (_, { time }) => (
          <div>
            <div>{time?.time}</div>
            <div>{time?.timeOfDay}</div>
          </div>
        ),
      },
    
      {
        title: 'Status',
        dataIndex: 'status',
        render: (_, { status }) => (
          <div>
            <div>{status?.statusPrimary}</div>
            <div>{status?.statusSecondary}</div>
          </div>
        ),
      },
    ];
  
    // const data: DataType[] = [];
    const [data, setData]: any = useState();
    const [defaultData, setDefaultData]: any = useState();
    const { userState } = useContext(UserContext);
    const { selectBuyer } = useContext(AgentContext);
    const history = useHistory();
    const handleBuyerTourDetail = (event: any) => {
  
      // if (
      //   userState?.userType === UserType.BUYER &&
      //   event?.responseData?.role === 'Agent'
      // ) {
      //   return;
      // }
      //todo jb agent accept krega per property agr 4 select ki and 4 accept hui tb niche wali cond me add hoga  and usko route pe bhejnge
      let buyerId = -1;
      let name = '',  phone= "", email= "", preferredContact= "";
      let routeStatus = '';
      buyerId = event?.responseData?.buyerId
        ? event?.responseData?.buyerId
        : event?.responseData?.buyer?.buyerId;
      name = event?.responseData?.buyer?.name;
      phone = event?.responseData?.buyer?.phone;
      email = event?.responseData?.buyer?.email;
      preferredContact = event?.responseData?.buyer?.preferredContact;
      routeStatus = event?.responseData?.status;
      const propertyCount = event?.status?.statusPrimary.split('/');
      const acceptedPropertyCount = propertyCount?.[0];
      const totalPropertyCount = propertyCount?.[1]?.split(' ')?.[0];
  
      selectBuyer(buyerId, name,phone, email, preferredContact);
   
      if (!event?.formId && event?.responseData?.viewPropertyCalendarId) {
        let propId = event?.responseData?.mlsId;
        history.push(`/properties/${propId}`);
        return;
      }
  
      if (source === 'previousTour') {
        let buyerIdNew = event?.responseData?.buyerId;
        let formId = event?.formId;
        history.push(`/tourdetail?buyerId=${buyerIdNew}&formId=${formId}`);
        return;
        // history.push(`/tourdetail?buyerId=${buyerIdNew}&formId=${formId}`);
      } else if (acceptedPropertyCount === totalPropertyCount) {
        history.push(
          `/common?buyerId=${buyerId}&formId=${event?.formId}&statusType=${event?.responseData?.saveTourStatus}&routeStatus=${routeStatus}&allAccepted=allAccepted&role=${event?.responseData?.role}`
        );
      } else {
        history.push(
          `/common?buyerId=${buyerId}&formId=${event?.formId}&statusType=${event?.responseData?.saveTourStatus}&routeStatus=${routeStatus}&allAccepted=notAllAccepted&role=${event?.responseData?.role}`
        );
      }
    };
    useEffect(() => {
      if (upcomingStartEndDate?.startDate !== null) {
        setDefaultData([]);
        if (tours?.length > 0) {
          let finalArr: any = [];
          tours.map((item, i) => {
            let bool2 =
              moment(item?.date).isBetween(
                upcomingStartEndDate?.startDate,
                upcomingStartEndDate?.endDate
              ) ||
              moment(item?.date).isSame(upcomingStartEndDate?.startDate) ||
              moment(item?.date).isSame(upcomingStartEndDate?.endDate);
            if (bool2) {
              finalArr.push({
                key: i,
                date: { date: item?.date, dayOfWeek: item?.dayOfWeek },
                time: { time: item?.time, timeOfDay: item?.timeOfDay },
                area: item?.area,
                // properties: `${item?.properties} properties`,
                 properties:String(item?.properties)==='1' ? `${item?.properties} property`:`${item?.properties} properties`,
                status: {
                  statusPrimary: item?.statusPrimary,
                  statusSecondary: item?.statusSecondary,
                },
                formId: item?.formId,
                responseData: item?.responseData,
              });
            }
            return finalArr;
          });
  
          setData(finalArr);
        }
      }
    }, [tours, upcomingStartEndDate]);
  
    useEffect(() => {
      if (tours?.length > 0) {
        let finalArr: any = [];
        // we have to render future or current data
        let currDate = moment().format('LL').toString();
        tours.forEach((item, i) => {
          let bool2 = moment(item?.date).isSameOrAfter(currDate);
          if (bool2) {
            finalArr.push({
              key: i,
              date: { date: item?.date, dayOfWeek: item?.dayOfWeek },
              time: { time: item?.time, timeOfDay: item?.timeOfDay },
              area: item?.area,
              // properties: `${item?.properties} properties`,
              properties:String(item?.properties)==='1' ? `${item?.properties} property`:`${item?.properties} properties`,
              status: {
                statusPrimary: item?.statusPrimary,
                statusSecondary: item?.statusSecondary,
              },
              formId: item?.formId,
              responseData: item?.responseData,
            });
          }
        });
  
        setDefaultData(finalArr);
      }
    }, [tours]);
  
    return (
      <div style={{ width: '100%', padding: '0px 10px' }}>
        <Table
          onRow={(record) => {
            return {
              onClick: (event: any) => {
                handleBuyerTourDetail(record);
              },
            };
          }}
          style={{ width: '100% !important', cursor: 'pointer' }}
          // rowSelection={rowSelection}
          columns={columns}
          dataSource={data?.length > 0 ? data : defaultData}
          pagination={{ defaultCurrent: 1, pageSize: 5 }}
        />
      </div>
    );
  };

  export default ToursSummaryBuyerHome;