interface PriceFieldProps {
  value: number;
  setValue: (value: number) => void;
  placeholder: string;
  className?: string;
}

const PriceField: React.FC<PriceFieldProps> = ({ value, setValue, placeholder, className }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[^0-9]+/g, '');
    let numberValue = parseInt(rawValue);
    if (isNaN(numberValue)) {
      setValue(0);
      return;
    }
    if (numberValue > 99999999) {
      numberValue = Math.trunc(numberValue / 10);
    }
    setValue(Math.min(numberValue, 99999999));
  }

  return (
    <div className={className === undefined ? "text-field" : `text-field ${className}}`}>
      <input
        type="text"
        value={value === 0 ? "" : `$ ${value}`}
        onChange={handleChange}
        placeholder={placeholder}
        className={"text-field__input text-field__input--small"} />
    </div>
  );
};

export default PriceField;