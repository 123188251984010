import { ReactChild } from 'react';

export enum ButtonType {
  PRIMARY = 'BUTTON_PRIMARY',
  REGISTER_BUTTON = 'REGISTER_BUTTON',
  PRIMARY_NARROW = 'BUTTON_PRIMARY_NARROW',
  SECONDARY = 'BUTTON_SECONDARY',
  WHITE = 'BUTTON_WHITE',
  WHITE_NARROW = 'BUTTON_WHITE_NARROW',
  WHITE_SMALL = 'BUTTON_WHITE_SMALL',
  HEADER = 'BUTTON_HEADER',
  BROWN_SMALL = 'BUTTON_BROWN_SMALL',
  BLUE_BUTTON = 'BUTTON_BLUE_SMALL',
  ORANGE_BUTTON = 'BUTTON_ORANGE',
  GREEN_BORDER_BUTTON = 'GREEN_BORDER_BUTTON',
  SIGN_ME_UP = 'SIGN_ME_UP',
  SIGN_ME_UP2 = 'SIGN_ME_UP2',
  SCHEDULE_BUTTON = 'BUTTON_SCHEDULE_TOUR',
  SAVE_AND_UPDATE = 'SAVE_AND_UPDATE',
  FIND_AN_AGENT = 'FIND_AN_AGENT',
}

interface ButtonProps {
  type: ButtonType;
  text?: string;
  clickHandler: () => void;
  className?: string;
  width?: number | string;
  icon?: ReactChild;
  disable?: boolean;
  isDisabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  type,
  text,
  clickHandler,
  className,
  width,
  icon,
  isDisabled,
  disable = true,
}) => {
  let mainClassName = '';
  switch (type) {
    case ButtonType.PRIMARY:
      mainClassName = 'button--primary';
      break;
    case ButtonType.REGISTER_BUTTON:
      mainClassName = 'register--button';
      break;
    case ButtonType.PRIMARY_NARROW:
      mainClassName = 'button--primary-narrow';
      break;
    case ButtonType.GREEN_BORDER_BUTTON:
      mainClassName = 'green--border--button';
      break;
    case ButtonType.SECONDARY:
      mainClassName = 'button--secondary';
      break;
    case ButtonType.WHITE:
      mainClassName = 'button--white';
      break;
    case ButtonType.WHITE_NARROW:
      mainClassName = 'button--white-narrow';
      break;
    case ButtonType.WHITE_SMALL:
      mainClassName = 'button--white-small';
      break;
    case ButtonType.HEADER:
      mainClassName = 'button--header';
      break;
    case ButtonType.BROWN_SMALL:
      mainClassName = 'brown--white-small';
      break;
    case ButtonType.BLUE_BUTTON:
      mainClassName = 'blue-button-small';
      break;
    case ButtonType.ORANGE_BUTTON:
      mainClassName = 'orange-button';
      break;
    case ButtonType.SIGN_ME_UP:
      mainClassName = 'sign-me-up';
      break;
    case ButtonType.SIGN_ME_UP2:
      mainClassName = 'sign-me-up2';
      break;
    case ButtonType.SAVE_AND_UPDATE:
      mainClassName = 'save-and-update';
      break;
    case ButtonType.FIND_AN_AGENT:
      mainClassName = 'find-an-agent';
      break;
    default:
      mainClassName = '';
      break;
  }

  return (
    <div
      className={
        className === undefined
          ? `button ${mainClassName}`
          : `button ${mainClassName} ${className}`
      }
      onClick={clickHandler}
      style={
        width !== undefined
          ? {
            width: `${width}rem`,
            pointerEvents:
              (disable && type === ButtonType.REGISTER_BUTTON) || isDisabled ? 'none' : 'auto',
            backgroundColor:
              (disable && type === ButtonType.REGISTER_BUTTON) || isDisabled ? '#74a0bc' : '',
          }
          : {
            pointerEvents:
              (disable && type === ButtonType.REGISTER_BUTTON) || isDisabled ? 'none' : 'auto',
            backgroundColor:
              (disable && type === ButtonType.REGISTER_BUTTON) || isDisabled ? '#74a0bc' : '',
          }
      }
    >
      <p>{text || icon}</p>
    </div>
  );
};

export default Button;
