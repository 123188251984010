import React, { useContext, useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useHistory, useLocation } from "react-router-dom";
import { useGlobal } from "reactn";
import TextField, {
  TextFieldIconType,
  TextFieldType,
} from "../components/TextField";

import styled from "@emotion/styled";
import { Col, Modal, Row, Select } from "antd";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import iconLocation from "../assets/images/icon-location.svg";
import iconTime from "../assets/images/icon-time.svg";
import Button, { ButtonType } from "../components/Button";
import { notification } from "../components/notification";
import UserContext, { UserType } from "../contexts/UserContext";
import CalendarUi from "../modals/CalendarComponent/CalendarUi";
import { getBuyerAddressInfoService } from "../services/NavihomeService";
import CustomSpinner from "./spinner/CustomSpinner";

export enum Tabs {
  HOME = "home",
  OFFICE = "office",
  OTHER = "other",
}

const Input: any = styled.input`
  background-size: 20px;
  padding-left: 30px;
  width: 100%;
  height: 4.8rem;
  border-radius: 1rem;
  font-size: 15px;
  font-weight: bold;
  padding-left: 4rem;
  padding-right: 0.2rem;
  box-shadow: 0rem 0.3rem 0.6rem rgba(0, 0, 0, 0.2);
  border-radius: 0.8rem;
  border: 0.1rem solid #365073;
  vertical-align: top;
`;

const CustomSelect = styled(Select)`
  -webkit-appearance: none;
  padding-left: 4.3rem;
  font-size: 1.3rem;
  font-weight: 500;
  width: 100%;
  height: 4.8rem;
  box-shadow: 0rem 0.3rem 0.6rem var(--color-shadow);
  margin: 8px 0;
  display: inline-block;
  border: 0.1rem solid var(--color-primary);
  border-radius: 0.8rem;
  box-sizing: border-box;
  vertical-align: top;
  &:focus {
    outline: none;
  }
`;

const CustomSelectCol = styled(Col)`
  .ant-select-selector {
    height: 46px !important;
    margin-left: -7px;
    align-items: center;
    padding: 0 !important;
    border: none !important;
  }
  .ant-select-selection-item {
    font-size: 15px !important;
    font-weight: bold !important;
  }
  &&.:focus {
    outline: none !important;
  }
`;

const CustomImage = styled.img`
  position: absolute;
  top: 4rem;
  left: 2rem;
`;
interface ITourDetailsFormProps {
  setFormData: any;
  setFormDataEdited: any;
  setStartLocation: any;
  setEndLocation: any;
  setStartTimeForTimeStamp: any;
  setSaveTourClicked: (val: string) => void;
  setScheduleStatus: (val: boolean) => void;
  setIsFormDataChanged: (value: boolean) => void;
  setIsOutTimeProperty: (value: boolean) => void;
  setEliminatePropertyFromTheTour: (value: number) => void;
  setFilteredLinkedProperties: (value: Array<any>) => void;
  setLinkedPropertiesData: (value: Array<any>) => void;
  DateTime?: string;
  TimePerProperty?: string;
  StartAdd?: string;
  StartCityZip?: string;
  EndAdd?: string;
  EndCityZip?: string;
  className?: string;
  formData?: any;
  formDataEdited?: any;
  buyerIdForAddress?: any;
  agentName?: string;
  buyerName?: string;
  buyerSideAgentId?: any;
  saveButtonData?: any;
  isFormDataChanged?: boolean;
  linkedPropertiesData?: Array<any>;
  filteredLinkedProperties?: Array<any>;
  selected?: string;
  bestRoute?: Array<any>;
  useManual?: boolean;

  handleTogglingClicked?: string;
  isShowSchedulignStatusModal?: boolean;
}

const TourDetailsForm = ({
  setFormData,
  formData,
  isFormDataChanged,
  setStartLocation,
  setEndLocation,
  buyerIdForAddress,
  selected,
  agentName,
  buyerName,
  saveButtonData,
  buyerSideAgentId,
  setSaveTourClicked,
  setIsFormDataChanged,
  linkedPropertiesData,
  setStartTimeForTimeStamp,
  setIsOutTimeProperty,
  setScheduleStatus,
  formDataEdited,
  setFormDataEdited,
  isShowSchedulignStatusModal,
  setEliminatePropertyFromTheTour,
}: ITourDetailsFormProps) => {
  const history = useHistory();
  const [isFormItemClicked, setIsFormItemClicked] = useState<string>("NO");
  const [dateTime, setDateTime] = useState<any>(
    isFormDataChanged ? formDataEdited?.dateTime : formData?.dateTime || ""
  );
  const [timePerProp, setTimePerProp] = useState<any>(
    isFormDataChanged
      ? formDataEdited?.timePerProp
      : formData?.timePerProp || "Select"
  );
  const [startAdd, setStartAdd] = useState<any>(formData?.startAdd || "");
  const [startAddCity, setStartAddCity] = useState<any>(
    formData?.startAddCity || ""
  );
  const [endingAdd, setEndingAdd] = useState<any>(formData?.endingAdd || "");
  const [endingAddCity, setEndingAddCity] = useState<any>(
    formData?.endingAddCity || ""
  );
  const [selectedProperties, setSelectedProperties] = useState<any>("");
  const area="";
  const propertyStatus="";
  const userId="";
  const [buyerGlobalId, setBuyerGlobalId] = useGlobal<any>("buyerId");
  const [tourCalModal, setTourCalModal] = useState<any>(false);
  const [activeTab, setActiveTab] = useState<Tabs | null>(
    formData?.activeTab || null
  );
  const [activeTabOherButton, setActiveTabOherButton] = useState<Tabs | null>(
    formData?.activeTabOherButton || null
  );

  const [startrouteLocation, setStartRouteLocation] =
    useGlobal<any>("startroutedetail");
  const [endrouteLocation, setEndRouteLocation] =
    useGlobal<any>("endroutedetail");
  const [startAddOnChange, setStartAddOnChange] =
    useGlobal<any>("startAddOnChange");
  const [endAddOnChange, setEndAddOnChange] = useGlobal<any>("endAddOnChange");
  const [dateTimeChange, setDateTimeChange] = useGlobal<any>("dateTimeChange");
  const [timePerPropChange, setTimePerPropChange] =
    useGlobal<any>("timePerPropChange");
  const [startAddLatLng, setStartAddLatLng] = useState({
    lat: null,
    lng: null,
  });
  const [endAddLatLng, setEndAddLatLng] = useState({
    lat: null,
    lng: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState<any>({
    homeCityAndZip: "",
    homeStreetAddress: "",
    officeCityAndZip: "",
    officeStreetAddress: "",
  });
  const [isNotEditable, setIsNotEditable] = useState<boolean>(false);
  const { userState } = useContext(UserContext);
  const isCard = useMediaQuery({ maxWidth: 991 });

  const handleHomeAddress = async () => {
    setSaveTourClicked("No")
    setActiveTab(Tabs.HOME);
    setFormData((item: any) => {
      return { ...item, activeTab: Tabs.HOME };
    });
    setStartAdd(address?.homeStreetAddress);
    setStartAddCity(address?.homeCityAndZip);
  };

  const handleOfficeAddress = () => {
    setSaveTourClicked("No")
    setActiveTab(Tabs.OFFICE);
    setFormData((item: any) => {
      return { ...item, activeTab: Tabs.OFFICE };
    });
    setStartAdd(address?.officeStreetAddress);
    setStartAddCity(address?.officeCityAndZip);
  };

  const handleOtherAddress = () => {
    setSaveTourClicked("No")
    setActiveTab(Tabs.OTHER);
    setFormData((item: any) => {
      return { ...item, activeTab: Tabs.OTHER };
    });
    setStartAdd("");
    setStartAddCity("");
  };

  const handleHomeAddressEndButtons = () => {
    setSaveTourClicked("No")
    setActiveTabOherButton(Tabs.HOME);
    setFormData((item: any) => {
      return { ...item, activeTabOherButton: Tabs.HOME };
    });
    setEndingAdd(address?.homeStreetAddress);
    setEndingAddCity(address?.homeCityAndZip);
  };

  const handleOfficeAddressEndButtons = () => {
    setSaveTourClicked("No")
    setActiveTabOherButton(Tabs.OFFICE);
    setFormData((item: any) => {
      return { ...item, activeTabOherButton: Tabs.OFFICE };
    });
    setEndingAdd(address?.officeStreetAddress);
    setEndingAddCity(address?.officeCityAndZip);
  };

  const handleOtherAddressEndButtons = () => {
    setSaveTourClicked("No")
    setActiveTabOherButton(Tabs.OTHER);
    setFormData((item: any) => {
      return { ...item, activeTabOherButton: Tabs.OTHER };
    });
    setEndingAdd("");
    setEndingAddCity("");
  };

  const handleToggling = () => {
    setIsFormItemClicked("Date and Time");
    setTourCalModal(!tourCalModal);

  };

  const handleOk = () => {
    setTourCalModal(false);
  };

  const handleCancel = () => {
    setTourCalModal(false);
  };

  useEffect(() => {
    setIsLoading(true);
    let token: any = "";
    let role: any = param.get("role") ?? "";

    if (userState?.userType === UserType.AGENT) {
      if (role === "Buyer") setIsNotEditable(true);
      token = userState?.agent?.token;
    } else {
      if (role === "Agent") setIsNotEditable(true);
      token = userState?.buyer?.token;
    }
    try {
      getBuyerAddressInfoService(token, buyerIdForAddress).then((res: any) => {
        setIsLoading(false);
        setAddress({
          homeCityAndZip: res?.homeCityAndZip || "",
          homeStreetAddress: res?.homeStreetAddress || "",
          officeCityAndZip: res?.officeCityAndZip || "",
          officeStreetAddress: res?.officeStreetAddress || "",
        });
      });
    } catch (error) {
      setIsLoading(false);
      console.log("ERROR", error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let date = moment(saveButtonData?.dateTime).format("MM-DD-YYYY");
    if (isFormItemClicked !== "NO") {
      setScheduleStatus(true);
      setIsFormDataChanged(true);
      setSaveTourClicked("No")
    }

    setFormData({
      dateTime: dateTime || date + "," + saveButtonData?.time,
      timePerProp: timePerProp || saveButtonData?.timePerProperty,
      startAdd: startAdd || saveButtonData?.startAdd,
      startAddCity: startAddCity || saveButtonData?.startAddCity,
      endingAdd: endingAdd || saveButtonData?.endingAdd,
      endingAddCity: endingAddCity || saveButtonData?.endingAddCity,
      selectedProperties: selectedProperties || null,
      area: area || null,
      propertyStatus: propertyStatus || null,
      agentId:
        userState?.userType === UserType.AGENT
          ? userState?.agent?.agentId
          : buyerSideAgentId || null,
      id: userId || null,
      activeTab: activeTab || null,
      activeTabOherButton: activeTabOherButton || null,
      startrouteLocation: startAddLatLng || saveButtonData?.startrouteLocation,
      endrouteLocation: endAddLatLng || saveButtonData?.endrouteLocation,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateTime,
    timePerProp,
    startAdd,
    startAddCity,
    endingAdd,
    endingAddCity,
    userId,
    startrouteLocation,
    endrouteLocation,
    startAddLatLng,
    endAddLatLng,
  ]);

  useEffect(() => {
    if (saveButtonData && Object.keys(saveButtonData)?.length > 0) {
      let date = moment
        .tz(saveButtonData?.dateTime, moment.tz.guess())
        .utc()
        .format("MM-DD-YYYY");
      setSaveTourClicked("No")
      setFormData({
        dateTime: dateTime || date + "," + saveButtonData?.time,
        timePerProp: timePerProp || saveButtonData?.timePerProperty,
        startAdd: startAdd || saveButtonData?.startAdd,
        startAddCity: startAddCity || saveButtonData?.startAddCity,
        endingAdd: endingAdd || saveButtonData?.endingAdd,
        endingAddCity: endingAddCity || saveButtonData?.endingAddCity,
        selectedProperties: selectedProperties || null,
        area: area || null,
        propertyStatus: propertyStatus || null,
        agentId:
          userState?.userType === UserType.AGENT
            ? userState?.agent?.agentId
            : buyerSideAgentId || null,
        id: userId || null,
        activeTab: activeTab || saveButtonData?.firstActiveTab,
        activeTabOherButton:
          activeTabOherButton || saveButtonData?.secondActiveTab,
        startrouteLocation: saveButtonData?.startrouteLocation
          ? saveButtonData?.startrouteLocation
          : startrouteLocation
          ? startrouteLocation
          : null,
        endrouteLocation: saveButtonData?.endrouteLocation
          ? saveButtonData?.endrouteLocation
          : endrouteLocation
          ? endrouteLocation
          : null,
      });
      if (!isFormDataChanged) {
        setDateTime(date + "," + saveButtonData?.time);
        setTimePerProp(saveButtonData?.timePerProperty);
      }

      setStartAdd(saveButtonData?.startAdd);
      setStartAddCity(saveButtonData?.startAddCity);
      setEndingAdd(saveButtonData?.endingAdd);
      setEndingAddCity(saveButtonData?.endingAddCity);
      setActiveTab(saveButtonData?.firstActiveTab);
      setActiveTabOherButton(saveButtonData?.secondActiveTab);
      setStartAddLatLng(saveButtonData?.startrouteLocation);
      setEndAddLatLng(saveButtonData?.endrouteLocation);
      // setActiveTab(saveButtonData.)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveButtonData, selected]);

  const StartAddHandleChange = (value: any) => {
    setStartAdd(value);
    setIsFormItemClicked("Starting Address");
    setFormDataEdited({ ...formDataEdited, startAdd: value });
  };

  const StartAddHandleSelect = async (value: any) => {
    const results = await geocodeByAddress(value);
    let localVal = "";
    results[0]?.address_components.find((item: any) => {
      if (item.types.includes("locality")) {
        localVal += item?.long_name;
      }
      if (item?.types?.includes("administrative_area_level_1")) {
        localVal += "," + item?.long_name;
      }
    });
    setStartAddCity(localVal);
    const LatLng = await getLatLng(results[0]);
    setStartAddLatLng(LatLng);

    setStartAdd(value);
    setStartLocation({
      lat: LatLng?.lat ?? null,
      lng: LatLng?.lng ?? null,
    });
    setStartRouteLocation({
      lat: LatLng?.lat ?? null,
      lng: LatLng?.lng ?? null,
    });
    
    // setStartAddOnChange(value)
  };

  const EndAddHandleChange = (value: any) => {
    setEndingAdd(value);
    setIsFormItemClicked("Ending Address");
    setFormDataEdited({ ...formData, endingAdd: value });
  };

  const EndAddHandleSelect = async (value: any) => {
    const results = await geocodeByAddress(value);
    let localVal = "";
    results[0]?.address_components.find((item: any) => {
      if (item?.types?.includes("locality")) {
        localVal += item?.long_name;
      }

      if (item?.types?.includes("administrative_area_level_1")) {
        localVal += "," + item?.long_name;
      }
    });
    setEndingAddCity(localVal);
    const LatLng = await getLatLng(results[0]);
    setEndAddLatLng(LatLng);
    setEndingAdd(value);
    setEndLocation({
      lat: LatLng?.lat ?? null,
      lng: LatLng?.lng ?? null,
    });
    setEndRouteLocation({
      lat: LatLng?.lat ?? null,
      lng: LatLng?.lng ?? null,
    });
    // setEndAddOnChange(value)
  };
  const getALLLinkedPropertiesForBuyer = async (buyerId: any) => {
    let token = "";
    if (userState?.userType === UserType.AGENT) {
      token = userState?.agent?.token;
    } else {
      token = userState?.buyer?.token;
    }
    try {
      if (!buyerId) {
        return;
      }
      if (!token) {
        return;
      }
      if (linkedPropertiesData?.length <= 0) {
        console.error(" properties not found");
        return;
      }
      setSelectedProperties(linkedPropertiesData?.length);
      
    } catch (err) {
      return notification("something went wrong ", "error");
    }
  };

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let param = useQuery();

  useEffect(() => {
    if (buyerGlobalId) {
      localStorage.setItem("buyerGlobalIdForMap", buyerGlobalId);
    }
    const buyerId = localStorage.getItem("buyerGlobalIdForMap");
    getALLLinkedPropertiesForBuyer(buyerGlobalId ? buyerGlobalId : buyerId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerGlobalId]);

  useEffect(() => {
    // const date = dateTime?.split(",")[0];
    // const start = dateTime?.split(",").pop();
    // const startTime = start?.split("-")[0];
    // const endTime = dateTime?.split("-").pop();
    // const now = moment(date + " " + startTime);
    // const end = moment(date + " " + endTime);
    // const duration = moment.duration(now.diff(end), "minutes");
    // const durationFix = String(duration)?.split("T").pop();
    // let totalTime = Number(durationFix.slice(0, -4)) - 30;
    const start = formData?.dateTime?.split(",").pop();
    const startTime = start?.split("-")[0];
    const endTime = formData?.dateTime?.split("-").pop();
    const startTimeTemp = moment(startTime, 'hh:mm A');
    const endTimeTemp = moment(endTime, 'hh:mm A');
    const minutesDifference = endTimeTemp.diff(startTimeTemp, 'minutes');
    let totalTime = Number(minutesDifference) - 30;
    const tempTimePerProp: number = Number(timePerProp?.split(" ")?.[0]);
    let inTimeProperty: any = [],
      outTimeProperty: any = [];
    linkedPropertiesData?.forEach((property: any) => {
      if (totalTime >= 0) {
        inTimeProperty.push(property);
      } else {
        outTimeProperty.push(property);
      }
      totalTime -= Math.floor(Math.random() * 9) + 12 + tempTimePerProp;
    });

    if (
      outTimeProperty?.length > 0 &&
      outTimeProperty?.length < linkedPropertiesData?.length
    ) {
      setEliminatePropertyFromTheTour(outTimeProperty?.length ?? 0);
    } else {
      setIsOutTimeProperty(false);
      setEliminatePropertyFromTheTour(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateTime, timePerProp]);

  const renderTourDetailsForm = () => {
    return (
      <>
        {isLoading && <CustomSpinner />}
        {tourCalModal && (
          <Modal
            visible={tourCalModal}
            onOk={handleOk}
            onCancel={handleCancel}
            width={1100}
            bodyStyle={{ height: isCard ? undefined : 650, padding: "20px" }}
            footer={false}>
            <CalendarUi
              agentName={agentName}
              buyerName={buyerName}
              setDateTime={setDateTime}
              setFormDataEdited={setFormDataEdited}
              formDataEdited={formDataEdited}
              setTourCalModal={setTourCalModal}
              setStartTimeForTimeStamp={setStartTimeForTimeStamp}
            />
          </Modal>
        )}
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <TextField
              value={
                history?.location?.pathname === "/googlemap"
                  ? dateTimeChange
                  : dateTime
              }
              setValue={() => {}}
              placeholder={dateTime ? null : "Enter date and time"}
              onFocus={handleToggling}
              label="Date and Time"
              type={TextFieldType.ICON_AND_LABEL}
              icon={TextFieldIconType.CALENDAR}
              isRequired={true}
              className="tour_detail_form__date_time w-100"
              isDisabled={
                history?.location?.pathname === "/googlemap" || isNotEditable
                  ? true
                  : false
              }
            />
          </Col>
          <CustomSelectCol xs={24}>

            <label htmlFor="timePerProp">Viewing time per property:</label>
            <img
              className="dropDown-field__icon"
              src={iconTime}
              alt="TextField Icon"
            />

            <CustomSelect
              value={
                history?.location?.pathname === "/googlemap"
                  ? timePerPropChange
                  : timePerProp
              }
              onChange={(e: any) => {
                setTimePerProp(e);
                setIsFormItemClicked("Viewing Time Per Property");
                setFormDataEdited({ ...formData, timePerProp: e });
              }}
              id="timePerProp"
              placeholder="Enter Time"
              disabled={
                history?.location?.pathname === "/googlemap" || isNotEditable
                  ? true
                  : false
              }
              bordered={false}
              dropdownAlign={{ offset: [-36, 4] }}>
              <Select.Option value="5 min">5 min</Select.Option>
              <Select.Option value="10 min">10 min</Select.Option>
              <Select.Option value="15 min">15 min</Select.Option>
              <Select.Option value="20 min">20 min</Select.Option>
              <Select.Option value="25 min">25 min</Select.Option>
              <Select.Option value="30 min">30 min</Select.Option>
              <Select.Option value="35 min">35 min</Select.Option>
              <Select.Option value="40 min">40 min</Select.Option>
              <Select.Option value="45 min">45 min</Select.Option>
              <Select.Option value="50 min">50 min</Select.Option>
              <Select.Option value="60 min">1 hr</Select.Option>
              <Select.Option value="120 min">2 hr</Select.Option>
              <Select.Option value="180 min">3 hr</Select.Option>
              <Select.Option value="240 min">4 hr</Select.Option>
              <Select.Option value="300 min">5 hr</Select.Option>
            </CustomSelect>
          </CustomSelectCol>
          <Col xs={24}>
            <PlacesAutocomplete
              value={
                history?.location?.pathname === "/googlemap"
                  ? startAddOnChange
                  : startAdd
              }
              onChange={StartAddHandleChange}
              onSelect={StartAddHandleSelect}>
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div style={{ position: "relative" }}>
                  <label> Starting Address</label>
                  <CustomImage src={iconLocation} alt="location" />
                  <Input
                    {...getInputProps({
                      placeholder: "Enter Address",
                      disabled:
                        history?.location?.pathname === "/googlemap" ||
                        isNotEditable // || startInputDisable
                          ? true
                          : false,
                    })}
                  />
                  <div>
                    {loading && <div>Loading....</div>}
                    {suggestions.map((suggestion) => {
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#d4d4d4",
                            cursor: "pointer",
                            border: "1px solid black",
                            borderRadius: "5px",
                          }
                        : {
                            backgroundColor: "#fff",
                            cursor: "pointer",
                            borderBottom: "1px solid #d4d4d4",
                          };
                      return (
                        <div {...getSuggestionItemProps(suggestion, { style })}>
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Col>
          <Col xs={24}>
            <TextField
              value={startAddCity}
              placeholder="City and State"
              label=""
              type={TextFieldType.DEFAULT}
              icon={TextFieldIconType.LOCATION}
              isRequired={false}
              isDisabled={true}
              className="register-buyer-page__form-item w-100"
            />
          </Col>
          <Col xs={24}>
            <div className="label_for_location_buttons">
              <Button
                type={
                  activeTab === Tabs.HOME
                    ? ButtonType.SECONDARY
                    : ButtonType.WHITE
                }
                text="HOME"
                clickHandler={handleHomeAddress}
                className="tour_detail_form__time_button"
              />
              <Button
                type={
                  activeTab === Tabs.OFFICE
                    ? ButtonType.SECONDARY
                    : ButtonType.WHITE
                }
                text="OFFICE"
                clickHandler={handleOfficeAddress}
                className="tour_detail_form__time_button"
              />
              <Button
                type={
                  activeTab === Tabs.OTHER
                    ? ButtonType.SECONDARY
                    : ButtonType.WHITE
                }
                text="OTHER"
                clickHandler={handleOtherAddress}
                className="tour_detail_form__time_button"
              />
            </div>
          </Col>
          <Col xs={24}>
            <PlacesAutocomplete
              value={
                history?.location?.pathname === "/googlemap"
                  ? endAddOnChange
                  : endingAdd
              }
              onChange={EndAddHandleChange}
              onSelect={EndAddHandleSelect}>
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <label> Ending Address</label>

                  <CustomImage src={iconLocation} alt="location" />
                  <Input
                    {...getInputProps({
                      placeholder: "Enter Address",
                      disabled:
                        history?.location?.pathname === "/googlemap" ||
                        isNotEditable // || endInputDisable
                          ? true
                          : false,
                    })}
                  />
                  <div>
                    {loading && <div>Loading....</div>}
                    {suggestions.map((suggestion) => {
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#d4d4d4",
                            cursor: "pointer",
                            border: "1px solid black",
                            borderRadius: "5px",
                          }
                        : {
                            backgroundColor: "#fff",
                            cursor: "pointer",
                            borderBottom: "1px solid #d4d4d4",
                          };
                      return (
                        <div {...getSuggestionItemProps(suggestion, { style })}>
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Col>
          <Col xs={24}>
            <TextField
              value={endingAddCity}
              placeholder="City and State"
              label=""
              type={TextFieldType.DEFAULT}
              icon={TextFieldIconType.NONE}
              isRequired={false}
              isDisabled={true}
              className="register-buyer-page__form-item w-100"
            />
          </Col>
          <Col xs={24}>
            <div className="label_for_location_buttons">
              <Button
                type={
                  activeTabOherButton === Tabs.HOME
                    ? ButtonType.SECONDARY
                    : ButtonType.WHITE
                }
                text="HOME"
                clickHandler={handleHomeAddressEndButtons}
                className="tour_detail_form__time_button"
              />
              <Button
                type={
                  activeTabOherButton === Tabs.OFFICE
                    ? ButtonType.SECONDARY
                    : ButtonType.WHITE
                }
                text="OFFICE"
                clickHandler={handleOfficeAddressEndButtons}
                className="tour_detail_form__time_button"
              />
              <Button
                type={
                  activeTabOherButton === Tabs.OTHER
                    ? ButtonType.SECONDARY
                    : ButtonType.WHITE
                }
                text="OTHER"
                clickHandler={handleOtherAddressEndButtons}
                className="tour_detail_form__time_button"
              />
            </div>
          </Col>
        </Row>
      </>
    );
  };
  return (
    <div className="tour_details_form" style={{ marginBottom: "10rem" }}>
      {renderTourDetailsForm()}
    </div>
  );
};

export default TourDetailsForm;
// scriptLoader([
//   `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_GOOGLE_MAP_API}&libraries=places`,
// ])
