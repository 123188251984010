import styled from '@emotion/styled';
import { Popconfirm } from 'antd';
import { Fragment, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import deleteButton from '../assets/images/button-delete.svg';
import PropertyFiltersContext from '../contexts/PropertyFiltersContext';
import { SavedSearch } from '../services/NavihomeService';
import { parsePriceToString } from '../utils/parsePriceToString';

const CustomSpan = styled.span`
  background: #eb752f;
  border-radius: 14px;
  padding: 4px 8px;
  color: #fff;
`;
const CustomDiv = styled.div`
  width: 100%;
  word-break: break-all;
`;

export interface SavedSearchCardProps {
  data: SavedSearch;
  buyerId?: number;
  searchedId: number;
  deleteSavedSearch: (savedSearchId: number) => void;
}

const SavedSearchCard: React.FC<SavedSearchCardProps> = ({
  data,
  buyerId,
  searchedId,
  deleteSavedSearch,
}) => {
  const isSm = useMediaQuery({ maxWidth: 600 });

  const deleteThisSavedSearch = () => {
    deleteSavedSearch(data.savedSearchId);
  };

  const {
    setMinPrice,
    setMaxPrice,
    setBeds,
    setBaths,
    setHalfBaths,
    setCity,
    setState,
    setQuery,
    setMinSquareFootage,
    setMaxSquareFootage,
    setMinLotSize,
    setMaxLotSize,
    setMinYearBuilt,
    setMaxYearBuilt,
    setHeatingCooling,
    setMinYearConverted,
    setMaxYearConverted,
    setGarages,
    setParkingSpaces,
    setOffStreetParking,
    setPropertyType,
  } = useContext(PropertyFiltersContext);

  const history = useHistory();

  const goToPropertySaveSearches = (searchedId: number) => {
    if (data?.minPrice) {
      setMinPrice(data?.minPrice);
    }

    if (data?.maxPrice) {
      setMaxPrice(data?.maxPrice);
    }

    if (data?.beds) {
      setBeds(data?.beds);
    }

    if (data?.baths) {
      setBaths(data?.baths);
    }

    if (data?.halfBaths) {
      setHalfBaths(data?.halfBaths);
    }

    if (data?.city) {
      setCity(data?.city);
    }

    if (data?.state) {
      setState(data?.state);
    }

    if (data?.query) {
      setQuery(data?.query);
    }

    if (data?.minSquareFootage > 0) {
      setMinSquareFootage(data?.minSquareFootage);
    }

    if (data?.maxSquareFootage > 0) {

      setMaxSquareFootage(data?.maxSquareFootage);
    }

    if (data?.minLotSize > 0) {
      setMinLotSize(data?.minLotSize);
    }

    if (data?.maxLotSize > 0) {
      setMaxLotSize(data?.maxLotSize);
    }

    if (data?.minYearBuilt > 0) {
      setMinYearBuilt(data?.minYearBuilt);
    }

    if (data?.maxYearBuilt > 0) {
      setMaxYearBuilt(data?.maxYearBuilt);
    }

    if (data?.minYearConverted) {
      setMinYearConverted(data?.minYearConverted);
    }

    if (data?.maxYearConverted) {
      setMaxYearConverted(data?.maxYearConverted);
    }

    if (data?.heatingCooling) {
      setHeatingCooling(data?.heatingCooling);
    }

    if (data?.garages) {
      setGarages(data?.garages);
    }

    if (data?.parkingSpaces) {
      setParkingSpaces(data?.parkingSpaces);
    }

    if (data?.offStreetParking) {
      setOffStreetParking(data?.offStreetParking);
    }

    if (data?.propertyType) {
      setPropertyType(data?.propertyType);
    }

    // history.push(`/properties?searchedId=${searchedId}`+ '=' + buyerId);
    history.push(
      `/properties?searchedId=${searchedId}&type=${data?.sortType}&filter=yes&buyerDashboard=saved&buyerId=${buyerId}`
    );
  };

  const renderInfo = () => {
    const {
      minPrice,
      maxPrice,
      beds,
      baths,
      halfBaths,
      minSquareFootage,
      maxSquareFootage,
      minLotSize,
      minYearBuilt,
      garages,
      parkingSpaces,
      offStreetParking,
      propertyType,
      heatingCooling,
      minYearConverted,
    } = data;

    var info1 = `${minPrice ? `$${parsePriceToString(minPrice)}` : 'No Min Price'
      } - $${maxPrice ? parsePriceToString(maxPrice) : 'No Max Price'}, ${beds ? `0 - ${beds}` : 'N/A'
      } bed, ${beds ? `0 - ${baths}` : 'N/A'} bath, ${halfBaths ? `${halfBaths}` : 'N/A'
      } half-bath, ${propertyType?.length > 0
        ? `${propertyType.split('&type=')?.map((item) => item)}`
        : ''
      }`;

    var info2 = `${minSquareFootage > 0 ? minSquareFootage : ''} - ${maxSquareFootage > 0 ? `${maxSquareFootage} Sqft` : 'N/A Area'
      }, ${minLotSize > 0
        ? `Lot Size > ${minLotSize} ${minLotSize > 100 ? 'Sqft' : 'acres'}`
        : 'N/A Lot size'
      }, ${minYearBuilt > 0 ? `Built >${minYearBuilt}` : 'N/A Built Yr'}, ${minYearBuilt > 0 ? `Converted >${minYearConverted}` : 'N/A Converted Yr'
      }, ${heatingCooling?.length > 0 ? `${heatingCooling}` : 'N/A Heating/Cooling'
      }`;
    var info3 = `${parkingSpaces ? parkingSpaces : 'N/A'} Parking, ${offStreetParking ? offStreetParking : 'N/A'
      } Street Parking,  ${garages ? garages : 'N/A'} Garage`;

    return (
      <Fragment>
        <p>{info1}</p>
        <p>{info2}</p>
        <p>{info3}</p>
      </Fragment>
    );
  };

  return (
    <div
      className="saved-search-card"
      onClick={() => {
        goToPropertySaveSearches(searchedId);
      }}
    >
      <CustomDiv className="saved-search-card__info" >
        <h4 style={{ fontWeight: 'bold', color: '#004876' }}>
          {data?.city}{' '}
          {data?.sortType === 'open_house' && (
            <CustomSpan>Open House</CustomSpan>
          )}{' '}
        </h4>
        {renderInfo()}
      </CustomDiv>
      {!isSm && (
        <>
          <div className="saved-search-card__buttons">
          </div>
          <div
            className="saved-search-card__alerts"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Popconfirm
              title="Are you sure to delete this save search?"
              onConfirm={(e) => {
                e.stopPropagation();
                deleteThisSavedSearch();
              }}
              onCancel={(e) => e.stopPropagation()}
              okText="Yes"
              cancelText="No"
            >
              <img
                src={deleteButton}
                alt="Delete Button"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </Popconfirm>
          </div>
        </>
      )}
      {isSm && (
        <>
          <div className="saved-search-card__buttons">

            <img
              src={deleteButton}
              alt="Delete Button"
              onClick={deleteThisSavedSearch}
            />

          </div>
        </>
      )}
    </div>
  );
};

export default SavedSearchCard;
