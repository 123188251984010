import { Col, Row } from 'antd';
import React, { useContext, useState } from 'react';
import UserContext from '../../../contexts/UserContext';
import {
  inviteAgentByBuyer,
  updateAgentStatusService
} from '../../../services/NavihomeService';
import Button, { ButtonType } from '../../Button';
import './styles/calendarPopover.css';

import moment from 'moment';
import Location from '../../../assets/find-an-agent-page/Icon - Location.svg';
import User from '../../../assets/find-an-agent-page/Icon - User.svg';
import Path from '../../../assets/find-an-agent-page/Icon link.svg';
import CustomSpinner from '../../spinner/CustomSpinner';

interface CalendarRequestPopoverProps {
  arg: any;
  getEventDetails?: (val: any) => void;
  listingAgentId?: number;
  buyerId?: number;
}
const CalendarRequestPopover: React.FunctionComponent<
  CalendarRequestPopoverProps
> = ({ getEventDetails, arg, listingAgentId, buyerId }) => {
  const link = process.env.REACT_APP_PROPERTY_URL_LOCAL;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const baseUrl = process.env.REACT_APP_BASE_URL;
  const { extendedProps } = arg?.event?._def;
  const { userState } = useContext(UserContext);
  const buttonClickedHandler = async () => {
    setIsLoading(true);
    try {
      await updateAgentStatusService(extendedProps?.eventId, false, true);
      await inviteAgentByBuyer(
        listingAgentId,
        'rejected',
        `${userState?.agent?.name?.toUpperCase()} buyer agent has cancelled their request to view ${extendedProps?.address}
        ${extendedProps?.city}, ${extendedProps?.state}
        ${extendedProps?.postalCode} | MLS #${extendedProps?.listingId
        } on ${moment(arg?.event?._instance?.range?.start).format(
          'MMMM Do, yyyy'
        )} from ${moment(arg?.event?.start).format('h:mm A')} -
        ${moment(arg?.event?.end).format('hh:mm A')}`,
        Number(extendedProps?.mlsId),
        '',
        '',
        buyerId
      );
      await inviteAgentByBuyer(
        userState?.agent?.agentId,
        'rejected',
        `You have cancelled your showing request to view ${extendedProps?.address}
        ${extendedProps?.city}, ${extendedProps?.state}
        ${extendedProps?.postalCode} | MLS #${extendedProps?.listingId
        } on ${moment(arg?.event?._instance?.range?.start).format(
          'MMMM Do, yyyy'
        )} from ${moment(arg?.event?.start).format('h:mm A')} -
        ${moment(arg?.event?.end).format('hh:mm A')}`,
        Number(extendedProps?.mlsId),
        '',
        '',
        buyerId
      );

      // getEventDetails();
      setIsLoading(false);
      getEventDetails(extendedProps?.eventId);
    } catch (error) {
      console.log('ERROR:++++++ ', error);
      setIsLoading(false);
    }
  };
  return (
    <>
      {isLoading && <CustomSpinner />}
      <div className="popoverContainer">
        {extendedProps?.eventType === 'Scheduled Property' ? (
          <div className="popoverTitle">
            {'SCHEDULED'}: {moment(arg?.event?.start).format('h:mm A')} |{' '}
            {extendedProps?.client?.length > 0 ? extendedProps?.client : ""}{" "}
            {extendedProps?.phone?.length > 0 ? `(${extendedProps?.clientPhone}) ` : ""}
            {extendedProps?.address?.length > 0
              ? extendedProps?.address
              : 'N/A'}
            , {extendedProps?.city} |<div> MLS #{extendedProps?.listingId}</div>
          </div>
        ) : (
          <div className="popoverTitle">
            {extendedProps?.isAccepted ? 'ACCEPTED' : 'REQUESTED'}:{' '}
            {moment(arg?.event?.start).format('h:mm A')} |{' '}
            {extendedProps?.client?.length > 0 ? extendedProps?.client : ""}{" "}
            {extendedProps?.phone?.length > 0 ? `(${extendedProps?.clientPhone})` : ""}
            {extendedProps?.address?.length > 0
              ? extendedProps?.address
              : 'N/A'}
            , {extendedProps?.city} |<div> MLS #{extendedProps?.listingId}</div>
          </div>
        )}

        <div className="popoverDate">
          {moment(arg?.event?.start).format('Do MMMM')} &bull;{' '}
          {moment(arg?.event?.start).format('h:mm A')} -{' '}
          {moment(arg?.event?.end).format('hh:mm A')}
        </div>
        <div className="infoCont">
          <div className="infoIcon">
            <img src={User} alt="..." />
          </div>
          <div className="agentText">
            {extendedProps?.buyerAgentName ? extendedProps?.buyerAgentName : "N/A"}
          </div>
        </div>
        <div className="infoCont">
          <div className="infoIcon">
            <img src={Location} alt="..." />
          </div>
          <div className="infoText">
            {extendedProps?.address} <br />
            {extendedProps?.city}, {extendedProps?.state}{' '}
            {extendedProps?.postalCode}
          </div>
        </div>
        <div className="infoCont">
          <div className="infoIcon">
            <img src={Path} alt="..." />
          </div>
          <div className="infoText link url-container">
            <a href={link + '/properties/' + extendedProps?.pid}>
              {link + '/properties/' + extendedProps?.pid}
            </a>
          </div>
        </div>

        {extendedProps?.type === 'Requested' &&
          !extendedProps?.isAccepted &&
          !extendedProps?.isRejected && (
            <Row
              justify="center"
              style={{
                marginTop: '12px',
                position: 'relative',
                // right: "2rem",
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Col style={{ justifyContent: 'center' }}>
                {userState?.agent?.agentId === extendedProps?.agentId && (
                  <Button
                    type={ButtonType.PRIMARY_NARROW}
                    text="CANCEL REQUEST"
                    clickHandler={buttonClickedHandler}
                  />
                )}
              </Col>
            </Row>
          )}
      </div>
    </>
  );
};

export default CalendarRequestPopover;
