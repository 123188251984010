/* eslint-disable use-isnan */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import styled from "@emotion/styled";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid"; // a plugin!
import { Popover, Select } from "antd";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import CustomSpinner from "../../../components/spinner/CustomSpinner";
import UserContext, { UserType } from "../../../contexts/UserContext";
import CalendarModal from "../../../modals/CalendarModal";
import { getAgentAccountSettings, getBuyerCalendarEvents, postViewPropertyCalendar, updateViewPropertyCalendar } from "../../../services/NavihomeService";
import CalendarSchedulePopover from "../../AgentCalendar/calendarPopovers/calendarSchdeulePopover";
import ConsumerCalendarEventPopover from "../../AgentCalendar/calendarPopovers/consumerCalendarEventPopovers";
import PropertyCalendarPopoverContent from "../../AgentCalendar/calendarPopovers/propertyCalendarPopoverContent";
import { getAllViewPropertyByAgetntId } from "../../AgentCalendar/ulits";
import CalendarAvailabilityModal from "./calendarModals/calendarAvailabilityModal";
import CustomRecurrenceModal from "./calendarModals/customRecurrenceModal";


const { Option } = Select;

const ScrollDiv = styled.div`
  .fc {
    height: 80vh;
    width: 100%;
    overflow-y: auto;
  }
  .fc-popover {
    z-index: 100;
  }
`;

interface CalendarMainProps {
  sellerName: string;
  sellerId: number;
  propMlsId: number;
}

const CalendarMain: React.FC<CalendarMainProps> = ({ sellerName, sellerId, propMlsId }): JSX.Element => {
  const { userState } = useContext(UserContext);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [values, setValues] = useState({
    date1: "",
    time1: "",
    date2: "",
    time2: "",
    recurringStatus: "",
    description: ""
  });
  const isMobile = useMediaQuery({ maxWidth: 850 });
  const isCalentitle = useMediaQuery({ maxWidth: 652 });
  const [isNew, setIsNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [events, setEvents] = useState<Array<any>>([]);
  let [view, setView] = useState("timeGridWeek");
  const [isAvailablePopoverVisible, setIsAvailablePopoverVisible] = useState(false);
  const [eventFilter, setEventFilter] = useState<Array<any>>([]);
  const [isRescheduleRequestEvent, setIsRescheduleRequestEvent] = useState<boolean>(false);
  const [recievedArg, setRecievedArg] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [Stime, setStime] = useState<any>();
  const [Sdate, setSdate] = useState<Date>(new Date());
  const [Etime, setEtime] = useState<any>();
  const [Edate, setEdate] = useState<Date>(new Date());
  const [SViewTime, setSViewTime] = useState<any>();
  const [SViewDate, setSViewDate] = useState<any>();
  const [EViewTime, setEViewTime] = useState<any>();
  const [EViewDate, setEViewDate] = useState<any>();
  const [viewTittle, setViewTittle] = useState<string>("");
  const [viewRStatus, setViewRStatus] = useState<string>("");
  const [viewEventId, setViewEventId] = useState<number>(0);
  const [viewREdate, setViewREdate] = useState<Date>(new Date());

  const [isShowingViewCalendarModal, setIsShowingViewCalendarModal] = useState<boolean>(false);
  let calendarRef = useRef(null);
  const history = useHistory();

  const getModalData = async (values: any, customStartDate?: any, customEndDate?: any, type?: string, eventId?: number, buyerAgentId?: number, listingBuyerId?: number, updateSender?: string) => {
    const mlsId = history?.location?.pathname?.split("/")?.[2];

    const calendarEvents: any = [
      {
        agentId: userState?.agent?.agentId,
        mlsId: parseInt(mlsId) ?? null,
        startDate: moment(values?.StartDate).format("MM/DD/YYYY"),
        endDate: moment(values?.EndDate).format("MM/DD/YYYY"),
        startTime: moment(values?.StartTime).format("HH:mm:ss A"),
        endTime: moment(values?.EndTime).format("HH:mm:ss A"),
        recuringStatus: values?.RecurringStatus,
        description: values?.Description,
        buyerId: listingBuyerId ? listingBuyerId : sellerId ? sellerId : 1,
        isAccepted: false,
        recuringStartDate: moment(customStartDate).utc().format("MM/DD/YYYY"),
        recuringEndDate: moment(customEndDate).utc().format("MM/DD/YYYY"),
        type: "Available",
        sender: "agent",
        isRejected: false,
        propertyDetails: "6",
        agentAgentId: userState?.agent?.agentId,
        buyerBuyerId: listingBuyerId ? listingBuyerId : sellerId ? sellerId : 1,
        client: "",
        listingAgentName: userState?.agent?.name ?? ""
      }
    ];

    const from = customStartDate;
    const to = customEndDate;
    let count: number = 0;

    if (values?.RecurringStatus === "Daily") {
      count = Math.abs(moment(from, "YYYY-MM-DD").startOf("day").diff(moment(to, "YYYY-MM-DD").startOf("day"), "days")) + 1;

      for (let i = 1; i < count; i++) {
        calendarEvents.push({
          agentId: userState?.agent?.agentId,
          mlsId: parseInt(mlsId) ?? null,
          startDate: moment(values?.StartDate).add(i, "days").format("MM/DD/YYYY"),
          endDate: moment(values?.EndDate).add(i, "days").format("MM/DD/YYYY"),
          startTime: moment(values?.StartTime).format("HH:mm:ss A"),
          endTime: moment(values?.EndTime).format("HH:mm:ss A"),
          recuringStatus: values?.RecurringStatus,
          description: values?.Description,
          buyerId: listingBuyerId ? listingBuyerId : sellerId ? sellerId : 1,
          isAccepted: false,
          recuringStartDate: moment(customStartDate).utc().format("MM/DD/YYYY"),
          recuringEndDate: moment(customEndDate).utc().format("MM/DD/YYYY"),
          type: "Available",
          sender: "agent",
          isRejected: false,
          propertyDetails: "7",
          agentAgentId: userState?.agent?.agentId,
          buyerBuyerId: listingBuyerId ? listingBuyerId : sellerId ? sellerId : 1,
          client: "",
          listingAgentName: userState?.agent?.name ?? ""
        });
      }
    } else if (values?.RecurringStatus === "Weekly") {
      count = Math.abs(moment(from, "YYYY-MM-DD").startOf("day").diff(moment(to, "YYYY-MM-DD").startOf("weeks"), "weeks")) + 1;
      for (let i = 1; i < count; i++) {
        calendarEvents.push({
          agentId: userState?.agent?.agentId,
          mlsId: parseInt(mlsId) ?? null,
          startDate: moment(values?.StartDate).add(i, "weeks").format("MM/DD/YYYY"),
          endDate: moment(values?.EndDate).add(i, "weeks").format("MM/DD/YYYY"),
          startTime: moment(values?.StartTime).format("HH:mm:ss A"),
          endTime: moment(values?.EndTime).format("HH:mm:ss A"),
          recuringStatus: values?.RecurringStatus,
          description: values?.Description,
          buyerId: listingBuyerId ? listingBuyerId : sellerId ? sellerId : 1,
          isAccepted: false,
          recuringStartDate: moment(customStartDate).utc().format("MM/DD/YYYY"),
          recuringEndDate: moment(customEndDate).utc().format("MM/DD/YYYY"),
          type: "Available",
          sender: "agent",
          isRejected: false,
          propertyDetails: "8",
          agentAgentId: userState?.agent?.agentId,
          buyerBuyerId: listingBuyerId ? listingBuyerId : sellerId ? sellerId : 1,
          client: "",
          listingAgentName: userState?.agent?.name ?? ""
        });
      }
    } else if (values?.RecurringStatus === "Monthly") {
      count = Math.abs(moment(from, "YYYY-MM-DD").startOf("day").diff(moment(to, "YYYY-MM-DD").startOf("day"), "months")) + 1;
      for (let i = 1; i < count; i++) {
        calendarEvents.push({
          agentId: userState?.agent?.agentId,
          mlsId: parseInt(mlsId) ?? null,
          startDate: moment(values?.StartDate).add(i, "months").format("MM/DD/YYYY"),
          endDate: moment(values?.EndDate).add(i, "months").format("MM/DD/YYYY"),
          startTime: moment(values?.StartTime).format("HH:mm:ss A"),
          endTime: moment(values?.EndTime).format("HH:mm:ss A"),
          recuringStatus: values?.RecurringStatus,
          description: values?.Description,
          // buyerId: sellerId ? sellerId : 53,
          buyerId: listingBuyerId ? listingBuyerId : sellerId ? sellerId : 1,
          isAccepted: false,
          recuringStartDate: moment(customStartDate).format("MM/DD/YYYY"),
          recuringEndDate: moment(customEndDate).format("MM/DD/YYYY"),
          type: "Available",
          sender: "agent",
          isRejected: false,
          propertyDetails: "9",
          agentAgentId: userState?.agent?.agentId,
          buyerBuyerId: listingBuyerId ? listingBuyerId : sellerId ? sellerId : 1,
          client: "",
          listingAgentName: userState?.agent?.name ?? ""
        });
      }
    } else {
      count = 0;
    }

    let putPayload = {
      viewPropertyCalendarId: eventId,
      agentId: buyerAgentId ? buyerAgentId : userState?.agent?.agentId,
      mlsId: Number(mlsId),
      sender: updateSender?.length > 0 ? updateSender : "agent",
      // startDate: moment(values?.StartDate).utc().format("MM/DD/YYYY"),
      startDate: moment(values?.StartDate).format("MM/DD/YYYY"),
      // endDate: moment(values?.EndDate).utc().format("MM/DD/YYYY"),
      endDate: moment(values?.EndDate).format("MM/DD/YYYY"),
      startTime: moment(values?.StartTime).format("HH:mm:ss A"),
      endTime: moment(values?.EndTime).format("HH:mm:ss A"),
      recuringStatus: values?.RecurringStatus,
      description: values?.Description,
      recuringStartDate: moment(customStartDate).utc().format("MM/DD/YYYY"),
      recuringEndDate: moment(customEndDate).utc().format("MM/DD/YYYY")
    };

    setIsLoading(true);

    if (type != "viewUpdate") {
      try {
        if (userState?.userType == UserType?.AGENT) {
          const response = await postViewPropertyCalendar(calendarEvents, userState?.agent?.agentId, userState?.agent?.token);

          if (response) {
            setIsLoading(false);
          }
          setIsLoading(false);
        }
        setIsLoading(false);
        // getEventDetails();
        getAllEvents();
      } catch (error) {
        setIsLoading(false);
        console?.log("error", error);
      }
    } else {
      try {
        if (userState?.userType == UserType?.AGENT) {
          console.log("putPayloadputPayload", putPayload);
          
          const response = await updateViewPropertyCalendar(putPayload, userState?.agent?.token);
          if (response) {
            // setIsLoading(false);
            getAllEvents();
            return;
          }
          setIsLoading(false);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console?.log("error", error);
      }
    }
  };

  const getAllEvents = async () => {
    try {
      setIsLoading(true);
      const query = history?.location?.pathname?.split("/")?.[2];
      const propertyId = parseInt(query) ?? null;
      let tempEventArray: Array<any> = [];
      const events: any = await getAllViewPropertyByAgetntId(userState?.agent?.agentId, userState?.agent?.token, "calendar", userState, "property calendar");

      const acceptedOnly = events?.filter((item: any) => item?.isAccepted || item?.mlsId === propertyId);
      tempEventArray = [...tempEventArray, ...acceptedOnly];

      const calendarEvent: any = await getBuyerCalendarEvents(sellerId, userState?.agent?.token);

      if(calendarEvent.length > 0 ){
      calendarEvent?.map((item: any, i: number) => {
        const stYear: any = new Date(item?.startDate).getFullYear();
        const stmonth: any = new Date(item?.startDate).getMonth();
        const stDay: any = new Date(item?.startDate).getUTCDate();
        let stHours: any = Number(item?.startTime?.split(":")?.[0]);
        let mins: any = Number(item?.startTime?.split(":")?.[1]);
        const endmonth: any = new Date(item?.endDate).getMonth();
        const endDay: any = new Date(item?.endDate).getUTCDate();
        let endHours: any = Number(item?.endTime?.split(":")?.[0]);
        let endmins: any = Number(item?.endTime?.split(":")?.[1]);

        if (item?.startTime?.split(" ")?.[1]?.toLowerCase() === "pm" && stHours < 12) stHours += 12;
        if (item?.endTime?.split(" ")?.[1]?.toLowerCase() === "pm" && endHours < 12) endHours += 12;
        if (mins == "0") mins = "00";
        if (endmins == "0") endmins = "00";
        tempEventArray?.push({
          id: "seller",
          title: "Free",
          groupId: "Calendar Event",
          start: new Date(Number(stYear), Number(stmonth), Number(stDay), Number(stHours), Number(mins), 0),
          end: new Date(Number(stYear), Number(endmonth), Number(endDay), Number(endHours), Number(endmins), 0),
          recurstart: new Date(Number(stYear), Number(stmonth), Number(stDay), Number(stHours), Number(mins), 0),
          recurend: new Date(Number(stYear), 8, 5, 10, 30, 0),
          rStatus: item?.recurringStatus,
          eventId: item?.calendarId,
          Stime: item?.startTime,
          Sdate: item?.startDate,
          Etime: item?.endTime,
          Edate: item?.endDate,
          type: "Calendar Event",
          rEdate: item?.recuringEndDate,
          color: "red"
        });
      });
    }
      setEvents(acceptedOnly);
      setIsLoading(false);
    } catch (error) {
      console.log("Err:", error);
    }
  };
  useEffect(() => {
    getAllEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [avaibilityModalVisible, setAvaibilityModalVisible] = useState(false);
  const [isShowingCalendarModal, setIsShowingCalendarModal] = useState(false);

  let [availabilityModalData, setAvailabilityModalData] = useState({
    start: "",
    end: "",
    title: ""
  });
  let [recurrenceModalVisible, setRecurrenceModalVisible] = useState(false);
  let [recurrenceModalData, setRecurrenceModalData] = useState({
    start: "",
    end: "",
    title: ""
  });

  const [agentAccountSettings, setAgentAccountSettings] = useState({
    markWeekends: [],
    workingHoursFrom: "",
    workingHoursTo: ""
  });
  const [argData, setArgData] = useState(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [offCalDays, setOffCalDays] = useState([]);
  let [offCalHours, setOffCalHours] = useState({
    startTime: "",
    endTime: ""
  });

  let eventClickHandler = (info: any) => {
    switch (info?.event?._def.publicId) {
      case "blank":
        setRecurrenceModalData((data) => ({
          ...data,
          title: info.event._def.title
        }));
        setRecurrenceModalVisible(true);
        break;
    }
  };

  const calanderModalHandler = () => {
    setIsShowingCalendarModal(!isShowingCalendarModal);
  };

  const viewCalanderModalHandler = () => {
    setIsShowingViewCalendarModal(!isShowingViewCalendarModal);
    setArgData(null);
  };

  const scheduleEditEventModalHandler = () => {
    setIsRescheduleRequestEvent(!isRescheduleRequestEvent);
    setArgData(null);
  };

  const selectHandler = (args: any) => {
    setSdate(args?.start);
    setStime(moment(args?.start).format("HH:mm a"));
    setEdate(args?.end);
    setEtime(moment(args?.end).format("HH:mm"));
    setIsShowingCalendarModal(true);
  };
  let changeView = (value: string) => {
    switch (value) {
      case "timeGridDay":
        calendarRef?.current?.getApi().changeView("timeGridDay");
        break;
      case "timeGridWeek":
        calendarRef?.current?.getApi().changeView("timeGridWeek");
        break;
      case "dayGridMonth":
        calendarRef?.current?.getApi().changeView("dayGridMonth");
        break;
    }
    setView(value);
  };

  let getOffDays = async () => {
    let results = agentAccountSettings?.markWeekends?.map((day) => {
      switch (day) {
        case "Sun":
          return 0;
        case "Mon":
          return 1;
        case "Tue":
          return 2;
        case "Wed":
          return 3;
        case "Thu":
          return 4;
        case "Fri":
          return 5;
        case "Sat":
          return 6;
      }
    });

    return results;
  };

  const getDayName = (dayIndex: number) => {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return days[dayIndex];
  };

  let isOffHours = (date: Date) => {
    let day = date?.toDateString()?.split(" ")?.[0];
    if (agentAccountSettings?.markWeekends?.includes(day)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getAgentAccountSettings(userState?.agent?.agentId).then((value: any) => {
      let data = value?.[0];
      let weekends = data?.markWeekends?.split(",");
      setAgentAccountSettings((agentAccountSettings) => ({
        ...agentAccountSettings,
        markWeekends: weekends,
        workingHoursFrom: data?.workingHoursfrom,
        workingHoursTo: data?.workingHourTo
      }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePopoverAvailableChange = (id: number) => {
    setIsAvailablePopoverVisible(!isAvailablePopoverVisible);
  };
  const handleVisibleChange = (arg: any, editclicked: boolean) => {
    setArgData(arg?.event?._def?.extendedProps || null);

    if (editclicked) {
      setSViewTime(arg?.event?._def?.extendedProps?.Stime.toString()?.split(" ")[0]);
      setSViewDate(arg?.event?._instance?.range?.start);
      setEViewTime(arg?.event?._def?.extendedProps?.Etime?.toString()?.split(" ")[0]);
      setEViewDate(arg?.event?._def?.extendedProps?.Edate?.toString()?.split("T")[0]);
      setViewTittle(arg?.event?.title);
      setViewRStatus(arg?.event?._def?.extendedProps?.rStatus);
      setViewREdate(arg?.event?._def?.extendedProps?.rEdate);
      setViewEventId(arg?.event?._def?.extendedProps?.eventId);
      handlePopoverAvailableChange(arg?.event?._def?.extendedProps?.eventId);
    } else {
    }
  };
  useEffect(() => {
    getOffDays().then((value: any) => {
      let offDays = [0, 1, 2, 3, 4, 5, 6];
      if (value?.length > 0) {
        let offDaysArr = offDays?.filter((day) => !value?.includes(day));
        setOffCalDays(offDaysArr);
      }
    });
    let startTime = agentAccountSettings?.workingHoursFrom;
    let endTime = agentAccountSettings?.workingHoursTo;

    setOffCalHours((offCalHours) => ({
      ...offCalHours,
      startTime: startTime,
      endTime: endTime
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentAccountSettings]);

  return (
    <>
      {isLoading && <CustomSpinner />}

      <div
        style={{
          overflowX: isMobile ? "auto" : "hidden",
          position: "relative"
        }}
        className="propertycalendarwidth"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            position: "absolute",
            top: "2px",
            right: "3px",
            zIndex: 10
          }}
        >
          <Select className="viewSelect" value={view} onChange={(e) => changeView(e)}>
            <Option value="timeGridWeek">Week</Option>
            <Option value="timeGridDay">Day</Option>
            <Option value="dayGridMonth">Month</Option>
          </Select>
        </div>
        <ScrollDiv style={{ width: isMobile ? "100%" : "100%" }}>
          <FullCalendar
            plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
            // timeZone="America/New_York"
            initialView={view?.toString()}
            editable={false}
            eventDisplay="block"
            selectable={true}
            select={selectHandler}
            allDaySlot={false}
            eventMaxStack={3}
            selectMirror={true}
            moreLinkClick="month"
            droppable={true}
            eventStartEditable={false}
            eventResizableFromStart={true}
            eventDurationEditable={true}
            dayMaxEvents={true}
            all-day={false}
            displayEventTime={true}
            nowIndicator={true}
            weekends={true}
            handleWindowResize={true}
            eventColor="#378006"
            eventOverlap={false}
            events={events}
            eventClick={(info) => {
              eventClickHandler(info);
            }}
            headerToolbar={{
              left: "prev,next,today",
              center: isCalentitle ? null : "title",
              end: null
            }}
            eventContent={(arg: any, index: any) => {
              const query = history?.location?.pathname?.split("/")?.[2];
              const propertyId = parseInt(query) ?? null;
              return (
                <>
                  {arg.event?._def?.extendedProps?.pid !== propertyId && arg?.event?._def?.publicId !== "seller" ? (
                    <>
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          inset: "50px 0% -300px",
                          backgroundColor: "#d9d9d9",
                          zIndex: 1,
                          padding: "0 4px",
                          whiteSpace: "normal",
                          borderRadius: "10px",
                          borderLeft: "1px solid white",
                          borderTop: "1px solid white",
                          overflow: "hidden"
                        }}
                      >
                        <></>
                      </div>
                    </>
                  ) : (
                    <>
                      {arg?.event?._def?.publicId === "Requested" && (
                        <>
                          <Popover
                            trigger="hover"
                            overlayInnerStyle={{
                              border: "1.4px solid #004876",
                              borderRadius: "10px",
                              zIndex: 999999
                            }}
                            content={
                              <>
                                <CalendarSchedulePopover
                                  argRecieved={setRecievedArg}
                                  extendedProps={arg?.event?._def?.extendedProps}
                                  setIsRescheduleRequestEvent={setIsRescheduleRequestEvent}
                                  getEventDetails={getAllEvents}
                                  arg={arg}
                                  propMlsId={propMlsId}
                                  eventType="Calendar Main"
                                  calendarType="Property Calendar"
                                  setEventFilter={setEventFilter}
                                />
                                {index}
                              </>
                            }
                            id={index}
                          >
                            {arg?.event?._def?.extendedProps?.isAccepted ? (
                              <div
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  inset: "50px 0% -300px",
                                  backgroundColor: "green",
                                  zIndex: 1,
                                  padding: "1px 3px",
                                  whiteSpace: "normal",
                                  borderRadius: "6px",
                                  borderLeft: "1px solid #ccc",
                                  borderTop: "1px solid #ccc",
                                  overflow: "hidden",
                                  textDecoration: arg?.event?._def?.extendedProps?.strike
                                }}
                              >
                                <p style={{ color: "#fff", fontSize: "15px" }}>
                                  {arg?.event?._def?.extendedProps?.systemSchedule ? "LB" : "PS"} {moment(arg?.event.start).format("hh:mm A")} |MLS #{arg?.event?._def?.extendedProps?.listingId}
                                </p>
                              </div>
                            ) : (
                              <div
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  inset: "50px 0% -300px",
                                  backgroundColor: "#505050",
                                  zIndex: 1,
                                  padding: "1px 3px",
                                  whiteSpace: "normal",
                                  borderRadius: "6px",
                                  borderLeft: "1px solid #ccc",
                                  borderTop: "1px solid #ccc",
                                  overflow: "hidden",
                                  textDecoration: arg?.event?._def?.extendedProps?.strike
                                }}
                              >
                                <p style={{ color: "#fff", fontSize: "15px" }}>
                                  {arg?.event?.title} {moment(arg?.event.start).format("hh:mm a")} |Requested
                                </p>
                              </div>
                            )}
                          </Popover>
                        </>
                      )}
                      {arg?.event?._def?.publicId === "Scheduled" && (
                        <>
                          <Popover
                            trigger="hover"
                            overlayInnerStyle={{
                              border: "1.4px solid #004876",
                              borderRadius: "10px",
                              zIndex: 999999
                            }}
                            content={
                              <>
                                <CalendarSchedulePopover
                                  argRecieved={setRecievedArg}
                                  extendedProps={arg?.event?._def?.extendedProps}
                                  setIsRescheduleRequestEvent={setIsRescheduleRequestEvent}
                                  getEventDetails={getAllEvents}
                                  arg={arg}
                                  propMlsId={propMlsId}
                                  eventType="Calendar Main"
                                  calendarType="Property Calendar"
                                  setEventFilter={setEventFilter}
                                />
                                {index}
                              </>
                            }
                            id={index}
                          >
                            {arg?.event?._def?.extendedProps?.isAccepted ? (
                              <div
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  inset: "50px 0% -300px",
                                  backgroundColor: "#004876",
                                  zIndex: 1,
                                  padding: "1px 3px",
                                  whiteSpace: "normal",
                                  borderRadius: "6px",
                                  borderLeft: "1px solid #ccc",
                                  borderTop: "1px solid #ccc",
                                  overflow: "hidden",
                                  textDecoration: arg?.event?._def?.extendedProps?.strike
                                }}
                              >
                                <p style={{ color: "#fff", fontSize: "15px" }}>
                                  {arg?.event?._def?.extendedProps?.systemSchedule ? "LB" : `${arg?.event?._def?.extendedProps?.client?.split(" ")?.[0]}`} {moment(arg?.event.start).format("hh:mm A")}{" "}
                                  |MLS #{arg?.event?._def?.extendedProps?.listingId}
                                </p>
                              </div>
                            ) : (
                              <div
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  inset: "50px 0% -300px",
                                  backgroundColor: "#505050",
                                  zIndex: 1,
                                  padding: "1px 3px",
                                  whiteSpace: "normal",
                                  borderRadius: "6px",
                                  borderLeft: "1px solid #ccc",
                                  borderTop: "1px solid #ccc",
                                  overflow: "hidden",
                                  textDecoration: arg?.event?._def?.extendedProps?.strike
                                }}
                              >
                                <p style={{ color: "#fff", fontSize: "15px" }}>
                                  {arg?.event?._def?.extendedProps?.systemSchedule ? "LB" : `${arg?.event?._def?.extendedProps?.client?.split(" ")?.[0]}`} {moment(arg?.event.start).format("hh:mm A")}{" "}
                                  |Requested
                                </p>
                              </div>
                            )}
                          </Popover>
                        </>
                      )}
                      {arg?.event?._def?.publicId === "Available" && (
                        <>
                          <Popover
                            trigger="hover"
                            id={arg?.event?._def?.extendedProps?.eventId}
                            onVisibleChange={(visible) => {}}
                            overlayInnerStyle={{
                              border: "1.4px solid #004876",
                              borderRadius: "10px"
                            }}
                            content={
                              <>
                                <PropertyCalendarPopoverContent
                                  args={arg}
                                  handleVisibleChange={handleVisibleChange}
                                  calenderEvent={getAllEvents}
                                  setIsAvailablePopoverVisible={setIsAvailablePopoverVisible}
                                  setIsShowingViewCalendarModal={setIsShowingViewCalendarModal}
                                  setSelectRecurringInstanceId={() => {}}
                                  setEditClicked={() => {}}
                                />
                              </>
                            }
                          >
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                inset: "50px 0% -300px",
                                backgroundColor: "#C2D500",
                                zIndex: 1,
                                padding: "0 4px",
                                whiteSpace: "normal",
                                borderRadius: "10px",
                                borderLeft: "1px solid white",
                                borderTop: "1px solid white",
                                overflow: "hidden"
                              }}
                            >
                              <p style={{ color: "black", fontSize: "16px" }}>Available for showing</p>
                            </div>
                          </Popover>
                        </>
                      )}
                      {arg?.event?._def?.publicId === "seller" && (
                        <>
                          <Popover
                            trigger="hover"
                            zIndex={9999999999}
                            overlayInnerStyle={{
                              border: "1.4px solid #004876",
                              borderRadius: "10px"
                            }}
                            content={
                              <>
                                <ConsumerCalendarEventPopover args={arg} isBuyerSide={true} sellerName={sellerName} />
                              </>
                            }
                            id={index}
                          >
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                inset: "50px 0% -300px",
                                backgroundColor: "#EC8B51",
                                zIndex: 1,
                                padding: "0 4px",
                                whiteSpace: "normal",
                                borderRadius: "10px",
                                borderLeft: "1px solid #ccc",
                                borderTop: "1px solid #ccc",
                                overflow: "hidden"
                              }}
                            >
                              <p>{sellerName?.split(" ")?.[0]} | Free</p>
                            </div>
                          </Popover>
                        </>
                      )}
                    </>
                  )}
                </>
              );
            }}
            dayHeaderContent={(arg) => {
              return (
                <div
                  style={{
                    margin: "0px !important",
                    padding: "0px !important"
                  }}
                >
                  {arg?.view?.type !== "dayGridMonth" && (
                    <div>
                      <div
                        style={{
                          color: "#000000"
                        }}
                      >
                        {getDayName(new Date(arg?.date)?.getUTCDay())}
                      </div>
                      <div
                        style={{
                          color: "#000000"
                        }}
                      >
                        {new Date(arg?.date)?.getMonth() + 1} /{new Date(new Date(arg?.date).setUTCDate(new Date(arg?.date).getUTCDate())).getUTCDate().toString()}
                      </div>
                    </div>
                  )}
                  {arg?.view?.type === "dayGridMonth" && (
                    <div>
                      <div
                        style={{
                          color: "#000000"
                        }}
                      >
                        {getDayName(new Date(arg?.date)?.getUTCDay())}
                      </div>
                      <div
                        style={{
                          color: "#000000"
                        }}
                      ></div>
                    </div>
                  )}
                </div>
              );
            }}
            height={"auto"}
            dayCellClassNames={(arg) => {
              if (isOffHours(arg?.date)) {
                return "calendarDayClass";
              }
            }}
            slotLaneClassNames={(arg) => {
              let time = arg?.date?.toTimeString()?.split(" ")[0];
              let isTime = time < offCalHours?.startTime || time > offCalHours?.endTime;
              let day = arg?.date?.toDateString()?.split(" ")?.[0];
              if (isTime && !agentAccountSettings?.markWeekends?.includes(day)) {
                return ["offHours"];
              } else {
                return ["timeGrid"];
              }
            }}
            buttonText={{
              today: "Today",
              month: "Month",
              week: "Week",
              day: "Day"
            }}
            ref={calendarRef}
          />
        </ScrollDiv>
        <CalendarAvailabilityModal
          isNew={isNew}
          isEdit={isEdit}
          values={values}
          setValues={setValues}
          setIsModalVisible={setIsModalVisible}
          avaibilityModalVisible={avaibilityModalVisible}
          setAvaibilityModalVisible={setAvaibilityModalVisible}
          availabilityModalData={availabilityModalData}
        ></CalendarAvailabilityModal>
        {isShowingCalendarModal && (
          <CalendarModal
            isShowing={isShowingCalendarModal}
            Sdate={Sdate}
            Stime={Stime}
            Edate={Edate}
            Etime={Etime}
            closeAction={calanderModalHandler}
            getModalData={getModalData}
            type="view"
            eventType="create"
            setIsRescheduleRequestEvent={setIsRescheduleRequestEvent}
          />
        )}
        {isShowingViewCalendarModal && (
          <CalendarModal
            arg={argData}
            isShowing={isShowingViewCalendarModal}
            Sdate={SViewDate}
            Stime={SViewTime}
            Edate={EViewDate}
            Etime={EViewTime}
            title={viewTittle}
            rStatus={viewRStatus}
            rEdate={viewREdate}
            eventId={viewEventId}
            setEditClicked={() => {}}
            closeAction={viewCalanderModalHandler}
            getModalData={getModalData}
            type="viewUpdate"
            eventType="update"
            setIsRescheduleRequestEvent={setIsRescheduleRequestEvent}
          />
        )}
        {isRescheduleRequestEvent && (
          <CalendarModal
            arg={recievedArg}
            isShowing={isRescheduleRequestEvent}
            Sdate={recievedArg?.event?._def?.extendedProps?.Sdate}
            Stime={recievedArg?.event?._def?.extendedProps?.Stime}
            Edate={recievedArg?.event?._def?.extendedProps?.Edate}
            Etime={recievedArg?.event?._def?.extendedProps?.Etime}
            title={recievedArg?.event?.title}
            rStatus={recievedArg?.event?._def?.extendedProps?.rStatus}
            rEdate={recievedArg?.event?._def?.extendedProps?.rEdate}
            eventId={recievedArg?.event?._def?.extendedProps?.eventId}
            setEditClicked={() => {}}
            closeAction={scheduleEditEventModalHandler}
            getModalData={getModalData}
            type="viewUpdate"
            setIsRescheduleRequestEvent={setIsRescheduleRequestEvent}
            eventType="update"
            updateSender="listingAgent"
          />
        )}
        <CustomRecurrenceModal recurrenceModalVisible={recurrenceModalVisible} setRecurrenceModalVisible={setRecurrenceModalVisible} recurrenceModalData={recurrenceModalData}></CustomRecurrenceModal>
      </div>
    </>
  );
};

export default CalendarMain;
