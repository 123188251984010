// import React from 'react';

const ScheduleBody = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '80vh',
        background: '#91aed9',
        padding: '200px',
        textAlign: 'center',
      }}
    >
      <h1>Schedule Three Body</h1>
    </div>
  );
};

export default ScheduleBody;