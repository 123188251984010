/* eslint-disable jsx-a11y/img-redundant-alt */
import { Avatar } from "antd";
import React, { useContext } from "react";
import UserContext, { UserType } from "../../contexts/UserContext";
import { UserOutlined } from "@ant-design/icons";
import Phone from "../../assets/agent-notifications/Icon - phone.svg";
import Email from "../../assets/agent-notifications/Icon - Email.svg";
import Website from "../../assets/agent-notifications/Icon - Website.svg";
import DefaultCompLogo from "../../assets/agent-notifications/defaultCompanyLogo.png";
import { useMediaQuery } from "react-responsive";

type SellerDetail = {
  email?: string;
  name?: string;
  phone?: string;
  avatarUrl?: string;
  website?: string;
  CompanyLogo?: string;
};

type Props = {
  sellerDetail: SellerDetail;
  agentSubscription_type?: string;
};

const SellerHeader = ({ sellerDetail, agentSubscription_type }: Props) => {
  const { userState } = useContext(UserContext);
  const isSm = useMediaQuery({ maxWidth: 650 });
  const isXS = useMediaQuery({ maxWidth: 300 });
  return (
    <>
      <div className="notification-page-user_header">
        <div className="notification-page-user_image">
          {sellerDetail?.avatarUrl && (agentSubscription_type === 'Navihome Advanced Plan' || agentSubscription_type === 'Navihome Premimum Plan') ? (
            <div>
              <Avatar src={sellerDetail?.avatarUrl} />
            </div>
          ) : (
            <div>
              <Avatar icon={<UserOutlined />} />
            </div>
          )}

          <p>{sellerDetail?.name || "--"}</p>
        </div>

        {!isSm && (
          <div className="notification-page-user_media">
            <div className="notification-page-user_media-phone">
              <img src={Phone} alt="phone" />
              <p>{sellerDetail?.phone?.[0] === '+' ? '' : '+'}{sellerDetail?.phone || "--"}</p>
            </div>

            <div
              className="notification-page-user_media-email"
              style={{ marginRight: "35px" }}
            >
              {!isXS && <img src={Email} alt="email" />}
              <p>{sellerDetail?.email || "--"}</p>
            </div>

            <div
              className="notification-page-user_media-website"
              style={{ display: "flex" }}
            >
              {userState?.userType === UserType?.BUYER ? (
                <>
                  {!isXS && (
                    <img
                      src={Website}
                      style={{ paddingRight: "10px" }}
                      alt="website"
                    />
                  )}
                  <p style={{ color: "white" }}>
                    {(agentSubscription_type === 'Navihome Advanced Plan' || agentSubscription_type === 'Navihome Premimum Plan') ? sellerDetail?.website : "--"}
                  </p>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}

        <div className="notification-page-user_logo">
          {userState?.userType === UserType?.BUYER ? (
            <div className="notification-page-user_media-logo">
              {!isXS &&
                (sellerDetail?.CompanyLogo && (agentSubscription_type === 'Navihome Advanced Plan' || agentSubscription_type === 'Navihome Premimum Plan') ? (
                  <img
                    src={sellerDetail?.CompanyLogo}
                    alt="image"
                    style={{ height: "38px", width: "130px" }}
                  />
                ) : (
                  <img
                    src={DefaultCompLogo}
                    alt="image"
                    style={{ height: "38px", width: "130px" }}
                  />
                ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default SellerHeader;
