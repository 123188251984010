/* eslint-disable jsx-a11y/img-redundant-alt */
import { Avatar, Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import requestShowingOn from '../assets/images/button-request-showing-on.svg';
import requestShowingOff from '../assets/images/button-request-showing-off.svg';
import favoriteOn from '../assets/images/button-favorite-on.svg';
import favoriteOff from '../assets/images/button-favorite-off.svg';
import buttonRemoveProperty from '../assets/images/button-remove-property.svg';
import iconPhone from '../assets/images/icon-phone.svg';
import iconEmail from '../assets/images/icon-email.svg';
import homeWithClickq from '../assets/CalendarPopover/Mask Group 609.svg';
import Accepted from '../assets/CalendarPopover/Group 1217.svg';

import styled from '@emotion/styled';
import { UserOutlined } from '@ant-design/icons';
import {

  removeLinkedPropertyForBuyer,
  
} from '../services/NavihomeService';
import UserContext, { UserType } from '../contexts/UserContext';
import AgentContext from '../contexts/AgentContext';

import ListedAgentModal from '../modals/GoogleMapModals/ReginaGeorge';
import NotListedAgentModal from '../modals/GoogleMapModals/Kevinheardsib';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

const FlexContainer = styled.div<{
  direction: string;
  alignItem: string;
  justify: string;
}>`
  display: flex;
  flex-direction: ${(props) => props?.direction};
  align-items: ${(props) => props?.alignItem};
  justify-content: ${(props) => props?.justify};
`;


const CustomCardDiv = styled.div<{ isAccepted?: boolean }>`
  width: 65%;
  background: ${(props) => (props.isAccepted ? '#bcf61f' : '#ff8237')};
  border-radius: 7px;
  padding: 4px 8px;
  position: absolute;
  top: 6px;
  text-align: center;
  left: 1rem;
  font-size: 11px;
  color: ${(props) => (props.isAccepted ? '#000' : '#fff')};
  width: 88%;
  @media screen and (max-width: 1650px) {
    width: 84%;
  }
  @media screen and (max-width: 1450px) {
    width: 83%;
  }
  @media screen and (max-width: 1350px) {
    width: 80%;
  }
  @media screen and (max-width: 1200px) {
    width: 91%;
  }
  @media screen and (max-width: 800px) {
    width: 90%;
  }
  @media screen and (max-width: 750px) {
    width: 86%;
  }
  @media screen and (max-width: 576px) {
    width: 94%;
  }
  @media screen and (max-width: 312px) {
    width: 90%;
  }
`;

export const CustomImg = styled.img`
  height: 136px;
  width: 135px;
  @media screen and (min-width: 1200px) {
    width: 100%;
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
`;

interface ToursCardProps {
  item: any;
  buyerGlobalId?: any;
  linkedProperties?: any;
  setLinkedProperties?: any;
  setShowMap?: any;
  setUnlinkPropHeader?: any;
  setTourPropPage?: any;
  index?: number;
  timeStamp?: number;
  startTimeStamp?: any;
  endTimeStamp?: string;
  numberingMarker?: boolean;
  linkedPropertiesData?: any;
  setLinkedPropertiesData?: any;
  setselected?: (val: string) => void;
  setRunMap?: (val: boolean) => void;
  setIsUnlinkedPropertyClicked?: (val: boolean) => void;
  isFavoriteProp?: boolean;
  isRequestedProp?: boolean;
  isListed?: any;
  isAccepted?: boolean;
  isRejected?: boolean;
  property?: any;
  SetShowTimeBar?: any;
  showTimeBar?: boolean;
  setIsAllAccepted?: (val: boolean) => void;
  setScheduleStatus?: (val: boolean) => void;
  setLinkedPropertiesDataMain?: (val: Array<any>) => void;
  linkedPropertiesDataMain?: Array<any>;
  setFilteredLinkedPropertiesMain?: (val: Array<any>) => void;
  filteredLinkedPropertiesMain?: Array<any>;
  setBestRoute?: (val: Array<any>) => void;
  setHandleTogglingClicked?: (val: string) => void;
}

const ToursCard: React.FC<ToursCardProps> = ({
  item,
  setShowMap,
  setUnlinkPropHeader,
  setTourPropPage,
  startTimeStamp,
  endTimeStamp,
  numberingMarker,
  setLinkedPropertiesData,
  setselected,
  setRunMap,
  isFavoriteProp,
  isRequestedProp,
  isListed,
  isAccepted,
  setBestRoute,
  setHandleTogglingClicked,
  property,
  index,
  SetShowTimeBar,
  showTimeBar,
  setScheduleStatus,
  setIsUnlinkedPropertyClicked,
  setLinkedPropertiesDataMain,
  linkedPropertiesDataMain,
  setFilteredLinkedPropertiesMain,
  filteredLinkedPropertiesMain,
}): JSX.Element => {
  const { userState } = useContext(UserContext);
  const { agentState } = useContext(AgentContext);
  const [listedAgent, setListedAgent] = useState(false);
  const [notListedAgent, setNotListedAgent] = useState(false);
  const [agentEmail, setAgentEmail] = useState<string>('');
  const [agentPhone, setAgentPhone] = useState<string>('');
  const [agentPhoto, setAgentPhoto] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [modeOfContact, setModeOfContact] = useState<string>('');
  const [isNotEditable, setIsNotEditable] = useState<boolean>(false);
  const isMD = useMediaQuery({ maxWidth: 1200 });
  const isMD2 = useMediaQuery({ maxWidth: 1300 });
 
  const unlinkproperty = async (propertyId: any) => {
    if (isNotEditable) return;

    if (param.get('statusType') !== 'afterRouteCreated')
      SetShowTimeBar(false);

    let token = '';
    let buyerId = -1;

    switch (userState.userType) {
      case UserType.BUYER:
        buyerId = userState?.buyer?.buyerId;
        token = userState?.buyer?.token;
        break;

      case UserType.AGENT:
        buyerId = agentState?.buyerId;
        token = userState?.agent?.token;
        break;
      default:
        break;
    }

    await removeLinkedPropertyForBuyer(propertyId, buyerId, token);
    setShowMap(false);
    setUnlinkPropHeader(true);
    setTourPropPage(false);
    setselected('prop');
    setIsUnlinkedPropertyClicked(true);
    setRunMap(false);
    const filteredProperties: any = (
      filteredLinkedPropertiesMain.length > 0
        ? filteredLinkedPropertiesMain
        : linkedPropertiesDataMain
    )?.filter((item: any) => {
      return (item?.propertyId ? item?.propertyId : item?.mlsId) !== propertyId;
    });
    setScheduleStatus(true);
    setLinkedPropertiesDataMain(filteredProperties);
    setFilteredLinkedPropertiesMain(filteredProperties);
    setLinkedPropertiesData(filteredProperties);
    setBestRoute([]);
    if (param.get('statusType') === 'afterRouteCreated') {
      setHandleTogglingClicked('Clicked');
    }
  };

  const handleAgentDetail = (item?: any) => {
    if (isListed === true || isListed === '1') {
      // that means agent is listed
      setUserName(item?.listingAgent?.name ? item?.listingAgent?.name : item?.listingAgentName);
      setAgentEmail(item?.listingAgent?.email ? item?.listingAgent?.email : item?.listingAgentEmail);
      setAgentPhone(item?.listingAgent?.phone);
      setAgentPhoto(item?.listingAgent?.avatarUrl);
      setModeOfContact(item?.listingAgent?.preferredContact);
      setListedAgent(true);
    } else {
      // agent is not listed
      setUserName(`${item?.agent?.firstName} ${item?.agent?.lastName}`);
      setAgentPhone(item?.agent?.contact?.office);
      setNotListedAgent(true);
    }
  };

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let param = useQuery();

  useEffect(() => {
    let userRole: any = param.get('role') ?? '';
    if (userState?.userType === UserType.AGENT && userRole === 'Buyer') {
      setIsNotEditable(true);
    } else if (userState?.userType === UserType.BUYER && userRole === 'Agent') {
      setIsNotEditable(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Row
        gutter={[10, 10]}
        key={item?.mlsId}
        style={{
          width: isMD ? '' : '100%',
          borderBottom: ` 4px solid #004876`,
          marginBottom: '10px',
          marginLeft: '17px',
        }}
      // className="main_div_property_detail_image_and_propertyinfo"
      >
        {/* this col is for image sas*/}
        <Col xs={24} sm={24} md={24} lg={24} xl={10}>
          {/* {(numberingMarker ||
            (param.get('formId') &&
              param.get('statusType') === 'afterRouteCreated' &&
              param.get('routeStatus') === 'Scheduled')) && (
            <CustomCardDiv isAccepted={isAccepted ? true :false}>
              {startTimeStamp && endTimeStamp
                ? startTimeStamp + '-' + endTimeStamp
                : '00:00 - 00:00'}
            </CustomCardDiv>
          )} */}
          {showTimeBar ? (
            <>
              {param.get('formId') &&
                param.get('statusType') === 'afterRouteCreated' && item.isOutOfTheBox !== "YES" &&
                property?.[index]?.startTime?.length > 0 ? (
                <>
                  {property?.[index]?.startTime === 'Invalid date' ? (
                    <></>
                  ) : (
                    <>
                      <CustomCardDiv isAccepted={isAccepted ? true : false}>
                        {moment(property?.[index]?.startTime, 'hh:mm a').format(
                          'hh:mm a'
                        ) +
                          '-' +
                          moment(property?.[index]?.endTime, 'hh:mm a').format(
                            'hh:mm a'
                          )}
                      </CustomCardDiv>
                    </>
                  )}
                </>
              ) : (
                numberingMarker && item.isOutOfTheBox !== "YES" && (
                  <>
                    <CustomCardDiv isAccepted={isAccepted ? true : false}>
                      {property?.[index]?.startTime?.length > 0 ? (
                        <>
                          {moment(
                            property?.[index]?.startTime,
                            'hh:mm a'
                          ).format('hh:mm a') +
                            '-' +
                            moment(
                              property?.[index]?.endTime,
                              'hh:mm a'
                            ).format('hh:mm a')}
                        </>
                      ) : startTimeStamp && endTimeStamp ? (
                        moment(startTimeStamp, 'hh:mm a').format('hh:mm a') +
                        '-' +
                        moment(endTimeStamp, 'hh:mm a').format('hh:mm a')
                      ) : (
                        '00:00 - 00:00'
                      )}
                    </CustomCardDiv>
                  </>
                )
              )}
            </>
          ) : (
            <></>
          )}
          <CustomImg
            className="tour_detail_property_card"
            src={item?.photos?.[0]}
            alt="Property"
            width={isMD2 ? '110px' : '125px'}
            height="125px"
          />
        </Col>
        {/* this col is for price and property details */}
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <FlexContainer
            direction="row"
            alignItem="center"
            justify="space-between"
            className="list-price-img-container"
          >
            <h4>
              {item?.listPrice?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 3,
              }) ?? ''}
            </h4>
            {isListed === true || isListed === '1' ? (
              <img src={homeWithClickq} alt="home image" />
            ) : (
              <></>
            )}
            <div className="property-card__buttons">
              <div key={item?.mlsId} className="property-card__button">
                {isRequestedProp ? (
                  <img
                    role="button"
                    src={requestShowingOn}
                    alt="Showing On Button"
                  />
                ) : (
                  <img
                    role="button"
                    src={requestShowingOff}
                    alt="Showing Off Button"
                  />
                )}
              </div>
              <div className="property-card__button">
                {isFavoriteProp ? (
                  <img
                    role="button"
                    src={favoriteOn}
                    alt="Favorite On Button"
                  />
                ) : (
                  <img
                    role="button"
                    src={favoriteOff}
                    alt="Favorite Off Button"
                  />
                )}
              </div>
              <div className="property-card__button">
                {!isAccepted || item.isOutOfTheBox === "YES" ? (
                  <img
                    src={buttonRemoveProperty}
                    alt="remove property"
                    role={'button'}
                    onClick={() => {
                      unlinkproperty(item?.mlsId);
                    }}
                  />
                ) : (
                  <img src={Accepted} alt={'Accepted Image'} />
                )}
              </div>
            </div>
          </FlexContainer>

          <FlexContainer
            direction="column"
            alignItem="flex-start"
            justify="center"
          >
            <p style={{ fontSize: '12px' }}>
              {item?.address?.streetNumber} {item?.address?.streetName}
            </p>
            <p style={{ fontSize: '12px' }}>
              {item?.address?.city},{' '}{item?.address?.state}{' '}
              {item?.address?.postalCode}
            </p>
          </FlexContainer>

          <FlexContainer
            direction="row"
            alignItem="center"
            justify="space-between"
          >
            <div
              className="tour_details_avatar_name"
              onClick={() => {
                if (userState?.userType === UserType.AGENT) {
                  handleAgentDetail(item);
                }
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingTop: '15px',
                cursor: 'pointer',
              }}
            >
              <Avatar
                icon={
                  (isListed === true || isListed === '1') && (item?.listingAgentActivatedPlan === 'Navihome Advanced Plan' || item?.listingAgentActivatedPlan === 'Navihome Premimum Plan') ? '' : <UserOutlined />
                }
                src={
                  (isListed === true || isListed === '1') && (item?.listingAgentActivatedPlan === 'Navihome Advanced Plan' || item?.listingAgentActivatedPlan === 'Navihome Premimum Plan')
                    ? item?.listingAgent?.avatarUrl
                    : ''
                }
                size={40}
                style={{
                  marginRight: '10px',
                  cursor: 'pointer',
                }}
              />
              <div>
                {isListed === true || isListed === '1' ? (
                  <>
                    <span style={{ fontWeight: 'bold' }}>
                      {item?.listingAgent?.name ? item?.listingAgent?.name : item?.listingAgentName}
                    </span>
                    <br />
                    {item?.listingAgent?.company}
                  </>
                ) : (
                  <>
                    <span style={{ fontWeight: 'bold', fontSize: '12px' }}>
                      {item?.agent?.firstName} {item?.agent?.lastName}
                    </span>
                    <br />
                    {item?.office?.name}
                  </>
                )}
                {/* <p>Realogics international</p> */}
              </div>
              {userState?.userType === UserType?.AGENT ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {isListed === true || isListed === '1' ? (
                    <img
                      src={iconEmail}
                      alt="mail_icon"
                      style={{ marginRight: '10px' }}
                    />
                  ) : (
                    <></>
                  )}
                  {
                    <img
                      src={iconPhone}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                      alt="phone_icon"
                    />
                  }
                </div>
              ) : (
                <></>
              )}
            </div>
          </FlexContainer>
        </Col>
      </Row>
      {listedAgent && (
        <ListedAgentModal
          item={item}
          isShowing={listedAgent}
          closeAction={() => {
            setListedAgent(!listedAgent);
          }}
          agentEmail={agentEmail}
          agentPhone={agentPhone}
          modeOfContact={modeOfContact}
          agentPhoto={agentPhoto}
          userName={userName}
          listingAgentActivatedPlan={item?.listingAgentActivatedPlan ?? ''}
        />
      )}

      {notListedAgent && (
        <NotListedAgentModal
          item={item}
          isShowing={notListedAgent}
          closeAction={() => {
            setNotListedAgent(!notListedAgent);
          }}
          agentPhone={agentPhone}
          userName={userName}
        />
      )}
    </>
  );
};

export default ToursCard;
