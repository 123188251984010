/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useContext, useEffect, useState } from "react";
import _debounce from "lodash/debounce";
import { Link, Route, useHistory, useLocation } from "react-router-dom";
import Button, { ButtonType } from "../components/Button";
import UserContext, { UserType } from "../contexts/UserContext";
import calendarIcon from "../assets/images/icon-calendar-big.svg";
import helpIcon from "../assets/find-an-agent-page/Group 987.svg";
import notificationsIcon from "../assets/images/icon-notifications.svg";
import PreLoginNavbar from "./PreLogInNavbar";
import { useMediaQuery } from "react-responsive";
import SearchField from "../components/SearchField";
import { UserOutlined } from "@ant-design/icons";
import FindAnAgentModal from "../modals/findAnAgentModal";
import styled from "@emotion/styled";
import AgentContext from "../contexts/AgentContext";
import { getAgentNotifications, getBuyerInfoService, getAgentInfoService, getBuyerNotificationsByEmail, getBuyerNotificationsById, updatePaymentReminder } from "../services/NavihomeService";
import { Badge, Avatar } from "antd";
import { useGlobal } from "reactn";
import {
  MdOutlineCalendarToday,
  MdOutlineCircleNotifications,
  MdOutlineFeaturedPlayList,
  MdOutlineHelp,
  MdOutlineLogout,
  MdOutlineMoney,
  MdOutlinePermDeviceInformation,
  MdOutlinePerson,
  MdOutlineRealEstateAgent,
  MdOutlineSell,
  MdOutlineSettings,
  MdOutlineShop,
  MdOutlineShowChart
} from "react-icons/md";
import PropertyFiltersContext from "../contexts/PropertyFiltersContext";
import { io } from "socket.io-client";
import { Socket } from "dgram";

const CustomLink = styled(Link)`
  color: black !important;
  font-weight: bold;
`;

const CustomNavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomResponsiveNav = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CustomResponsiveNavCont = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 40px;
  padding: 10px 20px;
`;

interface HeaderProps {
  setIsShowingLoginModal: (value: boolean) => void;
  isShowingLoginModal?: boolean;
}

const Header: React.FC<HeaderProps> = ({ setIsShowingLoginModal, isShowingLoginModal }) => {
  const { agentState } = useContext(AgentContext);
  const { userState, changeUserType, logOut, logIn } = useContext(UserContext);
  const [isShowingsActive, setIsShowingsActive] = useState(false);
  const [isListingsActive, setIsListingsActive] = useState(false);
  const [isBuyActive, setIsBuyActive] = useState(false);
  const [socket, setSocket] = useState<Socket>();
  const [buyerId, setBuyerId] = useState<string>("");
  const [isSellActive, setIsSellActive] = useState(false);
  const [searchText, setSearchText] = useState("");
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: 996 });
  const [message, setMessage] = useState<String[]>([]);
  const [upcomingBuyerName, setUpcomingBuyerName] = useState<string>("");
  const { setQuery, propertyFiltersState, setReceiveNotificationsInfo, setCity, setState, clearFilters } = useContext(PropertyFiltersContext);
  const { name, query, socketCall, messageSocketCaller, createNotificationsInfo, receiveNotificationsInfo, getNotificationsUserId, getNotificationsUserEmail } = propertyFiltersState;
  const [globalState, setGlobalState] = useGlobal<any>("notificationBadge"); // AGENT NOTIFICATIONS
  const [globalStateBuyerNotification, setGlobalStateBuyerNotification] = useGlobal<any>("notificationBadgeBuyer"); // BUYER NOTIFICATIONS
  const [buyerinfo, setBuyerInfo] = useState<any>(null);
  const [agentInfo, setAgentInfo] = useState<any>(null);
  const [isAgentModal, setIsAgentModal] = useState(false);

  // HANDLERS
  const handleLogin = (setIsNavExpanded: any) => {
    setIsShowingLoginModal(true);
    setIsNavExpanded(false);
  };

  const handleLogout = async () => {
    clearFilters();
    logOut();
    if (userState.userType === UserType.AGENT) {
      changeUserType(UserType.AGENT);
      await updatePaymentReminder(userState?.agent?.agentId, true, userState?.agent?.token);
      history.push("/");
    } else {
      history.push("/buy");
    }
  };

  const handleChangeToSaleAgent = (setIsNavExpanded: any) => {
    setIsNavExpanded(false);
    changeUserType(UserType.AGENT);
    history.push("/");
  };
  const handleChangeToPreLoginHome = (setIsNavExpanded: any) => {
    setIsNavExpanded(false);
    changeUserType(UserType.BUYER);
    history.push("/buy");
  };

  // LINKS

  const goToRegister = (setIsNavExpanded: any) => {
    setIsNavExpanded(false);
    history.push("/register");
  };

  const goToPricing = (setIsNavExpanded: any) => {
    setIsNavExpanded(false);
    history.push("/pricing");
  };
  const goToAgentInfo = (setIsNavExpanded: any) => {
    setIsNavExpanded(false);
    const type = history?.location?.pathname?.split("/")?.[1] ?? "";
    history.push(`/agent-info?type=${type}`);
  };

  const goToAccountDetails = (setIsNavExpanded: any) => {
    setIsNavExpanded(false);
    history.push("/account-details");
  };

  const goToAccountSettings = (setIsNavExpanded: any) => {
    setIsNavExpanded(false);
    history.push("/account-settings");
  };
  const goToNotifications = (setIsNavExpanded: any) => {
    setIsNavExpanded(false);
    history.push("/notifications");
  };

  const goToCalendar = (setIsNavExpanded: any) => {
    setIsNavExpanded(false);
    if (userState?.isLoggedIn && userState?.userType?.toLocaleLowerCase() === "buyer" && !userState?.buyer?.isLinked) {
      setIsAgentModal(!isAgentModal);
      return;
    }

    history.push("/calendar");
  };
  const closeFindAnAgentModal = () => {
    setIsAgentModal(false);
  };
  const goToHelp = (setIsNavExpanded: any) => {
    setIsNavExpanded(false);
    history.push("/help");
  };
  const searchAction = (query: any, setIsNavExpanded: any) => {
    setIsNavExpanded(false);
    setSearchText(query);

    const sell = history.location.pathname.split("/")?.[1] ?? "";
    if (sell === "sell") {
      history.push(`/properties?query=${query}&buyerId=${param.get("buyerId")}&type=sell`);
    } else {
      history.push(`/properties?query=${query}&buyerId=${param.get("buyerId")}&searchTextRandomValue=${Math.floor(Math.random() * 100)}`);
    }
  };

  const autoCompleteLocation = (e: string) => {
    setSearchText(e);
    setQuery(e);
    // debounceFn(e);
  };

  const logoClickHandler = (setIsNavExpanded?: any) => {
    setIsNavExpanded(false);
  };
  const showingsActiveHandler = (setIsNavExpanded?: any) => {
    setIsNavExpanded(false);
  };
  const listingsActiveHandler = (setIsNavExpanded?: any) => {
    setIsNavExpanded(false);
  };
  const buyActiveHandler = (setIsNavExpanded?: any) => {
    setIsNavExpanded(false);
  };
  const sellActiveHandler = (setIsNavExpanded?: any) => {
    setIsNavExpanded(false);
  };

  const searchClickHandler = () => {
    const upcomingBuyerId = history?.location?.search?.split("=");
    setBuyerId(upcomingBuyerId?.[1]);
  };

  //Buyer Information
  const getBuyer = async () => {
    try {
      getBuyerInfoService(userState?.buyer?.token, userState?.buyer?.buyerId).then((res: any) => {
        setBuyerInfo(res);
      });
    } catch (error) {
      console.error("ERROR", error);
    }
  };
  //Agent Information
  const getAgent = async () => {
    try {
      getAgentInfoService(userState?.agent?.token, userState?.agent?.agentId).then((res: any) => {
        setAgentInfo(res);
      });
    } catch (error) {
      console.error("ERROR", error);
    }
  };

  useEffect(() => {
    if (userState?.isLoggedIn) {
      getNotifications();
    }
  }, [socketCall]);

  const getNotifications = async () => {
    try {
      const notifications: any = await getAgentNotifications(userState?.agent?.token);

      const filteredNotifications = notifications?.length > 0 && notifications?.filter((item: any) => !item?.isRead && !item.isRejected);
      let groupedNotifications: any = [];
      if (filteredNotifications?.length > 0) {
        await filteredNotifications?.forEach((notf: any, i: number) => {
          let notfGroup = filteredNotifications?.filter((n: any) => n?.buyerId === notf?.buyerId);
          switch (true) {
            case i === 0 && notf?.type !== "invite":
              groupedNotifications.push({
                buyerId: notf?.buyerId,
                id: notf?.id,
                buyerNotf: notfGroup.map((nt: any) => nt?.id),
                type: "group",
                createdAt: notfGroup?.length > 0 ? notfGroup[0]?.createdAt : new Date(),
                message: `You have ${notfGroup?.length} notifications from Buyer ${notf?.buyer?.name}`,
                isNotificationButton: true
              });
              break;
            case notf?.type === "invite":
              groupedNotifications.push({
                buyerId: notf?.buyerId,
                id: notf?.id,
                buyerNotf: [notf],
                type: "invite",
                createdAt: notf?.createdAt,
                message: notf?.message,
                isNotificationButton: false
              });
              break;
            case !groupedNotifications.map((nt: any) => nt?.buyerId).includes(notf?.buyerId):
              groupedNotifications.push({
                buyerId: notf?.buyerId,
                id: notf?.id,
                buyerNotf: notfGroup.map((nt: any) => nt?.id),
                type: "group",
                createdAt: notfGroup?.length > 0 ? notfGroup[0]?.createdAt : new Date(),
                message: `You have ${notfGroup?.length} notifications from Buyer ${notf?.buyer?.name}`,
                isNotificationButton: true
              });
              break;
          }
        });
      }
      setGlobalState(groupedNotifications?.length > 0 ? groupedNotifications?.length : 0);
    } catch (error) {
      console.error(error);
    }
  };

  // BUYERS NOTIFICATIONS

  let getBuyerNotf = async () => {
    let mailNotifications = 0;
    await getMailNotifications(userState?.login?.email).then((value) => {
      mailNotifications = value;
    });

    let idNotifications = 0;
    await getIdNotifications().then((value) => {
      idNotifications = value;
    });

    if (mailNotifications > 0) {
      setGlobalStateBuyerNotification(idNotifications > 0 ? mailNotifications + idNotifications : mailNotifications);
    } else {
      setGlobalStateBuyerNotification(idNotifications > 0 ? idNotifications : 0);
    }
  };

  const getMailNotifications = async (email: string) => {
    try {
      const notifications: any = await getBuyerNotificationsByEmail(email, userState?.buyer?.token);
      const filteredNotifications = notifications?.length > 0 && notifications?.filter((item: any) => !item?.isRead && !item?.isRejected);
      return filteredNotifications?.length;
      // setEmailNotifications(filteredNotifications);
    } catch (error) {
      console.error(error);
    }
  };

  const getIdNotifications = async () => {
    try {
      const notifications: any = await getBuyerNotificationsById(userState?.buyer?.buyerId, userState?.buyer?.token);
      const filteredNotifications = notifications?.length > 0 && notifications?.filter((item: any) => !item?.isRead && !item?.isRejected);
      return filteredNotifications?.length;
      // setIdNotifications(filteredNotifications);
    } catch (error) {
      console.error(error);
    }
  };

  // RENDERS
  const renderAgentButton = (setIsNavExpanded: any) => {
    if (userState?.userType === UserType.BUYER) {
      return (
        <div className="header__agent-button">
          <Button
            type={ButtonType.SIGN_ME_UP2}
            text="AGENT"
            clickHandler={() => {
              handleChangeToSaleAgent(setIsNavExpanded);
            }}
          />
        </div>
      );
    }
  };

  const renderNavPrimary = (setIsNavExpanded: any) => {
    return (
      <div className="header__nav--primary">
        {!userState.isLoggedIn && (
          <Fragment>
            {userState.userType === UserType.AGENT && (
              <p
                className="header__nav--primary__button"
                onClick={() => {
                  goToPricing(setIsNavExpanded);
                }}
              >
                Pricing Plan
              </p>
            )}
            {!userState?.isLoggedIn && userState.userType === UserType.BUYER && isMobile && (
              <>
                <p
                  className="header__nav--primary__button"
                  onClick={() => {
                    buyActiveHandler(setIsNavExpanded);
                  }}
                >
                  <CustomLink to="/buy">Buy</CustomLink>
                </p>
                <p
                  className={isSellActive && "header__nav--primary__button"}
                  onClick={() => {
                    sellActiveHandler(setIsNavExpanded);
                  }}
                >
                  <CustomLink to="/sell">Sell</CustomLink>
                </p>
              </>
            )}
            <p
              className="header__nav--primary__button"
              onClick={() => {
                handleLogin(setIsNavExpanded);
              }}
            >
              Login
            </p>
            <p
              className="header__nav--primary__button"
              onClick={() => {
                goToRegister(setIsNavExpanded);
              }}
            >
              Register
            </p>
            {/* {userState.userType === UserType.AGENT &&
              renderPreLoginButton(setIsNavExpanded)} */}
            {renderAgentButton(setIsNavExpanded)}
          </Fragment>
        )}
        {userState.isLoggedIn && (
          <Fragment>
            <CustomNavContainer>
              <div className="header__nav__signed-in-button" onClick={searchClickHandler}>
                <SearchField
                  placeholder="Enter neighborhood, city, MLS id etc"
                  searchAction={(value) => {
                    searchAction(value, () => { });
                  }}
                  className="header__nav__search-field"
                  defaultValue={searchText}
                  onChange={(e) => {
                    autoCompleteLocation(e);
                  }}
                />
              </div>
              <div
                className="header__nav__signed-in-button"
                onClick={() => {
                  goToHelp(() => { });
                }}
              >
                <img src={helpIcon} alt="Help Icon" />
                <p>Help</p>
              </div>
              <div
                className="header__nav__signed-in-button"
                onClick={() => {
                  goToCalendar(() => { });
                }}
              >
                <img src={calendarIcon} alt="Calendar Icon" />
                <p>Calendar</p>
              </div>
              <div
                className="header__nav__signed-in-button"
                onClick={() => {
                  goToNotifications(() => { });
                }}
              >
                {userState.userType === UserType?.BUYER && (
                  <Badge offset={[-4, 4]} count={globalStateBuyerNotification} overflowCount={99}>
                    <img src={notificationsIcon} alt="Notifications Icon" />
                  </Badge>
                )}
                {userState.userType === UserType?.AGENT && (
                  <Badge offset={[-4, 4]} count={Number(globalState) === 1 || Number(globalState) === 0 || globalState === undefined ? 0 : globalState - 1} overflowCount={99}>
                    <img src={notificationsIcon} alt="Notifications Icon" />
                  </Badge>
                )}
                <p>Notifications</p>
              </div>
              {userState.isLoggedIn && userState.userType === UserType.AGENT && isMobile && (
                <Fragment>
                  <p className="header__nav--primary__button">
                    <Link to="/showings">Showings</Link>
                  </p>
                  <p className="header__nav--primary__button">
                    <Link to="/listings">Listings</Link>
                  </p>
                </Fragment>
              )}
              {userState.isLoggedIn && userState.userType === UserType.BUYER && isMobile && (
                <Fragment>
                  <p className="header__nav--primary__button">
                    <Link to="/buy">Buy</Link>
                  </p>
                  <p className="header__nav--primary__button">
                    <Link to="sell">Sell</Link>
                  </p>
                </Fragment>
              )}

              <div className="header__nav__signed-in-button header__nav__main-dropdown">
                {userState.userType === UserType.BUYER &&
                  (buyerinfo?.avatarUrl ? (
                    <div>
                      <Avatar src={buyerinfo?.avatarUrl} />
                    </div>
                  ) : (
                    <div>
                      <Avatar icon={<UserOutlined />} />
                    </div>
                  ))}

                {userState.userType === UserType.BUYER && <p>{buyerinfo?.name}</p>}
                {userState.userType === UserType.AGENT &&
                  (agentInfo?.avatarUrl ? (
                    <div>
                      <Avatar src={agentInfo?.avatarUrl} />
                    </div>
                  ) : (
                    <div>
                      <Avatar icon={<UserOutlined />} />
                    </div>
                  ))}
                {userState.userType === UserType.AGENT && <p>{userState.agent.name}</p>}

                <div className="header__nav__main-dropdown__content">
                  {userState.userType === UserType.AGENT && (
                    <p
                      onClick={() => {
                        goToPricing(setIsNavExpanded);
                      }}
                    >
                      Pricing Plan
                    </p>
                  )}
                  {userState.userType === UserType.BUYER && userState?.buyer?.isLinked && (
                    <p
                      onClick={() => {
                        goToAgentInfo(setIsNavExpanded);
                      }}
                    >
                      My Agent
                    </p>
                  )}
                  <p
                    onClick={() => {
                      goToAccountDetails(() => { });
                    }}
                  >
                    Account Details
                  </p>
                  <p
                    onClick={() => {
                      goToAccountSettings(() => { });
                    }}
                  >
                    Account Settings
                  </p>
                  <p onClick={handleLogout}>Log out</p>
                </div>
              </div>
            </CustomNavContainer>
          </Fragment>
        )}
      </div>
    );
  };

  const renderNavResponsive = (setIsNavExpanded: any) => {
    return (
      <div className="header__nav--primary">
        <CustomResponsiveNav>
          <div>
            {!userState.isLoggedIn && (
              <Fragment>
                {userState.userType === UserType.AGENT && (
                  <p
                    className="header__nav--primary__button"
                    onClick={() => {
                      goToPricing(setIsNavExpanded);
                    }}
                  >
                    Pricing Plans
                  </p>
                )}
                {!userState?.isLoggedIn && userState.userType === UserType.BUYER && isMobile && (
                  <>
                    <p
                      className="header__nav--primary__button"
                      onClick={() => {
                        buyActiveHandler(setIsNavExpanded);
                      }}
                    >
                      <CustomLink to="/buy">Buy</CustomLink>
                    </p>
                    <p
                      className="header__nav--primary__button"
                      onClick={() => {
                        sellActiveHandler(setIsNavExpanded);
                      }}
                    >
                      <CustomLink to="/sell">Sell</CustomLink>
                    </p>
                  </>
                )}
                <p
                  className="header__nav--primary__button"
                  onClick={() => {
                    handleLogin(setIsNavExpanded);
                  }}
                >
                  Login
                </p>
                <p
                  className="header__nav--primary__button"
                  onClick={() => {
                    goToRegister(setIsNavExpanded);
                  }}
                >
                  Register
                </p>
                {/* {userState.userType === UserType.AGENT &&
              renderPreLoginButton(setIsNavExpanded)} */}
                {renderAgentButton(setIsNavExpanded)}
              </Fragment>
            )}
          </div>
          {userState.isLoggedIn && (
            <Fragment>
              <CustomResponsiveNav>
                <CustomResponsiveNavCont>
                  <SearchField
                    placeholder="Enter neighborhood, city, MLS id etc"
                    searchAction={(value) => {
                      searchAction(value, setIsNavExpanded);
                    }}
                    className="header__nav__search-field"
                    defaultValue={searchText}
                    onChange={(e) => {
                      autoCompleteLocation(e);
                    }}
                  />
                </CustomResponsiveNavCont>
                <CustomResponsiveNavCont>
                  <MdOutlinePerson
                    style={{
                      fontSize: "24px",
                      color: "#1890ff",
                      fontWeight: "lighter",
                      opacity: 0.7
                    }}
                  ></MdOutlinePerson>
                  <div>
                    {userState.userType === UserType.BUYER && <p>{buyerinfo?.name}</p>}
                    {userState.userType === UserType.AGENT && <p>{userState.agent.name}</p>}
                  </div>
                </CustomResponsiveNavCont>
                {userState.userType === UserType.AGENT && (
                  <CustomResponsiveNavCont
                    onClick={() => {
                      goToPricing(setIsNavExpanded);
                    }}
                  >
                    <MdOutlineMoney
                      style={{
                        fontSize: "24px",
                        color: "#1890ff",
                        fontWeight: "lighter",
                        opacity: 0.7
                      }}
                    ></MdOutlineMoney>
                    <p>Pricing Plans</p>
                  </CustomResponsiveNavCont>
                )}
                {userState.userType === UserType.BUYER && userState?.buyer?.isLinked && (
                  <CustomResponsiveNavCont
                    onClick={() => {
                      goToAgentInfo(setIsNavExpanded);
                    }}
                  >
                    <MdOutlineRealEstateAgent
                      style={{
                        fontSize: "24px",
                        color: "#1890ff",
                        fontWeight: "lighter",
                        opacity: 0.7
                      }}
                    ></MdOutlineRealEstateAgent>
                    <p>My Agent</p>
                  </CustomResponsiveNavCont>
                )}
                <CustomResponsiveNavCont
                  onClick={() => {
                    goToAccountDetails(setIsNavExpanded);
                  }}
                >
                  <MdOutlinePermDeviceInformation
                    style={{
                      fontSize: "24px",
                      color: "#1890ff",
                      fontWeight: "lighter",
                      opacity: 0.7
                    }}
                  ></MdOutlinePermDeviceInformation>
                  <p>Account Details</p>
                </CustomResponsiveNavCont>
                <CustomResponsiveNavCont
                  onClick={() => {
                    goToAccountSettings(setIsNavExpanded);
                  }}
                >
                  <MdOutlineSettings
                    style={{
                      fontSize: "24px",
                      color: "#1890ff",
                      fontWeight: "lighter",
                      opacity: 0.7
                    }}
                  ></MdOutlineSettings>
                  <p>Account Settings</p>
                </CustomResponsiveNavCont>

                <CustomResponsiveNavCont
                  onClick={() => {
                    goToCalendar(setIsNavExpanded);
                  }}
                >
                  <MdOutlineCalendarToday
                    style={{
                      fontSize: "24px",
                      color: "#1890ff",
                      fontWeight: "lighter",
                      opacity: 0.7
                    }}
                  ></MdOutlineCalendarToday>
                  <p>Calendar</p>
                </CustomResponsiveNavCont>
                <CustomResponsiveNavCont
                  onClick={() => {
                    goToNotifications(setIsNavExpanded);
                  }}
                >
                  <Badge offset={[-4, 4]} count={globalState === 1 || globalState === 0 || globalState === undefined ? 0 : globalState - 1} overflowCount={99}>
                    <MdOutlineCircleNotifications
                      style={{
                        fontSize: "24px",
                        color: "#1890ff",
                        fontWeight: "lighter",
                        opacity: 0.7
                      }}
                    ></MdOutlineCircleNotifications>
                  </Badge>
                  <p>Notifications</p>
                </CustomResponsiveNavCont>
                {userState.isLoggedIn && userState.userType === UserType.AGENT && isMobile && (
                  <Fragment>
                    <CustomResponsiveNavCont>
                      <MdOutlineShowChart
                        style={{
                          fontSize: "24px",
                          color: "#1890ff",
                          fontWeight: "lighter",
                          opacity: 0.7
                        }}
                      ></MdOutlineShowChart>
                      <p className="header__nav--primary__button">
                        <Link
                          to="/showings"
                          onClick={() => {
                            showingsActiveHandler(setIsNavExpanded);
                          }}
                          style={{ color: "black" }}
                        >
                          Showings
                        </Link>
                      </p>
                    </CustomResponsiveNavCont>
                    <CustomResponsiveNavCont>
                      <MdOutlineFeaturedPlayList
                        style={{
                          fontSize: "24px",
                          color: "#1890ff",
                          fontWeight: "lighter",
                          opacity: 0.7
                        }}
                      ></MdOutlineFeaturedPlayList>
                      {/* <img src={calendarIcon} alt="Calendar Icon" /> */}
                      <p className="header__nav--primary__button">
                        <Link
                          to="/listings"
                          onClick={() => {
                            listingsActiveHandler(setIsNavExpanded);
                          }}
                          style={{ color: "black" }}
                        >
                          Listings
                        </Link>
                      </p>
                    </CustomResponsiveNavCont>
                  </Fragment>
                )}
                {userState.isLoggedIn && userState.userType === UserType.BUYER && isMobile && (
                  <Fragment>
                    <CustomResponsiveNavCont>
                      <MdOutlineShop
                        style={{
                          fontSize: "24px",
                          color: "#1890ff",
                          fontWeight: "lighter",
                          opacity: 0.7
                        }}
                      ></MdOutlineShop>
                      <p className="header__nav--primary__button">
                        <Link
                          to="/buy"
                          onClick={() => {
                            buyActiveHandler(setIsNavExpanded);
                          }}
                          style={{ color: "black" }}
                        >
                          Buy
                        </Link>
                      </p>
                    </CustomResponsiveNavCont>
                    <CustomResponsiveNavCont>
                      {/* <img src=} alt="Calendar Icon" /> */}
                      <MdOutlineSell
                        style={{
                          fontSize: "24px",
                          color: "#1890ff",
                          fontWeight: "lighter",
                          opacity: 0.7
                        }}
                      ></MdOutlineSell>
                      <p className="header__nav--primary__button">
                        <Link
                          to="sell"
                          onClick={() => {
                            sellActiveHandler(setIsNavExpanded);
                          }}
                          style={{ color: "black" }}
                        >
                          Sell
                        </Link>
                      </p>
                    </CustomResponsiveNavCont>
                  </Fragment>
                )}
                <CustomResponsiveNavCont
                  onClick={() => {
                    goToHelp(setIsNavExpanded);
                  }}
                >
                  <MdOutlineHelp
                    style={{
                      fontSize: "24px",
                      color: "#1890ff",
                      fontWeight: "lighter",
                      opacity: 0.7
                    }}
                  ></MdOutlineHelp>
                  <p>Help</p>
                </CustomResponsiveNavCont>

                <CustomResponsiveNavCont onClick={handleLogout}>
                  <MdOutlineLogout
                    style={{
                      fontSize: "24px",
                      color: "#1890ff",
                      fontWeight: "lighter",
                      opacity: 0.7
                    }}
                  ></MdOutlineLogout>
                  <p>Log Out</p>
                </CustomResponsiveNavCont>
              </CustomResponsiveNav>
              <CustomResponsiveNav>
                {/* <div>
                  {userState.isLoggedIn && userState.userType === UserType.AGENT && isMobile && (
                    <Fragment>
                      <p className="header__nav--primary__button">
                        <Link to="/showings">Showings</Link>
                      </p>
                      <p className="header__nav--primary__button">
                        <Link to="/listings">Listings</Link>
                      </p>
                    </Fragment>
                  )}
                </div> */}
                {/* <div>
                  {userState.isLoggedIn && userState.userType === UserType.BUYER && isMobile && (
                    <Fragment>
                      <p className="header__nav--primary__button">
                        <Link to="/buy">Buy</Link>
                      </p>
                      <p className="header__nav--primary__button">
                        <Link to="sell">Sell</Link>
                      </p>
                    </Fragment>
                  )}
                </div> */}
              </CustomResponsiveNav>
              <div className="header__nav__signed-in-button header__nav__main-dropdown">
                {/* <img src={profileIcon} alt="Profile Icon" />
                {userState.userType === UserType.BUYER && <p>{userState.buyer.name}</p>}
                {userState.userType === UserType.AGENT && <p>{userState.agent.name}</p>} */}
                <div className="header__nav__main-dropdown__content">
                  {/* {userState.userType === UserType.AGENT && (
                    <p
                      onClick={() => {
                        goToPricing(setIsNavExpanded);
                      }}
                    >
                      Pricing Plans
                    </p>
                  )}
                  {userState.userType === UserType.BUYER && isBuyerLinked && (
                    <p
                      onClick={() => {
                        goToAgentInfo(setIsNavExpanded);
                      }}
                    >
                      My Agent
                    </p>
                  )} */}
                  {/* <p onClick={goToAccountDetails}>Account Details</p>
                  <p onClick={goToAccountSettings}>Account Settings</p>
                  <p onClick={handleLogout}>Log out</p> */}
                </div>
              </div>
            </Fragment>
          )}
        </CustomResponsiveNav>
      </div>
    );
  };

  const renderNavSecondary = (setIsNavExpanded: any) => {
    return (
      <div className="header__nav--secondary">
        {userState.userType === UserType.BUYER && (
          <Link
            to={!userState?.isLoggedIn ? "/home" : "/dashboard"}
            className="header__logo"
            onClick={() => {
              logoClickHandler(setIsNavExpanded);
            }}
          >
            Navihome
          </Link>
        )}
        {userState.userType === UserType.AGENT && userState?.isLoggedIn && (
          <Link
            to="/"
            className="header__logo"
            onClick={() => {
              logoClickHandler(setIsNavExpanded);
            }}
          >
            Navihome
          </Link>
        )}
        {userState.userType === UserType.AGENT && !userState?.isLoggedIn && (
          <Link
            to="/buy"
            className="header__logo"
            onClick={() => {
              handleChangeToPreLoginHome(setIsNavExpanded);
            }}
          >
            Navihome
          </Link>
        )}

        {!userState?.isLoggedIn && userState.userType === UserType.BUYER && !isMobile && (
          <Fragment>
            <p className="header__nav--secondary__button">
              <Link
                to="/buy"
                style={
                  isBuyActive
                    ? {
                      color: "black",
                      borderBottom: "2px solid #C2D500",
                      paddingBottom: "6px"
                    }
                    : { color: "black" }
                }
                onClick={() => {
                  buyActiveHandler(() => { });
                }}
              >
                Buy
              </Link>
            </p>
            <p className="header__nav--secondary__button">
              <Link
                to="/sell"
                style={
                  isSellActive
                    ? {
                      color: "black",
                      borderBottom: "2px solid #C2D500",
                      paddingBottom: "6px"
                    }
                    : { color: "black" }
                }
                onClick={() => {
                  sellActiveHandler(() => { });
                }}
              >
                Sell
              </Link>
            </p>
          </Fragment>
        )}

        {userState.isLoggedIn && userState.userType === UserType.AGENT && !isMobile && (
          <Fragment>
            <p className="header__nav--secondary__button">
              <Link
                to="/showings"
                style={
                  isShowingsActive
                    ? {
                      color: "#004876",
                      borderBottom: "2px solid #C2D500",
                      paddingBottom: "6px"
                    }
                    : { color: "#004976" }
                }
                onClick={() => {
                  showingsActiveHandler(() => { });
                }}
              >
                Showings
              </Link>
            </p>
            <p className="header__nav--secondary__button">
              <Link
                to="/listings"
                style={
                  isListingsActive
                    ? {
                      color: "#004876",
                      borderBottom: "2px solid #C2D500",
                      paddingBottom: "6px"
                    }
                    : { color: "#004976" }
                }
                onClick={() => {
                  listingsActiveHandler(() => { });
                }}
              >
                Listings
              </Link>
            </p>
          </Fragment>
        )}
        {userState.isLoggedIn && userState.userType === UserType.BUYER && !isMobile && (
          <Fragment>
            <p className="header__nav--secondary__button">
              <Link
                to="/buy"
                style={
                  isBuyActive
                    ? {
                      color: "black",
                      borderBottom: "2px solid #C2D500",
                      paddingBottom: "6px"
                    }
                    : { color: "black" }
                }
                onClick={() => {
                  buyActiveHandler(() => { });
                }}
              >
                Buy
              </Link>
            </p>
            {/* <Link to='/sell' href='/sell'>Bu</Link> */}
            {/* <Route path="/test" exact component={Test} >Buy</Route> */}
            <p className="header__nav--secondary__button">
              <Link
                to="/sell"
                style={
                  isSellActive
                    ? {
                      color: "black",
                      borderBottom: "2px solid #C2D500",
                      paddingBottom: "6px"
                    }
                    : { color: "black" }
                }
                onClick={() => {
                  sellActiveHandler(() => { });
                }}
              >
                Sell
              </Link>
            </p>
          </Fragment>
        )}
      </div>
    );
  };

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let param = useQuery();

  useEffect(() => {
    const path = history.location.pathname;
    const sellTab = param.get("type");
    switch (path) {
      case "/buy":
        setIsBuyActive(true);
        setIsSellActive(false);
        setIsListingsActive(false);
        setIsShowingsActive(false);
        break;
      case "/sell":
        setIsSellActive(true);
        setIsBuyActive(false);
        setIsListingsActive(false);
        setIsShowingsActive(false);
        break;
      case "/listings":
        setIsListingsActive(true);
        setIsBuyActive(false);
        setIsSellActive(false);
        setIsShowingsActive(false);
        break;
      case "/showings":
        setIsShowingsActive(true);
        setIsBuyActive(false);
        setIsSellActive(false);
        setIsListingsActive(false);
        break;
      default:
        setIsBuyActive(false);
        setIsSellActive(false);
        setIsListingsActive(false);
        setIsShowingsActive(false);
    }
    if (sellTab === "sell") setIsSellActive(true);
    if (sellTab === "buy") setIsBuyActive(true);
  }, [history?.location]);

  useEffect(() => {
    setSearchText(query);
  }, [query]);

  useEffect(() => {
    if (userState?.isLoggedIn && userState?.userType === UserType.BUYER) {
      getBuyer();
    }
  }, [name, userState?.buyer]);

  useEffect(() => {
    if (userState?.isLoggedIn) {
      getAgent();
    }
  }, [name, userState?.agent]);

  useEffect(() => {
    if (userState?.isLoggedIn && userState?.userType === UserType?.AGENT) {
      getNotifications();
    }

    if (userState?.isLoggedIn && userState?.userType === UserType?.BUYER) {
      getBuyerNotf();
    }
  }, [userState?.isLoggedIn, globalStateBuyerNotification, globalState, socketCall]);

  useEffect(() => {
    setUpcomingBuyerName(userState?.userType === UserType?.BUYER ? userState?.buyer?.name : agentState?.buyerName);
  }, [history?.location]);

  const recieve = (val: any) => {
    socket?.emit("message", val);
  };

  // useEffect(() => {
  //   // const newSocket: any = io("http://localhost:8001",{multiplex: false}); { multiplex: false, transports: [ "polling"], withCredentials: false, forceNew: true }
  //   const newSocket: any = io(process.env.REACT_APP_BASE_URL, { path: "/apiwebsocket", port: 8001, transports: ["polling"] });
  //   console.log(newSocket, "newSocket")
  //   newSocket?.on("connect", () => {
  //     console.log("connected");

  //   });
  //   // newSocket.
  //   setSocket(newSocket);
  // }, [setSocket]);

  const Messagelistenr = (val: string) => {
    if (userState?.userType === UserType?.BUYER) {
      logIn(userState?.buyer?.Email, userState?.buyer?.Password);
      setCity(String(Math.floor(Math.random() * 100 + 1)));
      setIsAgentModal(false);
    }
    if (userState?.userType === UserType?.AGENT) {
      setState(String(Math.floor(Math.random() * 100 + 1)));
      setCity(String(Math.floor(Math.random() * 100 + 1)));
    }
  };

  useEffect(() => {
    socket?.on("message", Messagelistenr);
    return () => {
      socket?.off("message", Messagelistenr);
    };
  }, [Messagelistenr]);

  useEffect(() => {
    recieve("value");
  }, [messageSocketCaller]);

  const recieveNotifications = (id: number, email: string) => {
    if (email?.length > 0) {
      socket?.emit("AcceptInvitation", email);
    } else {
      socket?.emit("AcceptInvitation", String(id));
    }
  };

  useEffect(() => {
    // const newSocket: any = io("http://localhost:8001",{multiplex: false}); { multiplex: false, transports: ['websocket', "polling"], withCredentials: false, forceNew: true }
    const newSocket: any = io(process.env.REACT_APP_BASE_URL, { path: "/apiwebsocket", transports: ["polling"], port: 8001 });
    console.log(newSocket, "newSocket")
    newSocket?.on("connect", () => {
      console.log("Connection Successful")
    });
    newSocket?.on('error', (error: any) => {
      console.log("Error of Websocket", error);
    })
    setSocket(newSocket);
  }, [setSocket]);

  const notificationsListener = (val: string) => {
    if (userState?.userType === UserType?.BUYER && (val === String(userState?.buyer?.buyerId) || val === userState?.buyer?.Email)) {
      setReceiveNotificationsInfo(String(Math?.floor(Math?.random() * 100 + 1)));
    }
    if (userState?.userType === UserType?.AGENT && (val === String(userState?.agent?.agentId) || val === userState?.agent?.email)) {
      setReceiveNotificationsInfo(String(Math.floor(Math.random() * 100 + 1)));
    }
  };
  useEffect(() => {
    socket?.on("AcceptInvitation", notificationsListener);
    return () => {
      socket?.off("AcceptInvitation", notificationsListener);
    };
  }, [notificationsListener]);

  useEffect(() => {
    recieveNotifications(getNotificationsUserId, getNotificationsUserEmail);
  }, [createNotificationsInfo]);

  useEffect(() => {
    if (userState?.userType === UserType?.AGENT && userState?.isLoggedIn) {
      getNotifications();
    } else if (userState?.isLoggedIn) {
      getBuyerNotf();
    }
  }, [receiveNotificationsInfo]);

  return (
    <header className="header">
      <PreLoginNavbar renderNavSecondary={renderNavSecondary} renderNavPrimary={renderNavPrimary} renderNavResponsive={renderNavResponsive} />
      {/* <div className="header__container">
        {renderNavSecondary()}
        {renderNavPrimary()}
      </div> */}
      {/* {renderBranding()} */}

      {isAgentModal && <FindAnAgentModal isShowing={isAgentModal} closeAction={closeFindAnAgentModal} text="calendar" />}
    </header>
  );
};

export default Header;
