import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Email from "../../assets/agent-notifications/Icon - Email.svg";
import Website from "../../assets/agent-notifications/Icon - Website.svg";
import Phone from "../../assets/agent-notifications/Icon - phone.svg";
import DefaultCompLogo from "../../assets/agent-notifications/defaultCompanyLogo.png";
import PropertyFiltersContext from "../../contexts/PropertyFiltersContext";
import UserContext, { UserType } from "../../contexts/UserContext";
import { Agent, getAgentInfoService } from "../../services/NavihomeService";

const UserHeader = () => {
  const isSm = useMediaQuery({ maxWidth: 650 });
  const isXS = useMediaQuery({ maxWidth: 300 });
  const { userState } = useContext(UserContext);
  const [buyerName, setBuyerName] = useState("");
  const [agentName, setAgentName] = useState("");
  const [agentInfo, setAgentInfo] = useState<Agent>(null);
  const { propertyFiltersState } = useContext(PropertyFiltersContext);
  const { name } = propertyFiltersState;
  const getAgentInfo = async () => {
    const myresult: any = await getAgentInfoService(
      userState.agent.token,
      userState.agent.agentId
    );
  };
  // Agent Information
  const getAgent = async () => {
    try {
      getAgentInfoService(
        userState.agent?.token,
        userState?.agent?.agentId
      ).then((res: any) => {
        setAgentInfo(res);
      });
    } catch (error) {
      console.error("ERROR", error);
    }
  };

  useEffect(() => {
    getAgentInfo();
    trimUserName();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAgent();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const trimUserName = () => {
    if (userState.userType === UserType.BUYER) {
      let user = "";
      let i = 0;
      if (userState.buyer.name.includes(" ")) {
        while (userState.buyer.name[i] !== " ") {
          user += userState.buyer.name[i];
          i++;
        }
        setBuyerName(user);
      } else setBuyerName(userState.buyer.name);
    }
    if (userState.userType === UserType.AGENT) {
      let user = "";
      let i = 0;
      if (userState.agent.name.includes(" ")) {
        while (userState.agent.name[i] !== " ") {
          user += userState.agent.name[i];
          i++;
        }
        setAgentName(user);
      } else setAgentName(userState.agent.name);
    }
  };


  return (
    <>
      <div className="notification-page-user_header">
        <div className="notification-page-user_image">
          {agentInfo?.avatarUrl ? (
            <div>
              <Avatar src={agentInfo?.avatarUrl} />
            </div>
          ) : (
            <div>
              <Avatar icon={<UserOutlined />} />
            </div>
          )}
          {userState.userType === UserType.BUYER && <p>{buyerName}</p>}
          {userState.userType === UserType.AGENT && <p>{agentName}</p>}
        </div>
        {!isSm && (
          <div className="notification-page-user_media">
            <div className="notification-page-user_media-phone">
              <img src={Phone} alt="phone" />
              {userState.userType === UserType.BUYER && <p>{"--"}</p>}
              {userState.userType === UserType.AGENT && <p>{agentInfo?.phone?.[0]==='+'? "" : '+'}{agentInfo?.phone}</p>}
            </div>
            <div className="notification-page-user_media-phone">
              <img src={Email} alt="Email" />
              {userState.userType === UserType.BUYER && <p>{"--"}</p>}
              {userState.userType === UserType.AGENT && <p>{agentInfo?.email}</p>}
            </div>
            <div
              className="notification-page-user_media-website"
              style={{ display: "flex" }}
            >
              {!isXS && (
                <img
                  src={Website}
                  style={{ paddingRight: "10px" }}
                  alt="website"
                />
              )}
              <p style={{ color: "white" }}>{agentInfo?.agents?.moreinfo?.url || "--"}</p>
            </div>
          </div>
        )}
        <div className="notification-page-user_logo">
          <div className="notification-page-user_media-logo">
            {!isXS &&
              (agentInfo?.agents?.moreinfo?.imgurl ? (
                // eslint-disable-next-line jsx-a11y/img-redundant-alt
                <img
                  src={agentInfo?.agents?.moreinfo?.imgurl}
                  alt="image"
                  style={{ height: "38px", width: "130px" }}
                />
              ) : (
                // eslint-disable-next-line jsx-a11y/img-redundant-alt
                <img
                  src={DefaultCompLogo}
                  alt="image"
                  style={{ height: "38px", width: "130px" }}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserHeader;
