import Button, { ButtonType } from "./Button";

interface NotificationBuyerInviteProps {
  text: string,
  timestamp: string,
  buyerId: number,
}

const NotificationBuyerInvite: React.FC<NotificationBuyerInviteProps> = ({ text, timestamp, buyerId }) => {

  const rejectInvite = async () => {

  }

  const acceptInvite = async () => {

  }

  return (
    <div className="notification-buyer-invite">
      <div className="notification-buyer-invite__info-container">
        <div className="notification-buyer-invite__color-flag"></div>
        <div className="notification-buyer-invite__info">
          <p className="notification-buyer-invite__info-text">{text}</p>
          <p className="notification-buyer-invite__timestamp">{timestamp}</p>
        </div>
      </div>
      <div className="notification-buyer-invite__buttons">
        <Button 
          text="REJECT INVITE"
          type={ButtonType.WHITE}
          clickHandler={rejectInvite}
        />
        <Button 
          text="ACCEPT INVITE"
          type={ButtonType.PRIMARY}
          clickHandler={acceptInvite}
          className="notification-buyer-invite__button--accept"
        />
      </div>
    </div>
  );
};

export default NotificationBuyerInvite;