import { UserOutlined } from "@ant-design/icons";
import { Avatar, Col, Row } from "antd";
import Phone from "../../assets/find-an-agent-page/Phone icon.svg";
import Web from "../../assets/find-an-agent-page/Icon - Website.svg";
import Email from "../../assets/find-an-agent-page/Icon - Email.svg";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Button, { ButtonType } from "../Button";
import { useMediaQuery } from "react-responsive";
import DefaultCompLogo from "../../assets/agent-notifications/defaultCompanyLogo.png";
import { Socket } from "socket.io-client";

const CustomDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  // background: green;
  @media screen and (max-width: 576px) {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
`;
const CustomDivLeft = styled.div`
  padding-left: 20px;
  // background: red;
`;
const CustomDivRight = styled.div`
  padding-left: 20px;
  width: 86% !important;
  @media screen and (max-width: 1200px) {
    width: 80% !important;
  }
`;
const CustomLogoDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const CustomSecondDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 670px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`;
const CustomWebCol = styled(Col)`
  display: flex;
  flex-direction: row;
  margin-right: 50px;
`;
const CustomWebRow = styled(Row)`
  padding: 12px 0;
  marginbottom: 18px;
  @media screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;
  }
`;

const CustomAgentTypePara = styled.p`
  background-color: var(--color-secondary);
  padding: 0.4rem 3rem;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  width: fit-content;
  margin-top: 3rem;
`;

const CustomNameHeading = styled.h1`
  font-size: 24px;
  color: #004876;
`;
const CustomTitleCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const CustomTitleCol1 = styled(Col)`
  padding-top: 3rem;
  padding-bottom: 5rem;
  display: flex;
  font-size: 16px;
`;
const CustomRow = styled(Row)`
  justify: space-between;
  @media screen and (max-width: 1018px) {
    justify: center;
  }
`;

interface FindAnAgentPageCardPropsModel {
  imageUrl?: string;
  agentName: string;
  companyLogo?: any;
  agentType?: string;
  mlsAgentId?: string;
  agentPhone?: string;
  agentEmail?: string;
  agentWeb?: string;
  agentAbout?: string;
  avatarUrl?: string;
  agentTagline?: string;
  goToSingleAngent: () => void;
  getAgentInfo1?: (value: any) => void;
  agentSubscription_type?: string;
  agentPreferredContact?: string
}

const FindAnAgentPageCard: React.FC<FindAnAgentPageCardPropsModel> = ({
  agentName,
  companyLogo,
  mlsAgentId,
  agentPhone,
  agentEmail,
  agentWeb,
  agentAbout,
  avatarUrl,
  goToSingleAngent,
  agentTagline,
  agentSubscription_type
}) => {
  const [socket, setSocket] = useState<Socket>();
  const isXS = useMediaQuery({ maxWidth: 576 });
  const isSM = useMediaQuery({ maxWidth: 768 });
  const Messagelistenr = () => {
    // goToSingleAngent;
    // setSocketCall(String(Math.random() * 10) + 10);
  };
  useEffect(() => {
    socket?.on("AcceptInvitation", Messagelistenr);
    return () => {
      socket?.off("AcceptInvitation", Messagelistenr);
    };
  }, [Messagelistenr, setSocket]);
  return (
    <div className="find-an-agent-page-card">
      <CustomDiv>
        <CustomDivLeft>
          <Avatar
            size={isXS ? 138 : 172}
            icon={
              avatarUrl?.length > 0 && (agentSubscription_type === 'Navihome Advanced Plan' || agentSubscription_type === 'Navihome Premimum Plan') ? (
                <img src={avatarUrl} alt=".." />
              ) : (
                <UserOutlined />
              )
            }
          />
        </CustomDivLeft>

        <CustomDivRight>
          <CustomRow justify="space-between">
            <Col lg={19}>
              <CustomNameHeading>{agentName}</CustomNameHeading>

              {agentTagline && agentSubscription_type === 'Navihome Advanced Plan' ? (
                <CustomAgentTypePara>{agentTagline}</CustomAgentTypePara>
              ) : (
                <></>
              )}
              <p style={{ paddingBottom: "10px", marginTop: "2rem" }}>
                MLS Agent ID:
                <span style={{ paddingLeft: "2rem" }}>{mlsAgentId}</span>{" "}
              </p>
              <CustomWebRow>
                <CustomWebCol>
                  <img src={Phone} alt="..." />
                  <p style={{ marginLeft: "8px" }}>
                    {agentPhone?.length > 1 ? `+${agentPhone}` : "..."}
                  </p>
                </CustomWebCol>
                <CustomWebCol>
                  <img src={Email} alt="..." />
                  <p style={{ marginLeft: "8px" }}>
                    {agentEmail?.length > 0 ? agentEmail : "..."}
                  </p>
                </CustomWebCol>
                <CustomWebCol style={{ paddingTop: "5px" }}>
                  <img src={Web} alt="..." />
                  <p style={{ marginLeft: "8px" }}>
                    {agentWeb?.length && (agentSubscription_type === 'Navihome Advanced Plan' || agentSubscription_type === 'Navihome Premimum Plan') ? agentWeb : "..."}
                  </p>
                </CustomWebCol>
              </CustomWebRow>
            </Col>
            <Col>
              {companyLogo?.length > 0 && (agentSubscription_type === 'Navihome Advanced Plan' || agentSubscription_type === 'Navihome Premimum Plan') ? (
                <img
                  src={companyLogo}
                  alt="..."
                  style={{ height: "90px", width: "190px" }}
                />
              ) : (
                <img src={DefaultCompLogo} alt="..." />
              )}
            </Col>
          </CustomRow>
          {!isSM && (
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <CustomTitleCol>
                <CustomTitleCol1> {agentAbout}</CustomTitleCol1>
                <Button
                  type={ButtonType.PRIMARY}
                  text={"CONNECT NOW"}
                  clickHandler={goToSingleAngent}
                />
              </CustomTitleCol>
            </Row>
          )}
        </CustomDivRight>
      </CustomDiv>
      {isSM && (
        <Row style={{ padding: "16px 12px" }}>
          <CustomTitleCol>
            <p style={{ paddingBottom: "20px" }}>{agentAbout}</p>
            <Button
              type={ButtonType.PRIMARY}
              text={"CONNECT NOW"}
              clickHandler={goToSingleAngent}
            />
          </CustomTitleCol>
        </Row>
      )}
    </div>
  );
};
export default FindAnAgentPageCard;
